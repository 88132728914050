import { Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import SmallCard, { SmallCardForBrand, SmallCardForSummary } from "../../../../../components/servicetitan/SmallCard";
import moment from "moment";

export default function WorkOrderCardForCustomerNoteSection(props) {

    const { workOrder, brand } = props

    const WorkOrderId = workOrder?.name?.split(":")

    return <Paper sx={{ width: "100%", borderRadius: "0.5em", border: "1px solid gray", borderLeft: "4px solid #00a79d", marginTop: "0.5em" }}>
        <><Box sx={{ display: "flex", flexDirection: "row", padding: "0.4em", alignItems: "center", borderRadius: "0.5em", gap: "1em", }}>
            <Typography style={{ fontWeight: "bold" }} >{(`#${WorkOrderId[1]}` ?? "")}</Typography>
        </Box>
            <Grid container sx={{ marginLeft: "10px" }} >
                <Grid item xs={1.5} >
                    <SmallCard heading={"Scheduled Date"} value={workOrder?.dateService ? moment.unix(workOrder?.dateService).utc().format('MM/DD/YYYY h:mm A') : "-"}></SmallCard>
                </Grid>
                <Grid item xs={1.5}>
                    < SmallCardForBrand heading={"Brand"} brand={brand}></SmallCardForBrand>
                </Grid>
                <Grid item xs={1.5}>
                    <SmallCard heading={"Route"} value={workOrder?.route ? workOrder?.route.name : "-"}></SmallCard>
                </Grid>
                <Grid item xs={1.5}>
                    <SmallCard heading={"Duration"} value={workOrder?.duration ? `${workOrder?.duration?.fieldValue} ${"mins"}` : "-"}></SmallCard>
                </Grid>
                <Grid item xs={1.5} >
                    <SmallCard heading={"Status"} value={workOrder?.status ? workOrder?.status.fieldValue : ""}></SmallCard>
                </Grid>
                <Grid item xs={1.5} >
                    <SmallCard heading={"Client"} value={workOrder?.client ? workOrder?.client.name : "-"}></SmallCard>
                </Grid>
                <Grid item xs={1.5}>
                    <SmallCard heading={"Contact"} value={workOrder?.contact ? workOrder?.contact.name : "-"}></SmallCard>
                </Grid>
                <Grid item xs={1.5}>
                    <SmallCardForSummary heading={"Location"} value={workOrder?.location ? workOrder?.location.name : "-"}></SmallCardForSummary>
                </Grid>
            </Grid>
            <Grid container sx={{ marginLeft: "10px" }}>
                <Grid item xs={12} >
                    <SmallCardForSummary heading={"Summary"} value={workOrder?.summary ? workOrder?.summary.fieldValue : ""}></SmallCardForSummary>
                </Grid>
            </Grid>
            <Box sx={{ display: "flex", flexDirection: "row", padding: "0.4em", borderRadius: "0.5em", gap: "1em" }}>
            </Box>
        </>
    </Paper>
}