import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { compareTwoStringsInAssendingOrder, compareTwoStringsInDecendingOrder } from '../../../../utils/compareFunctions';
import { getDateTimeStringFromDateObject, getTenantTimeZoneTimeString } from '../../../../utils/time/time';
import { CommonContext } from '../../../../contexts/CommonContextProvider';

const headCells = {
    index: {
        id: 'index',
        numeric: false,
        shouldSort: false,
        alignment: "left",
        label: '#',
    },
    alertTime: {
        id: 'alert_time_local',
        numeric: false,
        shouldSort: true,
        alignment: "left",
        label: 'Alert Time',
    },
    status: {
        id: 'status',
        numeric: false,
        shouldSort: false,
        alignment: "center",
        label: 'Status',
    },
};

function TaskAlertHistoryTableHead(props) {
    const { order, orderBy, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {Object.values(headCells).map((h) => (
                    <TableCell
                        key={h.id}
                        align={h.alignment}
                        padding={h.id === headCells.index.id ? "1em" : "none"}
                        sortDirection={orderBy === h.id ? order : false}>
                        {h.shouldSort ? <TableSortLabel
                            active={orderBy === h.id}
                            direction={orderBy === h.id ? order : 'asc'}
                            onClick={createSortHandler(h.id)}
                        >
                            {h.label}
                            {orderBy === h.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                            : h.label
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

TaskAlertHistoryTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export default function TaskAlertHistory(props) {
    const { taskAlerts = [], task } = props

    const { generalTenantConfiguration, timezones } = React.useContext(CommonContext)

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState();

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    function compareFunction(a, b) {
        const result = order === 'desc' ? compareTwoStringsInDecendingOrder(a.alert_time_local, b.alert_time_local) : compareTwoStringsInAssendingOrder(a.alert_time_local, b.alert_time_local)
        return result
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2, padding: "1em", background: 'none', boxShadow: '1px 3px 2px -2px rgba(0,0,0,0.2), 1px 2px 2px 1px rgba(0,0,0,0.14), 1px 2px 4px 1px rgba(0,0,0,0.12)' }}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={'small'}
                    >
                        <TaskAlertHistoryTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {taskAlerts?.length === 0 ? <TableCell component="th" align={"center"} colSpan={6} scope="row">No Alerts to show</TableCell>
                                : taskAlerts.sort(compareFunction).map((t, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow hover tabIndex={-1} key={t.name}>
                                            <TableCell component="th" align={"left"} padding={"1em"} id={labelId} scope="row">{index + 1}</TableCell>
                                            <TableCell component="th" align={"left"} padding={'none'} id={labelId} scope="row">{getTenantTimeZoneTimeString(t?.alert_time_utc, generalTenantConfiguration?.time_zone, timezones)}</TableCell>
                                            <TableCell align="center" padding={'none'} >{getTaskAlertStatus(t, task)}</TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );
}

function getTaskAlertStatus(taskAlert, task) {
    if (taskAlert.is_accepted === 1) return "Accepted"
    else if (taskAlert.is_dismissed === 1) return "Dismissed"
    else if (taskAlert.is_snoozed === 1) return "Snoozed"
    else if (task?.is_completed === 1) return "Completed"
    else if (getDateTimeStringFromDateObject(new Date(), taskAlert.time_zone) > taskAlert.alert_time_local) return "Missed"
    else return "Pending"
}