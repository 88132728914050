import { Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import { useContext, useEffect } from "react";
import DescriptionIcon from '@mui/icons-material/Description';
import PersonIcon from '@mui/icons-material/Person';
import CallsHistorySection from "./CallHistorySection";
import { CommonContext } from "../../../contexts/CommonContextProvider";
import CustomDatePicker from "../../CustomDatePicker";
import CustomTextField from "../../CustomTextField";
import CustomerHistorySection from "./CustomerHistorySection";
import { getFiveMinsAfterNow } from "../../../pages/other/components/PhoneClient";

export const HISTORY_SECTIONS = {
    calls: {
        index: 0,
        label: "Call History",
        icon: <DescriptionIcon />
    },
    customers: {
        index: 1,
        label: "Customers",
        icon: <PersonIcon />
    },
}

export default function DialPadHistory(props) {
    const { brand, franchise, filterationData, setFilterationData, customers, setCustomers, calls, setCalls, currentIndex, setCurrentIndex } = props
    const { phoneInfo } = useContext(CommonContext)

    useEffect(() => {
        setFilterationData((filterationData) => {
            return { ...filterationData, phoneNumber: phoneInfo.to, franchiseId: phoneInfo.franchiseId, brandId: phoneInfo.brandId }
        })
    }, [phoneInfo.to, phoneInfo.brandId, phoneInfo.franchiseId, setFilterationData])

    function handleTabChange(e, index) {
        setCurrentIndex(index);
    };

    function getCurrentTabPanelContent(index) {
        switch (index) {
            case HISTORY_SECTIONS.calls.index:
                return <CallsHistorySection brand={brand} franchise={franchise} filterationData={filterationData} calls={calls} setCalls={setCalls} />
            default:
                return <CustomerHistorySection brand={brand} franchise={franchise} filterationData={filterationData} customers={customers} setCustomers={setCustomers} />
        }
    }

    return <Box sx={{ flexGrow: 2, marginBlock: "0.5em", paddingBlock: "1em", borderBottom: 1, backgroundColor: 'background.ternary', paddingInline: "1em" }}>
        <Box width="100%" display="flex" flexDirection="row" gap="0.5em" >
            <Box flexGrow={2} />
            <Box sx={{ width: "14em" }} >
                <CustomDatePicker
                    maxTime={new Date(filterationData.createdOnOrBefore)}
                    label={"Created on or After"}
                    value={new Date(filterationData.createdOnOrAfter)}
                    onChange={(date) => {
                        if (date) setFilterationData({ ...filterationData, createdOnOrAfter: date?.toISOString?.() })
                    }}
                    renderInput={renderFilterDateInput}
                />
            </Box>
            <Box sx={{ width: "14em" }} >
                <CustomDatePicker
                    minTime={new Date(filterationData.createdOnOrAfter)}
                    maxTime={new Date(getFiveMinsAfterNow())}
                    label={"Created on or Before"}
                    value={filterationData.createdOnOrBefore ? new Date(filterationData.createdOnOrBefore) : new Date()}
                    onChange={(date) => {
                        if (date) {
                            setFilterationData({ ...filterationData, createdOnOrBefore: date?.toISOString?.() })
                        }
                    }}
                    renderInput={renderFilterDateInput}
                />
            </Box>
        </Box>
        <Tabs value={currentIndex} onChange={handleTabChange}  >
            {Object.values(HISTORY_SECTIONS).map((v) => {
                return <Tab key={v.index} tabIndex={v.index} label={v.label} icon={v.icon} iconPosition="start" />
            })}
        </Tabs>
        {getCurrentTabPanelContent(currentIndex)}
    </Box>
}

function renderFilterDateInput(params) {
    return <CustomTextField
        style={{ width: "250px", }}
        size={"small"}
        {...params}
        error={params.InputProps.error}
        helperText={params.InputProps.helperText}
    />
}