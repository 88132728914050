import { styled } from '@mui/material/styles';
import Slider from '@mui/material/Slider';
import Dialog from '@mui/material/Dialog';

export const CustomSlider = styled(Slider)(() => ({
    color: "#00a79d"
}));

export const EnterZipModal = styled(Dialog)(() => ({
    position: "absolute",
    marginLeft: "25%"
}));
