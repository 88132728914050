import { Typography, Box, CircularProgress, IconButton, TextField } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import { useContext, useEffect, useMemo, useState } from "react"
import { getConfigMessageFromSectionId, updateConfigMessage } from "../../services/configMesage"
import { NOTIFICATION_TYPES } from "../../constants/common";
import { CommonContext } from "../../contexts/CommonContextProvider";


export default function ConfigMessage(props) {
    const { sectionId, sx } = props

    const [sectionMessage, setSectionMessage] = useState()
    const [isSectionMessageLoading, setIsSectionMessageLoading] = useState(false)
    const [isError, setIsError] = useState("")
    const [isEditMessage, setIsEditMessage] = useState(false)
    const [editedMessage, setEditedMessage] = useState()
    const [isEditLoading, setIsEditLoading] = useState(false)
    const { showNotification } = useContext(CommonContext)
    const canEdit = useMemo(() => { return localStorage.getItem("user_level") > 40 })

    useEffect(() => {
        handleGetConfigMessage(sectionId, setSectionMessage, setIsSectionMessageLoading, setIsError)
    }, [sectionId])

    function onEditMessage() {
        handleUpdateConfigMessage(sectionMessage?.id, editedMessage, setIsEditLoading, onEditDone, onEditError)
    }

    function onEditDone(response) {
        onCancelEdit()
        setSectionMessage(response)
    }

    function onEditError(err) {
        showNotification({ isOpen: true, type: NOTIFICATION_TYPES.ERROR, message: "Important: Could not update message. Please try again" })
    }

    function onClickEditButton() {
        setIsEditMessage(true)
        setEditedMessage(sectionMessage)
    }

    function onCancelEdit() {
        setIsEditMessage(false)
        setEditedMessage()
    }

    return <>
        <Box sx={sx ? sx : { display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
            <Box sx={sx ? sx : { backgroundColor: 'text.highlight', borderRadius: "0.5em", paddingBlock: "0.1em", paddingInline: "0.5em", display: "flex", flexDirection: "row", alignItems: "center", flexGrow: (isEditMessage ? 2 : undefined) }}>
                {isSectionMessageLoading ? <CircularProgress size={20} color="grey" /> : isError ? <></> : isEditMessage ? <TextField variant="standard" multiline sx={{ flexGrow: 4 }} value={editedMessage?.message ?? ""} onChange={(e) => setEditedMessage({ ...editedMessage, message: e.target.value })} /> : <Typography>{sectionMessage?.message}</Typography>}
                {canEdit ? <ConfigMessageEditTools isEditMessage={isEditMessage} onEditMessage={onEditMessage} onCancelEdit={onCancelEdit} onClickEditButton={onClickEditButton} isEditLoading={isEditLoading} /> : <></>}
            </Box>
        </Box >
    </>
}

function ConfigMessageEditTools(props) {
    const { isEditMessage, onEditMessage, onCancelEdit, onClickEditButton, isEditLoading } = props
    return isEditMessage ?
        <>
            {isEditLoading ? <CircularProgress size={20} color="grey" /> : <IconButton key={"done_button"} onClick={onEditMessage}>{<DoneIcon fontSize={"10"} />}</IconButton>}
            <IconButton key={"clear_button"} onClick={onCancelEdit}>{<ClearIcon fontSize={"10"} />}</IconButton>
        </> :
        <IconButton key={"edit_button"} onClick={onClickEditButton}>{<EditIcon fontSize={"10"} />}</IconButton>

}


export async function handleGetConfigMessage(sectionId, setMessage, setIsMessageLoading, setIsError) {
    try {
        setIsMessageLoading(true)
        setIsError(false)
        const res = await getConfigMessageFromSectionId(sectionId)
        setMessage(res)
    } catch (err) {
        if (err.statusCode === 404) setIsError(true)
    } finally {
        setIsMessageLoading(false)
    }
}

export async function handleUpdateConfigMessage(id, updateRequest, setIsLoading, onDone, onError) {
    try {
        setIsLoading(true)
        const res = await updateConfigMessage(id, updateRequest)
        onDone(res)
    } catch (err) {
        onError(err)
    } finally {
        setIsLoading(false)
    }

}