import PersonIcon from '@mui/icons-material/Person';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import WorkIcon from '@mui/icons-material/Work';
import EngineeringIcon from '@mui/icons-material/Engineering';

export const CREATE_JOB_SECTION_IDS = { MAIN: "jobs-main-section", CUSTOMER: "jobs-customer-section", LOCATION: "jobs-location-section", CONTACT: "jobs-contact-section", JOB: "jobs-job-section", APPOINTMENT: "jobs-appointment-section" }
export const SECTIONS = {
    CUSTOMER: {
        id: CREATE_JOB_SECTION_IDS.CUSTOMER,
        icon: <PersonIcon sx={{ flex: "0 0 auto" }} />,
        content: "Customer",
    },
    CONTACT: {
        id: CREATE_JOB_SECTION_IDS.CONTACT,
        icon: <ContactMailIcon sx={{ flex: "0 0 auto" }} />,
        content: "Contact",
    },
    LOCATION: {
        id: CREATE_JOB_SECTION_IDS.LOCATION,
        icon: <LocationOnIcon sx={{ flex: "0 0 auto" }} />,
        content: "Location",
    },
    JOB: {
        id: CREATE_JOB_SECTION_IDS.JOB,
        icon: <WorkIcon sx={{ flex: "0 0 auto" }} />,
        content: "Job",
    },
    APPOINTMENT: {
        id: CREATE_JOB_SECTION_IDS.APPOINTMENT,
        icon: <EngineeringIcon sx={{ flex: "0 0 auto" }} />,
        content: "Appointment",
    },
}

export const ALERT_MESSAGES = {
    NOT_SAVED: "Changes you made will not be saved."
}

export const ZIP_CODE_SEARCH_FRANCHISE_TYPES = {
    default: "Default Franchises",
    other: "Other Franchises"
}

export const CONFIG_MESSAGES = {
    SERVICETITAN: {
        NEW_CUSTOMER: {
            id: 5
        },
        EXISTING_CUSTOMER: {
            id: 6
        },
        CONTACTS: {
            id: 7
        },
        NEW_LOCATION: {
            id: 8
        },
        EXISTING_LOCATION: {
            id: 9
        },
        APPOINTMENT: {
            id: 10
        },
        ZIP_COMPARISON: {
            id: 11
        }
    }
}