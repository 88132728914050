import { useState, useEffect } from "react"

export default function useDebounce(initialValue, callbackFunction = (value) => { }, delay = 1000) {
  const [debouncedValue, setDebouncedValue] = useState(initialValue);
  const [isInitialLoading, setIsInitialLoading] = useState(true)

  useEffect(() => {
    if (isInitialLoading) {
      setIsInitialLoading(false)
    } else {
      const timeout = setTimeout(() => {
        callbackFunction(debouncedValue);
      }, delay);
      return () => {
        clearTimeout(timeout);
      };
    }
  },
    [debouncedValue, delay] // eslint-disable-line
  );
  return setDebouncedValue;
}