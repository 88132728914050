import { Autocomplete, Box } from "@mui/material";
import { useContext, useState } from "react";
import { CommonContext } from "../../contexts/CommonContextProvider";
import CustomTextField from "../CustomTextField";
import LoadingButton from "../LoadingButton";
import { handleUpdateGeneralTenantConfiguration } from "../../state-services/configuration/configuration";

export default function GeneralSettingsSection(props) {
    const { timezones, isTimezonesLoading, isTimezonesError, generalTenantConfiguration, setGeneralTenantConfiguration, showNotification } = useContext(CommonContext)

    const [configuration, setConfiguration] = useState(generalTenantConfiguration ?? {});
    const [isUpdateLoading, setIsUpdateLoading] = useState(false);

    return <Box>
        <form onSubmit={(e) => {
            e?.preventDefault()
            handleUpdateGeneralTenantConfiguration(configuration, setIsUpdateLoading, setGeneralTenantConfiguration, showNotification)
        }}>
            <Box sx={{ display: "flex", flexDirection: "row", gap: "1em", flexWrap: "wrap" }} >
                <Autocomplete
                    sx={{ flexGrow: 4 }}
                    size="small"
                    noOptionsText={isTimezonesError ? "Something went wrong" : "No Timezones"}
                    disableClearable
                    options={timezones}
                    loading={isTimezonesLoading}
                    required={true}
                    getOptionLabel={(timeZone) => `${timeZone.time_zone} - ${timeZone.abbreviation}`}
                    value={timezones.find((t) => t.time_zone === configuration?.time_zone) ?? null}
                    onChange={(_, timezone) => {
                        setConfiguration({ ...configuration, time_zone: timezone?.time_zone })
                    }}
                    renderInput={(params) => <CustomTextField {...params} required={true} label="Contact Center Timezone" />}
                />
            </Box>
            <Box sx={{ width: "100%", marginTop: "0.5em", display: "flex", flexDirection: "row" }} >
                <Box flexGrow={2} />
                <LoadingButton type="submit" loading={isUpdateLoading} size="small" variant='contained'>Update</LoadingButton>
            </Box>
        </form>
    </Box>
}