import { Autocomplete, Box, Button, Dialog, CircularProgress, FormControlLabel, FormGroup, IconButton, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import LoadingButton from "../LoadingButton";
import { handleCreateLuminBrandConfiguration, handleGetAllBrandConfigurations, handleGetLuminConfiguration, handleUpdateLuminBrandConfiguration, handleUpdateLuminConfiguration } from "../../state-services/configuration/configuration";
import { useContext, useEffect, useState } from "react";
import PhoneNumberBox from "../PhoneNumberBox";
import RefreshErrorView from '../refreshView/RefreshErrorView';
import ImageBox from "../LogoBox/ImageBox";
import AddIcon from "@mui/icons-material/Add";
import { getMatchingBrandByBrandId } from "../../utils/miscellaneous";
import EditIcon from '@mui/icons-material/Edit';
import iBSBrandLogo from "../../assets/img/brand.png"
import { CommonContext } from "../../contexts/CommonContextProvider";
import CustomTextField from "../CustomTextField";
import { renderBrandOption } from "../servicetitan/RenderInput";

export default function LuminSettingsSection(props) {
    const { brands } = useContext(CommonContext)
    const { showNotification } = props
    const [luminConfigurations, setLuminConfigurations] = useState();
    const [isLuminConfigurationsLoading, setIsLuminConfigurationsLoading] = useState();
    const [isLuminConfigurationsLoadingError, setIsLuminConfigurationsLoadingError] = useState();
    const [luminBrandConfigurations, setLuminBrandConfigurations] = useState([])
    const [isLuminBrandConfigurationsLoading, setIsLuminBrandConfigurationsLoading] = useState(false)
    const [isLuminBrandConfigurationsLoadingError, setIsLuminBrandConfigurationsLoadingError] = useState(false)
    const [toggleRefresh, setToggleRefresh] = useState(true)
    const [toggleRefreshBrandConfig, setToggleRefreshBrandConfig] = useState(true)
    const [luminBrandConfig, setLuminBrandConfig] = useState(null)
    const [isUpdateLoading, setIsUpdateLoading] = useState(false)
    const [brandConfigForConfirmation, setBrandConfigForConfirmation] = useState(null)
    const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false)

    useEffect(() => {
        handleGetLuminConfiguration(setLuminConfigurations, setIsLuminConfigurationsLoading, setIsLuminConfigurationsLoadingError)
    }, [toggleRefresh])

    useEffect(() => {
        handleGetAllBrandConfigurations(setLuminBrandConfigurations, setIsLuminBrandConfigurationsLoading, setIsLuminBrandConfigurationsLoadingError)
    }, [toggleRefreshBrandConfig])

    function handleEditConfig(config) {
        setLuminBrandConfig(config)
    }

    function handleCloseConfirmModal() {
        setBrandConfigForConfirmation(null)
        setIsOpenConfirmModal(false)
    }

    function handleOpenConfirmModal(config) {
        setBrandConfigForConfirmation(config)
        setIsOpenConfirmModal(true)
    }

    function handleAddLuminBrandConfig() {
        setLuminBrandConfig({ brand: "", host_id: "", is_enabled: 1 })
    }

    function filterAlreadyAddedBrands() {
        return brands?.filter(brand => {
            return luminBrandConfigurations?.find(config => config?.brand === brand?.brand_id)
        })
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5em" }}>
            <Box>
                {isLuminBrandConfigurationsLoading || isLuminBrandConfigurationsLoadingError ?
                    <Box sx={{ width: "100%", margin: "1em", display: "flex", justifyContent: "center" }} >
                        {isLuminBrandConfigurationsLoading ? <CircularProgress /> : <RefreshErrorView onRefresh={() => setToggleRefreshBrandConfig(!toggleRefreshBrandConfig)} />}
                    </Box>
                    :
                    luminBrandConfig ?
                        <Box>
                            <Typography marginTop="1em" marginBottom="1em" fontWeight="bold" component="h3" variant="subtitle1" >Brand Credentials</Typography>
                            <BrandConfig config={luminBrandConfig} showNotification={showNotification} setLuminBrandConfig={setLuminBrandConfig} brands={brands} toggleRefreshBrandConfig={toggleRefreshBrandConfig}
                                setToggleRefreshBrandConfig={setToggleRefreshBrandConfig} alreadyAddedBrands={filterAlreadyAddedBrands()} currentBrandConfigs={luminBrandConfigurations} />
                        </Box>
                        :
                        <Box sx={{ display: "flex", overflow: "auto", flexDirection: "column", justifyContent: "start", gap: "0.5em" }}>
                            <Box sx={{ display: "flex", flexDirection: "row", alignContent: "center" }}>
                                <Typography fontWeight="bold" component="h3" variant="subtitle1" >Brand Credentials</Typography>
                                <Tooltip title={"Add Config"}>
                                    <IconButton
                                        onClick={() => handleAddLuminBrandConfig()}
                                        className={"icon-btn"}
                                        sx={{ marginLeft: "auto" }}
                                        aria-label="delete"
                                    >
                                        <AddIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            <Box sx={{ display: "flex", overflow: "auto", height: "30vh" }}>
                                <TableContainer sx={{ flexGrow: 2, display: "flex", flexDirection: "column", marginTop: "0.3em" }}>
                                    <Table aria-label="simple table" stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className="table-header" style={{ textAlign: "left" }} >#</TableCell>
                                                <TableCell className="table-header" style={{ textAlign: "left" }}  >Brand</TableCell>
                                                <TableCell className="table-header" style={{ textAlign: "left" }}  >Host Id</TableCell>
                                                <TableCell className="table-header" style={{ textAlign: "left", minWidth: "100px", paddingLeft: "35px" }} >Actions</TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody sx={{ flexGrow: 2 }}>
                                            {
                                                (luminBrandConfigurations)?.map?.((config, index) => {
                                                    const brand = getMatchingBrandByBrandId(config?.brand, brands)
                                                    return <TableRow>
                                                        <TableCell className="table-header" style={{ textAlign: "left" }} >{index + 1}</TableCell>
                                                        <TableCell className="table-cell" >
                                                            <ImageBox alt="brand-logo" src={brand?.brand_img ?? iBSBrandLogo} />
                                                        </TableCell>
                                                        <TableCell className="table-header" style={{ textAlign: "left" }}  >{config.host_id ? config?.host_id : "-"}</TableCell>
                                                        <TableCell className="table-header" style={{ textAlign: "left", minWidth: "100px" }} >
                                                            <Switch
                                                                checked={config?.is_enabled === 1}
                                                                onChange={(e) => handleOpenConfirmModal(config)}
                                                            />
                                                            <IconButton onClick={() => handleEditConfig(config)} color="primary" size={"medium"} style={{ marginRight: "10px" }}>
                                                                <Tooltip title={"Edit Config"}><EditIcon style={{ height: "20px", width: "20px" }} /></Tooltip>
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>

                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Box>
                }
            </Box>
            <Box>
                {isLuminConfigurationsLoading || isLuminConfigurationsLoadingError ? <Box sx={{ width: "100%", margin: "1em", display: "flex", justifyContent: "center" }} >
                    {isLuminConfigurationsLoading ? <CircularProgress /> : <RefreshErrorView onRefresh={() => setToggleRefresh(!toggleRefresh)} />}
                </Box>
                    : <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "start", gap: "0.5em" }}>
                        <form onSubmit={(e) => {
                            e?.preventDefault()
                            handleUpdateLuminConfiguration(luminConfigurations?.id, luminConfigurations, setIsUpdateLoading, showNotification)
                        }}>
                            <Typography marginTop="1em" marginBottom="1em" fontWeight="bold" component="h3" variant="subtitle1" >General Credentials</Typography>
                            <Box sx={{ display: "flex", flexDirection: "row", gap: "1em", flexWrap: "wrap" }} >
                                <TextField required sx={{ flexGrow: 2 }} size="small" label={"Base URL"} value={luminConfigurations?.base_url ?? ""} onChange={(e) => setLuminConfigurations({ ...luminConfigurations, base_url: e?.target?.value ?? "" })} />
                                <TextField required sx={{ flexGrow: 2, width: "100%" }} size="small" label={"Token"} value={luminConfigurations?.access_token ?? ""} onChange={(e) => setLuminConfigurations({ ...luminConfigurations, access_token: e?.target?.value ?? "" })} />
                                <PhoneNumberBox required sx={{ flexGrow: 2 }} size="small" label={"Test Phone 1"} value={luminConfigurations?.test_phone_one ?? ""} maxLength={10} onChange={(e) => setLuminConfigurations({ ...luminConfigurations, test_phone_one: e?.target?.value ?? "" })} />
                                <PhoneNumberBox required sx={{ flexGrow: 2 }} size="small" label={"Test Phone 2"} value={luminConfigurations?.test_phone_two ?? ""} maxLength={10} onChange={(e) => setLuminConfigurations({ ...luminConfigurations, test_phone_two: e?.target?.value ?? "" })} />
                                <PhoneNumberBox sx={{ flexGrow: 2, }} size="small" label={"Test Phone 3"} value={luminConfigurations?.test_phone_tree ?? ""} maxLength={10} onChange={(e) => setLuminConfigurations({ ...luminConfigurations, test_phone_tree: e?.target?.value ?? "" })} />
                                <TextField required sx={{ flexGrow: 2 }} size="small" label={"Test Email"} value={luminConfigurations?.test_email ?? ""} onChange={(e) => setLuminConfigurations({ ...luminConfigurations, test_email: e?.target?.value ?? "" })} />
                            </Box>
                            <Box sx={{ width: "100%", marginTop: "0.5em", display: "flex", flexDirection: "row" }} >
                                <Box flexGrow={2} />
                                <LoadingButton type="submit" loading={isUpdateLoading} size="small" variant='contained'>Update</LoadingButton>
                            </Box>
                        </form>
                    </Box>
                }
            </Box>
            {isOpenConfirmModal ? <ConfirmationModal onClose={handleCloseConfirmModal} open={isOpenConfirmModal} config={brandConfigForConfirmation} showNotification={showNotification} toggleRefreshBrandConfig={toggleRefreshBrandConfig} setToggleRefreshBrandConfig={setToggleRefreshBrandConfig} /> : <></>}
        </Box>
    )
}

function BrandConfig({ config, showNotification, setLuminBrandConfig, brands, toggleRefreshBrandConfig, setToggleRefreshBrandConfig, alreadyAddedBrands, currentBrandConfigs }) {

    const [brandConfig, setBrandConfig] = useState(config)
    const [initialBrandConfig, setInitialBrandConfig] = useState(config)
    const [isLoading, setIsLoading] = useState(false)
    const [hostIdDuplicateErr, setHostIdDuplicateErr] = useState("")
    const [brand, setBrand] = useState(config?.id ? selectCurrentBrand() : null)

    function selectCurrentBrand() {
        let brand = brands.find((b) => b?.brand_id === config?.brand)
        return brand
    }

    function handleBrandChange(brand) {
        setBrandConfig({ ...brandConfig, brand: brand?.brand_id })
        setBrand(brand)
    }

    function handleSetLuminHostId(e) {
        setHostIdDuplicateErr("")
        let trimmedValue = e?.target?.value?.replace(/\s+/g, '')
        let isDuplicate = checkIfHostIdDuplicate(trimmedValue)
        if (isDuplicate) {
            setHostIdDuplicateErr("Duplicate host id")
        }
        setBrandConfig({ ...brandConfig, host_id: trimmedValue ?? "" })
    }

    function shouldDisableOption(brand_id) {
        let brand = alreadyAddedBrands?.find((b) => b?.brand_id === brand_id)
        if (brand) {
            return true
        } else {
            return false
        }
    }

    function checkIfHostIdDuplicate(host_id) {
        let filterdBrands = currentBrandConfigs?.filter((config) => config?.brand !== initialBrandConfig?.brand)
        let brand = filterdBrands?.find((config) => config?.host_id === host_id)
        if (brand) {
            return true
        } else {
            return false
        }
    }

    return (
        <>
            <form onSubmit={(e) => {
                e?.preventDefault()
                brandConfig?.id ? handleUpdateLuminBrandConfiguration(brandConfig?.id, brandConfig, setIsLoading, showNotification, setLuminBrandConfig, toggleRefreshBrandConfig, setToggleRefreshBrandConfig) : handleCreateLuminBrandConfiguration(brandConfig, setIsLoading, showNotification, setLuminBrandConfig, toggleRefreshBrandConfig, setToggleRefreshBrandConfig)
            }}>
                <Box display="flex" flexDirection="row" alignItems="center" gap="1em"  >
                    <Autocomplete
                        sx={{ minWidth: "300px", maxWidth: "300px", marginRight: "20px", mb: 4, flexGrow: 2 }} size="small" noOptionsText="No brands"
                        disablePortal
                        options={brands}
                        getOptionLabel={(brand) => brand.brand_name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        getOptionDisabled={(option) => shouldDisableOption(option?.brand_id)}
                        renderOption={renderBrandOption}
                        value={(brands?.length > 0 && brand) ? brand : null}
                        onChange={(_, brand) => {
                            handleBrandChange(brand);
                        }}
                        renderInput={(params) => <CustomTextField required={true} {...params} label="Brand" />}
                    />
                    <Box sx={{ flexGrow: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <TextField required sx={{ flexGrow: 2, mb: hostIdDuplicateErr ? 1 : 4 }} error={hostIdDuplicateErr !== ""} helperText={hostIdDuplicateErr || ""} size="small" label={"Host Id"} value={brandConfig?.host_id ?? ""} onChange={(e) => handleSetLuminHostId(e)} />
                    </Box>
                    {!brandConfig?.id ?
                        <FormGroup>
                            <FormControlLabel control=
                                {<Switch
                                    sx={{ mb: 4 }}
                                    checked={brandConfig?.is_enabled === 1}
                                    onChange={(e, cheked) => setBrandConfig({ ...brandConfig, is_enabled: cheked ? 1 : 0 })}
                                />}
                                label={
                                    <Typography sx={{ mb: 4 }}>Enable</Typography>
                                }
                            />
                        </FormGroup>
                        : <></>}
                </Box>
                <Box sx={{ width: "100%", marginTop: "0.5em", display: "flex", flexDirection: "row" }} >
                    <Box flexGrow={2} />
                    <Button
                        size={"small"}
                        variant="contained"
                        onClick={() => {
                            setLuminBrandConfig(null)
                            setInitialBrandConfig(null)
                        }
                        }
                        className={"btn-secondary"}
                        style={{ marginRight: "10px" }}
                    >
                        Cancel
                    </Button>
                    <LoadingButton type="submit" disabled={hostIdDuplicateErr !== ""} loading={isLoading} size="small" variant='contained'>{brandConfig?.id ? "Update" : "Create"}</LoadingButton>
                </Box>
            </form>
        </>
    )
}

function ConfirmationModal({ onClose, open, config, showNotification, toggleRefreshBrandConfig, setToggleRefreshBrandConfig }) {

    const handleClose = () => {
        return onClose()
    }

    const handleYes = () => {
        let brandConfig = { ...config, is_enabled: config?.is_enabled ? 0 : 1 }
        handleUpdateLuminBrandConfiguration(config?.id, brandConfig, () => { }, showNotification, () => { }, toggleRefreshBrandConfig, setToggleRefreshBrandConfig)
        return onClose()
    }

    return (
        <Dialog onClose={handleClose} open={open}>
            <Box className={"dialog"}>
                {config?.is_enabled
                    ?
                    <Box>
                        <h3 className={"dialog-title"} style={{ marginBottom: "5px" }}>Are you sure you want to disable lumin brand configuration ?</h3>
                        <p>By disabling this toggle, Angi leads will no longer be redirected to Lumin for follow-up.</p>
                    </Box>
                    :
                    <Box>
                        <h3 className={"dialog-title"} style={{ marginBottom: "5px" }}>Are you sure you want to enable lumin brand configuration ? </h3>
                        <p>By enabling this toggle, Angi leads will be redirected to Lumin to followup.</p>
                    </Box>
                }
                <Box style={{ display: "flex", justifyContent: "end" }}>
                    <Button
                        size={"small"}
                        variant="contained"
                        onClick={handleClose}
                        className={"btn-secondary"}
                        style={{ marginRight: "10px" }}
                    >
                        No
                    </Button>
                    <Button
                        size={"small"}
                        variant="contained"
                        onClick={() => handleYes()}
                        className={"btn-primary"}
                    >
                        Yes
                    </Button>
                </Box>
            </Box>
        </Dialog>
    )
}
