import CustomTextField from "../CustomTextField"
import { handleGlobalSearch } from "../../state-services/globalSearch/globalSearch"
import { useEffect, useState } from "react"
import useDebounce from "../../hooks/useDebounce"
import { IconButton } from "@mui/material"
import ClearIcon from '@mui/icons-material/Clear';

export default function GlobalSearch({ indexName = "", setIsLoading = (isLoading) => { }, onSearch = (searchValue) => { }, onSuggestions = (records) => { }, searchValueTransformer = (searchValue) => searchValue }) {
    const [searchValue, setSearchValue] = useState("")
    const debouncedGlobalSearch = useDebounce(searchValue, (searchValue) => {
        const transformedSearchValue = searchValueTransformer(searchValue)
        onSearch(searchValue)
        handleGlobalSearch(indexName, transformedSearchValue, onSuggestions, setIsLoading, () => { })
    }, 1000)

    useEffect(() => {
        debouncedGlobalSearch(searchValue)
    }, [debouncedGlobalSearch, searchValue])

    return <CustomTextField
        sx={{ width: "350px" }}
        size="small"
        label="Search"
        value={searchValue}
        inputProps={{ "data-test": "user-search" }}
        InputProps={{
            endAdornment: searchValue ? (
                <IconButton size="small" onClick={() => setSearchValue("")}>
                    <ClearIcon />
                </IconButton>
            ) : undefined
        }}
        onChange={(e) => setSearchValue(e?.target?.value)}
    />
}