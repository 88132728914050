import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { NOTIFICATION_TYPES } from '../../../constants/common';
import { Box } from '@mui/material';

export default function NotificationIcon(props) {
    const { notificationType } = props

    function getNotificationIcon(notificationType = "") {
        switch (notificationType?.toLowerCase()) {
            case NOTIFICATION_TYPES.ERROR:
                return <ErrorOutlineIcon fontSize='small' sx={{ display: "inline", color: getNotificationColor(notificationType) }} />
            case NOTIFICATION_TYPES.WARNING:
                return <WarningAmberIcon fontSize='small' sx={{ display: "inline", color: getNotificationColor(notificationType) }} />
            case NOTIFICATION_TYPES.INFO:
                return <InfoOutlinedIcon fontSize='small' sx={{ display: "inline", color: getNotificationColor(notificationType) }} />
            case NOTIFICATION_TYPES.SUCCESS:
                return <CheckCircleOutlineOutlinedIcon fontSize='small' sx={{ display: "inline", color: getNotificationColor(notificationType) }} />
            default:
                return <></>
        }
    }

    return <Box sx={{ display: "inline" }}>{getNotificationIcon(notificationType)}</Box>
}

function getNotificationColor(notificationType = "") {
    switch (notificationType?.toLowerCase()) {
        case NOTIFICATION_TYPES.ERROR:
            return "error.light"
        case NOTIFICATION_TYPES.WARNING:
            return "warning.light"
        case NOTIFICATION_TYPES.INFO:
            return "info.light"
        case NOTIFICATION_TYPES.SUCCESS:
            return "success.light"
        default:
            return "secondary.light"
    }
}