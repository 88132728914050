export const SESSION_STORAGE_KEYS = {
    FAQ_franchise_id: "faq_franchise_id",  // This is used to get FAQS for different franchises 
    FAQ_key_words: "faq_key_words",
    FAQ_temp: "faq_temp",
    Brand_Image: "brand_img",
    Franchise_Name: "franchise_name",
    User_img: "user_img",
    Question_keys: "question_keys",
    ManualChats: "manual_chats",
    currentJobId: "current_job_id",
    is_call_log_created: "is_call_log_created",
    interactionGuid: "interaction_guid", //changed: moved from local storage to session storage
}

export const LOCAL_STORAGE_KEYS = {
    brandPrimaryId: "brand_primary_id",
    franchiseId: "franchise_id",
    ChannelId: "channel_id",
    media: "media",
    channelName: "channel_name",
    channelId: "channel_id",
    callerId: "caller_id",
    zipCode: "zip_code",
    brandId: "brand_id",
    jobId: "job_id",
    dialerMedia: "dialer_media",
    dialerChannelName: "dialer_channel_name",
    dialerChannelId: "dialer_channel_id",
    dialerCallerId: "dialer_caller_id",
    dialerZipCode: "dialer_zip_code",
    dialerSubject: "dialer_subject",
    dialerFrom: "dialer_from",
}