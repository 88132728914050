import { FetchClient } from "../../utils/fetchClient";

export async function getLuminConfiguration(tenantId = 0) {
    return await FetchClient(`/data/lumin/configuration/general?tenant_id=${tenantId}`).GET({})
}

export async function updateLuminConfiguration(id = 0, configuration = {}) {
    return await FetchClient(`/data/lumin/configuration/general?id=${id}`).PUT({ payload: JSON.stringify(configuration) })
}

export async function getLuminBrandsConfiguration(tenantId = 0) {
    return await FetchClient(`/data/lumin/configuration/brands?tenant_id=${tenantId}`).GET({})
}

export async function createLuminBrandConfiguration(configuration = {}) {
    return await FetchClient(`/data/lumin/configuration/brand`).POST({ payload: JSON.stringify(configuration) })
}

export async function updateLuminBrandConfiguration(id = 0, configuration = {}) {
    return await FetchClient(`/data/lumin/configuration/brand?id=${id}`).PUT({ payload: JSON.stringify(configuration) })
}

export async function updateGeneralTenantConfiguration(tenantId = 0, configuration = {}) {
    return await FetchClient(`/data/system-configuration/general?tenant_id=${tenantId}`).PUT({ payload: JSON.stringify(configuration) })
}

export async function getGeneralTenantConfiguration(tenantId = 0) {
    return await FetchClient(`/data/system-configuration/general?tenant_id=${tenantId}`).GET({})
}