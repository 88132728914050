import { Box, Typography } from "@mui/material"
import ReportIcon from '@mui/icons-material/Report';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';

const NOT_IN_SERVICE_AREA_MESSAGE = "Please ensure you select a franchise that operates in the customer’s location."
const IN_SERVICE_AREA_MESSAGE = "This Zip Code is within the service area."

export default function ServiceAreaMessage(props) {
    const { serviceableZipCodeResponse } = props
    return <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: "1em", borderRadius: "0.5em", gap: "1em" }} >
            {serviceableZipCodeResponse?.isServiceable ? <CheckCircleOutlineRoundedIcon color={"success"} /> : <ReportIcon color={"error"} />}
            <Typography flexGrow={0} color={serviceableZipCodeResponse?.isServiceable ? "green" : "red"}>{serviceableZipCodeResponse?.isServiceable === false ? NOT_IN_SERVICE_AREA_MESSAGE : serviceableZipCodeResponse?.message ? IN_SERVICE_AREA_MESSAGE : ""}</Typography>
        </Box>
    </Box>
}

