import { useState } from "react"
import { Autocomplete, Box, Typography } from "@mui/material"
import useDebounce from "../hooks/useDebounce"
import SearchIcon from '@mui/icons-material/Search';

import { handleGetPlacesAutocomplete, handleLocationAddressSearchPlaceDetails } from "../state-services-dublicate/googleServices"

import poweredByGoogle from "../assets/img/google/powered_by_google_on_white_hdpi.png"
import { CustomTextFieldForLocationAutoComplete } from "./CustomTextField"

const POWERED_BY_GOOGLE = "poweredByGoogle";
const EMPTY_ADDRESS_OBJECT = { street: "", city: "", state: "", zip: "", country: "" };

export default function AddressAutoComplete(props) {
    const { label, onChange, lat, long, radius, crm, isAddressSelected = () => { } } = props;

    const [addressSearchResults, setAddressSearchResults] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState();
    const [sessionToken, setSessionToken] = useState("");
    const debounceSetSearchString = useDebounce("", (searchString) => {
        if (searchString) {
            handleGetPlacesAutocomplete(searchString, sessionToken, setAddressSearchResults, setSessionToken, lat, long, radius);
        } else {
            setAddressSearchResults([])
        }
    }, 1000)

    function handleOnChange(_, value) {
        setSelectedAddress(value)
        if (value) {
            isAddressSelected(true)
            handleLocationAddressSearchPlaceDetails(value?.placesDetails, onChange, setSessionToken, crm)
        } else {
            isAddressSelected(false)
            setAddressSearchResults([])
            onChange(EMPTY_ADDRESS_OBJECT)
        }
    }

    return <Autocomplete
        {...props}
        freeSolo={true}
        data-test={"addressAutoComplete_selector_" + label}
        sx={{ width: "30%", marginRight: "20px" }}
        size="small"
        value={addressSearchResults?.length > 0 ? selectedAddress ?? null : null}
        disablePortal
        options={addressSearchResults}
        filterOptions={(options) => options}
        getOptionDisabled={(option) => option?.description === POWERED_BY_GOOGLE}
        getOptionLabel={(result) => {
            return result.description;
        }}
        renderOption={renderAddressSuggession}
        onChange={handleOnChange}
        renderInput={(params) => renderInput(params)}
    />

    function renderInput(params) {
        let inputValue = params.inputProps.value
        if (inputValue === "undefined") {
            inputValue = ""
        }
        params.inputProps.onKeyDown = (event) => {
            if (event.key === 'Enter') {
                // Prevent the default action to disable the Enter key
                event.preventDefault();
                // Stop the event from propagating to other handlers
                event.stopPropagation();
            }
        };
        let modifiedParams = { ...params, inputProps: { ...params.inputProps, value: inputValue, 'data-test': 'adress_autocomplte_address_value_' + label } }
        return <CustomTextFieldForLocationAutoComplete
            {...modifiedParams}
            onChange={(event) => {
                debounceSetSearchString(event.target.value)
            }}
            label={<Box sx={{ display: "flex", flexDirection: "row", gap: "0.5em", alignItems: "center" }}>
                <SearchIcon ></SearchIcon>
                <Typography variant="subtitle" fontSize="18px" >{label} </Typography>
            </Box>} />
    }

    function renderAddressSuggession(props, result) {
        if (result.description === POWERED_BY_GOOGLE) {
            return (<Box key={POWERED_BY_GOOGLE} style={{ width: "97%", padding: "1%", display: "flex", justifyContent: "end" }}><img alt={"Powered By Google"} height="15px" src={poweredByGoogle} /></Box>)
        }
        else {
            return (<Typography {...props} key={result.place_id}>
                {result.description}
            </Typography>)
        }
    }
}
