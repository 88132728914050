import { useContext, useMemo, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import Container from "@mui/material/Container"
import { updateJob } from "../services/jobService"
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import { JOB_UPDATE_TYPES, NOTIFICATION_TYPES } from '../constants/common';
import IBSBrandImage from "../assets/img/IBS_lOGO.png"
import { removeCallDetailsFromLocalStorage } from '../pages/other/home/SubmitDialog';
import DetailsItem from '../pages/other/home/components/DetailsItem';
import { useHistory, Link } from 'react-router-dom';
import { CommonContext } from '../contexts/CommonContextProvider';
import ImageBox from './LogoBox/ImageBox';
import { SESSION_STORAGE_KEYS } from '../constants/browserStorage';
import { Typography } from '@mui/material';
import { getFromSessionStorage } from '../utils/browserStorage';

export default function CallerDetails(props) {
    const { callerId, zipCode, channelId, channelName, brandId, isPreHoldJob, brandImg, media, holdJobClickCount } = props
    const [dialogOpen, setDialogOpen] = useState(false)
    const { showNotification } = useContext(CommonContext);
    const isDisplayWarningMessage = useRef(false)
    const timerId = useRef(null)
    const job = useMemo(() => {
        return {
            channel_id: channelId,
            franchise_id: -1,
            caller_id: callerId,
            zip_code: zipCode,
            brand_id: brandId,
            call_type: 1,
            channel_name: channelName,
            media: media
        }
    }, [channelId, callerId, zipCode, brandId, channelName, media])

    const handleHoldJobDialogOpen = () => {
        setDialogOpen(true)
        isDisplayWarningMessage.current = true
        handleTimer()
    }

    const handleHoldJobDialogClose = () => {
        setDialogOpen(false)
        holdJobClickCount.current = holdJobClickCount.current + 1
        isDisplayWarningMessage.current = false
        if (timerId.current) clearTimeout(timerId.current)
    }

    const handleTimer = () => {
        timerId.current = setTimeout(() => {
            isDisplayWarningMessage.current = false
        }, 3000)
    }

    return <>
        <Box className={"caller-details bg-shadow"} sx={{ width: "16em" }} flexGrow={0} flexShrink={0}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <h3>Caller Details</h3>
                <Link style={{ textDecoration: "none", fontWeight: "500" }} to="/call-logs"><Button size={"small"} variant="contained" className={"btn-secondary"} onClick={() => { }} >Application Panel</Button></Link>
            </div>
            <Box sx={{ marginTop: "20px", padding: "1em", borderRadius: "0.5em", backgroundColor: "#00a79c36", display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                <DetailsItem heading={"Caller ID"} value={callerId} />
                <DetailsItem heading={"Zip/Postal Code"} value={zipCode} />
                <DetailsItem heading={"Channel ID"} value={channelId} />
                <DetailsItem heading={"Channel Name"} value={channelName} />
            </Box>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div style={{ marginBottom: "25px", marginTop: "30px", textAlign: "center" }}>
                    <ImageBox src={brandImg ?? IBSBrandImage} alt="brandImg" size="xlarge" />
                </div>
            </div>
            {isPreHoldJob ? <></> : <>
                <Container style={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
                    <Button onClick={() => handleHoldJobDialogOpen()} variant="contained" className={"btn-primary"} fullWidth >Hold the Job</Button>
                </Container>
                <SubmitDialog onClose={() => handleHoldJobDialogClose()} job={job} media={media} open={dialogOpen} showNotification={showNotification} isDisplayWarningMessage={isDisplayWarningMessage} holdJobClickCount={holdJobClickCount} />
            </>}
        </Box>
    </>
}

function SubmitDialog(props) {
    const { onClose, open, job, media, showNotification, isDisplayWarningMessage, holdJobClickCount } = props;
    const history = useHistory()
    const handleClose = () => onClose()

    const handleHoldJob = () => {
        let jobId = getFromSessionStorage(SESSION_STORAGE_KEYS.currentJobId)
        updateJob(jobId, JOB_UPDATE_TYPES.JOB, job).then(() => {
            removeCallDetailsFromLocalStorage()
            showNotification({ message: "Job held", type: NOTIFICATION_TYPES.SUCCESS })
            history.replace("/dialer?media=MANUAL")
        }).catch(err => {
            showNotification({ message: "Unable to hold the job", type: NOTIFICATION_TYPES.ERROR })
        })
        onClose()
    }

    const handleOnHoldJobClick = () => {
        handleHoldJob()
    }

    return (
        <Dialog onClose={handleClose} open={open}>
            <div className={"dialog"}  >
                <h3 className={"dialog-title"}>Do you want to hold the job ?</h3>
                {(isDisplayWarningMessage?.current && holdJobClickCount.current < 1 && media !== "MANUAL") && <Box sx={{ marginBottom: "25px" }}>
                    <Typography variant="p"  >⚠️  Hang in There, Agent Feedback Will Arrive Shortly.</Typography>
                </Box>}
                <div style={{ display: "flex", justifyContent: "end" }}>
                    <Button size={"small"} variant="contained" onClick={handleClose} className={"btn-secondary"} style={{ marginRight: "10px" }} >No</Button>
                    <Button size={"small"} variant="contained" onClick={handleOnHoldJobClick} className={"btn-primary"} >yes</Button>
                </div>
            </div>
        </Dialog>
    );
}