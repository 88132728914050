import { useRef, useState, useEffect } from 'react'
import CustomTextField from './CustomTextField';
import { getPhoneNumberWithInputMask, removeInputMask } from '../utils/inputMasks';
import { imposibleToMatchRegex } from '../utils/validators';

export default function PhoneNumberBox(props) {
    const { isWithNormalTextField, fieldId, size, error, helperText, value, onChange = (e) => { }, maxLength } = props;
    let phoneNumberHelperText = helperText;
    let phoneNumberError = Boolean(error)
    if (value?.startsWith("0")) {
        phoneNumberHelperText = "Cannot starts with 0"
        phoneNumberError = true
    }
    const inputPattern = !phoneNumberError ? undefined : imposibleToMatchRegex
    const id = useRef(Math.floor((Math.random() * 1000000) + 1).toString() + "-search-phone-number").current
    const [previousPhoneNumber, setPreviousPhoneNumber] = useState(value ?? "");
    const [previousCursorPosition, setPreviousCursorPostion] = useState(1)
    const [phoneNumber, setPhoneNumber] = useState(value ?? "");

    useEffect(() => {
        setPhoneNumber(value)
    }, [value])

    useEffect(() => {
        if (previousPhoneNumber) {
            const cPosition = getCursorPostionForInputMask(getPhoneNumberWithInputMask(phoneNumber), getPhoneNumberWithInputMask(previousPhoneNumber), previousCursorPosition);
            setCursorPosition(id, cPosition)

        }
    }, [id, phoneNumber, previousPhoneNumber, previousCursorPosition])

    function getCursorPostionForInputMask(newInputString = "", oldInputString = "", previousCursorPostion) {
        if (newInputString === oldInputString) return previousCursorPostion;
        let stringUntilChanged = ""
        if (!oldInputString) return 2
        for (let i = 0; i < newInputString.length; i++) {
            stringUntilChanged += newInputString[i];
            const isIncluded = oldInputString.includes(stringUntilChanged);
            if (!isIncluded) {
                if (stringUntilChanged.length !== 5 && stringUntilChanged.length !== 10 && stringUntilChanged.length !== 15) {
                    return newInputString.length > oldInputString.length ? i + 1 : i
                } else if (stringUntilChanged.length === 5) {
                    return i + 3
                } else if (stringUntilChanged.length === 10) {
                    return i + 2
                } else if (stringUntilChanged.length === 15) {
                    return i + 2
                }
            }
        }
        return newInputString.length
    }

    function setCursorPosition(elemId = "", cursorPosition = 1) {
        var elem = document.getElementById(elemId);
        if (elem != null) {
            if (elem.createTextRange) {
                var range = elem.createTextRange();
                range.move('character', cursorPosition);
                range.select();
            }
            else if (elem.selectionStart) {
                elem.setSelectionRange(cursorPosition, cursorPosition);
            }
        }
    }

    function handleOnChangePhoneNumberWithInputMask(e) {
        if (maxLength && removeInputMask(e?.target?.value)?.length > maxLength) return
        setPreviousPhoneNumber(phoneNumber);
        setPreviousCursorPostion(e.target.selectionStart);
        e.target.value = removeInputMask(e.target.value);
        if (isWithNormalTextField) {
            e.target.value.length <= 10 && setPhoneNumber(e.target.value)
        } else {
            setPhoneNumber(e.target.value)
        }
        onChange(e)
        return
    }
    return isWithNormalTextField ?
        <CustomTextField
            {...props}
            inputProps={{ pattern: inputPattern, "data-test": "phone_number" }}
            id={fieldId}
            size={size}
            helperText={phoneNumberHelperText}
            error={phoneNumberError}
            value={getPhoneNumberWithInputMask(phoneNumber) ?? ""}
            onChange={handleOnChangePhoneNumberWithInputMask}
        />
        : <CustomTextField
            {...props}
            inputProps={{ pattern: inputPattern, "data-test": "phone_number" }}
            id={id}
            size="small"
            helperText={phoneNumberHelperText}
            error={phoneNumberError}
            value={getPhoneNumberWithInputMask(phoneNumber) ?? ""}
            onChange={handleOnChangePhoneNumberWithInputMask}
        />
}
