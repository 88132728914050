import { Autocomplete, Box } from "@mui/material";
import { getTimeZonedDate } from "../../../../../utils/time/time";
import { getById } from "../../../../../utils/miscellaneous";
import CustomTextField from "../../../../../components/CustomTextField";
import { renderBrandOption } from "../../../../../components/servicetitan/RenderInput";
import CustomDatePicker from "../../../../../components/CustomDatePicker";
import moment from "moment";
import { renderFranchiseOption } from "../../../utils/franchise";
import { CommonContext } from "../../../../../contexts/CommonContextProvider";
import { useContext } from "react";
import { getMatchingTimezoneByName } from "../../../../../utils/timezone/timezone";

export default function FiltrationArea(props) {
    const { filtration, setFiltration, brands, isBrandsLoading, franchises, isFranchisesLoading } = props

    const { generalTenantConfiguration, timezones } = useContext(CommonContext)

    return <Box sx={{ width: "100%", display: "flex", flexDirection: "row", gap: "0.5em" }}>
        <Box flexGrow={2} />
        <Autocomplete
            sx={{ minWidth: "350px" }}
            size="small"
            loading={isBrandsLoading}
            noOptionsText={"No brands"}
            disabled={brands === []}
            disablePortal
            options={brands}
            getOptionLabel={(brand) => brand.brand_name}
            renderOption={renderBrandOption}
            value={getById(filtration.brandId, brands) ?? null}
            onChange={(e, brand) => setFiltration({ ...filtration, brandId: brand?.id, brandBrandId: brand?.brand_id, franchiseId: undefined })}
            renderInput={(params) => <CustomTextField {...params} label="Brand" />}
        />
        <Autocomplete
            sx={{ minWidth: "300px" }}
            size="small"
            loading={isFranchisesLoading}
            noOptionsText={filtration.brandId ? "No franchises" : "Please select a brand"}
            disabled={franchises === []}
            disablePortal
            options={franchises}
            getOptionLabel={(franchise) => franchise.franchise_name}
            renderOption={renderFranchiseOption}
            value={getById(filtration.franchiseId, franchises) ?? null}
            onChange={(e, franchise) => setFiltration({ ...filtration, franchiseId: franchise?.id, channelId: undefined })}
            renderInput={(params) => <CustomTextField {...params} label="Franchise" />}
        />
        <div style={{ width: "220px", zIndex: 3 }} >
            <CustomDatePicker
                timeZone={generalTenantConfiguration?.time_zone}
                maxTime={new Date(moment(new Date(filtration.createdOnOrBefore)).valueOf())}
                disabled={false}
                label={`Start Date ( ${getMatchingTimezoneByName(generalTenantConfiguration?.time_zone, timezones)?.abbreviation ?? ""} )`}
                value={new Date(filtration.createdOnOrAfter) }
                onChange={(date) => {
                    setFiltration({ ...filtration, createdOnOrAfter: date.toISOString() })
                }}
                clearable={false}
                required={true}
            />
        </div>
        <div style={{ width: "220px", zIndex: 3 }} >
            <CustomDatePicker
                timeZone={generalTenantConfiguration?.time_zone}
                maxTime={new Date(getTimeZonedDate(new Date(), generalTenantConfiguration?.time_zone).add(1,"days"))}
                minTime={new Date(moment(new Date(filtration.createdOnOrAfter)).valueOf())}
                disabled={false}
                label={`End Date ( ${getMatchingTimezoneByName(generalTenantConfiguration?.time_zone, timezones)?.abbreviation ?? ""} )`}
                value={new Date(filtration.createdOnOrBefore)}
                onChange={(date) => {
                    setFiltration({ ...filtration, createdOnOrBefore: date.toISOString() })
                }}
                clearable={false}
                required={true}
            />
        </div>
    </Box>
}