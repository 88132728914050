import { Autocomplete, Box } from "@mui/material";
import { useEffect, useState } from "react";
import CustomTextField from "../../../../../components/CustomTextField";
import RenderInput from "../../../../../components/servicetitan/RenderInput";
import { CREATE_JOB_SECTION_IDS } from "../../../../../constants/jobs";
import { handleGetJobLables } from "../../../../../state-services-dublicate/vonigo";

export default function JobDetailsSection(props) {
    const { brand, franchise, existingJob, setJob, selectedDraftToContinue, setWorkOrderSummary } = props
    const [summary, setSummary] = useState(selectedDraftToContinue ? selectedDraftToContinue?.frontEndJobRequest?.summary : existingJob?.summary?.fieldValue ?? "")
    const [jobLables, setJobLables] = useState([])
    const [isJobLablesLoading, setIsJobLablesLoading] = useState(false)
    const [selectedLable, setSelectedLable] = useState(existingJob?.label ?? null);

    useEffect(() => {
        const jobLabelOptionId = selectedDraftToContinue?.frontEndJobRequest?.labelOptionId
        if (jobLabelOptionId && jobLables?.length > 0) {
            const selectedJobLabel = jobLables?.find((j) => j.optionID === jobLabelOptionId)
            setSelectedLable(selectedJobLabel)
        }
    }, [selectedDraftToContinue, jobLables])

    useEffect(() => {
        handleGetJobLables(brand, franchise, setIsJobLablesLoading, setJobLables);
    }, [brand, franchise])

    useEffect(() => {
        const newJobDetails = {
            summary: summary,
            labelOptionId: selectedLable?.optionID
        }
        setJob(newJobDetails)   
    }, [summary, selectedLable, setJob])

    function onSummaryChanged(e) {
        setSummary(e?.target?.value)
        setWorkOrderSummary(e?.target?.value)
    }

    function selectJobLabel(jobLables){
        if(jobLables){
            return jobLables?.find(({name})=>name==="Estimate Scheduled")
        }
    }

    return <Box id={CREATE_JOB_SECTION_IDS.JOB} display="flex" flexDirection="column" >
        <h4  style={{ marginBottom: "1em" }}>Job Details</h4>
        <Box sx={{ display: 'flex', flexDirection: "row", flexWrap: "wrap", width: "100%", gap: "1em" }} >
            <Box style={{ width: "100%", }}>
                <CustomTextField disabled={false} multiline rows={3} required={true} label="Summary" variant="outlined" size="small" style={{ width: "100%", }} value={summary} onChange={onSummaryChanged} />
            </Box>
            <Autocomplete
                sx={{ width: "100%", }}
                size="small"
                noOptionsText={franchise ? "No lables found" : "Please select a franchise"}
                loading={isJobLablesLoading}
                value={selectJobLabel(jobLables) ?? null}
                disablePortal
                disableClearable
                disabled={true}
                options={jobLables}
                getOptionLabel={(jobLabel) => jobLabel?.name ?? "-"}
                isOptionEqualToValue={(option, value) => option?.optionID === value?.optionID} 
                renderOption={(props, l) => <RenderInput {...props} key={l?.optionID} content={l?.name} />}
                renderInput={(params) => <CustomTextField   {...params} required={true} label="Select Job Label" />}
            />
        </Box>
    </Box>
}
