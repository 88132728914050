import { useEffect, useState } from "react";
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ErrorRow from "../../components/ErrorRow";
import LoadingRow from "../../components/LoadingRow"
import NoJobsRow from "../../components/NoJobsRow";
import moment from "moment";
import { handleVonigoGetJobs,handleGetSingleJobDetails } from "../../../../../state-services-dublicate/vonigo";
import ViewJobModal from "./ViewJobModal";
import { NOTIFICATION_TYPES } from "../../../../../constants/common";
import CreateJobModal from "./CreateJobModal";
import useDebounce from "../../../../../hooks/useDebounce";

const PAGE_SIZE = 50;

export default function VoingoJobs(props) {

    const urlParams = new URLSearchParams(window.location.search);

    const { callerId, zipCode, brand, franchise, openViewJob, onRefreshFilters, isCreateJobModalOpen, filterState, setJobStatisticsComponent, jobToView, setJobToView, setNotify, onCreateJobPageOpen, setIsCreateMultipleWorkOrders, selectedJobToView, setSelectedJobToView, setWorkOrderToReschedule, setIsRescheduleWorkOrder, page, setPage } = props

    const [isJobsLoading, setIsJobsLoading] = useState(false)
    const [isJobsLoadingError, setIsJobsLoadingError] = useState(false)
    const [jobs, setJobs] = useState([])
    const [isViewJobModalOpen, setIsViewJobModalOpen] = useState(false)
    const [isEditJobModalOpen, setIsEditJobModalOpen] = useState(false)
    const [selectedJobToEdit, setSelectedJobToEdit] = useState()
    const debounceHandleVonigoGetJobs = useDebounce({ redirect_url: brand?.redirect_url, franchiseId: franchise?.franchise_id, createdStart: filterState.createdStart, createdEnd: filterState.createdEnd, page: page }, ({ redirectUrl, franchiseId, createdStart, createdEnd, page }) => {
        handleVonigoGetJobs(redirectUrl, franchiseId, createdStart, createdEnd, page, PAGE_SIZE, setJobs, setIsJobsLoading, setIsJobsLoadingError);
    }, 1000)

    useEffect(() => {
        if (urlParams.get("isFromCallLog") && urlParams.get("isFromCallLog") =='true' && urlParams.get("vonigoJobid") && urlParams.get("franchiseId") && urlParams.get("baseUrl")) {
            const jobId = parseInt(urlParams.get("vonigoJobid"))
            const franchiseId = parseInt(urlParams.get("franchiseId"))
            const baseUrl = urlParams.get("baseUrl")
            handleGetSingleJobDetails(baseUrl,franchiseId ,jobId, onViewJobPressed)
        }

    },[])

    useEffect(() => {
        setIsJobsLoading?.(true);
        setIsJobsLoadingError?.(false);
        setJobs([]);
        debounceHandleVonigoGetJobs({ redirectUrl: brand?.redirect_url, franchiseId: franchise?.franchise_id, createdStart: filterState.createdStart, createdEnd: filterState.createdEnd, page: page })
    }, [brand?.redirect_url, franchise?.franchise_id, filterState.createdStart, filterState.createdEnd, page, debounceHandleVonigoGetJobs])

    useEffect(() => {
        setJobStatisticsComponent(<small><strong>{jobs?.length} {jobs?.length === 1 ? "Job" : "Jobs"}</strong></small>)
    }, [jobs, setJobStatisticsComponent])

    useEffect(() => {
        if (jobToView) onViewJobPressed(jobToView)
    }, [jobToView, openViewJob])

    function onPageChange(e, pageNo) {
        setPage(pageNo + 1)
    }

    function onViewJobModalClosed() {
        setSelectedJobToView();
        setJobToView();
        setIsViewJobModalOpen(false);
    }

    function showNotification(notify = { message: "", type: NOTIFICATION_TYPES.SUCCESS, isOpen: true }) {
        setNotify(notify)
    }

    function onViewJobPressed(job) {
        setSelectedJobToView(job)
        setIsViewJobModalOpen(true)
    }

    function onEditJobIconClicked(job) {
        setSelectedJobToEdit(job)
        setIsEditJobModalOpen(true)
    }

    function handleCreateJobModalClosed(job) {
        setIsEditJobModalOpen(false)
        setSelectedJobToEdit()
        if (job) {
            onRefreshFilters()
            setSelectedJobToView(job);
            setIsViewJobModalOpen(true)
        }
    }

    const canGotoNextPage = jobs?.length > 0
    return <div style={{ height: "60vh" }}>
        <TableContainer sx={{ height: "80%" }}>
            <Table stickyHeader='true'>
                <VonigoJobsTableHead />
                <TableBody>
                    {isJobsLoading ? isJobsLoadingError ? <ErrorRow colSpan={10} /> : <LoadingRow colSpan={10} /> : jobs?.length === 0 ? <NoJobsRow colSpan={10} /> : <JobRows jobs={jobs} brand={brand} franchise={franchise} onViewJobPressed={onViewJobPressed} onEditJobIconClicked={onEditJobIconClicked} />}
                </TableBody>
            </Table>
        </TableContainer>
        <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "end" }}>
            <TablePagination
                component={"div"}
                rowsPerPageOptions={canGotoNextPage ? [] : [PAGE_SIZE]}
                style={{ height: "5em", float: "right" }}
                count={canGotoNextPage ? -1 : (((PAGE_SIZE) * (page - 1)) + (jobs.length))}
                rowsPerPage={PAGE_SIZE}
                page={page - 1}
                onPageChange={onPageChange}
            />
        </div>
        {isCreateJobModalOpen || isEditJobModalOpen ? <CreateJobModal showNotification={showNotification} onRefreshFilters={onRefreshFilters} setJobToView={setJobToView} onClose={handleCreateJobModalClosed} open={isCreateJobModalOpen || isEditJobModalOpen} selectedJobToEdit={selectedJobToEdit} selectedBrandId={brand?.brand_id} selectedFranchise={franchise} selectedPhoneNumber={callerId} selectedZipCode={zipCode} selectedTenantId={franchise?.service_titan_tenant_id} /> : <></>}
        {isViewJobModalOpen ? <ViewJobModal brand={brand} franchise={franchise} onCreateJobPageOpen={onCreateJobPageOpen} setWorkOrderToReschedule={setWorkOrderToReschedule} setIsRescheduleWorkOrder={setIsRescheduleWorkOrder} setIsCreateMultipleWorkOrders={setIsCreateMultipleWorkOrders} setIsViewJobModalOpen={setIsViewJobModalOpen} showNotification={showNotification} onClose={onViewJobModalClosed} open={isViewJobModalOpen} job={selectedJobToView} setSelectedJobToView={setSelectedJobToView} /> : <></>}

    </div>
}

function VonigoJobsTableHead(props) {
    return <TableHead>
        <TableRow>
            <TableCell>#</TableCell>
            <TableCell>Id</TableCell>
            <TableCell>Job Status</TableCell>
            <TableCell>Job Label</TableCell>
            <TableCell>Client</TableCell>
            <TableCell>{"Created Date & Time"}</TableCell>
            <TableCell >Actions</TableCell>
        </TableRow>
    </TableHead>
}

const IS_INACTIVE = "false"
function JobRows(props) {
    const { onViewJobPressed = (job) => { }, onEditJobIconClicked } = props
    const jobs = props?.jobs ?? []

    return jobs?.map((job, i) => {
        return <TableRow key={i}>
            <TableCell>{i + 1}</TableCell>
            <TableCell>{job?.objectID ?? "-"}</TableCell>
            <TableCell>{job?.jobStatus?.name ?? "-"}</TableCell>
            <TableCell>{job?.label?.name ?? "-"}</TableCell>
            <TableCell>{job?.client?.name ?? "-"}</TableCell>
            <TableCell>{job?.dateCreated ? moment.unix(job?.dateCreated).format('MM/DD/YYYY - h:mm A') : "-"}</TableCell>
            <TableCell>
                <IconButton onClick={() => {
                    onViewJobPressed(job);
                }} color="primary" size={"medium"} style={{ marginRight: "10px" }}>
                    <Tooltip title={"View Job"}><VisibilityIcon style={{ height: "20px", width: "20px" }} /></Tooltip>
                </IconButton>
                <IconButton disabled={job?.isActive === IS_INACTIVE} onClick={() => {
                    onEditJobIconClicked(job)
                }} color='info' size={"medium"} style={{ marginRight: "10px" }}>
                    <Tooltip title={"Edit Job"}><EditIcon style={{ height: "20px", width: "20px" }} /></Tooltip>
                </IconButton>
            </TableCell>
        </TableRow>
    })
}