import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { UserContext } from "./User";

export const ColorModeContext = createContext({ toggleColorMode: () => { } });

export const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
        primary: {
          main: '#00a79d',
          contrastText: "#ffffff"
        },
        secondary: {
          main: "#00a79c36",
          contrastText: "#ffffff"
        },
        error: {
          main: "#f44336",
          light: "#e57373",
          dark: "#d32f2f",
          contrastText: "#ffffff"
        },
        info: {
          main: "#29b6f6",
          light: "#4fc3f7",
          dark: "#0288d1",
        },
        warning: {
          light: "#ffb74d",
          main: "#ffa726",
          dark: "#f57c00",
        },
        success: {
          light: "#81c784",
          main: "#66bb6a",
          dark: "#0288d1",
        },
        text: {
          primary: '#000000',
          secondary: '#4b4b4b',
          highlight: '#BBD67C',
          head: '#0000FF',
          green: '#008000'
        },
        form: {
          main: "#FFFFFF",
          secondary: "#F3F3F3",
          input: '#E6E6E6',
          contrastText: "#000000"
        },
        background: {
          main: "#ffffff",
          default: '#ffffff',
          paper: '#f5f5f5',
          dialer: '#00a79d',
          icon: '#D3D3D3',
          ternary: '#F3F3F3',
        },
        reports: {
          main: '#F5F5F5',
          card: '#FFFFFF',
        },
        wallboard: {
          logList: '#D5F5E3',
          agentInsightCard: '#F4D03F',
          agentMonitoringTile : "#ADDFFF"
        },
        fieldLabelColor: '#4b4b4b',
        disabledColor: 'black',
        notification: {
          background: '#CCE8E6'
        },
        autocompleteFieldLabel: 'blue',
        autocompleteBorder: "#00a79d",
        btn: {
          disabled: "#0000001f",
        }
      } : {
        // palette values for dark mode
        primary: {
          main: '#00A79D'
        },
        secondary: {
          main: '#ff7043',
        },
        error: {
          main: '#e57373',
        },
        warning: {
          main: '#ffb74d',
        },
        info: {
          main: '#64b5f6',
        },
        success: {
          main: '#81c784',
        },
        text: {
          primary: '#ffffff',
          secondary: '#ccc5bb', //#CAC3BA, ccc5bb
          highlight: '#4A581C',
          head: '#337DFF',
          green: '#66FB6D'
        },
        form: {
          main: "#181A1B",
          secondary: "#191B1C",
          input: '#26292b',
          contrastText: "#E8E6E3"
        },
        background: {
          main: '#181A1B',
          default: '#303030',
          paper: '#181A1B', //424242
          dialer: '#00867E',
          icon: '#313537',
          ternary: '#1F2123',
        },
        reports: {
          main: '#1E2021',
          card: '#181A1B',
        },
        wallboard: {
          logList: '#10422D',
          agentInsightCard: '#7B6407',
          agentMonitoringTile : "#456DF0"
        },
        fieldLabelColor: '#ccc5bb',
        disabledColor: '#919293',
        notification: {
          background: '#213838'
        },
        autocompleteFieldLabel: "#cfb313",
        autocompleteBorder: "#00a79d",
        btn: {
          disabled: "#ffffff1f",
        }
      }),
  },
});

export default function ThemeContextProvider(props) {
  const { userPreferences } = useContext(UserContext)
  const [mode, setMode] = useState(userPreferences.dark_mode_enabled === 1 ? 'dark' : 'light');

  useEffect(() => {
    setMode(userPreferences.dark_mode_enabled === 1 ? 'dark' : 'light')
  }, [userPreferences.dark_mode_enabled])

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', mode);
  }, [mode]);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );
  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {props.children}
      </ThemeProvider>
    </ColorModeContext.Provider>
  )
}