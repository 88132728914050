import { Autocomplete, Box } from "@mui/material";
import DialPad from '../../../components/dialPad/DialPad';
import DialPadHistory from "../../../components/dialPad/components/dialPadHistory";
import CustomTextField from "../../../components/CustomTextField";
import { renderBrandOption } from "../../../components/servicetitan/RenderInput";
import { useContext, useEffect, useState } from "react";
import { CommonContext } from "../../../contexts/CommonContextProvider";
import { getFranchiseOptionLabel, renderFranchiseOptionWithStatusAndEncryptionBadge } from "../../dashboard/utils/franchise";
import { getById, getMatchingBrandById } from "../../../utils/miscellaneous";
import moment from "moment";
import { handleGetBrandFranchises } from "../../../state-services/brands";


const INITIAL_FILERATION_DATA = {
    createdOnOrAfter: getOneMonthBeforeNow(),
    createdOnOrBefore: getFiveMinsAfterNow(),
    franchiseId: 0,
    brandId: 0,
    phoneNumber: "",
}

export function getRefreshedFilterationData(filterationData) {
    return {
        ...filterationData,
        createdOnOrAfter: getOneMonthBeforeNow(),
        createdOnOrBefore: getFiveMinsAfterNow(),
    }
}

export function getFiveMinsAfterNow() {
    return moment(new Date()).add(5, "minutes").toISOString()
}

export function getOneMonthBeforeNow() {
    return moment(new Date()).subtract(1, "months").toISOString()
}

export default function PhoneClient(props) {
    const { phoneInfo, setPhoneInfo, brands, isBrandsLoading } = useContext(CommonContext)
    const [franchises, setFranchises] = useState([])
    const [customers, setCustomers] = useState([])
    const [calls, setCalls] = useState([])
    const [isFranchisesLoading, setIsFranchisesLoading] = useState(false)
    const [brand, setBrand] = useState();
    const [franchise, setFranchise] = useState();
    const [currentIndex, setCurrentIndex] = useState(0)
    const [filterationData, setFilterationData] = useState(getRefreshedFilterationData(INITIAL_FILERATION_DATA))

    useEffect(() => {
        if (phoneInfo.brandId) {
            setBrand(getMatchingBrandById(phoneInfo.brandId, brands))
        } else {
            setBrand()
        }
    }, [phoneInfo.brandId, brands])

    useEffect(() => {
        handleGetBrandFranchises(brand?.brand_id, setFranchises, setIsFranchisesLoading)
    }, [brand?.brand_id])

    useEffect(() => {
        if (phoneInfo.franchiseId && franchises.length > 0) {
            const franchise = franchises.find(franchise => franchise.id === phoneInfo.franchiseId)
            setFranchise(franchise)
        } else {
            setFranchise()
        }
    }, [phoneInfo.franchiseId, franchises])

    function resetCallAndCustomerHistoryAfterCall() {
        setCustomers([])
        setCalls([])
    }

    return <div width="100%" display="flex" flexDirection="column" >
        <BrandFranchiseFilterArea brands={brands} isBrandsLoading={isBrandsLoading} franchises={franchises} isFranchisesLoading={isFranchisesLoading} phoneInfo={phoneInfo} setPhoneInfo={setPhoneInfo} />
        <Box sx={{ flexShrink: "0", backgroundColor: "primary.main", height: "4px", width: "100%" }} />
        <Box marginBlock="1em" width="100%" display="flex" flexDirection="row" justifyContent="center" >
            <DialPad brand={brand} franchise={franchise} estimateCallBack={phoneInfo.estimateCallBack} filterationData={filterationData} setFilterationData={setFilterationData} resetCallAndCustomerHistoryAfterCall={resetCallAndCustomerHistoryAfterCall} />
        </Box>
        <DialPadHistory brand={brand} franchise={franchise} filterationData={filterationData} setFilterationData={setFilterationData} customers={customers} setCustomers={setCustomers} calls={calls} setCalls={setCalls} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />
    </div >
}

function BrandFranchiseFilterArea(props) {
    const { brands, isBrandsLoading, franchises, isFranchisesLoading, phoneInfo, setPhoneInfo } = props
    return <Box margin="1em" width="100%" display="flex" flexDirection="row" justifyContent="start" gap="0.5em" >
        <Autocomplete
            sx={{ minWidth: "300px" }}
            size="small"
            loading={isBrandsLoading}
            noOptionsText={"No brands"}
            disabled={brands === []}
            disablePortal
            options={brands}
            getOptionLabel={(brand) => brand.brand_name}
            renderOption={renderBrandOption}
            value={getById(phoneInfo.brandId, brands) ?? null}
            onChange={(_, brand) => {
                const { jobOrLeadId, jobOrLead, ...newPhoneInfo } = phoneInfo;
                setPhoneInfo({ ...newPhoneInfo, brandId: brand?.id, franchiseId: undefined });
            }}
            renderInput={(params) => <CustomTextField {...params} label="Brand" />}
        />
        <Autocomplete
            sx={{ minWidth: "350px" }}
            size="small"
            disabled={franchises === []}
            disablePortal
            loading={isFranchisesLoading}
            noOptionsText={phoneInfo?.brandId ? "No franchises" : "Please select a brand"}
            options={franchises}
            getOptionLabel={(franchise) => getFranchiseOptionLabel(franchise)}
            renderOption={renderFranchiseOptionWithStatusAndEncryptionBadge}
            value={getById(phoneInfo.franchiseId, franchises) ?? null}
            onChange={(_, franchise) => {
                const { jobOrLeadId, jobOrLead, ...newPhoneInfo } = phoneInfo;
                setPhoneInfo({ ...newPhoneInfo, franchiseId: franchise?.id });
            }}
            renderInput={(params) => <CustomTextField  {...params} label="Franchise" />}
        />
    </Box>
}