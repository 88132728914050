import React, { useContext, useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ErrorView from '../../../ErrorView';
import { ERROR_COMPONENTS } from '../../../../constants/common';
import { CustomTextField } from '../../style/rightDock';
import { getFaqsByKeyWords } from '../../../../services/faqService';
import { getFromLocalStorage, getFromSessionStorage, setSessionStorage } from '../../../../utils/browserStorage';
import { LOCAL_STORAGE_KEYS, SESSION_STORAGE_KEYS } from '../../../../constants/browserStorage';
import { UserContext } from '../../../../contexts/User';
import SendIcon from '@mui/icons-material/Send';
import RealTimeTranscriptionBox from './RealTimeTranscriptionBox';
import { SttSocketContext } from '../../../../contexts/SttSocket';
import CcaiPng from "./images/ccai.png";
import CcaiGif from "./images/ccai.gif";
import { ImageOnErrorHandler } from '../../../../utils/imageError';

export default function AgentAssistBody() {
    const { me } = useContext(UserContext);
    const { AgentCurrentRecognition = "", CustomerCurrentRecognition = "", recognitionHistory = [], faqs = [], setFaqs } = useContext(SttSocketContext);
    const [showTranscript, setShowTranscript] = useState(faqs.length === 0 ? true : false);
    const [sugValue, setSugValue] = useState("");
    const [manualChats, setManualChats] = useState([]);

    useEffect(() => {
        let tempFaqs = JSON.parse(getFromSessionStorage(SESSION_STORAGE_KEYS.FAQ_temp));
        if (tempFaqs?.length > 0) {
            setFaqs(tempFaqs)
        }
        let checkFaq = JSON.parse(getFromSessionStorage(SESSION_STORAGE_KEYS.FAQ_temp));
        if (checkFaq?.length === 0) {
            setFaqs([]);
        }
        let storedManualChats = JSON.parse(getFromSessionStorage(SESSION_STORAGE_KEYS.ManualChats));
        if (storedManualChats?.length > 0) {
            setManualChats(storedManualChats);
        }
    }, []);

    useEffect(() => {
        try {
            setSessionStorage(SESSION_STORAGE_KEYS.ManualChats, JSON.stringify(manualChats));
        } catch (error) { }
    }, [manualChats]);

    const onSuggesionFieldKeyDownHandler = (e) => {
        let newValue = e.target.value;
        setSugValue(newValue);
        if (e.key === 'Enter' && newValue.length > 0) {
            onAddSuggestions();
        }
    };

    const onAddSuggestions = () => {
        if (sugValue.length > 0) {
            let message = {
                text: sugValue,
                on: new Date().getTime(),
            };
            handleGetFaqsByKeyWord(sugValue);
            setManualChats((old) => [message, ...old]);
            setShowTranscript(true);
            setSugValue("");
        }
    };

    const handleGetFaqsByKeyWord = async (text) => {
        // save text in question_keys with split on space
        let question_keys = JSON.parse(getFromSessionStorage(SESSION_STORAGE_KEYS.Question_keys));
        if (question_keys?.length > 0) {
            question_keys = [...question_keys, ...text.split(" ")];
        } else {
            question_keys = text.split(" ");
        }
        try {
            setSessionStorage(SESSION_STORAGE_KEYS.Question_keys, JSON.stringify(question_keys));
        } catch (error) { }

        try {
            let body = {
                keys: text,
                franchise_id: parseInt(getFromSessionStorage(SESSION_STORAGE_KEYS.FAQ_franchise_id)),
                brand_id: parseInt(getFromLocalStorage(LOCAL_STORAGE_KEYS.brandPrimaryId)),
            };
            let res = await getFaqsByKeyWords(body);

            if (Array.isArray(res)) {
                let manualFaqs = [];
                res.forEach(r => {
                    let manualFaq = {
                        question: r.question,
                        answer: r.answer
                    };
                    if (faqs?.length < 10 ) {
                        if (!faqs.find(f => f.question === manualFaq.question)) {
                            manualFaqs.push(manualFaq);
                        }
                    }else{
                        manualFaqs.push(manualFaq);
                    }
                });
                setFaqs((old) => [...manualFaqs, ...old]);
                //apped manual chat to FAQ_temp
                setSessionStorage(SESSION_STORAGE_KEYS.FAQ_temp, JSON.stringify([...manualFaqs, ...faqs]));
            }
        } catch (error) { }
    };

    const onChatInputFieldChangeHandler = (e) => setSugValue(e.target.value);
    const handleTranscriptDrawerShow = () => setShowTranscript(!showTranscript);

    return (
        <>
            <IconButton title={`${showTranscript ? "Hide" : "Show"} Transcript`} aria-label="expand" size="small"
                className="btn-primary" sx={{ float: 'right', marginTop: "5px" }}
                onClick={handleTranscriptDrawerShow}
            >
                {showTranscript ? <ArrowForwardIcon /> : <ArrowBackIcon />}
            </IconButton>
            <div className="aa-body">
                <div className={`faq-suggestions ${showTranscript ? "faq-suggestions-half" : "faq-suggestions-full"}`}>
                    {faqs.length > 0 ?
                        faqs.map((faq, i) => (
                            <div key={i} className="suggestions-body">
                                {faq.question.split('\n').map((line, index) => (
                                    <React.Fragment key={index}>
                                        <strong className='faq-question'>{line}</strong>
                                        <br />
                                    </React.Fragment>
                                ))}
                                {faq.answer.split('\n').map((line, index) => (
                                    <React.Fragment key={index}>
                                        {line}
                                        <br />
                                    </React.Fragment>
                                ))}
                            </div>
                        ))
                        :
                        <div className='error-view'>
                            <ErrorView type={ERROR_COMPONENTS.NOT_FOUND} title='Related FAQs are not found' />
                        </div>
                    }
                </div>
                {showTranscript &&
                    <div className="transcript-body">
                        <RealTimeTranscriptionBox type="in" image={me?.img} text={AgentCurrentRecognition} />
                        <RealTimeTranscriptionBox image="" text={CustomerCurrentRecognition} />
                        {(recognitionHistory.length > 0 || AgentCurrentRecognition.length > 0 || CustomerCurrentRecognition.length > 0 || manualChats.length > 0) ?
                            <>
                                {manualChats.map((mc, i) => (
                                    <div key={i} className={`transcript-item chat-manual`}>
                                        {mc.text}
                                    </div>
                                ))}
                                {recognitionHistory.map((tr, i) => (
                                    <div key={i}>
                                        <RealTimeTranscriptionBox type="in" image={me?.img} text={tr.agent} />
                                        <RealTimeTranscriptionBox text={tr.customer} />
                                    </div>
                                ))}
                            </>
                            :
                            <div className='error-view' style={{ paddingTop: 260, paddingLeft: 20 }}>
                                <img src={CcaiGif} alt="ccai-gif" onError={ImageOnErrorHandler} />
                                <img src={CcaiPng} alt="ccai-png" onError={ImageOnErrorHandler} height={53} />
                            </div>
                        }
                    </div>
                }
            </div>
            <div className='aa-chat-input'>
                <CustomTextField value={sugValue} onKeyDown={onSuggesionFieldKeyDownHandler} onChange={onChatInputFieldChangeHandler}
                    label={`👋 Hey! ${me?.first_name} How can I assist you?`} fullWidth variant='standard'
                />
                <IconButton component="span" onClick={onAddSuggestions}>
                    <SendIcon />
                </IconButton>
            </div>
        </>
    );
}
