import { limitToLast, equalTo, getDatabase, orderByChild, onChildAdded, startAfter, query, get, ref, off } from 'firebase/database'
import { getAuth, signInWithCustomToken } from "firebase/auth";
import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../../../contexts/User';
import { getFirebaseJWTToken } from '../../../../services/agentScoreService';
import "../styles/scoreDashboardStyles.css"
import { mapInfoFromAgents } from '../utils';
import { Box } from '@mui/system';
import { CircularProgress } from '@mui/material';
import AgentLogsTile from './agentLogsTile';
import ErrorView from '../../../../components/ErrorView';
import { ERROR_COMPONENTS } from '../../../../constants/common';

let refNodeOfTileView;
const realTimeDbNode = "Logs"

export default function AgentLogsTileView({ agents = [], agentState = [] }) {

    const [logs, setLogs] = useState([])
    const [logsLoading, setlogsLoading] = useState(true)
    const { me } = useContext(UserContext)

    useEffect(() => {
        if (me.user_id && agents.length > 0) {
            handleInitiate()
        }
        return () => {
            if (refNodeOfTileView) {
                off(refNodeOfTileView)
            }
        }
    }, [me.user_id, agents])

    useEffect(() => {
        if (logs.length > 0) {
            handleGetAgentStatusfromRealTimeDb()
        }
    }, [logsLoading])

    const handleInitiate = async () => {
        try {
            let token = await getFirebaseJWTToken(me.user_id)
            const auth = getAuth();
            await signInWithCustomToken(auth, token)
            const database = getDatabase();
            refNodeOfTileView = ref(database, realTimeDbNode)
            let agentlogs = await handleGetDataFromRealTimeDb(agents.filter((agent) => agent.eight_by_eight_agent_id !== ""))
            setLogs(agentlogs)
        } catch (error) {

        } finally {
            setlogsLoading(false)
        }
    }

    const handleGetDataFromRealTimeDb = async (agents) => {
        if (!refNodeOfTileView) return;
        let logs = []
        for (const agent of mapInfoFromAgents(agents)) {
            const agentIdQuery = query(refNodeOfTileView, orderByChild("AgentId"), equalTo(agent.AgentId), limitToLast(1));
            let snapshot = await get(agentIdQuery)
            if (snapshot.exists()) {
                let obj = {}
                let data = Object.values(snapshot.val())
                obj.AgentId = data[0].AgentId
                obj.Status = data[0].Status
                obj.Status === "Logout" ? obj.IsOnline = false : obj.IsOnline = true
                obj.AgentName = agent.AgentName
                logs.push(obj)
            } else {
                let obj = {}
                obj.AgentId = agent.AgentId
                obj.Status = "Not Started"
                obj.IsOnline = false
                obj.AgentName = agent.AgentName
                logs.push(obj)
            }
        }
        return logs
    }

    const handleGetAgentStatusfromRealTimeDb = () => {
        const currentDate = new Date();
        const currentUtcTime = currentDate.toISOString();
        const agentStatusQuery = query(refNodeOfTileView, orderByChild("FirbaseRecordCreatedAt"), startAfter(currentUtcTime));
        onChildAdded(agentStatusQuery, (snapshot) => {
            const data = snapshot.val();
            let log = logs.find((log) => log.AgentId == data.AgentId)
            if (log) {
                log.Status = data.Status
                log.Status === "Logout" ? log.IsOnline = false : log.IsOnline = true
                setLogs([...logs])
            }
        });
    }

    const filterLogsAccordingToAgentState = (logs) => {
        if (agentState.length !== 0) {
            return logs.filter((log) => agentState.includes(log.Status))
        } else {
            return logs
        }
    }

    const sortAgentsAlphabeticallyAndByOnlineStatus = (logs) => {
        let offlineAgents = logs.filter((log) => log.IsOnline === false)
        let onlineAgents = logs.filter((log) => log.IsOnline === true)
        onlineAgents.sort((a, b) => {
            if (a.AgentName < b.AgentName) {
                return -1;
            }
            if (a.AgentName > b.AgentName) {
                return 1;
            }
            return 0;
        })
        onlineAgents.sort((a, b) => {
            if (a.AgentName < b.AgentName) {
                return -1;
            }
            if (a.AgentName > b.AgentName) {
                return 1;
            }
            return 0;
        })
        return [...onlineAgents, ...offlineAgents]
    }

    return (
        <>
            <div className="bg-shadow bg-white agent-logs-lower-section">
                <div className='agent-log-title-container'>
                    <h2 style={{ marginTop: "0px" }}>Agent Logs Tile View</h2>
                </div>
                <div style={{ marginTop: "65px", overflowY: logsLoading ? "hidden" : "auto" }}>
                    {logsLoading ?
                        <div style={{ marginTop: "10%", marginLeft: "50%" }}>
                            <CircularProgress color="inherit" />
                        </div>
                        :
                        <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "1em", paddingLeft: "70px" }}>
                            {
                                filterLogsAccordingToAgentState(logs).length === 0 ?
                                    <Box sx={{ width: "600px", height: "600px", margin: "auto" }} >
                                        <ErrorView type={ERROR_COMPONENTS.NOT_FOUND} title='No Data Available' />
                                    </Box>
                                    :
                                    filterLogsAccordingToAgentState(sortAgentsAlphabeticallyAndByOnlineStatus(logs)).map((log, index) => {
                                        return (
                                            <AgentLogsTile log={log} index={index}></AgentLogsTile>
                                        )
                                    })
                            }
                        </Box>
                    }
                </div>
            </div>
        </>
    )
}
