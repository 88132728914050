import { NOTIFICATION_TYPES } from "../../constants/common";
import { changeLeadCallbackStatus, getEightByEightConfigurations } from "../../services/eigthByEight/configurations";

export async function handleGetConfigurations(setEightByEightConfigurations, setEightByEightConfigurationsLoading, setEightByEightConfigurationsLoadingError) {
    try {
        setEightByEightConfigurationsLoadingError(false)
        setEightByEightConfigurationsLoading(true)
        const config = await getEightByEightConfigurations();
        setEightByEightConfigurations(config)
    } catch {
        setEightByEightConfigurationsLoadingError(true)
    } finally {
        setEightByEightConfigurationsLoading(false)
    }
}

export async function handleChangeLeadCallbackStatus(configId, isEnabled, eightByEightConfigurations, setEightByEightConfigurations, showNotification) {
    try {
        await changeLeadCallbackStatus(configId, isEnabled)
        setEightByEightConfigurations({ ...eightByEightConfigurations, is_lead_outbound_enabled: isEnabled })
        showNotification({ message: "Successfully Updated", type: NOTIFICATION_TYPES.SUCCESS })
    } catch {
        showNotification({ message: "Unable to update brand at this moment", type: NOTIFICATION_TYPES.ERROR })
    }
}