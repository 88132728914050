import { useEffect, useState } from "react"
import ReportCard from "./ReportCard"
import sms from "../../../../assets/img/sms.png";
import { handleGetSMSStatistics } from "../../../../state-services/reports/reports";
import Chart, { getChartDataFromMediaReport } from "./Chart";
import { INITIAL_SELECTED_DATA } from "../Reports";

const CHART_TITLES = {
    SMS: "SMS"
}

export default function SMSStatistics(props) {
    const { filtration, setSelectedData, selectedData, onSmsStatisticsChange} = props
    const [smsStatistics, setSmsStatistics] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(true)

    useEffect(() => {
        setSelectedData(INITIAL_SELECTED_DATA);
        handleGetSMSStatistics(
            filtration.brandBrandId,
            filtration.franchiseId,
            filtration.channelId,
            filtration.startDate,
            filtration.endDate,
            (data) => {
                setSmsStatistics(data);
                onSmsStatisticsChange(data);
            },
            setIsLoading,
            setIsError
        );
    }, [filtration.brandBrandId, filtration.franchiseId, filtration.channelId, filtration.startDate, filtration.endDate]);

    return <ReportCard title={"SMS"} image={sms}>
        <Chart width={200} data={getChartDataFromMediaReport(CHART_TITLES.SMS, smsStatistics)} onClick={setSelectedData} isSelected={selectedData.title === CHART_TITLES.SMS} isLoading={isLoading} isError={isError} />
    </ReportCard>
}