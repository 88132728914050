import { getFranchiseBrandOutboundChannelIds } from "../../services/outboundChannelIds/outboundChannelIds";

export async function handleGetOutboundChannelIds(franchiseId = 0, brandBrandId = 0, isEnabled = 1, setOldOutboundChannelIds, setIsOutboundChannelIdsLoading) {
    try {
        setIsOutboundChannelIdsLoading?.(true)
        const response = await getFranchiseBrandOutboundChannelIds();
        if (Array.isArray(response)) setOldOutboundChannelIds?.(response)
    } catch {
    } finally {
        setIsOutboundChannelIdsLoading?.(false)
    }
}