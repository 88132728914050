import { useRef, useState, useEffect } from 'react'
import { getPhoneNumberWithInputMask, getPhoneNumberWithInputMaskAndSeperateExtention, removeInputMask } from '../../../../../utils/inputMasks';
import { CustomTextField } from '../../components/customComponents';

export function PhoneNumberBoxForJobs(props) {
    const { error, helperText, value, onChange = (e) => { } } = props;
    let phoneNumberHelperText = helperText;
    let phoneNumberError = error
    if (value?.startsWith("0")) {
        phoneNumberHelperText = "Cannot starts with 0"
        phoneNumberError = true
    }
    const id = useRef(Math.floor((Math.random() * 1000000) + 1).toString() + "-search-phone-number").current
    const [previousPhoneNumber, setPreviousPhoneNumber] = useState(value ?? "");
    const [previousCursorPosition, setPreviousCursorPostion] = useState(1)
    const [phoneNumber, setPhoneNumber] = useState(value ?? "");
   
    useEffect(() => {
        setPhoneNumber(value)
    }, [value])
    
    useEffect(() => {
        if (previousPhoneNumber) {
            const cPosition = getCursorPostionForInputMask(getPhoneNumberWithInputMask(phoneNumber), getPhoneNumberWithInputMask(previousPhoneNumber), previousCursorPosition);
            setCursorPosition(id, cPosition)

        }
    }, [id, phoneNumber, previousPhoneNumber, previousCursorPosition])

    function getCursorPostionForInputMask(newInputString = "", oldInputString = "", previousCursorPostion) {
        if (newInputString === oldInputString) return previousCursorPostion;
        let stringUntilChanged = ""
        if (!oldInputString) return 2
        for (let i = 0; i < newInputString.length; i++) {
            stringUntilChanged += newInputString[i];
            const isIncluded = oldInputString.includes(stringUntilChanged);
            if (!isIncluded) {
                if (stringUntilChanged.length !== 5 && stringUntilChanged.length !== 10 && stringUntilChanged.length !== 15) {
                    return newInputString.length > oldInputString.length ? i + 1 : i
                } else if (stringUntilChanged.length === 5) {
                    return i + 3
                } else if (stringUntilChanged.length === 10) {
                    return i + 2
                } else if (stringUntilChanged.length === 15) {
                    return i + 2
                }
            }
        }
        return newInputString.length
    }

    function setCursorPosition(elemId = "", cursorPosition = 1) {
        var elem = document.getElementById(elemId);
        if (elem != null) {
            if (elem.createTextRange) {
                var range = elem.createTextRange();
                range.move('character', cursorPosition);
                range.select();
            }
            else if (elem.selectionStart) {
                elem.setSelectionRange(cursorPosition, cursorPosition);
            }
        }
    }

    function mergerNumberWithExtention(e) {
        let num
        num = handleOnChangePhoneNumberWithInputMask(e)
        onChange(num)
    }

    function handleOnChangePhoneNumberWithInputMask(e) {
        setPreviousPhoneNumber(phoneNumber);
        setPreviousCursorPostion(e.target.selectionStart);
        e.target.value = removeInputMask(e.target.value);
        setPhoneNumber(e.target.value);
        return e.target.value
    }
    
    return <div style={{ display: "flex", flexDirection: "row", gap: "0.5em" }}>
        <CustomTextField
            {...props}
            id={id}
            size="small"
            helperText={phoneNumberHelperText}
            error={phoneNumberError}
            value={getPhoneNumberWithInputMaskAndSeperateExtention(phoneNumber) ?? ""}
            onChange={mergerNumberWithExtention}
        />
    </div>
}

export function PhoneNumberExtention(props) {
    const {value,handleCloseEdit,onChange = (e) => { } } = props;
    const [extention, setExtention] = useState(value ?? "")

    useEffect(() => {
        setExtention(value)
    }, [value,handleCloseEdit])

    function handleExtention(e) {
        setExtention(e.target.value.substring(0,10))
        onChange(e.target.value)
    }

    return <div style={{ display: "flex", flexDirection: "row", gap: "0.5em" }}>
        <CustomTextField
            {...props}
            size="small"
            value={extention ?? ""}
            onChange={handleExtention}
        />
    </div>
}
