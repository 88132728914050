import { Accordion, AccordionDetails, IconButton, AccordionSummary, Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import getValidationOnOwnerData from './utils/common'
import { isValidEmail } from '../../../../utils/validators';
import AddIcon from "@mui/icons-material/Add";
import { INITIAL_EDIT_INDEX, INITIAL_OWNER, NEW_OWNER_KEY, SAVE_HELPER_MESSAGE, UPDATE_HELPER_MESSAGE } from '../../franchise/model/OwnerTable/constants/common';
import EditBrandOwnerRow from './editOwnerRow';
import BrandOwnerRow from './ownerRow';
import NoBrandOwnersRow from './noOwnerRow';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const NEW_OWNER_ID = -1
const MAX_OWNER_NAME_LENGTH = 50
const MAX_OWNERS_COUNT = 10

export default function BrandOwnerViewer(props) {

    const { brandOwners: brandOwners = [], setBrandOwners: setBrandOwners, loading } = props
    const [editIndex, setEditIndex] = useState(INITIAL_EDIT_INDEX)
    const [isAddNewOwner, setIsAddNewOwner] = useState(false)
    const [newOwner, setNewOwner] = useState({ owner_name: '', owner_email: '' })
    const [ownerDetails, setOwnerDetails] = useState({ owner_name: '', owner_email: '' })
    const [helperText, setHelperText] = useState("")

    useEffect(() => {
        if (isAddNewOwner && (newOwner.owner_name || newOwner.owner_email)) {
            setHelperText(SAVE_HELPER_MESSAGE)
        } else if (isAddNewOwner) {
            setHelperText("")
        } else if (ownerDetails.owner_name || ownerDetails.owner_email) {
            setHelperText(UPDATE_HELPER_MESSAGE)
        } else {
            setHelperText("")
        }
    }, [newOwner, isAddNewOwner, ownerDetails])

    useEffect(() => {
        if (newOwner.owner_name || newOwner.owner_email) {
            setHelperText(SAVE_HELPER_MESSAGE)
        } else {
            setHelperText("")
        }
    }, [newOwner])

    function handleAddNewOwner() {
        const owner = {
            id: NEW_OWNER_ID,
            owner_name: newOwner.owner_name,
            owner_email: newOwner.owner_email,
        }
        if (owner.owner_name === "" || owner.owner_email === "") return
        if (owner.owner_name.length > MAX_OWNER_NAME_LENGTH) return
        const isInputValuesValid = getValidationOnOwnerData(owner.owner_email, brandOwners.map((o) => o?.owner_email))
        if (!isInputValuesValid) return
        setIsAddNewOwner(false)
        setNewOwner(INITIAL_OWNER)
        setBrandOwners([...brandOwners, owner])
    }

    function handleCancelAdd() {
        setNewOwner(INITIAL_OWNER)
        setIsAddNewOwner(false)
    }

    function handleEditOwner(index, owner = {}) {
        setOwnerDetails({ ...owner })
        setEditIndex(index)
    }

    function handleCancelEdit() {
        setEditIndex(INITIAL_EDIT_INDEX)
    }

    function handleDeleteOwner(index) {
        if (index > INITIAL_EDIT_INDEX) {
            brandOwners.splice(index, 1);
            setBrandOwners([...brandOwners])
            setEditIndex(INITIAL_EDIT_INDEX)
        }
    }

    function filterEmailsForEdit(brandOwners) {
        let emails = []
        brandOwners.forEach((o, index) => {
            if (index !== editIndex) {
                emails.push(o?.owner_email)
            }
        })
        return emails
    }

    function onUpdateOwnerhandler() {
        if (ownerDetails.owner_name === "" || ownerDetails.owner_email === "") return
        if (!isValidEmail(ownerDetails.owner_email)) return
        if (ownerDetails.owner_name.length > MAX_OWNER_NAME_LENGTH) return
        const isInputValuesValid = getValidationOnOwnerData(ownerDetails.owner_email, filterEmailsForEdit(brandOwners))
        if (!isInputValuesValid) return
        brandOwners[editIndex] = ownerDetails
        setEditIndex(INITIAL_EDIT_INDEX)
    }

    return (
        <Box sx={{ width: "auto" }}>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon data-test="brand_owners_expand" />}>
                    <p >Brand Owners</p>
                </AccordionSummary>
                <AccordionDetails>
                    <TableContainer component={Paper} sx={{ height: 210 }}>
                        <Table aria-label="simple table" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell width="20%">Name</TableCell>
                                    <TableCell width="30%" align="left">Email</TableCell>
                                    <TableCell width="25%" align="left" sx={{ paddingLeft: '0px' }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', pddingLeft: '0' }}>
                                            <Box>Action &nbsp;
                                                {brandOwners.length >= MAX_OWNERS_COUNT &&
                                                    <Tooltip title="Maximum number of owners exceeded">
                                                        <InfoOutlinedIcon sx={{ cursor: "pointer" }} color="error" style={{ height: "20px", width: "20px", marginRight: "0.2em" }} />
                                                    </Tooltip>
                                                }
                                            </Box>

                                            <IconButton
                                                data-test="brand_owner_add"
                                                title="Add Franchise Owners"
                                                size='small'
                                                className={isAddNewOwner || brandOwners.length >= MAX_OWNERS_COUNT || loading ? "icon-btn-disabled" : "icon-btn"}
                                                aria-label="delete"
                                                disabled={isAddNewOwner || brandOwners.length >= MAX_OWNERS_COUNT || loading}
                                                onClick={() => {
                                                    setIsAddNewOwner(true)
                                                    setEditIndex(INITIAL_EDIT_INDEX)
                                                }}
                                            >
                                                <AddIcon />
                                            </IconButton>
                                        </Box>

                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {isAddNewOwner ? <EditBrandOwnerRow key={NEW_OWNER_KEY} loading={loading} owner={newOwner} existingEmails={brandOwners.map((o) => o?.owner_email)} onOwnerChanged={setNewOwner} onSave={handleAddNewOwner} onCancel={handleCancelAdd} helperText={helperText} /> : <></>}
                                {(brandOwners && brandOwners?.length > 0) ?
                                    brandOwners?.map((owner, index) => index === editIndex ? <EditBrandOwnerRow key={owner.id} loading={loading} owner={ownerDetails} existingEmails={filterEmailsForEdit(brandOwners)} onOwnerChanged={setOwnerDetails} onCancel={handleCancelEdit} onSave={() => { onUpdateOwnerhandler(owner.id) }} helperText={helperText} /> : <BrandOwnerRow key={owner.id} loading={loading} owner={owner} onEdit={() => handleEditOwner(index, owner)} onDelete={() => handleDeleteOwner(index)} />) : <NoBrandOwnersRow />}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}
