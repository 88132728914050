import { ALL_FRANCHISES_STATUS, HTTP_STATUS_CODES, NOTIFICATION_TYPES } from "../constants/common"
import { getFranchiseByBrandId, getFranchiseByStatus, searchFranchiseByStatus, getFranchiseById, getFranchiseBrands } from "../services/franchises"
import { retry, shouldRetry } from "../state-services-dublicate/serviceTitan"
import { getSortedFranchisesAlphabeticallyAndActiveStatus } from "../utils/franchise"

export const handleFranchiseSearchByType = async (brand, status, debouncedSearchTerm, setLoading, setRecords, showNotification, setFeanchiseCount, page, size) => {
    try {
        if (debouncedSearchTerm?.length > 0) {
            setLoading(true)
            let searchresult = await searchFranchiseByStatus(status, brand?.brand_id, debouncedSearchTerm, page, size)
            setRecords(searchresult?.franchises ? searchresult.franchises : []);
            setFeanchiseCount({ all: searchresult.count.all, active: searchresult.count.active, deleted: searchresult.count.deleted, migrated: searchresult.count.migrated })
        } else {
            setRecords([])
        }
    } catch (error) {
        setRecords([])
        setFeanchiseCount({ all: 0, active: 0, deleted: 0, migrated: 0 })
        switch (error?.status) {
            case HTTP_STATUS_CODES.NotFound:
                showNotification({ message: `No franchises found with '${debouncedSearchTerm}' `, type: NOTIFICATION_TYPES.WARNING })
                break;
            case HTTP_STATUS_CODES.BadRequest:
                showNotification({ message: "Bad request", type: NOTIFICATION_TYPES.WARNING })
                break;
            case HTTP_STATUS_CODES.InternalServerError:
                showNotification({ message: "Unable to search at this moment", type: NOTIFICATION_TYPES.ERROR })
                break;
            default:
                showNotification({ message: "Unable to search at this moment", type: NOTIFICATION_TYPES.ERROR })
                break;
        }
    } finally {
        setLoading(false)
    }
}

export const getfranchisesHandler = async (brand, page, setLoading, status, countPerPage, setRecords, setFeanchiseCount, showNotification) => {
    try {
        setLoading(true);
        let franchiseData = await getFranchiseByStatus(status, brand?.brand_id, page, countPerPage)
        setRecords(franchiseData ? franchiseData.franchises : []);
        setFeanchiseCount({ all: franchiseData.count.all, active: franchiseData.count.active, deleted: franchiseData.count.deleted, migrated: franchiseData.count.migrated })
    } catch (error) {
        setRecords([])
        setFeanchiseCount({ all: 0, active: 0, deleted: 0, migrated: 0 })
        switch (error?.status) {
            case HTTP_STATUS_CODES.NotFound:
                showNotification({ message: `No franchises found for '${brand?.brand_name}' `, type: NOTIFICATION_TYPES.WARNING })
                break;
            case HTTP_STATUS_CODES.BadRequest:
                showNotification({ message: "Bad request", type: NOTIFICATION_TYPES.WARNING })
                break;
            case HTTP_STATUS_CODES.InternalServerError:
                showNotification({ message: "Unable to search at this moment", type: NOTIFICATION_TYPES.ERROR })
                break;
            default:
                showNotification({ message: "Unable to search at this moment", type: NOTIFICATION_TYPES.ERROR })
                break;
        }
    } finally {
        setLoading(false);
    }
}

export async function handleGetBrandFranchises(brandId = 0, setFranchises, setIsFranchisesLoading, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    try {
        setFranchises([])
        if (!brandId) return
        setIsFranchisesLoading(true)
        const res = await getFranchiseByBrandId(brandId, ALL_FRANCHISES_STATUS)
        if (Array.isArray(res)) setFranchises(getSortedFranchisesAlphabeticallyAndActiveStatus(res))
    } catch (err) {
        if (shouldRetry(backoffConfig) && err?.status !== 400 && err?.status !== 404) retry(() => handleGetBrandFranchises(brandId, setFranchises, setIsFranchisesLoading, backoffConfig), backoffConfig)
    } finally {
        setIsFranchisesLoading(false)
    }
}

export async function handleGetFranchiseBrands(franchiseIds = [], setFranchiseBrands, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    try {
        setFranchiseBrands([])
        if (franchiseIds.length === 0) return
        const res = await getFranchiseBrands(franchiseIds)
        if (Array.isArray(res)) setFranchiseBrands(res)
    } catch (err) {
        if (shouldRetry(backoffConfig) && err?.status !== 400 && err?.status !== 404) retry(() => handleGetBrandFranchises(franchiseIds, setFranchiseBrands, backoffConfig), backoffConfig)
    } finally {
    }
}

export async function handleGetFranchiseById(id, setFranchiseDetails) {
    try {
        let franchise = await getFranchiseById(id)
        setFranchiseDetails(franchise)
    } catch { }
}