import React, { useContext } from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Slider from '@mui/material/Slider';
import { getDesignTokens } from '../contexts/ThemeContextProvider';
import { UserContext } from '../contexts/User';

export default function CustomeSlider({ value, trackColor, trackHeight = 10, railColor, railHeight = 10, thumbColor, min, max, marks = [], onChange, track, step = null }) {

    const { userPreferences } = useContext(UserContext)
    const themeOptions = getDesignTokens(userPreferences.dark_mode_enabled === 1 ? 'dark' : 'light'); // You can also use 'dark' to switch to dark mode
    const theme = createTheme(themeOptions);

    const CustomTheme = createTheme({
        components: {
            MuiSlider: {
                styleOverrides: {
                    track: {
                        backgroundColor: trackColor,
                        height: trackHeight,
                    },
                    rail: {
                        height: railHeight,
                        backgroundColor: railColor,
                    },
                    thumb: {
                        color: thumbColor,
                    },
                    markLabel: {
                        color: theme?.palette?.text?.primary,
                    },
                },
            },
        },
    });

    return (
        <ThemeProvider theme={CustomTheme}>
            <Slider
                value={value}
                onChange={onChange}
                getAriaValueText={val => `${val}`}
                track={track}
                step={step}
                marks={marks}
                min={min}
                max={max}
            />
        </ThemeProvider>
    )
}
