import { FetchClient } from "../utils/fetchClient"


export const globalSearch = async (prefix = "", searchTerm = "") => {
    return FetchClient(`/global-search/redis-search?prefix=${prefix}&searchTerm=${searchTerm}`).GET({})
}

export const addLeadToRedisSearch = async (leadData = {}) => {
    return FetchClient(`/data/v3/global-search/lead`).POST({ payload: JSON.stringify(leadData) })
}

