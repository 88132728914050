import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import AddIcon from "@mui/icons-material/Add";
import { isValidEmail, isValidPhoneNumber } from '../../../../../utils/validators';
import { getIsEmailAlreadyExists, getValidationOnOwnerData } from './utils/common';
import EditOwnerRow from './components/EditOwnerRow';
import OwnerRow from './components/OwnerRow';
import NoOwnersRow from './components/NoOwnerRow';
import { INITIAL_EDIT_INDEX, INITIAL_OWNER, MAX_OWNERS_COUNT, MAX_OWNER_NAME_LENGTH, NEW_OWNER_ID, NEW_OWNER_KEY, SAVE_HELPER_MESSAGE, UPDATE_HELPER_MESSAGE } from './constants/common';
import { UserContext } from "../../../../../contexts/User";
import { userLevels } from '../../../../../constants/common';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export default function OwnersTable(props) {
    const { disabled, franchiseOwners = [], setFranchiseOwners } = props
    const [editIndex, setEditIndex] = useState(INITIAL_EDIT_INDEX)
    const [isAddNewOwner, setIsAddNewOwner] = useState(false)
    const [newOwner, setNewOwner] = useState(INITIAL_OWNER)
    const [ownerDetails, setOwnerDetails] = useState(INITIAL_OWNER)
    const [helperText, setHelperText] = useState("")
    const { me } = useContext(UserContext)

    useEffect(() => {
        if (isAddNewOwner && (newOwner.owner_name || newOwner.owner_email || newOwner.owner_phone)) {
            setHelperText(SAVE_HELPER_MESSAGE)
        } else if (isAddNewOwner) {
            setHelperText("")
        } else if (ownerDetails.owner_name || ownerDetails.owner_email || ownerDetails.owner_phone) {
            setHelperText(UPDATE_HELPER_MESSAGE)
        } else {
            setHelperText("")
        }
    }, [newOwner, isAddNewOwner, ownerDetails])

    useEffect(() => {
        if (newOwner.owner_name || newOwner.owner_email || newOwner.owner_phone) {
            setHelperText(SAVE_HELPER_MESSAGE)
        } else {
            setHelperText("")
        }
    }, [newOwner])

    function handleAddNewOwner() {
        const owner = {
            id: NEW_OWNER_ID,
            owner_name: newOwner.owner_name,
            owner_email: newOwner.owner_email,
            owner_phone: newOwner.owner_phone
        }
        if (owner.owner_name === "" || owner.owner_email === "") return
        if (owner.owner_name.length > MAX_OWNER_NAME_LENGTH) return
        const isInputValuesValid = getValidationOnOwnerData(owner.owner_email, owner.owner_phone, franchiseOwners.map((o) => o?.owner_email))
        if (!isInputValuesValid) return
        setIsAddNewOwner(false)
        setNewOwner(INITIAL_OWNER)
        setFranchiseOwners([...franchiseOwners, owner])
    }

    function handleCancelAdd() {
        setNewOwner(INITIAL_OWNER)
        setIsAddNewOwner(false)
    }

    function handleEditOwner(index, owner = {}) {
        setOwnerDetails({ ...owner })
        setEditIndex(index)
    }

    function handleCancelEdit() {
        setEditIndex(INITIAL_EDIT_INDEX)
    }

    function handleDeleteOwner(index) {
        if (index > INITIAL_EDIT_INDEX) {
           const newFranchiseOwners = franchiseOwners.toSpliced(index, 1);
            setFranchiseOwners(newFranchiseOwners)
            setEditIndex(INITIAL_EDIT_INDEX)
        }
    }

    function filterEmailsForEdit(franchiseOwners) {
        let emails = []
        franchiseOwners.forEach((o, index) => {
            if (index !== editIndex) {
                emails.push(o?.owner_email)
            }
        })
        return emails
    }

    function onUpdateOwnerhandler() {
        if (ownerDetails.owner_name === "" || ownerDetails.owner_email === "") return
        if (ownerDetails.owner_name.length > MAX_OWNER_NAME_LENGTH) return
        if (!isValidEmail(ownerDetails.owner_email)) return
        if (!isValidPhoneNumber(ownerDetails.owner_phone) && ownerDetails.owner_phone) return
        const isEmailAlreadyExist = getIsEmailAlreadyExists(ownerDetails.owner_email, filterEmailsForEdit(franchiseOwners))
        if (isEmailAlreadyExist) return
        const newFranchiseOwners = franchiseOwners.map((owner, index) => {
            if (index === editIndex) {
                return ownerDetails
            }
            return owner
        })
        setFranchiseOwners(newFranchiseOwners)
        setEditIndex(INITIAL_EDIT_INDEX)
    }

    return (
        <TableContainer component={Paper} sx={{ height: 210 }}>
            <Table aria-label="simple table" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell width="20%">Name</TableCell>
                        <TableCell width="25%" align="left">Email</TableCell>
                        <TableCell width="25%" align="left">Phone</TableCell>
                        {me?.level > userLevels.Supervisor &&
                            <TableCell width="30%" align="right">Action &nbsp;
                                {franchiseOwners.length >= MAX_OWNERS_COUNT &&
                                    <Tooltip title="Maximum number of owners exceeded">
                                        <InfoOutlinedIcon sx={{ cursor: "pointer" }} color="error" style={{ height: "20px", width: "20px", marginRight: ".2em" }} />
                                    </Tooltip>
                                }
                                <IconButton
                                    data-test="franchise_owner_add"
                                    title="Add Franchise Owners"
                                    size='small'
                                    className={disabled || franchiseOwners.length >= MAX_OWNERS_COUNT || isAddNewOwner ? "icon-btn-disabled" : "icon-btn"}
                                    aria-label="delete"
                                    disabled={disabled || isAddNewOwner || franchiseOwners.length >= MAX_OWNERS_COUNT}
                                    onClick={() => {
                                        setIsAddNewOwner(true)
                                        setEditIndex(INITIAL_EDIT_INDEX)
                                    }}
                                >
                                    <AddIcon />
                                </IconButton>
                            </TableCell>
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {isAddNewOwner ? <EditOwnerRow key={NEW_OWNER_KEY} owner={newOwner} existingEmails={franchiseOwners.map((o) => o?.owner_email)} onOwnerChanged={setNewOwner} onSave={handleAddNewOwner} onCancel={handleCancelAdd} helperText={helperText} /> : <></>}
                    {(franchiseOwners && franchiseOwners?.length > 0) ?
                        franchiseOwners?.map((owner, index) => index === editIndex ? <EditOwnerRow key={owner.id} owner={ownerDetails} existingEmails={filterEmailsForEdit(franchiseOwners)} onOwnerChanged={setOwnerDetails} onCancel={handleCancelEdit} onSave={() => { onUpdateOwnerhandler(owner.id) }} helperText={helperText} /> : <OwnerRow key={owner.id} owner={owner} onEdit={() => handleEditOwner(index, owner)} onDelete={() => handleDeleteOwner(index)} readOnly={me?.level <= userLevels.Supervisor} />) : <NoOwnersRow />}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
