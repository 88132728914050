import { IconButton, Paper, Tooltip } from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';
import "../styles/scoreDashboardStyles.css"
import CastIcon from '@mui/icons-material/Cast';
import profilePicture from '../../../../assets/img/screen_monitoring_images/userProfile.png'
import { closeConnection, updateMonitoringLogs } from "../../../../services/screenMonitoring";
import { MONITORING_STATUS } from "../../../../constants/monotoringLogs";

export function AgentTile({ agent, isAgentMonitorError, setIsAgentMonitorError, index, setSelectedAgent, lastInsertedId, selectedAgent, webRtcConnection, isDisconnected, setIsDisconnected, setisErrorOccured, isOffline }) {

    function handleSetAgent() {
        if (agent?.user_id != selectedAgent?.user_id) {
            if (webRtcConnection && !isAgentMonitorError) {
                localStorage.removeItem("agent")
                let closingData = {
                    message: JSON.stringify({ message_type: "close", body: "" }),
                    to_session_id: selectedAgent?.session_id,
                }
                closeConnection(closingData)
                let closeLogData = {
                    admin_id: parseInt(localStorage.getItem("user_id")),
                    agent_id: parseInt(selectedAgent?.user_id),
                    status: MONITORING_STATUS.end,
                    last_inserted_id: lastInsertedId
                }
                updateMonitoringLogs(closeLogData)
                webRtcConnection.close()
            }
            if (agent?.isOnline) {
                localStorage.setItem("agent", agent?.session_id)
            }
            setisErrorOccured(false)
            setIsAgentMonitorError(false)
            setIsDisconnected(false)
            setSelectedAgent(agent)
        }
    }

    return (
        <Paper key={index} className={"agent-tile"} sx={{ backgroundColor: selectedAgent?.user_id === agent?.user_id ? 'wallboard.logList' : agent?.level === 60 ? 'wallboard.agentMonitoringTile' : 'form.secondary' }} >
            <div style={{ display: "flex", flexDirection: "row", gap: "1em", alignItems: "center", width: "100%" }}>
                <div style={{ minWidth: "10%", position: "relative", marginRight: "3%" }}>
                    {agent?.img !== "" ?
                        <img style={{ height: "40px", marginLeft: "10%", borderRadius: "50%", marginTop: "2%" }} src={agent?.img} referrerpolicy="no-referrer" alt="" />
                        :
                        <img style={{ height: "40px", width: "40px", marginLeft: "10%", borderRadius: "50%", marginTop: "2%" }} src={profilePicture} alt="" />
                    }
                    {agent?.isOnline && !isOffline ?
                        <CircleIcon style={{ position: "absolute", bottom: 5, left: 27, height: "12px", Width: "12px", color: "green" }}></CircleIcon>
                        :
                        <CircleIcon style={{ position: "absolute", bottom: 5, left: 27, height: "12px", Width: "12px", color: "red" }}></CircleIcon>
                    }
                </div>
                <div style={{ display: "flex", minWidth: "35%", maxWidth: "70%", marginLeft: "3%", marginRight: "3%" }}>
                    <p>{`${agent?.first_name} ${agent?.last_name}`}</p>
                </div>
                <div style={{ marginLeft: "auto", marginRight: "6%" }}>
                    <IconButton className={agent?.isOnline && !isOffline ? "monitor-btn" : "monitor-btn-disabled"} disabled={!agent?.isOnline || isAgentMonitorError || isDisconnected || isOffline} onClick={() => handleSetAgent()}>
                        <Tooltip title={"Monitor Agents Screen"}><CastIcon></CastIcon></Tooltip>
                    </IconButton>
                </div>
            </div>
        </Paper>
    )
}