import { Autocomplete, Button, IconButton, Tooltip } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import { Box } from '@mui/system'
import React, { useState } from 'react'
import RenderInput from '../../../../../../../components/servicetitan/RenderInput'
import { NOTIFICATION_TYPES } from '../../../../../../../constants/common'
import { CustomTextField } from '../../../../../jobs/components/customComponents'
import { CUSTOMER_TYPES } from '../../../../../jobs/create-job-page/ServiceTitanMainSection'
import CustomerTypesRadioButton from '../../../../../jobs/serviceTitanJobs/components/CustomerTypesRadioButton'
import LoadingButton from '../../../../../../../components/LoadingButton';
import { updateFranchiseCustomer, updateServiceTitanMiddlewareLead } from '../../../../../../../services/serviceTitan';
import { addLeadToRedisSearch } from '../../../../../../../services/globalSearch';

export default function CustomerSection({ franchise, customers, selectedCustomerContacts, selectedLeadToEdit, selectedCustomer, setSelectedCustomer, showNotification }) {

    const [editCustomerDetails, setEditCustomerDetails] = useState(null)
    const [onEditCustomerLoading, setOnEditCustomerLoading] = useState(false)

    function onCustomerEditPressed() {
        setEditCustomerDetails(selectedCustomer)
    }

    function onEditCustomerCancelPressed() {
        setEditCustomerDetails(null)
    }

    function onEditDone(editedCustome) {
        setSelectedCustomer(editedCustome)
        showNotification({ message: "Successfully saved", type: NOTIFICATION_TYPES.SUCCESS })
        setEditCustomerDetails(null)
    }

    function onErrorEditCustomer(err) {
        let errDetails = JSON.parse(err.responseText)
        let errCode = errDetails?.data?.ErrorCode
        let stateErr
        if (errCode === "StateNotValid") {
            stateErr = "State doesn't valid with country"
        } else if (errCode === "CountryNotValid") {
            stateErr = "Country is not valid"
        } else {
            stateErr = ""
        }
        showNotification({ message: `Important: Could not edit the customer. ${stateErr}. Please try again`, type: NOTIFICATION_TYPES.ERROR })
    }

    async function handleEditCustomer(franchise, editedCustomer, setIsEditedCustomerLoading, onDone, onError) {
        try {
            setIsEditedCustomerLoading(true)
            const res = await updateFranchiseCustomer(franchise.id, editedCustomer.id, editedCustomer)
            onDone(res)
            await updateServiceTitanMiddlewareLead({ customerId: editedCustomer?.id, customerName: editedCustomer?.name })
            let updatedLeadDetails = {
                id: selectedLeadToEdit?.middlewareServiceTitanLead?.id,
                leadId: selectedLeadToEdit?.middlewareServiceTitanLead?.leadId,
                customerName: editedCustomer?.name,
                customerContacts: selectedCustomerContacts,
            }
            await addLeadToRedisSearch(updatedLeadDetails)
        } catch (err) {
            onError(err)
        } finally {
            setIsEditedCustomerLoading(false)
        }
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: "column", gap: "0.5em" }}>
            <Box sx={{ display: 'flex', flexDirection: "row", width: "100%", height: "3em", justifyContent: "left", alignItems: "center" }}>
                <h4>Customer Details</h4>
                <Tooltip title="Edit Customer" ><IconButton onClick={onCustomerEditPressed} disabled={editCustomerDetails !== null} size="small" ><EditIcon color={!editCustomerDetails ? "primary" : "disabled"} /></IconButton></Tooltip>
            </Box>
            {!editCustomerDetails ?
                <ExistingCustomerDetailsSection franchise={franchise} customers={customers} selectedCustomer={selectedCustomer} setSelectedCustomer={setSelectedCustomer} />
                :
                <EditCustomerDetailsSection franchise={franchise} editCustomerDetails={editCustomerDetails} setEditCustomerDetails={setEditCustomerDetails}
                    onEditCancel={onEditCustomerCancelPressed} onEditDone={onEditDone} handleEditCustomer={handleEditCustomer} isEditCustomerLoading={onEditCustomerLoading} setIsEditCustomerLoading={setOnEditCustomerLoading} onErrorEditCustomer={onErrorEditCustomer} />
            }
        </Box>
    )
}

function ExistingCustomerDetailsSection({ franchise, customers, selectedCustomer, setSelectedCustomer }) {
    return (
        <Box >
            <Box sx={{ display: 'flex', flexDirection: "row", flexWrap: "wrap", width: "100%", marginRight: "1em", gap: "1em" }}>
                <Autocomplete
                    sx={{ width: "45%" }}
                    size="small"
                    disabled={true}
                    noOptionsText={franchise ? "No customer found" : "Please select a franchise"}
                    value={selectedCustomer ?? null}
                    disablePortal
                    disableClearable
                    options={customers}
                    getOptionLabel={(customer) => customer.name}
                    renderOption={(props, customer) => <RenderInput {...props} key={customer?.id} content={customer?.name} />}
                    onChange={(_, customer) => {
                        setSelectedCustomer(customer);
                    }}
                    renderInput={(params) => <CustomTextField {...params} required={true} label="Select Customer" />}
                />
                <CustomerTypesRadioButton isDisabled={true} options={CUSTOMER_TYPES} value={selectedCustomer?.type ?? ""} />
            </Box>
        </Box>
    )
}

function EditCustomerDetailsSection({ franchise, editCustomerDetails, setEditCustomerDetails, onEditCancel, onEditDone, handleEditCustomer, isEditCustomerLoading, setIsEditCustomerLoading, onErrorEditCustomer }) {

    function onTypeChanged(_, type) {
        setEditCustomerDetails({ ...editCustomerDetails, type: type })
    }

    function onNameChanged(e) {
        setEditCustomerDetails({ ...editCustomerDetails, name: e.target.value });
    }

    return (
        <Box >
            <Box sx={{ display: 'flex', flexDirection: "row", flexWrap: "wrap", width: "100%", marginRight: "1em", gap: "1em" }}>
                <CustomTextField style={{ width: "45%" }} required={true} value={editCustomerDetails?.name ?? ""} size="small" label="Customer Name" onChange={onNameChanged} />
                <CustomerTypesRadioButton isDisabled={false} options={CUSTOMER_TYPES} value={editCustomerDetails?.type ?? ""} onChange={onTypeChanged} />
                <Box sx={{ marginLeft: "auto" }} >
                    <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "right", gap: "1em" }} >
                        <Button size={"small"} variant="contained" onClick={onEditCancel} className={"btn-secondary"} >Cancel</Button>
                        <LoadingButton
                            onClick={() => { handleEditCustomer(franchise, editCustomerDetails, setIsEditCustomerLoading, onEditDone, onErrorEditCustomer) }}
                            size={"small"}
                            variant="contained"
                            disabled={isEditCustomerLoading || editCustomerDetails?.address?.unit?.length > 50}
                            loading={isEditCustomerLoading}
                            className={isEditCustomerLoading ? "btn-disable" : "btn-primary"} >
                            {"Update Customer"}
                        </LoadingButton>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}