import { Box, Button } from "@mui/material";
import { useMemo, useState } from "react";
import AngiesListLeads from "./AngiesList/AngiesListLeads";
import { withRouter } from "react-router-dom";

const CAMPAIGN_SECTIONS = {
    ANGEIES_LEADS: {
        index: 0,
        section: "angies",
        title: "Angies",
    }
}

function CampaignsLandingPage(props) {
    const initialSection = useMemo(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const section = urlParams.get('section');
        return Object.values(CAMPAIGN_SECTIONS).find((v) => v.section === section)
    }, [])

    const [selectedSection, setSelectedSection] = useState(initialSection ? initialSection : CAMPAIGN_SECTIONS.ANGEIES_LEADS)

    function getSection() {
        switch (selectedSection.section) {
            case CAMPAIGN_SECTIONS.ANGEIES_LEADS.section:
                return <AngiesListLeads />
            default:
                return <></>
        }
    }

    return <Box sx={{ padding: "0.5em", flexGrow: 2, display: "flex", flexDirection: "column" }}>
        <Box className={"tabs"}>
            {Object.values(CAMPAIGN_SECTIONS).map((tab) => {
                return <Button
                    key={tab.index}
                    onClick={() => setSelectedSection(tab)}
                    size={"small"}
                    variant="contained"
                    className={tab === selectedSection ? "btn-primary tab" : "btn-secondary tab"}
                >{tab.title}</Button>
            })}
        </Box>
        <Box sx={{ flexGrow: 2 }} >{getSection()}</Box>
    </Box>
}

export default withRouter(CampaignsLandingPage);
