import { Table, TableHead, Container, Pagination, TableBody, TableCell, TableContainer, TableRow, IconButton, Tooltip, CircularProgress } from "@mui/material";
import moment from "moment";
import { useState, useEffect, useContext } from "react"
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { SERVICETITAN_PAGE_SIZE } from "../../../../../constants/serviceTitan";
import { NotSuccessIcon, SuccessIcon } from "../../../../../icons/common";
import { compareTwoRFCDateStringsDecendingOrder } from "../../../../../utils/compareFunctions";
import { getMatchingUserByUserId } from "../../../../../utils/miscellaneous";
import { handleGetServiceTItanjobDrafts, handleOnDeleteIconClicked } from "../../../../../state-services-dublicate/serviceTitan";
import { getPhoneNumberWithInputMask } from "../../../../../utils/inputMasks";
import { isContactsValid, isValidZipCode } from "../../../../../utils/validators";
import useDebounce from "../../../../../hooks/useDebounce";
import { ERROR_COMPONENTS } from "../../../../../constants/common";
import ErrorView from "../../../../../components/ErrorView";
import { CommonContext } from "../../../../../contexts/CommonContextProvider";

export default function ServiceTitanDraftJobs(props) {
    const { brand, franchise, globalSearchValue, filterState, setJobStatisticsComponent, setNotify, setDraftToContinue, page, setPage } = props
    const [draftJobs, setDraftJobs] = useState([]);
    const [showingDraftJobs, setShowingDraftJobs] = useState([]);
    const [totalDraftJobsCount, setTotalDraftJobsCount] = useState(0)
    const [isDraftJobsLoading, setIsDraftJobsLoading] = useState(false);
    const [isDraftJobsLoadingError, setIsDraftJobsLoadingError] = useState(false);
    const { allUsers } = useContext(CommonContext)
    const debounceHandleGetServiceTitanjobDrafts = useDebounce(({ brandId: brand?.id, franchiseId: franchise.id, createdStart: filterState.createdStart, createdEnd: filterState.createdEnd, page: page }), ({ brandId, franchiseId, createdStart, createdEnd, page }) => {
        handleGetServiceTItanjobDrafts(brandId, franchiseId, createdStart, createdEnd, page, SERVICETITAN_PAGE_SIZE, setDraftJobs, setTotalDraftJobsCount, setIsDraftJobsLoading, setIsDraftJobsLoadingError)
    }, 1000)

    useEffect(() => {
        draftJobs?.sort((a, b) => compareTwoRFCDateStringsDecendingOrder(a?.lastUpdatedAt, b?.lastUpdatedAt))
        setShowingDraftJobs([...draftJobs] ?? [])
    }, [draftJobs, setJobStatisticsComponent])

    useEffect(() => {
        setJobStatisticsComponent(<small><strong>{showingDraftJobs?.length} {showingDraftJobs?.length === 1 ? "Draft Job" : "Draft Jobs"}</strong></small>)
    }, [showingDraftJobs?.length, setJobStatisticsComponent])

    useEffect(() => {
        debounceHandleGetServiceTitanjobDrafts({ brandId: brand?.id, franchiseId: franchise.id, createdStart: filterState.createdStart, createdEnd: filterState.createdEnd, page: page })
    }, [brand?.id, franchise?.id, filterState?.createdStart, filterState?.createdEnd, page, debounceHandleGetServiceTitanjobDrafts])

    function showNotification(notify = {}) {
        setNotify({ ...notify, isOpen: true })
    }

    return <div style={{ minHeight: "60vh" }}>
        <TableContainer sx={{ height: "80%" }} >
            <Table stickyHeader='true'>
                <TableHead sx={{}}>
                    <TableRow>
                        <TableCell style={{ textAlign: "left" }} >#</TableCell>
                        <TableCell style={{ textAlign: "left" }} >Caller ID</TableCell>
                        <TableCell style={{ textAlign: "left" }} >Zip Code</TableCell>
                        <TableCell style={{ textAlign: "center" }}  >Customer</TableCell>
                        <TableCell style={{ textAlign: "center" }}  >Contact</TableCell>
                        <TableCell style={{ textAlign: "center" }}  >Location</TableCell>
                        <TableCell style={{ textAlign: "center" }}  >Job</TableCell>
                        <TableCell style={{ textAlign: "center" }}  >Appointment</TableCell>
                        <TableCell style={{ textAlign: "left" }} >Updated By</TableCell>
                        <TableCell style={{ textAlign: "left" }}  ><Container style={{ padding: 0, cursor: "pointer" }} sx={{ display: "flex", flexDirection: "row", columnGap: "0.5em", alignItems: "center", justifyContent: "left", }} onClick={() => { }}>{"Last Updated At"}{""}</Container></TableCell>
                        <TableCell >Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody >
                    {isDraftJobsLoading ? buildLoadingRow() : isDraftJobsLoadingError ? buildErrorRow() : showingDraftJobs.length === 0 ? buildNoJobsToShow() : buildDraftJobRows(showingDraftJobs)}
                </TableBody>
            </Table>
        </TableContainer>
        <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "end" }}>
            <Pagination
                page={page}
                onChange={(e, v) => {
                    setPage(v);
                }} color="standard" count={globalSearchValue ? 1 : Math.ceil(totalDraftJobsCount / SERVICETITAN_PAGE_SIZE)} sx={{ alignSelf: "right" }}
            />
        </div>
    </div>

    function buildDraftJobRows(jobs = []) {
        return jobs.map((draft, i) => {
            const user = getMatchingUserByUserId(draft?.lastUpdatedBy, allUsers)
            const userFullName = `${user?.first_name ?? ""} ${user?.last_name ?? ""}`
            return (
                <TableRow key={i}>
                    <TableCell>{i + 1}</TableCell>
                    <TableCell>{draft?.callerId ? getPhoneNumberWithInputMask(draft?.callerId) : "-"}</TableCell>
                    <TableCell>{draft?.zipCode ? draft?.zipCode : "-"}</TableCell>
                    <CenteredTableCell>{getIsCustomerComplete(draft) ? <SuccessIcon /> : <NotSuccessIcon />}</CenteredTableCell>
                    <CenteredTableCell>{getIsCustomerContactsComplete(draft) ? <SuccessIcon /> : <NotSuccessIcon />}</CenteredTableCell>
                    <CenteredTableCell>{getIsCustomerLocationComplete(draft) ? <SuccessIcon /> : <NotSuccessIcon />}</CenteredTableCell>
                    <CenteredTableCell>{getIsJobSectionComplete(draft?.jobCreateRequest) ? <SuccessIcon /> : <NotSuccessIcon />}</CenteredTableCell>
                    <TableCell style={{ textAlign: "center" }}>{getAppointmentSectionComplete(draft?.jobCreateRequest?.appointments?.[0]) ? <SuccessIcon /> : <NotSuccessIcon />}</TableCell>
                    
                    <TableCell >{user ? userFullName : "-"}</TableCell>
                    <TableCell >{draft?.lastUpdatedAt ? moment(draft?.lastUpdatedAt).format('MM/DD/YYYY - h:mm A') : "-"}</TableCell>
                    <TableCell >
                        <IconButton onClick={() => {
                                                        setDraftToContinue(draft)
                        }} color='info' size={"medium"} style={{ marginRight: "10px", paddingLeft: '0px' }}>
                            <Tooltip title={"Continue"}><EditIcon style={{ height: "20px", width: "20px" }} /></Tooltip>
                        </IconButton>
                        <IconButton onClick={() => {
                            handleOnDeleteIconClicked(draft, showingDraftJobs, setDraftJobs, setIsDraftJobsLoadingError, showNotification)
                        }} color='error' size={"medium"} style={{ marginRight: "10px" }}>
                            <Tooltip title={"Delete"}><DeleteIcon style={{ height: "20px", width: "20px", color: "red" }} /></Tooltip>
                        </IconButton>
                    </TableCell>
                </TableRow>
            );
        })
    }

    function buildErrorRow() {
        return buildInformationRowContainer(<ErrorView type={ERROR_COMPONENTS.SomthingWentWrong.type} />)
    }

    function buildNoJobsToShow() {
        return buildInformationRowContainer(<ErrorView type={ERROR_COMPONENTS.NoDraftsFound.type} />)
    }

    function buildLoadingRow() {
        return buildInformationRowContainer(<CircularProgress size={30} color="inherit" />)
    }

    function buildInformationRowContainer(children) {
        return <TableRow><TableCell colSpan={14} align="center" style={{ border: "none", height: "300px", color: "grey" }}>
            {children}
        </TableCell></TableRow>;
    }
}

function getIsCustomerComplete(draft) {
    return draft?.isNewCustomer ? (draft?.createCustomerRequest?.name && getIsAddressComplete(draft?.createCustomerRequest?.address)) : draft?.jobCreateRequest?.customerId
}

function getIsCustomerLocationComplete(draft) {
    return draft?.isNewCustomer || draft?.isNewLocation ? (draft?.createCustomerRequest?.locations?.[0]?.name && getIsAddressComplete(draft?.createCustomerRequest?.locations?.[0]?.address)) && draft?.isWithinServiceArea : (draft?.jobCreateRequest?.locationId && draft?.isWithinServiceArea)
}

function getIsCustomerContactsComplete(draft) {
    return draft?.isNewCustomer ? isContactsValid(draft?.createCustomerRequest?.contacts) : draft?.jobCreateRequest?.customerId ? true : false
}

function getIsAddressComplete(address) {
    return address && address?.street && address?.city && address?.state && address?.country && isValidZipCode(address?.zip, address?.country)
}

function getIsJobSectionComplete(job) {
    return job && job?.campaignId && job?.businessUnitId && job?.jobTypeId && job?.priority && job?.summary
}

function getAppointmentSectionComplete(appointment) {
    return appointment?.start && appointment?.end && appointment?.arrivalWindowStart && appointment?.arrivalWindowEnd
}

function CenteredTableCell(props) {
    return <TableCell style={{ textAlign: "center" }} >{props.children}</TableCell>
}