import { Autocomplete, Box, Button, Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import FaxIcon from '@mui/icons-material/Fax';
import AodIcon from '@mui/icons-material/Aod';
import { getAddressString, pinnedLatestFirstCompareFunction, sendAEmail } from '../../../../../utils/miscellaneous'
import { getPhoneNumberWithInputMask } from '../../../../../utils/inputMasks'
import { CommonContext } from '../../../../../contexts/CommonContextProvider';
import { getACustomerContacts, getCustomerById, getLocationById, getServiceTitanCustomerNotes } from '../../../../../services/serviceTitan';
import { handleGetTagTypes } from '../../../../../state-services-dublicate/serviceTitan';
import { CustomTextField } from '../../../faq/utils/CustomFaqStyles';
import RenderInput from '../../../../../components/servicetitan/RenderInput';
import LoadingButton from '../../../../../components/LoadingButton';
import { handleAddTagsForCustomer, handleServiceTitanCallBackLog } from '../../../../../state-services/serviceTitanEstimates/serviceTitanEstimates';
import LoadingView from '../../../../../components/refreshView/LoadingView';
import NoContentView from '../../../../../components/refreshView/NoContentView';
import NoteCard from '../../../../../components/servicetitan/NoteCard';
import RefreshErrorView from '../../../../../components/refreshView/RefreshErrorView';
import AddNote from './AddNote';
import { NOTIFICATION_TYPES } from '../../../../../constants/common';

export default function CustomerDetails({ estimate, brand, franchise }) {

    const { popPhoneDialer, showNotification } = useContext(CommonContext);
    const [customer, setCustomer] = useState();
    const [location, setLocation] = useState();
    const [customerContacts, setCustomerContacts] = useState([]);
    const [isTagTypesLoading, setIsTagTypesLoading] = useState(false)
    const [TagTypes, setTagTypes] = useState([])
    const [tagTypes, settagTypes] = useState([]);
    const [selectedTagTypes, setSelectedTagTypes] = useState([])
    const [notes, setNotes] = useState([])
    const [isAddTagButtonLoading, setIsAddTagButtonLoading] = useState(false)
    const [isNotesLoading, setIsNotesLoading] = useState(false)
    const [isNotesLoadingError, setIsNotesLoadingError] = useState(false)
    const [isAddNewNote, setIsAddNewNotes] = useState(false)
    const [toggleGetNotes, setToggleGetNotes] = useState(true)

    useEffect(() => {
        if (franchise && estimate) {
            getCustomerById(franchise.service_titan_tenant_id, franchise.service_titan_client_id, franchise.service_titan_client_secret, estimate?.customerId,).then((customerResponse) => {
                setCustomer(customerResponse)
            }).catch(() => {
            })

            getLocationById(franchise.service_titan_tenant_id, franchise.service_titan_client_id, franchise.service_titan_client_secret, estimate?.locationId,).then((locationResponse) => {
                setLocation(locationResponse)
            }).catch(() => {
            })
            getACustomerContacts(franchise?.id, estimate?.customerId).then((customerContactResponse) => {
                if (Array.isArray(customerContactResponse.data)) {
                    setCustomerContacts(customerContactResponse.data);
                }
            }).catch(() => {
            })
            handleGetTagTypes(franchise, [], setTagTypes, () => { }, setIsTagTypesLoading);
        }
    }, [estimate, franchise])

    useEffect(() => {
        if (franchise && estimate) {
            handleGetCustomerNotes(franchise?.id, estimate?.customerId, setNotes, setIsNotesLoading, setIsNotesLoadingError)
        }
    }, [franchise, estimate, toggleGetNotes])

    useEffect(() => {
        if (TagTypes.length > 0 && customer?.tagTypeIds?.length > 0) {
            const releventTages = TagTypes.filter((tagType) => customer?.tagTypeIds?.includes(tagType.id))
            settagTypes(releventTages);
            setSelectedTagTypes(releventTages);
        }
    }, [TagTypes, estimate?.tagTypeIds, customer?.tagTypeIds])

    const isDisableAddTagButton = () => {
        return selectedTagTypes.length === 0 || isAddTagButtonLoading;
    }

    async function handleAddTags(franchise, setIsLoading, showNotification, estimate) {
        try {
            let selectedTagTypeIds = selectedTagTypes.map((tagType) => tagType.id)
            let customerRequest = { ...customer, tagTypeIds: selectedTagTypeIds }
            await handleAddTagsForCustomer(franchise?.id, estimate?.customerId, customerRequest, setIsLoading, showNotification, selectedTagTypes, settagTypes)
        } catch (error) {
        }
    }

    async function handleGetCustomerNotes(franchiseId, customerId, setCustomerNotes, setIsLoading, setIsError) {
        try {
            setIsError(false)
            setIsLoading(true)
            const res = await getServiceTitanCustomerNotes(franchiseId, customerId)
            if (Array.isArray(res.data)) {
                setCustomerNotes(res.data)
            }
        } catch {
            setIsError(true)
        } finally {
            setIsLoading(false)
        }
    }

    async function handleCreateOrUpdateCallLogs(outboundCallId) {
        await handleServiceTitanCallBackLog(brand?.id, franchise?.id, estimate?.id,outboundCallId)
    }

    function onDoneAddNote(res) {
        showNotification({ message: "Successfully saved", type: NOTIFICATION_TYPES.SUCCESS })
        const newNotes = [res, ...notes]
        newNotes.sort(pinnedLatestFirstCompareFunction);
        setNotes(newNotes)
        setIsAddNewNotes(false)

    }

    return (
        <Box sx={{ marginBottom: "20px" }}>
            <Box sx={{ overflowY: "auto", display: "flex", flexDirection: "row", gap: "0.25em", padding: "0.25em", flexGrow: "1", width: "100%" }}>
                <Box sx={{ overflowY: "auto", display: "flex", flexDirection: "column", gap: "0.25em", padding: "0.25em", width: "30%", }}>
                    <Typography style={{ fontSize: "1.5rem", fontWeight: "bold" }}>Tags</Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "0.5em", }}>
                        {tagTypes?.map((tagType, i) => {
                            return <Typography key={i} style={{ paddingBlock: "0.1em", paddingInline: "0.5em", margin: "0", borderRadius: "0.5em" }} sx={{ backgroundColor: 'background.icon' }}>{tagType.name}</Typography>
                        })}
                    </Box>
                </Box>
                <Box sx={{ overflowY: "auto", display: "flex", flexDirection: "column", gap: "0.25em", padding: "0.25em", flexGrow: "1", width: "70%" }}>
                    <Typography style={{ fontSize: "1.5rem", fontWeight: "bold" }}>Overview</Typography>
                    <Box sx={{ display: "flex", flexDirection: "column", }}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={6}>
                                <Box sx={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
                                    <Typography style={{ fontSize: "0.9em", color: "GrayText", margin: "0", marginBottom: "0.5em" }}>Location</Typography>
                                    <Typography style={{ margin: "0" }}>{location?.name ?? ""}</Typography>
                                    <Typography style={{ margin: "0" }}>{getAddressString(location?.address)}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
                                    <Typography style={{ fontSize: "0.9em", color: "GrayText", margin: "0", marginBottom: "0.5em" }}>Customer</Typography>
                                    <Typography style={{ margin: "0" }}>{customer?.name ?? ""}</Typography>
                                    <Typography style={{ margin: "0" }}>{getAddressString(customer?.address)}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
                                    <Typography style={{ fontSize: "0.9em", color: "GrayText", margin: "0", marginBottom: "0.5em" }}>Customer Phone</Typography>
                                    {customerContacts.map((contact, i) => {
                                        return (contact.type === "Phone") ?
                                            <Box key={i} sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                <Tooltip title="Call" >
                                                    <IconButton sx={{ color: "#00a79d" }} aria-label="Call" component="span" onClick={() => {
                                                        popPhoneDialer(contact.value, franchise?.id, brand?.id,undefined, undefined, handleCreateOrUpdateCallLogs)
                                                    }}>
                                                        <CallIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Typography style={{ margin: "0" }}>{getPhoneNumberWithInputMask(contact.value)}</Typography>
                                            </Box>
                                            : <></>
                                    })}
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
                                    <Typography style={{ fontSize: "0.9em", color: "GrayText", margin: "0", marginBottom: "0.5em" }}>Customer Mobile Phone</Typography>
                                    {customerContacts.map((contact, i) => {
                                        return (contact.type === "MobilePhone") ?
                                            <Box key={i} sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                <Tooltip title="Call" >
                                                    <IconButton sx={{ color: "#00a79d" }} aria-label="Call" component="span" onClick={() => {
                                                        popPhoneDialer(contact.value, franchise?.id, brand?.id,undefined, undefined, handleCreateOrUpdateCallLogs)
                                                    }}>
                                                        <AodIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Typography style={{ margin: "0" }}>{getPhoneNumberWithInputMask(contact.value)}</Typography>
                                            </Box>
                                            : <></>
                                    })}
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
                                    <Typography style={{ fontSize: "0.9em", color: "GrayText", margin: "0", marginBottom: "0.5em" }}>Customer Email</Typography>
                                    {customerContacts.map((contact, i) => {
                                        return (contact.type === "Email") ? <Box key={i} sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            <Tooltip title="Email" >
                                                <IconButton sx={{ color: "#00a79d" }} aria-label="Email" component="span" onClick={() => {
                                                    sendAEmail(contact.value)
                                                }}>
                                                    <EmailIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Typography style={{ margin: "0" }}>{contact.value}</Typography>
                                        </Box> : <></>
                                    })}
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "column", overflow: "hidden", marginTop: "1em" }}>
                                    <Typography style={{ fontSize: "0.9em", color: "GrayText", margin: "0", marginBottom: "0.5em" }}>Customer Fax</Typography>
                                    {customerContacts.map((contact, i) => {
                                        return (contact.type === "Fax") ? <Box key={i} sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            <Tooltip title="Fax" >
                                                <FaxIcon sx={{ margin: "0.4em" }} />
                                            </Tooltip>
                                            <Typography style={{ margin: "0" }}>{getPhoneNumberWithInputMask(contact.value)}</Typography>
                                        </Box> : <></>
                                    })}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
            <Paper sx={{ width: "100%", borderRadius: "0.5em", border: "1px solid gray", marginTop: "0.5em", marginBottom: "2em", padding: "0.5em" }}>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
                    <Typography marginLeft="0.5em" component="h5" fontWeight="bold" >Add Tags</Typography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", padding: '10px', width: "100%" }}>
                    <Box sx={{ width: "60%" }}>
                        <Autocomplete
                            sx={{ width: "100%", }}
                            size="small"
                            disablePortal
                            multiple={true}
                            noOptionsText={franchise ? "No tag types for franchise" : "Please select a franchise"}
                            loading={isTagTypesLoading}
                            options={TagTypes}
                            getOptionLabel={(tagType) => tagType.name}
                            renderOption={(props, tagType) => <RenderInput {...props} key={tagType?.id} content={tagType?.name} />}
                            value={selectedTagTypes}
                            onChange={(_, selectedTagTypes) => {
                                setSelectedTagTypes(selectedTagTypes);
                            }}
                            renderInput={(params) => <CustomTextField {...params} label="Tag Types" />}
                        />
                    </Box>
                    <Box sx={{ display: "flex", width: "30%", marginLeft: "auto" }}>
                        <LoadingButton size={"medium"} variant="contained" disabled={isDisableAddTagButton()} loading={isAddTagButtonLoading} style={{ marginLeft: 'auto' }} className={isDisableAddTagButton() ? "btn-primary" : "btn-disabled"}
                            onClick={() => handleAddTags(franchise, setIsAddTagButtonLoading, showNotification, estimate)}
                        >
                            Add Tags
                        </LoadingButton>
                    </Box>
                </Box>
            </Paper>
            <Paper sx={{ width: "100%", borderRadius: "0.5em", border: "1px solid gray", marginTop: "0.5em", marginBottom: "2em", padding: "0.5em" }}>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
                    <Typography marginLeft="0.5em" component="h5" fontWeight="bold" >Notes</Typography>
                    <Tooltip enterDelay={1000} enterTouchDelay={1000} title={"Create new note"}>
                        <Button disabled={isAddNewNote} size="small" sx={{ color: "primary" }} variant="text" onClick={() => setIsAddNewNotes(true)}>{"\u002B New"}</Button>
                    </Tooltip>
                </Box>
                {isAddNewNote ? <AddNote franchiseId={franchise?.id} customerId={estimate?.customerId} onDone={onDoneAddNote} onCancel={() => setIsAddNewNotes(false)} /> : <></>}
                {isNotesLoadingError && !isAddNewNote ? <RefreshErrorView onRefresh={() => setToggleGetNotes(!toggleGetNotes)} /> : <></>}
                {isNotesLoading ? <LoadingView /> : isAddNewNote ? <></> : notes?.length === 0 ? <NoContentView message={"No notes to show"} /> : notes.map((n) => <NoteCard note={n} />)}
            </Paper>
        </Box>
    )
}
