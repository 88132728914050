import { Typography, Box, IconButton, TextField,Button } from "@mui/material"
import ReportIcon from "@mui/icons-material/Report";
import EditIcon from '@mui/icons-material/Edit';
import {CircularProgress} from "@mui/material";
import { useEffect, useState, useContext } from "react"
import { getWarningMessageFromSectionId, saveWarningMessageService } from "../../services/warningMessagesService";
import { CommonContext } from "../../contexts/CommonContextProvider";
import { NOTIFICATION_TYPES } from "../../constants/common";
import { UserContext } from "../../contexts/User";
import { userLevels } from "../../constants/common";
import LoadingButton from "../LoadingButton";

export default function WarningMessageWithSeparateButtons(props) {
	const { sectionId } = props

	const [message, setMessage] = useState("")
	const [isEditable, setIsEditable] = useState(false)
	const [isMessageLoading, setIsMessageLoading] = useState(true)
    const [editedMessage, setEditedMessage] = useState()
	const { me } = useContext(UserContext)
    const { showNotification } = useContext(CommonContext);

	useEffect(() => {
		(async () => {
			const warningMessage = await getWarningMessageFromSectionId(sectionId)
			setMessage(warningMessage?.message)
            setEditedMessage(warningMessage?.message)
			setIsMessageLoading(false)
		})()
	}, [sectionId])

	const saveWarningMessage = async () => {
		const body = {
			section_id: sectionId,
			message: editedMessage
		}

		await saveWarningMessageService(body).then((response) => {
			setIsEditable(false)
            setMessage(editedMessage)
			showNotification({ message: "Warning message updated successfully", type: NOTIFICATION_TYPES.SUCCESS })
		}).catch((error) => {
			showNotification({ message: error.message, type: NOTIFICATION_TYPES.ERROR })
		})

	}


	return (

		<Box>

			<Box style={{ display: 'flex', flexDirection: 'row', gap: '30px'}}>
				<Box sx={{ width: isEditable ?'100%':'600px' }} overflow="auto" padding="0.5em" borderRadius="0.5em" backgroundColor='form.input' alignContent="stretch" display="flex" flexDirection="column" marginBottom="1em">
					<Box display="flex" flexDirection="row" position="relative" justifyContent="space-between" alignItems="center" gap="0.5em" > <Box display="flex" flexDirection="row" alignItems="center" gap="0.5em" > <Typography variant="subtitle2">Message</Typography> <ReportIcon color="warning" style={{ height: "16px", width: "16px" }} /> </Box> </Box>
					{isMessageLoading ? <CircularProgress sx={{ alignSelf: 'center' }} size={30} color="inherit" /> : <>
						{isEditable ? (<Box display="flex" sx={{width:'650px'}}> <TextField variant="standard" multiline sx={{ flexGrow: 1 }} value={editedMessage ?? ""} onChange={(e) => setEditedMessage(e.target.value)} /> </Box>) : (<Box sx={{ overflow: "auto" }}><Typography sx={{ overflowWrap: "break-word", wordWrap: "break-word" }} fontWeight="100">{message}</Typography></Box>)}</>}
				</Box>

				{!isEditable && me?.level >= userLevels.SUPER_ADMIN && <IconButton color="primary" onClick={() => setIsEditable(true)} key={"edit_button"} >{<EditIcon style={{ height: "20px", width: "20px" }} />}</IconButton>}
			</Box>

			{isEditable && <Box
				sx={{
					width: "100%",
					marginTop: "0.5em",
					display: "flex",
					flexDirection: "row",
					justifyContent: "flex-end"
				}}
			>
				<Button
					size={"small"}
					variant="contained"
					className={"btn-secondary"}
					style={{ marginRight: "10px" }}
					onClick={() => setIsEditable(false)}
				>
					Cancel
				</Button>
				<Box  />
				<LoadingButton
					type="submit"
					size="small"
					variant="contained"
					onClick={() => saveWarningMessage()}
				>
					Update
				</LoadingButton>
			</Box>}

			

		</Box>



	)
}
