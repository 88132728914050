import { useState, useEffect } from "react";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { Autocomplete, Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import { CustomSelect, CustomTextField } from "./userCustomStyle";
import { handleSaveOrUpdateUser } from "../../../state-services/users";
import LoadingButton from "../../../components/LoadingButton";
import { getAllBrands } from "../../../services/brandService";
import { userLevels } from '../../../constants/common';
import { handleGetAgents } from "../../../state-services/eightByEight/agents";
import { USER_TYPES } from "../../../constants/users";
import { sortBrandsAlphabetically } from "../../../utils/miscellaneous";
import ImageBox from "../../../components/LogoBox/ImageBox";

const userTypes = [  // to fill dropdown
    { key: "User", value: 40, label: "User" },
    { key: "Supervisor", value: 60, label: "Supervisor" },
    { key: "Admin", value: 80, label: "Admin" },
    { key: "SuperAdmin", value: 120, label: "Super Admin" },
]
const SUPER_ADMIN_LEVEL = 120

export function UserModal(props) {
    const { onClose, open, data, showNotification, handleRefreshData, setUserUpdated, editClickCount } = props;
    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState("")
    const [level, setLevel] = useState("")
    const [currentLevel, setCurrentLevel] = useState(null)
    const [brands, setBrands] = useState([]);
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [allAgents, setAllAgents] = useState([])
    const [selectedAgent, setSelectedAgent] = useState(null)
    const [isAllAgentsLoading, setIsAllAgentsLoading] = useState(false)
    const [isAllAgentsLoadingError, setIsAllAgentsLoadingError] = useState(false)

    useEffect(() => {
        handleGetAgents(data?.eight_by_eight_agent_id, setAllAgents, setIsAllAgentsLoading, setIsAllAgentsLoadingError, setSelectedAgent)
    }, [])

    useEffect(() => {
        getBrands()
    }, []);

    useEffect(() => {
        setCurrentLevel(data?.level)
    }, [data])

    useEffect(() => {
        search8by8Agent()
    }, [editClickCount, isAllAgentsLoading])

    useEffect(() => {
        setSelectedBrands([])
        try {
            const userBrands = data?.user_brands
            let brandIds = []
            if (userBrands) {
                userBrands?.map((userBrand, i) => (
                    brandIds.push(userBrand.brand_id)
                ))
            }
            setSelectedBrands(brandIds)
        } catch (error) { }
    }, [open, data]);

    const getBrands = async () => {
        try {
            const brands = await getAllBrands()
            setBrands(sortBrandsAlphabetically(brands?.brands))
        } catch (error) { }
    }

    const handleClose = () => {
        setCurrentLevel(data?.level)
        setSelectedAgent(null)
        setErr("")
        setLevel("")
        return onClose();
    }

    const handleLevelChange = (e) => {
        if (e.target.value === userLevels.ADMIN || e.target.value === userLevels.Supervisor ) {
            setSelectedBrands([])
        }
        if (e.target.value === userLevels.ADMIN){
            setSelectedAgent(null)
        }
        setCurrentLevel(e.target.value)
        setLevel(e.target.value)
    }

    const brandOnChange = (e) => {
        let values = e.target.value
        setSelectedBrands(values)
    }

    const validate8by8Agent = (email) => {
        return selectedAgent?.email_address === email
    }

    const search8by8Agent = () => {
        if (data) {
            const selectedAgent = allAgents.find((a) => a?.agent_id === data?.eight_by_eight_agent_id)
            selectedAgent ? setSelectedAgent(selectedAgent) : setSelectedAgent(null)
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        let userBrands = []
        let user = {
            first_name: document.getElementById("first_name").value,
            last_name: document.getElementById("last_name").value,
            email: document.getElementById("email").value,
            level: level,
            eight_by_eight_agent_id: selectedAgent?.agent_id ? selectedAgent?.agent_id : ""
        };
        if (!validate8by8Agent(user.email) && selectedAgent) {
            setErr("Please select a valid 8x8 agent")
            setLoading(false);
        } else {
            if (!data) {
                userBrands = selectedBrands.map((brand, i) => {
                    return {
                        brand_id: brand,
                        status: 1
                    }
                })
                user.user_brands = userBrands
                handleSaveOrUpdateUser(null, user, showNotification, setErr, handleRefreshData, handleClose, setUserUpdated, setLoading)
            } else {
                userBrands = selectedBrands.map((brand, i) => {
                    return {
                        user_id: data.user_id,
                        brand_id: brand,
                        status: 1
                    }
                })
                user.user_brands = userBrands
                if (!user.level) {
                    user.level = data.level
                }
                handleSaveOrUpdateUser(data.user_id, user, showNotification, setErr, handleRefreshData, handleClose, setUserUpdated, setLoading)
            }
            setSelectedAgent(null)
        }
    };

    return (
        <Dialog open={open} maxWidth="lg" className="dialog-zindex">
            <div className={"dialog user-dialog"} style={{ width: "350px" }}>
                <IconButton
                    onClick={handleClose}
                    style={{ position: "absolute", right: "10px", top: "10px" }}
                    size={"small"}
                    aria-label="delete"
                >
                    <CloseIcon style={{ height: "15px", width: "15px" }} />
                </IconButton>
                <h3 className={"dialog-title"}>
                    {data ? "Update User" : "Create a New User"}
                </h3>
                <form onSubmit={onSubmit}>
                    <CustomTextField
                        required
                        type={"text"}
                        fullWidth
                        defaultValue={data?.first_name}
                        id="first_name"
                        label={"First Name"}
                        disabled={loading}
                        size={"small"}
                        margin="normal"
                        variant="outlined"
                    />
                    <CustomTextField
                        required
                        type={"text"}
                        fullWidth
                        defaultValue={data?.last_name}
                        id="last_name"
                        label={"Last Name"}
                        disabled={loading}
                        size={"small"}
                        margin="normal"
                        variant="outlined"
                    />
                    <CustomTextField
                        required
                        type={"email"}
                        fullWidth
                        defaultValue={data?.email}
                        onChange={(e) => setErr("")}
                        id="email"
                        label={"Email"}
                        size={"small"}
                        margin="normal"
                        variant="outlined"
                        disabled={data?.email || loading}
                    />
                    <CustomSelect required fullWidth disabled={loading} size={"small"} margin="normal">
                        <InputLabel>Level</InputLabel>
                        <Select
                            required
                            id="level"
                            label="Level"
                            onChange={handleLevelChange}
                            value={level !== '' ? level : (data ? data.level : '')}
                        >
                            {userTypes.map(type => (
                                <MenuItem style={{ fontSize: "14px" }} key={type.key} value={type.value} disabled={type.value === SUPER_ADMIN_LEVEL} >
                                    {type.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </CustomSelect>
                    {level === "" ? data?.level === userLevels.ADMIN || data?.level === userLevels.SUPER_ADMIN || data?.level === userLevels.Supervisor ?
                        <InputLabel className="success-label"> Assigned to all Brands </InputLabel> : <></>
                        : level === userLevels.ADMIN || level === userLevels.SUPER_ADMIN || level === userLevels.Supervisor ?
                            <InputLabel className="success-label"> Assigned to all Brands </InputLabel> : <></>}
                    <CustomSelect
                        id="brand"
                        fullWidth
                        size={"small"}
                        disabled = {loading}
                        margin="normal"
                    >
                        <InputLabel id="brand-label">Assigned Brands</InputLabel>
                        <Select
                            labelId="brand-label"
                            label="Assigned Brands"
                            id={"brandId"}
                            value={selectedBrands}
                            defaultValue={selectedBrands}
                            onChange={(e) => brandOnChange(e)}
                            disabled={loading ? true :level === "" ? data?.level === userLevels.ADMIN || data?.level === userLevels.SUPER_ADMIN || data?.level === userLevels.Supervisor ? true : false : level === userLevels.ADMIN || data?.level === userLevels.SUPER_ADMIN || level === userLevels.Supervisor ? true : false}
                            multiple
                        >
                            {brands.map((brand, i) => (
                                <MenuItem
                                    style={{
                                        fontSize: "14px",
                                        height: "50px",
                                        display: "flex",
                                    }}
                                    key={i}
                                    value={brand.id}
                                ><Checkbox checked={selectedBrands.indexOf(brand.id) > -1} />
                                    <span style={{ textAlign: "left" }}>
                                        {brand.brand_name}
                                    </span>
                                    <span style={{ marginLeft: "auto", order: "2" }}>
                                        <ImageBox alt={"brand-img"} src={brand.brand_img} size={'xsmall'} />
                                    </span>
                                </MenuItem>
                            ))}
                        </Select>
                    </CustomSelect>
                    <Autocomplete
                        sx={{ marginBlock: "1em" }}
                        size="small"
                        options={allAgents}
                        disabled={currentLevel > USER_TYPES?.supervisor?.level || loading}
                        loading={isAllAgentsLoading}
                        noOptionsText={isAllAgentsLoadingError ? "temporary failure" : "No Agents Found"}
                        getOptionLabel={(a) => `${a?.first_name.trim()} ${a?.last_name.trim()}`}
                        value={selectedAgent}
                        onChange={(e, a) => {
                            setErr("")
                            setSelectedAgent(a)
                        }}
                        renderInput={(props) => <CustomTextField {...props} onChange={() => setErr("")} label={"8x8 Agent"} />}
                    />
                    <small style={{ color: "red" }} >{err}</small>
                    <div style={{ display: "flex", justifyContent: "end", marginTop: "20px", }}>
                        <Button
                            size={"small"}
                            variant="contained"
                            disabled={loading}
                            onClick={handleClose}
                            className={"btn-secondary"}
                            style={{ marginRight: "10px" }}
                        >
                            Cancel
                        </Button>
                        <LoadingButton
                            type={"submit"}
                            size={"small"}
                            disabled={loading}
                            variant="contained"
                            loading={loading}
                            className={"btn-primary"}
                        >
                            Save
                        </LoadingButton>
                    </div>
                </form>
            </div>
        </Dialog >
    );
}
