import { Button, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

export default function LoadingButton(props) {
    const { loading, disabled, onClick } = props
    return <Button {...props} className={(disabled || loading) ? "btn-disable" : "btn-primary"} disabled={disabled || loading} onClick={onClick} >
        {loading ? <CircularProgress style={{ width: "20px", height: "20px" }} color="inherit" /> : <Typography sx={{ textTransform: "none" }} variant='button'>{props.children}</Typography>}
    </Button >
}

export function LoadingButtonForDelete(props) {
    const { loading, disabled, onClick } = props
    return <Button {...props} className={(disabled || loading) ? "btn-disable" : "btn-warning"} disabled={disabled || loading} onClick={onClick} >
        {loading ? <CircularProgress style={{ width: "20px", height: "20px" }} color="inherit" /> : <Typography sx={{ textTransform: "none" }} variant='button'>{props.children}</Typography>}
    </Button >
}