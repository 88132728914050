import React from 'react'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useState, useEffect } from 'react';
import { handleGetKeywords } from '../../../../../state-services/faq/faq'
import FaqRow from './FaqRow'
import EditFaqRow from './EditFaqRow'

export default function FaqTemplate({ faqs, isTemplateEmptyAtBegining ,setFaqs, isEditFrachise }) {

  const [keywords, setKeywords] = useState([])
  const [isKeywordsLoading, setIsKeywordsLoading] = useState(false)
  const [isKeywordsLoadingError, setIsKeywordsLoadingError] = useState(false)

  useEffect(() => {
    handleGetKeywords(setKeywords, setIsKeywordsLoading, setIsKeywordsLoadingError)
    return () => {
      setKeywords([])
    }
  }, [])

  return (
    <TableContainer component={Paper} sx={{ height: 445 }}>
      <Table aria-label="simple table" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell width="25%">Question</TableCell>
            <TableCell width="40%" align="left">Answer</TableCell>
            <TableCell width="25%" align="left">Keywords</TableCell>
            <TableCell width="15%" align="center">Actions</TableCell> 
          </TableRow>
        </TableHead>
        <TableBody>
          {(isEditFrachise && !isTemplateEmptyAtBegining ) ?
            faqs?.map((faq, index) => {
              return <EditFaqRow key={index} id={index} faq={faq} faqs={faqs} setFaqs={setFaqs} isKeywordsLoading={isKeywordsLoading} isKeywordsLoadingError={isKeywordsLoadingError} keywords={keywords} />
            })
            :
            faqs?.map((faq, index) => {
              return <FaqRow key={index} id={index} faq={faq} faqs={faqs} setFaqs={setFaqs} isKeywordsLoading={isKeywordsLoading} isKeywordsLoadingError={isKeywordsLoadingError} keywords={keywords} />
            })
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}
