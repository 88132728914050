import { Box, CircularProgress, Typography } from '@mui/material'
import React from 'react'
import ReactApexChart from 'react-apexcharts';
import ErrorView from '../../../../components/ErrorView';
import { ERROR_COMPONENTS } from '../../../../constants/common';

export default function RealTimeAgentStatus({ isLoading, logs }) {

    function calculateDataAndLabels() {
        const statusCounts = {};
        logs.forEach(item => {
            const status = item.Status;
            if (statusCounts[status]) {
                statusCounts[status]++;
            } else {
                statusCounts[status] = 1;
            }
        });
        let data = {
            series: [],
            labels: []
        }
        for (const [key, value] of Object.entries(statusCounts)) {
            data.series.push(value)
            data.labels.push(key)
        }
        return data
    }

    return (
        <Box sx={{ height: "600px", display: "flex", flexDirection: "column", overflow: 'auto' }}>
            <Typography variant="h6">Real Time Agent Status</Typography>
            {isLoading ?
                <Box sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <CircularProgress style={{ marginTop: "auto", marginBottom: "auto" }} color="inherit" />
                </Box>
                :
                logs?.length === 0 ?
                    <Box sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <ErrorView type={ERROR_COMPONENTS.NoDataFound.type} />
                    </Box>
                    :
                    <Box sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <ReactApexChart options={{
                            chart: {
                                width: 400,
                                height: 400,
                                type: 'donut',
                                dropShadow: {
                                    enabled: true,
                                    color: '#111',
                                    top: -1,
                                    left: 3,
                                    blur: 3,
                                    opacity: 0.2
                                }
                            },
                            stroke: {
                                width: 0,
                            },
                            dataLabels: {
                                enabled: true,
                                dropShadow: {
                                    blur: 3,
                                    opacity: 0.8
                                },
                            },
                            plotOptions: {
                                pie: {
                                    donut: {
                                        ignoreZeroSeries: false,
                                        labels: {
                                            show: true,
                                            total: {
                                                showAlways: true,
                                                show: true,
                                                color: '#7B7A75',
                                            },
                                            value: {
                                                color: '#7B7A75',
                                            }
                                        }
                                    }
                                }
                            },
                            labels: calculateDataAndLabels().labels,
                            legend: {
                                show: true,
                                position: 'right',
                                offsetY: 0,
                                height: 300,

                                formatter: function (val, _) {
                                    if (val.length > 12)
                                        return val.substring(0, 12) + "..."
                                    else
                                        return val
                                }
                            },
                        }}
                            series={calculateDataAndLabels().series} type="donut" width={500}
                        />
                    </Box>
            }
        </Box>
    )
}
