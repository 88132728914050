import React, { useContext, useEffect, useState } from "react";
import { Avatar, Menu, Button, IconButton, Tooltip, Box, useTheme } from "@mui/material";
import BrandImg from "../assets/img/brand.png";
import { getMe, logout } from "../services/authService";
import HelpImage from "../assets/img/question.png";
import NotificationModal from './notificationModal/NotificationModal';
import SettingModal from "./settingModal/SettingsModal";
import TuneTwoToneIcon from '@mui/icons-material/TuneTwoTone';
import { ScreenCapturesocketContext } from "../contexts/screenCaptureContextProvider";

// service
import { UserContext } from "../contexts/User";
import { LOCAL_STORAGE_KEYS, SESSION_STORAGE_KEYS } from "../constants/browserStorage";
import { setSessionStorage } from "../utils/browserStorage";
import { CommonContext } from "../contexts/CommonContextProvider";
import { handleGetUserBrands } from "../state-services-dublicate/brandServices";
import { Link, useHistory } from "react-router-dom";
import { handleGetAllFranchisesIncludeRemoved } from "../state-services-dublicate/franchiseServices";
import { handleGetAllUsers, handleGetAllUsersIncludeRemovedOnes } from "../state-services-dublicate/userServices";
import { ColorModeContext } from "../contexts/ThemeContextProvider";
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { handleGetUserPreferenceOfLoggedUser, handleSaveOrUpdateDarkMode } from "../state-services/users";
import { handleGetKeywords } from "../state-services/faq/faq";
import { handleGetTimezones } from "../state-services/schedule/schedule";
import { handleGetGeneralTenantConfiguration } from "../state-services/configuration/configuration";

export default function TopBar(props) {

  const { setBrands, setAllBrands, setIsBrandsLoading, setFranchises, setAllUsers, setAllUsersIncludeRemovedOnes, setKeywords, setIsKeywordsLoading, setIsKeywordsError, showNotification, setTimezones, setIsTimezonesLoading, setIsTimezonesError, setGeneralTenantConfiguration } = useContext(CommonContext)
  const { me, setMe, userPreferences, setUserPreferences } = useContext(UserContext)
  const [anchorEl, setAnchorEl] = useState(null);
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true)
  const [openTopBarModel, setOpenTopBarModel] = useState(false)
  const [screenCapturingInfo, setScreenCapturingInfo] = useContext(ScreenCapturesocketContext) // eslint-disable-line
  const history = useHistory()
  const theme = useTheme()
  const colorMode = useContext(ColorModeContext)

  useEffect(() => {
    if (me?.user_id) handleGetUserBrands(false, me, setBrands, setIsBrandsLoading, showNotification, setAllBrands)
    if (me?.user_id) handleGetAllFranchisesIncludeRemoved(setFranchises)
  }, [me?.user_id]);

  useEffect(() => {
    handleGetTimezones(setTimezones, setIsTimezonesLoading, setIsTimezonesError, showNotification);
  }, [setTimezones, setIsTimezonesLoading, setIsTimezonesError, showNotification])

  useEffect(() => {
    handleGetGeneralTenantConfiguration(setGeneralTenantConfiguration, () => { }, () => { })
  }, [setGeneralTenantConfiguration])

  const open = Boolean(anchorEl);

  const handleOpenTopBarModel = () => setOpenTopBarModel(true);
  const handleCloseTopBarModel = () => setOpenTopBarModel(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getMe().then((user) => {
      document.profile = { //To Support page
        email: user.email,
        firstName: user.first_name,
        lastName: user.last_name,
      }
      localStorage.setItem("user_id", user.user_id)
      localStorage.setItem("user_level", user.level)
      setSessionStorage(SESSION_STORAGE_KEYS.User_img, user.img)
      localStorage.setItem("user_name", user.first_name + " " + user.last_name)
      setMe(user)
      if (user.is_enabled === 0)
        history.replace("/")
      setUser(user);
      setLoading(false)
    }).catch((err) => {
      setLoading(false)
    }).finally(() => {
      setLoading(false)
    });
    handleGetAllUsers(setAllUsers, () => { }, () => { })
    handleGetAllUsersIncludeRemovedOnes(setAllUsersIncludeRemovedOnes)
    handleGetKeywords(setKeywords, setIsKeywordsLoading, setIsKeywordsError)
  }, []);

  useEffect(() => {
    handleGetUserPreferenceOfLoggedUser(setUserPreferences)
  }, [setUserPreferences]);

  const logOutBtnOnClick = () => {
    logout()
      .then(() => {
        setMe({})
        setUserPreferences({})
        history.replace("/")
      })
      .catch((err) => {
      });
  };

  function getDialerUrl() {
    const dialerMedia = localStorage.getItem(LOCAL_STORAGE_KEYS.dialerMedia) ?? ""
    const dialerChannelName = localStorage.getItem(LOCAL_STORAGE_KEYS.dialerChannelName) ?? ""
    const dialerChannelId = localStorage.getItem(LOCAL_STORAGE_KEYS.dialerChannelId) ?? ""
    const dialerCallerId = localStorage.getItem(LOCAL_STORAGE_KEYS.dialerCallerId) ?? ""
    const dialerZipCode = localStorage.getItem(LOCAL_STORAGE_KEYS.dialerZipCode) ?? ""
    const interactionGuid = sessionStorage.getItem(SESSION_STORAGE_KEYS.interactionGuid) ?? ""
    const subject = localStorage.getItem(LOCAL_STORAGE_KEYS.dialerSubject) ?? ""
    if (dialerMedia) {
      return `/dialer?media=${dialerMedia}&zip_code=${dialerZipCode}&caller_id=${dialerCallerId}&channel_id=${dialerChannelId}&channel_name=${dialerChannelName}&subject=${subject}&interaction_guid=${interactionGuid}`
    } else {
      return `/dialer?media=MANUAL`
    }
  }

  const handleDarkModeIconClick = () => {
    const darkMode = theme.palette.mode === 'dark' ? 0 : 1
    handleSaveOrUpdateDarkMode(
      { ...userPreferences, dark_mode_enabled: darkMode }
    );
    setUserPreferences({ ...userPreferences, dark_mode_enabled: darkMode })
    colorMode.toggleColorMode()
  }

  return (
    <div className={"top-bar"}>
      <img src={BrandImg} alt={"brand-img"} className={"brand-img"} />
      <div className={"top-bar-right"}>
        {!loading ?
          <Box sx={{ display: "flex", flexDirection: "row", gap: "0.5em", alignItems: "center" }}>
            <IconButton onClick={handleDarkModeIconClick} color="inherit" >
              <Tooltip title={theme.palette.mode === 'light' ? "Dark Mode" : "Light Mode"}>
                {theme.palette.mode === 'dark' ? <Brightness7Icon sx={{ color: "#00A79D" }} /> : <Brightness4Icon sx={{ color: "#00A79D" }} />}
              </Tooltip>
            </IconButton>
            <IconButton variant="contained" size="small" sx={{ textTransform: "none" }}><Link data-test="topbar_manual_job" to={getDialerUrl()}><Tooltip title="Create a job"><TuneTwoToneIcon color="primary" /></Tooltip></Link></IconButton>
            <NotificationModal />
            <img title="Middleware Guider" alt="hetp-img" src={HelpImage} style={{ height: 25, width: 25, marginRight: 10, cursor: 'pointer' }} onClick={handleOpenTopBarModel} />
            <Avatar
              style={{
                height: "30px",
                width: "30px",
                cursor: "pointer",
                textTransform: "uppercase",
                fontSize: "15px",
              }}
              id="basic-button"
              aria-controls="basic-menu"
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              src={user?.img}
            >
              {typeof user.firstName != 'undefined' ? (user?.first_name?.charAt(0) + user?.last_name?.charAt(0)) : ""}
            </Avatar>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              style={{ borderRadius: "10px", padding: "0" }}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <div className={"profile-box"}>
                <Avatar
                  style={{
                    height: "60px",
                    width: "60px",
                    textTransform: "uppercase",
                  }}
                  src={user?.img}
                >
                  {typeof user.firstName != 'undefined' ? (user?.first_name?.charAt(0) + user?.last_name?.charAt(0)) : ""}
                </Avatar>

                <p>{user?.first_name + " " + user?.last_name}</p>
                <small>{user?.email}</small>

                <Button
                  onClick={logOutBtnOnClick}
                  style={{ marginTop: "15px" }}
                  className={"btn-primary"}
                  size={"small"}
                >
                  Logout
                </Button>
              </div>
            </Menu>
          </Box>
          : <></>}
      </div>
      <SettingModal open={openTopBarModel} handleClose={handleCloseTopBarModel} />
    </div>
  );
}
