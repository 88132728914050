import { Box } from "@mui/material"

export default function ImageBox(props) {
    const { alt, src, size = 'small' } = props
    let width = '78px'
    let imageHeight = '25px'
    let boxHeight = '29px'
    let color = props.src ? 'white' : ''

    if (size === 'xsmall'){
        width = '60px'
        imageHeight = '20px'
        boxHeight = '24px'
    } else if (size === 'small') {
        width = '78px'
        imageHeight = '25px'
        boxHeight = '29px'
    } else if (size === 'medium') {
        width = '98px'
        imageHeight = '30px'
        boxHeight = '34px'
    } else if (size === 'large') {
        width = 'auto'
        imageHeight = '48px'
        boxHeight = '52px'   
    } else if (size === 'xlarge') {
        width = '196px'
        imageHeight = '64px'
        boxHeight = '68px'  
    }

    return (
        <>
            <Box sx={{padding: '2px', width: width, height: boxHeight, backgroundColor: color, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img src={src} alt={alt} height={imageHeight} style={{ margin: 'auto' }}/>
            </Box>
        </>
    )
}