import React from 'react'
import { useState, useEffect } from 'react';
import { Box, TableCell, TableRow, Chip } from '@mui/material'
import CustomTextField from '../../../../components/CustomTextField';
import EditBrandTemplateFaqDrawer from './EditBrandTemplateFaqDrawer';

export default function EditBrandTemplateFaqRow({ key, id, faqTemplateErros, setFaqTemplateErros, isAddNewQuestion, questionToEdit, setQuestionToEdit, faq, faqs, setFaqs, isKeywordsLoading, keywords }) {

    const [isOpenDrawer, setIsOpenDrawer] = useState(false)

    useEffect(() => {
        if (questionToEdit?.id === id) {
            setIsOpenDrawer(true)
        }
        return () => {
            setIsOpenDrawer(false)
        }
    }, [questionToEdit?.id])

    return <>
        <TableRow key={id} sx={{ padding: "10px" }} >
            <TableCell colSpan={1} >
                <Box sx={{ minHeight: "4em", width: "auto" }} >
                    <CustomTextField
                        sx={{ width: "100%" }}
                        inputProps={{
                            "data-test": "faq_question"
                        }}
                        disabled={true}
                        value={id === questionToEdit?.id ? questionToEdit?.question : faq?.question ?? null}
                        multiline
                        maxRows={1}
                        label="Question"
                        margin="normal"
                        size='small'
                        variant="outlined"
                    />
                </Box>
            </TableCell>
            <TableCell colSpan={1} >
                <Box sx={{ minHeight: "4em", width: "auto" }} >
                    <CustomTextField
                        sx={{ width: "100%" }}
                        inputProps={{
                            "data-test": "faq_answer"
                        }}
                        disabled={true}
                        value={id === questionToEdit?.id ? questionToEdit?.answer : faq?.answer}
                        multiline
                        maxRows={1}
                        label="Answer"
                        margin='normal'
                        size='small'
                        variant="outlined"
                    />
                </Box>
            </TableCell>
            <TableCell colSpan={1}>
                <Box sx={{ height: "2.9em", marginTop: "0.6em", borderRadius:"0.3em" ,width: "auto", border: "1px solid #ccc", overflow: "auto" }} >
                    {
                        id === questionToEdit?.id ?
                            questionToEdit?.keywords?.map?.((keyword) => {
                                return <Chip
                                    sx={{ margin: "0.4em" }}
                                    size="small"
                                    disabled={questionToEdit?.id !== id}
                                    label={keyword}
                                />
                            })
                            :
                            faq?.keywords?.map?.((keyword) => {
                                return <Chip
                                    sx={{ margin: "0.4em" }}
                                    size="small"
                                    disabled={questionToEdit?.id !== id}
                                    label={keyword}
                                />
                            })
                    }
                </Box>
            </TableCell>
        </TableRow >
        <EditBrandTemplateFaqDrawer key={id} id={id} faqTemplateErros={faqTemplateErros} setFaqTemplateErros={setFaqTemplateErros} isAddNewQuestion={isAddNewQuestion} questionToEdit={questionToEdit} setQuestionToEdit={setQuestionToEdit} faq={questionToEdit} faqs={faqs} setFaqs={setFaqs} isKeywordsLoading={isKeywordsLoading} keywords={keywords} drawerOpen={isOpenDrawer} onClose={() => { setIsOpenDrawer(false) }} />
    </>

}
