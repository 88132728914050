import { Box, Typography } from "@mui/material";

export default function ReportCard(props) {
    const { title, image, shouldWrap = true, isPhoneStatistics = false, isAllStatistics = false, isCrm = false } = props
    return <Box sx={{ flexGrow: 1, padding: !isCrm ? "1em" : "", backgroundColor: 'reports.main', borderRadius: "0.5em", }} >
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
            {!isCrm ? image ? <img alt={"Report"} style={{ height: "20px", marginLeft: "auto" }} src={image} /> : <></> : <></>}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: isAllStatistics ? undefined : isPhoneStatistics ? "7em" : "3em" }}>
            {!isCrm &&
                <Typography variant="subtitle1" >{title}</Typography>
            }
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", flexWrap: shouldWrap ? "wrap" : undefined, gap: isPhoneStatistics ? "4em" : "1em" }}>
                {props.children}
            </Box>
        </Box>
    </Box>
}