import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { compareTwoRFCDateStringsAssendingOrder, compareTwoRFCDateStringsDecendingOrder, compareTwoStringsInAssendingOrder, compareTwoStringsInDecendingOrder } from '../../../../../utils/compareFunctions';
import { NotSuccessIconError, SuccessIcon } from '../../../../../icons/common';
import { getPhoneNumberWithInputMask } from '../../../../../utils/inputMasks';
import { Typography } from '@mui/material';
import { CommonContext } from '../../../../../contexts/CommonContextProvider';
import { getTenantTimeZoneTimeString } from '../../../../../utils/time/time';

const headCells = {
    index: {
        id: 'index',
        numeric: false,
        shouldSort: false,
        alignment: "left",
        label: '#',
    },
    followUpDate: {
        id: 'follow-up_date',
        numeric: false,
        shouldSort: true,
        alignment: "left",
        label: 'Follow-Up Date',
    },
    isSucceded: {
        id: 'isSucceded',
        numeric: false,
        shouldSort: false,
        alignment: "center",
        label: 'Status',
    },
    callerId: {
        id: 'caller_id',
        numeric: false,
        shouldSort: false,
        alignment: "left",
        label: 'Phone'
    },
    callDuration: {
        id: 'call_duration',
        numeric: false,
        shouldSort: true,
        alignment: "left",
        label: 'Call Duration',
    },
};

function FollowUpHistoryTableHead(props) {
    const { order, orderBy, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {Object.values(headCells).map((h) => (
                    <TableCell
                        key={h.id}
                        align={h.alignment}
                        padding={h.id === headCells.index.id ? "1em" : "none"}
                        sortDirection={orderBy === h.id ? order : false}>
                        {h.shouldSort ? <TableSortLabel
                            active={orderBy === h.id}
                            direction={orderBy === h.id ? order : 'asc'}
                            onClick={createSortHandler(h.id)}
                        >
                            {h.label}
                            {orderBy === h.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                            : h.label
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

FollowUpHistoryTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export default function FollowUpHistory(props) {
    const { followUps = [] } = props

    const { generalTenantConfiguration, timezones } = React.useContext(CommonContext)
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState();

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    function compareFunction(a, b) {
        let result;
        if (orderBy === headCells.callDuration.id) {
            result = order === 'desc' ? compareTwoStringsInDecendingOrder(a.res_call_duration, b.res_call_duration) : compareTwoStringsInAssendingOrder(a.res_call_duration, b.res_call_duration)
        } else {
            result = order === 'desc' ? compareTwoRFCDateStringsDecendingOrder(a.follow_up_date, b.follow_up_date) : compareTwoRFCDateStringsAssendingOrder(a.follow_up_date, b.follow_up_date)
        }
        return result
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Typography style={{ fontSize: "1em", fontWeight: "bold" }}>8x8 Automatic Follow-up Calls</Typography>
            <Paper sx={{ width: '100%', mb: 2, padding: "1em", background: 'none', boxShadow: '1px 3px 2px -2px rgba(0,0,0,0.2), 1px 2px 2px 1px rgba(0,0,0,0.14), 1px 2px 4px 1px rgba(0,0,0,0.12)' }}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={'small'}
                    >
                        <FollowUpHistoryTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {followUps?.length === 0 ? <TableCell component="th" align={"center"} colSpan={6} scope="row">No Follow-Ups to show</TableCell>
                                : followUps.sort(compareFunction).map((f, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow hover tabIndex={-1} key={f.name}>
                                            <TableCell component="th" align={"left"} padding={"1em"} id={labelId} scope="row">{index + 1}</TableCell>
                                            <TableCell component="th" align={"left"} padding={'none'} id={labelId} scope="row">{getTenantTimeZoneTimeString(f.follow_up_date, generalTenantConfiguration?.time_zone, timezones)}</TableCell>
                                            <TableCell align="center" padding={'none'} >{f.is_followup_successful === 1 ? <SuccessIcon /> : <NotSuccessIconError />}</TableCell>
                                            <TableCell align="left" padding={'none'} >
                                                {getPhoneNumberWithInputMask(f.req_phone)}
                                            </TableCell>
                                            <TableCell align="left" padding={'none'} >{f.res_call_duration}</TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );
}