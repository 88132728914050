
import Button from "@mui/material/Button";
import { USER_STATUS } from "../../../constants/common";
import { Item } from "./userCustomStyle";
import { handleUserStatusChange } from "../../../state-services/users";

export function UserActiveModal(props) {
    const { onClose, open, data, status, showNotification, handleRefreshData } = props;

    const handleClose = () => {
        return onClose()
    }

    const handleChange = () => {
        handleUserStatusChange(status, data.user_id, showNotification, handleRefreshData, handleClose)
    }

    return (
        <Item onClose={handleClose} open={open}>
            <div className={"dialog"}>
                {status === USER_STATUS.enabled
                    ?
                    <h3 className={"dialog-title"}>Are you sure you want to activate the user ?</h3>
                    :
                    <h3 className={"dialog-title"}>Are you sure you want to deactivate the user ? </h3>
                }
                <p style={{ marginTop: "-10px", marginBottom: "15px" }}>{data?.first_name} {data?.last_name} : {data?.email}</p>
                <div style={{ display: "flex", justifyContent: "end" }}>
                    <Button
                        size={"small"}
                        variant="contained"
                        onClick={handleClose}
                        className={"btn-secondary"}
                        style={{ marginRight: "10px" }}
                    >
                        No
                    </Button>
                    <Button
                        size={"small"}
                        variant="contained"
                        onClick={handleChange}
                        className={"btn-primary"}
                    >
                        Yes
                    </Button>
                </div>
            </div>
        </Item>
    )
}
