import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";

export const CustomTextField = styled(TextField)(({theme})=>({
    "& label.Mui-focused": {
        color: "#00a79d",
        fontWeight: "500",
    },
    "& label": {
        fontSize: "15px",
        fontWeight: "300",
        color: theme.palette.fieldLabelColor,
    },
    "& .MuiOutlinedInput-root": {
        "&:hover fieldset": {
            borderColor: "#00a79d",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#00a79d",
        },
    },
}));

export const CustomSelect = styled(FormControl)(({theme})=>({
    "& label.Mui-focused": {
        color: "#00a79d",
        fontWeight: "500",
    },
    "& label": {
        fontSize: "15px",
        fontWeight: "300",
        color: theme.palette.fieldLabelColor,
        "&.Mui-required::after": {
            content: '"*"',
            color: "red",
            paddingLeft: "5px",
        },
        "& .MuiFormLabel-asterisk": {
            display: "none"
        }
    },
    "& .MuiOutlinedInput-root": {
        "&:hover fieldset": {
            borderColor: "#00a79d",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#00a79d",
        },
    },
}));
