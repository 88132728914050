import { TIME_ZONES } from "../constants/common";
import { CUSTOMER_TYPES } from "../pages/dashboard/jobs/vonigo/components/CustomerDetailsSection";
import { isValidEmail, isValidZipCode, numbersOnlyRegex } from "./validators";

export function getIsNewCustomerSectionComplete(newCustomer) {
    switch (newCustomer?.typeOptionId) {
        case CUSTOMER_TYPES.RESIDENTIAL.optionId:
            { return true } { break; }
        case CUSTOMER_TYPES.COMMERCIAL.optionId:
            if (newCustomer?.name) { return true } else { break; }
        default: return false
    }
}

export function getIsLocationComplete(newLocation, brand, franchise, selectedCustomer) {
    const isZipValid = isValidZipCode(newLocation?.address?.zip, newLocation?.address?.country) && newLocation?.address?.zip?.length > 3
    const isCan = newLocation?.name && getIsAddressSufficient(brand, franchise, selectedCustomer, newLocation) && isZipValid;
    return isCan;
}

export function getIsLocationCompleteWithServicebleZipCode(newLocation, brand, franchise, selectedCustomer, isServiceable) {
    return getIsLocationComplete(newLocation, brand, franchise, selectedCustomer) && isServiceable
}

export function getIsAddressSufficient(brand, franchise, selectedCustomer, location) {
    const locationRequest = getLocationRequest(brand, franchise, selectedCustomer, location)
    return locationRequest?.baseUrl && locationRequest?.franchiseId && locationRequest?.countryOptionId && locationRequest?.title && locationRequest?.street && locationRequest?.city && locationRequest?.zip
}

export function isContactValid(contact, customerType) {
    let ext1 = ""
    let ext2 = ""
    let ext3 = ""
    let phone1 = ""
    let phone2 = ""
    let phone3 = ""
    if (contact?.ext1) {
        ext1 = contact?.ext1
    }
    if (contact?.ext2) {
        ext2 = contact?.ext2
    }
    if (contact?.ext3) {
        ext3 = contact?.ext3
    }
    if (contact?.phone1?.length >= 10) {
        phone1 = `${contact?.phone1}${ext1}`
    } else {
        phone1 = contact?.phone1
    }
    if (contact?.phone2?.length >= 10) {
        phone2 = `${contact?.phone2}${ext2}`
    } else {
        phone2 = contact?.phone2
    }
    if (contact?.phone3?.length >= 10) {
        phone3 = `${contact?.phone3}${ext3}`
    } else {
        phone3 = contact?.phone3
    }
    const isValidPhoneNumber = (phoneNumber = "") => {
        return (numbersOnlyRegex.test(phoneNumber) && phoneNumber?.length >= 10 && phoneNumber?.length <= 20)
    }
    const isRequiredFieldFilled = contact?.firstName && contact?.lastName && isValidEmail(contact?.email) && isValidPhoneNumber(`${contact?.phone1}${ext1}`)
    let isOptionalFieldsFiled;
    switch (customerType) {
        case CUSTOMER_TYPES.RESIDENTIAL.name: isOptionalFieldsFiled = (!contact?.phone2 || isValidPhoneNumber(phone2)) && (!contact?.phone3 || isValidPhoneNumber(phone3)) && (!(contact?.phone1 && contact?.phone2 && phone1 === phone2) && !(contact?.phone2 && contact?.phone3 && phone2 === phone3) && !(contact?.phone1 && contact?.phone3 && phone1 === phone3)); break;
        case CUSTOMER_TYPES.COMMERCIAL.name: isOptionalFieldsFiled = (!contact?.phone2 || isValidPhoneNumber(phone2)) && (phone1 !== phone2); break;
        default: isOptionalFieldsFiled = true;
    }
    return isRequiredFieldFilled && isOptionalFieldsFiled
}

export function getIsWorkOrderSectionComplete(workOrder, isCreateMultipleWorkOrders) {
    return (isCreateMultipleWorkOrders ? workOrder?.lockId : workOrder?.duration && workOrder?.summary && workOrder?.lockId)
}

export function getIsJobComplete(job) {
    return job?.summary
}

export function getLocationRequest(brand, franchise, selectedCustomer, location) {
    return {
        baseUrl: brand?.redirect_url,
        franchiseId: franchise?.franchise_id,
        clientId: parseInt(selectedCustomer?.objectID, 10),
        title: location?.name,
        street: location?.address?.street,
        city: location?.address?.city,
        zip: location?.address?.zip,
        countryOptionId: location?.countryOptionId,
        stateOptionId: location?.stateOptionId,
        provice: location?.address?.state,
        state: location?.address?.state,
        location: location
    }
}

export function getTimeZoneName(zoneId) {
    let zoneName = TIME_ZONES.find(zone => zone.zone_id === zoneId)
    return zoneName?.zone
}