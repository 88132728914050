import { CUSTOMER_TYPES } from "../../pages/dashboard/jobs/vonigo/components/CustomerDetailsSection";
import { numbersOnlyRegex } from "../validators";

export function getContactPhoneErrors(currentPhone, otherPhone1, otherPhone2, customerType, shouldCheckAlreadyExist) {
    let isValidContact = isValidPhoneNumber(currentPhone) || !currentPhone;
    let errorText;
    if (!isValidContact) {
        errorText = "Invalid Phone Number"
    }
    let isExistingContact = false;
    switch (customerType) {
        case CUSTOMER_TYPES.RESIDENTIAL.name: isExistingContact = ((otherPhone1 && currentPhone === otherPhone1) || (otherPhone2 && currentPhone === otherPhone2)); break;
        case CUSTOMER_TYPES.COMMERCIAL.name: isExistingContact = (otherPhone1 && currentPhone === otherPhone1); break;
        default: isExistingContact = false; break;
    }
    isExistingContact = isExistingContact && shouldCheckAlreadyExist
    if (isExistingContact) {
        errorText = "Already exists"
    }
    isValidContact = isValidContact && !isExistingContact
    return [isValidContact, errorText]
}

const isValidPhoneNumber = (phoneNumber = "") => {
    if (phoneNumber == "undefined" || phoneNumber == "null") {
        return true
    } else {
        return (numbersOnlyRegex.test(phoneNumber) && phoneNumber?.length >= 10 && phoneNumber?.length <= 20);
    }
}

export function getContactPhoneErrorsForOptionalPhones(customerType, phone1, phone2, phone3) {
    if (phone1 == "null" || phone2 =="undefined") {
        const [isValidContact2, errorText2] = getContactPhoneErrors(phone2, phone1, phone3, customerType, false);
        const [isValidContact3, errorText3] = getContactPhoneErrors(phone3, phone1, phone2, customerType, false);
        return [isValidContact2, errorText2, isValidContact3, errorText3]
    } else {
        const [isValidContact2, errorText2] = getContactPhoneErrors(phone2, phone1, phone3, customerType, true);
        const [isValidContact3, errorText3] = getContactPhoneErrors(phone3, phone1, phone2, customerType, true);
        return [isValidContact2, errorText2, isValidContact3, errorText3]
    }
}