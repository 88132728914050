import { CircularProgress } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useRef } from 'react' 
import "../styles/scoreDashboardStyles.css"
import AgentLogsCard from './agentLogsCard'
import ErrorView from '../../../../components/ErrorView';
import moment from 'moment'
import { ERROR_COMPONENTS } from '../../../../constants/common'

const debounce = (func, delay) => {
    let debounceTimer;
    return function(...args) {
        clearTimeout(debounceTimer); 
        debounceTimer = setTimeout(() => {
            func(...args); 
        }, delay);
    };
};

export default function AgentLogsListView({ page, setPage, agentState, date, agent, totalLogs, logs, isLogsFromRealTimeDb ,isAgentLogsLoading, isLoading }) {

    const myDivRef = useRef(null);

    const handleScroll = () => { 
            const myDiv = myDivRef.current;
            const threshold = myDiv.clientHeight * (5 / 100);
            if (myDiv.scrollTop + myDiv.clientHeight >= myDiv.scrollHeight - threshold) {
                if (!isLoading.current && (moment(new Date(date)).format("YYYY MM DD") !== moment(new Date()).format("YYYY MM DD"))) {
                    isLoading.current = true;
                    setPage(prevPage => prevPage + 1);
                }
            }
    };

    const debounceHandleScroll = debounce(handleScroll, 200);

    useEffect(() => {
        return () => {
            setPage(1);
        }
    }, [])

    const handleAgentLogs = (logs) => {
        let formattedLogs = []
        if (isLogsFromRealTimeDb && logs.length > 1) {
            logs.sort((a, b) => {
                return new Date(b.StatusTime) - new Date(a.StatusTime);
            })
        }
        if (logs.length !== 0) {
            logs.forEach((log, index) => {
                if (logs[index - 1] && isLogsFromRealTimeDb) {
                    log.EndTime = logs[index - 1].StatusTime  //set end time for logs from real time db
                } else if (!isLogsFromRealTimeDb) {
                    log.EndTime = log.end_time
                }
                let formattedLog = {
                    StartTime: log?.StatusTime ? log?.StatusTime : log?.status_time,
                    EndTime: log?.EndTime,
                    Status: log?.Status ? log?.Status : log?.status
                }
                if (agentState.length !== 0) {
                    if (agentState.includes(formattedLog.Status)) {
                        formattedLogs.push(formattedLog)
                    }
                } else {
                    formattedLogs.push(formattedLog)
                }
            })
        }
        return formattedLogs
    }

    return (
        <>
            <div className="bg-shadow bg-white agent-logs-lower-section">
                <div className='agent-log-title-container'>
                    <h2 style={{ marginTop: "0px" }}>Agent Logs</h2>
                    <div >
                        <small><strong>{totalLogs} Logs</strong> </small>
                    </div>
                </div>
                {agent ?
                    <div ref={myDivRef} onScroll={debounceHandleScroll} style={{ marginTop: "65px", overflowY: "auto" }}>
                        {isAgentLogsLoading ?
                            <div style={{ margin: "auto" }}>
                                <CircularProgress color="inherit" />
                            </div>
                            : handleAgentLogs(logs).length === 0 ?
                                <Box sx={{ width: "600px", height: "600px", margin: "auto" }} >
                                    <ErrorView type={ERROR_COMPONENTS.NOT_FOUND} title='No Data Available' />
                                </Box>
                                :
                                <Box style={{ height: "100%" }}>
                                    {
                                        handleAgentLogs(logs)?.map((log) => {
                                            return (
                                                <AgentLogsCard isLogsFromRealTimeDb={isLogsFromRealTimeDb} log={log}></AgentLogsCard>
                                            )
                                        })
                                    }
                                </Box>
                        }
                    </div>
                    :
                    <div style={{ margin: "auto" }}>
                        <h3>Please Select An Agent</h3>
                    </div>
                }
            </div>
        </>
    )
}
