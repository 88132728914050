import { FetchClient } from "../utils/fetchClient";

export const getAllBrands = async (page, size) => {
    return FetchClient(`/data/brands?page=${page}&size=${size}`).GET({})
}

export const searchBrands = async (key, page, size) => {
    return FetchClient(`/data/brands?key=${key}&page=${page}&size=${size}`).GET({})
}

export const getBrandById = async (brandId = "") => {
    return FetchClient(`/data/brands?brand_id=${brandId}`).GET({})
}

export const createBrand = async (body) => {
    return FetchClient(`/data/brands`).POST({ payload: JSON.stringify(body) })
}

export const updateBrandById = async (id, body) => {
    return FetchClient(`/data/brands?id=${id}`).PUT({ payload: JSON.stringify(body) })
}

export const getBrandEmailsById = async (brandId = 0) => {
    return FetchClient(`/data/brands/emails?brand_id=${brandId}`).GET({})
}

export const getBrandOwnersById = async (brandId = 0) => {
    return FetchClient(`/data/brands/owners?brand_id=${brandId}`).GET({})
}

export async function getBrandByEmailAddress(email = "") {
    return FetchClient(`/data/brand?email=${email}`).GET({})
}

export async function getArrivalWindowByBrandId(brandId = 0) {
    return FetchClient(`/data/brands/arrival-window-configurations?brand_id=${brandId}`).GET({})
}

export async function getTemplateFaqs (brandId = 0) {
    return FetchClient(`/ccai/template-faqs?brand_id=${brandId}`).GET({})
}

export async function validateBrandCode(brandCode = "",id=0) {
    return FetchClient(`/data/brands/brand-code/validate?brand_code=${brandCode}&id=${id}`).GET({})
}

export async function getBrandEmailsByEmail(email = "") {
    return FetchClient(`/data/brands/emails?email=${email}`).GET({})
}

export async function getBrandsByIds(ids = []) {
    let idsQuery = ""
    for (const id of ids) {
        idsQuery += `id=${id}&`
    }
    return FetchClient(`/data/brands?${idsQuery}`).GET({})
}

export const getBrandJobTypesById = async (brandId = 0) => {
    return FetchClient(`/data/brands/job-types?brand_id=${brandId}`).GET({})
}