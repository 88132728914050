import { Box, Button, Dialog, Typography } from "@mui/material";
import DialogHeader from "../../../../components/dialog/DialogHeader";
import DialogBody from "../../../../components/dialog/DialogBody";
import { useContext, useState } from "react";
import { CommonContext } from "../../../../contexts/CommonContextProvider";
import { handleDeleteTasks } from "../../../../state-services/schedule/schedule";
import { LoadingButtonForDelete } from "../../../../components/LoadingButton";
import TasksSummary from "./TasksSummary";

export default function TaskDeleteDialog({ isOpen, onClose, tasks }) {
    const { showNotification } = useContext(CommonContext)
    const [isTaskDeleteLoading, setIsTaskDeleteLoading] = useState(false)

    return <Dialog fullWidth maxWidth="sm" disableScrollLock={true} open={isOpen} >
        <DialogHeader onClose={onClose} className={'dialog-plain-color'}>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center", gap: "0.5em", padding: "1em" }}>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "left", borderRadius: "0.5em", }}>
                    <Typography variant="h5" component={"h1"} fontWeight="bold" >Delete Tasks</Typography>
                </Box>
                <Box flexGrow={2}></Box>
                <TasksSummary tasks={tasks} />
            </Box>
        </DialogHeader>
        <DialogBody className={'dialog-color'}>
            <form onSubmit={(e) => {
                e?.preventDefault?.()
                handleDeleteTasks(tasks, setIsTaskDeleteLoading, () => onClose(true), showNotification)
            }} >
                <Box sx={{ marginBlock: "1em", display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    ⚠️  Please note that any information related to this tasks will be permanently removed from the following areas.
                    <ul>
                        <li> Tasks </li>
                        <li> Task Alerts </li>
                    </ul>
                </Box>
                <Box sx={{ marginBlock: "1em", display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <Button size={"small"} variant="contained" onClick={onClose} className={"btn-secondary"} style={{ marginRight: "10px" }} >Cancel</Button>
                    <LoadingButtonForDelete sx={{ paddingInline: "1em", color: "white", backgroundColor: "#B00020", textTransform: "none", "&:hover": { backgroundColor: "#B00020", } }} type={"submit"} size={"small"} variant="contained" loading={isTaskDeleteLoading} >Delete</LoadingButtonForDelete>
                </Box>
            </form>
        </DialogBody>
    </Dialog >
}