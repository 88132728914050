export const VALID_CALLBACK_MSG = "callback"
export const VALID_STOP_MSG = "stop"
export const VALID_START_MSG = "start"

export const timeDurations = {
    "LAST_WEEK": "LAST_WEEK",
    "LAST_MONTH": "LAST_MONTH",
    "LAST_THREE_MONTHS": "LAST_THREE_MONTHS",
}

export const callbackStatus = {
    completed: 1,
    pending: 0,
}

export const followupStatus = {
    completed: 1,
    pending: 0,
}

export const nonSpamAttemptsCount = 1

export const nonConfigOutboundChannelIdMsg = "The franchise and brand doesn’t have an outbound channel ID. Please contact administrative support first to update it."