import './styles/index.css';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

// Accordian
import CircularProgress from '@mui/material/CircularProgress';
import { ERROR_COMPONENTS } from "../../../constants/common";
import ErrorView from "../../../components/ErrorView";
import { CustomTextField } from "./utils/CustomFaqStyles"
import { useContext, useEffect, useState } from 'react';
import { handleGetKeywords } from '../../../state-services/faq/faq';
import KeywordCard from './components/keywordCard/keywordCard';
import AddKeywordDialog from './components/keywordCard/AddKeywordDialog';
import { IconButton, Tooltip } from '@mui/material';
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from '@mui/icons-material/Clear';
import { CommonContext } from '../../../contexts/CommonContextProvider';

export default function KeywordSection(props) {
    const { setKeywords } = useContext(CommonContext)
    const [allKeywords, setAllKeywords] = useState([])
    const [showingKeywords, setShowingKeywords] = useState([]);
    const [isKeywordsLoading, setIsKeywordsLoading] = useState(false);
    const [isKeywordsError, setIsKeywordsError] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [keywordsToggle, setKeywordsToggle] = useState(false)
    const [isCreateKeywordsDialogOpen, setIsCreateKeywordsDialogOpen] = useState(false)

    useEffect(() => {
        handleGetKeywords((keywords) => {
            setAllKeywords(keywords)
            setKeywords(keywords)
        }, setIsKeywordsLoading, setIsKeywordsError)
    }, [keywordsToggle, setKeywords]);

    useEffect(() => {
        const searchValueLowerCase = searchValue?.toLowerCase?.()
        const showingKeywords = allKeywords?.filter((k) => k?.keyword?.toLowerCase?.()?.includes?.(searchValueLowerCase))
        setShowingKeywords(showingKeywords ?? [])
    }, [searchValue, allKeywords])

    return <Grid item xs={12}>
        <Box className="bg-shadow bg-white" sx={{ paddingInline: "1em" }}>
            <Box sx={{ width: "100%" }}>
                <Box sx={{ display: "flex" }}>
                    <Box sx={{ flexGrow: 2 }} />
                    <CustomTextField
                        id="outlined-basic"
                        sx={{ marginLeft: 2 }}
                        label="Search"
                        size="small"
                        className="search-field"
                        variant="outlined"
                        onChange={(e) => setSearchValue(e.target.value)}
                        value={searchValue}
                        disabled={isKeywordsLoading}
                        InputProps={{
                            "data-test": "faq_keyword_search_field",
                            endAdornment: searchValue ? (
                                <IconButton size="small" onClick={() => setSearchValue("")}>
                                    <ClearIcon />
                                </IconButton>
                            ) : undefined
                        }}
                    />
                    <span>
                        <Tooltip title={"Add New"}>
                            <span>
                                <IconButton
                                    InputProps={{ "data-test": "faq_keyword_add_new_button" }}
                                    aria-label="expand" size="small"
                                    className={"icon-btn"}
                                    onClick={() => setIsCreateKeywordsDialogOpen(true)}
                                >
                                    <AddIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </span>
                </Box>
            </Box>
            {isKeywordsError ?
                <ErrorView type={ERROR_COMPONENTS.SomthingWentWrong} title='Something went wrong' />
                : isKeywordsLoading ?
                    <div className="loading-wrapper">
                        <CircularProgress size={30} color="inherit" />
                    </div> :
                    <>
                        <div className="accordian-wrapper">
                            {showingKeywords.length > 0 ?
                                <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }} >
                                    {
                                        showingKeywords.map((keyword, i) => (
                                            <KeywordCard keyword={keyword} refreshKeywords={() => setKeywordsToggle(!keywordsToggle)} />
                                        ))
                                    }</Box> :
                                <div className="no-record-div">
                                    <ErrorView type={ERROR_COMPONENTS.NOT_FOUND} title='No Keywords Found' />
                                </div>}
                        </div>
                    </>}
            <AddKeywordDialog handleClose={() => setIsCreateKeywordsDialogOpen(false)} isDialogOpen={isCreateKeywordsDialogOpen} onDone={() => setKeywordsToggle((t) => !t)} allKeywords={allKeywords} />
        </Box>
    </Grid>;
}