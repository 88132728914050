import { Box, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { handleGetChatReport } from "../../../../state-services/reports/reports"
import LoadingRow from "../../../../components/table/LoadingRow"
import ErrorRow from "../../../../components/table/ErrorRow"
import { ERROR_COMPONENTS } from "../../../../constants/common"
import { CommonContext } from "../../../../contexts/CommonContextProvider";
import { getMatchingBrandByBrandId, sortDataByBrandAlphabetically } from "../../../../utils/miscellaneous"
import ImageBox from "../../../../components/LogoBox/ImageBox"


export default function PhoneReportTable(props) {
    const { filtration, reportData, setReportData } = props
    const { brands } = useContext(CommonContext)
    const [page, setPage] = useState(1)
    const [isLoading, setIsLoading] = useState()
    const [isError, setIsError] = useState()

    useEffect(() => {
        handleGetChatReport(filtration.startDate, filtration.endDate, page, 50, setReportData, setIsLoading, setIsError)
    }, [page, filtration.startDate, filtration.endDate])

    function buildDataRows() {
        return sortDataByBrandAlphabetically(reportData?.data, brands)?.map?.((d, i) => <TableRow>
            <TableCell style={{ textAlign: "left" }} >{i + 1}</TableCell>
            <TableCell style={{ textAlign: "left" }} >
                <div style={{ display: "flex", alignItems: "center", gap: "4em" }}>
                    {getMatchingBrandByBrandId(d.brand, brands)?.brand_name ?? ""}
                    <div style={{ marginLeft: "auto", marginRight: "3%" }}>
                        <ImageBox src={getMatchingBrandByBrandId(d?.brand, brands)?.brand_img ?? ""}/>
                    </div>
                </div>
            </TableCell>
            <TableCell style={{ textAlign: "left", }}  >{d?.accepted?.toLocaleString()}</TableCell>
            <TableCell style={{ textAlign: "left", }}  >{d?.abandoned?.toLocaleString()}</TableCell>
            <TableCell style={{ textAlign: "left" }}  >{d?.rejected?.toLocaleString()}</TableCell>
            <TableCell style={{ textAlign: "left" }}  >{d?.rejected_timeout?.toLocaleString()}</TableCell>
            <TableCell style={{ textAlign: "left" }}  >{d?.hold?.toLocaleString()}</TableCell>
            <TableCell style={{ textAlign: "left" }}  >{d?.hold_time}</TableCell>
            <TableCell style={{ textAlign: "left" }}  >{d?.handle_time}</TableCell>
            <TableCell style={{ textAlign: "left" }}  >{d?.speed_to_answer}</TableCell>
        </TableRow>)
    }

    return <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <TableContainer sx={{ flexGrow: 2 }}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ textAlign: "left" }} >#</TableCell>
                        <TableCell style={{ textAlign: "left" }} >Brand</TableCell>
                        <TableCell style={{ textAlign: "left", }}  >Accepted</TableCell>
                        <TableCell style={{ textAlign: "left", }}  >Abandoned</TableCell>
                        <TableCell style={{ textAlign: "left" }}  >Rejected</TableCell>
                        <TableCell style={{ textAlign: "left" }}  >Rejected Time Out</TableCell>
                        <TableCell style={{ textAlign: "left" }}  >Hold Count</TableCell>
                        <TableCell style={{ textAlign: "left" }}  >Avg Hold Time</TableCell>
                        <TableCell style={{ textAlign: "left" }}  >Avg Handle Time</TableCell>
                        <TableCell style={{ textAlign: "left" }}  >Avg Speed To Answer</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {isLoading ? <LoadingRow colSpan={12} /> : isError ? <ErrorRow colSpan={12} type={ERROR_COMPONENTS.SomthingWentWrong.type} /> : reportData?.data?.length === 0 ? <ErrorRow colSpan={12} type={ERROR_COMPONENTS.NoDataFound.type} /> : buildDataRows()}
                </TableBody>
            </Table>
        </TableContainer>
        <Box style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "end" }}>
            <Pagination
                page={page}
                onChange={(e, v) => {
                    setPage(v);
                }}
                color="standard"
                count={Math.ceil((reportData?.total_count ?? 0) / 50)}
                sx={{ alignSelf: "right" }}
            />
        </Box>
    </Box>

}