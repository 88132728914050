import { Box, Button } from "@mui/material";
import { useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import ServiceTitanEstimates from "./ServiceTitan/ServiceTitanEstimates";

const ESTIMATES_SECTIONS = {
    SERVICE_TITAN_ESTIMATES: {
        index: 0,
        section: "serviceTitan",
        title: "ServiceTitan",
    }
}

function EstimatesLandingPage(props) {
    const initialSection = useMemo(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const section = urlParams.get('section');
        return Object.values(ESTIMATES_SECTIONS).find((v) => v.section === section)
    }, [])

    const [selectedSection, setSelectedSection] = useState(initialSection ? initialSection : ESTIMATES_SECTIONS.SERVICE_TITAN_ESTIMATES)

    function getSection() {
        switch (selectedSection.section) {
            case ESTIMATES_SECTIONS.SERVICE_TITAN_ESTIMATES.section:
                return <ServiceTitanEstimates />
            default:
                return <></>
        }
    }

    return <Box sx={{ padding: "0.5em", flexGrow: 2, display: "flex", flexDirection: "column" }}>
        <Box className={"tabs"} style={{ marginBottom: "20px" }}>
            {Object.values(ESTIMATES_SECTIONS).map((tab) => {
                return <Button
                    key={tab.index}
                    onClick={() => setSelectedSection(tab)}
                    size={"small"}
                    variant="contained"
                    className={tab === selectedSection ? "btn-primary tab" : "btn-secondary tab"}
                >{tab.title}</Button>
            })}
        </Box>
        <Box sx={{ flexGrow: 2 }} >{getSection()}</Box>
    </Box>
}

export default withRouter(EstimatesLandingPage);
