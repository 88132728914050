import { Autocomplete, Typography } from "@mui/material";
import CustomTextField from "./CustomTextField";
import { imposibleToMatchRegex, isValidZipCode } from "../utils/validators";
import { CLASS_NAMES } from "../constants/common";
import { filterStatesByCountry, validateStateWithCountry } from "../state-services-dublicate/vonigo";

export default function AddressBox(props) {
    const { address, location, isDisabled, setIsValidZipCode, isRequired, onChangeAddress = () => { }, isNotLeadSection = true, isSelectableStates = true, states = [], countries, zipCodeValidator, dataTest = "" } = props
    let isZipCodeValid
    if (zipCodeValidator) {
        isZipCodeValid = zipCodeValidator?.(address?.zip, address?.country)
        if (isNotLeadSection) setIsValidZipCode(isZipCodeValid)
    } else {
        isZipCodeValid = isValidZipCode(address?.zip, address?.country);
        if (isNotLeadSection) setIsValidZipCode(isZipCodeValid)
    }
    let zipCodePattern
    if (!isZipCodeValid) zipCodePattern = imposibleToMatchRegex
    return <>
        <CustomTextField inputProps={{ 'data-test': 'location_street_' + dataTest }} disabled={isDisabled} required={isRequired} label="Street" variant="outlined" size="small" style={{ flex: "0 1 25%" }} value={address?.street ?? ""} onChange={(event) => { onChangeAddress({ ...address, street: event.target.value }); }} ></CustomTextField>
        <CustomTextField inputProps={{ 'data-test': 'location_unit_' + dataTest }} disabled={isDisabled} label={!isDisabled ? "Unit - Optional" : "Unit"} variant="outlined" size="small" style={{ flex: "0 0 10%" }} error={address?.unit?.length > 50} helperText={address?.unit?.length > 50 ? `Value too long` : ""} value={address?.unit ?? ""} onChange={(event) => { onChangeAddress({ ...address, unit: event.target.value }); }} ></CustomTextField>
        <CustomTextField inputProps={{ 'data-test': 'location_city_' + dataTest }} disabled={isDisabled} required={isRequired} label="City" variant="outlined" size="small" style={{ flex: "1 1 15%" }} value={address?.city ?? ""} onChange={(event) => { onChangeAddress({ ...address, city: event.target.value }); }} ></CustomTextField>
        <CustomTextField className={CLASS_NAMES.ZIP} disabled={isDisabled} required={isRequired} label="Zip/Postal Code" variant="outlined" size="small" style={{ flex: "0 0 15%" }} value={address?.zip ?? ""} error={!isZipCodeValid} helperText={isZipCodeValid ? "" : `Invalid Zip Code`} inputProps={{ pattern: zipCodePattern, 'data-test': 'location_zip_' + dataTest }} onChange={(event) => { onChangeAddress({ ...address, zip: event.target.value }); }} ></CustomTextField>
        <Autocomplete
            style={{ flex: "0 0 25%" }}
            size="small"
            disableClearable={true}
            data-test='location_country_selector'
            disabled={isDisabled}
            options={countries}
            getOptionLabel={(country) => country}
            renderOption={(props, country) => {
                return (
                    <Typography {...props} key={country}>
                        {country}
                    </Typography>
                );
            }}
            value={address?.country ? address?.country ?? null : null}
            onChange={(event, country) => { onChangeAddress({ ...address, country: country }); }}
            renderInput={(params) => <CustomTextField required={isRequired} disabled={isDisabled}  {...params} inputProps={{ ...params.inputProps, 'data-test': 'location_country' }} label="Country" />}
        />
        {
            <Autocomplete
                style={{ flex: "0 0 25%" }}
                size="small"
                data-test=' location_state_selector'
                disableClearable={true}
                disabled={isDisabled}
                options={isSelectableStates == true ? filterStatesByCountry(states, address) : states}
                getOptionLabel={(state) => state}
                renderOption={(props, state) => {
                    return (
                        <Typography {...props} key={state}>
                            {state}
                        </Typography>
                    );
                }}
                value={address?.state ? address?.state ?? null : null}
                onChange={(event, state) => { onChangeAddress({ ...address, state: state }); }}
                renderInput={(params) => <CustomTextField error={!validateStateWithCountry(states, location)} helperText={validateStateWithCountry(states, location) ? "" : "state not valid for country"} required={isRequired} disabled={isDisabled}  {...params} inputProps={{ ...params.inputProps, 'data-test': 'location_state' }} label="State" />}
            />
        }
    </>
}

