import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Pagination } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress"
import moment from "moment";
import { ROW_COUNT_PER_PAGE, ERROR_COMPONENTS } from "../../../constants/common";
import { getAllCalls } from "../../../state-services/callLogs";
import ErrorView from "../../../components/ErrorView";
import { CommonContext } from "../../../contexts/CommonContextProvider";
import { UserContext } from "../../../contexts/User";
import ImageBox from "../../../components/LogoBox/ImageBox";
import { CustomSelect } from "../franchise/franchiseCustomStyle";
import CustomDatePicker from "../../../components/CustomDatePicker";
import { userLevels } from "../../../constants/common";

const CALL_FOR_TYPES = [
    {
        index: 0,
        type: "All"
    },
    {
        index: 1,
        type: "Jobs"
    },
    {
        index: 2,
        type: "Leads"
    },
    {
        index: 3,
        type: "Estimates"
    },
]


export default function AllCallPage() {

    const { brands, franchises, allUsersIncludeRemovedOnes } = useContext(CommonContext);
    const {me} = useContext(UserContext)
    const [startDate, setStartDate] = useState(Date.now());
    const [filterState, setFilterState] = useState()
    const [callCount, setCallCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [allCalls, setAllCalls] = useState([])
    const [loading, setLoading] = useState(true)
    const [callFor, setCallFor] = useState(0)
    const [startDateValue, setStartDateValue] = useState(moment(Date.now()).startOf('day').format('YYYY-MM-DD HH:mm:ss Z'));

    const history = useHistory()

    useEffect(() => {
        if (startDateValue) {
            setFilterState({ ...filterState, page: 1, size: 10, createdOn: startDateValue })
        }
    }, [startDateValue])

    useEffect(() => {
        if (filterState && brands.length > 0) {
            setLoading(true)
            let brandIds = me?.level < userLevels?.ADMIN ? brands?.map((b) => b?.brand_id) : []
            let brandPrimaryIds = me?.level < userLevels?.ADMIN ? brands?.map((b) => b?.id) : []
            getAllCalls(filterState.page, filterState.size, filterState.createdOn, filterState.callFor, brandIds, brandPrimaryIds).then((response) => {
                setAllCalls(response?.all_calls)
                setCallCount(response?.count)
            }).catch((error) => {
            }).finally(() => {
                setLoading(false)
            })
        } else {
            setLoading(false)
        }
    }, [filterState, brands])

    const handleCallForChange = (e) => {
        setCurrentPage(1)
        setFilterState({ ...filterState, page: 1, callFor: e.target.value })
        setCallFor(e.target.value)
    }

    const onPageChangeHandler = (page) => {
        setCurrentPage(page)
        setFilterState({ ...filterState, page: page })
    }

    const handleRowNumber = (i) => {
        if (currentPage === 1) {
            return i + 1
        } else {
            return (currentPage - 1) * ROW_COUNT_PER_PAGE + i + 1
        }
    }

    const getMatchingBrandByBrandIdForInboundCalls = (brandId) => {
        const brand = brands.find((brand) => brand.brand_id === brandId)
        return brand

    }

    const getMatchingBrandByBrandIdForOutboundCalls = (brandId) => {
        const brand = brands.find((brand) => brand.id === brandId)
        return brand
    }

    const getMatchingFranchiseByFranchiseIdForInboundCalls = (franchiseId) => {
        return franchiseId > 0 && franchises.find((franchise) => franchise.franchise_id === franchiseId)
    }

    const getMatchingFranchiseByFranchiseIdForOutboundCalls = (franchiseId) => {
        const franchise = franchises.find((franchise) => franchise.id === franchiseId)
        return franchise
    }

    const handleViewLeadDetails = (leadId) => {
        history.replace(`/leads?serviceTitanLeadId=${leadId}`)
    }

    const handleViewServiceTitanJobDetails = (serviceTitanJobId, franchise_id, brand_id, isInbound) => {
        const brandId = isInbound ? brand_id : getMatchingBrandByBrandIdForOutboundCalls(brand_id)?.brand_id
        const franchiseId = isInbound ? getMatchingFranchiseByFranchiseIdForInboundCalls(franchise_id)?.id : franchise_id
        history.replace(`/jobs?serviceTitanJobid=${serviceTitanJobId}&franchiseId=${franchiseId}&isFromCallLog=true&brandId=${brandId}`)
    }

    const handleViewVonigoJobDetails = (vonigoJobId, franchise_id, brand_id, isInbound) => {
        const redirectUrl = isInbound ? getMatchingBrandByBrandIdForInboundCalls(brand_id)?.redirect_url : getMatchingBrandByBrandIdForOutboundCalls(brand_id)?.redirect_url
        const brandId = isInbound ? brand_id : getMatchingBrandByBrandIdForOutboundCalls(brand_id)?.brand_id
        const franchiseId = isInbound ? getMatchingFranchiseByFranchiseIdForInboundCalls(franchise_id)?.id : franchise_id
        history.replace(`/jobs?vonigoJobid=${vonigoJobId}&franchiseId=${franchiseId}&baseUrl=${redirectUrl}&isFromCallLog=true&brandId=${brandId}`)
    }

    const handleViewEstimateDetails = (estimateId, franchise_id, brand_id) => {
        const brandId = getMatchingBrandByBrandIdForOutboundCalls(brand_id)?.brand_id
        history.replace(`/estimates?estimateId=${estimateId}&brandId=${brandId}&franchiseId=${franchise_id}`)
    }

    const getAgentNameFromId = (id) => {
        const user = allUsersIncludeRemovedOnes.find((user) => user.user_id == id)
        return user?.first_name + " " + user?.last_name || id
    }

    const sortCallsByCreatedTime = (calls) => {
        return calls.sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at)
        })
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <div
                    style={{ padding: "15px", paddingTop: '0px', borderRadius: "7px" }}
                    className={"bg-shadow bg-white"}
                >
                    <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div >
                            <div style={{ display: "flex", marginTop: "15px", marginBottom: "15px" }}>
                                <small>
                                    <strong>
                                        &nbsp; {callCount ?? 0} Call Records &nbsp;
                                    </strong>
                                </small>
                            </div>
                        </div>
                        <CustomSelect style={{ minWidth: "170px", marginRight: "20px", marginLeft: 'auto' }}>
                            <Select
                                size={"small"}
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                                className={"cus-input"}
                                value={callFor}
                                onChange={(e) => { handleCallForChange(e) }}
                            >
                                {CALL_FOR_TYPES.map((singleCallFor) => (
                                    <MenuItem value={singleCallFor.index}>{singleCallFor.type}</MenuItem>
                                ))}
                            </Select>
                        </CustomSelect>
                        <div style={{ width: "200px", float: 'right' }}>
                            <CustomDatePicker
                                showTimeSelect={false}
                                maxTime={new Date()}
                                disabled={false}
                                label={`Start Date`}
                                value={new Date(startDate)}
                                onChange={(date) => {
                                    setStartDateValue(moment(date).startOf('day').format('YYYY-MM-DD HH:mm:ss Z'))
                                    setStartDate(date)
                                }}
                                clearable={false}
                                required={true}
                            />
                        </div>
                    </div>
                    <div>
                        <TableContainer style={{ height: "70vh" }}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>#</TableCell>
                                        <TableCell align="left">From</TableCell>
                                        <TableCell align="left">To</TableCell>
                                        <TableCell align="center">{callFor === 0 ? "Job ID/ Lead ID/ Estimates ID" : callFor === 1 ? "Job ID" : callFor === 2 ? "Lead ID" : "Estimate ID"} </TableCell>
                                        <TableCell align="center">Brand</TableCell>
                                        <TableCell align="left">Franchise</TableCell>
                                        <TableCell align="left">Call Type</TableCell>
                                        <TableCell align="left">Created Time</TableCell>
                                        <TableCell align="left">Agent</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {loading ? (<TableRow>
                                        <TableCell colSpan={4} align="center" style={{ border: "none", height: "300px", color: "grey" }} >
                                            <CircularProgress size={30} color="inherit" />
                                        </TableCell>
                                    </TableRow>) : allCalls && allCalls.length !== 0 ? (
                                        sortCallsByCreatedTime(allCalls)?.map((row, i) => (
                                            <TableRow>
                                                <TableCell>{handleRowNumber(i)}</TableCell>
                                                <TableCell align="left">{row?.call_type != null && row?.call_type >= 0 ? (row?.caller_id && row.caller_id != "" ? row?.caller_id : "-") : (row?.channel_id && row?.channel_id != "" ? row?.channel_id : "-")}</TableCell>
                                                <TableCell align="left">{row?.call_type != null && row?.call_type >= 0 ? (row?.channel_id && row.channel_id != "" ? row?.channel_id : "-") : (row?.caller_id && row?.caller_id != "" ? row?.caller_id : "-")}</TableCell>
                                                {<TableCell align="center">{row?.service_titan_job_id !== 0 ? (<Button onClick={() => handleViewServiceTitanJobDetails(row?.service_titan_job_id, row?.franchise_id, row?.brand_id, row.call_type != null && row.call_type >= 0)}>{row?.service_titan_job_id}</Button>) : row?.lead_id !== 0 ? (<Button onClick={() => handleViewLeadDetails(row?.lead_id)}>{row?.lead_id}</Button>) : row?.vonigo_job_id !== 0 ? (<Button onClick={() => handleViewVonigoJobDetails(row?.vonigo_job_id, row?.franchise_id, row?.brand_id, row.call_type != null && row.call_type >= 0)}>{row?.vonigo_job_id}</Button>) : row?.estimate_id !== 0 ? (<Button onClick={() => handleViewEstimateDetails(row?.estimate_id, row?.franchise_id, row?.brand_id)}>{row?.estimate_id}</Button>) : row?.call_type !== 0 ? "N/A" : "Redirected"}</TableCell>}
                                                <TableCell align="center"><Box sx={{display:"flex", justifyContent:"center"}}><ImageBox alt={row?.brand_id} src={row?.call_type != null && row?.call_type >= 0 ? getMatchingBrandByBrandIdForInboundCalls(row?.brand_id)?.brand_img : getMatchingBrandByBrandIdForOutboundCalls(row.brand_id)?.brand_img} /></Box></TableCell>
                                                <TableCell align="left">{row?.franchise_id > 0 ? row?.call_type != null && row?.call_type >= 0 ? getMatchingFranchiseByFranchiseIdForInboundCalls(row?.franchise_id)?.franchise_name : getMatchingFranchiseByFranchiseIdForOutboundCalls(row?.franchise_id)?.franchise_name : "-"}</TableCell>
                                                <TableCell align="left">{row?.call_type != null && row?.call_type >= 0 ? "Inbound" : "Outbound"}</TableCell>
                                                <TableCell align="left">{moment(row?.created_at).format("hh:mm A")}</TableCell>
                                                <TableCell align="left">{getAgentNameFromId(row?.created_by)}</TableCell>
                                            </TableRow>
                                        ))) : brands?.length === 0 ?
                                        (
                                            <TableRow>
                                                <TableCell style={{ border: "none", height: "200px" }} align={"center"} colSpan={9}>
                                                    <ErrorView type={ERROR_COMPONENTS.NOT_FOUND} title='You Are Not Assigned To Any Brand' />
                                                </TableCell>
                                            </TableRow>
                                        )
                                        : (
                                            <TableRow>
                                                <TableCell style={{ border: "none", height: "200px" }} align={"center"} colSpan={9}>
                                                    <ErrorView type={ERROR_COMPONENTS.NOT_FOUND} title='No Records Found On Selected Day' />
                                                </TableCell>
                                            </TableRow>
                                        )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Pagination
                            page={currentPage}
                            onChange={(_, v) => onPageChangeHandler(v)}
                            className="pagination-bar"
                            count={Math.ceil(callCount / ROW_COUNT_PER_PAGE)}
                            color="standard" />
                    </div>
                </div>
            </Grid>
        </Grid>
    );
}
