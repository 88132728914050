import { NOTIFICATION_TYPES } from "../../constants/common"
import { createLuminBrandConfiguration, getGeneralTenantConfiguration, getLuminBrandsConfiguration, getLuminConfiguration, updateGeneralTenantConfiguration, updateLuminBrandConfiguration, updateLuminConfiguration } from "../../services/configurations/configurations"
import { getAllStates } from "../../services/stateCodesService"
import { retry, shouldRetry } from "../../state-services-dublicate/serviceTitan"

const DEFAULT_TENANT_ID = 1

export async function handleGetLuminConfiguration(setConfiguration, setIsLoading, setIsError, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    try {
        setConfiguration()
        setIsLoading(true)
        setIsError(false)
        const res = await getLuminConfiguration(DEFAULT_TENANT_ID)
        setConfiguration(res)
    } catch (err) {
        if (shouldRetry(backoffConfig) && (err?.status !== 400 || err?.status !== 404)) {
            retry(() => handleGetLuminConfiguration(setConfiguration, setIsLoading, setIsError, backoffConfig), backoffConfig)
        } else {
            setIsError(true)
        }
    } finally {
        setIsLoading(false)
    }
}

export async function handleGetAllBrandConfigurations(setConfiguration, setIsLoading, setIsError, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    try {
        setConfiguration()
        setIsLoading(true)
        setIsError(false)
        const res = await getLuminBrandsConfiguration(DEFAULT_TENANT_ID)
        setConfiguration(res)
    } catch (err) {
        if (shouldRetry(backoffConfig) && (err?.status !== 400 || err?.status !== 404)) {
            retry(() => handleGetLuminConfiguration(setConfiguration, setIsLoading, setIsError, backoffConfig), backoffConfig)
        } else {
            setIsError(true)
        }
    } finally {
        setIsLoading(false)
    }
}

export async function handleUpdateLuminConfiguration(id = 0, configuration = {}, setIsLoading, showNotification) {
    try {
        setIsLoading(true)
        await updateLuminConfiguration(id = 0, configuration)
        showNotification({ message: "Configuration updated successfully", type: NOTIFICATION_TYPES.SUCCESS })
    } catch (err) {
        showNotification({ message: "Important: Could not update configuration. Please try again", type: NOTIFICATION_TYPES.ERROR })
    } finally {
        setIsLoading(false)
    }
}

export async function handleUpdateLuminBrandConfiguration(id = 0, configuration = {}, setIsLoading, showNotification, setLuminBrandConfig, toggleRefreshBrandConfig, setToggleRefreshBrandConfig) {
    try {
        setIsLoading(true)
        await updateLuminBrandConfiguration(id, configuration)
        showNotification({ message: "Configuration updated successfully", type: NOTIFICATION_TYPES.SUCCESS })
        setLuminBrandConfig(null)
        setToggleRefreshBrandConfig(!toggleRefreshBrandConfig)
    } catch (err) {
        showNotification({ message: "Important: Could not update configuration. Please try again", type: NOTIFICATION_TYPES.ERROR })
    } finally {
        setIsLoading(false)
    }
}

export async function handleCreateLuminBrandConfiguration(configuration = {}, setIsLoading, showNotification, setLuminBrandConfig, toggleRefreshBrandConfig, setToggleRefreshBrandConfig) {
    try {
        setIsLoading(true)
        let config = { ...configuration, tenant_id: DEFAULT_TENANT_ID }
        await createLuminBrandConfiguration(config)
        showNotification({ message: "Configuration added successfully", type: NOTIFICATION_TYPES.SUCCESS })
        setLuminBrandConfig(null)
        setToggleRefreshBrandConfig(!toggleRefreshBrandConfig)
    } catch (err) {
        showNotification({ message: "Important: Could not add configuration. Please try again", type: NOTIFICATION_TYPES.ERROR })
    } finally {
        setIsLoading(false)
    }

}

export async function handleGetAllStates(setStates, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    try {
        setStates()
        const res = await getAllStates()
        if (Array.isArray(res)) {
            let states = []
            res.forEach(s => {
                states.push(s.state)
            })
            setStates(states)
        }
    } catch (err) {
        if (shouldRetry(backoffConfig) && (err?.status !== 400 || err?.status !== 404)) {
            retry(() => handleGetAllStates(setStates, backoffConfig), backoffConfig)
        } else {
            setStates([])
        }
    }
}

export async function handleUpdateGeneralTenantConfiguration(configuration = {}, setIsLoading, onDone = () => { }, showNotification) {
    try {
        setIsLoading(true)
        const res = await updateGeneralTenantConfiguration(DEFAULT_TENANT_ID, configuration)
        showNotification({ message: "Configuration updated successfully", type: NOTIFICATION_TYPES.SUCCESS })
        onDone(res)
    } catch (err) {
        showNotification({ message: "Important: Could not update configuration. Please try again", type: NOTIFICATION_TYPES.ERROR })
    } finally {
        setIsLoading(false)
    }
}

export async function handleGetGeneralTenantConfiguration(setConfiguration, setIsLoading, setIsError, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    try {
        setConfiguration()
        setIsLoading(true)
        setIsError(false)
        const res = await getGeneralTenantConfiguration(DEFAULT_TENANT_ID)
        setConfiguration(res)
    } catch (err) {
        if (shouldRetry(backoffConfig) && (err?.status !== 400 || err?.status !== 404)) {
            retry(() => handleGetGeneralTenantConfiguration(setConfiguration, setIsLoading, setIsError, backoffConfig), backoffConfig)
        } else {
            setIsError(true)
        }
    } finally {
        setIsLoading(false)
    }
}