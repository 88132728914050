import { Box, Typography } from "@mui/material"
import { useMemo } from "react"

export default function TasksSummary({ tasks }) {
    const taskSummary = useMemo(() => {
        return getTasksSummary(tasks)
    }, [tasks])

    return <Box>
        <Typography variant="subtitle2" >{`Total : ${taskSummary?.total} | Unassigned : ${taskSummary?.unassigned}`}</Typography>
    </Box>
}

function getTasksSummary(tasks) {
    let taskSummary = {
        total: 0,
        unassigned: 0,
        completed: 0,
        pending: 0,
        brandSummary: {}
    }
    tasks.forEach((task) => {
        taskSummary.total += 1
        if (task?.is_completed === 1) {
            taskSummary.completed += 1
        } else {
            taskSummary.pending += 1
        }
        if (task?.assigned_to === 0) {
            taskSummary.unassigned += 1
        }
        if (taskSummary.brandSummary[task.brand_id]) {
            taskSummary.brandSummary[task.brand_id] += 1
        } else {
            taskSummary.brandSummary[task.brand_id] = 1
        }
    })
    return taskSummary
}