import { LOCAL_STORAGE_KEYS, SESSION_STORAGE_KEYS } from "../../../constants/browserStorage";
import { NOTIFICATION_TYPES } from "../../../constants/common";
import { getFaqsByKeyWords } from "../../../services/faqService";
import { getFranchiseById } from "../../../services/franchises";
import { handleCheckAppWorking, handleStartRecordingOnDesktopApp } from "../../../state-services/callDetails"
import { getFromLocalStorage, getFromSessionStorage, setSessionStorage } from "../../../utils/browserStorage";

export const onSyncdesktopAppButtonHandler = async (frId, showNotification, sessionId) => {

    let media = localStorage.getItem(LOCAL_STORAGE_KEYS.media)


    if (!media) return
    if (media === 'MANUAL' || media === '') return

    try {
        let recordingData = {
            franchiseName: "",
            franchiseId: hanldeCurrentFranchiseId(frId),
            brandId: localStorage.getItem(LOCAL_STORAGE_KEYS.brandPrimaryId),
            fcmToken: localStorage.getItem("fcm_token"),
            callerId: localStorage.getItem(LOCAL_STORAGE_KEYS.callerId),
            sessionID: sessionStorage.getItem(SESSION_STORAGE_KEYS.interactionGuid),
            other: {
                user_id: localStorage.getItem("user_id"),
                user_img: getFromSessionStorage(SESSION_STORAGE_KEYS.User_img),
                user_name: localStorage.getItem("user_name"),
                channel_id: localStorage.getItem(LOCAL_STORAGE_KEYS.ChannelId),
                level: localStorage.getItem("user_level"),
                faq_sync: {
                    faq: null,
                }
            }
        }
        if (!isNaN(parseInt(recordingData.franchiseId))) {
            setSessionStorage(SESSION_STORAGE_KEYS.FAQ_franchise_id, recordingData.franchiseId)
            await handleGetFaqsByKeyWord()
            let currentFranchise = await getFranchiseById(recordingData.franchiseId)
            setSessionStorage(SESSION_STORAGE_KEYS.Franchise_Name, currentFranchise?.franchise_name)
            let appWorking = await handleCheckAppWorking()
            if (appWorking) {
                recordingData.franchiseName = currentFranchise?.franchise_name
                recordingData.other.faq_sync.faq = getFromSessionStorage(SESSION_STORAGE_KEYS.FAQ_temp)
                handleStartRecordingOnDesktopApp(recordingData)
            } else {
                showNotification?.({ message: "Unable Connect Agent Assist", type: NOTIFICATION_TYPES.WARNING })
            }
        }
    } catch (error) {
    }
}

const handleGetFaqsByKeyWord = async () => {
    try {
        let body = {
            keys: JSON.parse(getFromSessionStorage(SESSION_STORAGE_KEYS.Question_keys))?.flat(1)?.toString().split(",").join(" ")?.replace(/[.?]/g, ''),
            franchise_id: parseInt(getFromSessionStorage(SESSION_STORAGE_KEYS.FAQ_franchise_id)),
            brand_id: parseInt(getFromLocalStorage(LOCAL_STORAGE_KEYS.brandPrimaryId)),
        }
        let res = await getFaqsByKeyWords(body)
        if (Array.isArray(res)) {
            let suggArray = []
            res.forEach(r => {
                let sudg = {
                    question: r.question,
                    answer: r.answer
                }
                suggArray.push(sudg)
            });
            setSessionStorage(SESSION_STORAGE_KEYS.FAQ_temp, JSON.stringify(suggArray))
        } else {
            setSessionStorage(SESSION_STORAGE_KEYS.FAQ_temp, JSON.stringify([]))
        }
    } catch (error) {
    }
}

const hanldeCurrentFranchiseId = (franchiseId) => {
    if (typeof franchiseId === "number") {
        return franchiseId
    } else {
        if (!isNaN(parseInt(getFromSessionStorage(SESSION_STORAGE_KEYS.FAQ_franchise_id)))) {
            return parseInt(getFromSessionStorage(SESSION_STORAGE_KEYS.FAQ_franchise_id))
        }
    }
}