import React from 'react'
import { Box, Typography } from '@mui/material'
import AllCallsIcon from '../../../../assets/img/middlewear_statistics_images/All-Call(B).png';
import FollowUpCallIcon from '../../../../assets/img/middlewear_statistics_images/Follow Up Call (1).png';
import HoldCallIcon from '../../../../assets/img/middlewear_statistics_images/Hold-Call-Icon(B).png';
import InboundCallIcon from '../../../../assets/img/middlewear_statistics_images/Inbound Call Icon in ALL CALLS AND INBOUND TOTAL CALLS.png .png';
import jobsSentIcon from '../../../../assets/img/middlewear_statistics_images/image.png';
import otherCallsInboundAndOutbound from '../../../../assets/img/middlewear_statistics_images/other calls INBOUND & OUTBOUND BOTH OTHER CALLS.png';
import outboundCallIcon from '../../../../assets/img/middlewear_statistics_images/Outbound Call Icon - ALL CALLS & OUTBOUND TOTAL CALLS.png';

export default function MiddlewareStatisticsTile({ formattedFunction, data, type }) {

    let { labels, series } = formattedFunction(data, "Tiles")

    function constrcutIcons(label, type) {
        if(label ==="Total Calls" && type === "All"){
            return <img alt='call' src={AllCallsIcon}/>
        }else if (label ==="Inbound Calls" && type === "All"){
            return <img alt='call' src={InboundCallIcon}/>
        }else if (label === "Outbound Calls" && type === "All"){
            return <img alt='call' src={outboundCallIcon}/>
        }else if (label === "Total Calls" && type === "Inbound"){
            return <img alt='call' src={InboundCallIcon}/>
        }else if (label === "Jobs Sent" && type === "Inbound"){
            return <img alt='call' style={{width:"52px",height:"52px"}} src={jobsSentIcon}/>
        }else if (label === "Jobs On Hold" && type === "Inbound"){
            return <img alt='call' src={HoldCallIcon}/>
        }else if (label === "Other Calls" && type === "Inbound"){
            return <img alt='call' src={otherCallsInboundAndOutbound}/>
        }else if (label === "Total Calls" && type === "Outbound"){
            return <img alt='call' src={outboundCallIcon}/>
        }else if (label === "FollwUp Call Count" && type === "Outbound"){
            return <img alt='call' src={FollowUpCallIcon} style={{width:"60px"}}/>
        }else{
            return <img alt='call' src={otherCallsInboundAndOutbound}/>
        }
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "row", marginBottom: "1.5em" }}>
            {series?.map((value, index) => {
                return (
                    <Box sx={{ display: "flex", marginLeft: index === 0 ? "auto" : "40px", marginRight: index === series.length - 1 && "auto", flexDirection: "row", height: "auto", width: "auto", padding: "5px", backgroundColor: "#4bc0b8", borderRadius: "7px", boxShadow: "0px 2px 5px", opacity: 1 }}>
                        <Box sx={{ marginRight: "10px", display: "flex", alignItems: "center" }}>
                            {constrcutIcons(labels[index], type)}
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", marginRight: "10px" }}>
                            <Box>
                                <Typography color="black" variant="h6">{value}</Typography>
                            </Box>
                            <Box>
                                <Typography color="black" variant="h8">{labels[index]}</Typography>
                            </Box>
                        </Box>
                    </Box>
                )
            }
            )}
        </Box>
    )
}
