
import { Autocomplete} from "@mui/material"
import CustomTextField from "../../../../../components/CustomTextField"
import RenderInput from "../../../../../components/servicetitan/RenderInput"

export default function CampaignSelection(props) {
    const { noOptionText, isDisabled, value, campaigns, isCampaignsLoading = false, onChange } = props
    return <Autocomplete
        sx={{ flex: "1 1 100%" }}
        size="small"
        noOptionsText={noOptionText}
        loading={isCampaignsLoading}
        disabled={isDisabled}
        value={value ?? null}
        disablePortal
        options={campaigns}
        getOptionLabel={(campaign) => campaign?.campaign}
        renderOption={(props, option) => <RenderInput {...props} key={option?.optionID} content={option?.campaign ?? "-"} />}
        onChange={(_, option) => {
            onChange(option);
        }}
        renderInput={(params) => <CustomTextField {...params} required={false} disabled={isDisabled} label="Select Campaign" />}
    />
}