export const customRanges = [
    {
        label: "Yesterday",
        value: 1
    },
    {
        label: "Last 7 days",
        value: 7
    }
    ,
    {
        label: "Last 14 days",
        value: 14
    },
    {
        label: "Last 30 days",
        value: 30
    },
    {
        label: "Custom Range",
        value: 0
    }
]
