import { IconButton, Tooltip } from "@mui/material";
import ReportIcon from "@mui/icons-material/Report";


export function EncryptionWarningBadge(props) {
    const { title, bottom = "-12px", right = "-12px" } = props
    return <div style={{ zIndex: 5 }}>
        <Tooltip sx={{ position: "absolute", bottom: bottom, right: right }} title={title}>
            <IconButton onClick={(e) => e?.stopPropagation()}>
                <ReportIcon color="error" style={{ height: "16px", width: "16px" }} />
            </IconButton>
        </Tooltip>
    </div>;
}