import { NOTIFICATION_TYPES } from "../../constants/common"
import { acceptAlert, assignTasks, completeTask, createTask, deleteTasks, dismissAlert, getTaskAlerts, getTaskTypes, getTasks, getTimezones, moveTasks, requestTransfer, snoozeAlert } from "../../services/schedule/schedule"

export async function handleGetTasks(taskType, brandId, startOnOrAfter, startOnOrBefore, createdOnOrAfter, createdOnOrBefore, assignedTo, assignedBy, isCompleted, page, pageSize, setTasks, setCount, setIsLoading, setIsError, showNotification) {
    try {
        setIsLoading(true)
        setIsError(false)
        setCount()
        setTasks([])
        const res = await getTasks(taskType, brandId, startOnOrAfter, startOnOrBefore, createdOnOrAfter, createdOnOrBefore, assignedTo, assignedBy, isCompleted, page, pageSize)
        if (Array.isArray(res.tasks)) { setTasks(res.tasks) }
        setCount(res.count)
    } catch {
        setIsError(true)
        showNotification({ message: "Important!. could not fetch tasks. Please try again", type: NOTIFICATION_TYPES.ERROR })
    } finally {
        setIsLoading(false)
    }
}

export async function handleGetTaskAlerts(taskId, setTaskAlerts, setIsLoading, setIsError, showNotification) {
    try {
        setIsLoading(true)
        setIsError(false)
        setTaskAlerts([])
        const res = await getTaskAlerts(taskId)
        if (Array.isArray(res)) { setTaskAlerts(res) }
    } catch {
        setIsError(true)
        showNotification({ message: "Important!. could not fetch task alerts. Please try again", type: NOTIFICATION_TYPES.ERROR })
    } finally {
        setIsLoading(false)
    }
}

export function handleViewTaskPopUp(history, urlString, showNotification) {
    try {
        if (!urlString) return
        const url = new URL(urlString);
        if (urlString && window.location.hostname === url.hostname) {
            history.push(`${url.pathname}${url.search}`)
        } else {
            showNotification({ message: "Important!. Task pop-up is not available for this task", type: NOTIFICATION_TYPES.ERROR })
        }
    } catch (err) {
        showNotification({ message: "Important!. Task pop-up is not available for this task", type: NOTIFICATION_TYPES.ERROR })
    }
}

export async function handleGetTaskTypes(setTaskTypes, setIsTaskTypesLoading, setIsTaskTypesLoadingError, showNotification) {
    try {
        setIsTaskTypesLoading(true)
        setIsTaskTypesLoadingError(false)
        setTaskTypes([])
        const res = await getTaskTypes()
        if (Array.isArray(res)) { setTaskTypes(res) }
    } catch {
        setIsTaskTypesLoadingError(true)
        showNotification({ message: "Important!. could not fetch task types. Please try again", type: NOTIFICATION_TYPES.ERROR })
    } finally {
        setIsTaskTypesLoading(false)
    }
}

export async function handleGetTimezones(setTimezones, setIsTimezonesLoading, setIsTimezonesLoadingError, showNotification) {
    try {
        setIsTimezonesLoading(true)
        setIsTimezonesLoadingError(false)
        setTimezones([])
        const res = await getTimezones()
        if (Array.isArray(res)) { setTimezones(res) }
    } catch {
        setIsTimezonesLoadingError(true)
        showNotification({ message: "Important!. could not fetch timezones. Please try again", type: NOTIFICATION_TYPES.ERROR })
    } finally {
        setIsTimezonesLoading(false)
    }
}

export async function handleCreateTask(task, setIsLoading, onDone, showNotification) {
    try {
        setIsLoading(true)
        const res = await createTask(task)
        showNotification({ message: "Task created successfully", type: NOTIFICATION_TYPES.SUCCESS })
        onDone(res)
    } catch {
        showNotification({ message: "Important!. could not create task. Please try again", type: NOTIFICATION_TYPES.ERROR })
    } finally {
        setIsLoading(false)
    }
}


export async function handleDeleteTasks(tasks, setIsLoading, onDone, showNotification) {
    try {
        if (!Array.isArray(tasks) || tasks?.length === 0) return
        setIsLoading(true)
        await deleteTasks(tasks?.map?.((task) => task.id))
        showNotification({ message: "Tasks deleted successfully", type: NOTIFICATION_TYPES.SUCCESS })
        onDone()
    } catch {
        showNotification({ message: "Important!. could not delete tasks. Please try again", type: NOTIFICATION_TYPES.ERROR })
    } finally {
        setIsLoading(false)
    }
}

export async function handleAssignTasks(requests, setIsLoading, onDone, showNotification) {
    try {
        if (!Array.isArray(requests) || requests?.length === 0) return
        setIsLoading(true)
        const res = await assignTasks(requests)
        showNotification({ message: "Tasks assigned successfully", type: NOTIFICATION_TYPES.SUCCESS })
        onDone(res)
    } catch {
        showNotification({ message: "Important!. could not assign tasks. Please try again", type: NOTIFICATION_TYPES.ERROR })
    } finally {
        setIsLoading(false)
    }
}

export async function handleMoveTasks(tasks, setIsLoading, onDone, showNotification) {
    try {
        if (!Array.isArray(tasks) || tasks?.length === 0) return
        setIsLoading(true)
        await moveTasks(tasks)
        showNotification({ message: "Tasks moved successfully", type: NOTIFICATION_TYPES.SUCCESS })
        onDone()
    } catch {
        showNotification({ message: "Important!. could not move tasks. Please try again", type: NOTIFICATION_TYPES.ERROR })
    } finally {
        setIsLoading(false)
    }
}

export async function handleSnoozeAlert(alertId, snoozedToTimeUTC, onDone, showNotification) {
    try {
        await snoozeAlert(alertId, snoozedToTimeUTC)
        showNotification({ message: "Success. We will notify you in 15 minutes", type: NOTIFICATION_TYPES.SUCCESS })
        onDone()
    } catch {
        showNotification({ message: "Important!. Could not snooze. Please try again", type: NOTIFICATION_TYPES.ERROR })
    }
}

export async function handleAcceptAlert(taskId, alertId, onDone, showNotification) {
    try {
        await acceptAlert(taskId, alertId)
        showNotification({ message: "Task completed", type: NOTIFICATION_TYPES.SUCCESS })
        onDone()
    } catch {
        showNotification({ message: "Important!. Could not complete the task. Please try again", type: NOTIFICATION_TYPES.ERROR })
    }
}

export async function handleDismissAlert(alertId, onDone, showNotification) {
    try {
        await dismissAlert(alertId)
        showNotification({ message: "Task dismissed", type: NOTIFICATION_TYPES.SUCCESS })
        onDone()
    } catch {
        showNotification({ message: "Important!. Could not dismiss the task. Please try again", type: NOTIFICATION_TYPES.ERROR })
    }
}

export async function handleRequestTransfer(taskId, onDone, showNotification) {
    try {
        await requestTransfer(taskId)
        showNotification({ message: "Transfer requested successfully", type: NOTIFICATION_TYPES.SUCCESS })
        onDone()
    } catch {
        showNotification({ message: "Important!. Could not request transfer the task. Please try again", type: NOTIFICATION_TYPES.ERROR })
    }
}

export async function handleTaskComplete(taskId, onDone, showNotification) {
    try {
        await completeTask(taskId)
        showNotification({ message: "Task completed", type: NOTIFICATION_TYPES.SUCCESS })
        onDone()
    } catch {
        showNotification({ message: "Important!. Could not complete the task. Please try again", type: NOTIFICATION_TYPES.ERROR })
    }
}