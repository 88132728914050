import { Table, TableHead, Container, Pagination, TableBody, TableCell, TableContainer, TableRow, IconButton, Tooltip, CircularProgress } from "@mui/material";
import moment from "moment";
import { useState, useEffect } from "react"
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { SERVICETITAN_PAGE_SIZE } from "../../../../../constants/serviceTitan";
import { NotSuccessIcon, SuccessIcon } from "../../../../../icons/common";
import { compareTwoRFCDateStringsDecendingOrder } from "../../../../../utils/compareFunctions";
import { handleGetAllUsers } from "../../../../../state-services-dublicate/userServices";
import { getMatchingUserByUserId } from "../../../../../utils/miscellaneous";
import { getPhoneNumberWithInputMask } from "../../../../../utils/inputMasks";
import { getIsJobComplete, getIsLocationCompleteWithServicebleZipCode, getIsNewCustomerSectionComplete, getIsWorkOrderSectionComplete, isContactValid } from "../../../../../utils/vonigo";
import { handleGetVonigoJobDrafts, handleOnDeleteIconClicked } from "../../../../../state-services-dublicate/vonigo";
import { getExistingCustomerType } from "../../../../../utils/vonigo/miscellaneous";
import useDebounce from "../../../../../hooks/useDebounce";
import ErrorView from "../../../../../components/ErrorView";
import { ERROR_COMPONENTS } from "../../../../../constants/common";

export default function VonigoDraftJobs(props) {
    const { brand, franchise, globalSearchValue, filterState, setJobStatisticsComponent, setNotify, setDraftToContinue, page, setPage } = props
    const [draftJobs, setDraftJobs] = useState([]);
    const [showingDraftJobs, setShowingDraftJobs] = useState([]);
    const [users, setUsers] = useState([])
    const [totalDraftJobsCount, setTotalDraftJobsCount] = useState(0)
    const [isDraftJobsLoading, setIsDraftJobsLoading] = useState(false);
    const [isDraftJobsLoadingError, setIsDraftJobsLoadingError] = useState(false);
    const debouncedHandleGetVonigoJobDrafts = useDebounce({ brandId: brand?.id, franchiseId: franchise.id, createdStart: filterState.createdStart, createdEnd: filterState.createdEnd, page: page }, ({ brandId, franchiseId, createdStart, createdEnd, page }) => {
        handleGetVonigoJobDrafts(brandId, franchiseId, createdStart, createdEnd, page, SERVICETITAN_PAGE_SIZE, setDraftJobs, setTotalDraftJobsCount, setIsDraftJobsLoading, setIsDraftJobsLoadingError)
    }, 1000)
    const debounceHandleGetAllUsers = useDebounce(undefined, () => {
        handleGetAllUsers(setUsers)
    }, 1000)

    useEffect(() => {
        debounceHandleGetAllUsers()
    }, [debounceHandleGetAllUsers])

    useEffect(() => {
        draftJobs?.sort((a, b) => compareTwoRFCDateStringsDecendingOrder(a?.lastUpdatedAt, b?.lastUpdatedAt))
        setShowingDraftJobs([...draftJobs] ?? [])
    }, [draftJobs, setJobStatisticsComponent])

    useEffect(() => {
        setJobStatisticsComponent(<small><strong>{showingDraftJobs?.length} {showingDraftJobs?.length === 1 ? "Draft Job" : "Draft Jobs"}</strong></small>)
    }, [showingDraftJobs?.length, setJobStatisticsComponent])

    useEffect(() => {
        debouncedHandleGetVonigoJobDrafts({ brandId: brand?.id, franchiseId: franchise?.id, createdStart: filterState?.createdStart, createdEnd: filterState?.createdEnd, page: page })
    }, [brand?.id, franchise?.id, filterState?.createdStart, filterState?.createdEnd, page, debouncedHandleGetVonigoJobDrafts])

    function showNotification(notify = {}) {
        setNotify({ ...notify, isOpen: true })
    }

    return <div style={{ height: "60vh" }}>
        <TableContainer sx={{ height: "80%" }}>
            <Table stickyHeader='true' >
                <TableHead>
                    <TableRow>
                        <TableCell style={{ textAlign: "left"}} >#</TableCell>
                        <TableCell style={{ textAlign: "left"}} >Caller ID</TableCell>
                        <TableCell style={{ textAlign: "left"}} >Zip Code</TableCell>
                        <TableCell style={{ textAlign: "center"}}  >Customer</TableCell>
                        <TableCell style={{ textAlign: "center"}}  >Location</TableCell>
                        <TableCell style={{ textAlign: "center"}}  >Contact</TableCell>
                        <TableCell style={{ textAlign: "center"}}  >Job</TableCell>
                        <TableCell style={{ textAlign: "center"}}  >Appointment</TableCell>
                        <TableCell style={{ textAlign: "center"}}  >Completed</TableCell>
                        <TableCell style={{ textAlign: "left"}} >Updated By</TableCell>
                        <TableCell style={{ textAlign: "left"}}  >
                            <Container style={{ padding: 0, cursor: "pointer" }} sx={{ display: "flex", flexDirection: "row", columnGap: "0.5em", alignItems: "center", justifyContent: "left", }} >{"Last Updated At"}</Container>
                        </TableCell>
                        <TableCell style={{ textAlign: "left"}} >Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody >
                    {isDraftJobsLoading ? buildLoadingView() : isDraftJobsLoadingError ? buildErrorView() : showingDraftJobs.length === 0 ? buildNoJobsToShowView() : buildDraftJobRows(showingDraftJobs)}
                </TableBody>
            </Table>
        </TableContainer>
        <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "end" }}>
            <Pagination
                page={page}
                onChange={(e, v) => {
                    setPage(v);
                }} color="standard" count={globalSearchValue ? 1 : Math.ceil(totalDraftJobsCount / SERVICETITAN_PAGE_SIZE)} sx={{ alignSelf: "right" }}
            />
        </div>
    </div>

    function buildDraftJobRows(jobs = []) {
        return jobs.map((draft, i) => buildDraftJobRow(draft, i))
    }

    function buildDraftJobRow(draft, i) {
        const user = getMatchingUserByUserId(draft?.lastUpdatedBy, users)
        const userFullName = `${user?.first_name ?? ""} ${user?.last_name ?? ""}`
        return (
            <TableRow key={i}>
                <TableCell>{i + 1}</TableCell>
                <TableCell>{draft?.callerId ? getPhoneNumberWithInputMask(draft?.callerId) : "-"}</TableCell>
                <TableCell>{draft?.zipCode ? draft?.zipCode : "-"}</TableCell>
                <TableCell style={{ textAlign: "center"}}>{getIsCustomerComplete(draft) ? <SuccessIcon /> : <NotSuccessIcon />}</TableCell>
                <TableCell style={{ textAlign: "center"}}>{getIsCustomerLocationComplete(draft, brand, franchise) ? <SuccessIcon /> : <NotSuccessIcon />}</TableCell>
                <TableCell style={{ textAlign: "center"}}>{getIsContactComplete(draft) ? <SuccessIcon /> : <NotSuccessIcon />}</TableCell>
                <TableCell style={{ textAlign: "center"}}>{getIsJobComplete(draft?.frontEndJobRequest) ? <SuccessIcon /> : <NotSuccessIcon />}</TableCell>
                <TableCell style={{ textAlign: "center"}}>{getIsWorkOrderSectionComplete(draft?.frontEndCreateWorkOrderRequest) ? <SuccessIcon /> : <NotSuccessIcon />}</TableCell>
                <TableCell style={{ textAlign: "center"}}>{!draft?.isDraft ? <SuccessIcon /> : <NotSuccessIcon />}</TableCell>
                <TableCell>{user ? userFullName : "-"}</TableCell>
                <TableCell>{draft?.lastUpdatedAt ? moment(draft?.lastUpdatedAt).format('MM/DD/YYYY - h:mm A') : "-"}</TableCell>
                <TableCell>
                    <IconButton onClick={() => {
                        setDraftToContinue(draft)
                    }} color='info' size={"medium"} style={{ marginRight: "10px", paddingLeft: '0px' }}>
                        <Tooltip title={"Continue"}><EditIcon style={{ height: "20px", width: "20px" }} /></Tooltip>
                    </IconButton>
                    <IconButton onClick={() => handleOnDeleteIconClicked(draft, showingDraftJobs, setShowingDraftJobs, showNotification)} color='error' size={"medium"} style={{ marginRight: "10px" }}>
                        <Tooltip title={"Delete"}><DeleteIcon style={{ height: "20px", width: "20px", color: "red" }} /></Tooltip>
                    </IconButton>
                </TableCell>
            </TableRow>
        );
    }
}

function buildErrorView() {
    return buildInformationRowContainer(<ErrorView type={ERROR_COMPONENTS.SomthingWentWrong.type} />)
}

function buildNoJobsToShowView() {
    return buildInformationRowContainer(<ErrorView type={ERROR_COMPONENTS.NoDraftsFound.type} />)
}

function buildLoadingView() {
    return buildInformationRowContainer(<CircularProgress size={30} color="inherit" />)
}

function buildInformationRowContainer(children) {
    return <TableRow>
        <TableCell colSpan={14} align="center" style={{ border: "none", height: "300px", color: "grey" }}>
            {children}
        </TableCell>
    </TableRow>;
}

function getIsCustomerComplete(draft) {
    return draft?.isNewCustomer ? getIsNewCustomerSectionComplete(draft?.clientCreateRequest) : draft?.frontEndCreateWorkOrderRequest?.clientId
}

function getIsCustomerLocationComplete(draft, brand, franchise) {
    return draft?.isNewCustomer || draft?.isNewLocation ? getIsLocationCompleteWithServicebleZipCode(draft?.frontEndLocationRequest?.location, brand, franchise, undefined, draft?.isWithinServiceArea) : (draft?.frontEndCreateWorkOrderRequest?.locationId && draft?.isWithinServiceArea)
}

function getIsContactComplete(draft) {
    const customerTypeOptionId = draft?.isNewCustomer ? draft?.clientCreateRequest?.typeOptionId : draft?.existingCustomerTypeOptionId
    const customerType = getExistingCustomerType(customerTypeOptionId)
    return draft?.isNewCustomer || draft?.isNewContact ? isContactValid(draft?.frontEndContactRequest, customerType) : draft?.frontEndCreateWorkOrderRequest?.contactId
}
