import { Autocomplete, Box, Button, Dialog, Typography } from "@mui/material";
import DialogHeader from "../../../../components/dialog/DialogHeader";
import DialogBody from "../../../../components/dialog/DialogBody";
import CustomTextField from "../../../../components/CustomTextField";
import { useContext, useEffect, useMemo, useState } from "react";
import { CommonContext } from "../../../../contexts/CommonContextProvider";
import { handleCreateTask, handleGetTaskTypes } from "../../../../state-services/schedule/schedule";
import LoadingButton from "../../../../components/LoadingButton";
import CustomDatePicker from "../../../../components/CustomDatePicker";
import { renderBrandOption } from "../../../../components/servicetitan/RenderInput";
import { getMatchingBrandByBrandId, getMatchingUserByUserId, getUserFullName } from "../../../../utils/miscellaneous";
import { imposibleToMatchRegex, isValidHttpUrl } from "../../../../utils/validators";
import { getDateTimeStringFromDateObject } from "../../../../utils/time/time";
import { NOTIFICATION_TYPES } from "../../../../constants/common";

export default function TaskCreateDialog({ isOpen, onClose }) {

    const { showNotification, brands, allUsers, timezones, isTimezonesLoading, isTimezonesError, generalTenantConfiguration } = useContext(CommonContext)
    const [task, setTask] = useState({ time_zone: generalTenantConfiguration?.time_zone });
    const [taskTypes, setTaskTypes] = useState([]);
    const [isTaskTypesLoading, setIsTaskTypesLoading] = useState(false);
    const [isTaskTypesError, setIsTaskTypesError] = useState(false);
    const [isCreateTaskLoading, setIsCreateTaskLoading] = useState(false);

    const assignableUsers = useMemo(() => {
        const allUsersSorted = [...allUsers]?.sort?.((a, b) => a?.first_name?.localeCompare?.(b?.first_name))
        return allUsersSorted.filter((user) => {
            return !task?.brand_id || (user?.user_id === 0 || (user?.user_brands?.some?.((ub) => ub.brand_id === getMatchingBrandByBrandId(task?.brand_id, brands)?.id) ?? false))
        })
    }, [allUsers, task?.brand_id, brands])

    useEffect(() => {
        if (!isOpen) setTask({ time_zone: generalTenantConfiguration?.time_zone })
    }, [isOpen, generalTenantConfiguration?.time_zone])

    useEffect(() => {
        if (isOpen) {
            handleGetTaskTypes(setTaskTypes, setIsTaskTypesLoading, setIsTaskTypesError, showNotification);
        }
    }, [isOpen, showNotification])

    return <Dialog fullWidth maxWidth="lg" disableScrollLock={true} open={isOpen} >
        <DialogHeader onClose={onClose} className={'dialog-plain-color'}>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center", gap: "0.5em", padding: "1em" }}>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "left", borderRadius: "0.5em", }}>
                    <Typography variant="h5" component={"h1"} fontWeight="bold" >Create a Task</Typography>
                </Box>
                <Box flexGrow={2}></Box>
            </Box>
        </DialogHeader>
        <DialogBody className={'dialog-color'}>
            <form onSubmit={(e) => {
                e?.preventDefault?.()
                if (!task.start_time_utc) {
                    showNotification({ type: NOTIFICATION_TYPES.ERROR, message: "Important: Start Time Required" })
                    return
                }
                handleCreateTask(task, setIsCreateTaskLoading, () => onClose(true), showNotification)
            }} >
                <Box sx={{ height: "350px", display: "flex", flexDirection: "column", gap: "0.5em" }}>
                    <Box sx={{ display: "flex", flexDirection: "row", gap: "0.5em" }}>
                        <Autocomplete
                            sx={{ flexGrow: 2 }}
                            size="small"
                            noOptionsText={isTaskTypesError ? "Something went wrong" : "No Task Types"}
                            disableClearable
                            options={taskTypes}
                            loading={isTaskTypesLoading}
                            getOptionLabel={(taskType) => taskType.task_type}
                            value={taskTypes.find((t) => t.task_type === task.task_type) ?? null}
                            onChange={(_, taskType) => {
                                setTask({ ...task, task_type: taskType?.task_type })
                            }}
                            renderInput={(params) => <CustomTextField {...params} required={true} label="Task Type" />}
                        />
                        <Autocomplete
                            sx={{ flexGrow: 6 }}
                            size="small"
                            options={brands}
                            getOptionLabel={(brand) => brand.brand_name}
                            renderOption={renderBrandOption}
                            value={brands?.length > 0 ? getMatchingBrandByBrandId(task?.brand_id, brands) ?? null : null}
                            onChange={(_, brand) => setTask({ ...task, brand_id: brand?.brand_id })}
                            renderInput={(params) => <CustomTextField {...params} label="Brand" />}
                        />
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row", gap: "0.5em" }}>
                        <Autocomplete
                            sx={{ flexGrow: 4 }}
                            size="small"
                            noOptionsText={isTimezonesError ? "Something went wrong" : "No Timezones"}
                            disableClearable
                            options={timezones}
                            loading={isTimezonesLoading}
                            required={true}
                            getOptionLabel={(timeZone) => timeZone.abbreviation}
                            value={timezones.find((t) => t.time_zone === task.time_zone) ?? null}
                            onChange={(_, timezone) => {
                                setTask({ ...task, time_zone: timezone?.time_zone })
                            }}
                            renderInput={(params) => <CustomTextField {...params} required={true} label="Timezone" />}
                        />
                        <div style={{ flexGrow: 3 }} >
                            <CustomDatePicker
                                minTime={new Date()}
                                label={"Start Time"}
                                required={true}
                                value={task.start_time_utc ? new Date(task?.start_time_utc) : undefined}
                                timeZone={task?.time_zone ? task.time_zone : undefined}
                                onChange={(date) => {
                                    setTask({ ...task, start_time_local: getDateTimeStringFromDateObject(date, task.time_zone), start_time_utc: date?.toISOString?.() })
                                }}
                                clearable={false}
                            />
                        </div>
                        <div style={{ flexGrow: 3 }} >
                            <CustomDatePicker
                                minTime={task?.start_time_utc ? new Date(task?.start_time_utc) : new Date()}
                                required={false}
                                label={"End Time"}
                                value={task?.end_time_utc ? new Date(task?.end_time_utc) : undefined}
                                timeZone={task?.time_zone ? task.time_zone : undefined}
                                onChange={(date) => {
                                    setTask({ ...task, end_time_local: getDateTimeStringFromDateObject(date, task.time_zone), end_time_utc: date?.toISOString?.() })
                                }}
                                clearable={false}
                            />
                        </div>
                    </Box>
                    <CustomTextField size="small" label="Pop-Up URL" value={task?.popup_url} inputProps={{ pattern: (task?.popup_url && !isValidHttpUrl(task?.popup_url)) ? imposibleToMatchRegex : undefined }} error={task?.popup_url && !isValidHttpUrl(task?.popup_url)} helperText={(task?.popup_url && !isValidHttpUrl(task?.popup_url)) ? "Invalid URL" : ""} onChange={(e) => setTask({ ...task, popup_url: e?.target?.value })} />
                    <Autocomplete
                        size="small"
                        options={assignableUsers}
                        getOptionLabel={(user) => getUserFullName(user)}
                        value={assignableUsers?.length > 0 ? getMatchingUserByUserId(task?.assigned_to, assignableUsers) ?? null : null}
                        onChange={(_, user) => setTask({ ...task, assigned_to: user?.user_id })}
                        renderInput={(params) => <CustomTextField {...params} label="Assigned To" />}
                    />
                </Box>
                <Box sx={{ marginBlock: "1em", display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                    <Button size={"small"} variant="contained" onClick={onClose} className={"btn-secondary"} style={{ marginRight: "10px" }} >Cancel</Button>
                    <LoadingButton type={"submit"} size={"small"} variant="contained" loading={isCreateTaskLoading} className={"btn-primary"} >Create</LoadingButton>
                </Box>
            </form>
        </DialogBody>
    </Dialog >
}