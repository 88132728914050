import { Box, Dialog, IconButton, Tooltip, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { createTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { behaviourImpactLabels, callEnjoymentLabels, convertCallAnalysisTextToValue, convertCallEnjoyment, convertCustomerBehaviour, determineColor, getSpeedometerLabels } from '../../../other/utils/common';
import Speedometer from '../../../other/home/components/speedometer';
import CustomeSlider from '../../../../components/CustomeSlider';
import { CommonContext } from "../../../../contexts/CommonContextProvider";
import { updateJob } from '../../../../services/jobService';
import { JOB_UPDATE_TYPES, NOTIFICATION_TYPES } from '../../../../constants/common';
import LoadingButton from '../../../../components/LoadingButton';
import { getDesignTokens } from '../../../../contexts/ThemeContextProvider';
import { UserContext } from '../../../../contexts/User';

export default function EditCallLog({ onClose, open, setToggleRefresh, callLog }) {

    const [behaviourImpact, setBehaviourImpact] = useState(0)
    const [callEnjoyment, setCallEnjoyment] = useState(0)
    const [callAnalysis, setCallAnalysis] = useState(0)
    const [loading, setloading] = useState(false)
    const { showNotification } = useContext(CommonContext);

    const { userPreferences } = useContext(UserContext)
    const themeOptions = getDesignTokens(userPreferences.dark_mode_enabled === 1 ? 'dark' : 'light'); // You can also use 'dark' to switch to dark mode
    const theme = createTheme(themeOptions);

    useEffect(() => {
        setBehaviourImpact(convertCustomerBehaviour('toValue', callLog?.customer_behaviour))
        setCallEnjoyment(convertCallEnjoyment('toValue', callLog?.call_enjoyment))
        setCallAnalysis(convertCallAnalysisTextToValue(callLog?.sentimental_analysis))
    }, [callLog])

    const handleBehaviourImpactChange = (e) => {
        setBehaviourImpact(e.target.value);
    };

    const handleCallEnjoymentChange = (e) => {
        setCallEnjoyment(e.target.value);
    }

    const handleSubmit = async () => {
        setloading(true)
        let jobId = callLog?.id
        let body = getEditCallBody()
        try {
            await updateJob(jobId, JOB_UPDATE_TYPES.CHURN, body)
            showNotification({ message: "Details submitted successfully.", type: NOTIFICATION_TYPES.SUCCESS })
            setToggleRefresh((t) => !t)
            onClose()
        } catch (error) {
            showNotification({ message: "Unable to submit details at this time", type: NOTIFICATION_TYPES.ERROR })
        } finally {
            setloading(false)
        }
    }

    function getEditCallBody() {
        return {
            sentiment_id: parseInt(callLog?.sentiment_id),
            sentimental_analysis: callLog?.sentimental_analysis,
            call_enjoyment: convertCallEnjoyment('toText', callEnjoyment),
            customer_behaviour: convertCustomerBehaviour('toText', behaviourImpact),
            is_churn: callStatus()
        }
    }

    function callStatus() {
        let is_churn = 0
        const call_enjoyment = convertCallEnjoyment('toText', callEnjoyment)
        const customer_behaviour = convertCustomerBehaviour('toText', behaviourImpact)
        if ((callLog?.sentimental_analysis === 'Angry' || callLog?.sentimental_analysis === 'Upset') && (call_enjoyment === "Bad" || call_enjoyment === 'Dull') && (customer_behaviour === "Rude" || customer_behaviour === 'Awkward')) {
            is_churn = 1
        } else {
            is_churn = 0
        }
        return is_churn
    }

    return (
        <Dialog open={open} maxWidth="md">
            <Box className={"dialog"} style={{ width: "800px" }}>
                <IconButton onClick={() => { onClose() }} style={{ position: "absolute", right: "10px", top: "10px" }} size={"small"} aria-label="delete">
                    < CloseIcon style={{ height: "15px", width: "15px" }} />
                </IconButton>
                <h3 className={"dialog-title"}>Edit Agent Feedback</h3>
                {callLog?.sentimental_analysis &&
                    <Speedometer
                        data={callAnalysis}
                        width={400}
                        height={500}
                        minValue={0}
                        maxValue={100}
                        neddleColor='#2196f3'
                        textColor={theme?.palette?.text?.primary}
                        needleTransitionDuration={3333}
                        needleTransition='easeBounceInOut'
                        title='Customer Satisfaction'
                        labels={getSpeedometerLabels(theme?.palette?.text?.primary)}
                    />
                }
                <Box sx={{ display: "flex", flexDirection: "column", width: "auto", padding: "10px 40px 10px 20px", marginBottom: "10px", gap: "10px" }}>
                    <Box sx={{ display: "flex", flexDirection: "row", gap: "0.5em" }}>
                        <Typography variant='subtitle1' gutterBottom>
                            Customer Behaviour
                        </Typography>
                        <Tooltip placement='right' title="Enter the customer behaviour">
                            <IconButton size="small">
                                <InfoOutlinedIcon color="info" style={{ height: "20px", width: "20px" }} />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <CustomeSlider
                        value={behaviourImpact}
                        trackColor={determineColor(behaviourImpact)}
                        railColor='gray'
                        thumbColor={determineColor(behaviourImpact)}
                        onChange={handleBehaviourImpactChange}
                        marks={behaviourImpactLabels}
                        min={0}
                        max={80}
                        track="inverted"
                    />
                    <Box sx={{ display: "flex", flexDirection: "row", gap: "0.5em" }}>
                        <Typography variant='subtitle1' gutterBottom>
                            Call Enjoyment
                        </Typography>
                        <Tooltip placement='right' title="Enter the customer enjoyment">
                            <IconButton size="small">
                                <InfoOutlinedIcon color="info" style={{ height: "20px", width: "20px" }} />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <CustomeSlider
                        value={callEnjoyment}
                        trackColor={determineColor(callEnjoyment)}
                        railColor='gray'
                        thumbColor={determineColor(callEnjoyment)}
                        onChange={handleCallEnjoymentChange}
                        min={0}
                        max={80}
                        marks={callEnjoymentLabels}
                        track="inverted"
                    />
                </Box>
                <Box style={{ display: "flex", justifyContent: "end" }}>
                    <LoadingButton
                        size={"medium"}
                        variant="contained"
                        onClick={handleSubmit}
                        loading={loading}
                        className={"btn-primary"}
                        style={{ marginRight: "10px" }}
                    >
                        Submit
                    </LoadingButton>
                </Box>
            </Box>
        </Dialog>
    )
}
