import { Autocomplete, Box, Dialog, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CustomerSection from './components/CustomerSection'
import LocationSection from './components/LocationSection'
import ContactsSection from './components/ContactsSection'
import LeadSection from './components/LeadSection'
import DialogHeader from '../../../../../../components/dialog/DialogHeader'
import DialogBody from '../../../../../../components/dialog/DialogBody'
import { handleGetBusinessUnits, handleGetCallReasons, handleGetCampaigns, handleGetCustomerById, handleGetCustomerContacts, handleGetJobTypes, handleGetSelectedCustomerLocations, handleGetTagTypes } from '../../../../../../state-services-dublicate/serviceTitan'
import { handleGetAllUsers, handleGetCurrentUser } from '../../../../../../state-services-dublicate/userServices'
import { handleGetAllStates } from '../../../../../../state-services/configuration/configuration'
import { handleGetAllBrands } from '../../../../../../state-services-dublicate/brandServices'
import { renderBrandOption } from '../../../../../../components/servicetitan/RenderInput'
import { CustomTextField } from '../../../../jobs/components/customComponents'
import BillingAddressSection from './components/BillingAddressSection'
import { shouldFilterFor360Paintings } from '../../../../jobs/serviceTitanJobs/components/CreateJobSection'


export default function EditLeadModal({ onClose, open, selectedLeadToEdit, selectedFranchise, selectedBrand, showNotification }) {

    const [franchiseSearchCustomers, setFranchiseSearchCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState();
    const [selectedCustomerLocations, setSelectedCustomerLocations] = useState([]);
    const [isSelectedCustomerLocationsLoading, setIsSelectedCustomerLocationsLoading] = useState(false);
    const [selectedCustomerSelectedLocation, setSelectedCustomerSelectedLocation] = useState(null);
    const [selectedCustomerContacts, setSelectedCustomerContacts] = useState([]);
    const [currentlyEditingContactsCount, setCurrentlyEditingContactsCount] = useState(0)

    const [isCampaignsLoading, setIsCampaignsLoading] = useState(false);
    const [isBusinessUnitsLoading, setIsBusinessUnitsLoading] = useState(false);
    const [isJobTypesLoading, setIsJobTypesLoading] = useState(false);
    const [isCallReasonsLoading, setIsCallReasonsLoading] = useState(false);
    const [isTagTypesLoading, setIsTagTypesLoading] = useState(false);

    const [campaigns, setCampaigns] = useState([]);
    const [campaign, setCampaign] = useState();
    const [businessUnits, setBusinessUnits] = useState([]);
    const [businessUnit, setBusinessUnit] = useState();
    const [jobTypes, setJobTypes] = useState([]);
    const [jobType, setJobType] = useState();
    const [callReasons, setCallReasons] = useState([]);
    const [callReason, setCallReason] = useState();
    const [tagTypes, setTagTypes] = useState([]);
    const [users, setUsers] = useState([]);
    const [isUsersLoading, setisUsersLoading] = useState(false)
    const [isCurrentUserLoading, setIsCurrentUserLoading] = useState(false)
    const [selectedTagTypes, setSelectedTagTypes] = useState([]);
    const [createdByUser, setCreatedByUser] = useState(null)
    const [priority, setPriority] = useState(selectedLeadToEdit?.leadResponse?.priority);
    const [states, setStates] = useState([])
    const [brands, setBrands] = useState([])
    const [isBrandsLoading, setIsBrandsLoading] = useState(false)
    const [brand, setBrand] = useState(null)
    const [isEditLead, setIsEditLead] = useState(false)

    useEffect(() => {
        handleGetAllBrands(selectedLeadToEdit?.brandId, null, setBrands, setBrand, setIsBrandsLoading);
        handleGetAllUsers(setUsers, setisUsersLoading);
        handleGetCurrentUser(setCreatedByUser, setIsCurrentUserLoading);
    }, [selectedLeadToEdit?.brandId]);

    useEffect(() => {
        if (selectedFranchise && !isEditLead) {
            const getCampaignCancelFunc = handleGetCampaigns(selectedFranchise?.id, selectedLeadToEdit?.leadResponse?.campaignId, setCampaigns, setCampaign, setIsCampaignsLoading);
            const getBusinessUnitsCancelFunc = handleGetBusinessUnits(selectedFranchise?.id, selectedLeadToEdit?.leadResponse?.businessUnitId, setBusinessUnits, setBusinessUnit, setIsBusinessUnitsLoading, () => { });
            const getTagTypesCancelFunc = handleGetTagTypes(selectedFranchise, selectedLeadToEdit?.leadResponse?.tagTypeIds, setTagTypes, setSelectedTagTypes, setIsTagTypesLoading);
            const getCallReasonsCancelFunc = handleGetCallReasons(selectedFranchise, selectedLeadToEdit?.leadResponse?.callReasonId, setCallReasons, setCallReason, setIsCallReasonsLoading);
            const geteJobTypesCancelFunc = handleGetJobTypes(selectedFranchise?.id, selectedLeadToEdit?.leadResponse?.jobTypeId, true, shouldFilterFor360Paintings(selectedBrand), setJobTypes, setJobType, setIsJobTypesLoading, () => { })
            handleGetAllStates(setStates);
            let getCustomerByIdCancelFunc;
            if (selectedFranchise && selectedLeadToEdit?.leadResponse?.customerId) {
                getCustomerByIdCancelFunc = handleGetCustomerById(selectedFranchise, selectedLeadToEdit?.leadResponse?.customerId, setSelectedCustomer, setFranchiseSearchCustomers);
            }
            return () => {
                getCampaignCancelFunc?.();
                getBusinessUnitsCancelFunc?.();
                getTagTypesCancelFunc?.();
                getCallReasonsCancelFunc?.();
                geteJobTypesCancelFunc?.();
                getCustomerByIdCancelFunc?.();
            }
        }
    }, [isEditLead])

    useEffect(() => {
        if (selectedFranchise && selectedCustomer?.id) {
            const getCustomerLocationCancelFunc = handleGetSelectedCustomerLocations(selectedFranchise?.id, selectedCustomer?.id, setSelectedCustomerLocations, setSelectedCustomerSelectedLocation, setIsSelectedCustomerLocationsLoading);
            const getCustomerContactsCancelFunc = handleGetCustomerContacts(selectedFranchise?.id, selectedCustomer?.id, setSelectedCustomerContacts);
            return () => {
                getCustomerLocationCancelFunc?.();
                getCustomerContactsCancelFunc?.();
            }
        }
    }, [selectedCustomer])

    useEffect(() => {
        if (selectedCustomerLocations?.length > 0) {
            let locations = selectedCustomerLocations.filter(location => location?.id === selectedLeadToEdit?.leadResponse?.locationId)
            setSelectedCustomerSelectedLocation(locations[0])
        }
    }, [selectedCustomerLocations])

    const handleClose = (lead, isEditLead) => onClose(lead, isEditLead)

    function filterLocations(locations) {
        return locations.filter(location => location?.id === selectedLeadToEdit?.leadResponse?.locationId)
    }

    return (
        <>
            <Dialog fullWidth maxWidth="lg" onClose={() => { }} open={open} className="dialog-zindex">
                <DialogHeader onClose={() => { handleClose(null, false) }} className={'dialog-plain-color'}>
                    <Typography variant="h5" component={"h1"} fontWeight="bold" marginTop="1em" marginLeft="1em" >{`Edit Lead #${selectedLeadToEdit?.leadResponse?.id}`} </Typography>
                </DialogHeader>
                <DialogBody className={'dialog-color'}>
                    <Box sx={{ display: 'flex', flexDirection: "column", gap: "3em" }}>
                        <Box>
                            <Box className="form-control" style={{ marginBottom: "1em" }}>
                                <label><Typography variant="subtitle1" >Brand :</Typography></label>
                                <Autocomplete
                                    sx={{ width: "65%", }}
                                    size="small"
                                    disabled={true}
                                    loading={isBrandsLoading}
                                    disablePortal
                                    disableClearable
                                    options={brands}
                                    getOptionLabel={(brand) => brand.brand_name}
                                    renderOption={renderBrandOption}
                                    value={brands.length > 0 ? brand ?? null : null}
                                    onChange={(_, value) => setBrand(value)}
                                    renderInput={(params) => <CustomTextField required={true}  {...params} label="Brand" />}
                                />
                            </Box>
                            <Box className="form-control" style={{ marginBottom: "1em" }}>
                                <label><Typography variant="subtitle1" >Franchise :</Typography></label>
                                <Autocomplete
                                    sx={{ width: "65%", }}
                                    size="small"
                                    disablePortal
                                    disableClearable
                                    disabled={true}
                                    value={selectedFranchise ? selectedFranchise : null}
                                    options={selectedFranchise ? [selectedFranchise] : []}
                                    getOptionLabel={selectedFranchise => selectedFranchise.franchise_name}
                                    renderOption={(props, franchise) => {
                                        return (
                                            <div style={{ position: "relative" }}>
                                                <Typography {...props} component={"p"} key={franchise.id}>
                                                    {franchise.franchise_name}
                                                </Typography>
                                            </div>
                                        );
                                    }}
                                    renderInput={(params) => <CustomTextField required={true} {...params} label="Franchise" />}
                                />
                            </Box>
                        </Box>
                        <Box>
                            <CustomerSection franchise={selectedFranchise} selectedLeadToEdit={selectedLeadToEdit} selectedCustomerContacts={selectedCustomerContacts} customers={franchiseSearchCustomers} selectedCustomer={selectedCustomer} setSelectedCustomer={setSelectedCustomer} showNotification={showNotification}></CustomerSection>
                        </Box>
                        <Box >
                            <LocationSection franchise={selectedFranchise} selectedCustomer={selectedCustomer} locations={filterLocations(selectedCustomerLocations)} setLocations={setSelectedCustomerLocations} isLocationsLoading={isSelectedCustomerLocationsLoading} selectedLocation={selectedCustomerSelectedLocation} setSelectedLocation={setSelectedCustomerSelectedLocation} states={states} showNotification={showNotification}></LocationSection>
                        </Box>
                        <Box>
                            <BillingAddressSection franchise={selectedFranchise} selectedLeadToEdit={selectedLeadToEdit} selectedCustomerContacts={selectedCustomerContacts} states={states} customers={franchiseSearchCustomers} selectedCustomer={selectedCustomer} setSelectedCustomer={setSelectedCustomer} showNotification={showNotification} />
                        </Box>
                        <Box >
                            <ContactsSection franchiseId={selectedFranchise?.id} selectedLeadToEdit={selectedLeadToEdit} selectedCustomer={selectedCustomer} customerId={selectedCustomer?.id} existingContacts={selectedCustomerContacts} setExistingContacts={setSelectedCustomerContacts} showNotification={showNotification} currentlyEditingContactsCount={currentlyEditingContactsCount} setCurrentlyEditingContactsCount={setCurrentlyEditingContactsCount} />
                        </Box>
                        <Box >
                            <LeadSection isEditLead={isEditLead} setIsEditLead={setIsEditLead} selectedCustomer={selectedCustomer} selectedCustomerContacts={selectedCustomerContacts} brandId={selectedLeadToEdit?.brandId} franchise={selectedFranchise} selectedLeadToEdit={selectedLeadToEdit} users={users} createdByUser={createdByUser} priority={priority} setPriority={setPriority} campaigns={campaigns} campaign={campaign} setCampaign={setCampaign} isCampaignsLoading={isCampaignsLoading} isCallReasonsLoading={isCallReasonsLoading} isTagTypesLoading={isTagTypesLoading}
                                jobTypes={jobTypes} jobType={jobType} setJobType={setJobType} isJobTypesLoading={isJobTypesLoading} businessUnit={businessUnit} setBusinessUnit={setBusinessUnit} businessUnits={businessUnits} isBusinessUnitsLoading={isBusinessUnitsLoading} tagTypes={tagTypes} selectedTagTypes={selectedTagTypes} callReasons={callReasons} callReason={callReason} isUsersLoading={isUsersLoading} isCurrentUserLoading={isCurrentUserLoading} showNotification={showNotification} handleClose={handleClose} />
                        </Box>
                    </Box>
                </DialogBody>
            </Dialog>
        </>
    )
}
