import { createContext, useCallback, useState } from 'react';
import { NOTIFICATION_TYPES } from '../constants/common';
import { RIGHT_DOCK_ITEMS } from '../components/RightDock/RightDock';
import { FAQ_TYPE_SECTIONS } from '../pages/dashboard/faq/FaqSection';

export const CommonContext = createContext();

const INITIAL_FAQ_INFO = {
    franchiseId: 0,
    brandId: 0,
    brandPrimaryId: 0,
    keywords: [],
    section: FAQ_TYPE_SECTIONS.BRAND.section
}

const INITIAL_EMAIL_INFO = {
    from: "",
    to: "",
    subject: ""
}

export const INITIAL_PHONE_INFO = {
    from: "",
    to: "",
    franchiseId: 0,
    brandId: 0,
    jobOrLeadId: 0,
    jobOrLead: "",
    estimateCallBack: () => { }
}

export default function CommonContextProvider(props) {
    const [socket, setSocket] = useState()
    const [socketMessage, setSocketMessage] = useState()
    const [adminId, setAdminId] = useState(null)
    const [brands, setBrands] = useState([])
    const [allBrands, setAllBrands] = useState([])
    const [isBrandsLoading, setIsBrandsLoading] = useState(false)
    const [franchises, setFranchises] = useState([])
    const [emailInfo, setEmailInfo] = useState(INITIAL_EMAIL_INFO)
    const [selectedRightDockItem, setSelectedRightDockItem] = useState()
    const [phoneInfo, setPhoneInfo] = useState(INITIAL_PHONE_INFO)
    const [faqInfo, setFaqInfo] = useState(INITIAL_FAQ_INFO)
    const [toDoFilter, setToDoFilter] = useState()
    const [notify, setNotify] = useState({ isOpen: false, type: NOTIFICATION_TYPES.SUCCESS, message: "" })
    const [allUsers, setAllUsers] = useState([])
    const [allUsersIncludeRemovedOnes, setAllUsersIncludeRemovedOnes] = useState([])
    const [sessionId, setSessionId] = useState(null)
    const [keywords, setKeywords] = useState([])
    const [isKeywordsLoading, setIsKeywordsLoading] = useState(false)
    const [isKeywordsError, setIsKeywordsError] = useState(false)
    const [timezones, setTimezones] = useState([])
    const [isTimezonesLoading, setIsTimezonesLoading] = useState(false)
    const [toggleGetAppointments, setToggleGetAppointments] = useState(false)
    const [isTimezonesError, setIsTimezonesError] = useState(false)
    const [generalTenantConfiguration, setGeneralTenantConfiguration] = useState({ time_zone: "EST" })

    const showNotification = useCallback((notify = { message: "", type: NOTIFICATION_TYPES.SUCCESS, isOpen: true }) => {
        setNotify({ ...notify, isOpen: true })
    }, [])

    const popPhoneDialer = useCallback((to, franchiseId, brandId, jobOrLeadId, jobOrLead, estimateCallBack = () => { }) => {
        to = to.substring(0, 10);
        setSelectedRightDockItem(RIGHT_DOCK_ITEMS.PHONE)
        setPhoneInfo({ to: to, franchiseId: franchiseId, brandId: brandId, jobOrLeadId: jobOrLeadId, jobOrLead: jobOrLead, estimateCallBack: estimateCallBack })
    }, [])

    const popFaqClient = useCallback((franchiseId = 0, brandId = 0, brandPrimaryId = 0, keywords = [], section = FAQ_TYPE_SECTIONS.FRANCHISE.section) => {
        setSelectedRightDockItem(RIGHT_DOCK_ITEMS.FAQ)
        setFaqInfo({ franchiseId: franchiseId, brandId: brandId, brandPrimaryId: brandPrimaryId, keywords: keywords, section: section })
    }, [])

    const popToDoClient = useCallback((filter) => {
        setSelectedRightDockItem(RIGHT_DOCK_ITEMS.TODO)
        setToDoFilter(filter)
    }, [])

    return <CommonContext.Provider
        value={{
            socket,
            setSocket,
            adminId,
            setAdminId,
            socketMessage,
            setSocketMessage,
            emailInfo,
            setEmailInfo,
            selectedRightDockItem,
            setSelectedRightDockItem,
            phoneInfo,
            setPhoneInfo,
            faqInfo,
            setFaqInfo,
            toDoFilter,
            setToDoFilter,
            notify,
            setNotify,
            showNotification,
            popPhoneDialer,
            brands,
            setBrands,
            allBrands,
            setAllBrands,
            isBrandsLoading,
            setIsBrandsLoading,
            franchises,
            setFranchises,
            allUsers,
            setAllUsers,
            allUsersIncludeRemovedOnes,
            setAllUsersIncludeRemovedOnes,
            keywords,
            setKeywords,
            isKeywordsLoading,
            setIsKeywordsLoading,
            isKeywordsError,
            setIsKeywordsError,
            popFaqClient,
            popToDoClient,
            sessionId,
            setSessionId,
            timezones,
            setTimezones,
            isTimezonesLoading,
            setIsTimezonesLoading,
            isTimezonesError,
            setIsTimezonesError,
            generalTenantConfiguration,
            setGeneralTenantConfiguration,
            toggleGetAppointments,
            setToggleGetAppointments
        }}>
        {props.children}
    </CommonContext.Provider>
};