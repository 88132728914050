import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CustomTextField = styled(TextField)({
    "& label.Mui-focused": {
        color: "#00a79d",
        fontWeight: "500",
    },
    "& .MuiOutlinedInput-root": {
        "&:hover fieldset": {
            borderColor: "#00a79d",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#00a79d",
        },
    },
});