import { FetchClient } from "../../utils/fetchClient";

export async function getServiceTitanEstimates(franchiseId = 0, createdStart = "", createdEnd = "", status = "open", page = 1, pageSize = 50) {
    return await FetchClient(`/data/service-titan/estimates?franchise_id=${franchiseId}&status=${status}&createdStart=${createdStart}&createdEnd=${createdEnd}&page=${page}&pageSize=${pageSize}`).GET({})
}

export async function getFollowupHistory(estimateID = 0) {
    return await FetchClient(`/data/service-titan/estimate/callbackLogsByEstimateId?estimateId=${estimateID}`).GET({})
}

export async function getServiceTitanEstimateById(franchiseId = 0, estimateID = 0) {
    return await FetchClient(`/data/service-titan/estimate-by-id?franchise_id=${franchiseId}&estimateId=${estimateID}`).GET({})
}

export async function sellServiceTitanEstimate(franchiseId = 0, estimateID = 0, soldById = 0) {
    return await FetchClient(`/data/service-titan/estimate-sell?franchise_id=${franchiseId}&estimateID=${estimateID}&soldByID=${soldById}`).PUT({})
}

export async function dismissServiceTitanEstimate(franchiseId = 0, estimateID = 0) {
    return await FetchClient(`/data/service-titan/estimate-dismiss?franchise_id=${franchiseId}&estimateID=${estimateID}`).PUT({})
}

export async function getServiceTitanEstimatesByJobId(franchiseId = 0, jobId = 0) {
    return await FetchClient(`/data/service-titan/estimates-by-job-id?franchise_id=${franchiseId}&jobId=${jobId}`).GET({})
}

export async function dismissServiceTitanEstimateLog(brandId = 0, franchiseId = 0, estimateID = 0) {
    let body = {
        brand: brandId,
        franchise: franchiseId,
        estimate_id: estimateID,
        is_dismissed: 1

    }
    return FetchClient("/data/service-titan/estimate/dismissLogs").POST({ payload: JSON.stringify(body) })
}

export async function soldServiceTitanEstimateLog(brandId = 0, franchiseId = 0, estimateID = 0) {
    let body = {
        brand: brandId,
        franchise: franchiseId,
        estimate_id: estimateID,
        is_sold: 1

    }
    return FetchClient("/data/service-titan/estimate/soldLogs").POST({ payload: JSON.stringify(body) })
}

export async function SeviceTitanEstimateCallBackLog(brandId = 0, franchiseId = 0, estimateID = 0,outboundCallId=0) {
    let body = {
        brand: brandId,
        franchise: franchiseId,
        estimate_id: estimateID,
        callback_count: 1,
        outbound_call_id:outboundCallId
    }
    return FetchClient("/data/service-titan/estimate/callbackLogs").POST({ payload: JSON.stringify(body) })
}