import { FetchClient } from "../utils/fetchClient";

export async function getUserNotification(userId = 0, from = "", to = "", page = 0, pageSize = 50) {
    return FetchClient(`/notification/notifications?user_id=${userId}&from=${from}&to=${to}&page=${page}&page_size=${pageSize}`).GET({})
}

export const updateViewedNotifications = async (durationType, userId) => {
    return FetchClient(`/notification/notification_message?durationType=${durationType}&user_id=${userId}`).PUT({})
}

export const fetchConnectedAgents = async () => {
    return FetchClient(`/agent/screen-capture`).GET({})
}

export async function markAllAsNotificationRead(userId = 0) {
    return FetchClient(`/notification/notifications/mark-all-as-read?user_id=${userId}`).PUT({})
}

export async function markNotificationAsRead(id = 0, userId = 0) {
    return FetchClient(`/notification/notification/mark-as-read?id=${id}&user_id=${userId}`).PUT({})
}