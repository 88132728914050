import { Avatar, Box, CircularProgress, Dialog, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { CommonContext } from "../../../../contexts/CommonContextProvider";
import { handleGetTaskAlerts, handleViewTaskPopUp } from "../../../../state-services/schedule/schedule";
import DialogHeader from "../../../../components/dialog/DialogHeader";
import { getMatchingBrandByBrandId, getMatchingUserByUserId, getUserFullName } from "../../../../utils/miscellaneous";
import DialogBody from "../../../../components/dialog/DialogBody";
import SmallCard, { SmallCardForBrand } from "../../../../components/servicetitan/SmallCard";

import CallMadeIcon from '@mui/icons-material/CallMade';
import RefreshErrorView from "../../../../components/refreshView/RefreshErrorView";
import TaskAlertHistory from "./TaskAlertHistory";
import { useHistory } from "react-router-dom";
import { getTenantTimeZoneTimeString } from "../../../../utils/time/time";


export default function TaskViewDialog({ isOpen, task, onClose }) {
    const { showNotification, allBrands, allUsers, timezones, generalTenantConfiguration } = useContext(CommonContext)
    const [taskAlerts, setTaskAlerts] = useState([])
    const [isTaskAlertsLoading, setIsTaskAlertsLoading] = useState(false)
    const [isTaskAlertsError, setIsTaskAlertsError] = useState(false)
    const [toggleRefreshTaskAlerts, setToggleRefreshTaskAlerts] = useState(false)
    const history = useHistory()
    useEffect(() => {
        if (isOpen) handleGetTaskAlerts(task?.id, setTaskAlerts, setIsTaskAlertsLoading, setIsTaskAlertsError, showNotification)
    }, [isOpen, task?.id, showNotification, toggleRefreshTaskAlerts])


    return <Dialog fullWidth maxWidth="lg" disableScrollLock={true} open={isOpen} >
        <DialogHeader onClose={onClose} className={'dialog-plain-color'}>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center", gap: "0.5em", padding: "1em" }}>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "left", borderRadius: "0.5em", }}>
                    <Typography variant="h5" component={"h1"} fontWeight="bold" >{`Task #${task?.id} `}</Typography>
                </Box>
                <Box flexGrow={2}></Box>
                <Avatar>
                    <Tooltip title={task?.popup_url ? "Show task pop-up" : ""}>
                        <IconButton
                            className={task?.popup_url ? "btn-primary" : "btn-disable"}
                            disabled={!Boolean(task?.popup_url)}
                            onClick={(e) => handleViewTaskPopUp(history, task?.popup_url, showNotification)}
                        >
                            <CallMadeIcon />
                        </IconButton>
                    </Tooltip>
                </Avatar>
            </Box>
        </DialogHeader>
        <DialogBody className={'dialog-color'}>
            <Paper sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "start", gap: "0.5em", padding: "0.5em", borderRadius: "0.5em", width: "100%", justifyContent: "space-evenly", }}>
                <SmallCard heading="Task Type" value={task?.task_type ?? "-"}></SmallCard>
                <SmallCardForBrand heading="Brand" brand={getMatchingBrandByBrandId(task?.brand_id, allBrands)} />
                <SmallCard heading="Start Time" value={getTenantTimeZoneTimeString(task?.start_time_utc, generalTenantConfiguration?.time_zone, timezones)} />
                <SmallCard heading="End Time" value={task?.end_time_utc ? getTenantTimeZoneTimeString(task?.end_time_utc, generalTenantConfiguration?.time_zone, timezones) : "-"} />
                <SmallCard heading="Status" value={task?.is_completed ? "Completed" : "Not Completed"} />
                <SmallCard heading="Completed At" value={task?.is_completed ? getTenantTimeZoneTimeString(task?.completed_at, generalTenantConfiguration?.time_zone, timezones) : "-"} />
                <SmallCard heading="Date Created" value={getTenantTimeZoneTimeString(task?.created_at, generalTenantConfiguration?.time_zone, timezones)} />
                <SmallCard heading="Created By" value={task?.created_by ? getUserFullName(getMatchingUserByUserId(task?.created_by, allUsers)) : "System"} />
                <SmallCard heading="Assigned By" value={task?.assigned_by ? getUserFullName(getMatchingUserByUserId(task?.assigned_by, allUsers)) : "-"} />
            </Paper>
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "stretch", gap: "0.5em", borderRadius: "0.5em", marginBlock: "1em" }}>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "0.25em", padding: "0.25em", }}>
                <Typography style={{ fontSize: "1.5rem", fontWeight: "bold" }} >Task Alerts</Typography>
                <Box sx={{ display: "flex", flexDirection: "row", padding: "0.25em", gap: "1em" }} >
                    {isTaskAlertsLoading || isTaskAlertsError ? <Box sx={{ width: "100%", margin: "1em", display: "flex", justifyContent: "center" }} >{isTaskAlertsLoading ? <CircularProgress /> : <RefreshErrorView onRefresh={() => setToggleRefreshTaskAlerts(!toggleRefreshTaskAlerts)} />}</Box> : <TaskAlertHistory taskAlerts={taskAlerts} task={task} />}
                </Box>
            </Box>
        </DialogBody>
    </Dialog >
}