import { Card, CardContent, CardMedia, Typography } from '@mui/material'
import React from 'react'
import { ERROR_COMPONENTS } from '../constants/common'

// images
import SomthingWentWrongImg from '../assets/img/error_images/somthing-went-wrong.png'
import NotFoundImg from '../assets/img/error_images/no-items.png'
import RouteNotFound from '../assets/img/error_images/404.png'

export default function ErrorView({ type, title, description, background }) {
    const getTitleHandler = () => {
        switch (type) {
            case ERROR_COMPONENTS.SomthingWentWrong.type:
                return ({
                    title: ERROR_COMPONENTS.SomthingWentWrong.title,
                    description: ERROR_COMPONENTS.SomthingWentWrong.description,
                    image: SomthingWentWrongImg
                })
            case ERROR_COMPONENTS.NoLeadsFound.type:
                return ({
                    title: ERROR_COMPONENTS.NoLeadsFound.title,
                    description: ERROR_COMPONENTS.NoLeadsFound.description,
                    image: NotFoundImg
                })
            case ERROR_COMPONENTS.RouteNotFound.type:
                return ({
                    title: ERROR_COMPONENTS.RouteNotFound.title,
                    description: ERROR_COMPONENTS.RouteNotFound.description,
                    image: RouteNotFound
                })
            case ERROR_COMPONENTS.NOT_FOUND:
                return ({
                    title: title,
                    description: description,
                    image: NotFoundImg
                })
            case ERROR_COMPONENTS.NoJobsFound.type:
                return ({
                    title: ERROR_COMPONENTS.NoJobsFound.title,
                    description: ERROR_COMPONENTS.NoJobsFound.description,
                    image: NotFoundImg
                })
            case ERROR_COMPONENTS.NoDraftsFound.type:
                return ({
                    title: ERROR_COMPONENTS.NoDraftsFound.title,
                    description: ERROR_COMPONENTS.NoDraftsFound.description,
                    image: NotFoundImg
                })
            case ERROR_COMPONENTS.NoDataFound.type:
                return ({
                    title: ERROR_COMPONENTS.NoDataFound.title,
                    description: ERROR_COMPONENTS.NoDataFound.description,
                    image: NotFoundImg
                })
            case ERROR_COMPONENTS.NoEstimatesFound.type:
                return ({
                    title: ERROR_COMPONENTS.NoEstimatesFound.title,
                    description: ERROR_COMPONENTS.NoEstimatesFound.description,
                    image: NotFoundImg
                })
            case ERROR_COMPONENTS.NoFollowUpHistoryFound.type:
                return ({
                    title: ERROR_COMPONENTS.NoFollowUpHistoryFound.title,
                    description: ERROR_COMPONENTS.NoFollowUpHistoryFound.description,
                    image: NotFoundImg
                })
            default:
                return ({
                    title: ERROR_COMPONENTS.SomthingWentWrong.title,
                    description: ERROR_COMPONENTS.SomthingWentWrong.description,
                    image: SomthingWentWrongImg
                })
        }
    }

    return (
        <Card sx={{ maxWidth: 600, margin: 'auto', boxShadow: 'none', marginTop: 10, background: 'none' }}>
            <CardMedia
                component="img"
                alt="card image"
                height="300"
                image={getTitleHandler().image}
            />
            <CardContent sx={{ textAlign: 'center' }}>
                <Typography gutterBottom variant="h6" component="div">
                    {getTitleHandler().title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {getTitleHandler().description}
                </Typography>
            </CardContent>
        </Card>
    )
}
