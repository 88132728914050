import { Autocomplete, Box, FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import CustomTextField from "../../../components/CustomTextField";
import { getById } from "../../../utils/miscellaneous";
import { getFranchiseOptionLabel, renderFranchiseOptionWithStatusAndEncryptionBadge } from "../../dashboard/utils/franchise";
import RenderInput, { renderBrandOption } from "../../../components/servicetitan/RenderInput";
import { useContext, useEffect, useState } from "react";
import { CommonContext } from "../../../contexts/CommonContextProvider";
import { handleGetBrandFranchises } from "../../../state-services/brands";
import { handleFaqs } from "../../../state-services/faq";
import LoadingView from "../../../components/refreshView/LoadingView";
import NoContentView from "../../../components/refreshView/NoContentView";
import FaqCard from "../../dashboard/faq/components/faqCard/FaqCard";
import TemplateFaqCard from "../../dashboard/faq/components/faqCard/TemplateFaqCard";
import { faqGetReqTypes } from "../../../constants/faq";
import { FAQ_TYPE_SECTIONS } from "../../dashboard/faq/FaqSection";

export default function FaqClient(props) {
    const { allBrands, isBrandsLoading, faqInfo, setFaqInfo, keywords, isKeywordsLoading, isKeywordsError, showNotification, franchises: allFranchises } = useContext(CommonContext)
    const [franchises, setFranchises] = useState([])
    const [isFranchisesLoading, setIsFranchisesLoading] = useState(false)
    const [franchiseFaqs, setFranchiseFaqs] = useState([])
    const [isFranchiseFaqsLoading, setIsFranchiseFaqsLoading] = useState(false)
    const [expandedFranchiseFaq, setExpandedFranchiseFaq] = useState()
    const [brandFaqs, setBrandFaqs] = useState([])
    const [isBrandFaqsLoading, setIsBrandFaqsLoading] = useState(false)
    const [expandedBrandFaq, setExpandedBrandFaq] = useState()

    useEffect(() => {
        handleGetBrandFranchises(faqInfo?.brandId, setFranchises, setIsFranchisesLoading)
    }, [faqInfo?.brandId])

    useEffect(() => {
        if (!faqInfo?.brandPrimaryId) {
            setFranchiseFaqs([])
        } else {
            handleFaqs(faqInfo?.brandPrimaryId, faqInfo?.franchiseId, faqGetReqTypes.Franchise, "", faqInfo?.keywords, setIsFranchiseFaqsLoading, setFranchiseFaqs, showNotification, () => { }, 0, 0)
        }
        if (!faqInfo?.brandPrimaryId) {
            setBrandFaqs([])
        } else {
            handleFaqs(faqInfo?.brandPrimaryId, 0, faqGetReqTypes.Brand, "", faqInfo?.keywords, setIsBrandFaqsLoading, setBrandFaqs, showNotification, () => { }, 0, 0)
        }

    }, [faqInfo?.brandPrimaryId, faqInfo?.franchiseId, faqInfo?.keywords, showNotification])

    function getFaqSection() {
        switch (faqInfo.section) {
            case FAQ_TYPE_SECTIONS.BRAND.section:
                return <GetFaqView isFranchiseSelected={false} isLoading={isBrandFaqsLoading} faqs={brandFaqs} expandedFaq={expandedBrandFaq} setExpandedFaq={setExpandedBrandFaq} allFranchises={allFranchises} faqInfo={faqInfo} noOptionsLabel={faqInfo?.brandPrimaryId ? "No FAQs for filtration" : "Please select a brand"} />
            case FAQ_TYPE_SECTIONS.FRANCHISE.section:
                return <GetFaqView isFranchiseSelected={true} isLoading={isFranchiseFaqsLoading} faqs={franchiseFaqs} expandedFaq={expandedFranchiseFaq} setExpandedFaq={setExpandedFranchiseFaq} allFranchises={allFranchises} faqInfo={faqInfo} noOptionsLabel={faqInfo?.franchiseId ? "No FAQs for filtration" : "Please select a franchise"} />
            default:
                return <></>
        }
    }


    return <Box width="100%" height="100%" display="flex" flexDirection="column" >
        <BrandFranchiseFilterArea brands={allBrands} isBrandsLoading={isBrandsLoading} faqInfo={faqInfo} setFaqInfo={setFaqInfo} franchises={franchises} isFranchisesLoading={isFranchisesLoading} keywords={keywords} isKeywordsLoading={isKeywordsLoading} isKeywordsError={isKeywordsError} />
        <Box sx={{ flexShrink: "0", backgroundColor: "primary.main", height: "4px", width: "100%" }} />
        <Box className="tabs" sx={{ marginTop: "0.25em", marginBottom: "0.5em" }}>
            <FormControl>
                <RadioGroup
                    row
                    value={faqInfo?.section}
                    name="type-radio-buttons-group"
                    onChange={(e) => setFaqInfo((info) => {
                        console.log("info", e?.target?.value)
                        return { ...info, section: e?.target?.value }
                    })}>
                    {Object.values(FAQ_TYPE_SECTIONS).map((t) => <FormControlLabel
                        key={t.index}
                        value={t.section}
                        control={<Radio sx={{ '&, &.Mui-checked': { color: '#00a79d', }, "&.Mui-disabled": { color: "grayText" } }} />}
                        label={t.title}
                    />)}
                </RadioGroup>
            </FormControl>
        </Box>
        {getFaqSection()}
    </Box>
}


function GetFaqView({ isFranchiseSelected, isLoading, faqs, expandedFaq, setExpandedFaq, allFranchises, faqInfo, noOptionsLabel }) {
    return <Box sx={{ flexGrow: 2, overflowY: "scroll" }}>
        {isLoading ? <LoadingView />
            : faqs?.length ? faqs.map((faq, i) =>
                faq.is_template_faq ?
                    <TemplateFaqCard i={faq?.id} isFranchiseSelected={isFranchiseSelected} expanded={expandedFaq} setExpanded={setExpandedFaq} faq={faq} allFranchises={allFranchises} isEditEnabled={false} ></TemplateFaqCard>
                    :
                    <FaqCard i={faq?.id} isFranchiseSelected={isFranchiseSelected} expanded={expandedFaq} setExpanded={setExpandedFaq} faq={faq} allFranchises={allFranchises} isEditEnabled={false} />

            )
                : <NoContentView message={noOptionsLabel} />}
    </Box>;
}

function BrandFranchiseFilterArea(props) {
    const { brands, isBrandsLoading, franchises = [], isFranchisesLoading = false, faqInfo, setFaqInfo, keywords = [], isKeywordsLoading, isKeywordsError } = props
    return <Box padding="1em" width="100%" display="flex" flexDirection="column" gap="0.5em" >
        <Box display="flex" flexDirection="row" gap="0.5em" width={"100%"} >
            <Autocomplete
                sx={{ flexGrow: 1 }}
                size="small"
                loading={isBrandsLoading}
                noOptionsText={"No brands"}
                disabled={brands === []}
                disablePortal
                options={brands}
                getOptionLabel={(brand) => brand.brand_name}
                renderOption={renderBrandOption}
                value={getById(faqInfo.brandPrimaryId, brands) ?? null}
                onChange={(_, brand) => {
                    setFaqInfo({ ...faqInfo, brandPrimaryId: brand?.id, brandId: brand?.brand_id, franchiseId: undefined });
                }}
                renderInput={(params) => <CustomTextField {...params} label="Brand" />}
            />
            <Autocomplete
                sx={{ flexGrow: 1 }}
                size="small"
                disabled={franchises === []}
                disablePortal
                loading={isFranchisesLoading}
                noOptionsText={faqInfo?.brandId ? "No franchises" : "Please select a brand"}
                options={franchises}
                getOptionLabel={(franchise) => getFranchiseOptionLabel(franchise)}
                renderOption={renderFranchiseOptionWithStatusAndEncryptionBadge}
                value={getById(faqInfo.franchiseId, franchises) ?? null}
                onChange={(_, franchise) => {
                    setFaqInfo({ ...faqInfo, franchiseId: franchise?.id, section: FAQ_TYPE_SECTIONS.FRANCHISE.section });
                }}
                renderInput={(params) => <CustomTextField  {...params} label="Franchise" />}
            />
        </Box>
        <Box display="flex" flexDirection="row" gap="0.5em" width={"100%"} >
            <Autocomplete
                sx={{ flexGrow: 1 }}
                size="small"
                disablePortal
                multiple={true}
                noOptionsText={(isKeywordsError) ? "Temporary error" : "No matching keywords"}
                loading={(isKeywordsLoading)}
                options={keywords?.map?.((v) => v?.keyword)}
                getOptionLabel={(keyword) => keyword}
                renderOption={(props, keyword) => <RenderInput {...props} key={keyword} content={keyword} />}
                value={faqInfo?.keywords ?? []}
                onChange={(_, keywords) => {
                    setFaqInfo({ ...faqInfo, keywords: keywords });
                }}
                renderInput={(params) => {
                    let trimmedValue = params.inputProps.value?.trimStart();
                    return (
                        <CustomTextField
                            {...params}
                            inputProps={{
                                ...params.inputProps,
                                value: trimmedValue,
                            }}
                            label="Keywords"
                        />
                    )
                }
                }
            />
        </Box>
    </Box>
}