import StarIcon from '@mui/icons-material/StarBorderPurple500';
import moment from "moment";
import { USER_TYPES } from '../../../constants/users';
import { getAllActiveUsers } from '../../../services/userService';
import { getItemsFromLocalStorage } from '../../../utils/miscellaneous';
import { closeConnection, fetchConnectedAgents } from '../../../services/screenMonitoring';
import { getServerConfigurations } from '../../../services/agentScoreService';
import { retry, shouldRetry } from '../../../state-services-dublicate/serviceTitan';

const scoreLevels = {
    HIGH: 80,
    LOW: 35,
}

export const agentTypes = {
    ALL_ACTIVE_8BY8AGENTS: 1,
    ALL_ACTIVE_AGENTS: 2,
    ALL_USERS_INCLUDE_REMOVED: 3,
    ALL_ACTIVE_8BY8AGENTSINCUDESUPERVISORS: 4,
    ALL_ACTIVE_AGENTSINCUDESUPERVISORS: 5,
}

const serverConfigTypes = {
    NEW: "true",
    NOT_NEW: "false"
}

const turnServerErrors = {
    invalidConfigurations: "Turn Server Error"
}

export function mapInfoFromAgents(agents) {
    let agentInfo = []
    agents?.forEach((agent) => {
        let obj = {}
        obj.AgentName = agent?.first_name + " " + agent?.last_name
        obj.AgentId = agent?.eight_by_eight_agent_id
        agentInfo.push(obj)
    })
    return agentInfo
}

export function selectTopAndOtherAgents(data, setTopAgents, setOtherAgents) {
    let agentsWithData = data.filter((data) => data?.data !== null)
    let agentsSortByScore = []
    agentsSortByScore = agentsWithData.sort((a, b) => b?.data.stars - a?.data.stars)
    if (agentsSortByScore.length >= 5) {
        setTopAgents(filterTopAgents(agentsSortByScore))
        setOtherAgents(filterOtherAgents(agentsSortByScore))
    } else {
        setTopAgents(agentsSortByScore.sort((a, b) => b.data.score - a.data.score))
    }
}

export async function handleGetAllUsers(setAllUsers,me) {
    try {
        let data = await getAllActiveUsers(me?.level <=60 ? agentTypes.ALL_ACTIVE_8BY8AGENTS : agentTypes.ALL_ACTIVE_8BY8AGENTSINCUDESUPERVISORS)
        if (Array.isArray(data?.users)) {
            let users = data?.users
            if (me?.level === 60) {
                let myself = {...me, last_name: me?.last_name + " (Me)"}
                users.push(myself)
            }
            setAllUsers(data?.users)
        }
    } catch (error) { }
}

export const getScoreColor = (score) => {
    if (score > scoreLevels.HIGH)
        return "green"
    else if (score > scoreLevels.LOW)
        return "orange"
    if (score < scoreLevels.LOW)
        return "red"
}

export function mapActiveUsersWithAgentData(agentData, allUsers) {
    let dataMap = []
    allUsers?.forEach((user) => {
        if (agentData?.some((data) => data?.user_id === user?.user_id)) {
            let data = agentData?.find((data) => data?.user_id === user?.user_id)
            dataMap?.push({ user: user, data: data })
        } else {
            dataMap?.push({ user: user, data: null })
        }
    })
    return dataMap
}

export function setDatesWhenRangeChanges(setStartDate, setEndDate, dateRange) {
    let date = new Date();
    date.setDate(date.getDate() - 1);
    let endDate = moment(date).format("YYYY-MM-DD");
    setEndDate(endDate);
    date.setDate(date.getDate() - dateRange);
    let startDate = moment(date).format("YYYY-MM-DD");
    setStartDate(startDate);
}

export function handleDateChange(setCurrentAgentData, setCurrentAgentStatucCodeData, setAgentStatusCodeData, setChartData, setChartLabels) {
    setCurrentAgentData(null)
    setCurrentAgentStatucCodeData(null)
    setAgentStatusCodeData([])
    setChartData([])
    setChartLabels([])
}

export function handleCustomeDateChange(setDate, value, setCurrentAgentData, setCurrentAgentStatucCodeData, setAgentStatusCodeData, setChartData, setChartLabels) {
    setCurrentAgentData(null)
    setCurrentAgentStatucCodeData(null)
    setAgentStatusCodeData([])
    setChartData([])
    setChartLabels([])
    const date = moment(value)
    if (!(date?.isValid() && value)) return
    let formattedDate = date.format("YYYY-MM-DD");
    setDate(formattedDate)
}

function filterTopAgents(agentsSortByScore) {
    let agentWithStars = []
    agentsSortByScore.forEach((agent) => {
        if (agent?.data?.stars > 0) {
            agentWithStars.push(agent)
        }
    })
    if (agentWithStars.length === 0) {
        let agentWithStarsSort = agentsSortByScore.sort((a, b) => b?.data?.score - a?.data?.score)
        agentWithStars = agentWithStarsSort.slice(0, 5)
    }
    return agentWithStars
}

function filterOtherAgents(agentsSortByScore) {
    let agentWithoutStars = []
    let agentWithStars = []
    agentsSortByScore.forEach((agent) => {
        if (agent?.data?.stars === 0) {
            agentWithoutStars.push(agent)
        } else {
            agentWithStars.push(agent)
        }
    })
    if (agentWithStars.length === 0) {
        let agentWithoutStarsSort = agentsSortByScore.sort((a, b) => b?.data?.score - a?.data?.score)
        agentWithoutStars = agentWithoutStarsSort.slice(5)
    }
    agentWithoutStars.sort((a, b) => b?.data?.score - a?.data?.score)
    let mergerdArray = agentWithoutStars
    return mergerdArray
}

export function formatDetailsOfCurrentAgent(data, setCurrentAgentData, setCurrentAgentStatucCodeData, setChartLabels, setChartData, currentAgentData, selectedAgent, setSelectedAgent, toggleView) {
    if (getItemsFromLocalStorage("user_level") <= USER_TYPES?.agent?.level) {
        if (data?.some((record) => record?.user?.user_id == localStorage?.getItem("user_id"))) {
            let agent = data?.find((record) => record?.user?.user_id == localStorage?.getItem("user_id"))
            if (agent?.data) {
                setSelectedAgent(agent)
                setCurrentAgentData(agent?.data)
                setCurrentAgentStatucCodeData(agent?.data)
                formatDataForChart(agent?.data, setChartLabels, setChartData, toggleView)
            }
        }
    } else {
        if (!selectedAgent) {
            setAgentData(data, setCurrentAgentData, setCurrentAgentStatucCodeData, formatDataForChart, setChartLabels, setChartData, setSelectedAgent, toggleView)
        } else {
            let agent = data?.find((record) => record?.user?.user_id == selectedAgent?.user?.user_id)
            if (agent?.data !== null) {
                setCurrentAgentData(agent?.data)
                setSelectedAgent(agent)
                setCurrentAgentStatucCodeData(agent?.data)
                formatDataForChart(agent?.data, setChartLabels, setChartData, toggleView)
            }
        }
    }
}

function setAgentData(data, setCurrentAgentData, setCurrentAgentStatucCodeData, formatDataForChart, setChartLabels, setChartData, setSelectedAgent, toggleView) {
    let agentsWithData = data.filter((data) => data?.data !== null)
    if (agentsWithData?.length !== 0) {
        if (agentsWithData?.some((record) => record?.data?.stars !== 0)) {
            let agent = agentsWithData?.sort((a, b) => b?.data?.stars - a?.data?.stars)[0]
            setCurrentAgentData(agent?.data)
            setSelectedAgent(agent)
            setCurrentAgentStatucCodeData(agent?.data)
            formatDataForChart(agent?.data, setChartLabels, setChartData, toggleView)
        } else {
            let agent = agentsWithData?.sort((a, b) => b?.data.score - a?.data.score)[0]
            setCurrentAgentData(agent?.data)
            setSelectedAgent(agent)
            setCurrentAgentStatucCodeData(agent?.data)
            formatDataForChart(agent?.data, setChartLabels, setChartData, toggleView)
        }
    }
}

export function formatDataForChart(data, setChartLabels, setChartData, toggleView) {
    let chartData = []
    let labels = []
    chartData.push(moment.duration(data?.available_time).asSeconds())
    labels.push(`${getLabelPrefix(data?.active_days, toggleView)} Available Time`)
    chartData.push(moment.duration(data?.busy_time).asSeconds())
    labels.push(`${getLabelPrefix(data?.active_days, toggleView)} Busy Time`)
    chartData.push(moment.duration(data?.handling_time).asSeconds())
    labels.push(`${getLabelPrefix(data?.active_days, toggleView)} Handling Time`)
    chartData.push(moment.duration(data?.on_break_time).asSeconds())
    labels.push(`${getLabelPrefix(data?.active_days, toggleView)} On Break Time`)
    chartData.push(moment.duration(data?.working_offline_time).asSeconds())
    labels.push(`${getLabelPrefix(data?.active_days, toggleView)} Working Offline Time`)
    chartData.push(moment.duration(data?.wrap_up_time).asSeconds())
    labels.push(`${getLabelPrefix(data?.active_days, toggleView)} Wrap Up Time`)
    setChartLabels(labels)
    setChartData(chartData)
}

export const getLabelPrefix = (totalWorkingDays, toggleView) => {
    switch (toggleView) {
        case "Average":
            return totalWorkingDays == 1 ? "" : "Average"
        case "Total":
            return totalWorkingDays == 1 ? "" : "Total"
        default:
            return ""
    }
}

export function handleViewAgentStatics(agent, agentStatusCodeData, setCurrentAgentStatucCodeData, setChartLabels, setChartData, setSelectedAgent) {
    if (agent?.data) {
        formatDataForChart(agent?.data, setChartLabels, setChartData)
        agentStatusCodeData?.forEach((record) => {
            if (record?.user?.user_id === agent?.user?.user_id) {
                setCurrentAgentStatucCodeData(record?.data)
                setSelectedAgent(record)
            }
        })
    } else {
        agentStatusCodeData?.forEach((record) => {
            if (record?.user?.user_id === agent?.user?.user_id) {
                setSelectedAgent(record)
            }
        })
        setCurrentAgentStatucCodeData(null)
        setChartData([])
        setChartLabels([])
    }
}

function drawStars(stars) {
    let goldStars = stars;
    let whiteStars = 5 - stars;
    let gold = []
    let white = []
    for (let i = 0; i < goldStars; i++) {
        gold.push(i)
    }
    for (let j = 0; j < whiteStars; j++) {
        white.push(j)
    }
    return {
        gold: gold,
        white: white,
    }
}

export function calculateStarts(agent) {
    if (agent?.stars !== 0) {
        return <div>
            {drawStars(agent?.stars)?.gold?.map((index) => {
                return <StarIcon key={index} style={{ color: "burlywood" }} ></StarIcon>
            })}
            {drawStars(agent?.stars)?.white?.map((index) => {
                return <StarIcon key={index} style={{ color: "white" }} ></StarIcon>
            })}
        </div>
    } else {
        return <></>
    }
}

export function filterData(data, searchValue) {
    if (searchValue !== "") {
        data = data.filter((v, i) => {
            if (`${v?.first_name.replace(/\s/g, '').toLowerCase()}${v?.last_name.replace(/\s/g, '').toLowerCase()}`.includes(searchValue.replace(/\s/g, '').toLowerCase())) {
                return v;
            } else {
                return null;
            }
        })
    }
    return data
}

export async function getAllAgents(setAgentsLoading, setAgents,me ,setServerConfig) {
    try {
        setAgentsLoading(true)
        await handleGetServerConfig(setServerConfig, serverConfigTypes.NOT_NEW)
        let agents = await getAllActiveUsers( me?.level <=60 ? agentTypes.ALL_ACTIVE_AGENTS : agentTypes.ALL_ACTIVE_AGENTSINCUDESUPERVISORS )
        let onlineAgents = await fetchConnectedAgents()
        onlineAgents?.forEach((agent) => {
            agents?.users?.forEach((user) => {
                if (agent?.UserId == user?.user_id && agent?.SessionId.includes("Desktop")) {
                    user.isOnline = true
                    user.session_id = agent?.SessionId
                }
            })
        })
        agents.users.forEach((user) => {
            user.isAdminOnline = true
        })
        setAgentsLoading(false)
        setAgents(sortAgents(agents?.users))
    } catch (error) {
        setAgentsLoading(false)
        setAgents([])
    }
}

export function sortAgents(agents) {
    let allAgents = []
    let onlineAgents = []
    let offlineAgents = []
    agents?.forEach((user) => {
        user?.isOnline ? onlineAgents.push(user) : offlineAgents.push(user)
    })
    onlineAgents?.sort((x, y) => { return (`${x?.first_name.toLowerCase()}${x?.last_name.toLowerCase()}` < `${y?.first_name.toLowerCase()}${y?.last_name.toLowerCase()}`) ? -1 : (`${x?.first_name.toLowerCase()}${x?.last_name.toLowerCase()}` > `${y?.first_name.toLowerCase()}${y?.last_name.toLowerCase()}`) ? 1 : 0; })
    offlineAgents?.sort((x, y) => { return (`${x?.first_name.toLowerCase()}${x?.last_name.toLowerCase()}` < `${y?.first_name.toLowerCase()}${y?.last_name.toLowerCase()}`) ? -1 : (`${x?.first_name.toLowerCase()}${x?.last_name.toLowerCase()}` > `${y?.first_name.toLowerCase()}${y?.last_name.toLowerCase()}`) ? 1 : 0; })
    allAgents = onlineAgents.concat(offlineAgents)
    return allAgents
}

export async function handleGetServerConfig(setServerConfig, configType, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    try {
        let data = await getServerConfigurations(configType)
        await validateTurnServer(data)
        setServerConfig({
            iceServers: data,
            iceCandidatePoolSize: 10,
        })
    } catch (error) {
        if (error = turnServerErrors.invalidConfigurations) {
            if (shouldRetry(backoffConfig) && error?.status !== 400) {
                retry(() => handleGetServerConfig(setServerConfig, serverConfigTypes.NEW, backoffConfig), backoffConfig)
            }
        } else {
            setServerConfig({ iceServers: null, iceCandidatePoolSize: 0 })
        }
    }
}

async function validateTurnServer(turnServer) {
    return new Promise((resolve, reject) => {
        const pc = new RTCPeerConnection({
            iceServers: turnServer,
            iceCandidatePoolSize: 10,
        });
        pc.createDataChannel('valid_turn_server');
        pc.createOffer()
            .then((offer) => pc.setLocalDescription(offer))
            .then(() => {
                let relayCandidates = []
                pc.addEventListener('icecandidate', (event) => {
                    if (event?.candidate?.candidate?.includes('relay')) {
                        relayCandidates.push(event.candidate)
                    }
                });
                const timeoutId = setTimeout(() => {
                    pc.close()
                    reject("Turn Server Error")
                }, 2000);
                pc.addEventListener('icegatheringstatechange', () => {
                    clearTimeout(timeoutId);
                    if (pc.iceGatheringState == "complete") {
                        if (relayCandidates.length !== 0) {
                            pc.close()
                            resolve()
                        } else {
                            pc.close()
                            reject("Turn Server Error")
                        }
                    }
                })
            })
            .catch((error) => {
                pc.close()
                reject("Turn Server Error");
            });
    });
}

export async function checkAgentState(agent) {
    let onlineAgents = await fetchConnectedAgents()
    return onlineAgents?.some((onlineAgent) => onlineAgent?.SessionId.includes("Desktop") && onlineAgent?.SessionId == agent?.session_id)
}

export function handleCloseWhenTabChange(webRtcConnection, adminId) {
    let closingData = {
        message: JSON.stringify({ message_type: "close", body: "" }),
        to_session_id: localStorage.getItem("agent"),
        from_session_id: adminId?.toString(),
    }
    closeConnection(closingData)
    if (webRtcConnection) {
        webRtcConnection.close()
    }
    localStorage.removeItem("agent")
}

export function convertDayToCurrentTimeZone(day) {
    let timeZone = moment().tz("America/Montreal").format("Z")
    let offsetSign = timeZone.charAt(0);
    let offsetHours = parseInt(timeZone.substring(1, 3));
    let offsetMinutes = parseInt(timeZone.substring(4));
    let formattedDate
    if (offsetSign == "+") {
        formattedDate = moment(day).add(offsetHours, 'hours').add(offsetMinutes, 'minutes').format("YYYY-MM-DD HH:mm:ss")
    } else if (offsetSign == "-") {
        formattedDate = moment(day).subtract(offsetHours, 'hours').subtract(offsetMinutes, 'minutes').format("YYYY-MM-DD HH:mm:ss")
    }
    return formattedDate
}

export function sortAgentsAlphabetically(agents) {
    return agents?.sort((a, b) => a?.first_name.localeCompare(b?.first_name))
}

//function to get time difference between two dates
export function getTimeDifference(startDate, endDate) {
    if (endDate) {
        let start = moment(startDate)
        let end = moment(endDate)
        let duration = moment.duration(end.diff(start));
        let hours = parseInt(duration.asHours());
        let minutes = parseInt(duration.asMinutes()) - hours * 60;
        let seconds = parseInt(duration.asSeconds()) - minutes * 60 - hours * 60 * 60;
        if (hours !== 0 && minutes !== 0) {
            return `for ${hours} ` + (hours > 1 ? "hours" : "hour") + ` ${minutes} ` + (minutes > 1 ? "minutes" : "minute") + ` ${seconds} ` + (seconds > 1 ? "seconds" : "second")
        } else if (minutes !== 0) {
            return `for ${minutes} ` + (minutes > 1 ? "minutes" : "minute") + ` ${seconds} ` + (seconds > 1 ? "seconds" : "second")
        } else {
            return `for ${seconds} ` + (seconds > 1 ? "seconds" : "second")
        }
    } else {
        return " "
    }
}
