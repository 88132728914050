import React from 'react'
import { useState, useEffect } from 'react';
import { Box, Accordion,AccordionSummary, IconButton, AccordionDetails, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from "@mui/icons-material/Add";

import { handleGetKeywords } from '../../../../state-services/faq/faq';
import FaqRow from './BrandTemplateFaqRow';
import FaqEditRow from './EditBrandTemplateFaqRow';

let newQuestion = { 
    question: "",
    answer: "",
    keywords: [],
}

export default function BrandTemplateFaqs({ faqs, setFaqs, faqTemplateErros,setFaqTemplateErros,isEditBrand }) {

    const [keywords, setKeywords] = useState([])
    const [isKeywordsLoading, setIsKeywordsLoading] = useState(false)
    const [isKeywordsLoadingError, setIsKeywordsLoadingError] = useState(false)
    const [isAddNewQuestion, setIsAddNewQuestion] = useState(false) 
    const [questionToEdit, setQuestionToEdit] = useState(null)
    const [isOpenDRawer, setIsOpenDRawer] = useState(false) 

    useEffect(() => {
        handleGetKeywords(setKeywords, setIsKeywordsLoading, setIsKeywordsLoadingError)
        return () => {
            setKeywords([])
        }
    }, [])

    function addNewQuestion() {
        let newFaqs = [...faqs]
        let modelQuestion = { 
            ...newQuestion,
            keywords: [] 
        }
        newFaqs.push(modelQuestion)
        let lastIndex = newFaqs.length - 1
        newFaqs[lastIndex].id = lastIndex
        setFaqs(newFaqs)
        setIsAddNewQuestion(true)
        setIsOpenDRawer(true)
    }

    return (
        <Box sx={{ width: "auto", marginBottom: '10px' }}>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon data-test="brand_job_types_expand" />}>
                    <p>Brand Template FAQs</p>
                </AccordionSummary>
                <AccordionDetails sx={{ paddingTop: '0px' }}>
                    <TableContainer component={Paper} sx={{ height: "auto" }} >
                        <Table aria-label="simple table" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell width="35%">Question</TableCell>
                                    <TableCell width="45%" align="left">Answer</TableCell>
                                    <TableCell width="20%" align="left">Keywords</TableCell>
                                    <TableCell width="5%" align="left" sx={{ paddingLeft: '0px' }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: "3em", justifyContent: 'space-between', alignItems: 'center', paddingLeft: '1.6em' }}>
                                            <Box >Action</Box>
                                            <IconButton
                                                data-test="brand_jon_type_add"
                                                title="Add Brand FAQ"
                                                sx={{height: '30px', width: '30px'}}
                                                className={isAddNewQuestion || questionToEdit ? "icon-btn-disabled" : "icon-btn"}
                                                disabled={isAddNewQuestion || questionToEdit}
                                                onClick={() => addNewQuestion()}
                                            >
                                                <AddIcon />
                                            </IconButton>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!questionToEdit ?
                                    faqs?.length > 0 ?
                                    faqs?.map((faq, index) => {
                                        let id = faq?.alreadyInDb ? faq?.id : index
                                        return <FaqRow key={index} id={id} isOpenDRawer={isOpenDRawer} setIsOpenDRawer={setIsOpenDRawer} questionToEdit={questionToEdit} faqTemplateErros={faqTemplateErros} setFaqTemplateErros={setFaqTemplateErros} setQuestionToEdit={setQuestionToEdit} isAddNewQuestion={isAddNewQuestion} setIsAddNewQuestion={setIsAddNewQuestion} faq={faq} faqs={faqs} setFaqs={setFaqs} isKeywordsLoading={isKeywordsLoading} isKeywordsLoadingError={isKeywordsLoadingError} keywords={keywords} />
                                    })
                                    :
                                    <TableCell colSpan={4} align="center" sx={{ width: "100%" }}>No template FAQs found</TableCell>
                                    : faqs?.map((faq, index) => {
                                        let id = faq?.alreadyInDb ? faq?.id : index
                                        return <FaqEditRow key={index} id={id} isAddNewQuestion={isAddNewQuestion} faqTemplateErros={faqTemplateErros} setFaqTemplateErros={setFaqTemplateErros} questionToEdit={questionToEdit}  setQuestionToEdit={setQuestionToEdit} faq={faq} faqs={faqs} setFaqs={setFaqs} isKeywordsLoading={isKeywordsLoading} isKeywordsLoadingError={isKeywordsLoadingError} keywords={keywords} />
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}
