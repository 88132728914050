import { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom"
import { Typography, CircularProgress, Paper, Box, IconButton, Button, Tooltip } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import ServiceTitanJobsLandingPage from "./serviceTitanJobs/ServiceTitanJobsLandingPage";
import AdvanceFilterArea from "./components/AdvanceFilterArea";
import { getAllBrands } from "../../../services/brandService";
import { getMatchingBrandByBrandId, sortBrandsAlphabetically } from "../../../utils/miscellaneous"
import { CRMS, JOB_TABS } from "../../../constants/common"
import VonigoJobsLandingPage from "./vonigo/VonigoJobsLandingPage";
import CreateJobPage from "./create-job-page/CreateJobPage";
import { deleteVonigoJobDraft } from "../../../services/vonigo/jobScheduling";
import { deleteServiceTitanJobDraft, getNonDeletedFranchisesByBrandId } from "../../../services/serviceTitan";
import PleaseSelectBrandAndFranchise from "../../../components/PleaesSelectBrandAndFranchise";
import { CommonContext } from "../../../contexts/CommonContextProvider";
import AgentFeedback from "../../other/home/components/agentFeedback";
import { handleSetAgentFeedbackData } from "../../other/utils/common";
import { handleGetJobById } from "../../../state-services-dublicate/serviceTitan";
import { useHistory } from 'react-router-dom';

function getInitialFilters() {
    let currentDate = new Date();
    const dateTreeMonthBeforeToday = new Date(currentDate.setMonth(currentDate.getMonth() - 3));
    currentDate = new Date();
    const fiveMinutesAfterNow = new Date(currentDate.setMinutes(currentDate.getMinutes() + 5));
    return { createdStart: dateTreeMonthBeforeToday.toISOString(), createdEnd: fiveMinutesAfterNow.toISOString(), jobStatus: "All" };
}

function Jobs() {
    const urlParams = new URLSearchParams(window.location.search);
    const brandId = parseInt(urlParams.get("brandId"));
    const franchiseId = parseInt(urlParams.get("franchiseId"));
    const jobId = urlParams.get("jobId");
    const callerId = urlParams.get("callerId");
    const zipCode = urlParams.get("zipCode");
    const isNew = urlParams.get("isNew");

    const urlPramData = {
        brandId,
        franchiseId,
        callerId,
        zipCode,
        jobId,
        isNew
    }

    const globalSearchValue = ""
    const [brand, setBrand] = useState();
    const [brands, setBrands] = useState([]);
    const [franchise, setFranchise] = useState();
    const [franchises, setFranchises] = useState([]);
    const { showNotification, setNotify, socketMessage } = useContext(CommonContext);
    const [filterState, setFilterState] = useState(getInitialFilters())
    const [isCreateJobPagelOpen, setIsCreateJobPageOpen] = useState(false);
    const [jobStatisticsComponent, setJobStatisticsComponent] = useState(<></>)
    const [tab, setTab] = useState(JOB_TABS.JOBS);
    const [jobToView, setJobToView] = useState();
    const [jobToAddMultipleAppointments, setJobToAddMultipleAppointments] = useState();
    const [appointmentToReschedule, setAppointmentToReschedule] = useState();
    const [selectedJobToView, setSelectedJobToView] = useState()
    const [draftToContinue, setDraftToContinue] = useState()
    const [isBrandsLoading, setIsBrandsLoading] = useState(false);
    const [isFranchisesLoading, setIsFranchisesLoading] = useState(false);
    const [isCreateMultipleWorkOrders, setIsCreateMultipleWorkOrders] = useState(false)
    const [isRescheduleWorkOrder, setIsRescheduleWorkOrder] = useState(false)
    const [workOrderToReschedule, setWorkOrderToReschedule] = useState()
    const [openViewJob, setOpenViewJob] = useState(0)
    const [page, setPage] = useState(1)
    const history = useHistory();
    const [isOpenAgentFeedbackDialog, setIsOpenAgentFeedbackDialog] = useState(false)
    const [agentFeedbackData, setAgentFeedbackData] = useState({ sentimentId: 0, customerScore: 0.0, customerMagnitude: 0.0, agentScore: 0.0, agentMagnitude: 0.0 })

    useEffect(() => {
        if (draftToContinue && franchise && getIsCrmChanged(draftToContinue, franchise)) {
            deletePreviousDraft(draftToContinue)
            setDraftToContinue()
        }
    }, [franchise, draftToContinue])

    useEffect(() => {
        if (socketMessage?.message?.includes("SentimentId")) {
            handleSetAgentFeedbackData(socketMessage, setAgentFeedbackData, setIsOpenAgentFeedbackDialog)
        }
    }, [socketMessage])

    useEffect(() => {
        setPage(1)
    }, [brand, franchise, filterState?.createdStart, filterState?.createdEnd, filterState?.jobStatus, tab])

    useEffect(() => {
        if (franchise && brand && urlPramData?.jobId) {
            handleGetJobById(urlPramData?.franchiseId, urlPramData?.jobId, setJobToView)
        }
    }, [franchise, brand, urlPramData?.jobId, urlPramData?.franchiseId])

    useEffect(() => {
        if (urlPramData.isNew === "true") {
            setIsCreateJobPageOpen(true)
        } else {
            setIsCreateJobPageOpen(false)
        }
    }, [urlPramData.isNew])

    useEffect(() => {
        setIsBrandsLoading(true)
        getAllBrands().then((brandsResponse) => {
            if (Array.isArray(brandsResponse?.brands)) {
                setBrands(sortBrandsAlphabetically(brandsResponse?.brands))
                if (urlPramData.brandId) {
                    const brand = getMatchingBrandByBrandId(urlPramData.brandId, brandsResponse?.brands);
                    setBrand(brand)
                } else {
                    setBrand(brandsResponse?.brands?.[0])
                }
            }
        }).catch(() => {
        }).finally(() => {
            setIsBrandsLoading(false)
        })
    }, [urlPramData.brandId])

    useEffect(() => {
        if (brand) {
            setIsFranchisesLoading(true)
            setFranchises([])
            setFranchise()
            getNonDeletedFranchisesByBrandId(brand?.brand_id).then((franchisesResponse) => {
                if (Array.isArray(franchisesResponse)) {
                    setFranchises(franchisesResponse)
                    if (urlPramData.franchiseId) {
                        const fr = franchisesResponse.find((franchise) => franchise?.id === urlPramData?.franchiseId)
                        setFranchise(fr)
                    }
                } else {
                    setFranchise(undefined)
                    setFranchises([])
                }
            }).catch((error) => {
                setFranchise(undefined)
                setFranchises([])
            }).finally(() => {
                setIsFranchisesLoading(false)
            })
        }
    }, [brand, urlPramData.franchiseId])

    const handleAgentFeedBackDialogClose = () => {
        setIsOpenAgentFeedbackDialog(false)
    }

    const clearUrlParamsData = () => {
        if (urlPramData?.jobId) {
            urlParams.delete("jobId")
            history.push({ pathname: history.location.pathname, search: urlParams.toString() })
        }
    }

    function onCreateJobPageOpen() {
        setIsCreateJobPageOpen(!isCreateJobPagelOpen)
        window.history.pushState({}, undefined, `/jobs?isNew=true&brandId=${brand?.brand_id}&franchiseId=${franchise?.id}&isFromCallWindow=false`);
    }

    function onCreateJobPageClosed(createdJob, createdDraft, reschedued) {
        setIsCreateJobPageOpen(false)
        setIsRescheduleWorkOrder(false)
        setWorkOrderToReschedule()
        setSelectedJobToView()
        setJobToAddMultipleAppointments()
        setDraftToContinue()
        setIsCreateMultipleWorkOrders()
        setAppointmentToReschedule()
        if (createdJob) {
            setJobToView(createdJob)
            setTab(JOB_TABS.JOBS)
        }
        if (reschedued) {
            setJobToView(createdJob)
            setTab(JOB_TABS.JOBS)
        }
        if (createdDraft) {
            setTab(JOB_TABS.DRAFTS)
            setPage(1)
        }
        if (createdJob || createdDraft) {
            onRefreshFilters()
        }
        window.history.pushState({}, undefined, `/jobs?brandId=${brand?.brand_id}&franchiseId=${franchise?.id}`);
    }

    function onRefreshFilters(filter) {
        if (filter) {
            setFilterState(filter)
        } else {
            setFilterState(getInitialFilters())
        }
    }

    function onDraftToContinoue(jobDraft) {
        setDraftToContinue(jobDraft)
        setIsCreateJobPageOpen(true)
    }

    function handleSetIsAddMultipleWorkorders(isMultiple = false) {
        setIsCreateMultipleWorkOrders(isMultiple)
        setJobToAddMultipleAppointments(jobToView)
        setJobToView()
        setIsCreateJobPageOpen(true)
    }

    function handleSetAppointmentToRechedule(appointment) {
        setAppointmentToReschedule(appointment)
        handleSetIsAddMultipleWorkorders(true)
    }

    return <Paper sx={{ position: "relative", display: "flex", flexDirection: "column", padding: "1em", backgroundColor: 'background.main', backgroundImage: "none" }}>
        <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <div>
                <h2 style={{ margin: "0" }} >Jobs</h2>
                <div style={{ marginTop: "10px" }}>
                    {jobStatisticsComponent}
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: 'row', flexWrap: "wrap", justifyContent: "end", gap: "1em", marginLeft: "auto", alignItems: "end" }}>
                <Tooltip title={franchise?.is_enabled === 0 ? "Can not create jobs for inactive franchises" : ""} >
                    <span>
                        <IconButton
                            className={"icon-btn"}
                            data-test="add-job-button"
                            aria-label="create"
                            onClick={onCreateJobPageOpen}>
                            <Tooltip title={"Create a job"}>
                                <AddIcon />
                            </Tooltip>
                        </IconButton>
                    </span>
                </Tooltip>
            </div>
        </Box>
        <AdvanceFilterArea brands={brands} brand={brand} setBrand={setBrand} isBrandsLoading={isBrandsLoading} franchises={franchises} franchise={franchise} setFranchise={setFranchise} isFranchisesLoading={isFranchisesLoading} filterState={filterState} setFilterState={setFilterState} tab={tab} />
        {franchise && brand ? buildTable() : isFranchisesLoading ? <Box margin={"auto"} color={"gray"}><CircularProgress size={30} color="inherit" /></Box> : <PleaseSelectBrandAndFranchise />}
        {isCreateJobPagelOpen ? <CreateJobPage open={isCreateJobPagelOpen} onClose={onCreateJobPageClosed} initialZipCode={zipCode} setOpenViewJob={setOpenViewJob} openViewJob={openViewJob} selectedJobToView={selectedJobToView} jobToAddMultipleAppointments={jobToAddMultipleAppointments} setSelectedJobToView={setSelectedJobToView} brands={brands}
            setBrands={setBrands} isBrandsLoading={isBrandsLoading} brand={brand} workOrderToReschedule={workOrderToReschedule} setWorkOrderToReschedule={setWorkOrderToReschedule} isRescheduleWorkOrder={isRescheduleWorkOrder} setIsRescheduleWorkOrder={setIsRescheduleWorkOrder} isCreateMultipleWorkOrders={isCreateMultipleWorkOrders} setIsCreateMultipleWorkOrders={setIsCreateMultipleWorkOrders} appointmentToReschedule={appointmentToReschedule} setBrand={setBrand}
            isFranchisesLoading={isFranchisesLoading} franchise={franchise} setFranchise={setFranchise} selectedPhoneNumber={urlPramData?.callerId} selectedZipCode={urlPramData?.zipCode} showNotification={showNotification} selectedDraftToContinue={draftToContinue} /> : <></>}
        {isOpenAgentFeedbackDialog ? <AgentFeedback handleAgentFeedbackDialogClose={handleAgentFeedBackDialogClose} open={isOpenAgentFeedbackDialog} agentFeedbackData={agentFeedbackData} /> : <></>}
    </Paper>

    function buildTable() {
        return <>
            <div className={"tabs"} style={{ display: "flex", flexDirection: "row" }} >
                <Box>
                    <Button
                        data-test="jobs_tab"
                        onClick={() => setTab(JOB_TABS.JOBS)}
                        size={"small"}
                        variant="contained"
                        className={tab === JOB_TABS.JOBS ? "btn-primary tab" : "btn-secondary tab"}
                    >
                        Jobs
                    </Button>
                    <Button
                        data-test="draft_jobs_tab"
                        onClick={() => setTab(JOB_TABS.DRAFTS)}
                        size={"small"}
                        variant="contained"
                        className={tab === JOB_TABS.DRAFTS ? "btn-primary tab" : "btn-secondary tab"}
                    >
                        Draft Jobs
                    </Button>
                </Box>
            </div>
            {buildJobView()}
        </>
    }

    function buildJobView() {
        if (franchise && brand) {
            switch (franchise?.service_brand?.trim?.()?.toLowerCase?.()) {
                case CRMS[0]: return <ServiceTitanJobsLandingPage brands={brands} brand={brand} franchise={franchise} globalSearchValue={globalSearchValue} filterState={filterState} clearUrlParamsData={clearUrlParamsData} setJobStatisticsComponent={setJobStatisticsComponent} tab={tab} setTab={setTab} setNotify={setNotify} jobToView={jobToView} setJobToView={setJobToView} setDraftToContinue={onDraftToContinoue} setIsAddMultipleWorkorders={handleSetIsAddMultipleWorkorders} setAppointmentToReschedule={handleSetAppointmentToRechedule} page={page} setPage={setPage} />;
                case CRMS[1]: return <VonigoJobsLandingPage callerId={urlPramData.callerId} zipCode={urlPramData.zipCode} brand={brand} onRefreshFilters={onRefreshFilters} franchise={franchise} openViewJob={openViewJob} setWorkOrderToReschedule={setWorkOrderToReschedule} setIsRescheduleWorkOrder={setIsRescheduleWorkOrder} selectedJobToView={selectedJobToView} setSelectedJobToView={setSelectedJobToView} setIsCreateMultipleWorkOrders={setIsCreateMultipleWorkOrders} globalSearchValue={globalSearchValue} onCreateJobPageOpen={onCreateJobPageOpen} filterState={filterState} setJobStatisticsComponent={setJobStatisticsComponent} tab={tab} setTab={setTab} setNotify={setNotify} jobToView={jobToView} setJobToView={setJobToView} setDraftToContinue={onDraftToContinoue} page={page} setPage={setPage} />
                default: return <Typography>Unrecognised CRM</Typography>
            }
        }
    }
}
export default withRouter(Jobs);

function getIsCrmChanged(draftToContinue, franchise) {
    return draftToContinue?.crm && franchise?.service_brand && draftToContinue?.crm?.trim?.()?.toLowerCase?.() !== franchise?.service_brand?.trim?.()?.toLowerCase?.()
}

async function deletePreviousDraft(draftToContinue) {
    try {
        switch (draftToContinue?.crm?.trim?.()?.toLowerCase?.()) {
            case CRMS[0]: await deleteServiceTitanJobDraft(draftToContinue?.id); break;
            case CRMS[1]: await deleteVonigoJobDraft(draftToContinue?.id); break;
            default: break;
        }
    } catch { }
}