import { FetchClient } from "../../utils/fetchClient";

export async function getChannelIdValidationResponse(channelIds = [], excludeFranchiseId = 0) {
    const channelId = getChannelIdsString(channelIds)
    return FetchClient(`/data/v3/channel-id/validate?channel_id=${channelId}&exclude_franchise_id=${excludeFranchiseId}`).GET({})
}

export async function getFranchiseBrandChannels(brandId = 0, franchiseId = 0, isEnabled) {
    if (isEnabled === undefined) {
        isEnabled = ""
    }
    return FetchClient(`/data/v3/channel-ids?brand_id=${brandId}&franchise_id=${franchiseId}&is_enabled=${isEnabled}`).GET({})
}

function getChannelIdsString(channelIds = []) {
    let channelId = channelIds.join(",")
    let newStr = channelId;
    if (channelId?.endsWith(',')) {
        newStr = channelId.slice(0, channelId?.length - 1);
    }
    return newStr;
}