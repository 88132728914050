import { FetchClient } from "../../utils/fetchClient";

export async function getPhoneStatisticsReport(brandId = "", franchiseId = "", channelId = "", startDate = "", endDate = "") {
    if (!brandId) brandId = ""
    if (!franchiseId) franchiseId = ""
    if (!channelId) channelId = ""
    return FetchClient(`/reports/eight-by-eight/statistics/phone?start_date=${startDate}&end_date=${endDate}&brand_id=${brandId}&franchise_id=${franchiseId}&channel_id=${channelId}`).GET({})
}

export async function getEmailStatisticsReport(brandId = "", startDate = "", endDate = "") {
    if (!brandId) brandId = ""
    return FetchClient(`/reports/eight-by-eight/statistics/email?start_date=${startDate}&end_date=${endDate}&brand_id=${brandId}`).GET({})
}

export async function getSMSStatisticsReport(brandId = "", franchiseId = "", channelId = "", startDate = "", endDate = "") {
    if (!brandId) brandId = ""
    if (!franchiseId) franchiseId = ""
    if (!channelId) channelId = ""
    return FetchClient(`/reports/eight-by-eight/statistics/sms?start_date=${startDate}&end_date=${endDate}&brand_id=${brandId}&franchise_id=${franchiseId}&channel_id=${channelId}`).GET({})
}

export async function getChatStatisticsReport(brandId = "", startDate = "", endDate = "") {
    if (!brandId) brandId = ""
    return FetchClient(`/reports/eight-by-eight/statistics/chat?start_date=${startDate}&end_date=${endDate}&brand_id=${brandId}`).GET({})
}

export async function getVonigoStatisticsReport(brandId = "", franchiseId = "", startDate = "", endDate = "") {
    if (!brandId) brandId = ""
    if (!franchiseId) franchiseId = ""
    return FetchClient(`/reports/vonigo/statistics?start_date=${startDate}&end_date=${endDate}&brand_id=${brandId}&franchise_id=${franchiseId}`).GET({})
}

export async function getServicetitanStatisticsReport(brandId = "", franchiseId = "", startDate = "", endDate = "") {
    if (!brandId) brandId = ""
    if (!franchiseId) franchiseId = ""
    return FetchClient(`/reports/service-titan/statistics?start_date=${startDate}&end_date=${endDate}&brand_id=${brandId}&franchise_id=${franchiseId}`).GET({})
}

export async function getEmailReport(startDate = "", endDate = "", page = "", pageSize = "") {
    return FetchClient(`/reports/eight-by-eight/email?start_date=${startDate}&end_date=${endDate}&page=${page}&page_size=${pageSize}`).GET({})
}

export async function getChatReport(startDate = "", endDate = "", page = "", pageSize = "") {
    return FetchClient(`/reports/eight-by-eight/chat?start_date=${startDate}&end_date=${endDate}&page=${page}&page_size=${pageSize}`).GET({})
}

export async function getPhoneReport(brandId = 0, franchiseId = 0, startDate = "", endDate = "", page = "", pageSize = "") {
    if (!brandId) brandId = ""
    if (!franchiseId) franchiseId = ""
    return FetchClient(`/reports/eight-by-eight/phone?brand_id=${brandId}&franchise_id=${franchiseId}&start_date=${startDate}&end_date=${endDate}&page=${page}&page_size=${pageSize}`).GET({})
}

export async function getSMSReport(brandId = 0, franchiseId = 0, startDate = "", endDate = "", page = "", pageSize = "") {
    if (!brandId) brandId = ""
    if (!franchiseId) franchiseId = ""
    return FetchClient(`/reports/eight-by-eight/sms?brand_id=${brandId}&franchise_id=${franchiseId}&start_date=${startDate}&end_date=${endDate}&page=${page}&page_size=${pageSize}`).GET({})
}

export async function getAllCallsMiddleWearStatistics(brandId = 0, brandBrandId=0 ,franchiseId = 0, franchiseFranchiseId=0 ,agentId = 0, mediaType = "", inboundStartDate = "", inboundEndDate = "", outboundStartDate = "", outboundEndDate = "") {
    return FetchClient(`/data/v3/middlewearStatistics/all?brand_id=${brandId}&brand_Brand_id=${brandBrandId}&franchise_id=${franchiseId}&franchise_Franchise_id=${franchiseFranchiseId}&agent_id=${agentId}&media_type=${mediaType}&inbound_start_date=${inboundStartDate}&inbound_end_date=${inboundEndDate}&outbound_start_date=${outboundStartDate}&outbound_end_date=${outboundEndDate}`).GET({})
}

export async function getInboundCallMiddlewearStatistics(brandId = 0, franchiseId = 0, agentId = 0,mediaType="" ,inboundStartDate = "", inboundEndDate = "") {
    return FetchClient(`/data/v3/middlewearStatistics/inbound?brand_id=${brandId}&franchise_id=${franchiseId}&media_type=${mediaType}&agent_id=${agentId}&inbound_start_date=${inboundStartDate}&inbound_end_date=${inboundEndDate}`).GET({})
}

export async function getOutboundCallsMiddleWearStatistics(brandId = 0, franchiseId = 0, agentId = 0, outboundStartDate = "", outboundEndDate = "") {
    return FetchClient(`/data/v3/middlewearStatistics/outbound?brand_id=${brandId}&franchise_id=${franchiseId}&agent_id=${agentId}&outbound_start_date=${outboundStartDate}&outbound_end_date=${outboundEndDate}`).GET({})
}

export async function getInboundCallCountWithCategory(brandId = 0, franchiseId = 0, agentId = 0, mediaType="" ,inboundStartDate = "", inboundEndDate = "") {
    return FetchClient(`/data/v3/middlewearStatistics/inbound/callCountByCategory?brand_id=${brandId}&franchise_id=${franchiseId}&media_type=${mediaType}&agent_id=${agentId}&inbound_start_date=${inboundStartDate}&inbound_end_date=${inboundEndDate}`).GET({})
}

export async function getAllCallCountWithCategory(brandId = 0, brandBrandId=0 ,franchiseId = 0, franchiseFranchiseId=0 ,agentId = 0, mediaType="" ,inboundStartDate = "", inboundEndDate = "", outboundStartDate = "", outboundEndDate = "") {
    return FetchClient(`/data/v3/middlewearStatistics/all/callCountByCategory?brand_id=${brandId}&brand_Brand_id=${brandBrandId}&franchise_id=${franchiseId}&franchise_Franchise_id=${franchiseFranchiseId}&media_type=${mediaType}&agent_id=${agentId}&inbound_start_date=${inboundStartDate}&inbound_end_date=${inboundEndDate}&outbound_start_date=${outboundStartDate}&outbound_end_date=${outboundEndDate}`).GET({})
}

export async function getOutboundCallCountWithCategory(brandId = 0, franchiseId = 0, agentId = 0, outboundStartDate = "", outboundEndDate = "") {
    return FetchClient(`/data/v3/middlewearStatistics/outbound/callCountByCategory?brand_id=${brandId}&franchise_id=${franchiseId}&agent_id=${agentId}&outbound_start_date=${outboundStartDate}&outbound_end_date=${outboundEndDate}`).GET({})
}