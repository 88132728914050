import { FetchClient } from "../utils/fetchClient";

export const createJob = async (body) => {
    return FetchClient(`/data/jobs`).POST({ payload: JSON.stringify(body) })
}

export const updateJob = async (id, updateType, body) => {
    return FetchClient(`/data/jobs?id=${id}&update_type=${updateType}`).PUT({ payload: JSON.stringify(body) })
}

export const getJobs = async (callType, flaggedStatus, date, page, size,callFor=0, brandIds = []) => {
    let brandIdsStr = brandIds.join(',');
    return FetchClient(`/data/jobs?call_type=${callType}&flagged_status=${flaggedStatus}&date=${date}&page=${page}&size=${size}&call_for=${callFor}&brand_ids=${brandIdsStr}`).GET({})
}

export const getJobById = async (id) => {
    return FetchClient(`/data/jobs?id=${id}`).GET({})
}

export const mapCallJobLead = async (body) => {
    return FetchClient(`/data/jobs/map`).POST({ payload: JSON.stringify(body) })
}


