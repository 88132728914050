import { Autocomplete, Box, Tooltip, Chip, CircularProgress, IconButton, Popper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useRef, useState } from "react"
import { CONFIG_MESSAGES, CREATE_JOB_SECTION_IDS } from "../../../../../constants/jobs";
import { getTimeZoneName } from "../../../../../utils/vonigo";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { handleGetAvailabilities, handleGetJobTypeById, handleGetTechnicians } from "../../../../../state-services-dublicate/serviceTitan";
import CustomDatePicker from "../../../../../components/CustomDatePicker";
import CustomTextField from "../../../../../components/CustomTextField";
// import RenderInput from "../../../../../components/servicetitan/RenderInput";
import { RenderInputForTechnicians } from "../../../../../components/servicetitan/RenderInput";
import RefreshErrorView from "../../../../../components/refreshView/RefreshErrorView";
import ConfigMessage from "../../../../../components/configMessage/configMessage";
import TechnitianPickingConfirmationModal from "./TechnitianPickingConfirmationModal";
import TechnitianPickingCriteriaModal from "./TechnicianCriteriaModal";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';

const ESTIMATE_TECHNITIAN_FIELD_NAME = "estimatetechnician"
const BRAND_CODE_FIELD_NAME = "brandcode"
const IS_ESTIMATE_TECHNITIAN = "yes"
const IS_NO_ESTIMATE_TECHNITIAN = "no"

export default function CreateAppointmentSection(props) {
    const { franchise, brand, zipCode, arrivalWindow, job, appointment, setAppointment, isReschedule, appointmentToReschedule, previouslyScheduledTechnicianIds, setTechnicianCriteria } = props

    return <Box id={CREATE_JOB_SECTION_IDS.APPOINTMENT} style={{ marginBlock: "1em" }} >
        <h4>Appointment Details</h4>
        {<ConfigMessage sectionId={CONFIG_MESSAGES.SERVICETITAN.APPOINTMENT.id} />}
        <CreateAppointmentCard franchise={franchise} setTechnicianCriteria={setTechnicianCriteria} brand={brand} arrivalWindow={arrivalWindow} job={job} appointment={appointment} setAppointment={setAppointment} isReschedule={isReschedule} appointmentToReschedule={appointmentToReschedule} previouslyScheduledTechnicianIds={previouslyScheduledTechnicianIds} zipCode={zipCode} />
    </Box>
}

function getInitialAvailabilityTimeRange(timeZone = "UTC") {
    if (!timeZone) timeZone = "UTC"
    return { start: (moment(new Date()).tz(timeZone))?.toISOString(), end: moment(new Date()).tz(timeZone).add(6, "d").endOf("d").toISOString() }
}

function CreateAppointmentCard(props) {
    const { franchise, brand, arrivalWindow, job, setTechnicianCriteria = ()=>{} ,appointment, setAppointment, appointmentToReschedule, isReschedule, previouslyScheduledTechnicianIds, zipCode } = props
    const [availabilities, setAvailabilities] = useState()
    const [isAvailabilitiesLoading, setIsAvailabilitiesLoading] = useState()
    const [availabilityTimeRange, setAvailabilityDateRange] = useState(getInitialAvailabilityTimeRange(franchise?.time_zone))
    const [isAvailabilitiesError, setIsAvailabilitiesError] = useState()
    const [selectedTimeSlot, setSelectedTimeSlot] = useState()
    const [availabilityToggle, setAvailabilityToggle] = useState()
    const [technicians, setTechnicians] = useState([])
    const [isTechnicianLoading, setIsTechniciansLoading] = useState(false)
    const [isTechniciansLoadingError, setIsTechniciansLoadingError] = useState(false)
    const [isOpenTechnitianConfirmationModal, setIsOpenTechnitianConfirmationModal] = useState(false)
    const [jobType, setJobType] = useState()
    const [autoPickReason, setAutoPickReason] = useState(new Map())
    const [isOpenTechnicianCriteria, setIsOpenTechnicianCriteria] = useState(false)

    useEffect(() => {
        setTechnicianCriteria(autoPickReason)
    }, [autoPickReason])

    useEffect(() => {
        handleGetTechnicians(franchise, [], setTechnicians, setIsTechniciansLoading, setIsTechniciansLoadingError, () => { })
    }, [franchise?.id])

    useEffect(() => {
        if (job?.jobTypeId) {
            return handleGetJobTypeById(franchise, job?.jobTypeId, setJobType)
        }
    }, [franchise?.id, job?.jobTypeId])

    useEffect(() => {
        setSelectedTimeSlot()
        setAppointment()
        setAutoPickReason("")
        const technicianShiftsStartTime = moment(availabilityTimeRange?.start)?.format('YYYY-MM-DD') + "T00:00:00Z";
        const durationString = jobType?.duration ? getDurationStringFromSeconds(jobType?.duration) : "30m"
        handleGetAvailabilities(setIsAvailabilitiesLoading, setIsAvailabilitiesError, franchise?.id, brand?.brand_id, brand?.brand_short_code, availabilityTimeRange?.start, availabilityTimeRange?.end, durationString, technicianShiftsStartTime, setAvailabilities, zipCode); //TODO replace 30m with job duration
    }, [availabilityToggle, brand?.brand_id, franchise?.id, franchise?.time_zone, availabilityTimeRange?.start, availabilityTimeRange?.end, setAppointment, jobType?.duration])

    function processString(str) {
        let lowerCaseString = str.toLowerCase();
        let finalString = lowerCaseString.replace(/[\s\d\W_]/g, '');
        return finalString;
    }

    function addManualTechnicianToMap(manualTechnicianId, techniciansOfTheZone = []) {
        let customFieldMap = new Map()
        let manualTechnician = getSelectedTechnicianFromTechnicianArray(manualTechnicianId, technicians)
        if (manualTechnician?.customFields?.length !== 0) {
            let customeFields = manualTechnician?.customFields
            customeFields.forEach(customField => {
                customFieldMap.set(processString(customField?.name), customField?.value)
            })
            if (customFieldMap.has(BRAND_CODE_FIELD_NAME) || customFieldMap.has(ESTIMATE_TECHNITIAN_FIELD_NAME)) {
                let brandCode = customFieldMap.get(BRAND_CODE_FIELD_NAME)?.replace(/[^a-zA-Z0-9]/g, "")?.toLowerCase()
                let isEstimateTechnitian = customFieldMap.get(ESTIMATE_TECHNITIAN_FIELD_NAME)?.toLowerCase()
                let isZoneTechnician = techniciansOfTheZone?.includes(manualTechnicianId) ? true : false
                if (brandCode === brand?.brand_short_code) {
                    if (isEstimateTechnitian === IS_ESTIMATE_TECHNITIAN) {
                        if (isZoneTechnician) {
                            setAutoPickReason(preMap => ({ ...preMap, [manualTechnician?.name]: "Manually Picked: Estimate technician who is serving for the zone." }))
                            return
                        } else {
                            setAutoPickReason(preMap => ({ ...preMap, [manualTechnician?.name]: "Manually Picked: Estimate technician whose zone is not configured." }))
                            return
                        }
                    } else if (isEstimateTechnitian === IS_NO_ESTIMATE_TECHNITIAN) {
                        if (isZoneTechnician) {
                            setAutoPickReason(preMap => ({ ...preMap, [manualTechnician?.name]: "Manually Picked: non-estimate technician who is serving for the zone." }))
                            return
                        } else {
                            setAutoPickReason(preMap => ({ ...preMap, [manualTechnician?.name]: "Manually Picked: non-estimate technician whose zone is not configured." }))
                            return
                        }
                    } else {
                        if (isZoneTechnician) {
                            setAutoPickReason(preMap => ({ ...preMap, [manualTechnician?.name]: "Manually Picked: Technician whose estimate is not configured and is serving for the zone." }))
                            return
                        } else {
                            setAutoPickReason(preMap => ({ ...preMap, [manualTechnician?.name]: "Manually Picked: Technician whose estimate and zones are not configured." }))
                            return
                        }
                    }
                } else {
                    if (isEstimateTechnitian === IS_ESTIMATE_TECHNITIAN) {
                        if (isZoneTechnician) {
                            setAutoPickReason(preMap => ({ ...preMap, [manualTechnician?.name]: "Manually Picked: Estimate technician whose brand is not configured and is serving for the zone." }))
                            return
                        } else {
                            setAutoPickReason(preMap => ({ ...preMap, [manualTechnician?.name]: "Manually Picked: Estimate technician whose brand and zones are not configured." }))
                            return
                        }
                    } else if (isEstimateTechnitian === IS_NO_ESTIMATE_TECHNITIAN) {
                        if (isZoneTechnician) {
                            setAutoPickReason(preMap => ({ ...preMap, [manualTechnician?.name]: "Manually Picked: non-estimate technician whose brand is not configured and is serving for the zone." }))
                            return
                        } else {
                            setAutoPickReason(preMap => ({ ...preMap, [manualTechnician?.name]: "Manually Picked: non-estimate technician whose brand and zones are not configured." }))
                            return
                        }
                    } else {
                        if (isZoneTechnician) {
                            setAutoPickReason(preMap => ({ ...preMap, [manualTechnician?.name]: "Manually Picked: Non-configured technician who is serving for the zone." }))
                            return
                        } else {
                            setAutoPickReason(preMap => ({ ...preMap, [manualTechnician?.name]: "Manually Picked: Non-configured technician." }))
                            return
                        }
                    }
                }
            } else {
                setAutoPickReason(preMap => ({ ...preMap, [manualTechnician?.name]: "Manually Picked technician" }))
                return
            }
        } else {
            setAutoPickReason(preMap => ({ ...preMap, [manualTechnician?.name]: "Manually Picked technician" }))
            return
        }
    }

    function selectAutopickTechnitian(availableTechnitianIdsInTimeSlot = [], techniciansOfTheZone = []) {
        setAutoPickReason(new Map())
        let zoneTechnicians = [...new Set(techniciansOfTheZone)]
        let brandTechnicians = []
        let estimateTechnicians = []
        let noEstimateTechnicians = []
        let labeledTechnicians = []
        let techniciansWithNoBrandOrEmptyField = []
        let availableTechnicians = technicians?.filter((t) => availableTechnitianIdsInTimeSlot?.includes(t?.id))
        availableTechnicians?.forEach(technitian => {
            for (let customField of technitian?.customFields) {
                let formattedCustomField = processString(customField?.name)
                if (formattedCustomField === BRAND_CODE_FIELD_NAME || formattedCustomField === ESTIMATE_TECHNITIAN_FIELD_NAME) {
                    labeledTechnicians.push(technitian)
                    return;
                }
            }
        })
        availableTechnicians?.forEach(technitian => {
            for (let customField of technitian?.customFields) {
                let formattedCustomField = processString(customField?.name)
                if (formattedCustomField === BRAND_CODE_FIELD_NAME && customField?.value?.replace(/[^a-zA-Z0-9]/g, "")?.toLowerCase() === brand?.brand_short_code) {
                    brandTechnicians.push(technitian)
                    return;
                }
            }
        })
        brandTechnicians.forEach(technitian => {
            technitian?.customFields?.forEach(customField => {
                let formattedCustomField = processString(customField?.name)
                if (formattedCustomField === ESTIMATE_TECHNITIAN_FIELD_NAME && customField?.value?.toLowerCase() === IS_ESTIMATE_TECHNITIAN) {
                    estimateTechnicians.push(technitian)
                } else if (formattedCustomField === ESTIMATE_TECHNITIAN_FIELD_NAME && customField?.value?.toLowerCase() === IS_NO_ESTIMATE_TECHNITIAN) {
                    noEstimateTechnicians.push(technitian)
                }
            })
        })
        if (estimateTechnicians?.length !== 0) {
            let estimateTechniciansIds = estimateTechnicians?.map?.((t) => t?.id)
            //let availableEstimateTechnicians = estimateTechniciansIds?.filter((t) => availableTechnitianIdsInTimeSlot?.includes(t))
            let zoneTechs = estimateTechniciansIds?.filter((t) => zoneTechnicians?.includes(t))
            if (zoneTechs?.length !== 0) {
                if (zoneTechs?.length === 1) {
                    const techName = getSelectedTechnicianFromTechnicianArray(zoneTechs[0], technicians)?.name
                    setAutoPickReason(preMap => ({ ...preMap, [techName]: "Auto-picked: Estimate technician who is serving for the zone." }))
                    return zoneTechs
                } else {
                    return []
                }
            } else {
                if (estimateTechniciansIds?.length === 1) {
                    const techName = getSelectedTechnicianFromTechnicianArray(estimateTechniciansIds[0], technicians)?.name
                    setAutoPickReason(preMap => ({ ...preMap, [techName]: "Auto-picked: Estimate technician whose zone is not configured." }))
                    return estimateTechniciansIds
                } else {
                    let availableEt = technicians?.filter((t) => estimateTechniciansIds?.includes(t?.id))?.map?.((t) => t)
                    let avilableEtInAlphabaticalOrder = availableEt?.sort((a, b) => a?.name?.localeCompare(b?.name))
                    setAutoPickReason(preMap => ({ ...preMap, [avilableEtInAlphabaticalOrder[0]?.name]: "Auto-picked: Estimate technician whose zone is not configured." }))
                    return [avilableEtInAlphabaticalOrder[0]?.id]
                }
            }
        } else if (noEstimateTechnicians?.length !== 0) {
            let noEstimateTechniciansIds = noEstimateTechnicians?.map?.((t) => t?.id)
            let zoneTechs = noEstimateTechniciansIds?.filter((t) => zoneTechnicians?.includes(t))
            if (zoneTechs?.length !== 0) {
                if (zoneTechs?.length === 1) {
                    const techName = getSelectedTechnicianFromTechnicianArray(zoneTechs[0], technicians)?.name
                    setAutoPickReason(preMap => ({ ...preMap, [techName]: "Auto-picked: non-estimate technician who is serving for the zone." }))
                    return zoneTechs
                } else {
                    return []
                }
            } else {
                if (noEstimateTechniciansIds?.length === 1) {
                    const techName = getSelectedTechnicianFromTechnicianArray(noEstimateTechniciansIds[0], technicians)?.name
                    setAutoPickReason(preMap => ({ ...preMap, [techName]: "Auto-picked: non-estimate technician whose zone is not configured." }))
                    return noEstimateTechniciansIds
                } else {
                    return []
                }
            }
        }
        else if (brandTechnicians?.length !== 0 && estimateTechnicians?.length === 0 && noEstimateTechnicians?.length === 0) {
            let brandTechniciansIds = brandTechnicians?.map?.((t) => t?.id)
            //let avilableBrandTechnicians = brandTechniciansIds?.filter((t) => availableTechnitianIdsInTimeSlot?.includes(t))
            let zoneTechs = brandTechniciansIds?.filter((t) => zoneTechnicians?.includes(t))
            if (zoneTechs?.length !== 0) {
                if (zoneTechs?.length === 1) {
                    const techName = getSelectedTechnicianFromTechnicianArray(zoneTechs[0], technicians)?.name
                    setAutoPickReason(preMap => ({ ...preMap, [techName]: "Auto-picked: Technician whose estimate is not configured and is serving for the zone." }))
                    return zoneTechs
                } else {
                    return []
                }
            } else {
                if (brandTechniciansIds?.length === 1) {
                    const techName = getSelectedTechnicianFromTechnicianArray(brandTechniciansIds[0], technicians)?.name
                    setAutoPickReason(preMap => ({ ...preMap, [techName]: "Auto picked: Technician whose estimate and zones are not configured." }))
                    return brandTechniciansIds
                } else {
                    return []
                }
            }
        }
        if (labeledTechnicians?.length !== 0) {
            labeledTechnicians?.forEach(technitian => {
                for (let customField of technitian?.customFields) {
                    let formattedCustomField = processString(customField?.name)
                    if (formattedCustomField === BRAND_CODE_FIELD_NAME && customField?.value?.replace(/[^a-zA-Z0-9]/g, "")?.toLowerCase() == null) {
                        techniciansWithNoBrandOrEmptyField.push(technitian)
                        return;
                    }
                }
            })
            techniciansWithNoBrandOrEmptyField.forEach(technitian => {
                technitian?.customFields?.forEach(customField => {
                    let formattedCustomField = processString(customField?.name)
                    if (formattedCustomField === ESTIMATE_TECHNITIAN_FIELD_NAME && customField?.value?.toLowerCase() === IS_ESTIMATE_TECHNITIAN) {
                        estimateTechnicians.push(technitian)
                    } else if (formattedCustomField === ESTIMATE_TECHNITIAN_FIELD_NAME && customField?.value?.toLowerCase() === IS_NO_ESTIMATE_TECHNITIAN) {
                        noEstimateTechnicians.push(technitian)
                    }
                })
            })
            if (estimateTechnicians?.length !== 0) {
                let estimateTechniciansIds = estimateTechnicians?.map?.((t) => t?.id)
                //let availableEstimateTechnicians = estimateTechniciansIds?.filter((t) => availableTechnitianIdsInTimeSlot?.includes(t))
                let zoneTechs = estimateTechniciansIds?.filter((t) => zoneTechnicians?.includes(t))
                if (zoneTechs?.length !== 0) {
                    if (zoneTechs?.length === 1) {
                        const techName = getSelectedTechnicianFromTechnicianArray(zoneTechs[0], technicians)?.name
                        setAutoPickReason(preMap => ({ ...preMap, [techName]: "Auto-picked: Estimate technician whose brand is not configured and is serving for the zone." }))
                        return zoneTechs
                    } else {
                        return []
                    }
                } else {
                    if (estimateTechniciansIds?.length === 1) {
                        const techName = getSelectedTechnicianFromTechnicianArray(estimateTechniciansIds[0], technicians)?.name
                        setAutoPickReason(preMap => ({ ...preMap, [techName]: "Auto-picked: Estimate technician whose brand and zones are not configured." }))
                        return estimateTechniciansIds
                    } else {
                        let availableEt = technicians?.filter((t) => estimateTechniciansIds?.includes(t?.id))?.map?.((t) => t)
                        let avilableEtInAlphabaticalOrder = availableEt?.sort((a, b) => a?.name?.localeCompare(b?.name))
                        setAutoPickReason(preMap => ({ ...preMap, [avilableEtInAlphabaticalOrder[0]?.name]: "Auto-picked: Estimate technician whose brand and zones are not configured." }))
                        return [avilableEtInAlphabaticalOrder[0]?.id]
                    }
                }
            } else if (noEstimateTechnicians?.length !== 0) {
                let noEstimateTechniciansIds = noEstimateTechnicians?.map?.((t) => t?.id)
                let zoneTechs = noEstimateTechniciansIds?.filter((t) => zoneTechnicians?.includes(t))
                if (zoneTechs?.length !== 0) {
                    if (zoneTechs?.length === 1) {
                        const techName = getSelectedTechnicianFromTechnicianArray(zoneTechs[0], technicians)?.name
                        setAutoPickReason(preMap => ({ ...preMap, [techName]: "Auto-picked: non-estimate technician whose brand is not configured and is serving for the zone." }))
                        return zoneTechs
                    } else {
                        return []
                    }
                } else {
                    if (noEstimateTechniciansIds?.length === 1) {
                        const techName = getSelectedTechnicianFromTechnicianArray(noEstimateTechniciansIds[0], technicians)?.name
                        setAutoPickReason(preMap => ({ ...preMap, [techName]: "Auto-picked: non-estimate technician whose brand and zones are not configured." }))
                        return noEstimateTechniciansIds
                    } else {
                        return []
                    }
                }
            }
            else if (techniciansWithNoBrandOrEmptyField?.length !== 0 && estimateTechnicians?.length === 0 && noEstimateTechnicians?.length === 0) {
                let techniciansWithNoBrandOrEmptyFieldIds = techniciansWithNoBrandOrEmptyField?.map?.((t) => t?.id)
                //let availableTechniciansWithNoBrandOrEmptyField = techniciansWithNoBrandOrEmptyFieldIds?.filter((t) => availableTechnitianIdsInTimeSlot?.includes(t))
                let zoneTechs = techniciansWithNoBrandOrEmptyFieldIds?.filter((t) => zoneTechnicians?.includes(t))
                if (zoneTechs?.length !== 0) {
                    if (zoneTechs?.length === 1) {
                        const techName = getSelectedTechnicianFromTechnicianArray(zoneTechs[0], technicians)?.name
                        setAutoPickReason(preMap => ({ ...preMap, [techName]: "Auto-picked: Non configured technician who is serving for the zone." }))
                        return zoneTechs
                    } else {
                        return []
                    }
                } else {
                    if (techniciansWithNoBrandOrEmptyFieldIds?.length === 1) {
                        const techName = getSelectedTechnicianFromTechnicianArray(techniciansWithNoBrandOrEmptyFieldIds[0], technicians)?.name
                        setAutoPickReason(preMap => ({ ...preMap, [techName]: "Auto-picked: Non-configured technician." }))
                        return techniciansWithNoBrandOrEmptyFieldIds
                    } else {
                        return []
                    }
                }
            } else {
                return []
            }
        } else {
            return []
        }
    }

    return isAvailabilitiesError ? <RefreshErrorView onRefresh={() => setAvailabilityToggle(!availabilityToggle)} />
        : <AvailabilityTable
            franchise={franchise}
            brand={brand}
            arrivalWindow={arrivalWindow}
            processString={processString}
            availabilityTimeRange={availabilityTimeRange}
            setAvailabilityDateRange={setAvailabilityDateRange}
            isAvailabilitiesLoading={isAvailabilitiesLoading}
            availabilities={availabilities}
            appointment={appointment}
            selectAutopickTechnitian={selectAutopickTechnitian}
            setAppointment={setAppointment}
            selectedTimeSlot={selectedTimeSlot}
            setSelectedTimeSlot={setSelectedTimeSlot}
            appointmentToReschedule={appointmentToReschedule}
            technicians={technicians}
            isTechnicianLoading={isTechnicianLoading}
            isTechniciansLoadingError={isTechniciansLoadingError}
            isReschedule={isReschedule}
            previouslyScheduledTechnicianIds={previouslyScheduledTechnicianIds}
            isOpenTechnitianConfirmationModal={isOpenTechnitianConfirmationModal}
            setIsOpenTechnitianConfirmationModal={setIsOpenTechnitianConfirmationModal}
            autoPickReason={autoPickReason}
            setAutoPickReason={setAutoPickReason}
            addManualTechnicianToMap={addManualTechnicianToMap}
            isOpenTechnicianCriteria={isOpenTechnicianCriteria}
            setIsOpenTechnicianCriteria={setIsOpenTechnicianCriteria}
        />
}

function AvailabilityTable(props) {
    const { franchise, brand, processString, arrivalWindow, selectAutopickTechnitian, availabilityTimeRange, setAvailabilityDateRange, isAvailabilitiesLoading, availabilities, appointment,
        setAppointment, selectedTimeSlot, setSelectedTimeSlot, appointmentToReschedule, technicians, isTechnicianLoading, isReschedule, previouslyScheduledTechnicianIds, isOpenTechnitianConfirmationModal, setIsOpenTechnitianConfirmationModal,
        autoPickReason, setAutoPickReason, addManualTechnicianToMap, isOpenTechnicianCriteria, setIsOpenTechnicianCriteria } = props

    const tempTechnitianIds = useRef([])
    const tempTechnitianId = useRef([])
    const message = useRef("")

    function addWeekFormDateRange() {
        const newDateRange = { start: addWeekForDate(availabilityTimeRange?.start), end: addWeekForDate(availabilityTimeRange?.end) }
        onDateRangeChanged(newDateRange)
    }

    function substractWeekFormDateRange() {
        const newDateRange = { start: substractWeekForDate(availabilityTimeRange?.start), end: substractWeekForDate(availabilityTimeRange?.end) }
        onDateRangeChanged(newDateRange)
    }

    function onDateRangeChanged(newRange) {
        setSelectedTimeSlot()
        setAppointment()
        setAvailabilityDateRange(newRange)
    }

    function onSelectedTimeslotChanged(slot, arrivalWindow) {
        let dat = new Date(slot?.start)
        dat.setMinutes(dat.getMinutes() + arrivalWindow);
        let autoArrivalWindowEnd = dat.toISOString()
        setSelectedTimeSlot(slot)
        let technitianIds = selectAutopickTechnitian(slot?.available_technician_ids, slot?.zone_technician_ids)
        setAppointment({ ...appointment, start: slot?.start, end: slot?.end, arrivalWindowStart: slot?.start, arrivalWindowEnd: autoArrivalWindowEnd, technicianIds: technitianIds })
    }

    function onArrivalWindowStartChanged(date) {
        const startDate = date.toISOString()
        const newAppointment = { ...appointment, arrivalWindowStart: startDate }
        setAppointment(newAppointment)
    }

    function onArrivalWindowEndChanged(date) {
        const endDate = date.toISOString()
        setAppointment({ ...appointment, arrivalWindowEnd: endDate });
    }

    function getIsPreviousButtonDisabled() {
        let disablebutton
        if (franchise?.time_zone !== "") {
            disablebutton = moment().tz(franchise?.time_zone)?.format("YYYY-MM-DD") === moment(availabilityTimeRange?.start)?.tz(franchise?.time_zone)?.format("YYYY-MM-DD")
        }
        else {
            disablebutton = moment()?.utc()?.format("YYYY-MM-DD") === moment(availabilityTimeRange?.start)?.utc()?.format("YYYY-MM-DD")
        }
        return disablebutton
    }

    // function filterEstimateTechnitiansForBrand(brandShortCode) {
    //     let labeledTechnitians = []
    //     technicians?.forEach(technitian => {
    //         for (let customField of technitian?.customFields) {
    //             let formattedCustomField = processString(customField?.name)
    //             if (formattedCustomField === BRAND_CODE_FIELD_NAME || formattedCustomField === ESTIMATE_TECHNITIAN_FIELD_NAME) {
    //                 labeledTechnitians.push(technitian)
    //                 return;
    //             }
    //         }
    //     })
    //     if (labeledTechnitians?.length === 0) {
    //         let availableTechnitianIds = technicians?.filter((t) => selectedTimeSlot?.available_technician_ids?.includes(t?.id))?.map?.((t) => t?.id)
    //         return technicians?.length > 0 ? availableTechnitianIds : []
    //     } else {
    //         let brandTechnitians = []
    //         labeledTechnitians.forEach(technitian => {
    //             technitian?.customFields?.forEach(customField => {
    //                 let formattedCustomField = processString(customField?.name)
    //                 if (formattedCustomField === BRAND_CODE_FIELD_NAME && customField?.value?.replace(/[^a-zA-Z0-9]/g, "")?.toLowerCase() === brandShortCode) {
    //                     brandTechnitians.push(technitian)
    //                 }
    //             })
    //         })
    //         if (brandTechnitians?.length === 0) {
    //             labeledTechnitians.forEach(technitian => {
    //                 technitian?.customFields?.forEach(customField => {
    //                     let formattedCustomField = processString(customField?.name)
    //                     if (formattedCustomField === BRAND_CODE_FIELD_NAME && !customField?.value) {
    //                         brandTechnitians.push(technitian)
    //                     }
    //                 })
    //             })
    //         }
    //         let estimateTechnitians = []
    //         labeledTechnitians.forEach(technitian => {
    //             technitian?.customFields?.forEach(customField => {
    //                 let formattedCustomField = processString(customField?.name)
    //                 if (formattedCustomField === ESTIMATE_TECHNITIAN_FIELD_NAME && customField?.value?.toLowerCase() === IS_ESTIMATE_TECHNITIAN) {
    //                     estimateTechnitians.push(technitian)
    //                 }
    //             })
    //         })
    //         let estimateTechnitiansOfBrand = []
    //         brandTechnitians.forEach(technitian => {
    //             technitian?.customFields?.forEach(customField => {
    //                 let formattedCustomField = processString(customField?.name)
    //                 if (formattedCustomField === ESTIMATE_TECHNITIAN_FIELD_NAME && customField?.value?.toLowerCase() === IS_ESTIMATE_TECHNITIAN) {
    //                     estimateTechnitiansOfBrand.push(technitian)
    //                 }
    //             })
    //         })
    //         if (estimateTechnitiansOfBrand?.length !== 0) {
    //             let brandTechnicansButNotEstimateTechnitians = brandTechnitians?.filter((t) => !estimateTechnitiansOfBrand?.includes(t))
    //             let brandTechniciansButNotEstimateTechnitiansIds = brandTechnicansButNotEstimateTechnitians?.filter((t) => selectedTimeSlot?.available_technician_ids?.includes(t?.id))?.map?.((t) => t?.id)
    //             let availableTechnitianIds = estimateTechnitiansOfBrand?.filter((t) => selectedTimeSlot?.available_technician_ids?.includes(t?.id))?.map?.((t) => t?.id)
    //             return availableTechnitianIds.concat(brandTechniciansButNotEstimateTechnitiansIds)
    //         }
    //         else if (estimateTechnitiansOfBrand?.length === 0 && brandTechnitians?.length > 0) {
    //             let availableTechnitianIds = brandTechnitians?.filter((t) => selectedTimeSlot?.available_technician_ids?.includes(t?.id))?.map?.((t) => t?.id)
    //             return availableTechnitianIds;
    //         } else if (estimateTechnitians?.length !== 0) {
    //             let availableTechnitianIds = estimateTechnitians?.filter((t) => selectedTimeSlot?.available_technician_ids?.includes(t?.id))?.map?.((t) => t?.id)
    //             return availableTechnitianIds
    //         } else {
    //             let availableTechnitianIds = technicians?.filter((t) => selectedTimeSlot?.available_technician_ids?.includes(t?.id))?.map?.((t) => t?.id)
    //             return availableTechnitianIds

    //         }
    //     }
    // }

    function checkForNonEstimateTechnitians(technitianIds, zone_technician_ids) {
        let customFieldMap = new Map()
        let newlyAddingTechnitianId = technitianIds.filter(id => !appointment?.technicianIds.includes(id))
        let newlyAddingTechnician = getSelectedTechnicianFromTechnicianArray(newlyAddingTechnitianId[0], technicians)
        if (newlyAddingTechnician?.customFields?.length !== 0) {
            let customeFields = newlyAddingTechnician?.customFields
            customeFields.forEach(customField => {
                customFieldMap.set(processString(customField?.name), customField?.value)
            })
            if (customFieldMap.has(BRAND_CODE_FIELD_NAME) || customFieldMap.has(ESTIMATE_TECHNITIAN_FIELD_NAME)) {
                let brandCode = customFieldMap.get(BRAND_CODE_FIELD_NAME)?.replace(/[^a-zA-Z0-9]/g, "")?.toLowerCase()
                let isEstimateTechnitian = customFieldMap.get(ESTIMATE_TECHNITIAN_FIELD_NAME)?.toLowerCase()
                let isZoneTechnician = zone_technician_ids?.includes(newlyAddingTechnitianId[0])
                if (brandCode === brand?.brand_short_code) {
                    if (isEstimateTechnitian === IS_ESTIMATE_TECHNITIAN) {
                        if (isZoneTechnician) {
                            addManualTechnicianToMap(newlyAddingTechnitianId[0], zone_technician_ids)
                            setAppointment({ ...appointment, technicianIds: technitianIds });
                            return
                        } else {
                            message.current = "Are you sure you want to pick an estimate technician whose zone is not configured?"
                            tempTechnitianIds.current = technitianIds
                            tempTechnitianId.current = newlyAddingTechnitianId
                            setIsOpenTechnitianConfirmationModal(true)
                            return
                        }
                    } else if (isEstimateTechnitian === IS_NO_ESTIMATE_TECHNITIAN) {
                        if (isZoneTechnician) {
                            message.current = "Are you sure you want to pick a non-estimate technician who is serving for the zone?"
                            tempTechnitianIds.current = technitianIds
                            tempTechnitianId.current = newlyAddingTechnitianId
                            setIsOpenTechnitianConfirmationModal(true)
                            return
                        } else {
                            message.current = "Are you sure you want to pick a non-estimate technician whose zone is not configured?"
                            tempTechnitianIds.current = technitianIds
                            tempTechnitianId.current = newlyAddingTechnitianId
                            setIsOpenTechnitianConfirmationModal(true)
                            return
                        }
                    } else {
                        if (isZoneTechnician) {
                            message.current = "Are you sure you want to pick a technician whose estimate is not configured and is serving for the zone?"
                            tempTechnitianIds.current = technitianIds
                            tempTechnitianId.current = newlyAddingTechnitianId
                            setIsOpenTechnitianConfirmationModal(true)
                            return
                        } else {
                            message.current = "Are you sure you want to pick a technician whose estimate and zones are not configured?"
                            tempTechnitianIds.current = technitianIds
                            tempTechnitianId.current = newlyAddingTechnitianId
                            setIsOpenTechnitianConfirmationModal(true)
                            return
                        }
                    }
                } else {
                    if (isEstimateTechnitian === IS_ESTIMATE_TECHNITIAN) {
                        if (isZoneTechnician) {
                            addManualTechnicianToMap(newlyAddingTechnitianId[0], zone_technician_ids)
                            setAppointment({ ...appointment, technicianIds: technitianIds });
                            return
                        } else {
                            message.current = "Are you sure you want to pick an estimate technician whose brand and zones are not configured?"
                            tempTechnitianIds.current = technitianIds
                            tempTechnitianId.current = newlyAddingTechnitianId
                            setIsOpenTechnitianConfirmationModal(true)
                            return
                        }
                    } else if (isEstimateTechnitian === IS_NO_ESTIMATE_TECHNITIAN) {
                        if (isZoneTechnician) {
                            message.current = "Are you sure you want to pick a non-estimate technician whose brand is not configured and is serving for the zone?"
                            tempTechnitianIds.current = technitianIds
                            tempTechnitianId.current = newlyAddingTechnitianId
                            setIsOpenTechnitianConfirmationModal(true)
                            return
                        } else {
                            message.current = "Are you sure you want to pick a non-estimate technician whose brand and zones are not configured?"
                            tempTechnitianIds.current = technitianIds
                            tempTechnitianId.current = newlyAddingTechnitianId
                            setIsOpenTechnitianConfirmationModal(true)
                            return
                        }
                    } else {
                        if (isZoneTechnician) {
                            message.current = "Are you sure you want to pick a non-configured technician who is serving for the zone?"
                            tempTechnitianIds.current = technitianIds
                            tempTechnitianId.current = newlyAddingTechnitianId
                            setIsOpenTechnitianConfirmationModal(true)
                            return
                        } else {
                            message.current = "Are you sure you want to pick a non-configured technician?"
                            tempTechnitianIds.current = technitianIds
                            tempTechnitianId.current = newlyAddingTechnitianId
                            setIsOpenTechnitianConfirmationModal(true)
                            return
                        }
                    }
                }
            } else {
                addManualTechnicianToMap(newlyAddingTechnitianId[0], zone_technician_ids)
                setAppointment({ ...appointment, technicianIds: technitianIds });
                return
            }
        } else {
            addManualTechnicianToMap(newlyAddingTechnitianId[0], zone_technician_ids)
            setAppointment({ ...appointment, technicianIds: technitianIds });
            return
        }
    }

    function wantToPickNonEstimateTechnitian() {
        addManualTechnicianToMap(tempTechnitianId.current[0], selectedTimeSlot?.zone_technician_ids)
        setAppointment({ ...appointment, technicianIds: tempTechnitianIds.current });
        setIsOpenTechnitianConfirmationModal(false)
        message.current = ""
        tempTechnitianIds.current = []
        tempTechnitianId.current = []
    }

    function onCloseTechnitianConfirmationModal() {
        setIsOpenTechnitianConfirmationModal(false)
        message.current = ""
        tempTechnitianIds.current = []
        tempTechnitianId.current = []
    }

    return <>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1em", marginBottom: "0.5em" }}>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <IconButton data-test="appointment_back_button" disabled={getIsPreviousButtonDisabled()} className={getIsPreviousButtonDisabled() ? "btn-disable" : "btn-primary"} style={{ height: "30px", width: "30px", marginTop: "5px" }} onClick={substractWeekFormDateRange}><ArrowLeftIcon /></IconButton>
                    <div style={{ marginLeft: "40px", marginRight: "40px", paddingTop: "4%" }}><strong>{getFranchiseFormattedTime(availabilityTimeRange.start, franchise?.time_zone)}</strong> to <strong>{getFranchiseFormattedTime(availabilityTimeRange.end, franchise?.time_zone)}</strong></div>
                    <IconButton data-test="appointment_forward_button" className={"btn-primary"} style={{ height: "30px", width: "30px", marginTop: "5px" }} onClick={addWeekFormDateRange}><ArrowRightIcon /></IconButton>
                </div>
                <Box flexGrow={2} />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <div>
                    {franchise?.time_zone ? <p>{`*All time slots are based on the franchise time zone (${getTimeZoneName(franchise?.time_zone)})`}</p> : <p style={{ color: "red" }}>*Franchise time zone not provided. All time slots are based on UTC time</p>}
                    {appointmentToReschedule?.start ? <box style={{ backgroundColor: '#34C759', padding: '5px', fontWeight: 'bold' }}>*Previously Scheduled Date: ({franchise?.time_zone ? moment(appointmentToReschedule?.arrivalWindowStart)?.tz(franchise?.time_zone)?.format("MM/DD/yyyy h:mm A") : moment(appointmentToReschedule?.arrivalWindowStart)?.utc()?.format("MM/DD/yyyy h:mm A")})</box> : <></>}
                </div>
                <Box sx={{ display: 'flex', flexDirection: "row" }}>
                    <p style={{ color: "red" }}>*</p><p >Select Available Job Start and End Timeslot</p>
                </Box>
            </Box>
            <Box style={{ minHeight: "80px" }}>
                {isAvailabilitiesLoading ?
                    <div style={{ paddingLeft: "45%", marginTop: "25px" }}> <CircularProgress size={30} color="inherit" /></div>
                    : <TableContainer>
                        <TimeSlotTable franchise={franchise} arrivalWindow={arrivalWindow} availabilityTimeRange={availabilityTimeRange} availabilities={availabilities} selectedTimeSlot={selectedTimeSlot} setSelectedTimeSlot={onSelectedTimeslotChanged} />
                    </TableContainer>}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: "column", flexWrap: "wrap", width: "100%", gap: "0.5em", zIndex: "1000" }}>
                <Box width="100%" display="flex" flexDirection="row" gap="1em" >
                    <CustomDatePicker
                        label={"Arrival Window Start"}
                        maxTime={appointment?.arrivalWindowStart ? new Date(appointment?.arrivalWindowStart) : null}
                        minTime={appointment?.start ? new Date(appointment?.start) : null}
                        disabled={true}
                        value={appointment?.arrivalWindowStart ? franchise?.time_zone ? new Date(appointment?.arrivalWindowStart) : new Date(appointment?.arrivalWindowStart) : null}
                        onChange={onArrivalWindowStartChanged}
                        renderInput={(params) => <CustomTextField {...params} sx={{ width: "30%", flexGrow: "1" }} size="small" required={true} label="Job Start" />}
                        timeZone={franchise?.time_zone ? franchise?.time_zone : "UTC"}
                    />
                    <CustomDatePicker
                        label={"Arrival Window End"}
                        minTime={appointment?.arrivalWindowStart ? new Date(appointment?.arrivalWindowStart) : null}
                        maxTime={appointment?.arrivalWindowEnd ? new Date(new Date(appointment?.arrivalWindowStart).getTime() + 24 * 60 * 60 * 1000) : null}
                        disabled={appointment?.start ? false : true}
                        value={appointment?.arrivalWindowEnd ? new Date(appointment?.arrivalWindowEnd) : null}
                        onChange={onArrivalWindowEndChanged}
                        renderInput={(params) => <CustomTextField {...params} sx={{ width: "30%", flexGrow: "1" }} size="small" required={true} label="Job End" />}
                        timeZone={franchise?.time_zone ? franchise?.time_zone : "UTC"}
                    />
                </Box>
                {Object.keys(autoPickReason).length !== 0 ?
                    Object.keys(autoPickReason).length === 1 ?
                        <Box sx={{ display: 'flex', flexDirection: "row", gap: "1em", marginBottom: "0.3em" }}>
                            <InfoOutlinedIcon sx={{ cursor: "pointer" }} color="info" style={{ height: "25px", width: "25px", marginLeft: "0.5em", marginTop: "" }} />
                            <Typography variant="p">
                                {`${Object.keys(autoPickReason)[0]} - ${Object.values(autoPickReason)[0]}`}
                            </Typography>
                        </Box>
                        :
                        <Box sx={{ display: 'flex', flexDirection: "row", gap: "1em", alignItems: "center", marginBottom: "0.3em" }}>
                            <Typography variant="subtitle1">
                                Technician Picking Criteria
                            </Typography>
                            <Tooltip title={"View Technician Picking Criteria"} placement="top" arrow>
                                <IconButton color="primary"
                                    onClick={() => {
                                        setIsOpenTechnicianCriteria(true)
                                    }
                                    }>
                                    <VisibilityIcon style={{ height: "20px", width: "20px" }} />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    : <></>
                }
                <Box sx={{ zIndex: "2000" }}>
                    <Autocomplete
                        sx={{ width: "100%" }}
                        size="small"
                        disablePortal={true}
                        PopperComponent={
                            (props) => {
                                return <Popper {...props} placement="top" />
                            }
                        }
                        disableClearable={(isReschedule && !selectedTimeSlot)}
                        multiple={true}
                        noOptionsText={selectedTimeSlot || isReschedule ? isReschedule ? "Please select a timeslot" : "No technicians available" : "Please select a timeslot"}
                        loading={isTechnicianLoading || isAvailabilitiesLoading}
                        options={isReschedule && !selectedTimeSlot ? appointment?.technicianIds ?? [] : !selectedTimeSlot ? [] : [...new Set(selectedTimeSlot?.available_technician_ids)]?.map((id) => id) ?? []}
                        getOptionLabel={(technicianId) => getSelectedTechnicianFromTechnicianArray(technicianId, technicians)?.name}
                        renderOption={(props, technicianId) => {
                            const technician = getSelectedTechnicianFromTechnicianArray(technicianId, technicians)
                            const isDisabled = appointment?.technicianIds?.length === 1;
                            // let isSelctedTech = appointment?.technicianIds[0] === technician?.id
                            return (
                                <RenderInputForTechnicians {...props} key={technician?.id} content={technician?.name} currentTechId={technician?.id} disabled={isDisabled} selectedTech={appointment?.technicianIds[0]} />
                            )
                        }}
                        renderTags={(isReschedule && !selectedTimeSlot) ? (techIds) => {
                            return techIds?.map?.((tId) => {
                                const technician = getSelectedTechnicianFromTechnicianArray(tId, technicians)
                                return <Chip size="small" label={technician?.name} />
                            })
                        } : undefined}
                        value={isReschedule && !selectedTimeSlot ? previouslyScheduledTechnicianIds : appointment?.technicianIds ?? []}
                        onChange={(_, techniciansIds) => {
                            if (appointment?.technicianIds?.length === 0) {
                                //addManualTechnicianToMap(techniciansIds[0], selectedTimeSlot?.zone_technician_ids)
                                checkForNonEstimateTechnitians(techniciansIds, selectedTimeSlot?.zone_technician_ids)
                            } else if (appointment?.technicianIds?.length < techniciansIds?.length) {
                                //addManualTechnicianToMap(techniciansIds[techniciansIds.length - 1], selectedTimeSlot?.zone_technician_ids)
                                checkForNonEstimateTechnitians(techniciansIds, selectedTimeSlot?.zone_technician_ids)
                            } else {
                                let removedTechnitianId = appointment?.technicianIds.filter(id => !techniciansIds.includes(id))
                                let removedTechnitian = technicians.find(t => t.id === removedTechnitianId[0])?.name
                                setAutoPickReason(preMap => {
                                    if (removedTechnitian) {
                                        const { [removedTechnitian]: _, ...updatedMap } = preMap;
                                        return updatedMap;
                                    }
                                    return preMap;
                                });
                                setAppointment({ ...appointment, technicianIds: techniciansIds });
                            }
                        }}
                        renderInput={(params) =>
                            <CustomTextField
                                {...params}
                                label="Technicians"
                                disabled={appointment?.technicianIds?.length === 1}
                                InputProps={{
                                    ...params.InputProps,
                                    inputProps: {
                                        ...params.inputProps,
                                        style: appointment?.technicianIds?.length === 1 ? { caretColor: 'transparent' } : {},
                                    },
                                }}
                            />
                        }
                    />
                </Box>
            </Box>
        </Box>
        {isOpenTechnitianConfirmationModal ? <TechnitianPickingConfirmationModal isOpen={isOpenTechnitianConfirmationModal} onCloseHandler={() => onCloseTechnitianConfirmationModal()} wantToPickNonEstimateTechnitian={wantToPickNonEstimateTechnitian} message={message?.current} /> : <></>}
        {isOpenTechnicianCriteria ? <TechnitianPickingCriteriaModal isOpen={isOpenTechnicianCriteria} onCloseHandler={() => setIsOpenTechnicianCriteria(false)} reasonsMap={autoPickReason} /> : <></>}
    </>
}

function TimeSlotTable(props) {
    const { franchise, arrivalWindow, availabilityTimeRange, availabilities, selectedTimeSlot, setSelectedTimeSlot } = props
    return <Table border={"1px"} stickyHeader>
        <TableHead>
            {getTimeSlotsHead(franchise?.time_zone, availabilityTimeRange?.start, availabilityTimeRange?.end)}
        </TableHead>
        <TableBody style={{ overflowY: "scroll" }}>
            {getTimeSlotsRows(availabilityTimeRange, arrivalWindow, franchise?.time_zone, availabilities, selectedTimeSlot, setSelectedTimeSlot)}
        </TableBody>
    </Table>
}

function addDays(date = new Date(), numberOfDaysToAdd = 1) {
    return (new Date(date).setDate(new Date(date).getDate() + numberOfDaysToAdd))
}

function getDates(startDate = (new Date()), stopDate = addDays(startDate, 7), timeZone = 'UTC') {
    if (!timeZone) timeZone = "UTC"
    const dateArray = [];
    let currentDateMoment = moment(startDate).tz(timeZone).startOf("d");
    const stopDateMoment = moment(stopDate).tz(timeZone).endOf("d")
    while (currentDateMoment <= stopDateMoment) {
        dateArray.push(new Date(currentDateMoment));
        currentDateMoment = addDays(currentDateMoment, 1);
    }
    return dateArray;
}

function getTimeSlotsHead(timeZone = "UTC", startDate, endDate) {
    if (!timeZone) timeZone = "UTC"
    const dates = getDates(new Date(startDate), new Date(endDate), timeZone)
    return <TableRow>{dates?.map((d) => {
        const columnBackgroundColor = getColumnBackgroundColor(timeZone, d)
        return <TableCell key={d.toISOString()} sx={{ backgroundColor: columnBackgroundColor, textAlign: "center", maxWidth: "80px" }} >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                <Box>
                    {`${moment(d)?.tz(timeZone)?.format("MM/DD/yyyy")}`}
                </Box>
                <Box>
                    {`${moment(d)?.tz(timeZone)?.format('dddd')}`}
                </Box>
            </Box>
        </TableCell>
    })}</TableRow>
}

function filterTechnicians(timeSlots) {
    let formattedSlots = []
    for (let timeSlot of timeSlots) {
        let estimateTechniciansOfTheBrandInZone = timeSlot?.available_technician_ids?.filter((t) => timeSlot?.estimate_tech_of_brand_in_zone?.includes(t))
        // let estimateTechniciansOfTheBrandNotInZone = timeSlot?.available_technician_ids?.filter((t) => timeSlot?.estimate_tech_of_brand_not_in_zone?.includes(t))
        // let notSetupEstimateTechnicianOfTheBrandInZone = timeSlot?.available_technician_ids?.filter((t) => timeSlot?.brand_not_setup_estimate_tech_in_zone?.includes(t))
        // let notSetupEstimateTechnicianOfTheBrandNotInZone = timeSlot?.available_technician_ids?.filter((t) => timeSlot?.brand_not_setup_estimate_tech_not_in_zone?.includes(t))
        // let notSetupBrandEstimateTechniciansInZone = timeSlot?.available_technician_ids?.filter((t) => timeSlot?.not_setup_brand_estimate_tech_in_zone?.includes(t))
        // let notSetupBrandEstimateTechniciansNotInZone = timeSlot?.available_technician_ids?.filter((t) => timeSlot?.not_setup_brand_estimate_tech_not_in_zone?.includes(t))
        // let notSetupBrandNotSetupEstimateTechniciansInZone = timeSlot?.available_technician_ids?.filter((t) => timeSlot?.not_setup_brand_not_setup_estimate_tech_in_zone?.includes(t))
        // let notSetupBrandNotSetupEstimateTechniciansNotInZone = timeSlot?.available_technician_ids?.filter((t) => timeSlot?.not_setup_brand_not_setup_estimate_tech_not_in_zone?.includes(t))
        let brandNoEstimateTechInZone = timeSlot?.available_technician_ids?.filter((t) => timeSlot?.brand_no_estimate_tech_in_zone?.includes(t))
        // let brandNoEstimateTechNotInZone = timeSlot?.available_technician_ids?.filter((t) => timeSlot?.brand_no_estimate_tech_not_in_zone?.includes(t))
        // let notSetupBrandNoEstimateTechInZone = timeSlot?.available_technician_ids?.filter((t) => timeSlot?.not_setup_brand_no_estimate_tech_in_zone?.includes(t))
        // let notSetupBrandNoEstimateTechNotInZone = timeSlot?.available_technician_ids?.filter((t) => timeSlot?.not_setup_brand_no_estimate_tech_not_in_zone?.includes(t))
        //let techniciansWithNoCustomFields = timeSlot?.available_technician_ids?.filter((t) => timeSlot?.tech_with_no_custom_fields?.includes(t))
        
        if (estimateTechniciansOfTheBrandInZone?.length + brandNoEstimateTechInZone?.length > 0) {
            timeSlot.available_technician_ids = estimateTechniciansOfTheBrandInZone.concat(brandNoEstimateTechInZone)
            formattedSlots.push(timeSlot)
        }
        
        // else if (techniciansWithNoCustomFields?.length > 0) {
        //     timeSlot.available_technician_ids = techniciansWithNoCustomFields
        //     formattedSlots.push(timeSlot)
        // } 
        // else if (notSetupEstimateTechnicianOfTheBrandInZone?.length > 0) {
        //     timeSlot.available_technician_ids = notSetupEstimateTechnicianOfTheBrandInZone
        //     formattedSlots.push(timeSlot)
        // } else if (estimateTechniciansOfTheBrandNotInZone?.length + brandNoEstimateTechNotInZone?.length > 0) {
        //     timeSlot.available_technician_ids = estimateTechniciansOfTheBrandNotInZone.concat(brandNoEstimateTechNotInZone)
        //     formattedSlots.push(timeSlot)
        // } else if (notSetupEstimateTechnicianOfTheBrandNotInZone?.length > 0) {
        //     timeSlot.available_technician_ids = notSetupEstimateTechnicianOfTheBrandNotInZone
        //     formattedSlots.push(timeSlot)
        // } else if (notSetupBrandEstimateTechniciansInZone?.length + notSetupBrandNoEstimateTechInZone?.length > 0) {
        //     timeSlot.available_technician_ids = notSetupBrandEstimateTechniciansInZone.concat(notSetupBrandNoEstimateTechInZone)
        //     formattedSlots.push(timeSlot)
        // } else if (notSetupBrandNotSetupEstimateTechniciansInZone?.length > 0) {
        //     timeSlot.available_technician_ids = notSetupBrandNotSetupEstimateTechniciansInZone
        //     formattedSlots.push(timeSlot)
        // } else if (notSetupBrandEstimateTechniciansNotInZone?.length + notSetupBrandNoEstimateTechNotInZone?.length > 0) {
        //     timeSlot.available_technician_ids = notSetupBrandEstimateTechniciansNotInZone.concat(notSetupBrandNoEstimateTechNotInZone)
        //     formattedSlots.push(timeSlot)
        // } else if (notSetupBrandNotSetupEstimateTechniciansNotInZone?.length > 0) {
        //     timeSlot.available_technician_ids = notSetupBrandNotSetupEstimateTechniciansNotInZone
        //     formattedSlots.push(timeSlot)
        // } else if (techniciansWithNoCustomFields?.length > 0) {
        //     timeSlot.available_technician_ids = techniciansWithNoCustomFields
        //     formattedSlots.push(timeSlot)
        // }

    }
    return formattedSlots
}

function getTimeSlotsRows(availabilityTimeRange, arrivalWindow, timeZone = "UTC", availabilities, selectedTimeSlot, setSelectedTimeSlot) {
    if (!timeZone) timeZone = "UTC"
    if (!availabilities) return <></>
    let tableBodyContent = [];
    const dateSlotsMap = getDatesTimeSlotes(availabilityTimeRange, availabilities, timeZone)
    for (let date in dateSlotsMap) {
        const timeSlots = dateSlotsMap[date] ?? []
        const availableTimeSlots = timeSlots.filter((s) => s.is_available)
        const slotsFilterByTechnicians = filterTechnicians(availableTimeSlots)
        let columContent = []
        const currentTime = moment(new Date()).tz(timeZone)
        const filteredAvailableTimeSlots = slotsFilterByTechnicians?.filter((s) => moment(Date.parse(s?.start)).tz(timeZone) > currentTime)
        if (filteredAvailableTimeSlots?.length === 0) {
            columContent.push(<Box key={"NO_TIME_SLOT"} style={{ textAlign: "center", fontWeight: "bold", maxWidth: "80px", margin: "auto" }} >{"No timeslots available"}</Box>)
        } else {
            for (let i = 0; i < filteredAvailableTimeSlots?.length; i++) {
                const timeSlot = filteredAvailableTimeSlots[i]
                const previousTimeSlot = i > 0 ? filteredAvailableTimeSlots?.[i - 1] : null
                if (!timeSlot.is_available) continue
                const startTime = moment(Date.parse(timeSlot?.start)).tz(timeZone)
                const startTimeInCell = startTime.format('hh:mm')
                const endTimeInCell = moment(Date.parse(timeSlot?.end)).tz(timeZone).format('hh:mm')
                const previousStartTime = previousTimeSlot ? moment(Date.parse(previousTimeSlot?.start)).tz(timeZone) : ""
                const shouldIncludeAmCell = (timeSlot && i === 0 && startTime.format('LT').includes("AM"))
                const shouldIncludePmCell = ((!previousStartTime || previousStartTime?.format?.('LT')?.includes?.("AM")) && startTime.format('LT').includes("PM"))
                const backgroundColor = (selectedTimeSlot?.start && timeSlot?.start && selectedTimeSlot?.start === timeSlot?.start) ? "#00a79d" : ""
                columContent.push(<Box key={timeSlot?.start} textAlign={"start"} >
                    {(shouldIncludeAmCell) ? <Box style={{ textAlign: "center", fontWeight: "bold" }} >{"AM"}</Box> : <></>}
                    {(shouldIncludePmCell) ? <Box style={{ textAlign: "center", fontWeight: "bold" }} >{"PM"}</Box> : <></>}
                    <Box data-test={"time_slot_" + startTimeInCell + "_" + endTimeInCell} style={{ textAlign: "center", marginBlock: "0.5em", paddingInline: "0.4em", backgroundColor: backgroundColor, borderRadius: "0.5em" }} onClick={() => { if (timeSlot) setSelectedTimeSlot(timeSlot, arrivalWindow) }}>{`${timeSlot?.start ? startTimeInCell : ""} - ${timeSlot?.end ? endTimeInCell : ""}`}</Box>
                </Box>)
            }
        }
        const columnBackgroundColor = getColumnBackgroundColor(timeZone, date)
        tableBodyContent.push(<TableCell key={date?.date} sx={{ backgroundColor: columnBackgroundColor, verticalAlign: "top", cursor: "pointer", maxWidth: "80px" }}>{columContent}</TableCell>)
    }
    return <TableRow>{tableBodyContent}</TableRow>
}

function getDatesTimeSlotes(availabilityTimeRange, availabilities, timeZone = 'UTC') {
    if (!timeZone) timeZone = "UTC"
    if (!availabilities) return {}
    const dates = getDates(Date.parse(availabilityTimeRange.start), Date.parse(availabilityTimeRange.end), timeZone)
    const datesSlotsMap = {}
    for (let date of dates) {
        const dateMoment = moment(date).tz(timeZone)
        const dateSlots = []
        if (Array.isArray(availabilities.available_time_slots)) {
            for (let slot of availabilities.available_time_slots) {
                const startTime = moment(slot.start)?.tz(timeZone)
                const endOfTheDate = moment(date)?.tz(timeZone).endOf("d")
                if (startTime < endOfTheDate && dateMoment <= startTime) {
                    dateSlots.push(slot)
                }
            }
        }
        datesSlotsMap[date] = dateSlots
    }
    return datesSlotsMap
}

function getColumnBackgroundColor(timeZone = "UTC", date) {
    if (!timeZone) timeZone = "UTC"
    return getIsDateToday(timeZone, date) ? 'form.secondary' : 'background.main'
}

function getIsDateToday(timeZone = "UTC", date) {
    if (!timeZone) timeZone = "UTC"
    const isSameDate = moment(date).tz(timeZone)?.format("MM/DD/yyyy") === moment(new Date())?.tz(timeZone)?.format("MM/DD/yyyy")
    return isSameDate;
}

function substractWeekForDate(dateString = (new Date()).toISOString()) {
    const date = moment(new Date(dateString))
    date?.subtract(7, "d");
    return date?.toISOString()
}

function addWeekForDate(dateString = (new Date()).toISOString()) {
    const date = moment(new Date(dateString))
    date?.add(7, "d");
    return date?.toISOString()
}

function getSelectedTechnicianFromTechnicianArray(id, technicians = []) {
    return technicians.find((t) => t.id === id)
}

function getFranchiseFormattedTime(timeISOString, timeZone = "UTC") {
    if (!timeZone) timeZone = "UTC"
    return moment(timeISOString)?.tz(timeZone)?.startOf("d")?.format?.("MM/DD/yyyy") ?? ""
}

function getDurationStringFromSeconds(seconds) {
    const minutes = Math.floor(seconds / 60)
    const durationString = `${minutes}m`
    return durationString
}