import { Box, Button, IconButton, Typography } from "@mui/material"
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';

export default function CustomChipForEmails(props) {
    const { id, label, onDelete, handleValidateEmail, disabled } = props
    return <Box display="flex" flexDirection="row">
        <Box
            id={id}
            sx={{ display: "flex", flexDirection: "row", borderRadius: "1em", border: "1px solid #BDBDBD", gap: "0.5em", paddingInline: "0.5em", paddingBlock: "0.2em" }}
        >
            <Typography variant="body2" >{label.email}</Typography>
            <Button disabled={label.status === true ? true : false}
                style={{ height: "0.3em", backgroundColor: label.status === true ? "#0000001f" : "#00a79d", color: "white", padding: "0.8em", borderRadius: "1em" }}
                onClick={handleValidateEmail}
            >validate</Button>
            {label?.status && label.status === true ? <CheckCircleIcon fontSize='small' style={{ color: "#00a79d" }} /> : <WarningIcon fontSize='small' style={{ color: "#B00020" }} />}
            <IconButton disabled={disabled} sx={{ width: "1em", height: "1em" }} onClick={onDelete}>
                <HighlightOffTwoToneIcon fontSize='small' color="#BDBDBD" />
            </IconButton>
        </Box>
        <Box flexGrow={2} />
    </Box>
}