import { Autocomplete, Box, Button, FormControl, FormControlLabel, Grid, Paper, Radio, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import RenderInput from "../../../../../components/servicetitan/RenderInput";
import { NOTIFICATION_TYPES } from "../../../../../constants/common";
import { cancelJob } from "../../../../../services/vonigo/jobScheduling";
import { handleGetCancelReasons } from "../../../../../state-services-dublicate/vonigo";
import { CustomTextField } from "../../components/customComponents";
import LoadingButton from "../../../../../components/LoadingButton";

export default function CancelJob({ brand, franchise, handleCancelJob, job, showNotification, onCreateJobPageOpen, setIsViewJobModalOpen, setIsRescheduleWorkOrder }) {

    const [cancelRessons, setCancelRessons] = useState([])
    const [isCancelReasonsLoading, setisCancelReasonsLoading] = useState(false)
    const [selectedReason, setSelectedReason] = useState(null)
    const [isCancelJobLoading, setIsCancelJobLoading] = useState(false)
    const [cancelNote, setCancelNote] = useState("")
    const [cancelStage, setCancelStage] = useState(CANCEL_STAGES?.BY_AGENT?.optionId)

    useEffect(() => {
        handleGetCancelReasons(brand, franchise, setisCancelReasonsLoading, setCancelRessons)
    }, [])

    function handleOpenResecheduling() {
        setIsViewJobModalOpen(false)
        setIsRescheduleWorkOrder(true)
        onCreateJobPageOpen()
    }

    function isEnableCancelJobButton() {
        return selectedReason !== null && cancelNote !== "" && cancelStage !== ""
    }

    return <>
        <Paper sx={{ width: "100%", borderRadius: "0.5em", border: "1px solid gray", marginTop: "0.5em", padding: "0.5em" }}>
            <Grid container  >
                <Grid item xs={6} >
                    <Typography style={{ margin: "0" }}>{"Please Select Who Is Cancelling Job"}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{ display: 'flex', flexDirection: "row", width: "100%", }}>
                        <FormControl
                            onChange={(e) => setCancelStage(e.target.value)}
                        >
                            <FormControlLabel key={CANCEL_STAGES.BY_AGENT.optionId} value={CANCEL_STAGES.BY_AGENT.optionId} control={<Radio sx={{ '&, &.Mui-checked': { color: '#00a79d', }, "&.Mui-disabled": { color: "grayText" } }} />} checked="true" label={CANCEL_STAGES.BY_AGENT.name} />
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={6} >
                    <Typography style={{ margin: "0" }}>{"Reason"}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Autocomplete
                        sx={{ width: "100%", }}
                        size="small"
                        loading={isCancelReasonsLoading}
                        value={selectedReason ?? null}
                        disablePortal
                        disableClearable
                        options={cancelRessons}
                        getOptionLabel={(reason) => reason?.name}
                        renderOption={(props, reason) => <RenderInput {...props} key={reason?.optionID} content={reason?.name ?? "-"} />}
                        onChange={(_, reason) => {
                            setSelectedReason(reason);
                        }}
                        renderInput={(params) => <CustomTextField {...params} label="Select Reason" />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box style={{ width: "100%", marginTop: "1em" }}>
                        <CustomTextField multiline rows={3} onChange={(event) => setCancelNote(event.target.value)} required={true} label="Cancellation Comments" variant="outlined" size="small" style={{ width: "100%", }} />
                    </Box>
                </Grid>
            </Grid>
            <Box sx={{ width: "100%", marginTop: "0.5em", padding: "0.5em" }}>
                <Button size={"small"} variant="contained" onClick={() => handleCancelJob()} className={"btn-modal"} >Cancel</Button>
                <LoadingButton type={"submit"} size={"small"} disabled={!isEnableCancelJobButton()} variant="contained" loading={isCancelJobLoading} style={{ marginLeft: "0.5em" }} className={isEnableCancelJobButton() ? "btn-primary" : "btn-disable"}
                    onClick={() => jobCancel(brand, franchise, setIsCancelJobLoading, showNotification, job, selectedReason, cancelNote, cancelStage, handleOpenResecheduling)}
                >
                    Cancel Job
                </LoadingButton>
            </Box>
        </Paper>
    </>
}

async function jobCancel(brand, franchise, setIsCancelJobLoading, showNotification, job, selectedReason, cancelNote, cancelStage, handleOpenResecheduling) {
    try {
        setIsCancelJobLoading(true)
        const jobCancelRequest = {
            baseUrl: brand?.redirect_url,
            franchiseId: franchise?.franchise_id,
            jobId: job?.objectID,
            cancelNotes: cancelNote,
            cancelReasonId: selectedReason?.optionID,
            cancelStageId: parseInt(cancelStage)
        }
        await cancelJob?.(jobCancelRequest)
        handleOpenResecheduling()
        showNotification({ message: "Job has been canceled successfully", type: NOTIFICATION_TYPES.SUCCESS, isOpen: true })
    } catch (error) {
        showNotification({ message: "Important: Job couldn’t be canceled at this time. Please try again", type: NOTIFICATION_TYPES.ERROR })
    } finally {
        setIsCancelJobLoading?.(false)
    }
}

export const CANCEL_STAGES = { BY_AGENT: { name: "By Agent", optionId: "10151" }, BY_CLIENT_ONLINE: { name: "By Client Online", optionId: "10150" }, CANCELLED_BY_GM: { name: "Cancelled by GM", optionId: "10152" } }
