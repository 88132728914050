import { getAllCampaigns } from "../../services/eigthByEight/campaigns"

export async function handleGetAllCampaigns(selectedCampaignId, setAllCampaigns, setIsAllCampaignsLoading, setIsAllCampaignsLoadingError, setSelectedCampaign) {
    try {
        setIsAllCampaignsLoading(true)
        setIsAllCampaignsLoadingError(false)
        const campaingsResponse = await getAllCampaigns()
        const campaigns = campaingsResponse?.campaigns
        if (Array.isArray(campaigns)) {
            setAllCampaigns(campaigns)
            if (selectedCampaignId) {
                const selectedCampaign = campaigns?.find((c) => c?.campaign_id === selectedCampaignId)
                if (selectedCampaign) setSelectedCampaign(selectedCampaign)
            }
        }
    } catch {
        setIsAllCampaignsLoadingError(true)
    } finally {
        setIsAllCampaignsLoading(false)
    }

}