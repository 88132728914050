import React, { useState, useEffect } from "react";
import {
    Dialog,
    Collapse,
    Grid,
    FormLabel,
    Paper,
    Box,
    Tooltip,
    Typography,
    Switch,
    Chip,
    Badge,
    CircularProgress,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';

//mui table components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

//components
import ImageBox from "../../../../components/LogoBox/ImageBox";
import CopyToClipBoard from "../../../../components/CopyToClipBoard";
import CustomAuditTextField from "../components/CustomAuditTextField";

//utils
import { getMatchingBrandByBrandId } from "../../../../utils/miscellaneous";

//assets
import iBSBrandLogo from "../../../../assets/img/brand.png";

//services
import { handleGetMostRecentFranchiseAuditByGivenAudit, isValueChanged, isArraysChanged, getValue, changeDisplayIndex, getFranchiseByFranchiseId } from "../../../../state-services/audit/audit";
import { handleGetBusinessUnits, handleGetJobTypes, handleGetCampaigns } from "../../../../state-services-dublicate/serviceTitan";
import { getById } from "../../../../utils/miscellaneous";

//constants
import { TIME_ZONES, AVAILABLE_CRMS } from "../../../../constants/common";

const sectionKeys = [
    "franchise",
    "franchise_brands",
    "franchise_angies_list_task_name_configurations",
    "franchise_lumin_configuration",
    "owners",
    "faqs"
]

export default function FranchiseDetailsModal(props) {
    const { franchiseDetails, isShowFranchise, setIsShowFranchise, brands } =
        props;

    const [displayIndex, setDisplayIndex] = useState(0);
    const [recentFranchise, setRecentFranchise] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [isShowComparison, setIsShowComparison] = useState(false)
    const [comparisonSection, setComparisonSection] = useState()
    const [showingFranchiseDetails, setShowingFranchiseDetails] = useState()
    const [servicetitanJobTypes, setServicetitanJobTypes] = useState([])
    const [servicetitanBusinessUnits, setServicetitanBusinessUnits] = useState([])
    const [isServiceTitanBusinessUnitsLoading, setIsServiceTitanBusinessUnitsLoading] = useState(false)
    const [isServiceTitanJobTypesLoading, setIsServiceTitanJobTypesLoading] = useState(false)
    const [servicetitanCampaigns, setServicetitanCampaigns] = useState([])
    const [isServiceTitanCampaignsLoading, setIsServiceTitanCampaignsLoading] = useState(false)

    useEffect(() => {

        return () => {
            setRecentFranchise();
            setIsLoading(true)
            setIsShowComparison(false)
            setComparisonSection()
            setShowingFranchiseDetails()
        };
    }, []);

    useEffect(() => {

        if (!franchiseDetails) return;

        if (franchiseDetails?.payload?.faq_template === null) {
            franchiseDetails.payload.faq_template = []
        }

        setShowingFranchiseDetails(franchiseDetails)

        var franchiseId = 0;

        if (franchiseDetails?.payload?.franchise) {
            franchiseId = franchiseDetails.payload.franchise.id;
        } else if (franchiseDetails?.payload?.id) {
            franchiseId = franchiseDetails.payload.id;
        }
        setDisplayIndex(0);

        if (franchiseDetails?.filter?.audit_option === "Franchise Update") {
            handleGetMostRecentFranchiseAuditByGivenAudit(
                franchiseId,
                franchiseDetails?.filter.audit_option,
                franchiseDetails?.created_at,
                setRecentFranchise,
                setIsLoading
            );
        } else if (franchiseDetails?.filter?.audit_option === "Franchise Active/Deactive" || franchiseDetails?.filter?.audit_option === "Franchise Delete") {
            getFranchiseByFranchiseId(franchiseId, franchiseDetails, setShowingFranchiseDetails, setIsLoading)

        } else {
            setIsLoading(false)
        }



    }, [franchiseDetails]);

    useEffect(() => {
        if (franchiseDetails && franchiseDetails?.payload?.franchise?.service_brand === AVAILABLE_CRMS.servicetitan.value) {
            return handleGetBusinessUnits(franchiseDetails?.payload?.franchise?.id, undefined, setServicetitanBusinessUnits, () => { }, setIsServiceTitanBusinessUnitsLoading)
        }
    }, [franchiseDetails])

    useEffect(() => {
        if (franchiseDetails && franchiseDetails?.payload?.franchise?.service_brand === AVAILABLE_CRMS.servicetitan.value) {
            return handleGetJobTypes(franchiseDetails?.payload?.franchise?.id, undefined, false, false, setServicetitanJobTypes, () => { }, setIsServiceTitanJobTypesLoading)
        }
    }, [franchiseDetails])

    useEffect(() => {
        if (franchiseDetails && franchiseDetails?.payload?.franchise?.service_brand === AVAILABLE_CRMS.servicetitan.value) {
            return handleGetCampaigns(franchiseDetails?.payload?.franchise?.id, undefined, setServicetitanCampaigns, () => { }, setIsServiceTitanCampaignsLoading)
        }
    }, [franchiseDetails])


    function getSection(sectionKey) {
        switch (sectionKey) {
            case "franchise":
                return (
                    <MainSection
                        franchise={showingFranchiseDetails?.payload?.franchise ?? null}
                        lastFranchise={recentFranchise?.payload?.franchise ?? null}
                    />
                );
            case "franchise_brands":
                return (
                    <BrandSection
                        userFranchiseBrands={
                            showingFranchiseDetails?.payload?.franchise_brands ?? null
                        }
                        isTollFree={
                            showingFranchiseDetails?.payload?.franchise?.is_toll_free ?? false
                        }
                        brands={brands}
                    />
                );
            case "franchise_angies_list_task_name_configurations":
                return (
                    <AngieList
                        configurations={
                            showingFranchiseDetails?.payload
                                ?.franchise_angies_list_task_name_configurations ?? null
                        }
                        current_servicetitan_campaign_id={
                            showingFranchiseDetails?.payload
                                ?.franchise_angies_list_configuration?.servicetitan_campaign_id ?? 0
                        }
                        last_servicetitan_campaign_id={
                            recentFranchise?.payload
                                ?.franchise_angies_list_configuration?.servicetitan_campaign_id ?? 0
                        }
                        servicetitanJobTypes={servicetitanJobTypes}
                        servicetitanBusinessUnits={servicetitanBusinessUnits}
                        isServiceTitanBusinessUnitsLoading={isServiceTitanBusinessUnitsLoading}
                        isServiceTitanJobTypesLoading={isServiceTitanJobTypesLoading}
                        servicetitanCampaigns={servicetitanCampaigns}
                        isServiceTitanCampaignsLoading={isServiceTitanCampaignsLoading}
                    />
                );
            case "franchise_lumin_configuration":
                return (
                    <LuminSection
                        franchise_lumin_configuration={
                            showingFranchiseDetails?.payload?.franchise_lumin_configuration ?? null
                        }
                        last_franchise_lumin_configuration={
                            recentFranchise?.payload?.franchise_lumin_configuration ?? null
                        }
                    />
                );
            case "owners":
                return (
                    <OwnerSection
                        franchiseOwners={showingFranchiseDetails?.payload?.owners ?? null}
                    />
                );
            case "ServiceTitan":
                return (
                    <ServiceTitan
                        franchise={showingFranchiseDetails?.payload?.franchise ?? null}
                        lastFranchise={recentFranchise?.payload?.franchise ?? null}
                    />
                );
            case "faqs":
                return (
                    <FAQSection
                        franchise_faqs={showingFranchiseDetails?.payload?.faq_template ?? null}
                    />
                )
            default:
                return <></>
        }
    }

    function getSectionNameArea(sectionKey) {
        switch (sectionKey) {
            case "franchise":
                return (
                    <>
                        <Badge
                            color="error"
                            variant="dot"
                            invisible={
                                !(getIsSectionChanged(sectionKey))
                            }
                        >
                            <FormLabel>{"Main"}</FormLabel>
                        </Badge>
                    </>
                );
            case "franchise_brands":
                return (
                    <>
                        <Badge
                            color="error"
                            variant="dot"
                            invisible={
                                !(getIsSectionChanged(sectionKey))
                            }
                        >
                            <FormLabel>{"Brands"}</FormLabel>
                        </Badge>
                    </>
                );
            case "franchise_angies_list_task_name_configurations":
                return (
                    <>
                        <Badge
                            color="error"
                            variant="dot"
                            invisible={
                                !(getIsSectionChanged(sectionKey))
                            }
                        >
                            <FormLabel>{"Angie's List"}</FormLabel>
                        </Badge>
                    </>
                );
            case "franchise_lumin_configuration":
                return (
                    <>
                        <Badge
                            color="error"
                            variant="dot"
                            invisible={
                                !(getIsSectionChanged(sectionKey))
                            }
                        >
                            <FormLabel>{"Lumin"}</FormLabel>
                        </Badge>
                    </>
                );
            case "owners":
                return (
                    <>
                        <Badge
                            color="error"
                            variant="dot"
                            invisible={
                                !(getIsSectionChanged(sectionKey))
                            }
                        >
                            <FormLabel>{"Owners"}</FormLabel>
                        </Badge>
                    </>
                );
            case "ServiceTitan":
                return (
                    <>
                        <Badge
                            color="error"
                            variant="dot"
                            invisible={
                                !(getIsSectionChanged(sectionKey))
                            }
                        >
                            <FormLabel>{"Service Titan"}</FormLabel>
                        </Badge>
                    </>
                );
            case "faqs":
                return (
                    <>
                        <Badge
                            color="error"
                            variant="dot"
                            invisible={
                                !(getIsSectionChanged(sectionKey))
                            }
                        >
                            <FormLabel>{"FAQs"}</FormLabel>
                        </Badge>
                    </>
                );
            default:
                <></>
        }
    }

    function validateSectionKey(sectionKey) {
        if (
            sectionKey !== "franchise" &&
            sectionKey !== "franchise_brands" &&
            sectionKey !== "franchise_angies_list_task_name_configurations" &&
            sectionKey !== "franchise_lumin_configuration" &&
            sectionKey !== "owners" &&
            sectionKey !== "faqs"
        ) {
            return false;
        }
        return true;
    }

    function getIsSectionChanged(sectionKey) {
        switch (sectionKey) {
            case "franchise":
                return (
                    recentFranchise &&
                    (isValueChanged(
                        showingFranchiseDetails?.payload?.franchise?.franchise_name ?? "",
                        recentFranchise?.payload?.franchise?.franchise_name ?? ""
                    ) ||
                        isValueChanged(
                            showingFranchiseDetails?.payload?.franchise
                                ?.franchise_location ?? "",
                            recentFranchise?.payload?.franchise?.franchise_location ??
                            ""
                        ) ||
                        isValueChanged(
                            showingFranchiseDetails?.payload?.franchise?.exact_zipcode ?? "",
                            recentFranchise?.payload?.franchise?.exact_zipcode ?? ""
                        ) ||
                        isValueChanged(
                            showingFranchiseDetails?.payload?.franchise?.franchise_id ?? "",
                            recentFranchise?.payload?.franchise?.franchise_id ?? ""
                        ) ||
                        isValueChanged(
                            showingFranchiseDetails?.payload?.franchise?.service_brand ?? "",
                            recentFranchise?.payload?.franchise?.service_brand ?? ""
                        ) ||
                        isValueChanged(
                            showingFranchiseDetails?.payload?.franchise?.time_zone ?? "",
                            recentFranchise?.payload?.franchise?.time_zone ?? ""
                        ) ||
                        isValueChanged(
                            showingFranchiseDetails?.payload?.franchise?.is_call_center_booking ?? false,
                            recentFranchise?.payload?.franchise?.is_call_center_booking ?? false
                        )
                    )
                );
            case "franchise_brands":
                return (recentFranchise &&
                    isArraysChanged(
                        showingFranchiseDetails?.payload
                            ?.franchise_brands ?? null,
                        recentFranchise?.payload?.franchise_brands ??
                        null
                    )
                );
            case "franchise_angies_list_task_name_configurations":
                return (recentFranchise &&
                    (isArraysChanged(
                        showingFranchiseDetails?.payload
                            ?.franchise_angies_list_task_name_configurations ?? null,
                        recentFranchise?.payload
                            ?.franchise_angies_list_task_name_configurations ?? null
                    )
                        ||
                        isValueChanged(
                            showingFranchiseDetails?.payload?.franchise_angies_list_configuration
                                ?.servicetitan_campaign_id ?? 0,
                            recentFranchise?.payload?.franchise_angies_list_configuration
                                ?.servicetitan_campaign_id ?? 0
                        )
                    )
                );
            case "franchise_lumin_configuration":
                return (
                    recentFranchise &&
                    isValueChanged(
                        showingFranchiseDetails?.payload?.franchise_lumin_configuration
                            ?.is_enabled ?? false,
                        recentFranchise?.payload?.franchise_lumin_configuration
                            ?.is_enabled ?? false
                    )
                )
            case "owners":
                return (
                    recentFranchise &&
                    isArraysChanged(
                        showingFranchiseDetails?.payload?.owners ?? null,
                        recentFranchise?.payload?.owners ?? null
                    )
                );
            case "ServiceTitan":
                return (
                    recentFranchise &&
                    (isValueChanged(
                        showingFranchiseDetails?.payload?.franchise
                            ?.service_titan_client_id ?? "",
                        recentFranchise?.payload?.franchise
                            ?.service_titan_client_id ?? ""
                    ) ||
                        isValueChanged(
                            showingFranchiseDetails?.payload?.franchise
                                ?.service_titan_client_secret ?? "",
                            recentFranchise?.payload?.franchise
                                ?.service_titan_client_secret ?? ""
                        ) ||
                        isValueChanged(
                            showingFranchiseDetails?.payload?.franchise
                                ?.service_titan_tenant_id ?? "",
                            recentFranchise?.payload?.franchise
                                ?.service_titan_tenant_id ?? ""
                        ) ||
                        isValueChanged(
                            showingFranchiseDetails?.payload?.franchise
                                ?.service_titan_tenant_name ?? "",
                            recentFranchise?.payload?.franchise
                                ?.service_titan_tenant_name ?? ""
                        ) ||
                        isValueChanged(
                            showingFranchiseDetails?.payload?.franchise
                                ?.api_key ?? "",
                            recentFranchise?.payload?.franchise
                                ?.api_key ?? ""
                        )
                    )
                );
            case "faqs":
                return (
                    recentFranchise &&
                    isArraysChanged(
                        showingFranchiseDetails?.payload?.faq_template ?? null,
                        recentFranchise?.payload?.faq_template ?? null
                    )
                );
            default:
                return <></>
        }
    }

    function getExpandButton(sectionKey) {

        if (sectionKey === "franchise_brands" || sectionKey === "franchise_angies_list_task_name_configurations" || sectionKey === "owners" || sectionKey === "faqs") {
            return <ExpandButtonComponent sectionKey={sectionKey} />
        } else {
            return <></>
        }
    }

    function ExpandButtonComponent(props) {

        const { sectionKey } = props;
        return (
            <Box sx={{ display: 'flex', marginBottom: '10px' }}>
                <Typography>Current Details</Typography>
                {getIsSectionChanged(sectionKey) && (
                    <IconButton
                        size={"small"}
                        color="info"
                        onClick={() => {
                            setComparisonSection(sectionKey)
                            setIsShowComparison(true)
                        }}
                        sx={{ marginLeft: 'auto' }}
                    >
                        <Tooltip title={"Expand to compare"}>
                            <ExpandCircleDownIcon style={{ height: "20px", width: "20px" }} />
                        </Tooltip>

                    </IconButton>

                )}
            </Box>
        )
    }

    function CollapseArea(props) {
        const { sectionKey, index } = props;

        return (
            <div style={{ marginTop: "10px" }}>
                <Grid container spacing={2} className={"franchise-form"} onClick={() => changeDisplayIndex(index, displayIndex, setDisplayIndex)}>
                    <Grid item xs={10} style={{ margin: "auto" }}>
                        {getSectionNameArea(sectionKey)}
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton
                            sx={{ float: "right" }}
                            aria-label="expand"
                            size="small"
                            onClick={() => changeDisplayIndex(index, displayIndex, setDisplayIndex)}
                        >
                            {index === displayIndex ? (
                                <KeyboardArrowUpIcon />
                            ) : (
                                <KeyboardArrowDownIcon />
                            )}
                        </IconButton>
                    </Grid>
                </Grid>
                <Collapse in={index === displayIndex} unmountOnExit>
                    {showingFranchiseDetails?.payload && getExpandButton(sectionKey)}
                    {showingFranchiseDetails?.payload && getSection(sectionKey)}
                </Collapse>
            </div>
        );
    }

    function getComparisonSection(sectionKey) {
        switch (sectionKey) {
            case "franchise_brands":
                return (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                        <h3>Before Change</h3>
                        <BrandSection
                            userFranchiseBrands={
                                recentFranchise?.payload?.franchise_brands ?? null
                            }
                            isTollFree={
                                recentFranchise?.payload?.franchise?.is_toll_free ?? false
                            }
                            brands={brands}
                        />
                        <h3>After Change</h3>
                        <BrandSection
                            userFranchiseBrands={
                                showingFranchiseDetails?.payload?.franchise_brands ?? null
                            }
                            isTollFree={
                                showingFranchiseDetails?.payload?.franchise?.is_toll_free ?? false
                            }
                            brands={brands}
                        />

                    </Box>

                );
            case "franchise_angies_list_task_name_configurations":
                return (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                        <h3>Before Change</h3>
                        <AngieList
                            configurations={
                                recentFranchise?.payload
                                    ?.franchise_angies_list_task_name_configurations ?? null
                            }
                            current_servicetitan_campaign_id={
                                recentFranchise?.payload
                                    ?.franchise_angies_list_configuration?.servicetitan_campaign_id ?? 0
                            }
                            servicetitanJobTypes={servicetitanJobTypes}
                            servicetitanBusinessUnits={servicetitanBusinessUnits}
                            isServiceTitanBusinessUnitsLoading={isServiceTitanBusinessUnitsLoading}
                            isServiceTitanJobTypesLoading={isServiceTitanJobTypesLoading}
                            servicetitanCampaigns={servicetitanCampaigns}
                            isServiceTitanCampaignsLoading={isServiceTitanCampaignsLoading}
                        />
                        <h3>After Change</h3>
                        <AngieList
                            configurations={
                                showingFranchiseDetails?.payload
                                    ?.franchise_angies_list_task_name_configurations ?? null
                            }
                            current_servicetitan_campaign_id={
                                showingFranchiseDetails?.payload
                                    ?.franchise_angies_list_configuration?.servicetitan_campaign_id ?? 0
                            }
                            servicetitanJobTypes={servicetitanJobTypes}
                            servicetitanBusinessUnits={servicetitanBusinessUnits}
                            isServiceTitanBusinessUnitsLoading={isServiceTitanBusinessUnitsLoading}
                            isServiceTitanJobTypesLoading={isServiceTitanJobTypesLoading}
                            servicetitanCampaigns={servicetitanCampaigns}
                            isServiceTitanCampaignsLoading={isServiceTitanCampaignsLoading}
                        />
                    </Box>

                );

            case "owners":
                return (

                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                        <h3>Before Change</h3>
                        <OwnerSection
                            franchiseOwners={recentFranchise?.payload?.owners ?? null}
                        />
                        <h3>After Change</h3>
                        <OwnerSection
                            franchiseOwners={showingFranchiseDetails?.payload?.owners ?? null}
                        />
                    </Box>

                );
            case "faqs":
                return (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                        <h3>Before Change</h3>
                        <FAQSection
                            franchise_faqs={recentFranchise?.payload?.faq_template ?? null}
                        />
                        <h3>After Change</h3>
                        <FAQSection
                            franchise_faqs={showingFranchiseDetails?.payload?.faq_template ?? null}
                        />
                    </Box>
                )
            default:
                return <></>
        }
    }

    return (
        <Dialog
            open={isShowFranchise}
            fullWidth
            maxWidth="xl"
            className="dialog-zindex"
        >
            {isLoading ? (
                <CircularProgress sx={{ alignSelf: 'center' }} size={30} color="inherit" />
            ) : isShowComparison && comparisonSection ? (<div
                style={{ paddingBlock: "10px" }}
                className={"dialog"}
            >

                <h2 style={{ margin: "0px" }}>Audit Comparison</h2>

                <IconButton
                    size={"small"}
                    aria-label="delete"
                    color="info"
                    style={{ position: "absolute", right: "10px", top: "15px" }}
                    onClick={() => {
                        setIsShowComparison(false)
                        setComparisonSection()
                    }}
                >
                    <Tooltip title={"Go back"}>
                        <ExpandCircleDownIcon style={{ height: "20px", width: "20px", transform: "rotate(180deg)" }} />
                    </Tooltip>

                </IconButton>


                {getComparisonSection(comparisonSection)}

            </div>) :

                (
                    <div
                        style={{ paddingBlock: "10px", paddingInline: "20px" }}
                        className={"dialog"}
                    >

                        <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'center' }}>
                            <h2 style={{ margin: '0px' }}>Audit Details</h2>
                            {franchiseDetails?.filter?.audit_option === "Franchise Delete" && showingFranchiseDetails?.payload?.franchise?.is_removed && <Chip color="error" style={{ fontWeight: 'bold' }} label="Deleted" />}
                            {franchiseDetails?.filter?.audit_option === "Franchise Active/Deactive" && franchiseDetails?.payload?.is_enabled == 0 && <Chip color="warning" style={{ fontWeight: 'bold' }} label="Deactivated" />}
                            {franchiseDetails?.filter?.audit_option == "Franchise Active/Deactive" && franchiseDetails?.payload?.is_enabled == 0 && (<Typography style={{ color: 'red' }}>
                                Please note that the brand details will be removed, and the Lumin toggle will be disabled. This action may occur upon reactivation of the franchise and subsequent updates.
                            </Typography>)}
                            {franchiseDetails?.filter?.audit_option === "Franchise Active/Deactive" && franchiseDetails?.payload?.is_enabled == 1 && <Chip color="success" style={{ fontWeight: 'bold' }} label="Activated" />}
                            {franchiseDetails?.filter?.audit_option === "Franchise Update" && !recentFranchise && <Chip style={{ fontWeight: 'bold' }} label="Audit comparison is not possible as this is the only audit available for this franchise." />}
                        </div>




                        <IconButton
                            size={"small"}
                            aria-label="delete"
                            style={{ position: "absolute", right: "10px", top: "15px" }}
                            onClick={() => setIsShowFranchise(false)}
                        >
                            <CloseIcon style={{ height: "15px", width: "15px" }} />
                        </IconButton>

                        {showingFranchiseDetails?.payload &&
                            Object.keys(showingFranchiseDetails?.payload).length > 0 && (
                                <>
                                    {sectionKeys.map(
                                        (sectionKey, index) =>
                                            validateSectionKey(sectionKey) && (
                                                <CollapseArea
                                                    key={index}
                                                    index={index}
                                                    sectionKey={sectionKey}
                                                />
                                            )
                                    )}
                                </>
                            )}

                        <CollapseArea key={7} index={7} sectionKey={"ServiceTitan"} />
                    </div>
                )}
        </Dialog>
    );
}

function MainSection(props) {
    const { franchise, lastFranchise } = props;
    return (
        <Grid container spacing={1}>
            <Grid item xs={franchise?.is_toll_free ? 7 : 5}>
                <CustomAuditTextField
                    value={
                        lastFranchise
                            ? getValue(
                                lastFranchise?.franchise_name ?? "",
                                franchise?.franchise_name ?? ""
                            )
                            : franchise?.franchise_name ?? ""
                    }
                    type={"text"}
                    fullWidth
                    label={"Franchise Name"}
                    size={"small"}
                    margin="normal"
                    variant="outlined"
                    error={
                        lastFranchise
                            ? isValueChanged(
                                lastFranchise?.franchise_name ?? "",
                                franchise?.franchise_name ?? ""
                            )
                            : false
                    }
                    InputProps={{
                        readOnly: true,
                        disableUnderline: true,
                        pointerEvents: "none",
                    }}
                />
            </Grid>
            <Grid item xs={5}>
                <CustomAuditTextField
                    value={
                        lastFranchise
                            ? getValue(
                                lastFranchise?.franchise_location ?? "",
                                franchise?.franchise_location ?? ""
                            )
                            : franchise?.franchise_location ?? ""
                    }
                    type={"text"}
                    fullWidth
                    label={"Franchise Location"}
                    size={"small"}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                        readOnly: true,
                        disableUnderline: true,
                    }}
                    error={
                        lastFranchise
                            ? isValueChanged(
                                lastFranchise?.franchise_location ?? "",
                                franchise?.franchise_location ?? ""
                            )
                            : false
                    }
                />
            </Grid>
            {!Boolean(franchise?.is_toll_free) && (
                <>
                    <Grid item xs={2}>
                        <CustomAuditTextField
                            value={
                                lastFranchise
                                    ? getValue(
                                        lastFranchise?.exact_zipcode ?? "",
                                        franchise?.exact_zipcode ?? ""
                                    )
                                    : franchise?.exact_zipcode ?? ""
                            }
                            type={"text"}
                            fullWidth
                            label={"Franchise Exact ZIP Code"}
                            size={"small"}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                                disableUnderline: true,
                            }}
                            error={
                                lastFranchise
                                    ? isValueChanged(
                                        lastFranchise?.exact_zipcode ?? "",
                                        franchise?.exact_zipcode ?? ""
                                    )
                                    : false
                            }
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <CustomAuditTextField
                            value={
                                lastFranchise
                                    ? getValue(
                                        lastFranchise?.franchise_id ?? "",
                                        franchise?.franchise_id ?? ""
                                    )
                                    : franchise?.franchise_id ?? ""
                            }
                            type={"number"}
                            fullWidth
                            label={"Franchise Id"}
                            size={"small"}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                                disableUnderline: true,
                            }}
                            error={
                                lastFranchise
                                    ? isValueChanged(
                                        lastFranchise?.franchise_id ?? "",
                                        franchise?.franchise_id ?? ""
                                    )
                                    : false
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <CustomAuditTextField
                            value={
                                lastFranchise
                                    ? getValue(
                                        lastFranchise?.service_brand ?? "",
                                        franchise?.service_brand ?? ""
                                    )
                                    : franchise?.service_brand ?? ""
                            }
                            fullWidth
                            label={"CRM System"}
                            size={"small"}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                                disableUnderline: true,
                            }}
                            error={
                                lastFranchise
                                    ? isValueChanged(
                                        lastFranchise?.service_brand ?? "",
                                        franchise?.service_brand ?? ""
                                    )
                                    : false
                            }
                        />
                    </Grid>

                    <Grid item xs={7}>
                        <CustomAuditTextField
                            value={
                                lastFranchise
                                    && isValueChanged(
                                        lastFranchise?.time_zone ?? "",
                                        franchise?.time_zone ?? ""
                                    )
                                    ? `${TIME_ZONES.find((zone) => zone?.zone_id === lastFranchise?.time_zone ?? "")?.zone ?? ""} --> ${TIME_ZONES.find((zone) => zone?.zone_id === franchise?.time_zone ?? "")?.zone ?? ""}` : TIME_ZONES.find((zone) => zone?.zone_id === franchise?.time_zone ?? "")?.zone ?? ""
                            }
                            fullWidth
                            label={"Time Zone"}
                            size={"small"}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                                disableUnderline: true,
                            }}
                            error={
                                lastFranchise
                                    ? isValueChanged(
                                        lastFranchise?.time_zone ?? "",
                                        franchise?.time_zone ?? ""
                                    )
                                    : false
                            }
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <Box display="flex" flexDirection="row" alignItems="center" >
                            <CheckBoxComparisonComponent value={franchise?.is_call_center_booking} lastValue={lastFranchise?.is_call_center_booking} title={"Call Center Booking"} />
                        </Box>
                    </Grid>


                </>
            )}
        </Grid>
    );
}

function FAQSection(props) {
    const { franchise_faqs } = props;
    return (
        <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%", mb: 2, padding: "1em" }}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={"small"}
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell align="left">Question</TableCell>
                                <TableCell align="left">Answer</TableCell>
                                <TableCell align="left">Keywords</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {franchise_faqs?.length === 0 ? (
                                <TableCell
                                    component="th"
                                    align={"center"}
                                    colSpan={3}
                                    scope="row"
                                >
                                    No Data to Show
                                </TableCell>
                            ) : (
                                franchise_faqs?.map?.((faq, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow tabIndex={-1} key={faq?.id}>
                                            <TableCell
                                                component="th"
                                                align={"left"}
                                                padding={"1em"}
                                                id={labelId}
                                                scope="row"
                                            >
                                                {index + 1}
                                            </TableCell>
                                            <TableCell align="left">
                                                {faq.question.split('\n').map((line, index) => (
                                                    <React.Fragment key={index}>
                                                        {line}
                                                        <br />
                                                    </React.Fragment>
                                                ))}
                                            </TableCell>
                                            <TableCell align="left">
                                                {faq.answer.split('\n').map((line, index) => (
                                                    <React.Fragment key={index}>
                                                        {line}
                                                        <br />
                                                    </React.Fragment>
                                                ))}
                                            </TableCell>
                                            <TableCell align="left">
                                                {faq?.keywords?.map?.((keyword) => {
                                                    return (
                                                        <Chip
                                                            sx={{ marginTop: "0.2em", marginRight: "0.2em" }}
                                                            label={keyword}
                                                        />
                                                    );
                                                }) ?? <></>}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );
}

function BrandSection(props) {
    const { userFranchiseBrands, isTollFree, brands } = props;
    return (
        <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%", mb: 2, padding: "1em" }}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={"small"}
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell align="left">Brand</TableCell>
                                <TableCell align="left">Channel IDs</TableCell>
                                {isTollFree ? (
                                    <></>
                                ) : (
                                    <TableCell align="left">Primary ZIP/Postal Codes</TableCell>
                                )}
                                {isTollFree ? (
                                    <></>
                                ) : (
                                    <TableCell align="left">Secondary ZIP/Postal Codes</TableCell>
                                )}
                                {isTollFree ? (
                                    <></>
                                ) : (
                                    <TableCell align="left">Angie's Entity Ids</TableCell>
                                )}
                                {isTollFree ? (
                                    <></>
                                ) : (
                                    <TableCell align="left">Outbound Channel</TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {userFranchiseBrands?.length === 0 ? (
                                <TableCell
                                    component="th"
                                    align={"center"}
                                    colSpan={6}
                                    scope="row"
                                >
                                    No Data to Show
                                </TableCell>
                            ) : (
                                userFranchiseBrands?.map?.((b, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow tabIndex={-1} key={b?.franchise_brand?.brand_id}>
                                            <TableCell
                                                component="th"
                                                align={"left"}
                                                padding={"1em"}
                                                id={labelId}
                                                scope="row"
                                            >
                                                {index + 1}
                                            </TableCell>
                                            <TableCell sx={{ maxWidth: "180px" }} align="left">
                                                <Tooltip
                                                    title={
                                                        getMatchingBrandByBrandId(b?.franchise_brand?.brand_id, brands)
                                                            ?.brand_name ?? ""
                                                    }
                                                >
                                                    <ImageBox
                                                        alt={"brand-img"}
                                                        src={
                                                            getMatchingBrandByBrandId(b?.franchise_brand?.brand_id, brands)
                                                                ?.brand_img ?? iBSBrandLogo
                                                        }
                                                        size={"xsmall"}
                                                    />
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell align="left">
                                                {b?.channels?.map?.((channel) => {
                                                    return (
                                                        <Chip
                                                            sx={{ marginTop: "0.2em", marginRight: "0.2em" }}
                                                            label={channel.channel_id}
                                                        />
                                                    );
                                                }) ?? <></>}
                                            </TableCell>
                                            {isTollFree ? (
                                                <></>
                                            ) : (
                                                <TableCell
                                                    component="th"
                                                    align={"left"}
                                                    id={labelId}
                                                    scope="row"
                                                >
                                                    {b?.zip_codes?.filter?.(singleZipCode => singleZipCode.type == "primary")?.length > 0 ? (
                                                        <CopyToClipBoard
                                                            textToCopy={b?.zip_codes?.filter?.(singleZipCode => singleZipCode.type == "primary").map((zipCode) => zipCode.zip_code).join?.(",")}
                                                        >
                                                            {b?.zip_codes?.filter?.(singleZipCode => singleZipCode.type == "primary").map((zipCode) => {
                                                                return (
                                                                    <Chip
                                                                        sx={{
                                                                            marginTop: "0.2em",
                                                                            marginRight: "0.2em",
                                                                        }}
                                                                        label={zipCode.zip_code}
                                                                    />
                                                                );
                                                            }) ?? <></>}
                                                        </CopyToClipBoard>
                                                    ) : (
                                                        ""
                                                    )}
                                                </TableCell>
                                            )}
                                            {isTollFree ? (
                                                <></>
                                            ) : (
                                                <TableCell
                                                    component="th"
                                                    align={"left"}
                                                    id={labelId}
                                                    scope="row"
                                                >
                                                    {b?.zip_codes?.filter?.(singleZipCode => singleZipCode.type == "secondary")?.length > 0 ? (
                                                        <CopyToClipBoard
                                                            textToCopy={b?.zip_codes?.filter?.(singleZipCode => singleZipCode.type == "secondary").map((zipCode) => zipCode.zip_code).join?.(",")}
                                                        >
                                                            {b?.zip_codes?.filter?.(singleZipCode => singleZipCode.type == "secondary").map((zipCode) => {
                                                                return (
                                                                    <Chip
                                                                        sx={{
                                                                            marginTop: "0.2em",
                                                                            marginRight: "0.2em",
                                                                        }}
                                                                        label={zipCode.zip_code}
                                                                    />
                                                                );
                                                            }) ?? <></>}
                                                        </CopyToClipBoard>
                                                    ) : (
                                                        ""
                                                    )}
                                                </TableCell>
                                            )}
                                            {isTollFree ? (
                                                <></>
                                            ) : (
                                                <TableCell
                                                    component="th"
                                                    align={"left"}
                                                    id={labelId}
                                                    scope="row"
                                                >
                                                    {b?.franchise_angies_list_sp_entity_ids?.map?.(
                                                        (franchise_angies_list_sp_entity_id) => {
                                                            return (
                                                                <Chip
                                                                    sx={{
                                                                        marginTop: "0.2em",
                                                                        marginRight: "0.2em",
                                                                    }}
                                                                    label={`${franchise_angies_list_sp_entity_id?.angies_list_sp_entity_id}`}
                                                                />
                                                            );
                                                        }
                                                    ) ?? <></>}
                                                </TableCell>
                                            )}
                                            {isTollFree ? (
                                                <></>
                                            ) : (
                                                <TableCell
                                                    component="th"
                                                    align={"left"}
                                                    id={labelId}
                                                    scope="row"
                                                >
                                                    {b?.outbound_channels?.[0]?.channel_id ? (
                                                        <Chip
                                                            sx={{ marginTop: "0.2em", marginRight: "0.2em" }}
                                                            label={`${b?.outbound_channels?.[0]?.channel_id}`}
                                                        />
                                                    ) : (
                                                        <></>
                                                    )}
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    );
                                }) ?? <></>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );
}

function ServiceTitan(props) {
    const { franchise, lastFranchise } = props;
    return (
        <Grid container spacing={1}>
            <Grid item xs={4}>
                <CustomAuditTextField
                    type={"text"}
                    fullWidth
                    value={
                        lastFranchise
                            ? getValue(
                                lastFranchise?.service_titan_tenant_id ?? "",
                                franchise?.service_titan_tenant_id ?? ""
                            )
                            : franchise?.service_titan_tenant_id ?? ""
                    }
                    label={"Service Titan Tenant Id"}
                    size={"small"}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                        readOnly: true,
                        disableUnderline: true,
                    }}
                    error={
                        lastFranchise
                            ? isValueChanged(
                                lastFranchise?.service_titan_tenant_id ?? "",
                                franchise?.service_titan_tenant_id ?? ""
                            )
                            : false
                    }
                />
            </Grid>
            <Grid item xs={4}>
                <CustomAuditTextField
                    value={
                        lastFranchise
                            ? getValue(
                                lastFranchise?.service_titan_tenant_name ?? "",
                                franchise?.service_titan_tenant_name ?? ""
                            )
                            : franchise?.service_titan_tenant_name ?? ""
                    }
                    type={"text"}
                    fullWidth
                    id="serviceTitanTenantName"
                    label={"Service Titan Tenant Name"}
                    size={"small"}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                        readOnly: true,
                        disableUnderline: true,
                    }}
                    error={
                        lastFranchise
                            ? isValueChanged(
                                lastFranchise?.service_titan_tenant_name ?? "",
                                franchise?.service_titan_tenant_name ?? ""
                            )
                            : false
                    }
                />
            </Grid>
            <Grid item xs={4}>
                <CustomAuditTextField
                    type={"text"}
                    fullWidth
                    value={
                        lastFranchise
                            ? getValue(
                                lastFranchise?.api_key ?? "",
                                franchise?.api_key ?? ""
                            )
                            : franchise?.api_key ?? ""
                    }
                    label={"API key"}
                    size={"small"}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                        readOnly: true,
                        disableUnderline: true,
                    }}
                    error={
                        lastFranchise
                            ? isValueChanged(
                                lastFranchise?.api_key ?? "",
                                franchise?.api_key ?? ""
                            )
                            : false
                    }
                />
            </Grid>
            <Grid item xs={4}>
                <CustomAuditTextField
                    type={"text"}
                    fullWidth
                    value={
                        lastFranchise
                            ? getValue(
                                lastFranchise?.service_titan_client_id ?? "",
                                franchise?.service_titan_client_id ?? ""
                            )
                            : franchise?.service_titan_client_id ?? ""
                    }
                    id="clientId"
                    label={"Client ID"}
                    size={"small"}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                        readOnly: true,
                        disableUnderline: true,
                    }}
                    error={
                        lastFranchise
                            ? isValueChanged(
                                lastFranchise?.service_titan_client_id ?? "",
                                franchise?.service_titan_client_id ?? ""
                            )
                            : false
                    }
                />
            </Grid>
            <Grid item xs={4}>
                <CustomAuditTextField
                    type={"text"}
                    fullWidth
                    value={
                        lastFranchise
                            ? getValue(
                                lastFranchise?.service_titan_client_secret ?? "",
                                franchise?.service_titan_client_secret ?? ""
                            )
                            : franchise?.service_titan_client_secret ?? ""
                    }
                    id="clientSecret"
                    label={"Client Secret"}
                    size={"small"}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                        readOnly: true,
                        disableUnderline: true,
                    }}
                    error={
                        lastFranchise
                            ? isValueChanged(
                                lastFranchise?.service_titan_client_secret ?? "",
                                franchise?.service_titan_client_secret ?? ""
                            )
                            : false
                    }
                />
            </Grid>
        </Grid>
    );
}

function AngieList(props) {
    const { configurations, current_servicetitan_campaign_id, last_servicetitan_campaign_id, servicetitanJobTypes, servicetitanBusinessUnits, servicetitanCampaigns, isServiceTitanJobTypesLoading, isServiceTitanBusinessUnitsLoading, isServiceTitanCampaignsLoading } = props;
    return (
        <Box sx={{ width: "100%" }}>

            {isServiceTitanCampaignsLoading ? (<CircularProgress sx={{ alignSelf: 'center' }} size={20} color="inherit" />) : (
                <CustomAuditTextField
                    type={"text"}
                    fullWidth
                    style={{ marginBottom: '10px' }}
                    value={
                        last_servicetitan_campaign_id >= 0
                            ? getValue(
                                getById(last_servicetitan_campaign_id, servicetitanCampaigns)?.name ?? "",
                                getById(current_servicetitan_campaign_id, servicetitanCampaigns)?.name ?? "",
                            )
                            : getById(current_servicetitan_campaign_id, servicetitanCampaigns)?.name ?? ""
                    }
                    label={"Angie's Servicetitan Campaign"}
                    size={"small"}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                        readOnly: true,
                        disableUnderline: true,
                    }}
                    sx={{ pointerEvents: "none" }}
                    error={
                        last_servicetitan_campaign_id >= 0
                            ? isValueChanged(
                                getById(current_servicetitan_campaign_id, servicetitanCampaigns)?.name ?? "",
                                getById(last_servicetitan_campaign_id, servicetitanCampaigns)?.name ?? "",
                            )
                            : false
                    }
                />
            )}

            <Paper sx={{ width: "100%", mb: 2, padding: "1em" }}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={"small"}
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell align="left">Task Name</TableCell>
                                <TableCell align="left">Job Type</TableCell>
                                <TableCell align="left">Business Unit</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {configurations?.length === 0 ? (
                                <TableCell
                                    component="th"
                                    align={"center"}
                                    colSpan={6}
                                    scope="row"
                                >
                                    No Data to Show
                                </TableCell>
                            ) : (
                                configurations?.map?.((c, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow hover tabIndex={-1} key={c.name}>
                                            <TableCell
                                                component="th"
                                                align={"left"}
                                                padding={"1em"}
                                                id={labelId}
                                                scope="row"
                                            >
                                                {index + 1}
                                            </TableCell>
                                            <TableCell sx={{ maxWidth: "180px" }} align="left">
                                                <Tooltip title={c.task_name}>
                                                    <Typography
                                                        sx={{
                                                            fontSize: "14px",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                        }}
                                                    >
                                                        {c.task_name}
                                                    </Typography>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell align="left">
                                                {isServiceTitanJobTypesLoading ? (<CircularProgress sx={{ alignSelf: 'center' }} size={20} color="inherit" />) : servicetitanJobTypes?.length > 0 && c?.servicetitan_job_type_id ? getById(c?.servicetitan_job_type_id, servicetitanJobTypes)?.name : "Job Type Not Found!"}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                align={"left"}
                                                id={labelId}
                                                scope="row"
                                            >
                                                {isServiceTitanBusinessUnitsLoading ? (<CircularProgress sx={{ alignSelf: 'center' }} size={20} color="inherit" />) : servicetitanBusinessUnits?.length > 0 && c?.servicetitan_business_unit_id ? getById(c?.servicetitan_business_unit_id, servicetitanBusinessUnits)?.name : "Business Unit Not Found!"}
                                            </TableCell>
                                        </TableRow>
                                    );
                                }) ?? <></>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );
}

function LuminSection(props) {
    const { franchise_lumin_configuration, last_franchise_lumin_configuration } =
        props;
    return (
        <CheckBoxComparisonComponent value={franchise_lumin_configuration?.is_enabled} lastValue={last_franchise_lumin_configuration?.is_enabled} title={"Enable sales accelerator"} />
    );
}

function OwnerSection(props) {
    const { franchiseOwners } = props;
    return (
        <TableContainer component={Paper} sx={{ height: 150 }}>
            <Table aria-label="simple table" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell width="20%">Name</TableCell>
                        <TableCell width="25%" align="left">
                            Email
                        </TableCell>
                        <TableCell width="25%" align="left">
                            Phone
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {franchiseOwners && franchiseOwners?.length === 0 ? (
                        <TableRow>
                            <TableCell colSpan={4} align="center" sx={{ width: "100%" }}>
                                No franchise owners found
                            </TableCell>
                        </TableRow>
                    ) : (
                        franchiseOwners?.map?.((owner, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                        {owner?.owner_name ?? null}
                                    </TableCell>
                                    <TableCell align="left">
                                        {owner.owner_email ?? null}
                                    </TableCell>
                                    <TableCell>{owner?.owner_phone ?? null}</TableCell>
                                </TableRow>
                            );
                        })
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

function CheckBoxComparisonComponent(props) {
    const { value, lastValue, title } = props;
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <text>{title}</text>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    border:
                        lastValue != undefined &&
                        isValueChanged(
                            value,
                            lastValue
                        ) &&
                        "2.5px solid #0e994a",
                }}
            >
                {lastValue != undefined &&
                    isValueChanged(
                        value,
                        lastValue
                    ) ? (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                    >
                        <Switch
                            disabled={true}
                            checked={
                                lastValue ?? false
                            }
                        />
                        <div>{"-->"}</div>
                    </div>
                ) : (<></>)}

                <Switch
                    disabled={true}
                    checked={value ?? false}
                />
            </div>
        </div>
    );
}