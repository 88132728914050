
import { useEffect, useState } from "react"
import { Container, Table, TableCell, TableContainer, TableHead, TableRow, TableBody, Pagination, CircularProgress, IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ViewJobModal from "./ViewJobModal";
import { SERVICETITAN_PAGE_SIZE } from "../../../../../constants/serviceTitan";
import { handleGetJobs,handleGetJob } from "../../../../../state-services-dublicate/serviceTitan";
import { getMatchingPriorityImage } from "../../../../../utils/imageGetters";
import useDebounce from "../../../../../hooks/useDebounce";
import CreateJobPage from "../../create-job-page/CreateJobPage";
import ErrorView from "../../../../../components/ErrorView";
import { ERROR_COMPONENTS } from "../../../../../constants/common";

export default function ServiceTitanJobs(props) {

    const urlParams = new URLSearchParams(window.location.search);
    const { brands, brand, franchise, globalSearchValue, filterState, clearUrlParamsData, setJobStatisticsComponent, jobToView, setJobToView, setNotify, setIsAddMultipleWorkorders, setAppointmentToReschedule, page, setPage } = props

    const [jobs, setJobs] = useState([]);
    const [showingJobs, setShowingJobs] = useState([]);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [selectedJobToEdit, setSelectedJobToEdit] = useState();
    const [isEditJobModalOpen, setIsEditJobModalOpen] = useState(false);
    const [isJobsLoading, setIsJobsLoading] = useState(false);
    const [isJobsLoadingError, setIsJobsLoadingError] = useState(false);
    const [toggleRefresh, setToggleRefresh] = useState(false)
    const debouncedServiceTitanJobs = useDebounce({ franchiseId: franchise.id, page: page, createdStart: filterState.createdStart, createdEnd: filterState.createdEnd, jobStatus: filterState.jobStatus, setJobStatisticsComponent }, ({ franchiseId, page, createdStart, createdEnd, jobStatus, setJobStatisticsComponent }) => {
        handleGetJobs(franchiseId, page, SERVICETITAN_PAGE_SIZE, createdStart, createdEnd, jobStatus.replace(/\s/g, ""), setJobs, setIsJobsLoading, setIsJobsLoadingError, setTotalPageCount, setJobStatisticsComponent);
    }, 1000)

    useEffect(() => {
        if (urlParams.get("isFromCallLog") && urlParams.get("serviceTitanJobid") && urlParams.get("franchiseId")) {
            const jobId = parseInt(urlParams.get("serviceTitanJobid"))
            const franchiseId = parseInt(urlParams.get("franchiseId"))
            handleGetJob(jobId ,franchiseId, onViewJobIconClicked)
        }

    },[])

    useEffect(() => {
        setShowingJobs(filterJobsByGlobalSearchValue(globalSearchValue, jobs))
    }, [globalSearchValue, jobs])

    useEffect(() => {
        if (jobToView) onViewJobIconClicked(jobToView)
    }, [jobToView])

    useEffect(() => {
        if (franchise?.id) {
            setShowingJobs([])
            debouncedServiceTitanJobs({ franchiseId: franchise?.id, page: page, createdStart: filterState.createdStart, createdEnd: filterState.createdEnd, jobStatus: filterState.jobStatus, setJobStatisticsComponent })
        }
    }, [franchise?.id, filterState.createdStart, filterState.createdEnd, filterState.jobStatus, toggleRefresh, page, debouncedServiceTitanJobs])

    function showNotification(notify = {}) {
        setNotify({ ...notify, isOpen: true })
    }

    function onViewJobModalClosed(editedJob = {}, appointmentCount = 0) {
        const newJobs = jobs.map((job) => {
            if (job.id === editedJob.id) {
                job.appointmentCount = appointmentCount;
            }
            return job
        })
        clearUrlParamsData()
        setJobs(newJobs);
        setJobToView()
    }

    function onViewJobIconClicked(job = {}) {
        setJobToView(job)
    }

    function onEditJobIconClicked(job = {}) {
        setSelectedJobToEdit(job)
        setIsEditJobModalOpen(true)
    }

    function onEditJobClosed(editedJob) {
        setIsEditJobModalOpen(false);
        setJobToView(editedJob)
        if (!editedJob) return
        const previousJobs = [...jobs]
        const newJobsWithEditedJob = previousJobs.map((j) => {
            if (j.id === editedJob?.id) {
                return editedJob
            } else {
                return j
            }
        })
        setJobs(newJobsWithEditedJob)
    }

    return <div style={{ height: "60vh" }}>
        <TableContainer sx={{ height: "80%" }}>
            <Table stickyHeader='true'>
                <TableHead>
                    <TableRow >
                        <TableCell style={{ textAlign: "left" }} >#</TableCell>
                        <TableCell style={{ textAlign: "left" }} >Id</TableCell>
                        <TableCell style={{ textAlign: "left" }}  >Job Status</TableCell>
                        <TableCell style={{ textAlign: "center" }}  >App. Count</TableCell>
                        <TableCell style={{ textAlign: "left" }} >Priority</TableCell>
                        <TableCell style={{ textAlign: "left" }}  ><Container style={{ padding: 0, cursor: "pointer" }} sx={{ display: "flex", flexDirection: "row", columnGap: "0.5em", alignItems: "center", justifyContent: "left", }} onClick={() => { }}>{"Created Date & Time"}{""}</Container></TableCell>
                        <TableCell style={{ textAlign: "left" }} >Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody >
                    {isJobsLoading ? buildLoadingRow() : isJobsLoadingError ? buildErrorRow() : showingJobs.length === 0 ? buildNoJobsToShow() : buildJobRows(showingJobs)}
                </TableBody>
            </Table>
        </TableContainer>
        <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "end" }}>
            <Pagination
                page={page}
                onChange={(e, v) => {
                    setPage(v);
                }} color="standard" count={globalSearchValue ? 1 : Math.ceil(totalPageCount / SERVICETITAN_PAGE_SIZE)} sx={{ alignSelf: "right" }}
            />
        </div>
        {isEditJobModalOpen ? <CreateJobPage onClose={onEditJobClosed} selectedJobToEdit={selectedJobToEdit} brands={brands} brand={brand} franchise={franchise} showNotification={showNotification} selectedJobToView={jobToView} setSelectedJobToView={setJobToView} /> : <></>}
        {jobToView ? <ViewJobModal onClose={onViewJobModalClosed} open={jobToView !== undefined} setToggleRefresh={setToggleRefresh} toggleRefresh={toggleRefresh} job={jobToView} brand={brand} franchise={franchise} showNotification={showNotification} setIsAddMultipleWorkorders={setIsAddMultipleWorkorders} setAppointmentToReschedule={setAppointmentToReschedule} /> : <></>}
    </div>

    function formatJobStatus(jobStatus = "-") {
        if (jobStatus === "InProgress") {
            return "In Progress"
        } else {
            return jobStatus
        }
    }

    function buildJobRows(jobs = []) {
        return jobs.map((job, i) => {
            return (
                <TableRow key={i}>
                    <TableCell >{i + 1}</TableCell>
                    <TableCell >{job?.id}</TableCell>
                    <TableCell >{formatJobStatus(job?.jobStatus)}</TableCell>
                    <TableCell style={{ textAlign: "center" }}>{job?.appointmentCount ?? "-"}</TableCell>
                    <TableCell className="table-cell" >
                        <Tooltip enterDelay={1000} enterTouchDelay={1000} title={job?.priority}><img alt={job?.priority} src={getMatchingPriorityImage(job?.priority)} height="25px" /></Tooltip>
                    </TableCell>
                    <TableCell >{job?.createdOn ? moment(job?.createdOn).format('MM/DD/YYYY - h:mm A') : "-"}</TableCell>
                    <TableCell >
                        <IconButton onClick={() => {
                            onViewJobIconClicked(job);
                        }} color="primary" size={"medium"} style={{ marginRight: "10px" }}>
                            <Tooltip title={"View Job"}><VisibilityIcon style={{ height: "20px", width: "20px" }} /></Tooltip>
                        </IconButton>
                        <IconButton onClick={() => {
                            onEditJobIconClicked(job)
                        }} color='info' size={"medium"} style={{ marginRight: "10px" }}>
                            <Tooltip title={"Edit Job"}><EditIcon style={{ height: "20px", width: "20px" }} /></Tooltip>
                        </IconButton>
                    </TableCell>
                </TableRow>
            );
        })
    }

}


function buildErrorRow() {
    return buildInformationRowContainer(<ErrorView type={ERROR_COMPONENTS.SomthingWentWrong.type} />)
}

function buildNoJobsToShow() {
    return buildInformationRowContainer(<ErrorView type={ERROR_COMPONENTS.NoJobsFound.type} />)
}

function buildLoadingRow() {
    return buildInformationRowContainer(<CircularProgress size={30} color="inherit" />)
}

function buildInformationRowContainer(children) {
    return <TableRow><TableCell colSpan={9} align="center" style={{ border: "none", height: "300px", color: "grey" }}>
        {children}
    </TableCell></TableRow>;
}

function filterJobsByGlobalSearchValue(searchValue = "", jobs = []) {
    if (!searchValue) return jobs
    const filterdJobs = jobs.filter((job) => {
        const jobString = `${job.id ?? ""}${job.priority ?? ""}${job.jobStatus ?? ""}`.toLowerCase()
        return jobString.includes(searchValue.trim().toLowerCase())
    })
    return filterdJobs;
}

