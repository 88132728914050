import { Box, CircularProgress, Typography } from "@mui/material";
import ReactApexChart from "react-apexcharts";

const INITIAL_CHART_DATA = {
    title: "",
    series: [],
    labels: []
}

export default function Chart(props) {
    const { data = INITIAL_CHART_DATA, width, isSmall = true, isSelected = false, onClick = (data) => { }, isLoading, isError } = props
    return <div onClick={() => onClick(data)} >
        <Box sx={{ padding: "0.5em", backgroundColor: 'background.main', borderRadius: "0.5em", boxShadow: isSelected ? "0px 2px 5px" : undefined, opacity: isSelected ? 1 : [0.9, 0.8, 0.7], display: "flex", flexDirection: "column", alignItems: "center" }} >
            {(isLoading) ? <Box sx={{ width: "200px", height: "200px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                <CircularProgress style={{ marginTop: "auto", marginBottom: "auto" }} color="inherit" />
                {(data.title !== "SMS") && (data.title !== "Chat") && (data.title !== "Email") ?
                    <Typography fontSize={isSmall ? 12 : 14} fontWeight={600} >{data.title}</Typography>
                    : <></>
                }
            </Box> :
                isError ?
                    <Box sx={{ width: "200px", height: "200px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <h6>Something Went Wrong</h6>
                    </Box>
                    :
                    <>
                        <ReactApexChart height={isSmall ? width : "250px"} options={{
                            chart: {
                                width: width,
                                type: 'donut',
                                dropShadow: {
                                    enabled: true,
                                    color: '#111',
                                    top: -1,
                                    left: 3,
                                    blur: 3,
                                    opacity: 0.2
                                }
                            },
                            stroke: {
                                width: 0,
                            },
                            dataLabels: {
                                enabled: true,
                                dropShadow: {
                                    blur: 3,
                                    opacity: 0.8
                                },
                            },
                            plotOptions: {
                                pie: {
                                    donut: {
                                        ignoreZeroSeries: false,
                                        labels: {
                                            show: true,
                                            total: {
                                                showAlways: true,
                                                show: true,
                                                formatter: function (w) {
                                                    let total = 0
                                                    w?.globals?.seriesTotals?.forEach(element => {
                                                        total = total + element
                                                    });
                                                    return total.toLocaleString()
                                                },
                                                color: '#7B7A75',
                                            },
                                            value: {
                                                color: '#7B7A75',
                                            }
                                        }
                                    }
                                }
                            },
                            labels: data.labels,
                            legend: {
                                show: !isSmall,
                                position: 'right',
                                offsetY: 0,
                                height: 230,
                            },
                        }}
                            series={data.series} type="donut" width={width} />
                        {(data.title !== "SMS") && (data.title !== "Chat") && (data.title !== "Email") ?
                            <Typography fontSize={isSmall ? 12 : 14} fontWeight={600} >{data.title}</Typography>
                            : <></>
                        }
                    </>
            }
        </Box>
    </div>
}

export function getChartDataFromMediaReport(title = "", mediaReport = {}) {
    return {
        title: title,
        labels: ["Accepted", "Abandoned", "Rejected", "Rejected Time-Out", "Alert"],
        series: [mediaReport.accepted ? mediaReport.accepted : 0, mediaReport.abandoned ? mediaReport.abandoned : 0, mediaReport.rejected ? mediaReport.rejected : 0, mediaReport.rejected_timeout ? mediaReport.rejected_timeout : 0, mediaReport.alert ? mediaReport.alert : 0],
        mediaReport: mediaReport
    }
}