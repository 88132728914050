import { createKeywords, deleteKeywordByKeyword, getFaqKeywords, getKeywords } from "../../services/faq/faq"

export async function handleGetKeywords(setKeywords, setIsKeywordsLoading, setIsKeywordsError) {
    try {
        setKeywords([])
        setIsKeywordsError(false)
        setIsKeywordsLoading(true)
        const response = await getKeywords()
        if (Array.isArray(response)) setKeywords(response)
    } catch (error) {
        setIsKeywordsError(true)
    } finally {
        setIsKeywordsLoading(false)
    }
}

export async function handleFaqGetKeywords(faqId, setKeywords, setIsKeywordsLoading, setIsKeywordsError) {
    try {
        setKeywords([])
        setIsKeywordsError(false)
        setIsKeywordsLoading(true)
        const response = await getFaqKeywords(faqId)
        if (Array.isArray(response)) setKeywords(response)
    } catch (error) {
        setIsKeywordsError(true)
    } finally {
        setIsKeywordsLoading(false)
    }
}

export async function handleDeleteKeywordByKeyword(keyword = "", onError = () => { }, onDone = () => { }) {
    try {
        await deleteKeywordByKeyword(keyword)
        onDone()
    } catch {
        onError()
    }
}

export async function handleAddKeywords(keywords = [], onError = () => { }, onDone = () => { }) {
    try {
        const keywordsObjects = keywords?.map?.((keyword) => ({ keyword: keyword?.trim?.() }))
        await createKeywords(keywordsObjects)
        onDone()
    } catch {
        onError()
    }
}