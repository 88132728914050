import React from 'react'
import "../styles/scoreDashboardStyles.css"

export default function AgentTimer({ time }) {
    return (
        <div className='agent-timer'>
            {time?.toString().length < 2 ? <div style={{ display: "flex" }}><p style={{ fontSize: "15px" }}>0</p><p style={{ fontSize: "15px" }}>{time}</p></div> : <p style={{ fontSize: "15px" }}>{time}</p>}
        </div>
    )
}
