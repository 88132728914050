import React, { useContext, useEffect, useState } from 'react'
import { Autocomplete, Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import SmallCard, { SmallCardForEstimateSubTotal } from '../../../../../components/servicetitan/SmallCard'
import moment from 'moment'
import LoadingButton, { LoadingButtonForDelete } from '../../../../../components/LoadingButton'
import { CommonContext } from '../../../../../contexts/CommonContextProvider'
import { handleDismissServiceTitanEstimate, handleDismissServiceTitanEstimateLog, handleGetServiceTitanEmployees, handleSellServiceTitanEstimate, handleSoldServiceTitanEstimateLog } from '../../../../../state-services/serviceTitanEstimates/serviceTitanEstimates'
import { CustomTextField } from '../../../faq/utils/CustomFaqStyles'
import RenderInput from '../../../../../components/servicetitan/RenderInput'

export default function Estimate({ brand, franchise, estimate, isSoldEstimate, isDismissEstimate }) {

    const { showNotification } = useContext(CommonContext);
    const [createdByEmployee, setCreatedByEmployee] = useState({})
    const [isSoldButtonLoading, setIsSoldButtonLoading] = useState(false)
    const [isDismissButtonLoading, setIsDismissButtonLoading] = useState(false)
    const [selectedEmployee, setSelectedEmployee] = useState(null)
    const [isEmployeesLoading, setIsEmployeesLoading] = useState([])
    const [isEmployeesLoadingError, setIsEmployeesLoadingError] = useState(false)
    const [employees, setEmployees] = useState([])
    const [itemTypes, setItemTypes] = useState([])

    useEffect(() => {
        handleGetServiceTitanEmployees(franchise, setEmployees, estimate?.soldBy, setCreatedByEmployee, setIsEmployeesLoading, setIsEmployeesLoadingError)
        return () => {
            setCreatedByEmployee({})
        }
    }, [franchise, estimate?.soldBy])

    useEffect(() => {
        if (estimate?.status?.name === "Sold" || estimate?.status?.name === "Dismissed") {
            let employee = employees.find((employee) => employee?.id === estimate?.soldBy)
            setSelectedEmployee(employee)
        }
        return () => {
            setSelectedEmployee(null)
        }
    }, [employees, estimate?.status?.name, estimate?.soldBy])

    useEffect(() => {
        let itemTypes = estimate?.items?.map((item) => item?.sku?.type)
        itemTypes = [...new Set(itemTypes)]
        setItemTypes(itemTypes)
        return () => {
            setItemTypes([])
        }
    }, [estimate?.items])

    async function handleConvertEstimate(franchise, setIsLoading, showNotification, estimate) {
        try {
            await handleSellServiceTitanEstimate(franchise?.id, estimate?.id, selectedEmployee?.id, setIsLoading, showNotification, isSoldEstimate, handleSoldEstimateLog)
        } catch (error) {
        }
    }

    async function handleSoldEstimateLog() {
        try {
            await handleSoldServiceTitanEstimateLog(brand?.id, franchise?.id, estimate?.id)
        } catch (error) {
        }
    }

    async function handleDismissEstimate(franchise, setIsLoading, showNotification, estimate) {
        try {
            await handleDismissServiceTitanEstimate(franchise?.id, estimate?.id, setIsLoading, showNotification, isDismissEstimate, handleDismissEstimateLog)
        } catch (error) {
        }
    }

    async function handleDismissEstimateLog() {
        try {
            await handleDismissServiceTitanEstimateLog(brand?.id, franchise?.id, estimate?.id)
        } catch (error) {
        }
    }

    function isDisableSoldButton() {
        return isSoldEstimate?.current || isDismissEstimate?.current || estimate?.status?.name === "Dismissed" || estimate?.status?.name === "Sold"
    }

    function isDisableDismissButton() {
        return isDismissEstimate?.current || isSoldEstimate?.current || estimate?.status?.name === "Dismissed" || estimate?.status?.name === "Sold"
    }

    function buildTable(items, type) {



        return (
            <Box sx={{ display: "flex", flexDirection: "column", marginTop: "30px" }}>
                <Typography style={{ fontSize: "1em", fontWeight: "bold", margin: "0", marginBottom: "0.3em" }}>{type}</Typography>
                <TableContainer sx={{ flexGrow: 2, display: "flex", flexDirection: "column", marginTop: "0.3em" }}>
                    <Table aria-label="simple table" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell className="table-header" style={{ textAlign: "left" }} >#</TableCell>
                                <TableCell className="table-header" style={{ textAlign: "left", width: "300px" }} >Name</TableCell>
                                <TableCell className="table-header" style={{ textAlign: "left" }}  >QTY</TableCell>
                                <TableCell className="table-header" style={{ textAlign: "left" }}  >Unit Price</TableCell>
                                <TableCell className="table-header" style={{ textAlign: "left" }} >Total Price</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items?.map((item, index) => {
                                return (
                                    <TableRow>
                                        <TableCell style={{ textAlign: "left" }} >{index + 1}</TableCell>
                                        <TableCell style={{ textAlign: "left", widthi: "300px" }} >{item?.sku?.displayName}</TableCell>
                                        <TableCell style={{ textAlign: "left" }}  >{item?.qty}</TableCell>
                                        <TableCell style={{ textAlign: "left" }}  >{item?.unitRate}</TableCell>
                                        <TableCell style={{ textAlign: "left" }} >{`$ ${item?.total}`}</TableCell>
                                    </TableRow>
                                )
                            })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box sx={{ display: "flex" }}>
                    <Typography sx={{ fontSize: "0.9em", display: "inline-block", fontWeight: "bold", marginLeft: "auto", marginRight: "1em", marginBottom: "0.2em", marginTop: "0.7em", width: "auto" }}>Total Price {`$ ${items?.reduce((acc, item) => acc + item?.total, 0)}`}</Typography>
                </Box>
            </Box>
        )
    }

    return (
        <Box>
            <Paper sx={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center", gap: "0.5em", padding: "0.25em", justifyContent: "space-evenly" }}>
                <SmallCard heading="Received" value={estimate?.createdOn ? moment(new Date(estimate?.createdOn))?.format('MM/DD/YYYY h:mm A') : "-"} />
                <SmallCard heading="Status" value={estimate?.status?.name ?? "-"} />
                <SmallCard heading="Job Id" value={estimate?.jobId ?? "-"} />
                <SmallCard heading="Name" value={estimate?.name ?? "-"} />
                <SmallCard heading="Business Unit" value={estimate?.businessUnitName ?? "-"} />
                <SmallCardForEstimateSubTotal heading="SubTotal" value={estimate?.subtotal ? `$ ${estimate?.subtotal}` : "-"} />
                <SmallCard heading="Sold By" value={createdByEmployee?.name ?? "-"} />
            </Paper>
            <Box sx={{ overflowY: "auto", display: "flex", flexDirection: "column", gap: "0.25em", padding: "0.25em", flexGrow: "1", width: "100%" }}>
                {(estimate?.summary !== "" || estimate?.items?.length !== 0) &&
                    <Typography style={{ fontSize: "1.5rem", fontWeight: "bold" }}>Overview</Typography>
                }
                {estimate?.summary !== "" &&
                    <Box sx={{ display: "flex", flexDirection: "column", marginLeft: "30px" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", marginTop: "1em" }}>
                            <Typography style={{ fontSize: "1.1em", fontWeight: "bold", margin: "0", marginBottom: "0.3em" }}>Summary</Typography>
                            <Box sx={{ inlineSize: '100%', overflowX: 'auto', overflowWrap: 'break-word' }}>
                                <Typography >{estimate?.summary}</Typography>
                            </Box>
                        </Box>
                    </Box>
                }
                {estimate?.items?.length !== 0 &&
                    <Box sx={{ display: "flex", flexDirection: "column", maxHeight: "40vh", overflow: 'auto', marginLeft: "30px" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", marginTop: "1em" }}>
                            <Typography style={{ fontSize: "1.1em", fontWeight: "bold" }}>Estimate Items</Typography>
                            <Box sx={{ inlineSize: '100%' }}>
                                {itemTypes?.map((type) => {
                                    return buildTable(estimate?.items?.filter((item) => item?.sku?.type === type), type)
                                })
                                }
                            </Box>
                        </Box>
                    </Box>
                }
                <form onSubmit={(e) => {
                    e.preventDefault();
                    handleConvertEstimate(franchise, setIsSoldButtonLoading, showNotification, estimate)
                }
                }>
                    <Paper sx={{ width: "100%", borderRadius: "0.5em", border: "1px solid gray", marginTop: "0.5em", marginBottom: "2em", padding: "0.5em" }}>
                        <Box sx={{ display: "flex", flexDirection: "row", padding: '10px', width: "100%" }}>
                            <Autocomplete
                                sx={{ width: "30%" }}
                                size="small"
                                value={selectedEmployee ?? null}
                                noOptionsText={isEmployeesLoadingError ? "Something went wrong" : "No Employees Found"}
                                disablePortal={false}
                                disabled={isSoldEstimate?.current || isDismissEstimate?.current || estimate?.status?.name === "Dismissed" || estimate?.status?.name === "Sold"}
                                loading={isEmployeesLoading}
                                options={employees}
                                getOptionLabel={(employee) => employee?.name}
                                renderOption={(props, employee) => <RenderInput {...props} key={employee?.id} content={employee?.name ?? "-"} />}
                                onChange={(_, employee) => {
                                    setSelectedEmployee(employee);
                                }}
                                renderInput={(params) => <CustomTextField {...params} required={true} label="Select Who Sold This Estimate" />}
                            />
                            <LoadingButton type="submit" size={"small"} variant="contained" disabled={isDisableSoldButton()} loading={isSoldButtonLoading} style={{ marginLeft: "auto" }} className={isDisableSoldButton() ? "btn-primary" : "btn-disabled"}>
                                Sold/Convert
                            </LoadingButton>
                            <LoadingButtonForDelete size={"small"} variant="contained" disabled={isDisableDismissButton()} loading={isDismissButtonLoading} style={{ marginLeft: "10px", marginRight: "5px" }} className={isDisableDismissButton() ? "btn-warning" : "btn-disabled"}
                                onClick={() => handleDismissEstimate(franchise, setIsDismissButtonLoading, showNotification, estimate)}
                            >
                                Dismiss
                            </LoadingButtonForDelete>
                        </Box>
                    </Paper>
                </form>
            </Box>
        </Box>
    )
}
