import { Autocomplete, Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import RenderInput from "../../../../../components/servicetitan/RenderInput";
import { CREATE_JOB_SECTION_IDS } from "../../../../../constants/jobs";
import { PRIORITIES } from "../../../../../constants/serviceTitan";
import { handleGetBusinessUnits, handleGetCampaigns, handleGetJobTypes, handleGetTagTypes, handleGetZones, resetJobDetailsData } from "../../../../../state-services-dublicate/serviceTitan";
import { CustomTextField } from "../../components/customComponents";
import { handleGetZipCodes } from "../../../../../state-services/zipCodes/zipCode";
import { getIsValidUSOrCanadianZipCode } from "../../../../../utils/validators";

const BRAND_ID_OF_360_PAINTINGS = 10

export function shouldFilterFor360Paintings(brand) {
    return brand?.brand_id === BRAND_ID_OF_360_PAINTINGS
}

export default function CreateJobSection(props) {
    const { franchise, brand, shouldLoadDetailsForJob, setShouldLoadDetailsForJob, isEditJob, job, setJob, onTagTypesChanged = () => { }, zipCode } = props

    const [campaigns, setCampaigns] = useState([]);
    const [campaign, setCampaign] = useState();
    const [businessUnits, setBusinessUnits] = useState([]);
    const [businessUnit, setBusinessUnit] = useState();
    const [jobTypes, setJobTypes] = useState([]);
    const [jobType, setJobType] = useState();
    const [tagTypes, setTagTypes] = useState([]);
    const [selectedTagTypes, setSelectedTagTypes] = useState([]);
    const [priority, setPriority] = useState(job?.priority ?? PRIORITIES[2]);
    const [summary, setSummary] = useState(job?.summary ?? "");
    const [isCampaignsLoading, setIsCampaignsLoading] = useState(false);
    const [isBusinessUnitsLoading, setIsBusinessUnitsLoading] = useState(false);
    const [isJobTypesLoading, setIsJobTypesLoading] = useState(false);
    const [isTagTypesLoading, setIsTagTypesLoading] = useState(false);
    const [zones, setZones] = useState([]);
    const [zipCodes, setZipCodes] = useState([])

    useEffect(() => {
        if (franchise && shouldLoadDetailsForJob) {
            if (!isEditJob) resetJobDetailsData(setCampaigns, setSummary, setCampaign, setBusinessUnits, setBusinessUnit, setJobTypes, setJobType, setSelectedTagTypes, setTagTypes)
            const getCampaignsCancelFunc = handleGetCampaigns(franchise?.id, job?.campaignId, setCampaigns, handleSetCampaign, setIsCampaignsLoading);
            const getBusinessUnitsCancelFunc = handleGetBusinessUnits(franchise?.id, job?.businessUnitId, setBusinessUnits, handleSetBusinessUnit, setIsBusinessUnitsLoading, () => { });
            const getJobTypesCancelFunc = handleGetJobTypes(franchise?.id, job?.jobTypeId, true, shouldFilterFor360Paintings(brand), setJobTypes, handleInitialSetJobType, setIsJobTypesLoading, () => { });
            const getTagTypesCancelFunc = handleGetTagTypes(franchise, job?.tagTypeIds, setTagTypes, handleSetSelectedTagTypesInitial, setIsTagTypesLoading);
            const getZonesCalcelFunc = handleGetZones(franchise?.id, setZones);
            setShouldLoadDetailsForJob(false)
            return () => {
                getCampaignsCancelFunc?.();
                getBusinessUnitsCancelFunc?.();
                getJobTypesCancelFunc?.();
                getTagTypesCancelFunc?.();
                getZonesCalcelFunc?.();
            }
        }
    }, [franchise, shouldLoadDetailsForJob])

    useEffect(() => {
        if (job && job.summary) {
            setSummary(job.summary)
        }
    }, [job])

    useEffect(() => {
        handleGetZipCodes([brand?.brand_id], [franchise?.id], [], setZipCodes, () => { }, () => { })
        return () => {
            setZipCodes([])
        }
    }, [franchise?.id])

    function handleSetCampaign(campaign) {
        setCampaign(campaign);
        setJob({ ...job, campaignId: campaign?.id })
    }

    function handleSetBusinessUnit(businessUnit) {
        setBusinessUnit(businessUnit);
        setJob({ ...job, businessUnitId: businessUnit?.id })
    }

    function handleInitialSetJobType(jobType) {
        setJobType(jobType);
        setJob((job) => { return { ...job, jobTypeId: jobType?.id } })
    }

    function handleSetJobType(jobType) {
        if (jobType?.priority) handleSetPriority(undefined, jobType?.priority)
        if (!isEditJob) handleSetSelectedTagTypesInitial(tagTypes?.filter?.((v) => jobType?.tagTypeIds?.some((tId) => tId === v.id)) ?? [])
        if (!isEditJob) handleSetJobSummary(jobType !== null ? jobType?.summary : "")
        autoPickBusinessUnit(jobType)
        handleInitialSetJobType(jobType)
    }

    function handleSetSelectedTagTypesInitial(selectedTagTypes) {
        setSelectedTagTypes(selectedTagTypes);
        setJob({ ...job, tagTypeIds: selectedTagTypes?.map((tag) => tag.id) })
    }

    function handleSetSelectedTagTypes(selectedTagTypes) {
        if (jobType) {
            setSelectedTagTypes(selectedTagTypes);
            setJob({ ...job, tagTypeIds: selectedTagTypes?.map((tag) => tag.id) })
        } else {
            setSelectedTagTypes([]);
        }
    }

    function handleSetPriority(index = 0, priority) {
        setPriority(priority);
        setJob((job) => { return { ...job, priority } })
    }

    function handleSetJobSummary(summary) {
        setSummary(summary);
        setJob((job) => { return { ...job, summary } })
    }

    function autoPickBusinessUnit(jobType) {
        setBusinessUnit()
        if (jobType?.businessUnitIds?.length === 1) {
            setBusinessUnit(businessUnits?.find?.((b) => b.id === jobType?.businessUnitIds?.[0]) ?? null)
            let businessUnitId = jobType?.businessUnitIds?.[0]
            setJob((job) => { return { ...job, businessUnitId } })
        } else if (filterBusinessUnits(jobType)?.length === 1) {
            setBusinessUnit(filterBusinessUnits(jobType)?.[0])
            let businessUnitId = filterBusinessUnits(jobType)?.[0]?.id
            setJob((job) => { return { ...job, businessUnitId } })
        }
        else {
            setBusinessUnit(null)
            let businessUnitId = null
            setJob((job) => { return { ...job, businessUnitId } })
        }
    }

    const filterBusinessUnits = (jobType) => {
        let isValidZipCode = getIsValidUSOrCanadianZipCode(zipCode)
        let businessUnitsOfJobType = businessUnits?.filter?.((v) => jobType?.businessUnitIds?.includes?.(v.id))
        if (zipCode && isValidZipCode) {
            let zoneServingForZipCode = zones?.find?.((z) => z?.zips?.includes?.(zipCode))
            if (zoneServingForZipCode) {
                let businessUnits = businessUnitsOfJobType?.filter?.((v) => zoneServingForZipCode?.businessUnits?.includes?.(v.id))
                if (businessUnits?.length > 0) return businessUnits
                return businessUnitsOfJobType
            } else {
                return businessUnitsOfJobType
            }
        } else if (!zipCode || !isValidZipCode) {
            let zipCodesOfTheSelectedFranchise = zipCodes?.map((z) => z.zip_code)
            let zonesIncludeZipCode = []
            zones?.forEach((z) => {
                if (checkIntersection(z.zips, zipCodesOfTheSelectedFranchise)) {
                    zonesIncludeZipCode.push(z)
                }
            })
            if (zonesIncludeZipCode?.length > 0) {
                let businessUnits = []
                zonesIncludeZipCode?.forEach((z) => {
                    businessUnits = businessUnits.concat(z.businessUnits)
                })
                businessUnits = [...new Set(businessUnits)];
                businessUnits = businessUnitsOfJobType?.filter?.((v) => businessUnits?.includes?.(v.id))
                if (businessUnits?.length > 0) {
                    return businessUnits
                } else {
                    return businessUnitsOfJobType
                }
            } else {
                return businessUnitsOfJobType
            }
        } else {
            return businessUnitsOfJobType
        }
    }

    function checkIntersection(arr1, arr2) {
        return arr1.some(item => arr2.includes(item));
    }

    return <Box id={CREATE_JOB_SECTION_IDS.JOB} style={{ paddingBlock: "0.5em" }}>
        <Box sx={{display:"flex" ,gap:"0.5em" ,alignItems:"center"}}>
            <h4>Job Details</h4>
            {(isEditJob && job?.appointmentCount>1) && <Typography sx={{color:"#3598DB"}}>When editing a job with multiple appointments, only one appointment details will be changed.</Typography>}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: "row", flexWrap: "wrap", width: "100%", gap: "1em" }} >
            <Autocomplete
                sx={{ width: "49%", flexGrow: 1 }}
                size="small"
                disablePortal
                data-test="campaign_selector"
                disableClearable
                noOptionsText={franchise ? "No campaigns for franchise" : "Please select a franchise"}
                loading={isCampaignsLoading}
                options={campaigns}
                getOptionLabel={(campaign) => campaign.name}
                renderOption={(props, campaign) => <RenderInput {...props} key={campaign?.id} content={campaign?.name} />}
                value={campaigns.length > 0 ? (campaign ?? null) : null}
                onChange={(_, campaign) => handleSetCampaign(campaign)}
                renderInput={(params) => <CustomTextField  {...params} inputProps={{ ...params.inputProps, 'data-test': 'campaign_value' }} required={true} label="Select Campaign" />}
            />
            <Autocomplete
                sx={{ width: "49%", flexGrow: 1 }}
                size="small"
                disablePortal
                noOptionsText={franchise ? "No job types for franchise" : "Please select a franchise"}
                data-test="job_type_selector"
                loading={isJobTypesLoading}
                options={jobTypes}
                getOptionLabel={(jobType) => jobType.name}
                renderOption={(props, jobType) => <RenderInput {...props} key={jobType?.id} content={jobType?.name} />}
                value={jobTypes.length > 0 ? (jobType ?? null) : null}
                onChange={(_, jobType) => handleSetJobType(jobType)}
                renderInput={(params) => <CustomTextField   {...params} inputProps={{ ...params.inputProps, 'data-test': 'job_type_value' }} required label="Select Job Type" />}
            />
            <Autocomplete
                style={{ width: "49%", flexGrow: 1 }}
                size="small"
                disablePortal
                disabled={!jobType}
                data-test='business_unit_selector'
                noOptionsText={franchise ? "No business units for franchise" : "Please select a franchise"}
                loading={isBusinessUnitsLoading}
                options={jobType?.businessUnitIds?.length ? filterBusinessUnits(jobType) : businessUnits}
                getOptionDisabled={(option) => !option.active}
                getOptionLabel={(businessUnit) => businessUnit.name}
                renderOption={(props, businessUnit) => <RenderInput {...props} key={businessUnit?.id} content={businessUnit?.name} />}
                value={businessUnits.length > 0 ? (businessUnit ?? null) : null}
                onChange={(e, businessUnit) => handleSetBusinessUnit(businessUnit)}
                renderInput={(params) => <CustomTextField   {...params} inputProps={{ ...params.inputProps, 'data-test': 'business_unit_value' }} required label="Select Busines Unit" />}
            />
            <Autocomplete
                style={{ width: "49%", flexGrow: 2 }}
                size="small"
                disablePortal
                data-test='priority_selector'
                disableClearable
                options={PRIORITIES}
                getOptionLabel={(priority) => priority}
                renderOption={(props, priority) => <RenderInput {...props} key={priority} content={priority} />}
                value={(priority ?? null)}
                onChange={handleSetPriority}
                renderInput={(params) => <CustomTextField   {...params} inputProps={{ ...params.inputProps, 'data-test': 'priority_value' }} required={true} label="Priority" />}
            />
            <Autocomplete
                sx={{ width: "100%", }}
                size="small"
                disablePortal
                multiple={true}
                data-test='tag_type_selector'
                noOptionsText={franchise ? "No tag types for franchise" : "Please select a franchise"}
                loading={isTagTypesLoading}
                options={tagTypes}
                getOptionLabel={(tagType) => tagType.name}
                renderOption={(props, tagType) => <RenderInput {...props} key={tagType?.id} content={tagType?.name} />}
                value={selectedTagTypes}
                onChange={(_, selectedTagTypes) => {
                    handleSetSelectedTagTypes(selectedTagTypes)
                    onTagTypesChanged(true)
                }}
                renderInput={(params) => <CustomTextField {...params} inputProps={{ ...params.inputProps, 'data-test': 'tag_type_value' }} label="Tag Types" />}
            />
            <Box style={{ width: "100%", }}>
                <CustomTextField inputProps={{ 'data-test': 'job_summary' }} disabled={false} multiline rows={3} required={true} label="Summary" variant="outlined" size="small" style={{ width: "100%", }} value={summary} onChange={(event) => handleSetJobSummary(event?.target?.value)} />
            </Box>
        </Box>
    </Box>
}