import { IconButton, Tooltip } from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';

export default function RefreshButton(props) {
    const { onRefresh = () => { } } = props
    return <Tooltip title={"Refresh"} >
        <IconButton data-test={"refresh-button"} onClick={onRefresh}>
            <RefreshIcon />
        </IconButton>
    </Tooltip>;
}