import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { CommonContext } from '../../../contexts/CommonContextProvider';
import { NotSuccessIconError, SuccessIcon } from '../../../icons/common';
import { Tooltip, Typography } from '@mui/material';
import { getTenantTimeZoneTimeString } from '../../../utils/time/time';

const headCells = {
    index: {
        id: 'index',
        numeric: false,
        shouldSort: false,
        alignment: "left",
        label: '#',
    },
    luminId: {
        id: 'bot_number',
        numeric: false,
        shouldSort: false,
        alignment: "left",
        label: 'Bot Number',
    },
    status: {
        id: 'Status',
        numeric: false,
        shouldSort: false,
        alignment: "center",
        label: 'Status',
    },
    createdAtDate: {
        id: 'created_at',
        numeric: false,
        shouldSort: false,
        alignment: "left",
        label: 'Created At',
    },
    createdBy: {
        id: 'created_by',
        numeric: false,
        shouldSort: false,
        alignment: "left",
        label: 'Created By',
    },
};

function SalesAcceleratorHistoryHead(props) {
    return (
        <TableHead>
            <TableRow>
                {Object.values(headCells).map((h) => (
                    <TableCell
                        key={h.id}
                        align={h.alignment}
                        padding={h.id === headCells.index.id ? "1em" : "none"}
                    >{h.label}</TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default function SalesAcceleratorHistory(props) {
    const { requests = [] } = props
    const { allUsers, generalTenantConfiguration, timezones } = React.useContext(CommonContext)
    return <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2, padding: "1em" }}>
            <TableContainer>
                <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={'small'}
                >
                    <SalesAcceleratorHistoryHead />
                    <TableBody>
                        {requests?.length === 0 ? <TableCell component="th" align={"center"} colSpan={6} scope="row">No Data to Show</TableCell>
                            : requests?.map?.((f, index) => {
                                const labelId = `enhanced-table-checkbox-${index}`;
                                const user = allUsers?.find?.((u) => u?.user_id === f?.created_by)
                                let userName = `System`
                                if (f.created_by && user) {
                                    userName = `${user?.first_name} ${user?.last_name}`
                                }
                                return (
                                    <TableRow hover tabIndex={-1} key={f.name}>
                                        <TableCell component="th" align={"left"} padding={"1em"} id={labelId} scope="row">{index + 1}</TableCell>
                                        <TableCell sx={{ maxWidth: "180px" }} align="left" padding={'none'} ><Tooltip title={f.lumin_bot_number} ><Typography sx={{ fontSize: "14px", overflow: "hidden", textOverflow: "ellipsis" }}>{f.lumin_bot_number}</Typography></Tooltip></TableCell>
                                        <TableCell align="center" padding={'none'} >{f.lumin_ok === 1 ? <SuccessIcon /> : <NotSuccessIconError />}</TableCell>
                                        <TableCell component="th" align={"left"} padding={'none'} id={labelId} scope="row">{getTenantTimeZoneTimeString(f.created_at, generalTenantConfiguration?.time_zone, timezones)}</TableCell>
                                        <TableCell align="left" padding={'none'} >{userName}</TableCell>
                                    </TableRow>
                                );
                            }) ?? <></>}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    </Box>;
}