import { Box } from "@mui/material";
import CustomTextField from "../../../../components/CustomTextField";
import PhoneNumberBox from "../../../../components/PhoneNumberBox";
import LoadingButton from "../../../../components/LoadingButton";

export default function CustomerSearchSection(props) {
    const { isDisabled, customerPhoneNumber, onCustomerPhoneNumberChanged, customerName, onCustomerNameChanged, isSearchResultCutomersLoading, onSearch } = props

    return <Box sx={{ display: 'flex', flexDirection: "row", marginBottom: "15px", height: "3em", alignItems: "start", gap: "1em" }}>
        <CustomTextField inputProps={{ "data-test": "customer_search_name" }} style={{ width: "15em" }} label="Name" type="search" size="small" value={customerName ?? ""} onChange={onCustomerNameChanged} />
        <PhoneNumberBox data-test={"customer_search_phone"} value={customerPhoneNumber} type="search" label="Phone Number" style={{ width: "10em" }} onChange={onCustomerPhoneNumberChanged} maxLength={15} />
        <LoadingButton data-test={"customer_search_button"} color={"primary"} style={{ textTransform: "none", color: "white" }} variant="contained" loading={isSearchResultCutomersLoading} disabled={isSearchButtonDissabled(isDisabled, customerPhoneNumber, customerName)} onClick={onSearch}>Search</LoadingButton>
    </Box>
}

function isSearchButtonDissabled(isDissabled, customerPhoneNumber = "", customerName = "") {
    const shouldDissabled = (isDissabled || !(customerPhoneNumber || customerName) || (customerPhoneNumber?.length < 10 && !customerName))
    return shouldDissabled;
}