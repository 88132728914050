import { Autocomplete, Box, Button, Grid, Paper, Typography } from '@mui/material';
import React, { useState } from 'react'
import LoadingButton from '../../../../../components/LoadingButton';
import RenderInput from '../../../../../components/servicetitan/RenderInput';
import { NOTIFICATION_TYPES } from '../../../../../constants/common';
import { cancelJob } from '../../../../../services/serviceTitan';
import { CustomTextField } from '../../components/customComponents';
import Loading from '../../../../other/components/loading';

export default function CancelJobModal({ franchise, job, showNotification, cancelReasons, handleCloseCancelJob }) {

    const [selectedReason, setSelectedReason] = useState(null)
    const [cancelNote, setCancelNote] = useState("")
    const [isCancelJobLoading, setIsCancelJobLoading] = useState(false)

    function isEnableCancelJobButton() {
        return selectedReason !== null && cancelNote !== ""
    }

    return (
        <>
            <Paper sx={{ width: "100%", borderRadius: "0.5em", border: "1px solid gray", marginTop: "0.5em", padding: "0.5em" }}>
                <Grid container  >
                    <Grid item xs={6} >
                        <Typography style={{ margin: "0" }}>{"Reason"}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Autocomplete
                            sx={{ width: "100%", }}
                            size="small"
                            value={selectedReason ?? null}
                            disablePortal
                            disableClearable
                            options={cancelReasons}
                            getOptionLabel={(reason) => reason?.name}
                            renderOption={(props, reason) => <RenderInput {...props} key={reason?.id} content={reason?.name ?? "-"} />}
                            onChange={(_, reason) => {
                                setSelectedReason(reason);
                            }}
                            renderInput={(params) => <CustomTextField {...params} label="Select Reason" />}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box style={{ width: "100%", marginTop: "1em" }}>
                            <CustomTextField multiline rows={3} onChange={(event) => setCancelNote(event.target.value)} required={true} label="Cancellation Comments" variant="outlined" size="small" style={{ width: "100%", }} />
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{ width: "100%", marginTop: "0.5em", padding: "0.5em" }}>
                    <Button size={"small"} variant="contained" onClick={() => handleCloseCancelJob(null)} className={"btn-modal"} >Cancel</Button>
                    <LoadingButton type={"submit"} size={"small"} disabled={!isEnableCancelJobButton()} variant="contained" loading={false} style={{ marginLeft: "0.5em" }} className={isEnableCancelJobButton() ? "btn-primary" : "btn-disable"}
                        onClick={() => jobCancel(franchise, setIsCancelJobLoading, handleCloseCancelJob, showNotification, job, selectedReason, cancelNote, true)}
                    >
                        Cancel Job
                    </LoadingButton>
                </Box>
            </Paper>
            {isCancelJobLoading && <Loading loadingMessage={"Cancelling Job"} isOpen={isCancelJobLoading} />}
        </>
    )
}

async function jobCancel(franchise, setIsCancelJobLoading, handleCloseCancelJob, showNotification, job, selectedReason, cancelNote, isInitialRequest) {
    try {
        setIsCancelJobLoading(true)
        const jobCancelRequest = {
            reasonId: selectedReason?.id,
            memo: cancelNote,
        }
        let res = await cancelJob(franchise?.id, job?.id, jobCancelRequest, isInitialRequest)
        if (res?.JobCanceled === false) {
            setTimeout(() => {
                jobCancel(franchise, setIsCancelJobLoading, handleCloseCancelJob, showNotification, job, selectedReason, cancelNote, false)
            }, 2000)
        } else {
            setIsCancelJobLoading(false)
            showNotification({ message: "Job has been canceled successfully", type: NOTIFICATION_TYPES.SUCCESS, isOpen: true })
            let canceledJob = { ...job, jobStatus: "Canceled" }
            setTimeout(() => {
                handleCloseCancelJob(canceledJob)
            }, 1000)
        }
    } catch (error) {
        showNotification({ message: "Important: Job couldn’t be canceled at this time. Please try again", type: NOTIFICATION_TYPES.ERROR })
        setIsCancelJobLoading(false)
    }
}

