import React from 'react'
import "../styles/scoreDashboardStyles.css"
import AvilableImg from "../../../../assets/img/agent_logs_images/available.png";
import AssignmentImg from "../../../../assets/img/agent_logs_images/assignment.png";
import BusyImg from "../../../../assets/img/agent_logs_images/busy.png";
import CallBackImg from "../../../../assets/img/agent_logs_images/callback.png";
import CoachingImg from "../../../../assets/img/agent_logs_images/coaching.png";
import EmailImg from "../../../../assets/img/agent_logs_images/email.png";
import IssuelImg from "../../../../assets/img/agent_logs_images/issue.png";
import LunchImg from "../../../../assets/img/agent_logs_images/lunch.png";
import OfflineImg from "../../../../assets/img/agent_logs_images/working_offline.png";
import OnBreakImg from "../../../../assets/img/agent_logs_images/on_break.png";
import WrapUpImg from "../../../../assets/img/agent_logs_images/wrapup.png";
import ManagementImg from "../../../../assets/img/agent_logs_images/management.png";
import LoggedInImg from "../../../../assets/img/agent_logs_images/login.png";
import LoggedOutImg from "../../../../assets/img/agent_logs_images/logout.png";
import WorkingLeadImg from "../../../../assets/img/agent_logs_images/working_lead.png";
import ScheduledBreakImg from "../../../../assets/img/agent_logs_images/scheduled_break.png";
import TrainingImg from "../../../../assets/img/agent_logs_images/training.png";
import UnscheduledImg from "../../../../assets/img/agent_logs_images/unscheduled_break.png";
import UnknownImg from "../../../../assets/img/agent_logs_images/unknown.png";
import NotStartedImg from "../../../../assets/img/agent_logs_images/not_started.png";
import { LOG_STATUS } from '../../../../constants/agentLogs';
import { ImageOnErrorHandler } from '../../../../utils/imageError';

export default function AgentLogsIcons({ status }) {
    switch (status) {
        case LOG_STATUS.Available:
            return <img className='logs-icon' alt='available' onError={ImageOnErrorHandler} src={AvilableImg} />
        case LOG_STATUS.Busy:
            return <img className='logs-icon' alt='busy' onError={ImageOnErrorHandler} src={BusyImg} />
        case LOG_STATUS.Onbreak:
            return <img className='logs-icon' alt='onbreak' onError={ImageOnErrorHandler} src={OnBreakImg} />
        case LOG_STATUS.Workoffline:
            return <img className='logs-icon' alt='workoffline' onError={ImageOnErrorHandler} src={OfflineImg} />
        case LOG_STATUS.Wrapup:
            return <img className='logs-icon' alt='wrapup' onError={ImageOnErrorHandler} src={WrapUpImg} />
        case LOG_STATUS.Email:
            return <img className='logs-icon' alt='email' onError={ImageOnErrorHandler} src={EmailImg} />
        case LOG_STATUS.TechIssue:
            return <img className='logs-icon' alt='techissue' onError={ImageOnErrorHandler} src={IssuelImg} />
        case LOG_STATUS.LunchBreak:
            return <img className='logs-icon' alt='lunch' onError={ImageOnErrorHandler} src={LunchImg} />
        case LOG_STATUS.CoachingMeeting:
            return <img className='logs-icon' alt='meeting' onError={ImageOnErrorHandler} src={CoachingImg} />
        case LOG_STATUS.CSRProject:
            return <img className='logs-icon' alt='project' onError={ImageOnErrorHandler} src={AssignmentImg} />
        case LOG_STATUS.CallBacks:
            return <img className='logs-icon' alt='callback' onError={ImageOnErrorHandler} src={CallBackImg} />
        case LOG_STATUS.Management:
            return <img className='logs-icon' alt='management' onError={ImageOnErrorHandler} src={ManagementImg} />
        case LOG_STATUS.Login:
            return <img className='logs-icon' alt='login' onError={ImageOnErrorHandler} src={LoggedInImg} />
        case LOG_STATUS.Logout:
            return <img className='logs-icon' alt='logout' onError={ImageOnErrorHandler} src={LoggedOutImg} />
        case LOG_STATUS.WorkingLead:
            return <img className='logs-icon' alt='lead' onError={ImageOnErrorHandler} src={WorkingLeadImg} />
        case LOG_STATUS.ScheduledBreak:
            return <img className='logs-icon' alt='schedulebreak' onError={ImageOnErrorHandler} src={ScheduledBreakImg} />
        case LOG_STATUS.Training:
            return <img className='logs-icon' alt='training' onError={ImageOnErrorHandler} src={TrainingImg} />
        case LOG_STATUS.UnscheduledBreak:
            return <img className='logs-icon' alt='unschedulebreak' onError={ImageOnErrorHandler} src={UnscheduledImg} />
        case LOG_STATUS.NotStarted:
            return <img className='logs-icon' alt='notstarted' onError={ImageOnErrorHandler} src={NotStartedImg} />
        default:
            return <img className='logs-icon' alt='unknown' onError={ImageOnErrorHandler} src={UnknownImg} />
    }
}
