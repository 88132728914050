import { Box, Dialog, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import DialogHeader from "../../../../../components/dialog/DialogHeader";
import DialogBody from "../../../../../components/dialog/DialogBody";
import SmallCard from "../../../../../components/servicetitan/SmallCard";
import CallMadeIcon from '@mui/icons-material/CallMade';
import TuneTwoToneIcon from '@mui/icons-material/TuneTwoTone';
import { Link } from "react-router-dom";
import NoContentView from "../../../../../components/refreshView/NoContentView";
import iBSBrandLogo from "../../../../../assets/img/brand.png"
import PhoneCard from "../../../../../components/servicetitan/PhoneCard";
import EmailCard from "../../../../../components/servicetitan/EmailCard";
import SalesAcceleratorSection from "../../../../../components/lumin/SalesAcceleratorSection";
import ImageBox from "../../../../../components/LogoBox/ImageBox";
import { getTenantTimeZoneTimeString } from "../../../../../utils/time/time";
import { CommonContext } from "../../../../../contexts/CommonContextProvider";
import { useContext } from "react";

export default function ViewLeadModal(props) {
    const { open, onClose, lead, franchise, brand } = props;

    const { generalTenantConfiguration, timezones } = useContext(CommonContext)

    return <Dialog onClose={() => { }} open={open} fullWidth maxWidth="lg" disableScrollLock={true}>
        <DialogHeader onClose={() => {
            onClose(lead)
        }} className={'dialog-plain-color'}>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center", gap: "0.5em", padding: "1em" }}>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "left", borderRadius: "0.5em", }}>
                    <Typography variant="h5" component={"h1"} fontWeight="bold" >{`Lead #${lead?.lead_oid} `}</Typography>
                    <Typography variant="subtitle2" component={"p"} margin={"0"} padding={"0"} color={"grayText"} >{`${franchise?.franchise_name ?? ""}`}</Typography>
                </Box>
                <ImageBox alt="brand-logo" src={brand?.brand_img ?? iBSBrandLogo} size={'large'} />
                <Box flexGrow={2}></Box>
                <IconButton variant="contained" size="small" sx={{ textTransform: "none" }}><Link data-test="topbar_manual_job" to={getCreateJobUrl(franchise, lead)}><Tooltip title="Create a job"><TuneTwoToneIcon color="primary" /></Tooltip></Link></IconButton>
                <Tooltip title={lead?.middleware_lead_id ? "View Servicetitan Lead" : "No Servicetitan lead created"}>
                    <Box sx={{ height: "2.5em", borderRadius: "50%", backgroundColor: lead?.middleware_lead_id ? "primary.main" : "lightgrey", padding: "0.5em", margin: "0.5em" }}>
                        {lead?.middleware_lead_id ? <Link to={`/leads?section=servicetitan&id=${lead?.middleware_lead_id}`}>
                            <CallMadeIcon sx={{ color: "white" }} />
                        </Link> : <CallMadeIcon sx={{ color: "white" }} />}
                    </Box>
                </Tooltip>
            </Box>
        </DialogHeader>
        <DialogBody className={'dialog-color'}>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "start", gap: "0.5em", padding: "0.5em", borderRadius: "0.5em", width: "100%", justifyContent: "start", }}>
                <Paper sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "start", gap: "0.5em", padding: "0.5em", borderRadius: "0.5em", width: "100%", justifyContent: "space-evenly", }}>
                    <SmallCard heading="Lead Source" value={lead?.lead_source} />
                    <SmallCard heading="Contact Status" value={lead?.contact_status} />
                    <SmallCard heading="Appointment Status" value={lead?.appointment?.status} />
                    <SmallCard heading="Match Type" value={lead?.match_type} />
                    <SmallCard heading="Task Name" value={lead?.task_name} />
                    <SmallCard heading="Date Created" value={getTenantTimeZoneTimeString(lead.created_at, generalTenantConfiguration?.time_zone, timezones)} />
                </Paper>
                <Box sx={{ width: "65%", display: "flex", flexDirection: "column", alignItems: "stretch", gap: "0.5em" }}>
                    <Typography style={{ marginTop: "1em", fontSize: "1em", fontWeight: "bold" }}>Lead Summary</Typography>
                    <Box sx={{ width: "100%", display: "flex", flexDirection: "row", paddingRight: "1em", gap: "2em", justifyContent: "flex-start", flexWrap: "wrap" }}>
                        <SmallCard heading="Name" value={lead?.name} />
                        <PhoneCard heading="Primary Phone" phone={lead?.primary_phone} ext={lead?.phone_ext} franchiseId={franchise?.id} brandId={brand?.id} />
                        <PhoneCard heading="Secondary Phone" phone={lead?.secondary_phone} ext={lead?.secondary_phone_ext} franchiseId={franchise?.id} brandId={brand?.id} />
                        <EmailCard heading="Email" email={lead?.email} />
                    </Box>
                    <Box sx={{ width: "100%", display: "flex", flexDirection: "row", paddingRight: "1em", gap: "1em", justifyContent: "flex-start", flexWrap: "wrap" }}>
                        <SmallCard heading="Location" value={lead.address} />
                        <SmallCard heading="Description" value={lead?.lead_description} />
                        <SmallCard heading="Comments" value={lead?.comments} />
                    </Box>
                </Box>
                <Box sx={{ width: "30%", display: "flex", flexDirection: "column", alignItems: "stretch", gap: "0.5em" }}>
                    <Typography style={{ marginTop: "1em", fontSize: "1em", fontWeight: "bold" }}>Appointment Request</Typography>
                    {lead?.appointment?.appointment_oid ? <>
                        <Box sx={{ width: "100%", display: "flex", flexDirection: "row", paddingRight: "1em", gap: "2em", justifyContent: "flex-start", flexWrap: "wrap" }}>
                            <SmallCard heading="Id" value={lead?.appointment?.appointment_oid} />
                            <SmallCard heading="Type" value={lead?.appointment?.type} />
                            <SmallCard heading="Status" value={lead?.appointment?.status} />
                        </Box>
                        <Box sx={{ width: "100%", display: "flex", flexDirection: "row", paddingRight: "1em", gap: "1em", justifyContent: "flex-start", flexWrap: "wrap" }}>
                            <SmallCard heading="Start" value={lead?.appointment?.start ? getTenantTimeZoneTimeString(lead?.appointment?.start, generalTenantConfiguration?.time_zone, timezones) : "-"} />
                            <SmallCard heading="End" value={lead?.appointment?.end ? getTenantTimeZoneTimeString(lead?.appointment?.end, generalTenantConfiguration?.time_zone, timezones) : "-"} />
                        </Box> </> : <NoContentView message="No Appointment" withBoarder={false} />}
                </Box>
                <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "0.5em" }}>
                    <Typography style={{ marginTop: "1em", fontSize: "1em", fontWeight: "bold" }}>Interview</Typography>
                    <Box sx={{ height: "12em", display: "flex", flexDirection: "column", overflow: "auto", gap: "0.5em" }}>
                        {lead?.interview?.map((interview) => {
                            return <Box>
                                <Typography>{`Question : ${interview?.question}`}</Typography>
                                <Typography>{`Answer : ${interview?.answer}`}</Typography>
                            </Box>
                        })}
                    </Box>
                </Box>
                <SalesAcceleratorSection franchise={franchise} middlewareLeadId={lead?.middleware_lead_id} shouldAllowRescheduling={false} />
            </Box>
        </DialogBody>
    </Dialog >
}

function getCreateJobUrl(franchise, lead) {
    if (franchise && lead?.primary_phone && lead?.postal_code) {
        return `/home?media=MANUAL&zip_code=${lead?.postal_code}&caller_id=${lead?.primary_phone}&channel_id=${franchise?.channels?.[0]?.channel_id ?? ""}&brand_id=${franchise?.brand_id}`
    } else {
        return `/dialer?media=MANUAL&zip_code=${lead?.postal_code}&caller_id=${lead?.primary_phone}&override=true`
    }
}