// other routes
import Login from "../pages/LogIn/Login";
import Home from "../pages/other/home/Home";
import Dialer from "../pages/other/Dialer";

// dashboard
import CallLogsPage from "../pages/dashboard/callLogs/CallLogsPage";
import Users from "../pages/dashboard/user/Users";
import Brands from "../pages/dashboard/brands/Brands";
import Jobs from "../pages/dashboard/jobs/jobs"
import ScoreDashboard from "../pages/dashboard/scoreDashboard/ScoreDashboard";
import Faq from "../pages/dashboard/faq/Faq";
import AgentLogs from "../pages/dashboard/scoreDashboard/components/agentLogs"
import Report from "../pages/dashboard/reports/Reports";
import FailureView from "../pages/dashboard/brands/components/FailureView";
import SuccessView from "../pages/dashboard/brands/components/successView";
import LeadsLandingPage from "../pages/dashboard/Leads/LeadsLandingPage";
import CampaignsLandingPage from "../pages/dashboard/campaigns/CampaignsLandingPage";
import Franchise from "../pages/dashboard/franchise/Franchise";
import Audit from "../pages/dashboard/audit/Audit";
import ScheduleLandingPage from "../pages/dashboard/schedule/ScheduleLandingPage";
import EstimatesLandingPage from "../pages/dashboard/estimates/EstimatesLandingPage";

const OtherRoutes = [
    {
        path: "/",
        exact: true,
        name: "IBS Middleware",
        component: Login,
    },
    {
        path: "/dialer",
        name: "IBS Middleware",
        exact: true,
        component: Dialer,
    },
    {
        path: "/home",
        name: "IBS Middleware",
        exact: true,
        component: Home,
    },
    {
        path: "/brands/validation/success",
        name: "IBS Middleware",
        exact: true,
        component: SuccessView,
    },
    {
        path: "/brands/validation/failure",
        name: "IBS Middleware",
        exact: true,
        component: FailureView,
    },
];


const DashboardRoutes = [
    {
        path: "/call-logs",
        name: "Call Logs",
        component: CallLogsPage,
        level: 40,
        topMenu: true
    },
    {
        path: "/users",
        name: "Users",
        component: Users,
        level: 70,
        topMenu: true
    },
    {
        path: "/brands",
        name: "Brands",
        component: Brands,
        level: 70,
        topMenu: true

    },
    {
        path: "/franchise",
        name: "Franchise",
        component: Franchise,
        level: 40,
        topMenu: true
    },
    {
        path: "/jobs",
        name: "Jobs",
        component: Jobs,
        level: 40,
        topMenu: true

    },
    {
        path: "/leads",
        name: "Leads",
        component: LeadsLandingPage,
        level: 40,
        topMenu: true

    },
    {
        path: "/campaigns",
        name: "Campaigns",
        component: CampaignsLandingPage,
        level: 40,
        topMenu: true

    },
    {
        path: "/estimates",
        name: "Estimates",
        component: EstimatesLandingPage,
        level: 40,
        topMenu: true

    },
    {
        path: "/assignments",
        name: "Assignments",
        component: ScheduleLandingPage,
        level: 50,
        topMenu: true
    },
    {
        path: "/reports",
        name: "Reports",
        component: Report,
        level: 70,
        topMenu: false

    },
    {
        path: "/faqs",
        name: "FAQs",
        component: Faq,
        level: 40,
        topMenu: true
    },
    {
        path: "/wallboard",
        name: "Wallboard",
        component: ScoreDashboard,
        level: 40,
        topMenu: false,
    },
    {
        path: "/wallboard/agentLogs",
        name: "",
        component: AgentLogs,
        level: 40,
        topMenu: false,
    },
    {
        path: "/audit",
        name: "Audit",
        component: Audit,
        level: 120,
        topMenu: true,
    },
]

export { OtherRoutes, DashboardRoutes }