import moment from "moment";
import { mapActiveUsersWithAgentData } from "../pages/dashboard/scoreDashboard/utils";
import { getAllAgentScoreByDateRange } from "../services/agentScoreService";

export const formatStartAndEndDate = (range, setEndDate, setStartDate, start, end) => {
    let startDate
    let endDate
    if (range !== 0) {
        let date = new Date();
        date.setDate(date.getDate());
        endDate = moment(date).format();
        endDate && setEndDate(moment(endDate).format("YYYY-MM-DD"));
        date.setDate(date.getDate() - range);
        startDate = moment(date).format();
        startDate && setStartDate(moment(startDate).format("YYYY-MM-DD"));
    } else {
        startDate = moment(start).format()
        endDate = moment(end).format()
    }
    return { startDate, endDate }
}

export const getInformation = async (start, end, setLoading, setEndDate, setStartDate, range, formatDetailsOfCurrentAgent, setCurrentAgentData, selectTopAndOtherAgents, setTopAgents,
    setOtherAgents, setCurrentAgentStatucCodeData, setAgentStatusCodeData, setChartData, setChartLabels, currentAgentData, selectedAgent, setSelectedAgent, allUsers, setIsAgentDataLoading, setSearchValue, setIsNoAgentAvailableForSearch, setAgentData, toggleView) => {
    setSearchValue('')
    setIsNoAgentAvailableForSearch(false)
    const { startDate, endDate } = formatStartAndEndDate(range, setEndDate, setStartDate, start, end)
    await getAgentData(startDate, endDate, formatDetailsOfCurrentAgent, setCurrentAgentData, selectTopAndOtherAgents, setTopAgents, setOtherAgents,
        setCurrentAgentStatucCodeData, setAgentStatusCodeData, setChartData, setChartLabels, currentAgentData, selectedAgent, setSelectedAgent, allUsers, setAgentData, toggleView)
    setIsAgentDataLoading(false)
    setLoading(false)
}

export const getAgentData = async (startDate, endDate, formatDetailsOfCurrentAgent, setCurrentAgentData, selectTopAndOtherAgents, setTopAgents,
    setOtherAgents, setCurrentAgentStatucCodeData, setAgentStatusCodeData, setChartData, setChartLabels, currentAgentData, selectedAgent, setSelectedAgent, allUsers, setAgentData, toggleView) => {
    try {
        setChartData([])
        setChartLabels([])
        setTopAgents([])
        setOtherAgents([])
        let onlineAgents
        let isTotal = false
        let agentData = await getAllAgentScoreByDateRange(startDate, endDate, isTotal)
        if (Array.isArray(agentData)) {
            let dataMap = mapActiveUsersWithAgentData(agentData, allUsers)
            setAgentData(dataMap)
            formatDetailsOfCurrentAgent(dataMap, setCurrentAgentData, setCurrentAgentStatucCodeData, setChartLabels, setChartData, currentAgentData, selectedAgent, setSelectedAgent, toggleView)
            selectTopAndOtherAgents(dataMap, setTopAgents, setOtherAgents, onlineAgents)
            setAgentStatusCodeData(dataMap)
        } else {
            setSelectedAgent(null)
        }
    } catch (error) {
        setSelectedAgent(null)
        setCurrentAgentData(null)
        setCurrentAgentStatucCodeData(null)
        setAgentStatusCodeData([])
        setChartData([])
        setChartLabels([])
        setTopAgents([])
        setOtherAgents([])
    }
}
