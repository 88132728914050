import { Button, Dialog } from "@mui/material";
import { NOTIFICATION_TYPES } from "../../../../constants/common";
import { deleteUserById } from "../../../../services/userService";

export default function DeleteDialog(props) {
    const { onClose, open, data, showNotification, handleRefreshData } = props;
    const handleClose = () => onClose();

    const handleDeleteUser = async () => {
        try {
            let successMessage = `User has been permanently deleted successfully`
            await deleteUserById(data.user_id)
            showNotification({ message: successMessage, type: NOTIFICATION_TYPES.SUCCESS })
            onClose()
            handleRefreshData()
        } catch (error) {
            let errorMessage = `Unable to delete User at this moment`
            showNotification({ message: errorMessage, type: NOTIFICATION_TYPES.ERROR })
        }
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <div className={"dialog"}>
                <h3 className={"dialog-title"}>
                    Are you sure you want to permanently delete the user ?
                </h3>
                <p style={{ marginTop: "-10px", marginBottom: "15px" }}>
                    {data?.first_name + " " + data?.last_name}
                </p>
                <div style={{ display: "flex", justifyContent: "end" }}>
                    <Button
                        size={"small"}
                        variant="contained"
                        onClick={handleClose}
                        className={"btn-secondary"}
                        style={{ marginRight: "10px" }}
                    >
                        No
                    </Button>
                    <Button
                        size={"small"}
                        variant="contained"
                        onClick={handleDeleteUser}
                        className={"btn-primary"}
                    >
                        yes
                    </Button>
                </div>
            </div>
        </Dialog>
    );
}
