import CustomTextField from "../../../../components/CustomTextField"
import { imposibleToMatchRegex, isValidEmail } from "../../../../utils/validators"

export default function EmailField(props) {
    const { required = false, disabled, value, onChange } = props
    const isEmailValid = isValidEmail(value) || !value
    const inputPattern = isEmailValid ? undefined : imposibleToMatchRegex

    function onValueChange(e) {
        const email = e?.target?.value?.trim?.()
        e.target.value = email ?? ""
        onChange(e)
    }

    return <CustomTextField
        {...props}
        inputProps={{ pattern: inputPattern }}
        disabled={disabled}
        required={required}
        error={!isEmailValid}
        helperText={isEmailValid ? "" : `Invalid Email`}
        label={props.label ? props?.label : "Email (test@email.com)"}
        variant="outlined"
        size="small"
        style={{ flexGrow: "1" }}
        value={value ?? ""}
        onChange={onValueChange} />
}