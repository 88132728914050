import { useEffect, useState } from "react"
import { handleGetCompaigns, handleGetIndustries } from "../../../../../state-services-dublicate/vonigo"
import { CUSTOMER_TYPES } from "../../vonigo/components/CustomerDetailsSection"
import ExistingCustomerSection from "./ExistingCustomerSection"
import NewCustomerSection from "./NewCustomerSection"

export default function CustomerDetailsSection(props) {
    const { isNewCustomer, brand, franchise, setEditContact, setEditLocation, selectedDraftToContinue, customer, setCustomer, customers, isCustomersLoading, selectedCustomer, setSelectedCustomer } = props
    const [industries, setIndustries] = useState([])
    const [campaign, setCampaign] = useState()
    const [campaigns, setCampaigns] = useState([])
    const [isIndustriesLoading, setIsIndustriesLoading] = useState(false)
    const [isCampaignsLoading, setIsCampaignsLoading] = useState(false)

    useEffect(() => {
        const selectedIndustryId = selectedDraftToContinue?.clientCreateRequest?.industryOptionId
        if (selectedIndustryId && industries?.length > 0) {
            const selectedIndustry = industries?.find((i) => `${i?.optionID}` === selectedIndustryId)
            onIndustryChanged(selectedIndustry)
        }
    }, [selectedDraftToContinue, industries])

    useEffect(() => {
        const selectedCampaignId = selectedDraftToContinue?.clientCreateRequest?.marketingCampaignOptionId
        if (selectedCampaignId && campaigns?.length > 0) {
            const selectedCampaign = campaigns?.find((c) => `${c?.optionID}` === selectedCampaignId)
            onCampaignChanged(selectedCampaign)
        }
    }, [selectedDraftToContinue, campaigns])

    useEffect(() => {
        handleGetIndustries(brand, franchise, setIsIndustriesLoading, setIndustries)
        handleGetCompaigns(brand, franchise, setIsCampaignsLoading, setCampaigns)
    }, [brand, franchise])

    function onCampaignChanged(campaign) {
        setCampaign(campaign)
        setCustomer({ ...customer, marketingCampaignOptionId: `${campaign?.optionID ? campaign?.optionID : ""}` })
    }

    function onIndustryChanged(industry) {
        setCustomer({ ...customer, industryOptionId: `${industry?.optionID}` })
    }

    function onCustomerNameChanged(event) {
        setCustomer({ ...customer, name: event.target.value });
    }

    function onCustomerTypeChanged(e, value) {
        e?.preventDefault()
        const customerType = Object.values(CUSTOMER_TYPES).find((c) => c.name === value)
        setCustomer({ ...customer, type: value, typeOptionId: customerType?.optionId })
    }

    return isNewCustomer ? <NewCustomerSection franchise={franchise} customer={customer} setCustomer={setCustomer} onCustomerNameChanged={onCustomerNameChanged} onCustomerTypeChanged={onCustomerTypeChanged} isDisabled={!isNewCustomer} onIndustryChanged={onIndustryChanged} industries={industries} isIndustriesLoading={isIndustriesLoading} campaign={campaign} onCampaignChanged={onCampaignChanged} campaigns={campaigns} isCampaignsLoading={isCampaignsLoading} />
        : <ExistingCustomerSection brand={brand} franchise={franchise} setEditContact={setEditContact} setEditLocation={setEditLocation} isRequired={true} customers={customers} isCustomersLoading={isCustomersLoading} selectedCustomer={selectedCustomer} setSelectedCustomer={setSelectedCustomer} />
}