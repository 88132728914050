import { Button, Box, Tooltip, IconButton, FormControlLabel, Typography, Checkbox } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import AddressAutoComplete from "../../../../components/AddressAutoComplete";
import { MAX_FRANCHISE_LOCATION_RADIUS } from "../../../../constants/location";
import AddressBox from "../../../../components/AddressBox";
import { AVAILABLE_CRMS, COUNTRIES, NOTIFICATION_TYPES } from "../../../../constants/common";
import { updateFranchiseCustomer } from "../../../../services/serviceTitan";
import { useState } from "react";
import LoadingButton from "../../../../components/LoadingButton";
import EditWarningMessage from "../components/EditWarningMessage";

export default function BillingAddressSection(props) {

    const { franchise, setCustomers, states, setSelectedCustomer, customers, selectedCustomer, isEditCustomer, isNewCustomer, location, newCustomer, setNewCustomer, editedCustomer, setEditedCustomer, isBillingSameAsLocation, setIsBillingSameAsLocation, showNotification } = props

    const [isEditBillingAddress, setIsEditBillingAddress] = useState(false)
    const [isValidZipCode, setIsValidZipCode] = useState(true)

    function onBillingAddressEditDone(editedCustomer) {
        if (!editedCustomer) return
        const index = customers.findIndex((v) => v.id === editedCustomer.id)
        if (index === -1) {
            setCustomers([editedCustomer, ...customers])
        } else {
            customers[index] = editedCustomer
            setCustomers(customers)
            setSelectedCustomer(editedCustomer)
        }
        setIsEditBillingAddress(false)
    }

    const onEditBillingAddressPressed = () => {
        if (isEditCustomer) {
            setEditedCustomer()
        } else {
            setEditedCustomer(selectedCustomer)
        }
        setIsEditBillingAddress(!isEditBillingAddress)
    }

    return <Box >
        <Box sx={{ display: 'flex', flexDirection: "row", width: "100%", justifyContent: "left", alignItems: "center", paddingTop: "0.5em", gap: "0.5em" }}>
            <label><Typography variant="subtitle1" sx={{ marginBlock: "0.4em" }} >Billing Address</Typography></label>
            {isNewCustomer ? <></> : <Tooltip title="Edit Billing Address" ><IconButton data-test="billing_address_edit_button" onClick={onEditBillingAddressPressed} disabled={isEditBillingAddress || !selectedCustomer} size="small" ><EditIcon color={!isEditBillingAddress && selectedCustomer ? "primary" : "disabled"} /></IconButton></Tooltip>}
        </Box>
        {isNewCustomer ? <NewBillingAddressSection franchise={franchise} setIsValidZipCode={setIsValidZipCode} location={location} newCustomer={newCustomer} setNewCustomer={setNewCustomer} isBillingSameAsLocation={isBillingSameAsLocation} setIsBillingSameAsLocation={setIsBillingSameAsLocation} states={states} /> : isEditBillingAddress ? <EditBillingAddressSection franchise={franchise} isValidZipCode={isValidZipCode} setIsValidZipCode={setIsValidZipCode} editedCustomer={editedCustomer} setEditedCustomer={setEditedCustomer} showNotification={showNotification} onEditDone={onBillingAddressEditDone} onEditClose={onEditBillingAddressPressed} states={states} /> : <ExistingBillingAddressSection selectedCustomer={selectedCustomer} states={states} setIsValidZipCode={setIsValidZipCode} />}
    </Box>
}

function NewBillingAddressSection(props) {
    const { franchise, location, newCustomer, setIsValidZipCode, setNewCustomer, isBillingSameAsLocation, states, setIsBillingSameAsLocation } = props

    function onAddressChanged(address) {
        setNewCustomer({ ...newCustomer, address: address })
    }

    function onChangedBillingSameAsLocation(e) {
        const isSame = e?.target?.checked ?? true
        setIsBillingSameAsLocation(isSame)
        if (!isSame) {
            setNewCustomer({ ...newCustomer, address: undefined })
        } else {
            setNewCustomer({ ...newCustomer, address: location?.address })
        }
    }

    return <Box>
        <Box sx={{ display: 'flex', flexDirection: "column", width: "100%", justifyContent: "left", alignItems: "left", marginBottom: "1em" }}>
            <FormControlLabel style={{ marginLeft: "1em" }}
                control={<Checkbox inputProps={{ 'data-test': 'adress_autocomplete_checkbox' }} sx={{ '&, &.Mui-checked': { color: '#00a79d', }, }} size={"small"} onChange={onChangedBillingSameAsLocation} checked={isBillingSameAsLocation} />}
                label="Make customer billing address same as customer location address" />
            {isBillingSameAsLocation ? <></> : <AddressAutoComplete label={"Search Billing Address"} onChange={onAddressChanged} lat={franchise?.latitude} long={franchise?.longitude} radius={MAX_FRANCHISE_LOCATION_RADIUS} crm={AVAILABLE_CRMS.servicetitan.value} />}
        </Box>
        {isBillingSameAsLocation ? <></> :
            <Box sx={{ display: 'flex', flexDirection: "row", flexWrap: "wrap", width: "100%", gap: "1em" }} >
                <AddressBox address={newCustomer?.address ?? ""} isRequired={true} setIsValidZipCode={setIsValidZipCode} isSelectableStates={false} countries={COUNTRIES} states={states} onChangeAddress={onAddressChanged} dataTest="new_billing_address" />
            </Box>
        }
    </Box>
}

function ExistingBillingAddressSection(props) {
    const { selectedCustomer, states, setIsValidZipCode } = props
    return <>
        <Box sx={{ display: 'flex', flexDirection: "row", flexWrap: "wrap", width: "100%", gap: "1em" }} >
            <AddressBox isDisabled={true} setIsValidZipCode={setIsValidZipCode} address={selectedCustomer?.address ?? ""} isRequired={true} isSelectableStates={false} countries={COUNTRIES} states={states} dataTest="existing_billing_address" />
        </Box>
    </>
}

function EditBillingAddressSection(props) {
    const { franchise, editedCustomer, isValidZipCode, setIsValidZipCode, setEditedCustomer, showNotification, onEditDone, onEditClose, states } = props

    const [isEditBillingAddressLoading, setIsEditBillingAddressLoading] = useState(false)

    function onAddressChanged(address) {
        setEditedCustomer({ ...editedCustomer, address: address })
    }

    function onErrorEditBillingAddress(err) {
        let errDetails = JSON.parse(err.responseText)
        let errCode = errDetails?.data?.ErrorCode
        let stateErr
        if (errCode === "StateNotValid") {
            stateErr = "State doesn't valid with country"
        } else if (errCode === "CountryNotValid") {
            stateErr = "Country is not valid"
        } else {
            stateErr = ""
        }
        showNotification({ message: `Important: Could not edit the location ${stateErr === "" ? "" : stateErr} . Please try again`, type: NOTIFICATION_TYPES.ERROR })
    }

    function onCompleteEditBillingAddress(res) {
        showNotification({ message: "Successfully saved", type: NOTIFICATION_TYPES.SUCCESS })
        onEditDone(res)
    }

    return <>
        <Box sx={{ display: 'flex', flexDirection: "row", width: "100%", justifyContent: "left", alignItems: "left", marginBlock: "1em" }}>
            <AddressAutoComplete label={"Search Billing Address"} onChange={onAddressChanged} lat={franchise?.latitude} long={franchise?.longitude} radius={MAX_FRANCHISE_LOCATION_RADIUS} crm={AVAILABLE_CRMS.servicetitan.value} />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: "row", flexWrap: "wrap", width: "100%", gap: "1em" }} >
            <AddressBox address={editedCustomer?.address ?? ""} isRequired={true} setIsValidZipCode={setIsValidZipCode} isSelectableStates={false} countries={COUNTRIES} states={states} onChangeAddress={onAddressChanged} dataTest="edit_billing_address" />
        </Box>
        <Box display="flex" flexDirection="column" alignItems="right" >
            <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "right", gap: "1em" }} >
                <Button data-test="edit_billing_address_cancel_button" size={"small"} variant="contained" onClick={onEditClose} className={"btn-secondary"}>Cancel</Button>
                <LoadingButton
                    onClick={() => { handleEditCustomer(franchise, editedCustomer, setIsEditBillingAddressLoading, onCompleteEditBillingAddress, onErrorEditBillingAddress) }}
                    size={"small"}
                    variant="contained"
                    data-test="edit_billing_address_save_button"
                    disabled={isEditBillingAddressLoading || !isValidZipCode}
                    loading={isEditBillingAddressLoading}
                    className={isEditBillingAddressLoading || !isValidZipCode ? "btn-disable" : "btn-primary"} >
                    {"Update Billing Address"}
                </LoadingButton>
            </Box>
            <EditWarningMessage />
        </Box>
    </>
}

async function handleEditCustomer(franchise, editedCustomer, setIsEditedCustomerLoading, onDone, onError) {
    try {
        setIsEditedCustomerLoading(true)
        const res = await updateFranchiseCustomer(franchise.id, editedCustomer.id, editedCustomer)
        onDone(res)
    } catch (err) {
        onError(err)
    } finally {
        setIsEditedCustomerLoading(false)
    }
}