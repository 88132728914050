import { getAllChannels } from "../../services/eigthByEight/channels";
import { isValidPhoneNumber } from "../../utils/validators";

export async function handleGetChannels(setAllChannels, setIsAllChannelsLoading, setIsAllChannelsLoadingError) {
    try {
        setIsAllChannelsLoading(true)
        setIsAllChannelsLoadingError(false)
        const channelsResponse = await getAllChannels();
        const channels = channelsResponse?.channels
        if (Array.isArray(channels)) {
            const phoneChannels = channels.filter((c) => isValidPhoneNumber(`${c.channel_name}`))
            setAllChannels(phoneChannels)
        }
    } catch {
        setIsAllChannelsLoadingError(true)
    } finally {
        setIsAllChannelsLoading(false)
    }
}