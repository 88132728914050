import { Box } from "@mui/material"
import { useEffect, useState } from "react"
import CustomTextField from "../../../../../components/CustomTextField"
import { handleGetCompaigns, handleGetIndustries } from "../../../../../state-services-dublicate/vonigo"
import CampaignSelection from "../../vonigo/components/CampaignSelection"
import { CUSTOMER_TYPES } from "../../vonigo/components/CustomerDetailsSection"
import IndustrySelection from "../../vonigo/components/IndustrySelection"

export default function MultipleWorkOrdersCustomerDetails(props) {
    const { brand, franchise, customer, isDisabled } = props
    const [industries, setIndustries] = useState([])
    const [isIndustriesLoading, setIsIndustriesLoading] = useState(false)
    const [campaign, setCampaign] = useState()
    const [campaigns, setCampaigns] = useState([])
    const [isCampaignsLoading, setIsCampaignsLoading] = useState(false)

    useEffect(() => {
        handleGetIndustries(brand, franchise, setIsIndustriesLoading, setIndustries)
        handleGetCompaigns(brand, franchise, setIsCampaignsLoading, setCampaigns)
    }, [brand, franchise])

    useEffect(() => {
        const selectedCampaignId = customer?.marketingCampaingOptionId?.optionID
        if (selectedCampaignId && campaigns?.length > 0) {
            const selectedCampaign = campaigns?.find((c) => c?.optionID === selectedCampaignId)
            setCampaign(selectedCampaign)
        }
    }, [customer, campaigns])


    return <Box style={{ width: "100%" }} >
        <Box sx={{ display: 'flex', flexDirection: "row", width: "100%", height: "3em", justifyContent: "left", alignItems: "center", paddingTop: "1em", marginBottom: "0.5em" }}>
            <h4>Customer</h4>
        </Box>
        <Box style={{ display: "flex", flexDirection: "column", width: "100%", gap: "1em" }} >
            <Box style={{ display: "flex", flexDirection: "row", width: "100%", }}>
                <CustomTextField style={{ minWidth: "30em", flexGrow: "2", marginRight: "0.5em" }} disabled={true} size="small" value={customer?.title ?? ""} label="Customer Name" />
                <CampaignSelection noOptionText={franchise ? "No Campaigns" : "Please select a franchise"} isDisabled={isDisabled} value={campaign ?? null} campaigns={campaigns} isCampaignsLoading={isCampaignsLoading} />
            </Box>
            <Box style={{ display: "flex", flexDirection: "row", width: "100%", gap: "1em" }}>
                {(customer?.typeOptionId?.name === CUSTOMER_TYPES.COMMERCIAL.name) && <IndustrySelection franchise={franchise} noOptionText={franchise ? "Please select a franchise" : "No Industries"} isDisabled={isDisabled} value={customer?.industryOptionId ?? null} industries={industries} isIndustriesLoading={isIndustriesLoading} />}
            </Box>
        </Box>
    </Box>
}