import { useEffect, useState } from "react"
import { SmallCardForCrmData } from "../../../../components/servicetitan/SmallCard"
import { handleGetServicetitanStatistics } from "../../../../state-services/reports/reports"
import jobImg from "../../../../assets/img/jobs.png";
import leadImg from "../../../../assets/img/leads.png";
import workorderImg from "../../../../assets/img/workorders.png";
import { Box } from "@mui/material";

export default function ServicetitanStatistics(props) {
    const { filtration, onStatisticsChangeST } = props
    const [servicetitanStatistics, setServicetitanStatistics] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(true)

    useEffect(() => {
        handleGetServicetitanStatistics(
            filtration.brandBrandId, 
            filtration.franchiseId, 
            filtration.startDate, 
            filtration.endDate, 
            (data) => {
                setServicetitanStatistics(data);
                onStatisticsChangeST(data);
            },
            setIsLoading,
            setIsError
        );
    }, [filtration.brandBrandId, filtration.franchiseId, filtration.startDate, filtration.endDate])


    return <Box sx={{ display: "flex", flexDirection: "row", gap: "20px", justifyContent: "space-evenly", minWidth: "150px" }}>
        <SmallCardForCrmData heading={"Leads"} image={leadImg} value={servicetitanStatistics?.st_leads?.toLocaleString?.() ?? "-"} isLoading={isLoading} isError={isError} />
        <SmallCardForCrmData heading={"Appointments"} image={workorderImg} value={servicetitanStatistics?.st_appointments?.toLocaleString?.() ?? "-"} isLoading={isLoading} isError={isError} />
        <SmallCardForCrmData heading={"Jobs"} image={jobImg} value={servicetitanStatistics?.st_jobs?.toLocaleString?.() ?? "-"} isLoading={isLoading} isError={isError} />
    </Box>
}

