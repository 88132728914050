import { NOTIFICATION_TYPES } from "../../constants/common"
import { getServiceTitanEmployees, getServiceTitanTechnicians, updateFranchiseCustomer } from "../../services/serviceTitan"
import { SeviceTitanEstimateCallBackLog, dismissServiceTitanEstimate, dismissServiceTitanEstimateLog, getFollowupHistory, getServiceTitanEstimateById, getServiceTitanEstimates, sellServiceTitanEstimate, soldServiceTitanEstimateLog } from "../../services/serviceTitanEstimates/serviceTitanEstimates"
import { retry, shouldRetry } from "../../state-services-dublicate/serviceTitan"

export async function handleGetServiceTitanEstimates(franchiseId = 0, createdStart = "", createdEnd = "", estimateStatus, page = 1, pageSize = 50, setLeadRes = (res) => { }, setIsLoading = (isLoading) => { }, setIsError = (isError) => { }, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    try {
        setIsLoading(true)
        setIsError(false)
        const res = await getServiceTitanEstimates(franchiseId, createdStart, createdEnd, estimateStatus, page, pageSize)
        setLeadRes(res)
    } catch (error) {
        if (shouldRetry(backoffConfig) && error?.status !== 400) {
            retry(() => handleGetServiceTitanEstimates(franchiseId, createdStart, createdEnd, estimateStatus, page, pageSize, setLeadRes, setIsLoading, setIsError, backoffConfig), backoffConfig)
        } else {
            setIsError(true)
        }
    } finally {
        setIsLoading(false)
    }
}

export async function handleGetServiceTitanEmployees(franchise = {}, setEmployeesRes = (res) => { }, soldById = 0, setCreatedByEmployee = () => { }, setIsLoading = (isLoading) => { }, setIsError = (isError) => { }, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    try {
        setIsLoading(true)
        setIsError(false)
        let resEmployees = await getServiceTitanEmployees(franchise?.id)
        let resTechnicians = await getServiceTitanTechnicians(franchise?.service_titan_tenant_id, franchise?.service_titan_client_id, franchise?.service_titan_client_secret, [])
        let res = [...resEmployees?.data, ...resTechnicians?.data?.filter((t) => t?.isManagedTech)]
        let createdByEmployee = res.find((employee) => employee?.id === soldById)
        setCreatedByEmployee(createdByEmployee)
        if (Array.isArray(res)) {
            setEmployeesRes(res)
        }
    } catch (error) {
        if (shouldRetry(backoffConfig) && error?.status !== 400) {
            retry(() => handleGetServiceTitanEmployees(franchise?.id, setEmployeesRes, soldById, setCreatedByEmployee, setIsLoading, setIsError, backoffConfig), backoffConfig)
        } else {
            setIsError(true)
        }
    } finally {
        setIsLoading(false)
    }
}

export async function handleGetFollwupHistory(estimateID = 0, setFollowupHistory = (res) => { }, setIsLoading = (isLoading) => { }, setIsError = (isError) => { }, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    try {
        setIsLoading(true)
        setIsError(false)
        const res = await getFollowupHistory(estimateID)
        if (Array.isArray(res) && res.length > 0) {
            res.sort((a, b) => new Date(a?.created_at) - new Date(b?.created_at))
            setFollowupHistory(res)
        } else {
            setFollowupHistory([])
        }
        setFollowupHistory(res)
    } catch (error) {
        if (shouldRetry(backoffConfig) && error?.status !== 400) {
            retry(() => handleGetFollwupHistory(estimateID, setFollowupHistory, setIsLoading, setIsError, backoffConfig), backoffConfig)
        } else {
            setIsError(true)
        }
    } finally {
        setIsLoading(false)
    }
}

export async function handleSellServiceTitanEstimate(franchiseId = 0, estimateID = 0, soldById = 0, setIsLoading, showNotification, isSoldEstimate, handleSoldEstimateLog) {
    try {
        setIsLoading(true)
        await sellServiceTitanEstimate(franchiseId, estimateID, soldById)
        showNotification({ message: "Estimate Sold Success", type: NOTIFICATION_TYPES.SUCCESS })
        handleSoldEstimateLog()
        isSoldEstimate.current = true
    } catch (error) {
        const nonManagedPhrase = "sold by non-managed technician";
        if (error?.responseText?.includes(nonManagedPhrase)) {
            showNotification({ message: "Estimate can't be sold by non-managed technician.", type: NOTIFICATION_TYPES.ERROR })
        } else {
            showNotification({ message: "Something went wrong", type: NOTIFICATION_TYPES.ERROR })
        }
    } finally {
        setIsLoading(false)
    }
}

export async function handleGetServiceTitanEstimateById(franchiseId = 0, estimateID = 0, setselectedEstimate, setIsOpenViewEstimateModal) {
    try {
        let res = await getServiceTitanEstimateById(franchiseId, estimateID)
        setselectedEstimate(res)
        setIsOpenViewEstimateModal(true)
    } catch (error) {
    }
}

export async function handleDismissServiceTitanEstimate(franchiseId = 0, estimateID = 0, setIsLoading, showNotification, isDismissEstimate, handleDismissEstimateLog) {
    try {
        setIsLoading(true)
        await dismissServiceTitanEstimate(franchiseId, estimateID)
        showNotification({ message: "Estimate Dismissed Success", type: NOTIFICATION_TYPES.SUCCESS })
        handleDismissEstimateLog()
        isDismissEstimate.current = true
    } catch (error) {
        showNotification({ message: "Something went wrong", type: NOTIFICATION_TYPES.ERROR })
    } finally {
        setIsLoading(false)
    }
}

export async function handleDismissServiceTitanEstimateLog(brandId = 0, franchiseId = 0, estimateID = 0) {
    try {
        await dismissServiceTitanEstimateLog(brandId, franchiseId, estimateID)
    } catch (error) {
    }

}

export async function handleSoldServiceTitanEstimateLog(brandId = 0, franchiseId = 0, estimateID = 0) {
    try {
        await soldServiceTitanEstimateLog(brandId, franchiseId, estimateID)
    } catch (error) {
    }

}

export async function handleServiceTitanCallBackLog(brandId = 0, franchiseId = 0, estimateID = 0,outboundCallId=0) {
    try {
        await SeviceTitanEstimateCallBackLog(brandId, franchiseId, estimateID,outboundCallId)
    } catch (error) {
    }

}

export async function handleAddTagsForCustomer(franchiseId = 0, customerId = 0, customerUpdateRequest = {}, setIsLoading, showNotification, selectedTagTypes, settagTypes) {
    try {
        setIsLoading(true)
        await updateFranchiseCustomer(franchiseId, customerId, customerUpdateRequest)
        showNotification({ message: "Tags Added Success", type: NOTIFICATION_TYPES.SUCCESS })
        settagTypes(selectedTagTypes)
    } catch (error) {
        showNotification({ message: "Something went wrong", type: NOTIFICATION_TYPES.ERROR })
    } finally {
        setIsLoading(false)
    }
}