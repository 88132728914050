import { Box, Stepper, Step, StepLabel } from "@mui/material";
import { NotSuccessIconError, SuccessIcon } from "../../../../icons/common";

export default function CreateJobStepper(props) {
    const { steps, onActiveStepChanged, getIsCompleted } = props
    return <Box sx={{ width: '100%', marginBlock: "1em" }}>
        <Stepper activeStep={5}>
            {steps.map((step, i) => {
                return <Step key={step?.id} onClick={() => onActiveStepChanged(i)} >
                    <StepLabel data-test={`job_step_` + step?.content} className="job-step-icon" StepIconComponent={getIsCompleted(step?.id) ? SuccessComponent : NotSuccessComponent} >{step?.content}</StepLabel>
                </Step>
            })}
        </Stepper>
    </Box>
}

function SuccessComponent(props) {
    return <SuccessIcon />
}

function NotSuccessComponent(props) {
    return <NotSuccessIconError />
}