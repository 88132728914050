import { getUsers } from "../../services/userService"

let lastParams = ""
export async function handleGetUsers(ids = [], brandId=[], userLevel=[], page, pageSize, setUsers, setCount, setIsLoading, setIsError) {
    try {
        lastParams = `${ids.join(",")}_${page}_${pageSize}`
        setIsLoading(true)
        setIsError(false)
        const res = await getUsers(ids, brandId, userLevel, page, pageSize)
        if (lastParams !== `${ids.join(",")}_${page}_${pageSize}`) return
        setUsers(res?.users ? res?.users : [])
        setCount({ all: res?.count?.all, active: res?.count?.active, inactive: res?.count?.inactive })
    } catch {
        setIsError(true)
    } finally {
        setIsLoading(false)
    }
}