import { Autocomplete, Box, Button, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import CustomTextField from "../../../../../components/CustomTextField"
import RenderInput from "../../../../../components/servicetitan/RenderInput"
import { NOTIFICATION_TYPES } from "../../../../../constants/common"
import { createContact, getContacts } from "../../../../../services/vonigo/jobScheduling"
import { isValidEmail, isValidPhoneNumber } from "../../../../../utils/validators"
import EmailField from "../../components/EmailField"
import PhoneNumberField from "./PhoneNumberField"
import LoadingButton from "../../../../../components/LoadingButton"

export default function ContactSection(props) {
    const { brand, franchise, isNewCustomer, isNewContact, setIsNewContact, contact, setContact, contacts, setContacts, isDisabled, selectedCustomer, onContactChanged, showNotification } = props
    const [newContact, setNewContact] = useState({})
    const [isContactsLoading, setIsContactsLoading] = useState(false)
    const [isCreateContactLoading, setIsCreateContactLoading] = useState(false)

    useEffect(() => {
        if (brand && franchise && brand?.redirect_url && franchise?.franchise_id && selectedCustomer?.objectID) {
            setIsContactsLoading(true)
            getContacts(brand?.redirect_url, franchise?.franchise_id, selectedCustomer?.objectID).then((response) => {
                if (Array.isArray(response?.Contacts)) {
                    setContacts(response?.Contacts)
                }
            }).catch(() => { }).finally(() => setIsContactsLoading())
        }
    }, [brand, franchise, selectedCustomer?.objectID, setContacts])

    useEffect(() => {
        onContactChanged(contact)
    }, [contact])

    function createNewontact() {
        if (brand && franchise && brand?.redirect_url && franchise?.franchise_id && selectedCustomer?.objectID) {
            const contactCreateRequest = {
                ...newContact,
                baseUrl: brand?.redirect_url,
                franchiseId: franchise?.franchise_id,
                clientId: parseInt(selectedCustomer?.objectID, 10),
            }
            setIsCreateContactLoading(true)
            createContact(contactCreateRequest).then(response => {
                if (Array.isArray(contacts)) {
                    setContacts([response, ...contacts])
                } else {
                    setContacts([response])
                }
                setContact(response)
                setNewContact({})
                setIsNewContact(false)
                showNotification({ message: "Contact has been created successfully", type: NOTIFICATION_TYPES.SUCCESS, isOpen: true })
            }).catch(err => {
                showNotification({ message: "Important: Contact couldn’t be created at this time. Please try again", type: NOTIFICATION_TYPES.ERROR })
            }).finally(() => setIsCreateContactLoading(false))
        }
    }

    return <Box sx={{ display: "flex", flexDirection: "column", gap: "1em" }} >
        <Box display="flex" flexDirection="row" alignItems="center" marginTop={"1em"} >
            <label ><Typography variant="subtitle1" >Contacts</Typography></label>
            {isNewCustomer ? <></> : <Button
                sx={{ color: "#00a79d" }}
                title="Add new contact"
                disabled={!selectedCustomer?.objectID}
                variant="text"
                onClick={() => setIsNewContact(!isNewContact)}>{!isNewContact ? "\u002B New" : "Existing"}
            </Button>}
        </Box>
        {isNewContact ? <><Box sx={{ display: "flex", flexDirection: "row", gap: "1em" }}>
            <CustomTextField
                disabled={isDisabled}
                required={!isDisabled}
                label={"First Name"}
                variant="outlined"
                size="small"
                style={{ flexGrow: "1" }}
                value={newContact?.firstName}
                onChange={(e) => {
                    setNewContact({ ...newContact, firstName: e?.target?.value })
                }} />
            <CustomTextField
                disabled={isDisabled}
                required={true}
                label={"Last Name"}
                variant="outlined"
                size="small"
                style={{ flexGrow: "1" }}
                value={newContact?.lastName}
                onChange={(e) => {
                    setNewContact({ ...newContact, lastName: e?.target?.value })
                }} />
        </Box>
            <Box sx={{ display: "flex", flexDirection: "row", gap: "1em" }}>
                <EmailField required={true} disabled={false} value={newContact?.email} onChange={(e) => setNewContact({ ...newContact, email: e?.target?.value })} />
                <PhoneNumberField isRequired={true} isDisabled={false} value={newContact?.phone1} onChange={(phone) => setNewContact({ ...newContact, phone1: phone })} />
            </Box>
            <Box style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "flex-end" }}>
                <LoadingButton size={"small"} variant="contained"
                    loading={isCreateContactLoading}
                    onClick={() => {
                        createNewontact()
                    }} className={isContactValid(newContact) && selectedCustomer ? "btn-primary" : "btn-disable"} >
                    {"Create Contact"}
                </LoadingButton>
            </Box> </> :
            <Autocomplete
                sx={{ width: "100%", }}
                size="small"
                noOptionsText={selectedCustomer ? "No Contacts found for customer" : "Please select a customer"}
                loading={isContactsLoading}
                value={contact ?? null}
                disablePortal
                disableClearable
                options={contacts}
                getOptionLabel={(contact) => contact?.name ?? "-"}
                renderOption={(props, c) => <RenderInput {...props} key={c?.objectID} content={c?.name} />}
                onChange={(_, value) => {
                    setContact(value);
                }}
                renderInput={(params) => <CustomTextField   {...params} required={!isNewCustomer} label="Select Customer's Contact" />}
            />}
    </Box>
}

function isContactValid(contact) {
    const isValid = contact?.firstName && contact?.lastName && isValidEmail(contact?.email) && isValidPhoneNumber(contact?.phone1)
    return isValid
}