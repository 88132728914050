import { Box } from '@mui/material'
import React from 'react'
import CustomTextField from '../../../../components/CustomTextField'
import EventIcon from '@mui/icons-material/Event';

export default function dataPickerCustomeInput() {
    return (
        <CustomTextField
            size="small"
            InputProps={{
                endAdornment:
                    <Box display={"flex"} flexDirection={"row"} alignItems={"center"} >
                        <EventIcon style={{ color: "gray", cursor: "pointer" }} />
                    </Box>
            }}
        />
    )
}
