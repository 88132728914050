import { useState, useEffect, useContext } from "react";
import "./styles/scoreDashboardStyles.css"
import { Link, withRouter } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import Select from "@mui/material/Select";
import Chart from 'react-apexcharts';
import CircularProgress from '@mui/material/CircularProgress';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Paper, Tooltip, IconButton, FormControl, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import ReactDatePicker from "react-datepicker";
import CustomTextField from "../../../components/CustomTextField";
import { calculateStarts, formatDataForChart, formatDetailsOfCurrentAgent, getLabelPrefix, getScoreColor, handleCustomeDateChange, handleDateChange, handleGetAllUsers, handleViewAgentStatics, selectTopAndOtherAgents, setDatesWhenRangeChanges } from "./utils";
import { formatStartAndEndDate, getInformation } from "../../../state-services/scoreDashboard";
import { ScoreCard } from "./components/scoreCard";
import { AgentCard } from "./components/agentCard";
import ConnectedTvIcon from '@mui/icons-material/ConnectedTv';
import { closeConnection, updateMonitoringLogs } from "../../../services/screenMonitoring";
import { AgentMonitorMainPage } from "./components/agentMonitoringMainPage";
import { CustomSelect } from "./styles/customStyle";
import { EMPTY_CHECK_REGEX, ERROR_COMPONENTS } from "../../../constants/common";
import statusCodeItem from "./components/statusCodeItem";
import chartOptions from "./components/chartOptions";
import dataPickerCustomeInput from "./components/dataPickerCustomeInput";
import { customRanges } from "./constants/constants";
import { filterAgents } from "../../../utils/miscellaneous";
import { USER_TYPES } from "../../../constants/users";
import ScoreIndicaters from "./components/scoreIndicaters";
import ErrorView from "../../../components/ErrorView";
import { UserContext } from '../../../contexts/User';
import moment from "moment";
import NoDataAvailableMessage from "./components/noDataAvailableMessage";
import NoAgentsForSearchMessage from "./components/noAgentsForSearchMessage";
import { MONITORING_STATUS } from "../../../constants/monotoringLogs";
import { getFromLocalStorage, removeFromLocalStorage } from "../../../utils/browserStorage";
import { CommonContext } from "../../../contexts/CommonContextProvider";
import { getSingleAgentDataByDateRange } from "../../../services/agentScoreService";

const AGENT_DATA_TYPES = [{ type: "Average" }, { type: "Total" }]

function ScoreDashboard() {
  const [start, setStartDate] = useState(null);
  const [end, setEndDate] = useState(null);
  const [dateChangeCount, setDateChangeCount] = useState(0)
  const [range, setRange] = useState(30);
  const [loading, setLoading] = useState(false)
  const [isTotalValuesLoading, setIsTotalValuesLoading] = useState(false)
  const [currentAgentData, setCurrentAgentData] = useState(null)
  const [selectedAgent, setSelectedAgent] = useState(null)
  const [currentAgentStatusCodeData, setCurrentAgentStatucCodeData] = useState(null)
  const [agentStatusCodeData, setAgentStatusCodeData] = useState([])
  const [chartData, setChartData] = useState([])
  const [chartLabels, setChartLabels] = useState([])
  const [topAgents, setTopAgents] = useState([])
  const [otherAgents, setOtherAgents] = useState([])
  const [searchValueOfAgents, setSearchValueOfAgents] = useState("");
  const [allUsers, setAllUsers] = useState([])
  const [isNoAgentAvailableForSearch, setIsNoAgentAvailableForSearch] = useState(false)
  const [isDisconnected, setIsDisconnected] = useState(false)
  const [IsAgentDataLoading, setIsAgentDataLoading] = useState(false)
  const [toggleView, setToggleView] = useState("Average")
  const [agentData, setAgentData] = useState([])
  const { me } = useContext(UserContext)

  const [agents, setAgents] = useState([])
  const [selectedAgentForMonitoring, setSelectedAgentForMonitoring] = useState(null)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const [lastInsertedId, setlastInsertedId] = useState(null)
  const [isAgentMonitorError, setIsAgentMonitorError] = useState(false)
  const [webRtcConnection, setWebRtcConnection] = useState(null)
  const { adminId } = useContext(CommonContext)

  let isEnableScore = false

  useEffect(() => {
    setLoading(true)
    setIsAgentDataLoading(true)
    if (allUsers?.length === 0) {
      handleGetAllUsers(setAllUsers, me)
    }
    if (allUsers?.length !== 0) {
      getInformation(start, end, setLoading, setEndDate, setStartDate, range, formatDetailsOfCurrentAgent, setCurrentAgentData, selectTopAndOtherAgents,
        setTopAgents, setOtherAgents, setCurrentAgentStatucCodeData, setAgentStatusCodeData, setChartData, setChartLabels, currentAgentData, selectedAgent, setSelectedAgent, allUsers, setIsAgentDataLoading, setSearchValue, setIsNoAgentAvailableForSearch, setAgentData, toggleView);
    } else {
      setLoading(false)
      setIsNoAgentAvailableForSearch(false)
    }
  }, [dateChangeCount, range, allUsers]);

  useEffect(() => {
    if (selectedAgent && toggleView === "Total") {
      handleGetSingleAgentData()
    } else if (selectedAgent && toggleView === "Average" && agentData?.length !== 0) {
      formatDetailsOfCurrentAgent(agentData, setCurrentAgentData, setCurrentAgentStatucCodeData, setChartLabels, setChartData, currentAgentData, selectedAgent, setSelectedAgent, toggleView)
      setAgentStatusCodeData(agentData)
    }
  }, [toggleView, selectedAgent])

  const handleGetSingleAgentData = async () => {
    const isTotal = "true"
    const { startDate, endDate } = formatStartAndEndDate(range, setEndDate, setStartDate, start, end)
    try {
      setIsTotalValuesLoading(true)
      let res = await getSingleAgentDataByDateRange(startDate, endDate, isTotal, selectedAgent?.user?.user_id)
      if (Array.isArray(res)) {
        setCurrentAgentData(res[0])
        setCurrentAgentStatucCodeData(res[0])
        formatDataForChart(res[0], setChartLabels, setChartData, toggleView, start, end)
      }
    } catch (error) {
      setCurrentAgentData(null)
      setCurrentAgentStatucCodeData(null)
      setAgentStatusCodeData([])
      setChartData([])
      setChartLabels([])
    } finally {
      setIsTotalValuesLoading(false)
    }
  }

  const handleSetRange = async (e) => {
    try {
      setToggleView("Average")
      let dateRange = e.target.value;
      setRange(dateRange)
      if (dateRange !== 0) {
        handleDateChange(setCurrentAgentData, setCurrentAgentStatucCodeData, setAgentStatusCodeData, setChartData, setChartLabels)
        setDatesWhenRangeChanges(setStartDate, setEndDate, dateRange)
      }
    } catch (error) { }
  };

  const handleStartDateChange = async (value) => {
    try {
      setToggleView("Average")
      setRange(0)
      setDateChangeCount(dateChangeCount + 1)
      handleCustomeDateChange(setStartDate, value, setCurrentAgentData, setCurrentAgentStatucCodeData, setAgentStatusCodeData, setChartData, setChartLabels)
    } catch (error) { }
  };

  const handleEndDateChange = async (value) => {
    try {
      setToggleView("Average")
      setRange(0)
      setDateChangeCount(dateChangeCount + 1)
      handleCustomeDateChange(setEndDate, value, setCurrentAgentData, setCurrentAgentStatucCodeData, setAgentStatusCodeData, setChartData, setChartLabels)
    } catch (error) { }
  };

  const handleSetSearchValue = (e) => {
    let keyWord = e.target.value.replace(EMPTY_CHECK_REGEX, '')
    setNoAgentAvaibaleForSearchError(keyWord)
    { keyWord !== '' ? setSearchValue(keyWord) : setSearchValue('') }
  };

  const setNoAgentAvaibaleForSearchError = (key) => {
    let top = filterAgents(topAgents, key)
    let other = filterAgents(otherAgents, key)
    top?.length === 0 && other?.length === 0 ? setIsNoAgentAvailableForSearch(true) : setIsNoAgentAvailableForSearch(false)
  }

  const substractHoursFromDate = (day) => {
    if (moment(day).isValid()) {
      return moment(day).subtract(1, 'day')
    } else {
      return day
    }
  }

  const addHoursToDate = (day) => {
    if (moment(day).isValid()) {
      return moment(day).add(1, 'day')
    } else {
      return day
    }
  }

  const selectedDate = (day) => {
    if (moment(day).isValid()) {
      return moment(day)
    } else {
      return day
    }
  }

  const checkDigitsOfActiveDays = (number) => {
    return number?.toString().length
  }

  function handleOpenAgentMonitor() {
    setDialogOpen(true)
  }

  function handleRadioChange(e) {
    setLoading(true)
    setToggleView(e.target.value)
    setLoading(false)
  }

  function handleCloseAgentMonitoring() {
    try {
      if (localStorage.getItem("agent")) {
        localStorage.removeItem("agent")
      }
      if (getFromLocalStorage("already_monitoring")) {
        removeFromLocalStorage("already_monitoring")
      }
      if (!isAgentMonitorError && !isDisconnected && selectedAgentForMonitoring) {
        let closingData = {
          message: JSON.stringify({ message_type: "close", body: "" }),
          to_session_id: selectedAgentForMonitoring?.session_id,
          from_session_id: adminId?.toString(),
        }
        closeConnection(closingData)
        let closeLogData = {
          admin_id: parseInt(localStorage.getItem("user_id")),
          agent_id: parseInt(selectedAgentForMonitoring?.user_id),
          status: MONITORING_STATUS.end,
          last_inserted_id: lastInsertedId
        }
        updateMonitoringLogs(closeLogData)
      }
      if (webRtcConnection) {
        webRtcConnection.close()
        setWebRtcConnection(null)
      }
      setSearchValueOfAgents("")
      setIsAgentMonitorError(false)
      setSelectedAgentForMonitoring(null)
      setIsDisconnected(false)
    } catch (error) {
      setSearchValueOfAgents("")
      setIsAgentMonitorError(false)
      setSelectedAgentForMonitoring(null)
      setIsDisconnected(false)
    }
  }

  return (
    <div>
      <div className="bg-shadow bg-white report-bg">
        <div className="upper-section bg-shadow" >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img className="image-style" src={me?.img} alt="user_image" />
            <h4 className="name-style">{`${me?.first_name} ${me?.last_name}`}</h4>
            {me?.level <= USER_TYPES?.agent?.level && isEnableScore &&
              <div className="my-score-container">
                <div className="gray-line"></div>
                <div className="score-title"> <p>Score</p></div>
                <h2 style={{ color: getScoreColor(currentAgentData?.score) }}>{currentAgentData?.score}</h2>
              </div>
            }
          </div>
          {(me?.level <= USER_TYPES?.agent?.level && isEnableScore) &&
            <div className="score-margins">
              {ScoreIndicaters("box green", "high")}
              {ScoreIndicaters("box orange", "medium")}
              {ScoreIndicaters("box red", "Low")}
            </div>
          }
          {me?.level > USER_TYPES?.agent?.level &&
            <div style={{ marginLeft: "auto" }}>
              <IconButton className="monitor-btn" onClick={() => handleOpenAgentMonitor()}>
                <Tooltip title={"Monitor Agent"}><ConnectedTvIcon style={{ height: "25px", width: "25px" }}></ConnectedTvIcon></Tooltip>
              </IconButton>
            </div>
          }
          {me?.level > USER_TYPES?.agent?.level &&
            <div style={{ marginLeft: "1%", marginRight: "1%" }}>
              <Link to="/wallboard/agentLogs">
                <IconButton className="icon-btn"  >
                  <Tooltip title={"Agent Logs"}><ContentPasteIcon style={{ height: "25px", width: "25px" }}></ContentPasteIcon></Tooltip>
                </IconButton>
              </Link>
            </div>
          }
          <div className="top-bar-right-section">
            {(me?.level > USER_TYPES?.agent?.level && selectedAgent?.data && currentAgentStatusCodeData) || (me?.level <= USER_TYPES?.agent?.level && currentAgentStatusCodeData) ?
              <div className="working-days-container">
                <div><p style={{ fontSize: "15px" }}>Total Working Days</p></div>
                <div className="working-days-number">
                  {me?.level > USER_TYPES?.agent?.level ? checkDigitsOfActiveDays(selectedAgent?.data?.active_days) < 2 && <p className="working-days-number-wrap"><string>0</string></p> : checkDigitsOfActiveDays(currentAgentStatusCodeData?.active_days) < 2 && <p className="working-days-number-wrap"><string>0</string></p>}
                  <p className="working-days-number-wrap"><string>{me?.level > USER_TYPES?.agent?.level ? selectedAgent?.data?.active_days : currentAgentStatusCodeData?.active_days}</string></p>
                </div>
              </div>
              :
              <>
              </>
            }
            <CustomSelect style={{ marginRight: "25px" }} sx={{ m: 1, minWidth: 150 }}>
              <Select value={range} onChange={handleSetRange} size={"small"} displayEmpty inputProps={{ "aria-label": "Without label" }} className={"cus-input"}>
                {customRanges.map((cr, i) => (
                  <MenuItem key={i} value={cr.value}>{cr.label}</MenuItem>
                ))}
              </Select>
            </CustomSelect>
            <div className="date-picker-container">
              <ReactDatePicker maxDate={new Date(substractHoursFromDate(end))} dateFormat="MM/dd/yyyy" onChange={handleStartDateChange} customInput={dataPickerCustomeInput()}
                selected={typeof start === 'undefined' ? null : new Date(selectedDate(start))}
              />
              <span style={{ margin: 10 }}>to</span>
              <ReactDatePicker minDate={new Date(addHoursToDate(start))} maxDate={new Date()} dateFormat="MM/dd/yyyy" onChange={handleEndDateChange} customInput={dataPickerCustomeInput()}
                selected={typeof end === 'undefined' ? null : new Date(selectedDate(end))}
              />
            </div>
          </div>
        </div>
        <div className="score-data" >
          {me?.level <= USER_TYPES?.agent?.level &&
            <div className={"data-row"} >
              <ScoreCard name={`${getLabelPrefix(currentAgentStatusCodeData?.active_days, toggleView)} Logged In Time`} value={currentAgentData?.logged_in_time} loading={loading} />
              <ScoreCard name={`${getLabelPrefix(currentAgentStatusCodeData?.active_days, toggleView)} Busy Time`} value={currentAgentData?.busy_time} loading={loading} />
              <ScoreCard name={`${getLabelPrefix(currentAgentStatusCodeData?.active_days, toggleView)} Handling Time`} value={currentAgentData?.handling_time} loading={loading} />
              <ScoreCard name={`${getLabelPrefix(currentAgentStatusCodeData?.active_days, toggleView)} Available Time`} value={currentAgentData?.available_time} loading={loading} />
              <ScoreCard name={`${getLabelPrefix(currentAgentStatusCodeData?.active_days, toggleView)} On Break Time`} value={currentAgentData?.on_break_time} loading={loading} />
              <ScoreCard name={`${getLabelPrefix(currentAgentStatusCodeData?.active_days, toggleView)} Offline Time`} value={currentAgentData?.working_offline_time} loading={loading} />
              <ScoreCard name={`${getLabelPrefix(currentAgentStatusCodeData?.active_days, toggleView)} Wrap Up Time`} value={currentAgentData?.wrap_up_time} loading={loading} />
            </div>
          }
          <div className={"main"}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={1}>
                {me?.level > USER_TYPES?.agent?.level &&
                  <Grid item xs={3}>
                    {IsAgentDataLoading ?
                      <Paper className="agent-score-section">
                        <div className="agent-section-loading-container">
                          <h3 className="title">Agent Insights</h3>
                          <div className="status-code-section-loading">
                            <CircularProgress color="inherit" />
                          </div>
                        </div>
                      </Paper>
                      :
                      <Paper className="agent-score-section">
                        <div className="agent-search-container">
                          <h3 className="title">Agent Insights</h3>
                          {topAgents.length !== 0 &&
                            <CustomTextField
                              onChange={handleSetSearchValue}
                              id="outlined-basic"
                              label={"Search"}
                              size={"small"}
                              style={{ width: "220px", marginRight: "17px", marginLeft: "auto" }}
                              variant="outlined"
                              value={searchValue}
                            />
                          }
                        </div>
                        <div style={{ overflow: "auto" }}>
                          <Box className="top-agents-container">
                            {topAgents.length !== 0 ?
                              isNoAgentAvailableForSearch ?
                                (<NoAgentsForSearchMessage cssClass={"no-data-available-score"} />)
                                :
                                filterAgents(topAgents, searchValue)?.map((agent) => {
                                  return (
                                    <AgentCard agent={agent} index={agent?.user?.user_id} cssClass={"agent-card"} handleViewAgentStatics={handleViewAgentStatics} setSelectedAgent={setSelectedAgent} agentStatusCodeData={agentStatusCodeData}
                                      setCurrentAgentStatucCodeData={setCurrentAgentStatucCodeData} setChartLabels={setChartLabels} setChartData={setChartData} calculateStarts={calculateStarts} selectedAgent={selectedAgent}
                                    />
                                  )
                                })
                              : (<NoDataAvailableMessage cssClass={"no-data-available-score"} />)
                            }
                          </Box>
                          {me?.level > USER_TYPES?.agent?.level && otherAgents.length !== 0 &&
                            <>
                              <Box className="other-agents-container">
                                {filterAgents(otherAgents, searchValue)?.map((agent) => {
                                  return (
                                    <AgentCard agent={agent} index={agent?.user?.user_id} cssClass={"agent-card"} handleViewAgentStatics={handleViewAgentStatics} agentStatusCodeData={agentStatusCodeData} setSelectedAgent={setSelectedAgent}
                                      setCurrentAgentStatucCodeData={setCurrentAgentStatucCodeData} setChartLabels={setChartLabels} setChartData={setChartData} calculateStarts={calculateStarts} selectedAgent={selectedAgent}
                                    />
                                  )
                                })}
                              </Box>
                            </>
                          }
                        </div>
                      </Paper>
                    }
                  </Grid>
                }
                <Grid item xs={me?.level > USER_TYPES?.agent?.level ? 6 : 8}  >
                  {loading || isTotalValuesLoading ?
                    <Paper className="chart-section" style={{ height: me.level <= USER_TYPES?.agent?.level && "62vh" }}>
                      <div className="chart-section-container">
                        <h3 className="title">{me?.level <= USER_TYPES?.agent?.level ? "My Statistics" : "Agent Statistics"}</h3>
                        <div className="status-code-section-loading" style={{ height: me?.level <= USER_TYPES?.agent?.level && "57vh" }}>
                          <CircularProgress color="inherit" />
                        </div>
                      </div>
                    </Paper>
                    :
                    <Paper className="chart-section" style={{ height: me?.level <= USER_TYPES?.agent?.level && "62vh" }}>
                      <div className="chart-section-container">
                        <h3 className="title">{me?.level <= USER_TYPES?.agent?.level ? "My Statistics" : "Agent Statistics"}</h3>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          {(me?.level > USER_TYPES?.agent?.level && selectedAgent) &&
                            <p style={{ fontSize: "18px" }}>{selectedAgent?.user?.first_name + " "}{selectedAgent?.user?.last_name}</p>
                          }
                          {currentAgentStatusCodeData?.active_days > 1 &&
                            <div style={{ marginLeft: 'auto', zIndex: '3' }}>
                              <FormControl >
                                <RadioGroup disabled={isTotalValuesLoading} row value={toggleView} name="type-radio-buttons-group"
                                  onChange={handleRadioChange}>
                                  {AGENT_DATA_TYPES.map((option) => <FormControlLabel key={option.type} value={option.type} control={<Radio sx={{ '&, &.Mui-checked': { color: '#00a79d', }, "&.Mui-disabled": { color: "grayText" } }} />} label={option.type} />)}
                                </RadioGroup>
                              </FormControl>
                            </div>
                          }
                        </div>
                      </div>
                      {chartData.length !== 0 ?
                        <Chart series={chartData} options={chartOptions(chartLabels)} type="donut" width={"100%"} height={"87%"} />
                        :
                        <div className="no-data-available-chart">
                          <ErrorView type={ERROR_COMPONENTS.NOT_FOUND} title='No Data Available For Selected Range' background={true} />
                        </div>
                      }
                    </Paper>
                  }
                </Grid>
                <Grid item xs={me?.level > USER_TYPES?.agent?.level ? 3 : 4}>
                  {loading || isTotalValuesLoading ?
                    <Paper className="status-code-section" style={{ height: me.level <= USER_TYPES?.agent?.level && "62vh" }}>
                      <div className="agent-statistics-title-section">
                        <h3 className="title">{me?.level <= USER_TYPES?.agent?.level ? "My Status Code Statistics" : "Agent Status Code Statistics"}</h3>
                        <div className="status-code-section-loading" style={{ height: me?.level <= USER_TYPES?.agent?.level && "57vh" }}>
                          <CircularProgress color="inherit" />
                        </div>
                      </div>
                    </Paper>
                    :
                    <Paper className="status-code-section" style={{ height: me?.level <= USER_TYPES?.agent?.level && "62vh" }}>
                      <div style={{ marginBottom: "1em" }}>
                        <div className="agent-statistics-title-section">
                          <h3 className="title">{me?.level <= USER_TYPES?.agent?.level ? "My Status Code Statistics" : "Agent Status Code Statistics"}</h3>
                          {(me?.level > USER_TYPES?.agent?.level && selectedAgent) &&
                            <p style={{ fontSize: "18px" }}>{selectedAgent?.user?.first_name + " "}{selectedAgent?.user?.last_name}</p>
                          }
                        </div>
                      </div>
                      {currentAgentStatusCodeData ?
                        <div className="agent-statistics-title-section">
                          <Accordion className="status-code-accordation" sx={{ borderLeftColor: "#F14C5F" }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <p className="status-code-title">On Break</p>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div className="status-code-container-of-break-codes"  >
                                {statusCodeItem(currentAgentStatusCodeData?.lunch_break, `${getLabelPrefix(currentAgentStatusCodeData?.active_days, toggleView)} Lunch Break Time`)}
                                {statusCodeItem(currentAgentStatusCodeData?.scheduled_break, `${getLabelPrefix(currentAgentStatusCodeData?.active_days, toggleView)} Scheduled Break Time`)}
                                {statusCodeItem(currentAgentStatusCodeData?.unscheduled_break, `${getLabelPrefix(currentAgentStatusCodeData?.active_days, toggleView)} Unscheduled Break Time`)}
                                {statusCodeItem(currentAgentStatusCodeData?.other_break, `${getLabelPrefix(currentAgentStatusCodeData?.active_days, toggleView)} Other Break Time`)}
                              </div>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion className="status-code-accordation" sx={{ borderLeftColor: "#775DD0" }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <p className="status-code-title">Offline</p>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div className="status-code-container-of-offline-codes" >
                                {statusCodeItem(currentAgentStatusCodeData?.call_backs, `${getLabelPrefix(currentAgentStatusCodeData?.active_days, toggleView)} Call Back Time`)}
                                {statusCodeItem(currentAgentStatusCodeData?.csr_project_or_assignment, `${(currentAgentStatusCodeData?.active_days, toggleView)} CSR Project Or Assignment Time`)}
                                {statusCodeItem(currentAgentStatusCodeData?.management, `${getLabelPrefix(currentAgentStatusCodeData?.active_days, toggleView)} Management Time`)}
                                {statusCodeItem(currentAgentStatusCodeData?.coaching_meeting, `${getLabelPrefix(currentAgentStatusCodeData?.active_days, toggleView)} Coaching/Meeting Time`)}
                                {statusCodeItem(currentAgentStatusCodeData?.tech_or_system_issue, `${getLabelPrefix(currentAgentStatusCodeData?.active_days, toggleView)} Tech Or System Issue Time`)}
                                {statusCodeItem(currentAgentStatusCodeData?.training, `${getLabelPrefix(currentAgentStatusCodeData?.active_days, toggleView)} Training Time`)}
                                {statusCodeItem(currentAgentStatusCodeData?.working_lead, `${getLabelPrefix(currentAgentStatusCodeData?.active_days, toggleView)} Working Lead Time`)}
                                {statusCodeItem(currentAgentStatusCodeData?.other_offline, `${getLabelPrefix(currentAgentStatusCodeData?.active_days, toggleView)} Other Offline Time`)}
                              </div>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion disabled={true} className="status-code-accordation" sx={{ borderLeftColor: "#038FFA" }}>
                            <AccordionSummary  >
                              <p className="status-code-title">Available</p>
                            </AccordionSummary >
                          </Accordion >
                          <Accordion disabled={true} className="status-code-accordation" sx={{ borderLeftColor: "#68E497" }}>
                            <AccordionSummary >
                              <p className="status-code-title">Busy</p>
                            </AccordionSummary>
                          </Accordion>
                          <Accordion disabled={true} className="status-code-accordation" sx={{ borderLeftColor: "#FCB031" }}>
                            <AccordionSummary >
                              <p className="status-code-title">Handling</p>
                            </AccordionSummary>
                          </Accordion>
                          <Accordion disabled={true} className="status-code-accordation" sx={{ borderLeftColor: "#671F34" }}>
                            <AccordionSummary >
                              <p className="status-code-title">Wrapup</p>
                            </AccordionSummary>
                          </Accordion>
                        </div>
                        : (<NoDataAvailableMessage cssClass={"no-data-available-status-codes"} />)}
                    </Paper>
                  }
                </Grid>
              </Grid>
              {dialogOpen &&
                <AgentMonitorMainPage
                  handleCloseAgentMonitoring={handleCloseAgentMonitoring}
                  webRtcConnection={webRtcConnection}
                  setWebRtcConnection={setWebRtcConnection}
                  searchValue={searchValueOfAgents}
                  lastInsertedId={lastInsertedId}
                  setlastInsertedId={setlastInsertedId}
                  isDisconnected={isDisconnected}
                  setIsDisconnected={setIsDisconnected}
                  setDialogOpen={setDialogOpen}
                  setSearchValue={setSearchValueOfAgents}
                  isAgentMonitorError={isAgentMonitorError}
                  setIsAgentMonitorError={setIsAgentMonitorError}
                  agents={agents} setAgents={setAgents}
                  selectedAgent={selectedAgentForMonitoring}
                  setSelectedAgent={setSelectedAgentForMonitoring}
                />
              }
            </Box>
          </div>
        </div>
      </div >
    </div >
  );
}
export default withRouter(ScoreDashboard);
