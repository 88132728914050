import { Box, Typography } from "@mui/material";
import CustomTextField from "../../../../../components/CustomTextField";
import LoadingButton from "../../../../../components/LoadingButton";


export default function VonigoCustomerSearchSection(props) {
    const { isDissabled, existingJob, searchPar, selectedJobToEdit, onSearchParChanged, isSearchResultCutomersLoading, onSearch } = props

    return <Box sx={{ display: 'flex', flexDirection: "row", width: "100%", height: "3em", justifyContent: "left", alignItems: "center" }}>
        <label style={{ marginRight: "auto" }} ><Typography variant="subtitle1" >Customer Search Information : </Typography></label>
        <Box sx={{ display: 'flex', flexDirection: "row", width: "65%", height: "3em", justifyContent: "right", alignItems: "center", gap: "1em" }}>
            <CustomTextField style={{ width: "100%" }} disabled={existingJob || selectedJobToEdit} label="Search Customer" type="search" size="small" value={searchPar ?? ""} onChange={onSearchParChanged} />
            <LoadingButton sx={{ backgroundColor: 'background.dialer', textTransform: "none", "&:hover": { backgroundColor: 'background.dialer', } }} variant="contained" loading={isSearchResultCutomersLoading} disabled={isSearchButtonDissabled(isDissabled, searchPar) || selectedJobToEdit} onClick={onSearch}>Search</LoadingButton>
        </Box>
    </Box>
}

function isSearchButtonDissabled(isDissabled, searchPar) {
    const shouldDissabled = (isDissabled || (searchPar?.length < 3))
    return shouldDissabled;
}
