
import { Box, Autocomplete } from "@mui/material";
import CustomTextField from "../../../../components/CustomTextField";
import { isFranchiseDisabled, sortFranchisesByActiveState } from "../../../../utils/miscellaneous";
import { renderBrandOption } from "../../../../components/servicetitan/RenderInput";
import { JOB_STATUS, JOB_TABS } from "../../../../constants/common";
import iBSBrandLogo from "../../../../assets/img/brand.png"
import CustomDatePicker from "../../../../components/CustomDatePicker";
import { getFranchiseOptionLabel, renderFranchiseOptionWithStatusAndEncryptionBadge } from "../../utils/franchise";
import ImageBox from "../../../../components/LogoBox/ImageBox";

export default function AdvanceFilterArea(props) {
    const { brands, brand, setBrand, isBrandsLoading, franchises, franchise, setFranchise, isFranchisesLoading, filterState, setFilterState, tab } = props

    return <Box className="st-filter-area" style={{ display: "flex", flexDirection: "row", gap: "2em", marginBlock: "2em" }}>
        <Box sx={{ maxWidth: "12em" }}>
            <h4>Filter Your Results Here</h4>
        </Box>
        <Box sx={{ marginLeft: "auto", marginTop: "auto", marginBottom: "auto" }}>
            <ImageBox alt="brand-logo" src={brand?.brand_img ?? iBSBrandLogo} size="medium" />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", flexGrow: 2, gap: "1em", flexWrap: "wrap", justifyContent: "end" }}>
            <div style={{ width: "20%", zIndex: 4 }} >
                <CustomDatePicker
                    maxTime={new Date(filterState.createdEnd)}
                    label={tab === JOB_TABS.JOBS ? "Created After" : "Updated After"}
                    value={new Date(filterState.createdStart)}
                    onChange={(date) => {
                        if (date) {
                            setFilterState({ ...filterState, createdStart: date?.toISOString?.() })
                        }
                    }
                    }
                    dataTest={"job_landing_page_start_date_selector"}
                    renderInput={renderFilterDateInput}
                />
            </div>
            <div style={{ width: "20%", zIndex: 3 }} >
                <CustomDatePicker
                    minTime={new Date(filterState.createdStart)}
                    label={tab === JOB_TABS.JOBS ? "Created Before" : "Updated Before"}
                    value={new Date(filterState.createdEnd)}
                    onChange={(date) => {
                        if (date) {
                            setFilterState({ ...filterState, createdEnd: date?.toISOString?.() })
                        }
                    }}
                    dataTest={"job_landing_page_end_date_selector"}
                    renderInput={renderFilterDateInput}
                />
            </div>
            {(franchise?.service_brand === "ServiceTitan" && tab === JOB_TABS.JOBS) &&
                <Autocomplete
                    sx={{ minWidth: "200px" }}
                    size="small"
                    noOptionsText={"No Job Statuses"}
                    data-test="job_landing_page_status_selector"
                    disabled={JOB_STATUS === []}
                    disablePortal
                    disableClearable
                    options={JOB_STATUS}
                    getOptionLabel={(jobStatus) => jobStatus}
                    value={filterState.jobStatus ?? null}
                    onChange={(_, Status) => {
                        setFilterState({ ...filterState, jobStatus: Status });
                    }}
                    renderInput={(params) => <CustomTextField
                        {...params}
                        inputProps={{
                            ...params.inputProps,
                            'data-test': 'job_landing_page_job-status'
                        }}
                        label="Job Status" />}
                />
            }
            <Autocomplete
                sx={{ minWidth: "300px" }}
                size="small"
                data-test="job_landing_page_brand_selector"
                loading={isBrandsLoading}
                noOptionsText={"No brands"}
                disabled={brands === []}
                disablePortal
                disableClearable
                options={brands?.sort((a, b) => a.brand_name.localeCompare(b.brand_name))}
                getOptionLabel={(brand) => brand.brand_name}
                renderOption={renderBrandOption}
                value={brand ?? null}
                onChange={(_, brand) => {
                    setBrand(brand);
                    setFilterState({ ...filterState, brandId: brand.brand_id });
                }}
                renderInput={(params) => <CustomTextField
                    {...params}
                    inputProps={{
                        ...params.inputProps,
                        'data-test': 'job_landing_page_brand'
                    }}
                    label="Brand"
                />}
            />
            <Autocomplete
                sx={{ minWidth: "200px", maxWidth: "300px", flexGrow: 2 }}
                size="small"
                disabled={franchises === []}
                data-test="job_landing_page_franchise_selector"
                disablePortal
                loading={isFranchisesLoading}
                noOptionsText={"No franchises"}
                disableClearable
                options={sortFranchisesByActiveState(franchises)}
                getOptionLabel={(franchise) => getFranchiseOptionLabel(franchise)}
                getOptionDisabled={(franchise) => {
                    return isFranchiseDisabled(franchise);
                }}
                renderOption={renderFranchiseOptionWithStatusAndEncryptionBadge}
                value={franchise ?? null}
                onChange={(_, franchise) => {
                    setFranchise(franchise)
                    setFilterState({ ...filterState, franchiseId: franchise.id });
                }}
                renderInput={(params) => <CustomTextField
                    {...params}
                    inputProps={{
                        ...params.inputProps,
                        'data-test': 'job_landing_page_franchise'
                    }}
                    label="Franchise"
                />}
            />
        </Box>
    </Box>
}

function renderFilterDateInput(params) {
    return <CustomTextField
        style={{ width: "225px", }}
        size={"small"}
        {...params}
        error={params.InputProps.error}
        helperText={params.InputProps.helperText}
    />
}