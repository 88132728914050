import { Box, Button } from "@mui/material";
import { useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import TasksSection from "./component/TasksSection";

const SCHEDULE_SECTIONS = {
    TASKS: {
        index: 0,
        section: "tasks",
        title: "Tasks",
    }
}

function SchedulerLandingPage(props) {
    const initialSection = useMemo(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const section = urlParams.get('section');
        return Object.values(SCHEDULE_SECTIONS).find((v) => v.section === section)
    }, [])

    const [selectedSection, setSelectedSection] = useState(initialSection ? initialSection : SCHEDULE_SECTIONS.TASKS)

    function getSection() {
        switch (selectedSection.section) {
            case SCHEDULE_SECTIONS.TASKS.section:
                return <TasksSection />
            default:
                return <></>
        }
    }

    return <Box sx={{ width: "100%", padding: "0.5em", flexGrow: 2, display: "flex", flexDirection: "column" }}>
        <Box width={"100%"} className={"tabs"}>
            {Object.values(SCHEDULE_SECTIONS).map((tab) => {
                return <Button
                    key={tab.index}
                    onClick={() => setSelectedSection(tab)}
                    size={"small"}
                    variant="contained"
                    className={tab === selectedSection ? "btn-primary tab" : "btn-secondary tab"}
                >{tab.title}</Button>
            })}
        </Box>
        <Box sx={{ width: "100%", flexGrow: 2 }} >{getSection()}</Box>
    </Box>
}

export default withRouter(SchedulerLandingPage);
