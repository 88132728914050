import './App.css';
import Main from './router/routes';
import { useState, useContext, useEffect } from "react"
import app from "./firebase"
import { getMessaging, getToken } from 'firebase/messaging';
import AppNotification from './components/Notification';
import { NOTIFICATION_TYPES } from './constants/common';
import "react-datepicker/dist/react-datepicker.css";
import UserContextProvider from './contexts/User';
import { sttSocket } from './SocketIo';
import { SttSocketContext } from './contexts/SttSocket';
import { getFromSessionStorage, setSessionStorage } from './utils/browserStorage';
import { SESSION_STORAGE_KEYS } from './constants/browserStorage';
import { CommonContext } from './contexts/CommonContextProvider';
import ThemeContextProvider from './contexts/ThemeContextProvider';

function App() {

  const { notify, setNotify, showNotification } = useContext(CommonContext)

  const [AgentCurrentRecognition, setAgentCurrentRecognition] = useState("");
  const [CustomerCurrentRecognition, setCustomerCurrentRecognition] = useState("");
  const [recognitionHistory, setRecognitionHistory] = useState([]);
  const [faqs, setFaqs] = useState([])

  useEffect(() => {
    connectToSTTSocket()
    const messaging = getMessaging(app);
    getToken(messaging).then(token => {
      localStorage.setItem("fcm_token", token)
    }).catch(err => { })

    return () => {
      sttSocket.off('connect', (data) => setSessionStorage(SESSION_STORAGE_KEYS.Question_keys, JSON.stringify([])));
      sttSocket.off('receive_audio_text', (data) => speechRecognized(data));
      sttSocket.off('receive_faq', (data) => onRecieveFaq(data));
    };
  }, [])

  const connectToSTTSocket = () => { // Speech to text socket
    sttSocket.on("connect", () => {
      const roomName = sessionStorage.getItem(SESSION_STORAGE_KEYS.interactionGuid)
      sttSocket.emit("join_room", roomName)
      sttSocket.on("receive_audio_text", (data) => {
        speechRecognized(data)
      });
    })
    sttSocket.on("receive_faq", (data) => {
      onRecieveFaq(data)
    })
  }

  const onRecieveFaq = (data) => {
    if (data?.faqs?.length > 0) {
      setFaqs((old) => {
        const newFaqs = [...data?.faqs, ...old];
        const uniqueFaqs = Array.from(new Map(newFaqs.map((faq) => [faq.answer, faq])).values());
        const existingFaqs = JSON.parse(getFromSessionStorage(SESSION_STORAGE_KEYS.FAQ_temp) || "[]");
        const updatedFaqs = [...existingFaqs, ...uniqueFaqs];
        try {
          setSessionStorage(SESSION_STORAGE_KEYS.FAQ_temp, JSON.stringify(updatedFaqs));
        } catch (error) { }
        return uniqueFaqs;
      })
    }
  }

  const speechRecognized = (data) => {
    if (data.isFinal) {
      setAgentCurrentRecognition("");
      setCustomerCurrentRecognition("");
      let message = {
        agent: data.text.agent,
        customer: data.text.customer,
        on: new Date().getTime(),
      }
      setRecognitionHistory((old) => {
        let mergedFaqs = [message, ...old];
        // set customer's text as question keys and pass to BE when the connect button is clicked
        let customerQuestionKeys = [];
        if (data.text.customer.length > 0) {
          customerQuestionKeys = mergedFaqs.map((m) => m.customer.split(" "));
        }
        let agentQuestionKeys = [];
        if (data.text.agent.length > 0) {
          agentQuestionKeys = mergedFaqs.map((m) => m.agent.split(" "));
        }
        // set session storage manual chats save to BE when the connect button is clicked
        let manualChats = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.ManualChats));
        let manualChatsQuestionKeys = [];

        if (manualChats?.length > 0) {
          manualChatsQuestionKeys = manualChats.flatMap((m) => m.text.split(" "));
        }


        // Retrieve existing question keys from the session storage if any
        const existingQuestionKeys = JSON.parse(getFromSessionStorage(SESSION_STORAGE_KEYS.Question_keys) || "[]");

        // Merge the new question keys with the existing ones
        const updatedQuestionKeys = [...existingQuestionKeys, ...customerQuestionKeys, ...agentQuestionKeys, ...manualChatsQuestionKeys];
        // Save the updated question keys to session storage
        try {
          setSessionStorage(SESSION_STORAGE_KEYS.Question_keys, JSON.stringify(updatedQuestionKeys.flat(1)));
        } catch (error) { }
        return mergedFaqs;
      });

    } else {
      setAgentCurrentRecognition(data.text.agent);
      setCustomerCurrentRecognition(data.text.customer);
    }
  };

  document.addEventListener("JIRASUPPOTER-V1-SUPPORT-ALERT-EVENT", (e) => {
    let message = ""
    let type = ""
    switch (e.detail.type) {
      case "SUCCESS": message = "Successfully submitted your response"; type = NOTIFICATION_TYPES.SUCCESS; break;
      case "WARNING": message = "Something went wrong. Please try again"; type = NOTIFICATION_TYPES.ERROR; break;
      case "INFO": break;
      default: message = "Successfully submitted your response"; type = NOTIFICATION_TYPES.SUCCESS; break;
    }
    showNotification({ type: type, message: message, isOpen: true })
  })

  return (
    <UserContextProvider>
      <div className="App">
        <ThemeContextProvider>
          <SttSocketContext.Provider value={{ AgentCurrentRecognition, CustomerCurrentRecognition, recognitionHistory, faqs, setFaqs }}>
            <Main />
          </SttSocketContext.Provider>
          <div id="JIRASUPPOTER-V1-SUPPORT-ALERT_CUSTOM" >
            <AppNotification notify={notify} setNotify={setNotify} />
          </div>
        </ThemeContextProvider>
      </div>
    </UserContextProvider>
  );
}

export default App;