import { searchAudits, getMostRecentFranchiseAuditByGivenAudit, getAuditOptions,getAllBrandFranchises,getFranchiseById } from "../../services/audit/audit"
import {sortFranchisesByActiveState} from "../../utils/miscellaneous"
import { getAnUser } from "../../services/userService";

export async function handleSearchAllAudits(setAudits, setLoading, setAuditCount, filterState, allUsers, franchises) {
    try {
        const res = await searchAudits(filterState)
        if (Array.isArray(res.audits)) {
            const modifiedArray = await Promise.all(res.audits.map(async (element) => {
                let adminDetails = {}
                const isAdminPresent = allUsers.filter((singleUser) => singleUser.user_id === element.admin_id)[0]

                var franchiseDetails=[]

                if(element.payload?.franchise){
                    franchiseDetails = franchises.filter((singleFranchise) => singleFranchise.id === element.payload?.franchise?.id)[0]
                }else if(element.payload?.id){
                    franchiseDetails = franchises.filter((singleFranchise) => singleFranchise.id === element.payload?.id)[0]
                }

                
                //if admin is diactivated or removed get the admin details from the user service
                if(!isAdminPresent) {
                     await getAnUser(element.admin_id).then((res) => {
                       if(res){
                        adminDetails.first_name= res.first_name ?? ""
                        adminDetails.last_name= res.last_name ?? ""
                       }
                    }).catch(err => {
                        adminDetails = isAdminPresent
                    })
                }else{
                    adminDetails = isAdminPresent
                }

                return { ...element, admin_name: adminDetails? `${adminDetails?.first_name} ${adminDetails?.last_name}` : "N/A", current_franchise_name: franchiseDetails?.franchise_name };
            })) 

            setAudits(modifiedArray)
            setAuditCount(res.count)
        } else {
            setAudits([])
        }
    } catch (err) {
        setAudits([])
    } finally {
        setLoading(false)
    }
}

export async function handleSetAllBrands(setAllBrands, setIsBrandsLoading, brands) {
    try {
        setAllBrands(brands)
    } catch (err) {
        setAllBrands([])
    } finally {
        setIsBrandsLoading(false)
    }
}

export async function handleSetAllFranchises(setAllFranchises, setIsFranchisesLoading, franchises) {
    try {
        setAllFranchises(franchises)
    } catch (err) {
        setAllFranchises([])
    } finally {
        setIsFranchisesLoading(false)
    }
}

export function handleGetInitialFilterStatus(auditType = "Franchise", auditOption,setFilterState, setUser, setFranchise, setBrand) {
    const currentDate = new Date();
    const dateOneMonthBeforeToday = new Date();
    // Subtract one month
    dateOneMonthBeforeToday.setMonth(currentDate.getMonth() - 1);
    // Set the time to 11:59 PM
    dateOneMonthBeforeToday.setHours(0, 0, 0, 0);

    setFilterState({ page: 1, size: 10, audit_type: auditType,audit_option:auditOption, created_after: dateOneMonthBeforeToday.getTime(), created_before: currentDate.getTime(), })
    setUser()
    setFranchise()
    setBrand()
}

export function handleShowAudit(audit, setShowingFranchise, setIsShowFranchise) {
    setShowingFranchise(audit);
    setIsShowFranchise(true)
}

export async function handleGetAuditOptions(auditType, setAuditOptions,setIsAuditOptionsLoading) {

    try {
        const res = await getAuditOptions(auditType)
        if (Array.isArray(res) && res.length > 0) {
            setAuditOptions(res)
        } else {
            setAuditOptions([])
        }
    } catch (err) {
        setAuditOptions([])
    } finally {
        setIsAuditOptionsLoading(false)
    }

}

export async function handleSelectBrand(brandId, setAllFranchises, setFranchise) {
    await getAllBrandFranchises(brandId).then(franchises => {
        if (Array.isArray(franchises.franchises)) {
            const sortedFranchises = sortFranchisesByActiveState(franchises.franchises) 
            setAllFranchises(sortedFranchises)
        }
    }).catch(err => {
        setAllFranchises([])
    }).finally(() => {
        setFranchise()
    });    
}

export function getAdminUsers (allUsers,setAdminUsers){
    const newAdminUsers = allUsers.filter((singleUser) => singleUser.level >=80)
    setAdminUsers(newAdminUsers)
}

// services for show franchise audit details model

export async function handleGetMostRecentFranchiseAuditByGivenAudit(franchiseId,auditOption, createdBefore, setRecentFranchise, setIsLoading) {
    try {
        const filter = {
            franchise_id: franchiseId,
            audit_option:auditOption,
            created_before: createdBefore
        }
        const res = await getMostRecentFranchiseAuditByGivenAudit(filter)
        if (Object.keys(res).length !== 0) {
            setRecentFranchise(res)
        } else {
            setRecentFranchise()
        }
    } catch (err) {
        setRecentFranchise()
    } finally {
        setIsLoading(false)
    }
}

export function isValueChanged(recentFranchiseValue, currentFranchiseValue) {
    if (recentFranchiseValue !== currentFranchiseValue) {
        return true;
    }
    return false;
}

export function isArraysChanged(jsonArray1, jsonArray2) {
    if (!jsonArray1 && !jsonArray2) {
        return false;
    } else if (!jsonArray1 || !jsonArray2) {
        return true
    } else if (jsonArray1.length == 0 && jsonArray2.length == 0) {
        return false;
    } else if (jsonArray1.length !== jsonArray2.length) {
        return true;
    } else {
        var jsonString1 = JSON.stringify(
            jsonArray1.map((obj) => JSON.stringify(obj)).sort()
        );
        var jsonString2 = JSON.stringify(
            jsonArray2.map((obj) => JSON.stringify(obj)).sort()
        );

        // Compare sorted JSON strings
        return jsonString1 !== jsonString2;
    }
}

export function getValue(recentFranchiseValue, currentFranchiseValue) {
    if (recentFranchiseValue !== currentFranchiseValue) {
        return `${recentFranchiseValue} --> ${currentFranchiseValue}`;
    } else {
        return currentFranchiseValue;
    }
}

export function changeDisplayIndex(index,displayIndex, setDisplayIndex) {
    if (index !== displayIndex) {
        setDisplayIndex(index);
    }else{
        setDisplayIndex(0);
    }
    
}

export function checkShouldHideValue(key,value){
    if(key==="service_titan_client_id" || key==="service_titan_client_secret"){
        return '*'.repeat(value.length)
    }
    return value
}

// get franchise details by franchise id and set state
export async function getFranchiseByFranchiseId(franchiseId,franchiseDetails,setShowingFranchiseDetails,setIsLoading){
    try{

        const latestshowingFranchiseDetails = await getFranchiseById(franchiseId)
        setShowingFranchiseDetails({...franchiseDetails, payload: latestshowingFranchiseDetails})
        
    }catch(err){
        
    }finally {
        setIsLoading(false)
    }
}