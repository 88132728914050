import "../styles/scoreDashboardStyles.css"

export function timeCard(time) {
    if (time) {
        let timeArray = time?.split(":")
        let hours = timeArray[0]
        let minutes = timeArray[1]
        let seconds = timeArray[2]
        return <div style={{ display: "flex", flexDirection: "row", marginLeft: "auto", height: "22px", gap: "0.2em" }}>
            <div className="time-card"><p><string>{hours}</string></p></div>
            <p><strong>:</strong></p>
            <div className="time-card"><p><string>{minutes}</string></p></div>
            <p><strong>:</strong></p>
            <div className="time-card"><p><string>{seconds}</string></p></div>
        </div>
    } else {
        return <></>
    }
}