import { Box, CircularProgress, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import RefreshErrorView from "../../../../../components/refreshView/RefreshErrorView"
import AppointmentCard from "../../serviceTitanJobs/components/AppointmentCard"
import { handleGetappointments } from "../../../../../state-services-dublicate/serviceTitan"

export default function AppointmentsSection(props) {
    const { franchise, job, customerId ,toggleGetAppointments, setToggleGetAppointments, setPreviousAppointmentForRescheduleJob, setjobForTheAppointment, setisOpenAppointmentRescheduleModal, shouldAllowCancelAndReschedule } = props
    const [isAppointmentsLoadingError, setIsAppointmentsLoadingError] = useState(false)
    const [isAppointmentsLoading, setIsAppointmentsLoading] = useState(false)
    const [appointments, setAppointments] = useState([])

    useEffect(() => {
        handleGetappointments(franchise, job?.id, customerId, setAppointments, setIsAppointmentsLoading, setIsAppointmentsLoadingError)
    }, [franchise, job?.id, customerId, toggleGetAppointments])

    return franchise?.id && (job?.id || customerId) ? <Box marginTop="0.5em" width="100%" >
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
            <Typography component="h5" fontWeight="bold" >Appointments</Typography>
        </Box>
        {isAppointmentsLoadingError ? <RefreshErrorView onRefresh={() => setToggleGetAppointments(!toggleGetAppointments)} /> : <></>}
        {isAppointmentsLoading ? <Box width="100%" sx={{ height: "2em" }}><CircularProgress sx={{ color: "grey" }} size={20} /></Box> : appointments.map((a, i) => <AppointmentCard index={i} job={job} total={appointments?.length} appointment={a} franchise={franchise} 
            setPreviousAppointmentForRescheduleJob={setPreviousAppointmentForRescheduleJob} setjobForTheAppointment={setjobForTheAppointment}  setisOpenAppointmentRescheduleModal={setisOpenAppointmentRescheduleModal} shouldAllowCancelAndReschedule={shouldAllowCancelAndReschedule} />)}
        </Box >
        :
        <></>
}