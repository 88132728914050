import React, { useContext, useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import { OtherRoutes, DashboardRoutes } from "./index"

// layout Components
import TopBar from '../components/TopBar';
import TopMenu from '../components/TopMenu';
import NotFound from '../pages/NotFound';
import { Box } from '@mui/material';
import RightDock from '../components/RightDock/RightDock';
import TaskAlertPopUp from '../pages/dashboard/schedule/component/TaskAlertPopUp';
import { UserContext } from '../contexts/User';


function DashboardLayout(props) {
    useEffect(() => {
        document.title = props.name;
    });

    return (
        <Box style={{ height: "100vh", width: "100vw", display: "flex", flexDirection: "column" }}>
            <TopMenu />
            <Box sx={{ flexGrow: 2, display: "flex", flexDirection: "column", overflowY: "auto" }}>
                {props.children}
            </Box>
        </Box>
    )
}

function OtherLayout(props) {

    const isRoot = window.location.pathname === "/"

    useEffect(() => {
        document.title = props.name;
    });

    return <>
        {!isRoot && <RightDock />}
        {!isRoot && <TaskAlertPopUp />}
        <props.children />
    </>

}

/**
 * 
 * @returns Dashboard pages
 */


function Dashboard() {
    const isRoot = window.location.pathname === "/"
    const { me } = useContext(UserContext)
    return <>
        <TopBar />
        {!isRoot && <RightDock />}
        {!isRoot && <TaskAlertPopUp />}
        {me?.user_id ? DashboardRoutes.map((route, index) => (
            <Route
                key={index}
                path={route.path}
                exact
                render={(props) => (
                    <DashboardLayout name={route.name}>
                        <route.component {...props} />
                    </DashboardLayout>
                )}
            />
        )) : <></>}
    </>
}



/**
 * @returns full screen routes
 */
function Other() {
    return (
        OtherRoutes.map((route, index) => (
            <Route
                key={index}
                path={route.path}
                exact
                render={(props) => <OtherLayout name={route?.name}>{route.component}</OtherLayout>}
            />
        ))
    )
}


/**
 * 
 * @returns Main Router
 */
function Main() {
    return (
        <Router>
            <Switch>
                {Other()}
                {Dashboard()}
                {/* <Dashboard /> */}
                <Route
                    path={"*"}
                    exact
                    component={NotFound}
                />
            </Switch>
        </Router>
    )
}

export default Main