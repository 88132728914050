import { NOTIFICATION_TYPES } from "../constants/common";
import { getCountryValueFromVonigoCountryFieldValue } from "../pages/dashboard/jobs/create-job-page/vonigo/VonigoMainSection";
import { deleteVonigoJobDraft, editClientNotes, getCampaigns, getCancelReasons, getClientJobs, getClientWorkOrders, getContacts, getCustomers, getDurationForZip, getIndustries, getJobLables, getJobs, getLocations, getSingleContactDetails, getSingleCustomerDetails, getSingleLocationDetails, getVonigoJobDrafts, removeLock,getSingleJobDetails } from "../services/vonigo/jobScheduling";
import { compareTwoRFCDateStringsDecendingOrder, compareTwoStringsInAssendingOrder } from "../utils/compareFunctions";

export function handleVonigoGetJobs(redirectUrl, franchiseId, createdStart, createdEnd, page, pageSize, setJobs, setIsJobsLoading, setIsJobsLoadingError) {
    if (franchiseId && createdStart && createdEnd) {
        setIsJobsLoading?.(true);
        setIsJobsLoadingError?.(false);
        setJobs([]);
        getJobs(redirectUrl, franchiseId, createdStart, createdEnd, "true", page, pageSize).then((jobsResponse) => {
            if (Array.isArray(jobsResponse.Job)) {
                setJobs?.(jobsResponse.Job);
            }
        }).catch(() => {
            setIsJobsLoadingError(true);
        }).finally(() => setIsJobsLoading?.(false));
    }
}

export async function handleSearchCustomers(brand, franchise, searchPar = "", setIsSearchResultCutomersLoading, setSelectedCustomer, setExistingCustomers, setContact, setContacts, setLocation, setLocations, customerSearchCount, setCustomerSearchCount, setEditContact, setEditLocation, setIsEditContact, setIsEditLocation) {
    try {
        setIsEditContact(false)
        setIsEditLocation(false)
        if (brand && franchise && brand?.redirect_url && franchise?.franchise_id && searchPar) {
            setIsSearchResultCutomersLoading(true);
            setSelectedCustomer()
            setExistingCustomers([]);
            setContact();
            setEditContact();
            setContacts([]);
            setLocation();
            setEditLocation();
            setLocations([]);
            const searchParWithoutSymbols = searchPar.replace(/[^a-zA-Z0-9]/g, '');
            const searchParOnlyNumbers = searchParWithoutSymbols.replace(/x/i, '');
            const searchParForAPIWithSpaces = /^\d+$/.test(searchParOnlyNumbers) ? searchParWithoutSymbols : searchPar.replace(/[$%\-]/gi, "")
            const searchParForAPI = searchParForAPIWithSpaces?.trim()
            const response = await getCustomers(brand?.redirect_url, franchise.franchise_id, searchParForAPI)
            if (Array.isArray(response?.Clients)) {
                response?.Clients?.sort?.((a, b) => compareTwoRFCDateStringsDecendingOrder(a?.dateLastEdited, b?.dateLastEdited))
                setExistingCustomers(response?.Clients);
            }
        }
    } catch {
    } finally {
        setIsSearchResultCutomersLoading(false);
        setCustomerSearchCount(customerSearchCount + 1)
    }
}

export async function handleGetSingleContactDetails(brand, franchise, selectedContact, setSelectedContactDetails, setEditContact) {
    try {
        const response = await getSingleContactDetails(brand?.redirect_url, franchise?.franchise_id, selectedContact?.objectID)
        const contact = {
            email: response?.email?.fieldValue,
            firstName: response?.firstName?.fieldValue,
            lastName: response?.lastName?.fieldValue,
            phone1: response?.phone1?.fieldValue,
            phone2: response?.phone2?.fieldValue,
            phone3: response?.phone3?.fieldValue,
        }
        setSelectedContactDetails(contact)
        setEditContact({
            email: response?.email?.fieldValue,
            firstName: response?.firstName?.fieldValue,
            lastName: response?.lastName?.fieldValue,
            phone1: response?.phone1?.fieldValue?.substr(0, 10),
            ext1: response?.phone1?.fieldValue?.substr(11),
            phone2: response?.phone2?.fieldValue?.substr(0, 10),
            ext2: response?.phone2?.fieldValue?.substr(11),
            phone3: response?.phone3?.fieldValue?.substr(0, 10),
            ext3: response?.phone3?.fieldValue?.substr(11)
        })
    } catch { }
}

export async function handleGetWorkOrdersOfClient(brand, franchise, customer, setCustomerWorkOrders) {
    try {
        const response = await getClientWorkOrders(brand?.redirect_url, franchise?.franchise_id, customer?.objectID)
        if (Array.isArray(response?.WorkOrder)) {
            response?.WorkOrder?.sort((a, b) => a.dateService - b.dateService)
            setCustomerWorkOrders(response?.WorkOrder)
        }
    } catch (error) { }
}

export async function handleGetJobsOfClient(brand, franchise, customer, setCustomerJobs) {
    try {
        const response = await getClientJobs(brand?.redirect_url, franchise?.franchise_id, customer?.objectID)
        if (Array.isArray(response?.Job)) {
            getCancelledJobs(response?.Job)
        }
    } catch (error) { }
}

function getCancelledJobs(jobs, setCustomerJobs) {
    let cancelledJobs = []
    jobs?.forEach((job) => {
        if (job?.jobStatus?.name === "Cancelled") {
            cancelledJobs.push(job)
        }
    })
    setCustomerJobs(cancelledJobs)
}

export async function handleEditCustomerNotes(editClientNoteRequest) {
    try {
        const response = await editClientNotes(editClientNoteRequest)
        return response
    } catch (error) { }
}

export async function handleGetJobLables(brand, franchise, setIsJobLablesLoading, setJobLables) {
    try {
        if (brand && franchise && brand?.redirect_url && franchise?.franchise_id) {
            setIsJobLablesLoading(true);
            const response = await getJobLables(brand?.redirect_url, franchise?.franchise_id)
            if (Array.isArray(response?.Options)) {
                response?.Options?.sort((a, b) => compareTwoStringsInAssendingOrder(a?.name, b?.name))
                setJobLables(response?.Options);
            }
        }
    } catch {
    } finally {
        setIsJobLablesLoading(false)
    }
}

export async function handleGetIndustries(brand, franchise, setIsIndustriesLoading, setIndustries) {
    try {
        if (brand && franchise && brand?.redirect_url && franchise?.franchise_id) {
            setIsIndustriesLoading(true)
            const industriesResponse = await getIndustries(brand?.redirect_url, franchise?.franchise_id)
            if (Array.isArray(industriesResponse.Options)) {
                industriesResponse?.Options?.sort((a, b) => compareTwoStringsInAssendingOrder(a?.name, b?.name))
                setIndustries(industriesResponse?.Options)
            }
        }
    } catch {
    } finally {
        setIsIndustriesLoading(false)
    }
}

export async function handleGetCancelReasons(brand, franchise, setIsReasonsLoading, setReasons) {
    try {
        if (brand && franchise && brand?.redirect_url && franchise?.franchise_id) {
            setIsReasonsLoading(true)
            const reasonsResponse = await getCancelReasons(brand?.redirect_url, franchise?.franchise_id)
            if (Array.isArray(reasonsResponse.Options)) {
                reasonsResponse?.Options?.sort((a, b) => compareTwoStringsInAssendingOrder(a?.name, b?.name))
                setReasons(reasonsResponse?.Options)
            }
        }
    } catch {
    } finally {
        setIsReasonsLoading(false)
    }
}

export async function handleGetCompaigns(brand, franchise, setIsCampaignsLoading, setCampaigns) {
    try {
        setIsCampaignsLoading(true)
        const campaignResponse = await getCampaigns(brand?.redirect_url, franchise?.franchise_id)
        if (Array.isArray(campaignResponse.Campaigns)) {
            campaignResponse?.Campaigns?.sort((a, b) => compareTwoStringsInAssendingOrder(a?.campaign, b?.campaign))
            const activeCampaigns = campaignResponse.Campaigns.filter((c) => c?.isActive)
            setCampaigns(activeCampaigns)
        }
    } catch {
    } finally {
        setIsCampaignsLoading(false)
    }
}

export async function handleGetSingleCustomerDetails(brand, franchise, selectedJobToView, setcustomerDetailsToview) {
    try {
        let customerDetails = await getSingleCustomerDetails(brand?.redirect_url, franchise?.franchise_id, selectedJobToView?.client?.objectID)
        setcustomerDetailsToview(customerDetails)
    } catch (error) { }
}

export async function handleGetLocations(brand, franchise, selectedCustomer, setSelectedLocation, setLocations, setIsLocationsLoading) {
    try {
        if (brand && franchise && brand?.redirect_url && franchise?.franchise_id && selectedCustomer?.objectID) {
            setLocations([])
            setIsLocationsLoading(true)
            const response = await getLocations(brand?.redirect_url, franchise?.franchise_id, selectedCustomer?.objectID)
            if (Array.isArray(response?.Location)) {
                response?.Location?.sort?.((a, b) => compareTwoRFCDateStringsDecendingOrder(a?.dateLastEdited, b?.dateLastEdited))
                setLocations(response?.Location)
                if (response?.Location.length === 1) {
                    setSelectedLocation(response?.Location[0])
                }
            }
        }
    } catch {
    } finally {
        setIsLocationsLoading(false)
    }
}

export async function handleGetVonigoJobDrafts(brandId, franchiseId, updatededOnOrAfter, updatededOnOrBefore, page, pageSize, setDraftJobs, setTotalDraftJobsCount, setIsDraftJobsLoading, setIsDraftJobsLoadingError) {
    try {
        setIsDraftJobsLoadingError?.(false)
        setIsDraftJobsLoading?.(true)
        const jobDraftResponse = await getVonigoJobDrafts(brandId, franchiseId, updatededOnOrAfter, updatededOnOrBefore, page, pageSize)
        if (Array.isArray(jobDraftResponse.vonigoJobDrafts)) {
            setDraftJobs?.(jobDraftResponse.vonigoJobDrafts)
        } else {
            setDraftJobs([])
        }
        setTotalDraftJobsCount?.(jobDraftResponse.count ?? 0)
    } catch {
        setIsDraftJobsLoadingError?.(true)
    } finally {
        setIsDraftJobsLoading?.(false)
    }
}

export function handleGetSingleClient(clientId, brand, franchise, setCustomers, setSelectedCustomer) {
    if (clientId) {
        getSingleCustomerDetails(brand?.redirect_url, franchise?.franchise_id, clientId).then((response) => {
            setCustomers([response])
            setSelectedCustomer(response)
        }).catch(err => { })
    }
}

export function handleGetSingleLoction(locationId, brand, franchise, setLocations, setSelectedLocation) {
    if (locationId) {
        getSingleLocationDetails(brand?.redirect_url, franchise?.franchise_id, locationId).then((response) => {
            setLocations([response])
            setSelectedLocation(response)
        }).catch(err => { })
    }
}

export function handleGetSingleLoctionDetails(brand, franchise, selectedLocation, setSelectedLocationDetails, setEditLocation) {
    if (selectedLocation) {
        getSingleLocationDetails(brand?.redirect_url, franchise?.franchise_id, selectedLocation?.objectID).then((response) => {
            setSelectedLocationDetails(response)
            setEditLocation({
                title: response?.title,
                street: response?.street?.fieldValue,
                city: response?.city?.fieldValue,
                zip: response?.zip?.fieldValue,
                countryOptionId: response?.countryOptionId?.optionID,
                stateOptionId: response?.stateOptionId?.optionID,
                state: response?.stateOptionId?.name,
                country: getCountryValueFromVonigoCountryFieldValue(response?.countryOptionId?.fieldValue)
            })

        }).catch(err => { })
    } else {
        setSelectedLocationDetails()
        setEditLocation()
    }
}

export function handleGetSingleContact(contactId, brand, franchise, setContacts, setSelectedContact) {
    if (contactId) {
        getSingleContactDetails(brand?.redirect_url, franchise?.franchise_id, contactId).then((response) => {
            setContacts([response])
            setSelectedContact(response)
        }).catch(err => { })
    }
}

export function handleGetContacts(brand, franchise, selectedCustomer, setSelectedContact, setIsContactsLoading, setContacts) {
    if (brand && franchise && brand?.redirect_url && franchise?.franchise_id && selectedCustomer?.objectID) {
        setIsContactsLoading(true)
        getContacts(brand?.redirect_url, franchise?.franchise_id, selectedCustomer?.objectID).then((response) => {
            if (Array.isArray(response?.Contacts)) {
                response?.Contacts?.sort?.((a, b) => compareTwoRFCDateStringsDecendingOrder(a?.dateLastEdited, b?.dateLastEdited))
                setContacts(response?.Contacts)
                if (response?.Contacts.length === 1) {
                    setSelectedContact(response?.Contacts[0])
                }
            }
        }).catch(() => { }).finally(() => setIsContactsLoading())
    }
}

export async function handleRemoveLock(brand, franchise, workOrder, onWorkOrderChanged) {
    try {
        const removeLockRequest = {
            baseUrl: brand?.redirect_url,
            franchiseId: franchise?.franchise_id,
            lockId: workOrder?.lockId
        };
        await removeLock(removeLockRequest);
        onWorkOrderChanged({ ...workOrder, lockId: undefined });
    } catch { }
}

export async function handleOnDeleteIconClicked(jobDraft, draftJobs = [], setDraftJobs, showNotification) {
    try {
        await deleteVonigoJobDraft(jobDraft?.id);
        const jobsWithoutThisJob = draftJobs?.filter((draft) => draft?.id !== jobDraft?.id);
        setDraftJobs?.(jobsWithoutThisJob)
        showNotification({ message: "Draft Deleted", type: NOTIFICATION_TYPES.SUCCESS })
    } catch (err) {
        showNotification({ message: "Important: Couldn’t delete the draft", type: NOTIFICATION_TYPES.ERROR })
    }
}

export async function handleGetDuration(brand, franchise, zip, setDuration) {
    let data
    let durationForFranchise
    try {
        data = await getDurationForZip(brand?.redirect_url, franchise?.franchise_id, zip)
        durationForFranchise = filterDurationForFranchise(franchise, data)
        setDuration(durationForFranchise)
    } catch (error) { }
}

function filterDurationForFranchise(franchise, data) {
    let duration
    if (data?.ServiceTypes) {
        data?.ServiceTypes?.forEach((serviceType) => {
            if (process.env.REACT_APP_CURRENT_ENV === "production" || process.env.REACT_APP_CURRENT_ENV === "stage") {
                if (serviceType?.franchiseID === franchise?.franchise_id) {
                    duration = serviceType?.duration
                }
            } else {
                if (serviceType?.franchiseID === 490) {
                    duration = serviceType?.duration
                }
            }
        })
    } else {
        duration = data?.ServiceTypes
    }
    return duration
}

const USA_STATES = 9311
const CANEDIAN_STATES = 9312

export function filterStatesByCountry(states, editLocation) {
    let filterStates = []
    if (editLocation?.country) {
        if (editLocation?.country === "United States") {
            filterStates = states.filter((state) => state.fieldID === USA_STATES)
        } else if (editLocation?.country === "Canada") {
            filterStates = states.filter((state) => state.fieldID === CANEDIAN_STATES)
        } else {
            filterStates = states
        }
    } else {
        filterStates = states
    }
    return filterStates?.map?.((c) => c.name)
}

export function validateStateWithCountry(states, location) {
    if (states?.length !== 0 && location?.address?.country) {
        let state = states.find((state) => state?.optionID === location?.stateOptionId)
        if (location?.address?.country === "United States" && state?.fieldID === USA_STATES) {
            return true
        } else if (location?.address?.country === "Canada" && state?.fieldID === CANEDIAN_STATES) {
            return true
        } else {
            return false
        }
    } else {
        return true
    }
}

export function validateStateWithCountryForEditLocation(states, location) {
    if (states && location?.country) {
        let state = states.find((state) => state?.optionID === location?.stateOptionId)
        if (location?.country === "United States" && state?.fieldID === USA_STATES) {
            return true
        } else if (location?.country === "Canada" && state?.fieldID === CANEDIAN_STATES) {
            return true
        } else {
            return false
        }
    } else {
        return true
    }
}

export async function handleGetSingleJobDetails (baseUrl,franchiseId,vonigoJobId,onViewJobPressed){
    try {
        const response = await getSingleJobDetails(baseUrl,franchiseId,vonigoJobId)
        if(response){
            onViewJobPressed(response)
        }
    } catch (error) {
        console.log("error", error );
    }
}