import { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom"
import moment from "moment";
//mui components
import { CircularProgress, Box, IconButton, Tooltip, Pagination } from "@mui/material";

//mui table components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

//icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

//services
import { handleSearchAllAudits, handleSetAllBrands, handleSetAllFranchises, handleGetInitialFilterStatus, handleSelectBrand, handleShowAudit, handleGetAuditOptions, getAdminUsers } from "../../../state-services/audit/audit";
import { handleGetAllFranchisesIncludeRemoved } from "../../../state-services-dublicate/franchiseServices";

//constant
import { ERROR_COMPONENTS, ROW_COUNT_PER_PAGE } from "../../../constants/common";


// components
import AdvanceFilterArea from "./components/AdvanceFilterArea";
import ErrorView from "../../../components/ErrorView";

//Models
import FranchiseDetailsModal from "./model/FranchiseAuditDetails";

import { CommonContext } from "../../../contexts/CommonContextProvider";

const AuditTypeData = [
    { type: "Franchise", value: 1 },
    { type: "User", value: 2 }
]

function Audit() {
    //states
    const [loading, setLoading] = useState(true);
    const [audits, setAudits] = useState([])

    const [brand, setBrand] = useState();
    const [allBrands, setAllBrands] = useState([]);
    const [isBrandsLoading, setIsBrandsLoading] = useState(false);

    const [franchise, setFranchise] = useState();
    const [allFranchises, setAllFranchises] = useState([]);
    const [isFranchisesLoading, setIsFranchisesLoading] = useState(false);

    const [user, setUser] = useState()

    const [auditTypes, setAuditTypes] = useState([])
    const [auditType, setAuditType] = useState({ type: "Franchise", value: 1 })

    const [auditOptions, setAuditOptions] = useState([])
    const [auditOption, setAuditOption] = useState()
    const [isAuditOptionsLoading, setIsAuditOptionsLoading] = useState(true)

    const [filterState, setFilterState] = useState();

    const [currentPage, setCurrentPage] = useState(1)
    const [auditCount, setAuditCount] = useState(0);

    const [showingFranchise, setShowingFranchise] = useState();
    const [isShowFranchise, setIsShowFranchise] = useState(false)

    const [franchises, setFranchises] = useState([])

    const [adminUsers, setAdminUsers] = useState([])

    //context
    const { allUsers, brands } = useContext(CommonContext)

    //functins
    const onPageChangeHandler = (page) => {
        setCurrentPage(page)
        setFilterState({ ...filterState, page: page })
    }

    const handleRowNumber = (i) => {
        if (currentPage === 1) {
            return i + 1
        } else {
            return (currentPage - 1) * ROW_COUNT_PER_PAGE + i + 1
        }
    }

    //effects

    useEffect(() => {
        setAuditTypes(AuditTypeData)
    }, [])

    useEffect(() => {
        if (allUsers.length > 0) {
            getAdminUsers(allUsers, setAdminUsers)
        }
    }, [allUsers])

    useEffect(() => {
        if (franchises.length <= 0) {
            handleGetAllFranchisesIncludeRemoved(setFranchises)
        }
    }, [])

    useEffect(() => {
        if (auditTypes.length > 0 && auditType) {
            handleGetAuditOptions(auditType.type, setAuditOptions, setIsAuditOptionsLoading)
        }
    }, [auditTypes])

    useEffect(() => {
        if (auditType && !filterState) {
            handleGetInitialFilterStatus(auditType.type, auditOption?.audit_option, setFilterState, setUser, setFranchise, setBrand)
        }
    }, [auditType, auditOption])

    useEffect(() => {
        if (allUsers.length > 0 && franchises.length > 0 && filterState) {
            handleSearchAllAudits(setAudits, setLoading, setAuditCount, filterState, allUsers, franchises)
        }

    }, [allUsers, franchises, filterState])

    useEffect(() => {
        if (brands.length > 0) {
            handleSetAllBrands(setAllBrands, setIsBrandsLoading, brands)
        }
    }, [brands])

    useEffect(() => {
        if (franchises.length > 0) {
            handleSetAllFranchises(setAllFranchises, setIsFranchisesLoading, franchises)
        }
    }, [franchises])

    useEffect(() => {
        if (brand) {
            handleSelectBrand(brand.brand_id, setAllFranchises, setFranchise)
        } else {
            setAllFranchises(franchises)
        }
    }, [brand])

    return (
        <Box sx={{ position: "relative", display: "flex", flexDirection: "column", padding: "1em", backgroundColor: 'background.main', backgroundImage: "none" }}>

            <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
                <div>
                    <h2 style={{ margin: "0" }} >Audits</h2>
                </div>

            </Box>

            <AdvanceFilterArea brands={allBrands} brand={brand} setBrand={setBrand} isBrandsLoading={isBrandsLoading} franchises={allFranchises} franchise={franchise} setFranchise={setFranchise} isFranchisesLoading={isFranchisesLoading} filterState={filterState} setFilterState={setFilterState} auditTypes={auditTypes} setAuditType={setAuditType} auditType={auditType} auditOptions={auditOptions} auditOption={auditOption} setAuditOption={setAuditOption} isAuditOptionsLoading={isAuditOptionsLoading} allUsers={adminUsers} user={user} setUser={setUser} />

            <TableContainer style={{ height: "60vh" }}>
                <Table aria-label="simple table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Date & Time</TableCell>
                            <TableCell>Audit Type</TableCell>
                            <TableCell>Admin</TableCell>
                            <TableCell>Franchise Name</TableCell>
                            <TableCell>Action<Tooltip title={"The red dot indicates sections changed"}>
                                <span>
                                    <IconButton color="info">
                                        <InfoOutlinedIcon style={{ height: "20px", width: "20px" }} />
                                    </IconButton>
                                </span>
                            </Tooltip></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRow>
                                <TableCell
                                    colSpan={10}
                                    align="center"
                                    style={{ border: "none", height: "300px", color: "grey" }}
                                >
                                    <CircularProgress size={30} color="inherit" />
                                </TableCell>
                            </TableRow>
                        ) : (
                            audits.length === 0 ?
                                <TableRow>
                                    <TableCell colSpan={11} align="center" style={{ border: "none", height: "300px", color: "grey" }}>
                                        <ErrorView type={ERROR_COMPONENTS.NOT_FOUND} title="No Audit Found" />
                                    </TableCell>
                                </TableRow>
                                :
                                audits?.map((audit, i) => (
                                    <TableRow key={i}>
                                        <TableCell>{handleRowNumber(i)}</TableCell>
                                        <TableCell>{moment(audit.created_at).format("MM/ DD/ YYYY , h:mm:ss A")}</TableCell>
                                        <TableCell>{audit.filter.audit_option.split(' ').filter((word, index) => index > 0).join(' ')}</TableCell>
                                        <TableCell>{audit.admin_name}</TableCell>
                                        <TableCell>{audit.current_franchise_name}</TableCell>
                                        <TableCell>
                                            <Tooltip title={"View Info"}>
                                                <span>
                                                    <IconButton onClick={() => {
                                                        handleShowAudit(audit, setShowingFranchise, setIsShowFranchise)
                                                    }} color="info">
                                                        <VisibilityIcon style={{ height: "20px", width: "20px" }} />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <Box sx={{ marginBottom: '2em' }}>
                <Pagination page={currentPage} onChange={(e, v) => onPageChangeHandler(v)} className="pagination-bar" color="standard"
                    count={Math.ceil(auditCount / ROW_COUNT_PER_PAGE)}
                />
            </Box>

            {isShowFranchise && <FranchiseDetailsModal franchiseDetails={showingFranchise} isShowFranchise={isShowFranchise} setIsShowFranchise={setIsShowFranchise} brands={allBrands} />}

        </Box>
    )

}

export default withRouter(Audit)