import { Autocomplete, Box, Button, Tooltip, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import AddressAutoComplete from "../../../../../components/AddressAutoComplete"
import AddressBox from "../../../../../components/AddressBox"
import CustomTextField from "../../../../../components/CustomTextField"
import RenderInput from "../../../../../components/servicetitan/RenderInput"
import { AVAILABLE_CRMS, NOTIFICATION_TYPES } from "../../../../../constants/common"
import { MAX_FRANCHISE_LOCATION_RADIUS } from "../../../../../constants/location"
import { createLocation, getCountries, getLocations } from "../../../../../services/vonigo/jobScheduling"
import { isValidZipCode } from "../../../../../utils/validators"
import LoadingButton from "../../../../../components/LoadingButton"

export default function CustomerLocationDetails(props) {
    const { isNewCustomer, brand, franchise, selectedCustomer, isNewLocation, setIsNewLocation, location, setLocation, locations, setLocations, onLocationChanged, showNotification } = props
    const [countries, setCountries] = useState([]);
    const [newLocation, setNewLocation] = useState();
    const [isLocationsLoading, setIsLocationsLoading] = useState(false);
    const [isCreateLocationLoading, setIsCreateLocationLoading] = useState(false)

    useEffect(() => {
        if (brand && franchise && brand?.redirect_url && franchise?.franchise_id) {
            getCountries(brand?.redirect_url, franchise?.franchise_id).then((response) => {
                if (Array.isArray(response?.Options)) {
                    setCountries(response?.Options)
                }
            }).catch((err) => { })
        }
    }, [brand, franchise])

    useEffect(() => {
        if (brand && franchise && brand?.redirect_url && franchise?.franchise_id && selectedCustomer?.objectID) {
            setLocations([])
            setIsLocationsLoading(true)
            getLocations(brand?.redirect_url, franchise?.franchise_id, selectedCustomer?.objectID).then((response) => {
                if (Array.isArray(response?.Location)) {
                    setLocations(response?.Location)
                }
            }).catch((err) => {
            }).finally(() => setIsLocationsLoading(false))
        }
        if (selectedCustomer?.objectID) {
            setNewLocation({ ...newLocation, name: selectedCustomer?.name?.fieldValue })
        }
    }, [brand, franchise, selectedCustomer])

    useEffect(() => {
        onLocationChanged(location)
    }, [location])

    function onNewLocationChanged(location) {
        setNewLocation(location)
    }

    function onCreateLocationButtonPressed() {
        const locationCreateRequest = getLocationRequest()
        setIsCreateLocationLoading(true)
        createLocation(locationCreateRequest).then(response => {
            setLocations([response, ...locations]);
            setLocation(response)
            setNewLocation({})
            setIsNewLocation(false)
            showNotification({ message: "Location has been created successfully", type: NOTIFICATION_TYPES.SUCCESS, isOpen: true })
        }).catch(err => {
            showNotification({ message: "Important: Location couldn’t be created at this time. Please try again", type: NOTIFICATION_TYPES.ERROR })
        }).finally(() => {
            setIsCreateLocationLoading(false)
        })
    }

    function getLocationRequest() {
        return {
            baseUrl: brand?.redirect_url,
            franchiseId: franchise?.franchise_id,
            clientId: parseInt(selectedCustomer.objectID, 10),
            title: newLocation?.name,
            street: newLocation?.address?.street,
            city: newLocation?.address?.city,
            zip: newLocation?.address?.zip,
            countryOptionId: newLocation?.countryOptionId,
            provice: newLocation?.address?.state,
            state: newLocation?.address?.state,
        }
    }

    function isAddressSufficient(address) {
        const locationRequest = getLocationRequest()
        return locationRequest?.baseUrl && locationRequest?.franchiseId && locationRequest?.countryOptionId && locationRequest?.title && locationRequest?.street && locationRequest?.city && locationRequest?.zip
    }

    function canCreateLocation() {
        const isZipValid = isValidZipCode(newLocation?.address?.zip, newLocation?.address?.country) && newLocation?.address?.zip?.length > 3
        const isCan = !isNewCustomer && franchise && selectedCustomer?.objectID && newLocation?.name && isAddressSufficient(newLocation?.address) && isZipValid;
        return isCan;
    }

    function handleOnAddressChanged(address) {
        if (isNewLocation) {
            const countryOptionId = countries?.find((c) => c?.name === address?.country)?.optionID
            onNewLocationChanged({ ...newLocation, address: address, countryOptionId: countryOptionId })
        }
    }

    function zipCodeValidator(z, c) {
        return newLocation?.address?.country ? isValidZipCode(z, c) && (z?.length > 3 || !z?.length) : true
    }

    return <>
        <Box sx={{ display: "flex", flexDirection: "column" }} >
            <Box sx={{ display: 'flex', flexDirection: "row", width: "100%", justifyContent: "left", alignItems: "center", marginBlock: "1em" }}>
                <label><Typography variant="subtitle1" >Location</Typography></label>
                {isNewCustomer ? <></> : <Tooltip title={isNewLocation ? "Use existing location" : "Add new location"} >
                    <Button disabled={!selectedCustomer?.objectID} sx={{ color: "#00a79d" }} variant="text"
                        onClick={() => { setIsNewLocation(!isNewLocation) }}>
                        {isNewLocation ? "Existing" : "\u002B New"}
                    </Button>
                </Tooltip>}
                <Box flexGrow={2}></Box>
                {isNewLocation ?
                    <AddressAutoComplete disabled={!isNewLocation}
                        label={"Search Location Address"}
                        onChange={handleOnAddressChanged}
                        lat={franchise?.latitude}
                        long={franchise?.longitude}
                        radius={MAX_FRANCHISE_LOCATION_RADIUS}
                        crm={AVAILABLE_CRMS.vonigo.value}
                    /> : <></>
                }
            </Box>
            <Box sx={{ display: 'flex', flexDirection: "row", flexWrap: "wrap", width: "100%", gap: "1em" }} >
                {isNewLocation ? <><CustomTextField style={{ width: "45%" }} required={true} value={isNewLocation ? newLocation?.name ?? "" : ""} size="small" label="Location Name"
                    onChange={(event) => {
                        if (isNewLocation) {
                            onNewLocationChanged({ ...newLocation, name: event?.target?.value });
                        }
                    }} />
                    <AddressBox address={isNewLocation ? newLocation?.address : location?.address} isDisabled={isNewLocation ? false : true} isRequired={true} isStateRequired={false} countries={countries?.map(c => c.name)}
                        zipCodeValidator={zipCodeValidator}
                        onChangeAddress={handleOnAddressChanged} />
                </>
                    : <Autocomplete
                        sx={{ width: "100%", }}
                        size="small"
                        noOptionsText={selectedCustomer ? "No Locations found for customer" : "Please select a customer"}
                        loading={isLocationsLoading}
                        value={location ?? null}
                        disablePortal
                        disableClearable
                        options={locations}
                        getOptionLabel={(location) => location?.name ?? "-"}
                        renderOption={(props, location) => <RenderInput {...props} key={location?.id} content={location?.name} />}
                        onChange={(_, value) => {
                            setLocation(value);
                        }}
                        renderInput={(params) => <CustomTextField   {...params} required={!isNewLocation} label="Select Customer's Location" />}
                    />}
            </Box>
        </Box>
        {isNewLocation ?
            <div style={{ display: "flex", justifyContent: "end", marginTop: "20px" }}>
                <LoadingButton size={"small"} variant="contained"
                    loading={isCreateLocationLoading}
                    onClick={onCreateLocationButtonPressed}
                    className={canCreateLocation() ? "btn-primary" : "btn-disable"} >
                    {"Create Location"}
                </LoadingButton>
            </div> : <></>}
    </>
}
