import { IconButton, Tooltip } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';


export default function DialogHeader(props) {
    return <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", position: "sticky", top: "0", paddingRight: "2em", zIndex: 2 }} className={props.className}>
        <Tooltip title={"Close"}><IconButton onClick={() => {
            props.onClose()
        }} style={{ position: "absolute", right: "10px", top: "10px" }} size={"small"} aria-label="delete">
            < CloseIcon style={{ height: "15px", width: "15px" }} />
        </IconButton></Tooltip>
        {props.children}
    </div>
}