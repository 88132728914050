import { NOTIFICATION_TYPES } from "../../constants/common"
import { getLuminSalesAcceleratorDetails, spinLuminSalesAccelerator } from "../../services/lumin/lumin"
import { retry, shouldRetry } from "../../state-services-dublicate/serviceTitan"

export async function handleGetLuminSalesAcceleratorDetails(leadId = 0, setLuminDetails = (details) => { }, setIsLeading, setIsError, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    try {
        setLuminDetails([])
        setIsError(false)
        setIsLeading(true)
        const res = await getLuminSalesAcceleratorDetails(leadId)
        if (Array.isArray(res?.data)) {
            setLuminDetails(res?.data)
        }
    } catch (err) {
        if (shouldRetry(backoffConfig) && err?.status !== 400) {
            retry(() => handleGetLuminSalesAcceleratorDetails(leadId, setLuminDetails, setIsLeading, setIsError, backoffConfig), backoffConfig)
        } else {
            setIsError(true)
        }
    } finally {
        setIsLeading(false)
    }
}

export async function handleSpinLuminSalesAccelerator(franchise, middlewareLead, customer, contacts, address, setIsLoading = (loading) => { }, onDone = () => { }, showNotification) {
    try {
        setIsLoading(true)
        const request = getLuminSalesAcceleratorRequest(franchise, middlewareLead, customer, contacts, address)
        const res = await spinLuminSalesAccelerator(request)
        onDone(res)
        showNotification({ message: "Success", type: NOTIFICATION_TYPES.SUCCESS })
    } catch (err) {
        showNotification({ message: "Important : Could not spin. Please try again", type: NOTIFICATION_TYPES.ERROR })
    } finally {
        setIsLoading(false)
    }
}

export function getLuminSalesAcceleratorRequest(franchise, middlewareLead, customer, contacts, address) {
    const { firstName, lastName } = getFirstNameAndLastName(customer?.name)
    const { leadEmail, leadPhone1, leadPhone2, leadPhone3 } = getContactInformation(contacts)
    const luminRequest = {
        given_name: firstName,
        surname: lastName,
        lead_email: leadEmail,
        lead_phone_1: leadPhone1,
        lead_phone_2: leadPhone2,
        lead_phone_3: leadPhone3,
        address: address,
        st_identifiers: {
            tenant_id: `${franchise?.tenant_id ?? ""}`,
            businessunit_id: `${middlewareLead?.leadResponse?.businessUnitId ?? ""}`,
            campaign_id: `${middlewareLead?.leadResponse?.campaignId ?? ""}`,
            jobtype_id: `${middlewareLead?.leadResponse?.jobTypeId ?? ""}`,
            customer_id: `${middlewareLead?.leadResponse?.customerId ?? ""}`,
            location_id: `${middlewareLead?.leadResponse?.locationId ?? ""}`,
            lead_id: `${middlewareLead?.leadResponse?.id ?? ""}`,
            job_id: undefined,
        }
    }
    return {
        brand_id: franchise?.brand_id,
        franchise_id: franchise?.id,
        middleware_servicetitan_lead_id: middlewareLead?.middlewareServiceTitanLead?.id,
        lumin_sales_accelerator_request: luminRequest,
    }
}

function getFirstNameAndLastName(fullName = "") {
    const nameParts = fullName.split(' ');
    if (nameParts.length === 1) return { firstName: fullName, lastName: '' };
    if (nameParts.length >= 2) return { firstName: nameParts[0], lastName: nameParts.slice(1).join(" ") };
    return { firstName: '', lastName: '' };
}

function getContactInformation(jsonData) {
    const contactInformation = { leadEmail: "", leadPhone1: "", leadPhone2: "", leadPhone3: "" };
    let phoneCount = 1;
    for (const item of jsonData) {
        if (item.type === "Phone" || item.type === "MobilePhone") {
            const phoneKey = `leadPhone${phoneCount}`;
            contactInformation[phoneKey] = item.value;
            phoneCount++;

            if (phoneCount > 3) break; // Stop adding phone numbers after 3
        } else if (item.type === "Email") {
            contactInformation["leadEmail"] = item.value;
        }
    }
    return contactInformation;
}