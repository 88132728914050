import { Box } from "@mui/material"
import CustomTextField from "../../../../../components/CustomTextField"
import CustomerTypesRadioButton from "../../serviceTitanJobs/components/CustomerTypesRadioButton"
import CampaignSelection from "../../vonigo/components/CampaignSelection"
import { CUSTOMER_TYPES } from "../../vonigo/components/CustomerDetailsSection"
import IndustrySelection from "../../vonigo/components/IndustrySelection"

export default function NewCustomerSection(props) {
    const { franchise, customer, onCustomerNameChanged, onCustomerTypeChanged, isDisabled, industry, onIndustryChanged, industries, isIndustriesLoading, campaign, onCampaignChanged, campaigns, isCampaignsLoading } = props

    return <Box style={{ width: "100%" }} >
        <Box style={{ display: "flex", flexDirection: "column", width: "100%", gap: "1em" }} >
            <Box style={{ display: "flex", flexDirection: "row", width: "100%", }}>
                {customer?.type === CUSTOMER_TYPES.COMMERCIAL.name ?
                    <CustomTextField style={{ minWidth: "30em", flexGrow: "2", marginRight: "0.5em" }} required={true} value={customer?.name ?? ""} size="small" label="Company Name"
                        onChange={onCustomerNameChanged} />
                    : <></>}
                <CustomerTypesRadioButton isDisabled={isDisabled} options={Object.values(CUSTOMER_TYPES).map((c) => c.name)} value={(customer?.type ?? "")}
                    onChange={onCustomerTypeChanged} />
                <CampaignSelection noOptionText={franchise ? "No Campaigns" : "Please select a franchise"} value={campaign} campaigns={campaigns} isCampaignsLoading={isCampaignsLoading} onChange={onCampaignChanged} />
            </Box>
            <Box style={{ display: "flex", flexDirection: "row", width: "100%", gap: "1em" }}>
                {(customer?.type === CUSTOMER_TYPES.COMMERCIAL.name) ? <IndustrySelection franchise={franchise} noOptionText={franchise ? "Please select a franchise" : "No Industries"} isRequired={true} value={industry} industries={industries} isIndustriesLoading={isIndustriesLoading} onChange={onIndustryChanged} /> : <></>}
            </Box>
        </Box>
    </Box>
}