import { useEffect, useMemo, useRef, useState, useCallback } from "react";
import { Button, Box, Tooltip, IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { canCreateSTLead, isDifferentArrays } from "../../../../utils/miscellaneous";
import { handleGetCustomerById,handleUpdateTechnicianCriteria ,handleGetCustomerContacts, handleGetLocationById, handleGetSelectedCustomerLocations, handleSearchCustomer } from "../../../../state-services-dublicate/serviceTitan";
import { NOTIFICATION_TYPES } from "../../../../constants/common";
import CreateJobSection from "../serviceTitanJobs/components/CreateJobSection";
import CreateAppointmentSection from "./servicetitan/CreateAppointmentSection";
import DialogFooter from "../../../../components/dialog/DialogFooter";
import { getIsServiceableZipCode } from "../../../../services/crm/crm";
import { getIsValidUSOrCanadianZipCode, isContactsValid, isValidZipCode } from "../../../../utils/validators";
import { CustomerDetailsSection } from "./CustomerDetailsSection";
import LocationDetailsSection from "./LocationDetailsSection";
import ContactsSection from "./ContactsSection";
import { createCustomerContact, createCustomerLocation, createFranchiseCustomer, createServiceTitanJobDraft, createServiceTitanJobs, updateServiceTitanJob, updateServiceTitanJobDraft } from "../../../../services/serviceTitan";
import { CONFIG_MESSAGES, CREATE_JOB_SECTION_IDS, SECTIONS } from "../../../../constants/jobs";
import { CONTACTS_TYPES, PRIORITIES } from "../../../../constants/serviceTitan";
import { handleScrollPosition } from "./CreateJobPage";
import { Prompt } from "react-router-dom";
import { getCanFetchServiceableZipCode, getPromptMessage } from "../../../../utils/jobs";
import DraftOrLeadModal from "./DraftOrLeadModal";
import useDebounce from "../../../../hooks/useDebounce";
import { getCountryForServiceableZipCode, getZipCodeForServiceableZipCode } from "../../../../utils/servicetitan/jobs";
import ConfigMessage from "../../../../components/configMessage/configMessage";
import LoadingButton from "../../../../components/LoadingButton";
import BillingAddressSection from "./BillingAddressSection";
import { handleGetAllStates } from "../../../../state-services/configuration/configuration";
import { handleGetArrivalWindowByBrandId } from "../../../../state-services/brands";
import { updateJob, mapCallJobLead } from "../../../../services/jobService";
import { LOCAL_STORAGE_KEYS } from "../../../../constants/browserStorage";
import NewCustomerCreateConfirmModal from "./NewCustomerCreateConfirmModal";
import LocationConfirmModal from "./LocationConfirmModal";
import { handleRescheduleAppointment, handleCreateTechnicianCriteria, handleGetTechnicianCriteria, handleGetAppointmentAssignmentsForEditJob, handleAppointmentUnassignment, handleAppointmentAssignment } from "../../../../state-services-dublicate/serviceTitan";
import ZipCodeComparisonModal from "./ZipCodeComparisonModal";
import Loading from "../../../other/components/loading";
import PreviousAppointmentRescheduleModal from "./previousAppointmentRescheduleModal";
import { addServiceTitanJobSpecificNote } from "../../../../services/serviceTitan";
import EmptyTechnicianConfirmationModal from "./emptyTechniciansConfirmModal";
import {getServiceTitanAppointments} from "./../../../../services/serviceTitan"; 

export const CUSTOMER_TYPES = ["Residential", "Commercial"]
export const CUSTOMER_INITIAL_CONTACTS = [{ type: CONTACTS_TYPES[0], value: "", memo: "" }, { type: CONTACTS_TYPES[1], value: "", memo: "" }]
export const CUSTOMER_INITIAL_CONTACT = { type: "", value: "", memo: "" }

export default function ServiceTitanMainSection(props) {
    const { brand, franchise, setIsFromHomePage, setZipCode ,selectedJobToEdit, selectedPhoneNumber, selectedZipCode, isDisabled, showNotification, onClose, setCompletedSections, selectedDraftToContinue, shouldSaveDraft, setShouldSaveDraft, zipCode } = props

    const urlParams = new URLSearchParams(window.location.search);

    const isFromCallWindow = urlParams.get("isFromCallWindow");

    const [initialContact, trimedPhoneNumber] = useMemo(() => {
        if (selectedPhoneNumber) {
            const trimedPhoneNumber = selectedPhoneNumber?.length > 10 ? selectedPhoneNumber.substr(selectedPhoneNumber.length - 10) : selectedPhoneNumber
            const initialContact = [{ type: CONTACTS_TYPES[0], value: trimedPhoneNumber }, { type: CONTACTS_TYPES[1], value: "", memo: "" }]
            return [initialContact, trimedPhoneNumber]
        } else {
            return [[{ type: "Phone", value: "", memo: "" }, { type: "Email", value: "", memo: "" }], selectedPhoneNumber]
        }
    }, [selectedPhoneNumber])

    const trimedZipCode = useMemo(() => {
        return selectedZipCode?.length > 10 ? selectedZipCode.substr(0, 10) : selectedZipCode
    }, [selectedZipCode]);

    const [jobNote, setJobNote] = useState({ text: "", pinToTop: false })
    const [isCustomersLoading, setIsCustomersLoading] = useState(false)
    const [customerPhoneNumber, setCustomerPhoneNumber] = useState(selectedDraftToContinue?.callerId ?? trimedPhoneNumber ?? "")
    const [customerZipCode, setCustomerZipCode] = useState(selectedDraftToContinue?.zipCode ?? trimedZipCode ?? "")
    const [customerName, setCustomerName] = useState("")
    const [isNewCustomer, setIsNewCustomer] = useState(selectedDraftToContinue ? selectedDraftToContinue?.isNewCustomer : selectedJobToEdit ? false : false)
    const [isEditCustomer, setIsEditCustomer] = useState(selectedDraftToContinue ? selectedDraftToContinue?.isEditCustomer : false)
    const [editedCustomer, setEditedCustomer] = useState(selectedDraftToContinue ? selectedDraftToContinue?.editedCustomer : false)
    const [isNewLocationForExistingCustomer, setIsNewLocationForExistingCustomer] = useState(selectedDraftToContinue ? selectedDraftToContinue?.isNewLocation : false)
    const [isEditLocation, setIsEditLocation] = useState(false)
    const [editedLocation, setEditedLocation] = useState()
    const [selectedCustomer, setSelectedCustomer] = useState()
    const [existingContacts, setExistingContacts] = useState([])
    const [currentlyEditingContactsCount, setCurrentlyEditingContactsCount] = useState(0);
    const [customers, setCustomers] = useState([])
    const [selectedLocation, setSelectedLocation] = useState()
    const [locations, setLocations] = useState([])
    const [isLocationsLoading, setIsLocationsLoading] = useState(false)
    const [newCustomer, setNewCustomer] = useState(selectedDraftToContinue?.createCustomerRequest ? selectedDraftToContinue?.createCustomerRequest : { type: CUSTOMER_TYPES[0] })
    const [location, setLocation] = useState(selectedDraftToContinue?.createCustomerRequest?.locations?.[0])
    const [isBillingSameAsLocation, setIsBillingSameAsLocation] = useState(selectedDraftToContinue ? selectedDraftToContinue?.isBillingAddressSameAsLocation ?? true : true)
    const [contacts, setContacts] = useState(Array.isArray(selectedDraftToContinue?.createCustomerRequest?.contacts) ? [...selectedDraftToContinue?.createCustomerRequest?.contacts] : [])
    const [job, setJob] = useState(selectedDraftToContinue ? selectedDraftToContinue?.jobCreateRequest : selectedJobToEdit ? selectedJobToEdit : null)
    const [appointment, setAppointment] = useState(selectedDraftToContinue?.jobCreateRequest?.appointments?.[0])
    const [isCreateJobLoading, setIsCreateJobLoading] = useState(false)
    const [showingServiceableZipCodeResponse, setShowingServiceableZipCodeResponse] = useState()
    const [serviceableZipCodeResponse, setSrviceableZipCodeResponse] = useState()
    const [createdCustomer, setCreatedCustomer] = useState()
    const [createdLocation, setCreatedLocation] = useState()
    const [createdJob, setCreatedJob] = useState()
    const [states, setStates] = useState([])
    const [arrivalWindow, setArrivalWindow] = useState(null)
    const [isDraftOrLeadModalOpen, setIsDraftOrLeadModalOpen] = useState(false)
    const [isOpenConfirmCreateNewCustomerModal, setIsOpenConfirmCreateNewCustomerModal] = useState(false)
    const [isAddressSelected, setIsAddressSelected] = useState(false)
    const isAddressChanged = useRef(false)
    const [isOpenConfirmLocationModal, setIsOpenConfirmLocationModal] = useState(false)
    const [isOpenZipCompariosnModal, setIsOpenZipCompariosnModal] = useState(false)
    const customerSearchCount = useRef(0);
    const [shouldLoadDetailsForJob, setShouldLoadDetailsForJob] = useState(false)
    const [isOpenProceedWithEmptyTechniciansConfirmationModal, setIsOpenProceedWithEmptyTechniciansConfirmationModal] = useState(false)
    
    const [technicianCriteria, setTechnicianCriteria] = useState(new Map())
    const [criteriaId, setCriteriaId] = useState(0)
    const [zipCodeOfEditJob, setZipCodeOfEditJob] = useState("")
    const [appointmentId, setAppointmentId] = useState(0)

    //This is used to handle the previous appointment reschedule
    const [previousAppointmentForRescheduleJob, setPreviousAppointmentForRescheduleJob] = useState(null)
    const [jobForTheAppointment, setjobForTheAppointment] = useState(null)
    const [isOpenAppointmentRescheduleModal, setisOpenAppointmentRescheduleModal] = useState(false)

    const [previousContacts, setPreviousContacts] = useState(selectedDraftToContinue ? selectedDraftToContinue?.previousContacts : initialContact)
    const previousJobTypeId = selectedJobToEdit?.jobTypeId ?? null
    const debounceServiceAreaRequest = useDebounce({ brandId: brand?.id, franchiseId: franchise?.id, zip: "", country: "" }, ({ brandId, franchiseId, zip, country }) => {
        if (getCanFetchServiceableZipCode(brandId, franchiseId, zip, country)) {
            getIsServiceableZipCode(brandId, franchiseId, zip).then((response) => {
                setSrviceableZipCodeResponse(response)
            }).catch((err) => { })
        }
    }, 1000)

    const GetTechnicianCriteria = useCallback(
        async () => {
            let appointmentRes = await getServiceTitanAppointments(franchise?.id, selectedJobToEdit?.id,selectedJobToEdit?.customerId)
            for (let i = 0; i < appointmentRes.data.length; i++) {
                if (appointmentRes.data[i].jobId === selectedJobToEdit?.id) {
                    let res = await handleGetTechnicianCriteria(appointmentRes.data[i]?.id)
                    if (res?.id !== 0) {
                        setTechnicianCriteria(res?.technician_criteria)
                        setCriteriaId(res?.id)
                        setAppointmentId(res?.appointment_id)
                        setZipCodeOfEditJob(res?.zip_code)
                        setZipCode(res?.zip_code)
                    }
                }
            }
        },
        [],
    )

    useEffect(() => {
        if (isFromCallWindow === 'true') {
            setIsFromHomePage(true)
        }
    }, [isFromCallWindow])

    useEffect(() => {
        if (selectedJobToEdit) {
            GetTechnicianCriteria()
        }
        return () => {
        }
    }, [])

    useEffect(() => {
        if (zipCode && franchise) {
            checkForZipCodeComparison()
        }
    }, [selectedLocation?.address?.zip, location?.address?.zip, zipCode])

    useEffect(() => {
        if (brand) handleGetArrivalWindowByBrandId(brand?.id, setArrivalWindow)
        return () => {
            setArrivalWindow(null)
        }
    }, [brand])

    useEffect(() => {
        handleScrollPosition()
    }, [isNewCustomer])

    useEffect(() => {
        handleGetAllStates(setStates);
    }, [])

    useEffect(() => {
        if (isNewCustomer || isNewLocationForExistingCustomer) {
            if (!(isValidZipCode(location?.address?.zip, location?.address?.country)) || !location?.address?.zip) setShowingServiceableZipCodeResponse()
        } else {
            if (!(isValidZipCode(selectedLocation?.address?.zip, selectedLocation?.address?.country)) || !selectedLocation?.address?.zip) setShowingServiceableZipCodeResponse()
        }
    }, [location?.address?.zip, location?.address?.country, isNewLocationForExistingCustomer, isNewCustomer, selectedLocation])

    useEffect(() => {
        let customerId
        customerSearchCount.current = 0;
        setCustomers([])
        setSelectedCustomer()
        setExistingContacts([])
        setSelectedLocation()
        setLocations([])
        if (selectedDraftToContinue) {
            customerId = selectedDraftToContinue?.jobCreateRequest?.customerId
        } else if (selectedJobToEdit) {
            customerId = selectedJobToEdit?.customerId
        }
        if (franchise && customerId) handleGetCustomerById(franchise, customerId, setSelectedCustomer, setCustomers)
    }, [franchise, selectedDraftToContinue?.jobCreateRequest?.customerId, selectedJobToEdit?.customerId])

    useEffect(() => {
        let customerId
        let locationId
        if (selectedDraftToContinue) {
            customerId = selectedDraftToContinue?.jobCreateRequest?.customerId
            locationId = selectedDraftToContinue?.jobCreateRequest?.locationId
        } else if (selectedJobToEdit) {
            customerId = selectedJobToEdit?.customerId
            locationId = selectedJobToEdit?.locationId
        }
        if (franchise && locationId && customerId === selectedCustomer?.id) handleGetLocationById(franchise, locationId, setSelectedLocation)
    }, [franchise, selectedDraftToContinue?.jobCreateRequest?.customerId, selectedJobToEdit?.customerId, selectedCustomer?.id])

    useEffect(() => {
        if (!shouldSaveDraft) return
        const createCustomerRequest = getCreateCustomerRequest(newCustomer, location, contacts)
        const createJobRequest = getNewJobRequest(job, createdCustomer?.id ?? selectedCustomer?.id, createdLocation?.id ?? selectedLocation?.id, appointment)
        const draftBody = getServiceTitanCreateDraftBody(brand?.id, franchise?.id, franchise?.service_brand, customerPhoneNumber, customerZipCode, createCustomerRequest, location, createJobRequest, previousContacts, isNewCustomer, isNewLocationForExistingCustomer, false, showingServiceableZipCodeResponse?.isServiceable, isBillingSameAsLocation)
        const shouldOpenDraftOrLeadModal = selectedDraftToContinue ? getShouldUpdateDraft(selectedDraftToContinue, draftBody) : getShouldCreateDraft(isNewCustomer, customerPhoneNumber, customerZipCode, newCustomer, location, contacts, previousContacts, job)
        if (shouldOpenDraftOrLeadModal && !selectedJobToEdit) {
            setIsDraftOrLeadModalOpen(true)
        } else {
            onClose()
        }
    }, [brand, franchise, selectedDraftToContinue, shouldSaveDraft, customerPhoneNumber, customerZipCode, newCustomer, location, contacts, createdCustomer, selectedCustomer, createdLocation, selectedLocation, job, appointment, isNewCustomer, isNewLocationForExistingCustomer, previousContacts, showingServiceableZipCodeResponse?.isServiceable, isBillingSameAsLocation, onClose, selectedJobToEdit])

    useEffect(() => {
        const isCustomerSectionComplete = isNewCustomer ? getIsNewCustomerComplete(newCustomer) : selectedCustomer
        const isLocationSectionComplete = isNewCustomer || isNewLocationForExistingCustomer ? getIsNewCustomerLocationComplete(location, newCustomer, showingServiceableZipCodeResponse?.isServiceable) : selectedLocation && showingServiceableZipCodeResponse?.isServiceable
        const isContactsSectionComplete = isNewCustomer ? isContactsValid(contacts) : isContactsValid(contacts, existingContacts)
        const isJobSectionComplete = getIsJobSectionComplete(job)
        const isAppointmentSectionComplete = selectedJobToEdit && isJobTypeChanged(selectedJobToEdit, previousJobTypeId, job?.jobTypeId) ? getAppointmentSectionComplete(appointment, previousJobTypeId) : selectedJobToEdit ? true : getAppointmentSectionComplete(appointment, previousJobTypeId)
        const completedSections = []
        if (isCustomerSectionComplete) completedSections.push(SECTIONS?.CUSTOMER)
        if (isLocationSectionComplete) completedSections.push(SECTIONS?.LOCATION)
        if (isContactsSectionComplete) completedSections.push(SECTIONS?.CONTACT)
        if (isJobSectionComplete) completedSections.push(SECTIONS?.JOB)
        if (isAppointmentSectionComplete) completedSections.push(SECTIONS?.APPOINTMENT)
        setCompletedSections(completedSections)
    }, [isNewCustomer, isNewLocationForExistingCustomer, selectedCustomer, selectedLocation, newCustomer, location, contacts, existingContacts, job, appointment, setCompletedSections, showingServiceableZipCodeResponse?.isServiceable])

    useEffect(() => {
        let cleanUpFunction = () => { };
        if (selectedCustomer?.id) {
            const getLocationsCancelFunc = handleGetSelectedCustomerLocations(franchise?.id, selectedCustomer?.id, setLocations, setSelectedLocation, setIsLocationsLoading)
            const getCustomerContactsCancelFunc = handleGetCustomerContacts(franchise?.id, selectedCustomer?.id, setExistingContacts);
            cleanUpFunction = () => {
                getLocationsCancelFunc?.();
                getCustomerContactsCancelFunc?.();
            }
        }
        setCurrentlyEditingContactsCount(0)
        return cleanUpFunction
    }, [selectedCustomer?.id])

    useEffect(() => {
        const zipCode = getZipCodeForServiceableZipCode(isNewCustomer, isNewLocationForExistingCustomer, isEditLocation, location?.address?.zip, selectedLocation?.address?.zip, editedLocation?.address?.zip)
        const country = getCountryForServiceableZipCode(isNewCustomer, isNewLocationForExistingCustomer, isEditLocation, location?.address?.country, selectedLocation?.address?.country, editedLocation?.address?.country)
        if (getCanFetchServiceableZipCode(brand, franchise?.id, zipCode, country)) {
            setShowingServiceableZipCodeResponse(serviceableZipCodeResponse)
        } else {
            setShowingServiceableZipCodeResponse()
        }
    }, [isNewCustomer, isNewLocationForExistingCustomer, isEditLocation, location?.address?.zip, selectedLocation?.address?.zip, editedLocation?.address?.zip, location?.address?.country, selectedLocation?.address?.country, editedLocation?.address?.country, serviceableZipCodeResponse])

    useEffect(() => {
        const zipCode = getZipCodeForServiceableZipCode(isNewCustomer, isNewLocationForExistingCustomer, isEditLocation, location?.address?.zip, selectedLocation?.address?.zip, editedLocation?.address?.zip)
        const country = getCountryForServiceableZipCode(isNewCustomer, isNewLocationForExistingCustomer, isEditLocation, location?.address?.country, selectedLocation?.address?.country, editedLocation?.address?.country)
        setShowingServiceableZipCodeResponse()
        debounceServiceAreaRequest({ brandId: brand?.id, franchiseId: franchise?.id, zip: zipCode, country: country })
    }, [franchise, isNewCustomer, isNewLocationForExistingCustomer, isEditCustomer, selectedLocation?.address?.zip, selectedLocation?.address?.country, location?.address?.zip, location?.address?.country, editedLocation?.address?.zip, editedLocation?.address?.country, debounceServiceAreaRequest])

    useEffect(() => {
        if (!(selectedDraftToContinue || selectedJobToEdit)) {
            setJob({ priority: PRIORITIES[2] })
            setShouldLoadDetailsForJob(true)
        } else {
            setShouldLoadDetailsForJob(true)
        }
    }, [selectedDraftToContinue, selectedJobToEdit, franchise])

    async function onSubmit(e, uuid = "", isInitialRequest = true) {
        try {
            e?.preventDefault();
            setIsCreateJobLoading(true)
            if (!franchise) return setIsCreateJobLoading(false)
            if (selectedDraftToContinue && isInitialRequest) updateDraft(selectedDraftToContinue?.id, { ...selectedDraftToContinue, isDraft: false });
            let customerResponse
            let locationId
            if (isNewCustomer && isInitialRequest) {
                if (isNewCustomer && !createdCustomer) customerResponse = await createNewCustomer(franchise, newCustomer, location, contacts)
                if (customerResponse) {
                    setCreatedCustomer(customerResponse)
                    locationId = customerResponse?.locations?.[0]?.id
                } else if (createdCustomer) {
                    locationId = createdCustomer?.locations?.[0]?.id
                }
            } else {
                if (isNewLocationForExistingCustomer) {
                    if (!createdLocation && isInitialRequest) {
                        const locationResponse = await createNewLocation(franchise, selectedCustomer?.id, location, showNotification)
                        if (locationResponse) {
                            setCreatedLocation(locationResponse)
                            locationId = locationResponse?.id
                        }
                    } else {
                        locationId = createdLocation?.id
                    }
                } else {
                    locationId = selectedLocation?.id
                }
                if (contacts?.length > 0 && isContactsValid(contacts) && isInitialRequest) {
                    await createContacts(franchise, selectedCustomer?.id, contacts)
                }
            }
            let customerId = isNewCustomer ? createdCustomer?.id ?? customerResponse?.id : selectedCustomer?.id
            let jobResponse
            if ((!createdJob && customerId && locationId) || (!createdJob && !isInitialRequest)) {
                if (selectedJobToEdit) {
                    jobResponse = await handleUpdateJob(franchise, job, customerId, locationId)
                    if (appointment) {
                        if (criteriaId !== 0 && (appointmentId === selectedJobToEdit?.lastAppointmentId)) await handleUpdateTechnicianCriteria(criteriaId, technicianCriteria)
                        await rescheduleAppointment()
                    }
                } else {
                    jobResponse = await createNewJob(franchise, job, customerId, locationId, appointment, uuid, isInitialRequest)
                    if (jobResponse.isResponseComplete && jobNote.text !== "" && jobNote.text !== null && jobNote.text !== undefined) {
                        try {
                            await addServiceTitanJobSpecificNote(franchise.id, jobResponse.id, jobNote);
                        } catch (error) {
                            showNotification({ message: "Unable to add job specific note", type: NOTIFICATION_TYPES.ERROR })
                        }
                    }
                    if (jobResponse.isResponseComplete) {
                        try {
                            let res = await getServiceTitanAppointments(franchise?.id, jobResponse?.id,jobResponse?.customerId)
                            res.data.sort((a, b) => {
                                if (a.createdOn < b.createdOn) return -1
                                if (a.createdOn > b.createdOn) return 1
                                return 0
                            })
                            let appointmentId = res?.data?.[0]?.id
                            await handleCreateTechnicianCriteria(jobResponse.id, appointmentId ,zipCode, franchise?.id, brand?.id, technicianCriteria);
                        } catch (error) {

                        }
                    }
                }
            }
            if (jobResponse && selectedJobToEdit) {
                setIsCreateJobLoading(false)
                setCreatedJob(jobResponse)
                showNotification({ message: "The job has been updated successfully", type: NOTIFICATION_TYPES.SUCCESS })
                onClose(jobResponse)
            } else {
                if (jobResponse && jobResponse?.isResponseComplete === true) {
                    setIsCreateJobLoading(false)
                    setCreatedJob(jobResponse)
                    showNotification({ message: "The job has been created successfully", type: NOTIFICATION_TYPES.SUCCESS })
                    if (isFromCallWindow == 'true') {
                        await updateExistingJob()
                        await mapCallWithJobLead(jobResponse.id)
                    }
                    onClose(jobResponse)
                } else {
                    setTimeout(() => {
                        onSubmit(e, jobResponse?.uuidForJobResponse, false);
                    }, 5000);
                }
            }
        }
        catch (err) {
            setIsCreateJobLoading(false)
            let stateErr
            let jsonValidateErr = false
            try {
                JSON.parse(err?.responseText)
                jsonValidateErr = true
            } catch (error) {
                stateErr = ""
            }
            if (jsonValidateErr) {
                let errDetails = JSON.parse(err?.responseText)
                let errCode = errDetails?.data?.ErrorCode
                if (errCode === "StateNotValid") {
                    stateErr = "State doesn't valid with country"
                } else if (errCode === "CountryNotValid") {
                    stateErr = "Country is not valid"
                } else {
                    stateErr = ""
                }
            }
            showNotification({ message: selectedJobToEdit ? `Important: Job couldn’t be updated at this time ${stateErr}. Please try again` : `Important: Job couldn’t be created at this time ${stateErr}. Please try again`, type: NOTIFICATION_TYPES.ERROR })
        }
    }

    function checkForZipCodeComparison() {
        if (isNewCustomer) {
            if (isValidZipCode(location?.address?.zip, location?.address?.country) && getIsValidUSOrCanadianZipCode(zipCode)) {
                if (location?.address?.zip !== zipCode && location?.address?.zip) {
                    setIsOpenZipCompariosnModal(true)
                }
            }
        } else {
            if (isNewLocationForExistingCustomer) {
                if (isValidZipCode(location?.address?.zip, location?.address?.country) && getIsValidUSOrCanadianZipCode(zipCode)) {
                    if (location?.address?.zip !== zipCode && location?.address?.zip) {
                        setIsOpenZipCompariosnModal(true)
                    }
                }
            } else {
                if (isValidZipCode(selectedLocation?.address?.zip, selectedLocation?.address?.country) && getIsValidUSOrCanadianZipCode(zipCode)) {
                    if (selectedLocation?.address?.zip !== zipCode && selectedLocation?.address?.zip) {
                        setIsOpenZipCompariosnModal(true)
                    }
                }
            }
        }
    }

    function handleCloseZipComparisonModal() {
        setIsOpenZipCompariosnModal(false)
    }

    function getFullDraftBody() {
        const createCustomerRequest = getCreateCustomerRequest(newCustomer, location, contacts)
        const createJobRequest = getNewJobRequest(job, createdCustomer?.id ?? selectedCustomer?.id, createdLocation?.id ?? selectedLocation?.id)
        return getServiceTitanCreateDraftBody(brand?.id, franchise?.id, franchise?.service_brand, customerPhoneNumber, customerZipCode, createCustomerRequest, location, createJobRequest, previousContacts, isNewCustomer, isNewLocationForExistingCustomer, false, showingServiceableZipCodeResponse?.isServiceable, isBillingSameAsLocation)
    }

    async function saveDraft(isLead = false) {
        try {
            const draftBody = getFullDraftBody()
            let draftResponse;
            const shouldUpdateDraft = selectedDraftToContinue && getShouldUpdateDraft(selectedDraftToContinue, draftBody)
            if (shouldUpdateDraft) {
                draftResponse = await updateDraft(selectedDraftToContinue?.id, draftBody);
                if (!isLead) showNotification({ message: "Draft updated", type: NOTIFICATION_TYPES.SUCCESS })
            } else if ((!selectedDraftToContinue && getShouldCreateDraft(isNewCustomer, customerPhoneNumber, customerZipCode, newCustomer, location, contacts, previousContacts, job)) || isLead) {
                draftResponse = await createDraft(draftBody);
                if (!isLead) showNotification({ message: "Saved to drafts", type: NOTIFICATION_TYPES.SUCCESS })
            }
            if (isLead) {
                return draftResponse
            } else {
                onClose(undefined, draftResponse)
            }
        } catch {
            showNotification({ message: "Important: Couldn’t save to drafts", type: NOTIFICATION_TYPES.ERROR })
            setShouldSaveDraft(false)
        }
    }

    async function createALead() {
        try {
            if (canCreateSTLead(franchise)) {
                const draftBody = getFullDraftBody()
                const createDraftResponse = selectedDraftToContinue ? await updateDraft(selectedDraftToContinue?.id, draftBody) : await saveDraft(true);
                if (createDraftResponse) {
                    // Save the encoded jobNote in localStorage to send lead create modal
                    const encodedJobToLeadNote = encodeURIComponent(JSON.stringify(jobNote));
                    localStorage.setItem('jobToLeadNote', encodedJobToLeadNote);
                    if (isFromCallWindow == 'true') {
                        window.location.replace(`/leads?section=servicetitan&jobDraftId=${selectedDraftToContinue ? selectedDraftToContinue?.id : createDraftResponse?.id}&isFromCallWindow=true&callerId=${urlParams.get("callerId")}&call_type=${urlParams.get("call_type")}&jobId=${urlParams.get("jobId")}&type=${urlParams.get("type")}&zipCode=${zipCode}`)
                    } else {
                        window.location.replace(`/leads?section=servicetitan&jobDraftId=${selectedDraftToContinue ? selectedDraftToContinue?.id : createDraftResponse?.id}&callerId=${urlParams.get("callerId")}`)
                    }
                }
            }
        } catch { }
    }

    function handleCloseCreateNewCustomerConfirmModal() {
        setIsOpenConfirmCreateNewCustomerModal(false)
    }

    function onCustomerToggleChanged() {
        if (!isNewCustomer && !isOpenConfirmCreateNewCustomerModal) {
            setIsOpenConfirmCreateNewCustomerModal(true)
            return
        }
        if (isOpenConfirmCreateNewCustomerModal) handleCloseCreateNewCustomerConfirmModal()
        const isNewCustomerDuplicate = isNewCustomer
        setIsNewCustomer(!isNewCustomer)
        if (!isNewCustomerDuplicate) {
            setPreviousContacts(contacts)
            setContacts(previousContacts)
        } else {
            setPreviousContacts(contacts)
            setContacts(previousContacts)
        }
        if (!isNewCustomerDuplicate && location && !newCustomer?.address) {
            setNewCustomer({ ...newCustomer, address: location?.address })
        }
    }

    function onDraftOrLeadModalClosed() {
        setIsDraftOrLeadModalOpen(false);
        setShouldSaveDraft(false)
    }

    function getShouldPrompt() {
        const draftBody = getFullDraftBody()
        const isAppointmentDataFilled = getIsAppointmentSufficientToDraft(appointment)
        return (selectedDraftToContinue ? getShouldUpdateDraft(selectedDraftToContinue, draftBody) : getShouldCreateDraft(isNewCustomer, customerPhoneNumber, customerZipCode, newCustomer, location, contacts, previousContacts, job)) || isAppointmentDataFilled
    }

    function onCustomerEditDone(editedCustomer) {
        if (!editedCustomer) return
        const index = customers.findIndex((v) => v.id === editedCustomer.id)
        if (index === -1) {
            setCustomers([editedCustomer, ...customers])
        } else {
            customers[index] = editedCustomer
            setCustomers(customers)
            setSelectedCustomer(editedCustomer)
        }
        setIsEditCustomer(false)
    }

    function onCustomerEditPressed() {
        if (isEditCustomer) {
            setEditedCustomer()
        } else {
            setEditedCustomer(selectedCustomer)
        }
        setIsEditCustomer(!isEditCustomer)
    }

    function setIsAddressChanged() {
        if (!isAddressChanged.current && !isAddressSelected) {
            isAddressChanged.current = true
            setIsOpenConfirmLocationModal(true)
        }
    }

    function SearchCustomer() {
        customerSearchCount.current = customerSearchCount.current + 1
        handleSearchCustomer(franchise, setSelectedCustomer, setCustomers, setSelectedLocation, setLocations, setExistingContacts, setIsCustomersLoading, customerPhoneNumber, setCustomerPhoneNumber, "", customerName)
    }

    async function updateExistingJob() {
        const body = {
            franchise_id: franchise.franchise_id,
            zip_code: zipCode,
            call_type: parseInt(urlParams.get("call_type"))
        }

        const jobId = parseInt(urlParams.get("jobId"))
        const jobtype = urlParams.get("type")

        await updateJob(jobId, jobtype, body).then((res) => {
            showNotification({ message: "The call details updated successfully", type: NOTIFICATION_TYPES.SUCCESS })
        }).catch((err) => {
            showNotification({ message: "Unable to update call details at this time", type: NOTIFICATION_TYPES.ERROR })
        }).finally(() => {
            removeCallDetailsFromLocalStorage()
        })
    }

    async function mapCallWithJobLead(serviceTitanJobId) {
        const body = {
            call_id: parseInt(urlParams.get("jobId")),
            service_titan_job_id: serviceTitanJobId,
            franchise_id: franchise?.id,
            brand_id: brand?.brand_id,
        }

        await mapCallJobLead(body).then((res) => {
        }).catch((err) => {
            showNotification({ message: "Unable to map the call and the job", type: NOTIFICATION_TYPES.ERROR })
        })
    }
    function confirmLocation() {
        setIsOpenConfirmLocationModal(false)
    }

    function handleCloseConfirmLocatinModal() {
        setIsOpenConfirmLocationModal(false)
        isAddressChanged.current = false
    }

    async function rescheduleAppointment() {
        try {
            if (!(appointment?.arrivalWindowStart && appointment?.arrivalWindowEnd)) return showNotification({ message: "Important: Please select a timeslot and try again", type: NOTIFICATION_TYPES.ERROR })
            await handleRescheduleAppointment(franchise, selectedJobToEdit?.lastAppointmentId, appointment)
            const previouslyScheduledTechnicianIds = await handleGetAppointmentAssignmentsForEditJob(franchise, selectedJobToEdit?.lastAppointmentId)
            if (previouslyScheduledTechnicianIds?.length > 0) {
                const appointmentUnssignmentRequest = {
                    jobAppointmentId: selectedJobToEdit?.lastAppointmentId,
                    technicianIds: previouslyScheduledTechnicianIds ?? []
                }
                await handleAppointmentUnassignment(franchise, appointmentUnssignmentRequest)
            }
            if (appointment?.technicianIds?.length > 0) {
                const appointmentAssignmentRequest = {
                    jobAppointmentId: selectedJobToEdit?.lastAppointmentId,
                    technicianIds: appointment.technicianIds
                }
                await handleAppointmentAssignment(franchise, appointmentAssignmentRequest)
            }
            showNotification({ message: "The appointment has been rescheduled successfully", type: NOTIFICATION_TYPES.SUCCESS })
        } catch {
            showNotification({ message: "Important: appointment couldn’t be rescheduled at this time. Please try again", type: NOTIFICATION_TYPES.ERROR })
        }
    }

    //This function is handling the appointment reschedule feature in the job and appointment section in the create job page
    function handleClosePreviousAppointmentRescheduleModal() {
        setPreviousAppointmentForRescheduleJob(null)
        setjobForTheAppointment(null)
        setisOpenAppointmentRescheduleModal(false)
    }

    function checkIfBookingWithEmptyTechnicians(e) {
        e.preventDefault()
        if (isEditCustomer) return handleScrollPosition(SECTIONS.CUSTOMER.id)
        if (currentlyEditingContactsCount > 0 && !isNewCustomer) return handleScrollPosition(SECTIONS.CONTACT.id)
        if (isEditLocation) return handleScrollPosition(SECTIONS.LOCATION.id)
        if (!showingServiceableZipCodeResponse?.isServiceable) return showNotification({ message: "Important: Zip Code not within service area", type: NOTIFICATION_TYPES.ERROR })
        if ((selectedJobToEdit && isJobTypeChanged(selectedJobToEdit, previousJobTypeId, job?.jobTypeId) && !(appointment?.arrivalWindowStart && appointment?.arrivalWindowEnd)) || (!selectedJobToEdit && !(appointment?.arrivalWindowStart && appointment?.arrivalWindowEnd))) return showNotification({ message: "Important: Please select a timeslot and try again", type: NOTIFICATION_TYPES.ERROR })
        if (appointment?.technicianIds?.length === 0) {
            setIsOpenProceedWithEmptyTechniciansConfirmationModal(true)
        } else {
            onSubmit()
        }
    }

    function bookJobWithoutTechnicians() {
        setIsOpenProceedWithEmptyTechniciansConfirmationModal(false)
        onSubmit()
    }

    return <Box id={CREATE_JOB_SECTION_IDS.MAIN} sx={{ display: "flex", flexDirection: "column", flex: "1 2 0", width: "100%", overflowX: "hidden", overflowY: "scroll", paddingRight: "1em" }} >
        <form onSubmit={checkIfBookingWithEmptyTechnicians}>
            <Prompt
                when={Boolean(getShouldPrompt()) ?? false}
                message={getPromptMessage}
            />
            <Box id={CREATE_JOB_SECTION_IDS.CUSTOMER} sx={{ display: 'flex', flexDirection: "row", width: "100%", justifyContent: "left", alignItems: "center", marginBottom: "0.5em", paddingTop: "1em", gap: "0.5em" }}>
                <h4>Customer</h4>
                <Tooltip enterDelay={1000} enterTouchDelay={1000} title={isNewCustomer ? "Create job for an existing customer" : "Create job for a new customer"}><Button data-test={"job_customer_type"} sx={{ color: "#00a79d" }} variant="text" onClick={onCustomerToggleChanged}>{isNewCustomer ? " Existing" : "\u002B New"}</Button></Tooltip>
                {isNewCustomer ? <></> : <Tooltip title="Edit Customer" ><IconButton data-test="job_create_service_titan_edit_customer" onClick={onCustomerEditPressed} disabled={!selectedCustomer || isEditCustomer} size="small" ><EditIcon color={selectedCustomer && !isEditCustomer ? "primary" : "disabled"} /></IconButton></Tooltip>}
                {<ConfigMessage sectionId={isNewCustomer ? CONFIG_MESSAGES.SERVICETITAN.NEW_CUSTOMER.id : CONFIG_MESSAGES.SERVICETITAN.EXISTING_CUSTOMER.id} />}
            </Box>
            <CustomerDetailsSection
                franchise={franchise}
                isNewCustomer={isNewCustomer}
                newCustomer={newCustomer}
                setNewCustomer={setNewCustomer}
                customers={customers}
                isCustomersLoading={isCustomersLoading}
                selectedCustomer={selectedCustomer}
                setSelectedCustomer={setSelectedCustomer}
                location={location} setLocation={setLocation}
                isBillingSameAsLocation={isBillingSameAsLocation}
                setIsBillingSameAsLocation={setIsBillingSameAsLocation}
                isEditCustomer={isEditCustomer}
                editedCustomer={editedCustomer}
                setEditedCustomer={setEditedCustomer}
                onEditDone={onCustomerEditDone}
                onEditClose={onCustomerEditPressed}
                showNotification={showNotification}
                customerSearchCount={customerSearchCount}
                isDisabled={isDisabled}
                customerPhoneNumber={customerPhoneNumber}
                setCustomerPhoneNumber={setCustomerPhoneNumber}
                customerZipCode={customerZipCode}
                setCustomerZipCode={setCustomerZipCode}
                customerName={customerName}
                setCustomerName={setCustomerName}
                SearchCustomer={SearchCustomer}
                setPreviousAppointmentForRescheduleJob={setPreviousAppointmentForRescheduleJob}
                setjobForTheAppointment={setjobForTheAppointment}
                setisOpenAppointmentRescheduleModal={setisOpenAppointmentRescheduleModal}
                jobNote={jobNote}
                setJobNote={setJobNote}
            />
            <ContactsSection
                franchiseId={franchise?.id}
                customerId={selectedCustomer?.id}
                isNewCustomer={isNewCustomer}
                contacts={contacts}
                setContacts={setContacts}
                existingContacts={existingContacts}
                setExistingContacts={setExistingContacts}
                showNotification={showNotification}
                currentlyEditingContactsCount={currentlyEditingContactsCount}
                setCurrentlyEditingContactsCount={setCurrentlyEditingContactsCount}
            />
            <LocationDetailsSection
                franchise={franchise}
                isNewCustomer={isNewCustomer}
                location={location}
                setLocations={setLocations}
                setLocation={setLocation}
                newCustomer={newCustomer}
                setNewCustomer={setNewCustomer}
                isBillingSameAsLocation={isBillingSameAsLocation}
                setIsBillingSameAsLocation={setIsBillingSameAsLocation}
                selectedCustomer={selectedCustomer}
                locations={locations}
                isLocationsLoading={isLocationsLoading}
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
                isNewLocationForExistingCustomer={isNewLocationForExistingCustomer}
                setIsNewLocationForExistingCustomer={setIsNewLocationForExistingCustomer}
                serviceableZipCodeResponse={showingServiceableZipCodeResponse}
                isEditLocation={isEditLocation}
                setIsEditLocation={setIsEditLocation}
                editedLocation={editedLocation}
                setEditedLocation={setEditedLocation}
                states={states}
                showNotification={showNotification}
                editedCustomer={editedCustomer}
                setEditedCustomer={setEditedCustomer}
                onBillingAdressEditDone={onCustomerEditDone}
                setIsAddressSelected={setIsAddressSelected}
                setIsAddressChanged={setIsAddressChanged}
                isAddressChanged={isAddressChanged}
            />
            <BillingAddressSection
                franchise={franchise}
                isNewCustomer={isNewCustomer}
                location={location}
                newCustomer={newCustomer}
                isEditCustomer={isEditCustomer}
                states={states}
                setNewCustomer={setNewCustomer}
                selectedCustomer={selectedCustomer}
                editedCustomer={editedCustomer}
                setEditedCustomer={setEditedCustomer}
                isBillingSameAsLocation={isBillingSameAsLocation}
                setIsBillingSameAsLocation={setIsBillingSameAsLocation}
                setCustomers={setCustomers}
                setSelectedCustomer={setSelectedCustomer}
                customers={customers}
                showNotification={showNotification}
            />
            <CreateJobSection franchise={franchise} brand={brand}
                isEditJob={Boolean(selectedJobToEdit)} job={job} setJob={setJob} onTagTypesChanged={(isEdited) => { }} shouldLoadDetailsForJob={shouldLoadDetailsForJob} setShouldLoadDetailsForJob={setShouldLoadDetailsForJob}
                zipCode={zipCode} />
            {(!job?.jobTypeId || (selectedJobToEdit && !isJobTypeChanged(selectedJobToEdit, previousJobTypeId, job?.jobTypeId))) ? <></> :
                <CreateAppointmentSection
                    franchise={franchise}
                    brand={brand}
                    arrivalWindow={arrivalWindow}
                    job={job}
                    zipCode={selectedJobToEdit ? zipCodeOfEditJob :zipCode}
                    appointment={appointment}
                    setTechnicianCriteria={setTechnicianCriteria}
                    setAppointment={setAppointment}
                />
            }
            <DialogFooter className={'dialog-footer'}>
                <Button data-test="job_cancel" size={"small"} variant="contained" onClick={() => { setShouldSaveDraft(true) }} className={"btn-secondary"}>Cancel</Button>
                {!selectedJobToEdit &&
                    <Button data-test="job_create_lead" size={"small"}
                        onClick={createALead}
                        variant="contained" className={canCreateSTLead(franchise) ? "button-secondary" : "btn-disable"} >
                        Create a lead
                    </Button>
                }
                <LoadingButton data-test="job_create" type={"submit"} size={"small"} variant="contained" loading={false} className={isCreateJobLoading ? "btn-disable" : "btn-primary"} >
                    {selectedJobToEdit ? "Update Job" : "Create Job"}
                </LoadingButton>
            </DialogFooter>
        </form >
        {isCreateJobLoading && <Loading loadingMessage={selectedJobToEdit ? "Updating Job" : "Creating Job"} isOpen={isCreateJobLoading} />
        }
        {isDraftOrLeadModalOpen ? <DraftOrLeadModal isOpen={isDraftOrLeadModalOpen} onSaveDraft={saveDraft} onCreateLead={createALead} onCloseHandler={onDraftOrLeadModalClosed} /> : <></>}
        {isOpenConfirmCreateNewCustomerModal ? <NewCustomerCreateConfirmModal isOpen={isOpenConfirmCreateNewCustomerModal} wantToCreateNewCustomer={onCustomerToggleChanged} onCloseHandler={handleCloseCreateNewCustomerConfirmModal} /> : <></>}
        {isOpenConfirmLocationModal ? <LocationConfirmModal isOpen={isOpenConfirmLocationModal} confirmLocation={confirmLocation} onCloseHandler={handleCloseConfirmLocatinModal} /> : <></>}
        {isOpenZipCompariosnModal ? <ZipCodeComparisonModal isOpen={isOpenZipCompariosnModal} onCloseHandler={handleCloseZipComparisonModal} /> : <></>}
        {isOpenAppointmentRescheduleModal ? <PreviousAppointmentRescheduleModal franchise={franchise} brand={brand} showNotification={showNotification} isOpen={isOpenAppointmentRescheduleModal} onCloseHandler={handleClosePreviousAppointmentRescheduleModal} appointmentToReschedule={previousAppointmentForRescheduleJob} job={jobForTheAppointment} /> : <></>}
        {isOpenProceedWithEmptyTechniciansConfirmationModal ? <EmptyTechnicianConfirmationModal isOpen={isOpenProceedWithEmptyTechniciansConfirmationModal} onCloseHandler={() => setIsOpenProceedWithEmptyTechniciansConfirmationModal(false)} wantToBookJobWithEmptyTechnicians={bookJobWithoutTechnicians} /> : <></>}
    </Box >
}

function getShouldCreateDraft(isNewCustomer, customerPhoneNumber, customerZipCode, newCustomer, location, contacts, previousContacts, job) {
    const isSearchDataAvailable = customerPhoneNumber || customerZipCode
    const isCustomerDataSufficient = getIsNewCustomerSufficientToDraft(newCustomer)
    const isLocationDataSufficient = getIsLocationSufficientToDraft(location)
    const isContactsSufficient = getIsContactsSufficientToDraft(isNewCustomer, contacts, previousContacts)
    const isJobSectionSufficient = getIsJobSufficentToDraft(job);
    const shouldCreateDraft = (isSearchDataAvailable || isCustomerDataSufficient || isLocationDataSufficient || isContactsSufficient || isJobSectionSufficient)
    return shouldCreateDraft;
}

function getIsNewCustomerSufficientToDraft(newCustomer) {
    const isCustomerSufficient = newCustomer?.name || newCustomer?.type !== CUSTOMER_TYPES[0] || isAddressSufficientToDraft(newCustomer?.address)
    return isCustomerSufficient
}

function getIsLocationSufficientToDraft(location) {
    const isLocationSufficient = location?.name || isAddressSufficientToDraft(location?.address)
    return isLocationSufficient
}

function isAddressSufficientToDraft(address) {
    return address?.street || address?.unit || address?.city || address?.state || address?.zip || address?.country
}

function getIsContactsSufficientToDraft(isNewCustomer, contacts, previousContacts) {
    const isContactSufficientToDraft = isNewCustomer ? contacts?.length > 1 || isContactDataSufficentToDraft(contacts?.[0]) || previousContacts?.length > 1 || isContactDataSufficentToDraft(previousContacts?.[0]) : contacts?.length > 1 || isContactDataSufficentToDraft(contacts?.[0]) || previousContacts?.length > 1 || isContactDataSufficentToDraft(previousContacts?.[0])
    return isContactSufficientToDraft
}

function isContactDataSufficentToDraft(contact) {
    return contact?.type || contact?.value || contact?.memo
}

function getIsJobSufficentToDraft(job) {
    return job?.businessUnitId || job?.campaignId || job?.jobTypeId || (job?.priority !== PRIORITIES[2]) || job?.summary || job?.tagTypeIds?.length > 0
}

function getIsAppointmentSufficientToDraft(appointment) {
    return appointment?.start || appointment?.end || appointment?.arrivalWindowStart || appointment?.arrivalWindowEnd || appointment?.technicianIds?.length > 0
}

function getShouldUpdateDraft(oldDraft, newDraft) {
    const isDifferentFranchise = oldDraft?.franchiseId !== newDraft?.franchiseId
    const isDifferentFlow = oldDraft?.isNewCustomer !== newDraft?.isNewCustomer || oldDraft?.isNewLocation !== newDraft?.isNewLocation || oldDraft?.isBillingAddressSameAsLocation !== newDraft?.isBillingAddressSameAsLocation
    const isDifferentSearchData = oldDraft?.callerId !== newDraft?.callerId || oldDraft?.zipCode !== newDraft?.zipCode
    const isDifferentCustomerData = getIsDifferentNewCustomerDetails(oldDraft?.createCustomerRequest, newDraft?.createCustomerRequest) || getIsDifferentExistingCustomer(oldDraft?.jobCreateRequest?.customerId, newDraft?.jobCreateRequest?.customerId ?? 0)
    const isDifferentLocationData = getIsDifferentNewLocationDetails(oldDraft?.createCustomerRequest?.locations?.[0], newDraft?.createCustomerRequest?.locations?.[0]) || getIsDifferentExistingLocation(oldDraft?.jobCreateRequest?.locationId, newDraft?.jobCreateRequest?.locationId ?? 0) || (oldDraft?.isWithinServiceArea !== newDraft?.isWithinServiceArea && newDraft?.isWithinServiceArea)
    const isDifferentContactData = getIsDifferentContactDetails(oldDraft?.createCustomerRequest?.contacts, newDraft?.createCustomerRequest?.contacts)
    const isDifferentPreviousContactData = getIsDifferentContactDetails(oldDraft?.previousContacts, newDraft?.previousContacts)
    const isDifferentJobData = getIsDifferentJobDetails(oldDraft?.jobCreateRequest, newDraft?.jobCreateRequest)
    const isDifferentAppointmentData = getIsDifferentAppointmentDetails(oldDraft?.jobCreateRequest?.appointments?.[0], newDraft?.jobCreateRequest?.appointments?.[0])
    const shouldUpdateDraft = isDifferentFranchise || isDifferentFlow || isDifferentSearchData || isDifferentCustomerData || isDifferentLocationData || isDifferentContactData || isDifferentPreviousContactData || isDifferentJobData || isDifferentAppointmentData
    return shouldUpdateDraft
}

function getIsDifferentAppointmentDetails(oldAppointment, newAppointment) {
    return (newAppointment?.start && oldAppointment?.start !== newAppointment?.start) || (newAppointment?.end && oldAppointment?.end !== newAppointment?.end) || (newAppointment?.arrivalWindowStart && oldAppointment?.arrivalWindowStart !== newAppointment?.arrivalWindowStart) || (newAppointment?.arrivalWindowEnd && oldAppointment?.arrivalWindowEnd !== newAppointment?.arrivalWindowEnd) || isDifferentArrays(oldAppointment?.technicianIds, newAppointment?.technicianIds)
}

function getIsDifferentJobDetails(oldJob, newJob) {
    return (oldJob?.campaignId !== (newJob?.campaignId ?? 0)) || (oldJob?.businessUnitId !== (newJob?.businessUnitId ?? 0)) || (oldJob?.jobTypeId !== (newJob?.jobTypeId ?? 0)) || oldJob?.priority !== newJob?.priority || ((oldJob?.summary ?? "") !== (newJob?.summary ?? "")) || isDifferentArrays(oldJob?.tagTypeIds, newJob?.tagTypeIds)
}

function getIsDifferentContactDetails(oldContacts, newContacts) {
    return isDifferentArrays(oldContacts, newContacts)
}

function getIsDifferentExistingLocation(oldLocatioinId, newLocationId) {
    return oldLocatioinId !== newLocationId
}

function getIsDifferentNewLocationDetails(oldLocation, newLocation) {
    return oldLocation?.name !== newLocation?.name || getIsDifferentAddresses(oldLocation?.address, newLocation?.address)
}

function getIsDifferentExistingCustomer(oldCustomerId, newCustomerId) {
    return oldCustomerId !== newCustomerId
}

function getIsDifferentNewCustomerDetails(oldCustomer, newCustomer) {
    return oldCustomer?.name !== newCustomer?.name || oldCustomer?.type !== newCustomer?.type || getIsDifferentAddresses(oldCustomer?.address, newCustomer?.address)
}

function getIsDifferentAddresses(address1, address2) {
    return address1?.street !== address2?.street || address1?.unit !== address2?.unit || address1?.city !== address2?.city || address1?.state !== address2?.state || address1?.zip !== address2?.zip || address1?.country !== address2?.country
}

function getIsNewCustomerComplete(customer) {
    return customer?.name
}

function getIsNewCustomerLocationComplete(location, customer, isServiceable) {
    return location?.name && getIsAddressComplete(location?.address) && getIsAddressComplete(customer?.address) && isServiceable
}

function getIsAddressComplete(address) {
    return address && address?.street && address?.city && address?.state && address?.country && isValidZipCode(address?.zip, address?.country)
}

function getIsJobSectionComplete(job) {
    return job && job?.campaignId && job?.businessUnitId && job?.jobTypeId && job?.priority && job?.summary
}

function getAppointmentSectionComplete(appointment) {
    return appointment?.start && appointment?.end && appointment?.arrivalWindowStart && appointment?.arrivalWindowEnd
}

function isJobTypeChanged(selectedJobToEdit, previousJobTypeId, currentJobTypeId) {
    return selectedJobToEdit && previousJobTypeId !== currentJobTypeId
}

async function createNewJob(franchise, job, customerId, locationId, appointment, uniqueId, isInitialRequest) {
    let jobResponse
    try {
        const jobRequest = getNewJobRequest(job, customerId, locationId, appointment)
        jobResponse = await createServiceTitanJobs(franchise.service_titan_tenant_id, franchise.service_titan_client_id, franchise.service_titan_client_secret, jobRequest, uniqueId, isInitialRequest);
        return jobResponse
    } catch (err) {
        throw err
    }
}

async function handleUpdateJob(franchise, job, customerId, locationId) {
    let jobResponse
    try {
        const jobRequest = getNewJobRequest(job, customerId, locationId)
        jobResponse = await updateServiceTitanJob(franchise.service_titan_tenant_id, franchise.service_titan_client_id, franchise.service_titan_client_secret, job?.id, jobRequest);

        return jobResponse
    } catch (err) {
        throw err
    }
}

function getNewJobRequest(job, customerId, locationId, appointment) {
    return {
        ...job,
        customerId: customerId,
        locationId: locationId,
        shouldUpdateInvoiceItems: true,
        customFields: [],
        appointments: [appointment],
        externalData: undefined
    };
}

async function createNewCustomer(franchise, newCustomer, location, contacts) {
    try {
        if (!franchise) return
        const customerCreateRequest = getCreateCustomerRequest(newCustomer, location, contacts)
        const customerResponse = await createFranchiseCustomer(franchise.service_titan_tenant_id, franchise.service_titan_client_id, franchise.service_titan_client_secret, customerCreateRequest)
        return customerResponse
    } catch (err) {
        throw err
    }
}

async function createNewLocation(franchise, customerId, location) {
    try {
        if (!franchise || !customerId) return
        const locationCreateRequest = {
            ...location,
            customerId: customerId
        }
        const locationResponse = await createCustomerLocation(franchise.service_titan_tenant_id, franchise.service_titan_client_id, franchise.service_titan_client_secret, locationCreateRequest)
        return locationResponse
    } catch (err) {
        throw err
    }
}

async function createContacts(franchise, customerId, contacts) {
    try {
        for (let i = 0; i < contacts.length; i++) {
            const contact = contacts[i]
            await createCustomerContact(franchise?.service_titan_tenant_id, franchise?.service_titan_client_id, franchise?.service_titan_client_secret, customerId, contact)
        }
    } catch (err) {
        throw err
    }
}

function getServiceTitanCreateDraftBody(brandId, franchiseId, crm, callerId, zipCode, createCustomerRequest, createLocationRequest = {}, createJobRequest, previousContacts, isNewCustomer, isNewLocation, isLead = false, isServiceableZipCode, isBillingAddressSameAsLocation) {
    const trimedCRM = crm?.trim?.()
    return {
        brandId: brandId,
        franchiseId: franchiseId,
        crm: trimedCRM,
        callerId: callerId,
        zipCode: zipCode,
        createCustomerRequest: createCustomerRequest,
        createLocationRequest: createLocationRequest,
        jobCreateRequest: createJobRequest,
        previousContacts: previousContacts,
        isBillingAddressSameAsLocation: isBillingAddressSameAsLocation,
        isWithinServiceArea: isServiceableZipCode,
        isDeleted: false,
        isDraft: true,
        isNewCustomer: isNewCustomer,
        isNewLocation: isNewLocation,
        isLead: isLead
    }
}

function getCreateCustomerRequest(newCustomer, location, contacts) {
    return {
        name: newCustomer?.name ?? "",
        type: newCustomer?.type ?? CUSTOMER_TYPES[0],
        locations: [location],
        address: newCustomer?.address ?? {},
        contacts: contacts,
    }
}

async function createDraft(draftBody) {
    try {
        const newDraft = await createServiceTitanJobDraft(draftBody);
        return newDraft;
    } catch (err) {
        throw err
    }
}

async function updateDraft(id, draftBody) {
    try {
        const jobDraft = {
            ...draftBody,
            id: id
        }
        return await updateServiceTitanJobDraft(jobDraft)
    } catch (err) {
        throw err
    }
}

export function removeCallDetailsFromLocalStorage() {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.media)
    localStorage.removeItem(LOCAL_STORAGE_KEYS.channelName)
    localStorage.removeItem(LOCAL_STORAGE_KEYS.channelId)
    localStorage.removeItem(LOCAL_STORAGE_KEYS.callerId)
    localStorage.removeItem(LOCAL_STORAGE_KEYS.zipCode)
    localStorage.removeItem(LOCAL_STORAGE_KEYS.brandId)
    localStorage.removeItem(LOCAL_STORAGE_KEYS.jobId)
    localStorage.removeItem(LOCAL_STORAGE_KEYS.brandPrimaryId)
}