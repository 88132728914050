import { Autocomplete, Box, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material"
import { AVAILABLE_CRMS, FRANCHISE_STATUS, userLevels } from "../../../../../constants/common"
import { getById } from "../../../../../utils/miscellaneous"
import ReplayIcon from '@mui/icons-material/Replay';
import AddIcon from "@mui/icons-material/Add";
import { handleGetBusinessUnits, handleGetCampaigns, handleGetJobTypes } from "../../../../../state-services-dublicate/serviceTitan"
import CustomTextField from "../../../../../components/CustomTextField";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { UserContext } from "../../../../../contexts/User";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import AngieListDeleteDialog from "./AngieListDeleteDialog";
import { USER_TYPES } from "../../../../../constants/users";

const INITIAL_CONFIGURATIONS = { servicetitan_campaign_id: 0, servicetitan_business_unit_id: 0, servicetitan_job_type_id: 0 }

const LabelWithAsterisk = ({ label }) => (
    <span>
        {label}
        <span style={{ color: 'red' }}> *</span>
    </span>
);

export default function AngiesServicetitanConfigurationsSection(props) {
    const { oldFranchise, angiesTaskNameConfigurations, setAngiesTaskNameConfigurations, angiesConfigurations, setAngiesConfigurations } = props

    const { me } = useContext(UserContext)
    const [servicetitanCampaigns, setServicetitanCampaigns] = useState([])
    const [isServiceTitanCampaignsLoading, setIsServiceTitanCampaignsLoading] = useState(false)
    const [isServiceTitanCampaignsLoadingError, setIsServiceTitanCampaignsLoadingError] = useState(false)
    const [toggleGetServiceTitanCampaigns, setToggleGetServiceTitanCampaigns] = useState(true)
    const [servicetitanBusinessUnits, setServicetitanBusinessUnits] = useState([])
    const [isServiceTitanBusinessUnitsLoading, setIsServiceTitanBusinessUnitsLoading] = useState(false)
    const [isServiceTitanBusinessUnitsLoadingError, setIsServiceTitanBusinessUnitsLoadingError] = useState(false)
    const [toggleGetServiceTitanBusinessUnits, setToggleGetServiceTitanBusinessUnits] = useState(true)
    const [servicetitanJobTypes, setServicetitanJobTypes] = useState([])
    const [isServiceTitanJobTypesLoading, setIsServiceTitanJobTypesLoading] = useState(false)
    const [isServiceTitanJobTypesLoadingError, setIsServiceTitanJobTypesLoadingError] = useState(false)
    const [toggleGetServiceTitanJobTypes, setToggleGetServiceTitanJobTypes] = useState(true)
    const [newConfigurations, setNewConfigurations] = useState(INITIAL_CONFIGURATIONS)
    const [editableConfigurations, setEditableConfigurations] = useState(null)
    const [editableConfigurationsIndex, setEditableConfigurationsIndex] = useState(null)

    useEffect(() => {
        if (oldFranchise?.id && oldFranchise?.service_brand === AVAILABLE_CRMS.servicetitan.value) { //FIXME: only edit option will load the existing campaigns
            return handleGetCampaigns(oldFranchise?.id, undefined, setServicetitanCampaigns, () => { }, setIsServiceTitanCampaignsLoading, setIsServiceTitanCampaignsLoadingError)
        }
    }, [oldFranchise?.id, oldFranchise?.service_brand, toggleGetServiceTitanCampaigns])

    useEffect(() => {
        if (oldFranchise?.id && oldFranchise?.service_brand === AVAILABLE_CRMS.servicetitan.value) { //FIXME: only edit option will load the existing business units
            return handleGetBusinessUnits(oldFranchise?.id, undefined, setServicetitanBusinessUnits, () => { }, setIsServiceTitanBusinessUnitsLoading, setIsServiceTitanBusinessUnitsLoadingError)
        }
    }, [oldFranchise?.id, oldFranchise?.service_brand, toggleGetServiceTitanBusinessUnits])

    useEffect(() => {
        if (oldFranchise?.id && oldFranchise?.service_brand === AVAILABLE_CRMS.servicetitan.value) { //FIXME: only edit option will load the existing business units
            return handleGetJobTypes(oldFranchise?.id, undefined, false, false, setServicetitanJobTypes, () => { }, setIsServiceTitanJobTypesLoading, setIsServiceTitanJobTypesLoadingError)
        }
    }, [oldFranchise?.id, oldFranchise?.service_brand, toggleGetServiceTitanJobTypes])

    function getCampaignsHelperText() {
        if (oldFranchise?.service_brand !== AVAILABLE_CRMS.servicetitan.value) {
            return "Franchise is not using Servicetitan"
        } else if (isServiceTitanCampaignsLoadingError) {
            return "Temporary failure"
        } else if (!oldFranchise?.service_titan_client_id || !oldFranchise?.service_titan_client_id) {
            return "Please update Servicetitan credentials"
        } else {
            return "No Campaigns Found"
        }
    }

    return <>
        <Box sx={{ marginBlock: "0.8em", flexGrow: 4, display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Autocomplete
                sx={{ flexGrow: 2 }}
                size="small"
                disabled={oldFranchise?.is_enabled === FRANCHISE_STATUS.deleted || me?.level <= userLevels.Supervisor}
                options={servicetitanCampaigns}
                loading={isServiceTitanCampaignsLoading}
                noOptionsText={getCampaignsHelperText()}
                value={servicetitanCampaigns?.length > 0 && angiesConfigurations?.servicetitan_campaign_id ? getById(angiesConfigurations?.servicetitan_campaign_id, servicetitanCampaigns) : null}
                onChange={(e, c) => {
                    setAngiesConfigurations((oldC) => {
                        return {
                            ...oldC,
                            servicetitan_campaign_id: c?.id
                        }
                    })
                }}
                getOptionLabel={(c) => `${c?.name}`}
                renderOption={(props, c) => {
                    return <Typography {...props} data-test={`franchise_angies_campaign_${c.name}`} key={`${c.id}`}>{c.name}</Typography>;
                }}
                renderInput={(props) => <CustomTextField {...props} inputProps={{ ...props.inputProps, "data-test": "franchise_angies_campaign" }} label={"Angie's Servicetitan Campaign"} />} />
            <Tooltip title={"Refresh"}>
                <span style={{ height: "100%", margin: "auto" }}>
                    <IconButton
                        data-test="franchise_angies_refresh"
                        disabled={isServiceTitanJobTypesLoading || me?.level <= userLevels.Supervisor || oldFranchise?.is_enabled === FRANCHISE_STATUS.deleted}
                        onClick={() => setToggleGetServiceTitanCampaigns((t) => !t)}
                    >
                        <ReplayIcon fontSize='0.8em' />
                    </IconButton>
                </span>
            </Tooltip>
        </Box>
        {me?.level > USER_TYPES?.supervisor?.level &&
            <AddNewConfigurationSection
                newConfigurations={newConfigurations}
                setNewConfigurations={setNewConfigurations}
                configurations={angiesTaskNameConfigurations}
                setConfigurations={setAngiesTaskNameConfigurations}
                oldFranchise={oldFranchise}
                me={me}
                servicetitanJobTypes={servicetitanJobTypes}
                isServiceTitanJobTypesLoading={isServiceTitanJobTypesLoading}
                isServiceTitanJobTypesLoadingError={isServiceTitanJobTypesLoadingError}
                setToggleGetServiceTitanJobTypes={setToggleGetServiceTitanJobTypes}
                servicetitanBusinessUnits={servicetitanBusinessUnits}
                isServiceTitanBusinessUnitsLoading={isServiceTitanBusinessUnitsLoading}
                isServiceTitanBusinessUnitsLoadingError={isServiceTitanBusinessUnitsLoadingError}
                setToggleGetServiceTitanBusinessUnits={setToggleGetServiceTitanBusinessUnits}
                onAddNewConfiguration={(config) => {
                    setAngiesTaskNameConfigurations((c) => [...c, config])
                }}
            />
        }
        <ConfigurationTable setNewConfigurations={setNewConfigurations} configurations={angiesTaskNameConfigurations} setConfigurations={setAngiesTaskNameConfigurations} businessUnits={servicetitanBusinessUnits} jobTypes={servicetitanJobTypes} readOnly={oldFranchise?.is_enabled === FRANCHISE_STATUS.deleted || me?.level <= userLevels.Supervisor} setEditableConfigurations={setEditableConfigurations} editableConfigurations={editableConfigurations} servicetitanJobTypes={servicetitanJobTypes} isServiceTitanJobTypesLoading={isServiceTitanJobTypesLoading} servicetitanBusinessUnits={servicetitanBusinessUnits} isServiceTitanBusinessUnitsLoading={isServiceTitanBusinessUnitsLoading} editableConfigurationsIndex={editableConfigurationsIndex}
            setEditableConfigurationsIndex={setEditableConfigurationsIndex} />
    </>
}

function AddNewConfigurationSection({ newConfigurations, setNewConfigurations, configurations, setConfigurations, oldFranchise, me, servicetitanJobTypes, isServiceTitanJobTypesLoading, isServiceTitanJobTypesLoadingError, setToggleGetServiceTitanJobTypes, servicetitanBusinessUnits, isServiceTitanBusinessUnitsLoading, isServiceTitanBusinessUnitsLoadingError, setToggleGetServiceTitanBusinessUnits }) {
    const taskNameRef = useRef(null)
    const jobTypeRef = useRef(null)
    const businessUnitRef = useRef(null)
    const [duplicatedValue, setDuplicatedValue] = useState()
    const [alreadyExistsValue, setAlreadyExistsValue] = useState()

    const checkDuplicateTaskName = useMemo(() => {
        const taskNames = newConfigurations?.task_name?.trim?.().split(",")
        const uniqueValues = new Set();
        if (!taskNames) return false
        for (const value of taskNames) {
            if (uniqueValues.has(value.trim().toLowerCase())) {
                setDuplicatedValue(value)
                return true;
            }
            uniqueValues.add(value.trim().toLowerCase());
        }
        setDuplicatedValue()
        return false
    }, [newConfigurations.task_name, configurations])

    const isTaskNameAlreadyExists = useMemo(() => {
        const taskNames = newConfigurations?.task_name?.trim?.().split(",")
        const existingName = taskNames?.find((taskName) =>
            configurations?.some((c) => c?.task_name?.toLowerCase?.() === taskName?.toLowerCase?.().trim?.())
        );

        if (!existingName || existingName === undefined) {
            setAlreadyExistsValue()
            return false
        } else {
            setAlreadyExistsValue(existingName)
            return true
        }

    }, [newConfigurations.task_name, configurations])

    return <Grid item xs={12}>
        <Box sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "start",
            gap: "0.5em"
        }}>
            <Box sx={{ marginBlock: "1em", flexGrow: 2, flexDirection: "row", alignItems: "start" }}>
                <CustomTextField inputProps={{ "data-test": "franchise_angies_task_name" }} inputRef={taskNameRef} error={isTaskNameAlreadyExists || checkDuplicateTaskName} helperText={isTaskNameAlreadyExists ? `Already exists - ${alreadyExistsValue}` : checkDuplicateTaskName ? `Duplicate - ${duplicatedValue}` : ""} label="Task Name" sx={{ width: "100%" }} size="small" disabled={oldFranchise?.is_enabled === FRANCHISE_STATUS.deleted || me?.level <= userLevels.Supervisor} value={newConfigurations.task_name ?? ""} onChange={(e) => {
                    setNewConfigurations((config) => {
                        return { ...config, task_name: e.target.value };
                    });
                }} />
            </Box>
            <Box sx={{ marginBlock: "1em", flexGrow: 4, display: "flex", flexDirection: "row", alignItems: "start" }}>
                <Autocomplete
                    sx={{ flexGrow: 2 }}
                    size="small"
                    disabled={oldFranchise?.is_enabled === FRANCHISE_STATUS.deleted || me?.level <= userLevels.Supervisor}
                    options={servicetitanJobTypes}
                    loading={isServiceTitanJobTypesLoading}
                    noOptionsText={oldFranchise?.service_brand === AVAILABLE_CRMS.servicetitan.value ? isServiceTitanJobTypesLoadingError ? "Temporary failure" : "No Job Types Found" : "Franchise is not using Servicetitan"}
                    value={servicetitanJobTypes?.length > 0 && newConfigurations?.servicetitan_job_type_id ? getById(newConfigurations?.servicetitan_job_type_id, servicetitanJobTypes) : null}
                    onChange={(e, j) => {
                        setNewConfigurations((config) => {
                            return { ...config, servicetitan_job_type_id: j?.id };
                        });
                    }}
                    getOptionLabel={(c) => `${c?.name}`}
                    renderOption={(props, c) => {
                        return <Typography {...props} data-test="franchise_angies_job_type" key={`${c.id}`}>{c.name}</Typography>;
                    }}
                    renderInput={(props) => <CustomTextField {...props} inputProps={{ ...props.inputProps, "data-test": "franchise_angies_job_type" }} inputRef={jobTypeRef} label={<LabelWithAsterisk label="Servicetitan Job Type" />} />} />
                <Tooltip title={"Refresh"}>
                    <span style={{ height: "100%", margin: "auto" }}>
                        <IconButton
                            data-test="franchise_angies_job_type_refresh"
                            disabled={isServiceTitanJobTypesLoading || me?.level <= userLevels.Supervisor || oldFranchise?.is_enabled === FRANCHISE_STATUS.deleted}
                            onClick={() => setToggleGetServiceTitanJobTypes((t) => !t)}
                        >
                            <ReplayIcon fontSize='0.8em' />
                        </IconButton>
                    </span>
                </Tooltip>
            </Box>
            <Box sx={{ marginBlock: "1em", flexGrow: 4, display: "flex", flexDirection: "row", alignItems: "start" }}>
                <Autocomplete
                    sx={{ flexGrow: 2 }}
                    size="small"
                    disabled={oldFranchise?.is_enabled === FRANCHISE_STATUS.deleted || me?.level <= userLevels.Supervisor}
                    options={servicetitanBusinessUnits}
                    loading={isServiceTitanBusinessUnitsLoading}
                    noOptionsText={oldFranchise?.service_brand === AVAILABLE_CRMS.servicetitan.value ? isServiceTitanBusinessUnitsLoadingError ? "Temporary failure" : "No Business Units Found" : "Franchise is not using Servicetitan"}
                    value={servicetitanBusinessUnits?.length > 0 && newConfigurations?.servicetitan_business_unit_id ? getById(newConfigurations?.servicetitan_business_unit_id, servicetitanBusinessUnits) : null}
                    onChange={(e, b) => {
                        setNewConfigurations((c) => {
                            return { ...c, servicetitan_business_unit_id: b?.id };
                        });
                    }}
                    getOptionLabel={(b) => `${b?.name}`}
                    renderOption={(props, b) => {
                        return <Typography {...props} key={`${b.id}`}>{b.name}</Typography>;
                    }}
                    renderInput={(props) => <CustomTextField {...props} inputProps={{ ...props.inputProps, "data-test": "franchise_angies_business_unit" }} inputRef={businessUnitRef} label={<LabelWithAsterisk label="Servicetitan Business Unit" />} />} />
                <Tooltip title={"Refresh"}>
                    <span style={{ height: "100%", margin: "auto" }}>
                        <IconButton
                            data-test="franchise_angies_business_unit_refresh"
                            disabled={isServiceTitanBusinessUnitsLoading || me?.level <= userLevels.Supervisor || oldFranchise?.is_enabled === FRANCHISE_STATUS.deleted}
                            onClick={() => setToggleGetServiceTitanBusinessUnits((t) => !t)}
                        >
                            <ReplayIcon fontSize='0.8em' />
                        </IconButton>
                    </span>
                </Tooltip>
                {
                    (oldFranchise?.is_enabled === FRANCHISE_STATUS.deleted || me?.level <= userLevels.Supervisor) ? <></> : <Tooltip title={"Add"}>
                        <span style={{ height: "100%", margin: "auto" }}>
                            <IconButton data-test="franchise_angies_add" sx={{ marginTop: "0.25em" }} size='small' title={`Add`} className="icon-btn"
                                onClick={() => {
                                    if (!newConfigurations.task_name || newConfigurations.task_name.trim() == "" || isTaskNameAlreadyExists || checkDuplicateTaskName) {
                                        taskNameRef.current.focus()
                                        return
                                    } else if (!newConfigurations.servicetitan_job_type_id) {
                                        jobTypeRef.current.focus()
                                    } else if (!newConfigurations.servicetitan_business_unit_id) {
                                        businessUnitRef.current.focus()
                                    } else {
                                        const newAllConfigurations = newConfigurations.task_name.split(",").map((task_name) => {
                                            if (task_name?.trim?.() != "") return { ...newConfigurations, task_name: task_name.trim() }
                                        }).filter(Boolean)
                                        setConfigurations((c) => [...c, ...newAllConfigurations])
                                        setNewConfigurations(INITIAL_CONFIGURATIONS)
                                    }

                                }}
                            >
                                <AddIcon />
                            </IconButton>
                        </span>
                    </Tooltip>}
            </Box>
        </Box>
    </Grid >;
}

function ConfigurationTable(props) {
    const taskNameRef = useRef(null)
    const jobTypeRef = useRef(null)
    const businessUnitRef = useRef(null)
    const { configurations = [], businessUnits = [], jobTypes = [], setConfigurations = (configs = []) => { }, readOnly = false, setEditableConfigurations, editableConfigurations, servicetitanJobTypes, isServiceTitanJobTypesLoading, servicetitanBusinessUnits
        , isServiceTitanBusinessUnitsLoading, editableConfigurationsIndex, setEditableConfigurationsIndex } = props

    const isTaskNameAlreadyExists = useMemo(() => {
        return editableConfigurations && configurations?.some((c) => c?.task_name?.toLowerCase?.() === editableConfigurations?.task_name?.trim?.().toLowerCase?.() && configurations.indexOf(c) !== editableConfigurationsIndex)
    }, [editableConfigurations?.task_name, configurations])

    const cancelEdit = () => {
        setEditableConfigurations(null)
    }

    const saveEdit = () => {
        setConfigurations((configs = []) => {
            const newConfigs = configs.map((c, index) => {
                if (editableConfigurationsIndex === index) {
                    return { ...editableConfigurations, task_name: editableConfigurations.task_name.trim() }
                }
                return c
            })
            return newConfigs
        })
        setEditableConfigurations(null)
    }

    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [configToDeleteIndex, setConfigToDeleteIndex] = useState(null);

    const handleConfirmDelete = () => {
        setConfigurations((configs = []) => {
            const newConfigs = configs.filter((c, i) => i !== configToDeleteIndex);
            return newConfigs;
        });
        setDeleteConfirmationOpen(false);
    };

    const handleCancelDelete = () => {
        setDeleteConfirmationOpen(false);
    };

    return <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2, padding: "1em" }}>
            <TableContainer>
                <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={'small'}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell align="left">Task Name</TableCell>
                            <TableCell align="left" >Job Type</TableCell>
                            <TableCell align="left" >Business Unit</TableCell>
                            {readOnly ? <></> : <TableCell align="center">Actions</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {configurations?.length === 0 ? <TableCell component="th" align={"center"} colSpan={6} scope="row">No Data to Show</TableCell>
                            : configurations?.map?.((c, index) => {
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow hover tabIndex={-1} key={c.name}>
                                        <TableCell sx={{ width: '5%' }} component="th" align={"left"} padding={"1em"} id={labelId} scope="row">{index + 1}</TableCell>
                                        <TableCell sx={{ width: "25%" }} align="left" > {editableConfigurations && editableConfigurationsIndex === index ? (<Box><CustomTextField inputRef={taskNameRef} value={editableConfigurations.task_name} onChange={(e) => {
                                            setEditableConfigurations((c) => {
                                                return { ...c, task_name: e?.target?.value };
                                            });
                                        }} sx={{ width: "100%" }} size="small" inputProps={{ "data-test": "franchise_angies_task_name" }} error={isTaskNameAlreadyExists} helperText={isTaskNameAlreadyExists ? "Already exists" : ""} /></Box>) : (<Tooltip title={c.task_name} ><Typography sx={{ fontSize: "14px", overflow: "hidden", textOverflow: "ellipsis" }}>{c.task_name}</Typography></Tooltip>)} </TableCell>
                                        <TableCell sx={{ width: "25%" }} align="left" >{editableConfigurations && editableConfigurationsIndex === index ? (<Autocomplete
                                            sx={{ flexGrow: 2 }}
                                            size="small"
                                            options={servicetitanJobTypes}
                                            loading={isServiceTitanJobTypesLoading}
                                            value={servicetitanJobTypes?.length > 0 && editableConfigurations?.servicetitan_job_type_id ? getById(editableConfigurations?.servicetitan_job_type_id, servicetitanJobTypes) : null}
                                            getOptionLabel={(s) => `${s?.name}`}
                                            onChange={(e, j) => {
                                                setEditableConfigurations((config) => {
                                                    return { ...config, servicetitan_job_type_id: j?.id };
                                                });
                                            }}
                                            renderOption={(props, s) => {
                                                return <Typography {...props} data-test="franchise_angies_job_type" key={`${s.id}`}>{s.name}</Typography>;
                                            }}
                                            renderInput={(props) => <CustomTextField {...props} inputRef={jobTypeRef} inputProps={{ ...props.inputProps, "data-test": "franchise_angies_job_type" }} label={<LabelWithAsterisk label="Servicetitan Job Type" />} />} />) : getById(c.servicetitan_job_type_id, jobTypes)?.name ?? "-"} </TableCell>
                                        <TableCell sx={{ width: "25%" }} component="th" align={"left"} id={labelId} scope="row">{editableConfigurations && editableConfigurationsIndex === index ? (<Autocomplete
                                            sx={{ flexGrow: 2 }}
                                            size="small"
                                            options={servicetitanBusinessUnits}
                                            loading={isServiceTitanBusinessUnitsLoading}
                                            getOptionLabel={(b) => `${b?.name}`}
                                            value={servicetitanBusinessUnits?.length > 0 && editableConfigurations?.servicetitan_business_unit_id ? getById(editableConfigurations?.servicetitan_business_unit_id, servicetitanBusinessUnits) : null}
                                            onChange={(e, b) => {
                                                setEditableConfigurations((c) => {
                                                    return { ...c, servicetitan_business_unit_id: b?.id };
                                                });
                                            }}
                                            renderOption={(props, b) => {
                                                return <Typography {...props} key={`${b.id}`}>{b.name}</Typography>;
                                            }}
                                            renderInput={(props) => <CustomTextField inputRef={businessUnitRef} {...props} inputProps={{ ...props.inputProps, "data-test": "franchise_angies_business_unit" }} label={<LabelWithAsterisk label="Servicetitan Business Unit" />} />} />) : getById(c.servicetitan_business_unit_id, businessUnits)?.name ?? "-"}</TableCell>
                                        {readOnly ? <></> : <TableCell sx={{ width: "10%" }} align="center" >

                                            {editableConfigurations && editableConfigurationsIndex === index ? (<>
                                                <Tooltip title={"Cancel"}>
                                                    <span>
                                                        <IconButton data-test="franchise_owner_close" color="primary" title='Cancel' onClick={() => cancelEdit()} >
                                                            <CloseIcon style={{ height: "20px", width: "20px" }} />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                                <Tooltip title={"Save"}>
                                                    <span>
                                                        <IconButton data-test="franchise_owner_save" type={"button"} color="primary" title='Save' onClick={
                                                            () => {
                                                                if (!editableConfigurations.task_name || editableConfigurations.task_name.trim() == "" || isTaskNameAlreadyExists) {
                                                                    taskNameRef.current.focus()
                                                                    return
                                                                } else if (!editableConfigurations.servicetitan_job_type_id) {
                                                                    jobTypeRef.current.focus()
                                                                } else if (!editableConfigurations.servicetitan_business_unit_id) {
                                                                    businessUnitRef.current.focus()
                                                                } else {
                                                                    saveEdit()
                                                                }
                                                            }} >
                                                            <SaveIcon style={{ height: "20px", width: "20px" }} />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                            </>) : (<>

                                                <Tooltip title={"Edit"}>
                                                    <span>
                                                        <IconButton onClick={() => {
                                                            setEditableConfigurations(c)
                                                            setEditableConfigurationsIndex(index)
                                                        }} color="info">
                                                            <EditIcon style={{ height: "20px", width: "20px" }} />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>

                                                <Tooltip title={"Delete"}>
                                                    <span style={{ height: "100%", margin: "auto" }}>
                                                        <IconButton
                                                            data-test="franchise_angies_configuration_delete"
                                                            size='small'
                                                            title={`Delete`}
                                                            color="error"
                                                            onClick={() => {
                                                                setConfigToDeleteIndex(index);
                                                                setDeleteConfirmationOpen(true);
                                                            }}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                            </>)}
                                        </TableCell>}
                                    </TableRow>
                                );
                            }) ?? <></>}
                    </TableBody>
                </Table>
            </TableContainer>
            <AngieListDeleteDialog
                open={deleteConfirmationOpen}
                onClose={handleCancelDelete}
                handleConfirmDelete={handleConfirmDelete}
                configurations={configurations}
                configToDeleteIndex={configToDeleteIndex}
            />
        </Paper>
    </Box >;
}