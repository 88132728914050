import styled from "@emotion/styled";
import { FormControl } from "@mui/material";

export const CustomSelect = styled(FormControl)(({theme})=>({
    "& label.Mui-focused": {
        color: "#00a79d",
        fontWeight: "500",
    },
    "& label": {
        fontSize: "15px",
        fontWeight: "300",
        color: theme.palette.fieldLabelColor,
    },

    "& select": {
        backgroundColor: "red",
    },

    "& .MuiInputBase-input": {
        fontSize: "13px",
        backgroundColor: theme.palette.background.paper,
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor:theme.palette.background.icon,
        },
        "&:hover fieldset": {
            borderColor: "#00a79d",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#00a79d",
        },
    },
})); // move to style folder