import { IconButton, TableCell, TableRow, Typography } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { CustomTextField } from '../../../franchiseCustomStyle';
import { imposibleToMatchRegex, isValidEmail, isValidPhoneNumber } from '../../../../../../utils/validators';
import PhoneNumberBox from '../../../../../../components/PhoneNumberBox';
import { getIsEmailAlreadyExists } from '../utils/common';
import { Box } from '@mui/system';
import { MAX_OWNER_NAME_LENGTH } from '../constants/common';

const INVALID_EMAIL = 'Invalid Email Address'
const INVALID_PHONE = "Invaild Phone Number"

export default function EditOwnerRow(props) {
    const { key, owner, existingEmails = [], onOwnerChanged, onCancel, onSave, helperText = "" } = props

    const isInvalidEmail = !isValidEmail(owner.owner_email) || getIsEmailAlreadyExists(owner.owner_email, existingEmails)
    const emailPattern = isInvalidEmail ? imposibleToMatchRegex : undefined
    const isPhoneNumberValid = isValidPhoneNumber(owner?.owner_phone) || !owner.owner_phone
    const phonePattern = isPhoneNumberValid ? undefined : imposibleToMatchRegex
    return <TableRow key={key} >
        <TableCell colSpan={1} >
            <Box sx={{ height: "5em", width: "30em" }} >
                <CustomTextField
                    inputProps={{
                        "data-test": "franchise_owner_name"
                    }}
                    required
                    sx={{ width: "100%" }}
                    value={owner?.owner_name ?? null}
                    error={owner.owner_name ? owner.owner_name.length > MAX_OWNER_NAME_LENGTH : false}
                    helperText={owner.owner_name && owner.owner_name.length > MAX_OWNER_NAME_LENGTH ? "max length exceeded" : ""}
                    onChange={(e) => onOwnerChanged({ ...owner, owner_name: e?.target?.value })}
                    label={"Name"}
                    size={"small"}
                    margin="normal"
                    variant="outlined"
                />
            </Box>
        </TableCell>
        <TableCell colSpan={1} >
            <Box sx={{ height: "5em", width: "20em" }} >
                <CustomTextField
                    required
                    sx={{ width: "100%" }}
                    error={owner.owner_email ? isInvalidEmail : false}
                    inputProps={{ pattern: emailPattern, "data-test": "franchise_owner_email" }}
                    helperText={owner.owner_email && !isValidEmail(owner.owner_email) ? INVALID_EMAIL : getIsEmailAlreadyExists(owner.owner_email, existingEmails) ? "Email already exists" : ""}
                    value={owner.owner_email ?? null}
                    onChange={(e) => onOwnerChanged({ ...owner, owner_email: e?.target?.value?.trim?.() })}
                    size={"small"}
                    label="Email"
                    margin="normal"
                    variant="outlined"
                />
            </Box>
        </TableCell>
        <TableCell colSpan={1} >
            <Box sx={{ height: "5em", width: "15em" }} >
                <PhoneNumberBox
                    sx={{ width: "100%" }}
                    value={owner?.owner_phone ?? null}
                    inputProps={{ pattern: phonePattern }}
                    error={!isPhoneNumberValid}
                    helperText={isPhoneNumberValid ? "" : INVALID_PHONE}
                    onChange={(e) => onOwnerChanged({ ...owner, owner_phone: e?.target?.value })}
                    label={"Phone"}
                    size={"small"}
                    margin="normal"
                    variant="outlined"
                />
            </Box>
        </TableCell>
        <TableCell colSpan={1} align='right'>
            <Box sx={{ display: "flex", alignItems: "center", height: "4em" }} >
                <Typography sx={{ color: "#B00020", marginRight: "1em", margin: "auto" }} >{helperText}</Typography>
                <IconButton data-test="franchise_owner_close" color="primary" title='Cancel' onClick={onCancel}>
                    <CloseIcon style={{ height: "20px", width: "20px" }} />
                </IconButton>
                <IconButton data-test="franchise_owner_save" type={"button"} color="primary" title='Save' onClick={onSave}>
                    <SaveIcon style={{ height: "20px", width: "20px" }} />
                </IconButton>
            </Box>
        </TableCell>
    </TableRow>
}
