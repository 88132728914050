import { getAllFranchises, getFranchiseById } from "../services/franchises";
import { sortFranchisesByActiveState } from "../utils/miscellaneous";

const STATUS_FOR_GET_ALL_FRANCHISES_INCLUDE_REMOVED = 3

export function handleGetFranchiseById(franchiseId, setFranchise) {
    getFranchiseById(franchiseId).then((franchise) => {
        setFranchise(franchise);
    }).catch(() => {
    });
}

export function handleGetAllFranchises(setAllFranchises) {
    getAllFranchises().then(franchises => {
        if (Array.isArray(franchises)) {
            setAllFranchises(franchises)
        }
    }).catch(err => {
    })
}

export function handleGetAllFranchisesIncludeRemoved(setAllFranchises) {
    getAllFranchises(STATUS_FOR_GET_ALL_FRANCHISES_INCLUDE_REMOVED).then(franchises => {
        if (Array.isArray(franchises)) {
            const sortedFranchises = sortFranchisesByActiveState(franchises)
            setAllFranchises(sortedFranchises)
        }
    }).catch(err => {
    })
}