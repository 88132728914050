import moment from "moment-timezone";
import { getJobs } from "../services/jobService";
import { getAbandenedCalls, updateAbandenedCall, getCallJobLeadDetailsByJobId, getOutboundCallsByCallId, getOutboundCallsWithLeadDetails, getAllCallsService } from "../services/callService";

export const getJobsHandler = async (page, callType, flaggedStatus, startDate, countPerPage, setRecords, setCallLogsCount, setLoading, setAbandenedRecords, setAbandenedCallLogsCount, callFor = 0, brandIds=[]) => {
    try {
        setLoading(true)
        let date = moment(startDate).format("YYYY-MM-DD Z");
        let jobsData = null;
        if (callType === 0 || callType === 1 || callType === 2) {
            jobsData = await getJobs(callType, flaggedStatus, date, page, countPerPage, callFor,brandIds)
            setRecords(jobsData ? jobsData.jobs : []);
            setCallLogsCount({ count: jobsData?.count, abandonedCalls: 0 })
        } else {
            const userLevel = localStorage.getItem("user_level")
            let abnDate = moment(startDate).format("YYYY-MM-DD");
            jobsData = await getAbandenedCalls(userLevel, abnDate, page, countPerPage)
            setAbandenedRecords(jobsData ? jobsData.abandonedCallLogs : []);
            setAbandenedCallLogsCount(jobsData?.count)
        }
        setLoading(false)
    } catch (error) {
    } finally {
        setLoading(false)
    }
}

export const updateFollowupStatus = async (id, setLoading, startDate, setAbandenedRecords, setAbandenedCallLogsCount) => {
    try {
        setLoading(true)
        const data = {
            "followup_status": 1
        }
        let jobsData = null;
        await updateAbandenedCall(id, data)
        let date = moment(startDate).format("YYYY-MM-DD")
        const userLevel = localStorage.getItem("user_level")
        jobsData = await getAbandenedCalls(userLevel, date, 1, 10)
        setAbandenedRecords(jobsData ? jobsData.abandonedCallLogs : []);
        setAbandenedCallLogsCount(jobsData?.count)
        setLoading(false)
    } catch (error) {
    } finally {
        setLoading(false)
    }
}

export const getCallJobLeadDetails = async (jobId) => {
    try {
        const jobsData = await getCallJobLeadDetailsByJobId(jobId)
        return jobsData
    } catch (error) {
    }

}

export const getBrandRedirectUrlByBrandId = async (brandId, brands) => {
    let brand = brands.find((brand) => brand.id === brandId)
    return brand?.redirect_url
}

export const getOutboundCallsDetails = async (callId, setIsShowOutboundCallDetails, setIsOutboundCallLoading, setOutboundCallDetails) => {
    try {
        setIsOutboundCallLoading(true)
        await getOutboundCallsByCallId(callId).then((response) => {
            setOutboundCallDetails(response)
            setIsShowOutboundCallDetails(true)
        }).catch((error) => {
            throw error
        }).finally(() => {
            setIsOutboundCallLoading(false)
        })
    } catch (error) {
    }
}

export const getOutboundCallsWithJobLeadDetails = async (page, size, createdOn, status, callFor = 0, brandPrimaryIds =[]) => {
    const dateOnMoment = moment(createdOn)
    try {
        const createdAfter = dateOnMoment.clone().tz('UTC').format("YYYY-MM-DDTHH:mm:ssZ")
        const createdBefore = dateOnMoment.clone().add(23, 'hours').add(59, 'minutes').add(59, 'seconds').tz('UTC').format("YYYY-MM-DDTHH:mm:ssZ")
        const response = await getOutboundCallsWithLeadDetails(page, size, createdAfter, createdBefore, status, callFor,brandPrimaryIds)
        return response
    } catch (error) {
    }
}

export const getAllCalls = async (page, size, createdOn, callFor = 0, brandIds=[], brandPrimaryIds=[]) => {
    const dateOnMoment = moment(createdOn)
    try {
        const date = moment(createdOn).format("YYYY-MM-DD Z")
        const createdAfter = dateOnMoment.clone().tz('UTC').format("YYYY-MM-DDTHH:mm:ssZ")
        const createdBefore = dateOnMoment.clone().add(23, 'hours').add(59, 'minutes').add(59, 'seconds').tz('UTC').format("YYYY-MM-DDTHH:mm:ssZ")
        const response = await getAllCallsService(page, size, date, createdAfter, createdBefore, callFor,brandIds, brandPrimaryIds)
        return response
    } catch (error) {
    }
}
