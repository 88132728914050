import { Autocomplete, Box } from "@mui/material";
import { useEffect, useState } from "react";
import CustomTextField from "../../../../../components/CustomTextField";
import RenderInput from "../../../../../components/servicetitan/RenderInput";
import { CREATE_JOB_SECTION_IDS } from "../../../../../constants/jobs";
import { handleGetJobLables } from "../../../../../state-services-dublicate/vonigo";

export default function MultipleWorkOrdersJobDetails(props) {
    const { brand, franchise, existingJob } = props
    const [jobLables, setJobLables] = useState([])
    const [isJobLablesLoading, setIsJobLablesLoading] = useState(false)

    useEffect(() => {
        handleGetJobLables(brand, franchise, setIsJobLablesLoading, setJobLables);
    }, [brand, franchise])

    return <>
        <h4 id={CREATE_JOB_SECTION_IDS.JOB} style={{ marginBottom: "1em" }}>Job Details</h4>
        <Box sx={{ display: 'flex', flexDirection: "row", flexWrap: "wrap", width: "100%", gap: "1em" }} >
            <Box style={{ width: "100%", }}>
                <CustomTextField disabled={true} multiline rows={3} label="Summary" variant="outlined" size="small" value={existingJob?.summary?.fieldValue ?? ""} style={{ width: "100%", }} />
            </Box>
            <Autocomplete
                sx={{ width: "100%", }}
                size="small"
                noOptionsText={franchise ? "No lables found" : "Please select a franchise"}
                disabled={true}
                loading={isJobLablesLoading}
                value={jobLables?.length > 0 ? existingJob?.label ?? null : null}
                isOptionEqualToValue={(option, value) => option?.optionID === value?.optionID}
                disablePortal
                disableClearable
                options={jobLables}
                getOptionLabel={(jobLabel) => jobLabel?.name ?? "-"}
                renderOption={(props, l) => <RenderInput {...props} key={l?.optionID} content={l?.name} />}
                renderInput={(params) => <CustomTextField   {...params} required={true} label="Select Job Label" />}
            />
        </Box></>
}
