import { Box, IconButton, Typography } from "@mui/material"
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';

export default function CustomChip(props) {
    const { id, label, onDelete, disabled } = props
    return (
        <Box
            id={id}
            data-test={`tag_${label}`}
            sx={{
                display: "flex", alignItems: "center", borderRadius: "1em", border: "1px solid #BDBDBD",
                paddingRight: "0.2em", paddingLeft: "0.4em", paddingTop: "0.2em", paddingBottom: "0.2em"
            }}>
            <Typography variant="body2" >{label}</Typography>
            <IconButton disabled={disabled} sx={{ width: "1em", height: "1em" }} onClick={onDelete}>
                <HighlightOffTwoToneIcon fontSize='small' color="#BDBDBD" />
            </IconButton>
        </Box>
    )
}