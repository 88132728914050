import { Box } from "@mui/material";
import CustomTextField from "../../../../../components/CustomTextField";
import LoadingButton from "../../../../../components/LoadingButton";

export default function VonigoCustomerSearchSection(props) {
    const { isDissabled, searchPar, onSearchParChanged, isSearchResultCutomersLoading, onSearch } = props
    return <Box sx={{ display: 'flex', flexDirection: "row", width: "40%", height: "3em", justifyContent: "right", alignItems: "center", gap: "1em" }}>
        <CustomTextField style={{ width: "70%" }} disabled={isDissabled} label="Search Customer" type="search" size="small" value={searchPar ?? ""} onChange={onSearchParChanged} />
        <LoadingButton sx={{ backgroundColor: "#00a79d", color: "white", textTransform: "none", "&:hover": { backgroundColor: "#00a79d" } }} variant="contained" loading={isSearchResultCutomersLoading} disabled={isSearchButtonDissabled(isDissabled, searchPar)} onClick={onSearch}>Search</LoadingButton>
    </Box>
}

function isSearchButtonDissabled(isDissabled, searchPar) {
    const shouldDissabled = (isDissabled || !searchPar || (searchPar?.length < 3))
    return shouldDissabled;
}