import { useContext, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress'
import { Collapse, IconButton, Tooltip, Typography } from "@mui/material";
import SubmitDialog from "./SubmitDialog";
import ErrorView from "../../../components/ErrorView";
import { CALL_CENTER_BOOKING, ERROR_COMPONENTS } from "../../../constants/common";
import { getIsValidUSOrCanadianZipCode } from "../../../utils/validators";
import CustomTextField from '../../../components/CustomTextField';
import InvalidZipCodeImage from "../../../assets/img/error_images/invalid_zip_code.png"
import NoZipCodeSearchLocations from "../../../assets/img/error_images/no_zip_code_search_locations.png"
import { onSyncdesktopAppButtonHandler } from '../utils/desktopapp';
import QuizIcon from '@mui/icons-material/Quiz';
import { CommonContext } from '../../../contexts/CommonContextProvider';
import StarIcon from '@mui/icons-material/Star';
import AgentFeedback from './components/agentFeedback';
import { handleSetAgentFeedbackData } from '../utils/common';
import { FAQ_TYPE_SECTIONS } from '../../dashboard/faq/FaqSection';
import CallCenterBookingConfirmModal from './components/callCenterBookingConfirmModal';

export default function FranchiseSelectView(props) {
    const { urlParamData, isFranchisesByChannelIdAndZipCodeLoading, defaultFranchisees, channelId, otherFranchises, isOtherFranchisesLoading, showNotification, handleDialogOpen, isDisplayGoToMapBtn, handleMapOpen, setZipCode, dialogOpen, handleDialogClose, jobId, franchise, brand, callerId, zipCode, navigateToServiceTitanCreateLeadModal, zipCodeFranchises, isZipCodeFranchisesLoading, getIsWithinServiceArea = () => { }, handleFaqFranchiseChange, isOpenCallCenterBookingConfirmationModal, handleOpenCallCenterBookingConfirmationModal, handleCloseCallCenterBookingConfirmationModal, franchiseSelectedForBooking } = props;

    const otherFranchisesWithoutTollFree = useMemo(() => otherFranchises?.filter((f) => !f.is_toll_free), [otherFranchises])

    const { socketMessage } = useContext(CommonContext);
    const [isOpenAgentFeedbackDialog, setIsOpenAgentFeedbackDialog] = useState(false)
    const [isFromAutoPickedFranchise, setIsFromAutoPickedFranchise] = useState(false)
    const [isSelectZipCodeFranchise, setIsSelectZipCodeFranchise] = useState(false)
    const [isSameFranchiseAsDefault, setIsSameFranchiseAsDefault] = useState(false)
    const [agentFeedbackData, setAgentFeedbackData] = useState({ sentimentId: 0, customerScore: 0.0, customerMagnitude: 0.0, agentScore: 0.0, agentMagnitude: 0.0 })

    function onZipLooseFocus(e) {
        if (!zipCode) {
            setZipCode(urlParamData?.zipCode)
            handleFaqFranchiseChange(defaultFranchisees[0].id)
        }
    }

    const handleAgentFeedBackDialogClose = () => {
        setIsOpenAgentFeedbackDialog(false)
    }

    useEffect(() => {
        if (socketMessage?.message?.includes("SentimentId")) {
            handleSetAgentFeedbackData(socketMessage, setAgentFeedbackData, setIsOpenAgentFeedbackDialog)
        }
    }, [socketMessage])

    const checkIfSelectingSameFranchiseAsDefault = (franchise) => {
        if (defaultFranchisees?.find?.((f) => f?.id === franchise?.id)) {
            setIsSameFranchiseAsDefault(true)
        } else {
            setIsSameFranchiseAsDefault(false)
        }
    }

    return <Box flexGrow={2} style={{ overflowY: 'scroll' }}>
        {isFranchisesByChannelIdAndZipCodeLoading ?
            <div style={{ height: "80vh", display: "flex", justifyContent: "center", alignItems: "center", color: "grey" }}>
                <CircularProgress />
            </div> :
            <Box width="100%" display="flex" flexDirection="column" gap="0.5em" sx={{ color: 'text.primary' }}>
                <Box className={"bg-shadow bg-white"} sx={{ paddingInline: "20px", borderRadius: "7px" }} >
                    <h2>Auto Picked Franchise</h2>
                    {defaultFranchisees.length === 0 ?
                        <div style={{ padding: "1em", marginBlock: "1em" }} className={"franchise warning"}>
                            <div style={{ display: "flex" }}>
                                <p className={'text-color'}>This ZIP/Postal code <strong>({urlParamData?.zipCode})</strong> does not belong to any Franchise.</p>
                            </div>
                        </div>
                        : <></>}
                    {defaultFranchisees.length > 0 && defaultFranchisees.map((franchise, i) => (
                        <DefaultFranchiseCard i={i} brand={brand} franchise={franchise} showNotification={showNotification} handleDialogOpen={handleDialogOpen} handleOpenCallCenterBookingConfirmationModal={handleOpenCallCenterBookingConfirmationModal} setIsFromAutoPickedFranchise={setIsFromAutoPickedFranchise} setIsSelectZipCodeFranchise={setIsSelectZipCodeFranchise} setIsSameFranchiseAsDefault={setIsSameFranchiseAsDefault} />
                    ))}
                </Box>
                <Box className={"bg-shadow bg-white"} style={{ paddingInline: "20px", borderRadius: "7px" }}>
                    <Box sx={{ marginTop: "2em", display: "flex", flexDirection: "row", alignItems: "top", gap: "1em" }}>
                        <Box display="inline-block" alignSelf="center" >
                            <h2 style={{ marginTop: "0", marginRight: "0.1em", display: "inline" }}>Search for Different Franchise</h2>
                            <Tooltip title="Please search for a franchise using the exact zip code in order to obtain a relevant result">
                                <span style={{ margin: "0.5em", height: "20px", width: "20px", display: "inline-block", }} >
                                    <InfoOutlinedIcon sx={{ cursor: "pointer" }} color="info" style={{ height: "20px", width: "20px" }} />
                                </span>
                            </Tooltip>
                        </Box>
                        <Box sx={{ flexGrow: 2 }} />
                        <Button sx={{ width: "8em", height: "3em" }} disabled={isFranchisesByChannelIdAndZipCodeLoading || (!isDisplayGoToMapBtn(otherFranchises) && defaultFranchisees.length === 0)} onClick={handleMapOpen} size={"medium"} variant="contained" className={isFranchisesByChannelIdAndZipCodeLoading || (!isDisplayGoToMapBtn(otherFranchises) && defaultFranchisees.length === 0) ? "btn-disable" : "btn-primary"}>Go to map</Button>
                        <CustomTextField onBlur={onZipLooseFocus} value={zipCode} onChange={(e) => setZipCode(e.target.value)} id="outlined-basic" size={"small"} label="ZIP/Postal Code" variant="outlined" error={!getIsValidUSOrCanadianZipCode(zipCode)} helperText={getIsValidUSOrCanadianZipCode(zipCode) ? "" : "Invalid ZIP/Postal Code"} />
                    </Box>
                    <Collapse in={getIsValidUSOrCanadianZipCode(zipCode) && zipCode !== "" && urlParamData?.zipCode !== zipCode}>
                        <Box sx={{ display: "flex", alignItems: "center", }}>
                            <Typography variant="h6" marginBottom={"1em"} >ZIP/Postal Exact Match</Typography>
                        </Box>
                        {zipCodeFranchises.map((franchise, i) => {
                            const modifiedFranchise = { ...franchise, isZipcodeFranchise: true };
                            return (
                                <DefaultFranchiseCard
                                    i={i}
                                    brand={brand}
                                    franchise={modifiedFranchise}
                                    showNotification={showNotification}
                                    handleDialogOpen={handleDialogOpen}
                                    handleOpenCallCenterBookingConfirmationModal={handleOpenCallCenterBookingConfirmationModal}
                                    setIsFromAutoPickedFranchise={setIsFromAutoPickedFranchise}
                                    setIsSelectZipCodeFranchise={setIsSelectZipCodeFranchise}
                                    setIsSameFranchiseAsDefault={setIsSameFranchiseAsDefault}
                                />
                            );
                        })}
                        {zipCodeFranchises.length === 0 && !isZipCodeFranchisesLoading ?
                            <div style={{ padding: "1em", marginBottom: "1em" }} className={"franchise warning"}>
                                <div style={{ display: "flex" }}>
                                    <p className={'text-color'}>This ZIP/Postal code <strong>({zipCode})</strong> does not belong to any Franchise.</p>
                                </div>
                            </div>
                            : <></>}
                    </Collapse>
                    <Typography variant="h6" >Next Nearby Locations</Typography>
                    {isOtherFranchisesLoading ? <Box sx={{ height: "80vh", display: "flex", justifyContent: "center", alignItems: "center", color: "grey" }}><CircularProgress /></Box> :
                        <>
                            {otherFranchisesWithoutTollFree?.length > 0 ? <OtherFranchisesTable brand={brand} otherFranchises={otherFranchisesWithoutTollFree} showNotification={showNotification} handleDialogOpen={handleDialogOpen} handleOpenCallCenterBookingConfirmationModal={handleOpenCallCenterBookingConfirmationModal} setIsFromAutoPickedFranchise={setIsFromAutoPickedFranchise} checkIfSelectingSameFranchiseAsDefault={checkIfSelectingSameFranchiseAsDefault} />
                                : <Box height="80vh" width="100%" display="flex" flexDirection="column" alignItems="center" gap="1em" marginTop="2em">
                                    {getIsValidUSOrCanadianZipCode(zipCode) ? <img alt="No Zip Codes" width="35%" src={NoZipCodeSearchLocations} /> : <img marginTop="5em" width="20%" src={InvalidZipCodeImage} />}
                                    <Typography variant='h6' >{getIsValidUSOrCanadianZipCode(zipCode) ? "No Next Nearby Locations" : "Invalid ZIP/Postal code"}</Typography>
                                    <Box flexGrow={2} />                                </Box>
                            }
                        </>}
                    {isOpenCallCenterBookingConfirmationModal ? <CallCenterBookingConfirmModal isOpen={isOpenCallCenterBookingConfirmationModal} franchise={franchiseSelectedForBooking} handleDialogOpen={handleDialogOpen} onCloseHandler={handleCloseCallCenterBookingConfirmationModal} /> : <></>}
                    {dialogOpen ? <SubmitDialog onClose={handleDialogClose} open={dialogOpen} channelId={channelId} jobId={jobId} franchise={franchise} brand={brand} callerId={callerId} zipCode={isSelectZipCodeFranchise ? zipCode : isSameFranchiseAsDefault ? zipCode : defaultFranchisees?.find?.((f) => f?.id === franchise?.id) ? urlParamData?.zipCode : zipCode ? zipCode : urlParamData?.zipCode} onCreateLeadPressed={navigateToServiceTitanCreateLeadModal} showNotification={showNotification} isServiceAreaFranchise={getIsWithinServiceArea(franchise)} isOtherFranchisesLoading={isOtherFranchisesLoading} isFromAutoPickedFranchise={isFromAutoPickedFranchise} /> : <></>}
                    {isOpenAgentFeedbackDialog ? <AgentFeedback handleAgentFeedbackDialogClose={handleAgentFeedBackDialogClose} open={isOpenAgentFeedbackDialog} agentFeedbackData={agentFeedbackData} /> : <></>}
                </Box>
            </Box>
        }
    </Box >;
}

function OtherFranchisesTable(props) {
    const { brand, otherFranchises, showNotification, handleOpenCallCenterBookingConfirmationModal, setIsFromAutoPickedFranchise, checkIfSelectingSameFranchiseAsDefault } = props

    const { popFaqClient } = useContext(CommonContext)

    return <TableContainer>
        <Table aria-label="simple table" stickyHeader>
            <TableHead>
                <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Franchise Name</TableCell>
                    <TableCell>Address</TableCell>
                    <TableCell sx={{ width: "15em" }}>
                        <Box >
                            Distance
                            <Tooltip title="Within 90 miles radius, franchises are sorted by distance, while others are by radius distance">
                                <IconButton size="small">
                                    <InfoOutlinedIcon color="info" style={{ height: "20px", width: "20px" }} />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </TableCell>
                    <TableCell colSpan={2} align="center">
                        <Box width="11em">
                            <span >FAQ/Pricing</span>
                            <span style={{ marginLeft: "40px" }}>Action</span>
                        </Box>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {otherFranchises?.length !== 0 ? (
                    otherFranchises?.map((franchise, i) => (
                        <TableRow key={i}>
                            <TableCell component="th" scope="row">{i + 1}</TableCell>
                            <TableCell>
                                <Box sx={{ display: "flex", gap: "0.5em", alignItems: "center" }}>
                                    <Box>
                                        {franchise.franchise_name}
                                    </Box>
                                    {franchise?.is_call_center_booking === CALL_CENTER_BOOKING.booking &&
                                        <Box sx={{ paddingTop: "5px" }}>
                                            <Tooltip title="CCC Booking">
                                                <StarIcon className='call-center-booking-icon' />
                                            </Tooltip>
                                        </Box>
                                    }
                                </Box>
                            </TableCell>
                            <TableCell>{franchise.franchise_location ? franchise.franchise_location : "-"}</TableCell>
                            <TableCell >{franchise?.drive_distance?.drive_distance_text ? franchise?.drive_distance?.drive_distance_text : franchise?.distance_in_radius ? "> 90 miles" : "-"}</TableCell>
                            <TableCell sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }} >
                                <Box sx={{ display: "flex" }}>
                                    <Box sx={{ marginLeft: "12px" }}>
                                        <Tooltip title="View FAQ/Pricing">
                                            <span>
                                                <IconButton onClick={() => popFaqClient(franchise?.id, brand?.brand_id, brand?.id, [])} >
                                                    <QuizIcon />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                    </Box>
                                    <Box sx={{ marginLeft: "55px" }}>
                                        <Button data-test="select" onClick={() => { setIsFromAutoPickedFranchise(false); checkIfSelectingSameFranchiseAsDefault(franchise); onSyncdesktopAppButtonHandler(franchise?.id, showNotification); handleOpenCallCenterBookingConfirmationModal(franchise); }} size={"small"} variant="contained" className={"btn-secondary"}>Select</Button>
                                    </Box>
                                </Box>
                            </TableCell>
                        </TableRow>
                    ))
                ) : (
                    <TableRow>
                        <TableCell style={{ border: "none", height: "200px" }} align={"center"} colSpan={7}>
                            <ErrorView type={ERROR_COMPONENTS.NOT_FOUND} title='No Franchises Found' />
                        </TableCell>
                    </TableRow>
                )
                }
            </TableBody >
        </Table >
    </TableContainer >;
}

function DefaultFranchiseCard(props) {
    const { i, brand, franchise, showNotification, handleOpenCallCenterBookingConfirmationModal, setIsFromAutoPickedFranchise, setIsSelectZipCodeFranchise, setIsSameFranchiseAsDefault } = props

    const { popFaqClient } = useContext(CommonContext)

    function checkIfZipCodeFranchiseIsSelecting(f) {
        setIsSameFranchiseAsDefault(false)
        if (f.isZipcodeFranchise) {
            setIsSelectZipCodeFranchise(true)
        } else {
            setIsSelectZipCodeFranchise(false)
        }
    }

    return <Box key={i} className={"franchise"}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={4}>
                <Box sx={{ display: "flex", gap: "0.5em", alignItems: "center" }}>
                    <Box>
                        <small>Franchise</small>
                        <p className={'text-color'}>{franchise.franchise_name}</p>
                    </Box>
                    {franchise?.is_call_center_booking === CALL_CENTER_BOOKING.booking &&
                        <Box sx={{ display: "flex", alignContent: "center", marginTop: "auto" }}>
                            <Tooltip title="CCC Booking">
                                <StarIcon className='call-center-booking-icon' />
                            </Tooltip>
                        </Box>
                    }
                </Box>
            </Grid>
            <Grid item xs={3}>
                <small>Location</small>
                <p className={'text-color'}>{franchise.franchise_location ? franchise.franchise_location : "-"}</p>
            </Grid>
            <Grid item xs={3}>
                <small>Distance</small>
                <p className={'text-color'}>{franchise?.drive_distance?.drive_distance_text ? franchise?.drive_distance?.drive_distance_text : "-"}</p>
            </Grid>
            <Grid item xs={2} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <small>FAQ/Pricing</small>
                    <Tooltip title="View FAQ/Pricing">
                        <span style={{ marginLeft: "15px" }}>
                            <IconButton onClick={() => popFaqClient(franchise?.id, brand?.brand_id, brand?.id, [], FAQ_TYPE_SECTIONS.FRANCHISE.section)} >
                                <QuizIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5em" }}>
                    <small style={{ marginLeft: "10px" }}>Action</small>
                    <Button data-test="autopick_select" onClick={() => { setIsFromAutoPickedFranchise(true); checkIfZipCodeFranchiseIsSelecting(franchise); onSyncdesktopAppButtonHandler(franchise?.id, showNotification); handleOpenCallCenterBookingConfirmationModal(franchise); }} size={"small"} variant="contained" className={"btn-primary"}>
                        Select
                    </Button>
                </Box>
            </Grid>
        </Grid >
    </Box >;
}
