
import { Box, Button, Collapse, IconButton } from "@mui/material"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useEffect, useState } from "react";
import { handleEditCustomerNotes, handleGetJobsOfClient, handleGetWorkOrdersOfClient } from "../../../../../state-services-dublicate/vonigo";
import { CustomTextField } from "../../components/customComponents";
import WorkOrderCardForCustomerNoteSection from "./WorkOrderCardForCustomerNoteSection";
import NoteCardForCustomerNoteSection from "./NoteCardForCustomerNoteSection";
import { NOTIFICATION_TYPES } from "../../../../../constants/common";
import LoadingButton from "../../../../../components/LoadingButton";

export default function CustomerNoteSection(props) {
    const { brand, franchise, selectedCustomer, showNotification } = props
    const [isClickNotes, setIsClickNotes] = useState(false)
    const [isClickWorkOrders, setIsClickWorkOrders] = useState(false)
    const [isClickJobs, setIsClickJobs] = useState(false)
    const [note, setNote] = useState()
    const [isEdited, setIsEdited] = useState(false)
    const [editCount, setEditCount] = useState(0)
    const [isEditNoteLoading, setIsEditNoteLoading] = useState(false)
    const [customerWorkOrders, setCustomerWorkOrders] = useState([])
    const [customerJobs, setCustomerJobs] = useState([])

    useEffect(() => {
        if (selectedCustomer) {
            setCustomerWorkOrders()
            setCustomerJobs()
            setNote(selectedCustomer?.notes?.fieldValue ? selectedCustomer?.notes?.fieldValue : "")
            setIsClickNotes(false)
            setIsClickWorkOrders(false)
            setIsClickJobs(false)
            handleGetWorkOrdersOfClient(brand, franchise, selectedCustomer, setCustomerWorkOrders)
            handleGetJobsOfClient(brand, franchise, selectedCustomer, setCustomerJobs)
        }
    }, [selectedCustomer])

    function canEnableNoteButton() {
        return selectedCustomer?.notes?.fieldValue
    }

    function canEnableWorkOrderButton() {
        return Array.isArray(customerWorkOrders) && customerWorkOrders?.length !== 0
    }

    function canEnableJobButton() {
        return Array.isArray(customerJobs) && customerJobs?.length !== 0
    }

    function handleCloseEdit() {
        if (isEdited === false && editCount <= 0) {
            setNote(selectedCustomer?.notes?.fieldValue ? selectedCustomer?.notes?.fieldValue : "")
        }
        setIsEdited(false)
        setIsClickNotes(false)
    }

    return <Box>
        <Box className="customer-note-section" style={{ display: "flex", flexDirection: "row", justifyContent: "left", gap: "10em", padding: "0.2em", flexWrap: "wrap", marginTop: "1em" }}>
            <Box sx={{ display: "flex", flexDirection: "row", height: "40px", alignItems: "center" }}>
                <p style={{ color: isClickNotes ? "#00a79d" : canEnableNoteButton() && selectedCustomer ? "#337DFF" : "#3f3f3f" }}>Customer Notes</p>
                {isClickNotes ?
                    <IconButton
                        aria-label="create"
                        disabled={!selectedCustomer || !canEnableNoteButton()}
                        onClick={() => setIsClickNotes(!isClickNotes)}>
                        <ExpandMoreIcon />
                    </IconButton>
                    : <IconButton
                        aria-label="create"
                        disabled={!selectedCustomer || !canEnableNoteButton()}
                        onClick={() => setIsClickNotes(!isClickNotes)}>
                        <ExpandLessIcon />
                    </IconButton>}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", height: "40px", alignItems: "center" }}>
                <p style={{ color: isClickWorkOrders ? "#00a79d" : canEnableWorkOrderButton() && selectedCustomer ? "#337DFF" : "#3f3f3f" }}>Work Orders</p>
                {isClickWorkOrders ?
                    <IconButton
                        aria-label="create"
                        disabled={!selectedCustomer || !canEnableWorkOrderButton()}
                        onClick={() => setIsClickWorkOrders(!isClickWorkOrders)}>
                        <ExpandMoreIcon />
                    </IconButton>
                    : <IconButton
                        aria-label="create"
                        disabled={!selectedCustomer || !canEnableWorkOrderButton()}
                        onClick={() => setIsClickWorkOrders(!isClickWorkOrders)}>
                        <ExpandLessIcon />
                    </IconButton>
                }
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", height: "40px", alignItems: "center" }}>
                <p style={{ color: isClickJobs ? "#00a79d" : canEnableJobButton() && selectedCustomer ? "#337DFF" : "#3f3f3f" }}>Cancelled Jobs</p>
                {isClickJobs ?
                    <IconButton
                        aria-label="create"
                        disabled={!selectedCustomer || !canEnableJobButton()}
                        onClick={() => setIsClickJobs(!isClickJobs)}>
                        <ExpandMoreIcon />
                    </IconButton>
                    : <IconButton
                        aria-label="create"
                        disabled={!selectedCustomer || !canEnableJobButton()}
                        onClick={() => setIsClickJobs(!isClickJobs)}>
                        <ExpandLessIcon />
                    </IconButton>
                }
            </Box>
        </Box>
        <Collapse in={isClickNotes}>
            {isClickNotes && <Box className="customer-sections" style={{ display: "flex", flexDirection: "column", padding: "0.2em" }}>
                <h4>Notes</h4>
                <Box style={{ width: "100%", marginTop: "1em", marginBottom: "0.5em", marginRight: "2em" }}>
                    <CustomTextField multiline rows={3} value={note} onChange={(event) => setNote(event.target.value)} required={true} label="Customer Notes" variant="outlined" size="small" style={{ width: "100%", }} />
                </Box>
                <Box style={{ marginBottom: "1em", marginLeft: "auto" }}>
                    <Button size={"small"} variant="contained" className={"btn-secondary"} onClick={handleCloseEdit}>Cancel</Button>
                    <LoadingButton sx={{ flex: "0 0 12em" }} type={"submit"} loading={isEditNoteLoading} size={"small"} variant="contained" style={{ marginLeft: "1em" }}
                        onClick={() => editClientNote(brand, franchise, note, selectedCustomer, setIsEditNoteLoading, showNotification, setNote, setIsEdited, editCount, setEditCount)} className={isEditNoteLoading ? "btn-disable" : "btn-primary"} >
                        {"Edit Note"}
                    </LoadingButton>
                </Box>
            </Box>}
        </Collapse>
        <Collapse in={isClickWorkOrders}>
            {isClickWorkOrders && <Box className="customer-sections" style={{ display: "flex", flexDirection: "column", justifyContent: "left", padding: "0.2em", flexWrap: "wrap" }}>
                <h4>Work Orders</h4>
                {customerWorkOrders?.map((workOrder) => {
                    return <WorkOrderCardForCustomerNoteSection key={workOrder?.name} brand={brand} workOrder={workOrder} />
                })}
            </Box>}
        </Collapse>
        <Collapse in={isClickJobs}>
            {isClickJobs && <Box className="customer-sections" style={{ display: "flex", flexDirection: "column", justifyContent: "left", padding: "0.2em", flexWrap: "wrap" }}>
                <h4>Cancelled Jobs</h4>
                {customerJobs?.map((job) => {
                    return <NoteCardForCustomerNoteSection key={job?.title} brand={brand} job={job} />
                })}
            </Box>}
        </Collapse>
    </Box>
}

async function editClientNote(brand, franchise, note, selectedCustomer, setIsEditNoteLoading, showNotification, setNote, setIsEdited, editCount, setEditCount) {
    try {
        setIsEditNoteLoading(true)
        const editNoteRequest = {
            baseUrl: brand?.redirect_url,
            franchiseId: franchise?.franchise_id,
            clientId: parseInt(selectedCustomer?.objectID, 10),
            notes: note
        }
        let editNoteResponse = await handleEditCustomerNotes(editNoteRequest)
        setNote(editNoteResponse?.notes?.fieldValue)
        setEditCount(editCount + 1)
        setIsEdited(true)
        showNotification({ message: "Note has been edited successfully", type: NOTIFICATION_TYPES.SUCCESS, isOpen: true })
    } catch (error) {
        showNotification({ message: "Important: Note couldn’t be edited at this time. Please try again", type: NOTIFICATION_TYPES.ERROR })
    } finally {
        setIsEditNoteLoading(false)
    }
}