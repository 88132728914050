
import { initializeApp } from 'firebase/app';
let currentEnv = process.env.REACT_APP_CURRENT_ENV

const selectConfigForEnv = () => {
    let firebaseConfig = {}
    switch (currentEnv) {
        case "localhost":
            firebaseConfig = {
                apiKey: "AIzaSyAba0VAkr-Vs9Q3MLM50-rsUS4Xl6F3zyg",
                authDomain: "ibs-dev-335315.firebaseapp.com",
                projectId: "ibs-dev-335315",
                storageBucket: "ibs-dev-335315.appspot.com",
                databaseURL: "https://ibs-dev-335315-default-rtdb.firebaseio.com/",
                messagingSenderId: "662924876976",
                appId: "1:662924876976:web:8b25df525e5b1eceb36f75",
                measurementId: "G-1YWHT6EK7E"
            };
            return firebaseConfig;
        case "dev":
            firebaseConfig = {
                apiKey: "AIzaSyAba0VAkr-Vs9Q3MLM50-rsUS4Xl6F3zyg",
                authDomain: "ibs-dev-335315.firebaseapp.com",
                projectId: "ibs-dev-335315",
                storageBucket: "ibs-dev-335315.appspot.com",
                messagingSenderId: "662924876976",
                appId: "1:662924876976:web:8b25df525e5b1eceb36f75",
                measurementId: "G-1YWHT6EK7E"
            };
            return firebaseConfig;
        case "qa":
            firebaseConfig = {
                apiKey: "AIzaSyBUzDOwRzVsj1F_clVoXkosnZMA31OsQZQ",
                authDomain: "ibs-qa-335315.firebaseapp.com",
                projectId: "ibs-qa-335315",
                storageBucket: "ibs-qa-335315.appspot.com",
                messagingSenderId: "603569441238",
                appId: "1:603569441238:web:e81c089d8f2e9a52593c77"
            };
            return firebaseConfig;
        case "stage":
            firebaseConfig = {
                apiKey: "AIzaSyBRI6uzW1KOgDf7W1msUXU5jNPtxnY2z7U",
                authDomain: "ibs-stage.firebaseapp.com",
                projectId: "ibs-stage",
                storageBucket: "ibs-stage.appspot.com",
                messagingSenderId: "461718902166",
                appId: "1:461718902166:web:b80f32db326a6ea9d639b7"
            };
            return firebaseConfig;
        case "production":
            firebaseConfig = {
                apiKey: "AIzaSyApSRlKJ9rOXSqNXCRq19nrwgFDAuUzWbY",
                authDomain: "fawkes-phoenix.firebaseapp.com",
                projectId: "fawkes-phoenix",
                storageBucket: "fawkes-phoenix.appspot.com",
                messagingSenderId: "858089005593",
                appId: "1:858089005593:web:4afff5a2c881ee454e4088"
            };
            return firebaseConfig;
        default:
            firebaseConfig = {
                apiKey: "AIzaSyAba0VAkr-Vs9Q3MLM50-rsUS4Xl6F3zyg",
                authDomain: "ibs-dev-335315.firebaseapp.com",
                projectId: "ibs-dev-335315",
                storageBucket: "ibs-dev-335315.appspot.com",
                messagingSenderId: "662924876976",
                appId: "1:662924876976:web:8b25df525e5b1eceb36f75",
                measurementId: "G-1YWHT6EK7E"
            };
            return firebaseConfig;
    }
}

const firebaseConfig = selectConfigForEnv()
// Initialize Firebase
const app = initializeApp(firebaseConfig);
// firebase.initializeApp(firebaseConfig);

export default app



