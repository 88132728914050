import React, { useState, useEffect, useContext } from 'react'
import {
    Box, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Typography, Tooltip, IconButton, CircularProgress, Chip,
    Pagination, Button, Grid
} from "@mui/material"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import FeedbackIcon from '@mui/icons-material/Feedback';
import CallIcon from '@mui/icons-material/Call';
import { getById, makeACallWithCallback } from "../utils/miscellaneous"
import { getPhoneNumberWithInputMask } from "../utils/inputMasks"
import ErrorView from "./ErrorView";
import { ERROR_COMPONENTS } from "../constants/common";
import { VALID_CALLBACK_MSG, callbackStatus, followupStatus, nonSpamAttemptsCount, VALID_STOP_MSG, VALID_START_MSG } from "../constants/abndCallLog";
import moment from "moment";
import Notification from "../components/Notification";
import { CommonContext } from '../contexts/CommonContextProvider';
import { getFranchiseBrandOutboundChannelIds } from '../services/outboundChannelIds/outboundChannelIds';
import ImageBox from './LogoBox/ImageBox';

export default function AbandonedCallsTable(props) {
    const { socketMessage, brands } = useContext(CommonContext);
    const { loading, records, onPageChangeHandler, callLogsCount, countPerPage, messageBlocks } = props
    const [dialogOpen, setDialogOpen] = useState(false)
    const [spam, setSpam] = useState({})
    const [hideContentPanel, setHideContentPanel] = useState(false)
    const [notify, setNotify] = useState({ message: "", type: "", isOpen: false });
    const [page, setPage] = useState(1)

    useEffect(() => {
        checkSpamMessages()
    }, [spam])

    useEffect(() => {
        setPage(1)
    }, [socketMessage])

    const triggerVoiceCall = async (id, callerId, agentOutboundCallId) => {
        try {
            makeACallWithCallback(id, callerId, agentOutboundCallId, showNotification)
        } catch (error) { }
    }

    const viewSpamDetails = (job) => {
        let attemptDetails = job?.attempt_details
        job.attempt_details = attemptDetails
        setSpam(job)
        setDialogOpen(true)
    }

    const handleClose = () => {
        setDialogOpen(false);
    };

    const checkSpamMessages = () => {
        setHideContentPanel(false)
        let arr = []
        let stopMsgList = []
        let startMsgList = []
        spam?.attempt_details?.filter(item => {
            const message = item.reply_message.toLowerCase().trim().replace(/\s/g, '')
            if (message === VALID_CALLBACK_MSG) {
                arr.push(item)
            }
            if (message === VALID_STOP_MSG) {
                stopMsgList.push(item)
            }
            if (message === VALID_START_MSG) {
                startMsgList.push(item)
            }
        })
        if (spam?.attempt_details?.length > 1) {
            if (spam?.attempt_details?.length === arr?.length || spam?.attempt_details?.length === stopMsgList?.length ||
                spam?.attempt_details?.length === startMsgList?.length) {
                setHideContentPanel(true)
            }
        }
    }

    function showNotification(notify = {}) {
        setNotify({ ...notify, isOpen: true })
    }

    function handlePagination(v) {
        setPage(v)
        onPageChangeHandler(v)
    }

    async function handleCall(job) {
        if (job?.abndcall_with_franchise_data?.caller_id) {
            const brand = getById(parseInt(job?.abndcall_with_franchise_data?.brand_id), brands)
            const outboundPhoneNumbers = await getFranchiseBrandOutboundChannelIds(job?.abndcall_with_franchise_data?.franchise_id, brand?.brand_id)
            triggerVoiceCall(job?.abndcall_with_franchise_data?.id,
                job?.abndcall_with_franchise_data?.caller_id,
                outboundPhoneNumbers?.[0]?.channel_id)
        }
    }

    return <>
        <TableContainer style={{ height: "70vh" }}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>Caller ID</TableCell>
                        <TableCell>Channel Id</TableCell>
                        <TableCell>Brand</TableCell>
                        <TableCell>Franchise</TableCell>
                        <TableCell>Abandoned Date</TableCell>
                        <TableCell>Callback Status</TableCell>
                        <TableCell>Followup Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ?
                        <TableRow>
                            <TableCell colSpan={8} align="center" style={{ border: "none", height: "300px", color: "grey" }} >
                                <CircularProgress size={30} color="inherit" />
                            </TableCell>
                        </TableRow>
                        :brands?.length === 0 ?
                        <TableRow>
                            <TableCell colSpan={8} align="center" style={{ border: "none", height: "300px", color: "grey" }} >
                                <ErrorView type={ERROR_COMPONENTS.NOT_FOUND} title='You Are Not Assigned To Any Brand' />
                            </TableCell>
                        </TableRow>
                        : 
                        records && records.length !== 0 ? (
                            records?.map((job, i) => (
                                <TableRow key={i}>
                                    <TableCell component="th" scope="row">
                                        {i + 1}
                                    </TableCell>
                                    <TableCell>
                                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            <Tooltip title="Call" >
                                                <IconButton sx={{ color: "#00a79d" }} aria-label="Call" component="span" disabled={
                                                    job?.abndcall_with_franchise_data?.callback_status === 0 ||
                                                    messageBlocks.includes(job?.abndcall_with_franchise_data?.caller_id)
                                                }
                                                    onClick={() => handleCall(job)}>
                                                    <CallIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Typography variant="p" noWrap>{getPhoneNumberWithInputMask(job?.abndcall_with_franchise_data?.caller_id) ?? "-"}</Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell>{job?.abndcall_with_franchise_data?.channel_id}</TableCell>
                                    <TableCell>
                                        {job?.abndcall_with_franchise_data?.brand_img !== "" &&
                                            <ImageBox alt={"brand_img"} src={job?.abndcall_with_franchise_data?.brand_img} />
                                        }
                                    </TableCell>
                                    <TableCell>{job?.abndcall_with_franchise_data?.franchise_name}</TableCell>
                                    <TableCell>{moment(job?.abndcall_with_franchise_data?.created_at).format("MM/DD/YYYY hh:mm A")}</TableCell>
                                    <TableCell>
                                        <div className="callback-column">
                                            {messageBlocks.includes(job?.abndcall_with_franchise_data?.caller_id) ?
                                                <Chip className='callback-status-spam' label="Rejected" size="small" variant="outlined" color='error' /> :
                                                job?.abndcall_with_franchise_data?.callback_status === callbackStatus.completed ? <>
                                                    <Chip className='callback-status-spam' label="Received" color="success" size="small" variant="outlined" />
                                                </> :
                                                    <Chip className='callback-status-spam' label="Required" color="warning" size="small" variant="outlined" />}
                                            {job?.attempts > nonSpamAttemptsCount &&
                                                <Box className="spam-box" sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                    <Tooltip sx={{ color: "#f5f5f9" }} title="Spam Details" placement="bottom">
                                                        <IconButton sx={{ color: "#d32f2f" }} aria-label="Spam" component="span" onClick={() => { viewSpamDetails(job) }}>
                                                            <ReportGmailerrorredIcon style={{ fontSize: "30px" }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Box>
                                            }
                                            {job?.attempts <= nonSpamAttemptsCount && job?.abndcall_with_franchise_data?.reply_message !== "" ?
                                                <Box className="spam-box" sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                    <Tooltip sx={{ color: "#f5f5f9" }} title={"Reply : " + job?.abndcall_with_franchise_data?.reply_message} placement="right">
                                                        <IconButton aria-label="Call" component="span">
                                                            <FeedbackIcon color="action" />
                                                        </IconButton>
                                                    </Tooltip> </Box> :
                                                <></>
                                            }
                                        </div>
                                    </TableCell>
                                    <TableCell>{messageBlocks.includes(job?.abndcall_with_franchise_data?.caller_id) ?
                                        <Chip label="No need" size="small" variant="outlined" color='error' /> :
                                        job?.abndcall_with_franchise_data?.followup_status === followupStatus.completed ?
                                            <Chip label="Completed" color="success" size="small" variant="outlined" /> :
                                            <Chip label="Pending" size="small" variant="outlined" />}
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell style={{ border: "none", height: "200px" }} align={"center"} colSpan={8}>
                                    <ErrorView type={ERROR_COMPONENTS.NOT_FOUND} title='No Abandoned Calls Found' />
                                </TableCell>
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>
        </TableContainer>
        <Pagination page={page} onChange={(e, v) => handlePagination(v)} className="pagination-bar"
            count={Math.ceil(callLogsCount / countPerPage)}
            color="standard" />
        <Dialog
            maxWidth="lg"
            open={dialogOpen}
            onClose={handleClose}
        >
            <DialogTitle className='spam-dialog-title'>Abandoned Customer Replies</DialogTitle>
            <DialogContent>
                <div className='spam-dialog'>
                    <Grid container spacing={2}>
                        <Grid item xs={4} md={4}>
                            <div className='spam-label'>Caller ID</div>
                            <div className='spam-data'>{getPhoneNumberWithInputMask(spam?.abndcall_with_franchise_data?.caller_id) ?? "-"}</div>
                        </Grid>
                        <Grid item xs={4} md={4}>
                            <div className='spam-label'>No of Attempts</div>
                            <div className='spam-data' style={{ paddingLeft: "50px" }}>{spam?.attempts}</div>
                        </Grid>
                        <Grid item xs={4} md={4}>
                            <div className='spam-label'>Channel Id</div>
                            <div className='spam-data'>{getPhoneNumberWithInputMask(spam?.abndcall_with_franchise_data?.channel_id) ?? "-"}</div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: "20px" }}>
                        <Grid item xs={8} md={8}>
                            <div className='spam-label'>SMS Content</div>
                            {
                                hideContentPanel ? <div style={{ marginTop: "20px" }}>
                                    <div className='spam-msg-item'>{spam?.attempt_details[0]?.reply_message}</div>
                                    <div className='spam-msg-item-time'>{spam?.attempt_details[0]?.reply_message && moment(spam?.attempt_details[0]?.created_at).format("hh:mm A")}</div>
                                </div> : <div className='spam-msg-content'>
                                    {
                                        spam?.attempt_details && spam?.attempt_details.length > 0 && (
                                            spam?.attempt_details?.map((attemptDeatil, i) => (
                                                <div key={i}>
                                                    <div className='spam-msg-item'>{attemptDeatil.reply_message}</div>
                                                    <div className='spam-msg-item-time'>{attemptDeatil.reply_message && moment(attemptDeatil.created_at).format("hh:mm A")}</div>
                                                </div>
                                            ))
                                        )
                                    }
                                </div>
                            }
                        </Grid>
                        <Grid item xs={4} md={4}>
                            {spam?.abndcall_with_franchise_data?.brand_img !== "" &&
                                <Box sx={{ marginTop: '70px' }}>
                                    <ImageBox size='large' alt={"brand_img"} src={spam?.abndcall_with_franchise_data?.brand_img} />
                                </Box>
                            }
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
        {(notify?.isOpen) ? <Notification notify={notify} setNotify={setNotify} /> : <></>}
    </>
}
