import { useContext, useEffect, useState } from "react"
import { getJobCancelReasons, getServiceTitanJobs } from "../../../../../services/serviceTitan"
import { Box, Button, Collapse, IconButton, Paper, Typography } from "@mui/material"
import { handleGetAllJobTypes, handleGetBusinessUnits, handleGetCampaigns } from "../../../../../state-services-dublicate/serviceTitan";
import RefreshErrorView from "../../../../../components/refreshView/RefreshErrorView"
import SmallCard from "../../../../../components/servicetitan/SmallCard"
import moment from "moment"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AppointmentsSection from "./AppointmentsSection";
import LoadingView from "../../../../../components/refreshView/LoadingView";
import NoContentView from "../../../../../components/refreshView/NoContentView";
import { chooseColorForJobStatus, getById } from "../../../../../utils/miscellaneous";
import CancelJobModal from "../../serviceTitanJobs/components/CancelJobModal";
import { CommonContext } from "../../../../../contexts/CommonContextProvider";

const INITIAL_EXPANDED_JOB_INDEX = -1

export default function JobsSection(props) {
    const { franchise, customerId, locationId, isShort = false, showNotification = () => { }, shouldShowTechnicianCriteriaIcon,
        setPreviousAppointmentForRescheduleJob = () => { }, setjobForTheAppointment = () => { }, setisOpenAppointmentRescheduleModal = () => { }, shouldAllowCancelAndReschedule = false } = props
    const [isCustomerJobsLoadingError, setIsCustomerJobsLoadingError] = useState(false)
    const [isCustomerJobsLoading, setIsCustomerJobsLoading] = useState(false)
    const [customerJobs, setCustomerJobs] = useState([])
    const [toggleGetJobs, setToggleGetJobs] = useState(false)
    const [campaigns, setCampaigns] = useState([])
    const [jobTypes, setJobTypes] = useState([])
    const [businessUnits, setBusinessUnits] = useState([])
    const [expandedJobIndex, setExpandedJobIndex] = useState(INITIAL_EXPANDED_JOB_INDEX)
    const [jobCancelReasons, setJobCancelReasons] = useState([])

    useEffect(() => {
        handleGetCampaigns(franchise?.id, undefined, setCampaigns, () => { }, () => { })
        handleGetAllJobTypes(franchise?.id, setJobTypes)
        handleGetBusinessUnits(franchise?.id, undefined, setBusinessUnits, undefined, () => { }, () => { })
    }, [franchise])

    useEffect(() => {
        handleGetCustomerJobs(franchise?.id, customerId, locationId, setCustomerJobs, setIsCustomerJobsLoading, setIsCustomerJobsLoadingError)
    }, [franchise?.id, customerId, locationId, toggleGetJobs])

    useEffect(() => {
        getJobCancelReasons(franchise?.id).then((jobCancelReasonsRes) => {
            if (Array.isArray(jobCancelReasonsRes?.data)) {
                setJobCancelReasons(jobCancelReasonsRes?.data);
            }
        }).catch(() => {
        })
        return () => {
            setJobCancelReasons([])
        }
    }, [franchise?.id])

    return franchise?.id && (customerId || locationId) ? <Box marginTop="0.5em" width="100%">
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
            <Typography marginLeft="0.5em" component="h5" fontWeight="bold" >Jobs</Typography>
        </Box>
        {isCustomerJobsLoadingError ? <RefreshErrorView onRefresh={() => setToggleGetJobs(!toggleGetJobs)} /> : <></>}
        {isCustomerJobsLoading ? <LoadingView /> :
            customerJobs?.length === 0 ? <NoContentView message={"No jobs to show"} /> : customerJobs.map((j, i) => <JobCard franchise={franchise} job={j} index={i} businessUnits={businessUnits}
                jobTypes={jobTypes} campaigns={campaigns} expandedJobIndex={expandedJobIndex} setExpandedJobIndex={setExpandedJobIndex} isShort={isShort}
                showNotification={showNotification} jobCancelReasons={jobCancelReasons} currentJobs={customerJobs} setPreviousAppointmentForRescheduleJob={setPreviousAppointmentForRescheduleJob}
                setjobForTheAppointment={setjobForTheAppointment} setisOpenAppointmentRescheduleModal={setisOpenAppointmentRescheduleModal} shouldAllowCancelAndReschedule={shouldAllowCancelAndReschedule} 
                shouldShowTechnicianCriteriaIcon ={shouldShowTechnicianCriteriaIcon}
                />)}
    </Box > : <></>
}

function JobCard(props) {
    const { franchise, job, index, businessUnits = [], jobTypes = [], campaigns = [], expandedJobIndex, setExpandedJobIndex, isShort, showNotification,
        jobCancelReasons, currentJobs, setPreviousAppointmentForRescheduleJob, setjobForTheAppointment, setisOpenAppointmentRescheduleModal, shouldAllowCancelAndReschedule, shouldShowTechnicianCriteriaIcon } = props

    const [isOpenCancelJobsModal, setIsOpenCancelJobsModal] = useState(false)
    const { toggleGetAppointments, setToggleGetAppointments } = useContext(CommonContext);

    function handleOpenCancelJob() {
        setIsOpenCancelJobsModal(true)
    }

    function handleCloseCancelJob(canceledJob) {
        if (canceledJob) {
            let cancelldJobIndex = currentJobs.findIndex(j => j.id === canceledJob.id)
            if (cancelldJobIndex !== -1) {
                let job = currentJobs[cancelldJobIndex]
                job.jobStatus = "Canceled"
            }
            setToggleGetAppointments(!toggleGetAppointments)
        }
        setIsOpenCancelJobsModal(false)
    }

    return <Paper key={job?.id} style={{ display: "flex", flexDirection: "column", borderRadius: "0.5em", border: "1px solid gray", borderLeft: "4px solid #00a79d", marginTop: "0.5em" }}>
        <Box sx={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "start", gap: "0.5em", padding: "0.25em", justifyContent: "space-between" }}>
            <Box width="12em"><SmallCard heading="Received" value={job?.createdOn ? moment(new Date(job?.createdOn))?.format('MM/DD/YYYY h:mm A') : "-"} /></Box>
            {isShort ? <></> : <SmallCard heading="Job Type" value={getById(job.jobTypeId, jobTypes)?.name ?? "-"} />}
            <Box width="4em" ><SmallCard heading="Priority" value={job?.priority ?? "-"} /></Box>
            <Box width="10em" ><SmallCard heading="Business Unit" value={getById(job.businessUnitId, businessUnits)?.name ?? "-"} /></Box>
            <Box width="6em" ><SmallCard heading="Status" shouldAddColorTovalue={true} colorOfTheValue={chooseColorForJobStatus(job?.jobStatus)} value={job?.jobStatus ?? "-"} /></Box>
            {isShort ? <></> : <SmallCard heading="Campaign" value={getById(job.campaignId, campaigns)?.name ?? "-"} />}
            <Box width="51em" ><SmallCard heading="Summary" value={job?.summary ?? "-"} /></Box>
            <IconButton
                sx={{ marginInline: "1em" }}
                onClick={() => expandedJobIndex === index ? setExpandedJobIndex(INITIAL_EXPANDED_JOB_INDEX) : setExpandedJobIndex(index)}
                size="small"
            >
                {expandedJobIndex === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
        </Box>
        {(job?.jobStatus !== 'Canceled' && shouldAllowCancelAndReschedule) &&
            <Box sx={{ display: "flex", flexDirection: "column", padding: '10px', width: "100%" }}>
                <Button size={"small"} variant="contained" style={{ marginTop: "0.5em", marginLeft: "auto" }} onClick={() => handleOpenCancelJob()} disabled={isOpenCancelJobsModal} className={isOpenCancelJobsModal ? "btn-disable" : "btn-primary"} >Cancel Job</Button>
            </Box>
        }
        {isOpenCancelJobsModal &&
            <Box sx={{ padding: "10px" }}>
                <CancelJobModal franchise={franchise} job={job} showNotification={showNotification} cancelReasons={jobCancelReasons} handleCloseCancelJob={handleCloseCancelJob} />
            </Box>
        }
        <Collapse in={expandedJobIndex === index} >
            <Box margin="1em">
                <AppointmentsSection franchise={franchise} job={job} toggleGetAppointments={toggleGetAppointments} setToggleGetAppointments={setToggleGetAppointments} shouldShowTechnicianCriteriaIcon={shouldShowTechnicianCriteriaIcon}
                    setPreviousAppointmentForRescheduleJob={setPreviousAppointmentForRescheduleJob} setjobForTheAppointment={setjobForTheAppointment} setisOpenAppointmentRescheduleModal={setisOpenAppointmentRescheduleModal} shouldAllowCancelAndReschedule={shouldAllowCancelAndReschedule} />
            </Box>
        </Collapse>
    </Paper>
}

async function handleGetCustomerJobs(franchiseId, customerId, locationId, setJobs, setIsLoading, setIsError) {
    try {
        setIsError(false)
        setIsLoading(true)
        const res = await getServiceTitanJobs(franchiseId, 1, 50, "", "", "", customerId, locationId)
        if (Array.isArray(res?.data)) {
            setJobs(res?.data)
        }
    } catch {
        setIsError(true)
    } finally {
        setIsLoading(false)
    }
}
