
import { Box, Autocomplete } from "@mui/material";
import CustomTextField from "../../../../components/CustomTextField";
import { sortFranchisesByActiveState } from "../../../../utils/miscellaneous";
import { renderBrandOption } from "../../../../components/servicetitan/RenderInput";
import CustomDatePicker from "../../../../components/CustomDatePicker";
import { getFranchiseOptionLabel, renderFranchiseOptionWithStatusAndEncryptionBadge } from "../../utils/franchise";

export default function AdvanceFilterArea(props) {
    const { getInitialFilterStatus, brands, brand, setBrand, isBrandsLoading, franchises, franchise, setFranchise, isFranchisesLoading, filterState, setFilterState, auditTypes, auditType, setAuditType, allUsers, user, setUser, auditOptions, auditOption, setAuditOption, isAuditOptionsLoading } = props
    return (
        <Box className="st-filter-area" style={{ display: "flex", flexDirection: "row", gap: "2em", marginBlock: "2em" }}>
            <Box sx={{ maxWidth: "12em" }}>
                <h4>Filter Your Results Here</h4>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "row", flexGrow: 2, gap: "1em", flexWrap: "wrap", justifyContent: "end" }}>

                <Autocomplete
                    sx={{ minWidth: "200px" }}
                    size="small"
                    noOptionsText={"No Audit Types"}
                    disabled={auditTypes !== []}
                    disablePortal
                    disableClearable
                    options={auditTypes?.sort((a, b) => a.type.localeCompare(b.type))}
                    getOptionLabel={(auditType) => auditType.type}
                    value={auditType ?? null}
                    onChange={(_, auditType) => {
                        setAuditType(auditType);
                        getInitialFilterStatus(auditType.type)
                    }}
                    renderInput={(params) => <CustomTextField {...params} label="Audit Section" />}
                />

                <div style={{ width: "15%", zIndex: 4 }} >
                    <CustomDatePicker
                        maxTime={filterState?.created_before && new Date(filterState.created_before)}
                        label={"Created After"}
                        value={filterState?.created_after && new Date(filterState.created_after)}
                        onChange={(date) => {
                            if (date) {
                                setFilterState({ ...filterState, page: 1, created_after: date?.getTime?.() })
                            }
                        }
                        }
                        renderInput={renderFilterDateInput}
                    />
                </div>

                <div style={{ width: "15%", zIndex: 3 }} >
                    <CustomDatePicker
                        minTime={filterState?.created_after && new Date(filterState.created_after)}
                        label={"Created Before"}
                        value={filterState?.created_before && new Date(filterState.created_before)}
                        onChange={(date) => {
                            if (date) {
                                setFilterState({ ...filterState, page: 1, created_before: date?.getTime?.() })
                            }
                        }}
                        renderInput={renderFilterDateInput}
                    />
                </div>

                <Autocomplete
                    sx={{ minWidth: "200px" }}
                    size="small"
                    loading={isBrandsLoading}
                    noOptionsText={"No brands"}
                    disabled={brands === []}
                    disablePortal
                    options={brands?.sort((a, b) => a.brand_name.localeCompare(b.brand_name))}
                    getOptionLabel={(brand) => brand.brand_name}
                    renderOption={renderBrandOption}
                    value={brand ?? null}
                    onChange={(i, brand) => {
                        setBrand(brand);
                        const { franchise_id: _, ...newFilterState } = filterState;
                        setFilterState({ ...newFilterState, page: 1, brand_id: brand?.brand_id ?? 0, });
                    }}
                    renderInput={(params) => <CustomTextField {...params} label="Brand" />}
                />

                <Autocomplete
                    sx={{ minWidth: "200px", maxWidth: "300px", flexGrow: 2 }}
                    size="small"
                    disabled={franchises === []}
                    disablePortal
                    loading={isFranchisesLoading}
                    noOptionsText={"No franchises"}
                    options={sortFranchisesByActiveState(franchises)}
                    getOptionLabel={(franchise) => getFranchiseOptionLabel(franchise)}
                    renderOption={renderFranchiseOptionWithStatusAndEncryptionBadge}
                    value={franchise ?? null}
                    onChange={(_, franchise) => {
                        setFranchise(franchise)
                        setFilterState({ ...filterState, page: 1, franchise_id: franchise?.id ?? 0 });
                    }}
                    renderInput={(params) => <CustomTextField  {...params} label="Franchise" />}
                />

                <Autocomplete
                    sx={{ minWidth: "200px" }}
                    size="small"
                    noOptionsText={"No Audit Options"}
                    disabled={auditOptions === []}
                    loading={isAuditOptionsLoading}
                    disablePortal
                    options={auditOptions?.sort((a, b) => a.audit_option.localeCompare(b.audit_option))}
                    getOptionLabel={(auditOption) => auditOption.audit_option}
                    value={auditOption ?? null}
                    onChange={(_, auditOption) => {
                        setAuditOption(auditOption);
                        setFilterState({ ...filterState,page:1, audit_option: auditOption?.audit_option ?? "" });
                    }}
                    renderInput={(params) => <CustomTextField {...params} label="Audit Option" />}
                />

                <Autocomplete
                    sx={{ minWidth: "200px" }}
                    size="small"
                    loading={isBrandsLoading}
                    noOptionsText={"No admins"}
                    disabled={allUsers === []}
                    disablePortal
                    options={allUsers?.sort((a, b) => a.first_name.localeCompare(b.first_name))}
                    getOptionLabel={(user) => user.first_name + " " + user.last_name}
                    value={user ?? null}
                    onChange={(_, user) => {
                        setUser(user);
                        setFilterState({ ...filterState, page: 1, admin_id: user?.user_id ?? 0 });
                    }}
                    renderInput={(params) => <CustomTextField {...params} label="Admin" />}
                />


            </Box>
        </Box>
    )
}

function renderFilterDateInput(params) {
    return <CustomTextField
        style={{ width: "225px", }}
        size={"small"}
        {...params}
        error={params.InputProps.error}
        helperText={params.InputProps.helperText}
    />
}