import { useState, useEffect, useMemo, useContext, useRef } from "react";
import { useHistory, withRouter } from "react-router-dom"
import TopBar from "../../../components/TopBar"
import Box from '@mui/material/Box';
import CallerDetails from "../../../components/CallerDetails";

import { KM_PER_MILE } from "../../../constants/common";
import { getIsValidUSOrCanadianZipCode, isValidPhoneNumber } from "../../../utils/validators";
import { getDistanceCalculatedFranchisesOutsideRadius, getFranchisesWithinRadius, sortFranchises } from "../../../utils/franchise";
import { getFromSessionStorage, setSessionStorage } from "../../../utils/browserStorage";
import { LOCAL_STORAGE_KEYS, SESSION_STORAGE_KEYS } from "../../../constants/browserStorage";
import { onSyncdesktopAppButtonHandler } from "../utils/desktopapp";
import { handleGetBrandById } from "../../../state-services-dublicate/brandServices";
import useDebounce from "../../../hooks/useDebounce";
import MapView from "./MapView";
import FranchiseSelectView from "./FranchiseSelectView";
import { CommonContext } from "../../../contexts/CommonContextProvider";
import { MEDIA_TYPES } from "../Dialer";
import { handleGetOtherFranchisesV3, handleGetFranchisesWithBrandIdAndZipCode, handleGetSearchForDifferentFranchisesWithBrandIdAndZipCode } from "../../../state-services/franchise/v3/franchise";
import { createJob } from "../../../services/jobService";
import { FAQ_TYPE_SECTIONS } from "../../dashboard/faq/FaqSection";
import { removeCallDetailsFromLocalStorage } from "./SubmitDialog";

function Home(props) {
    const urlParamData = useMemo(() => {
        const urlParams = new URLSearchParams(window.location.search);
        return {
            media: urlParams.get("media") ?? "",
            channelName: urlParams.get("channel_name") ?? "",
            channelId: urlParams.get("channel_id") ?? "",
            callerId: urlParams.get("caller_id") ?? "",
            zipCode: urlParams.get("zip_code") ?? "",
            brandId: urlParams.get("brand_id") ?? "",
            jobId: urlParams.get("job_id") ?? "",
            subject: urlParams.get("subject") ?? "",
            from: urlParams.get("from") ?? "",
            interactionGuid: urlParams.get("interaction_guid") ?? "",
        }
    }, [])

    const [dialogOpen, setDialogOpen] = useState(false)
    const [isFranchisesByChannelIdAndZipCodeLoading, setIsFranchisesByChannelIdAndZipCodeLoading] = useState(false)
    const [isZipCodeFranchisesLoading, setIsZipCodeFranchisesLoading] = useState(false)
    const [isOtherFranchisesLoading, setIsOtherFranchisesLoading] = useState(false)
    const [mapOpen, setMapOpen] = useState(false)
    const [franchise, setFranchise] = useState(null)
    const [zipCode, setZipCode] = useState(urlParamData?.zipCode)
    const [autoPickZipCodeLocationData, setAutoPickZipCodeLocationData] = useState({ lat: null, lng: null })
    const [zipCodeLocationData, setZipCodeLocationData] = useState({ lat: null, lng: null })
    const [withinRadiusFranchises, setWithinRadiusFranchises] = useState([])
    const [franchisesOutsideRadius, setFranchisesOutsideRadius] = useState([])
    const [radius, setRadius] = useState(90)
    const [mapFranchise, setMapFranchise] = useState(null);
    const [isMapDialogOpen, setIsMapDialogOpen] = useState(false);
    const [defaultFranchisees, setDefaultFranchises] = useState([])
    const [zipCodeFranchises, setZipCodeFranchises] = useState([])
    const [otherFranchisesWithOutSort, setOtherFranchisesWithOutSort] = useState([])
    const [otherFranchises, setOtherFranchises] = useState([])
    const [brand, setBrand] = useState({})
    const { showNotification, setFaqInfo } = useContext(CommonContext);
    const [isZipEnterModalOpen, setIsZipEnterModalOpen] = useState(false)
    const [selectedFranchise, setSelectedFranchise] = useState({})
    const [faqFranchiseChanged, setFaqFranchiseChanged] = useState(false)
    const [isOpenCallCenterBookingConfirmationModal, setIsOpenCallCenterBookingConfirmationModal] = useState(false)
    const [franchiseSelectedForBooking, setFranchiseSelectedForBooking] = useState(null)
    const [iseCheckingServiceAreaFranchise, setIseCheckingServiceAreaFranchise] = useState(true)
    const history = useHistory()
    const holdJobClickCount = useRef(0)

    const debouncedHandleGetGetOtherFranchises = useDebounce({ brandId: urlParamData?.brandId, defaultFranchiseIds: [], zipCode: urlParamData?.zipCode, radius: Math.round(90 * KM_PER_MILE), setOtherFranchisesWithOutSort: setOtherFranchisesWithOutSort, setIsOtherFranchisesLoading: setIsOtherFranchisesLoading }, ({ brandId, defaultFranchisesIds, zipCode, radius, setOtherFranchisesWithOutSort, setIsOtherFranchisesLoading }) => {
        handleGetOtherFranchisesV3(brandId, defaultFranchisesIds, zipCode, radius, setOtherFranchisesWithOutSort, setIsOtherFranchisesLoading)
    }, 1000)

    useEffect(() => {
        localStorage.setItem(LOCAL_STORAGE_KEYS.media, urlParamData.media)
        localStorage.setItem(LOCAL_STORAGE_KEYS.channelName, urlParamData.channelName)
        localStorage.setItem(LOCAL_STORAGE_KEYS.channelId, urlParamData.channelId)
        localStorage.setItem(LOCAL_STORAGE_KEYS.callerId, urlParamData.callerId)
        localStorage.setItem(LOCAL_STORAGE_KEYS.zipCode, urlParamData.zipCode)
        localStorage.setItem(LOCAL_STORAGE_KEYS.brandId, urlParamData.brandId)
        localStorage.setItem(LOCAL_STORAGE_KEYS.jobId, urlParamData.jobId)
        sessionStorage.setItem(SESSION_STORAGE_KEYS.interactionGuid, urlParamData.interactionGuid)
    }, [urlParamData])

    useEffect(() => {
        handleGetBrandById(urlParamData.brandId, (brand) => {
            setBrand(brand);
            localStorage.setItem(LOCAL_STORAGE_KEYS.brandPrimaryId, brand?.id)
            setSessionStorage(SESSION_STORAGE_KEYS.Brand_Image, brand?.brand_img)
            if (urlParamData.media == MEDIA_TYPES.PHONE.media) {
                handleAgentAssist(urlParamData.interactionGuid)
            } else {
                return
            }

        })
    }, [urlParamData.brandId, faqFranchiseChanged])

    useEffect(() => {
        if (brand?.id) {
            setFaqInfo({ franchiseId: 0, brandId: brand?.brand_id, brandPrimaryId: brand?.id, keywords: [], section: FAQ_TYPE_SECTIONS.BRAND.section });
        }
    }, [brand?.id, brand?.brand_id, setFaqInfo])

    useEffect(() => {
        function getCallLogBody() {
            return {
                channel_id: urlParamData?.channelId,
                media: urlParamData?.media,
                caller_id: urlParamData?.callerId,
                zip_code: urlParamData?.zipCode,
                brand_id: parseInt(urlParamData?.brandId),
                call_type: 2,
                is_churn: 0
            }
        }
        if (!sessionStorage.getItem(SESSION_STORAGE_KEYS.is_call_log_created)) {
            let body = getCallLogBody()
            createJob(body).then(res => {
                sessionStorage.setItem(SESSION_STORAGE_KEYS.currentJobId, res?.id)
                sessionStorage.setItem(SESSION_STORAGE_KEYS.is_call_log_created, true)
            }).catch(err => {
            })
        }
    }, [urlParamData.channelId, urlParamData.callerId, urlParamData.zipCode, urlParamData.brandId])

    useEffect(() => {
        if (urlParamData?.brandId && urlParamData?.zipCode) {
            return handleGetFranchisesWithBrandIdAndZipCode(setDefaultFranchises, urlParamData?.brandId, urlParamData?.zipCode, (locationData) => { setZipCodeLocationData(locationData); setAutoPickZipCodeLocationData(locationData) }, setIsFranchisesByChannelIdAndZipCodeLoading, handleFaqFranchiseChange);
        } else {
            history.replace('/call-logs')
        }
    }, [urlParamData?.brandId, urlParamData?.zipCode])
    useEffect(() => {
        if (urlParamData?.zipCode !== zipCode && getIsValidUSOrCanadianZipCode(zipCode) && zipCode !== "") {
            return handleGetSearchForDifferentFranchisesWithBrandIdAndZipCode(setZipCodeFranchises, urlParamData?.brandId, zipCode, setZipCodeLocationData, setIsZipCodeFranchisesLoading, setOtherFranchisesWithOutSort, setIsOtherFranchisesLoading, handleFaqFranchiseChange);
        } else if (urlParamData?.zipCode === zipCode && getIsValidUSOrCanadianZipCode(zipCode)) {
            setZipCodeFranchises([])
            const defaultFranchisesIds = defaultFranchisees?.map?.((f) => f?.id)
            setZipCodeLocationData(autoPickZipCodeLocationData)
            debouncedHandleGetGetOtherFranchises({ brandId: urlParamData?.brandId, defaultFranchisesIds: defaultFranchisesIds, zipCode: urlParamData?.zipCode, radius: Math.round(90 * KM_PER_MILE), setOtherFranchisesWithOutSort: setOtherFranchisesWithOutSort, setIsOtherFranchisesLoading: setIsOtherFranchisesLoading })
        } else if (zipCode === "") {
            setZipCodeFranchises([])
            const defaultFranchisesIds = defaultFranchisees?.map?.((f) => f?.id)
            setZipCodeLocationData(autoPickZipCodeLocationData)
            return handleGetOtherFranchisesV3(urlParamData?.brandId, defaultFranchisesIds, urlParamData?.zipCode, Math.round(90 * KM_PER_MILE), setOtherFranchisesWithOutSort, setIsOtherFranchisesLoading)
        } else {
            setZipCodeFranchises([])
            setOtherFranchisesWithOutSort([])
        }
    }, [defaultFranchisees, urlParamData?.brandId, urlParamData?.zipCode, zipCode, autoPickZipCodeLocationData, debouncedHandleGetGetOtherFranchises])

    useEffect(() => {
        const sortedOtherFranchisesArray = sortFranchises([...otherFranchisesWithOutSort])
        setOtherFranchises(sortedOtherFranchisesArray)
        const franchisesWithinRadius = getFranchisesWithinRadius([...defaultFranchisees, ...sortedOtherFranchisesArray, ...zipCodeFranchises], radius)
        const franchisesOutsideRadius = getDistanceCalculatedFranchisesOutsideRadius([...defaultFranchisees, ...sortedOtherFranchisesArray, zipCodeFranchises], radius)
        setWithinRadiusFranchises(franchisesWithinRadius)
        setFranchisesOutsideRadius(franchisesOutsideRadius)
    }, [defaultFranchisees, otherFranchisesWithOutSort, zipCodeFranchises, radius])

    useEffect(() => { // Use to set FAQ franchise when no Auto picked franchise
        if (defaultFranchisees.length === 0 && otherFranchises.length > 0 && zipCodeFranchises.length === 0) {
            handleFaqFranchiseChange(otherFranchises[0].id)
        }
    }, [defaultFranchisees, otherFranchises, zipCodeFranchises])

    useEffect(() => {
        const shouldOpenEnterZipCodeModal = getShouldOpenEnterZipCodeModal(withinRadiusFranchises, zipCodeLocationData)
        setIsZipEnterModalOpen(shouldOpenEnterZipCodeModal)
    }, [withinRadiusFranchises, zipCodeLocationData])


    const handleAgentAssist = (interactionGuid) => {
        onSyncdesktopAppButtonHandler("", showNotification)
        if (getFromSessionStorage(SESSION_STORAGE_KEYS.interactionGuid) !== interactionGuid) {
            setSessionStorage(SESSION_STORAGE_KEYS.FAQ_temp, JSON.stringify([]))
            setSessionStorage(SESSION_STORAGE_KEYS.Question_keys, JSON.stringify([]))
        }
    }

    const handleFaqFranchiseChange = (franchiseOd) => {
        setSessionStorage(SESSION_STORAGE_KEYS.FAQ_franchise_id, franchiseOd)
        setFaqFranchiseChanged(!faqFranchiseChanged)
    }

    const onSelectFranchise = (item) => {
        setSelectedFranchise(item)
    }

    const handleDialogOpen = (franchise) => {
        setFranchise(franchise)
        setDialogOpen(true)
    }

    const handleDialogClose = () => {
        setFranchise(null)
        setDialogOpen(false)
    }

    const handleOpenCallCenterBookingConfirmationModal = (franchise) => {
        if (franchise?.is_call_center_booking) {
            handleDialogOpen(franchise)
            return
        }
        setFranchiseSelectedForBooking(franchise)
        setIsOpenCallCenterBookingConfirmationModal(true)
    }

    const handleCloseCallCenterBookingConfirmationModal = () => {
        setFranchiseSelectedForBooking(null)
        setIsOpenCallCenterBookingConfirmationModal(false)
    }

    const handleMapDialogOpen = (mapFranchise) => {
        setMapFranchise(mapFranchise)
        setIsMapDialogOpen(true)
    }

    const handleMapDialogClose = () => {
        setMapFranchise(null)
        setIsMapDialogOpen(false)
    }

    const handleMapOpen = () => {
        setMapOpen(true)
        const shouldOpenEnterZipCodeModal = getShouldOpenEnterZipCodeModal(withinRadiusFranchises, zipCodeLocationData)
        setIsZipEnterModalOpen(shouldOpenEnterZipCodeModal)
    }

    const onMapClose = () => {
        setMapOpen(false)
        setSelectedFranchise({})
    }

    const closeZipEnterModal = () => {
        setIsZipEnterModalOpen(false)
    }


    function handleSetFranchisesWithinRadius(radius = 60) {
        setRadius(radius)
    }

    function navigateToServiceTitanCreateLeadModal(brandId, franchiseId, franchiseServiceTitanTenantId, callerId, zipCode, jobId, jobType, callType) {
        let newCallerId = callerId
        if (!isValidPhoneNumber(newCallerId)) newCallerId = ""
        removeCallDetailsFromLocalStorage()
        history.push(`/leads?section=servicetitan&brandId=${brandId ?? ""}&franchiseId=${franchiseId ?? ""}&tenantId=${franchiseServiceTitanTenantId ?? ""}&callerId=${newCallerId ?? ""}&zipCode=${zipCode ?? ""}&isFromCallWindow=true&call_type=${callType}&jobId=${jobId}&type=${jobType}`)
    }

    function getShouldDisplayGotoMapButton(otherFranchises) {
        const result = otherFranchises?.find((franchise) => {
            return franchise?.drive_distance?.drive_distance_text ? franchise?.drive_distance?.drive_distance_text : franchise?.distance_in_radius ? false : false
        })
        if (result) {
            return true
        } else {
            return false
        }
    }

    function getIsServiceAreaFranchise(franchise) {
        const isWithinServiceArea = [...defaultFranchisees, ...zipCodeFranchises].find((f) => f.id === franchise?.id) && !otherFranchises.find((f) => f.id === franchise?.id)
        setIseCheckingServiceAreaFranchise(false)
        return isWithinServiceArea && isWithinServiceArea != undefined ? true : false;
    }

    return <Box sx={{ width: "100vw", height: "100vh", display: "flex", flexDirection: "column", gap: "0.5em" }}>
        <TopBar />
        <Box sx={{ flexGrow: 2, width: "100%", overflowY: "scroll", display: "flex", flexDirection: "row", gap: "0.5em", marginBottom: "0.5em" }} >
            <CallerDetails
                callerId={urlParamData?.callerId}
                zipCode={urlParamData?.zipCode}
                channelId={urlParamData?.channelId}
                channelName={urlParamData?.channelName}
                brand={brand?.brand_name}
                brandImg={brand?.brand_img}
                brandId={brand?.brand_id}
                isPreHoldJob={urlParamData?.jobId}
                media={urlParamData?.media}
                holdJobClickCount={holdJobClickCount}
            />
            {mapOpen ? <MapView onMapClose={onMapClose} radius={radius} handleSetFranchisesWithinRadius={handleSetFranchisesWithinRadius} setZipCode={setZipCode} zipCodeLocationData={zipCodeLocationData} withinRadiusFranchises={withinRadiusFranchises} onSelectFranchise={onSelectFranchise} franchisesOutsideRadius={franchisesOutsideRadius} selectedFranchise={selectedFranchise} setSelectedFranchise={setSelectedFranchise} handleMapDialogOpen={handleMapDialogOpen} isZipEnterModalOpen={isZipEnterModalOpen} isFranchisesByChannelIdAndZipCodeLoading={isFranchisesByChannelIdAndZipCodeLoading} isOtherFranchisesLoading={isOtherFranchisesLoading} closeZipEnterModal={closeZipEnterModal} zipCode={zipCode} isMapDialogOpen={isMapDialogOpen} handleMapDialogClose={handleMapDialogClose} channelId={urlParamData?.channelId} jobId={urlParamData?.jobId} mapFranchise={mapFranchise} brand={brand} callerId={urlParamData?.callerId} navigateToServiceTitanCreateLeadModal={navigateToServiceTitanCreateLeadModal} showNotification={showNotification} getIsServiceAreaFranchise={getIsServiceAreaFranchise} iseCheckingServiceAreaFranchise={iseCheckingServiceAreaFranchise} />
                : <FranchiseSelectView urlParamData={urlParamData} isFranchisesByChannelIdAndZipCodeLoading={isFranchisesByChannelIdAndZipCodeLoading} defaultFranchisees={defaultFranchisees} channelId={urlParamData?.channelId} otherFranchises={otherFranchises} isOtherFranchisesLoading={isOtherFranchisesLoading} showNotification={showNotification} handleDialogOpen={handleDialogOpen} isDisplayGoToMapBtn={getShouldDisplayGotoMapButton} handleMapOpen={handleMapOpen} setZipCode={setZipCode} dialogOpen={dialogOpen} handleDialogClose={handleDialogClose} jobId={urlParamData?.jobId} franchise={franchise} brand={brand} callerId={urlParamData?.callerId} zipCode={zipCode} navigateToServiceTitanCreateLeadModal={navigateToServiceTitanCreateLeadModal} zipCodeFranchises={zipCodeFranchises} isZipCodeFranchisesLoading={isZipCodeFranchisesLoading} getIsWithinServiceArea={getIsServiceAreaFranchise} handleFaqFranchiseChange={handleFaqFranchiseChange} isOpenCallCenterBookingConfirmationModal={isOpenCallCenterBookingConfirmationModal} handleOpenCallCenterBookingConfirmationModal={handleOpenCallCenterBookingConfirmationModal} handleCloseCallCenterBookingConfirmationModal={handleCloseCallCenterBookingConfirmationModal} franchiseSelectedForBooking={franchiseSelectedForBooking} iseCheckingServiceAreaFranchise={iseCheckingServiceAreaFranchise} />
            }
        </Box>
    </Box >
}

export default withRouter(Home)

function getShouldOpenEnterZipCodeModal(withinRadiusFranchises, zipCodeLocationData) {
    if (withinRadiusFranchises?.length === 0 || !(zipCodeLocationData?.lat || zipCodeLocationData?.lng)) {
        return true
    } else {
        return false
    }
}