import { Box, Button, Dialog, Typography } from '@mui/material'
import React, { useMemo, useState } from 'react'
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import DialogHeader from '../dialog/DialogHeader';
import PhoneIcon from '@mui/icons-material/Phone';
import FilterTiltShiftIcon from '@mui/icons-material/FilterTiltShift';
import { USER_TYPES } from '../../constants/users';
import { NOTIFICATION_TYPES } from '../../constants/common';
import Notification from '../Notification';
import AgentAssistSettingsSection from './AgentAssistsSettingsSection';
import EightByEightSettingsSection from './EigthByEightSettingsSection';
import LuminSettingsSection from './LuminSetttingsSection';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import UserPreferencesSettingsSection from './UserPreferencesSettingsSection';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import GeneralSettingsSection from './GeneralSettingsSection';

const settingsSections = {
    general: {
        id: "general",
        name: "General",
        icon: RoomPreferencesIcon,
        user: USER_TYPES.superAdmin

    },
    ccai: {
        id: "ccai",
        name: "Agent Assist",
        icon: SupportAgentIcon,
        user: USER_TYPES.agent

    },
    eigthByEight: {
        id: "eightByEight",
        name: "8x8",
        icon: PhoneIcon,
        user: USER_TYPES.superAdmin
    },
    lumin: {
        id: "lumin",
        name: "Lumin",
        icon: FilterTiltShiftIcon,
        user: USER_TYPES.superAdmin
    },
    userPreferences: {
        id: "userPreferences",
        name: "User Preferences",
        icon: AccountCircleOutlinedIcon,
        user: USER_TYPES.agent,
    },
}

export default function SettingsModal(props) {
    const { open, handleClose } = props
    const [currentSection, setCurrentSection] = useState(settingsSections.ccai)
    const [notify, setNotify] = useState({ message: "", type: NOTIFICATION_TYPES.SUCCESS, isOpen: false })
    const userLevel = useMemo(() => localStorage.getItem("user_level"))

    function renderMainSection(section) {
        switch (section.id) {
            case settingsSections.general.id:
                return <GeneralSettingsSection showNotification={showNotification} />
            case settingsSections.ccai.id:
                return <AgentAssistSettingsSection handleClose={handleClose} />
            case settingsSections.eigthByEight.id:
                return <EightByEightSettingsSection showNotification={showNotification} />
            case settingsSections.lumin.id:
                return <LuminSettingsSection showNotification={showNotification} />
            case settingsSections.userPreferences.id:
                return <UserPreferencesSettingsSection showNotification={showNotification} />
            default: return <></>
        }
    }

    function showNotification(notify = {}) {
        setNotify({ ...notify, isOpen: true })
    }

    return (
        <Dialog fullWidth maxWidth="md" open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description"  >
            <Box className={'dialog-color'}>
                <DialogHeader onClose={handleClose}>
                    <Typography paddingTop="1em" paddingLeft="1em" component="h2" variant="h6">Settings</Typography>
                </DialogHeader>
                <Box sx={{ height: "80vh", display: "flex", flexDirection: "row", padding: "1em", gap: "1em" }} >
                    <Box sx={{ flex: "1 0 0", color: "red" }}>
                        {Object.values(settingsSections).map((s) => userLevel >= s.user.level && <Button
                            key={s.id}
                            sx={{ textAlign: "left", paddingInline: "1em", textTransform: "none" }}
                            variant={currentSection.id === s.id ? "contained" : 'text'}
                            startIcon={<s.icon />} fullWidth={true}
                            style={{ justifyContent: "flex-start" }}
                            onClick={() => setCurrentSection(s)}>
                            {s.name}
                        </Button>)}
                    </Box>
                    <Box sx={{ flex: "4 0 0" }}>
                        {renderMainSection(currentSection)}
                    </Box>
                </Box>
                <Notification notify={notify} setNotify={setNotify} />
            </Box>
        </Dialog>
    )
}