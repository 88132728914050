import { Box, Button, Dialog, Tooltip, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import DialogBody from "../../../../../components/dialog/DialogBody";
import DialogHeader from "../../../../../components/dialog/DialogHeader";
import { getSingleCustomerDetails, getSingleJobDetails, getVonigoFranchisesByBrandId, updateJob } from "../../../../../services/vonigo/jobScheduling";
import { handleGetAllBrands } from "../../../../../state-services-dublicate/brandServices";
import CreateJobFranchiseSelector from "../../components/CreateJobFranchiseSelector";
import CreateJobSection from "./CreateJobSection";
import CustomerDetailsSection from "./CustomerDetailsSection";
import VonigoCustomerSearchSection from "./VonigoSearchSection";
import { NOTIFICATION_TYPES } from "../../../../../constants/common";
import { handleSearchCustomers } from "../../../../../state-services-dublicate/vonigo";
import LoadingButton from "../../../../../components/LoadingButton";

export default function CreateJobModal(props) {
    let { showNotification, onClose, open, onRefreshFilters, selectedBrandId, setJobToView, selectedFranchise, selectedZipCode, selectedPhoneNumber, selectedJobToEdit, selectedDraftToContinue } = props;
    if (selectedPhoneNumber?.length >= 10) {
        selectedPhoneNumber = selectedPhoneNumber ? selectedPhoneNumber.substr(selectedPhoneNumber.length - 10) : undefined;
    } else {
        selectedPhoneNumber = undefined;
    }
    const existingJob = useMemo(() => selectedJobToEdit ?? selectedDraftToContinue, [selectedJobToEdit, selectedDraftToContinue])
    const [brands, setBrands] = useState([]);
    const [franchises, setFranchises] = useState([]);
    const [brand, setBrand] = useState()
    const [franchise, setFranchise] = useState()
    const [isBrandsLoading, setIsBrandsLoading] = useState(false);
    const [isFranchisesLoading, setIsFranchisesLoading] = useState(false);
    const [searchPar, setSearchPar] = useState(selectedPhoneNumber ?? selectedZipCode ?? selectedDraftToContinue?.callerId ?? "")
    const [client, setClient] = useState();
    const [location, setLocation] = useState()
    const [locations, setLocations] = useState([])
    const [contact, setContact] = useState();
    const [contacts, setContacts] = useState([])
    const [jobDetails, setJobDetails] = useState({});
    const [createdJobDetails, setJobCretedDetails] = useState()
    const [existingCustomers, setExistingCustomers] = useState([])
    const [selectedCustomer, setSelectedCustomer] = useState()
    const [isNewCustomer, setIsNewCustomer] = useState(true)
    const [isSearchResultCutomersLoading, setIsSearchResultCutomersLoading] = useState(false)
    const [isCreateJobLoading, setIsCreateJobLoading] = useState();
    const [numberOfTimesJobDetailsChanged, setnumberOfTimesJobDetailsChanged] = useState(0)

    useEffect(() => {
        handleGetAllBrands(selectedBrandId, undefined, setBrands, setBrand, setIsBrandsLoading);
    }, [setIsBrandsLoading, setBrands, setBrand, selectedBrandId]);

    useEffect(() => {
        if (brand) {
            setIsFranchisesLoading(true)
            getVonigoFranchisesByBrandId(brand?.brand_id).then((response) => {
                if (Array.isArray(response)) {
                    setFranchises(removeDuplicateFranchises(response))
                    if (selectedFranchise?.brand_id === brand.brand_id) {
                        setFranchise(selectedFranchise)
                    }
                }
            }).catch(() => {
            }).finally(() => setIsFranchisesLoading(false))
        }
    }, [brand, selectedFranchise]);

    useEffect(() => {
        if (brand && franchise && selectedJobToEdit) {
            getSingleCustomerDetails(brand?.redirect_url, franchise?.franchise_id, selectedJobToEdit?.client?.objectID).then((response) => {
                setClient(response)
                isNewCustomer(false)
            }).catch((err) => {
            })
        }
    }, [selectedJobToEdit, brand, franchise])

    useEffect(() => {
        if (createdJobDetails) {
            onClose(createdJobDetails)
        }
    }, [createdJobDetails])

    function removeDuplicateFranchises(franchises) {
        const uniqueFranchiseIds = []
        const uniqueFranchises = franchises.filter(franchise => {
            const isDuplicate = uniqueFranchiseIds.includes(franchise.franchise_id)
            if (!isDuplicate) {
                uniqueFranchiseIds.push(franchise.franchise_id)
                return true
            }
            return false
        })
        return uniqueFranchises
    }

    function isEditJobButtonDisabled() {
        if (numberOfTimesJobDetailsChanged === 0) {
            return true
        }
        else if (numberOfTimesJobDetailsChanged !== 0) {
            const isEditJobSectionComplete = jobDetails?.summary && jobDetails?.labelOptionId
            return !(isEditJobSectionComplete)
        }
    }

    function onJobCreated(job) {
        setJobCretedDetails(job)
    }

    function handleOnChangeClient(c) {
        setContact()
        setLocation()
        setClient(c)
    }

    function handleCreateJob(e) {
        e?.preventDefault()
        if (selectedJobToEdit) {
            editJob(brand, franchise, jobDetails, selectedJobToEdit?.objectID, setIsCreateJobLoading, createdJobDetails, showNotification, onJobCreated, setJobToView, onClose, onRefreshFilters)
        }
    }

    return <>
        <Dialog fullWidth maxWidth="xl" open={open}>
            <Box className={"dialog-color"}>
                <DialogHeader onClose={selectedJobToEdit ? onClose : () => { saveDraft(onClose); }}>
                    <Typography margin="1em" variant="h5" component={"h1"} fontWeight="bold" >{selectedJobToEdit ? `Edit Job #${selectedJobToEdit.jobID}` : "Create New Job"}</Typography>
                </DialogHeader>
                <DialogBody>
                    <form onSubmit={handleCreateJob} autoComplete="off" >
                        <CreateJobFranchiseSelector brands={brands} brand={brand} setBrand={setBrand} isBrandsLoading={isBrandsLoading} franchises={franchises} setFranchises={setFranchises} franchise={franchise} setFranchise={setFranchise} isFranchisesLoading={isFranchisesLoading} isDisabled={existingJob ? true : false} />
                        <VonigoCustomerSearchSection isDissabled={!(franchise && brand)} selectedJobToEdit={selectedJobToEdit} searchPar={searchPar} onSearchParChanged={(e) => { setSearchPar(e?.target?.value ?? "") }} isSearchResultCutomersLoading={isSearchResultCutomersLoading} onSearch={() => handleSearchCustomers(brand, franchise, searchPar, setIsSearchResultCutomersLoading, setSelectedCustomer, setExistingCustomers, setContact, setContacts, setLocation, setLocations)} />
                        <Box sx={{ display: 'flex', flexDirection: "row", width: "100%", height: "3em", justifyContent: "left", alignItems: "center" }}>
                            <h4>Customer Details</h4>
                            {!selectedJobToEdit ? <Tooltip enterDelay={1000} enterTouchDelay={1000} title={isNewCustomer ? "Create job for an existing customer" : "Create job for a new customer"} ><Button sx={{ color: "#00a79d" }} variant="text" onClick={() => { setIsNewCustomer(!isNewCustomer) }}>{isNewCustomer ? " Existing" : "\u002B New"}</Button></Tooltip> : <></>}
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: "row", width: "100%" }} >
                            <CustomerDetailsSection client={client} selectedJobToEdit={selectedJobToEdit} isDisabled={existingJob ? true : false} isNewCustomer={isNewCustomer} setContact={setContact} setLocation={setLocation} setIsNewCustomer={setIsNewCustomer} brand={brand} franchise={franchise} locations={locations}
                                setLocations={setLocations} location={location} contacts={contacts} setContacts={setContacts} contact={contact} existingCustomers={existingCustomers} setExistingCustomers={setExistingCustomers} selectedCustomer={selectedCustomer} setSelectedCustomer={setSelectedCustomer} isExistingCustomersLoading={isSearchResultCutomersLoading}
                                onClientChanged={handleOnChangeClient} onLocationChanged={(l) => setLocation(l)} onContactChanged={(c) => setContact(c)} showNotification={showNotification} />
                        </Box>
                        <CreateJobSection brand={brand} franchise={franchise} numberOfTimesJobDetailsChanged={numberOfTimesJobDetailsChanged} setnumberOfTimesJobDetailsChanged={setnumberOfTimesJobDetailsChanged}
                            existingJob={existingJob} onJobDetailsChange={setJobDetails} onTagTypesChanged={() => { }} />
                        <Box sx={{display: 'flex', flexDirection:'row',  justifyContent:'end', paddingBlock:'20px', gap:'1em'}}>
                            <Button size={"small"} variant="contained" onClick={!selectedJobToEdit ? () => { saveDraft(onClose) } : () => { onClose() }} className={"btn-modal"}>Cancel</Button>
                            <LoadingButton type={"submit"} size={"small"} variant="contained" disabled={isEditJobButtonDisabled()} loading={isCreateJobLoading} className={!selectedJobToEdit ? "btn-primary" : isEditJobButtonDisabled() ? "btn-disable" : "btn-primary"} >
                                {selectedJobToEdit ? "Update Job" : "Create Job"}
                            </LoadingButton>
                        </Box>
                    </form>
                </DialogBody>
            </Box>
        </Dialog>
    </>
}

function saveDraft(onDone = () => { }) {
    onDone()
}

async function editJob(brand, franchise, jobDetails, jobId, setIsCreateJobLoading, createdJobDetails, showNotification, setJobToView, onClose, onRefreshFilters) {
    try {
        setIsCreateJobLoading?.(true)
        const jobUpdateRequest = {
            baseUrl: brand?.redirect_url,
            franchiseId: franchise?.franchise_id,
            jobId: jobId,
            summary: jobDetails?.summary,
            labelOptionId: jobDetails?.labelOptionId
        }
        await updateJob?.(jobUpdateRequest)
        let jobDetailsRes = await getSingleJobDetails(brand?.redirect_url, franchise?.franchise_id, jobId)
        setJobToView(jobDetailsRes)
        showNotification({ message: "Job has been updated successfully", type: NOTIFICATION_TYPES.SUCCESS, isOpen: true })
    } catch (error) {
        showNotification({ message: "Important: Job couldn’t be updated at this time. Please try again", type: NOTIFICATION_TYPES.ERROR })
    } finally {
        setIsCreateJobLoading?.(false)
    }
}