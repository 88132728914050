export const invalidFranchiseId = -1

export const tabName = {
    brand: "brand",
    franchise: "franchise",
}

export const faqGetReqTypes = {
    Brand: "brand",
    Franchise: "franchise",
    AllFranchise: "allFranchise",
}