import React, { useContext, useEffect, useState } from 'react'
import { Box, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { handleGetFollwupHistory } from '../../../../../state-services/serviceTitanEstimates/serviceTitanEstimates'
import { CommonContext } from '../../../../../contexts/CommonContextProvider'
import ErrorView from '../../../../../components/ErrorView'
import { ERROR_COMPONENTS } from '../../../../../constants/common'
import { getTenantTimeZoneTimeString } from '../../../../../utils/time/time'

export default function FollowupSummary({ estimate }) {

    const { allUsers, generalTenantConfiguration, timezones } = useContext(CommonContext);
    const [followupHistory, setFollowupHistory] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)

    useEffect(() => {
        handleGetFollwupHistory(estimate?.id, setFollowupHistory, setIsLoading, setIsError)
        return () => {
            setFollowupHistory([])
        }
    }, [estimate?.id])

    return (
        <Box sx={{ minHeight: "20vh", maxHeight: "50vh", display: "flex", overflow: "auto" }}>
            <TableContainer sx={{ flexGrow: 2, marginTop: "0.3em" }}>
                <Table aria-label="simple table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell className="table-header" style={{ textAlign: "left" }} >Follow-Up Stage</TableCell>
                            <TableCell className="table-header" style={{ textAlign: "left" }} >Follow-Up By</TableCell>
                            <TableCell className="table-header" style={{ textAlign: "left" }}  >Follow-Up At</TableCell>
                        </TableRow>
                    </TableHead>
                    {isLoading ? buildLoadingView()
                        : isError ? buildErrorView()
                            : !followupHistory?.length ? buildNoEstimatesToShowView() : <TableBody sx={{ flexGrow: 2 }}>
                                {followupHistory?.map?.((h, index) => {
                                    let createdBy = allUsers?.find?.(u => u?.user_id === h?.created_by)
                                    createdBy = createdBy?.first_name + " " + createdBy?.last_name
                                    return <TableRow>
                                        <TableCell className="table-header" style={{ textAlign: "left" }} >{h?.followup_stage}</TableCell>
                                        <TableCell className="table-header" style={{ textAlign: "left" }} >{createdBy ?? "-"}</TableCell>
                                        <TableCell className="table-header" style={{ textAlign: "left" }} >{getTenantTimeZoneTimeString(h?.created_at, generalTenantConfiguration?.time_zone, timezones)}</TableCell>
                                    </TableRow>

                                })}
                            </TableBody>}
                </Table>
            </TableContainer>
        </Box>
    )
}

function buildErrorView() {
    return buildInformationRowContainer(<ErrorView type={ERROR_COMPONENTS.SomthingWentWrong.type} />)
}

function buildNoEstimatesToShowView() {
    return buildInformationRowContainer(<ErrorView type={ERROR_COMPONENTS.NoFollowUpHistoryFound.type} />)
}

function buildLoadingView() {
    return buildInformationRowContainer(<CircularProgress size={30} color="inherit" />)
}

function buildInformationRowContainer(children) {
    return <TableRow><TableCell colSpan={10} align="center" style={{ border: "none", height: "300px", color: "grey" }}>
        {children}
    </TableCell></TableRow>;
}
