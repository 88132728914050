import { IconButton, Paper, Tooltip } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';

export function AgentCard(props) {
    const { agent, index, cssClass, setSelectedAgent, handleViewAgentStatics, agentStatusCodeData, setCurrentAgentStatucCodeData, setChartLabels, setChartData, calculateStarts, selectedAgent } = props

    let isScoreDisabled = true

    return (
        <Paper key={index} className={cssClass} sx={{ backgroundColor: agent?.user?.user_id == selectedAgent?.user?.user_id ? 'wallboard.agentInsightCard' : 'form.input' }} >
            <div style={{ minWidth: "35%", maxWidth: "70%", paddingLeft: "10px" }}>
                <p>{agent?.user?.first_name} {agent?.user?.last_name}</p>
            </div>
            {!isScoreDisabled &&
                <div style={{ minWidth: "10%", marginLeft: "auto" }}>
                    <p>{agent?.data && agent?.data?.score <= 100 ? agent?.data?.score : 100}</p>
                </div>
            }
            {!isScoreDisabled &&
                < div style={{ minWidth: "32%", marginLeft: "auto" }}>
                    {calculateStarts(agent)}
                </div>
            }
            <div style={{ marginLeft: "auto" }}>
                <IconButton onClick={() => {
                    handleViewAgentStatics(agent, agentStatusCodeData, setCurrentAgentStatucCodeData, setChartLabels, setChartData, setSelectedAgent);
                }} color="primary" size={"medium"} style={{ marginRight: "10px" }}>
                    <Tooltip title={"View Agent Statistics"}><VisibilityIcon style={{ height: "20px", width: "20px" }} /></Tooltip>
                </IconButton>
            </div>
        </Paper >
    )
}