import { useContext, useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Dialog, Button, Autocomplete, Tooltip, Paper, Typography, CircularProgress, Collapse } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
import FaxIcon from '@mui/icons-material/Fax';
import CallMadeIcon from '@mui/icons-material/CallMade';

import CustomTextField from "../../../../../components/CustomTextField";
import SmallCard from "../../../../../components/servicetitan/SmallCard"

import { addFollowUpDate, updateMiddlewareFollowUp, dismissLead } from "../../../../../services/serviceTitan"

import { getPhoneNumberWithInputMask } from "../../../../../utils/inputMasks"
import { getAddressString, isAbleToNavigateToServiceTitanPage, getMatchingUserByUserId, sendAEmail, getUserFullName } from "../../../../../utils/miscellaneous"

import { NOTIFICATION_TYPES } from "../../../../../constants/common";
import { MIDDLEWARE_FOLLOW_UP_STAGES } from "../../../../../constants/serviceTitan"
import { handleGetBusinessUnit, handleGetCallReasons, handleGetCampaignById, handleGetCustomerById, handleGetCustomerContacts, handleGetJobTypeById, handleGetLeadFollowUpHistory, handleGetLocationById, handleGetTagTypes } from "../../../../../state-services-dublicate/serviceTitan";
import DialogHeader from "../../../../../components/dialog/DialogHeader";
import DialogBody from "../../../../../components/dialog/DialogBody";
import CustomDatePicker from "../../../../../components/CustomDatePicker";
import FollowUpHistory from "./FollowUpHistory";
import RefreshErrorView from "../../../../../components/refreshView/RefreshErrorView";
import LoadingButton, { LoadingButtonForDelete } from "../../../../../components/LoadingButton";
import AodIcon from '@mui/icons-material/Aod';
import { CommonContext } from "../../../../../contexts/CommonContextProvider";
import SalesAcceleratorSection from "../../../../../components/lumin/SalesAcceleratorSection";
import { handleGetFranchiseById } from "../../../../../state-services/franchise";
import ImageBox from "../../../../../components/LogoBox/ImageBox";
import { getTenantTimeZoneTimeString } from "../../../../../utils/time/time";
import { getMatchingTimezoneByName } from "../../../../../utils/timezone/timezone";
import { LeadSectionNotes } from "./LeadSectionNotes";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export default function ExpandedLeadModal(props) {
    const { showNotification, onClose, open, lead, franchise, brand } = props;

    const { generalTenantConfiguration, timezones } = useContext(CommonContext)

    const [followUpDate, setFollowUpDate] = useState(lead?.leadResponse?.followUpDate)
    const [status, setStatus] = useState(lead?.leadResponse?.status);
    const [customer, setCustomer] = useState();
    const [customerContacts, setCustomerContacts] = useState([]);
    const [location, setLocation] = useState();
    const [jobType, setJobType] = useState();
    const [campaign, setCampaign] = useState();
    const [tagTypes, setTagTypes] = useState([]);
    const [businessUnit, setBusinessUnit] = useState();
    const [newFollowUpDate, setNewFollowUpdate] = useState();
    const [newMiddlewareFollowUpStage, setNewMiddlewareFollowUpStage] = useState(lead?.middlewareServiceTitanLead?.middlewareFollowUpStage);
    const [callReasons, setCallReasons] = useState([]);
    const [leadDissmissReason, setLeadDissmissReason] = useState();
    const [leadDismissLoading, setLeadDismissLoading] = useState(false)

    const [isUpdateMiddlewareFollowUpLoading, setIsUpdateMiddlewareFollowUpLoading] = useState(false);
    const [isCallReasonsLoading, setIsCallReasonsLoading] = useState(false);

    const [historyFollowUps, setHistoryFollowUps] = useState([]);
    const [isFollowUpHistoryLoading, setIsFollowUpHistoryLoading] = useState(false);
    const [isFollowUpHistoryError, setIsFollowUpHistoryError] = useState(false);
    const [toggleRefreshFollowUpHistory, setToggleRefreshFollowUpHistory] = useState(true)
    const { popPhoneDialer, allUsers } = useContext(CommonContext)
    const [franchiseDetails, setFranchiseDetails] = useState(franchise)
    const [isNotesOpen, setIsNotesOpen] = useState(true);

    useEffect(() => {
        if (franchise?.id) {
            handleGetFranchiseById(franchise?.id, setFranchiseDetails)
        }
    }, [franchise?.id])

    useEffect(() => {
        handleGetLeadFollowUpHistory(lead?.middlewareServiceTitanLead?.id, setHistoryFollowUps, setIsFollowUpHistoryLoading, setIsFollowUpHistoryError)
    }, [lead?.middlewareServiceTitanLead?.id, toggleRefreshFollowUpHistory])

    useEffect(() => {
        if (lead && franchise) {
            const getCustomerByIdCancelFunc = handleGetCustomerById(franchise, lead?.leadResponse?.customerId, setCustomer);
            let getLocationByIdCancelFunc;
            if (lead.leadResponse.locationId) {
                getLocationByIdCancelFunc = handleGetLocationById(franchise, lead?.leadResponse?.locationId, setLocation);
            }
            let getJobTypeByIdCancelFunc;
            if (lead.leadResponse.jobTypeId) {
                getJobTypeByIdCancelFunc = handleGetJobTypeById(franchise, lead.leadResponse.jobTypeId, setJobType);
            }
            const getCampaignByIdCancelFunc = handleGetCampaignById(franchise, lead.leadResponse.campaignId, setCampaign);
            const getTagTypesCancelFunc = handleGetTagTypes(franchise, lead?.leadResponse?.tagTypeIds, () => { }, setTagTypes, () => { });
            let getBusinessUnitCancelFunc;
            if (lead.leadResponse.businessUnitId) {
                getBusinessUnitCancelFunc = handleGetBusinessUnit(franchise, lead.leadResponse.businessUnitId, setBusinessUnit);
            }
            const getCallReasonseCancelFunc = handleGetCallReasons(franchise, undefined, setCallReasons, () => { }, setIsCallReasonsLoading);
            return () => {
                getCustomerByIdCancelFunc?.();
                getLocationByIdCancelFunc?.();
                getJobTypeByIdCancelFunc?.();
                getCampaignByIdCancelFunc?.();
                getTagTypesCancelFunc?.();
                getBusinessUnitCancelFunc?.();
                getCallReasonseCancelFunc?.();
            }
        }
    }, [franchise?.id, lead?.leadResponse?.id])

    useEffect(() => {
        if (callReasons?.length > 0) {
            const callReason = callReasons.find((callReason) => callReason?.id === lead?.leadResponse?.callReasonId)
            if (callReason) setLeadDissmissReason(callReason)
        }
    }, [callReasons])

    useEffect(() => {
        if (franchise && customer) {
            return handleGetCustomerContacts(franchise?.id, customer?.id, setCustomerContacts);
        }
    }, [franchise, customer])

    function onDismissLeadPressed() {
        if (franchise) {
            setLeadDismissLoading(true)
            dismissLead(franchise.service_titan_tenant_id, franchise.service_titan_client_id, franchise.service_titan_client_secret, lead.leadResponse.id, { dismissingReasonId: leadDissmissReason?.id }).then((dissmissResponse) => {
                showNotification({ message: "Lead dismissed", type: NOTIFICATION_TYPES.SUCCESS })
                setStatus("Dismissed")
                lead.leadResponse.status = "Dismissed"
            }).catch(() => {
                showNotification({ message: "Important: Lead couldn’t be dismiss at this time. Please try again", type: NOTIFICATION_TYPES.ERROR })
            }).finally(() => {
                setLeadDismissLoading(false)
            })
        }
    }

    function onUpdateMiddlewareFollowUpStage() {
        setIsUpdateMiddlewareFollowUpLoading(true)
        const newMiddlewareServiceTitanLead = { ...lead.middlewareServiceTitanLead, middlewareFollowUpStage: (newMiddlewareFollowUpStage ?? lead.middlewareServiceTitanLead?.middlewareFollowUpStage), middlewareAssignedToId: lead?.middlewareServiceTitanLead?.middlewareAssignedToId }
        updateMiddlewareFollowUp(newMiddlewareServiceTitanLead).then(() => {
            lead.middlewareServiceTitanLead = newMiddlewareServiceTitanLead;
            showNotification({ message: "The follow-up stage has been updated successfully.", type: NOTIFICATION_TYPES.SUCCESS })
        }).catch(() => {
            showNotification({ message: "Important: Your follow-up stage couldn’t be updated at this time. Please try again", type: NOTIFICATION_TYPES.ERROR })
        }).finally(() => {
            setIsUpdateMiddlewareFollowUpLoading(false)
        })
    }

    function onScheduleButtonPressed() {
        if (franchise && lead && newFollowUpDate) {
            addFollowUpDate(franchise.service_titan_tenant_id, franchise.service_titan_client_id, franchise.service_titan_client_secret, lead.leadResponse.id, { followUpdate: newFollowUpDate }).then((followUpResponse) => {
                setFollowUpDate(followUpResponse.followUpDate);
                setNewFollowUpdate(undefined)
                lead.leadResponse.followUpDate = followUpResponse.followUpDate //To update lead table when close
                showNotification({ message: "A new follow-up date is added successfully.", type: NOTIFICATION_TYPES.SUCCESS })
            }).catch((err) => {
                showNotification({ message: "Important: Your new follow-up date couldn’t be added at this time. Please try again", type: NOTIFICATION_TYPES.ERROR })
            })
        }
    }

    function goToServiceTitanLeadPage(leadId) {
        if (leadId && franchise?.service_titan_tenant_name) {
            window.open(`https://go.servicetitan.com/#/lead/${leadId}?proxyTenant=${franchise?.service_titan_tenant_name}`, "_blank");
        }
    }

    function onFollowUpDateChanged(date) {
        const dateNow = (new Date()).toISOString()
        const dateISOString = date?.toISOString()
        if (date && dateISOString > dateNow) {
            setNewFollowUpdate(dateISOString);
        } else if (date && dateISOString < dateNow) {
            setNewFollowUpdate(dateNow);
        } else {
            setNewFollowUpdate()
        }
    }

    async function handlePopPhoneDialer(contact) {
        popPhoneDialer(contact, franchise?.id, brand?.id, lead?.leadResponse?.id, "ServiceTitanLead")
    }

    const handleToggleCollapse = () => {
        setIsNotesOpen(!isNotesOpen);
    };

    return (
        <Dialog onClose={() => { }} open={open} fullWidth maxWidth="lg" disableScrollLock={true}>
            <DialogHeader onClose={() => {
                onClose(lead)
            }} className={'dialog-plain-color'}>
                <Box sx={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center", gap: "0.5em", padding: "1em" }}>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "left", borderRadius: "0.5em", }}>
                        <Typography variant="h5" component={"h1"} fontWeight="bold" >{`Lead #${lead?.leadResponse?.id} `}</Typography>
                        <Typography variant="subtitle2" component={"p"} margin={"0"} padding={"0"} color={"grayText"} >{`${franchise?.franchise_name ?? ""}`}</Typography>
                    </Box>
                    <ImageBox alt="brand-logo" src={brand?.brand_img} size={'large'} />
                    <Box flexGrow={2}></Box>
                    <Box style={{ display: "flex", flexDirection: "row", gap: "0.5em", marginBlock: "0.5em", justifyContent: "flex-end" }}>
                        <div style={{ width: "300px" }} >
                            <CustomDatePicker
                                minTime={new Date()}
                                disabled={lead.leadResponse.status !== "Open"}
                                label={`New Follow-Up Date ( ${getMatchingTimezoneByName(generalTenantConfiguration?.time_zone, timezones)?.abbreviation ?? ""} )`}
                                value={newFollowUpDate ? new Date(newFollowUpDate) : null}
                                timeZone={generalTenantConfiguration?.time_zone}
                                onChange={onFollowUpDateChanged}
                                clearable={newFollowUpDate}
                                onClear={() => setNewFollowUpdate()}
                                required={true}
                            />
                        </div>
                        <Button sx={{ color: "white", paddingInline: "1em", backgroundColor: "#00a79d", textTransform: "none", "&:hover": { backgroundColor: "#00a79e", } }} variant="contained" disabled={(!newFollowUpDate || status !== "Open")} onClick={onScheduleButtonPressed}>New Follow-Up Date</Button>
                        <Tooltip title={isAbleToNavigateToServiceTitanPage(franchise) ? "View in ServiceTitan" : ""}>
                            <IconButton
                                className={isAbleToNavigateToServiceTitanPage(franchise) ? "btn-primary" : "btn-disable"}
                                disabled={!isAbleToNavigateToServiceTitanPage(franchise)}
                                onClick={(e) => {
                                    e.preventDefault();
                                    goToServiceTitanLeadPage(lead.leadResponse.id)
                                }} ><CallMadeIcon /></IconButton>
                        </Tooltip>
                    </Box>
                </Box>
            </DialogHeader>
            <DialogBody className={'dialog-color'}>
                <Paper sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "start", gap: "0.5em", padding: "0.5em", borderRadius: "0.5em", width: "100%", justifyContent: "space-evenly", }}>
                    <SmallCard heading="Next Follow-Up Date" value={followUpDate ? getTenantTimeZoneTimeString(followUpDate, generalTenantConfiguration?.time_zone, timezones) : "-"}></SmallCard>
                    <SmallCard heading="Job Type" value={jobType?.name ?? "-"}></SmallCard>
                    <SmallCard heading="Priority" value={lead?.leadResponse?.priority ?? "-"}></SmallCard>
                    <SmallCard heading="Business Unit" value={businessUnit?.name ?? "-"}></SmallCard>
                    <SmallCard heading="Status" value={status ?? "-"}></SmallCard>
                    <SmallCard heading="Date Created" value={getTenantTimeZoneTimeString(lead.leadResponse.createdOn, generalTenantConfiguration?.time_zone, timezones)}></SmallCard>
                    <SmallCard heading="Campaign" value={campaign?.name ?? "-"}></SmallCard>
                    <SmallCard heading="Created By" value={lead?.middlewareServiceTitanLead?.createdBy ? getUserFullName(getMatchingUserByUserId(lead?.middlewareServiceTitanLead?.createdBy, allUsers) ?? { first_name: "Unknown", last_name: "" }) ?? "-" : "System"}></SmallCard>
                </Paper>
                <Box sx={{ height: "280px", display: "flex", flexDirection: "row", alignItems: "stretch", gap: "0.5em", borderRadius: "0.5em", marginBlock: "1em" }}>
                    <Box sx={{ overflowY: "auto", display: "flex", flexDirection: "column", gap: "0.25em", padding: "0.25em", width: "25%", }}>
                        <Typography style={{ fontSize: "1.5rem", fontWeight: "bold" }} >Tags</Typography>
                        <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "0.5em", }}>
                            {tagTypes.map((tagType) => {
                                return <Typography style={{ paddingBlock: "0.1em", paddingInline: "0.5em", margin: "0", borderRadius: "0.5em" }} sx={{ backgroundColor: 'background.icon' }}>{tagType.name}</Typography>
                            })}
                        </Box>
                    </Box>
                    <Box sx={{ overflowY: "auto", display: "flex", flexDirection: "column", gap: "0.25em", padding: "0.25em", width: "70%" }}>
                        <Typography style={{ fontSize: "1.5rem", fontWeight: "bold" }} >Overview</Typography>
                        <Box sx={{ display: "flex", flexDirection: "row", }}>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={6}>
                                    <Box sx={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
                                        <Typography style={{ fontSize: "0.9em", color: "GrayText", margin: "0", marginBottom: "0.5em" }}>Location</Typography>
                                        <Typography style={{ margin: "0" }}>{location?.name ?? ""}</Typography>
                                        <Typography style={{ margin: "0" }}>{getAddressString(location?.address)}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box sx={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
                                        <Typography style={{ fontSize: "0.9em", color: "GrayText", margin: "0", marginBottom: "0.5em" }}>Customer {`(${customer?.type ?? ""})`}</Typography>
                                        <Typography style={{ margin: "0" }}>{customer?.name ?? ""}</Typography>
                                        <Typography style={{ margin: "0" }}>{getAddressString(customer?.address)}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box sx={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
                                        {customerContacts?.some((contact) => contact.type === "Phone") ? <Typography style={{ fontSize: "0.9em", color: "GrayText", margin: "0", marginBottom: "0.5em" }}>Customer Phone</Typography> : <></>}
                                        {customerContacts.map((contact) => {
                                            return (contact.type === "Phone") ?
                                                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                    <Tooltip title="Call" >
                                                        <IconButton sx={{ color: "#00a79d" }} aria-label="Call" component="span" onClick={() => {
                                                            handlePopPhoneDialer(contact.value)
                                                        }}>
                                                            <CallIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Typography style={{ margin: "0" }}>{getPhoneNumberWithInputMask(contact.value)}</Typography>
                                                </Box>
                                                : <></>
                                        })}
                                    </Box>
                                    <Box sx={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
                                        {customerContacts?.some((contact) => contact.type === "MobilePhone") ? <Typography style={{ fontSize: "0.9em", color: "GrayText", margin: "0", marginBottom: "0.5em" }}>Customer Mobile Phone</Typography> : <></>}
                                        {customerContacts.map((contact) => {
                                            return (contact.type === "MobilePhone") ?
                                                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                    <Tooltip title="Call" >
                                                        <IconButton sx={{ color: "#00a79d" }} aria-label="Call" component="span" onClick={() => {
                                                            handlePopPhoneDialer(contact.value)
                                                        }}>
                                                            <AodIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Typography style={{ margin: "0" }}>{getPhoneNumberWithInputMask(contact.value)}</Typography>
                                                </Box>
                                                : <></>
                                        })}
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box sx={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
                                        {customerContacts?.some((contact) => contact.type === "Email") ? <Typography style={{ fontSize: "0.9em", color: "GrayText", margin: "0", marginBottom: "0.5em" }}>Customer Email</Typography> : <></>}
                                        {customerContacts.map((contact, i) => {
                                            return (contact.type === "Email") ? <Box key={i} sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                <Tooltip title="Email" >
                                                    <IconButton sx={{ color: "#00a79d" }} aria-label="Email" component="span" onClick={() => {
                                                        sendAEmail(contact.value)
                                                    }}>
                                                        <EmailIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Typography style={{ margin: "0" }}>{contact.value}</Typography>
                                            </Box> : <></>
                                        })}
                                    </Box>
                                    <Box sx={{ display: "flex", flexDirection: "column", overflow: "hidden", marginTop: "1em" }}>
                                        {customerContacts?.some((contact) => contact.type === "Fax") ? <Typography style={{ fontSize: "0.9em", color: "GrayText", margin: "0", marginBottom: "0.5em" }}>Customer Fax</Typography> : <></>}
                                        {customerContacts.map((contact, i) => {
                                            return (contact.type === "Fax") ? <Box key={i} sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                <Tooltip title="Fax" >
                                                    <FaxIcon sx={{ margin: "0.4em" }} />
                                                </Tooltip>
                                                <Typography style={{ margin: "0" }}>{getPhoneNumberWithInputMask(contact.value)}</Typography>
                                            </Box> : <></>
                                        })}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", paddingRight: "1em", width: "100%" }}>
                            <Typography style={{ fontSize: "1.1em", fontWeight: "bold", margin: "0", marginBottom: "0.3em" }}>Call Summary</Typography>
                            <Box sx={{ inlineSize: '100%', overflowX: 'auto', overflowWrap: 'break-word' }}>
                                <Typography>{lead.leadResponse.summary}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "0.25em", padding: "0.25em", }}>
                    <Typography style={{ fontSize: "1.5rem", fontWeight: "bold" }} >Follow-Up</Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", padding: "0.25em", gap: "1em" }} >
                        <Autocomplete
                            sx={{ width: "30%", }}
                            size="small"
                            disabled={lead?.middlewareServiceTitanLead?.middlewareFollowUpStage === MIDDLEWARE_FOLLOW_UP_STAGES[3]}
                            getOptionDisabled={(stage) => {
                                return MIDDLEWARE_FOLLOW_UP_STAGES.indexOf(stage) < MIDDLEWARE_FOLLOW_UP_STAGES.indexOf(lead?.middlewareServiceTitanLead?.middlewareFollowUpStage)
                            }}
                            disableClearable
                            options={MIDDLEWARE_FOLLOW_UP_STAGES}
                            getOptionLabel={(stage) => stage}
                            renderOption={(props, stage) => {
                                return (
                                    <Typography {...props} key={stage}>
                                        {stage}
                                    </Typography>
                                );
                            }}
                            value={newMiddlewareFollowUpStage ? newMiddlewareFollowUpStage : lead?.middlewareServiceTitanLead?.middlewareFollowUpStage ?? null}
                            onChange={(_, stage) => {
                                setNewMiddlewareFollowUpStage(stage);
                            }}
                            renderInput={(params) => <CustomTextField  {...params} required={true} label="Follow-Up Stage" />}
                        />
                        <LoadingButton sx={{ color: "white" }} disabled={(lead?.middlewareServiceTitanLead?.middlewareFollowUpStage === MIDDLEWARE_FOLLOW_UP_STAGES[3]) || !(newMiddlewareFollowUpStage !== lead?.middlewareServiceTitanLead?.middlewareFollowUpStage)} type="submit" size={"small"} variant="contained" loading={isUpdateMiddlewareFollowUpLoading} onClick={onUpdateMiddlewareFollowUpStage} >Update</LoadingButton>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row", padding: "0.25em", gap: "1em" }} >
                        {isFollowUpHistoryLoading || isFollowUpHistoryError ? <Box sx={{ width: "100%", margin: "1em", display: "flex", justifyContent: "center" }} >{isFollowUpHistoryLoading ? <CircularProgress /> : <RefreshErrorView onRefresh={() => setToggleRefreshFollowUpHistory(!toggleRefreshFollowUpHistory)} />}</Box> : <FollowUpHistory followUps={historyFollowUps} />}
                    </Box>
                    {franchiseDetails?.franchise_angies_list_configuration?.servicetitan_campaign_id && lead?.leadResponse?.campaignId && franchiseDetails?.franchise_angies_list_configuration?.servicetitan_campaign_id === lead?.leadResponse?.campaignId ? <SalesAcceleratorSection franchise={franchiseDetails} middlewareLead={lead} customer={customer} contacts={customerContacts} address={location?.address} shouldAllowRescheduling={true} /> : <></>}
                </Box>
                {/* notes */}
                <Box sx={{ flexGrow: "2", display: "flex", flexDirection: "column", gap: "0.25em", padding: "0.25em" }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography style={{ fontSize: "1.5rem", fontWeight: "bold" }}>Notes</Typography>
                        <IconButton onClick={handleToggleCollapse}>
                            {isNotesOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </Box>
                    <Collapse in={isNotesOpen}>
                        <LeadSectionNotes 
                            franchise={franchise} 
                            customerId={lead.leadResponse.customerId} 
                            locationId={lead.leadResponse.locationId} 
                            showNotification={showNotification} 
                            leadId={lead.leadResponse.id} 
                            fromLeadCreatePage={false} 
                            fromLeadViewPage={true} 
                        />
                    </Collapse>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "0.25em", padding: "0.25em", }}>
                    <Typography style={{ fontSize: "1.5rem", fontWeight: "bold" }} >Dismiss Lead</Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", padding: "0.25em", gap: "1em" }} >
                        <Autocomplete
                            style={{ width: "39%" }}
                            size="small"
                            disablePortal
                            disabled={status === "Dismissed"}
                            noOptionsText={franchise ? "No call reasons for franchise" : "Please select a franchise"}
                            loading={isCallReasonsLoading}
                            options={callReasons}
                            getOptionLabel={(callReason) => callReason.name}
                            getOptionDisabled={(callReason) => !callReason.active}
                            renderOption={(props, callReason) => {
                                return (
                                    <Typography {...props} key={callReason.id}>
                                        {callReason.name}
                                    </Typography>
                                );
                            }}
                            value={(leadDissmissReason ?? null)}
                            onChange={(_, callReason) => {
                                setLeadDissmissReason(callReason)
                            }}
                            renderInput={(params) => <CustomTextField required={!followUpDate} {...params} label="Call Reason" />}
                        />
                        <LoadingButtonForDelete sx={{ paddingInline: "1em", color: "white", backgroundColor: "#B00020", textTransform: "none", "&:hover": { backgroundColor: "#B00020", } }} variant="contained" disabled={(status === "Dismissed" || !leadDissmissReason || !franchise)} loading={leadDismissLoading} onClick={onDismissLeadPressed}>Dismiss</LoadingButtonForDelete>
                    </Box>
                </Box>
            </DialogBody>
        </Dialog >
    );
}