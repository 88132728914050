import { Box, IconButton, Tooltip } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { CommonContext } from "../../../../contexts/CommonContextProvider";
import { useContext } from "react";
import { closeConnection, initializeMonitoring, initializeMonitoringLogs, sendAnswerToAgent, sendIceCandidateToAgent, updateMonitoringLogs } from "../../../../services/screenMonitoring";
import { MONITORING_STATUS } from "../../../../constants/monotoringLogs";
import AgentTimer from "./agentTimer";
import DesktopAccessDisabledIcon from '@mui/icons-material/DesktopAccessDisabled';
import { checkAgentState } from "../utils";


function createPeerConnection(setWebRtcConnection, serverConfig) {
    try {
        let peerConnection = new RTCPeerConnection(serverConfig)
        setWebRtcConnection(peerConnection)
        return peerConnection
    } catch (error) { }
}

export function AgentMonitor({ setIsAgentMonitorError, serverConfig, selectedAgent, setSelectedAgent, webRtcConnection, setWebRtcConnection, lastInsertedId, setlastInsertedId,
    isMonitoringTimeOutDialogOpen, setIsMonitoringTimeOutDialogOpen, reconnectCount, isDisconnected, setIsDisconnected, agents, setAgents, CloseAgentMonitoring, setisErrorOccured }) {

    const videoRef = useRef(null)
    const [remoteDescription, setRemoteDescription] = useState(null)
    const [connectCount, setConnectCount] = useState(0)
    const [timerCount, setTimerCount] = useState({
        seconds: 0,
        minutes: 0,
        hours: 0
    })
    const { socket, setSocketMessage, adminId } = useContext(CommonContext)

    let peerConnection = useMemo(() => createPeerConnection(setWebRtcConnection, serverConfig), [selectedAgent, setWebRtcConnection, reconnectCount])

    socket.onmessage = (event) => {
        try {
            let data = JSON.parse(event.data)
            setSocketMessage(data)
            let msg = JSON.parse(data.message)
            if (msg.message_type === "already_monitoring") {
                setIsAgentMonitorError(true)
                let alreadyMonitoringLogData = {
                    admin_id: parseInt(localStorage.getItem("user_id")),
                    agent_id: parseInt(selectedAgent?.user_id),
                    status: MONITORING_STATUS.already_monitoring,
                    last_inserted_id: lastInsertedId
                }
                updateMonitoringLogs(alreadyMonitoringLogData)
                setisErrorOccured(true)
                localStorage.removeItem("agent")
                setTimeout(() => {
                    setIsAgentMonitorError(false)
                    setSelectedAgent(null)
                }, 3000)
            }
            else if (msg.message_type === "offer") {
                setRemoteDescription(msg.body)
            } else if (msg.message_type === "offer_icecandidate") {
                peerConnection.addIceCandidate(msg.body)
            }
        } catch (error) { }
    }

    useEffect(() => {
        let timeoutId;
        if (peerConnection.remoteDescription && !isMonitoringTimeOutDialogOpen) {
            timeoutId = setTimeout(() => {
                getTimerCount(setTimerCount, timerCount)
            }, 1000)
        }
        return () => {
            if (timeoutId) clearTimeout(timeoutId)
        }
    }, [timerCount, peerConnection.remoteDescription])

    useEffect(() => {
        let timeOutId
        initiateMonitoring()
        if (timeOutId) clearTimeout(timeOutId)
        timeOutId = setTimeout(() => {
            if (!peerConnection.remoteDescription) {
                setIsDisconnected(true)
                setSelectedAgent(null)
                setIsDisconnected(false)
            }
        }, 15000)
        return () => {
            if (timeOutId) clearTimeout(timeOutId)
        }
    }, [selectedAgent, reconnectCount])

    useEffect(() => {
        let id;
        if (id) clearTimeout(id)
        id = setTimeout(() => {
            timeOutConnection()
        }, process.env.REACT_APP_SCREEN_MONITORING_TIMEOUT * 1000 * 60);
        return () => {
            if (id) clearTimeout(id)
        }
    }, [reconnectCount, connectCount])

    useEffect(() => {
        createConnection()
    }, [remoteDescription])

    peerConnection.onconnectionstatechange = event => {
        switch (peerConnection.connectionState) {
            case "connected":
                try {
                    let successLogData = {
                        admin_id: parseInt(localStorage.getItem("user_id")),
                        agent_id: parseInt(selectedAgent?.user_id),
                        status: MONITORING_STATUS.connect,
                        last_inserted_id: lastInsertedId
                    }
                    updateMonitoringLogs(successLogData)
                } catch (error) { }
                break;
            case "disconnected" || "failed":
                try {
                    setIsDisconnected(true)
                    agents?.forEach((user) => {
                        if (user?.session_id == selectedAgent?.session_id) {
                            user.isOnline = false
                        }
                    })
                    setAgents([...agents])
                    let failLogData = {
                        admin_id: parseInt(localStorage.getItem("user_id")),
                        agent_id: parseInt(selectedAgent?.user_id),
                        status: MONITORING_STATUS.fail,
                        last_inserted_id: lastInsertedId
                    }
                    updateMonitoringLogs(failLogData)
                    setisErrorOccured(true)
                    localStorage.removeItem("agent")
                    setTimeout(() => {
                        setIsDisconnected(false)
                        setSelectedAgent(null)
                    }, 3000)
                } catch (error) { }
                break;
            default:
                break;
        }
    }

    peerConnection.ontrack = event => {
        const remoteStream = new MediaStream()
        event.streams[0].getTracks().forEach(track => {
            remoteStream.addTrack(track)
            videoRef.current.srcObject = remoteStream
        })
    }

    const getTimerCount = (setTimerCount, timerCount) => {
        let seconds = timerCount?.seconds < 59 ? timerCount?.seconds + 1 : 0
        let minutes = timerCount?.seconds < 59 ? timerCount?.minutes : timerCount?.minutes < 59 ? timerCount?.minutes + 1 : 0
        let hours = timerCount?.minutes < 59 ? timerCount?.hours : timerCount?.hours + 1
        setTimerCount({ seconds: seconds, minutes: minutes, hours: hours })
    }

    const initiateMonitoring = async () => {
        try {
            let isAgentOnline = await checkAgentState(selectedAgent)
            if (isAgentOnline) {
                setConnectCount(connectCount + 1)
                setTimerCount({
                    seconds: 0,
                    minutes: 0,
                    hours: 0
                })
                setlastInsertedId(null)
                let initiateData = {
                    message: JSON.stringify({ message_type: "initiateMonitoring", body: "", user_id: localStorage.getItem("user_id") }),
                    to_session_id: selectedAgent?.session_id,
                    from_session_id: adminId.toString(),

                }
                initializeMonitoring(initiateData)
                let initiateLogData = {
                    admin_id: parseInt(localStorage.getItem("user_id")),
                    agent_id: parseInt(selectedAgent?.user_id),
                    status: MONITORING_STATUS.initialize
                }
                let data = await initializeMonitoringLogs(initiateLogData)
                setlastInsertedId(data?.last_inserted_id)
            } else {
                agents?.forEach((user) => {
                    if (user?.session_id == selectedAgent?.session_id) {
                        user.isOnline = false
                    }
                })
                setAgents([...agents])
            }
        } catch (error) {
            setTimerCount({
                seconds: 0,
                minutes: 0,
                hours: 0
            })
            setlastInsertedId(null)
        }
    }

    const timeOutConnection = () => {
        try {
            localStorage.removeItem("agent")
            setTimerCount({
                seconds: 0,
                minutes: 0,
                hours: 0
            })
            let closingData = {
                message: JSON.stringify({ message_type: "close", body: "" }),
                to_session_id: selectedAgent?.session_id,
                from_session_id: adminId.toString(),
            }
            closeConnection(closingData)
            let closeLogData = {
                admin_id: parseInt(localStorage.getItem("user_id")),
                agent_id: parseInt(selectedAgent?.user_id),
                status: MONITORING_STATUS.end,
                last_inserted_id: lastInsertedId
            }
            updateMonitoringLogs(closeLogData)
            if (webRtcConnection) {
                webRtcConnection.close()
            }
            setIsMonitoringTimeOutDialogOpen(true)
        } catch (error) { }
    }

    const createConnection = async () => {
        if (remoteDescription) {
            try {
                peerConnection.onicecandidate = event => {
                    if (event.candidate) {
                        let answerCandidate = {
                            message: JSON.stringify({ message_type: "answer_icecandidate", body: event.candidate }),
                            to_session_id: selectedAgent?.session_id,
                            from_session_id: adminId.toString(),
                        }
                        sendIceCandidateToAgent(answerCandidate)
                    }
                }
                peerConnection.setRemoteDescription(new RTCSessionDescription(remoteDescription))
                const answerDescription = await peerConnection.createAnswer()
                let answerData = {
                    message: JSON.stringify({ message_type: "answer", body: answerDescription }),
                    to_session_id: selectedAgent?.session_id,
                    from_session_id: adminId.toString(),
                }
                sendAnswerToAgent(answerData)
                await peerConnection.setLocalDescription(answerDescription)
            } catch (error) { }
        }
    }

    return (<Box sx={{ width: "100%", height: "100%" }}>
        <div style={{ display: "flex", flexDirection: "row", marginBottom: "0.5em", marginLeft: "auto" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <AgentTimer time={timerCount?.hours}></AgentTimer>
                <div style={{ padding: "0.3em" }}>
                    <p>:</p>
                </div>
                <AgentTimer time={timerCount?.minutes}></AgentTimer>
                <div style={{ padding: "0.3em" }}>
                    <p>:</p>
                </div>
                <AgentTimer time={timerCount?.seconds}></AgentTimer>
            </div>
            <div style={{ marginLeft: "auto" }}>
                <IconButton className="monitor-btn" style={{ marginLeft: "10px" }} onClick={() => { CloseAgentMonitoring() }} >
                    <Tooltip title={"Close Agents Screen"}><DesktopAccessDisabledIcon></DesktopAccessDisabledIcon></Tooltip>
                </IconButton>
            </div>
        </div>
        <video ref={videoRef} autoPlay height={"90%"} width={"100%"} style={{ borderStyle: "solid", borderWidth: "2px" }} />
    </Box >
    );
}