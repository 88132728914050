
import { useState, useEffect, useMemo, useContext } from 'react';
import { Button, Chip, Autocomplete, Box, Typography } from '@mui/material';
import { imposibleToMatchRegex, isValidEmail, isValidFaxNumber, isValidPhoneNumber } from "../../utils/validators";
import PhoneNumberBox from "../../components/PhoneNumberBox";
import { withRouter, useHistory } from "react-router";
import { getIsValidUSOrCanadianZipCode } from '../../utils/validators';
import { handleGetUserBrands } from '../../state-services-dublicate/brandServices';
import CallIcon from '@mui/icons-material/Call';
import SmsIcon from '@mui/icons-material/Sms';
import ChatIcon from '@mui/icons-material/Chat';
import EmailIcon from '@mui/icons-material/Email';
import TuneTwoToneIcon from '@mui/icons-material/TuneTwoTone';
import { renderBrandOption } from '../../components/servicetitan/RenderInput';
import IBSLogoImage from './../../assets/img/IBS_lOGO.png'
import { LOCAL_STORAGE_KEYS, SESSION_STORAGE_KEYS } from '../../constants/browserStorage';
import CustomTextField from '../../components/CustomTextField';
import { CommonContext } from '../../contexts/CommonContextProvider';
import { NOTIFICATION_TYPES } from '../../constants/common';
import { handleGetMe, handleGetUserPreferenceOfLoggedUser } from '../../state-services/users';
import { handleGetBrandByEmailAddress } from '../../state-services/brands';
import { Link } from 'react-router-dom';
import SplitButton from '../../components/Buttons/SplitButton';
import { getIsOnlyContainNumbers } from '../../utils/miscellaneous';
import { handleGetFranchiseByChannelIdV3 } from '../../state-services/franchise/v3/franchise';
import { UserContext } from '../../contexts/User';
import { handleGetKeywords } from '../../state-services/faq/faq';
import ImageBox from '../../components/LogoBox/ImageBox';
import { handleGetTimezones } from '../../state-services/schedule/schedule';
import { handleGetGeneralTenantConfiguration } from '../../state-services/configuration/configuration';

export const MEDIA_TYPES = {
    PHONE: {
        id: "PHONE",
        media: "PHONE",
        icon: <CallIcon />
    },
    CHAT: {
        id: "CHAT",
        media: "CHAT",
        icon: <ChatIcon />
    },
    SMS: {
        id: "SMS",
        media: "SMS",
        icon: <SmsIcon />
    },
    EMAIL: {
        id: "EMAIL",
        media: "EMAIL",
        icon: <EmailIcon />
    },
    MANUAL: {
        id: "MANUAL",
        media: "MANUAL",
        icon: <TuneTwoToneIcon />
    }
}

function Dialer(props) {
    const urlParamData = useMemo(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const media = urlParams.get("media") ?? ""
        const shouldOverridePrevious = urlParams.get("override") ?? ""
        let channelId = urlParams.get("channel_id")?.trim?.() ?? ""
        let callerId = urlParams.get("caller_id")?.trim?.() ?? ""
        channelId = (media === MEDIA_TYPES.PHONE.media || media === MEDIA_TYPES.SMS.media || isValidPhoneNumber(channelId)) ? channelId?.slice?.(-10) : channelId
        callerId = (media === MEDIA_TYPES.PHONE.media || media === MEDIA_TYPES.SMS.media || isValidPhoneNumber(callerId)) ? callerId?.slice?.(-10) : callerId
        let urlParamData = {}
        if (media !== MEDIA_TYPES.MANUAL.media || shouldOverridePrevious) { //handling real pop up from 8x8
            urlParamData = {
                media: media,
                channelName: urlParams.get("channel_name") ?? "",
                channelId: channelId ?? "",
                callerId: callerId ?? "",
                zipCode: urlParams.get("zip_code") ?? "",
                subject: urlParams.get("subject") ?? "",
                dialerFrom: callerId ?? "",
                interactionGuid: urlParams.get("interaction_guid") ?? "",
            }
        } else {
            urlParamData = {
                media: localStorage.getItem(LOCAL_STORAGE_KEYS.dialerMedia) ?? MEDIA_TYPES.MANUAL.media,
                channelName: localStorage.getItem(LOCAL_STORAGE_KEYS.dialerChannelName) ?? "",
                channelId: localStorage.getItem(LOCAL_STORAGE_KEYS.dialerChannelId) ?? "",
                callerId: localStorage.getItem(LOCAL_STORAGE_KEYS.dialerCallerId) ?? "",
                zipCode: localStorage.getItem(LOCAL_STORAGE_KEYS.dialerZipCode) ?? "",
                subject: localStorage.getItem(LOCAL_STORAGE_KEYS.dialerSubject) ?? "",
                dialerFrom: localStorage.getItem(LOCAL_STORAGE_KEYS.dialerFrom) ?? "",
                interactionGuid: sessionStorage.getItem(SESSION_STORAGE_KEYS.interactionGuid) ?? "",
            }
            window.history.pushState({}, undefined, `/dialer?media=${urlParamData.media}&zip_code=${urlParamData.zipCode}&caller_id=${urlParamData.callerId}&channel_id=${urlParamData.channelId}&channel_name=${urlParamData.channelName}&interaction_guid=${urlParamData.interactionGuid}`);
        }
        return urlParamData
    }, [])
    const { setEmailInfo, brands, setBrands, setAllBrands, isBrandsLoading, setIsBrandsLoading, showNotification, setKeywords, setIsKeywordsLoading, setIsKeywordsError, setTimezones, setIsTimezonesLoading, setIsTimezonesError, setGeneralTenantConfiguration } = useContext(CommonContext)
    const [franchise, setFranchise] = useState()
    const [selectedBrand, setSelectedBrand] = useState()
    const [autoPickBrand, setAutoPickBrand] = useState()
    const [callerId, setCallerId] = useState(urlParamData?.callerId);
    const [zipCode, setZipCode] = useState(urlParamData?.zipCode);
    const [user, setUser] = useState()
    const [loading, setLoading] = useState(true)
    const { userPreferences, setUserPreferences } = useContext(UserContext)
    const [selectedProceedOption, setSelectedProceedOption] = useState(getProceedOptions()[0])
    const history = useHistory()

    useEffect(() => {
        handleGetMe(setUser)
        handleGetUserPreferenceOfLoggedUser(setUserPreferences)
        handleGetKeywords(setKeywords, setIsKeywordsLoading, setIsKeywordsError)
    }, [setUserPreferences])

    useEffect(() => {
        handleGetTimezones(setTimezones, setIsTimezonesLoading, setIsTimezonesError, showNotification);
    }, [setTimezones, setIsTimezonesLoading, setIsTimezonesError, showNotification])

    useEffect(() => {
        handleGetGeneralTenantConfiguration(setGeneralTenantConfiguration, () => { }, () => { })
    }, [setGeneralTenantConfiguration])

    useEffect(() => { proceedOption() }, [userPreferences?.dialler_proceed])
    useEffect(() => { proceedOption() }, [userPreferences?.dialler_proceed])

    useEffect(() => {
        if (user?.is_enabled === 0) window.location.replace("/")
    }, [user?.is_enabled])

    useEffect(() => {
        if (user) handleGetUserBrands(urlParamData.media !== MEDIA_TYPES.MANUAL.media, user, setBrands, setIsBrandsLoading, showNotification, setAllBrands)
    }, [urlParamData.media, user, setBrands, setIsBrandsLoading, showNotification, setAllBrands])

    useEffect(() => {
        localStorage.setItem(LOCAL_STORAGE_KEYS.dialerMedia, urlParamData.media)
        localStorage.setItem(LOCAL_STORAGE_KEYS.dialerChannelName, urlParamData.channelName)
        localStorage.setItem(LOCAL_STORAGE_KEYS.dialerChannelId, urlParamData.channelId)
        localStorage.setItem(LOCAL_STORAGE_KEYS.dialerCallerId, urlParamData.callerId)
        localStorage.setItem(LOCAL_STORAGE_KEYS.dialerZipCode, urlParamData.zipCode)
        localStorage.setItem(LOCAL_STORAGE_KEYS.dialerSubject, urlParamData.subject)
        localStorage.setItem(LOCAL_STORAGE_KEYS.dialerSubject, urlParamData.subject)
        localStorage.setItem(LOCAL_STORAGE_KEYS.dialerFrom, urlParamData.callerId)
        sessionStorage.setItem(SESSION_STORAGE_KEYS.interactionGuid, urlParamData.interactionGuid)
        if (urlParamData.media === MEDIA_TYPES.EMAIL.media) {
            setEmailInfo((emailInfo) => {
                return { ...emailInfo, from: urlParamData.callerId, to: urlParamData.channelId, subject: urlParamData.subject }
            })
        }
    }, [urlParamData.media, urlParamData.channelName, urlParamData.channelId, urlParamData.callerId, urlParamData.zipCode, urlParamData.subject, setEmailInfo])

    useEffect(() => {
        if (urlParamData.channelId && urlParamData.media === MEDIA_TYPES.PHONE.media) {
            handleGetFranchiseByChannelIdV3(urlParamData.channelId, (f) => {
                setFranchise(f)
                if (!f && urlParamData.media === MEDIA_TYPES.PHONE.media) {
                    showNotification({ message: `The Channel ID-${urlParamData.channelId} doesn’t exist in the Middleware`, type: NOTIFICATION_TYPES.ERROR })
                }
            })
        } else if (urlParamData.channelId && urlParamData.media === MEDIA_TYPES.EMAIL.media) {
            handleGetBrandByEmailAddress(urlParamData.channelId, (brand) => {
                if (brand) {
                    setAutoPickBrand(brand)
                    setSelectedBrand(brand)
                    setEmailInfo((emailInfo) => {
                        return { ...emailInfo, brandId: brand.id }
                    })
                } else {
                    showNotification({ message: `The Channel ID-${urlParamData.channelId} doesn’t exist in the Middleware`, type: NOTIFICATION_TYPES.ERROR })
                }
            })
        } else if (urlParamData.media === MEDIA_TYPES.PHONE.media) {
            showNotification({ message: "The Channel ID doesn’t exist in the Middleware", type: NOTIFICATION_TYPES.ERROR })
        }
    }, [urlParamData.media, urlParamData.channelId, setEmailInfo, showNotification])

    useEffect(() => {
        if (Array.isArray(brands) && brands?.length > 0 && franchise) {
            const brand = getBrandFromFranchise(franchise, urlParamData?.channelId, brands)
            setSelectedBrand(brand)
            setAutoPickBrand(brand)
        }
    }, [brands, franchise, urlParamData?.channelId])

    useEffect(() => {
        if (urlParamData.zipCode && getIsValidUSOrCanadianZipCode(urlParamData.zipCode) && autoPickBrand?.id && urlParamData?.channelId) {
            redirectToHomePage(history, urlParamData.media, autoPickBrand.brand_id, urlParamData.zipCode, urlParamData.callerId, urlParamData.channelId, urlParamData.channelName, "", urlParamData.subject, urlParamData.dialerFrom, urlParamData.interactionGuid, true)
        }
    }, [history, urlParamData.zipCode, urlParamData.media, urlParamData.callerId, urlParamData.channelId, urlParamData.channelName, urlParamData.subject, urlParamData.dialerFrom, urlParamData.interactionGuid, autoPickBrand?.id, autoPickBrand?.brand_id])

    function proceedOption() {
        let val = 0
        if (userPreferences?.id && userPreferences?.dialler_proceed !== 0) {
            val = (parseInt(userPreferences?.dialler_proceed) - 1) ?? 0
            setSelectedProceedOption(getProceedOptions()[val])
            setLoading(false)
        } else {
            setSelectedProceedOption(getProceedOptions()[0])
            setLoading(false)
        }
    }

    function getProceedOptions() {
        return [
            { title: "Proceed", disabled: false, shouldOpenInNewTab: false, shouldClearData: false },
            { title: "Proceed in new tab", disabled: false, shouldOpenInNewTab: true, shouldClearData: false },
            { title: "Clear and proceed in new tab", disabled: false, shouldOpenInNewTab: true, shouldClearData: true }
        ]
    }

    function handleSubmit(e) {
        e.preventDefault();
        if (selectedProceedOption?.shouldOpenInNewTab) {
            removeDialerLocalStorageDetails()
            openHomePageInSeparateTab(urlParamData?.media, selectedBrand?.brand_id, zipCode, callerId, urlParamData?.channelId, urlParamData?.channelName, "", urlParamData.subject, urlParamData.dialerFrom, urlParamData.interactionGuid)
        } else {
            redirectToHomePage(history, urlParamData?.media, selectedBrand?.brand_id, zipCode, callerId, urlParamData?.channelId, urlParamData?.channelName, "", urlParamData.subject, urlParamData.dialerFrom, urlParamData.interactionGuid, true)
        }
        if (selectedProceedOption.shouldClearData) {
            setSelectedBrand()
            setZipCode("")
            setCallerId("")
        }
    }

    function handleCallerIdChange(e) {
        let callerId = e?.target?.value ?? ""
        if (urlParamData.media === MEDIA_TYPES.MANUAL.media) {
            callerId = callerId.replace(/\s|\(|\)/g, '');
            const callerIdWithoutDash = callerId.replace(/[\s\(\)\-]/g, '');
            if (getIsOnlyContainNumbers(callerIdWithoutDash)) {
                callerId = callerIdWithoutDash
            }
        }
        setCallerId(callerId)
    }

    return (
        <>
            {!loading ?
                <Box data-test="dialer_box" sx={{ position: "absolute", left: "0", right: "0", top: "0", bottom: "0" }} className={'dialer'}>
                    <Link data-test="dialer_application_pannel" style={{ textDecoration: "none", fontWeight: "500" }} to="/call-logs">
                        <Button variant='contained' sx={{ position: "relative", left: "0", top: "0", margin: "1em", backgroundColor: 'form.main', color: "#00a79d", border: 1, borderColor: "#00a79d", borderWidth: '1px' }}>
                            Application Panel
                        </Button>
                    </Link>
                    <form onSubmit={handleSubmit}>
                        <Box sx={{ margin: "auto", marginTop: "10%", padding: "1em", width: { xs: "15em", sm: "20em", md: "25em", lg: "30em" }, backgroundColor: 'form.main', borderRadius: "0.5em", display: "flex", flexDirection: "column", gap: "1em" }}>
                            <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <Typography variant='h6' >Customer Details</Typography><Box flexGrow={2} />
                                <MediaIcon media={urlParamData.media ? urlParamData.media : MEDIA_TYPES.MANUAL.media} />
                            </Box>
                            <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "end" }}>
                                <ImageBox size={'large'} alt="Brand" src={selectedBrand?.brand_img ?? IBSLogoImage} />
                            </Box>
                            <Autocomplete
                                data-test="dialer_brand_selector"
                                loading={isBrandsLoading}
                                noOptionsText={"No brands"}
                                disabled={brands === []}
                                disablePortal
                                disableClearable
                                options={brands}
                                getOptionLabel={(brand) => brand.brand_name}
                                renderOption={renderBrandOption}
                                value={selectedBrand ?? null}
                                onChange={(_, brand) => {
                                    setSelectedBrand(brand);
                                }}
                                renderInput={(params) => <CustomTextField
                                    {...params}
                                    inputProps={{
                                        ...params.inputProps,
                                        'data-test': 'dialer_brand'
                                    }}
                                    required
                                    label="Brand"
                                />}
                            />
                            <CustomTextField
                                inputProps={{ pattern: getIsValidUSOrCanadianZipCode(zipCode) ? undefined : imposibleToMatchRegex, 'data-test': 'dialer_zip_code' }}
                                required
                                label="Zip/Postal Code"
                                type="search" value={zipCode ?? ""}
                                onChange={(e) => setZipCode(e?.target?.value ?? "")}
                                error={!getIsValidUSOrCanadianZipCode(zipCode) && zipCode}
                                helperText={getIsValidUSOrCanadianZipCode(zipCode) || !zipCode ? "" : "Invalid Zip/Postal Code"} />
                            {urlParamData?.media === MEDIA_TYPES?.PHONE.media || urlParamData?.media === MEDIA_TYPES?.SMS.media ? <PhoneNumberBox
                                data-test="dialer_caller_id"
                                type="text"
                                label="Caller ID"
                                placeholder={"(123) 456-7890"}
                                style={{ width: "100%" }}
                                value={callerId}
                                size="medium"
                                inputProps={{ pattern: isValidFaxNumber(callerId) ? undefined : imposibleToMatchRegex }}
                                onChange={(e) => {
                                    if (e?.target?.value?.length <= 10) setCallerId(e?.target?.value ?? "")
                                }}
                                isWithNormalTextField={true}
                                error={callerId && !isValidFaxNumber(callerId) ? true : false}
                                helperText={callerId && !isValidFaxNumber(callerId) ? "Invalid caller id" : ""}
                            /> : <CustomTextField
                                type="text"
                                label="Caller ID"
                                placeholder={"test@test.com or 1234567890"}
                                style={{ width: "100%" }}
                                value={callerId}
                                size="medium"
                                inputProps={{ pattern: isValidFaxNumber(callerId) || isValidEmail(callerId) ? undefined : imposibleToMatchRegex, 'data-test': 'dialer_caller_id' }}
                                onChange={handleCallerIdChange}
                                isWithNormalTextField={true}
                                error={callerId && !(isValidFaxNumber(callerId) || isValidEmail(callerId)) ? true : false}
                                helperText={callerId && !(isValidFaxNumber(callerId) || isValidEmail(callerId)) ? "Invalid caller id" : ""}
                            />}
                            <Box sx={{ width: "100%", display: "flex", flexDirection: "row" }}><Box flexGrow={2} />
                                <SplitButton data-test="dialer_proceed" size="large" options={getProceedOptions()} selectedOption={selectedProceedOption} setSelectedOption={setSelectedProceedOption} value={proceedOption} />
                            </Box>
                        </Box>
                    </form>
                </Box> : <></>
            }
        </>
    );
}

export default withRouter(Dialer);



export function redirectToHomePage(history, media = "", brandId = "", zipCode = "", callerId = "", channelId = "", channelName = "", jobId = "", subject = "", dialerFrom = "", interactionGuid = "", overrideHistory = false) {
    if (!getIsValidUSOrCanadianZipCode(zipCode)) return
    const url = getHomeUrl(media, brandId, zipCode, callerId, channelId, channelName, jobId, subject, dialerFrom, interactionGuid)
    if (overrideHistory) {
        removeDialerLocalStorageDetails()
        setTimeout(() => history.replace(url), 500) //TO prevent browser refresh before removing items from local storage
    } else {
        return history.push(`/home?media=${media}&brand_id=${brandId}&zip_code=${zipCode}&caller_id=${callerId}&channel_id=${channelId}&channel_name=${channelName}&job_id=${jobId}&subject=${subject}&from=${dialerFrom}&interaction_guid=${interactionGuid}`)
    }
}

export function openHomePageInSeparateTab(media = "", brandId = "", zipCode = "", callerId = "", channelId = "", channelName = "", jobId = "", subject = "", dialerFrom = "", interactionGuid = "") {
    const url = getHomeUrl(media, brandId, zipCode, callerId, channelId, channelName, jobId, subject, dialerFrom, interactionGuid)
    window.open(url, "_blank")
}

export function getHomeUrl(media = "", brandId = "", zipCode = "", callerId = "", channelId = "", channelName = "", jobId = "", subject = "", dialerFrom = "", interactionGuid = "") {
    return `/home?media=${media}&brand_id=${brandId}&zip_code=${zipCode}&caller_id=${callerId}&channel_id=${channelId}&channel_name=${channelName}&job_id=${jobId}&subject=${subject}&from=${dialerFrom}&interaction_guid=${interactionGuid}`
}

function MediaIcon(props) {
    const { media } = props

    function getMediaTypeIcon(media) {
        switch (media) {
            case MEDIA_TYPES.CHAT.media:
                return MEDIA_TYPES.CHAT.icon
            case MEDIA_TYPES.EMAIL.media:
                return MEDIA_TYPES.EMAIL.icon
            case MEDIA_TYPES.PHONE.media:
                return MEDIA_TYPES.PHONE.icon
            case MEDIA_TYPES.SMS.media:
                return MEDIA_TYPES.SMS.icon
            default:
                return MEDIA_TYPES.MANUAL.icon
        }
    }
    return <Chip label={media ?? MEDIA_TYPES.MANUAL.media} icon={getMediaTypeIcon(media)} />
}

function removeDialerLocalStorageDetails() {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.dialerMedia)
    localStorage.removeItem(LOCAL_STORAGE_KEYS.dialerChannelName)
    localStorage.removeItem(LOCAL_STORAGE_KEYS.dialerChannelId)
    localStorage.removeItem(LOCAL_STORAGE_KEYS.dialerCallerId)
    localStorage.removeItem(LOCAL_STORAGE_KEYS.dialerZipCode)
    localStorage.removeItem(LOCAL_STORAGE_KEYS.dialerSubject)
    localStorage.removeItem(LOCAL_STORAGE_KEYS.dialerFrom)
    sessionStorage.removeItem(SESSION_STORAGE_KEYS.interactionGuid)
    sessionStorage.removeItem(SESSION_STORAGE_KEYS.currentJobId)
    sessionStorage.removeItem(SESSION_STORAGE_KEYS.is_call_log_created)
}

function getBrandFromFranchise(franchise, channelId, brands = []) {
    const franchiseBrand = franchise?.franchise_brands?.find?.((fb) => {
        return fb?.channels?.some?.((ch) => ch?.channel_id === channelId)
    })
    const brand = brands.find((b) => b.brand_id === franchiseBrand?.franchise_brand?.brand_id)
    return brand
}