import { Button, Container, Dialog, IconButton, Tooltip, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export default function TechnitianPickingCriteriaModal({ isOpen, onCloseHandler, reasonsMap }) {

    function onYesPressed() {
        onCloseHandler()
    }

    return <Dialog maxWidth="sm" open={isOpen} onClose={onCloseHandler}>
        <Tooltip title={"Close"}>
            <IconButton onClick={onCloseHandler} style={{ position: "absolute", right: "5px", top: "5px" }} size={"small"} aria-label="delete">
                < CloseIcon style={{ height: "15px", width: "15px" }} />
            </IconButton>
        </Tooltip>
        <Container sx={{ display: "flex", flexDirection: "column", paddingTop: "2em", paddingBottom: "2em", alignContent: "center", gap: "1em" }}>
            <Typography variant="h6" align="left">Technician Picking Criteria</Typography>
            <ul>
                {Object.entries(reasonsMap).map(([technician, reason], index) => (
                    <li key={index}>{<strong>{technician}</strong>}{` - ${reason}`}</li>
                ))}
            </ul>
            <Container sx={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: "1em" }} >
                <Button variant="contained" size="small" onClick={onYesPressed} className="btn-primary" sx={{marginLeft:"auto"}} >OK</Button>
            </Container>
        </Container>
    </Dialog >;
}
