import moment from "moment"
import { getMatchingTimezoneByName } from "../timezone/timezone";
import moment_timezone from 'moment-timezone';

export function getTimeZonedDate(date, timeZone) {
    if (timeZone) {
        return moment(date).tz(timeZone)
    } else {
        return moment(date)
    }
}

export function getDateTimeStringFromDateObject(date, timezone) {
    if (timezone) {
        const dataString = moment(date).tz(timezone).format('YYYY-MM-DD HH:mm:ss')
        return dataString
    } else {
        const dataString = moment(date).format('YYYY-MM-DD HH:mm:ss')
        return dataString
    }
}

export function getTenantTimeZoneTimeString(isoDateString, timezone, timezones = [{ time_zone: "EST", abbreviation: "EST" }]) {
    if (timezone) {
        const timeString = moment(isoDateString).tz(timezone).format('YYYY-MM-DD HH:mm')
        return `${timeString} ${getMatchingTimezoneByName(timezone, timezones)?.abbreviation ?? ""}`
    } else {
        const timeString = moment(isoDateString).format('YYYY-MM-DD HH:mm')
        return `${timeString}`
    }
}

export function getTimeOfTimeZoneInISO(timeString, timezone = "America/New_York") {
    if (timezone) {
        const time = moment_timezone.tz(timeString, 'h:mm A', timezone);
        return time.toISOString();
    } else {
        return moment(timeString).toISOString()
    }
}

export function getTimeZonedTime(timestamp, timeZone) {
    if (timeZone) {
        return moment.utc(timestamp, "YYYY-MM-DD HH:mm:ss").tz(timeZone).format("hh:mm A")
    } else {
        return moment(timestamp)
    }
}