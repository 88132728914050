import { getAllAgents } from "../../services/eigthByEight/agents";

export async function handleGetAgents(selectedAgentId, setAllAgents, setIsAllAgentsLoading, setIsAllAgentsLoadingError, setSelectedAgent) {
    try {
        setIsAllAgentsLoadingError(false)
        setIsAllAgentsLoading(true)
        const agentsResponse = await getAllAgents();
        const agents = agentsResponse?.agents
        if (Array.isArray(agents)) {
            let activeAgents = agents?.filter((agent) => agent?.deleted !== true)
            const uniqueAgents = activeAgents.filter((obj, index) => {
                return index === activeAgents.findIndex(o => obj.agent_id === o.agent_id);
            });
            setAllAgents(uniqueAgents)
            if (selectedAgentId) {
                const selectedAgent = agents.find((a) => a?.agent_id === selectedAgentId)
                if (selectedAgent) setSelectedAgent(selectedAgent)
            }
        }
    } catch (error) {
        setIsAllAgentsLoadingError(true)
    } finally {
        setIsAllAgentsLoading(false)
    }
}