import { Autocomplete, Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react'
import EditIcon from '@mui/icons-material/Edit';
import CustomDatePicker from '../../../../../../../components/CustomDatePicker';
import { NOTIFICATION_TYPES } from '../../../../../../../constants/common';
import { PRIORITIES } from '../../../../../../../constants/serviceTitan';
import { CustomTextField } from '../../../../../jobs/components/customComponents';
import { editLead } from '../../../../../../../services/serviceTitan';
import LoadingButton from '../../../../../../../components/LoadingButton';

export default function LeadSection({ handleClose, isCallReasonsLoading, isTagTypesLoading, isEditLead, setIsEditLead, selectedLeadToEdit, selectedCustomer, selectedCustomerContacts, brandId, franchise, users, createdByUser, priority, setPriority, campaigns, campaign, setCampaign, isCampaignsLoading,
    jobTypes, jobType, setJobType, isJobTypesLoading, businessUnit, setBusinessUnit, businessUnits, isBusinessUnitsLoading, tagTypes, selectedTagTypes, callReasons, callReason, isUsersLoading, isCurrentUserLoading, showNotification }) {

    const [isEditLeadLoading, setIsEditLeadLoading] = useState(false)

    function onEditLeadPressed() {
        setIsEditLead(true)
    }

    function onEditLeadCancelPressed() {
        setPriority(selectedLeadToEdit?.leadResponse?.priority)
        setIsEditLead(false)
    }

    function handleEditLead(setIsEditLeadLoading) {
        const editedLead = {
            id: selectedLeadToEdit?.middlewareServiceTitanLead?.id,
            brandId: brandId,
            franchiseId: franchise.id,
            middlewareAssignedToId: selectedLeadToEdit?.middlewareServiceTitanLead?.middlewareAssignedToId,
            middlewareFollowUpStage: selectedLeadToEdit?.middlewareServiceTitanLead?.middlewareFollowUpStage,
            isCreatedWithinMiddlware: selectedLeadToEdit?.middlewareServiceTitanLead?.isCreatedWithinMiddlware,
            ignoreAutomaticFollowUp: selectedLeadToEdit?.middlewareServiceTitanLead?.ignoreAutomaticFollowUp,
            customerName: selectedCustomer?.name,
            customerContacts: selectedCustomerContacts,
            leadUpdateRequest: {
                brandId: brandId,
                franchiseId: franchise?.id,
                campaignId: campaign?.id,
                businessUnitId: businessUnit?.id,
                jobTypeId: jobType?.id,
                priority: priority
            }
        };
        setIsEditLeadLoading(true);
        editLead(franchise.service_titan_tenant_id, franchise.service_titan_client_id, franchise.service_titan_client_secret, selectedLeadToEdit?.leadResponse?.id, editedLead).then((editedLeadResponse) => {
            showNotification({ message: "The lead has been updated successfully", type: NOTIFICATION_TYPES.SUCCESS })
            const newLead = { ...editedLeadResponse }
            handleClose(newLead, true);
        }).catch(() => {
            showNotification({ message: "Important: Your lead couldn’t be updated at this time. Please try again", type: NOTIFICATION_TYPES.ERROR })
        }).finally(() => {
            setIsEditLeadLoading(false);
        })
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: "column", gap: "0.5em" }}>
            <Box sx={{ display: 'flex', flexDirection: "row", width: "100%", height: "3em", justifyContent: "left", alignItems: "center" }}>
                <Box sx={{ display: 'flex', flexDirection: "row", width: "100%", height: "3em", justifyContent: "left", alignItems: "center" }}>
                    <h4>Lead Details</h4>
                    <Tooltip title="Edit Lead" ><IconButton onClick={onEditLeadPressed} disabled={isEditLead} size="small" ><EditIcon color={!isEditLead ? "primary" : "disabled"} /></IconButton></Tooltip>
                </Box>
            </Box>
            {isEditLead ?
                <EditLeadSection setIsEditLeadLoading={setIsEditLeadLoading} isEditLeadLoading={isEditLeadLoading} handleEditLead={handleEditLead} setPriority={setPriority} setCampaign={setCampaign} setJobType={setJobType} setBusinessUnit={setBusinessUnit} onEditLeadCancelPressed={onEditLeadCancelPressed} selectedLeadToEdit={selectedLeadToEdit} franchise={franchise} users={users} createdByUser={createdByUser} priority={priority} campaigns={campaigns} campaign={campaign} isCampaignsLoading={isCampaignsLoading} jobTypes={jobTypes} jobType={jobType} isJobTypesLoading={isJobTypesLoading}
                    businessUnit={businessUnit} businessUnits={businessUnits} isBusinessUnitsLoading={isBusinessUnitsLoading} tagTypes={tagTypes} selectedTagTypes={selectedTagTypes} callReasons={callReasons} callReason={callReason} isUsersLoading={isUsersLoading} isCurrentUserLoading={isCurrentUserLoading} />
                :
                <ExistingLeadSection selectedLeadToEdit={selectedLeadToEdit} franchise={franchise} isCallReasonsLoading={isTagTypesLoading} isTagTypesLoading={isTagTypesLoading} users={users} createdByUser={createdByUser} priority={priority} campaigns={campaigns} campaign={campaign} isCampaignsLoading={isCampaignsLoading} jobTypes={jobTypes} jobType={jobType} isJobTypesLoading={isJobTypesLoading}
                    businessUnit={businessUnit} businessUnits={businessUnits} isBusinessUnitsLoading={isBusinessUnitsLoading} tagTypes={tagTypes} selectedTagTypes={selectedTagTypes} callReasons={callReasons} callReason={callReason} isUsersLoading={isUsersLoading} isCurrentUserLoading={isCurrentUserLoading} />
            }

        </Box>
    )

}

function ExistingLeadSection({ selectedLeadToEdit, isCallReasonsLoading, isTagTypesLoading, franchise, users, createdByUser, priority, campaigns, campaign, isCampaignsLoading, jobTypes, jobType, isJobTypesLoading, businessUnit, businessUnits, isBusinessUnitsLoading, tagTypes, selectedTagTypes, callReasons, callReason, isUsersLoading, isCurrentUserLoading }) {
    return (
        <Box sx={{ display: 'flex', flexDirection: "column", gap: "0.5em", marginBottom: "3em" }}>
            <Box sx={{ display: 'flex', flexDirection: "row", flexWrap: "wrap", width: "100%", gap: "1em" }} >
                <Autocomplete
                    style={{ width: "49%", flexGrow: 2 }}
                    size="small"
                    disablePortal
                    disableClearable
                    disabled={true}
                    loading={isCampaignsLoading}
                    noOptionsText={franchise ? "No campaigns for franchise" : "Please select a franchise"}
                    options={campaigns}
                    getOptionLabel={(campaign) => campaign.name}
                    renderOption={(props, campaign) => {
                        return (
                            <Typography {...props} key={campaign.id}>
                                {campaign.name}
                            </Typography>
                        );
                    }}
                    value={campaigns.length > 0 ? (campaign ?? null) : null}
                    renderInput={(params) => <CustomTextField   {...params} required={true} label="Select Campaign" />}
                />
                <Autocomplete
                    style={{ width: "49%", flexGrow: 2 }}
                    size="small"
                    disablePortal
                    disableClearable={true}
                    disabled={true}
                    noOptionsText={franchise ? "No job types for franchise" : "Please select a franchise"}
                    loading={isJobTypesLoading}
                    options={jobTypes}
                    getOptionLabel={(jobType) => jobType.name}
                    renderOption={(props, jobType) => {
                        return (
                            <Typography {...props} key={jobType.id}>
                                {jobType.name}
                            </Typography>
                        );
                    }}
                    value={jobTypes.length > 0 ? (jobType ?? null) : null}
                    renderInput={(params) => <CustomTextField   {...params} label="Select Job Type" />}
                />
                <Autocomplete
                    style={{ width: "49%", flexGrow: 2 }}
                    size="small"
                    disablePortal
                    disabled={true}
                    disableClearable={selectedLeadToEdit}
                    noOptionsText={franchise ? "No business units for franchise" : "Please select a franchise"}
                    loading={isBusinessUnitsLoading}
                    options={jobType?.businessUnitIds?.length ? businessUnits?.filter?.((v) => jobType?.businessUnitIds?.includes?.(v.id)) : businessUnits}
                    getOptionDisabled={(option) => !option.active}
                    getOptionLabel={(businessUnit) => businessUnit.name}
                    renderOption={(props, businessUnit) => {
                        return (
                            <Typography {...props} key={businessUnit.id}>
                                {businessUnit.name}
                            </Typography>
                        );
                    }}
                    value={businessUnits.length > 0 ? (businessUnit ?? null) : null}
                    renderInput={(params) => <CustomTextField   {...params} label="Select Busines Unit" />}
                />
                <Autocomplete
                    style={{ width: "49%", flexGrow: 2 }}
                    size="small"
                    disablePortal
                    disableClearable
                    disabled={true}
                    options={PRIORITIES}
                    getOptionLabel={(priority) => priority}
                    renderOption={(props, priority) => {
                        return (
                            <Typography {...props} key={priority}>
                                {priority}
                            </Typography>
                        );
                    }}
                    value={(priority ?? null)}
                    renderInput={(params) => <CustomTextField   {...params} required={selectedLeadToEdit} label="Priority" />}
                />
                <div style={{ width: "49%", flexGrow: 2 }} >
                    <CustomDatePicker
                        minTime={selectedLeadToEdit ? undefined : new Date()}
                        disabled={true}
                        label={"Next Follow-Up Date"}
                        value={(selectedLeadToEdit?.leadResponse?.followUpDate ? (Date.parse(selectedLeadToEdit?.leadResponse?.followUpDate)) : null)}
                    />
                </div>
                <Autocomplete
                    style={{ width: "49%", flexGrow: 2 }}
                    size="small"
                    disablePortal
                    disabled={true}
                    options={callReasons}
                    loading={isCallReasonsLoading}
                    getOptionLabel={(callReason) => callReason.name}
                    getOptionDisabled={(callReason) => !callReason.active}
                    renderOption={(props, callReason) => {
                        return (
                            <Typography {...props} key={callReason.id}>
                                {callReason.name}
                            </Typography>
                        );
                    }}
                    value={callReasons.length > 0 ? (callReason ?? null) : null}
                    renderInput={(params) => <CustomTextField  {...params} label="Call Reason" />}
                />
                <Autocomplete
                    style={{ width: "49%", flexGrow: 2 }}
                    size="small"
                    disablePortal
                    multiple={true}
                    disabled={true}
                    loading={isTagTypesLoading}
                    noOptionsText={franchise ? "No tag types for franchise" : "Please select a franchise"}
                    options={tagTypes}
                    getOptionLabel={(tagType) => tagType.name}
                    renderOption={(props, tagType) => {
                        return (
                            <Typography {...props} key={tagType.id}>
                                {tagType.name}
                            </Typography>
                        );
                    }}
                    value={selectedTagTypes}
                    renderInput={(params) => <CustomTextField {...params} label="Tag Types" />}
                />
                <Box style={{ width: "100%", }}>
                    <CustomTextField disabled={true} multiline rows={2} required={true} label="Summary" variant="outlined" size="small" style={{ width: "100%", }} value={selectedLeadToEdit?.leadResponse?.summary ?? ""} />
                </Box>
            </Box>
        </Box>
    )
}

function EditLeadSection({ setIsEditLeadLoading, isEditLeadLoading, handleEditLead, onEditLeadCancelPressed, selectedLeadToEdit, franchise, users, createdByUser, priority, setPriority, campaigns, campaign, setCampaign, isCampaignsLoading, jobTypes, jobType, setJobType, isJobTypesLoading, businessUnit, setBusinessUnit, businessUnits, isBusinessUnitsLoading, tagTypes, selectedTagTypes, callReasons, callReason, isUsersLoading, isCurrentUserLoading }) {

    function handleJobTypeChange(_, jobType) {
        setJobType(jobType)
        handleAutoPickBusineeUnit(jobType)
        if (jobType?.priority) setPriority(jobType?.priority)
    }

    function handleAutoPickBusineeUnit(jobType) {
        setBusinessUnit()
        if (jobType?.businessUnitIds?.length === 1) {
            const businessUnit = businessUnits.find((b) => b.id === jobType?.businessUnitIds[0])
            setBusinessUnit(businessUnit)
        }
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: "column", gap: "0.5em", marginBottom: "3em" }}>
            <Box sx={{ display: 'flex', flexDirection: "row", flexWrap: "wrap", width: "100%", gap: "1em" }} >
                <Autocomplete
                    style={{ width: "49%", flexGrow: 2 }}
                    size="small"
                    disablePortal
                    disableClearable
                    disabled={false}
                    loading={isCampaignsLoading}
                    noOptionsText={franchise ? "No campaigns for franchise" : "Please select a franchise"}
                    options={campaigns}
                    getOptionLabel={(campaign) => campaign.name}
                    renderOption={(props, campaign) => {
                        return (
                            <Typography {...props} key={campaign.id}>
                                {campaign.name}
                            </Typography>
                        );
                    }}
                    value={campaigns.length > 0 ? (campaign ?? null) : null}
                    onChange={(_, campaign) => setCampaign(campaign)}
                    renderInput={(params) => <CustomTextField   {...params} required={true} label="Select Campaign" />}
                />
                <Autocomplete
                    style={{ width: "49%", flexGrow: 2 }}
                    size="small"
                    disablePortal
                    disableClearable={true}
                    disabled={false}
                    noOptionsText={franchise ? "No job types for franchise" : "Please select a franchise"}
                    loading={isJobTypesLoading}
                    options={jobTypes}
                    getOptionLabel={(jobType) => jobType.name}
                    renderOption={(props, jobType) => {
                        return (
                            <Typography {...props} key={jobType.id}>
                                {jobType.name}
                            </Typography>
                        );
                    }}
                    onChange={handleJobTypeChange}
                    value={jobTypes.length > 0 ? (jobType ?? null) : null}
                    renderInput={(params) => <CustomTextField   {...params} label="Select Job Type" />}
                />
                <Autocomplete
                    style={{ width: "49%", flexGrow: 2 }}
                    size="small"
                    disablePortal
                    disabled={!jobType}
                    disableClearable={selectedLeadToEdit}
                    noOptionsText={franchise ? "No business units for franchise" : "Please select a franchise"}
                    loading={isBusinessUnitsLoading}
                    options={jobType?.businessUnitIds?.length ? businessUnits?.filter?.((v) => jobType?.businessUnitIds?.includes?.(v.id)) : businessUnits}
                    getOptionDisabled={(option) => !option.active}
                    getOptionLabel={(businessUnit) => businessUnit.name}
                    renderOption={(props, businessUnit) => {
                        return (
                            <Typography {...props} key={businessUnit.id}>
                                {businessUnit.name}
                            </Typography>
                        );
                    }}
                    onChange={(_, businessUnit) => {
                        setBusinessUnit(businessUnit);
                    }}
                    value={businessUnits.length > 0 ? (businessUnit ?? null) : null}
                    renderInput={(params) => <CustomTextField   {...params} label="Select Busines Unit" />}
                />
                <Autocomplete
                    style={{ width: "49%", flexGrow: 2 }}
                    size="small"
                    disablePortal
                    disableClearable
                    disabled={false}
                    options={PRIORITIES}
                    getOptionLabel={(priority) => priority}
                    renderOption={(props, priority) => {
                        return (
                            <Typography {...props} key={priority}>
                                {priority}
                            </Typography>
                        );
                    }}
                    onChange={(_, priority) => setPriority(priority)}
                    value={(priority ?? null)}
                    renderInput={(params) => <CustomTextField   {...params} required={selectedLeadToEdit} label="Priority" />}
                />
                <div style={{ width: "49%", flexGrow: 2 }} >
                    <CustomDatePicker
                        minTime={selectedLeadToEdit ? undefined : new Date()}
                        disabled={true}
                        label={"Next Follow-Up Date"}
                        value={(selectedLeadToEdit?.leadResponse?.followUpDate ? (Date.parse(selectedLeadToEdit?.leadResponse?.followUpDate)) : null)}
                    />
                </div>
                <Autocomplete
                    style={{ width: "49%", flexGrow: 2 }}
                    size="small"
                    disablePortal
                    disabled={true}
                    options={callReasons}
                    getOptionLabel={(callReason) => callReason.name}
                    getOptionDisabled={(callReason) => !callReason.active}
                    renderOption={(props, callReason) => {
                        return (
                            <Typography {...props} key={callReason.id}>
                                {callReason.name}
                            </Typography>
                        );
                    }}
                    value={callReasons.length > 0 ? (callReason ?? null) : null}
                    renderInput={(params) => <CustomTextField  {...params} label="Call Reason" />}
                />
                <Autocomplete
                    style={{ width: "49%", flexGrow: 2 }}
                    size="small"
                    disablePortal
                    multiple={true}
                    disabled={true}
                    noOptionsText={franchise ? "No tag types for franchise" : "Please select a franchise"}
                    options={tagTypes}
                    getOptionLabel={(tagType) => tagType.name}
                    renderOption={(props, tagType) => {
                        return (
                            <Typography {...props} key={tagType.id}>
                                {tagType.name}
                            </Typography>
                        );
                    }}
                    value={selectedTagTypes}
                    renderInput={(params) => <CustomTextField {...params} label="Tag Types" />}
                />
                <Box style={{ width: "100%", }}>
                    <CustomTextField disabled={true} multiline rows={2} required={true} label="Summary" variant="outlined" size="small" style={{ width: "100%", }} value={selectedLeadToEdit?.leadResponse?.summary ?? ""} />
                </Box>
                <Box sx={{ marginLeft: "auto" }} >
                    <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "right", gap: "1em" }} >
                        <Button size={"small"} variant="contained" onClick={onEditLeadCancelPressed} className={"btn-secondary"} >Cancel</Button>
                        <LoadingButton
                            onClick={() => { handleEditLead(setIsEditLeadLoading) }}
                            size={"small"}
                            variant="contained"
                            disabled={isEditLeadLoading}
                            loading={isEditLeadLoading}
                            className={isEditLeadLoading ? "btn-disable" : "btn-primary"} >
                            {"Update Lead"}
                        </LoadingButton>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
