import { NOTIFICATION_TYPES } from "../../constants/common"
import { getAngiesLeadById, getAngiesLeads } from "../../services/angiesLeads/angeisLeads"
import { retry, shouldRetry } from "../../state-services-dublicate/serviceTitan"

export async function handleGetAngiesLeads(name = "", brandBrandId = 0, franchiseId = 0, createdOnOrAfter = "", createdOnOrBefore = "", page = 1, pageSize = 50, setLeadRes = (res) => { }, setIsLoading = (isLoading) => { }, setIsError = (isError) => { }, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    try {
        setIsLoading(true)
        setIsError(false)
        const res = await getAngiesLeads(name, brandBrandId, franchiseId, createdOnOrAfter, createdOnOrBefore, page, pageSize)
        setLeadRes(res)
    } catch (error) {
        if (shouldRetry(backoffConfig) && error?.status !== 400) {
            retry(() => handleGetAngiesLeads(name, brandBrandId, franchiseId, createdOnOrAfter, createdOnOrBefore, page, pageSize, setLeadRes, setIsLoading, setIsError, backoffConfig), backoffConfig)
        } else {
            setIsError(true)
        }
    } finally {
        setIsLoading(false)
    }
}

export async function handleGetAngiesLeadById(id = 0, setLead = (lead) => { }, showNotification, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    try {
        if (!id) return
        const lead = await getAngiesLeadById(id)
        setLead(lead)
    } catch (error) {
        if (shouldRetry(backoffConfig) && error?.status !== 400 && error?.status !== 404) {
            retry(() => handleGetAngiesLeadById(id, setLead, showNotification, backoffConfig), backoffConfig)
        } else if (error?.status === 404) {
            showNotification({ message: `Important : Lead id :${id} not found`, type: NOTIFICATION_TYPES.ERROR })
        } else {
            showNotification({ message: "Important : Could not load lead. Please try again later", type: NOTIFICATION_TYPES.ERROR })
        }
    }
}

