import { Autocomplete, Typography } from "@mui/material";
import CustomTextField from "./CustomTextField";
import { imposibleToMatchRegex, isValidZipCode } from "../utils/validators";
import { CLASS_NAMES } from "../constants/common";
import { filterStatesByCountry, validateStateWithCountryForEditLocation } from "../state-services-dublicate/vonigo";

export default function AddressBoxOfLocation(props) {
    const { address, editLocation, isDisabled, isRequired, setisValidZipCodeAddress, onChangeEditAddress = () => { }, states, countries, zipCodeValidator } = props
    let isZipCodeValid
    if (zipCodeValidator) {
        isZipCodeValid = zipCodeValidator?.(address?.zip, address?.country)
        setisValidZipCodeAddress(isZipCodeValid)
    } else {
        isZipCodeValid = isValidZipCode(address?.zip, address?.country);
        setisValidZipCodeAddress(isZipCodeValid)
    }
    let zipCodePattern
    if (!isZipCodeValid) zipCodePattern = imposibleToMatchRegex
    return <>
        <CustomTextField disabled={isDisabled} required={isRequired} label="Street" variant="outlined" size="small" style={{ flex: "0 1 25%" }} value={address?.street ?? ""} onChange={(event) => { onChangeEditAddress({ ...address, street: event.target.value }); }} ></CustomTextField>
        <CustomTextField disabled={isDisabled} label={!isDisabled ? "Unit - Optional" : "Unit"} variant="outlined" size="small" style={{ flex: "0 0 10%" }} value={address?.unit ?? ""} onChange={(event) => { onChangeEditAddress({ ...address, unit: event.target.value }); }} ></CustomTextField>
        <CustomTextField disabled={isDisabled} required={isRequired} label="City" variant="outlined" size="small" style={{ flex: "1 1 15%" }} value={address?.city ?? ""} onChange={(event) => { onChangeEditAddress({ ...address, city: event.target.value }); }} ></CustomTextField>
        <CustomTextField className={CLASS_NAMES.ZIP} disabled={isDisabled} required={isRequired} label="Zip/Postal Code" variant="outlined" size="small" style={{ flex: "0 0 15%" }} value={address?.zip ?? ""} error={!isZipCodeValid} helperText={isZipCodeValid ? "" : `Invalid Zip Code`} inputProps={{ pattern: zipCodePattern }} onChange={(event) => { onChangeEditAddress({ ...address, zip: event.target.value }); }} ></CustomTextField>
        <Autocomplete
            style={{ flex: "0 0 25%" }}
            size="small"
            disableClearable={true}
            disabled={isDisabled}
            options={countries}
            getOptionLabel={(country) => country}
            renderOption={(props, country) => {
                return (
                    <Typography {...props} key={country}>
                        {country}
                    </Typography>
                );
            }}
            value={address?.country ? address?.country ?? "" : ""}
            onChange={(event, country) => { onChangeEditAddress({ ...address, country: country }); }}
            renderInput={(params) => <CustomTextField required={isRequired} disabled={isDisabled}  {...params} label="Country" />}
        />
        <Autocomplete
            style={{ flex: "0 0 25%" }}
            size="small"
            disableClearable={true}
            disabled={isDisabled}
            options={filterStatesByCountry(states, address)}
            getOptionLabel={(state) => state}
            renderOption={(props, state) => {
                return (
                    <Typography {...props} key={state}>
                        {state}
                    </Typography>
                );
            }}
            value={address?.state ? address?.state ?? "" : ""}
            onChange={(event, state) => { onChangeEditAddress({ ...address, state: state }); }}
            renderInput={(params) => <CustomTextField required={isRequired} error={!validateStateWithCountryForEditLocation(states, editLocation)} helperText={validateStateWithCountryForEditLocation(states, editLocation) ? "" : "state not valid for country"} disabled={isDisabled}  {...params} label="State" />}
        />
    </>
}

