import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import ibsLogo from "../../assets/img/IBS_lOGO.png";
import { LOCAL_STORAGE_KEYS, SESSION_STORAGE_KEYS } from "../../constants/browserStorage";
import { checkLoginStatus } from "../../services/userService";
import { MEDIA_TYPES } from "../other/Dialer";
import bgImg from "./assets/Rectangle.png"
import "./styles/login.css"

function LoginPage(props) {
    const [err, setErr] = useState("")

    useEffect(() => {
        checkLogin()
    }, [])

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const err = urlParams.get("err");
        if (err) setErr("You are not authorized to use this system. Please contact your administrator")
    }, []);

    const checkLogin = async () => {
        try {
            let response = await checkLoginStatus()
            if (response.is_logged_in) {
                let urlParamData = {
                    media: localStorage.getItem(LOCAL_STORAGE_KEYS.dialerMedia) ?? MEDIA_TYPES.MANUAL.media,
                    channelName: localStorage.getItem(LOCAL_STORAGE_KEYS.dialerChannelName) ?? "",
                    channelId: localStorage.getItem(LOCAL_STORAGE_KEYS.dialerChannelId) ?? "",
                    callerId: localStorage.getItem(LOCAL_STORAGE_KEYS.dialerCallerId) ?? "",
                    zipCode: localStorage.getItem(LOCAL_STORAGE_KEYS.dialerZipCode) ?? "",
                    subject: localStorage.getItem(LOCAL_STORAGE_KEYS.dialerSubject) ?? "",
                    interactionGuid: sessionStorage.getItem(SESSION_STORAGE_KEYS.interactionGuid) ?? "",
                }
                let url = `/dialer?media=${urlParamData.media}&zip_code=${urlParamData.zipCode}&caller_id=${urlParamData.callerId}&channel_id=${urlParamData.channelId}&channel_name=${urlParamData.channelName}&subject=${urlParamData.subject}&interaction_guid=${urlParamData.interactionGuid}`
                props.history.push(url)
            }
        } catch (error) { }
    }

    const handleGoogleLogin = () => window.location.replace(process.env.REACT_APP_AUTH_API_URL + "/auth/google/login");

    return (
        <div className="login-page" >
            <img alt="bg" src={bgImg} className="login-background" />
            <div className="login-content">
                <div className="login-col">
                    <img src={ibsLogo} alt="ibs-logo" />
                </div>
                <div className="login-col">
                    <div>
                        <h1>Sign in</h1>
                        <p>Are you ready to simplify your workday and get more done?<br /> IBS middleware product is here to help.</p>
                        <p style={{ color: "red", fontSize: "12px" }}>{err}</p>
                        <Button onClick={handleGoogleLogin} className="btn-google">
                            <img alt="google-icon" src="https://img.icons8.com/color/48/000000/google-logo.png" />
                            Sign in with Google
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(LoginPage);
