import React from 'react'
import ReactApexChart from 'react-apexcharts';

export default function MiddlewearStatisticsDounetChart({ formatterFunction, data }) {

    let labelsAndSeries = formatterFunction(data, "DonutChart")

    return (
        <>
            <ReactApexChart
                options={{
                    chart: {
                        width: 300,
                        height: 300,
                        type: 'donut',
                        offsetY: 60,
                        offsetX: 62,
                        dropShadow: {
                            enabled: true,
                            color: '#111',
                            top: -1,
                            left: 3,
                            blur: 3,
                            opacity: 0.2
                        },
                    },
                    stroke: {
                        width: 0,
                    },
                    dataLabels: {
                        enabled: true,
                        dropShadow: {
                            blur: 3,
                            opacity: 0.8
                        },
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                ignoreZeroSeries: false,
                                labels: {
                                    show: true,
                                    total: {
                                        showAlways: true,
                                        show: true,
                                        label: 'Total Calls',
                                        color: '#7B7A75',
                                    },
                                    value: {
                                        color: '#7B7A75',
                                    }
                                }
                            }
                        }
                    },
                    labels: labelsAndSeries?.labels,
                    legend: {
                        show: true,
                        position: 'right',
                        offsetY: -10,
                        offsetX: 50,
                        height: 300,
                    },
                }}
                series={labelsAndSeries?.series} type="donut" width={600}
            />
        </>
    )
}
