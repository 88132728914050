import { NOTIFICATION_TYPES, SERVICE_TYPES } from "../constants/common"
import { INITIAL_LEAD_STATISTICS } from "../constants/serviceTitan"

import { getAllBrands, searchBrands } from "../services/brandService"
import { globalSearch } from "../services/globalSearch"

export const handleSearch = async (serviceType, debouncedSearchTerm, setLoading, setRecords, showNotification, setCount, page, size) => {
    try {
        if (debouncedSearchTerm?.length > 0) {
            debouncedSearchTerm = debouncedSearchTerm.trim()
            let searchresult
            setLoading(true)
            switch (serviceType) {
                case SERVICE_TYPES.Brands:
                    searchresult = await searchBrands(debouncedSearchTerm, page, size)
                    setRecords(searchresult?.brands ? searchresult?.brands : [])
                    setCount(searchresult?.count)
                    break;
                default:
                    setRecords(null)
                    break;
            }
        } else {
            setRecords([])
        }
    } catch (error) {
        showNotification({ message: "Unable to search at this moment", type: NOTIFICATION_TYPES.ERROR })
    } finally {
        setLoading(false)
    }
}

export const getAllHandler = async (serviceType, page, countPerPage, setLoading, setCount, setRecords) => {
    try {
        let apiData
        setLoading(true)
        switch (serviceType) {
            case SERVICE_TYPES.Brands:
                apiData = await getAllBrands(page, countPerPage)
                setRecords(apiData?.brands)
                setCount(apiData?.count)
                break;
            default:
                apiData = []
                break;
        }
    } catch (error) {
    } finally {
        setLoading(false)
    }
}

export const handleGlobalSearch = async (prefix, searchTerm, setRedisSearchResults, setIsRedisSeachLoading, setAllLeads, setLeadStatistics, setSelectedLead) => {
    try {
        setIsRedisSeachLoading(true)
        let res = await globalSearch(prefix, searchTerm)
        if (Array.isArray(res)) {
            setAllLeads([])
            setLeadStatistics(INITIAL_LEAD_STATISTICS)
            const redisSearchResults = res?.map((d) => ({
                id: d?.id?.split(":")[1]?.trim(),
                contacts: d?.properties?.contacts,
                customerName: d?.properties?.customer,
            }));
            setRedisSearchResults(redisSearchResults);
        }
    } catch (error) { } finally {
        setIsRedisSeachLoading(false)
    }
}