import React from 'react'
import { useState, useEffect, useContext } from 'react';
import { Box, TableCell, IconButton, TableRow, Chip, Tooltip, Typography } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility';
import { CustomTextFieldForFaqTempalte } from '../../../../../../src/components/CustomTextField'
import { getPhoneNumberWithInputMask } from '../../../../../utils/inputMasks';
import Draw from './Drawer'
import { UserContext } from '../../../../../contexts/User';
import { USER_TYPES } from '../../../../../constants/users';

export default function EditFaqRow({ id, faq, faqs, setFaqs, isKeywordsLoading, isKeywordsLoadingError, keywords }) {

    const [faqToEdit, setFaqToEdit] = useState(faq)
    const [isEditFaq, setisEditFaq] = useState(false)
    const [isOpenDrawer, setIsOpenDrawer] = useState(false)
    const [displayedKeywords, setDisplayedKeywords] = useState([]);
    const [remainingCount, setRemainingCount] = useState(0);
    const { userPreferences, me } = useContext(UserContext)

    useEffect(() => {
        let totalFaqKeywords = faq.keywords.length;
        setDisplayedKeywords(faq.keywords.slice(0, 1));
        setRemainingCount(totalFaqKeywords - 1);
    }, [faq]);

    return <>
        <TableRow key={id}>
            <TableCell colSpan={1} >
                <Box sx={{ height: "auto", width: "auto" }} >
                    <CustomTextFieldForFaqTempalte
                        required
                        sx={{ width: "100%" }}
                        multiline
                        maxRows={1}
                        disabled={true}
                        value={faqToEdit?.question ?? null}
                        size="small"
                        margin="normal"
                        variant="outlined"
                    />
                </Box>
            </TableCell>
            <TableCell colSpan={1} >
                <Box sx={{ height: "4.5em", width: "auto" }} >
                    <CustomTextFieldForFaqTempalte
                        required
                        sx={{
                            width: "100%",
                        }}
                        value={faqToEdit?.question === "Business Number" ? getPhoneNumberWithInputMask(faqToEdit?.answer) : faqToEdit?.answer ?? null}
                        disabled={true}
                        multiline
                        maxRows={1}
                        size="small"
                        margin="normal"
                        variant="outlined"
                    />
                </Box>
            </TableCell>
            <TableCell colSpan={1}>
                <Box sx={{
                    height: "2.9em",
                    marginTop: "0.6em",
                    borderRadius: "0.3em",
                    width: "25em",
                    border: userPreferences?.dark_mode_enabled ? "1px solid #5F6061" : "1px solid #ccc",
                    overflow: 'auto',
                    display: "flex",
                    alignItems: "center",
                }} >
                    {displayedKeywords.map((keyword, index) => (
                        <Chip
                            key={index}
                            disabled={true}
                            sx={{
                                marginRight: "0.3em",
                            }}
                            size="small"
                            label={keyword}
                        />
                    ))}
                    {remainingCount > 0 && (
                        <Typography
                            sx={{
                                marginBottom: "0.4em",
                                flexShrink: 0,
                                whiteSpace: "nowrap",
                            }}
                        >
                            {`+${remainingCount} more`}
                        </Typography>
                    )}
                </Box>
            </TableCell>
            <TableCell align="center">
                {!isEditFaq &&
                    <Box>
                        <Tooltip title={me?.level <= USER_TYPES?.supervisor?.level ? "View" : "View & Edit"} placement="top" arrow>
                            <IconButton color="primary" title='Edit Faq'
                                onClick={() => {
                                    setisEditFaq(true)
                                    setIsOpenDrawer(true)
                                }
                                }>
                                <VisibilityIcon style={{ height: "20px", width: "20px" }} />
                            </IconButton>
                        </Tooltip>
                    </Box>

                }
            </TableCell>
        </TableRow>
        <Draw id={id} faq={faq} faqs={faqs} setFaqs={setFaqs} faqToEdit={faqToEdit} setFaqToEdit={setFaqToEdit} setisEditFaq={setisEditFaq} isKeywordsLoading={isKeywordsLoading} keywords={keywords} drawerOpen={isOpenDrawer} onClose={() => setIsOpenDrawer(false)} />
    </>
}
