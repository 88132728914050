import { Autocomplete, Box, Button, Dialog, Typography } from "@mui/material";
import DialogHeader from "../../../../components/dialog/DialogHeader";
import DialogBody from "../../../../components/dialog/DialogBody";
import { useContext, useEffect, useState } from "react";
import { CommonContext } from "../../../../contexts/CommonContextProvider";
import { handleMoveTasks } from "../../../../state-services/schedule/schedule";
import LoadingButton from "../../../../components/LoadingButton";
import TasksSummary from "./TasksSummary";
import CustomDatePicker from "../../../../components/CustomDatePicker";
import { getDateTimeStringFromDateObject } from "../../../../utils/time/time";
import { NOTIFICATION_TYPES } from "../../../../constants/common";
import CustomTextField from "../../../../components/CustomTextField";


export default function TaskMoveDialog({ isOpen, onClose, tasks }) {
    const { showNotification, timezones, isTimezonesLoading, isTimezonesError, generalTenantConfiguration } = useContext(CommonContext)
    const [isTaskCopyLoading, setIsTasksCopyLoading] = useState(false)
    const [newTasks, setNewTasks] = useState([...tasks])
    const [copyDetails, setCopyDetails] = useState({ time_zone: generalTenantConfiguration?.time_zone })

    useEffect(() => {
        if (!isOpen) {
            setNewTasks([])
            setCopyDetails({ time_zone: generalTenantConfiguration?.time_zone })
        } else {
            setNewTasks([...tasks])
        }
    }, [isOpen, tasks, generalTenantConfiguration?.time_zone])

    return <Dialog fullWidth maxWidth="md" disableScrollLock={true} open={isOpen} >
        <DialogHeader onClose={onClose} className={'dialog-plain-color'}>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center", gap: "0.5em", padding: "1em" }}>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "left", borderRadius: "0.5em", }}>
                    <Typography variant="h5" component={"h1"} fontWeight="bold" >Move Tasks</Typography>
                </Box>
                <Box flexGrow={2}></Box>
                <TasksSummary tasks={tasks} />
            </Box>
        </DialogHeader>
        <DialogBody className={'dialog-color'}>
            <form onSubmit={(e) => {
                e?.preventDefault?.()
                if (!copyDetails.start_time_utc) {
                    showNotification({ type: NOTIFICATION_TYPES.ERROR, message: "Important: Start Time Required" })
                    return
                }
                const modifiedTasks = newTasks?.map?.((task) => {
                    return { ...task, copied_from: task?.id, assigned_to: 0 }
                })
                handleMoveTasks(modifiedTasks, setIsTasksCopyLoading, () => onClose(true), showNotification)
            }} >
                <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5em" }}>

                    <Box sx={{ display: "flex", flexDirection: "row", gap: "0.5em", height: "250px" }}>
                        <Autocomplete
                            sx={{ flexGrow: 4 }}
                            size="small"
                            noOptionsText={isTimezonesError ? "Something went wrong" : "No Timezones"}
                            disableClearable
                            options={timezones}
                            loading={isTimezonesLoading}
                            required={true}
                            getOptionLabel={(timeZone) => timeZone.abbreviation}
                            value={timezones.find((t) => t.time_zone === copyDetails?.time_zone) ?? null}
                            onChange={(_, timezone) => {
                                const modifiedTasks = newTasks?.map?.((task) => {
                                    return { ...task, time_zone: timezone?.time_zone }
                                })
                                setNewTasks(modifiedTasks)
                                setCopyDetails({ ...copyDetails, time_zone: timezone?.time_zone })
                            }}
                            renderInput={(params) => <CustomTextField {...params} required={true} label="Timezone" />}
                        />
                        <div style={{ flexGrow: 3 }} >
                            <CustomDatePicker
                                minTime={new Date()}
                                label={"Start Time"}
                                required={true}
                                value={copyDetails.start_time_utc ? new Date(copyDetails?.start_time_utc) : undefined}
                                timeZone={copyDetails?.time_zone ? copyDetails.time_zone : undefined}
                                onChange={(date) => {
                                    const modifiedTasks = newTasks?.map?.((task) => {
                                        return { ...task, start_time_local: getDateTimeStringFromDateObject(date, task.time_zone), start_time_utc: date?.toISOString?.() }
                                    })
                                    setNewTasks(modifiedTasks)
                                    setCopyDetails({ ...copyDetails, start_time_local: getDateTimeStringFromDateObject(date, copyDetails.time_zone), start_time_utc: date?.toISOString?.() })
                                }}
                                clearable={false}
                            />
                        </div>
                        <div style={{ flexGrow: 3 }} >
                            <CustomDatePicker
                                minTime={copyDetails?.start_time_utc ? new Date(copyDetails?.start_time_utc) : new Date()}
                                required={false}
                                label={"End Time"}
                                value={copyDetails?.end_time_utc ? new Date(copyDetails?.end_time_utc) : undefined}
                                timeZone={copyDetails?.time_zone ? copyDetails.time_zone : undefined}
                                onChange={(date) => {
                                    const modifiedTasks = newTasks?.map?.((task) => {
                                        return { ...task, end_time_local: getDateTimeStringFromDateObject(date, task.time_zone), end_time_utc: date?.toISOString?.() }
                                    })
                                    setNewTasks(modifiedTasks)
                                    setCopyDetails({ ...copyDetails, end_time_local: getDateTimeStringFromDateObject(date, copyDetails.time_zone), end_time_utc: date?.toISOString?.() })
                                }}
                                clearable={false}
                            />
                        </div>
                    </Box>
                    <Box sx={{ marginBlock: "1em", display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                        <Button size={"small"} variant="contained" onClick={onClose} className={"btn-secondary"} style={{ marginRight: "10px" }} >Cancel</Button>
                        <LoadingButton type={"submit"} size={"small"} variant="contained" loading={isTaskCopyLoading} color="error" >Move</LoadingButton>
                    </Box>
                </Box>
            </form>
        </DialogBody>
    </Dialog >
}