import { Box, Button, Tooltip } from "@mui/material"
import { useEffect, useMemo, useState } from "react"
import { Prompt } from "react-router-dom"
import DialogFooter from "../../../../../components/dialog/DialogFooter"
import { COUNTRIES, NOTIFICATION_TYPES } from "../../../../../constants/common"
import { CREATE_JOB_SECTION_IDS, SECTIONS } from "../../../../../constants/jobs"
import useDebounce from "../../../../../hooks/useDebounce"
import { getIsServiceableZipCode } from "../../../../../services/crm/crm"
import { createContact, createCustomer, createVonigoJobDraft, createJob, createLocation, createWorkOrder, deleteVonigoJobDraft, updateVonigoJobDraft, getMessages } from "../../../../../services/vonigo/jobScheduling"
import { handleGetContacts, handleGetLocations, handleGetSingleClient, handleGetSingleContact, handleGetSingleContactDetails, handleGetSingleLoction, handleGetSingleLoctionDetails, handleRemoveLock, handleSearchCustomers } from "../../../../../state-services-dublicate/vonigo"
import { getCanFetchServiceableZipCode, getPromptMessage } from "../../../../../utils/jobs"
import { isValidZipCode } from "../../../../../utils/validators"
import { getIsWorkOrderSectionComplete, getIsNewCustomerSectionComplete, isContactValid, getIsJobComplete, getLocationRequest, getIsLocationCompleteWithServicebleZipCode } from "../../../../../utils/vonigo"
import { getExistingCustomerType } from "../../../../../utils/vonigo/miscellaneous"
import CreateWorkOrderSection from "../../vonigo/components/CreateWorkOrderSection"
import { CUSTOMER_TYPES, INITIAL_CUSTOMER_VALUES } from "../../vonigo/components/CustomerDetailsSection"
import { handleScrollPosition } from "../CreateJobPage"
import ContactDetailsSection from "./ContactDetailsSection"
import CustomerDetailsSection from "./CustomerDetailsSection"
import JobDetailsSection from "./JobDetailsSection"
import LocationDetailsSection from "./LocationDetailsSection"
import MultipleWorkOrdersCustomerDetails from "./MultipleWorkOrdersCustomerDetails"
import MultipleWorkOrdersJobDetails from "./MultipleWorkOrdersJobDetails"
import VonigoCustomerSearchSection from "./VonigoSearchSection"
import CustomerSearchMessage from "./CustomerSearchMessage"
import CustomerNoteSection from "./CustomerNoteSection"
import { messageTypes } from "./messageConstat"
import LoadingButton from "../../../../../components/LoadingButton"
import { updateJob, mapCallJobLead } from "../../../../../services/jobService"
import { LOCAL_STORAGE_KEYS } from "../../../../../constants/browserStorage"

export function VonigoMainSection(props) {
    const { brand, franchise, setOpenViewJob, openViewJob, selectedPhoneNumber, isDisabled, showNotification, onClose, selectedZipCode, setCompletedSections, selectedDraftToContinue, shouldSaveDraft, setShouldSaveDraft, isCreateMultipleWorkOrders, setIsCreateMultipleWorkOrders, selectedJobToView, setSelectedJobToView, workOrderToReschedule, isRescheduleWorkOrder, setIsRescheduleWorkOrder, shouldRemoveLock, setshouldRemoveLock } = props
    let initialPhoneNumber = useMemo(() => {
        return selectedPhoneNumber?.length > 10 ? selectedPhoneNumber.substr(selectedPhoneNumber.length - 10) : selectedPhoneNumber
    }, [selectedPhoneNumber])
    const [isCustomersLoading, setIsCustomersLoading] = useState(false)
    const [searchPar, setSearchPar] = useState(selectedDraftToContinue ? selectedDraftToContinue?.searchPar : initialPhoneNumber)
    const [isNewCustomer, setIsNewCustomer] = useState(selectedDraftToContinue ? selectedDraftToContinue?.isNewCustomer : true)
    const [isNewLocationForExistingCustomer, setIsNewLocationForExistingCustomer] = useState(selectedDraftToContinue ? selectedDraftToContinue?.isNewLocation : false)
    const [selectedCustomer, setSelectedCustomer] = useState()
    const [customers, setCustomers] = useState([])
    const [selectedLocation, setSelectedLocation] = useState()
    const [selectedLocationDetails, setSelectedLocationDetails] = useState()
    const [locations, setLocations] = useState([])
    const [isLocationsLoading, setIsLocationsLoading] = useState(false)
    const [customer, setCustomer] = useState(selectedDraftToContinue ? getInitialCustomer(selectedDraftToContinue) : INITIAL_CUSTOMER_VALUES)
    const [location, setLocation] = useState(selectedDraftToContinue?.frontEndLocationRequest?.location)
    const [contact, setContact] = useState(selectedDraftToContinue ? selectedDraftToContinue?.frontEndContactRequest : { phone1: initialPhoneNumber })
    const [editContact, setEditContact] = useState()
    const [editLocation, setEditLocation] = useState()
    const [isExistingCustomerNewContact, setIsExistingCustomerNewContact] = useState(selectedDraftToContinue ? selectedDraftToContinue?.isNewContact : false)
    const [contacts, setContacts] = useState([])
    const [selectedContact, setSelectedContact] = useState()
    const [isContactsLoading, setIsContactsLoading] = useState(false)
    const [job, setJob] = useState(selectedDraftToContinue?.frontEndJobRequest)
    const [workOrder, setWorkOrder] = useState(selectedDraftToContinue ? selectedDraftToContinue?.frontEndCreateWorkOrderRequest : { duration: null })
    const [isCreateJobLoading, setIsCreateJobLoading] = useState(false)
    const [showingServiceableZipCodeResponse, setShowingServiceableZipCodeResponse] = useState()
    const [serviceableZipCodeResponse, setServiceableZipCodeResponse] = useState()
    const [createdCustomer, setCreatedCustomer] = useState()
    const [createdLocation, setCreatedLocation] = useState()
    const [createdContact, setCreatedContact] = useState()
    const [createdJob, setCreatedJob] = useState()
    const [displayId, setDisplayId] = useState(null)
    const [didWorkOrderSummaryEdited, setDidWorkOrderSummaryEdited] = useState(false)
    const [customerSearchCount, setCustomerSearchCount] = useState(0)
    const [locationEditCount, setLocationEditCount] = useState(0)
    const [contactEditCount, setContactEditCount] = useState(0)
    const [configMessages, setConfigMessages] = useState([])
    const [isEditLocation, setIsEditLocation] = useState(false)
    const [isEditContact, setIsEditContact] = useState(false)
    const [selectedContactDetails, setSelectedContactDetails] = useState();

    const urlParams = new URLSearchParams(window.location.search);

    const isFromCallWindow = urlParams.get("isFromCallWindow");

    const newDraft = getFullDraftRequest(brand, franchise, searchPar, selectedPhoneNumber, selectedZipCode, isNewCustomer, isNewLocationForExistingCustomer, isExistingCustomerNewContact, customer, selectedCustomer, location, selectedContact, contact, selectedLocation, job, workOrder, showingServiceableZipCodeResponse?.isServiceable ?? selectedDraftToContinue?.isWithinServiceArea)
    const shouldPrompt = getShouldPrompt()
    const debounceServiceAreaRequest = useDebounce({ brandId: brand?.brand_id, franchiseId: franchise?.id, zip: "", country: "" }, ({ brandId, franchiseId, zip, country }) => {
        if (getCanFetchServiceableZipCode(brandId, franchise?.id, zip, country) && zip?.length > 3) {
            getIsServiceableZipCode(brandId, franchiseId, zip).then((response) => {
                setServiceableZipCodeResponse(response)
            }).catch((err) => { })
        }
    }, 1000)
    useEffect(() => {
        handleGetMessages()
    }, [])
    useEffect(() => {
        if (!selectedDraftToContinue) {
            setIsNewCustomer(false)
        }
        const clientId = selectedJobToView ? selectedJobToView?.client?.objectID : selectedDraftToContinue?.frontEndCreateWorkOrderRequest?.clientId
        handleGetSingleClient(clientId, brand, franchise, setCustomers, setSelectedCustomer)
        const locationId = selectedDraftToContinue?.frontEndCreateWorkOrderRequest?.locationId
        handleGetSingleLoction(locationId, brand, franchise, setLocations, setSelectedLocation)
        const contactId = selectedDraftToContinue?.frontEndCreateWorkOrderRequest?.contactId
        handleGetSingleContact(contactId, brand, franchise, setContacts, setSelectedContact)
    }, [brand, franchise, selectedDraftToContinue, selectedJobToView])

    useEffect(() => {
        handleScrollPosition()
    }, [isNewCustomer])

    useEffect(() => {
        if (shouldSaveDraft) {
            handleSaveDraft()
        }
    }, [shouldSaveDraft])

    useEffect(() => {
        if (shouldRemoveLock) {
            handleRemoveLockInMultipleWorkOrders()
        }
    }, [shouldRemoveLock])

    useEffect(() => {
        const isCustomerSectionComplete = isNewCustomer ? getIsNewCustomerSectionComplete(customer) : selectedCustomer
        const isLocationSectionComplete = isNewCustomer || isNewLocationForExistingCustomer ? getIsLocationCompleteWithServicebleZipCode(location, brand, franchise, selectedCustomer, showingServiceableZipCodeResponse?.isServiceable) : (selectedLocation && showingServiceableZipCodeResponse?.isServiceable)
        const customerType = isNewCustomer ? customer?.type : getExistingCustomerType(selectedCustomer?.typeOptionId?.optionID)
        const isContactsSectionComplete = isNewCustomer || isExistingCustomerNewContact ? isContactValid(contact, customerType) : selectedContact
        const isJobSectionComplete = getIsJobComplete(job)
        const isAppointmentSectionComplete = getIsWorkOrderSectionComplete(workOrder, isCreateMultipleWorkOrders)
        const completedSections = []
        if (isCreateMultipleWorkOrders) completedSections.push(SECTIONS?.CUSTOMER)
        if (isCreateMultipleWorkOrders) completedSections.push(SECTIONS?.JOB)
        if (isCustomerSectionComplete) completedSections.push(SECTIONS?.CUSTOMER)
        if (isLocationSectionComplete) completedSections.push(SECTIONS?.LOCATION)
        if (isContactsSectionComplete) completedSections.push(SECTIONS?.CONTACT)
        if (isJobSectionComplete) completedSections.push(SECTIONS?.JOB)
        if (isAppointmentSectionComplete) completedSections.push(SECTIONS?.APPOINTMENT)
        setCompletedSections(completedSections)
    }, [brand, franchise, isNewCustomer, isNewLocationForExistingCustomer, isExistingCustomerNewContact, customer, selectedCustomer, location, selectedLocation, contact, selectedContact, job, workOrder, setCompletedSections, showingServiceableZipCodeResponse])

    useEffect(() => {
        handleGetLocations(brand, franchise, selectedCustomer, setSelectedLocation, setLocations, setIsLocationsLoading)
    }, [brand, franchise, selectedCustomer, locationEditCount])

    useEffect(() => {
        handleGetContacts(brand, franchise, selectedCustomer, setSelectedContact, setIsContactsLoading, setContacts)
    }, [brand, franchise, selectedCustomer])

    useEffect(() => {
        if (!(isValidZipCode(location?.address?.zip, location?.address?.country)) || !location?.address?.zip) setShowingServiceableZipCodeResponse()
    }, [location?.address?.zip, location?.address?.country])

    useEffect(() => {
        const zipCode = getZipCodeForIsServiceableZipCode(isNewCustomer, isNewLocationForExistingCustomer, location?.address?.zip, selectedLocation?.zip?.fieldValue)
        const countryValue = getCountryForIsServiceableZipCode(isNewCustomer, isNewLocationForExistingCustomer, location?.address?.country, selectedLocation?.countryOptionId?.fieldValue)
        if (getCanFetchServiceableZipCode(brand?.brand_id, franchise?.id, zipCode, countryValue) && zipCode?.length > 3) {
            setShowingServiceableZipCodeResponse(serviceableZipCodeResponse)
        } else {
            setShowingServiceableZipCodeResponse()
        }
    }, [isNewCustomer, isNewLocationForExistingCustomer, location?.address?.zip, selectedLocation?.zip?.fieldValue, serviceableZipCodeResponse])

    useEffect(() => {
        setShowingServiceableZipCodeResponse()
        setServiceableZipCodeResponse()
        const zipCode = getZipCodeForIsServiceableZipCode(isNewCustomer, isNewLocationForExistingCustomer, location?.address?.zip, selectedLocation?.zip?.fieldValue)
        const countryValue = getCountryForIsServiceableZipCode(isNewCustomer, isNewLocationForExistingCustomer, location?.address?.country, selectedLocation?.countryOptionId?.fieldValue)
        debounceServiceAreaRequest({ brandId: brand?.brand_id, franchiseId: franchise?.id, zip: zipCode, country: countryValue })
    }, [isNewCustomer, franchise, isNewLocationForExistingCustomer, selectedLocation, location?.address?.zip, location?.address?.country])

    useEffect(() => {
        setShowingServiceableZipCodeResponse()
        setServiceableZipCodeResponse()
        debounceServiceAreaRequest({ brandId: brand?.brand_id, franchiseId: franchise?.id, zip: editLocation?.zip, country: editLocation?.country })
    }, [editLocation?.zip, editLocation?.country])

    function getShouldPrompt() {
        if (isCreateMultipleWorkOrders) {
            return isNewLocationForExistingCustomer || selectedLocation || selectedContact || getIsLocationSufficientToDraft(location) || isExistingCustomerNewContact || getIsContactsSufficientToDraft(contact) || getIsWorkOrderSufficientToDraft(workOrder)
        } else {
            return selectedDraftToContinue ? getShouldUpdateDraft(selectedDraftToContinue, newDraft) || workOrder?.lockId : getShouldCreateDraft(searchPar, customer, selectedCustomer, location, selectedLocation, contact, selectedContact, job, workOrder)
        }
    }

    function onCustomerChanged(newCustomer) {
        if (newCustomer?.name != customer?.name) setLocation({ ...location, name: newCustomer?.name })
        setCustomer(newCustomer);
    }

    function handleCustomerToggle() {
        setIsNewCustomer(!isNewCustomer);
    }

    async function handleGetMessages() {
        let sectionMessages = []
        try {
            let messages = await getMessages()
            messages.forEach((message) => {
                messageTypes.forEach((messageType) => {
                    if (messageType?.value == message?.section_id) {
                        sectionMessages.push({ section: messageType?.name, message: message?.message })
                    }
                })
            })
            setConfigMessages(sectionMessages)
        } catch (error) { }
    }


    async function handleCreateJob(e) {
        e?.preventDefault()
        try {
            if (!showingServiceableZipCodeResponse?.isServiceable) return showNotification({ message: "Important: Zip Code not within service area", type: NOTIFICATION_TYPES.ERROR })
            if (!workOrder?.lockId) return showNotification({ message: "Important: Please select a timeslot and try again", type: NOTIFICATION_TYPES.ERROR })
            setIsCreateJobLoading(true)
            const createdJobResponse = await createNewJob(brand, franchise, isNewCustomer, customer, selectedCustomer, isNewLocationForExistingCustomer, location, selectedLocation, isExistingCustomerNewContact, contact, selectedContact, job, workOrder, createdCustomer, setCreatedCustomer,
                createdLocation, setCreatedLocation, createdContact, setCreatedContact, createdJob, setCreatedJob, selectedDraftToContinue, isCreateMultipleWorkOrders, setIsCreateMultipleWorkOrders, setSelectedJobToView, selectedJobToView, showNotification, showingServiceableZipCodeResponse, isRescheduleWorkOrder, setIsRescheduleWorkOrder)
            if (createdJobResponse) {
                showNotification({ message: isCreateMultipleWorkOrders ? "Work order has been created successfully" : "The job has been created successfully", type: NOTIFICATION_TYPES.SUCCESS })

                if (isFromCallWindow === 'true') {
                    await updateExistingJob()
                    await mapCallWithJobLead(createdJobResponse.jobID)
                }

                onClose(createdJobResponse)
                setOpenViewJob(openViewJob + 1)
            } else {
                showNotification({ message: isCreateMultipleWorkOrders ? "Important: Work order couldn’t be created at this time. Please try again" : "Important: Job couldn’t be created at this time. Please try again", type: NOTIFICATION_TYPES.ERROR })
            }
        } catch (err) {
            showNotification({ message: isCreateMultipleWorkOrders ? "Important: Work order couldn’t be created at this time. Please try again" : "Important: Job couldn’t be created at this time. Please try again", type: NOTIFICATION_TYPES.ERROR })
        } finally {
            setIsCreateJobLoading(false)
        }
    }

    async function handleRemoveLockInMultipleWorkOrders() {
        try {
            if (workOrder?.lockId) {
                await handleRemoveLock(brand, franchise, workOrder, setWorkOrder)
                showNotification({ message: "Time Slot Released", type: NOTIFICATION_TYPES.SUCCESS })
            }
            onClose(selectedJobToView, undefined, undefined)
        } catch (error) { }
        finally {
            setOpenViewJob(openViewJob + 1)
            setshouldRemoveLock(false)
        }
    }

    async function handleSaveDraft() {
        try {
            if (workOrder?.lockId) {
                handleRemoveLock(brand, franchise, workOrder, setWorkOrder)
                showNotification({ message: "Time Slot Released", type: NOTIFICATION_TYPES.SUCCESS })
            }
            if (!shouldPrompt) {
                onClose(undefined, undefined, undefined)
                return
            }
            let draftResponse;
            const createDraftRequest = getFullDraftRequest(brand, franchise, searchPar, initialPhoneNumber, selectedZipCode, isNewCustomer, isNewLocationForExistingCustomer, isExistingCustomerNewContact, customer, selectedCustomer, location, selectedLocation, contact, selectedContact, job, workOrder, showingServiceableZipCodeResponse?.isServiceable ?? selectedDraftToContinue?.isWithinServiceArea)
            if (selectedDraftToContinue) {
                const updateDraftRequest = {
                    ...createDraftRequest,
                    callerId: selectedDraftToContinue?.callerId,
                    zipCode: selectedDraftToContinue?.zipCode,
                    id: selectedDraftToContinue?.id,
                }
                draftResponse = await updateDraft(updateDraftRequest)
                if (draftResponse) showNotification({ message: "Draft updated", type: NOTIFICATION_TYPES.SUCCESS })
            } else {
                draftResponse = await saveDraft(createDraftRequest)
                if (draftResponse) showNotification({ message: "Saved to drafts", type: NOTIFICATION_TYPES.SUCCESS })
            }
            if (draftResponse) onClose(undefined, draftResponse, undefined)
        } catch {
            showNotification({ message: "Important: Couldn’t save to drafts", type: NOTIFICATION_TYPES.ERROR })
            setShouldSaveDraft(false)
        }
    }

    function handleRescheduleClose() {
        try {
            if (workOrder?.lockId) {
                handleRemoveLock(brand, franchise, workOrder, setWorkOrder)
                showNotification({ message: "Time Slot Released", type: NOTIFICATION_TYPES.SUCCESS })
            }
            setOpenViewJob(openViewJob + 1)
            onClose(selectedJobToView, undefined, undefined)
        } catch (error) { }
    }

    function handleOnSelectedCustomerChange(customer) {
        setSelectedContact()
        setContacts([])
        setSelectedLocation()
        setSelectedCustomer(customer)
    }

    function handleOnSearchParChanged(e) {
        setSearchPar(e?.target?.value)
    }

    function onWorkOrderSummaryChanged(summary) {
        if (!didWorkOrderSummaryEdited) setWorkOrder({ ...workOrder, summary: summary })
    }

    function getUnlockTimeAndGetPromptMessage() {
        if (workOrder?.lockId) {
            handleRemoveLock(brand, franchise, workOrder, setWorkOrder)
            showNotification({ message: "Time Slot Released", type: NOTIFICATION_TYPES.SUCCESS })
            setDisplayId()
        }
        return getPromptMessage()
    }

    function selectConfigMessage(configMessages, sectionName) {
        let sectionMessage
        if (configMessages?.length !== 0) {
            sectionMessage = configMessages?.find(({ section }) => section === sectionName)
        }
        return sectionMessage?.message
    }

    function handleCloseEditForContact() {
        let contactRes = contacts?.find(({ objectID }) => objectID === selectedContact?.objectID)
        handleGetSingleContactDetails(brand, franchise, contactRes, setSelectedContactDetails, setEditContact)
        setIsEditContact(false)
    }

    function handleCloseEditLocation() {
        handleGetSingleLoctionDetails(brand, franchise, selectedLocation, setSelectedLocationDetails, setEditLocation)
        setIsEditLocation(false)
    }

    async function updateExistingJob() {
        const body = {
            franchise_id: franchise.franchise_id,
            zip_code: urlParams.get("zipCode"),
            call_type: parseInt(urlParams.get("call_type"))
        }

        const jobId = parseInt(urlParams.get("jobId"))
        const jobtype = urlParams.get("type")

        await updateJob(jobId, jobtype, body).then((res) => {
            showNotification({ message: "The call details updated successfully", type: NOTIFICATION_TYPES.SUCCESS })
        }).catch((err) => {
            showNotification({ message: "Unable to update call details at this time", type: NOTIFICATION_TYPES.ERROR })
        }).finally(() => {
            removeCallDetailsFromLocalStorage()
        })
    }

    async function mapCallWithJobLead(vonigoJobId) {
        const body = {
            call_id: parseInt(urlParams.get("jobId")),
            vonigo_job_id: parseInt(vonigoJobId),
            franchise_id: franchise?.id,
            brand_id: brand?.brand_id,
        }

        await mapCallJobLead(body).then((res) => {
        }).catch((err) => {
            showNotification({ message: "Unable to map the call and the job", type: NOTIFICATION_TYPES.ERROR })
        })
    }

    return <Box id={CREATE_JOB_SECTION_IDS.MAIN} sx={{ display: "flex", flexDirection: "column", flex: "1 2 0", maxWidth: "100%", overflowX: "hidden", overflowY: "scroll", paddingRight: "1em" }} >

        <form onSubmit={handleCreateJob} >
            <Prompt
                when={Boolean(shouldPrompt) ?? false}
                message={getUnlockTimeAndGetPromptMessage}
            />
            {!isCreateMultipleWorkOrders ?
                <Box id={CREATE_JOB_SECTION_IDS.CUSTOMER} sx={{ display: 'flex', flexDirection: "row", width: "100%", height: "3em", justifyContent: "left", alignItems: "center", paddingTop: "1em", marginBottom: "0.5em" }}>
                    <h4>Customer</h4>
                    {isNewCustomer || selectedDraftToContinue || customerSearchCount > 0 ? <Tooltip enterDelay={1000} enterTouchDelay={1000} title={isNewCustomer ? "Create job for an existing customer" : "Create job for a new customer"}><Button sx={{ color: "#00a79d" }} variant="text" onClick={handleCustomerToggle}>{isNewCustomer ? " Existing" : "\u002B New"}</Button></Tooltip> : <></>}
                    {isNewCustomer ? <></>
                        : <VonigoCustomerSearchSection
                            isDisabled={isDisabled}
                            searchPar={searchPar}
                            onSearchParChanged={handleOnSearchParChanged}
                            isSearchResultCutomersLoading={isCustomersLoading}
                            onSearch={() => handleSearchCustomers(brand, franchise, searchPar, setIsCustomersLoading, setSelectedCustomer, setCustomers, setSelectedContact, setContacts, setSelectedLocation, setLocations, customerSearchCount, setCustomerSearchCount, setEditContact, setEditLocation, setIsEditContact, setIsEditLocation)}
                        />}
                    {configMessages.length !== 0 && !isNewCustomer ? <Box className="messageBox" sx={{ marginLeft: "auto" }}><p>{selectConfigMessage(configMessages, "existingCustomer")}</p></Box> : <></>}
                    {configMessages.length !== 0 && isNewCustomer ? <Box className="messageBox" sx={{ marginLeft: "auto" }}><p>{selectConfigMessage(configMessages, "newCustomer")}</p></Box> : <></>}
                </Box>
                : <></>
            }
            {isCustomersLoading ? <></> : isNewCustomer ? <></> : customerSearchCount > 0 ? <CustomerSearchMessage customers={customers} /> : <></>}
            {isCreateMultipleWorkOrders ? <MultipleWorkOrdersCustomerDetails brand={brand} franchise={franchise} customer={selectedCustomer} isDisabled={true} /> : <></>}
            {!isCreateMultipleWorkOrders ? <CustomerDetailsSection isNewCustomer={isNewCustomer} setEditContact={setEditContact} setEditLocation={setEditLocation} brand={brand} franchise={franchise} selectedDraftToContinue={selectedDraftToContinue} customer={customer} setCustomer={onCustomerChanged} customers={customers} isCustomersLoading={isCustomersLoading} selectedCustomer={selectedCustomer} setSelectedCustomer={handleOnSelectedCustomerChange} /> : <></>}
            {!isNewCustomer ? <CustomerNoteSection brand={brand} franchise={franchise} showNotification={showNotification} selectedCustomer={selectedCustomer} /> : <></>}
            <ContactDetailsSection brand={brand} selectedContactDetails={selectedContactDetails} setSelectedContactDetails={setSelectedContactDetails} franchise={franchise} handleCloseEditForContact={handleCloseEditForContact} selectedDraftToContinue={selectedDraftToContinue} isEditContact={isEditContact} setIsEditContact={setIsEditContact} isCreateMultipleWorkOrders={isCreateMultipleWorkOrders} selectConfigMessage={selectConfigMessage} configMessages={configMessages} customerSearchCount={customerSearchCount} isNewCustomer={isNewCustomer} editContact={editContact} contactEditCount={contactEditCount} setContactEditCount={setContactEditCount}
                setEditContact={setEditContact} customer={customer} isNewContact={isExistingCustomerNewContact} setIsNewContact={setIsExistingCustomerNewContact} contact={contact} setContact={setContact} contacts={contacts} setContacts={setContacts} selectedContact={selectedContact} setSelectedContact={setSelectedContact} isContactsLoading={isContactsLoading} selectedCustomer={selectedCustomer} showNotification={showNotification} />
            <LocationDetailsSection brand={brand} handleCloseEditLocation={handleCloseEditLocation} isEditLocation={isEditLocation} selectedDraftToContinue={selectedDraftToContinue} isNewLocationForExistingCustomer={isNewLocationForExistingCustomer} setIsEditLocation={setIsEditLocation} isCreateMultipleWorkOrders={isCreateMultipleWorkOrders} selectConfigMessage={selectConfigMessage} configMessages={configMessages} locationEditCount={locationEditCount} setLocationEditCount={setLocationEditCount} franchise={franchise} customerSearchCount={customerSearchCount} isNewCustomer={isNewCustomer} isNewLocation={isNewLocationForExistingCustomer} setIsNewLocation={setIsNewLocationForExistingCustomer} location={location} setLocation={setLocation} selectedCustomer={selectedCustomer}
                locations={locations} editLocation={editLocation} setEditLocation={setEditLocation} isLocationsLoading={isLocationsLoading} selectedLocation={selectedLocation} setSelectedLocation={setSelectedLocation} serviceableZipCodeResponse={showingServiceableZipCodeResponse} selectedLocationDetails={selectedLocationDetails} setSelectedLocationDetails={setSelectedLocationDetails} showNotification={showNotification} />
            {isCreateMultipleWorkOrders ? <></> : <JobDetailsSection brand={brand} franchise={franchise} selectedDraftToContinue={selectedDraftToContinue} setJob={setJob} setWorkOrderSummary={onWorkOrderSummaryChanged} />}
            {isCreateMultipleWorkOrders ? <MultipleWorkOrdersJobDetails brand={brand} franchise={franchise} existingJob={selectedJobToView} /> : <></>}
            <CreateWorkOrderSection brand={brand} selectConfigMessage={selectConfigMessage} configMessages={configMessages} isNewCustomer={isNewCustomer} franchise={franchise} editLocation={editLocation} serviceableZipCodeResponse={serviceableZipCodeResponse} showNotification={showNotification} client={true} selectedLocation={selectedLocation} workOrderToReschedule={workOrderToReschedule}
                isRescheduleWorkOrder={isRescheduleWorkOrder} isNewLocation={isNewLocationForExistingCustomer} location={location} contact={contact} existingWorkOrder={undefined} workOrder={workOrder} onWorkOrderChanged={setWorkOrder} setDidWorkOrderSummaryEdited={setDidWorkOrderSummaryEdited} toggleAvailability={undefined} displayId={displayId} setDisplayId={setDisplayId} />
            <DialogFooter className={'dialog-footer'}>
                <Button size={"small"} variant="contained" onClick={!isCreateMultipleWorkOrders ? handleSaveDraft : handleRescheduleClose} className={"btn-secondary"}>Cancel</Button>
                <LoadingButton sx={{ flex: "0 0 12em" }} type={"submit"} size={"small"} variant="contained" disabled={isCreateJobLoading || ((isEditContact || isEditLocation) && !isNewCustomer)} loading={isCreateJobLoading} className={isCreateJobLoading || ((isEditContact || isEditLocation) && !isNewCustomer) ? "btn-disable" : "btn-primary"} >
                    {isCreateMultipleWorkOrders ? "Create Work Order" : "Create Job"}
                </LoadingButton>
            </DialogFooter>
        </form >
    </Box >
}

async function saveDraft(createDraftRequest) {
    let draftResponse;
    try {
        draftResponse = await createVonigoJobDraft(createDraftRequest)
        return draftResponse;
    } catch (err) {
        throw err
    }
}

async function updateDraft(updateDraftRequest) {
    let draftResponse;
    try {
        draftResponse = await updateVonigoJobDraft(updateDraftRequest)
        return draftResponse;
    } catch (err) {
        throw err
    }
}

function getFullDraftRequest(brand, franchise, searchPar, callerId, zipCode, isNewCustomer, isNewLocation, isNewContact, customer, selectedCustomer, location, selectedLocation, contact, selectedContact, job, workOrder, isServiceableZipCode, phoneNumbers) {
    const createCustomerRequest = getCreateCustomerRequest(brand, franchise, customer, contact)
    const craeteLocationRequest = isNewCustomer ? getLocationRequest(brand, franchise, undefined, location) : getLocationRequest(brand, franchise, selectedCustomer, location)
    const createContactRequest = isNewCustomer ? getDraftContactRequest(brand, franchise, contact, phoneNumbers, undefined) : getDraftContactRequest(brand, franchise, contact, phoneNumbers, selectedCustomer)
    const createJobRequest = isNewCustomer ? getCreateJobRequest(brand, franchise, undefined, job) : getCreateJobRequest(brand, franchise, selectedCustomer, job)
    const createWorkOrderRequest = getCreateWorkOrderRequest(brand, franchise, job, selectedCustomer, selectedLocation, selectedContact, workOrder)
    const existingCustomerTypeOptionId = selectedCustomer?.typeOptionId?.optionID
    createWorkOrderRequest.lockId = undefined
    return getCreateDraftRequest(brand, franchise, franchise?.service_brand, searchPar, callerId, zipCode, isNewCustomer, isNewLocation, isNewContact, createCustomerRequest, craeteLocationRequest, createContactRequest, createJobRequest, createWorkOrderRequest, existingCustomerTypeOptionId, isServiceableZipCode)
}

function getCreateCustomerRequest(brand, franchise, customer, contact) {
    let residentialCustomerId = "59"
    let commercialCustomerId = "60"
    let repaintOfficeSpace = "49"
    let customerRequest = {
        ...customer,
        franchiseId: franchise?.franchise_id,
        baseUrl: brand?.redirect_url
    }
    if (customer?.typeOptionId === residentialCustomerId) {
        customerRequest.name = contact?.firstName + contact?.lastName
    }
    if (customer?.typeOptionId === commercialCustomerId) {
        customerRequest.industryOptionId = repaintOfficeSpace
    }
    return customerRequest
}

function getCreateDraftRequest(brand, franchise, crm, searchPar, callerId, zipCode, isNewCustomer, isNewLocation, isNewContact, createCustomerRequest, craeteLocationRequest, createContactRequest, createJobRequest, createWorkOrderRequest, existingCustomerTypeOptionId, isWithinServiceArea) {
    const trimedCrm = crm?.trim?.()
    return {
        brandId: brand?.id,
        franchiseId: franchise?.id,
        crm: trimedCrm,
        searchPar: searchPar,
        callerId: callerId,
        zipCode: zipCode,
        isNewCustomer: isNewCustomer,
        isNewLocation: isNewLocation,
        isNewContact: isNewContact,
        clientCreateRequest: createCustomerRequest,
        frontEndLocationRequest: craeteLocationRequest,
        frontEndContactRequest: createContactRequest,
        frontEndJobRequest: createJobRequest,
        frontEndCreateWorkOrderRequest: createWorkOrderRequest,
        existingCustomerTypeOptionId: existingCustomerTypeOptionId,
        isWithinServiceArea: isWithinServiceArea,
        isDraft: true
    }
}

function getDraftContactRequest(brand, franchise, contact, phoneNumbers, customer) {
    let contactCreateRequest = {
        firstName: contact?.firstName,
        lastName: contact?.lastName,
        email: contact?.email,
        baseUrl: brand?.redirect_url,
        franchiseId: franchise?.franchise_id,
        clientId: parseInt(customer?.objectID, 10),
    }
    if (contact?.phone1) {
        contactCreateRequest.phone1 = contact?.phone1
        contactCreateRequest.ext1 = contact?.ext1
    }
    if (contact?.phone2) {
        contactCreateRequest.phone2 = contact?.phone2
        contactCreateRequest.ext2 = contact?.ext2
    }
    if (contact?.phone3) {
        contactCreateRequest.phone3 = contact?.phone3
        contactCreateRequest.ext3 = contact?.ext3
    }
    return contactCreateRequest
}

function getCreateJobRequest(brand, franchise, customer, job) {
    let estimateScheduleOptionId = 10027
    return {
        baseUrl: brand?.redirect_url,
        serviceTypeOptionId: brand.brand_id,
        franchiseId: franchise?.franchise_id,
        clientId: customer?.objectID,
        summary: job?.summary,
        labelOptionId: estimateScheduleOptionId
    }
}

function getCreateWorkOrderRequest(brand, franchise, job, customer, location, contact, workOrder, selectedJobToView, isRescheduleWorkOrder) {
    return {
        baseUrl: brand?.redirect_url,
        serviceTypeId: `${brand?.brand_id}`,
        franchiseId: franchise?.franchise_id,
        jobId: isRescheduleWorkOrder ? job?.objectID : selectedJobToView ? selectedJobToView?.objectID : job?.objectID,
        lockId: workOrder?.lockId,
        clientId: isRescheduleWorkOrder ? customer?.objectID : selectedJobToView ? `${selectedJobToView?.client?.objectID}` : customer?.objectID,
        contactId: contact?.objectID,
        locationId: location?.objectID,
        duration: `${workOrder?.duration}`,
        summary: selectedJobToView ? selectedJobToView?.summary?.fieldValue : workOrder?.summary,
    }
}

async function createNewJob(brand, franchise, isNewCustomer, customer, selectedCustomer, isNewLocation, location, selectedLocation, isNewContact, contact, selectedContact, job, workOrder, createdCustomer, setCreatedCustomer, createdLocation, setCreatedLocation, createdContact, setCreatedContact, createdJob, setCreatedJob, selectedDraftToContinue, isCreateMultipleWorkOrders, setIsCreateMultipleWorkOrders, setSelectedJobToView, selectedJobToView, showNotification, serviceableZipCodeResponse, isRescheduleWorkOrder, setIsRescheduleWorkOrder, phoneNumbers) {
    try {
        if (workOrder?.lockId && serviceableZipCodeResponse?.isServiceable) {
            let customerResponse = createdCustomer;
            let locationResponse = createdLocation;
            let contactResponse = createdContact;
            let jobResponse = createdJob
            if (isNewCustomer && !createdCustomer && !isCreateMultipleWorkOrders) {
                const createCustomerRequest = getCreateCustomerRequest(brand, franchise, customer, contact)
                customerResponse = await createCustomer(createCustomerRequest)
                setCreatedCustomer(customerResponse)
            }
            const customerToProceed = isNewCustomer ? customerResponse : selectedCustomer
            if ((isNewLocation || isNewCustomer) && !createdLocation) {
                const locationCreateRequest = getLocationRequest(brand, franchise, customerToProceed, location)
                locationResponse = await createLocation(locationCreateRequest)
                setCreatedLocation(locationResponse)
            }
            const locationToProceed = (isNewLocation || isNewCustomer) ? locationResponse : selectedLocation
            if ((isNewCustomer || isNewContact) && !createdContact) {
                let contactCreateRequest = {
                    firstName: contact?.firstName,
                    lastName: contact?.lastName,
                    email: contact?.email,
                    baseUrl: brand?.redirect_url,
                    franchiseId: franchise?.franchise_id,
                    clientId: parseInt(customerToProceed?.objectID, 10),
                }
                let phoneNumber1
                let phoneNumber2
                let phoneNumber3
                if (contact?.phone1 && contact?.ext1) {
                    phoneNumber1 = contact?.phone1 + contact?.ext1
                } else {
                    phoneNumber1 = contact?.phone1
                }
                if (contact?.phone2 && contact?.ext2) {
                    phoneNumber2 = contact?.phone2 + contact?.ext2
                } else {
                    phoneNumber2 = contact?.phone2
                }
                if (contact?.phone3 && contact?.ext3) {
                    phoneNumber3 = contact?.phone3 + contact?.ext3
                } else {
                    phoneNumber3 = contact?.phone3
                }
                if (contact?.phone1) {
                    contactCreateRequest.phone1 = phoneNumber1
                }
                if (contact?.phone2) {
                    contactCreateRequest.phone2 = phoneNumber2
                }
                if (contact?.phone3) {
                    contactCreateRequest.phone3 = phoneNumber3
                }
                contactResponse = await createContact(contactCreateRequest)
                setCreatedContact(contactResponse)
            }
            const contactToProceed = (isNewCustomer || isNewContact) ? contactResponse : selectedContact
            if (!createdJob && !isCreateMultipleWorkOrders) {
                const jobCreateRequest = getCreateJobRequest(brand, franchise, customerToProceed, job)
                jobResponse = await createJob?.(jobCreateRequest)
                setCreatedJob(jobResponse)
            }
            if (isCreateMultipleWorkOrders) {
                jobResponse = selectedJobToView
                setCreatedJob(jobResponse)
            }
            const createWorkOrderRequest = getCreateWorkOrderRequest(brand, franchise, jobResponse, customerToProceed, locationToProceed, contactToProceed, workOrder, selectedJobToView, isRescheduleWorkOrder)
            await createWorkOrder(createWorkOrderRequest)
            if (selectedDraftToContinue) {
                await deleteVonigoJobDraft(selectedDraftToContinue?.id)
            }
            setIsRescheduleWorkOrder(false)
            setIsCreateMultipleWorkOrders(false)
            return jobResponse
        }
        else {
            showNotification({ message: "Please Select Time", type: NOTIFICATION_TYPES.ERROR })
        }
    } catch { }
}

function getShouldCreateDraft(searchPar, customer, selectedCustomer, location, selectedLocation, contact, selectedContact, job, workOrder) {
    return (searchPar || selectedCustomer || getIsCustomerSufficientToDraft(customer) || selectedLocation || getIsLocationSufficientToDraft(location) || selectedContact || getIsContactsSufficientToDraft(contact) || getIsJobSufficentToDraft(job) || getIsWorkOrderSufficientToDraft(workOrder))
}

function getIsCustomerSufficientToDraft(customer) {
    return customer?.name || customer?.industryOptionId || customer?.marketingCampaignOptionId || customer?.phone || customer?.email
}

function getIsLocationSufficientToDraft(location) {
    return location?.name || location?.address?.street || location?.address?.unit || location?.address?.city || location?.address?.state || location?.address?.city || location?.address?.zip || location?.address?.country
}

function getIsContactsSufficientToDraft(contact) {
    return contact?.firstName || contact?.lastName || contact?.email || contact?.phone1 || contact?.phone2 || contact?.phone3
}

function getIsJobSufficentToDraft(job) {
    return job?.summary || job?.labelOptionId
}

function getIsWorkOrderSufficientToDraft(workOrder) {
    return workOrder?.summary || workOrder?.lockId
}

function getShouldUpdateDraft(oldDraft, newDraft) {
    const isDifferentSearchPar = oldDraft?.searchPar !== newDraft?.searchPar
    const isDifferentFlow = oldDraft?.isNewCustomer !== newDraft?.isNewCustomer || oldDraft?.isNewLocation !== newDraft?.isNewLocation || oldDraft?.isNewContact !== newDraft?.isNewContact
    const isDifferentCustomerData = getIsDifferentNewCustomerDetails(oldDraft?.clientCreateRequest, newDraft?.clientCreateRequest) || getIsDifferentExistingCustomer(oldDraft?.frontEndCreateWorkOrderRequest?.clientId, newDraft?.frontEndCreateWorkOrderRequest?.clientId ?? "")
    const isDifferentLocationData = getIsDifferentNewLocationDetails(oldDraft?.frontEndLocationRequest, newDraft?.frontEndLocationRequest) || getIsDifferentExistingLocation(oldDraft?.frontEndCreateWorkOrderRequest?.locationId, newDraft?.frontEndCreateWorkOrderRequest?.locationId ?? "") || oldDraft?.isWithinServiceArea !== newDraft?.isWithinServiceArea
    const isDifferentContactData = getIsDifferentNewContactDetails(oldDraft?.frontEndContactRequest, newDraft?.frontEndContactRequest) || getIsDifferentExistingContact(oldDraft?.frontEndCreateWorkOrderRequest?.contactId, newDraft?.frontEndCreateWorkOrderRequest?.contactId ?? "")
    const isDifferentJobData = getIsDifferentJobDetails(oldDraft?.frontEndJobRequest, newDraft?.frontEndJobRequest)
    const isDifferentAppointmentData = getIsDifferentWorkOrderDetails(oldDraft?.frontEndCreateWorkOrderRequest, newDraft?.frontEndCreateWorkOrderRequest)
    const shouldUpdateDraft = isDifferentSearchPar || isDifferentFlow || isDifferentCustomerData || isDifferentLocationData || isDifferentContactData || isDifferentJobData || isDifferentAppointmentData
    return shouldUpdateDraft
}

function getIsDifferentNewCustomerDetails(oldCus, newCus) {
    return oldCus?.baseUrl !== newCus?.baseUrl || oldCus?.name !== newCus?.name || oldCus?.email !== newCus?.email || oldCus?.franchiseId !== newCus?.franchiseId || oldCus?.industryOptionId !== newCus?.industryOptionId || oldCus?.marketingCampaignOptionId !== newCus?.marketingCampaignOptionId || oldCus?.typeOptionId !== newCus?.typeOptionId || oldCus?.phone !== newCus?.phone
}

function getIsDifferentExistingCustomer(oldCustomerId, newCustomerId) {
    return oldCustomerId !== newCustomerId
}

function getIsDifferentNewLocationDetails(oldLoc, newLoc) {
    return oldLoc?.location?.name !== newLoc?.location?.name || getIsDifferentAddresses(oldLoc?.location?.address, newLoc?.location?.address)
}

function getIsDifferentAddresses(address1, address2) {
    return address1?.street !== address2?.street || address1?.unit !== address2?.unit || address1?.city !== address2?.city || address1?.state !== address2?.state || address1?.zip !== address2?.zip || address1?.country !== address2?.country
}

function getIsDifferentExistingLocation(oldLocationId, newLocationId) {
    return oldLocationId !== newLocationId
}

function getIsDifferentNewContactDetails(oldCon, newCon) {
    return oldCon?.firstName !== newCon?.firstName || oldCon?.lastName !== newCon?.lastName || oldCon?.phone1 !== newCon?.phone1 || oldCon?.phone2 !== newCon?.phone2 || oldCon?.phone3 !== newCon?.phone3 || oldCon?.email !== newCon?.email
}

function getIsDifferentExistingContact(oldContactId, newContactId) {
    return oldContactId !== newContactId
}

function getIsDifferentJobDetails(oldJob, newJob) {
    return (oldJob?.labelOptionId !== (newJob?.labelOptionId ?? 0)) || (oldJob?.summary !== (newJob?.summary ?? ""))
}

function getIsDifferentWorkOrderDetails(oldWo, newWo) {
    return oldWo?.clientId !== (newWo?.clientId ?? "") || oldWo?.summary !== (newWo?.summary ?? "") || newWo?.lockId || oldWo?.duration !== newWo?.duration
}

function getInitialCustomer(selectedDraftToContinue) {
    const selectedTypeId = selectedDraftToContinue?.clientCreateRequest?.typeOptionId
    let selectedType;
    if (selectedTypeId) {
        selectedType = Object?.values(CUSTOMER_TYPES).find((t) => t.optionId === selectedTypeId)
    } else {
        selectedType = CUSTOMER_TYPES.RESIDENTIAL
    }
    const phone = selectedDraftToContinue?.clientCreateRequest?.phone
    const email = selectedDraftToContinue?.clientCreateRequest?.email
    return { ...selectedDraftToContinue?.clientCreateRequest, phone: phone, email: email, type: selectedType?.name, typeOptionId: selectedType?.optionId }
}

function getZipCodeForIsServiceableZipCode(isNewCustomer, isNewLocationForExistingCustomer, newLocationZip, existingLocationZip) {
    return isNewCustomer || isNewLocationForExistingCustomer ? newLocationZip : existingLocationZip
}

function getCountryForIsServiceableZipCode(isNewCustomer, isNewLocationForExistingCustomer, newCountry, existingCountry) {
    return isNewCustomer || isNewLocationForExistingCustomer ? newCountry : getCountryValueFromVonigoCountryFieldValue(existingCountry)
}

const US = "US"
const CANADA = "CA"
export function getCountryValueFromVonigoCountryFieldValue(fieldValue) {
    switch (fieldValue) {
        case US: return "United States"
        case CANADA: return COUNTRIES[1]
    }
}

function removeCallDetailsFromLocalStorage() {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.media)
    localStorage.removeItem(LOCAL_STORAGE_KEYS.channelName)
    localStorage.removeItem(LOCAL_STORAGE_KEYS.channelId)
    localStorage.removeItem(LOCAL_STORAGE_KEYS.callerId)
    localStorage.removeItem(LOCAL_STORAGE_KEYS.zipCode)
    localStorage.removeItem(LOCAL_STORAGE_KEYS.brandId)
    localStorage.removeItem(LOCAL_STORAGE_KEYS.jobId)
    localStorage.removeItem(LOCAL_STORAGE_KEYS.brandPrimaryId)
}