import moment from "moment";
import { getTimeWithTimeZone } from "../../../../components/CustomDatePicker";

//Data section reports
export function HandleDownloadDataReport(data, selectedDownloadReportTab, REPORT_TABLES, filtration) {
  let processedData = [];
  let reportName = "data";
  let reportTitle = "Data Report";

  if (selectedDownloadReportTab === REPORT_TABLES.PHONE.title) {
    processedData = formatterOfPhoneData(data, filtration);
    reportName = "Phone Data";
    reportTitle = reportTitleGenerator(reportName, filtration)
    reportName = reportNameGenerator(reportName, filtration)
  } else if (selectedDownloadReportTab === REPORT_TABLES.SMS.title) {
    processedData = formatterOfSmsData(data, filtration);
    reportName = "SMS Data";
    reportTitle = reportTitleGenerator(reportName, filtration)
    reportName = reportNameGenerator(reportName, filtration)
  } else if (selectedDownloadReportTab === REPORT_TABLES.EMAIL.title) {
    processedData = formatterOfEmailData(data);
    reportName = "Report of Email Data";
    reportTitle = " Report of Email Data";
  } else if (selectedDownloadReportTab === REPORT_TABLES.CHAT.title) {
    processedData = formatterOfChatData(data);
    reportName = "Report of Chat Data";
    reportTitle = " Report of Chat Data";
  }

  let startDate = getTimeWithTimeZone(filtration.startDate, "America/Montreal")
  let endDate = getTimeWithTimeZone(filtration.endDate, "America/Montreal")

  // Build CSV content
  let csvContent = "data:text/csv;charset=utf-8,";

  // Insert topic line before headers
  csvContent += `Report Title: ${reportTitle}\r\n`;
  csvContent += `Start Date : ${moment(startDate).format("MM/DD/YYYY")}\r\n`;
  csvContent += `End Date : ${moment(endDate).format("MM/DD/YYYY")}\r\n`;

  if (!(selectedDownloadReportTab === REPORT_TABLES.EMAIL.title || selectedDownloadReportTab === REPORT_TABLES.CHAT.title)) {
    let brandName = ''
    if (filtration.brandId) {
      brandName = wrapIfNecessary(filtration.brandName ?? "All Brands");
    } else {
      brandName = "All Brands";
    }
    csvContent += `Brand: ${brandName}\r\n`;

    let franchiseName = "All Franchises";
    if (filtration.franchise?.franchise_name) {
      franchiseName = wrapIfNecessary(filtration.franchise.franchise_name);
    }
    csvContent += `Franchise: ${franchiseName}\r\n`;
  }
  csvContent += "\r\n\r\n";
  const headers = processedData.length > 0 ? Object.keys(processedData[0]) : [];
  csvContent += headers.join(",") + "\r\n";
  csvContent += "\r\n";

  //data rows
  if (processedData.length > 0) {
    processedData.forEach((obj) => {
      const row = headers.map((header) => {
        let value = obj[header];
        if (typeof value === "string") {
          if (value.includes(",") || value.includes(":")) {
            value = `"${value}"`;
          }
        }
        return value;
      });
      const rowString = row.join(",");
      csvContent += rowString + "\r\n";
    });
  } else {
    csvContent += "No data to display\r\n";
  }
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", reportName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

// Function to wrap fields with special characters and escape problematic characters
function wrapIfNecessary(value) {
  if (typeof value !== 'string') {
    return value;
  }
  value = value.replace(/\t/g, ' ');
  value = value.replace(/,/g, ' ');
  value = value.replace(/"/g, '""');

  if (value.includes(",") || value.includes(":") || value.includes("|")) {
    return `${value}`;
  }
  return value;
}

function reportNameGenerator(reportName, filtration) {
  if (filtration.brandId && filtration.franchiseId && filtration.channelId) {
    reportName += " Filter By Brand,Franchise And Channel";
  } else if (filtration?.brandId && filtration?.franchiseId) {
    reportName += " Filter By Brand And Franchise";
  } else if (filtration?.brandId) {
    reportName += " Filter By Brand";
  } else if (filtration?.franchiseId) {
    reportName += " Filter By Franchise";
  }
  reportName += ".csv";
  return reportName;
}

function reportTitleGenerator(reportName, filtration) {
  let reportTitle = `Report Of ${reportName}`;
  if (filtration.brandId && filtration.franchiseId && filtration.channelId) {
    reportName += " Filter By Brand, Franchise And Channel";
  } else if (filtration?.brandId && filtration?.franchiseId) {
    reportTitle += " Filter By Brand And Franchise";
  } else if (filtration?.brandId) {
    reportTitle += " Filter By Brand";
  } else if (filtration?.franchiseId) {
    reportTitle += " Filter By Franchise";
  }
  if (filtration.brandId && filtration.franchiseId && filtration.channelId) {
    reportTitle += " Filter By Brand, Franchise And Channel";
  }
  return reportTitle;
}

function formatterOfPhoneData(data, filtration) {
  let formattedData = [];
  data?.inbound_data?.forEach((d) => {
    let formattedItem = {};
    if (!(filtration?.brandId)) {
      formattedItem['Brand'] = d.brand_name || 'Unknown Brand';
    }
    formattedItem['Franchise'] = filtration?.brandId ? d.franchise_name : 'ALL';
    formattedItem['Channel'] = filtration?.franchiseId ? d.channel : 'ALL';
    formattedItem['Accepted'] = d.inbound.accepted;
    formattedItem['Abandoned'] = d.inbound.abandoned;
    formattedItem['Rejected'] = d.inbound.rejected;
    formattedItem['Rejected_Timeout'] = d.inbound.rejected_timeout;
    formattedItem['Hold_Count'] = d.inbound.hold;
    formattedItem['Avg_Hold_Time'] = d.inbound.hold_time;
    formattedItem['Avg_Handle_Time'] = d.inbound.handle_time;
    formattedItem['Avg_Speed_To_Answer'] = d.inbound.speed_to_answer;
    formattedData.push(formattedItem);
  });
  return formattedData;
}

function formatterOfSmsData(data, filtration) {
  let formattedData = [];
  data?.data?.forEach((d) => {
    let formattedItem = {};
    if (!(filtration?.brandId)) {
      formattedItem['Brand'] = d.brand_name || 'Unknown Brand';
    }
    formattedItem['Franchise'] = filtration?.brandId ? d.franchise_name : 'ALL';
    formattedItem['Channel'] = filtration?.franchiseId ? d.channel : 'ALL';
    formattedItem['Accepted'] = d.accepted;
    formattedItem['Abandoned'] = d.abandoned;
    formattedItem['Rejected'] = d.rejected;
    formattedItem['Rejected_Timeout'] = d.rejected_timeout;
    formattedItem['Hold_Count'] = d.hold;
    formattedItem['Avg_Hold_Time'] = d.hold_time;
    formattedItem['Avg_Handle_Time'] = d.handle_time;
    formattedItem['Avg_Speed_To_Answer'] = d.speed_to_answer;
    formattedData.push(formattedItem);
  });
  return formattedData;
}

function formatterOfEmailData(data) {
  let formattedData = []
  data?.data?.forEach((d, i) => {
    formattedData.push({
      Brand: d?.brand_name,
      Accepted: d.accepted,
      Abandoned: d.abandoned,
      Rejected: d.rejected,
      Rejected_Timeout: d.rejected_timeout,
      Hold_Count: d.hold,
      Avg_Hold_Time: d.hold_time,
      Avg_Handle_Time: d.handle_time,
      Avg_Speed_To_Answer: d.speed_to_answer,
    })
  })
  return formattedData
}

function formatterOfChatData(data) {
  let formattedData = []
  data?.data?.forEach((d, i) => {
    formattedData.push({
      Brand: d?.brand_name,
      Accepted: d.accepted,
      Abandoned: d.abandoned,
      Rejected: d.rejected,
      Rejected_Timeout: d.rejected_timeout,
      Hold_Count: d.hold,
      Avg_Hold_Time: d.hold_time,
      Avg_Handle_Time: d.handle_time,
      Avg_Speed_To_Answer: d.speed_to_answer,
    })
  })
  return formattedData
}

//Statistics section reports
export function HandleDownloadStatisticsReport(data, selectedDownloadReportTab, STATISTICS_SECTIONS, filtration) {
  if (selectedDownloadReportTab === STATISTICS_SECTIONS.EIGHT_BY_.title) {
    HandleDownloadStatisticsReportEightByEight(data, selectedDownloadReportTab, STATISTICS_SECTIONS, filtration);
  } else if (selectedDownloadReportTab === STATISTICS_SECTIONS.CRM.title) {
    HandleDownloadStatisticsReportCRM(data, selectedDownloadReportTab, STATISTICS_SECTIONS, filtration);
  }
}

function HandleDownloadStatisticsReportEightByEight(data, selectedDownloadReportTab, STATISTICS_SECTIONS, filtration) {
  let processedData = [];
  let reportName = "statistics";
  let reportTitle = "Statistics Report";

  processedData = formatterOfEightByEightData(data, filtration);
  reportName = "EightByEight Data";

  reportTitle = reportTitleGenerator(reportName, filtration);
  reportName = reportNameGenerator(reportName, filtration);

  let startDate = getTimeWithTimeZone(filtration.startDate, "America/Montreal")
  let endDate = getTimeWithTimeZone(filtration.endDate, "America/Montreal")

  let csvContent = "data:text/csv;charset=utf-8,";

  csvContent += `Report Title: ${reportTitle}\r\n`;
  csvContent += `Start Date : ${moment(startDate).format("MM/DD/YYYY")}\r\n`;
  csvContent += `End Date : ${moment(endDate).format("MM/DD/YYYY")}\r\n`;

  let brandName = ''
  if (filtration.brandId) {
    brandName = wrapIfNecessary(filtration.brandName ?? "All Brands");
  } else {
    brandName = "All Brands";
  }
  csvContent += `Brand: ${brandName}\r\n`;

  let franchiseName = "All Franchises";
  if (filtration.franchise?.franchise_name) {
    franchiseName = wrapIfNecessary(filtration.franchise.franchise_name);
  }
  csvContent += `Franchise: ${franchiseName}\r\n`;

  let channelId = 'All Channels'
  if (filtration.franchiseId) {
    if (filtration.channelId) {
      channelId = wrapIfNecessary(filtration.channelId);
    }
    csvContent += `Channel: ${channelId}\r\n`;
  }
  csvContent += "\r\n\r\n";

  if (processedData.length > 0) {
    const headers = ["Category", "Accepted", "Abandoned", "Rejected", "Rejected_Timeout", "Alert", "Hold_Count", "Avg_Handle_Time", "Avg_Hold_Time", "Avg_Speed_To_Answer"];
    csvContent += headers.join(",") + "\r\n";

    processedData.forEach((item) => {
      const row = Object.values(item).map((value) => {
        if (typeof value === "string") {
          if (value.includes(",") || value.includes(":")) {
            value = `"${value}"`;
          }
        }
        return value;
      });
      csvContent += row.join(",") + "\r\n";
    });

  } else {
    csvContent += "No data to display\r\n";
  }
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", reportName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function formatterOfEightByEightData(data, filtration) {
  const formattedData = [];

  function createFormattedItem(data, category) {
    return {
      Category: category,
      Accepted: data.accepted ?? 0,
      Abandoned: data.abandoned ?? 0,
      Rejected: data.rejected ?? 0,
      Rejected_Timeout: data.rejected_timeout ?? 0,
      Alert: data.alert ?? 0,
      Hold_Count: data.hold ?? 0,
      Avg_Handle_Time: data.handle_time.trim() === "" ? "00:00:00" : data.handle_time ?? 0,
      Avg_Hold_Time: data.hold_time.trim() === "" ? "00:00:00" : data.handle_time ?? 0,
      Avg_Speed_To_Answer: data.speed_to_answer.trim() === "" ? "00:00:00" : data.handle_time ?? 0,
    };
  }

  // Process Phone data
  const phoneStats = data.phoneStats ?? {};

  // Inbound
  const phoneInbound = phoneStats.inbound_data.inbound ?? {};
  if (Object.keys(phoneInbound).length > 0) {
    formattedData.push(createFormattedItem(phoneInbound, "Phone Inbound"));
  }

  // Outbound
  const phoneOutbound = phoneStats.outbound_data ?? {};
  if (Object.keys(phoneOutbound).length > 0) {
    formattedData.push(createFormattedItem(phoneOutbound, "Phone Outbound"));
  }

  // Callback
  const phoneCallback = phoneStats.inbound_data?.callback ?? {};
  if (Object.keys(phoneCallback).length > 0) {
    formattedData.push(createFormattedItem(phoneCallback, "Phone Callback"));
  }

  // Voicemail
  const phoneVoicemail = phoneStats.inbound_data?.voicemail ?? {};
  if (Object.keys(phoneVoicemail).length > 0) {
    formattedData.push(createFormattedItem(phoneVoicemail, "Phone Voicemail"));
  }

  // Process SMS data
  const smsStats = data.smsStats ?? {};
  if (Object.keys(smsStats).length > 0) {
    formattedData.push(createFormattedItem(smsStats, "SMS"));
  }

  // Process Email data
  const emailStats = data.emailStats ?? {};
  if (Object.keys(emailStats).length > 0) {
    formattedData.push(createFormattedItem(emailStats, "Email"));
  }

  // Process Chat data
  const chatStats = data.chatStats ?? {};
  if (Object.keys(chatStats).length > 0) {
    if (filtration?.franchiseId) {
      formattedData.push({
        Category: "Chat",
        Accepted: 0,
        Abandoned: 0,
        Rejected: 0,
        Rejected_Timeout: 0,
        Alert: 0,
        Hold_Count: 0,
        Avg_Handle_Time: "00:00:00",
        Avg_Hold_Time: "00:00:00",
        Avg_Speed_To_Answer: "00:00:00",
      });
    } else {
      formattedData.push(createFormattedItem(chatStats, "Chat"));
    }
  }

  return formattedData;
}

export function HandleDownloadStatisticsReportCRM(data, selectedDownloadReportTab, STATISTICS_SECTIONS, filtration) {
  let reportName = "CRM Data";
  let reportTitle = "CRM Report";

  const processedData = formatterOfCRMData(data, filtration);

  reportTitle = reportTitleGenerator(reportName, filtration)
  reportName = reportNameGenerator(reportName, filtration)

  const csvContent = createCSVContent(processedData, filtration, reportTitle, reportName, data.crmType.title);

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", reportName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

// Check if the brand name is already in the list, to avoid duplication
const prependBrandName = (initialBrand, brandList) => {
  const brandArray = brandList.split(", ").map((brand) => brand.trim());
  if (!brandArray.includes(initialBrand)) {
    brandArray.unshift(initialBrand);
  }
  return brandArray.join(", ");
};

function formatterOfCRMData(data, filtration) {
  const formattedData = {
    report1: [],
    report2: [],
    report3: [],
    report4: [],
  };

  // report1
  if (filtration.brandName && data.statisticsStackFranchise && data.statisticsStackFranchise.length > 0) {
    data.statisticsStackFranchise.forEach((d) => {
      const initialBrandNames = (d.brand_names || []).join(", ");
      const brandNamesString = prependBrandName(filtration.brandName, initialBrandNames);
      formattedData.report1.push({
        Franchise: d.franchiseName,
        Leads: d.st_leads,
        Appointments: d.st_appointments,
        Jobs: d.st_jobs,
        Stacked_Brands: brandNamesString,
      });
    });
  }

  // report2
  if (filtration.brandName && data.statisticsBrand) {
    formattedData.report2.push({
      Total: data.nonStackFranchisesCount,
      Leads: data.statisticsBrand.st_leads,
      Appointments: data.statisticsBrand.st_appointments,
      Jobs: data.statisticsBrand.st_jobs,
    });
  }

  if (filtration.franchiseId) {
    if (data.crmType.title === "Service Titan") { //report3
      formattedData.report3.push({
        Leads: data.statisticsST.st_leads,
        Appointments: data.statisticsST.st_appointments,
        Jobs: data.statisticsST.st_jobs,
      });
    }
    else if (data.crmType.title === "Vonigo") { //report4
      formattedData.report4.push({
        Leads: data.statisticsVonigo.vonigo_leads,
        Appointments: data.statisticsVonigo.vonigo_workorders,
        Jobs: data.statisticsVonigo.vonigo_jobs,
      });
    }
  }
  return formattedData;
}

// Function to create CSV content of CRM data
function createCSVContent(processedData, filtration, reportTitle, reportName, crmType) {
  let csvContent = "data:text/csv;charset=utf-8,";

  let startDate = getTimeWithTimeZone(filtration.startDate, "America/Montreal")
  let endDate = getTimeWithTimeZone(filtration.endDate, "America/Montreal")

  csvContent += `Report Title: ${reportTitle}\r\n`;
  csvContent += `Start Date : ${moment(startDate).format("MM/DD/YYYY")}\r\n`;
  csvContent += `End Date: ${moment(endDate).format("MM/DD/YYYY")}\r\n`;

  let brandName = ''
  if (filtration.brandId) {
    brandName = wrapIfNecessary(filtration.brandName ?? "All Brands");
  } else {
    brandName = "All Brands";
  }
  csvContent += `Brand: ${brandName}\r\n`;

  let franchiseName = "All Franchises";
  if (filtration.franchise?.franchise_name) {
    franchiseName = wrapIfNecessary(filtration.franchise.franchise_name);
  }
  csvContent += `Franchise: ${franchiseName}\r\n`;

  if (filtration.franchiseId && crmType === "Service Titan") {
    csvContent += `CRM: Service Titan\r\n`;
  }
  else if (filtration.franchiseId && crmType === "Vonigo") {
    csvContent += `CRM: Vonigo\r\n`;
  }
  csvContent += "\r\n\r\n";

  if (filtration.brandId && !filtration.franchiseId) {
    // report1 Data
    csvContent += "\r\CRM Statistics of Brand\r\n(Without Stacked Franchises)\r\n\r\n";
    csvContent += "Total Franchises,Leads,Appointments,Jobs\r\n\r\n";
    if (processedData.report2.length > 0) {
      processedData.report2.forEach((row) => {
        const rowData = Object.values(row)
          .map((value) => (typeof value === "string" && (value.includes(",") || value.includes("\"")) ? `"${value}"` : value))
          .join(",");
        csvContent += `${rowData}\r\n`;
      });
    } else {
      csvContent += "No data to display\r\n";
    }
    csvContent += "\r\n\r\n";

    // report2 Data
    csvContent += "\r\CRM Statistics of Stacked Franchises\r\n\r\n";
    csvContent += "Franchise,Leads,Appointments,Jobs,Stacked Brands\r\n\r\n";
    if (processedData.report1.length > 0) {
      processedData.report1.forEach((row) => {
        const rowData = Object.values(row)
          .map((value) => (typeof value === "string" && (value.includes(",") || value.includes("\"")) ? `"${value}"` : value))
          .join(",");
        csvContent += `${rowData}\r\n`;
      });
    } else {
      csvContent += "\r\n\r\n";
      csvContent += "No data to display\r\n";
    }
    csvContent += "\r\n\r\n";
  }
  else if (filtration.franchiseId && crmType === "Service Titan") {
    // Add report3 Data
    csvContent += "CRM Statistics of Franchise\r\n\r\n";
    csvContent += "Leads,Appointments,Jobs\r\n\r\n";
    if (processedData.report3.length > 0) {
      processedData.report3.forEach((row) => {
        const rowData = Object.values(row)
          .map((value) => (typeof value === "string" && (value.includes(",") || value.includes("\"")) ? `"${value}"` : value))
          .join(",");
        csvContent += `${rowData}\r\n`;
      });
    } else {
      csvContent += "No data to display\r\n";
    }
    csvContent += "\r\n\r\n";
  }
  else if (filtration.franchiseId && crmType === "Vonigo") {
    // Add report4 Data
    csvContent += "CRM Statistics of Franchise\r\n\r\n";
    csvContent += "Leads,Appointments,Jobs\r\n\r\n";
    if (processedData.report4.length > 0) {
      processedData.report4.forEach((row) => {
        const rowData = Object.values(row)
          .map((value) => (typeof value === "string" && (value.includes(",") || value.includes("\"")) ? `"${value}"` : value))
          .join(",");
        csvContent += `${rowData}\r\n`;
      });
    } else {
      csvContent += "No data to display\r\n";
    }
    csvContent += "\r\n\r\n";
  }
  return csvContent;
}