import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import { IconButton, Tooltip } from "@mui/material";
import ReportIcon from "@mui/icons-material/Report";
import { updateJob, } from "../../../services/jobService";
import { canCreateAJob, canCreateSTLead } from "../../../utils/miscellaneous";
import { AVAILABLE_CRMS, JOB_UPDATE_TYPES, NOTIFICATION_TYPES } from "../../../constants/common";
import { MISSING_CREDIENTIALS_WARNING, TEMPORY_FAILURE } from "../../../constants/errorMessages";
import { LOCAL_STORAGE_KEYS, SESSION_STORAGE_KEYS } from '../../../constants/browserStorage';
import DetailsItem from './components/DetailsItem';
import { isValidPhoneNumber } from '../../../utils/validators';
import { useHistory } from 'react-router-dom';
import SplitButton from '../../../components/Buttons/SplitButton';
import { useState } from 'react';
import ImageBox from '../../../components/LogoBox/ImageBox';
import { getFromSessionStorage } from '../../../utils/browserStorage';
import WarningMessage from '../../../components/warningMessage/WarningMessage';
import { WARNING_MESSAGE } from '../../../constants/warningMessages';
import CircularProgress from '@mui/material/CircularProgress'
import CloseIcon from '@mui/icons-material/Close';

export default function SubmitDialog(props) {
    const { onClose, open, franchise, brand, callerId, zipCode, channelId, jobId, onCreateLeadPressed, showNotification, isServiceAreaFranchise = false, isOtherFranchisesLoading = true, isFromAutoPickedFranchise = false } = props;
    const history = useHistory()
    const [selectedCreateJobOption, setSelectedCreateJobOption] = useState(getCreateJobOptions()[0])

    const handleClose = () => onClose()

    function getCreateJobOptions() {
        let canCreateJob = canCreateAJob(franchise)
        let canCreateLead = canCreateSTLead(franchise)
        if (canCreateJob || canCreateLead) {
            return [
                { title: "Create a Job within ST", disabled: false, shouldOpenInNewTab: false, withInMw: false },
                { title: "Open in new tab", disabled: false, shouldOpenInNewTab: true, withInMw: false },
                { title: "Create a Job in MW", disabled: false, isCreateJobInMW: true, withInMw: true },
                { title: "Create a Lead in MW", disabled: false, isCreateJobInMW: false, withInMw: true }
            ]
        } else {
            return [
                { title: "Create a Job within ST", disabled: false, shouldOpenInNewTab: false, withInMw: false },
                { title: "Open in new tab", disabled: false, shouldOpenInNewTab: true, withInMw: false },
            ]
        }
    }

    const redirectToJobSection = () => {
        let jobId = getFromSessionStorage(SESSION_STORAGE_KEYS.currentJobId)
        let newCallerId = callerId
        if (!isValidPhoneNumber(newCallerId)) newCallerId = ""
        removeCallDetailsFromLocalStorage()
        history.replace(`/jobs?isNew=true&brandId=${brand.brand_id}&franchiseId=${franchise.id}&callerId=${newCallerId}&zipCode=${zipCode}&isFromCallWindow=true&call_type=0&jobId=${jobId}&type=${JOB_UPDATE_TYPES.JOB}`)
    }

    function getCallLogBody() {
        return {
            franchise_id: franchise.franchise_id,
            zip_code: zipCode,
            call_type: 0
        }
    }

    function handleCallFunction(selectedCreateJobOption) {
        if (selectedCreateJobOption?.withInMw) {
            if (selectedCreateJobOption?.isCreateJobInMW) {
                redirectToJobSection()
            } else {
                if (!canCreateSTLead(franchise)) return
                handleSubmit(true)
            }
        } else {
            handleSubmit(false, selectedCreateJobOption?.shouldOpenInNewTab)
        }
    }

    const handleSubmit = (isCreateLead = false, shouldRedirectInNewTab = false) => {
        let body = getCallLogBody()
        if (franchise.franchise_id === -1) {
            franchise.franchise_id = "tollfree"
        }
        if (isCreateLead) {
            let currentJobId = jobId != "" ? jobId : getFromSessionStorage(SESSION_STORAGE_KEYS.currentJobId)
            onCreateLeadPressed(brand?.brand_id, franchise?.id, franchise?.service_titan_tenant_id, callerId, zipCode, currentJobId, JOB_UPDATE_TYPES.JOB, 0)
        } else {
            if (jobId) {
                updateJob(jobId, JOB_UPDATE_TYPES.JOB, body)
                    .then(res => {
                        showNotification({ message: "Job has been created successfully.", type: NOTIFICATION_TYPES.SUCCESS })
                    }).catch(err => {
                        showNotification({ message: "Unable to create job at this time", type: NOTIFICATION_TYPES.ERROR })
                    }).finally(() => {
                        removeCallDetailsFromLocalStorage()
                        handleRedirecToCrm(zipCode, shouldRedirectInNewTab)
                        onClose()
                    })
            } else {
                let jobId = getFromSessionStorage(SESSION_STORAGE_KEYS.currentJobId)
                updateJob(jobId, JOB_UPDATE_TYPES.JOB, body).then(res => {
                    showNotification({ message: "Job details have been successfully submitted", type: NOTIFICATION_TYPES.SUCCESS })
                }).catch(err => {
                    showNotification({ message: "Unable to create job at this time", type: NOTIFICATION_TYPES.ERROR })
                }).finally(() => {
                    removeCallDetailsFromLocalStorage()
                    handleRedirecToCrm(zipCode, shouldRedirectInNewTab)
                    onClose()
                })
            }
        }


    }

    const handleRedirecToCrm = (zipCode, shouldRedirectInNewTab = false) => {
        const trimmedZipCode = zipCode?.trim() ?? ""
        window.history.pushState({}, undefined, `/call-logs`);
        let newCallerId = callerId
        if (!isValidPhoneNumber(newCallerId)) newCallerId = ""
        let url = ""
        if (franchise.service_brand === AVAILABLE_CRMS.vonigo.value) {
            url = `${brand.redirect_url}api/telco/?provider=eightbyeight&franchiseID=${franchise.franchise_id}&serviceTypeID=${brand.brand_id}&ani=${newCallerId}&dnis=${channelId}&zip=${trimmedZipCode}`;
        } else {
            url = `${brand.redirect_url_service_titan}?portal=premiumservicebrands&tenantId=${franchise.service_titan_tenant_id}&callerID=${newCallerId}&zip=${trimmedZipCode}`
        }
        if (shouldRedirectInNewTab) {
            window.open(url, "_blank")
        } else {
            window.location.replace(url)
        }
    }

    return (
        <Dialog onClose={handleClose} open={open} className="dialog-zindex">
            <div className={"dialog"} padding="1em" >
                <Tooltip title={"Close"}>
                    <IconButton onClick={handleClose} style={{ position: "absolute", right: "5px", top: "5px" }} size={"small"} aria-label="delete">
                        < CloseIcon style={{ height: "15px", width: "15px" }} />
                    </IconButton>
                </Tooltip>
                <Box sx={{ float: "right", marginTop: "1em" }} >
                    <div style={{ position: "relative" }}>
                    </div>
                </Box>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "3em", paddingBottom: "0.5em" }}>
                    <h3 className={"dialog-title"}>Confirm Submission</h3>
                </div>
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "0.5em" }}>
                    <Box sx={{ display: "flex", flexDirection: 'column', justifyContent: "start", marginBottom: "0.5m", gap: "0.5em", alignItems: "start" }}>
                        <DetailsItem heading={"Caller ID"} value={callerId} alignment="start" />
                        <DetailsItem heading={"Franchise"} value={franchise?.franchise_name} alignment="start" />
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: 'column', justifyContent: "start", marginBottom: "0.5m", gap: "0.5em", alignItems: "start" }}>
                        <DetailsItem heading={"ZIP/Postal Code"} value={zipCode} alignment="start" />
                        <Box sx={{ display: "flex", flexDirection: "column", gap: "0.1em" }}>
                            <small >{"Brand"}</small>
                            <ImageBox alt={'brand_img'} src={brand?.brand_img} size={'large'} />
                        </Box>
                    </Box>
                </Box>
                {isOtherFranchisesLoading ? <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", marginBottom: "0.5em" }}><CircularProgress sx={{ alignSelf: 'center' }} size={30} color="inherit" /> </Box> : <>{isFromAutoPickedFranchise || isServiceAreaFranchise ? <></> : <WarningMessage sx={{ maxWidth: '600px' }} sectionId={WARNING_MESSAGE.HOMEPAGE.SUBMISSION_FORM.id} />}</>}
                <div style={{ display: "flex", justifyContent: "end", gap: "0.5em" }}>
                    <div style={{ position: "relative" }}>
                        {franchise?.is_encryption_failed ? <Tooltip sx={{ position: "absolute", bottom: "-12px", right: "-12px" }} title={franchise && !franchise?.is_encryption_failed ? MISSING_CREDIENTIALS_WARNING : TEMPORY_FAILURE}>
                            <IconButton>
                                <ReportIcon color="error" style={{ height: "16px", width: "16px", zIndex: 2 }} />
                            </IconButton>
                        </Tooltip> : <></>}
                    </div>
                    <SplitButton data-test="submit_dialog_submit_button" size="small" options={getCreateJobOptions()} selectedOption={selectedCreateJobOption} setSelectedOption={setSelectedCreateJobOption} onClick={() => handleCallFunction(selectedCreateJobOption)} />
                </div>
            </div>
        </Dialog >
    );
}

export function removeCallDetailsFromLocalStorage() {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.media)
    localStorage.removeItem(LOCAL_STORAGE_KEYS.channelName)
    localStorage.removeItem(LOCAL_STORAGE_KEYS.channelId)
    localStorage.removeItem(LOCAL_STORAGE_KEYS.callerId)
    localStorage.removeItem(LOCAL_STORAGE_KEYS.zipCode)
    localStorage.removeItem(LOCAL_STORAGE_KEYS.brandId)
    localStorage.removeItem(LOCAL_STORAGE_KEYS.jobId)
    localStorage.removeItem(LOCAL_STORAGE_KEYS.brandPrimaryId)
}