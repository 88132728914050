import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useState } from 'react'
import LoadingRow from '../../../../components/table/LoadingRow'
import ErrorRow from '../../../../components/table/ErrorRow'
import { ERROR_COMPONENTS } from '../../../../constants/common'
import { CustomTextField } from '../../user/userCustomStyle'

export default function RealTimeMetricsQueueTable({ isLoading, queueMetricsData }) {

    const [searchVal, setSearchVal] = useState("")

    function filterByQueueName(data) {
        if (searchVal === "") {
            return data
        } else {
            return data?.filter?.((data) => {
                return data["queue_name"].toLowerCase().includes(searchVal.toLowerCase())
            })
        }
    }

    function checkData(data) {
        if (searchVal === "") {
            return true
        } else {
            if (data?.some((data) => data["queue_name"].toLowerCase().includes(searchVal.toLowerCase()))) {
                return true
            } else {
                return false
            }
        }
    }

    function buildDataRows() {
        return (filterByQueueName(queueMetricsData))?.map?.((d, i) => <TableRow>
            <TableCell style={{ textAlign: "left" }} >{i + 1}</TableCell>
            <TableCell style={{ textAlign: "left" }} >{d["queue_name"]}</TableCell>
            <TableCell style={{ textAlign: "left", }}  >{d["availableIdle.rt"]}</TableCell>
            <TableCell style={{ textAlign: "left", }}  >{d["handling.rt"]}</TableCell>
            <TableCell style={{ textAlign: "left" }}  >{d["interactionsWaitInQueue.rt"]}</TableCell>
            <TableCell style={{ textAlign: "left" }}  >{convertMillisecondsToTime(d["interactionsLongestWaitInQueue.rt"])}</TableCell>
            <TableCell style={{ textAlign: "left" }}  >{convertPercentage(d["abandonedPercentage.today"])}</TableCell>
            <TableCell style={{ textAlign: "left" }}  >{convertMillisecondsToTime(d["interactionsLongestWaitInQueue.today"])}</TableCell>
            <TableCell style={{ textAlign: "left" }}  >{convertMillisecondsToTime(d["interactionsAvgWaitTime.today"])}</TableCell>
            <TableCell style={{ textAlign: "left" }}  >{d["entered.today"]}</TableCell>
            <TableCell style={{ textAlign: "left" }}  >{d["accepted.today"]}</TableCell>
            <TableCell style={{ textAlign: "left" }}  >{d["abandoned.today"]}</TableCell>
            <TableCell style={{ textAlign: "left" }}  >{convertPercentage(d["slaPercentage.today"])}</TableCell>
        </TableRow>)
    }

    function convertPercentage(data) {
        if (data === "-") {
            return data
        } else {
            let percentage = (data * 100).toFixed(2)
            return percentage + "%"
        }
    }

    function convertMillisecondsToTime(milliseconds) {
        if (milliseconds === "-") {
            return milliseconds
        } else {
            let seconds = Math.floor(milliseconds / 1000);
            let minutes = Math.floor(seconds / 60);
            let hours = Math.floor(minutes / 60);

            seconds = seconds % 60;
            minutes = minutes % 60;

            hours = hours.toString().padStart(2, '0');
            minutes = minutes.toString().padStart(2, '0');
            seconds = seconds.toString().padStart(2, '0');

            return `${hours}:${minutes}:${seconds}`;
        }
    }

    return (
        <Box sx={{ height: "400px", display: "flex", flexDirection: "column", marginBottom: '10px', overflow: 'auto' }}>
            <Box sx={{ display: "flex", flexDirection: "row", paddingTop: "10px", paddingBottom: "10px" }}>
                <Typography variant="h6">Real Time Queue Metrics</Typography>
                <Box sx={{ flexGrow: 1 }} />
                <CustomTextField
                    label={"Search by queue name"}
                    size={"small"}
                    style={{ width: "200px", marginRight: "10px" }}
                    variant="outlined"
                    type="search"
                    value={searchVal}
                    onChange={(event) => {
                        setSearchVal(event.target.value)
                    }}
                />
            </Box>
            <TableContainer sx={{ flexGrow: 2 }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "left" }} >#</TableCell>
                            <TableCell style={{ textAlign: "left" }} >Queue Name</TableCell>
                            <TableCell style={{ textAlign: "left", }}  >Available Agents Idle(RT)</TableCell>
                            <TableCell style={{ textAlign: "left", }}  >Handling Agents(RT)</TableCell>
                            <TableCell style={{ textAlign: "left" }}  >Waiting In Queue(RT)</TableCell>
                            <TableCell style={{ textAlign: "left" }}  >Longest Wait In Queue(RT)</TableCell>
                            <TableCell style={{ textAlign: "left" }}  >Abandoned %(Today)</TableCell>
                            <TableCell style={{ textAlign: "left" }}  >Longest Wait(Today)</TableCell>
                            <TableCell style={{ textAlign: "left" }}  >Avg Waiting Time(Today)</TableCell>
                            <TableCell style={{ textAlign: "left" }}  >Entered(Today)</TableCell>
                            <TableCell style={{ textAlign: "left" }}  >Accepted(Today)</TableCell>
                            <TableCell style={{ textAlign: "left" }}  >Abandoned(Today)</TableCell>
                            <TableCell style={{ textAlign: "left" }}  >SLA %(Today)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading ? <LoadingRow colSpan={13} /> : (queueMetricsData?.length === 0 || !checkData(queueMetricsData)) ? <ErrorRow colSpan={13} type={ERROR_COMPONENTS.NoDataFound.type} /> : buildDataRows()}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box >
    )
}
