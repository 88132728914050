import { getAllQueues } from "../../services/eigthByEight/queues";

export async function handleGetQueues(selectedQueuetId, setAllQueues, setIsAllQueuesLoading, setIsAllQueuesLoadingError, setSelectedQueue) {
    try {
        setIsAllQueuesLoading(true)
        setIsAllQueuesLoadingError(false)
        const queueResponse = await getAllQueues();
        const queues = queueResponse?.queues
        if (Array.isArray(queues)) {
            setAllQueues(queues)
            if (selectedQueuetId) {
                const selectedQueue = queues.find((q) => q?.queue_id === selectedQueuetId)
                if (selectedQueue) setSelectedQueue(selectedQueue)
            }
        }
    } catch {
        setIsAllQueuesLoadingError(true)
    } finally {
        setIsAllQueuesLoading(false)
    }
}