import { Autocomplete, Box, Button, ButtonGroup, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Tooltip, Paper, Radio, RadioGroup, Select, Typography } from '@mui/material'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { renderBrandOption, renderUserOptionForMiddleWearStatistics } from '../../../../components/servicetitan/RenderInput'
import { getById, getMatchingUserByUserId, getUserFullName } from '../../../../utils/miscellaneous'
import { CustomTextField } from '../../user/userCustomStyle'
import { renderFranchiseOption } from '../../utils/franchise'
import { REPORT_DATE_RANGES } from '../Reports'
import { CommonContext } from '../../../../contexts/CommonContextProvider'
import CustomSelect from '../../../../components/CustomSelect'
import CustomDatePicker from '../../../../components/CustomDatePicker'
import moment from 'moment'
import ImageBox from '../../../../components/LogoBox/ImageBox';
import { handleGetAllCallsMiddleWearStatistics, handleGetMiddleWearStatisticsAllCallCount, handleGetOutboundMiddlewearCallCountByCategory, handleGetInboundMiddlewearStatisticsCallCountByCategory, handleGetInboundMiddlewearStatistics, handleGetOutboundMiddlewearStatistics } from '../../../../state-services/reports/reports';
import MiddleWearStatisticsLineChart from './MiddleWearStatisticsLineChart';
import MiddlewearStatisticsBarChart from './MiddlewearStatisticsBarChart';
import MiddlewearStatisticsDounetChart from './MiddlewearStatisticsDounetChart';
import MiddlewareStatisticsTile from './MiddlewareStatisticsTile';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const MEDIA_TYPES = {
    ALL: {
        index: 0,
        title: "All",
    },
    PHONE: {
        index: 1,
        title: "PHONE",
    },
    MANUAL: {
        index: 2,
        title: "MANUAL",
    },
    CHAT: {
        index: 3,
        title: "CHAT",
    },
    VOICEMAIL: {
        index: 4,
        title: "VOICE MAIL",
    },
    EMAIL: {
        index: 5,
        title: "EMAIL",
    },
}

const STATISTICS_SECTIONS = {
    ALL: {
        index: 0,
        section: "all",
        title: "All Calls",
    },
    INBOUND: {
        index: 1,
        section: "inbound",
        title: "Inbound Calls",
    },
    OUTBOUND: {
        index: 2,
        section: "outbound",
        title: "Outbound Calls",
    }
}

function getTimeZonedDate(date, timeZone) {
    if (timeZone) {
        return moment(date).tz(timeZone)
    } else {
        return moment(date)
    }
}

function getDayBeforeDateISOString(date, timeZone, subtraction = 1) {
    return getTimeZonedDate(date, timeZone).subtract(subtraction, "day").startOf("d")
}

function getDayBeforeEndDateISOString(date, timeZone, subtraction = 0) {
    return getTimeZonedDate(date, timeZone).subtract(subtraction, "day").endOf("d")
}

function moveStartDateIfTimeDiffIsMoreThanMonth(date, timeZone, add = 1) {
    return getTimeZonedDate(date, timeZone).add(add, "day").startOf("d")
}

const INITIAL_FILTRATION = {
    startDate: getDayBeforeDateISOString(new Date(), Intl.DateTimeFormat().resolvedOptions().timeZone, 1),
    endDate: getDayBeforeEndDateISOString(new Date(), Intl.DateTimeFormat().resolvedOptions().timeZone, 1),
    brandImg: "",
    brandId: 0,
    brandBrandId: 0,
    franchiseId: 0,
    franchise: null,
    user: null,
    section: STATISTICS_SECTIONS?.ALL?.section,
    media: MEDIA_TYPES?.ALL,
}

const INITTIAL_CHART_TYPE = {
    AREA: {
        type: "Area",
        selected: true,
    },
    BAR: {
        type: "Bar",
        selected: false,
    },
}

export default function MiddlewearStatistics() {

    const { franchises: allFranchises, brands, isBrandsLoading, allUsersIncludeRemovedOnes } = useContext(CommonContext)
    const nonRemovedFranchises = useMemo(() => {
        return allFranchises.filter((franchise) => !franchise.is_removed)
    }, [allFranchises])
    const [filtration, setFiltration] = useState(INITIAL_FILTRATION)
    const [range, setRange] = useState(REPORT_DATE_RANGES.YESTERDAY)
    const [chartType, setChartType] = useState(INITTIAL_CHART_TYPE)
    const [allCallsMiddleWearStatistics, setgetAllCallsMiddleWearStatistics] = useState()
    const [inboundCallsMiddleWearStatistics, setInboundCallsMiddleWearStatistics] = useState()
    const [outboundCallsMiddleWearStatistics, setOutboundCallsMiddleWearStatistics] = useState()
    const [inboundCallsCountWithCategory, setInboundCallsCountWithCategory] = useState()
    const [outboundCallsCountWithCategory, setOutboundCallsCountWithCategory] = useState()
    const [allCallsCountWithCategory, setAllCallsCountWithCategory] = useState()

    const filterFranchisesByBrand = (brandId) => {
        return allFranchises?.filter((franchise) => franchise.brand_id === brandId)
    }

    useEffect(() => {
        if (filtration?.section === STATISTICS_SECTIONS?.ALL?.section) {
            handleGetAllCallsMiddleWearStatistics(filtration?.brandId, filtration?.brandBrandId, filtration?.franchiseId, filtration?.franchise?.franchise_id, filtration?.user, "All", filtration?.startDate, filtration?.endDate, setgetAllCallsMiddleWearStatistics)
            handleGetMiddleWearStatisticsAllCallCount(filtration?.brandId, filtration?.brandBrandId, filtration?.franchiseId, filtration?.franchise?.franchise_id, filtration?.user, "All", filtration?.startDate, filtration?.endDate, setAllCallsCountWithCategory)
        }
        return () => {
            setgetAllCallsMiddleWearStatistics()
            setAllCallsCountWithCategory()
        }
    }, [filtration?.section, filtration?.startDate, filtration?.endDate, filtration?.brandId, filtration?.brandBrandId, filtration?.franchiseId, filtration?.franchise, filtration?.user])

    useEffect(() => {
        if (filtration?.section === STATISTICS_SECTIONS?.INBOUND?.section) {
            handleGetInboundMiddlewearStatistics(filtration?.brandBrandId, filtration?.franchise?.franchise_id, filtration?.user, filtration?.media, filtration?.startDate, filtration?.endDate, setInboundCallsMiddleWearStatistics)
            handleGetInboundMiddlewearStatisticsCallCountByCategory(filtration?.brandBrandId, filtration?.franchise?.franchise_id, filtration?.user, filtration?.media, filtration?.startDate, filtration?.endDate, setInboundCallsCountWithCategory)
        }
        return () => {
            setInboundCallsMiddleWearStatistics()
        }
    }, [filtration?.section, filtration?.startDate, filtration?.endDate, filtration?.brandBrandId, filtration?.franchise, filtration?.user, filtration?.media])

    useEffect(() => {
        if (filtration?.section === STATISTICS_SECTIONS?.OUTBOUND?.section) {
            handleGetOutboundMiddlewearStatistics(filtration?.brandId, filtration?.franchiseId, filtration?.user, filtration?.startDate, filtration?.endDate, setOutboundCallsMiddleWearStatistics)
            handleGetOutboundMiddlewearCallCountByCategory(filtration?.brandId, filtration?.franchiseId, filtration?.user, filtration?.startDate, filtration?.endDate, setOutboundCallsCountWithCategory)
        }
        return () => {
            setOutboundCallsMiddleWearStatistics()
        }
    }, [filtration?.section, filtration?.startDate, filtration?.endDate, filtration?.brandId, filtration?.franchiseId, filtration?.user])

    //This function is using to format inbound statistics for chart by date
    const formatInboundMiddleWearStatisticsForChart = (inboundCallsMiddleWearStatistics, type) => {
        let inboundCallStatistics = []
        inboundCallsMiddleWearStatistics?.forEach((inbound) => {
            let obj = {}
            obj.jobs = inbound.jobs_count
            obj.leads = inbound.lead_count
            obj.redirected = inbound.redirected_count
            obj.date = checkIfTimeRangeIsOneDay() ? inbound.created_date : moment(inbound.created_date).format("MM-DD-YYYY")
            inboundCallStatistics.push(obj)
        })

        let StatisticsMap = new Map();

        if (checkIfTimeRangeIsOneDay()) {
            let inboundStatisticsGroupByTime = groupInboundCallStatisticsByTimeRangeIfDateDifferenceIsOneDay(inboundCallStatistics)
            let inboundStatArr = Array.from(inboundStatisticsGroupByTime.values())
            inboundStatArr.forEach((inbound) => {
                if (!StatisticsMap.has(inbound.date)) {
                    StatisticsMap.set(inbound.date, {
                        date: inbound.date,
                        jobs: 0,
                        leads: 0,
                        redirected: 0,
                        estimates: 0
                    });
                }
                let stats = StatisticsMap.get(inbound.date);
                stats.jobs += inbound.jobs ?? 0;
                stats.leads += inbound.leads ?? 0;
                stats.redirected += inbound.redirected ?? 0;
            });
        } else {
            inboundCallStatistics.forEach((inbound) => {
                if (!StatisticsMap.has(inbound.date)) {
                    StatisticsMap.set(inbound.date, {
                        date: inbound.date,
                        jobs: 0,
                        leads: 0,
                        redirected: 0
                    });
                }
                let stats = StatisticsMap.get(inbound.date);
                stats.jobs += inbound.jobs ?? 0;
                stats.leads += inbound.leads ?? 0;
                stats.redirected += inbound.redirected ?? 0;
            });
        }

        let startArr = Array.from(StatisticsMap.values());
        startArr.sort((a, b) => new Date(a.date) - new Date(b.date))

        if(!checkIfTimeRangeIsOneDay()){
            startArr.forEach((result) => {
                result.date = moment(result.date).format("MM-DD-YYYY ddd")
            })
        }

        let jobsData = []
        let leadsData = []
        let redirectedData = []

        startArr.forEach((result) => {
            let obj = {}
            obj.x = result.date
            obj.y = result.jobs
            jobsData.push(obj)
        })

        startArr.forEach((result) => {
            let obj = {}
            obj.x = result.date
            obj.y = result.leads
            leadsData.push(obj)
        })

        startArr.forEach((result) => {
            let obj = {}
            obj.x = result.date
            obj.y = result.redirected
            redirectedData.push(obj)
        })

        if (type === "Jobs") {
            return jobsData
        } else if (type === "Leads") {
            return leadsData
        } else if (type === "Redirected") {
            return redirectedData
        } else {
            return []
        }
    }

    //This function is using to format outbound statistics for chart by date
    const formatOutboundMiddleWearStatisticsForChart = (outboundCallsMiddleWearStatistics, type) => {
        let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let outboundCallStatistics = []
        outboundCallsMiddleWearStatistics?.forEach((outbound) => {
            let obj = {}
            obj.jobs = outbound.jobs_count
            obj.leads = outbound.leads_count
            obj.estimates = outbound.estimate_count
            obj.date = checkIfTimeRangeIsOneDay() ? outbound.created_date : moment(outbound.created_date).tz(timeZone).format("MM-DD-YYYY")
            outboundCallStatistics.push(obj)
        })

        let StatisticsMap = new Map();

        if (checkIfTimeRangeIsOneDay()) {
            let outboundStatisticsGroupByTime = groupOutboundCallStatisticsByTimeRangeIfDateDifferenceIsOneDay(outboundCallStatistics)
            let outboundStatArr = Array.from(outboundStatisticsGroupByTime.values())
            outboundStatArr.forEach((outbound) => {
                if (!StatisticsMap.has(outbound.date)) {
                    StatisticsMap.set(outbound.date, {
                        date: outbound.date,
                        jobs: 0,
                        leads: 0,
                        estimates: 0,
                        redirected: 0
                    });
                }
                let stats = StatisticsMap.get(outbound.date);
                stats.jobs += outbound.jobs ?? 0;
                stats.leads += outbound.leads ?? 0;
                stats.estimates += outbound.estimates ?? 0;
            });
        } else {
            outboundCallStatistics.forEach((outbound) => {
                if (!StatisticsMap.has(outbound.date)) {
                    StatisticsMap.set(outbound.date, {
                        date: outbound.date,
                        jobs: 0,
                        leads: 0,
                        estimates: 0,
                    });
                }
                let stats = StatisticsMap.get(outbound.date);
                stats.jobs += outbound.jobs ?? 0;
                stats.leads += outbound.leads ?? 0;
                stats.estimates += outbound.estimates ?? 0;
            })
        }

        let startArr = Array.from(StatisticsMap.values());
        startArr.sort((a, b) => new Date(a.date) - new Date(b.date))

        if(!checkIfTimeRangeIsOneDay()){
            startArr.forEach((result) => {
                result.date = moment(result.date).format("MM-DD-YYYY ddd")
            })
        }

        let jobsData = []
        let leadsData = []
        let estimatesData = []

        startArr.forEach((result) => {
            let obj = {}
            obj.x = result.date
            obj.y = result.jobs
            jobsData.push(obj)
        })

        startArr.forEach((result) => {
            let obj = {}
            obj.x = result.date
            obj.y = result.leads
            leadsData.push(obj)
        })

        startArr.forEach((result) => {
            let obj = {}
            obj.x = result.date
            obj.y = result.estimates
            estimatesData.push(obj)
        })

        if (type === "Jobs") {
            return jobsData
        } else if (type === "Leads") {
            return leadsData
        } else if (type === "Estimates") {
            return estimatesData
        } else {
            return []
        }
    }

    const checkIfTimeRangeIsOneDay = () => {
        let start = new Date(filtration.startDate)
        let end = new Date(filtration.endDate)
        let diff = Math.abs(start - end)
        let diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24))
        return diffDays === 1
    }

    //This function is using to group inbound statistics by time range if date difference is one day
    const groupInboundCallStatisticsByTimeRangeIfDateDifferenceIsOneDay = (inboundCallStatistics) => {
        let inboundCallStatisticsMap = new Map();
        let obj = {
            date: "",
            jobs: 0,
            leads: 0,
            redirected: 0
        }
        inboundCallStatisticsMap.set("00", { ...obj, date: "12 AM - 12:59 AM" })
        inboundCallStatisticsMap.set("06", { ...obj, date: "1 AM - 5:59 AM" })
        inboundCallStatisticsMap.set("12", { ...obj, date: "6 AM - 11:59 AM" })
        inboundCallStatisticsMap.set("18", { ...obj, date: "12 PM - 5:59 PM" })
        inboundCallStatisticsMap.set("24", { ...obj, date: "6 PM - 11:59 PM" })
        inboundCallStatistics.forEach((inbound) => {
            let date = new Date(inbound.date)
            let hours = date.getHours()
            let key = ""
            if (hours === 0) {
                key = "00"
            } else if (hours > 0 && hours < 6) {
                key = "06"
            } else if (hours >= 6 && hours < 12) {
                key = "12"
            } else if (hours >= 12 && hours < 18) {
                key = "18"
            } else if (hours >= 18 && hours < 24) {
                key = "24"
            }
            if (inboundCallStatisticsMap.has(key)) {
                let stats = inboundCallStatisticsMap.get(key)
                stats.jobs += inbound.jobs
                stats.leads += inbound.leads
                stats.redirected += inbound.redirected
            }
        })
        return inboundCallStatisticsMap
    }

    const groupOutboundCallStatisticsByTimeRangeIfDateDifferenceIsOneDay = (outboundCallStatistics) => {
        let outboundCallStatisticsMap = new Map();
        let obj = {
            date: "",
            jobs: 0,
            leads: 0,
            estimates: 0
        }
        outboundCallStatisticsMap.set("00", { ...obj, date: "12 AM - 12:59 AM" })
        outboundCallStatisticsMap.set("06", { ...obj, date: "1 AM - 5:59 AM" })
        outboundCallStatisticsMap.set("12", { ...obj, date: "6 AM - 11:59 AM" })
        outboundCallStatisticsMap.set("18", { ...obj, date: "12 PM - 5:59 PM" })
        outboundCallStatisticsMap.set("24", { ...obj, date: "6 PM - 11:59 PM" })
        outboundCallStatistics.forEach((outbound) => {
            let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            let date = new Date(moment(outbound.date).tz(timeZone))
            let hours = date.getHours()
            let key = ""
            if (hours === 0) {
                key = "00"
            } else if (hours > 0 && hours < 6) {
                key = "06"
            } else if (hours >= 6 && hours < 12) {
                key = "12"
            } else if (hours >= 12 && hours < 18) {
                key = "18"
            } else if (hours >= 18 && hours <= 24) {
                key = "24"
            }
            if (outboundCallStatisticsMap.has(key)) {
                let stats = outboundCallStatisticsMap.get(key)
                stats.jobs += outbound.jobs
                stats.leads += outbound.leads
                stats.estimates += outbound.estimates
            }
        })
        return outboundCallStatisticsMap
    }

    //This function is using to format inbound and outbound statistics for chart by date
    const formatAllMiddleWearStatisticsForChart = (allCallsMiddleWearStatistics, type) => {
        let inboundCallStatistics = []
        allCallsMiddleWearStatistics?.inbound_statistics?.forEach((inbound) => {
            let obj = {}
            obj.jobs = inbound.jobs_count
            obj.leads = inbound.lead_count
            obj.redirected = inbound.redirected_count
            obj.date = checkIfTimeRangeIsOneDay() ? inbound.created_date : moment(inbound.created_date).format("MM-DD-YYYY")
            inboundCallStatistics.push(obj)
        })
        let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let outboundCallStatistics = []
        allCallsMiddleWearStatistics?.outbound_statistics?.forEach((outbound) => {
            let obj = {}
            obj.jobs = outbound.jobs_count
            obj.leads = outbound.leads_count
            obj.estimates = outbound.estimate_count
            obj.date = checkIfTimeRangeIsOneDay() ? outbound.created_date : moment(outbound.created_date).tz(timeZone).format("MM-DD-YYYY")
            outboundCallStatistics.push(obj)
        })

        let jobsData = []
        let leadsData = []
        let redirectedData = []
        let estimatesData = []

        let combinedStatisticsMap = new Map();

        if (checkIfTimeRangeIsOneDay()) {
            let inboundStatisticsGroupByTime = groupInboundCallStatisticsByTimeRangeIfDateDifferenceIsOneDay(inboundCallStatistics)
            let outboundStatisticsGroupByTime = groupOutboundCallStatisticsByTimeRangeIfDateDifferenceIsOneDay(outboundCallStatistics)
            let inboundStatArr = Array.from(inboundStatisticsGroupByTime.values())
            let outboundStatArr = Array.from(outboundStatisticsGroupByTime.values())
            inboundStatArr.forEach((inbound) => {
                if (!combinedStatisticsMap.has(inbound.date)) {
                    combinedStatisticsMap.set(inbound.date, {
                        date: inbound.date,
                        jobs: 0,
                        leads: 0,
                        redirected: 0,
                        estimates: 0
                    });
                }
                let stats = combinedStatisticsMap.get(inbound.date);
                stats.jobs += inbound.jobs ?? 0;
                stats.leads += inbound.leads ?? 0;
                stats.redirected += inbound.redirected ?? 0;
            });
            outboundStatArr.forEach((outbound) => {
                if (!combinedStatisticsMap.has(outbound.date)) {
                    combinedStatisticsMap.set(outbound.date, {
                        date: outbound.date,
                        jobs: 0,
                        leads: 0,
                        estimates: 0,
                        redirected: 0
                    });
                }
                let stats = combinedStatisticsMap.get(outbound.date);
                stats.jobs += outbound.jobs ?? 0;
                stats.leads += outbound.leads ?? 0;
                stats.estimates += outbound.estimates ?? 0;
            });
        } else {
            // Combine both inbound and outbound call statistics
            inboundCallStatistics?.forEach((inbound) => {
                if (!combinedStatisticsMap.has(inbound.date)) {
                    combinedStatisticsMap.set(inbound.date, {
                        date: inbound.date,
                        jobs: 0,
                        leads: 0,
                        estimates: 0,
                        redirected: 0
                    });
                }
                let stats = combinedStatisticsMap.get(inbound.date);
                stats.jobs += inbound.jobs ?? 0;
                stats.leads += inbound.leads ?? 0;
                stats.redirected += inbound.redirected ?? 0;
            });

            outboundCallStatistics.forEach((outbound) => {
                if (!combinedStatisticsMap.has(outbound.date)) {
                    combinedStatisticsMap.set(outbound.date, {
                        date: outbound.date,
                        jobs: 0,
                        leads: 0,
                        estimates: 0,
                        redirected: 0
                    });
                }
                let stats = combinedStatisticsMap.get(outbound.date);
                stats.jobs += outbound.jobs ?? 0;
                stats.leads += outbound.leads ?? 0;
                stats.estimates += outbound.estimates ?? 0;
            });
        }

        let combinedResults = Array.from(combinedStatisticsMap.values());
        combinedResults.sort((a, b) => new Date(a.date) - new Date(b.date))

        if(!checkIfTimeRangeIsOneDay()){
            combinedResults.forEach((result) => {
                result.date = moment(result.date).format("MM-DD-YYYY ddd")
            })
        }

        combinedResults.forEach((result) => {
            let obj = {}
            obj.x = result.date
            obj.y = result.jobs
            jobsData.push(obj)
        })

        combinedResults.forEach((result) => {
            let obj = {}
            obj.x = result.date
            obj.y = result.leads
            leadsData.push(obj)
        })

        combinedResults.forEach((result) => {
            let obj = {}
            obj.x = result.date
            obj.y = result.redirected
            redirectedData.push(obj)
        })

        combinedResults.forEach((result) => {
            let obj = {}
            obj.x = result.date
            obj.y = result.estimates
            estimatesData.push(obj)
        })

        if (type === "Jobs") {
            return jobsData
        } else if (type === "Leads") {
            return leadsData
        } else if (type === "Redirected") {
            return redirectedData
        } else if (type === "Estimates") {
            return estimatesData
        } else {
            return []
        }
    }

    function getAllCallsCount(allCallsCount, type) {
        let labels = ['Inbound Calls', 'Outbound Calls']
        let series = []
        let inboundCount = 0
        let outboundCount = 0
        inboundCount = allCallsCount?.inbound_call_count_with_category?.jobs_on_hold + allCallsCount?.inbound_call_count_with_category?.jobs_sent + allCallsCount?.inbound_call_count_with_category?.other_calls
        outboundCount = allCallsCount?.outbound_call_count_with_category?.follow_up_count + allCallsCount?.outbound_call_count_with_category?.other_count
        if (type === "Tiles") {
            series.push(inboundCount + outboundCount)
        }
        series.push(inboundCount)
        series.push(outboundCount)
        if (type === "Tiles") {
            labels.unshift('Total Calls')
        }
        return { labels, series }
    }

    function getInboundCallsCount(inboundCallsCountWithCategory, type) {
        let labels = ['Jobs Sent', 'Jobs On Hold', 'Non Job Calls']
        let series = []
        if (type === "Tiles") {
            series.push(inboundCallsCountWithCategory?.jobs_sent + inboundCallsCountWithCategory?.jobs_on_hold + inboundCallsCountWithCategory?.other_calls)
        }
        series.push(inboundCallsCountWithCategory?.jobs_sent ?? 0)
        series.push(inboundCallsCountWithCategory?.jobs_on_hold ?? 0)
        series.push(inboundCallsCountWithCategory?.other_calls ?? 0)
        if (type === "Tiles") {
            labels.unshift('Total Calls')
        }
        return { labels, series }
    }

    function getOutboundCallsCount(outboundCallsCountWithCategory, type) {
        let labels = ['FollowUp Calls', 'Other Calls']
        let series = []
        if (type === "Tiles") {
            series.push(outboundCallsCountWithCategory?.follow_up_count + outboundCallsCountWithCategory?.other_count)
        }
        series.push(outboundCallsCountWithCategory?.follow_up_count ?? 0)
        series.push(outboundCallsCountWithCategory?.other_count ?? 0)
        if (type === "Tiles") {
            labels.unshift('Total Calls')
        }
        return { labels, series }
    }

    return (
        <Box sx={{ disply: 'flex', flexDirection: 'row' }}>
            <Grid >
                <Grid item xs={2} md={2}>
                    <Box>
                        <Box sx={{ marginLeft:"0.3em" }}>
                            <FormControl>
                                <RadioGroup
                                    row
                                    value={filtration?.section}
                                    name="type-radio-buttons-group"
                                    onChange={(e) => (setFiltration({ ...filtration, section: e.target.value }))}>
                                    {Object.values(STATISTICS_SECTIONS).map((t) => <FormControlLabel
                                        key={t.index}
                                        value={t.section}
                                        control={<Radio sx={{ '&, &.Mui-checked': { color: '#00a79d', }, "&.Mui-disabled": { color: "grayText" } }} inputProps={{ "data-test": `faq_type_${t.section}` }} />}
                                        label={t.title}
                                    />)}
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={2} md={2} >
                    <Box sx={{ display: "flex", marginLeft: "auto", marginRight: "20px", marginBottom: "5px" }}>
                        {filtration?.brandImg &&
                            <Box>
                                <ImageBox alt="brand" src={filtration.brandImg} size="large">
                                </ImageBox>
                            </Box>
                        }
                        <Box sx={{ display: "flex", flexDirection: "row", marginBottom: "12px", flexWrap: "wrap", gap: "1em", marginLeft: "auto" }}>
                            <Autocomplete
                                sx={{ minWidth: "250px" }}
                                size="small"
                                loading={isBrandsLoading}
                                noOptionsText={"No brands"}
                                disabled={brands?.length === 0}
                                disablePortal
                                options={brands}
                                getOptionLabel={(brand) => brand.brand_name}
                                renderOption={renderBrandOption}
                                value={getById(filtration.brandId, brands) ?? null}
                                onChange={(e, brand) => setFiltration({ ...filtration, brandId: brand?.id, brandImg: brand?.brand_img, brandBrandId: brand?.brand_id, franchiseId: undefined, channelId: undefined, franchise: undefined })}
                                renderInput={(params) => <CustomTextField {...params} label="Brand" />}
                            />
                            <Autocomplete
                                sx={{ minWidth: "250px" }}
                                size="small"
                                noOptionsText={"No franchises"}
                                disabled={filtration?.brandId ? filterFranchisesByBrand(filtration?.brandBrandId)?.length === 0 : nonRemovedFranchises?.length === 0}
                                disablePortal
                                options={filtration?.brandId ? filterFranchisesByBrand(filtration?.brandBrandId) : nonRemovedFranchises}
                                inputValue={filtration.franchiseName}
                                getOptionLabel={(franchise) => franchise.franchise_name}
                                renderOption={renderFranchiseOption}
                                value={getById(filtration.franchiseId, filtration?.brandId ? filterFranchisesByBrand(filtration?.brandBrandId) : nonRemovedFranchises) ?? null}
                                onChange={(e, franchise) => setFiltration({ ...filtration, franchiseId: franchise?.id, channelId: undefined, franchise: franchise })}
                                renderInput={(params) => <CustomTextField {...params} label="Franchise" />}
                            />
                            <Autocomplete
                                sx={{ minWidth: "230px" }}
                                size="small"
                                disablePortal
                                disabled={allUsersIncludeRemovedOnes?.length === 0}
                                options={allUsersIncludeRemovedOnes?.sort((a, b) => b.is_enabled - a.is_enabled)}
                                getOptionLabel={(user) => getUserFullName(user)}
                                renderOption={renderUserOptionForMiddleWearStatistics}
                                value={filtration.user ? getMatchingUserByUserId(filtration.user, allUsersIncludeRemovedOnes) ?? null : null}
                                onChange={(_, user) => {
                                    if (user) {
                                        setFiltration({ ...filtration, user: user.user_id });
                                    } else {
                                        setFiltration({ ...filtration, user: null });
                                    }
                                }}
                                renderInput={(params) => <CustomTextField {...params} label="Agents" />}
                            />
                            {filtration?.section === STATISTICS_SECTIONS?.INBOUND?.section &&
                                <CustomSelect sx={{ width: "150px" }} >
                                    <InputLabel >Media Type</InputLabel>
                                    <Select
                                        size={"small"}
                                        displayEmpty
                                        label="Media Type"
                                        className={"cus-input"}
                                        value={filtration.media}
                                        onChange={(e) => {
                                            setFiltration({ ...filtration, media: e.target.value })
                                        }
                                        }
                                    >
                                        {Object.values(MEDIA_TYPES).map((cr, i) => <MenuItem key={cr.index} value={cr}>{cr.title}</MenuItem>)}
                                    </Select>
                                </CustomSelect>
                            }
                            <CustomSelect sx={{ width: "150px" }} >
                                <Select
                                    size={"small"}
                                    displayEmpty
                                    value={range}
                                    onChange={(e) => {
                                        const newFiltration = {
                                            ...filtration,
                                            startDate: getDayBeforeDateISOString(new Date(), Intl.DateTimeFormat().resolvedOptions().timeZone, e.target.value.diffFromNow),
                                            endDate: getDayBeforeEndDateISOString(new Date(), Intl.DateTimeFormat().resolvedOptions().timeZone, 1),
                                        }
                                        setFiltration(newFiltration)
                                        setRange(e.target.value)

                                    }}
                                >
                                    {Object.values(REPORT_DATE_RANGES).map((cr, i) => <MenuItem key={cr.index} value={cr}>{cr.label}</MenuItem>)}
                                </Select>
                            </CustomSelect>
                            <Box sx={{ width: "220px", zIndex: "1000" }} >
                                <CustomDatePicker
                                    showTimeSelect={true}
                                    maxTime={new Date(moment(filtration.endDate).valueOf())}
                                    disabled={false}
                                    label={"Start Date"}
                                    value={new Date(filtration.startDate)}
                                    onChange={(date) => {
                                        setRange(REPORT_DATE_RANGES.CUSTOM_RANGE)

                                        let daysDiff = moment(filtration.endDate).diff(moment(date), "days")
                                        let updatedEndDate = filtration.endDate

                                        if (daysDiff >= 29) {
                                            let needToSubstractDays = daysDiff - 29
                                            updatedEndDate = getDayBeforeEndDateISOString(filtration.endDate, Intl.DateTimeFormat().resolvedOptions().timeZone, needToSubstractDays)
                                        }

                                        setFiltration({
                                            ...filtration,
                                            startDate: date.toISOString(),
                                            endDate: updatedEndDate
                                        })

                                    }}
                                    clearable={false}
                                    required={true}
                                />
                            </Box>
                            <Box sx={{ width: "220px", zIndex: "1000" }} >
                                <CustomDatePicker
                                    showTimeSelect={true}
                                    maxTime={new Date(getDayBeforeEndDateISOString(new Date(), Intl.DateTimeFormat().resolvedOptions().timeZone, 1))}
                                    minTime={new Date(moment(new Date(filtration.startDate)).valueOf())}
                                    disabled={false}
                                    label={"End Date"}
                                    value={new Date(moment(new Date(filtration.endDate)).valueOf())}
                                    onChange={(date) => {
                                        setRange(REPORT_DATE_RANGES.CUSTOM_RANGE)

                                        let daysDiff = moment(date).diff(moment(filtration?.startDate), "days")
                                        let updatedStartDate = filtration.startDate

                                        if (daysDiff >= 29) {
                                            let needToAddDays = daysDiff - 29
                                            updatedStartDate = moveStartDateIfTimeDiffIsMoreThanMonth(filtration.startDate, Intl.DateTimeFormat().resolvedOptions().timeZone, needToAddDays)
                                        }
                                        setFiltration({
                                            ...filtration,
                                            endDate: date.toISOString(),
                                            startDate: updatedStartDate
                                        });
                                    }}
                                    clearable={false}
                                    required={true}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={2} md={2}>
                    {
                        filtration?.section === STATISTICS_SECTIONS?.ALL?.section ?
                            <MiddlewareStatisticsTile formattedFunction={getAllCallsCount} data={allCallsCountWithCategory} type={"All"} />
                            :
                            filtration?.section === STATISTICS_SECTIONS?.INBOUND?.section ?
                                <MiddlewareStatisticsTile formattedFunction={getInboundCallsCount} data={inboundCallsCountWithCategory} type={"Inbound"} />
                                :
                                <MiddlewareStatisticsTile formattedFunction={getOutboundCallsCount} data={outboundCallsCountWithCategory} type={"Outbound"} />
                    }
                </Grid>
                <Grid item xs={6} md={6}>
                    <Box sx={{ height: '670px', display: 'flex', flexDirection: 'row', gap: '10px', marginTop: "10px" }}>
                        <Paper sx={{ height: "95%", width: '30%', padding: "20px", borderRadius: "7px", backgroundColor: 'background.main', boxShadow: "0px 2px 5px", opacity: 1 }}>
                            <Typography variant="h6">{filtration?.section === STATISTICS_SECTIONS?.ALL?.section ? "All Call Statistics" : filtration?.section === STATISTICS_SECTIONS?.INBOUND?.section ? "Inbound Call Statistics" : "Outbound Call Statistics"}</Typography>
                            <Box sx={{ width: "100%", height: "100%",display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <MiddlewearStatisticsDounetChart
                                    formatterFunction={filtration?.section === STATISTICS_SECTIONS?.ALL?.section ? getAllCallsCount : filtration?.section === STATISTICS_SECTIONS?.INBOUND?.section ? getInboundCallsCount : getOutboundCallsCount}
                                    data={filtration?.section === STATISTICS_SECTIONS?.ALL?.section ? allCallsCountWithCategory : filtration?.section === STATISTICS_SECTIONS?.INBOUND?.section ? inboundCallsCountWithCategory : outboundCallsCountWithCategory}
                                />
                            </Box>
                        </Paper>
                        <Paper sx={{ height: '95%', width: '70%', marginRight: "20px", padding: "20px", borderRadius: "7px", backgroundColor: "background.main", boxShadow: "0px 2px 5px", opacity: 1 }}>
                            <Box sx={{ display: "flex", flexDirection: "row" }}>
                                {filtration?.section === STATISTICS_SECTIONS?.ALL?.section ?
                                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                                        <Typography variant="h6">All Statistics</Typography>
                                        <Tooltip placement="right" title="Includes only Jobs Sent from Inbound Calls and Follow Up Calls from Outbound Calls">
                                            <InfoOutlinedIcon sx={{ cursor: "pointer" }} color="info" style={{ height: "20px", width: "20px" }} />
                                        </Tooltip>
                                    </Box>
                                    :
                                    <Typography sx={{ width: "25%", marginBottom: "10px" }} variant="h6">{filtration?.section === STATISTICS_SECTIONS?.INBOUND?.section ? "Jobs Sent Statistics" : "Follow Up Call Statistics"}</Typography>
                                }
                                <Box sx={{ width: "auto", display: "flex", flexDirection: "row", marginLeft: "auto", marginBottom: "10px" }}>
                                    <ButtonGroup variant="contained" aria-label="Basic button group">
                                        <Button onClick={() => {
                                            setChartType({ ...chartType, AREA: { ...chartType.AREA, selected: true }, BAR: { ...chartType.BAR, selected: false } })
                                        }} style={{ backgroundColor: chartType?.AREA?.selected ? "#00a79d" : "#B3C8CF", color: "black" }}>Area</Button>
                                        <Button onClick={() => setChartType(
                                            { ...chartType, AREA: { ...chartType.AREA, selected: false }, BAR: { ...chartType.BAR, selected: true } }
                                        )} style={{ backgroundColor: chartType?.BAR?.selected ? "#00a79d" : "#B3C8CF", color: "black" }}>Bar</Button>
                                    </ButtonGroup>
                                </Box>
                            </Box>
                            <Box sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                {chartType?.AREA?.selected ?
                                    <MiddleWearStatisticsLineChart
                                        formatterFunction={filtration?.section === STATISTICS_SECTIONS?.ALL?.section ? formatAllMiddleWearStatisticsForChart : filtration?.section === STATISTICS_SECTIONS?.INBOUND?.section ? formatInboundMiddleWearStatisticsForChart : formatOutboundMiddleWearStatisticsForChart}
                                        data={filtration?.section === STATISTICS_SECTIONS?.ALL?.section ? allCallsMiddleWearStatistics : filtration?.section === STATISTICS_SECTIONS?.INBOUND?.section ? inboundCallsMiddleWearStatistics : outboundCallsMiddleWearStatistics}
                                        dataType={filtration?.section === STATISTICS_SECTIONS?.ALL?.section ? "All" : filtration?.section === STATISTICS_SECTIONS?.INBOUND?.section ? "Inbound" : "Outbound"} 
                                        fileName={filtration?.section === STATISTICS_SECTIONS?.ALL?.section ? "All Statistics" : filtration?.section === STATISTICS_SECTIONS?.INBOUND?.section ? "Jobs Sent Statistics" : "Follow Up Call Statistics"}
                                    />
                                    :
                                    <MiddlewearStatisticsBarChart
                                        formatterFunction={filtration?.section === STATISTICS_SECTIONS?.ALL?.section ? formatAllMiddleWearStatisticsForChart : filtration?.section === STATISTICS_SECTIONS?.INBOUND?.section ? formatInboundMiddleWearStatisticsForChart : formatOutboundMiddleWearStatisticsForChart}
                                        data={filtration?.section === STATISTICS_SECTIONS?.ALL?.section ? allCallsMiddleWearStatistics : filtration?.section === STATISTICS_SECTIONS?.INBOUND?.section ? inboundCallsMiddleWearStatistics : outboundCallsMiddleWearStatistics}
                                        dataType={filtration?.section === STATISTICS_SECTIONS?.ALL?.section ? "All" : filtration?.section === STATISTICS_SECTIONS?.INBOUND?.section ? "Inbound" : "Outbound"} 
                                        fileName={filtration?.section === STATISTICS_SECTIONS?.ALL?.section ? "All Statistics" : filtration?.section === STATISTICS_SECTIONS?.INBOUND?.section ? "Jobs Sent Statistics" : "Follow Up Call Statistics"}
                                    />
                                }
                            </Box>
                        </Paper>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}
