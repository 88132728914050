import React from 'react'
import { Box, Button, Drawer, Grid, Typography, Popper, Chip, Autocomplete } from '@mui/material'
import { CustomTextFieldForFaqTempalte } from '../../../../../components/CustomTextField'
import { getPhoneNumberWithInputMask } from '../../../../../utils/inputMasks'
import { trimEmptySpaces } from '../../../brands/components/EditBrandTemplateFaqDrawer'

export default function DrawerForEnterFaq({ id, faq, keywords, helperText, setHelperText, isKeywordsLoading, handleSetFaqs, handleSetFaqsByTags, drawerOpen, onClose }) {

    const handleDrawerClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        if (faq?.answer === "") {
            setHelperText("Answer is required")
            return;
        }
        if (helperText !== "") {
            return;
        }
        onClose();
    };

    const getFullKeywordFromKeywords = (kwords) => {
        let fullKeywords = []
        kwords?.forEach((kword) => {
            let fullKeyword = keywords?.find?.((k) => k?.keyword === kword)
            fullKeywords.push(fullKeyword)
        })
        return fullKeywords
    }

    return <Drawer
        sx={{ zIndex: 1301 }} anchor="right" lassName="dock-drawer" open={drawerOpen} onClose={handleDrawerClose} >
        <Box sx={{ height: "auto", width: "700px", padding: "30px" }} >
            <Typography sx={{ marginBottom: "1em" }} variant="h5">Franchise Template FAQ Section</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{ height: "auto", width: "auto" }} >
                        <Typography variant="h6">Question</Typography>
                        <CustomTextFieldForFaqTempalte
                            inputProps={{
                                "data-test": "faq_question"
                            }}
                            multiline
                            required
                            sx={{ width: "100%" }}
                            value={faq?.question ?? null}
                            size="small"
                            margin="normal"
                            variant="outlined"
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ height: "auto", width: "auto" }} >
                        <Typography variant="h6">Answer</Typography>
                        <CustomTextFieldForFaqTempalte
                            required
                            sx={{ width: "100%" }}
                            inputProps={{
                                "data-test": "faq_answer"
                            }}
                            error={helperText !== ""}
                            helperText={helperText}
                            value={faq?.question === "Business Number" ? getPhoneNumberWithInputMask(faq?.answer) : faq?.answer ?? null}
                            multiline
                            onChange={(e) => {
                                let value = trimEmptySpaces(e.target.value)
                                handleSetFaqs(id, value, "Answer")
                            }}
                            size="small"
                            margin="normal"
                            variant="outlined"

                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ height: "auto", marginTop: "0.5em", width: "auto" }} >
                        <Typography variant="h6">Keywords</Typography>
                        <Autocomplete
                            sx={{ width: "100%", marginTop: "1em" }}
                            size="small"
                            disablePortal={true}
                            PopperComponent={
                                (props) => {
                                    return <Popper {...props} placement="bottom" />
                                }
                            }
                            disableClearable={false}
                            multiple={true}
                            loading={isKeywordsLoading}
                            options={keywords}
                            getOptionLabel={(keyword) => keyword?.keyword}
                            renderOption={(props, keyword) => {
                                return <RenderInput {...props} content={keyword?.keyword} />
                            }}
                            renderTags={(keywords) => {
                                return keywords?.map?.((keyword) => {
                                    return <Chip
                                        size="small"
                                        sx={{
                                            marginRight:"0.3em",
                                            marginBottom: "0.6em",
                                        }}
                                        label={keyword?.keyword}
                                        onDelete={() => {
                                            handleSetFaqsByTags(id, faq?.keywords?.filter?.((k) => k !== keyword?.keyword))
                                        }}
                                    />
                                })
                            }}
                            value={getFullKeywordFromKeywords(faq?.keywords) ?? []}
                            onChange={(_, techniciansIds) => {
                                handleSetFaqs(id, techniciansIds, "Keywords")
                            }}
                            renderInput={(params) => {
                                let trimmedValue = params.inputProps.value?.trimStart();
                                return (
                                    <CustomTextFieldForFaqTempalte
                                        {...params}
                                        inputProps={{
                                            ...params.inputProps,
                                            value: trimmedValue
                                        }}
                                    />
                                );
                            }
                            } />
                    </Box>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: "1.2em" }}>
                    <Box sx={{ display: "flex", gap: "1em" }}>
                        <Button
                            sx={{ marginLeft: "auto" }}
                            type={"button"}
                            size=" large"
                            variant="contained"
                            className={"btn-primary"}
                            onClick={handleDrawerClose}
                        >
                            Save
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    </Drawer >
}

function RenderInput(props) {
    const { content } = props
    return (
        <Typography {...props}>
            {content}
        </Typography>
    );
}