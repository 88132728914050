import { Dialog, FormControl, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

export const CustomTextField = styled(TextField)(({theme}) =>({
    "& label.Mui-focused": {
        color: "#00a79d",
        fontWeight: "500",
    },
    "& label": {
        fontSize: "15px",
        fontWeight: "300",
        color: theme.palette.fieldLabelColor,
    },
    "& .MuiOutlinedInput-root": {
        "&:hover fieldset": {
            borderColor: "#00a79d",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#00a79d",
        },
    },
}));

export const CustomSelect = styled(FormControl)(({theme})=>({
    "& label.Mui-focused": {
        color: "#00a79d",
        fontWeight: "500",
    },
    "& label": {
        fontSize: "15px",
        fontWeight: "300",
        color: theme.palette.fieldLabelColor,
    },
    "& .MuiOutlinedInput-root": {
        "&:hover fieldset": {
            borderColor: "#00a79d",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#00a79d",
        },
    },
}));

export const CustomSwitch = styled(Switch)({
    "& .MuiSwitch-switchBase": {
        "&.Mui-checked": {
            color: "#00a79d",
            "& + .MuiSwitch-track": {
                backgroundColor: "#00a79d",
            },
        },
    },
});

export const Item = styled(Dialog)(() => ({

}));

