import moment from "moment";
import { CRMS, NOTIFICATION_TYPES } from "../constants/common";
import { nonConfigOutboundChannelIdMsg } from "../constants/abndCallLog";
import { TEMPORY_FAILURE } from "../constants/errorMessages";
import { isValidEmail, isValidPhoneNumber } from "./validators";

export const COLORS_OF_JOB_STATUS = {
  "Canceled": "red",
  "Hold": "#1450A3",
  "Completed": "#87A922",
  "InProgress": "#FB6D48",
  "Scheduled": "#BC7AF9",
}

//This function is using to choose the color for the job status
export function chooseColorForJobStatus(jobStatus) {
  return COLORS_OF_JOB_STATUS[jobStatus] ?? "black"
}

export const getMatchingBrandById = function (id, brands) {
  const brand = brands.find(brand => brand.id === id);
  return brand;
}

export const getMatchingBrandByBrandIds = function (ids = [], brands = []) {
  return brands.filter((v) => ids.includes(v.brand_id))
}

export function filterAgents(data, searchValue) {
  if (searchValue !== "") {
    data = data.filter((v, i) => {
      if (`${v.user.first_name.replace(/\s/g, '')}${v.user.last_name.replace(/\s/g, '')}`.toLowerCase().includes(searchValue.replace(/\s/g, '').toLowerCase())) {
        return v;
      } else {
        return null;
      }
    })
  }
  return data
}

export function getItemsFromLocalStorage(itemName) {
  return localStorage.getItem(itemName)
}

export const getMatchingFranchiseByIdOrTenantId = function (franchiseId, tenantId, franchises) {
  let franchise = franchises.find(franchise => franchise?.id === franchiseId)
  if (!franchise) {
    franchise = franchises.find(franchise => franchise?.service_titan_tenant_id === tenantId)
  }
  return franchise;
}

export const getMatchingFranchiseById = function (franchiseId = 0, franchises = []) {
  const franchise = franchises.find(fanchise => fanchise.id === franchiseId)
  return franchise;
}

export function isFranchiseDisabled(franchise) {
  return franchise?.service_brand?.trim()?.toLocaleLowerCase() === CRMS[0].trim().toLocaleLowerCase() && (franchise?.service_titan_tenant_id === "" || franchise?.service_titan_client_id === "" || franchise?.service_titan_client_secret === "")
}

export function getMatchingUserByUserId(userId, users = []) {
  const user = users.find((user) => user.user_id === userId)
  return user;
}

export function getAddressString(address = {}) {
  address.latitude = undefined;
  address.longitude = undefined;

  let addressString = ""
  Object.values(address).forEach((value) => {
    if (value) {
      addressString += `${value}\n`
    }
  })
  return addressString
}

export function getMatchingBrandByBrandId(brandId = 0, brands = []) {
  return brands.find(brand => brand.brand_id === brandId)
}

export function sortDataByBrandAlphabetically(data = [], brands = []) {
  data.forEach((item) => {
    const brand = getMatchingBrandByBrandId(item.brand, brands)
    item.brand_name = brand?.brand_name
  })
  return data.sort((a, b) => {
    if (a.brand_name < b.brand_name) {
      return -1;
    }
    if (a.brand_name > b.brand_name) {
      return 1;
    }
    return 0;
  })
}

export function sortDataByFranchiseAlphabetically(data = [], franchises = []) {
  data.forEach((item) => {
    const franchise = getMatchingFranchiseById(item.franchise_id, franchises)
    item.franchise_name = franchise?.franchise_name
    franchise?.is_removed == 1 ? item.is_removed = 1 : item.is_removed = 0
  })
  let permanantRemovedFranchises = data.filter((item) => item.is_removed == 1)
  let otherFranchises = data.filter((item) => item.is_removed == 0)
  otherFranchises.sort((a, b) => {
    if (a.franchise_name < b.franchise_name) {
      return -1;
    }
    if (a.franchise_name > b.franchise_name) {
      return 1;
    }
    return 0;
  })
  return [...otherFranchises, ...permanantRemovedFranchises]
}

export function sortFranchisesAlphabetically(franchises = []) {
  return franchises.sort((a, b) => {
    if (a.franchise_name < b.franchise_name) {
      return -1;
    }
    if (a.franchise_name > b.franchise_name) {
      return 1;
    }
    return 0;
  })
}

export function sortFranchisesByActiveState(franchises = []) {
  let activeFranchises = franchises.filter((item) => item.is_enabled == 1)
  let deactiveFranchises = franchises.filter((item) => item.is_enabled == 0)
  activeFranchises.sort((a, b) => {
    if (a.franchise_name < b.franchise_name) {
      return -1;
    }
    if (a.franchise_name > b.franchise_name) {
      return 1;
    }
    return 0;
  }
  )
  deactiveFranchises.sort((a, b) => {
    if (a.franchise_name < b.franchise_name) {
      return -1;
    }
    if (a.franchise_name > b.franchise_name) {
      return 1;
    }
    return 0;
  })
  return [...activeFranchises, ...deactiveFranchises]
}

export function sortBrandsAlphabetically(brands = []) {
  return brands.sort((a, b) => {
    if (a.brand_name < b.brand_name) {
      return -1;
    }
    if (a.brand_name > b.brand_name) {
      return 1;
    }
    return 0;
  })
}

export function makeACall(phoneNumber = "", outboundNumber = "", showNotification, id = 0) {
  if (!isValidPhoneNumber(outboundNumber)) {
    showNotification?.({ message: nonConfigOutboundChannelIdMsg, type: NOTIFICATION_TYPES.WARNING })
    return
  }
  const phoneNumberOnly = phoneNumber.substring(0, 10)
  const callBackUrl = `${process.env.REACT_APP_PRIMARY_API_URL}/data/eight-by-eight/call-callback-sucess`
  const ctlUserData = `[ctl_callbackUrl|${callBackUrl}][caller_id|$caller_id$][transaction_id|$transaction_id$][call_answered_time|$call_answered_time$][call_hangup_time|$call_hangup_time$][call_answered_tenant_TT|$callAnsweredTenantTT$][call_hangup_tenant_TT|$callHangupTenantTT$][call_duration|$callDuration$][interaction_guid|$interaction_guid$][year|$year$][month|$month$][date|$date$][hours|$hours$][minutes|$minutes$][seconds|$seconds$][transaction_codes|$transaction_codes$][notes|$notes$][last_tcl_list_name|$lastTclListName$][last_tcl_code_desc|$lastTclCodeDesc$][last_tcl_code_short|$lastTclCodeShort$][id|${id}]`
  let url = `https://vcc-na29.8x8.com/AGUI/make_call.php?prefix=1&phone=${phoneNumberOnly}&callerId=${outboundNumber}`
  if (id) {
    url += `&ctl_userdata=${ctlUserData}`
  }
  window.open(url, "_blank");
}

export function makeACallWithCallback(id, phoneNumber = "", agentOutboundCallId = "", showNotification) {
  const phoneNumberOnly = phoneNumber.substring(0, 10)
  if (!isValidPhoneNumber(agentOutboundCallId)) {
    showNotification({ message: nonConfigOutboundChannelIdMsg, type: NOTIFICATION_TYPES.WARNING })
  } else {
    window.open(`https://vcc-na29.8x8.com/AGUI/make_call.php?prefix=1&phone=${phoneNumberOnly}&callerId=${agentOutboundCallId}&ctl_userdata=[ctl_callbackUrl|${process.env.REACT_APP_FOLLOWUP_CALLBACK_URL}?call_details=${id}|$call_answered_time$|$call_hangup_time$|$callDuration$]`, "_blank");
  }
}

export function sendAEmail(emailAddress = "", senderEmail = 0) {
  if (!isValidEmail(emailAddress)) return
  window.open(`https://mail.google.com/mail/u/${senderEmail}/?view=cm&to=${emailAddress}&su=&body=`, "_blank");  //TODO: move url to environmentvariables
}

export function getNoTimeSlotText(franchise, jobStartDate, jobEndDate) {
  return (franchise) ? (jobStartDate && jobEndDate) ? `No Timeslots` : "Please select start and end time for job" : "please select a franchise"
}

export function isFranchiseCredentialsExist(franchise) {
  return (franchise?.service_titan_tenant_id && franchise?.service_titan_client_id && franchise?.service_titan_client_secret)
}

export function isAbleToNavigateToServiceTitanPage(franchise) {
  return (franchise?.service_titan_tenant_id && franchise?.service_titan_tenant_name && franchise?.service_titan_client_id && franchise?.service_titan_client_secret)
}

export function getUniqueFranchises(franchises = []) {
  let uniqueServiceTitanFranchisesMap = {};
  let uniqueVoniFranchisesMap = {};
  let otherFranchises = []; //to handle franchises where CRM is not equal to vonigo or servicetitan
  franchises.forEach((franchise,) => {
    if (franchise?.service_brand?.trim()?.toLowerCase() === CRMS?.[0]) {
      if (franchise.service_titan_tenant_id === "") {
        otherFranchises.push(franchise);
      } else {
        uniqueServiceTitanFranchisesMap[franchise.service_titan_tenant_id] = franchise;
      }
    } else if (franchise?.service_brand?.trim()?.toLowerCase() === CRMS?.[1]) {
      if (franchise?.franchise_id === "") {
        otherFranchises.push(franchise);
      } else {
        uniqueVoniFranchisesMap[franchise.franchise_id] = franchise;
      }
    }
  });
  const serviceTItanFranchises = Object.values(uniqueServiceTitanFranchisesMap);
  const vonigoFranchises = Object.values(uniqueVoniFranchisesMap);
  return [...serviceTItanFranchises, ...vonigoFranchises, ...otherFranchises]
}

export function getServiceTitanUniqueTenantFranchisesWithCredentials(franchises = []) {
  let uniqueFranchiseMap = {};
  const franchisesWithCredencials = franchises.filter((franchise) => {
    return franchise.service_titan_tenant_id !== "" && franchise.service_titan_client_id !== "" && franchise.service_titan_client_secret !== ""
  })
  franchisesWithCredencials.forEach((franchise) => {
    uniqueFranchiseMap[franchise.service_titan_tenant_id] = franchise
  });
  return Object.values(uniqueFranchiseMap);
}

export function getMatchingFranchiseByFranchiseIdOrTenantId(franchiseId, tenantId, franchises) {
  let franchise = franchises.find(franchise => franchise?.id === franchiseId)
  if (!franchise) {
    franchise = franchises.find(franchise => franchise?.service_titan_tenant_id === `${tenantId}`)
  }
  return franchise;
}

export function getMatchingCallReasonByCallReasonId(callReasonId, callReasons) {
  return callReasons.find(callReason => callReason.id === callReasonId);
}

export function getMatchingTechniciansByIds(ids = [], technicians = []) {
  if (Array.isArray(ids) && Array.isArray(technicians)) {
    const selectedTechnicians = technicians.filter((tech) => ids.some((id) => id === tech.id))
    return selectedTechnicians;
  } else {
    return []
  }
}

export function pinnedLatestFirstCompareFunction(first, second) {
  if (first.isPinned && second.isPinned) {
    if (first.createdOn > second.createdOn) {
      return -1;
    } else if (first.createdOn === second.createdOn) {
      return 0;
    } else {
      return 1
    }
  } else if (first.isPinned && !second.isPinned) {
    return -1
  } else if (!first.isPinned && second.isPinned) {
    return 1
  }
  else {
    if (first.createdOn > second.createdOn) {
      return -1;
    } else if (first.createdOn === second.createdOn) {
      return 0;
    } else {
      return 1
    }
  }
}

const MINUTES_PER_HOUR = 60
export function getDuration(start, end) {
  if (!(start && end)) return "-"
  const differenceInMinutes = moment(end).diff(moment(start), "minutes")
  if (differenceInMinutes > MINUTES_PER_HOUR) {
    const hours = Math.floor(differenceInMinutes / MINUTES_PER_HOUR);
    const minutes = differenceInMinutes % MINUTES_PER_HOUR;
    const minutesText = minutes > 0 ? `${minutes} mins` : ""
    return `${(hours)} Hours ${minutesText}`
  } else {
    return `${(differenceInMinutes)} mins`
  }
}

export function addHours(numOfHours, date = new Date()) {
  date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);
  return date;
}

export function isContactExists(contact = {}, contacts = []) {
  return contacts?.some((c) => c?.value === contact?.value && c?.type === contact?.type) && contact?.value;
}

export function isDublicateContactsExists(newContacts = [], oldContacts = []) {
  return newContacts?.some((c) => isContactExists(c, oldContacts))
}

export function canCreateSTLead(franchise) {
  return franchise?.service_brand?.trim()?.toLowerCase() === CRMS[0] && franchise?.service_titan_tenant_id && franchise?.service_titan_client_id && franchise?.service_titan_client_secret;
}

export function canCreateAJob(franchise) {
  return franchise?.service_brand?.trim()?.toLowerCase() === CRMS[1] || canCreateSTLead(franchise)
}

export function isAllRequiredJobFieldsCompleted(job) {
  return job?.campaignId && job?.businessUnitId && job?.jobTypeId && job?.priority && job?.summary
}

export function isAppointmentSectionComplete(appointment) {
  return appointment.start && appointment.start >= moment((new Date())).startOf("minute").toISOString() && appointment.end && appointment.end > appointment.start && appointment.arrivalWindowStart && appointment.arrivalWindowEnd && appointment.arrivalWindowEnd > appointment.arrivalWindowStart
}

export function getUserFullName(user) {
  return user ? `${user.first_name} ${user.last_name}` : "-"
}

export function getOnlyNumbers(value = "") {
  return value.replace(/\D/g, "")
}

export function getIsOnlyContainNumbers(phoneNumber = "") {
  return /^[0-9]+$/.test(phoneNumber);
}

export function getCredentialsMissingWarning(franchise) {
  if (franchise?.is_encryption_failed) {
    return TEMPORY_FAILURE
  } else if (franchise?.service_titan_tenant_id === "" || franchise?.service_titan_client_id === "" || franchise?.service_titan_client_secret === "") {
    return "Client id and secret are not provided"
  } else {
    return ""
  }
}

export function getIsSTCredentialsAvailable(franchise) {
  return franchise?.service_titan_client_id && franchise?.service_titan_client_secret && franchise?.service_titan_tenant_name
}

export function isDifferentArrays(array1 = [], array2 = []) {
  return JSON.stringify(array1) !== JSON.stringify(array2)
}

export function getById(id, array = []) {
  return array.find((v) => v.id === id)
}

export function getByIds(ids = [], array = []) {
  return array.filter((v) => ids.includes(v.id))
}