import { FetchClient } from "../../utils/fetchClient";

export async function getIsValidZipCodeResponse(brandId = 0, zipCodes = [], excludeFranchiseId = 0) {
    const zipCode = getZipCodeString(zipCodes)
    return FetchClient(`/data/v3/zip-code/validate?brand_id=${brandId}&zip_code=${zipCode}&exclude_franchise_id=${excludeFranchiseId}`).GET({})
}

export async function getZipCodes(brandIds = [], franchiseIds = [], zipCodes = [], removeDeactivated = 1) {
    let searchQuery = ""
    if (Array.isArray(brandIds) && brandIds.length) {
        for (const id of brandIds) {
            searchQuery += `brand_id=${id}&`
        }
    }
    if (Array.isArray(franchiseIds) && franchiseIds.length) {
        for (const id of franchiseIds) {
            searchQuery += `franchise_id=${id}&`
        }
    }
    if (Array.isArray(zipCodes) && zipCodes.length) {
        for (const id of zipCodes) {
            searchQuery += `zip_code=${id}&`
        }
    }
    searchQuery += `remove_deactivated=${removeDeactivated}`
    return FetchClient(`/data/v3/zip-codes?${searchQuery}`).GET({})
}

export async function getZipCodeDetails(zipCode = "", removeDeactivated = 1) {
    return FetchClient(`/data/v3/zip-code/details?zip_code=${zipCode}&remove_deactivated=${removeDeactivated}`).GET({})
}

function getZipCodeString(zipCodes = []) {
    let zipCode = zipCodes.join(",")
    let newStr = zipCode;
    if (zipCode?.endsWith(',')) {
        newStr = zipCode.slice(0, zipCode?.length - 1);
    }
    return newStr;
}