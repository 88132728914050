import { Box, Typography } from '@mui/material'
import React from 'react'

export default function NoAgentsForSearchMessage({ cssClass }) {
    return (
        <Box className={cssClass} >
            <Typography variant={"subtitle2"} >No Agents </Typography>
        </Box>
    )
}
