import { Button, Dialog } from "@mui/material";
import { FRANCHISE_STATUS, NOTIFICATION_TYPES } from "../../../constants/common";
import { setStatusName } from "./utils";
import { useContext,useState } from "react";
import { CommonContext } from "../../../contexts/CommonContextProvider";
import { handleEnableOrDisableFranchiseV3, handleRemoveFranchiseV3 } from "../../../state-services/franchise/v3/franchise";

export default function DeleteDialog(props) {
    const { onClose, open, franchise, action, handleRefreshData, } = props;
    const handleClose = () => onClose();

    const { showNotification } = useContext(CommonContext)

    const [isDisabledButton, setIsDisabledButton] = useState(false)

    const handleDeleteFranchise = async () => {
        try {
            setIsDisabledButton(true)
            if (action === FRANCHISE_STATUS.removed) {
                await handleRemoveFranchiseV3(franchise?.id, showNotification)
            } else {
                await handleEnableOrDisableFranchiseV3(franchise?.id, action, showNotification)
            }
            handleRefreshData()
            onClose()
        } catch (err) {
            showNotification({ isOpen: true, type: NOTIFICATION_TYPES.ERROR, message: "Important: Could not update the franchise. Please try again" })
        }finally{
            setIsDisabledButton(false)
        }
    };

    return (
        <Dialog onClose={handleClose} open={open} className="dialog-zindex">
            <div className={"dialog"}>
                <div style={{ marginTop: 25 }}>
                    {action === FRANCHISE_STATUS.removed &&
                        <>
                            ⚠️  Please note that any information related to this franchise will be permanently removed from the following areas.
                            <ul>
                                <li> Call logs (Sent jobs and Abandoned)</li>
                                <li> Reporting statistics</li>
                                <li> FAQs</li>
                                <li> ServiceTitan leads</li>
                            </ul>
                        </>
                    }
                    {action === FRANCHISE_STATUS.deleted &&
                        <>
                            ⚠️  Please note that any information related to this franchise will be removed.
                            <ul>
                                <li style={{ paddingBottom: "0.5em" }}> Franchise location’s brands including Channel IDs, Primary & Secondary Zip Codes, Entity IDs</li>
                                <li> The Lumin enable status will be changed from enabled to disabled</li>
                                <li> FAQs Related to this Franchise</li>
                            </ul>
                        </>
                    }
                </div>

                <h3 className={"dialog-title"}>
                    {` Are you sure you want to ${setStatusName(action)[0]} the franchise ?`}
                </h3>
                <p style={{ marginTop: "-10px", marginBottom: "15px" }}>
                    {franchise?.franchise_name}
                </p>
                <div style={{ display: "flex", justifyContent: "end" }}>
                    <Button disabled={isDisabledButton} size={"small"} variant="contained" onClick={handleClose} className={"btn-secondary"} style={{ marginRight: "10px" }} >
                        No
                    </Button>
                    <Button disabled={isDisabledButton} size={"small"} variant="contained" onClick={handleDeleteFranchise} className={"btn-primary"} >
                        yes
                    </Button>
                </div>
            </div>
        </Dialog>
    );
}
