import React, { createContext, useState } from "react";

export const UserContext = createContext({});

export default function UserContextProvider(props) {
	const [me, setMe] = useState({});
	const [userPreferences, setUserPreferences] = useState({});

	return (
		<UserContext.Provider
			value={{ 
				me, 
				setMe, 
				userPreferences, 
				setUserPreferences,
			}}
		>
			{props.children}
		</UserContext.Provider>
	);
}
