
export const WORKORDER_DURATIONS = [0.5, 1.0, 1.5, 2.0, 2.5, 3.0, 3.5, 4.0, 4.5, 5.0, 5.5, 6.0, 6.5, 7.0, 7.5, 8.0, 8.5, 9.0, 9.5, 10.0, 10.5, 11.0, 11.5, 12.0, 12.5, 13.0, 13.5, 14.0, 14.5, 15.0, 15.5, 16.0, 16.5, 17.0, 17.5, 18.0, 18.5, 19.0, 19.5, 20.0, 20.5,
    21.0, 21.5, 22.0, 22.5, 23.0, 23.5, 24.0]
export const TEST_ROUTE = { route: "Test Route", id: "12345" }
export const TEST_TIME = { time: "490", routes: [TEST_ROUTE] }
export const MUNITUES_PER_HOUR = 60


export const DURATION = [
    {
        franchiseId: 453,
        duration: 2,
    },
    {
        franchiseId: 398,
        duration: 2,
    },
    {
        franchiseId: 294,
        duration: 2,
    },
    {
        franchiseId: 241,
        duration: 1.5,
    },
    {
        franchiseId: 272,
        duration: 1.5,
    },
    {
        franchiseId: 1030369576,
        duration: 2,
    },
    {
        franchiseId: 352,
        duration: 2,
    },
    {
        franchiseId: 84,
        duration: 3,
    },
    {
        franchiseId: 383,
        duration: 2,
    },
    {
        franchiseId: 370,
        duration: 2,
    },
    {
        franchiseId: 370,
        duration: 2,
    },
    {
        franchiseId: 225,
        duration: 2,
    },
    {
        franchiseId: 273,
        duration: 2,
    },
    {
        franchiseId: 355,
        duration: 1.5,
    },
    {
        franchiseId: 304,
        duration: 2,
    },
    {
        franchiseId: 320,
        duration: 2,
    },
    {
        franchiseId: 425,
        duration: 2,
    },
    {
        franchiseId: 445,
        duration: 2,
    },
    {
        franchiseId: 1028138916,
        duration: 1,
    },
    {
        franchiseId: 411,
        duration: 2,
    },
    {
        franchiseId: 1030369571,
        duration: 2,
    },
    {
        franchiseId: 446,
        duration: 3,
    },
    {
        franchiseId: 1028138916,
        duration: 1,
    },
    {
        franchiseId: 438,
        duration: 2,
    },
    {
        franchiseId: 300,
        duration: 2,
    },
    {
        franchiseId: 403,
        duration: 2,
    },
    {
        franchiseId: 372,
        duration: 1.5,
    },
    {
        franchiseId: 21,
        duration: 2,
    },
    {
        franchiseId: 396,
        duration: 2,
    },
    {
        franchiseId: 432,
        duration: 2,
    },
    {
        franchiseId: 379,
        duration: 1,
    },
    {
        franchiseId: 271,
        duration: 1,
    },
    {
        franchiseId: 442,
        duration: 2,
    },
    {
        franchiseId: 180,
        duration: 2,
    },
    {
        franchiseId: 180,
        duration: 2,
    },
    {
        franchiseId: 356,
        duration: 2.5,
    },
    {
        franchiseId: 238,
        duration: 2,
    },
    {
        franchiseId: 202,
        duration: 2,
    },
    {
        franchiseId: 108,
        duration: 2,
    },
    {
        franchiseId: 192,
        duration: 2,
    },
    {
        franchiseId: 359,
        duration: 0.5,
    },
    {
        franchiseId: 424,
        duration: 2,
    },
    {
        franchiseId: 298,
        duration: 3,
    },
    {
        franchiseId: 312,
        duration: 2,
    },
    {
        franchiseId: 283,
        duration: 2,
    },
    {
        franchiseId: 172,
        duration: 2,
    },
    {
        franchiseId: 369,
        duration: 2,
    },
    {
        franchiseId: 338,
        duration: 2,
    },
    {
        franchiseId: 319,
        duration: 2,
    },
    {
        franchiseId: 366,
        duration: 2,
    },
    {
        franchiseId: 338,
        duration: 2,
    },
    {
        franchiseId: 371,
        duration: 2,
    },
    {
        franchiseId: 1028404647,
        duration: 1,
    },
    {
        franchiseId: 421,
        duration: 2,
    },
    {
        franchiseId: 247,
        duration: 1,
    },
    {
        franchiseId: 247,
        duration: 1,
    },
    {
        franchiseId: 191,
        duration: 1.5,
    },
    {
        franchiseId: 247,
        duration: 1,
    },
    {
        franchiseId: 875355683,
        duration: 2,
    },
    {
        franchiseId: 217,
        duration: 2,
    },
    {
        franchiseId: 435,
        duration: 1.5,
    },
    {
        franchiseId: 217,
        duration: 2,
    },
    {
        franchiseId: 303,
        duration: 2,
    },
    {
        franchiseId: 352,
        duration: 2,
    },
    {
        franchiseId: 84,
        duration: 3,
    },
    {
        franchiseId: 352,
        duration: 2,
    },
    {
        franchiseId: 280,
        duration: 2,
    },
    {
        franchiseId: 242,
        duration: 2,
    },
    {
        franchiseId: 464,
        duration: 2,
    },
    {
        franchiseId: 464,
        duration: 2,
    },
    {
        franchiseId: 875770147,
        duration: 1,
    },
    {
        franchiseId: 400,
        duration: 2,
    },
    {
        franchiseId: 1028946468,
        duration: 1,
    },
    {
        franchiseId: 1018277818,
        duration: 2,
    },
    {
        franchiseId: 240,
        duration: 1.5,
    },
    {
        franchiseId: 117,
        duration: 1,
    },
    {
        franchiseId: 234,
        duration: 2,
    },
    {
        franchiseId: 244,
        duration: 2,
    },
    {
        franchiseId: 466,
        duration: 2,
    },
    {
        franchiseId: 101,
        duration: 2,
    },
    {
        franchiseId: 326,
        duration: 1,
    },
    {
        franchiseId: 267,
        duration: 2,
    },
    {
        franchiseId: 462,
        duration: 2,
    },
    {
        franchiseId: 1018346531,
        duration: 1,
    },
    {
        franchiseId: 178,
        duration: 1,
    },
    {
        franchiseId: 1030338595,
        duration: 2,
    },
    {
        franchiseId: 1030338595,
        duration: 2,
    },
    {
        franchiseId: 90,
        duration: 2,
    },
    {
        franchiseId: 407,
        duration: 2,
    },
    {
        franchiseId: 354,
        duration: 2,
    },
    {
        franchiseId: 245,
        duration: 2,
    },
    {
        franchiseId: 189,
        duration: 2,
    },
    {
        franchiseId: 439,
        duration: 2,
    },
    {
        franchiseId: 190,
        duration: 2,
    },
    {
        franchiseId: 83,
        duration: 2,
    },
    {
        franchiseId: 393,
        duration: 1,
    },
    {
        franchiseId: 434,
        duration: 2,
    },
    {
        franchiseId: 335,
        duration: 1.5,
    },
    {
        franchiseId: 265,
        duration: 2,
    },
    {
        franchiseId: 265,
        duration: 2,
    },
    {
        franchiseId: 220,
        duration: 2,
    },
    {
        franchiseId: 295,
        duration: 2,
    },
    {
        franchiseId: 181,
        duration: 2,
    },
    {
        franchiseId: 81,
        duration: 2,
    },
    {
        franchiseId: 275,
        duration: 2,
    },
    {
        franchiseId: 279,
        duration: 3.5,
    },
    {
        franchiseId: 115,
        duration: 2,
    },
    {
        franchiseId: 115,
        duration: 2,
    },
    {
        franchiseId: 228,
        duration: 1,
    },
    {
        franchiseId: 228,
        duration: 1,
    },
    {
        franchiseId: 313,
        duration: 2,
    },
    {
        franchiseId: 437,
        duration: 2,
    },
    {
        franchiseId: 159,
        duration: 1,
    },
    {
        franchiseId: 159,
        duration: 1,
    },
    {
        franchiseId: 330,
        duration: 1,
    },
    {
        franchiseId: 323,
        duration: 2,
    },
    {
        franchiseId: 271,
        duration: 1,
    },
    {
        franchiseId: 114,
        duration: 2,
    },
    {
        franchiseId: 290,
        duration: 0.5,
    },
    {
        franchiseId: 290,
        duration: 0.5,
    },
    {
        franchiseId: 472,
        duration: 2,
    },
    {
        franchiseId: 227,
        duration: 1,
    },
    {
        franchiseId: 227,
        duration: 1,
    },
    {
        franchiseId: 288,
        duration: 2,
    },
    {
        franchiseId: 385,
        duration: 2.5,
    },
    {
        franchiseId: 316,
        duration: 1,
    },
    {
        franchiseId: 357,
        duration: 2,
    },
    {
        franchiseId: 875895459,
        duration: 1,
    },
    {
        franchiseId: 187,
        duration: 2,
    },
    {
        franchiseId: 875895459,
        duration: 1,
    },
    {
        franchiseId: 229,
        duration: 2,
    },
    {
        franchiseId: 216,
        duration: 1.5,
    },
    {
        franchiseId: 223,
        duration: 2,
    },
    {
        franchiseId: 187,
        duration: 2,
    },
    {
        franchiseId: 397,
        duration: 2,
    },
    {
        franchiseId: 236,
        duration: 2,
    },
    {
        franchiseId: 274,
        duration: 1.5,
    },
    {
        franchiseId: 274,
        duration: 1.5,
    },
    {
        franchiseId: 274,
        duration: 1.5,
    },
    {
        franchiseId: 404,
        duration: 2,
    },
    {
        franchiseId: 363,
        duration: 2,
    },
    {
        franchiseId: 177,
        duration: 2,
    },
    {
        franchiseId: 270,
        duration: 2,
    },
    {
        franchiseId: 430,
        duration: 2,
    },
    {
        franchiseId: 292,
        duration: 2,
    },
    {
        franchiseId: 195,
        duration: 2,
    },
    {
        franchiseId: 243,
        duration: 2,
    },
    {
        franchiseId: 157,
        duration: 2,
    },
    {
        franchiseId: 212,
        duration: 2,
    },
    {
        franchiseId: 390,
        duration: 2,
    },
    {
        franchiseId: 441,
        duration: 2,
    },
    {
        franchiseId: 322,
        duration: 1,
    },
    {
        franchiseId: 333,
        duration: 2,
    },
    {
        franchiseId: 284,
        duration: 1.5,
    },
    {
        franchiseId: 268,
        duration: 2,
    },
    {
        franchiseId: 134,
        duration: 2.5,
    },
    {
        franchiseId: 246,
        duration: 1.5,
    },
    {
        franchiseId: 239,
        duration: 2,
    },
    {
        franchiseId: 1018346536,
        duration: 2,
    },
    {
        franchiseId: 328,
        duration: 2,
    },
    {
        franchiseId: 386,
        duration: 2,
    },
    {
        franchiseId: 1018277813,
        duration: 1.5,
    },
    {
        franchiseId: 405,
        duration: 1.5,
    },
    {
        franchiseId: 163,
        duration: 2,
    },
    {
        franchiseId: 243,
        duration: 2,
    },
    {
        franchiseId: 395,
        duration: 1,
    },
    {
        franchiseId: 193,
        duration: 2,
    },
    {
        franchiseId: 318,
        duration: 2,
    },
    {
        franchiseId: 452,
        duration: 2,
    },
    {
        franchiseId: 374,
        duration: 2,
    },
    {
        franchiseId: 274,
        duration: 1.5,
    },
    {
        franchiseId: 306,
        duration: 2,
    },
    {
        franchiseId: 875903139,
        duration: 1,
    },
    {
        franchiseId: 875903139,
        duration: 1,
    },
    {
        franchiseId: 412,
        duration: 2,
    },
    {
        franchiseId: 1018335779,
        duration: 1,
    },
    {
        franchiseId: 412,
        duration: 2,
    },
    {
        franchiseId: 324,
        duration: 0.5,
    },
    {
        franchiseId: 364,
        duration: 2,
    },
    {
        franchiseId: 104,
        duration: 2,
    },
    {
        franchiseId: 320,
        duration: 0.5,
    },
    {
        franchiseId: 320,
        duration: 0.5,
    },
    {
        franchiseId: 320,
        duration: 0.5,
    },
    {
        franchiseId: 320,
        duration: 0.5,
    },
    {
        franchiseId: 320,
        duration: 0.5,
    },
    {
        franchiseId: 320,
        duration: 0.5,
    },
    {
        franchiseId: 450,
        duration: 2,
    },
    {
        franchiseId: 320,
        duration: 0.5,
    },
    {
        franchiseId: 164,
        duration: 2,
    },
    {
        franchiseId: 320,
        duration: 0.5,
    },
    {
        franchiseId: 373,
        duration: 2,
    },
    {
        franchiseId: 235,
        duration: 1.5,
    },
    {
        franchiseId: 314,
        duration: 2,
    },
    {
        franchiseId: 156,
        duration: 2,
    },
    {
        franchiseId: 156,
        duration: 2,
    },
    {
        franchiseId: 10,
        duration: 2,
    },
    {
        franchiseId: 277,
        duration: 2,
    },
    {
        franchiseId: 149,
        duration: 2,
    },
    {
        franchiseId: 201,
        duration: 2,
    },
    {
        franchiseId: 93,
        duration: 1,
    },
    {
        franchiseId: 171,
        duration: 2,
    },
    {
        franchiseId: 179,
        duration: 1.5,
    },
    {
        franchiseId: 435,
        duration: 2,
    },
    {
        franchiseId: 188,
        duration: 2,
    },
    {
        franchiseId: 87,
        duration: 2,
    },
    {
        franchiseId: 326,
        duration: 1,
    },
    {
        franchiseId: 449,
        duration: 2,
    },
    {
        franchiseId: 463,
        duration: 2,
    },
    {
        franchiseId: 470,
        duration: 2,
    },
    {
        franchiseId: 320,
        duration: 1,
    },
    {
        franchiseId: 474,
        duration: 2,
    },
    {
        franchiseId: 471,
        duration: 2.5,
    },
    {
        franchiseId: 475,
        duration: 2,
    },
    {
        franchiseId: 480,
        duration: 2,
    },
    {
        franchiseId: 480,
        duration: 2,
    },
]
