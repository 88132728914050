import PhoneNumberBox from "../../../../../components/PhoneNumberBox"
import { imposibleToMatchRegex, isValidPhoneNumber } from "../../../../../utils/validators"
export default function PhoneNumberField(props) {
    const { isRequired, isDisabled, value, onChange, label } = props
    const isPhoneNumberValid = isValidPhoneNumber(value) || !value
    const inputPattern = isPhoneNumberValid ? undefined : imposibleToMatchRegex

    return <PhoneNumberBox
        inputProps={{ pattern: inputPattern }}
        disabled={isDisabled}
        required={isRequired}
        error={!isPhoneNumberValid}
        helperText={isPhoneNumberValid ? "" : "Invalid Phone Number"}
        label={label ?? "Phone"}
        variant="outlined"
        size="small"
        style={{ flexGrow: "1" }}
        value={value}
        onChange={(e) => {
            onChange(e?.target?.value)
        }} />
}