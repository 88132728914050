import { Autocomplete, Box, Button, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import { useContext, useEffect, useMemo, useState } from "react";
import { handleGetAllBrands } from "../../../state-services-dublicate/brandServices";
import { handleGetBrandFranchises, handleGetFranchiseBrands } from "../../../state-services/franchise";
import RenderInput, { renderBrandOption } from "../../../components/servicetitan/RenderInput";
import { getChannelOptionLabel, renderFranchiseOption } from "../utils/franchise";
import { CustomTextField } from "../user/userCustomStyle";
import CustomDatePicker from "../../../components/CustomDatePicker";

import CustomSelect from "../../../components/CustomSelect";
import { getPhoneNumberWithInputMask } from "../../../utils/inputMasks";
import PhoneStatistics from "./components/PhoneStatistics";
import SMSStatistics from "./components/SMSStatistics";
import EmailStatistics from "./components/EmailStatistics";
import ChatStatistics from "./components/ChatStatistics";
import VonigoStatistics from "./components/VonigoStatistics";
import ServicetitanStatistics from "./components/ServicetitanStatistics";
import EmailReportTable from "./components/EmailReportTable";
import PhoneReportTable from "./components/PhoneReportTable";
import SMSReportTable from "./components/SMSReportTable";
import ChatReportTable from "./components/ChatReportTable";
import Chart from "./components/Chart";
import ReportCard from "./components/ReportCard";
import ReportTile from "./components/ReportTile";
import vonigoImg from "../../../assets/img/vonig_logo.png";
import { getTimeZonedDate } from "../../../utils/time/time";
import { handleGetFranchiseBrandChannels } from "../../../state-services/channelIds/channelIds";
import { AVAILABLE_CRMS, STATUS_EMOJI } from "../../../constants/common";
import { CommonContext } from "../../../contexts/CommonContextProvider";
import NoContentView from "../../../components/refreshView/NoContentView";
import ImageBox from "../../../components/LogoBox/ImageBox";
import RealTimeMetrics from "./components/RealTimeMetrics";
import { handleGetAgents } from "../../../state-services-dublicate/userServices";
import BrandStatistics from "./components/BrandStatistics";
import StackFranchisesStatistics from "./components/StackFranchisesStatistics";
import MiddlewearStatistics from "./components/MiddlewearStatistics";
import { HandleDownloadDataReport, HandleDownloadStatisticsReport } from "./components/HandleDownloadReport";

export const REPORT_DATE_RANGES = {
    YESTERDAY: {
        index: 0,
        label: "Yesterday",
        diffFromNow: 1
    },
    LAST_7_DAYS: {
        index: 1,
        label: "Last 7 days",
        diffFromNow: 7
    },
    LAST_14_DAYS: {
        index: 2,
        label: "Last 14 days",
        diffFromNow: 14
    },
    LAST_30_DAYS: {
        index: 3,
        label: "Last 30 days",
        diffFromNow: 30
    },
    CUSTOM_RANGE: {
        index: 4,
        label: "Custom Range",
        diffFromNow: 1
    }
}

const CRM_TYPES = {
    SERVICE_TITAN: {
        index: 0,
        title: "Service Titan",
    },
    VONIGO: {
        index: 1,
        title: "Vonigo",
    },
}

const REPORT_TABLES = {
    PHONE: {
        index: 0,
        title: "Phone",
    },
    SMS: {
        index: 1,
        title: "SMS",
    },
    EMAIL: {
        index: 2,
        title: "Email",
    },
    CHAT: {
        index: 3,
        title: "Chat",
    },
}

const REPORT_SECTIONS = {
    STATISTICS: {
        index: 0,
        title: "Statistics",
    },
    DATA: {
        index: 1,
        title: "Data",
    },
    REAL_TIME_METRICS: {
        index: 2,
        title: "Real Time Metrics",
    },
    MIDDLEWEAR_STATISTICS: {
        index: 3,
        title: "Middleware Statistics",
    },
}

export const STATISTICS_SECTIONS = {
    EIGHT_BY_: {
        index: 0,
        section: "eight_by_eight",
        title: "8x8",
    },
    CRM: {
        index: 1,
        section: "crm",
        title: "CRM",
    }
}

const INITIAL_FILTRATION = {
    startDate: getDayBeforeDateISOString(new Date(), "America/Montreal", 1),
    endDate: getDayBeforeEndDateISOString(new Date(), "America/Montreal", 1),
    brandImg: "",
    brandId: 0,
    brandBrandId: 0,
    brandName: "",
    franchiseId: 0,
    franchise: null,
    channelId: ""
}

function getDayBeforeDateISOString(date, timeZone, subtraction = 1) {
    return getTimeZonedDate(date, timeZone).subtract(subtraction, "day").startOf("d").toISOString()
}

function getDayBeforeEndDateISOString(date, timeZone, subtraction = 0) {
    return getTimeZonedDate(date, timeZone).subtract(subtraction, "day").endOf("d").toISOString()
}

let selectedDownloadReportTab = "";
let statisticsReportData = {};

export default function Report() {
    const [filtration, setFiltration] = useState(INITIAL_FILTRATION)
    const [brands, setBrands] = useState([])
    const [isBrandsLoading, setIsBrandsLoading] = useState(false)
    const [franchises, setFranchises] = useState([])
    const [isFranchisesLoading, setIsFranchisesLoading] = useState(false)
    const [channels, setChannels] = useState([])
    const [franchiseBrands, setFranchiseBrands] = useState([])
    const [agents, setAgents] = useState([])
    const [isChannelsLoading, setIsChannelsLoading] = useState(false)
    const [crmType, setCrmType] = useState(CRM_TYPES.SERVICE_TITAN)
    const [selectedTab, setSelectedTab] = useState(REPORT_SECTIONS.STATISTICS)
    const [range, setRange] = useState(REPORT_DATE_RANGES.YESTERDAY)
    const [statisticsSection, setStatisticsSection] = useState(STATISTICS_SECTIONS.EIGHT_BY_.section)
    const [reportData, setReportData] = useState()

    useEffect(() => {
        handleGetAllBrands(undefined, undefined, setBrands, () => { }, setIsBrandsLoading)
        handleGetAgents(setAgents)
    }, [])

    useEffect(() => {
        handleGetBrandFranchises(filtration.brandBrandId, setFranchises, setIsFranchisesLoading)
    }, [filtration.brandBrandId])

    useEffect(() => {
        if (franchises.length > 0) {
            let franchiseIds = franchises.filter((franchise) => franchise.is_stack).map((franchise) => franchise.id)
            handleGetFranchiseBrands(franchiseIds, setFranchiseBrands)
        }
    }, [franchises])

    useEffect(() => {
        handleGetFranchiseBrandChannels(filtration.brandBrandId, filtration.franchiseId, setChannels, setIsChannelsLoading)
    }, [filtration.brandBrandId, filtration.franchiseId])

    function getSelectedTabContent() {
        switch (selectedTab.index) {
            case REPORT_SECTIONS.STATISTICS.index:
                return <Box sx={{ display: "flex", flexDirection: "column", gap: "1em" }} >
                    {statisticsSection === STATISTICS_SECTIONS.EIGHT_BY_.section &&
                        <EightByEightStatistics filtration={filtration} brands={brands} />
                    }
                    {statisticsSection === STATISTICS_SECTIONS.CRM.section &&
                        <CRMStatistics filtration={filtration} crmType={crmType} franchises={franchises} brands={brands} franchiseBrands={franchiseBrands} />
                    }
                </Box>
            case REPORT_SECTIONS.DATA.index:
                return <Box sx={{ flexGrow: 2 }} >
                    <ReportTable brands={brands} isBrandsLoading={isBrandsLoading} franchises={franchises} isFranchisesLoading={isFranchisesLoading} channels={channels} isChannelsLoading={isChannelsLoading} filtration={filtration} setFiltration={setFiltration} range={range} setRange={setRange} statisticsSection={statisticsSection} crmType={crmType} setCrmType={setCrmType} reportData={reportData} setReportData={setReportData} />
                </Box>
            case REPORT_SECTIONS.REAL_TIME_METRICS.index:
                return <Box sx={{ flexGrow: 2 }} >
                    <RealTimeMetrics agents={agents} />
                </Box>
            case REPORT_SECTIONS.MIDDLEWEAR_STATISTICS.index:
                return <Box sx={{ flexGrow: 2 }} >
                    <MiddlewearStatistics />
                </Box>
            default:
                <></>
        }
    }

    const isReportDataEmpty = reportData?.total_count === 0;
    const isStatisticsDataEmpty = Object.values(statisticsReportData).every(
        (value) => !value || (Array.isArray(value) && value.length === 0) || (typeof value === 'object' && Object.keys(value).length === 0)
    );

    return (
        <>
            <Box sx={{ height: "100%", padding: "0.5em", borderRadius: "0.5em", display: "flex", flexDirection: "column", gap:"0.2em" }} >
                <Box className={"tabs"} sx={{ display: "flex", flexDirection: "row" }}>
                    {Object.values(REPORT_SECTIONS).map((tab) => {
                        return <Button
                            key={tab.index}
                            onClick={() => setSelectedTab(tab)}
                            size={"small"}
                            variant="contained"
                            className={tab === selectedTab ? "btn-primary tab" : "btn-secondary tab"}
                        >{tab.title}</Button>
                    })}
                    {selectedTab.title === REPORT_SECTIONS.REAL_TIME_METRICS.title &&
                        <Tooltip title="Time Zone" placement="left" >
                            <Typography sx={{ marginLeft: "auto", marginRight: "35px" }} variant="h6">America/NewYork</Typography>
                        </Tooltip>
                    }
                </Box>
                <Box className="tabs" sx={{display: "flex", flexDirection: "row" }}>
                    {selectedTab.title === REPORT_SECTIONS.STATISTICS.title &&
                        <FormControl>
                            <RadioGroup
                                row
                                value={statisticsSection}
                                name="type-radio-buttons-group"
                                onChange={(e) => (setStatisticsSection(e?.target?.value))}>
                                {Object.values(STATISTICS_SECTIONS).map((t) => <FormControlLabel
                                    key={t.index}
                                    value={t.section}
                                    control={<Radio sx={{ '&, &.Mui-checked': { color: '#00a79d', }, "&.Mui-disabled": { color: "grayText" } }} inputProps={{ "data-test": `faq_type_${t.section}` }} />}
                                    label={t.title}
                                />)}
                            </RadioGroup>
                        </FormControl>
                    }
                    {selectedTab.title === REPORT_SECTIONS.DATA.title &&
                        <Box sx={{ marginLeft: "auto", marginRight: "15px" }}>
                            <Button
                                onClick={() => HandleDownloadDataReport(reportData, selectedDownloadReportTab, REPORT_TABLES, filtration)}
                                disabled={isReportDataEmpty}
                                className={isReportDataEmpty ? "btn-secondary" : "btn-primary"} >
                                Download Report
                            </Button>
                        </Box>
                    }
                    {selectedTab.title === REPORT_SECTIONS.STATISTICS.title &&
                        <Box sx={{ marginLeft: "auto", marginRight: "15px" }}>
                            <Button
                                onClick={() => HandleDownloadStatisticsReport(statisticsReportData, selectedDownloadReportTab, STATISTICS_SECTIONS, filtration)}
                                disabled={isStatisticsDataEmpty}
                                className={"btn-primary"} >
                                Download Report
                            </Button>
                        </Box>
                    }
                </Box>
                {selectedTab.title === REPORT_SECTIONS.STATISTICS.title &&
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "start", gap: "0.5em" }} >
                        <FiltrationArea brands={brands} isBrandsLoading={isBrandsLoading} franchises={franchises} isFranchisesLoading={isFranchisesLoading} channels={channels} isChannelsLoading={isChannelsLoading} filtration={filtration} setFiltration={setFiltration} range={range} setRange={setRange} selectedTableTab={{ title: "Statistics", }} statisticsSection={statisticsSection} crmType={crmType} setCrmType={setCrmType} />
                    </Box>
                }
                {getSelectedTabContent()}
            </Box>
        </>
    )
}

function ReportTable(props) {
    const { brands, isBrandsLoading, franchises, isFranchisesLoading, channels, isChannelsLoading, filtration, setFiltration, range, setRange, statisticsSection, crmType, setCrmType, reportData, setReportData } = props
    const [selectedTableTab, setSelectedTableTab] = useState(REPORT_TABLES.PHONE)
    selectedDownloadReportTab = selectedTableTab.title;

    function getREportDataTable() {
        switch (selectedTableTab) {
            case REPORT_TABLES.PHONE:
                return <PhoneReportTable filtration={filtration} reportData={reportData} setReportData={setReportData} />
            case REPORT_TABLES.SMS:
                return <SMSReportTable filtration={filtration} reportData={reportData} setReportData={setReportData} />
            case REPORT_TABLES.EMAIL:
                return <EmailReportTable filtration={filtration} reportData={reportData} setReportData={setReportData} />
            case REPORT_TABLES.CHAT:
                return <ChatReportTable filtration={filtration} reportData={reportData} setReportData={setReportData} />
            default:
                return <></>
        }
    }

    return <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }} >
        <Box className={"tabs"}>
            {Object.values(REPORT_TABLES).map((tab) => {
                return <Button
                    key={tab.index}
                    onClick={() => setSelectedTableTab(tab)}
                    size={"small"}
                    variant="contained"
                    className={tab === selectedTableTab ? "btn-primary tab" : "btn-secondary tab"}
                >{tab.title}</Button>
            })}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "start", gap: "0.5em" }} >
            <FiltrationArea brands={brands} isBrandsLoading={isBrandsLoading} franchises={franchises} isFranchisesLoading={isFranchisesLoading} channels={channels} isChannelsLoading={isChannelsLoading} filtration={filtration} setFiltration={setFiltration} range={range} setRange={setRange} selectedTableTab={selectedTableTab} statisticsSection={statisticsSection} crmType={crmType} setCrmType={setCrmType} />
        </Box>
        <Box sx={{ flexGrow: 2 }}>
            {getREportDataTable()}
        </Box>
    </Box>
}

function CRMStatistics(props) {
    const { filtration, crmType, franchises, brands, franchiseBrands } = props

    function getCRMSectionLabel(label, isEnabled) {
        const statusSymbol = isEnabled ? STATUS_EMOJI.active : STATUS_EMOJI.inactive
        return `${statusSymbol}  ${label}`
    }

    function getStackFranchises() {
        return franchises.filter((franchise) => franchise?.is_stack)
    }

    function getBrandsOfStackFranchise() {
        if (filtration?.franchise?.is_stack) {
            let brandIds = []
            for (let j = 0; j < franchiseBrands?.length; j++) {
                if (filtration?.franchise?.id === franchiseBrands[j]?.franchise_id) {
                    brandIds.push(franchiseBrands[j]?.brand_id)
                }
            }
            let filterdBrandIds = brandIds?.filter((id) => id !== filtration?.brandBrandId)
            let brandImages = []
            for (let k = 0; k < filterdBrandIds?.length; k++) {
                for (let l = 0; l < brands?.length; l++) {
                    if (filterdBrandIds[k] === brands[l]?.brand_id) {
                        brandImages.push(brands[l]?.brand_img)
                    }
                }
            }
            return brandImages
        } else {
            return []
        }
    }

    selectedDownloadReportTab = STATISTICS_SECTIONS.CRM.title;
    const nonStackFranchises = [];
    for (const franchise of franchises) {
        if (franchise.is_stack === 0) {
            nonStackFranchises.push(franchise);
        }
    }
    const nonStackFranchisesCount = nonStackFranchises?.length ?? 0;

    const [statisticsST, setStatisticsST] = useState(null);
    const handleStatisticsChangeST = (newStatistics) => {
        setStatisticsST(newStatistics);
    };

    const [statisticsVonigo, setStatisticsVonigo] = useState(null);
    const handleStatisticsChangeVonigo = (newStatistics) => {
        setStatisticsVonigo(newStatistics);
    };

    const [statisticsBrand, setStatisticsBrand] = useState(null);
    const handleStatisticsChangeBrand = (newStatistics) => {
        setStatisticsBrand(newStatistics);
    };

    const [statisticsStackFranchise, setStatisticsStackFranchise] = useState(null);
    const handleStackFranchisesStatisticsChange = (data) => {
        setStatisticsStackFranchise(data);
    };

    statisticsReportData = {
        statisticsST,
        statisticsVonigo,
        statisticsBrand,
        statisticsStackFranchise,
        nonStackFranchisesCount,
        crmType,
    };

    return <Box>
        {(filtration?.franchiseId) ?
            <>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <Typography variant="h6" >CRM Statistics of {filtration?.franchise?.is_stack ? "Stacked" : ""} Franchise </Typography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row" }} >
                    {(crmType === CRM_TYPES.SERVICE_TITAN) &&
                        <Box sx={{ display: "flex", width: "100%", padding: "10px", flexDirection: "column", marginRight: "20px", gap: "0.5em", backgroundColor: 'reports.main' }}>
                            <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                <Typography variant="subtitle1" >{getCRMSectionLabel("ServiceTitan", (!filtration?.franchise || filtration?.franchise?.service_brand === AVAILABLE_CRMS.servicetitan.value))}</Typography>
                                <Box sx={{ display: "flex", flexDirection: "row", gap: "10px", marginLeft: "auto" }}>
                                    {getBrandsOfStackFranchise()?.map((brand, index) => {
                                        return <Box sx={{ marginBottom: "10px", marginLeft: "10px" }}>
                                            <ImageBox src={brand} size={'medium'} />
                                        </Box>
                                    })}
                                </Box>
                            </Box>
                            <Box sx={{ width: "100%", backgroundColor: 'reports.main', padding: "1em" }}>
                                <ServicetitanStatistics filtration={filtration} onStatisticsChangeST={handleStatisticsChangeST} />
                            </Box>
                        </Box>
                    }
                    {(crmType === CRM_TYPES.VONIGO) &&
                        <Box sx={{ display: "flex", width: "100%", padding: "10px", flexDirection: "column", marginRight: "20px", gap: "0.5em", backgroundColor: 'reports.main' }} >
                            <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                <Typography variant="subtitle1" >{getCRMSectionLabel("Vonigo", (!filtration?.franchise || filtration?.franchise?.service_brand === AVAILABLE_CRMS.vonigo.value))}</Typography>
                                {vonigoImg ? <img alt={"Vonigo"} style={{ height: "30px", marginLeft: "auto" }} src={vonigoImg} /> : <></>}
                            </Box>
                            <Box sx={{ width: "100%", backgroundColor: 'reports.main', padding: "1em" }}>
                                <VonigoStatistics filtration={filtration} onStatisticsChangeVonigo={handleStatisticsChangeVonigo} />
                            </Box>
                        </Box>
                    }
                </Box>
            </>
            :
            filtration?.brandId ?
                <>
                    <div>
                        <div style={{ display: "flex", marginTop: "2px", marginBottom: "15px" }}>
                            <strong> Active Franchises : </strong> {franchises?.length ?? 0}
                        </div>
                    </div>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="h6" >CRM Statistics of Brand (Without Stacked Franchises)</Typography>
                        <Typography sx={{ marginRight: "15px" }} ><strong>Total: </strong> {nonStackFranchises?.length ?? 0}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Box sx={{ width: "100%", backgroundColor: 'reports.main', padding: "1em" }}>
                            <BrandStatistics filtration={filtration} brands={brands} onStatisticsChangeBrand={handleStatisticsChangeBrand} />
                        </Box>
                    </Box>
                    {franchiseBrands?.length > 0 &&
                        <Box>
                            {getStackFranchises()?.length > 0 &&
                                <>
                                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                        <Typography variant="h6" >CRM Statistics of Stacked Franchises</Typography>
                                        <Typography sx={{ marginRight: "15px" }} ><strong>Total: </strong> {statisticsStackFranchise?.length ?? 0}</Typography>
                                    </Box>
                                    <StackFranchisesStatistics filtration={filtration} stackFranchises={getStackFranchises()} brands={brands} franchiseBrands={franchiseBrands} onStackFranchisesStatisticsChange={handleStackFranchisesStatisticsChange} />
                                </>
                            }
                        </Box>
                    }
                </>
                :
                <NoContentView message={"Please select a brand or franchise to view CRM statistics"} />
        }
    </Box>
}

export const INITIAL_SELECTED_DATA = {
    title: "",
    labels: [],
    series: [],
    mediaReport: {
        accepted: 0,
        hold: 0,
        rejected: 0,
        rejected_timeout: 0,
    }
}

function EightByEightStatistics(props) {
    const { filtration, brands } = props

    const [selectedData, setSelectedData] = useState(INITIAL_SELECTED_DATA)

    const [smsStats, setSmsStats] = useState(null);
    const [emailStats, setEmailStats] = useState(null);
    const [chatStats, setChatStats] = useState(null);
    const [phoneStats, setPhoneStats] = useState(null);

    const handleSmsStatisticsChange = (data) => { setSmsStats(data); };
    const handleEmailStatisticsChange = (data) => { setEmailStats(data); };
    const handleChatStatisticsChange = (data) => { setChatStats(data); };
    const handlePhoneStatisticsChange = (data) => { setPhoneStats(data); };

    statisticsReportData = {
        smsStats,
        emailStats,
        chatStats,
        phoneStats,
    };

    selectedDownloadReportTab = STATISTICS_SECTIONS.EIGHT_BY_.title;
    return <Box>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
            <Typography variant="h6" >8x8 Statistics</Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", gap: "0.5em" }} >
            <Box sx={{ flexGrow: 2, display: "flex", flexDirection: "column", gap: "0.5em", flexWrap: "wrap" }} >
                <PhoneStatistics filtration={filtration} brands={brands} setSelectedData={setSelectedData} selectedData={selectedData} onPhoneStatisticsChange={handlePhoneStatisticsChange} />
                <Box sx={{ display: "flex", flexDirection: "row", gap: "0.5em", flexWrap: "wrap" }} >
                    <SMSStatistics filtration={filtration} setSelectedData={setSelectedData} selectedData={selectedData} onSmsStatisticsChange={handleSmsStatisticsChange} />
                    <EmailStatistics filtration={filtration} setSelectedData={setSelectedData} selectedData={selectedData} onEmailStatisticsChange={handleEmailStatisticsChange} />
                    <ChatStatistics filtration={filtration} setSelectedData={setSelectedData} selectedData={selectedData} onChatStatisticsChange={handleChatStatisticsChange} />
                </Box>
            </Box>
            <Box sx={{ display: { xs: "none", sm: "none", md: "flex", lg: "flex" }, flexGrow: 0, flexBasis: "30em", flexDirection: "column", gap: "0.5em", flexWrap: "wrap", alignItems: "center" }} >
                <ReportCard isAllStatistics={true}>
                    <Chart data={selectedData} width={430} isSelected={true} isSmall={false} />
                    <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "0.2em" }} >
                        <Box sx={{ width: "100%", display: "flex", flexDirection: "row", gap: "0.2em" }} >
                            <ReportTile title={"Accepted"} value={selectedData?.mediaReport?.accepted ? selectedData?.mediaReport?.accepted?.toLocaleString() : 0} />
                            <ReportTile title={"Abandoned"} value={selectedData?.mediaReport?.abandoned ? selectedData?.mediaReport?.abandoned?.toLocaleString() : 0} />
                        </Box>
                        <Box sx={{ width: "100%", display: "flex", flexDirection: "row", gap: "0.2em" }} >
                            <ReportTile title={"Rejected"} value={selectedData?.mediaReport?.rejected ? selectedData?.mediaReport?.rejected?.toLocaleString() : 0} />
                            <ReportTile title={"Rejected Time-Out"} value={selectedData?.mediaReport?.rejected_timeout ? selectedData?.mediaReport?.rejected_timeout?.toLocaleString() : 0} />
                        </Box>
                        <Box sx={{ width: "100%", display: "flex", flexDirection: "row", gap: "0.2em" }} >
                            <ReportTile title={"Alert"} value={selectedData?.mediaReport?.alert ? selectedData?.mediaReport?.alert?.toLocaleString() : 0} />
                            <ReportTile title={"Hold Count"} value={selectedData?.mediaReport?.hold ? selectedData?.mediaReport?.hold?.toLocaleString() : 0} />
                        </Box>
                        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "0.2em" }} >
                            <ReportTile title={"Avg Handling Time"} value={selectedData?.mediaReport?.handle_time ? selectedData?.mediaReport?.handle_time : "00:00:00"} />
                            <ReportTile title={"Avg Hold Time"} value={selectedData?.mediaReport?.hold_time ? selectedData?.mediaReport?.hold_time : "00:00:00"} />
                        </Box>
                        <Box sx={{ width: "100%" }} >
                            <ReportTile title={"Avg Speed To Answer"} value={selectedData?.mediaReport?.speed_to_answer ? selectedData?.mediaReport?.speed_to_answer : "00:00:00"} />
                        </Box>
                    </Box>
                </ReportCard>
            </Box>
        </Box>
    </Box>
}

function FiltrationArea(props) {
    const { brands, isBrandsLoading, franchises, isFranchisesLoading, channels, isChannelsLoading, filtration, setFiltration, range, setRange, selectedTableTab, statisticsSection, crmType, setCrmType } = props
    const { franchises: allFranchises } = useContext(CommonContext)
    const nonRemovedFranchises = useMemo(() => {
        return allFranchises.filter((franchise) => !franchise.is_removed)
    }, [allFranchises])

    function shouldDisplayCrmSection() {
        let isCrm = false
        let isStatisticsSection = selectedTableTab?.title === "Statistics" && statisticsSection !== STATISTICS_SECTIONS.EIGHT_BY_.section
        let isFranchise = filtration?.franchiseId
        if (isStatisticsSection && isFranchise) {
            isCrm = true
        }
        return isCrm
    }

    return <Box sx={{ flexGrow: 2, margin: "0.5em", display: "flex", flexDirection: "row", justifyContent: "end", alignItems: "center", gap: "0.5em", flexWrap: "wrap" }} >
        {(selectedTableTab?.title === "Phone" || selectedTableTab?.title === "SMS" || selectedTableTab?.title === "Statistics") ?
            <Box sx={{ marginRight: "auto", backgroundColor: 'background.main' }}>
                <ImageBox src={filtration.brandImg} size={'large'} />
            </Box>
            : <></>
        }
        {(selectedTableTab?.title === "Phone" || selectedTableTab?.title === "SMS" || selectedTableTab?.title === "Statistics") ?
            <>
                <Autocomplete
                    sx={{ minWidth: "350px" }}
                    size="small"
                    loading={isBrandsLoading}
                    noOptionsText={"No brands"}
                    disabled={brands?.length === 0}
                    disablePortal
                    options={brands}
                    getOptionLabel={(brand) => brand.brand_name}
                    renderOption={renderBrandOption}
                    value={getById(filtration.brandId, brands) ?? null}
                    onChange={(e, brand) => setFiltration({ ...filtration, brandId: brand?.id, brandImg: brand?.brand_img, brandBrandId: brand?.brand_id, franchiseId: undefined, channelId: undefined, franchise: undefined })}
                    renderInput={(params) => <CustomTextField {...params} label="Brand" />}
                />
                <Autocomplete
                    sx={{ minWidth: "300px" }}
                    size="small"
                    loading={isFranchisesLoading}
                    noOptionsText={"No franchises"}
                    disabled={filtration?.brandId ? franchises?.length === 0 : nonRemovedFranchises?.length === 0}
                    disablePortal
                    options={filtration?.brandId ? franchises : nonRemovedFranchises}
                    inputValue={filtration.franchiseName}
                    getOptionLabel={(franchise) => franchise.franchise_name}
                    renderOption={renderFranchiseOption}
                    value={getById(filtration.franchiseId, filtration?.brandId ? franchises : nonRemovedFranchises) ?? null}
                    onChange={(e, franchise) => setFiltration({ ...filtration, franchiseId: franchise?.id, channelId: undefined, franchise: franchise })}
                    renderInput={(params) => <CustomTextField {...params} label="Franchise" />}
                />
            </>
            : <></>
        }
        {(selectedTableTab?.title === "Statistics" && statisticsSection !== STATISTICS_SECTIONS.CRM.section) ?
            <Autocomplete
                sx={{ minWidth: "220px" }}
                size="small"
                loading={isChannelsLoading}
                noOptionsText={filtration.franchiseId ? "No channels" : "Please select a franchise"}
                disabled={channels?.length === 0}
                disablePortal
                options={channels}
                getOptionLabel={(channel) => getPhoneNumberWithInputMask(channel.channel_id)}
                renderOption={(props, channel) => <RenderInput {...props} content={getChannelOptionLabel(channel)} />}
                value={getByChannelId(filtration.channelId, channels) ?? null}
                onChange={(e, channel) => setFiltration({ ...filtration, channelId: channel?.channel_id })}
                renderInput={(params) => <CustomTextField {...params} label="Channel" />}
            />
            :
            <></>
        }
        {shouldDisplayCrmSection() &&
            <CustomSelect sx={{ width: "220px" }} >
                <InputLabel >CRM</InputLabel>
                <Select
                    size={"small"}
                    displayEmpty
                    label="CRM"
                    className={"cus-input"}
                    value={crmType}
                    onChange={(e) => {
                        setCrmType(e.target.value)

                    }}
                >
                    {Object.values(CRM_TYPES).map((cr, i) => <MenuItem key={cr.index} value={cr}>{cr.title}</MenuItem>)}
                </Select>
            </CustomSelect>
        }
        <CustomSelect sx={{ width: "220px" }} >
            <Select
                size={"small"}
                displayEmpty
                value={range}
                onChange={(e) => {
                    const newFiltration = {
                        ...filtration,
                        startDate: getDayBeforeDateISOString(new Date(), "America/Montreal", e.target.value.diffFromNow),
                        endDate: getDayBeforeEndDateISOString(new Date(), "America/Montreal", 1),
                    }
                    setFiltration(newFiltration)
                    setRange(e.target.value)

                }}
            >
                {Object.values(REPORT_DATE_RANGES).map((cr, i) => <MenuItem key={cr.index} value={cr}>{cr.label}</MenuItem>)}
            </Select>
        </CustomSelect>
        <div style={{ width: "220px", zIndex: 4 }} >
            <CustomDatePicker
                showTimeSelect={false}
                timeZone={"America/Montreal"}
                maxTime={new Date(moment(filtration.endDate).valueOf())}
                disabled={false}
                label={"Start Date"}
                value={new Date(filtration.startDate)}
                onChange={(date) => {
                    setRange(REPORT_DATE_RANGES.CUSTOM_RANGE)
                    setFiltration({ ...filtration, startDate: date.toISOString() })
                }}
                clearable={false}
                required={true}
            />
        </div>
        <div style={{ width: "220px", zIndex: 3 }} >
            <CustomDatePicker
                showTimeSelect={false}
                timeZone={"America/Montreal"}
                maxTime={new Date(getDayBeforeEndDateISOString(new Date(), "America/Montreal", 1))}
                minTime={new Date(moment(new Date(filtration.startDate)).valueOf())}
                disabled={false}
                label={"End Date"}
                value={new Date(moment(new Date(filtration.endDate)).valueOf())}
                onChange={(date) => {
                    setRange(REPORT_DATE_RANGES.CUSTOM_RANGE)
                    setFiltration({ ...filtration, endDate: date.toISOString() })
                }}
                clearable={false}
                required={true}
            />
        </div>
    </Box>

}

function getById(id, array = []) {
    return array.find((v) => v.id === id)
}

function getByChannelId(channelId, array = []) {
    return array.find((v) => v.channel_id === channelId)
}
