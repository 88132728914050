import React, { useContext, useEffect, useState } from 'react';
import CallIcon from '@mui/icons-material/Call';
import { Button, IconButton, Tooltip, Box, Typography } from "@mui/material";
import BackspaceIcon from '@mui/icons-material/Backspace';
import { makeACall } from '../../utils/miscellaneous';
import { CommonContext, INITIAL_PHONE_INFO } from '../../contexts/CommonContextProvider';
import { getPhoneNumberWithInputMask } from '../../utils/inputMasks';
import clickSound from '../../assets/audio/click.mp3';
import { NOTIFICATION_TYPES } from '../../constants/common';
import { createOutboundCall, mapOutboundCallWithInboundCall } from '../../services/outboundCalls/outboundCalls';
import { getRefreshedFilterationData } from '../../pages/other/components/PhoneClient';
import { isValidPhoneNumber } from '../../utils/validators';
import { nonConfigOutboundChannelIdMsg } from '../../constants/abndCallLog';
import { getFranchiseBrandOutboundChannelIds } from '../../services/outboundChannelIds/outboundChannelIds';

const clickAudio = new Audio(clickSound);
clickAudio.volume = 0.1;

export default function DialPad(props) {
    const { brand, franchise, estimateCallBack, filterationData, setFilterationData, resetCallAndCustomerHistoryAfterCall } = props;
    const { phoneInfo, setPhoneInfo, showNotification } = useContext(CommonContext);
    const [isDialerHaveFocus, setIsDialerHaveFocus] = useState(true);
    const [outboundLeadAndCallInfo, setOutboundLeadAndCallInfo] = useState({ jobOrLeadId: phoneInfo.jobOrLeadId, jobOrLead: phoneInfo.jobOrLead });

    function handleClick(digit) {
        clickAudio.play();
        if (phoneInfo.to.length >= 10) return;
        setPhoneInfo((phoneInfo) => { return { ...phoneInfo, to: phoneInfo.to + digit } });
    };

    function handleDelete() {
        clickAudio.play();
        setPhoneInfo((phoneInfo) => setPhoneInfo({ ...phoneInfo, to: (phoneInfo.to.slice(0, -1)) }));
    };

    function handleClear() {
        clickAudio.play();
        handleDelete();
    };

    function resetPhoneIfoAfterCall() {
        setPhoneInfo(INITIAL_PHONE_INFO);
    }

    async function handleCall() {
        clickAudio.play();
        const outboundPhoneNumbers = await getFranchiseBrandOutboundChannelIds(franchise?.id, brand?.brand_id)
        if (!franchise) {
            showNotification({ message: "Important : Please make sure to select a brand and franchise before proceeding.", type: NOTIFICATION_TYPES.ERROR })
            return
        } else if (!isValidPhoneNumber(phoneInfo.to)) {
            showNotification({ message: "Important : Please enter valid phone number.", type: NOTIFICATION_TYPES.ERROR })
            return
        } else if (!isValidPhoneNumber(outboundPhoneNumbers?.[0]?.channel_id)) {
            showNotification({ message: "Important : " + nonConfigOutboundChannelIdMsg, type: NOTIFICATION_TYPES.ERROR })
            return
        }
        handleCreateOuthboundCall(franchise.id, brand.id, outboundPhoneNumbers?.[0]?.channel_id, phoneInfo.to, showNotification, (call) => {
            setFilterationData(getRefreshedFilterationData(filterationData))
            makeACall(phoneInfo.to, outboundPhoneNumbers?.[0]?.channel_id, showNotification, call.id)
            estimateCallBack(call.id)
            if (call && outboundLeadAndCallInfo?.jobOrLeadId && outboundLeadAndCallInfo?.jobOrLead) {
                mapOutboundCallWithInboundCall(call.id, outboundLeadAndCallInfo?.jobOrLeadId, outboundLeadAndCallInfo?.jobOrLead)
            }
            resetPhoneIfoAfterCall()
            resetCallAndCustomerHistoryAfterCall()
            setOutboundLeadAndCallInfo({})
        })
    };

    function handleKeyDown(event) {
        if (!isDialerHaveFocus) return;
        const digit = event.key;
        if (/\d/.test(digit)) {
            handleClick(digit);
        } else if (digit === '*' || digit === '#') {
            handleClick(digit);
        } else if (digit === 'Backspace') {
            handleDelete();
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    });

    return (
        <Box onMouseEnter={() => setIsDialerHaveFocus(true)} onMouseLeave={() => setIsDialerHaveFocus(false)} sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", gap: "0.5em" }} >
            <Box sx={{ width: "18em", height: "3em", display: "flex", flexDirection: "row", gap: "0.5em" }}>
                <Typography variant="h4" sx={{ flexGrow: 2 }}>{getPhoneNumberWithInputMask(phoneInfo.to)}</Typography>
                <IconButton onClick={handleClear}>
                    <BackspaceIcon />
                </IconButton>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", gap: "0.5em", justifyContent: "center" }}>
                <DialButton digit={1} handleClick={handleClick} />
                <DialButton digit={2} handleClick={handleClick} />
                <DialButton digit={3} handleClick={handleClick} />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", gap: "0.5em", justifyContent: "center" }}>
                <DialButton digit={4} handleClick={handleClick} />
                <DialButton digit={5} handleClick={handleClick} />
                <DialButton digit={6} handleClick={handleClick} />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", gap: "0.5em", justifyContent: "center" }}>
                <DialButton digit={7} handleClick={handleClick} />
                <DialButton digit={8} handleClick={handleClick} />
                <DialButton digit={9} handleClick={handleClick} />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", gap: "0.5em", justifyContent: "center" }}>
                <DialButton digit={"*"} handleClick={handleClick} />
                <DialButton digit={0} handleClick={handleClick} />
                <DialButton digit={"#"} handleClick={handleClick} />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", gap: "0.5em", justifyContent: "center" }}>
                <Tooltip title="Call" >
                    <span style={{ fexGrow: 2 }}>
                        <IconButton sx={dialButtonStyle} variant="contained" color="primary" onClick={handleCall}>
                            <CallIcon fontSize='large' />
                        </IconButton>
                    </span>
                </Tooltip>
            </Box>
        </Box>
    );

    async function handleCreateOuthboundCall(franchiseId, brandId, from, to, showNotification, onDone = (call) => { }) {
        try {
            const requestBody = {
                from_franchise_id: franchiseId,
                from_brand_id: brandId,
                from_phone: from,
                to_phone: to
            }
            const call = await createOutboundCall(requestBody)
            onDone(call)
        } catch {
            showNotification({ message: "Important : Could not make the call. Please try again", type: NOTIFICATION_TYPES.ERROR })
        }
    }

};

const dialButtonStyle = {
    width: "64px",
    height: "64px",
    borderRadius: "50%",
    border: "2px solid transparent",
    borderColor: "primary.main",
    background: "radial-gradient(100% 100%, transparent, #00a79c36)",
    "&:hover": { background: "radial-gradient(100% 100%, transparent, #00a79d)" }
}

function DialButton(props) {
    const { digit, handleClick } = props;
    return (
        <Button
            sx={dialButtonStyle}
            variant="contained" onClick={() => handleClick(digit)}
        >
            <Typography color="primary" >{digit}</Typography>
        </Button>
    );
}
