
import { styled } from "@mui/material/styles";
import { FormControl } from "@mui/material"
const CustomSelect = styled(FormControl)(({theme})=>({
  "& label.Mui-focused": {
    color: "#00a79d",
    fontWeight: "500",
  },
  "& label": {
    fontSize: "15px",
    fontWeight: "300",
    color: theme.palette.fieldLabelColor,
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#00a79d",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#00a79d",
    },
  },
}));

export default CustomSelect