import { Box, IconButton, Typography } from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete";
import { handleDeleteKeywordByKeyword } from "../../../../../state-services/faq/faq";
import { CommonContext } from "../../../../../contexts/CommonContextProvider";
import { useCallback, useContext, useState } from "react";
import { NOTIFICATION_TYPES } from "../../../../../constants/common";
import DeleteConfirmationDialog from "./DeleteConfirmationDialog";

export default function KeywordCard(props) {
    const { keyword, refreshKeywords = () => { } } = props
    const { showNotification } = useContext(CommonContext)
    const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] = useState(false)

    const onDeleteSuccess = useCallback(() => {
        refreshKeywords()
        showNotification({ message: "Success", type: NOTIFICATION_TYPES.SUCCESS })
    }, [refreshKeywords, showNotification])

    const onDeleteError = useCallback(() => {
        showNotification({ message: "Important : Could not delete. Please try again", type: NOTIFICATION_TYPES.ERROR })
    }, [showNotification])

    return <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", borderRadius: "1em", paddingLeft: "0.7em", paddingBlock: "0.2em", margin: "0.3em", gap: "0.2em", backgroundColor: 'background.icon' }} >
        <Typography variant="body1" >{keyword?.keyword}</Typography>
        <Box sx={{ marginLeft: "0.4em", width: "2px", height: "60%", backgroundColor: "grey" }} />
        <IconButton sx={{ marginRight: "0.1em", height: '35px', paddingBottom: '8px' }} size="small" onClick={() => setIsDeleteConfirmationDialogOpen(true)}><DeleteIcon data-test={`faq_keyword_${keyword?.keyword}_delete`} fontSize="small" color="disabled" /></IconButton>
        <DeleteConfirmationDialog keyword={keyword?.keyword ?? ""} handleDelete={() => handleDeleteKeywordByKeyword(keyword?.keyword, onDeleteError, onDeleteSuccess)} isDeleteDialogOpen={isDeleteConfirmationDialogOpen} handleDeleteDialogClose={() => setIsDeleteConfirmationDialogOpen(false)} />
    </Box >
}