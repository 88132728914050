import { Box, FormControlLabel, FormGroup, Switch } from "@mui/material";

export default function luminConfigurationsSection(props) {
    const { isDisabled, luminConfigurations, setLuminConfigurations } = props;

    return <Box display="flex" flexDirection="row" alignItems="center" gap="1em" >
        <FormGroup>
            <FormControlLabel control=
                {<Switch
                    inputProps={{ "data-test": `franchise_lumin_enabled_${luminConfigurations?.is_enabled === 1}` }}
                    disabled={isDisabled}
                    checked={luminConfigurations?.is_enabled === 1}
                    onChange={(e, cheked) => setLuminConfigurations({ ...luminConfigurations, is_enabled: cheked ? 1 : 0 })}
                />}
                label="Enable sales accelerator" />
        </FormGroup>
    </Box>
}