import { useEffect, useState } from "react"
import { SmallCardForCrmData } from "../../../../components/servicetitan/SmallCard"
import { handleGetServicetitanStatistics } from "../../../../state-services/reports/reports"
import jobImg from "../../../../assets/img/jobs.png";
import leadImg from "../../../../assets/img/leads.png";
import workorderImg from "../../../../assets/img/workorders.png";
import { Box } from "@mui/material";
import { getMatchingBrandByBrandId } from "../../../../utils/miscellaneous"

export default function BrandStatistics(props) {
    const { filtration, brands, onStatisticsChangeBrand } = props
    const [brandStatistics, setBrandStatistics] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(true)

    useEffect(() => {
        const matchingBrandName = getMatchingBrandByBrandId(filtration.brandBrandId, brands);
        if (matchingBrandName) {
            filtration.brandName = matchingBrandName.brand_name;
        }
        handleGetServicetitanStatistics(
            filtration.brandBrandId, 
            filtration.franchiseId, 
            filtration.startDate, 
            filtration.endDate, 
            (data) => {
                setBrandStatistics(data);
                onStatisticsChangeBrand(data);
            },
            setIsLoading,
            setIsError
        );
    }, [filtration.brandBrandId, filtration.franchiseId, filtration.startDate, filtration.endDate])

    return <Box sx={{ display: "flex", flexDirection: "row", gap: "20px", justifyContent: "space-evenly", minWidth: "150px" }}>
        <SmallCardForCrmData heading={"Leads"} image={leadImg} value={brandStatistics?.st_leads?.toLocaleString?.() ?? "-"} isLoading={isLoading} isError={isError} />
        <SmallCardForCrmData heading={"Appointments"} image={workorderImg} value={brandStatistics?.st_appointments?.toLocaleString?.() ?? "-"} isLoading={isLoading} isError={isError} />
        <SmallCardForCrmData heading={"Jobs"} image={jobImg} value={brandStatistics?.st_jobs?.toLocaleString?.() ?? "-"} isLoading={isLoading} isError={isError} />
    </Box>
}

