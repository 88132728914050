import { getIsValidZipCodeResponse, getZipCodeDetails, getZipCodes } from "../../services/zipCodes/zipCodes"
import { getIsValidUSOrCanadianZipCode } from "../../utils/validators"

export async function handleValidateZipCode(brandId = 0, zipCodes = [], excludeFranchiseId = 0) {
    if (!brandId) {
        return { isValid: false, message: "Brand not selected" }
    }
    for (const z of zipCodes) {
        const isValidZipCode = getIsValidUSOrCanadianZipCode(z) && z
        if (!isValidZipCode) {
            return { isValid: false, message: `Invalid - ${z}` }
        }
    }
    try {
        const res = await getIsValidZipCodeResponse(brandId, zipCodes, excludeFranchiseId)
        return { isValid: res?.is_valid, message: res?.message ?? "" }
    }
    catch (err) {
        return { isValid: true, message: "" }
    }
}

export async function handleGetZipCodes(brandIds = [], franchiseIds = [], zipCodes = [], setZipCodes = (zipCodes) => { }, setIsError = (isError) => { }, setIsLoading = (isLoading) => { }) {
    try {
        setIsError(false)
        setIsLoading(true)
        setZipCodes([])
        const res = await getZipCodes(brandIds, franchiseIds, zipCodes, 1)
        setZipCodes(res)
    } catch (err) {
        setIsError(true)
    } finally {
        setIsLoading(false)
    }
}

export async function handleGetZipCodeDetails(zipCode = "", setDetails = (details) => { }, setIsError = (isError) => { }, setIsLoading = (isLoading) => { }) {
    try {
        setIsError(false)
        setIsLoading(true)
        setDetails({})
        const res = await getZipCodeDetails(zipCode, 1)
        setDetails(res)
    } catch (err) {
        setIsError(true)
    } finally {
        setIsLoading(false)
    }
}