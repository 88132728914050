import { JOB_TABS } from "../../../../constants/common";
import ServiceTitanDraftJobs from "./components/ServiceTitanDraftJobs";
import ServiceTitanJobs from "./components/ServiceTitanJobs";

function ServiceTitanJobsLandingPage(props) {
    const { brands, brand, franchise, globalSearchValue, filterState, clearUrlParamsData, setJobStatisticsComponent, tab, setNotify, jobToView, setJobToView, setDraftToContinue, setIsAddMultipleWorkorders, setAppointmentToReschedule, page, setPage } = props

    switch (tab) {
        case JOB_TABS.JOBS:
            return <ServiceTitanJobs brands={brands} brand={brand} franchise={franchise} filterState={filterState} clearUrlParamsData={clearUrlParamsData} globalSearchValue={globalSearchValue} setJobStatisticsComponent={setJobStatisticsComponent} jobToView={jobToView} setJobToView={setJobToView} setNotify={setNotify} setIsAddMultipleWorkorders={setIsAddMultipleWorkorders} setAppointmentToReschedule={setAppointmentToReschedule} page={page} setPage={setPage} />
        case JOB_TABS.DRAFTS:
            return <ServiceTitanDraftJobs brand={brand} franchise={franchise} filterState={filterState} setJobStatisticsComponent={setJobStatisticsComponent} setNotify={setNotify} setDraftToContinue={setDraftToContinue} page={page} setPage={setPage} />
        default:
            return <></>
    }
}
export default ServiceTitanJobsLandingPage;