import { FetchClient } from "../../utils/fetchClient"

export async function getTasks(taskType, brandId, startOnOrAfter, startOnOrBefore, createdOnOrAfter, createdOnOrBefore, assignedTo, assignedBy, isCompleted, page = 0, pageSize = 0) {
    let urlQuery = `page=${page}&page_size=${pageSize}&`
    if (taskType) {
        urlQuery += `task_type=${taskType}&`
    }
    if (brandId !== undefined) {
        urlQuery += `brand_id=${brandId}&`
    }
    if (startOnOrAfter) {
        urlQuery += `start_on_or_after=${startOnOrAfter}&`
    }
    if (startOnOrBefore) {
        urlQuery += `start_on_or_before=${startOnOrBefore}&`
    }
    if (createdOnOrAfter) {
        urlQuery += `created_on_or_after=${createdOnOrAfter}&`
    }
    if (createdOnOrBefore) {
        urlQuery += `created_on_or_before=${createdOnOrBefore}&`
    }
    if (assignedTo !== undefined) {
        urlQuery += `assigned_to=${assignedTo}&`
    }
    if (assignedBy !== undefined) {
        urlQuery += `assigned_by=${assignedBy}&`
    }
    if (isCompleted !== undefined) {
        urlQuery += `is_completed=${isCompleted}&`
    }
    return FetchClient(`/scheduler/tasks?${urlQuery}`).GET({})
}

export async function getTaskAlerts(taskId = 0) {
    return FetchClient(`/scheduler/task-alerts?task_id=${taskId}`).GET({})
}

export async function getTaskTypes() {
    return FetchClient(`/scheduler/task-types`).GET({})
}

export async function getTimezones() {
    return FetchClient(`/scheduler/timezones`).GET({})
}

export async function createTask(task) {
    return FetchClient(`/scheduler/task`).POST({ payload: JSON.stringify(task) })
}

export async function deleteTasks(tasksIds = []) {
    if (!Array.isArray(tasksIds) || tasksIds.length === 0) return
    const tasksIdsString = tasksIds.join(",")
    return FetchClient(`/scheduler/tasks?id=${tasksIdsString}`).DELETE({})
}

export async function assignTasks(request) {
    if (!Array.isArray(request) || request.length === 0) return
    return FetchClient(`/scheduler/tasks/assign`).POST({ payload: JSON.stringify(request) })
}

export async function moveTasks(tasks = []) {
    if (!Array.isArray(tasks) || tasks.length === 0) return
    return FetchClient(`/scheduler/tasks/move`).POST({ payload: JSON.stringify(tasks) })
}

export async function snoozeAlert(alertId, snoozeToTimeUTC) {
    return FetchClient(`/scheduler/task-alert/snooze?id=${alertId}&snooze_to_time_utc=${snoozeToTimeUTC}`).POST({})
}

export async function acceptAlert(taskId, alertId) {
    return FetchClient(`/scheduler/task-alert/accept?id=${alertId}&task_id=${taskId}`).POST({})
}

export async function dismissAlert(alertId) {
    return FetchClient(`/scheduler/task-alert/dismiss?id=${alertId}`).POST({})
}

export async function requestTransfer(taskId) {
    return FetchClient(`/scheduler/task/request-transfer?id=${taskId}`).POST({})
}

export async function completeTask(taskId) {
    return FetchClient(`/scheduler/task/complete?id=${taskId}`).POST({})
}