import { Autocomplete, Box, CircularProgress, IconButton, Table, Tooltip, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import RefreshIcon from '@mui/icons-material/Refresh';
import moment from "moment-timezone"
import { useEffect, useState } from "react"
import CustomTextField from "../../../../../components/CustomTextField";
import { getAvailabilities, lockTime } from "../../../../../services/vonigo/jobScheduling";
import { getLoopCancelCallback, retry, shouldRetry } from "../../../../../state-services-dublicate/serviceTitan";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { NOTIFICATION_TYPES } from "../../../../../constants/common";
import { handleGetDuration, handleRemoveLock } from "../../../../../state-services-dublicate/vonigo";
import { MUNITUES_PER_HOUR, WORKORDER_DURATIONS } from "../../../../../constants/vonigoJobs";
import { CREATE_JOB_SECTION_IDS } from "../../../../../constants/jobs";
import { getTimeZoneName } from "../../../../../utils/vonigo";
import RenderInput from "../../../../../components/servicetitan/RenderInput";

export default function CreateWorkOrderSection(props) {
    const { brand, selectConfigMessage, configMessages, franchise, isNewCustomer, serviceableZipCodeResponse, isNewLocation, location, onWorkOrderChanged, toggleAvailability, showNotification,
        workOrder, isRescheduleWorkOrder, displayId, setDisplayId, editLocation } = props

    const [isAvailabilitiesLoading, setIsAvailabilitiesLoading] = useState(false)
    const [isAvailabilitiesLoadingError, setIsAvailabilitiesLoadingError] = useState(false)
    const [availability, setavailability] = useState({ days: [] })
    const [allAvailableTimeSlots, setallAvailableTimeSlots] = useState({})
    const [duration, setDuration] = useState()
    const [routes, setRoutes] = useState([])
    const [selectedRoute, setselectedRoute] = useState()
    const [canSelectTimeSlot, setCanSelectTimeSlot] = useState(false)
    const [isNoDaysAvailable, setIsNoDaysAvailable] = useState(false)
    const [isLoadingError, setIsLoadingError] = useState(false)
    const [timeRange, settimeRange] = useState({ start: moment(), end: moment().add(6, "d") })
    const [isLockSlotLoading, setisLockSlotLoading] = useState(false)
    const [toggleRefreshAvailability, setToggleRefreshAvailability] = useState(false)
    const [currentBrand, setCurrentBrand] = useState()
    const [currentFranchise, setCurrentFranchise] = useState()
    const [availabilityResponse, setAvailabilityResponse] = useState()

    useEffect(() => {
        if ((currentBrand?.brand_id !== brand?.brand_id) || (currentFranchise?.franchise_id !== franchise?.franchise_id)) {
            setavailability({ days: [] })
            setallAvailableTimeSlots({})
        }
        if (serviceableZipCodeResponse && (serviceableZipCodeResponse?.isServiceable === true)) {
            setIsLoadingError(false)
            setIsNoDaysAvailable(false)
            handleGetDuration(brand, franchise, chooseZipCodeForGettingDuration(isNewLocation, location, editLocation, isNewCustomer), setDuration)
            if (duration) {
                handleDurationChanged(duration / MUNITUES_PER_HOUR)
                return handleGetAvailability(brand, franchise, workOrder, onWorkOrderChanged, duration, location?.address?.zip, timeRange, setIsAvailabilitiesLoading, setIsAvailabilitiesLoadingError, setAvailabilityResponse);
            }
        }
    }, [brand?.id, franchise?.id, duration, serviceableZipCodeResponse, timeRange, isNewLocation, workOrder?.start, workOrder?.end, toggleAvailability, toggleRefreshAvailability])

    useEffect(() => {
        if (availabilityResponse) {
            formatTimeSlotsForTableView(availabilityResponse, franchise, timeRange, setRoutes, selectedRoute, setselectedRoute, setavailability, setallAvailableTimeSlots, setIsLoadingError)
        }
    }, [availabilityResponse])

    useEffect(() => {
        setDisplayId(null)
        if (selectedRoute) {
            setRoutes([])
            setselectedRoute()
        }
        if (workOrder?.lockId) {
            handleRemoveLock(currentBrand, currentFranchise, workOrder, onWorkOrderChanged);
            showNotification({ message: "Time Slot Released", type: NOTIFICATION_TYPES.SUCCESS })
            setCurrentBrand(brand)
            setCurrentFranchise(franchise)
        } else {
            setCurrentBrand(brand)
            setCurrentFranchise(franchise)
        }
    }, [brand, franchise])

    function formatDayId(dayId) {
        return new Date(`${dayId.substring(0, 4)}-${dayId.substring(4, 6)}-${dayId.substring(6, 8)}`)
    }

    function chooseZipCodeForGettingDuration(isNewLocation, location, editLocation, isNewCustomer) {
        let zip
        if (isNewLocation || isNewCustomer) {
            zip = location?.address?.zip
        } else {
            zip = editLocation?.zip
        }
        return zip
    }

    function filterTimes(time) {
        const h = time?.startTime / 60 | 0;
        const m = time?.startTime % 60 | 0;
        let startTimeOfSlot = moment().hours(h).minutes(m)?.format("HH:mm ");
        let day = `${time?.dayID.substring(0, 4)}-${time?.dayID.substring(4, 6)}-${time?.dayID.substring(6, 8)}`
        let dayAndStartTime = `${day} ${startTimeOfSlot}`
        let franchiseTimeNow
        if (franchise?.time_zone !== "") {
            franchiseTimeNow = moment().tz(franchise?.time_zone)?.format("YYYY-MM-DD HH:mm ")
        } else {
            franchiseTimeNow = moment().tz("UTC")?.format("YYYY-MM-DD HH:mm ")
        }
        if (dayAndStartTime > franchiseTimeNow) {
            return true
        }
        else {
            return false
        }
    }

    function checkIfTimeSlotsAvailable(times) {
        let slotAvailability = { amSlotsAvailable: 0, pmSlotsAvailable: 0 }
        let amTimeSlotsCount = 0
        let pmTimeSlotsCount = 0
        if (times?.length === 0) {
            return slotAvailability
        }
        else {
            times?.AM?.forEach(time => {
                if (filterTimes(time)) {
                    amTimeSlotsCount++
                }
            })
            times?.PM?.forEach(time => {
                if (filterTimes(time)) {
                    pmTimeSlotsCount++
                }
            })
            return slotAvailability = { amSlotsAvailable: amTimeSlotsCount, pmSlotsAvailable: pmTimeSlotsCount }
        }
    }

    function handleRouteChange(route) {
        setIsNoDaysAvailable(false)
        setselectedRoute(route)
        allAvailableTimeSlots?.forEach(routeInfo => {
            if (routeInfo?.routeName === route?.name) {
                setavailability(routeInfo)
            }
        })
    }

    function handleDurationChanged(duration) {
        onWorkOrderChanged({ ...workOrder, duration: `${duration * MUNITUES_PER_HOUR}` })
    }

    async function addWeekToDateRange() {
        setDisplayId(null)
        if (workOrder?.lockId) {
            await handleRemoveLock(brand, franchise, workOrder, onWorkOrderChanged);
        }
        settimeRange({ start: timeRange.start?.add(7, "d"), end: timeRange.end.add(7, "d") })
    }

    async function substractWeekFromDateRange() {
        setDisplayId(null)
        if (workOrder?.lockId) {
            await handleRemoveLock(brand, franchise, workOrder, onWorkOrderChanged);
        }
        settimeRange({ start: timeRange.start?.subtract(7, "d"), end: timeRange.end.subtract(7, "d") })
    }

    async function handleLockTimeSlot(event, value) {
        try {
            setDisplayId(value?.displayId)
            setisLockSlotLoading(true)
            if (canSelectTimeSlot === true) {
                setCanSelectTimeSlot(false)
            }
            if (workOrder?.lockId) {
                await handleRemoveLock(brand, franchise, workOrder, onWorkOrderChanged);
            }
            const lockTimeRequest = {
                baseUrl: brand?.redirect_url,
                serviceTypeId: `${brand?.brand_id}`,
                franchiseId: franchise?.franchise_id,
                dayId: value?.dayID,
                routeId: `${value?.routeID}`,
                zip: location?.address?.zip,
                duration: `${workOrder?.duration}`,
                startTime: `${value?.startTime}`
            }
            let lockResponse = await lockTime(lockTimeRequest)
            onWorkOrderChanged({ ...workOrder, lockId: lockResponse?.Ids?.lockID })
            setCanSelectTimeSlot(true)
        } catch (error) {
            setCanSelectTimeSlot(false)
            showNotification({ message: "One of the agents has already selected the booked time slot at the same time", type: NOTIFICATION_TYPES.ERROR })
        } finally { setisLockSlotLoading(false) }
    }

    function TimeSlotCustomeComponent(props) {
        const { times } = props
        let slotAvailability = checkIfTimeSlotsAvailable(times)
        return <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "10px" }}>
            {(slotAvailability?.amSlotsAvailable === 0) && (slotAvailability?.pmSlotsAvailable === 0) &&
                <div style={{ marginLeft: "40%", maxWidth: "80px" }}>
                    <p><strong>No Time Slots Available</strong></p>
                </div>
            }
            {slotAvailability?.amSlotsAvailable !== 0 &&
                <div style={{ marginLeft: "auto", marginRight: "auto" }}>
                    <strong>AM</strong>
                </div>
            }
            {times.AM?.map((time, i) => {
                return filterTimes(time) && <div key={i} onClick={(e) => handleLockTimeSlot(e, time)} className={(time?.displayId === displayId && canSelectTimeSlot) ? "time-slot" : ""}
                    style={{ textAlign: "center", cursor: "pointer", borderRadius: "0.5em", pointerEvents: isLockSlotLoading && "none" }}>{`${getTimeFromMins(time?.startTime)} - ${getTimeFromMins(time?.endTime)} `}
                </div>
            })}
            {slotAvailability?.pmSlotsAvailable !== 0 &&
                <div style={{ marginRight: "auto", marginLeft: "auto" }}>
                    <strong>PM</strong>
                </div>
            }
            {times.PM?.map((time, i) => {
                return filterTimes(time) && <div key={i} onClick={(e) => handleLockTimeSlot(e, time)} className={(time?.displayId === displayId && canSelectTimeSlot) ? "time-slot" : ""}
                    style={{ textAlign: "center", cursor: "pointer", borderRadius: "0.5em", pointerEvents: isLockSlotLoading && "none" }}>{`${getTimeFromMins(time?.startTime)} - ${getTimeFromMins(time?.endTime)}`}</div>
            })}
        </Box>
    }

    function disablePreviousButton() {
        let disablebutton
        if (franchise?.time_zone !== "") {
            disablebutton = moment().tz(franchise?.time_zone)?.format("YYYY-MM-DD") === timeRange?.start?.tz(franchise?.time_zone)?.format("YYYY-MM-DD")
        }
        else {
            disablebutton = moment()?.tz("UTC")?.format("YYYY-MM-DD") === timeRange?.start?.tz("UTC")?.format("YYYY-MM-DD")
        }
        return disablebutton
    }

    function setBackGroundColor(day) {
        let setColor
        if (franchise?.time_zone != "") {
            setColor = moment(formatDayId(day?.dayId))?.format("MM/DD/yyyy") === moment(new Date()).tz(franchise?.time_zone)?.format("MM/DD/yyyy")
        } else {
            setColor = moment(formatDayId(day?.dayId))?.format("MM/DD/yyyy") === moment(new Date()).tz("UTC")?.format("MM/DD/yyyy")
        }
        return setColor
    }

    function TimeSlotTableHead(props) {
        const { days } = props
        const cells = days?.map((day, i) => {
            return <TableCell key={i} style={{ textAlign: "center"}} sx={{backgroundColor:setBackGroundColor(day) ? 'form.secondary' : 'background.main'}} >
                {moment(formatDayId(day?.dayId))?.format("MM/DD/yyyy")}
            </TableCell>
        })
        return cells ?? <></>
    }

    function TimeSlotRow(props) {
        const { days, isNoDaysAvailable } = props
        if (days === null) {
            setIsNoDaysAvailable(true)
        }
        const rows = days?.map((day, i) => {
            return <TableCell key={day?.dayId} style={{ verticalAlign: "top" }} sx={{backgroundColor:setBackGroundColor(day) ? 'form.secondary' : 'background.main'}}>
                <TimeSlotCustomeComponent times={day?.times} isNoDaysAvailable={isNoDaysAvailable} />
            </TableCell>
        })
        return rows ?? [<></>]
    }

    function getTimeFromMins(mins) {
        const h = mins / 60 | 0;
        const m = mins % 60 | 0;
        return moment().hours(h).minutes(m)?.format("hh:mm ");
    }

    function renderRouteOption(props, route) {
        return <RenderInput {...props} key={route?.id} content={route?.name ?? ""} />
    }

    function buildTableView() {
        return <>
            <TableContainer >
                <Table border={"1px"} stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TimeSlotTableHead days={availability?.days} />
                        </TableRow>
                    </TableHead>
                    <TableBody style={{ overflowY: "scroll" }}>
                        <TableRow >
                            <TimeSlotRow days={availability?.days} ></TimeSlotRow>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    }

    function buildErrorView() {
        return <div style={{ marginTop: "25px", border: "1px solid", display: "flex", flexDirction: "row" }}>
            <p><strong>{"Please Use The Refresh Button"}</strong></p>
            <BuildRefreshButton />
        </div>
    }

    function buildNoSlotsView() {
        return <div style={{ paddingLeft: "40%", marginTop: "25px", border: "1px solid" }}> <p><strong>{"No Available Time"}</strong></p></div>
    }

    function buildLoadingErrorView() {
        return <div style={{ paddingLeft: "40%", marginTop: "25px", border: "1px solid" }}> <p><strong>{"Something Went Wrong"}</strong></p></div>
    }

    function buildInitialView() {
        return <div style={{ paddingLeft: "40%", marginTop: "25px", border: "1px solid" }}> <p><strong>{"Please Fill Location Details"}</strong></p></div>
    }

    function buildNoDurationView() {
        return <div style={{ paddingLeft: "40%", marginTop: "25px", border: "1px solid" }}> <p><strong>{"Please Enter Serviceable Zip Code"}</strong></p></div>
    }

    function BuildRefreshButton() {
        return <Tooltip title={"Refresh"} ><IconButton onClick={() => {
            setToggleRefreshAvailability(!toggleRefreshAvailability);
        }}><RefreshIcon /></IconButton></Tooltip>;
    }

    return <Box id={CREATE_JOB_SECTION_IDS.APPOINTMENT} paddingTop="0.5em" >
        <Box sx={{ display: "flex", flexDirection: "raw", paddingTop: "10px", paddingBottom: "10px" }}>
            <h4 style={{ marginBottom: "1em" }}>Work Order Details</h4>
            {configMessages.length !== 0 ? <Box className="messageBox" sx={{ marginLeft: "auto", width: "75%", padding: "10px" }}><p>{selectConfigMessage(configMessages, "availability")}</p></Box> : <></>}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1em", marginBottom: "0.5em" }}>
            {!serviceableZipCodeResponse ? buildInitialView() :
                !duration || !serviceableZipCodeResponse?.isServiceable ? buildNoDurationView() :
                    <>
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <IconButton className={disablePreviousButton() || isAvailabilitiesLoading ? "btn-disable" : "btn-primary"} style={{ height: "30px", width: "30px", marginTop: "5px" }} disabled={disablePreviousButton() || isAvailabilitiesLoading} onClick={substractWeekFromDateRange}><ArrowLeftIcon fontSize="large" /></IconButton>
                                <div style={{ marginLeft: "40px", marginRight: "40px", paddingTop: "3%" }}><strong>{franchise?.time_zone !== "" ? timeRange?.start?.tz(franchise?.time_zone)?.format('MM/DD/yyyy') : timeRange?.start?.format('MM/DD/yyyy')}</strong> to <strong>{franchise?.time_zone !== "" ? timeRange?.end?.tz(franchise?.time_zone)?.format('MM/DD/yyyy') : timeRange?.end?.format('MM/DD/yyyy')}</strong></div>
                                <IconButton className={isAvailabilitiesLoading ? "btn-disable" : "btn-primary"} style={{ height: "30px", width: "30px", marginTop: "5px", marginRight: "20px" }} disabled={isAvailabilitiesLoading} onClick={addWeekToDateRange}><ArrowRightIcon fontSize="large" /></IconButton>
                            </div>
                            <div style={{ width: "110px", marginLeft: "auto", marginRight: "10px" }}>
                                <Autocomplete
                                    sx={{ width: "100%" }}
                                    size="small"
                                    disableClearable
                                    options={WORKORDER_DURATIONS}
                                    disabled={true}
                                    getOptionLabel={(option) => `${option}`}
                                    value={workOrder?.duration ? workOrder?.duration / MUNITUES_PER_HOUR : null}
                                    onChange={(k, value) => handleDurationChanged(value)}
                                    renderInput={(params) => <CustomTextField {...params} label="Duration (hours)" />}
                                />
                            </div>
                            <div style={{ width: "400px" }}>
                                <Autocomplete
                                    sx={{ width: "100%" }}
                                    size="small"
                                    disableClearable
                                    options={routes}
                                    getOptionLabel={(route) => route.name}
                                    renderOption={renderRouteOption}
                                    value={routes?.length > 0 ? selectedRoute ?? null : null}
                                    onChange={(k, value) => handleRouteChange(value)}
                                    renderInput={(params) => <CustomTextField {...params} label="Select Route" />}
                                />
                            </div>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <div>
                                {franchise?.time_zone !== "" ? <p>*All time slots are based on the franchise time zone ({getTimeZoneName(franchise?.time_zone)})</p> : <p style={{ color: "red" }}>*Franchise time zone not provided. All time slots are based on UTC time</p>}
                            </div>
                        </Box>
                        <Box style={{ minHeight: isRescheduleWorkOrder ? "200px" : "80px" }}>
                            {isAvailabilitiesLoading ?
                                <div style={{ paddingLeft: "45%", marginTop: "25px" }}> <CircularProgress size={30} color="inherit" /></div>
                                : isAvailabilitiesLoadingError ? buildErrorView() :
                                    isLoadingError ?
                                        buildLoadingErrorView()
                                        :
                                        !isNoDaysAvailable ?
                                            buildTableView()
                                            :
                                            buildNoSlotsView()
                            }
                        </Box>
                    </>
            }
        </Box>
    </Box>
}

let lastAvailabilityParams;
const firstAvailable = "First Available"

async function handleGetAvailability(brand, franchise, workOrder, onWorkOrderChanged, durationInHours, zip, timeRange, setIsAvailabilitiesLoading, setIsAvailabilitiesLoadingError, onDone, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    if (brand && franchise && brand?.redirect_url && franchise?.franchise_id && timeRange) {
        if (workOrder?.lockId) {
            await handleRemoveLock(brand, franchise, workOrder, onWorkOrderChanged);
        }
        const params = JSON.stringify(getAvailabilityParams(brand, franchise, timeRange, durationInHours, zip))
        lastAvailabilityParams = params
        setIsAvailabilitiesLoading(true);
        setIsAvailabilitiesLoadingError(false);
        try {
            let availabilityStart = franchise?.time_zone !== "" ? moment(timeRange?.start?.tz(franchise?.time_zone)).startOf("day").format('YYYY-MM-DDTHH:mm:ss') + "Z" : moment(timeRange?.start?.tz("UTC")).startOf("day").format('YYYY-MM-DDTHH:mm:ss') + "Z"
            let availabilityEnd = franchise?.time_zone !== "" ? moment(timeRange?.end?.tz(franchise?.time_zone)).startOf("day").format('YYYY-MM-DDTHH:mm:ss') + "Z" : moment(timeRange?.end?.tz("UTC")).startOf("day").format('YYYY-MM-DDTHH:mm:ss') + "Z"
            let data = await loadAvailabilities(brand?.redirect_url, franchise?.franchise_id, brand?.brand_id, availabilityStart, availabilityEnd, durationInHours, zip, onDone, setIsAvailabilitiesLoading, params, lastAvailabilityParams)
            if (lastAvailabilityParams === params) {
                onDone(data?.routes)
            }
        } catch (error) {
            if (shouldRetry(backoffConfig) && error?.status !== 400) {
                retry(() => handleGetAvailability(brand, franchise, durationInHours, zip, timeRange, setIsAvailabilitiesLoading, setIsAvailabilitiesLoadingError, onDone, backoffConfig), backoffConfig)
            } else {
                setIsAvailabilitiesLoadingError(true);
            }
        } finally { }
    }
    return getLoopCancelCallback(backoffConfig)
}

async function loadAvailabilities(redirect_url, franchise_id, brand_id, startTime, endTime, durationInHours, zip, onDone, setIsAvailabilitiesLoadingError, params, lastAvailabilityParams) {
    try {
        let res = await getAvailabilities(redirect_url, franchise_id, brand_id, startTime, endTime, durationInHours, zip)
        if (res?.isLoading === true) {
            setTimeout(async () => {
                res = await loadAvailabilities(redirect_url, franchise_id, brand_id, startTime, endTime, durationInHours, zip, onDone, setIsAvailabilitiesLoadingError)
                onDone(res?.routes)
            }, 10000);
        }
        if (res?.isLoading === false) {
            setIsAvailabilitiesLoadingError(false)
        }
        return res
    } catch (error) {
        throw error
    }
}

function getAvailabilityParams(brand, franchise, timeRange, durationInHours, zip) {
    let start
    let end
    if (franchise?.time_zone !== "") {
        start = timeRange?.start?.tz(franchise?.time_zone)?.format('YYYY-MM-DDTHH:mm:ss') + "Z"
        end = timeRange?.end?.tz(franchise?.time_zone)?.format('YYYY-MM-DDTHH:mm:ss') + "Z"
    }
    else {
        start = timeRange?.start?.format('YYYY-MM-DDTHH:mm:ss') + "Z"
        end = timeRange?.end?.format('YYYY-MM-DDTHH:mm:ss') + "Z"
    }
    return {
        brand: brand?.redirect_url,
        franchiseId: franchise?.franchise_id,
        brand: brand?.brand_id,
        start: start,
        end: end,
        durationInHours: durationInHours,
        zip: zip
    };
}

function groupByKey(array, key) {
    return array
        .reduce((hash, obj) => {
            if (obj[key] === 'undefined') return hash;
            return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) })
        }, {})
}

function formatTimeSlotsForTableView(availabilityRes, franchise, timeRange, setRoutes, selectedRoute, setselectedRoute, setavailability, setallAvailableTimeSlots, setIsLoadingError) {
    const routes = []
    let dateRange = []
    let dateStart
    if (franchise?.time_zone !== "") {
        dateStart = { ...timeRange.start.tz(franchise?.time_zone) }
    } else {
        dateStart = { ...timeRange.start.tz("UTC") }
    }
    let date = moment(dateStart)
    for (let i = 0; i < 7; i++) {
        dateRange.push(date?.format("YYYYMMDD"))
        date.add(1, "d")
    }
    let c = 0
    let r = 0
    if (availabilityRes?.loadingError !== "") {
        setIsLoadingError(true)
    }
    availabilityRes?.routes?.forEach(route => {
        let dayIdArray = []
        routes.push({ name: route?.routeName, id: r })
        r++
        route?.days?.forEach(day => {
            dayIdArray.push(day?.dayId)
        })
        let uniqueDays = dateRange.filter(function (obj) { return dayIdArray.indexOf(obj) == -1; });
        if (uniqueDays.length !== 0) {
            uniqueDays.forEach(day => {
                let obj = { dayId: day, times: [] }
                if (route?.days !== null) {
                    route?.days?.push(obj)
                }
            })
        }
        route?.days?.sort((a, b) => a.dayId - b.dayId);
        route?.days?.forEach(day => {
            let newTimes = []
            day?.times?.forEach(time => {
                time.displayId = c + new Date().getTime()
                c++
                newTimes.push({ t: time?.startTime < 720 ? "AM" : "PM", ...time })
            });
            let groupedTimes = groupByKey(newTimes, "t")
            day.times = groupedTimes
        });
    });
    setRoutes(routes)
    if (!selectedRoute) {
        setselectedRoute(routes[0])
    }
    if (!selectedRoute) {
        availabilityRes?.routes?.forEach(route => {
            if (route?.routeName === firstAvailable) {
                setavailability(route)
            }
        })
    }
    if (selectedRoute) {
        availabilityRes?.routes?.forEach(route => {
            if (route?.routeName === selectedRoute?.name) {
                setavailability(route)
            }
        })
    }
    setallAvailableTimeSlots(availabilityRes?.routes)
}