import moment from "moment"
import { Typography, IconButton, Tooltip, Dialog, Box, Paper, Button } from "@mui/material";
import CallMadeIcon from '@mui/icons-material/CallMade';
import SmallCard from "../../../../../components/servicetitan/SmallCard";
import DialogHeader from "../../../../../components/dialog/DialogHeader";
import DialogBody from "../../../../../components/dialog/DialogBody";
import WorkOrdersSection from "../workOrdersList";
import { useState } from "react";
import CancelJob from "./CancalJobSection";
import ImageBox from "../../../../../components/LogoBox/ImageBox";

function goToVonigoJobPage(jobId) {
    if (jobId) {
        window.open(`https://psb.vonigo.com/Schedule/Job/Job_Main.aspx?jobID=${jobId}&referringPage=/Job/Manage/Manage_Main.aspx?blank=&__rndPar123f=0.10957172944896265`, "_blank");
    }
}

export default function ViewJobModal(props) {
    const { brand, franchise, job, open, showNotification, onClose, setSelectedJobToView, setIsViewJobModalOpen, onCreateJobPageOpen, setIsCreateMultipleWorkOrders, setWorkOrderToReschedule, setIsRescheduleWorkOrder } = props

    const [isOpenCancelJob, setIsOpenCancelJob] = useState(false)
    const onViewInVonigoClick = (e) => {
        e.preventDefault();
        goToVonigoJobPage(job.objectID)
    }

    function handleOpenCancelJob() {
        setIsOpenCancelJob(true)
    }

    function handleCloseCancelJob() {
        setIsOpenCancelJob(false)
    }

    return <Dialog fullWidth open={open} maxWidth="lg" onClose={(job, isEdited = false) => { }}>
        <Box minHeight={"90vh"} maxHeight={"90vh"} style={{ overflowY: "scroll" }} className={'dialog-color'}>
            <DialogHeader onClose={() => { onClose(job) }}>
                <Box sx={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center", gap: "0.5em", padding: "1em" }}>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "left", borderRadius: "0.5em", }}>
                        <Typography variant="h5" component={"h1"} fontWeight="bold" >{`Job #${job?.objectID}`}</Typography>
                        <Typography variant="subtitle2" component={"p"} margin={"0"} padding={"0"} color={"grayText"} >{franchise?.franchise_name}</Typography>
                    </Box>
                    <ImageBox alt="brand-logo" src={brand?.brand_img} size="large" />
                    <Box flexGrow={2}></Box>
                    <Tooltip title={"View in vonigo"}>
                        <IconButton
                            className={"btn-primary"}
                            onClick={onViewInVonigoClick} >
                            <CallMadeIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </DialogHeader>
            <DialogBody>
                <Paper sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "start", gap: "0.5em", padding: "0.5em", borderRadius: "0.5em", width: "98%", justifyContent: "space-evenly", }}>
                    <SmallCard heading="Created Date" value={job?.dateCreated ? moment?.unix(job?.dateCreated)?.format('MM/DD/YYYY h:mm A') : "-"}></SmallCard>
                    <SmallCard heading="Job Label" value={job?.label?.name ?? "-"}></SmallCard>
                    <SmallCard heading="Status" value={job?.jobStatus?.name ?? "-"}></SmallCard>
                    <SmallCard heading="Created By" value={job?.CreatedBy?.name ?? "-"}></SmallCard>
                    <SmallCard heading="Origin" value={job?.origin?.name ?? "-"}></SmallCard>
                </Paper>
                <Box sx={{ height: "150px", display: "flex", flexDirection: "row", alignItems: "stretch", gap: "0.5em", borderRadius: "0.5em", marginBlock: "1em" }}>
                    <Box sx={{ overflowY: "auto", display: "flex", flexDirection: "column", gap: "0.25em", padding: "0.25em", flexGrow: "1", maxWidth: "70%" }}>
                        <Typography style={{ fontSize: "1.5rem", fontWeight: "bold" }}>Overview</Typography>
                        <Box sx={{ display: "flex", flexDirection: "column", }}>
                            <Box sx={{ display: "flex", flexDirection: "column", width: "100%", marginBottom: "1rem" }}>
                                <Typography style={{ fontSize: "1.1em", fontWeight: "bold", margin: "0", marginBottom: "0.3em" }}>Summary</Typography>
                                <Typography >{job?.summary?.fieldValue}</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ overflowY: "auto", display: "flex", flexDirection: "column", gap: "0.25em", padding: "0.25em", width: "34%", }}>
                        <Typography style={{ fontSize: "1.5rem", fontWeight: "bold" }}>&nbsp;</Typography>
                        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                            <Typography style={{ fontSize: "0.9em", color: "GrayText", margin: "0", marginBottom: "0.5em" }}>Customer</Typography>
                            <Typography style={{ margin: "0" }}>{job?.client?.name ?? ""}</Typography>
                        </Box>
                        {job?.jobStatus.name !== "Cancelled" ?
                            <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                <Button size={"small"} variant="contained" style={{ marginTop: "0.5em", marginLeft: "auto" }} onClick={() => handleOpenCancelJob()} disabled={isOpenCancelJob} className={isOpenCancelJob ? "btn-disable" : "btn-primary"} >Reschedule</Button>
                            </Box>
                            :
                            <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                <Typography style={{ fontSize: "0.9em", color: "GrayText", margin: "0", marginBottom: "0.5em" }}>Cancelation Notes</Typography>
                                <Typography style={{ margin: "0" }}>{job?.cancelReason?.fieldValue ?? ""}</Typography>
                            </Box>}
                    </Box>
                </Box>
                {isOpenCancelJob ? <CancelJob brand={brand} franchise={franchise} job={job} setIsRescheduleWorkOrder={setIsRescheduleWorkOrder} setSelectedJobToView={setSelectedJobToView} onCreateJobPageOpen={onCreateJobPageOpen} setIsViewJobModalOpen={setIsViewJobModalOpen} showNotification={showNotification} handleCancelJob={handleCloseCancelJob} ></CancelJob>
                    : <WorkOrdersSection brand={brand} franchise={franchise} setWorkOrderToReschedule={setWorkOrderToReschedule} setIsRescheduleWorkOrder={setIsRescheduleWorkOrder} onCreateJobPageOpen={onCreateJobPageOpen} setIsCreateMultipleWorkOrders={setIsCreateMultipleWorkOrders} setIsViewJobModalOpen={setIsViewJobModalOpen} job={job} showNotification={showNotification} />}
            </DialogBody>
        </Box>
    </Dialog>
}