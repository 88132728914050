import { useState, useEffect, useContext, useRef } from "react";
import { useHistory, withRouter } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CircularProgress from "@mui/material/CircularProgress"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FormControl, RadioGroup, FormControlLabel, Radio } from "@mui/material"
import moment from "moment";
import { CALL_LOG_STATUS, CHURN_STATUS, ERROR_COMPONENTS, ROW_COUNT_PER_PAGE } from "../../../constants/common";
import ErrorView from "../../../components/ErrorView";
import { MenuItem, Pagination, Select, Tooltip } from "@mui/material";
import { getJobsHandler, getCallJobLeadDetails, getBrandRedirectUrlByBrandId } from "../../../state-services/callLogs";
import AbandonedCallsTable from "../../../components/AbandonedCallsTable";
import { fetchMessageBlocks } from "../../../services/messageBlockService";
import FlagIcon from '@mui/icons-material/Flag';
import { redirectToHomePage } from "../../other/Dialer";
import { CommonContext } from "../../../contexts/CommonContextProvider";
import { UserContext } from "../../../contexts/User";
import { CustomSelect } from "../franchise/franchiseCustomStyle";
import EditCallLog from "./componenets/editCallLogs";
import AgentFeedback from "../../other/home/components/agentFeedback";
import ImageBox from "../../../components/LogoBox/ImageBox";
import { handleSetAgentFeedbackData } from "../../other/utils/common";
import OutboundCallPage from "./OutboundCallPage";
import AllCallPage from "./AllCallPage";
import CustomDatePicker from "../../../components/CustomDatePicker";
import { userLevels } from "../../../constants/common";

export const CALL_LOGS_TYPES = [
  {
    index: 2,
    section: "all",
    title: "All Calls",
  },
  {
    index: 1,
    section: "inbound",
    title: "Inbound",
  },
  {
    index: 0,
    section: "outbound",
    title: "Outbound",
  }
]

const CALL_FOR_TYPES = [
  {
    index: 0,
    type: "All"
  },
  {
    index: 1,
    type: "Jobs"
  },
  {
    index: 2,
    type: "Leads"
  },
]

function CallLogsPage(props) {
  const { socketMessage, allUsersIncludeRemovedOnes, brands,} = useContext(CommonContext);
  const {me} = useContext(UserContext);
  const [startDate, setStartDate] = useState(Date.now());
  const [tab, setTab] = useState(parseInt(new URLSearchParams(props.location.search).get('view')) || CALL_LOG_STATUS.jobsSendToCrm);
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true)
  const [callLogsCount, setCallLogsCount] = useState({ count: 0 })
  const [abandenedRecords, setAbandenedRecords] = useState([]);
  const [abandenedCallLogsCount, setAbandenedCallLogsCount] = useState({ call_count: 0, record_count: 0 });
  const [messageBlocks, setMessageBlocks] = useState([])
  const [toggleRefresh, setToggleRefresh] = useState(false)
  const [flaggedStatus, setFlaggedStatus] = useState(2)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [selectedCallLog, setSelectedCallLog] = useState(null)
  const [isOpenAgentFeedbackDialog, setIsOpenAgentFeedbackDialog] = useState(false)
  const [agentFeedbackData, setAgentFeedbackData] = useState({ sentimentId: 0, customerScore: 0.0, customerMagnitude: 0.0, agentScore: 0.0, agentMagnitude: 0.0 })
  const [callLogsSection, setCallLogsSection] = useState(CALL_LOGS_TYPES[0].section)
  const [callFor, setCallFor] = useState(0)
  const history = useHistory()
  const pageCount = useRef(1)
  const countPerPage = ROW_COUNT_PER_PAGE

  const [startDateValue, setStartDateValue] = useState(moment(Date.now()).format('YYYY-MM-DD'));

  useEffect(() => {
    getMessageBlocks()
  }, [])

  useEffect(() => {
    if (socketMessage?.message?.includes("SentimentId")) {
      handleSetAgentFeedbackData(socketMessage, setAgentFeedbackData, setIsOpenAgentFeedbackDialog)
    }
  }, [socketMessage])

  useEffect(() => {
    if (socketMessage?.title) {
      setToggleRefresh((t) => !t)
    }
  }, [socketMessage])

  useEffect(() => {
    let brandIds = me?.level < userLevels?.ADMIN ? brands?.map((b) => b?.brand_id) : []
    if (brands?.length > 0) {
      getJobsHandler(pageCount.current, tab, flaggedStatus, startDateValue, countPerPage, setRecords, setCallLogsCount, setLoading, setAbandenedRecords, setAbandenedCallLogsCount, callFor,brandIds)
    }else{
      setLoading(false)
    }
  }, [tab, countPerPage, toggleRefresh, flaggedStatus, callFor, startDateValue, brands])


  const getMessageBlocks = async () => {
    try {
      const blocks = await fetchMessageBlocks()
      if (blocks) {
        setMessageBlocks(blocks)
      } else {
        setMessageBlocks([])
      }
    } catch (error) { }
  }

  const onPageChangeHandler = (page) => {
    let brandIds = me?.level < userLevels?.ADMIN ? brands?.map((b) => b?.brand_id) : []
    pageCount.current = page
    if (brands?.length > 0){
      getJobsHandler(page, tab, flaggedStatus, startDate, countPerPage, setRecords, setCallLogsCount, setLoading, setAbandenedRecords, setAbandenedCallLogsCount, callFor,brandIds)
    }else{
      setLoading(false)
    }
  }

  const handleTabChange = (value) => {
    pageCount.current = 1
    setTab(value)
  }

  const handleAgentFeedBackDialogClose = () => {
    setIsOpenAgentFeedbackDialog(false)
  }

  const getMatchingAdminByAdminId = (adminId) => {
    const admin = allUsersIncludeRemovedOnes.find((user) => user.user_id === adminId)
    return admin?.first_name + " " + admin?.last_name
  }

  const handleStatusChange = (event) => {
    setFlaggedStatus(event.target.value)
  }

  const handleEditModalOpen = (selectedCallLog) => {
    setSelectedCallLog(selectedCallLog)
    setIsEditModalOpen(true)
  }

  const handleEditModalClose = () => {
    setSelectedCallLog(null)
    setIsEditModalOpen(false)
  }

  const handleViewLeadDetails = async (jonId) => {
    const leadDetails = await getCallJobLeadDetails(jonId)
    history.replace(`/leads?serviceTitanLeadId=${leadDetails.lead_id}`)
  }

  const handleViewServiceTitanJobDetails = async (jobId) => {
    const jobDetails = await getCallJobLeadDetails(jobId)
    history.replace(`/jobs?serviceTitanJobid=${jobDetails.service_titan_job_id}&franchiseId=${jobDetails.franchise_id}&isFromCallLog=true&brandId=${jobDetails.brand_id}`)
  }

  const handleViewVonigoJobDetails = async (jobId) => {
    const jobDetails = await getCallJobLeadDetails(jobId)
    const redirectUrl = await getBrandRedirectUrlByBrandId(jobDetails.brand_id, brands)
    history.replace(`/jobs?vonigoJobid=${jobDetails.vonigo_job_id}&franchiseId=${jobDetails.franchise_id}&baseUrl=${redirectUrl}&isFromCallLog=true&brandId=${jobDetails.brand_id}`)
  }

  const handleChangeSection = (section) => {
    pageCount.current = 1
    setCallFor(CALL_FOR_TYPES[0].index)
    setFlaggedStatus(CHURN_STATUS.all)
    setStartDate(Date.now())
    setStartDateValue(moment(Date.now()).format('YYYY-MM-DD'))
    setCallLogsSection(section)
  }

  const handleCallTypeChange = (e) => {
    pageCount.current = 1
    setCallFor(e.target.value)
  }

  const handleRowNumber = (i) => {
    if (pageCount.current === 1) {
      return i + 1
    } else {
      return (pageCount.current - 1) * ROW_COUNT_PER_PAGE + i + 1
    }
  }

  return (
    <Box className={"dashboard"} sx={{ flexGrow: 1 }}>
      <Grid className="tabs" sx={{ paddingInline: "1em", paddingTop: '15px' }} >
        <h2 style={{ margin: "0" }} >Call Logs</h2>
        <FormControl>
          <RadioGroup
            row
            value={callLogsSection}
            name="type-radio-buttons-group"
            onChange={(e) => (handleChangeSection(e?.target?.value))}>
            {Object.values(CALL_LOGS_TYPES).map((t) => <FormControlLabel
              key={t.index}
              value={t.section}
              control={<Radio sx={{ '&, &.Mui-checked': { color: '#00a79d', }, "&.Mui-disabled": { color: "grayText" } }} />}
              label={t.title}
            />)}
          </RadioGroup>
        </FormControl>
      </Grid>
      {callLogsSection === CALL_LOGS_TYPES[0].section ? (
        <AllCallPage />
      ) : callLogsSection === CALL_LOGS_TYPES[1].section ? (
        <Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div
                style={{ padding: "15px", paddingTop: '0px', borderRadius: "7px" }}
                className={"bg-shadow bg-white"}
              >
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div >
                    <div style={{ display: "flex", marginTop: "15px", marginBottom: "15px" }}>
                      <small>
                        <strong>
                          {tab === CALL_LOG_STATUS.jobsAbandoned && abandenedCallLogsCount.call_count}
                          {tab !== CALL_LOG_STATUS.jobsAbandoned && callLogsCount.count}
                          &nbsp; {tab === CALL_LOG_STATUS.jobsAbandoned && "Abandoned"} Call Records &nbsp;
                          {tab === CALL_LOG_STATUS.jobsAbandoned &&
                            <>
                              |&nbsp;  {abandenedCallLogsCount.call_count - abandenedCallLogsCount.record_count}
                              &nbsp;Spam Calls
                            </>
                          }
                        </strong>
                      </small>
                    </div>
                  </div>
                  <div>
                    {
                      tab === CALL_LOG_STATUS.jobsSendToCrm &&
                      <CustomSelect style={{ minWidth: "170px", marginRight: '20px' }}>
                        <Select
                          size={"small"}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          className={"cus-input"}
                          value={callFor}
                          onChange={(e) => { handleCallTypeChange(e) }}
                        >
                          {CALL_FOR_TYPES.map((singleCallFor, index) => (
                            <MenuItem key={index} value={singleCallFor.index}>{singleCallFor.type}</MenuItem>
                          ))}
                        </Select>
                      </CustomSelect>
                    }
                    {tab !== CALL_LOG_STATUS.jobsAbandoned &&
                      <CustomSelect style={{ minWidth: "170px", marginRight: "20px", marginLeft: 'auto' }}>
                        <Select
                          size={"small"}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          className={"cus-input"}
                          value={flaggedStatus}
                          onChange={handleStatusChange}
                        >
                          <MenuItem value={CHURN_STATUS.all}>All</MenuItem>
                          <MenuItem value={CHURN_STATUS.churn}>Flagged</MenuItem>
                          <MenuItem value={CHURN_STATUS.notChurn}>Not Flagged</MenuItem>
                        </Select>
                      </CustomSelect>
                    }

                    <div style={{ width: "200px", float: 'right' }}>
                      <CustomDatePicker
                        showTimeSelect={false}
                        maxTime={new Date()}
                        disabled={false}
                        label={`Start Date`}
                        value={new Date(startDate)}
                        onChange={(date) => {
                          pageCount.current = 1
                          setStartDateValue(moment(date.toISOString()).format('YYYY-MM-DD'))
                          setStartDate(date)
                        }}
                        clearable={false}
                        required={true}
                      />
                    </div>
                  </div>
                </div>
                <div className={"tabs"}>
                  <Button
                    onClick={() => handleTabChange(CALL_LOG_STATUS.jobsSendToCrm)}
                    size={"small"}
                    variant="contained"
                    disabled={loading}
                    className={loading && tab !== CALL_LOG_STATUS.jobsSendToCrm ? "btn-disable tab" : tab === CALL_LOG_STATUS.jobsSendToCrm ? "btn-primary tab" : "btn-secondary tab"}
                  >
                    Jobs sent
                  </Button>
                  <Button
                    onClick={() => handleTabChange(CALL_LOG_STATUS.jobsOnHold)}
                    size={"small"}
                    variant="contained"
                    disabled={loading}
                    className={loading && tab !== CALL_LOG_STATUS.jobsOnHold ? "btn-disable tab" : tab === CALL_LOG_STATUS.jobsOnHold ? "btn-primary tab" : "btn-secondary tab"}
                  >
                    Jobs on hold
                  </Button>
                  <Button
                    onClick={() => handleTabChange(CALL_LOG_STATUS.others)}
                    size={"small"}
                    variant="contained"
                    disabled={loading}
                    className={loading && tab !== CALL_LOG_STATUS.others ? "btn-disable tab" : tab === CALL_LOG_STATUS.others ? "btn-primary tab" : "btn-secondary tab"}
                  >
                    Non Job Calls
                  </Button>
                  <Button
                    onClick={() => handleTabChange(CALL_LOG_STATUS.jobsAbandoned)}
                    size={"small"}
                    variant="contained"
                    disabled={loading}
                    className={loading && tab !== CALL_LOG_STATUS.jobsAbandoned ? "btn-disable tab" : tab === CALL_LOG_STATUS.jobsAbandoned ? "btn-primary tab" : "btn-secondary tab"}
                  >
                    Abandoned Calls
                  </Button>
                </div>
                {
                  tab !== CALL_LOG_STATUS.jobsAbandoned ?
                    <div>
                      <TableContainer style={{ height: "70vh" }}>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>#</TableCell>
                              <TableCell>
                                <Box>
                                  Caller ID
                                  <Tooltip placement='right-end' title='Call records will be flagged due to negative feedback on customer satisfaction, customer behaviour, and call enjoyment.'>
                                    <span style={{ margin: "0.5em", height: "20px", width: "20px", display: "inline-block", }} >
                                      <InfoOutlinedIcon sx={{ cursor: "pointer" }} color="info" style={{ height: "20px", width: "20px" }} />
                                    </span>
                                  </Tooltip>
                                </Box>
                              </TableCell>
                              <TableCell>Zip Code</TableCell>
                              {tab === CALL_LOG_STATUS?.jobsSendToCrm && <TableCell align="center">{callFor == 0 ? "Job ID/ Lead ID" : callFor == 1 ? "Job ID" : "Lead ID"}</TableCell>}
                              <TableCell align="center">Brand</TableCell>
                              {tab === CALL_LOG_STATUS?.jobsSendToCrm ? <TableCell>Franchise</TableCell> : <></>}
                              <TableCell>Created Time</TableCell>
                              <TableCell>Customer Satisfaction</TableCell>
                              <TableCell>Customer Behaviour</TableCell>
                              <TableCell>Call Enjoyment</TableCell>
                              <TableCell>Media Type</TableCell>
                              <TableCell>Agent</TableCell>
                              <TableCell align={"center"}>Action</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {loading ?
                              <TableRow>
                                <TableCell colSpan={tab === CALL_LOG_STATUS?.jobsSendToCrm ? 12 : CALL_LOG_STATUS?.jobsOnHold ? 10 : 8} align="center" style={{ border: "none", height: "300px", color: "grey" }} >
                                  <CircularProgress size={30} color="inherit" />
                                </TableCell>
                              </TableRow>
                              : brands?.length === 0 ?
                                <TableRow>
                                  <TableCell style={{ border: "none", height: "200px" }} align={"center"} colSpan={tab === CALL_LOG_STATUS?.jobsSendToCrm ? 11 : 10}>
                                    <ErrorView type={ERROR_COMPONENTS.NOT_FOUND} title='You Are Not Assigned To Any Brand' />
                                  </TableCell>
                                </TableRow>
                                :
                                records && records.length !== 0 ? (
                                  records?.map((job, i) => (
                                    <TableRow key={i}>
                                      <TableCell component="th" scope="row">
                                        {handleRowNumber(i)}
                                      </TableCell>
                                      {(tab === CALL_LOG_STATUS.jobsSendToCrm && job.franchise_name !== "") || (tab === CALL_LOG_STATUS.jobsOnHold) || (tab === CALL_LOG_STATUS.others) ?
                                        <>
                                          <TableCell>
                                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                              <Box sx={{ marginTop: '5px' }}>
                                                {job.caller_id ? job.caller_id : "-"}
                                              </Box>
                                              <Box>
                                                {job?.is_churn === 1 && <FlagIcon style={{ color: 'red' }} />}
                                              </Box>
                                            </Box>
                                          </TableCell>
                                          <TableCell>
                                            {job.zip_code ? job.zip_code : "Not Provided"}
                                          </TableCell>
                                          {tab === CALL_LOG_STATUS.jobsSendToCrm && <TableCell align="center">{job?.service_titan_job_id !== 0 ? (<Button onClick={() => handleViewServiceTitanJobDetails(job?.id)}>{job?.service_titan_job_id}</Button>) : job?.lead_id !== 0 ? (<Button onClick={() => handleViewLeadDetails(job?.id)}>{job?.lead_id}</Button>) : job?.vonigo_job_id !== 0 ? (<Button onClick={() => handleViewVonigoJobDetails(job?.id)}>{job?.vonigo_job_id}</Button>) : "Redirected"}</TableCell>}
                                          <TableCell >
                                            <ImageBox  alt={"brand_img"} src={job.brand_img} />
                                          </TableCell>
                                          {tab === CALL_LOG_STATUS.jobsSendToCrm ? (
                                            <TableCell>{job.franchise_name}</TableCell>
                                          ) : (
                                            <></>
                                          )}
                                          <TableCell>{moment(job?.last_updated_at)?.format("hh:mm A")}</TableCell>
                                          <TableCell>{job?.sentimental_analysis ? job?.sentimental_analysis : "-"}</TableCell>
                                          <TableCell>{job?.customer_behaviour ? job?.customer_behaviour : "-"}</TableCell>
                                          <TableCell>{job?.call_enjoyment ? job?.call_enjoyment : "-"}</TableCell>
                                          <TableCell>{job?.media ? job?.media : "-"}</TableCell>
                                          <TableCell>{getMatchingAdminByAdminId(job.created_by)}</TableCell>
                                          <TableCell align={"center"}>
                                            <Box sx={{ display: 'flex', gap: '0.5em' }}>
                                              {tab === CALL_LOG_STATUS.jobsOnHold &&
                                                <Tooltip title={job?.job_sent_by !== "" && "Already created a job for this call record"} >
                                                  <div>
                                                    <Button
                                                      onClick={() => redirectToHomePage(history, job?.media, job?.brand_id, job?.zip_code, job?.caller_id, job?.channel_id, job?.channel_name, job?.id)}
                                                      size={"small"}
                                                      variant="contained"
                                                      className={job?.job_sent_by === "" && "btn-secondary"}
                                                      style={{ marginLeft: "auto" }}
                                                      disabled={job?.job_sent_by !== ""}
                                                    >
                                                      Open
                                                    </Button>
                                                  </div>

                                                </Tooltip>
                                              }
                                              <Button
                                                onClick={() => { handleEditModalOpen(job) }}
                                                size={"small"}
                                                variant="contained"
                                                className={"btn-secondary"}
                                                style={{ marginRight: 'auto', marginLeft: tab === CALL_LOG_STATUS.others ? 'auto' : '' }}
                                              >
                                                Edit
                                              </Button>
                                            </Box>
                                          </TableCell>
                                        </> :
                                        <TableCell colSpan={10} align='center'>This call record related franchise was deleted</TableCell>
                                      }
                                    </TableRow>
                                  ))
                                ) : (
                                  <TableRow>
                                    <TableCell style={{ border: "none", height: "200px" }} align={"center"} colSpan={tab === CALL_LOG_STATUS?.jobsSendToCrm ? 11 : 10}>
                                      <ErrorView type={ERROR_COMPONENTS.NOT_FOUND} title='No Jobs Found On Selected Day' />
                                    </TableCell>
                                  </TableRow>
                                )
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Pagination
                        page={pageCount.current}
                        onChange={(e, v) => onPageChangeHandler(v)}
                        className="pagination-bar"
                        count={Math.ceil((callLogsCount?.count) / countPerPage)}
                        color="standard" />
                    </div>
                    : <AbandonedCallsTable loading={loading} records={abandenedRecords}
                      onPageChangeHandler={onPageChangeHandler} callLogsCount={abandenedCallLogsCount.record_count}
                      countPerPage={countPerPage} messageBlocks={messageBlocks} />
                }
              </div>
            </Grid>
          </Grid>
          {isEditModalOpen && <EditCallLog open={isEditModalOpen} setToggleRefresh={setToggleRefresh} onClose={handleEditModalClose} callLog={selectedCallLog} />}
          {isOpenAgentFeedbackDialog ? <AgentFeedback handleAgentFeedbackDialogClose={handleAgentFeedBackDialogClose} open={isOpenAgentFeedbackDialog} agentFeedbackData={agentFeedbackData} /> : <></>}
        </Grid>
      ) : (<OutboundCallPage />)}
    </Box >
  );
}

export default withRouter(CallLogsPage);
