import { Box, Button, Dialog, IconButton, Tooltip, Typography } from '@mui/material'
import React, { useMemo, useRef, useState } from 'react'
import DialogBody from '../../../../../components/dialog/DialogBody'
import DialogHeader from '../../../../../components/dialog/DialogHeader'
import CallMadeIcon from '@mui/icons-material/CallMade';
import Estimate from './Estimate'
import ImageBox from '../../../../../components/LogoBox/ImageBox'
import CustomerDetails from './CustomerDetails'
import { useHistory } from 'react-router-dom';
import FollowupSummary from './FollowupSummary';

const VIEW_ESTIMATE_SECTIONS = {
    ESTIMATE: {
        index: 0,
        section: "estimate",
        title: "Estimate",
    },
    CUSTOMER_DETAILS: {
        index: 1,
        section: "customer details",
        title: "Customer Details",
    },
    FOLLOWUP_SUMMARY: {
        index: 3,
        section: "followup summary",
        title: "Follow-Up Summary",
    },
}

export default function ViewEstimateLandingPage({ open, onClose, estimate, franchise, brand }) {

    const initialSection = useMemo(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const section = urlParams.get('section');
        return Object.values(VIEW_ESTIMATE_SECTIONS).find((v) => v.section === section)
    }, [])

    const [selectedSection, setSelectedSection] = useState(initialSection ? initialSection : VIEW_ESTIMATE_SECTIONS.ESTIMATE)
    const isSoldEstimate = useRef(false)
    const isDismissEstimate = useRef(false)
    const history = useHistory();

    function getSection() {
        switch (selectedSection.section) {
            case VIEW_ESTIMATE_SECTIONS.ESTIMATE.section:
                return <Estimate estimate={estimate} brand={brand} franchise={franchise} isSoldEstimate={isSoldEstimate} isDismissEstimate={isDismissEstimate} />
            case VIEW_ESTIMATE_SECTIONS.CUSTOMER_DETAILS.section:
                return <CustomerDetails estimate={estimate} franchise={franchise} brand={brand} />
            case VIEW_ESTIMATE_SECTIONS.FOLLOWUP_SUMMARY.section:
                return <FollowupSummary estimate={estimate} />
            default:
                return <></>
        }
    }

    function onCloseEstimates() {
        let shouldReloadEstimates = isSoldEstimate.current || isDismissEstimate.current
        onClose(shouldReloadEstimates)
    }

    function redirectToJob() {
        const jobId = estimate?.jobId;
        const brandId = brand?.brand_id;
        const franchiseId = franchise?.id;
        history.push(`/jobs?jobId=${jobId}&brandId=${brandId}&franchiseId=${franchiseId}`);
    }

    return (
        <>
            <Dialog fullWidth maxWidth="lg" onClose={() => { }} open={open} className="dialog-zindex">
                <DialogHeader onClose={onCloseEstimates} className={'dialog-plain-color'}>
                    <Box sx={{ width: "80%", display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center", gap: "0.5em", padding: "0.25em" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "left", padding: "1em", borderRadius: "0.5em" }}>
                            <Typography variant="h5" component={"h1"} fontWeight="bold" >{`Estimate #${estimate?.id}`}</Typography>
                            <Typography variant="subtitle2" component={"p"} margin={"0"} padding={"0"} color={"grayText"} >{`${franchise?.franchise_name}`}</Typography>
                        </Box>
                        <ImageBox alt="brand-logo" src={brand?.brand_img} size={'large'} />
                    </Box>
                    <Box sx={{ width: '40px', height: '40px', marginRight: '10px', marginTop: '25px' }}>
                        <Tooltip title={"View Job For Estimate"}>
                            <IconButton
                                className={"btn-primary"}
                                onClick={(e) => {
                                    e.preventDefault();
                                    redirectToJob();
                                }} ><CallMadeIcon /></IconButton>
                        </Tooltip>
                    </Box>
                </DialogHeader>
                <DialogBody className={'dialog-color'}>
                    <Box className={"tabs"} style={{ marginBottom: '10px' }}>
                        {Object.values(VIEW_ESTIMATE_SECTIONS).map((tab) => {
                            return <Button
                                key={tab.index}
                                onClick={() => setSelectedSection(tab)}
                                size={"small"}
                                variant="contained"
                                className={tab === selectedSection ? "btn-primary tab" : "btn-secondary tab"}
                            >{tab.title}</Button>
                        })}
                    </Box>
                    <Box sx={{ flexGrow: 2 }} >{getSection()}</Box>
                </DialogBody>
            </Dialog>
        </>
    )
}
