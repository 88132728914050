import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Box, IconButton } from "@mui/material"
import EventIcon from '@mui/icons-material/Event';
import ClearIcon from '@mui/icons-material/Clear';
import CustomTextField from "./CustomTextField";
import moment from "moment";

export default function CustomDatePicker(props) {
    const { minTime, maxTime, disabled, label, value, onChange, clearable, onClear = () => { }, required, timeZone, showTimeSelect = true, dataTest = "" } = props
    const timeZonedMinTime = timeZone ? getTimeWithTimeZone(minTime, timeZone) : minTime
    const timeZonedMaxTime = timeZone ? getTimeWithTimeZone(maxTime, timeZone) : maxTime
    const timeZonedValue = timeZone ? getTimeWithTimeZone(value, timeZone) : value
    let minTimeForTime;
    if (timeZonedMinTime && timeZonedMinTime?.toDateString() === timeZonedValue?.toDateString()) {
        minTimeForTime = timeZonedMinTime
    } else {
        minTimeForTime = timeZone ? getTimeWithTimeZone(new Date(), timeZone) : new Date()
        minTimeForTime?.setHours(0, 0, 0)
    }
    let maxTimeForTime;
    if (timeZonedMaxTime && timeZonedMaxTime?.toDateString() === timeZonedValue?.toDateString()) {
        maxTimeForTime = timeZonedMaxTime
    } else {
        maxTimeForTime = timeZone ? getTimeWithTimeZone(new Date(), timeZone) : new Date()
        maxTimeForTime?.setHours(23, 59, 59)
    }

    function onTimeChanged(date) {
        const dateWithoutTimeZone = timeZone ? getTimeWithOutTimeZone(date, timeZone) : date
        let newDate
        if ((!minTime || dateWithoutTimeZone?.toISOString() > minTime.toISOString()) && (!maxTime || dateWithoutTimeZone?.toISOString() < maxTime.toISOString())) newDate = dateWithoutTimeZone
        if (minTime && dateWithoutTimeZone?.toISOString() <= minTime.toISOString()) {
            newDate = minTime
        }
        if (maxTime && dateWithoutTimeZone?.toISOString() >= maxTime.toISOString()) {
            newDate = maxTime
        }
        onChange(newDate)
    }

    function handleOnClearClick(e) {
        e.stopPropagation()
        onClear()
    }

    return <Box sx={{ zIndex: 1000, width: "100%" }} ><DatePicker
        minDate={timeZonedMinTime}
        maxDate={timeZonedMaxTime}
        minTime={minTimeForTime}
        maxTime={maxTimeForTime}
        sx={{ width: "100%", display: 'flex' }}
        disabled={disabled}
        placeholderText={label}
        selected={timeZone ? getTimeWithTimeZone(value, timeZone)?.valueOf?.() : value}
        value={timeZone ? getTimeWithTimeZone(value, timeZone)?.valueOf?.() : (value ? value : null)}
        onChange={onTimeChanged}
        required={required}
        customInput={<CustomTextField
            style={{ width: "100%", }}
            disabled={disabled}
            required={required}
            label={label}
            size={"small"}
            InputProps={{
                endAdornment:
                    <Box display={"flex"} flexDirection={"row"} alignItems={"center"} >
                        {clearable ? <IconButton onClick={handleOnClearClick}><ClearIcon /></IconButton> : <></>}
                        <EventIcon data-test={dataTest} style={{ color: "gray", cursor: "pointer" }} />
                    </Box>
            }} />}
        showTimeSelect={showTimeSelect}
        timeFormat="HH:mm"
        timeIntervals={15}
        timeCaption="Time"
        dateFormat={showTimeSelect ? "MM/dd/yyyy h:mm aa" : "MM/dd/yyyy"}
    /></Box>
}

export function getTimeWithTimeZone(dateTime, timeZone = "UTC") {
    if (!dateTime) return null;
    const mo1 = moment(dateTime);
    const mo2 = moment(dateTime).tz(timeZone);
    const modifiedDate = moment(dateTime);
    modifiedDate.subtract(mo1.utcOffset() - mo2.utcOffset(), 'minutes');
    return new Date(modifiedDate.valueOf());
}

function getTimeWithOutTimeZone(dateTime, timeZone = "UTC") {
    if (!dateTime) return null;
    const mo1 = moment(dateTime);
    const mo2 = moment(dateTime).tz(timeZone);
    const modifiedDate = moment(dateTime);
    modifiedDate.add(mo1.utcOffset() - mo2.utcOffset(), 'minutes');
    return new Date(modifiedDate.toISOString());
}