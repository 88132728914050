import { Grid, Paper } from '@mui/material'
import "../styles/scoreDashboardStyles.css"
import React from 'react'
import { Box } from '@mui/material';
import moment from 'moment';
import { convertDayToCurrentTimeZone, getTimeDifference } from '../utils';
import AgentLogsIcons from './agentLogsIcons';

export default function AgentLogsCard({ isLogsFromRealTimeDb, log }) {
    return (
        <Paper className='logs-card' sx={{ backgroundColor: (log?.StartTime && !log?.EndTime && isLogsFromRealTimeDb) ? 'wallboard.logList' : 'form.secondary' }} >
            <Grid container spacing={1}>
                <Grid item xs={2}>
                    <div >
                        <p style={{ fontSize: "18px", fontWeight: "400", marginLeft: "20px" }}>{moment(convertDayToCurrentTimeZone(log?.StartTime)).format("hh:mm:ss A")} - {log?.EndTime && moment(convertDayToCurrentTimeZone(log?.EndTime)).format("hh:mm:ss A")}</p>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <Box className='logs-tag'>
                        <div className='logs-tag-icon'>
                            <AgentLogsIcons status={log.Status}></AgentLogsIcons>
                        </div>
                        <div >
                            <p className='logs-paragraph'>{log.Status}</p>
                        </div>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <div style={{ display: "flex", justifyContent: "flex-start", marginLeft: "20%" }}>
                        <p style={{ fontSize: "18px", fontWeight: "400", marginLeft: "20px" }}>{!isLogsFromRealTimeDb ? `Agent was in ${log.Status} status  ${getTimeDifference(log.StartTime, log.EndTime)}` : log.EndTime ? `Agent was in ${log.Status} status  ${getTimeDifference(log.StartTime, log.EndTime)}` : `Agent is currently in ${log.Status} status`}</p>
                    </div>
                </Grid>
            </Grid>
        </Paper >
    )
}
