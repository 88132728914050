import React, { useContext, useState } from 'react'
import { CommonContext } from '../../../../../contexts/CommonContextProvider';
import { NOTIFICATION_TYPES } from '../../../../../constants/common';
import { Box, Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import LoadingButton from '../../../../../components/LoadingButton';
import { addServiceTitanCustomerNote } from '../../../../../services/serviceTitan';

export default function AddNote(props) {
    const { franchiseId, customerId, onDone, onCancel } = props

    const [note, setNote] = useState({ text: "", pinToTop: false, addToLocations: false })
    const [isAddNoteLoading, setIsAddNoteLoading] = useState(false)
    const { showNotification } = useContext(CommonContext);

    function onAddNoteError() {
        showNotification({ isOpen: true, message: "Important : Could not save the note. Please try again", type: NOTIFICATION_TYPES.ERROR })
    }

    function onAddNoteDone(res) {
        onDone(res)
        showNotification({ isOpen: true, message: "Successfully added the note", type: NOTIFICATION_TYPES.SUCCESS })
    }

    function isDisableSaveNoteButton() {
        return note?.text?.trim().length === 0 || isAddNoteLoading
    }

    return <>
        <TextField
            label="Add a note"
            required
            size="small"
            multiline={true}
            maxRows={2}
            onChange={(event) => {
                setNote({ ...note, text: event.target.value })
            }}
            value={note?.text ?? ""}
            minRows={2}
            maxLength={"100%"}
            style={{ width: "100%" }}
        />
        <Box sx={{ display: "flex", flexDirection: "row", width: "100%", gap: "0.5em", marginBlock: "0.5em" }}>
            <div>
                <LoadingButton size="small" disabled={isDisableSaveNoteButton()} sx={{ color: "white" }} type="submit" variant="contained" loading={isAddNoteLoading} onClick={(e) => { handleAddNewCustomerNote(franchiseId, customerId, note, setIsAddNoteLoading, onAddNoteError, onAddNoteDone) }}>Save</LoadingButton>
            </div>
            <div>
                <Button size="small" variant="contained" className="button-secondary" onClick={onCancel} style={{ marginRight: "10px" }}>Cancel</Button>
            </div>
            <div>
                <FormControlLabel style={{ margin: 0, padding: 0, marginLeft: "1em" }} control={<Checkbox size="small" checked={note?.pinToTop}
                    onChange={(event) => {
                        setNote({ ...note, pinToTop: event.target.checked })
                    }} />} label="Pin this note to the top" />
            </div>
        </Box>
    </>
}

async function handleAddNewCustomerNote(franchiseId, customerId, note, setIsLoading, onError, onDone) {
    try {
        setIsLoading(true)
        const res = await addServiceTitanCustomerNote(franchiseId, customerId, note)
        onDone(res)
    } catch {
        onError()
    } finally {
        setIsLoading(false)
    }
}