import { Box, Typography } from "@mui/material"
import ReportIcon from '@mui/icons-material/Report';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';

const CUSTOMER_FOUND_MESSAGE = "Customer(s) found! Select from the dropdown below. If none match the one you're looking for, double check the ZIP code, search with a different piece of information, or create a new account."
const CUSTOMER_NOT_FOUND_MESSAGE = "No customer found. Double check the ZIP code, search with a different piece of information, or create a new account."

export default function CustomerSearchMessage(props) {
    const { customers } = props
    return <Box sx={{ display: "flex", flexDirection: "row",marginTop:"1em",marginBottom:"1em" }}>
        <Box style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: "1em", borderRadius: "0.5em", gap: "1em" }} >
            {customers?.length > 0 ? <CheckCircleOutlineRoundedIcon color={"success"} /> : <ReportIcon color={"error"} />}
            <Typography flexGrow={0} color={customers?.length > 0 ? "green" : "red"}>{customers?.length > 0 ? CUSTOMER_FOUND_MESSAGE : CUSTOMER_NOT_FOUND_MESSAGE}</Typography>
        </Box>
    </Box>
}