import "./style/rightDock.css"
import { useState, useContext } from "react"
import { Button, Tooltip } from "@mui/material";
import DownloadImage from "../../assets/img/conversation.png";
import AgentAssistDrawer from "./components/AgentAssistDrawer/AgentAssistDrawer";
import IBSLogo from "../../assets/img/IBS_lOGO.png"
import { getFromLocalStorage } from "../../utils/browserStorage";
import { LOCAL_STORAGE_KEYS } from "../../constants/browserStorage";
import EmailClientDrawer from "./components/EmailClientDrawer";
import EmailImage from "../../assets/img/email_image.png"
import PhoneImage from "../../assets/img/phone_image.png"
import FaqImage from "../../assets/img/faq_image.png"
import TodoImage from "../../assets/img/todo_list.png"
import PhoneClientDrawer from "./components/PhoneClientDrawer";
import { CommonContext } from "../../contexts/CommonContextProvider";
import { MEDIA_TYPES } from "../../pages/other/Dialer";
import FaqDrawer from "./components/FaqDrawer/FaqDrawer";
import ToDoClientDrawer from "./components/ToDoClientDrawer";

export const RIGHT_DOCK_ITEMS = {
    FAQ: {
        id: "faq",
        title: "FAQs",
        image: FaqImage
    },
    TODO: {
        id: "todo",
        title: "To Do",
        image: TodoImage
    },
    EMAILS: {
        id: "emails",
        title: "Emails",
        image: EmailImage
    },
    PHONE: {
        id: 'phone',
        title: "Phone",
        image: PhoneImage
    }
}

export default function RightDock() {
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [dockOpen, setDockOpen] = useState(false)
    const { selectedRightDockItem, setSelectedRightDockItem } = useContext(CommonContext)

    const handleDockOpen = () => setDockOpen(true)
    const handleDockClose = () => setDockOpen(false)

    const handleAgentAssistToggle = () => {
        setDrawerOpen(!drawerOpen)
        setSelectedRightDockItem()
    }

    const MEDIA_TYPE = getFromLocalStorage(LOCAL_STORAGE_KEYS.media)

    const dockItems = [
        {
            title: "CCAI Agent Assist",
            imageSrc: DownloadImage,
            onClickHandler: handleAgentAssistToggle,
            // disable: getFromLocalStorage(LOCAL_STORAGE_KEYS.ChannelId) ? false : true
            disable: MEDIA_TYPE == MEDIA_TYPES.PHONE.media ? false : true
        },
    ]

    const imageOnErrorHandler = ({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = IBSLogo;
    }


    function getSelectedItemToView(item) {
        switch (item.id) {
            case RIGHT_DOCK_ITEMS.EMAILS.id:
                return <EmailClientDrawer drawerOpen={Boolean(item)} onClose={() => { setDrawerOpen(false); setSelectedRightDockItem() }} />
            case RIGHT_DOCK_ITEMS.PHONE.id:
                return <PhoneClientDrawer drawerOpen={Boolean(item)} onClose={() => { setDrawerOpen(false); setSelectedRightDockItem() }} />
            case RIGHT_DOCK_ITEMS.FAQ.id:
                return <FaqDrawer drawerOpen={Boolean(item)} onClose={() => { setDrawerOpen(false); setSelectedRightDockItem() }} />
            case RIGHT_DOCK_ITEMS.TODO.id:
                return <ToDoClientDrawer drawerOpen={Boolean(item)} onClose={() => { setDrawerOpen(false); setSelectedRightDockItem() }} />
            default:
                <></>
        }
    }

    return (
        <>
            <div onMouseOver={handleDockOpen} onMouseLeave={handleDockClose} className="right-dock-toggle-btn" size="small" aria-label="delete">
                {dockOpen &&
                    <ul className="right-dock">
                        {dockItems.map((item, index) => (
                            !item.disable && (
                                <div key={index}>
                                    <Tooltip placement="left" title={item.title} aria-label="sss">
                                        <li>
                                            <Button disableRipple sx={{
                                                "&:hover": {
                                                    boxShadow: "none",
                                                    backgroundColor: "transparent"
                                                },
                                                "&.MuiButton-root:hover": {
                                                    boxShadow: "none",
                                                    backgroundColor: "transparent"
                                                }
                                            }}>
                                                <img
                                                    onError={imageOnErrorHandler}
                                                    src={item.imageSrc}
                                                    alt="downloadApplication"
                                                    width={35}
                                                    height={35}
                                                    onClick={item.onClickHandler}
                                                    sx={{ "&:hover": { textDecoration: "transparent" } }}
                                                />
                                            </Button>
                                        </li>
                                    </Tooltip>
                                </div>
                            )
                        ))}

                        {Object.values(RIGHT_DOCK_ITEMS).map((item) => <div key={item.id} >
                            <Tooltip placement="left" title={item.title} aria-label="sss">
                                <li>
                                    <Button
                                        disableRipple
                                        sx={{
                                            "&:hover": {
                                                boxShadow: "none",
                                                backgroundColor: "transparent"
                                            },
                                            "&.MuiButton-root:hover": {
                                                boxShadow: "none",
                                                backgroundColor: "transparent"
                                            }
                                        }}
                                    >
                                        <img
                                            onError={imageOnErrorHandler}
                                            src={item.image} alt="Emails"
                                            width={35}
                                            height={35}
                                            onClick={() => {
                                                setSelectedRightDockItem(item)
                                                setDrawerOpen(true)
                                            }}
                                            sx={{ "&:hover": { textDecoration: "transparent" } }}
                                        />
                                    </Button>
                                </li>
                            </Tooltip>
                        </div>
                        )}
                    </ul>
                }
            </div>
            <AgentAssistDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
            {selectedRightDockItem ? getSelectedItemToView(selectedRightDockItem) : <></>}
        </>
    )
}