import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";

const CustomAuditTextField = styled(TextField)(({theme,value})=>({
    "& label": {
        fontSize: "15px",
        color: theme.palette.fieldLabelColor,
        fontWeight: value ? "500" : "400", // Apply different font weights based on value
        "& .MuiFormLabel-asterisk": {
          display: "none"
        }
      },
    
    "& .MuiOutlinedInput-input": {
        "&:focused": {
            color: 'transparent',
            textShadow: '0 0 0 #000000',
        },
      },

    "& .MuiOutlinedInput-root": {
        "&.Mui-error": {
            "& fieldset": {
                border:"2.5px solid #0e994a" // Change the border color when error is true
            },
        },
        "&.Mui-required": {

            "& fieldset": {
              borderColor: 'orange', // Change the border color when disabled
            },
          },
        
    },
}));

export default CustomAuditTextField