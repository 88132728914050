import { Autocomplete, Box, Button, IconButton, Tooltip } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useMemo, useState } from "react"
import CustomTextField from "../../../../../components/CustomTextField"
import RenderInput from "../../../../../components/servicetitan/RenderInput"
import { CREATE_JOB_SECTION_IDS } from "../../../../../constants/jobs"
import { handleGetSingleContactDetails } from "../../../../../state-services-dublicate/vonigo"
import { imposibleToMatchRegex, isValidEmail } from "../../../../../utils/validators"
import { getExistingCustomerType } from "../../../../../utils/vonigo/miscellaneous"
import { getContactPhoneErrors, getContactPhoneErrorsForOptionalPhones } from "../../../../../utils/vonigo/validators"
import { CUSTOMER_TYPES } from "../../vonigo/components/CustomerDetailsSection"
import EmailField from "../../components/EmailField"
import { getContacts, updateContact } from "../../../../../services/vonigo/jobScheduling";
import { PhoneNumberBoxForJobs, PhoneNumberExtention } from "./PhoneNumberBoxForJobs";
import EditWarningMessage from "../../components/EditWarningMessage";
import { NOTIFICATION_TYPES } from "../../../../../constants/common";
import LoadingButton from "../../../../../components/LoadingButton";


export default function ContactDetailsSection(props) {
    const { brand, franchise, selectedContactDetails, setSelectedContactDetails, selectedDraftToContinue, setContacts, handleCloseEditForContact, isEditContact, setIsEditContact, selectConfigMessage, isCreateMultipleWorkOrders, configMessages, contactEditCount, phoneNumbers, setPhoneNumbers, setContactEditCount, editContact, setEditContact, customerSearchCount, isNewCustomer, isNewContact, setIsNewContact, customer, contact, setContact, contacts, selectedContact, setSelectedContact, isContactsLoading, isDisabled, selectedCustomer, showNotification } = props
    const [isEditContactLoading, setIsEditContactLoading] = useState(false)

    useEffect(() => {
        if (selectedContact) {
            handleGetSingleContactDetails(brand, franchise, selectedContact, setSelectedContactDetails, setEditContact)
        } else {
            setSelectedContactDetails()
        }
    }, [selectedContact])

    function changeFlow() {
        setIsNewContact(!isNewContact)
        handleCloseEditForContact()
    }

    return <Box id={CREATE_JOB_SECTION_IDS.CONTACT} sx={{ display: "flex", flexDirection: "column", paddingBlock: "0.5em" }} >
        <Box display="flex" flexDirection="row" alignItems="center">
            <h4>Contacts</h4>
            {isNewCustomer || customerSearchCount === 0 ? <></> : <Button
                sx={{ color: "#00a79d" }}
                title="Add new contact"
                variant="text"
                onClick={() => changeFlow()}>{!isNewContact ? "\u002B New" : "Existing"}
            </Button>}
            {selectedDraftToContinue && customerSearchCount <= 0 && !isNewCustomer && <Button
                sx={{ color: "#00a79d" }}
                title="Add new contact"
                variant="text"
                onClick={() => changeFlow()}>{!isNewContact ? "\u002B New" : "Existing"}
            </Button>}
            {isCreateMultipleWorkOrders && <Button
                sx={{ color: "#00a79d" }}
                title="Add new contact"
                variant="text"
                onClick={() => changeFlow()}>{!isNewContact ? "\u002B New" : "Existing"}
            </Button>}
            {!isNewCustomer && !isNewContact ?
                <IconButton color="primary" size={"medium"} disabled={!selectedContact} style={{ marginRight: "10px" }}
                    onClick={() => setIsEditContact(true)}>
                    <Tooltip title={"Edit Contact"}><EditIcon style={{ height: "20px", width: "20px" }} /></Tooltip>
                </IconButton>
                : <></>}
            {configMessages.length !== 0 && (isNewCustomer || isNewContact) ? <Box className="messageBox" sx={{ marginLeft: "auto" }}><p>{selectConfigMessage(configMessages, "locationAndContact")}</p></Box> : <></>}
        </Box>
        {isNewCustomer || isNewContact ? <NewContactSection isDisabled={isDisabled} phoneNumbers={phoneNumbers} setPhoneNumbers={setPhoneNumbers} isNewCustomer={isNewCustomer} isNewContactForSelectedCustomer={isNewContact} customer={customer} selectedCustomer={selectedCustomer} contact={contact} setContact={setContact} />
            : <Box display="flex" flexDirection="column" gap="1em">
                <Autocomplete
                    sx={{ width: "100%" }}
                    size="small"
                    noOptionsText={selectedCustomer ? "No Contacts found for customer" : "Please select a customer"}
                    loading={isContactsLoading}
                    value={selectedContact ?? null}
                    disablePortal
                    disableClearable
                    options={contacts}
                    getOptionLabel={(contact) => contact?.name ?? ""}
                    renderOption={(props, c) => <RenderInput {...props} key={c?.objectID} content={c?.name} />}
                    onChange={(_, value) => {
                        setSelectedContact(value);
                    }}
                    renderInput={(params) => <CustomTextField   {...params} required={!isNewCustomer} label="Select Customer's Contact" />}
                />
                <ExistingContactSection brand={brand} isNewCustomer={isNewCustomer} handleCloseEditForContact={handleCloseEditForContact} selectedCustomer={selectedCustomer} setSelectedContactDetails={setSelectedContactDetails} customer={customer} setContacts={setContacts} setSelectedContact={setSelectedContact} contacts={contacts} contactEditCount={contactEditCount} setContactEditCount={setContactEditCount} franchise={franchise} contact={selectedContactDetails} editContact={editContact} setEditContact={setEditContact}
                    isEditContact={isEditContact} selectedContact={selectedContact} setIsEditContact={setIsEditContact} isEditContactLoading={isEditContactLoading} setIsEditContactLoading={setIsEditContactLoading} showNotification={showNotification} />
            </Box>}
    </Box>
}

function NewContactSection(props) {
    const { isDisabled, isNewCustomer, selectedCustomer, phoneNumbers, setPhoneNumbers, customer, contact, setContact } = props
    const customerType = useMemo(() => getCustomerType(isNewCustomer, customer, selectedCustomer), [isNewCustomer, selectedCustomer?.typeOptionId, customer?.type])

    const [isValidContact2, errorText2, isValidContact3, errorText3] = useMemo(() => getContactPhoneErrorsForOptionalPhones(customerType, phoneValidation(contact, "phone1"), phoneValidation(contact, "phone2"), phoneValidation(contact, "phone3")), [customerType, contact?.phone1, contact?.phone2, contact?.phone3, contact?.ext1, contact?.ext2, contact?.ext3])
    const [isValidContact1, errorText1] = useMemo(() => getContactPhoneErrors(phoneValidation(contact, "phone1"), phoneValidation(contact, "phone2"), phoneValidation(contact, "phone3"), customer?.type, false), [customer?.type, contact?.phone1, contact?.phone2, contact?.phone3, contact?.ext1, contact?.ext2, contact?.ext3])
    const inputPattern = useMemo(() => isValidContact1 ? undefined : imposibleToMatchRegex, [isValidContact1])

    function phoneValidation(contact, type) {
        let phone1 = ""
        let phone2 = ""
        let phone3 = ""
        if (type === "phone1") {
            if (contact?.phone1?.length === 10 && contact?.ext1) {
                phone1 = `${contact?.phone1}${contact?.ext1}`
            } else {
                phone1 = `${contact?.phone1}`
            }
            return phone1
        } else if (type === "phone2") {
            if (contact?.phone2?.length === 10 && contact?.ext2) {
                phone2 = `${contact?.phone2}${contact?.ext2}`
            } else {
                phone2 = `${contact?.phone2}`
            }
            return phone2
        } else if (type === "phone3") {
            if (contact?.phone3?.length === 10 && contact?.ext3) {
                phone3 = `${contact?.phone3}${contact?.ext3}`
            } else {
                phone3 = `${contact?.phone3}`
            }
            return phone3
        }
    }

    function formatPhoneNumber(number) {
        let maxLength = 10
        if (number.length > maxLength) {
            number = number.substr(0, maxLength)
        }
        number.replace(/\s+/g, '')
        number.replace(/\D/g, '');
        return number
    }

    function handleSetPhoneNumber1(number) {
        setContact({ ...contact, phone1: formatPhoneNumber(number) })
    }

    function handleSetPhoneNumberExtention(e) {
        setContact({ ...contact, ext1: formatPhoneNumber(e) })
    }

    return <Box sx={{ display: "flex", flexDirection: "column", gap: "1em" }}>
        <Box sx={{ display: "flex", flexDirection: "row", gap: "1em" }}>
            <CustomTextField
                disabled={isDisabled}
                required={!isDisabled}
                label={"First Name"}
                variant="outlined"
                size="small"
                style={{ flexGrow: "1" }}
                value={contact?.firstName ?? ""}
                onChange={(e) => {
                    setContact({ ...contact, firstName: e?.target?.value })
                }} />
            <CustomTextField
                disabled={isDisabled}
                required={!isDisabled}
                label={"Last Name"}
                variant="outlined"
                size="small"
                style={{ flexGrow: "1" }}
                value={contact?.lastName ?? ""}
                onChange={(e) => {
                    setContact({ ...contact, lastName: e?.target?.value })
                }} />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", gap: "1em" }}>
            <EmailField sx={{ flexGrow: 1, width: "80%" }} required={!isDisabled} disabled={isDisabled} value={contact?.email ?? ""} onChange={(e) => setContact({ ...contact, email: e?.target?.value.replace(/\s+/g, '') })} />
            <PhoneNumberBoxForJobs sx={{ flexGrow: 5, width: "100%" }} error={!isValidContact1} inputProps={{ pattern: inputPattern }} helperText={errorText1} label={"Phone 1 (123) 456-7890"} required={!isDisabled} disabled={isDisabled} value={contact?.phone1 ?? ""} onChange={handleSetPhoneNumber1} />
            <PhoneNumberExtention sx={{ flexGrow: 5, width: "100%" }} label={"ext"} value={contact?.ext1 ?? ""} onChange={handleSetPhoneNumberExtention} />
        </Box>
        <NewOptionalPhoneNumberSection required={!isDisabled} isDisabled={isDisabled} phoneNumbers={phoneNumbers} setPhoneNumbers={setPhoneNumbers} customerType={customerType} contact={contact} setContact={setContact} isValidContact2={isValidContact2} errorText2={errorText2} isValidContact3={isValidContact3} errorText3={errorText3} />
    </Box>
}

function getCustomerType(isNewCustomer, customer, selectedCustomer) {
    if (isNewCustomer) {
        return customer?.type
    } else {
        const type = getExistingCustomerType(selectedCustomer?.typeOptionId?.optionID)
        return type
    }
}

function ExistingContactSection(props) {
    const { brand, franchise, contact, handleCloseEditForContact, isNewCustomer, selectedCustomer, customer, numbers, setNumbers, contacts, setContacts, contactEditCount, setContactEditCount, isEditContact, selectedContact, setSelectedContact, setIsEditContact, editContact, setEditContact, isEditContactLoading, setIsEditContactLoading, showNotification } = props

    const [isValidContact1, errorText1] = useMemo(() => getContactPhoneErrors(phoneValidation(editContact, "phone1"), phoneValidation(editContact, "phone2"), phoneValidation(editContact, "phone3"), customer?.type, false), [customer?.type, editContact?.phone1, editContact?.ext1, editContact?.phone2, editContact?.ext2, editContact?.phone3, editContact?.ext3])
    const inputPattern = useMemo(() => isValidContact1 ? undefined : imposibleToMatchRegex, [isValidContact1])
    const customerType = useMemo(() => getCustomerType(isNewCustomer, customer, selectedCustomer), [isNewCustomer, selectedCustomer?.typeOptionId, customer?.type])
    const [isValidContact2, errorText2, isValidContact3, errorText3] = useMemo(() => getContactPhoneErrorsForOptionalPhones(customerType, phoneValidation(editContact, "phone1"), phoneValidation(editContact, "phone2"), phoneValidation(editContact, "phone3")), [customerType, editContact?.phone1, editContact?.ext1, editContact?.phone2, editContact?.ext2, editContact?.phone3, editContact?.ext3])
    const isValidEmailAddress = isValidEmail(editContact?.email ?? "") || !(editContact?.email ?? "")

    function phoneValidation(editContact, type) {
        let phone1 = ""
        let phone2 = ""
        let phone3 = ""
        if (type === "phone1") {
            if (editContact?.phone1?.length === 10) {
                phone1 = `${editContact?.phone1}${editContact?.ext1}`
            } else {
                phone1 = `${editContact?.phone1}`
            }
            return phone1
        } else if (type === "phone2") {
            if (editContact?.phone2?.length === 10) {
                phone2 = `${editContact?.phone2}${editContact?.ext2}`
            } else {
                phone2 = `${editContact?.phone2}`
            }
            return phone2
        } else if (type === "phone3") {
            if (editContact?.phone3?.length === 10) {
                phone3 = `${editContact?.phone3}${editContact?.ext3}`
            } else {
                phone3 = `${editContact?.phone3}`
            }
            return phone3
        }

    }

    function formatPhoneNumber(number) {
        let maxLength = 10
        if (number.length > maxLength) {
            number = number.substr(0, maxLength)
        }
        number.replace(/\s+/g, '')
        number.replace(/\D/g, '');
        return number
    }

    function handleSetPhoneNumber1(e) {
        setEditContact({ ...editContact, phone1: formatPhoneNumber(e) })
    }

    function handleSetPhoneNumberExtention(e) {
        setEditContact({ ...editContact, ext1: formatPhoneNumber(e) })
    }

    return <Box >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1em" }}>
            <Box sx={{ display: "flex", flexDirection: "row", gap: "1em" }}>
                <CustomTextField
                    disabled={!isEditContact}
                    label={"First Name"}
                    variant="outlined"
                    required={true}
                    size="small"
                    style={{ flexGrow: "1" }}
                    value={editContact?.firstName ?? ""}
                    onChange={(e) => {
                        setEditContact({ ...editContact, firstName: e?.target?.value })
                    }}
                />
                <CustomTextField
                    disabled={!isEditContact}
                    required={true}
                    label={"Last Name"}
                    variant="outlined"
                    size="small"
                    style={{ flexGrow: "1" }}
                    value={editContact?.lastName ?? ""}
                    onChange={(e) => {
                        setEditContact({ ...editContact, lastName: e?.target?.value })
                    }}
                />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", gap: "1em" }}>
                <EmailField disabled={!isEditContact} required={true} value={editContact?.email ?? ""}
                    onChange={(e) => {
                        setEditContact({ ...editContact, email: e?.target?.value.replace(/\s+/g, '') })
                    }}
                />
                <Box sx={{ display: "flex", flexDirection: "row", gap: "1em" }}>
                    <PhoneNumberBoxForJobs sx={{ flexGrow: 5, width: "100%" }} required={true} error={!isValidContact1} inputProps={{ pattern: inputPattern }} helperText={errorText1} label={"Phone 1 (123) 456-7890"} disabled={!isEditContact} value={editContact?.phone1 ?? ""} onChange={handleSetPhoneNumber1} />
                    <PhoneNumberExtention sx={{ flexGrow: 5, width: "100%" }} label={"ext"} disabled={!isEditContact} value={editContact?.ext1 ?? ""} onChange={handleSetPhoneNumberExtention} />
                </Box>
            </Box>
            {(editContact?.phone2 || editContact?.phone3) ? <ExistingOptionalPhoneNumberSection contact={contact} handleCloseEdit={handleCloseEditForContact} editContact={editContact} setEditContact={setEditContact} customerType={customerType} isEditContact={isEditContact} numbers={numbers} setNumbers={setNumbers} isValidContact2={isValidContact2} errorText2={errorText2} isValidContact3={isValidContact3} errorText3={errorText3} /> : <></>}
            {isEditContact ?
                <Box sx={{ display: "flex", flexDirection: "row", gap: "1em" }}>
                    <Button size={"small"} variant="contained" style={{ marginLeft: "auto" }} className={"btn-secondary"} onClick={() => handleCloseEditForContact()}>Cancel</Button>
                    <LoadingButton sx={{ flex: "0 0 12em" }} size={"small"} variant="contained" className={(isEditContactLoading || !(isValidContact1 && isValidContact2 && isValidContact3 && isValidEmailAddress)) ? "btn-disable" : "btn-primary"} loading={isEditContactLoading}
                        onClick={() => handleEditContact(brand, franchise, contacts, editContact, setIsEditContactLoading, selectedContact, setIsEditContact, contactEditCount, setContactEditCount, setSelectedContact, setContacts, selectedCustomer, showNotification)}>
                        Edit Contact
                    </LoadingButton>
                </Box>
                : <></>}
            {isEditContact ?
                <EditWarningMessage></EditWarningMessage>
                : <></>
            }
        </Box>
    </Box>
}

async function handleEditContact(brand, franchise, contacts, contact, setIsEditContactLoading, selectedContact, setIsEditContact, contactEditCount, setContactEditCount, setSelectedContact, setContacts, selectedCustomer, showNotification) {
    let contactEditRequest = {
        firstName: contact?.firstName,
        lastName: contact?.lastName,
        email: contact?.email,
        baseUrl: brand?.redirect_url,
        franchiseId: franchise?.franchise_id,
        contactId: parseInt(selectedContact?.objectID, 10),

    }
    if (contact?.firstName !== "" && contact?.lastName !== "" && contact?.email !== "" && contact?.phone1 !== "") {
        setIsEditContactLoading(true)
        let phoneNumber1
        let phoneNumber2
        let phoneNumber3
        if (contact?.phone1 && contact?.ext1) {
            phoneNumber1 = contact?.phone1 + contact?.ext1
        } else {
            phoneNumber1 = contact?.phone1
        }
        if (contact?.phone2 && contact?.ext2) {
            phoneNumber2 = contact?.phone2 + contact?.ext2
        } else {
            phoneNumber2 = contact?.phone2
        }
        if (contact?.phone3 && contact?.ext3) {
            phoneNumber3 = contact?.phone3 + contact?.ext3
        } else {
            phoneNumber3 = contact?.phone3
        }
        if (contact?.phone1) {
            contactEditRequest.phone1 = phoneNumber1
        }
        if (contact?.phone2) {
            contactEditRequest.phone2 = phoneNumber2
        }
        if (contact?.phone3) {
            contactEditRequest.phone3 = phoneNumber3
        }
        let response = await updateContact(contactEditRequest)
        let contactsResult = []
        getContacts(brand?.redirect_url, franchise?.franchise_id, selectedCustomer?.objectID).then((res) => {
            contactsResult = res?.Contacts
            setContacts(contactsResult)
            let contactRes = contactsResult?.find(({ objectID }) => objectID === response?.objectID)
            setSelectedContact(contactRes)
        })
        setIsEditContactLoading(false)
        setIsEditContact(false)
    } else {
        showNotification({ message: "Please Fill Required Fields", type: NOTIFICATION_TYPES.ERROR })
    }

}

function NewOptionalPhoneNumberSection(props) {
    const { isDisabled, customerType, contact, setContact, isValidContact2, errorText2, isValidContact3, errorText3 } = props
    const inputPattern2 = useMemo(() => isValidContact2 ? undefined : imposibleToMatchRegex, [isValidContact2])
    const inputPattern3 = useMemo(() => isValidContact3 ? undefined : imposibleToMatchRegex, [isValidContact3])
    const [isPhone2Visible, isPhone3Visible] = useMemo(() => [(customerType === CUSTOMER_TYPES.RESIDENTIAL.name || customerType === CUSTOMER_TYPES.COMMERCIAL.name), (customerType === CUSTOMER_TYPES.RESIDENTIAL.name)], [customerType])

    function formatPhoneNumber(number) {
        let maxLength = 10
        if (number.length > maxLength) {
            number = number.substr(0, maxLength)
        }
        number.replace(/\s+/g, '')
        return number
    }

    function handleSetPhoneNumber2(e) {
        setContact({ ...contact, phone2: formatPhoneNumber(e) })
    }

    function handleSetPhoneNumberExtention2(e) {
        setContact({ ...contact, ext2: formatPhoneNumber(e) })
    }

    function handleSetPhoneNumber3(e) {
        setContact({ ...contact, phone3: formatPhoneNumber(e) })
    }

    function handleSetPhoneNumberExtention3(e) {
        setContact({ ...contact, ext3: formatPhoneNumber(e) })
    }

    return (isPhone2Visible || isPhone3Visible) ? <Box sx={{ width: "100%", display: "flex", flexDirection: "row", gap: "1em" }}>
        {isPhone2Visible ? <Box sx={{ display: "flex", flexDirection: "row", gap: "1em" }}><PhoneNumberBoxForJobs sx={{ flexGrow: 5, width: "100%" }} inputProps={{ pattern: inputPattern2 }} label={"Phone 2 (123) 456-7890"} required={false} disabled={isDisabled} error={!isValidContact2} helperText={errorText2} value={contact?.phone2 ?? ""} onChange={handleSetPhoneNumber2} />
            <PhoneNumberExtention sx={{ flexGrow: 5, width: "100%" }} label={"ext"} value={contact?.ext2 ?? ""} onChange={handleSetPhoneNumberExtention2} />
        </Box> : <></>}
        {isPhone3Visible ? <Box sx={{ display: "flex", flexDirection: "row", gap: "1em" }}><PhoneNumberBoxForJobs sx={{ flexGrow: 5, width: "100%" }} inputProps={{ pattern: inputPattern3 }} label={"Phone 3 (123) 456-7890"} required={false} disabled={isDisabled} error={!isValidContact3} helperText={errorText3} value={contact?.phone3 ?? ""} onChange={handleSetPhoneNumber3} />
            <PhoneNumberExtention sx={{ flexGrow: 5, width: "100%" }} label={"ext"} value={contact?.ext3 ?? ""} onChange={handleSetPhoneNumberExtention3} />
        </Box> : <Box flexGrow={1} width={"100%"}>{" "}</Box>}
    </Box> : <></>
}

function ExistingOptionalPhoneNumberSection(props) {
    const { contact, handleCloseEdit, editContact, setEditContact, isEditContact, isValidContact2, errorText2, isValidContact3, errorText3 } = props
    const inputPattern2 = useMemo(() => isValidContact2 ? undefined : imposibleToMatchRegex, [isValidContact2])
    const inputPattern3 = useMemo(() => isValidContact3 ? undefined : imposibleToMatchRegex, [isValidContact3])

    function formatPhoneNumber(number) {
        let maxLength = 10
        if (number.length > maxLength) {
            number = number.substr(0, maxLength)
        }
        number.replace(/\s+/g, '')
        number.replace(/\D/g, '')
        return number
    }

    function handleSetPhoneNumber2(e) {
        setEditContact({ ...editContact, phone2: formatPhoneNumber(e) })
    }

    function handleSetPhoneNumberExtention2(e) {
        setEditContact({ ...editContact, ext2: formatPhoneNumber(e) })
    }

    function handleSetPhoneNumber3(e) {
        setEditContact({ ...editContact, phone3: formatPhoneNumber(e) })
    }

    function handleSetPhoneNumberExtention3(e) {
        setEditContact({ ...editContact, ext3: formatPhoneNumber(e) })
    }

    return (contact?.phone2 || contact?.phone3) ? <Box sx={{ width: "100%", display: "flex", flexDirection: "row", gap: "1em" }}>
        {contact?.phone2 ? <Box sx={{ display: "flex", flexDirection: "row", gap: "1em" }}><PhoneNumberBoxForJobs sx={{ flexGrow: 5, width: "100%" }} inputProps={{ pattern: inputPattern2 }} label={"Phone 2 (123) 456-7890"} required={false} disabled={!isEditContact} error={!isValidContact2} helperText={errorText2} value={editContact?.phone2 ?? ""} onChange={handleSetPhoneNumber2} />
            <PhoneNumberExtention sx={{ flexGrow: 5, width: "100%" }} disabled={!isEditContact} label={"ext"} handleCloseEdit={handleCloseEdit} value={editContact?.ext2 ?? ""} onChange={handleSetPhoneNumberExtention2} />
        </Box> : <></>}
        {contact?.phone3 ? <Box sx={{ display: "flex", flexDirection: "row", gap: "1em" }}><PhoneNumberBoxForJobs sx={{ flexGrow: 5, width: "100%" }} inputProps={{ pattern: inputPattern3 }} label={"Phone 3 (123) 456-7890"} required={false} disabled={!isEditContact} error={!isValidContact3} helperText={errorText3} value={editContact?.phone3 ?? ""} onChange={handleSetPhoneNumber3} />
            <PhoneNumberExtention sx={{ flexGrow: 5, width: "100%" }} disabled={!isEditContact} label={"ext"} handleCloseEdit={handleCloseEdit} value={editContact?.ext3 ?? ""} onChange={handleSetPhoneNumberExtention3} />
        </Box> : <Box flexGrow={1} width={"100%"}>{" "}</Box>}
        {!contact?.phone2 ? <Box flexGrow={1} width={"50%"}>{" "}</Box> : <></>}
    </Box> : <></>
}