import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import React from 'react'
import { withRouter } from 'react-router-dom';
import SuccessImg from '../../../../assets/img/brand/success.png'

function SuccessView() {

    const urlParams = new URLSearchParams(window.location.search);
    const validatedEmail = urlParams.get("email");

    return (
        <div style={{ marginTop: "10%" }}>
            <Card sx={{ maxWidth: 350, margin: 'auto', boxShadow: 'none', marginTop: 10 }}>
                <CardMedia
                    component="img"
                    alt="card image"
                    height="100%"
                    width="100%"
                    image={SuccessImg}
                />
                <CardContent sx={{ textAlign: 'center' }}>
                    <Typography gutterBottom variant="h6" component="div">
                        {"Success"}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {`${validatedEmail} has been validated successfully`}
                    </Typography>
                </CardContent>
            </Card>
        </div>
    )
}

export default withRouter(SuccessView);
