import { isValidEmail, isValidPhoneNumber } from "../../../../../../utils/validators"
import { EMAIL_ALREADY_EXISTS, INVALID_EMAIL, INVALID_PHONE } from "../constants/common"

export function getValidationOnOwnerData(ownerEmail, ownerPhone, existingEmails = []) {
    let errMessage = ""
    if (!isValidEmail(ownerEmail)) errMessage = INVALID_EMAIL
    if (!isValidPhoneNumber(ownerPhone) && ownerPhone) errMessage = INVALID_PHONE
    if (getIsEmailAlreadyExists(ownerEmail, existingEmails)) errMessage = EMAIL_ALREADY_EXISTS
    const isValid = errMessage === ""
    return isValid
}

export function getIsEmailAlreadyExists(emali = "", otherEmails = []) {
    return otherEmails.some(e => e === emali)
}