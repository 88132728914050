import { useEffect, useState } from "react"
import { handleGetCustomerContacts } from "../../../../../state-services-dublicate/serviceTitan"
import { Box, Paper, Typography } from "@mui/material"
import NoContentView from "../../../../../components/refreshView/NoContentView"
import RefreshErrorView from "../../../../../components/refreshView/RefreshErrorView"
import LoadingView from "../../../../../components/refreshView/LoadingView"
import SmallCard from "../../../../../components/servicetitan/SmallCard"
import { getPhoneNumberWithInputMask } from "../../../../../utils/inputMasks"

export default function ContactsSection(props) {
    const { franchiseId, customerId } = props
    const [contacts, setContacts] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [toggleGetContacts, setToggleGetContacts] = useState(false)

    useEffect(() => {
        handleGetCustomerContacts(franchiseId, customerId, setContacts, setIsLoading, setIsError)
    }, [franchiseId, customerId, toggleGetContacts])

    return franchiseId && customerId ? <Box marginTop="0.5em" width="100%">
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
            <Typography marginLeft="0.5em" component="h5" fontWeight="bold" >Contacts</Typography>
        </Box>
        {isError ? <RefreshErrorView onRefresh={() => setToggleGetContacts(!toggleGetContacts)} /> : <></>}
        {isLoading ? <LoadingView /> : contacts?.length === 0 ? <NoContentView message={"No leads to show"} /> : contacts.map((c, i) => <ContactCard contact={c} index={i} />)}
    </Box > : <></>
}

function ContactCard(props) {
    const { contact } = props

    return <Paper key={contact?.id} style={{ display: "flex", flexDirection: "column", borderRadius: "0.5em", border: "1px solid gray", borderLeft: "4px solid #00a79d", marginTop: "0.5em" }}>
        <Box sx={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "start", gap: "0.5em", padding: "0.25em", justifyContent: "space-evenly" }}>
            <Box width="8em"><SmallCard heading="Type" value={contact?.type ?? "-"} /></Box>
            <Box width="10em"><SmallCard heading="Value" value={getContactValue(contact) ?? "-"} /></Box>
            <Box width="15em" ><SmallCard heading="Memo" value={contact?.memo ? contact?.memo : "-"} /></Box>
        </Box>
    </Paper >
}

function getContactValue(contact) {
    switch (contact?.type) {
        case "Email":
            return contact?.value
        default:
            return getPhoneNumberWithInputMask(contact?.value)
    }
}