import { FRANCHISE_STATUS, KM_PER_MILE } from "../constants/common"
import { alphabeticallySortArray } from "./array"

export function sortFranchises(array = []) {
    array.sort((first, second) => {
        if (first?.drive_distance?.drive_distance_text && second?.drive_distance?.drive_distance_text) {
            return first?.drive_distance?.drive_distance - second?.drive_distance?.drive_distance
        } else if (first?.drive_distance?.drive_distance_text) {
            return -1
        } else if (second?.drive_distance?.drive_distance_text) {
            return 1
        } else {
            if (first?.distance_in_radius && second?.distance_in_radius) {
                return first?.distance_in_radius - second?.distance_in_radius
            } else if (first?.distance_in_radius) {
                return -1
            } else if (second?.distance_in_radius) {
                return 1
            }
        }
        return 0
    })
    return array
}

export function getFranchisesWithinRadius(allFranchises = [], radius = 60) {
    const withinRadiusArray = []
    for (let franchise of allFranchises) {
        if (getIsDistanceDataCalculated(franchise) && (franchise?.distance_in_radius / KM_PER_MILE) <= radius) {
            withinRadiusArray.push(franchise);
        }
    }
    return withinRadiusArray
}

export function getDistanceCalculatedFranchisesOutsideRadius(allFranchises = [], radius = 60) {
    const outsideRadiusArray = []
    for (let franchise of allFranchises) {
        if (getIsDistanceDataCalculated(franchise) && (franchise?.distance_in_radius / KM_PER_MILE) > radius) {
            outsideRadiusArray.push(franchise);
        }
    }
    return outsideRadiusArray
}

function getIsDistanceDataCalculated(franchise) {
    return (franchise?.drive_distance?.drive_distance_text)
}

export function getSortedFranchisesAlphabeticallyAndActiveStatus(franchises = []) {
    franchises.sort((first, second) => {
        if (first?.is_enabled === second?.is_enabled) {
            return alphabeticallySortArray(first?.franchise_name, second?.franchise_name)
        } else {
            if (first?.is_enabled === FRANCHISE_STATUS.active) {
                return -1
            } else if (second?.is_enabled === FRANCHISE_STATUS.active) {
                return 1
            } else {
                return alphabeticallySortArray(first?.franchise_name, second?.franchise_name)
            }
        }
    })
    return franchises
}
