import { CircularProgress } from "@mui/material"
import "../styles/scoreDashboardStyles.css"

export function ScoreCard(props) {
    const { name, value, loading } = props
    return (
        <div className={"data-item"}>
            {loading ?
                <>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <p >{name}</p>
                    </div>
                    <CircularProgress color="inherit" size={"20px"} style={{ marginTop: "15px" }} />
                </>
                :
                <>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <p >{name}</p>
                    </div>
                    <div className="score-card-container">
                        <p className={"count"}>{value}</p>
                    </div>
                </>
            }
        </div>
    )
}