import { Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import SmallCard, { SmallCardForBrand, SmallCardForSummary } from "../../../../../components/servicetitan/SmallCard";
import moment from "moment";

export default function NoteCardForCustomerNoteSection(props) {

    const { job, brand } = props

    const jobId = job?.title?.split(":")

    return <Paper sx={{ width: "100%", borderRadius: "0.5em", border: "1px solid gray", borderLeft: "4px solid #00a79d", marginTop: "0.5em" }}>
        <><Box sx={{ display: "flex", flexDirection: "row", padding: "0.4em", alignItems: "center", borderRadius: "0.5em", gap: "1em" }}>
            <Typography style={{ fontWeight: "bold" }} >{(`#${jobId[1]}` ?? "")}</Typography>
        </Box>
            <Grid container sx={{ marginLeft: "10px" }} >
                <Grid item xs={1.5} >
                    <SmallCard heading={"Created Date"} value={job?.dateCreated ? moment.unix(job?.dateCreated).utc().format('MM/DD/YYYY h:mm A') : "-"}></SmallCard>
                </Grid>
                <Grid item xs={1.5}>
                    < SmallCardForBrand heading={"Brand"} brand={brand}></SmallCardForBrand>
                </Grid>
                <Grid item xs={1.5}>
                    <SmallCard heading={"Job Label"} value={job?.label ? `${job?.label?.name} ` : "-"}></SmallCard>
                </Grid>
                <Grid item xs={1.5} >
                    <SmallCard heading={"Status"} value={job?.jobStatus ? job?.jobStatus?.name : ""}></SmallCard>
                </Grid>
                <Grid item xs={1.5} >
                    <SmallCard heading={"Created By"} value={job?.CreatedBy ? job?.CreatedBy?.name : ""}></SmallCard>
                </Grid>
                <Grid item xs={1.5}>
                    <SmallCard heading={"Origin"} value={job?.origin ? job?.origin?.name : "-"}></SmallCard>
                </Grid>
                <Grid item xs={1.5} >
                    <SmallCard heading={"Client"} value={job?.client ? job?.client.name : "-"}></SmallCard>
                </Grid>
                <Grid item xs={1.5}>
                    <SmallCardForSummary heading={"Summary"} value={job?.summary ? job?.summary?.fieldValue : "-"}></SmallCardForSummary>
                </Grid>
            </Grid>
            <Grid container sx={{ marginLeft: "10px" }}>
                <Grid item xs={12} >
                    <SmallCardForSummary heading={"Cancel Reason"} value={job?.cancelReason ? job?.cancelReason?.fieldValue : "-"}></SmallCardForSummary>
                </Grid>
            </Grid>
            <Box sx={{ display: "flex", flexDirection: "row", padding: "0.4em", borderRadius: "0.5em", gap: "1em" }}>
            </Box>
        </>
    </Paper>
}