import { Box, Drawer } from "@mui/material";
import ToDoClient from "../../../pages/other/components/ToDoClient";

export default function ToDoClientDrawer({ drawerOpen, onClose }) {
    return <Drawer sx={{ zIndex: 1301 }} anchor="right" className="dock-drawer" open={drawerOpen} onClose={onClose}>
        <Box sx={{ height: "100%", width: "700px", padding: "15px" }} >
            <ToDoClient />
        </Box>
    </Drawer>
}
