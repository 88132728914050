import { Autocomplete, Button, Box, Tooltip, IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import CustomTextField from "../../../../components/CustomTextField";
import RenderInput from "../../../../components/servicetitan/RenderInput";
import AddressAutoComplete from "../../../../components/AddressAutoComplete";
import { MAX_FRANCHISE_LOCATION_RADIUS } from "../../../../constants/location";
import AddressBox from "../../../../components/AddressBox";
import { AVAILABLE_CRMS, COUNTRIES, NOTIFICATION_TYPES } from "../../../../constants/common";
import { CONFIG_MESSAGES, CREATE_JOB_SECTION_IDS } from "../../../../constants/jobs";
import ServiceAreaMessage from "./vonigo/ServiceAreaMessage";
import { updateCustomerLocation } from "../../../../services/serviceTitan";
import LoadingButton from "../../../../components/LoadingButton";
import EditWarningMessage from "../components/EditWarningMessage";
import ConfigMessage from "../../../../components/configMessage/configMessage";
import { useState } from "react";

export default function LocationDetailsSection(props) {
    const { franchise, isNewCustomer, states, location, setLocations, setLocation, selectedCustomer, locations, isLocationsLoading, selectedLocation, setSelectedLocation, isNewLocationForExistingCustomer, setIsNewLocationForExistingCustomer,
        serviceableZipCodeResponse, showNotification, isEditLocation, setIsEditLocation, editedLocation, setEditedLocation, newCustomer, setNewCustomer, isBillingSameAsLocation, setIsBillingSameAsLocation, setIsAddressSelected, setIsAddressChanged, isAddressChanged } = props

    const [isValidZipCode, setIsValidZipCode] = useState(true)

    function onEditDone(editedLocation) {
        if (!editedLocation) return
        const index = locations.findIndex((v) => v.id === editedLocation.id)
        if (index === -1) {
            setLocations([editedLocation, ...locations])
        } else {
            locations[index] = editedLocation
            setLocations(locations)
            setSelectedLocation(editedLocation)
        }
        setIsEditLocation(false)
    }

    function onEditLocationPressed() {
        if (isEditLocation) {
            setEditedLocation()
        } else {
            setEditedLocation(selectedLocation)
        }
        setIsEditLocation(!isEditLocation)
    }

    return <Box id={CREATE_JOB_SECTION_IDS.LOCATION}>
        <Box sx={{ display: 'flex', flexDirection: "row", width: "100%", justifyContent: "left", alignItems: "center", paddingTop: "0.5em", gap: "0.5em" }}>
            <h4>Location</h4>
            {isNewCustomer ? <></> : <Tooltip title={isNewLocationForExistingCustomer ? "Use existing location" : "Add new location"} ><Button sx={{ color: "#00a79d" }} variant="text" onClick={() => {
                setIsNewLocationForExistingCustomer(!isNewLocationForExistingCustomer)
                if (!isNewLocationForExistingCustomer) {
                    isAddressChanged.current = false
                    setIsAddressSelected(false)
                }
            }}>{isNewLocationForExistingCustomer ? "Existing" : "\u002B New"}</Button></Tooltip>}
            {isNewCustomer || isNewLocationForExistingCustomer ? <></> : <Tooltip title="Edit Location" ><IconButton onClick={onEditLocationPressed} disabled={!selectedLocation || isEditLocation} size="small" ><EditIcon color={selectedLocation && !isEditLocation ? "primary" : "disabled"} /></IconButton></Tooltip>}
            {<ConfigMessage sectionId={isNewCustomer || isNewLocationForExistingCustomer ? CONFIG_MESSAGES.SERVICETITAN.NEW_LOCATION.id : CONFIG_MESSAGES.SERVICETITAN.EXISTING_LOCATION.id} />}
            <Box flexGrow={2}></Box>
        </Box>
        {(isNewCustomer || isNewLocationForExistingCustomer || isEditLocation) ?
            <Box sx={{ marginBottom: "20px" }}>
                <AddressAutoComplete
                    label={"Search Location Address"}
                    onChange={(address) => {
                        if (isEditLocation) {
                            setEditedLocation({ ...editedLocation, address: address })
                        } else {
                            setLocation({ ...location, address: address })
                            if (isBillingSameAsLocation) setNewCustomer({ ...newCustomer, address: address })
                        }
                    }}
                    lat={franchise?.latitude}
                    long={franchise?.longitude}
                    radius={MAX_FRANCHISE_LOCATION_RADIUS}
                    isAddressSelected={setIsAddressSelected}
                    crm={AVAILABLE_CRMS.servicetitan.value}
                />
            </Box>
            :
            <></>}
        {isNewCustomer || isNewLocationForExistingCustomer ? <NewLocationSection franchise={franchise} location={location} setLocation={setLocation} newCustomer={newCustomer} setNewCustomer={setNewCustomer} isBillingSameAsLocation={isBillingSameAsLocation} setIsBillingSameAsLocation={setIsBillingSameAsLocation} states={states} isValidZipCode={isValidZipCode} setIsValidZipCode={setIsValidZipCode} setIsAddressChanged={setIsAddressChanged} /> : isEditLocation ? <EditLocationSection franchise={franchise} selectedCustomer={selectedCustomer} editedLocation={editedLocation} setEditedLocation={setEditedLocation} onEditDone={onEditDone} onEditCancel={onEditLocationPressed} showNotification={showNotification} states={states} isValidZipCode={isValidZipCode} setIsValidZipCode={setIsValidZipCode} serviceableZipCodeResponse={serviceableZipCodeResponse} /> : <ExistingLocationSection selectedCustomer={selectedCustomer} locations={locations} isLocationsLoading={isLocationsLoading} selectedLocation={selectedLocation} setSelectedLocation={setSelectedLocation} states={states} setIsValidZipCode={setIsValidZipCode} />}
        {serviceableZipCodeResponse ? <ServiceAreaMessage serviceableZipCodeResponse={serviceableZipCodeResponse} /> : <></>}
    </Box>
}

function ExistingLocationSection(props) {
    const { selectedCustomer, locations, isLocationsLoading, selectedLocation, setSelectedLocation, states = [], setIsValidZipCode } = props
    return <>
        <Box sx={{ display: 'flex', flexDirection: "row", flexWrap: "wrap", width: "100%", gap: "1em" }} >
            <Autocomplete
                sx={{ width: "45%", }}
                size="small"
                noOptionsText={selectedCustomer ? "No Locations found for customer" : "Please select a customer"}
                loading={isLocationsLoading}
                value={locations?.length > 0 ? selectedLocation ?? null : null}
                disablePortal
                disableClearable
                options={locations}
                getOptionLabel={(location) => location.name}
                renderOption={(props, location) => <RenderInput {...props} key={location?.id} content={location?.name} />}
                onChange={(_, l) => {
                    setSelectedLocation(l);
                }}
                renderInput={(params) => <CustomTextField   {...params} inputProps={{ ...params.inputProps, 'data-test': 'existing_location' }} required={true} label="Select Customer's Location" />}
            />
            <AddressBox address={selectedLocation?.address} setIsValidZipCode={setIsValidZipCode} isDisabled={true} isRequired={true} isSelectableStates={false} countries={COUNTRIES} states={states} />
        </Box>
    </>
}

function NewLocationSection(props) {
    const { location, setLocation, newCustomer, setNewCustomer, isBillingSameAsLocation, states = [], setIsValidZipCode, setIsAddressChanged } = props

    function onLocationAdressChanged(address) {
        setLocation({ ...location, address: address })
        setIsAddressChanged()
        if (isBillingSameAsLocation) setNewCustomer({ ...newCustomer, address: address })
    }

    return <Box>
        <Box sx={{ display: 'flex', flexDirection: "row", flexWrap: "wrap", width: "100%", gap: "1em" }} >
            <CustomTextField inputProps={{ 'data-test': 'new_location_customer_name' }} style={{ width: "45%" }} required={true} value={location?.name ?? ""} size="small" label="Location Name" onChange={(e) => {
                setLocation({ ...location, name: e?.target?.value })
                setIsAddressChanged()
            }}
            />
            <AddressBox address={location?.address} setIsValidZipCode={setIsValidZipCode} isRequired={true} isSelectableStates={false} countries={COUNTRIES} states={states} onChangeAddress={(address) => onLocationAdressChanged(address)} />
        </Box>
    </Box>
}

function EditLocationSection(props) {
    const { franchise, editedLocation, setEditedLocation, onEditDone, onEditCancel, showNotification, states = [], isValidZipCode, setIsValidZipCode, serviceableZipCodeResponse } = props

    const [isEditLocationLoading, setIsEditLocationLoading] = useState(false)

    function onEditError(err) {
        let errDetails = JSON.parse(err.responseText)
        let errCode = errDetails?.data?.ErrorCode
        let stateErr
        if (errCode === "StateNotValid") {
            stateErr = "State doesn't valid with country"
        } else if (errCode === "CountryNotValid") {
            stateErr = "Country is not valid"
        } else {
            stateErr = ""
        }
        showNotification({ message: `Important: Could not edit the location ${stateErr === "" ? "" : stateErr} . Please try again`, type: NOTIFICATION_TYPES.ERROR })
    }

    function onCompleteEdit(editedLocation) {
        showNotification({ message: "Successfully saved", type: NOTIFICATION_TYPES.SUCCESS })
        onEditDone(editedLocation)
    }

    return <>
        <Box>
            <Box sx={{ display: 'flex', flexDirection: "row", flexWrap: "wrap", width: "100%", gap: "1em" }} >
                <CustomTextField inputProps={{ 'data-test': 'edit_location_customer_name' }} style={{ width: "45%" }} required={true} value={editedLocation?.name ?? ""} size="small" label="Location Name" onChange={(e) => setEditedLocation({ ...editedLocation, name: e?.target?.value })} />
                <AddressBox address={editedLocation?.address} setIsValidZipCode={setIsValidZipCode} isRequired={true} isSelectableStates={false} countries={COUNTRIES} states={states} onChangeAddress={(address) => setEditedLocation({ ...editedLocation, address: address })} />
            </Box>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="right" >
            <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "right", gap: "1em" }} >
                <Button data-test='edit_location_cancel_button' size={"small"} variant="contained" onClick={onEditCancel} className={"btn-secondary"}>Cancel</Button>
                <LoadingButton
                    onClick={() => { handleEditLocation(franchise, editedLocation, setIsEditLocationLoading, onCompleteEdit, onEditError) }}
                    size={"small"}
                    data-test='edit_location_save_button'
                    variant="contained"
                    disabled={isEditLocationLoading || !isValidZipCode || !serviceableZipCodeResponse?.isServiceable}
                    loading={isEditLocationLoading}
                    className={isEditLocationLoading || !isValidZipCode || !serviceableZipCodeResponse?.isServiceable ? "btn-disable" : "btn-primary"} >
                    {"Update Location"}
                </LoadingButton>
            </Box>
            <EditWarningMessage />
        </Box>
    </>
}

async function handleEditLocation(franchise, editedLocation, setIsEditLocationLoading, onDone, onError) {
    setIsEditLocationLoading(true)
    try {
        const res = await updateCustomerLocation(franchise.id, editedLocation.id, editedLocation)
        onDone(res)
    } catch (err) {
        onError(err)
    } finally {
        setIsEditLocationLoading(false)
    }
}
