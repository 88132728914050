import moment from "moment";
import { getAllCallsMiddleWearStatistics,getAllCallCountWithCategory,getOutboundCallCountWithCategory,getInboundCallCountWithCategory,getInboundCallMiddlewearStatistics,getOutboundCallsMiddleWearStatistics ,getChatReport, getChatStatisticsReport, getEmailReport, getEmailStatisticsReport, getPhoneReport, getPhoneStatisticsReport, getSMSReport, getSMSStatisticsReport, getServicetitanStatisticsReport, getVonigoStatisticsReport } from "../../services/reports/reports";
import { retry, shouldRetry } from "../../state-services-dublicate/serviceTitan";

export async function handleGetPhoneStatistics(brandId, franchiseId, channelId, startDate, endDate, setPhoneStatistics, setIsPhoneStatisticsLoading, setIsError, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    try {
        setIsError(false)
        setIsPhoneStatisticsLoading(true)
        const res = await getPhoneStatisticsReport(brandId, franchiseId, channelId, startDate, endDate)
        setPhoneStatistics(res)
    } catch (error) {
        if (shouldRetry(backoffConfig) && error?.status !== 400) {
            retry(() => handleGetPhoneStatistics(brandId, franchiseId, channelId, startDate, endDate, setPhoneStatistics, setIsPhoneStatisticsLoading, setIsError, backoffConfig), backoffConfig)
        } else {
            setIsError(true);
        }
    } finally {
        setIsPhoneStatisticsLoading(false)
    }
}

export async function handleGetEmailStatistics(brandId, startDate, endDate, setEmailStatistics, setIsEmailStatisticsLoading, setIsError, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    try {
        setIsError(false)
        setIsEmailStatisticsLoading(true)
        const res = await getEmailStatisticsReport(brandId, startDate, endDate)
        setEmailStatistics(res)
    } catch (error) {
        if (shouldRetry(backoffConfig) && error?.status !== 400) {
            retry(() => handleGetEmailStatistics(brandId, startDate, endDate, setEmailStatistics, setIsEmailStatisticsLoading, setIsError, backoffConfig), backoffConfig)
        } else {
            setIsError(true);
        }
    } finally {
        setIsEmailStatisticsLoading(false)
    }
}


export async function handleGetSMSStatistics(brandId, franchiseId, channelId, startDate, endDate, setSMSStatistics, setIsSMSStatisticsLoading, setIsError, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    try {
        setIsError(false)
        setIsSMSStatisticsLoading(true)
        const res = await getSMSStatisticsReport(brandId, franchiseId, channelId, startDate, endDate)
        setSMSStatistics(res)
    } catch (error) {
        if (shouldRetry(backoffConfig) && error?.status !== 400) {
            retry(() => handleGetSMSStatistics(brandId, franchiseId, channelId, startDate, endDate, setSMSStatistics, setIsSMSStatisticsLoading, setIsError, backoffConfig), backoffConfig)
        } else {
            setIsError(true);
        }
    } finally {
        setIsSMSStatisticsLoading(false)
    }
}


export async function handleGetChatStatistics(brandId, startDate, endDate, setChatStatistics, setIsChatStatisticsLoading, setIsError, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    try {
        setIsError(false)
        setIsChatStatisticsLoading(true)
        const res = await getChatStatisticsReport(brandId, startDate, endDate)
        setChatStatistics(res)
    } catch (error) {
        if (shouldRetry(backoffConfig) && error?.status !== 400) {
            retry(() => handleGetChatStatistics(brandId, startDate, endDate, setChatStatistics, setIsChatStatisticsLoading, setIsError, backoffConfig), backoffConfig)
        } else {
            setIsError(true);
        }
    } finally {
        setIsChatStatisticsLoading(false)
    }
}

export async function handleGetVonigoStatistics(brandId, franchiseId, startDate, endDate, setVonigoStatistics, setIsLoading, setIsError, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    try {
        setIsError(false)
        setIsLoading(true)
        const res = await getVonigoStatisticsReport(brandId, franchiseId, startDate, endDate)
        setVonigoStatistics(res)
    } catch (error) {
        if (shouldRetry(backoffConfig) && error?.status !== 400) {
            retry(() => handleGetVonigoStatistics(brandId, franchiseId, startDate, endDate, setVonigoStatistics, setIsLoading, setIsError, backoffConfig), backoffConfig)
        } else {
            setIsError(true);
        }
    } finally {
        setIsLoading(false)
    }
}

export async function handleGetServicetitanStatistics(brandId, franchiseId, startDate, endDate, setServiceTitanStatistics, setIsLoading, setIsError, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    try {
        setIsError(false)
        setIsLoading(true)
        const res = await getServicetitanStatisticsReport(brandId, franchiseId, startDate, endDate)
        setServiceTitanStatistics(res)
    } catch (error) {
        if (shouldRetry(backoffConfig) && error?.status !== 400) {
            retry(() => handleGetServicetitanStatistics(brandId, franchiseId, startDate, endDate, setServiceTitanStatistics, setIsLoading, setIsError, backoffConfig), backoffConfig)
        } else {
            setIsError(true);
        }
    } finally {
        setIsLoading(false)
    }
}

export async function handleGetServiceTitanStatisticsForStackFranchises(brandId, stackFranchises, startDate, endDate, setStackFranchisesStatistics, setIsLoading, setIsError, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    try {
        setIsError(false)
        setIsLoading(true)
        const promises = stackFranchises.map(franchise =>
            getServicetitanStatisticsReport(brandId, franchise?.id, startDate, endDate)
                .then(res => ({ ...res, franchiseName: franchise?.franchise_name, brands: franchise?.brandImages }))
        );

        const stackFranchisesStatistics = await Promise.all(promises);
        setStackFranchisesStatistics(stackFranchisesStatistics);
    } catch (error) {
        if (shouldRetry(backoffConfig) && error?.status !== 400) {
            retry(() => handleGetServiceTitanStatisticsForStackFranchises(brandId, stackFranchises, startDate, endDate, setStackFranchisesStatistics, setIsLoading, setIsError, backoffConfig), backoffConfig)
        } else {
            setIsError(true);
        }
    } finally {
        setIsLoading(false)
    }

}

export async function handleGetPhoneReport(brandId, franchiseId, startDate, endDate, page, pageSize, setReportData, setIsLoading, setIsError, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    try {
        setIsError(false)
        setIsLoading(true)
        const res = await getPhoneReport(brandId, franchiseId, startDate, endDate, page, pageSize)
        setReportData(res)
    } catch (error) {
        if (shouldRetry(backoffConfig) && error?.status !== 400) {
            retry(() => handleGetPhoneReport(brandId, franchiseId, startDate, endDate, page, pageSize, setReportData, setIsLoading, setIsError, backoffConfig), backoffConfig)
        } else {
            setIsError(true);
        }
    } finally {
        setIsLoading(false)
    }
}


export async function handleGetSMSReport(brandId, franchiseId, startDate, endDate, page, pageSize, setReportData, setIsLoading, setIsError, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    try {
        setIsError(false)
        setIsLoading(true)
        const res = await getSMSReport(brandId, franchiseId, startDate, endDate, page, pageSize)
        setReportData(res)
    } catch (error) {
        if (shouldRetry(backoffConfig) && error?.status !== 400) {
            retry(() => handleGetSMSReport(brandId, franchiseId, startDate, endDate, page, pageSize, setReportData, setIsLoading, setIsError, backoffConfig), backoffConfig)
        } else {
            setIsError(true);
        }
    } finally {
        setIsLoading(false)
    }
}

export async function handleGetChatReport(startDate, endDate, page, pageSize, setReportData, setIsLoading, setIsError, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    try {
        setIsError(false)
        setIsLoading(true)
        const res = await getChatReport(startDate, endDate, page, pageSize)
        setReportData(res)
    } catch (error) {
        if (shouldRetry(backoffConfig) && error?.status !== 400) {
            retry(() => handleGetChatReport(startDate, endDate, page, pageSize, setReportData, setIsLoading, setIsError, backoffConfig), backoffConfig)
        } else {
            setIsError(true);
        }
    } finally {
        setIsLoading(false)
    }
}

export async function handleGetEmailReport(startDate, endDate, page, pageSize, setReportData, setIsLoading, setIsError, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    try {
        setIsError(false)
        setIsLoading(true)
        const res = await getEmailReport(startDate, endDate, page, pageSize)
        setReportData(res)
    } catch (error) {
        if (shouldRetry(backoffConfig) && error?.status !== 400) {
            retry(() => handleGetEmailReport(startDate, endDate, page, pageSize, setReportData, setIsLoading, setIsError, backoffConfig), backoffConfig)
        } else {
            setIsError(true);
        }
    } finally {
        setIsLoading(false)
    }
}

export async function handleGetAllCallsMiddleWearStatistics(brandId = 0, brandBrandId=0 ,franchiseId = 0,franchiseFranchiseId=0 ,agentId, mediaType = "", startDate, endDate, setAllCallsMiddleWearStatistics = () => { }, setIsLoading = () => { }, setIsError = () => { }, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    try {
        setIsError(false)
        setIsLoading(true)
        const startdateOnMoment = moment(startDate)
        const enddateOnMoment = moment(endDate)
        const outboundStartDate = startdateOnMoment.tz('UTC').format("YYYY-MM-DDTHH:mm:ssZ")
        const outboundEndDate = enddateOnMoment.tz('UTC').format("YYYY-MM-DDTHH:mm:ssZ")
        const inboundStartDate = moment(startDate).format("YYYY-MM-DD Z")
        const inboundEndDate = moment(endDate).format("YYYY-MM-DD Z")
        if (!agentId) agentId = 0
        const res = await getAllCallsMiddleWearStatistics(brandId, brandBrandId ,franchiseId, franchiseFranchiseId ,agentId, mediaType, inboundStartDate, inboundEndDate, outboundStartDate, outboundEndDate)
        setAllCallsMiddleWearStatistics(res)
    } catch (error) {
        if (shouldRetry(backoffConfig) && error?.status !== 400) {
            retry(() => handleGetAllCallsMiddleWearStatistics(brandId,brandBrandId ,franchiseId, franchiseFranchiseId ,agentId, mediaType, startDate, endDate, setAllCallsMiddleWearStatistics, setIsLoading, setIsError, backoffConfig), backoffConfig)
        } else {
            setIsError(true);
        }
    } finally {
        setIsLoading(false)
    }
}

export async function handleGetInboundMiddlewearStatistics(brandId, franchiseId, agentId, mediaType, startDate, endDate, setInboundMiddlewearStatistics=()=>{}, setIsLoading=()=>{}, setIsError=()=>{}, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    try {
        setIsError(false)
        setIsLoading(true)
        const inboundStartDate = moment(startDate).format("YYYY-MM-DD Z")
        const inboundEndDate = moment(endDate).format("YYYY-MM-DD Z")
        if (!agentId) agentId = 0
        const res = await getInboundCallMiddlewearStatistics(brandId, franchiseId, agentId, mediaType?.title, inboundStartDate, inboundEndDate)
        setInboundMiddlewearStatistics(res)
    } catch (error) {
        if (shouldRetry(backoffConfig) && error?.status !== 400) {
            retry(() => handleGetInboundMiddlewearStatistics(brandId, franchiseId, agentId, mediaType, startDate, endDate, setInboundMiddlewearStatistics, setIsLoading, setIsError, backoffConfig), backoffConfig)
        } else {
            setIsError(true);
        }
    } finally {
        setIsLoading(false)
    }
}

export async function handleGetOutboundMiddlewearStatistics(brandId, franchiseId, agentId, startDate, endDate, setOutboundMiddlewearStatistics=()=>{}, setIsLoading=()=>{}, setIsError=()=>{}, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    try {
        setIsError(false)
        setIsLoading(true)
        const startdateOnMoment = moment(startDate)
        const enddateOnMoment = moment(endDate)
        const outboundStartDate = startdateOnMoment.tz('UTC').format("YYYY-MM-DDTHH:mm:ssZ")
        const outboundEndDate = enddateOnMoment.tz('UTC').format("YYYY-MM-DDTHH:mm:ssZ")
        if (!agentId) agentId = 0
        const res = await getOutboundCallsMiddleWearStatistics(brandId, franchiseId, agentId , outboundStartDate, outboundEndDate)
        setOutboundMiddlewearStatistics(res)
    } catch (error) {
        if (shouldRetry(backoffConfig) && error?.status !== 400) {
            retry(() => handleGetOutboundMiddlewearStatistics(brandId, franchiseId, agentId, startDate, endDate, setOutboundMiddlewearStatistics, setIsLoading, setIsError, backoffConfig), backoffConfig)
        } else {
            setIsError(true);
        }
    } finally {
        setIsLoading(false)
    }
}

export async function handleGetMiddleWearStatisticsAllCallCount(brandId,brandBrandId ,franchiseId,franchiseFranchiseId ,agentId, mediaType, startDate, endDate, setMiddlewearStatisticsAllCallsCount=()=>{}, setIsLoading=()=>{}, setIsError=()=>{}, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    try {
        setIsError(false)
        setIsLoading(true)
        const startdateOnMoment = moment(startDate)
        const enddateOnMoment = moment(endDate)
        const outboundStartDate = startdateOnMoment.tz('UTC').format("YYYY-MM-DDTHH:mm:ssZ")
        const outboundEndDate = enddateOnMoment.tz('UTC').format("YYYY-MM-DDTHH:mm:ssZ")
        const inboundStartDate = moment(startDate).format("YYYY-MM-DD Z")
        const inboundEndDate = moment(endDate).format("YYYY-MM-DD Z")
        if (!agentId) agentId = 0
        const res = await getAllCallCountWithCategory(brandId,brandBrandId ,franchiseId, franchiseFranchiseId ,agentId, mediaType, inboundStartDate, inboundEndDate, outboundStartDate, outboundEndDate)
        setMiddlewearStatisticsAllCallsCount(res)
    } catch (error) {
        if (shouldRetry(backoffConfig) && error?.status !== 400) {
            retry(() => handleGetMiddleWearStatisticsAllCallCount(brandId,brandBrandId ,franchiseId, franchiseFranchiseId ,agentId, mediaType, startDate, endDate, setMiddlewearStatisticsAllCallsCount, setIsLoading, setIsError, backoffConfig), backoffConfig)
        } else {
            setIsError(true);
        }
    } finally {
        setIsLoading(false)
    }
}

export async function handleGetInboundMiddlewearStatisticsCallCountByCategory(brandId, franchiseId, agentId, mediaType, startDate, endDate, setInboundMiddlewearStatisticsCallsCountByCategory=()=>{}, setIsLoading=()=>{}, setIsError=()=>{}, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    try {
        setIsError(false)
        setIsLoading(true)
        const inboundStartDate = moment(startDate).format("YYYY-MM-DD Z")
        const inboundEndDate = moment(endDate).format("YYYY-MM-DD Z")
        if (!agentId) agentId = 0
        const res = await getInboundCallCountWithCategory(brandId, franchiseId, agentId, mediaType?.title, inboundStartDate, inboundEndDate)
        setInboundMiddlewearStatisticsCallsCountByCategory(res)
    } catch (error) {
        if (shouldRetry(backoffConfig) && error?.status !== 400) {
            retry(() => handleGetInboundMiddlewearStatisticsCallCountByCategory(brandId, franchiseId, agentId, mediaType, startDate, endDate, setInboundMiddlewearStatisticsCallsCountByCategory, setIsLoading, setIsError, backoffConfig), backoffConfig)
        } else {
            setIsError(true);
        }
    } finally {
        setIsLoading(false)
    }
}

export async function handleGetOutboundMiddlewearCallCountByCategory(brandId, franchiseId, agentId, startDate, endDate, setOutboundCallCountByCategory=()=>{}, setIsLoading=()=>{}, setIsError=()=>{}, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    try {
        setIsError(false)
        setIsLoading(true)
        const startdateOnMoment = moment(startDate)
        const enddateOnMoment = moment(endDate)
        const outboundStartDate = startdateOnMoment.tz('UTC').format("YYYY-MM-DDTHH:mm:ssZ")
        const outboundEndDate = enddateOnMoment.tz('UTC').format("YYYY-MM-DDTHH:mm:ssZ")
        if (!agentId) agentId = 0
        const res = await getOutboundCallCountWithCategory(brandId, franchiseId, agentId , outboundStartDate, outboundEndDate)
        setOutboundCallCountByCategory(res)
    } catch (error) {
        if (shouldRetry(backoffConfig) && error?.status !== 400) {
            retry(() => handleGetOutboundMiddlewearCallCountByCategory(brandId, franchiseId, agentId, startDate, endDate, setOutboundCallCountByCategory, setIsLoading, setIsError, backoffConfig), backoffConfig)
        } else {
            setIsError(true);
        }
    } finally {
        setIsLoading(false)
    }
}