import { Box, Button, Dialog, IconButton, Tooltip } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import React, { useContext, useEffect, useState, useCallback } from "react";
import { handleGetArrivalWindowByBrandId } from "../../../../state-services/brands";
import { handleAppointmentAssignment , handleUpdateTechnicianCriteria,handleGetTechnicianCriteria ,handleAppointmentUnassignment, handleGetAppointmentAssignments, handleRescheduleAppointment } from "../../../../state-services-dublicate/serviceTitan";
import CreateAppointmentSection from "./servicetitan/CreateAppointmentSection";
import LoadingButton from "../../../../components/LoadingButton";
import { NOTIFICATION_TYPES } from "../../../../constants/common";
import { CommonContext } from "../../../../contexts/CommonContextProvider";

export default function RestoreJobWithNewTimesModal(props) {
    const { franchise, brand, isOpen, showNotification, onCloseHandler, appointmentToReschedule, job, jobRestore, toggleRefresh, toggleRefreshAppointments, status} = props

    const [arrivalWindow, setArrivalWindow] = useState(null)
    const [isRecheduleAppointmentLoading, setIsRecheduleAppointmentLoading] = useState(false)
    const [previouslyScheduledTechnicianIds, setPreviouslyScheduledTechnicianIds] = useState([])
    const [appointment, setAppointment] = useState(null)
    const [zipCode, setZipCode] = useState("")
    const [criteriaId, setCriteriaId] = useState(0) 
    const [technicianCriteria, setTechnicianCriteria] = useState(new Map()) 

    const { toggleGetAppointments, setToggleGetAppointments } = useContext(CommonContext);

    const GetTechnicianCriteria = useCallback(
        async () => {
            let res = await handleGetTechnicianCriteria(appointmentToReschedule?.id)
            if (res) {
                setTechnicianCriteria(res?.technician_criteria)
                setCriteriaId(res?.id)
                setZipCode(res?.zip_code)
            }
        },
        [appointmentToReschedule],
    )

    useEffect(() => {
        GetTechnicianCriteria()
        return () => {
        }
    }, [])

    useEffect(() => {
        if (brand) handleGetArrivalWindowByBrandId(brand?.id, setArrivalWindow)
        return () => {
            setArrivalWindow(null)
        }
    }, [brand])

    useEffect(() => {
        handleGetAppointmentAssignments(franchise, appointmentToReschedule, setPreviouslyScheduledTechnicianIds)
    }, [franchise, appointmentToReschedule])

    const handleCloseRescheduleAppointmentModal = (isRescheduled = false) => {
        setToggleGetAppointments(!toggleGetAppointments)
        onCloseHandler(isRescheduled)
    }

    async function rescheduleAppointment(e) {
        try {
            e?.preventDefault();
            if (!(appointment?.arrivalWindowStart && appointment?.arrivalWindowEnd)) return showNotification({ message: "Important: Please select a timeslot and try again", type: NOTIFICATION_TYPES.ERROR })
            await jobRestore(franchise, ()=>{}, ()=>{}, toggleRefresh, toggleRefreshAppointments, ()=>{}, status, showNotification, job)
            setIsRecheduleAppointmentLoading(true)
            await handleRescheduleAppointment(franchise, appointmentToReschedule?.id, appointment)
            if (previouslyScheduledTechnicianIds?.length > 0) {
                const appointmentUnssignmentRequest = {
                    jobAppointmentId: appointmentToReschedule.id,
                    technicianIds: previouslyScheduledTechnicianIds ?? []
                }
                await handleAppointmentUnassignment(franchise, appointmentUnssignmentRequest)
            }
            if (appointment?.technicianIds?.length > 0) {
                const appointmentAssignmentRequest = {
                    jobAppointmentId: appointmentToReschedule.id,
                    technicianIds: appointment.technicianIds
                }
                await handleAppointmentAssignment(franchise, appointmentAssignmentRequest)
            }
            if(criteriaId !== 0)  await handleUpdateTechnicianCriteria(criteriaId, technicianCriteria)
            setIsRecheduleAppointmentLoading(false)
            showNotification({ message: "The appointment has been rescheduled successfully", type: NOTIFICATION_TYPES.SUCCES })
            handleCloseRescheduleAppointmentModal(true)
        } catch(error) {
            showNotification({ message: "Important: appointment couldn’t be rescheduled at this time. Please try again", type: NOTIFICATION_TYPES.ERROR })
        }
    }

    return <Dialog maxWidth="xl" fullWidth={true} open={isOpen} onClose={onCloseHandler}>
        <Tooltip title={"Close"}>
            <IconButton onClick={() => { onCloseHandler(false) }} style={{ position: "absolute", right: "5px", top: "5px" }} size={"small"} aria-label="delete">
                < CloseIcon style={{ height: "15px", width: "15px" }} />
            </IconButton>
        </Tooltip>
        <form onSubmit={rescheduleAppointment}>
            <Box sx={{ padding: "20px" }}>
                <MemoizedCreateAppointmentSection
                    franchise={franchise}
                    brand={brand}
                    job={job}
                    arrivalWindow={arrivalWindow}
                    appointment={appointment}
                    setAppointment={setAppointment}
                    isReschedule={appointmentToReschedule !== undefined}
                    appointmentToReschedule={appointmentToReschedule}
                    zipCode={zipCode}
                    setTechnicianCriteria = {setTechnicianCriteria}
                    previouslyScheduledTechnicianIds={previouslyScheduledTechnicianIds}
                />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box sx={{ display: "flex", flexDirection: "row", gap: "0.5em", marginLeft: "auto", padding: "10px" }}>
                    <Button size={"medium"} variant="contained" className={"btn-secondary"} onClick={() => { onCloseHandler(false) }} >Cancel</Button>
                    <LoadingButton type={"submit"} size={"medium"} variant="contained" disabled={isRecheduleAppointmentLoading} loading={isRecheduleAppointmentLoading} className={isRecheduleAppointmentLoading ? "btn-disable" : "btn-primary"} >
                        {"Reschedule"}
                    </LoadingButton>
                </Box>
            </Box>
        </form>
    </Dialog >;
}

const MemoizedCreateAppointmentSection = React.memo(CreateAppointmentSection);
