import { useEffect, useState } from "react"
import ReportCard from "./ReportCard"
import chat from "../../../../assets/img/chat.png";
import { handleGetChatStatistics } from "../../../../state-services/reports/reports";
import Chart, { getChartDataFromMediaReport } from "./Chart";
import { Box } from "@mui/material";
import { INITIAL_SELECTED_DATA } from "../Reports";

const CHART_TITLES = {
    CHAT: "Chat"
}

export default function ChatStatistics(props) {
    const { filtration, setSelectedData, selectedData, onChatStatisticsChange } = props
    const [chatStatistics, setChatStatistics] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(true)

    useEffect(() => {
        setSelectedData(INITIAL_SELECTED_DATA);
            handleGetChatStatistics(
                filtration.brandBrandId,
                filtration.startDate,
                filtration.endDate,
                (data) => {
                    setChatStatistics(data);
                    onChatStatisticsChange(data);
                },
                setIsLoading,
                setIsError
            ); 
    }, [filtration.brandBrandId, filtration.startDate, filtration.endDate]);

    return <ReportCard title={"Chat"} image={chat}>
        {!filtration?.franchiseId ?
            <Chart width={200} data={getChartDataFromMediaReport(CHART_TITLES.CHAT, chatStatistics)} onClick={setSelectedData} isSelected={selectedData.title === CHART_TITLES.CHAT} isLoading={isLoading} isError={isError} />
            :
            <Box sx={{ padding: "0.5em", height: "200px", width: "200px", backgroundColor: 'background.main', borderRadius: "0.5em", display: "flex", flexDirection: "row", alignItems: "center" }} >
                <p style={{ marginLeft: "40%" }}>{"N/A"}</p>
            </Box>
        }
    </ReportCard>
}
