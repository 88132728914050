import { Autocomplete, Box } from "@mui/material";
import { useEffect, useState } from "react";
import RenderInput from "../../../../../components/servicetitan/RenderInput";
import { handleGetJobLables } from "../../../../../state-services-dublicate/vonigo";
import { CustomTextField } from "../../components/customComponents";

export default function CreateJobSection(props) {
    const { brand, franchise, existingJob, onJobDetailsChange, setnumberOfTimesJobDetailsChanged, numberOfTimesJobDetailsChanged } = props
    const [summary, setSummary] = useState(existingJob?.summary?.fieldValue ?? "")
    const [jobLables, setJobLables] = useState([])
    const [isJobLablesLoading, setIsJobLablesLoading] = useState(false)
    const [selectedLabal, setSelectedLabal] = useState(existingJob?.label ?? null);

    useEffect(() => {
        handleGetJobLables(brand, franchise, setIsJobLablesLoading, setJobLables)
    },[brand, franchise])

    useEffect(() => {
        const newJobDetails = {
            summary: summary,
            labelOptionId: selectedLabal?.optionID
        }
        onJobDetailsChange(newJobDetails)
    }, [summary, selectedLabal, onJobDetailsChange])

    return <>
        <h4 style={{ marginBottom: "1em" }}>Job Details</h4>
        <Box sx={{ display: 'flex', flexDirection: "row", flexWrap: "wrap", width: "100%", gap: "1em" }} >
            <Box style={{ width: "100%", }}>
                <CustomTextField disabled={false} multiline rows={3} required={true} label="Summary" variant="outlined" size="small" style={{ width: "100%", }} value={summary} onChange={(event) => {
                    setnumberOfTimesJobDetailsChanged(numberOfTimesJobDetailsChanged + 1)
                    setSummary(event.target.value);
                }} />
            </Box>
            <Autocomplete
                sx={{ width: "100%", }}
                size="small"
                noOptionsText={franchise ? "No lables found" : "Please select a franchise"}
                loading={isJobLablesLoading}
                value={selectedLabal ?? null}
                disablePortal
                disableClearable
                options={jobLables}
                getOptionLabel={(jobLabel) => jobLabel?.name ?? "-"}
                renderOption={(props, c) => <RenderInput {...props} key={c?.objectID} content={c?.name} />}
                onChange={(_, value) => {
                    setnumberOfTimesJobDetailsChanged(numberOfTimesJobDetailsChanged + 1)
                    setSelectedLabal(value);
                }}
                renderInput={(params) => <CustomTextField   {...params} required={true} label="Select job label" />}
            />
        </Box></>
}