import { FetchClient } from "../utils/fetchClient";

export const getFranchiseByBrandId = async (brandId, enabledStatus) => {
    let queryParam = ""
    if (enabledStatus) {
        queryParam = `&is_enabled=${enabledStatus}`
    }
    return FetchClient(`/data/franchises?brand_id=${brandId}${queryParam}`).GET({})
}

export const getFranchiseBrands = async (franchise_ids = []) => {
    let params = ""
    franchise_ids.forEach(id => {
        params = params.concat(`${id},`)
    })
    params = params.slice(0, -1)
    return FetchClient(`/data/v3/franchise-brands?franchise_id=${params}`).GET({})
}

export const getAllFranchises = async (status = "") => {
    return FetchClient(`/data/franchises?is_enabled=${status}`).GET({})
}

export const getFranchiseById = async (id) => {
    return FetchClient(`/data/franchises?id=${id}`).GET({})
}

export const getFranchiseByIds = async (ids = []) => {
    let params = ""
    ids.forEach(id => {
        params = params.concat(`id=${id}&`)
    });
    params = params.slice(0, -1)
    return FetchClient(`/data/franchises?${params}`).GET({})
}

export const franchiseDistanceNew = async (body) => {
    return FetchClient('/data/distances2').POST({ payload: JSON.stringify(body) })
}

export const getUsaGeocodes = async (zipcode) => {
    return FetchClient(`/data/get-usa-geo-codes?zip_code=${zipcode}`).GET({})
}

export const getCanadaGeocodes = async (zipcode) => {
    return FetchClient(`/data/get-canada-geo-codes?zip_code=${zipcode}`).GET({})
}

export const getFranchiseByStatus = (status, brand, page, size) => {
    let brandId = brand ? brand : 0
    return FetchClient(`/data/franchises?is_enabled=${status}&brand_id=${brandId}&page=${page}&size=${size}`).GET({})
}

export const searchFranchiseByStatus = (status, brand, key, page, size) => {
    let brandId = brand ? brand : 0
    return FetchClient(`/data/franchises?is_enabled=${status}&brand_id=${brandId}&key=${key}&page=${page}&size=${size}`).GET({})
}

export const getLocationDataByZip = async (zipCode) => {
    return FetchClient(`/data/location-details-by-zipcode?zip_code=${zipCode}`).GET({})
}