import { Drawer } from '@mui/material'
import React from 'react'
import AgentAssistBody from './BodySection'
import AgentAssistHead from './HeadSection'

export default function AgentAssistDrawer({ drawerOpen, setDrawerOpen }) {
    const handleCloseDrawer = () => setDrawerOpen(false)
    return (
        <Drawer sx={{ zIndex: 1301 }} anchor="right" className="dock-drawer" open={drawerOpen} onClose={handleCloseDrawer}>
            <div className="agent-assist-box">
                <AgentAssistHead setToggle={setDrawerOpen} />
                <AgentAssistBody />
            </div>
        </Drawer>
    )
}