import { Box, Autocomplete } from "@mui/material";
import CustomTextField from "../../../../../components/CustomTextField";
import { ALL_FRANCHISE } from "../../../../../constants/serviceTitan"
import { STATUSES, FILTERATION_PRIORITIES } from "../../../../../constants/serviceTitan"
import { getUserFullName, getMatchingUserByUserId, getMatchingBrandByBrandId, getMatchingFranchiseById, getCredentialsMissingWarning, getIsSTCredentialsAvailable } from "../../../../../utils/miscellaneous"
import RenderInput, { renderBrandOption, renderUserOption } from "../../../../../components/servicetitan/RenderInput";
import CustomDatePicker from "../../../../../components/CustomDatePicker";
import { TEMPORY_FAILURE } from "../../../../../constants/errorMessages";
import { EncryptionWarningBadge } from "../../../../../components/EncryptionWarningBadge";
import { getFranchiseOptionLabel, renderFranchiseOption } from "../../../utils/franchise";
import { useContext } from "react";
import { CommonContext } from "../../../../../contexts/CommonContextProvider";
import { getMatchingTimezoneByName } from "../../../../../utils/timezone/timezone";

export default function AdvanceFilterArea(props) {
    const { brands, isBrandsLoading, isFranchisesLoading, uniqueFranchises, users, filterState, setFilterState, isEncryptionFailed } = props

    const { generalTenantConfiguration, timezones } = useContext(CommonContext)

    return <Box className="st-filter-area" style={{ display: "flex", flexDirection: "row", gap: "2em" }}>
        <Box sx={{ maxWidth: "12em" }}>
            <h4>Filter Your Results Here</h4>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", flexGrow: 2, gap: "1em", flexWrap: "wrap", justifyContent: "end" }}>
            <div style={{ width: "300px", zIndex: 4 }} >
                <CustomDatePicker
                    maxTime={new Date(filterState.endFollowUpDate)}
                    disabled={filterState?.universalSearchString !== "" ? true : false}
                    label={`Next Follow-Up Start ( ${getMatchingTimezoneByName(generalTenantConfiguration?.time_zone, timezones)?.abbreviation ?? ""} )`}
                    value={new Date(filterState.startFollowUpDate)}
                    onChange={(date) => {
                        if (date) {
                            setFilterState({ ...filterState, startFollowUpDate: date.toISOString() })
                        }
                    }}
                    timeZone={generalTenantConfiguration?.time_zone}
                    clearable={false}
                    required={true}
                />
            </div>
            <div style={{ width: "300px", zIndex: 3 }} >
                <CustomDatePicker
                    minTime={new Date(filterState.startFollowUpDate)}
                    disabled={filterState?.universalSearchString !== "" ? true : false}
                    label={`Next Follow-Up End ( ${getMatchingTimezoneByName(generalTenantConfiguration?.time_zone, timezones)?.abbreviation ?? ""} )`}
                    value={new Date(filterState.endFollowUpDate)}
                    onChange={(date) => {
                        if (date) {
                            setFilterState({ ...filterState, endFollowUpDate: date.toISOString() })
                        }
                    }}
                    timeZone={generalTenantConfiguration?.time_zone}
                    clearable={false}
                    required={true}
                />
            </div>
            <Autocomplete
                sx={{ minWidth: "350px" }}
                size="small"
                loading={isBrandsLoading}
                noOptionsText={"No brands"}
                disabled={brands === [] || filterState?.universalSearchString !== ""}
                disablePortal
                disableClearable
                options={brands}
                getOptionLabel={(brand) => brand.brand_name}
                renderOption={renderBrandOption}
                value={getMatchingBrandByBrandId(filterState.brandId, brands) ?? null}
                onChange={(_, brand) => {
                    setFilterState({ ...filterState, brandId: brand.brand_id, franchiseId: "allFranchises", serviceTitanTenantId: "allFranchises" });
                }}
                renderInput={(params) => <CustomTextField {...params} label="Brand" />}
            />
            <Autocomplete
                sx={{ minWidth: "200px", maxWidth: "300px", flexGrow: 2 }}
                size="small"
                disabled={uniqueFranchises === [] || isEncryptionFailed || filterState?.universalSearchString !== ""}
                disablePortal
                loading={isFranchisesLoading}
                noOptionsText={"No ServiceTitan franchises"}
                disableClearable
                options={filterState?.brandId === "allBrands" ? [ALL_FRANCHISE] : uniqueFranchises}
                getOptionLabel={(franchise) => getFranchiseOptionLabel(franchise)}
                getOptionDisabled={(f) => getIsSTCredentialsAvailable(f) || f.id !== ALL_FRANCHISE.id}
                renderOption={(props, franchise) => renderFranchiseOptionWithEncryptionBadge(props, franchise, isEncryptionFailed)}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                value={filterState?.brandId === "allBrands" ? getMatchingFranchiseById(filterState.franchiseId, [ALL_FRANCHISE]) ?? null : getMatchingFranchiseById(filterState.franchiseId, uniqueFranchises) ?? null}
                onChange={(_, franchise) => {
                    setFilterState({ ...filterState, franchiseId: franchise.id, serviceTitanTenantId: franchise.service_titan_tenant_id });
                }}
                renderInput={(params) => <div style={{ position: "relative" }}>
                    <CustomTextField
                        {...params}
                        label="Franchise" />
                    {isEncryptionFailed ? <EncryptionWarningBadge title={TEMPORY_FAILURE} /> : <></>}
                </div>
                }
            />
            <Autocomplete
                sx={{ minWidth: "200px" }}
                size="small"
                disablePortal
                disabled={filterState?.universalSearchString !== "" ? true : false}
                disableClearable={true}
                options={users}
                getOptionLabel={(user) => getUserFullName(user)}
                renderOption={renderUserOption}
                value={filterState.userId ? getMatchingUserByUserId(filterState.userId, users) ?? null : null}
                onChange={(_, user) => {
                    if (user) {
                        setFilterState({ ...filterState, userId: user.user_id });
                    }
                }}
                renderInput={(params) => <CustomTextField {...params} label="User" />}
            />
            <Autocomplete
                sx={{ minWidth: "120px" }}
                size="small"
                disablePortal
                disableClearable
                disabled={filterState?.universalSearchString !== "" ? true : false}
                options={STATUSES}
                getOptionLabel={(status) => status}
                renderOption={renderStatusOption}
                value={filterState.status ?? null}
                onChange={(_, status) => {
                    setFilterState({ ...filterState, status: status });
                }}
                renderInput={(params) => <CustomTextField {...params} label="Status" />}
            />
            <Autocomplete
                sx={{ minWidth: "130px" }}
                size="small"
                disablePortal
                disableClearable
                disabled={filterState?.universalSearchString !== "" ? true : false}
                options={FILTERATION_PRIORITIES}
                getOptionLabel={(priority) => priority}
                renderOption={renderPriorityOption}
                value={filterState.priority ?? null}
                onChange={(_, priority) => {
                    setFilterState({ ...filterState, priority: priority });
                }}
                renderInput={(params) => <CustomTextField {...params} label="Priority" />}
            />
        </Box>
    </Box>
}

function renderFranchiseOptionWithEncryptionBadge(props, franchise, isEncryptionFailed) {
    return <div style={{ position: "relative" }}>
        {renderFranchiseOption(props, franchise)}
        {getIsSTCredentialsAvailable(franchise) || franchise?.id === ALL_FRANCHISE?.id ? <></> : <EncryptionWarningBadge title={isEncryptionFailed ? TEMPORY_FAILURE : getCredentialsMissingWarning(franchise)} bottom={"3px"} right={"5px"} />}
    </div>
}

function renderPriorityOption(props, priority) {
    return <RenderInput {...props} key={priority} content={priority} />
}

function renderStatusOption(props, status) {
    return <RenderInput {...props} key={status} content={status} />
}