import { Grid } from "@mui/material";
import AngiesServicetitanConfigurationsSection from "./AngiesServicetitanConfigurationsSection";

export default function AngiesConfigurationsSection(props) {
    const { oldFranchise, angiesConfigurations, setAngiesConfigurations, angiesTaskNameConfigurations, setAngiesTaskNameConfigurations } = props;
    return <Grid container >
        {oldFranchise?.id ?
            <AngiesServicetitanConfigurationsSection oldFranchise={oldFranchise} angiesConfigurations={angiesConfigurations} setAngiesConfigurations={setAngiesConfigurations} angiesTaskNameConfigurations={angiesTaskNameConfigurations} setAngiesTaskNameConfigurations={setAngiesTaskNameConfigurations} />
            : <></>}
    </Grid >
}