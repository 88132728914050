import { DISABLE_DESKTOP_SYNC } from "../pages/other/components/DesktopConnector"
import { checkDesktopAppWorking, startRecordingInDesktopApp } from "../services/desktopCallRecordingService"

export const handleCheckAppWorking = async () => {
    try {
        await checkDesktopAppWorking()
        return true
    } catch (error) {
        if (!DISABLE_DESKTOP_SYNC) {
            window.location.href = "ibs-cc-assist://"     // launch desktop application
        }
        return false
    }
}

export const handleStartRecordingOnDesktopApp = async (recordingdata) => {
    try {
        await startRecordingInDesktopApp(recordingdata)
    } catch (error) {
    }
}
