import { Box, Card, CardMedia } from '@mui/material'
import OfflineIcon from "../../../../assets/img/screen_monitoring_images/offlineIcon.png"
import React from 'react'

export default function MonitorMessageCard({ type, image, agent, handleReconnect }) {
    return (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", height: "100%" }}>
            <div style={{ marginTop: "5%", textAlign: "center" }}>
                {type === "default" ?
                    <h2>Experience The Benefits Of Seamless Visibility, No Matter Where Your Team Works </h2>
                    : type === "offline" ?
                        <h2>{`Hi there! We noticed that ${agent?.first_name} ${agent?.last_name} is not currently connected to the CCAI Desktop Application`}</h2>
                        : type === "monitoring" ?
                            <h2>{`Already Monitoring !`}</h2>
                            : <></>}
            </div>
            <Card sx={{ width: "auto", boxShadow: 'none', marginTop: 8, marginBottom: 4 }}>
                <CardMedia
                    component="img"
                    alt="card image"
                    height="80%"
                    image={image}
                />
            </Card>
            {type === "error" &&
                <div style={{ marginTop: "5%" }}>
                    <h2>{`Oops!`}</h2>
                </div>
            }
            <div style={{ marginTop: "1%", textAlign: "center" }}>
                {type === "default" ?
                    <p style={{ fontSize: "16px", color: "gray" }}>Raise the team's performance to new heights by understanding the inner workings of the PSB. </p>
                    : type === "offline" ?
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <img style={{ height: "35px", width: "35px" }} src={OfflineIcon}></img><p style={{ fontSize: "16px", color: "gray" }}>It is imperactive to ensure that the application is installed and running on the agent's device and agent must receive at least one call after the installation of the application for a successful connection</p>
                            </div>
                        </div>
                        : type === "monitoring" ?
                            <p style={{ fontSize: "16px", color: "gray" }}>{`We Apologize, ${agent?.first_name} ${agent?.last_name}'s Screen Is Currently Being Monitored By Another Administrator`}</p>
                            :
                            type === "error" ?
                                <p style={{ fontSize: "16px", color: "gray" }}>{`Seems Connection Lost ,Need to get confirmation on connection stability or desktop application is opened from agent side`}</p>
                                : <></>
                }
            </div>
        </Box>
    )
}
