import * as React from 'react';
import { Button, Dialog } from "@mui/material";

export default function AlertDialog(props) {

  const { title,extraDetails,open,setOpen,confirmFunction } = props

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
    <div className={"dialog"}>
        <h3 className={"dialog-title"}>
            {title}
        </h3>
        <p style={{ marginTop: "-10px", marginBottom: "15px" }}>
            {extraDetails??""}
        </p>
        <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
                size={"small"}
                variant="contained"
                onClick={handleClose}
                className={"btn-secondary"}
                style={{ marginRight: "10px" }}
            >
                No
            </Button>
            <Button
                size={"small"}
                variant="contained"
                onClick={()=>confirmFunction()}
                className={"btn-primary"}
            >
                yes
            </Button>
        </div>
    </div>
</Dialog>
  );
}