import { useEffect, useState } from "react"
import { handleGetSelectedCustomerLocations } from "../../../../../state-services-dublicate/serviceTitan"
import { Box, Paper, Typography } from "@mui/material"
import NoContentView from "../../../../../components/refreshView/NoContentView"
import RefreshErrorView from "../../../../../components/refreshView/RefreshErrorView"
import LoadingView from "../../../../../components/refreshView/LoadingView"
import SmallCard from "../../../../../components/servicetitan/SmallCard"
import { getAddressString } from "../../../../../utils/miscellaneous"

export default function LocationsSection(props) {
    const { franchiseId, customerId } = props
    const [locations, setLocations] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [toggleGetLocations, setToggleGetLocations] = useState(false)

    useEffect(() => {
        handleGetSelectedCustomerLocations(franchiseId, customerId, setLocations, undefined, setIsLoading, setIsError)
    }, [franchiseId, customerId, toggleGetLocations])

    return franchiseId && customerId ? <Box marginTop="0.5em" width="100%">
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
            <Typography marginLeft="0.5em" component="h5" fontWeight="bold" >Locations</Typography>
        </Box>
        {isError ? <RefreshErrorView onRefresh={() => setToggleGetLocations(!toggleGetLocations)} /> : <></>}
        {isLoading ? <LoadingView /> : locations?.length === 0 ? <NoContentView message={"No leads to show"} /> : locations.map((l, i) => <LocationCard location={l} index={i} />)}
    </Box > : <></>
}

function LocationCard(props) {
    const { location } = props

    return <Paper key={location?.id} style={{ display: "flex", flexDirection: "column", borderRadius: "0.5em", border: "1px solid gray", borderLeft: "4px solid #00a79d", marginTop: "0.5em" }}>
        <Box sx={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "start", gap: "0.5em", padding: "0.25em", justifyContent: "space-evenly" }}>
            <Box width="10em"><SmallCard heading="Name" value={location?.name ?? "-"} /></Box>
            <Box flexGrow={2} ><SmallCard heading="Address" value={getAddressString(location?.address) ?? "-"} /></Box>
        </Box >
    </Paper >
}