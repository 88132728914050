import React from 'react'
import { useState, useEffect, useContext } from 'react';
import { Box, TableCell, TableRow,Chip ,IconButton, Tooltip, Typography } from '@mui/material'
import CustomTextField from '../../../../components/CustomTextField';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from "@mui/icons-material/Delete";
import BrandTemplateFaqDrawer from './BrandTemplateFaqDrawer';
import { UserContext } from '../../../../contexts/User';
import { USER_TYPES } from '../../../../constants/users';

export default function BrandTemplateFaqRow({ id, faq, isOpenDRawer, setIsOpenDRawer, questionToEdit, faqTemplateErros, setFaqTemplateErros, setQuestionToEdit, faqs, setFaqs, isAddNewQuestion, setIsAddNewQuestion, isKeywordsLoading, keywords }) {

    const [displayedKeywords, setDisplayedKeywords] = useState([]);
    const [remainingCount, setRemainingCount] = useState(0);
    const { userPreferences, me } = useContext(UserContext)

    useEffect(() => {
        let totalFaqKeywords = faq.keywords.length;
        setDisplayedKeywords(faq.keywords.slice(0, 1));
        setRemainingCount(totalFaqKeywords - 1);
    }, [faq?.keywords]);

    const checkIfTheIndexIsTheLast = (index) => {
        if (faqs.length === 1) return true
        return index === faqs.length - 1
    }

    const deleteQuestion = () => {
        let newFaqs = [...faqs]
        newFaqs = newFaqs.filter((faq) => faq.id !== id)
        setFaqs(newFaqs)
        let newFaqTemplateErros = faqTemplateErros?.filter?.((error) => error?.id !== id)
        setFaqTemplateErros(newFaqTemplateErros)
    }

    const checkShouldDisableFields = (index) => {
        if (!questionToEdit) {
            if (isAddNewQuestion) return isAddNewQuestion && !checkIfTheIndexIsTheLast(index)
            return true
        }
    }

    const getFaqQuestionErros = (id) => {
        let questionError = faqTemplateErros?.find?.((error) => error?.id === id)?.question
        let answerError = faqTemplateErros?.find?.((error) => error?.id === id)?.answer
        return { questionError, answerError }
    }


    const checkIfFieldIsRequired = (field) => {
        if (field === "question") {
            return (faq?.answer === "" && faq?.question !== "") && id === faq?.id ? true : false
        } else if (field === "answer") {
            return (faq?.question === "" && faq?.answer !== "") && id === faq?.id ? true : false
        }
    }

    return <>
        <TableRow key={id} >
            <TableCell colSpan={1} >
                <Box sx={{ minHeight: "4em", width: "100%" }} >
                    <CustomTextField
                        sx={{
                            width: "100%",
                        }}
                        inputProps={{
                            "data-test": "faq_question"
                        }}
                        error={getFaqQuestionErros(id)?.questionError}
                        required={checkIfFieldIsRequired("answer")}
                        value={faq?.question ?? null}
                        disabled={checkShouldDisableFields(id)}
                        multiline
                        maxRows={1}
                        size='small'
                        margin="normal"
                        variant="outlined"
                    />
                </Box>
            </TableCell>
            <TableCell colSpan={1} >
                <Box sx={{ minHeight: "4em", width: "100%" }} >
                    <CustomTextField
                        sx={{
                            width: "100%",
                        }}
                        inputProps={{
                            "data-test": "faq_answer"
                        }}
                        error={getFaqQuestionErros(id)?.answerError}
                        value={faq?.answer}
                        required={checkIfFieldIsRequired("question")}
                        disabled={checkShouldDisableFields(id)}
                        multiline
                        maxRows={1}
                        margin="normal"
                        size='small'
                        variant="outlined"
                    />
                </Box>
            </TableCell>
            <TableCell colSpan={1} width="100%">
                <Box sx={{ display: "flex", alignItems: "center", height: "2.9em", marginTop: "0.6em", width:"14em", borderRadius: "0.3em", border: userPreferences?.dark_mode_enabled ? "1px solid #5F6061" : "1px solid #ccc", overflow: "auto" }} >
                    {displayedKeywords.map((keyword, index) => (
                        <Chip
                            key={index}
                            sx={{ margin: "0.4em" }}
                            size="small"
                            disabled={true}
                            label={keyword}
                        />
                    ))}
                    {remainingCount > 0 && (
                        <Typography
                            sx={{
                                margin: "0.4em",
                                flexShrink: 0,
                                whiteSpace: "nowrap",
                            }}
                        >
                            {`+${remainingCount} more`}
                        </Typography>
                    )}
                </Box>
            </TableCell>
            {(isAddNewQuestion && checkIfTheIndexIsTheLast(id)) ?
                <></>
                :
                <TableCell width="100%" align='left' sx={{ paddingLeft: '0px', paddingTop: "3em" }}>
                    <Box sx={{ display: "flex", marginBottom: "1.5em" }}>
                        <Tooltip title={me?.level <= USER_TYPES?.supervisor?.level ? "View" : "View & Edit"} placement="top" arrow>
                            <IconButton sx={{ paddingLeft: '0px', marginLeft: "auto" }} disabled={isAddNewQuestion} title='Edit' color={!isAddNewQuestion ? 'info' : '#0000001f'} onClick={() => setQuestionToEdit(faq)} >
                                <VisibilityIcon style={{ height: "20px", width: "20px" }} color={isAddNewQuestion ? 'disabled' : 'primary'} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete" placement="top" arrow>
                            <IconButton sx={{ marginRight: "auto" }} type={"button"} disabled={isAddNewQuestion} title='Delete' color={!isAddNewQuestion ? "error" : "#0000001f"} onClick={() => deleteQuestion()}>
                                <DeleteIcon style={{ height: "20px", width: "20px" }} />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </TableCell>
            }
        </TableRow>
        {checkIfTheIndexIsTheLast(id) &&
            <BrandTemplateFaqDrawer id={id} faq={faq} faqTemplateErros={faqTemplateErros} setFaqTemplateErros={setFaqTemplateErros}
                faqs={faqs} setFaqs={setFaqs} setIsAddNewQuestion={setIsAddNewQuestion} isKeywordsLoading={isKeywordsLoading} keywords={keywords} drawerOpen={isOpenDRawer} onClose={() => { setIsOpenDRawer(false) }} ></BrandTemplateFaqDrawer>
        }
    </>
}
