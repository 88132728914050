import { Accordion, AccordionDetails, IconButton, AccordionSummary, Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useState, useEffect,useRef } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from "@mui/icons-material/Add";
import { INITIAL_EDIT_INDEX } from '../../franchise/model/OwnerTable/constants/common';
import BrandJobTypeRow from './jobTypeRow';
import EditJobTypeRow from './editJobTypeRow';
import { handleLoadBrandJobTypes } from '../../../../state-services/brands';

export default function BrandJobTypes(props) {

    const { loading,brandId, setUpdateBrandJobTypes, updateBrandJobTypes } = props

    const [editIndex, setEditIndex] = useState(INITIAL_EDIT_INDEX)
    const [isAddNewJobType, setIsAddNewJobType] = useState(false)
    const [jobTypes, setJobTypes] = useState([])

    const tableRef = useRef(null);

    useEffect(() => {
        (async () => {
            if (brandId) {
                await handleLoadBrandJobTypes(brandId, setJobTypes)
            }
        })
            ()
    }, [brandId])


    // cancel the job type edit
    const oncancelJobTypeEdit = () => {
        setIsAddNewJobType(false)
        setEditIndex(INITIAL_EDIT_INDEX)
    }

    // to add new job type
    const onAddNewJobType = () => {
        setIsAddNewJobType(true)
        setEditIndex(INITIAL_EDIT_INDEX)
        if (tableRef.current) {
            tableRef.current.scrollTo(0, 0);
          }
    }

    return (
        <Box sx={{ width: "auto", marginBottom: '10px' }}>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon data-test="brand_job_types_expand" />}>
                    <p>Job Types</p>
                </AccordionSummary>
                <AccordionDetails sx={{ paddingTop: '0px' }}>
                    <TableContainer component={Paper} sx={{ height: 310 }} ref={tableRef}>
                        <Table aria-label="simple table" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell width="10%">#</TableCell>
                                    <TableCell width="60%">Type</TableCell>
                                    <TableCell width="30%" align="left" sx={{ paddingLeft: '0px' }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', pddingLeft: '0' }}>
                                            <Box>Action</Box>
                                            <IconButton
                                                data-test="brand_jon_type_add"
                                                title="Add Brand Job Type"
                                                size='small'
                                                className={isAddNewJobType || loading ? "icon-btn-disabled" : "icon-btn"}
                                                aria-label="delete"
                                                disabled={isAddNewJobType || loading}
                                                onClick={() => onAddNewJobType()}
                                            >
                                                <AddIcon />
                                            </IconButton>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {isAddNewJobType && editIndex===INITIAL_EDIT_INDEX && (<EditJobTypeRow key={-1} typeIndex={-1} loading={loading} jobTypes={jobTypes} setJobTypes={setJobTypes} jobType={{ type_name: '' }} onCancel={() => oncancelJobTypeEdit()} setUpdateBrandJobTypes={setUpdateBrandJobTypes} updateBrandJobTypes={updateBrandJobTypes} setIsAddNewJobType={setIsAddNewJobType} />)}
                                {jobTypes.length > 0 ? jobTypes.map((jobType, index) => (
                                    editIndex === index ? <EditJobTypeRow key={jobTypes.id} typeIndex={index} loading={loading} jobTypes={jobTypes} jobType={jobType} setJobTypes={setJobTypes} onCancel={() => oncancelJobTypeEdit()} setUpdateBrandJobTypes={setUpdateBrandJobTypes} updateBrandJobTypes={updateBrandJobTypes} /> :
                                        <BrandJobTypeRow key={jobTypes.id} typeIndex={index} loading={loading} jobType={jobType} onEdit={() => setEditIndex(index)} jobTypes={jobTypes} setJobTypes={setJobTypes} setUpdateBrandJobTypes={setUpdateBrandJobTypes} updateBrandJobTypes={updateBrandJobTypes} />

                                )) : !isAddNewJobType && (<TableRow key={1}>
                                    <TableCell colSpan={3} align="center" sx={{ width: "100%" }}>No job types found</TableCell>
                                </TableRow>)}


                            </TableBody>
                        </Table>
                    </TableContainer>
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}
