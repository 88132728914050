import { useState } from 'react';
import { IconButton, TableRow, TableCell } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from "@mui/icons-material/Delete";
import  AlertDialog from '../../../../components/AlertDialog'

export default function BrandJobTypeRow(props) {
    const { typeIndex, loading ,jobType, onEdit, jobTypes, setJobTypes, setUpdateBrandJobTypes, updateBrandJobTypes } = props

    const [openAlertDialog, setOpenAlertDialog] = useState(false)

    // to delete the job type
    const onDelete = () => {
        const deletedJobType = { ...jobType, is_enabled: 0 }

        const index = updateBrandJobTypes.findIndex(updateBrandJobType => updateBrandJobType === jobType);

        // if the job type is not in the update list, then add it to the update list
        if (index === -1) {
            setUpdateBrandJobTypes([...updateBrandJobTypes, deletedJobType])
        } else {
            if (updateBrandJobTypes[index].id) {
                const newUpdateBrandJobTypes = updateBrandJobTypes.toSpliced(index, 1, deletedJobType);
                setUpdateBrandJobTypes(newUpdateBrandJobTypes)
            } else {
                const newUpdateBrandJobTypes = updateBrandJobTypes.toSpliced(index, 1);
                setUpdateBrandJobTypes(newUpdateBrandJobTypes)
            }
        }

        const newJobTypes = jobTypes.filter((job, i) => i !== typeIndex)
        setJobTypes(newJobTypes)
        setOpenAlertDialog(false)

    }

    return <TableRow key={typeIndex}>
        <AlertDialog open={openAlertDialog} extraDetails={jobType.type_name} setOpen={setOpenAlertDialog} title={"Are you sure you want to delete this job type?"} confirmFunction={onDelete}></AlertDialog>
        <TableCell component="th" scope="row">{typeIndex + 1}</TableCell>
        <TableCell component="th" scope="row">{jobType.type_name}</TableCell>
        <TableCell align="left" sx={{ paddingLeft: '0px' }}>
            <IconButton sx={{ paddingLeft: '0px' }} disabled={loading} data-test="brand_job_type_edit" color="info" title='Edit' onClick={onEdit} >
                <EditIcon style={{ height: "20px", width: "20px" }} />
            </IconButton>
            <IconButton data-test="brand_job_type_delete" disabled={loading} style={{ marginRight: "15px" }} title='Delete' color="error" onClick={() => setOpenAlertDialog(true)}>
                <DeleteIcon style={{ height: "20px", width: "20px" }} />
            </IconButton>
        </TableCell>
    </TableRow>
    
}