import { useContext, useEffect, useMemo, useState } from "react";
import { getLuminSalesAcceleratorRequest, handleGetLuminSalesAcceleratorDetails, handleSpinLuminSalesAccelerator } from "../../state-services/lumin/lumin";
import { Box, Tooltip, Typography } from "@mui/material";
import LoadingView from "../refreshView/LoadingView";
import RefreshErrorView from "../refreshView/RefreshErrorView";
import SalesAcceleratorHistory from "./salesAccelerator/SalesAcceleratorHistory";
import { CommonContext } from "../../contexts/CommonContextProvider";
import LoadingButton from "../LoadingButton";

export default function SalesAcceleratorSection(props) {
    const { franchise, middlewareLead, middlewareLeadId, customer, contacts, address, shouldAllowRescheduling } = props;
    const { showNotification } = useContext(CommonContext)
    const [toggleLoading, setToggleLoading] = useState(false)
    const [isSalesAcceleratorLoading, setIsSalesAcceleratorLoading] = useState(false)
    const [disabledMessage, isDisabled] = useMemo(() => {
        if (franchise && middlewareLead && customer && contacts && address) {
            return getIsLuminSalesAcceleratorDisabled(franchise, middlewareLead, customer, contacts, address)
        }
        return ["", true]
    }, [franchise, middlewareLead, customer, contacts, address])

    return <Box sx={{ width: "100%", margin: "0.25em", display: "flex", flexDirection: "column", gap: "0.5em" }}>
        <Box sx={{ width: "100%", display: "flex", flexDirection: "row", gap: "0.5em", justifyContent: "start", alignItems: "center" }} >
            <Typography style={{ fontSize: "1em", fontWeight: "bold" }}>Lumin Sales Accelerator</Typography>
            {shouldAllowRescheduling ?
                <Tooltip title={isDisabled ? disabledMessage : "Spin Lumin Sales Accelerator"}>
                    <span>
                        <LoadingButton disabled={isDisabled} loading={isSalesAcceleratorLoading} variant="contained" size="small" sx={{ textTransform: "none" }} onClick={() => handleSpinLuminSalesAccelerator(franchise, middlewareLead, customer, contacts, address, setIsSalesAcceleratorLoading, () => setToggleLoading((l) => !l), showNotification)}>Spin Sales Accelerator</LoadingButton>
                    </span>
                </Tooltip>
                : <> </>}
        </Box>
        <SalesAcceleratorBody toggleLoading={toggleLoading} setToggleLoading={setToggleLoading} leadId={middlewareLead?.middlewareServiceTitanLead?.id ?? middlewareLeadId} />
    </Box>
}

function SalesAcceleratorBody(props) {
    const { leadId, toggleLoading, setToggleLoading, } = props;

    const [luminRequests, setLuminRequests] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)

    useEffect(() => {
        if (leadId) {
            handleGetLuminSalesAcceleratorDetails(leadId, setLuminRequests, setIsLoading, setIsError)
        }
    }, [leadId, toggleLoading])

    return <Box sx={{ padding: "0.25em", display: "flex", flexDirection: "column", overflow: "auto", gap: "0.5em" }}>
        {isLoading ? <LoadingView /> :
            isError ? <RefreshErrorView onRefresh={() => setToggleLoading((l) => !l)} /> :
                <SalesAcceleratorHistory requests={luminRequests} />
        }
    </Box>
}

function getIsLuminSalesAcceleratorDisabled(franchise, middlewareLead, customer, contacts, address) {
    const [missingDetailsErrorMessage, isRequiredDetailsMissing] = getIsRequiredDetailsMissing(franchise, middlewareLead, customer, contacts, address);
    return [missingDetailsErrorMessage, isRequiredDetailsMissing]
}

function getIsRequiredDetailsMissing(franchise, middlewareLead, customer, contacts, address) {
    const request = getLuminSalesAcceleratorRequest(franchise, middlewareLead, customer, contacts, address);
    let isRequiredDetailsMissing = false;
    const missingDetails = [];
    if (!request?.lumin_sales_accelerator_request?.st_identifiers?.businessunit_id) {
        isRequiredDetailsMissing = true;
        missingDetails.push("business unit");
    }
    if (!request?.lumin_sales_accelerator_request?.st_identifiers?.campaign_id) {
        isRequiredDetailsMissing = true;
        missingDetails.push("campaign");
    }
    if (!request?.lumin_sales_accelerator_request?.st_identifiers?.jobtype_id) {
        isRequiredDetailsMissing = true;
        missingDetails.push("job type");
    }
    if (!request?.lumin_sales_accelerator_request?.st_identifiers?.customer_id) {
        isRequiredDetailsMissing = true;
        missingDetails.push("customer");
    }
    if (!request?.lumin_sales_accelerator_request?.st_identifiers?.location_id) {
        isRequiredDetailsMissing = true;
        missingDetails.push("location");
    }
    if (!request?.lumin_sales_accelerator_request?.st_identifiers?.lead_id) {
        isRequiredDetailsMissing = true;
        missingDetails.push("lead");
    }
    let missingDetailsErrorMessage = "";
    if (isRequiredDetailsMissing) {
        missingDetailsErrorMessage = `Following details are missing to spinning Sales Accelerator: ${missingDetails.join(", ")}. Please edit the lead and add the missing details.`
    }
    return [missingDetailsErrorMessage, isRequiredDetailsMissing];
}
