import moment from "moment";
import { Typography, IconButton, Tooltip, Box, Paper, Grid } from "@mui/material";
import SmallCard, { SmallCardForEstimateName } from "../../../../../components/servicetitan/SmallCard";
import CallMadeIcon from '@mui/icons-material/CallMade';
import { useHistory } from "react-router-dom";

export default function EstimateCard({ index, total, estimate, franchise, brand, serviceTitanEmployees }) {

    const history = useHistory();

    const getCreateByEmployee = () => {
        return serviceTitanEmployees?.find((employee) => employee.id === estimate?.createdBy)
    }

    const redirectToEstimate = () => {
        const estimateId = estimate?.id;
        const franchiseId = franchise?.id;
        const brandId = brand?.brand_id;
        history.push(`/estimates?estimateId=${estimateId}&franchiseId=${franchiseId}&brandId=${brandId}`);
    }

    return <Paper sx={{ width: "100%", borderRadius: "0.5em", border: "1px solid gray", borderLeft: "4px solid #00a79d", marginTop: "0.5em" }}>
        <Box sx={{ display: "flex", flexDirection: "row", padding: "0.4em", alignItems: "center", borderRadius: "0.5em", gap: "1em" }}>
            <Typography style={{ fontWeight: "bold" }} >{(`#${estimate?.id}` ?? "")}</Typography> |
            <Typography style={{ fontWeight: "500", color: "gray" }} >{(estimate?.status?.name ?? "")}</Typography> |
            <Typography style={{ fontWeight: "500", color: "gray" }} >{(`${(index + 1)} of ${total}`)}</Typography>
            <Tooltip title="Redirect To Estimate"><IconButton onClick={() => {
                redirectToEstimate();
            }}
                size="small"
                style={{ marginLeft: "auto" }}>
                <CallMadeIcon />
            </IconButton>
            </Tooltip>
        </Box>
        {franchise?.time_zone ? <></> : <p style={{ color: "red", marginLeft: "1em" }}>*Franchise time zone not provided. Time is based on UTC time</p>}
        <Grid container justifyContent={"space-evenly"} spacing={2}>
            <Grid item xs={2} >
                <SmallCard heading="Received" value={estimate?.createdOn ? moment(new Date(estimate?.createdOn))?.format('MM/DD/YYYY h:mm A') : "-"} />
            </Grid>
            <Grid item xs={1} >
                <SmallCard heading="Status" value={estimate?.status?.name ?? "-"} />
            </Grid>
            <Grid item xs={1} >
                <SmallCard heading="Job Id" value={estimate?.jobId ?? "-"} />
            </Grid>
            <Grid item xs={2} >
                <SmallCardForEstimateName heading="Name" value={estimate?.name ?? "-"} maxWidth={"100px"} />
            </Grid>
            <Grid item xs={2} >
                <SmallCard heading="Business Unit" value={estimate?.businessUnitName ?? "-"} />
            </Grid>
            <Grid item xs={2} >
                <SmallCard heading="SubTotal" value={estimate?.subtotal ? `$ ${estimate?.subtotal}` : "-"} />
            </Grid>
            <Grid item xs={2} >
                <SmallCard heading="Sold By" value={getCreateByEmployee()?.name ?? "-"} />
            </Grid>
        </Grid>
    </Paper>
}