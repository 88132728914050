import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import ReportIcon from "@mui/icons-material/Report";
import AgentLogsIcons from "./agentLogsIcons";

export default function RenderInput(props) {
    const { content } = props
    return (
        <Typography {...props}>
            {content}
        </Typography>
    );
}

export function renderAgentOption(props, agent) {
    return <>
        {agent?.eight_by_eight_agent_id != "" ?
            <Box >
                <Box {...props} key={agent?.id} alignItems="center" paddingRight="1em" >
                    <RenderInput key={agent?.id} content={`${agent?.first_name.trim()}${" "}${agent?.last_name.trim()}` ?? ""} />
                </Box>
            </Box>
            :
            <Box position="relative" display="flex" flexDirection="row">
                <Box {...props} key={agent?.id} alignItems="center" paddingRight="1em" >
                    <RenderInput key={agent?.id} content={`${agent?.first_name}${" "}${agent?.last_name}` ?? ""} />
                </Box>
                {agent?.eight_by_eight_agent_id == "" &&
                    <div style={{ marginLeft: "auto" }}>
                        <Tooltip title="Please provide 8*8 id to show logs">
                            <IconButton size="small" color="warning">
                                <ReportIcon style={{ height: "16px", width: "16px" }} />
                            </IconButton>
                        </Tooltip>
                    </div>
                }
            </Box>
        }
    </>
}

export function renderStateOption(props, state) {
    return <Box {...props} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" paddingRight="1em">
        <div style={{ marginRight: "10%" }}>
            <AgentLogsIcons status={state}></AgentLogsIcons>
        </div>
        <RenderInput content={`${state}` ?? ""} />
    </Box>
}
