export const USER_TYPES = {
    superAdmin: {
        level: 120,
        name: "Admin"
    },
    admin: {
        level: 80,
        name: "Admin"
    },
    supervisor: {
        level: 60,
        name: "Supervisor"
    },
    agent: {
        level: 40,
        name: "Agent"
    }
}

export const getMeActionTypes = {
    id: "Id",
    all: "All",
}

export const isRemovedStatus = {
    removed: 1,
    nonRemoved: 0,
}

