import { Autocomplete, Box, Button, Dialog, Typography } from "@mui/material";
import DialogHeader from "../../../../components/dialog/DialogHeader";
import DialogBody from "../../../../components/dialog/DialogBody";
import { useContext, useEffect, useMemo, useState } from "react";
import { CommonContext } from "../../../../contexts/CommonContextProvider";
import { handleAssignTasks } from "../../../../state-services/schedule/schedule";
import LoadingButton from "../../../../components/LoadingButton";
import { getMatchingBrandByBrandId, getMatchingUserByUserId, getUserFullName } from "../../../../utils/miscellaneous";
import { renderUserOption } from "../../../../components/servicetitan/RenderInput";
import { CustomTextField } from "../../../../components/RightDock/style/rightDock";
import TasksSummary from "./TasksSummary";
import ImageBox from "../../../../components/LogoBox/ImageBox";
import IBSBrandImage from "../../../../assets/img/IBS_lOGO.png"

export default function TaskAssignDialog({ isOpen, onClose, tasks }) {
    const { showNotification, allUsers, brands } = useContext(CommonContext)
    const [isTaskAssignLoading, setIsTaskAssignLoading] = useState(false)
    const [assignRequests, setAssignRequests] = useState([])
    useEffect(() => {
        if (!isOpen) setAssignRequests({})
    }, [isOpen])

    const allUsersIncludingUnassigned = useMemo(() => {
        const allUsersSorted = [...allUsers]?.sort?.((a, b) => a?.first_name?.localeCompare?.(b?.first_name))
        return [{ user_id: 0, first_name: "[ Unassigned ]", last_name: "" }, ...allUsersSorted]
    }, [allUsers])

    const brandTasksSummary = useMemo(() => {
        return getTaskBrandSummary(tasks)
    }, [tasks])

    return <Dialog fullWidth maxWidth="sm" disableScrollLock={true} open={isOpen} >
        <DialogHeader onClose={onClose} className={'dialog-plain-color'}>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center", gap: "0.5em", padding: "1em" }}>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "left", borderRadius: "0.5em", }}>
                    <Typography variant="h5" component={"h1"} fontWeight="bold" >Assign Tasks</Typography>
                </Box>
                <Box flexGrow={2}></Box>
                <TasksSummary tasks={tasks} />
            </Box>
        </DialogHeader>
        <DialogBody className={'dialog-color'}>
            <form onSubmit={(e) => {
                e?.preventDefault?.()
                handleAssignTasks(Object.values(assignRequests), setIsTaskAssignLoading, () => onClose(true), showNotification)
            }} >
                <Box sx={{ display: "flex", flexDirection: "column", gap: "1em" }} >
                    {Object.values(brandTasksSummary).map(({ brandId, tasks = [] }) => {
                        return <BrandTaskAssignCard brand={getMatchingBrandByBrandId(brandId, brands)} allUsersIncludingUnassigned={allUsersIncludingUnassigned} assignRequests={assignRequests} setAssignRequests={setAssignRequests} tasks={tasks} />
                    })}
                </Box>
                <Box sx={{ marginBlock: "1em", display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Button size={"small"} variant="contained" onClick={onClose} className={"btn-secondary"} style={{ marginRight: "10px" }} >Cancel</Button>
                    <LoadingButton type={"submit"} size={"small"} variant="contained" loading={isTaskAssignLoading} color="error" >Assign</LoadingButton>
                </Box>
            </form>
        </DialogBody>
    </Dialog >
}

function BrandTaskAssignCard({ brand, allUsersIncludingUnassigned, assignRequests, setAssignRequests, tasks }) {

    const assignableUsers = useMemo(() => {
        const allUsersSorted = [...allUsersIncludingUnassigned]?.sort?.((a, b) => a?.first_name?.localeCompare?.(b?.first_name))
        return allUsersSorted.filter((user) => {
            return !brand?.id || (user?.user_id === 0 || (user?.user_brands?.some?.((ub) => ub.brand_id === brand?.id) ?? false))
        })
    }, [allUsersIncludingUnassigned, brand])

    return <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5em" }}>
        <Box sx={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center", gap: "0.5em", padding: "1em" }}>
            <ImageBox src={brand?.brand_img ?? IBSBrandImage} alt="brandImg" size="medium" />
            <Box flexGrow={2} />
            <TasksSummary tasks={tasks} />
        </Box>
        <Autocomplete
            sx={{ minWidth: "200px", flexGrow: 1 }}
            size="small"
            options={assignableUsers}
            getOptionLabel={(user) => getUserFullName(user)}
            renderOption={renderUserOption}
            value={getMatchingUserByUserId(assignRequests[brand?.brand_id]?.assigned_to, assignableUsers) ?? null}
            onChange={(_, user) => {
                setAssignRequests((prev) => { return { ...prev, [brand?.brand_id]: { task_ids: tasks?.map?.((t) => t.id), assigned_to: user?.user_id } }; });
            }}
            renderInput={(params) => <CustomTextField {...params} label="Assigned To" />} />
    </Box >;
}

function getTaskBrandSummary(tasks) {
    let taskBrandSummary = {}
    tasks.forEach((task) => {
        if (taskBrandSummary[task.brand_id]) {
            taskBrandSummary[task.brand_id].tasks.push(task)
        } else {
            taskBrandSummary[task.brand_id] = { brandId: task.brand_id, tasks: [task] }
        }
    })
    return taskBrandSummary
}