import { Avatar } from '@mui/material'
import React from 'react'
const IN = "in"

export default function RealTimeTranscriptionBox({ image, text, type }) {
    const isIncoming = type === IN;
    return (
        text.length > 0 &&
        <div className={`transcript-item ${type === IN ? "chat-incoming" : "chat-outgoing"}`}>
            {!isIncoming && <Avatar style={{ height: "25px", width: "25px", marginRight: "10px" }} src={image} />}
            {text}
            {isIncoming && <Avatar style={{ height: "25px", width: "25px", marginLeft: "10px", float: 'right' }} src={image} />}
        </div>
    )
}