import { Box, Typography } from "@mui/material"
import ReportIcon from '@mui/icons-material/Report';

const NOT_IN_SERVICE_AREA_MESSAGE = "WARNING! Out of area ZIP code. Location shown is the next closest location."

export default function ServiceAreaMessageForFranchises(props) {
    const { serviceableZipCodeResponse } = props
    return <Box sx={{ display: "flex", flexDirection: "row", maxWidth: "600px" }}>
        <Box style={{ display: "flex", flexDirection: "row", alignItems: "center", borderRadius: "0.5em", gap: "1em" }} >
            {serviceableZipCodeResponse?.isServiceable === false && <ReportIcon color={"error"} />}
            <Typography data-test="job_create_page_out_area_warning_message" flexGrow={0} color={serviceableZipCodeResponse?.isServiceable === false && "red"}>{serviceableZipCodeResponse?.isServiceable === false && NOT_IN_SERVICE_AREA_MESSAGE}</Typography>
        </Box>
    </Box>
}

