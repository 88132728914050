import { useEffect, useState } from "react"
import { handleGetVonigoStatistics } from "../../../../state-services/reports/reports"
import { SmallCardForCrmData } from "../../../../components/servicetitan/SmallCard"
import jobImg from "../../../../assets/img/jobs.png";
import leadImg from "../../../../assets/img/leads.png";
import workorderImg from "../../../../assets/img/workorders.png";
import { Box } from "@mui/material";

export default function VonigoStatistics(props) {
    const { filtration, onStatisticsChangeVonigo } = props
    const [vonigoStatistics, setVonigoStatistics] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(true)

    useEffect(() => {
        handleGetVonigoStatistics(
            filtration.brandBrandId, 
            filtration.franchiseId, 
            filtration.startDate, 
            filtration.endDate,
            (data) => {
                setVonigoStatistics(data);
                onStatisticsChangeVonigo(data);
            }, 
            setIsLoading,
            setIsError
        );
    }, [filtration.brandBrandId, filtration.franchiseId, filtration.startDate, filtration.endDate])

    return <Box sx={{ display: "flex", width: "100%", flexDirection: "row", gap: "20px", justifyContent: "space-evenly" }}>
        <SmallCardForCrmData heading={"Leads"} image={leadImg} value={vonigoStatistics?.vonigo_leads?.toLocaleString() ?? "-"} isLoading={isLoading} isError={isError} />
        <SmallCardForCrmData heading={"Estimate Appointments"} image={workorderImg} value={vonigoStatistics?.vonigo_workorders?.toLocaleString() ?? "-"} isLoading={isLoading} isError={isError} />
        <SmallCardForCrmData heading={"Jobs"} image={jobImg} value={vonigoStatistics?.vonigo_jobs?.toLocaleString() ?? "-"} isLoading={isLoading} isError={isError} />
    </Box>
}