import { useEffect, useState } from "react"
import { getServiceTitanCustomerLeads } from "../../../../../services/serviceTitan"
import { handleGetBusinessUnits, retry, shouldRetry } from "../../../../../state-services-dublicate/serviceTitan"
import { Box, Paper, Typography } from "@mui/material"
import NoContentView from "../../../../../components/refreshView/NoContentView"
import RefreshErrorView from "../../../../../components/refreshView/RefreshErrorView"
import LoadingView from "../../../../../components/refreshView/LoadingView"
import SmallCard from "../../../../../components/servicetitan/SmallCard"
import { getById } from "../../../../../utils/miscellaneous"
import moment from "moment"
import { compareTwoRFCDateStringsAssendingOrder } from "../../../../../utils/compareFunctions"

export default function LeadsSection(props) {
    const { franchiseId, customerId } = props
    const [leads, setLeads] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [businessUnits, setBusinessUnits] = useState([])
    const [toggleGetLeads, setToggleGetLeads] = useState(false)

    useEffect(() => {
        const businessUnitsCancelFUnc = handleGetBusinessUnits(franchiseId, undefined, setBusinessUnits, undefined, () => { }, () => { })
        return () => {
            businessUnitsCancelFUnc?.()
        }
    }, [franchiseId])

    useEffect(() => {
        handleGetCustomerLeads(franchiseId, customerId, setLeads, setIsLoading, setIsError)
    }, [franchiseId, customerId, toggleGetLeads])

    return franchiseId && customerId ? <Box marginTop="0.5em" width="100%">
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
            <Typography marginLeft="0.5em" component="h5" fontWeight="bold" >Leads</Typography>
        </Box>
        {isError ? <RefreshErrorView onRefresh={() => setToggleGetLeads(!toggleGetLeads)} /> : <></>}
        {isLoading ? <LoadingView /> :
            leads?.length === 0 ? <NoContentView message={"No leads to show"} /> : leads.map((l, i) => <LeadCard lead={l} index={i} businessUnits={businessUnits} />)}
    </Box > : <></>
}

function LeadCard(props) {
    const { lead, businessUnits = [] } = props

    return <Paper key={lead?.id} style={{ display: "flex", flexDirection: "column", borderRadius: "0.5em", border: "1px solid gray", borderLeft: "4px solid #00a79d", marginTop: "0.5em" }}>
        <Box sx={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "start", gap: "0.5em", padding: "0.25em", justifyContent: "space-evenly" }}>
            <Box width="12em"><SmallCard heading="Next Follow-Up Date " value={lead?.followUpDate ? moment(new Date(lead?.createdOn))?.format('MM/DD/YYYY h:mm A') : "-"} /></Box>
            <Box width="6em"><SmallCard heading="Priority" value={lead?.priority ?? "-"} /></Box>
            <Box width="10em"><SmallCard heading="Business Unit" value={getById(lead.businessUnitId, businessUnits)?.name ?? "-"} /></Box>
            <Box flexGrow={2}><SmallCard heading="Status" value={lead?.status ?? "-"} /></Box>
        </Box>
    </Paper>
}


async function handleGetCustomerLeads(franchiseId, customerId, setLeads, setIsLoading, setIsError, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    try {
        setIsError(false)
        setIsLoading(true)
        const res = await getServiceTitanCustomerLeads(franchiseId, customerId)
        if (Array.isArray(res.data)) {
            res.data.sort((a, b) => -1 * compareTwoRFCDateStringsAssendingOrder(a.followUpDate, b.followUpDate, a.createdOn, b.createdOns))
            setLeads(res.data)
        }
    } catch (err) {
        if (shouldRetry(backoffConfig) && err?.status !== 400) {
            retry(() => handleGetCustomerLeads(franchiseId, customerId, setLeads, setIsLoading, setIsError, backoffConfig), backoffConfig)
        } else {
            setIsError(true)
        }
    } finally {
        setIsLoading(false)
    }
}