import { Autocomplete, Box, CircularProgress } from "@mui/material";
import CustomTextField from "../../../../components/CustomTextField";
import { renderBrandOption } from "../../../../components/servicetitan/RenderInput";
import { isFranchiseDisabled } from "../../../../utils/miscellaneous";
import ServiceAreaMessageForFranchises from "./vonigo/ServiceAreaMessageForFranchises";
import { renderFranchiseOptionWithEncryptionBadge } from "../../utils/franchise";
import { useEffect, useRef, useState } from "react";
import { getIsValidUSOrCanadianZipCode } from "../../../../utils/validators";
import { KM_PER_MILE } from "../../../../constants/common";
import { getIsServiceableZipCode } from "../../../../services/crm/crm";
import { ZIP_CODE_SEARCH_FRANCHISE_TYPES } from "../../../../constants/jobs";
import { sortFranchises } from "../../../../utils/franchise";
import useDebounce from "../../../../hooks/useDebounce";
import { getFranchiseByZipCodeV3, getOtherFranchisesV3 } from "../../../../services/franchise/v3/franchise";

export default function FranchiseSelector(props) {
    const { isDisabled, isFromHomePage, brands, isBrandsLoading, brand, setBrand, franchises, setFranchises, isFranchisesLoading, setIsFranchisesLoading, franchise, setFranchise, isCreateMultipleWorkOrders, zipCode, setZipCode, isZipCodeValid, setisZipCodeValid } = props

    const [serviceableZipCodeResponseForFranchise, setServiceableZipCodeResponseForFranchise] = useState()
    const franchiseLoadCount = useRef(0)
    const debouncedGetFranchisesBasedOnZipCode = useDebounce({ brandId: brand?.brand_id, zipCode: zipCode, setFranchises: setFranchises, setIsFranchisesLoading: setIsFranchisesLoading, setFranchise: setFranchise, franchiseLoadCount: franchiseLoadCount }, ({ brandId, zipCode, setFranchises, setIsFranchisesLoading }) => {
        handleGetFranchisesBasedOnZipCode(brandId, zipCode, isZipCodeValid, setFranchises, setIsFranchisesLoading, setFranchise, franchiseLoadCount)
    }, 1000)

    useEffect(() => {
        if (brand?.brand_id && (getIsValidUSOrCanadianZipCode(zipCode) || !zipCode) && !isDisabled) {
            debouncedGetFranchisesBasedOnZipCode({ brandId: brand?.brand_id, zipCode: zipCode, setFranchises: setFranchises, setIsFranchisesLoading: setIsFranchisesLoading, setFranchise: setFranchise, franchiseLoadCount: franchiseLoadCount })
        } else if (!getIsValidUSOrCanadianZipCode(zipCode) && zipCode) {
            debouncedGetFranchisesBasedOnZipCode({ brandId: brand?.brand_id, zipCode: "", setFranchises: setFranchises, setIsFranchisesLoading: setIsFranchisesLoading, setFranchise: setFranchise, franchiseLoadCount: franchiseLoadCount })
        } else {
            setFranchises([])
        }
    }, [brand, isDisabled, zipCode])

    useEffect(() => {
        if (franchise?.id && getIsValidUSOrCanadianZipCode(zipCode) && zipCode) {
            setServiceableZipCodeResponseForFranchise()
            getIsServiceableZipCode(franchise?.brand_id, franchise?.id, zipCode).then((response) => {
                setServiceableZipCodeResponseForFranchise(response)
            }).catch((err) => { })
        } else (
            setServiceableZipCodeResponseForFranchise()
        )
    }, [franchise?.id, franchise?.brand_id, zipCode])

    function setSearchValue(e) {
        setZipCode(e?.target?.value)
        setisZipCodeValid(getIsValidUSOrCanadianZipCode(e?.target?.value))
    }

    return <Box className="st-filter-area" style={{ display: "flex", flexDirection: "row", justifyContent: "left", alignItems: "start", gap: "1em", padding: "1em", flexWrap: "wrap", marginTop: "1em" }}>
        <Autocomplete
            sx={{ minWidth: "250px", maxWidth: "500px", flexGrow: 2 }}
            data-test="job_create_page_brand_selector"
            size="small"
            loading={isBrandsLoading}
            noOptionsText={"No brands"}
            disabled={brands === [] || (isCreateMultipleWorkOrders) || isDisabled || isFromHomePage}
            disablePortal
            disableClearable
            options={brands}
            getOptionLabel={(brand) => brand.brand_name}
            renderOption={renderBrandOption}
            value={(brands?.length > 0 && brand) ? brand : null}
            onChange={(_, brand) => {
                setBrand(brand);
            }}
            renderInput={(params) => <CustomTextField
                {...params}
                inputProps={{
                    ...params.inputProps,
                    'data-test': 'job_create_page_brand'
                }}
                label="Brand"
            />}
        />
        <CustomTextField style={{ width: "8.3em" }} inputProps={{ 'data-test': 'job_create_page_zipcode_input' }} disabled={isCreateMultipleWorkOrders || isDisabled} label="Zip/Postal Code" type="search" size="small" value={zipCode ?? ""} onChange={setSearchValue} error={!getIsValidUSOrCanadianZipCode(zipCode) && zipCode} helperText={getIsValidUSOrCanadianZipCode(zipCode) || !zipCode ? "" : "Invalid"} />
        {isFranchisesLoading ? <CircularProgress size={30} color="grey" /> : <></>}
        <ServiceAreaMessageForFranchises serviceableZipCodeResponse={serviceableZipCodeResponseForFranchise}></ServiceAreaMessageForFranchises>
        <Autocomplete
            sx={{ minWidth: "250px", maxWidth: "450px", flexGrow: 2, marginLeft: "auto" }}
            size="small"
            data-test="job_create_page_franchise_selector"
            disabled={franchises === [] || (isCreateMultipleWorkOrders) || isDisabled}
            disablePortal
            loading={isFranchisesLoading}
            noOptionsText={(getIsValidUSOrCanadianZipCode(zipCode) || !zipCode) ? "No franchises" : "Please enter valid ZIP/Postal code"}
            disableClearable
            options={isDisabled ? [franchise] : franchises}
            groupBy={(franchise) => franchise.zipCodeSearchFranchiseType}
            getOptionLabel={(franchise) => franchise.franchise_name}
            getOptionDisabled={(franchise) => {
                return isFranchiseDisabled(franchise)
            }}
            renderOption={renderFranchiseOptionWithEncryptionBadge}
            value={getFranchiseValue(franchise, franchises, isDisabled)}
            onChange={(_, franchise) => {
                setFranchise(franchise)
            }}
            renderInput={(params) => <CustomTextField
                {...params}
                inputProps={{
                    ...params.inputProps,
                    'data-test': 'job_create_page_franchise'
                }}
                label="Franchise"
            />}
        />
    </Box>
}

async function handleGetFranchisesBasedOnZipCode(brandId, zipCode = "", isZipCodeValid, setFranchises, setIsFranchisesLoading, setFranchise, franchiseLoadCount) {
    try {
        setIsFranchisesLoading(true)
        setFranchises([])
        const defaultFranchises = await handleGetDefaultFranchises(brandId, zipCode)
        const excludingFranchiseIds = defaultFranchises.map((f) => f.id)
        sortFranchises(defaultFranchises)
        const otherFranchises = await handleGetOtherFranchises(brandId, zipCode, excludingFranchiseIds)
        sortFranchises(otherFranchises)
        const allFranchises = [...defaultFranchises, ...otherFranchises]
        if (franchiseLoadCount.current > 0) setFranchise(chooseFranchise(defaultFranchises, isZipCodeValid))
        setFranchises(allFranchises)
        franchiseLoadCount.current = franchiseLoadCount.current + 1
    } catch (err) {
    } finally {
        setIsFranchisesLoading(false)
    }
}

function chooseFranchise(defaultFranchises = [], isZipCodeValid) {
    if (defaultFranchises.length > 0 && defaultFranchises[0].service_brand === "ServiceTitan") {
        if (defaultFranchises[0]?.service_titan_client_id !== "" && isZipCodeValid) {
            return defaultFranchises[0]
        } else {
            return null
        }
    }
    if ((defaultFranchises.length > 0) && defaultFranchises[0].service_brand === "Vonigo" && isZipCodeValid) {
        return defaultFranchises[0]
    }
}

async function handleGetDefaultFranchises(brandId, zipCode) {
    try {
        const defaultFranchisesRes = await getFranchiseByZipCodeV3(brandId, zipCode)
        const defaultFranchises = Array.isArray(defaultFranchisesRes?.franchises) ? defaultFranchisesRes?.franchises : []
        defaultFranchises.forEach((f) => f.zipCodeSearchFranchiseType = ZIP_CODE_SEARCH_FRANCHISE_TYPES.default)
        return defaultFranchises
    } catch (err) {
        if (err.responseText?.code === 1003) {
            return []
        }
        throw err
    }
}

async function handleGetOtherFranchises(brandId, zipCode, excludingFranchiseIds) {
    try {
        const otherFranchisesRes = await getOtherFranchisesV3(brandId, excludingFranchiseIds, zipCode, Math.round(90 * KM_PER_MILE))
        const otherFranchises = Array.isArray(otherFranchisesRes?.franchises) ? otherFranchisesRes?.franchises : []
        otherFranchises.forEach((f) => f.zipCodeSearchFranchiseType = ZIP_CODE_SEARCH_FRANCHISE_TYPES.other)
        return otherFranchises
    } catch (err) {
        throw err
    }
}

function getFranchiseValue(franchise, franchises = [], isDisabled = false) {
    return (franchises?.length > 0 && franchise && franchise?.is_enabled) && franchises.some((f) => f.id === franchise.id) || isDisabled ? franchise : null
}

