import { Box, IconButton, Tooltip, Typography } from "@mui/material"
import { useContext } from "react"
import { CommonContext } from "../../contexts/CommonContextProvider"
import { getPhoneNumberWithInputMask } from "../../utils/inputMasks"
import CallIcon from '@mui/icons-material/Call';

export default function PhoneCard(props) {

    const { heading, phone, ext = "", franchiseId, brandId, contentFontSize } = props

    const { popPhoneDialer } = useContext(CommonContext)

    return <Box sx={{ fontSize: "1rem", padding: "0.5em" }}>
        {heading ? <Typography style={{ color: "gray", fontSize: "0.8rem" }}>{heading}</Typography> : <></>}
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Tooltip title="Call" >
                <IconButton size="small" sx={{ color: "primary.main", margin: "0", padding: "0", paddingTop: "0.2em", paddingRight: "0.2em" }} aria-label="Call" component="span" onClick={() => {
                    popPhoneDialer(getPhoneNumber(phone), franchiseId, brandId)
                }}>
                    <CallIcon />
                </IconButton>
            </Tooltip>
            <Typography style={{ margin: "0", fontSize: contentFontSize }}>{getPhoneNumberWithInputMask(`${phone}${ext}`)}</Typography>
        </Box>
    </Box>
}

function getPhoneNumber(phone = '') {
    if (phone?.length > 10) {
        phone = phone.slice(-10);
    }
    return phone
}
