import { FetchClient } from "../../utils/fetchClient";

export async function getKeywords() {
    return FetchClient("/ccai/faq/keywords").GET({})
}

export async function getFaqKeywords(faqId = 0) {
    return FetchClient(`/ccai/faq/keywords/faq?faq_id=${faqId}`).GET({})
}

export async function deleteKeywordByKeyword(keyword = "") {
    return FetchClient(`/ccai/faq/keywords?keyword=${keyword}`).DELETE({})
}

export async function createKeywords(keywords = []) {
    return FetchClient(`/ccai/faq/keywords`).POST({ payload: JSON.stringify(keywords) })
}