import { Box, Typography } from '@mui/material'
import React from 'react'

export default function NoDataAvailableMessage({ cssClass }) {
    return (
        <Box className={cssClass} >
            <Typography variant={"subtitle2"} >No Data Available For The Selected Range</Typography>
        </Box>
    )
}
