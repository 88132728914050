import { Autocomplete, Typography } from "@mui/material"
import CustomTextField from "../../../../components/CustomTextField"
import RenderInput from "../../../../components/servicetitan/RenderInput"

export default function CreateJobFranchiseSelector(props) {
    const { brands, brand, setBrand, isBrandsLoading, franchises, setFranchises, franchise, setFranchise, isFranchisesLoading, isDisabled } = props
    return <><div className="form-control" style={{ marginBottom: "1em" }}>
        <label><Typography variant="subtitle1" >Brand :</Typography></label>
        <Autocomplete
            sx={{ width: "65%", }}
            size="small"
            disabled={isDisabled}
            loading={isBrandsLoading}
            disablePortal
            disableClearable
            options={brands ?? []}
            getOptionLabel={(brand) => brand.brand_name}
            renderOption={(props, brand) => <RenderInput {...props} key={brand?.id}  content={brand?.brand_name} />}
            value={brand ?? null}
            onChange={(_, brand) => {
                setBrand(brand)
                setFranchises([])
                setFranchise()
            }}
            renderInput={(params) => <CustomTextField required={true} {...params} label="Brand" />}
        />
    </div>
        <div className="form-control" style={{ marginBottom: "1em" }}>
            <label><Typography variant="subtitle1" >Franchise :</Typography></label>
            <Autocomplete
                sx={{ width: "65%", }}
                size="small"
                disablePortal
                disableClearable
                loading={isFranchisesLoading}
                noOptionsText={brand ? "No franchises found" : "Please select a brand"}
                disabled={isDisabled}
                value={franchise ?? null}
                options={franchises ?? []}
                getOptionLabel={(f) => `${f.franchise_name}`}
                renderOption={(props, f) => <RenderInput {...props} key={f?.id} content={`${f?.franchise_name}`} />}
                onChange={(_, f) => {
                    setFranchise(f)
                }}
                renderInput={(params) => <CustomTextField  required={true} {...params} value={franchise?.franchise_name} label="Franchise" />}
            />
        </div>
    </>
}