
import { COUNTRIES } from "../constants/common";
import { CONTACTS_TYPES } from "../constants/serviceTitan";

export const isValidCanadianZipCode = (canadianZipCode = "") => {
    const regXFor6Charactors = canadian6DigitsZipCodeRegex
    const regXFor3Charactors = canadian3DigitsZipCodeRegex
    return (regXFor6Charactors.test(canadianZipCode) || regXFor3Charactors.test(canadianZipCode));
}

export const canadian6DigitsZipCodeRegex = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i
export const canadian3DigitsZipCodeRegex = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z]$/i

export const isValidUSZipCode = (uSZipCode) => {
    const regX = usZipCodeRegex
    return regX.test(uSZipCode);
}

export const usZipCodeRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/

export const isValidPhoneNumber = (phoneNumber = "") => {
    return (numbersOnlyRegex.test(phoneNumber) && phoneNumber?.length >= 10 && phoneNumber?.length <= 15)
}

export const numbersOnlyRegex = /^[1-9]\d*$/;

export function isValidFaxNumber(fax) {
    return (numbersOnlyRegex.test(fax) && fax?.length === 10)
}

export const isValidEmail = (email) => {
    const regX = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/
    return regX.test(email)
}

export function isValidZipCode(zipCode = "", country = "") {
    if (country === COUNTRIES[0]) {
        return isValidUSZipCode(zipCode)
    } else if (country === COUNTRIES[1]) {
        return isValidCanadianZipCode(zipCode)
    } else {
        return getIsValidUSOrCanadianZipCode(zipCode)
    }
}

export function getIsValidUSOrCanadianZipCode(zipCode = "") {
    return isValidUSZipCode(zipCode) || isValidCanadianZipCode(zipCode) || zipCode === ""
}

export function isContactValid(contact = {}) {
    if (contact?.type) {
        switch (contact.type) {
            case CONTACTS_TYPES[0]: //Phone number cases
            case CONTACTS_TYPES[3]:
                return isValidPhoneNumber(contact?.value)
            case CONTACTS_TYPES[2]:
                return isValidFaxNumber(contact?.value)
            case CONTACTS_TYPES[1]:
                return isValidEmail(contact?.value)
            default:
                return true;
        }
    }
    return false;
}

export function isContactsValid(contacts = [], existingContacts = [], isLeadSection = false) {
    let isDublicatesExists = false
    const allContacts = [...contacts, ...existingContacts]
    if (allContacts.length === 0 && !isLeadSection) return false
    allContacts.forEach((contact, index) => {
        const dublicate = allContacts.some((c, i) => c.type === contact.type && c.value === contact.value && i !== index && !c.id)
        if (dublicate) isDublicatesExists = true
    })
    return !contacts?.some((contact) => !isContactValid(contact)) && !isDublicatesExists;
}

export const isValidHttpUrl = (string) => {
    let url;
    try {
        url = new URL(string);
    } catch {
        return false;
    }
    return (url.protocol === "http:" || url.protocol === "https:") && url.hostname !== "";
}

export const imposibleToMatchRegex = "a^"

export function getIsOnlyNumbers(str = "") {
    return /^\d+$/.test(str);
}
