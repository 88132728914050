import { Box, Button, CircularProgress, Container, Dialog, IconButton, Tooltip, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { handleGetConfigMessage } from "../../../../components/configMessage/configMessage";
import { CONFIG_MESSAGES } from "../../../../constants/jobs";
import { useEffect, useState } from "react";

export default function ZipCodeComparisonModal(props) {
    const { isOpen, onCloseHandler } = props

    const [sectionMessage, setSectionMessage] = useState("")
    const [isMessageLoading, setisMessageLoading] = useState(false)

    useEffect(() => {
        handleGetConfigMessage(CONFIG_MESSAGES.SERVICETITAN.ZIP_COMPARISON.id, setSectionMessage, setisMessageLoading, () => { })
    }, [])

    return <Dialog maxWidth="xs" open={isOpen} onClose={() => { }}>
        <Tooltip title={"Close"}>
            <IconButton onClick={onCloseHandler} style={{ position: "absolute", right: "5px", top: "5px" }} size={"small"} aria-label="delete">
                < CloseIcon style={{ height: "15px", width: "15px" }} />
            </IconButton>
        </Tooltip>
        {isMessageLoading ?
            <Container sx={{ display: "flex", flexDirection: "column", paddingTop: "2em", paddingBottom: "2em", alignContent: "center", gap: "1em" }}>
                <Box sx={{ marginLeft: "auto", marginRight: "auto" }}>
                    <CircularProgress sx={{ width: "20px", height: "20px" }} color="inherit" />
                </Box>
            </Container>
            :
            <Container sx={{ display: "flex", flexDirection: "column", paddingTop: "2em", paddingBottom: "2em", alignContent: "center", gap: "1em" }}>
                <Typography style={{ paddingTop: "0.5em" }} variant="p">{sectionMessage?.message}</Typography>
                <Container sx={{ display: "flex", flexDirection: "row", justifyContent: "right" }} >
                    <Button size="small" onClick={onCloseHandler} className="btn-secondary btn-no">Close</Button>
                </Container>
            </Container>
        }
    </Dialog >;
}