import '../../styles/confirmationModal.css';
import { Dialog, Button } from "@mui/material";

function ConfirmationModal({ onClose, open, handleDeleteFAQ }) {
    const handleClose = () => {
        onClose();
    }

    return (
        <Dialog onClose={handleClose} open={open}>
            <div className="dialog">
                <h3 className="dialog-title"> Are you sure you want to delete this FAQ ? </h3>
                <div className="action-container">
                    <Button size="small" variant="contained" onClick={handleClose} className="btn-secondary btn-no">
                        No
                    </Button>
                    <Button size="small" variant="contained" onClick={handleDeleteFAQ} className="btn-primary">
                        yes
                    </Button>
                </div>
            </div>
        </Dialog>
    );
}

export default ConfirmationModal;