import { FetchClient } from "../../utils/fetchClient";

export function getVonigoFranchisesByBrandId(brandId = "") {
    return FetchClient(`/data/vonigo/get-vonigo-franchises?brandId=${brandId}`).GET({})
}

export async function getJobs(baseUrl = "", franchiseId = "", createdStart = "", createdEnd = "", isFull = "true", page = "1", pageSize = "50") {
    return await FetchClient(`/jobs/vonigo/jobs?baseUrl=${baseUrl}&franchiseId=${franchiseId}&createdStart=${createdStart}&createdEnd=${createdEnd}&isFull=${isFull}&page=${page}&pageSize=${pageSize}`).GET({})
}

export async function getCustomers(baseUrl = "", franchiseId = "", searchPar = "", page = 1, pageSize = 50) {
    return await FetchClient(`/jobs/vonigo/clients?baseUrl=${baseUrl}&franchiseId=${franchiseId}&searchPar=${searchPar}&page=${page}&pageSize=${pageSize}`).GET({})
}

export async function getIndustries(baseUrl = "", franchiseId = "", page = 1, pageSize = 50) {
    return await FetchClient(`/jobs/vonigo/industries?baseUrl=${baseUrl}&franchiseId=${franchiseId}&page=${page}&pageSize=${pageSize}`).GET({})
}

export async function getCancelReasons(baseUrl = "", franchiseId = "", page = 1, pageSize = 50) {
    return await FetchClient(`/jobs/vonigo/cancelReasons?baseUrl=${baseUrl}&franchiseId=${franchiseId}&page=${page}&pageSize=${pageSize}`).GET({})
}

export async function getCampaigns(baseUrl = "", franchiseId = "", page = 1, pageSize = 50) {
    return await FetchClient(`/jobs/vonigo/campaigns?baseUrl=${baseUrl}&franchiseId=${franchiseId}&page=${page}&pageSize=${pageSize}`).GET({})
}

export async function getCountries(baseUrl = "", franchiseId = "", page = 1, pageSize = 50) {
    return await FetchClient(`/jobs/vonigo/countries?baseUrl=${baseUrl}&franchiseId=${franchiseId}&page=${page}&pageSize=${pageSize}`).GET({})
}

export async function getStates(baseUrl = "", franchiseId = "", page = 1, pageSize = 50) {
    return await FetchClient(`/jobs/vonigo/states?baseUrl=${baseUrl}&franchiseId=${franchiseId}&page=${page}&pageSize=${pageSize}`).GET({})
}

export async function getDurationForZip(baseUrl = "", franchiseId = "", zip = "") {
    return await FetchClient(`/jobs/vonigo/duration?baseUrl=${baseUrl}&franchiseId=${franchiseId}&zip=${zip}`).GET({})
}

export async function getJobLables(baseUrl = "", franchiseId = "") {
    return await FetchClient(`/jobs/vonigo/job/labels?baseUrl=${baseUrl}&franchiseId=${franchiseId}`).GET({})
}

export async function createCustomer(customerCreateRequest = {}) {
    return await FetchClient(`/jobs/vonigo/client`).POST({ payload: JSON.stringify(customerCreateRequest) })
}

export async function getLocations(baseUrl = "", franchiseId = "", clientId = "", pageNo = "1", pageSize = "50") {
    return await FetchClient(`/jobs/vonigo/locations?baseUrl=${baseUrl}&franchiseId=${franchiseId}&clientId=${clientId}&pageNo=${pageNo}&pageSize=${pageSize}`).GET({})
}

export async function getContacts(baseUrl = "", franchiseId = "", clientId = "", pageNo = "1", pageSize = "50") {
    return await FetchClient(`/jobs/vonigo/contacts?baseUrl=${baseUrl}&franchiseId=${franchiseId}&clientId=${clientId}&pageNo=${pageNo}&pageSize=${pageSize}`).GET({})
}

export async function getAvailabilities(baseUrl = "", franchiseId = "", serviceTypeId = "", dateStart = "", dateEnd = "", duration = "", zip = "", pageNo = "1", pageSize = "50") {
    return await FetchClient(`/jobs/vonigo/availability?baseUrl=${baseUrl}&franchiseId=${franchiseId}&serviceTypeId=${serviceTypeId}&dateStart=${dateStart}&dateEnd=${dateEnd}&duration=${duration}&zip=${zip}&pageNo=${pageNo}&pageSize=${pageSize}`).GET({})
}

export async function getSingleWorkOrderDetails(baseUrl = "", franchiseId = "", workOrderId = "") {
    return await FetchClient(`/jobs/vonigo/workOrder?baseUrl=${baseUrl}&franchiseId=${franchiseId}&workOrderId=${workOrderId}`).GET({})
}

export async function getClientJobs(baseUrl = "", franchiseId = "", clientId = "", pageNo = "1", pageSize = "50", isFull = "true") {
    return await FetchClient(`/jobs/vonigo/client/jobs?baseUrl=${baseUrl}&franchiseId=${franchiseId}&clientId=${clientId}&pageNo=${pageNo}&pageSize=${pageSize}&isFull=${isFull}`).GET({})
}

export async function getClientWorkOrders(baseUrl = "", franchiseId = "", clientId = "", pageNo = "1", pageSize = "50", isFull = "true") {
    return await FetchClient(`/jobs/vonigo/client/workOrders?baseUrl=${baseUrl}&franchiseId=${franchiseId}&clientId=${clientId}&pageNo=${pageNo}&pageSize=${pageSize}&isFull=${isFull}`).GET({})
}

export async function getSingleCustomerDetails(baseUrl = "", franchiseId = "", clientId = "") {
    return await FetchClient(`/jobs/vonigo/client?baseUrl=${baseUrl}&franchiseId=${franchiseId}&clientId=${clientId}`).GET({})
}

export async function updateCustomer(customerUpdateRequest = {}) {
    return await FetchClient(`/jobs/vonigo/client`).PUT({ payload: JSON.stringify(customerUpdateRequest) })
}

export async function getSingleContactDetails(baseUrl = "", franchiseId = "", contactId = "") {
    return await FetchClient(`/jobs/vonigo/contact?baseUrl=${baseUrl}&franchiseId=${franchiseId}&contactId=${contactId}`).GET({})
}

export async function createContact(contactCreateRequest = {}) {
    return await FetchClient(`/jobs/vonigo/contact`).POST({ payload: JSON.stringify(contactCreateRequest) })
}

export async function updateContact(contactUpdateRequest = {}) {
    return await FetchClient(`/jobs/vonigo/contact`).PUT({ payload: JSON.stringify(contactUpdateRequest) })
}

export async function getSingleLocationDetails(baseUrl = "", franchiseId = "", locationId = "") {
    return await FetchClient(`/jobs/vonigo/location?baseUrl=${baseUrl}&franchiseId=${franchiseId}&locationId=${locationId}`).GET({})
}

export async function getMessages() {
    return await FetchClient(`/jobs/configMessages`).GET({})
}

export async function createLocation(locationCreateRequest = {}) {
    return await FetchClient(`/jobs/vonigo/location`).POST({ payload: JSON.stringify(locationCreateRequest) })
}

export async function updateLocation(locationUpdateRequest = {}) {
    return await FetchClient(`/jobs/vonigo/location`).PUT({ payload: JSON.stringify(locationUpdateRequest) })
}

export async function createJob(jobCreateRequest = {}) {
    return await FetchClient(`/jobs/vonigo/job`).POST({ payload: JSON.stringify(jobCreateRequest) })
}

export async function updateJob(jobUpdateRequest = {}) {
    return await FetchClient(`/jobs/vonigo/job`).PUT({ payload: JSON.stringify(jobUpdateRequest) })
}

export async function cancelJob(jobCancelRequest = {}) {
    return await FetchClient(`/jobs/vonigo/deleteJob`).POST({ payload: JSON.stringify(jobCancelRequest) })
}

export async function getSingleJobDetails(baseUrl = "", franchiseId = "", jobId = "") {
    return await FetchClient(`/jobs/vonigo/job?baseUrl=${baseUrl}&franchiseId=${franchiseId}&jobId=${jobId}`).GET({})
}

export async function lockTime(lockTimeRequest = {}) {
    return await FetchClient(`/jobs/vonigo/lockTime`).POST({ payload: JSON.stringify(lockTimeRequest) })
}

export async function removeLock(removeLockRequest = {}) {
    return await FetchClient(`/jobs/vonigo/lockTime`).PUT({ payload: JSON.stringify(removeLockRequest) })
}

export async function getWorkOrders(baseUrl = "", franchiseId = "", jobId = "", pageNo = "1", pageSize = "50", sortDirection = "1", sortMode = "1", isFull = "true") {
    return await FetchClient(`/jobs/vonigo/workOrders?baseUrl=${baseUrl}&franchiseId=${franchiseId}&jobId=${jobId}&pageNo=${pageNo}&pageSize=${pageSize}&sortDirection=${sortDirection}&sortMode=${sortMode}&isFull=${isFull}`).GET({})
}

export async function createWorkOrder(workOrderCreateRequest = {}) {
    return await FetchClient(`/jobs/vonigo/workOrder`).POST({ payload: JSON.stringify(workOrderCreateRequest) })
}

export async function rescheduleWorkOrder(workOrderUpdateRequest = {}) {
    return await FetchClient(`/jobs/vonigo/workOrder`).PUT({ payload: JSON.stringify(workOrderUpdateRequest) })
}

export async function editClientNotes(noteEditRequest = {}) {
    return await FetchClient(`/jobs/vonigo/client`).PUT({ payload: JSON.stringify(noteEditRequest) })
}

export async function getRoutes(baseUrl = "", franchiseId = "") {
    return await FetchClient(`/jobs/vonigo/routes?baseUrl=${baseUrl}&franchiseId=${franchiseId}`).GET({})
}

export async function getVonigoJobDrafts(brandId, franchiseId, updatedOnOrAfter = "", updatededOnOrBefore = "", page = 1, pageSize = 50) {
    return await FetchClient(`/jobs/vonigo/draft-jobs?brandId=${brandId}&franchiseId=${franchiseId}&updatedOnOrAfter=${updatedOnOrAfter}&updatededOnOrBefore=${updatededOnOrBefore}&page=${page}&pageSize=${pageSize}`).GET({})
}

export async function createVonigoJobDraft(createDraftRequest = {}) {
    return await FetchClient("/jobs/vonigo/draft-job").POST({ payload: JSON.stringify(createDraftRequest) })
}

export async function updateVonigoJobDraft(updateDraftRequest = {}) {
    return await FetchClient("/jobs/vonigo/draft-job").PUT({ payload: JSON.stringify(updateDraftRequest) })
}

export async function deleteVonigoJobDraft(id) {
    return await FetchClient(`/jobs/vonigo/draft-job?id=${id}`).DELETE({})
}

export async function getTimeZone(latitude = "", longitude = "", timeStamp = "") {
    return await FetchClient(`/jobs/vonigo/timeZone?latitude=${latitude}&longitude=${longitude}&timeStamp=${timeStamp}`).GET({})
}