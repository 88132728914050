export const LOG_STATUS = {
    Available: "Available",
    Busy: "Busy",
    Onbreak: "Onbreak",
    Workoffline: "Workoffline",
    Wrapup: "Wrapup",
    Email: "Email",
    TechIssue: "Tech/System Issue",
    LunchBreak: "Lunch Break",
    CoachingMeeting: "Coaching/Meeting",
    CSRProject: "CSR Project /Assignment",
    CallBacks: "Call Backs (with approval)",
    Management: "Management",
    Login: "Login",
    Logout: "Logout",
    WorkingLead: "Working Lead/Inbox",
    ScheduledBreak: "Scheduled Break ",
    Training: "Training",
    UnscheduledBreak: "Unscheduled Break (RR, Water, ETC)",
    NotStarted: "Not Started",
}