import { Box, FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material"

export default function CustomerTypesRadioButton(props) {
    const { isDisabled, options, value, onChange = () => { }, width = "50%" } = props
    return <Box sx={{ display: 'flex', flexDirection: "row", width: width, }}>
        <FormControl disabled={isDisabled}>
            <RadioGroup row value={value} name="type-radio-buttons-group"
                onChange={onChange}>
                {options.map((type) => <FormControlLabel key={type} value={type} control={<Radio inputProps={{ 'data-test': "custom_type_" + type }} sx={{ '&, &.Mui-checked': { color: '#00a79d', }, "&.Mui-disabled": { color: "grayText" } }} />} label={type} />)}
            </RadioGroup>
        </FormControl>
    </Box>
}