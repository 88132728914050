import { Box, IconButton, Slide, Snackbar, Tooltip, Typography } from "@mui/material"
import { useContext, useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom"
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { handleAcceptAlert, handleSnoozeAlert, handleViewTaskPopUp } from "../../../../state-services/schedule/schedule";
import { CommonContext } from "../../../../contexts/CommonContextProvider";
import { getTenantTimeZoneTimeString } from "../../../../utils/time/time";

const COMMUNICATION_NEW_TASK_TYPE = "Communication Message New Task"
const ALERT_AUTO_HIDE_DURATION = 30000

export default function TaskAlertPopUp(props) {

    const { socketMessage, setSocketMessage } = useContext(CommonContext)
    const [taskAlerts, setTaskAlerts] = useState([])

    useEffect(() => {
        if (socketMessage?.notification_type === COMMUNICATION_NEW_TASK_TYPE) {
            let newTaskAlert = JSON.parse(socketMessage?.message)
            newTaskAlert = { ...newTaskAlert, isOpen: true }
            setTaskAlerts((taskAlerts) => [newTaskAlert, ...taskAlerts])
        }
    }, [socketMessage, setSocketMessage])

    const handleClose = (_, reason, taskId) => {
        if (reason === "clickaway") {
            return
        }
        let newTaskAlerts = [...taskAlerts]
        newTaskAlerts = newTaskAlerts?.filter?.(taskAlert => {
            return taskAlert?.task?.id !== taskId
        })
        setTaskAlerts([...newTaskAlerts])
    }

    return taskAlerts?.reverse?.()?.map?.((taskAlert, index) => taskAlert.isOpen ? <Snackbar
        key={taskAlert.task.id}
        open={taskAlert.isOpen}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={ALERT_AUTO_HIDE_DURATION}
        onClose={(e, reason) => handleClose(e, reason, taskAlert.task.id)}
        TransitionComponent={(props) => <Slide {...props} timeout={100 * (taskAlerts?.length - index)} direction="left" />}
    >
        <div key={taskAlert.task.id} style={{ marginTop: `${((taskAlerts?.length - index) * 30)}px`, scale: 1 - (0.1 * index) }} >
            <TaskAlertPopUpCard key={taskAlert.task.id} taskAlert={taskAlert} onClose={(e, reason) => handleClose(e, reason, taskAlert.task.id)} />
        </div>
    </Snackbar > : <></>)
}

function TaskAlertPopUpCard(props) {
    const { taskAlert, onClose } = props
    const { showNotification, generalTenantConfiguration, timezones } = useContext(CommonContext)
    const history = useHistory()
    const isDisabled = useMemo(() => taskAlert?.task?.is_completed === 1, [taskAlert?.task?.is_completed])

    function onAccept() {
        handleAcceptAlert(taskAlert?.task?.id, taskAlert?.task_alert?.id, () => {
            onClose()
            handleViewTaskPopUp(history, taskAlert?.task?.popup_url, showNotification)
        }, showNotification)
    }

    function onSnooze() {
        const now = new Date();
        now.setMinutes(now.getMinutes() + 15);
        const snoozedToTimeUTC = now.toISOString();
        handleSnoozeAlert(taskAlert?.task_alert?.id, snoozedToTimeUTC, onClose, showNotification)
    }

    return <Box className="dialog-color" sx={{ margin: "0.5em", padding: "1em", borderRadius: "1em", display: "flex", flexDirection: "row", gap: "0.5em" }} >
        <Box sx={{ display: "flex", flexDirection: "column" }} >
            <Box sx={{ display: "flex", flexDirection: "row", gap: "1em" }}>
                <Typography variant="subtitle1" >{taskAlert?.task?.task_type}</Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", gap: "1em" }}>
                <Typography >{getTenantTimeZoneTimeString(taskAlert?.task?.start_time_utc, generalTenantConfiguration?.time_zone, timezones)}</Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", gap: "1em" }}>
                <Typography >{`${taskAlert?.task?.is_completed ? "Completed" : "Pending"}`}</Typography>
            </Box>
        </Box>
        <Box sx={{ flexGrow: 2 }} />
        <Box>
            <Tooltip title="Snooze for 15 min">
                <IconButton sx={{ padding: 0, margin: 0, color: "#FFBF00" }} disabled={isDisabled} onClick={onSnooze} >
                    <WatchLaterIcon fontSize="large" />
                </IconButton>
            </Tooltip>
        </Box>
        <Box>
            <Tooltip title="Accept">
                <IconButton sx={{ padding: 0, margin: 0, color: "green" }} disabled={isDisabled} onClick={onAccept} >
                    <CheckCircleIcon fontSize="large" />
                </IconButton>
            </Tooltip >
        </Box>
    </Box>
}