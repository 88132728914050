import lowPriority from "../assets/img/priority_images/lowPriority.png";
import normalPriority from "../assets/img/priority_images/normalPriority.png";
import highPriority from "../assets/img/priority_images/urgentPriority.png"
import urgentPriority from "../assets/img/priority_images/urgentPriority.png";

export function getMatchingPriorityImage(priority) {
    switch (priority) {
        case "Low": return lowPriority;
        case "Normal": return normalPriority;
        case "High": return highPriority;
        case "Urgent": return urgentPriority;
        default: return normalPriority;
    }
}