
export const CONTACTS_TYPES = ["Phone", "Email", "Fax", "MobilePhone"]
export const MIDDLEWARE_FOLLOW_UP_STAGES = ["First Call", "Second Call", "Third Call", "Done"]
export const JOB_STATUSES = ["Scheduled", "InProgress", "Hold", "Canceled"]
export const SHIFT_TYPES = ["Normal", "OnCall", "TimeOff"]
export const STATUSES = ["Open", "Converted", "Dismissed"]
export const FILTERATION_PRIORITIES = ["All Priorities", "Urgent", "High", "Normal", "Low"]
export const PRIORITIES = ["Urgent", "High", "Normal", "Low"]
export const SORT_BY = ["createdOn", "followUpDate"]
export const SORT_ORDER = [1, -1] //1 for assending order, -1 for dissending order
export const ALL_BRAND = { id: "allBrands", brand_id: "allBrands", brand_name: "All Brands" }
export const ALL_FRANCHISE = { id: "allFranchises", franchise_name: "All Franchises" };
export const ALL_USER = { user_id: "allUsers", first_name: "All", last_name: "Users" }
export const ALL_AGENT = { user_id: "allAgents", first_name: "All", last_name: "Agents" }
// export const ADMIN_USER_LEVEL = 80
export const SUPERVISOR_USER_LEVEL = 60
export const INITIAL_LEAD_STATISTICS = { totalLeadCount: 0, urgentLeadCount: 0, highLeadCount: 0, NormalLeadCount: 0, LowLeadCount: 0 }
export const SERVICETITAN_PAGE_SIZE = 50
