import { KM_PER_MILE, NOTIFICATION_TYPES } from "../../../constants/common"
import { enableOrDisableFranchiseV3, getFranchiseByAngiesEntityIdV3, getFranchiseByZipCodeV3, getFranchiseFromChannelIdV3, getFranchiseIdValidationV3, getFranchisesStatisticsV3, getFranchisesV3, getOtherFranchisesV3, removeFranchiseV3 } from "../../../services/franchise/v3/franchise"
import { getLoopCancelCallback, retry, shouldRetry } from "../../../state-services-dublicate/serviceTitan"
import { getIsOnlyNumbers } from "../../../utils/validators"

export async function handleGetFranchisesV3(brandId = 0, isActive = 1, page = 1, pageSize = 50, search = "", setFranchises, setIsFranchisesLoading, setFranchiseCount, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    try {
        setFranchises([])
        setIsFranchisesLoading(true)
        const res = await getFranchisesV3(brandId, isActive, page, pageSize, search)
        if (Array.isArray(res?.franchises)) {
            setFranchises(res?.franchises)
        }
        setFranchiseCount(res?.total_count)
    } catch (err) {
        if (shouldRetry(backoffConfig) && err?.status !== 400 && err?.status !== 404) retry(() => handleGetFranchisesV3(brandId, isActive, page, pageSize, search, setFranchises, setIsFranchisesLoading, setFranchiseCount, backoffConfig), backoffConfig)
    } finally {
        setIsFranchisesLoading(false)
    }
}

export async function handleGetFranchisesStatisticsV3(setStatistics, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    try {
        setStatistics({ total: 0, active: 0, inactive: 0 })
        const res = await getFranchisesStatisticsV3()
        setStatistics(res)
    } catch (err) {
        if (shouldRetry(backoffConfig) && err?.status !== 400 && err?.status !== 404) retry(() => handleGetFranchisesStatisticsV3(setStatistics, backoffConfig), backoffConfig)
    }
}

export async function handleEnableOrDisableFranchiseV3(franchiseId = 0, isEnabled = 0, showNotification) {
    try {
        await enableOrDisableFranchiseV3(franchiseId, isEnabled)
        showNotification({ isOpen: true, type: NOTIFICATION_TYPES.SUCCESS, message: "Success" })
    } catch {
        showNotification({ isOpen: true, type: NOTIFICATION_TYPES.ERROR, message: "Important: Could not update the franchise. Please try again" })
    }
}

export async function handleRemoveFranchiseV3(franchiseId = 0, showNotification) {
    try {
        await removeFranchiseV3(franchiseId)
        showNotification({ isOpen: true, type: NOTIFICATION_TYPES.SUCCESS, message: "Successfully removed" })
    } catch {
        showNotification({ isOpen: true, type: NOTIFICATION_TYPES.ERROR, message: "Important: Could not remove the franchise. Please try again" })
    }
}

export async function handleFranchiseIdValidation(franchiseId = 0, setFranchiseIdValidationResponse = (res) => { }) {
    try {
        const res = await getFranchiseIdValidationV3(franchiseId)
        setFranchiseIdValidationResponse(res)
    } catch { }
}

export async function validateAngiesEntityIdV3(id = "", existingEntityIds = []) {
    const isOnlyNumbers = id && getIsOnlyNumbers(id)
    if (!isOnlyNumbers) {
        return { isValid: false, message: "Invalid" }
    }
    if (existingEntityIds.some((existingId) => parseInt(id) === existingId)) {
        return { isValid: false, message: "Duplicate" }
    }
    try {
        const res = await getFranchiseByAngiesEntityIdV3(id)
        return { isValid: res?.id ? false : true, message: res?.id ? "Already exists" : "" }
    }
    catch (err) {
        return { isValid: true, message: "" }
    }
}

export async function handleGetFranchiseByChannelIdV3(channelId = "", onDone = (franchise) => { }, onError = () => { }) {
    try {
        const franchise = await getFranchiseFromChannelIdV3(channelId)
        onDone(franchise)
    } catch (err) {
        if (err.status === 404) {
            onDone()
        } else {
            onError()
        }
    }
}

export function handleGetOtherFranchisesV3(brandId, excludingFranchiseIds = [], zipCode, radiusInKm, setOtherFranchisesWithOutSort, setIsOtherFranchisesLoading, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    setIsOtherFranchisesLoading(true)
    setOtherFranchisesWithOutSort([])
    getOtherFranchisesV3(brandId, excludingFranchiseIds, zipCode, radiusInKm).then((otherFranchises) => {
        if (!backoffConfig.loopCondition) return
        if (Array.isArray(otherFranchises?.franchises)) {
            setOtherFranchisesWithOutSort(otherFranchises?.franchises)
        }
    }).catch(() => {
        retry(handleGetOtherFranchisesV3(brandId, excludingFranchiseIds = [], zipCode, radiusInKm, setOtherFranchisesWithOutSort, setIsOtherFranchisesLoading, backoffConfig), backoffConfig)
    }).finally(() => {
        setIsOtherFranchisesLoading(false)
    })
    return getLoopCancelCallback(backoffConfig)
}

export function handleGetFranchisesWithBrandIdAndZipCode(setDefaultFranchises, brandId, zipCode, setZipCodeLocationData, setIsFranchisesByChannelIdAndZipCodeLoading, handleFaqFranchiseChange, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    setIsFranchisesByChannelIdAndZipCodeLoading(true)
    setDefaultFranchises([]);
    getFranchiseByZipCodeV3(brandId, zipCode ? zipCode : "").then((data) => {
        if (!backoffConfig?.loopCondition) return
        if (Array.isArray(data?.franchises)) {
            setDefaultFranchises(data?.franchises);
            if (data?.franchises?.length > 0) { // Set FAQ franchise id when auto picked franchises available
                handleFaqFranchiseChange(data?.franchises?.[0]?.id)
            }
        }
        setZipCodeLocationData(data?.zip_code_location_details?.geometry?.location)
    }).catch((err) => {
        retry(() => handleGetFranchisesWithBrandIdAndZipCode(setDefaultFranchises, brandId, zipCode, setZipCodeLocationData, setIsFranchisesByChannelIdAndZipCodeLoading, handleFaqFranchiseChange, backoffConfig), backoffConfig)
    }).finally(() => {
        setIsFranchisesByChannelIdAndZipCodeLoading(false);
    })
    return getLoopCancelCallback(backoffConfig)
}

export function handleGetSearchForDifferentFranchisesWithBrandIdAndZipCode(setZipCodeFranchises, brandId, zipCode, setZipCodeLocationData, setIsFranchisesByChannelIdAndZipCodeLoading, setOtherFranchisesWithOutSort, setIsOtherFranchisesLoading, handleFaqFranchiseChange, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    setIsFranchisesByChannelIdAndZipCodeLoading(true)
    setZipCodeFranchises([]);
    getFranchiseByZipCodeV3(brandId, zipCode ? zipCode : "").then(data => {
        if (!backoffConfig.loopCondition) return
        if (Array.isArray(data?.franchises)) {
            setZipCodeFranchises(data?.franchises);
            if (data?.franchises?.length > 0) { // Set FAQ franchise id when auto picked franchises available
                handleFaqFranchiseChange(data?.franchises?.[0]?.id)
            }
        }
        setZipCodeLocationData(data?.zip_code_location_details?.geometry?.location)
        const zipCodeFranchisesIds = data?.franchises?.map?.((f) => f?.id)
        handleGetOtherFranchisesV3(brandId, zipCodeFranchisesIds, zipCode, Math.round(90 * KM_PER_MILE), setOtherFranchisesWithOutSort, setIsOtherFranchisesLoading)
    }).catch(() => {
        retry(handleGetSearchForDifferentFranchisesWithBrandIdAndZipCode(setZipCodeFranchises, brandId, zipCode, setZipCodeLocationData, setIsFranchisesByChannelIdAndZipCodeLoading, setOtherFranchisesWithOutSort, setIsOtherFranchisesLoading, handleFaqFranchiseChange, backoffConfig), backoffConfig)
    }).finally(() => {
        setIsFranchisesByChannelIdAndZipCodeLoading(false);
    })
    return getLoopCancelCallback(backoffConfig)
}
