import { useContext, useEffect, useRef, useState } from "react";
import _ from 'lodash';
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { Box, Collapse, Dialog, FormLabel, Grid, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ReportIcon from "@mui/icons-material/Report";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { createFranchiseV3, getFranchiseIdValidationV3, updateFranchiseV3 } from "../../../../services/franchise/v3/franchise";
import { AVAILABLE_CRMS, FRANCHISE_STATUS, NOTIFICATION_TYPES, userLevels } from "../../../../constants/common";
import { getServiceTitanCredentialsValidation } from "../../../../services/serviceTitan";
import { getIsValidUSOrCanadianZipCode, isValidEmail, isValidPhoneNumber } from "../../../../utils/validators";
import { handleGetChannels } from "../../../../state-services/eightByEight/channels";
import { MISSING_CREDIENTIALS_WARNING, TEMPORY_FAILURE } from "../../../../constants/errorMessages";
import { createFranchiseSectionsMap } from "../../../../constants/franchise";
import { UserContext } from "../../../../contexts/User";
import LoadingButton from "../../../../components/LoadingButton";
import ServicetitanCredentialsSection from "./components/ServicetitanCredentialsSection";
import FranchiseCreateMainSection from "./components/FranchiseCreateMainSection";
import AngiesConfigurationsSection from "./components/AngiesConfigurationsSection";
import LuminConfigurationsSection from "./components/LuminConfigurationsSection";
import OwnersTable from "./OwnerTable/OwnersTable";
import FranchiseBrandsSection from "./components/FranchiseBrandsSection";
import { getMatchingBrandByBrandId } from "../../../../utils/miscellaneous";
import { getIsAllSame } from "../../../../utils/array";
import { CommonContext } from "../../../../contexts/CommonContextProvider";
import FaqTemplate from "./FaqTemplate/FaqTemplate"; 

export default function FranchiseModal(props) {
    const { status, onClose, open, franchiseDetails, showNotification, handleRefreshData, displaySection } = props;

    const { me } = useContext(UserContext)
    const { brands } = useContext(CommonContext)
    const [newFranchise, setNewFranchise] = useState(franchiseDetails?.franchise)
    const [angiesConfigurations, setAngiesConfigurations] = useState(franchiseDetails?.franchise_angies_list_configuration)
    const [angiesTaskNameConfigurations, setAngiesTaskNameConfigurations] = useState(franchiseDetails?.franchise_angies_list_task_name_configurations ?? [])
    const [luminConfigurations, setLuminConfigurations] = useState(franchiseDetails?.franchise_lumin_configuration)
    const [franchiseBrands, setFranchiseBrands] = useState(convertFranchiseBrandsToCreateRequest(franchiseDetails?.franchise_brands) ?? [])
    const [franchiseOwners, setFranchiseOwners] = useState(franchiseDetails?.owners ?? [])
    const [faqs, setFaqs] = useState(franchiseDetails?.franchise_faq_template) 
    const [loading, setLoading] = useState(false);
    const [displayIndex, setDisplayIndex] = useState(displaySection ?? createFranchiseSectionsMap.main.index)
    const [all8x8Channels, setAll8x8Channels] = useState([])
    const [isAll8x8ChannelsLoading, setIsAll8x8ChannelsLoading] = useState(false)
    const [isAll8x8ChannelsLoadingError, setIsAll8x8ChannelsLoadingError] = useState(false)
    const [toggleGetAll8x8Channels, setToggleGetAll8x8Channels] = useState(true)
    const [selectedFranchiseBrandToEdit, setSelectedFranchiseBrandToEdit] = useState()
    const [isTemplateEmptyAtBegining, setIsTemplateEmptyAtBegining] = useState(checkIfFaqTemplateIsNotEmpty()) 

    const InitialNewFranchise = franchiseDetails?.franchise
    const InitialAngiesConfigurations = franchiseDetails?.franchise_angies_list_configuration
    const InitialAngiesTaskNameConfigurations = franchiseDetails?.franchise_angies_list_task_name_configurations ?? []
    const InitialLuminConfigurations = franchiseDetails?.franchise_lumin_configuration
    const InitialFranchiseBrands = convertFranchiseBrandsToCreateRequest(franchiseDetails?.franchise_brands) ?? []
    const InitialFranchiseOwners = franchiseDetails?.owners ?? []
    const InitialFranchiseFaqTemplate = useRef(_.cloneDeep(franchiseDetails?.franchise_faq_template))

    useEffect(() => {
        handleGetChannels(setAll8x8Channels, setIsAll8x8ChannelsLoading, setIsAll8x8ChannelsLoadingError)
    }, [toggleGetAll8x8Channels])

    const TrimAdditonalSpaces = (templateFaqs) => {
        templateFaqs?.forEach(item => {
            let splitAnswer = item?.answer.split("\n")?.filter(item => item !== "")
            let finalAnswer = splitAnswer.map(item => item.replace(/\s+/g,' ').trim()).join("\n")
            item.answer = finalAnswer
        })
    }

    async function onSubmit(e) {
        try {
            e?.preventDefault?.()
            let is_stack = franchiseBrands?.length > 1 ? 1 : 0
            let Franchise = { ...newFranchise, is_stack }
            setLoading(true);
            const createFranchiseRequest = {
                franchise: Franchise ? Franchise : {},
                owners: franchiseOwners?.map((o) => {
                    return { ...o, is_enabled: 1 }
                }),
                franchise_brand_create_requests: franchiseBrands,
                franchise_angies_list_configuration: angiesConfigurations,
                franchise_angies_list_task_name_configurations: angiesTaskNameConfigurations ?? [],
                franchise_lumin_configuration: luminConfigurations,
                faq_template: faqs
            }

            TrimAdditonalSpaces(createFranchiseRequest?.faq_template)

            const franchiseShouldUpdateFields = {
                should_update_franchise: !areObjectsEqual(InitialNewFranchise, newFranchise),
                should_update_owners: !areObjectsEqual(InitialFranchiseOwners, franchiseOwners),
                should_update_franchise_brand_create_requests: !areObjectsEqual(InitialFranchiseBrands, franchiseBrands),
                should_update_angies_list_configuration: !areObjectsEqual(InitialAngiesConfigurations, angiesConfigurations),
                should_update_angies_list_task_name_configurations: !areObjectsEqual(InitialAngiesTaskNameConfigurations, angiesTaskNameConfigurations),
                should_update_lumin_configuration: !areObjectsEqual(InitialLuminConfigurations, luminConfigurations),
                should_update_faq_template: !areObjectsEqual(InitialFranchiseFaqTemplate.current, faqs)
            }

            if (selectedFranchiseBrandToEdit) {
                createFranchiseRequest?.franchise_brand_create_requests?.push?.({ ...selectedFranchiseBrandToEdit })
            }
            const [isValid, sectionIndex, errorMessage] = await validateFranchiseCreateRequest(franchiseDetails?.franchise?.id, createFranchiseRequest, brands)
            if (!isValid) {
                setDisplayIndex(sectionIndex)
                showNotification({ message: errorMessage, type: NOTIFICATION_TYPES.ERROR })
                setLoading(false);
                return
            }
            if (franchiseDetails?.franchise?.id) {
                const updateFranchiseRequest = {
                    ...createFranchiseRequest,
                    franchise_id: franchiseDetails?.franchise?.id,
                    franchise_should_update_fields: franchiseShouldUpdateFields
                }
                await updateFranchiseV3(updateFranchiseRequest)
                showNotification({ message: "Franchise has been updated successfully", type: NOTIFICATION_TYPES.SUCCESS })
            } else {
                await createFranchiseV3(createFranchiseRequest)
                showNotification({ message: "Franchise has been added successfully", type: NOTIFICATION_TYPES.SUCCESS })
            }
            handleRefreshData()
            handleClose(false)
        } catch (error) {
            showNotification({ message: "Important : Unable to add Franchise at this moment", type: NOTIFICATION_TYPES.ERROR })
        } finally {
            setLoading(false);
        }
    }

    function handleClose(isFromCloseButton = true) {
        if (isFromCloseButton && franchiseDetails?.franchise?.franchise_name) {
            let isChangeHappeded = !areObjectsEqual(InitialFranchiseFaqTemplate.current, faqs)
            if (isChangeHappeded) {
                const userConfirmed = window.confirm("You have unsaved changes. Do you want to proceed without saving?"); // eslint-disable-line
                if (!userConfirmed) {
                    return;
                }
            }    
        }
        setIsTemplateEmptyAtBegining(false)
        return onClose();
    }

    const showCurrentCollapseHandler = (index) => {
        if (displayIndex === index) {
            setDisplayIndex(displaySection ?? createFranchiseSectionsMap.main.index)  // set -1 to colapse all 0 to keep open section 0
        } else {
            setDisplayIndex(index)
        }
    }

    function checkIfFaqTemplateIsNotEmpty() {
        if (!Array.isArray(faqs)) {
            return true;
        }
        let isAllEmpty = false
        let emptyCount = 0
        for (let faq of faqs) {
            if (!faq?.answer && faq?.keywords?.length === 0) {
                emptyCount++
            }
        }
        if (emptyCount === faqs?.length) {
            isAllEmpty = true
        }
        return isAllEmpty
    }

    const isCreateOrUpdateButtonDisabled = franchiseDetails?.is_encryption_failed || me?.level <= userLevels.Supervisor || (franchiseDetails?.franchise && franchiseDetails?.franchise?.is_enabled !== FRANCHISE_STATUS.active && displaySection !== createFranchiseSectionsMap.serviceTitan.index)
    return (open && <Dialog open={open} fullWidth maxWidth="xl" className="dialog-zindex">
        <div style={{ paddingBlock: "10px", paddingInline: "20px" }} className={'dialog'}>
            <IconButton onClick={() => { handleClose(true) }} size={"small"} aria-label="delete"
                style={{ position: "absolute", right: "10px", top: "10px" }}
            >
                <CloseIcon style={{ height: "15px", width: "15px" }} />
            </IconButton>
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "start" }}>
                <h3 className={"dialog-title"} style={{ marginTop: "10px" }}>
                    {franchiseDetails?.franchise ?
                        (status === FRANCHISE_STATUS.deleted || me?.level <= userLevels.Supervisor ? `Franchise Details - ${franchiseDetails?.franchise?.franchise_name ?? ""}` : `Update Franchise -  ${franchiseDetails?.franchise?.franchise_name ?? ""}`)
                        : "Create a New Franchise"}
                </h3>
            </Box>
            <form onSubmit={onSubmit}>
                {Object.values(createFranchiseSectionsMap).map((section) => {
                    if (franchiseDetails?.franchise?.is_toll_free === 1 && !section?.isApplicableForTollFree) {
                        return <></>
                    } else {
                        return <div key={section.index} >
                            <Grid  container spacing={2} className={'franchise-form'}
                                onClick={() => {
                                    showCurrentCollapseHandler(section.index)
                                }}>
                                <Grid item xs={10} sx={{ margin: 'auto' }}>
                                    <FormLabel>{section.name}</FormLabel>
                                </Grid>
                                <Grid item xs={2}>
                                    <IconButton
                                        data-test={`franchise_section_${section.test_automation_label}`}
                                        sx={{ float: 'right' }}
                                        onClick={() => {
                                            showCurrentCollapseHandler(section.index)
                                        }}
                                        aria-label="expand"
                                        size="small"
                                    >
                                        {section.index === displayIndex ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <Collapse in={section.index === displayIndex} unmountOnExit>
                                {getNormalFranchiseSelection(section.index)}
                            </Collapse>

                        </div>
                    }
                })}
                {(status === FRANCHISE_STATUS.deleted || me?.level <= userLevels.Supervisor) ? <></> : <div style={{ display: "flex", justifyContent: "end", marginTop: "20px" }}>
                    <Button
                        data-test="franchise_cancel"
                        size={"small"}
                        variant="contained"
                        onClick={() => { handleClose(true) }}
                        className={"btn-secondary"}
                        style={{ marginRight: "10px" }}
                    >
                        Cancel
                    </Button>
                    <div style={{ position: "relative" }}>
                        {franchiseDetails?.is_encryption_failed &&
                            <Tooltip sx={{ position: "absolute", bottom: "-10px", right: "-10px" }} title={franchiseDetails && !franchiseDetails?.is_encryption_failed ? MISSING_CREDIENTIALS_WARNING : TEMPORY_FAILURE}>
                                <IconButton>
                                    <ReportIcon color="error" style={{ height: "16px", width: "16px" }} />
                                </IconButton>
                            </Tooltip>
                        }
                        <LoadingButton
                            data-test="franchise_save"
                            type={"submit"}
                            size={"small"}
                            variant="contained"
                            loading={loading}
                            disabled={isCreateOrUpdateButtonDisabled}
                            className={isCreateOrUpdateButtonDisabled ? "btn-disable" : "btn-primary"}
                        >
                            {franchiseDetails?.franchise ? "Update" : "Save"}
                        </LoadingButton>
                    </div>
                </div >}
            </form >
        </div >
    </Dialog >
    );

    function getNormalFranchiseSelection(index) {
        switch (index) {
            case createFranchiseSectionsMap.main.index:
                return <FranchiseCreateMainSection oldFranchise={franchiseDetails?.franchise} isDisabled={(status === FRANCHISE_STATUS.deleted || me?.level <= userLevels.Supervisor)} franchise={newFranchise} setFranchise={setNewFranchise} franchiseBrands={franchiseBrands} setFranchiseBrands={setFranchiseBrands} />
            case createFranchiseSectionsMap.brands.index:
                return <FranchiseBrandsSection franchise={newFranchise} isTollFree={franchiseDetails?.franchise?.is_toll_free === 1} isDisabled={(status === FRANCHISE_STATUS.deleted || me?.level <= userLevels.Supervisor)} franchiseBrands={franchiseBrands} setFranchiseBrands={setFranchiseBrands} selectedFranchiseBrandToEdit={selectedFranchiseBrandToEdit} setSelectedFranchiseBrandToEdit={setSelectedFranchiseBrandToEdit} all8x8Channels={all8x8Channels} isAll8x8ChannelsLoading={isAll8x8ChannelsLoading} isAll8x8ChannelsLoadingError={isAll8x8ChannelsLoadingError} toggleGetAll8x8Channels={toggleGetAll8x8Channels} setToggleGetAll8x8Channels={setToggleGetAll8x8Channels} />
            case createFranchiseSectionsMap.serviceTitan.index:
                return <ServicetitanCredentialsSection isDisabled={(status === FRANCHISE_STATUS.deleted || me?.level <= userLevels.Supervisor || newFranchise?.service_brand !== AVAILABLE_CRMS.servicetitan.value)} isTollFree={franchiseDetails?.franchise?.is_toll_free === 1} franchise={newFranchise} setFranchise={setNewFranchise} />
            case createFranchiseSectionsMap.angiesList.index:
                return <AngiesConfigurationsSection oldFranchise={franchiseDetails?.franchise} angiesConfigurations={angiesConfigurations} setAngiesConfigurations={setAngiesConfigurations} angiesTaskNameConfigurations={angiesTaskNameConfigurations} setAngiesTaskNameConfigurations={setAngiesTaskNameConfigurations} />
            case createFranchiseSectionsMap.lumin.index:
                return <LuminConfigurationsSection isDisabled={(status === FRANCHISE_STATUS.deleted || me?.level <= userLevels.Supervisor)} luminConfigurations={luminConfigurations} setLuminConfigurations={setLuminConfigurations} />
            case createFranchiseSectionsMap.FaqTemplate.index:
                return <FaqTemplate faqs={faqs} setFaqs={setFaqs} isTemplateEmptyAtBegining={isTemplateEmptyAtBegining} isEditFrachise={isEditFranchise(franchiseDetails?.franchise)} />
            case createFranchiseSectionsMap.owners.index:
                return <OwnersTable disabled={status === FRANCHISE_STATUS.deleted || displaySection === createFranchiseSectionsMap.serviceTitan.index} franchiseOwners={franchiseOwners} setFranchiseOwners={setFranchiseOwners} />
            default:
                return (
                    <></>
                )
        }
    }
}

function isEditFranchise(franchise) {
    if (franchise?.id) {
        return true
    } else {
        return false
    }
}

async function validateFranchiseCreateRequest(isEditFranchise = false, createFranchiseRequest = {}, brands = []) {
    let [isValid, sectionIndex, errorMessage] = await validateFranchise(isEditFranchise, createFranchiseRequest?.franchise)
    if (!isValid) {
        return [isValid, sectionIndex, errorMessage]
    }
    [isValid, sectionIndex, errorMessage] = await validateServiceTitanCredentials(createFranchiseRequest?.franchise)
    if (!isValid) {
        return [isValid, sectionIndex, errorMessage]
    }
    [isValid, sectionIndex, errorMessage] = await validateFranchiseBrands(createFranchiseRequest?.franchise, createFranchiseRequest?.franchise_brand_create_requests, brands)
    if (!isValid) {
        return [isValid, sectionIndex, errorMessage]
    }
    [isValid, sectionIndex, errorMessage] = validateFranchiseOwners(createFranchiseRequest?.owners)
    if (!isValid) {
        return [isValid, sectionIndex, errorMessage]
    }
    [isValid, sectionIndex, errorMessage] = validateFaqTempalte(createFranchiseRequest?.franchise, createFranchiseRequest?.faq_template)
    if (!isValid) {
        return [isValid, sectionIndex, errorMessage]
    }
    return [isValid, sectionIndex, errorMessage]
}

async function validateFranchise(isEditFranchise = false, franchise = {}) {
    const franchiseMainSectionIndex = createFranchiseSectionsMap.main.index
    const servicetitanSectionIndex = createFranchiseSectionsMap.serviceTitan.index
    const isServicetitanFranchise = franchise?.service_brand === AVAILABLE_CRMS.servicetitan.value
    const isTollFreeFranchise = franchise?.is_toll_free === 1
    if (!franchise?.franchise_name) {
        return [false, franchiseMainSectionIndex, "Franchise name is required"]
    } else if (!franchise?.franchise_location) {
        return [false, franchiseMainSectionIndex, "Franchise location is required"]
    } else if (!franchise?.exact_zipcode && !isTollFreeFranchise) {
        return [false, franchiseMainSectionIndex, "Franchise exact zip code is required"]
    } else if (!getIsValidUSOrCanadianZipCode(franchise?.exact_zipcode) && !isTollFreeFranchise) {
        return [false, franchiseMainSectionIndex, "Franchise exact zip code is invalid"]
    } else if (!isTollFreeFranchise && !franchise?.franchise_id) {
        return [false, franchiseMainSectionIndex, "Franchise id is required"]
    } else if (!isTollFreeFranchise && !franchise?.franchise_id.toString().length > 11) {
        return [false, franchiseMainSectionIndex, "Franchise id length should be less than 12 digits"]
    } else if (!isTollFreeFranchise && !franchise?.service_brand) {
        return [false, franchiseMainSectionIndex, "Franchise CRM System is required"]
    } else if (!isTollFreeFranchise && !franchise?.time_zone) {
        return [false, franchiseMainSectionIndex, "Franchise time zone is required"]
    } else if (!isTollFreeFranchise && isServicetitanFranchise && !franchise?.service_titan_tenant_id) {
        return [false, servicetitanSectionIndex, "Servicetitan Tenant Id is required"]
    } else if (!isTollFreeFranchise && isServicetitanFranchise && !franchise?.service_titan_tenant_name) {
        return [false, servicetitanSectionIndex, "Servicetitan Tenant Name is required"]
    } else if (!isTollFreeFranchise && isServicetitanFranchise && !franchise?.api_key) {
        return [false, servicetitanSectionIndex, "Servicetitan API Key is required"]
    } else if (!isTollFreeFranchise && isServicetitanFranchise && franchise?.service_titan_client_secret && !franchise?.service_titan_client_id) {
        return [false, servicetitanSectionIndex, "Servicetitan Client Id is required"]
    } else if (!isTollFreeFranchise && isServicetitanFranchise && franchise?.service_titan_client_id && !franchise?.service_titan_client_secret) {
        return [false, servicetitanSectionIndex, "Servicetitan Client Secret is required"]
    } else if (!isEditFranchise) {
        const res = await validateFranchiseFranchiseId(franchise?.franchise_id)
        if (!res?.is_valid) {
            return [false, franchiseMainSectionIndex, "Franchise id Already Exists"]
        }
    }
    return [true, undefined, undefined]
}

function validateFaqTempalte(franchise, faqs = []) {
    if (franchise?.is_toll_free === 0) {
        for (let faq of faqs) {
            if (!faq?.answer) {
                return [false, createFranchiseSectionsMap.FaqTemplate.index, "Please Fill The Required Fields In FAQ Section"]
            }
        }
        return [true, undefined, undefined]
    } else {
        for (let faq of faqs) {
            if (!faq?.answer) {
                return [false, createFranchiseSectionsMap.FaqTemplate.index, "Please Fill The Required Fields In FAQ Section"]
            }
        }
        return [true, undefined, undefined]
    }
}

function validateFranchiseOwners(owners = []) {
    const franchiseOwnersSectionIndex = createFranchiseSectionsMap.owners.index
    for (let owner of owners) {
        if (!owner?.owner_name) {
            return [false, franchiseOwnersSectionIndex, "Owner name is required"]
        } else if (!owner?.owner_email) {
            return [false, franchiseOwnersSectionIndex, "Owner email is required"]
        } else if (owner?.owner_email && !isValidEmail(owner?.owner_email)) {
            return [false, franchiseOwnersSectionIndex, "Owner email is invalid"]
        } else if (owner?.owner_phone && !isValidPhoneNumber(owner?.owner_phone)) {
            return [false, franchiseOwnersSectionIndex, "Owner phone is invalid"]
        }
    }
    return [true, undefined, undefined]
}

async function validateServiceTitanCredentials(franchise = {}) {
    const servicetitanSectionIndex = createFranchiseSectionsMap.serviceTitan.index
    const isServicetitanFranchise = franchise?.service_brand === AVAILABLE_CRMS.servicetitan.value
    if (isServicetitanFranchise && franchise?.service_titan_client_id && franchise?.service_titan_client_secret && franchise?.service_titan_tenant_id) {
        const validationResponse = await getServiceTitanCredentialsValidation(franchise?.service_titan_tenant_id, franchise?.service_titan_client_id, franchise?.service_titan_client_secret)
        if (!validationResponse?.isClientIdAndClientSecretValid) {
            return [false, servicetitanSectionIndex, "Servicetitan Client Id and Client Secret are invalid"]
        } else if (!validationResponse?.isTenantIdValid) {
            return [false, servicetitanSectionIndex, "Servicetitan Tenant Id is invalid"]
        }
    }
    return [true, undefined, undefined]
}

async function validateFranchiseBrands(franchise = {}, franchiseBrands = [], brands) {
    const allVonigoRedirectUrls = []
    for (let fb of franchiseBrands) {
        const brand = getMatchingBrandByBrandId(fb?.brand_id, brands)
        const redirectUrl = brand?.redirect_url
        if (redirectUrl) {
            allVonigoRedirectUrls.push(redirectUrl)
        }
    }
    const isSameValues = getIsAllSame(allVonigoRedirectUrls)
    if (!isSameValues && franchise?.service_brand === AVAILABLE_CRMS.vonigo.value && franchise?.is_toll_free === 0) {
        const sectionIndex = createFranchiseSectionsMap.brands.index
        const message = "Important! Vonigo Redirect Url should be same for all brands"
        return [false, sectionIndex, message]
    }
    return [true, undefined, undefined]
}

async function validateFranchiseFranchiseId(franchiseId) {
    try {
        const res = await getFranchiseIdValidationV3(franchiseId)
        return res
    } catch {
        return { is_valid: true, message: "" }
    }
}

function convertFranchiseBrandsToCreateRequest(franchiseBrands = []) {
    return franchiseBrands?.map?.(brand => {
        return {
            brand_id: brand?.franchise_brand?.brand_id,
            channel_ids: brand?.channels?.map?.(channel => channel?.channel_id ?? "") ?? [],
            primary_zip_codes: brand?.zip_codes?.filter?.((z) => z.type === "primary")?.map?.(zipCode => zipCode?.zip_code ?? "") ?? [],
            secondary_zip_codes: brand?.zip_codes?.filter?.((z) => z.type === "secondary")?.map?.(zipCode => zipCode?.zip_code ?? "") ?? [],
            franchise_angies_list_sp_entity_ids: brand?.franchise_angies_list_sp_entity_ids?.map?.(id => id?.angies_list_sp_entity_id ?? "") ?? [],
            outbound_channels: brand?.outbound_channels ?? [],
        }
    }) ?? []
}

function areObjectsEqual(arr1, arr2) {
    return JSON.stringify(arr1) === JSON.stringify(arr2);
}