import { Box, Grid, Paper } from '@mui/material'
import React from 'react'
import AgentLogsIcons from './agentLogsIcons'
import CircleIcon from '@mui/icons-material/Circle';

export default function AgentLogsTile({ log, index }) {
    return (
        <Paper key={index} sx={{ display: "flex", flexDirection: "column", height: "150px", width: "280px", padding: "10px", background: 'form.secondary' }}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "1em" }}>
                        {log?.IsOnline === false
                            ?
                            <CircleIcon style={{ height: "12px", Width: "12px", color: "red" }}></CircleIcon>
                            :
                            <CircleIcon style={{ height: "12px", Width: "12px", color: "green" }}></CircleIcon>
                        }
                        <p>{log.AgentName}</p>
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Box sx={{ display: "flex", flexDirection: "row", gap: "2em", alignItems: "center", padding: "1.5em", borderRadius: "20px", height: "30px", backgroundColor: 'background.main' }}>
                        <div>
                            <AgentLogsIcons status={log.Status}></AgentLogsIcons>
                        </div>
                        <div >
                            <p sx={{ textOverflow: "ellipsis" }}>{log.Status}</p>
                        </div>
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    )
}
