import { Typography, Box, IconButton, TextField } from "@mui/material"
import ReportIcon from "@mui/icons-material/Report";
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import {CircularProgress} from "@mui/material";
import { useEffect, useState, useContext } from "react"
import { getWarningMessageFromSectionId, saveWarningMessageService } from "../../services/warningMessagesService";
import { CommonContext } from "../../contexts/CommonContextProvider";
import { NOTIFICATION_TYPES } from "../../constants/common";
import { UserContext } from "../../contexts/User";
import { userLevels } from "../../constants/common";

export default function WarningMessage(props) {
    const { sectionId,sx } = props
    const { me } = useContext(UserContext)

    const [message, setMessage] = useState("")
    const [isEditable, setIsEditable] = useState(false)
    const [editedMessage, setEditedMessage] = useState()
    const [isMessageLoading, setIsMessageLoading] = useState(true)

    const { showNotification } = useContext(CommonContext);

    useEffect(() => {
        (async () => {
            const warningMessage = await getWarningMessageFromSectionId(sectionId)
            setMessage(warningMessage?.message)
            setEditedMessage(warningMessage?.message)
            setIsMessageLoading(false)
        })()
    }, [sectionId])

    const saveWarningMessage = async () => {
        const body = {
            section_id: sectionId,
            message: editedMessage
        }

        await saveWarningMessageService(body).then((response) => {
            setIsEditable(false)
            setMessage(editedMessage)
            showNotification({ message: "Warning message updated successfully", type: NOTIFICATION_TYPES.SUCCESS })
        }).catch((error) => {
            showNotification({ message: error.message, type: NOTIFICATION_TYPES.ERROR })
        })

    }

    return <Box sx={sx ? sx : {maxWidth:'500px'}} overflow="auto" padding="0.5em" borderRadius="0.5em" backgroundColor='form.input' alignContent="stretch" display="flex" flexDirection="column" marginBottom="1em">
        <Box display="flex" flexDirection="row" position="relative" justifyContent="space-between" alignItems="center" gap="0.5em" > <Box display="flex" flexDirection="row" alignItems="center" gap="0.5em" > <Typography variant="subtitle2">Message</Typography> <ReportIcon color="warning" style={{ height: "16px", width: "16px" }} /> </Box> {!isEditable && me?.level >= userLevels.SUPER_ADMIN && <IconButton onClick={() => setIsEditable(true)} key={"edit_button"} >{<EditIcon style={{ height: "18px", width: "18px" }} />}</IconButton>}</Box>
        {isMessageLoading ? <CircularProgress sx={{alignSelf:'center'}} size={30} color="inherit" /> : <>
        {isEditable ? (<Box display="flex" sx={{minWidth:'500px'}}> <TextField variant="standard" multiline sx={{ flexGrow: 4 }} value={editedMessage ?? ""} onChange={(e) => setEditedMessage(e.target.value)} /> <IconButton key={"done_button"} onClick={() => saveWarningMessage()}>{<DoneIcon fontSize={"10"} />}</IconButton> <IconButton key={"clear_button"} onClick={() => setIsEditable(false)}>{<ClearIcon fontSize={"10"} />}</IconButton> </Box>) : (<Box sx={{ overflow: "auto"}}><Typography sx={{overflowWrap: "break-word", wordWrap: "break-word" }} fontWeight="100">{message}</Typography></Box>)}</>}

    </Box>
}
