export const prepareSyncDesktopAppTitle = (franchiseName) => `Get FAQs for ${franchiseName}`

export const getSpeedometerLabels = (textColor) => {

    const effectiveColor = textColor || 'gray';

    return [
        {
            text: 'Angry',
            position: 'OUTSIDE',
            color: effectiveColor,
        },
        {
            text: 'Upset',
            position: 'OUTSIDE',
            color: effectiveColor,
        },
        {
            text: 'Neutral',
            position: 'OUTSIDE',
            color: effectiveColor,
        },
        {
            text: 'Happy',
            position: 'OUTSIDE',
            color: effectiveColor,
        },
        {
            text: 'Excited',
            position: 'OUTSIDE',
            color: effectiveColor,
        },
    ]
}

export const behaviourImpactLabels = [
    { value: 0, label: 'Rude' },
    { value: 20, label: 'Awkward' },
    { value: 40, label: 'Average' },
    { value: 60, label: 'Good' },
    { value: 80, label: 'Decent' }
]

export const callEnjoymentLabels = [
    { value: 0, label: 'Bad' },
    { value: 20, label: 'Dull' },
    { value: 40, label: 'Average' },
    { value: 60, label: 'Good' },
    { value: 80, label: 'Amazing' }
]

export const determineColor = (value) => {
    if (value <= 20) return '#FF0000';
    if (value <= 40) return '#FF6600';
    if (value <= 60) return '#FF9900';
    if (value <= 80) return '#33CC33';
};

export function determineCustomerSatisfaction(agentFeedbackData) {
    if (parseFloat(agentFeedbackData?.customerScore) >= 0.1 && parseFloat(agentFeedbackData?.customerScore) < 0.5 && parseFloat(agentFeedbackData?.customerMagnitude) >= 1.0) {
        return { data: 70, emotion: 'Happy' }
    } else if (parseFloat(agentFeedbackData?.customerScore) >= 0.5 && parseFloat(agentFeedbackData?.customerScore) < 1.0 && parseFloat(agentFeedbackData?.customerMagnitude) >= 1.0) {
        return { data: 90, emotion: 'Excited' }
    } else if (parseFloat(agentFeedbackData?.customerScore) < -0.1 && parseFloat(agentFeedbackData?.customerScore) >= -0.3 && parseFloat(agentFeedbackData?.customerMagnitude) >= 1.0) {
        return { data: 30, emotion: 'Upset' }
    } else if (parseFloat(agentFeedbackData?.customerScore) < -0.3 && parseFloat(agentFeedbackData?.customerScore) >= -1.0 && parseFloat(agentFeedbackData?.customerMagnitude) >= 1.0) {
        return { data: 10, emotion: 'Angry' }
    } else if (parseFloat(agentFeedbackData?.customerScore) >= -0.1 && parseFloat(agentFeedbackData?.customerScore) < 0.1 && parseFloat(agentFeedbackData?.customerMagnitude) >= 1.0) {
        return { data: 50, emotion: 'Neutral' }
    }
    else {
        return { data: 50, emotion: 'Neutral' }
    }
}

export const convertCallAnalysisTextToValue = (value) => {
    switch (value) {
        case 'Happy':
            return 70;
        case 'Excited':
            return 90;
        case 'Upset':
            return 30;
        case 'Angry':
            return 10;
        case 'Neutral':
            return 50;
        default:
            return 50;
    }
}

export function convertCustomerBehaviour(type, value) {
    if (type === 'toText') {
        switch (value) {
            case 0:
                return 'Rude';
            case 20:
                return 'Awkward';
            case 40:
                return 'Average';
            case 60:
                return 'Good';
            default:
                return 'Decent';
        }
    } else if (type === 'toValue') {
        switch (value) {
            case 'Rude':
                return 0;
            case 'Awkward':
                return 20;
            case 'Average':
                return 40;
            case 'Good':
                return 60;
            case 'Decent':
                return 80;
            default:
                return 0;
        }
    }
}

export function convertCallEnjoyment(type, value) {
    if (type === 'toText') {
        switch (value) {
            case 0:
                return 'Bad';
            case 20:
                return 'Dull';
            case 40:
                return 'Average';
            case 60:
                return 'Good';
            default:
                return 'Amazing';
        }
    } else if (type === 'toValue') {
        switch (value) {
            case 'Bad':
                return 0;
            case 'Dull':
                return 20;
            case 'Average':
                return 40;
            case 'Good':
                return 60;
            case 'Amazing':
                return 80;
            default:
                return 0;
        }
    }
}

export function handleSetAgentFeedbackData(socketMessage, setAgentFeedbackData, setIsOpenAgentFeedbackDialog) {
    let agentScoreMatch = socketMessage?.message.match(/Agent Score: (-?\d+\.\d+)/);
    let agentMagnitudeMatch = socketMessage?.message.match(/Agent Magnitude: (-?\d+\.\d+)/);
    let interactionGuidMatch = socketMessage?.message.match(/InteractionGUID: ([^\s]+)/);
    let customerScoreMatch = socketMessage?.message.match(/Customer Score: (-?\d+\.\d+)/);
    let customerMagnitudeMatch = socketMessage?.message.match(/Customer Magnitude: (-?\d+\.\d+)/);
    let sentimentIdMatch = socketMessage?.message.match(/SentimentId: (\d+)/);
    let currentGUDI = sessionStorage.getItem("interaction_guid")
    if (interactionGuidMatch && interactionGuidMatch[1] === currentGUDI) {
        setAgentFeedbackData({
            agentScore: agentScoreMatch ? agentScoreMatch[1] : 0.0,
            agentMagnitude: agentMagnitudeMatch ? agentMagnitudeMatch[1] : 0.0,
            customerScore: customerScoreMatch ? customerScoreMatch[1] : 0.0,
            customerMagnitude: customerMagnitudeMatch ? customerMagnitudeMatch[1] : 0.0,
            sentimentId: sentimentIdMatch ? sentimentIdMatch[1] : 0
        })
        setIsOpenAgentFeedbackDialog(true)
    }
}