import { IconButton, TableRow, TableCell } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from "@mui/icons-material/Delete";
import { getPhoneNumberWithInputMask } from '../../../../../../utils/inputMasks';
import { UserContext } from "../../../../../../contexts/User";
import { useContext } from 'react';
import { userLevels } from '../../../../../../constants/common';

export default function OwnerRow(props) {
    const { key, owner, onEdit, onDelete, readOnly = false } = props
    const { me } = useContext(UserContext)
    return <TableRow key={key}>
        <TableCell component="th" scope="row">{owner.owner_name}</TableCell>
        <TableCell align="left">{owner.owner_email}</TableCell>
        <TableCell>{owner.owner_phone ? getPhoneNumberWithInputMask(owner.owner_phone) : "-"}</TableCell>
        {readOnly ? <></> : <TableCell align="right">
            <IconButton color="info" title='Edit' onClick={onEdit} disabled={me?.level <= userLevels.Supervisor}>
                <EditIcon style={{ height: "20px", width: "20px" }} />
            </IconButton>
            <IconButton title='Delete' color="error" onClick={onDelete} disabled={me?.level <= userLevels.Supervisor}>
                <DeleteIcon style={{ height: "20px", width: "20px", marginRight: "15px" }} />
            </IconButton>
        </TableCell>}
    </TableRow>
}