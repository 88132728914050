export const removeDuplicateOfArray = (array = []) => [...new Set(array)];

export function alphabeticallySortArray(a, b) {
    var lowerA = a.toLowerCase();
    var lowerB = b.toLowerCase();
    if (lowerA < lowerB) return -1;
    if (lowerA > lowerB) return 1;
    return 0;
}

export function getIsAllSame(arr = []) {
    if (arr.length === 0) {
        return true;
    }
    const firstString = arr[0];
    return arr.every(str => str === firstString);
}

