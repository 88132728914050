import { Box } from "@mui/material"
import { useEffect, useState } from "react"
import CustomTextField from "../../../../../components/CustomTextField"
import { NOTIFICATION_TYPES } from "../../../../../constants/common"
import { createCustomer, getCampaigns, getIndustries } from "../../../../../services/vonigo/jobScheduling"
import { isValidEmail, isValidPhoneNumber } from "../../../../../utils/validators"
import CustomerTypesRadioButton from "../../serviceTitanJobs/components/CustomerTypesRadioButton"
import CampaignSelection from "./CampaignSelection"
import { CUSTOMER_TYPES, INITIAL_CUSTOMER_VALUES } from "./CustomerDetailsSection"
import EmailField from "../../components/EmailField"
import IndustrySelection from "./IndustrySelection"
import PhoneNumberField from "./PhoneNumberField"
import LoadingButton from "../../../../../components/LoadingButton"

export default function CreateNewCustomer(props) {
    const { brand, franchise, setContacts, setLocations, isDisabled, onNewCustomerCreated, showNotification } = props
    const [newCustomer, setNewCustomer] = useState(INITIAL_CUSTOMER_VALUES)
    const [industries, setIndustries] = useState([])
    const [isIndustriesLoading, setIsIndustriesLoading] = useState(false)
    const [industry, setIndustry] = useState()
    const [isCreateCustomerLoading, setIsCreateCustomerLoading] = useState(false)
    const [campaign, setCampaign] = useState()
    const [campaigns, setCampaigns] = useState([])
    const [isCampaignsLoading, setIsCampaignsLoading] = useState(false)

    useEffect(() => {
        if (brand && franchise && brand?.redirect_url && franchise?.franchise_id) {
            const customer = { ...newCustomer, franchiseId: franchise?.franchise_id, baseUrl: brand?.redirect_url }
            setNewCustomer(customer)

            setIsIndustriesLoading(true)
            getIndustries(brand?.redirect_url, franchise?.franchise_id).then((industriesResponse) => {
                if (Array.isArray(industriesResponse.Options)) {
                    setIndustries(industriesResponse.Options)
                }
            }).catch((err) => {
            }).finally(() => setIsIndustriesLoading(false))

            setIsCampaignsLoading(true)
            getCampaigns(brand?.redirect_url, franchise?.franchise_id).then((campaignResponse) => {
                if (Array.isArray(campaignResponse.Campaigns)) {
                    const activeCampaigns = campaignResponse.Campaigns.filter((c) => c?.isActive)
                    setCampaigns(activeCampaigns)
                }
            }).catch((err) => {
            }).finally(() => setIsCampaignsLoading(false))
        }
    }, [brand, franchise])

    function onCreateCustomerClicked() {
        setIsCreateCustomerLoading(true)
        setContacts([])
        setLocations([])
        createCustomer(newCustomer).then((response) => {
            onNewCustomerCreated?.(response)
            showNotification({ message: "Customer has been created successfully", type: NOTIFICATION_TYPES.SUCCESS, isOpen: true })
        }).catch((err) => {
            showNotification({ message: "Important: Customer couldn’t be created at this time. Please try again", type: NOTIFICATION_TYPES.ERROR })
        }).finally(() => setIsCreateCustomerLoading(false))
    }

    function onCampaignChanged(campaign) {
        setCampaign(campaign)
        setNewCustomer({ ...newCustomer, marketingCampaignOptionId: `${campaign?.optionID ? campaign?.optionID : ""}` })
    }

    function onIndustryChanged(industry) {
        setIndustry(industry)
        setNewCustomer({ ...newCustomer, industryOptionId: `${industry?.optionID}` })
    }

    function isCreateCustomerButtonDissabled() {
        switch (newCustomer.type) {
            case CUSTOMER_TYPES.RESIDENTIAL.name:
                if (newCustomer.name) { return false } else { break; }
            case CUSTOMER_TYPES.COMMERCIAL.name:
                if (newCustomer?.name && isValidEmail(newCustomer?.email) && isValidPhoneNumber(newCustomer?.phone) && newCustomer.industryOptionId) { return false } else { break; }
            default: return true
        }
    }

    return <Box style={{ width: "100%" }} >
        <Box style={{ display: "flex", flexDirection: "column", width: "100%", gap: "1em" }} >
            <Box style={{ display: "flex", flexDirection: "row", width: "100%", }}>
                <CustomTextField style={{ minWidth: "30em", flexGrow: "2", marginRight: "0.5em" }} required={true} value={newCustomer?.name ?? ""} size="small" label="Customer Name"
                    onChange={(event) => {
                        setNewCustomer({ ...newCustomer, name: event.target.value });
                    }} />
                <CustomerTypesRadioButton isDisabled={isDisabled} options={Object.values(CUSTOMER_TYPES).map((c) => c.name)} value={(newCustomer?.type ?? "")}
                    onChange={(e, value) => {
                        e.preventDefault()
                        const customer = Object.values(CUSTOMER_TYPES).find((c) => c.name === value)
                        setNewCustomer({ ...newCustomer, type: value, typeOptionId: customer?.optionId })
                    }} />
            </Box>
            <Box style={{ display: "flex", flexDirection: "row", width: "100%", gap: "1em" }}>
                <CampaignSelection noOptionText={franchise ? "No Campaigns" : "Please select a franchise"} value={campaign} campaigns={campaigns} isCampaignsLoading={isCampaignsLoading} onChange={onCampaignChanged} />
                {(newCustomer?.type === CUSTOMER_TYPES.COMMERCIAL.name) ? <IndustrySelection franchise={franchise} noOptionText={franchise ? "Please select a franchise" : "No Industries"} isRequired={true} value={industry} industries={industries} isIndustriesLoading={isIndustriesLoading} onChange={onIndustryChanged} /> : <></>}
            </Box>
            {(newCustomer?.type !== CUSTOMER_TYPES.RESIDENTIAL.name) ?
                <Box style={{ display: "flex", flexDirection: "row", width: "100%", gap: "1em" }}>
                    {(newCustomer?.type !== CUSTOMER_TYPES.RESIDENTIAL.name) ? <EmailField required={true} disabled={false} value={newCustomer?.email} onChange={(e) => setNewCustomer({ ...newCustomer, email: e.target.value })} /> : <></>}
                    {(newCustomer?.type === CUSTOMER_TYPES.COMMERCIAL.name) ? <PhoneNumberField isRequired={true} isDisabled={false} value={newCustomer?.phone} onChange={(phone) => setNewCustomer({ ...newCustomer, phone: phone })} /> : <></>}
                </Box> : <></>}
            <Box style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "end" }}>
                <LoadingButton onClick={onCreateCustomerClicked} fullWidth={false} size={"small"} variant="contained" loading={isCreateCustomerLoading} className={isCreateCustomerLoading || isCreateCustomerButtonDissabled() ? "btn-disable" : "btn-primary"} >{"Create Customer"}</LoadingButton>
            </Box>
        </Box>
    </Box>
} 