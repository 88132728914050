import { Accordion, AccordionDetails, Box, Tooltip, Typography } from "@mui/material";
import React from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import StorefrontIcon from '@mui/icons-material/Storefront'; // eslint-disable-line
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionSummary } from "../../utils/CustomFaqStyles";
import { getById } from "../../../../../utils/miscellaneous";
import { useEffect, useMemo, useState } from "react";
import LoadingView from "../../../../../components/refreshView/LoadingView";
import NoContentView from "../../../../../components/refreshView/NoContentView";
import { handleFaqGetKeywords } from "../../../../../state-services/faq/faq";
import Hashtag from "../../../../../components/chips/HashTag";
export default function FaqCard({ i, expanded, setExpanded, faq, allFranchises, handleOnEdit, deleteOnClick, isFranchiseSelected, isEditEnabled = true, shouldShowFranchiseName = false }) {

    const [selectedKeywords, setSelectedKeywords] = useState([])
    const [isFaqKeywordsLoading, setIsFaqKeywordsLoading] = useState(false)
    const [isFaqKeywordsError, setIsFaqKeywordsError] = useState(false)
    const isExpanded = useMemo(() => expanded === 'panel' + i, [expanded, i])

    useEffect(() => {
        if (faq?.id && isExpanded) {
            handleFaqGetKeywords(faq?.id, (keywords) => setSelectedKeywords(keywords?.map?.((k) => k?.keyword)), setIsFaqKeywordsLoading, setIsFaqKeywordsError, faq?.id)
        } else {
            setSelectedKeywords([])
        }
    }, [faq?.id, isExpanded])

    function handleChange(event, isExpanded) {
        setExpanded(isExpanded ? `panel${i}` : false);
    };

    return <div className="accordian-item" key={i}>
        <Accordion expanded={isExpanded} onChange={handleChange}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon data-test={`faq_card_${faq?.id}_expand`} />} aria-controls="panel1bh-content" id="panel1bh-header"
                className="accordian-header faq-header"
            >
                <div className="question-wrapper">
                    {shouldShowFranchiseName &&
                        <small className="franchise-name">
                            <div style={{ marginBottom: "5px" }}> {getById(faq?.franchise_id, allFranchises)?.franchise_name ?? "-"} </div>
                        </small>
                    }
                    <Typography className="question" sx={{ width: '100%', flexShrink: 0 }}>
                        {faq.question.split('\n').map((line, index) => (
                            <React.Fragment key={index}>
                                {line}
                                <br />
                            </React.Fragment>
                        ))}
                    </Typography>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <Typography sx={{ width: '100%', flexShrink: 0 }}>
                    {faq.answer.split('\n').map((line, index) => (
                        <React.Fragment key={index}>
                            {line}
                            <br />
                        </React.Fragment>
                    ))}
                </Typography>
                <Box sx={{ width: "100%", marginTop: "1em", display: "flex", flexDirection: "row", alignItems: "center", flexWrap: "wrap" }} >
                    {selectedKeywords?.map?.((k, i) => <Hashtag key={i} label={k} />)}
                    {isFaqKeywordsLoading ? <LoadingView /> : <></>}
                    {isFaqKeywordsError ? <NoContentView message="Something went wrong" withBoarder={false} /> : <></>}
                </Box>
            </AccordionDetails>
            {isEditEnabled &&
                <div className="action-bar">
                    <Tooltip title="Edit">
                        <EditIcon data-test={`faq_card_${faq?.id}_edit`} color="info" className="action-icon" onClick={() => handleOnEdit(faq, getById(faq?.franchise_id, allFranchises)?.franchise_name ?? "-")} />
                    </Tooltip>
                    <Tooltip title="Delete">
                        <DeleteIcon data-test={`faq_card_${faq?.id}_delete`} color="error" className="action-icon" onClick={() => deleteOnClick(faq.id)} />
                    </Tooltip>
                </div>}
        </Accordion>
    </div>;
}
