import { Box } from "@mui/system";
import { useEffect, useMemo, useState } from "react";
import { retry, shouldRetry } from "../../../state-services-dublicate/serviceTitan";
import RefreshErrorView from "../../refreshView/RefreshErrorView"
import LoadingView from "../../refreshView/LoadingView";
import NoContentView from "../../refreshView/NoContentView";
import useDebounce from "../../../hooks/useDebounce";
import { getFranchiseCustomers } from "../../../services/serviceTitan";
import { isValidPhoneNumber } from "../../../utils/validators";
import { AVAILABLE_CRMS } from "../../../constants/common";
import CustomerTile from "./CustomerTile";
import { isFranchiseDisabled } from "../../../utils/miscellaneous";


export default function CustomerHistorySection(props) {
    const { filterationData, franchise, brand, customers, setCustomers } = props
    const [isCustomersLoading, setIsCustomersLoading] = useState(false)
    const [isCustomersLoadingError, setIsCustomersLoadingError] = useState(false)
    const [toggleGetCalls, setToggleGetCalls] = useState(false)
    const isServiceTitanFranchise = useMemo(() => {
        return franchise?.service_brand?.trim?.()?.toLowerCase?.() === AVAILABLE_CRMS.servicetitan.value.trim().toLowerCase()
    }, [franchise])
    const debouncedHandleGetCustomers = useDebounce(filterationData, (params) => {
        if (params.franchiseId && isServiceTitanFranchise && params.phoneNumber) handleGetCustomers(params.franchiseId, params.createdOnOrAfter, params.createdOnOrBefore, params.phoneNumber, params.page, params.pageSize, setCustomers, setIsCustomersLoading, setIsCustomersLoadingError)
    }, 1000)

    useEffect(() => {
        if (franchise?.id && isServiceTitanFranchise && filterationData.phoneNumber && !isFranchiseDisabled(franchise)) {
            setIsCustomersLoading(true)
            debouncedHandleGetCustomers({ ...filterationData, franchiseId: franchise?.id })
        } else {
            setCustomers([])
        }
    }, [franchise, filterationData, toggleGetCalls, isServiceTitanFranchise, debouncedHandleGetCustomers])

    function getCustomerHistoryBody() {
        if (!isServiceTitanFranchise) {
            return <NoContentView message={"Please select a Servicetitan franchise"} />
        } else if (isFranchiseDisabled(franchise)) {
            return <NoContentView message={"Selected franchise does not have Servicetitan credentials"} />
        } else if (isCustomersLoadingError) {
            return <RefreshErrorView onRefresh={() => setToggleGetCalls(!toggleGetCalls)} />
        } else if (isCustomersLoading) {
            return <LoadingView />
        } else if (customers?.length === 0) {
            return <NoContentView message={isValidPhoneNumber(filterationData.phoneNumber) ? "No customers to show" : "Please enter valid phone number to search"} />
        } else {
            return customers.map((c) => <CustomerTile key={c.id} franchise={franchise} brand={brand} customer={c} />)
        }
    }

    return <Box marginBlock="1em" width="100%" display="flex" flexDirection="column" gap="0.5em">
        {getCustomerHistoryBody()}
    </Box >
}

async function handleGetCustomers(franchiseId = 0, createdOnOrAfter = "", createdOnOrBefore = "", phoneNumber = "", page = 1, pageSize = 50, setCustomers, setIsCustomersLoading, setIsCustomersLoadingError, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    try {
        if (!isValidPhoneNumber(phoneNumber) && phoneNumber !== "") return
        setIsCustomersLoadingError(false)
        setIsCustomersLoading(true)
        const res = await getFranchiseCustomers(franchiseId, phoneNumber, "", page, pageSize, "", createdOnOrAfter, createdOnOrBefore)
        if (Array.isArray(res.data)) {
            setCustomers(res.data)
        }
    } catch (err) {
        if (shouldRetry(backoffConfig) && err?.status !== 400) {
            retry(() => handleGetCustomers(franchiseId, createdOnOrAfter, createdOnOrBefore, phoneNumber, page, pageSize, setCustomers, setIsCustomersLoading, setIsCustomersLoadingError, backoffConfig), backoffConfig)
        } else {
            setIsCustomersLoadingError(true)
        }
    } finally {
        setIsCustomersLoading(false)
    }
}