import { Box, Button, IconButton, Tooltip } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import React, { useState } from 'react'
import AddressAutoComplete from '../../../../../../../components/AddressAutoComplete'
import AddressBox from '../../../../../../../components/AddressBox'
import LoadingButton from '../../../../../../../components/LoadingButton'
import { AVAILABLE_CRMS, COUNTRIES, NOTIFICATION_TYPES } from '../../../../../../../constants/common'
import { MAX_FRANCHISE_LOCATION_RADIUS } from '../../../../../../../constants/location'
import { addLeadToRedisSearch } from '../../../../../../../services/globalSearch'
import { updateFranchiseCustomer, updateServiceTitanMiddlewareLead } from '../../../../../../../services/serviceTitan'

export default function BillingAddressSection({ franchise, selectedCustomerContacts, selectedLeadToEdit, states, selectedCustomer, setSelectedCustomer, showNotification }) {

    const [editBillingAddress, setEditBillingAddress] = useState(null)
    const [onEditBillingAdressLoading, setOnEditBillingAddressLoading] = useState(false)

    function onBillingAdressEditPressed() {
        setEditBillingAddress(selectedCustomer)
    }

    function onEditBillingAdressCancelPressed() {
        setEditBillingAddress(null)
    }

    function onEditDone(editedCustome) {
        setSelectedCustomer(editedCustome)
        showNotification({ message: "Successfully saved", type: NOTIFICATION_TYPES.SUCCESS })
        setEditBillingAddress(null)
    }

    function onErrorEditBillingAddress(err) {
        let errDetails = JSON.parse(err.responseText)
        let errCode = errDetails?.data?.ErrorCode
        let stateErr
        if (errCode === "StateNotValid") {
            stateErr = "State doesn't valid with country"
        } else if (errCode === "CountryNotValid") {
            stateErr = "Country is not valid"
        } else {
            stateErr = ""
        }
        showNotification({ message: `Important: Could not edit the billing address. ${stateErr}. Please try again`, type: NOTIFICATION_TYPES.ERROR })
    }

    async function handleEditBillingAddress(franchise, editedCustomer, setIsEditedBillingAddressLoading, onDone, onError) {
        try {
            setIsEditedBillingAddressLoading(true)
            const res = await updateFranchiseCustomer(franchise.id, editedCustomer.id, editedCustomer)
            onDone(res)
            await updateServiceTitanMiddlewareLead({ customerId: editedCustomer?.id, customerName: editedCustomer?.name })
            let updatedLeadDetails = {
                id: selectedLeadToEdit?.middlewareServiceTitanLead?.id,
                leadId: selectedLeadToEdit?.middlewareServiceTitanLead?.leadId,
                customerName: editedCustomer?.name,
                customerContacts: selectedCustomerContacts,
            }
            await addLeadToRedisSearch(updatedLeadDetails)
        } catch (err) {
            onError(err)
        } finally {
            setIsEditedBillingAddressLoading(false)
        }
    }

    function onAddressChanged(address) {
        setEditBillingAddress({ ...editBillingAddress, address: address })
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: "column", gap: "0.5em" }}>
            <Box sx={{ display: 'flex', flexDirection: "row", width: "100%", height: "3em", justifyContent: "left", alignItems: "center" }}>
                <Box sx={{ display: "flex", flexDirection: "row", minWidth: "200px" }}>
                    <h4>Billing Address</h4>
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                        <Tooltip title="Edit Customer" ><IconButton onClick={onBillingAdressEditPressed} disabled={editBillingAddress !== null} size="small" ><EditIcon color={!editBillingAddress ? "primary" : "disabled"} /></IconButton></Tooltip>
                    </Box>
                </Box>
            </Box>
            {!editBillingAddress ?
                <ExistingBillingAddressSection states={states} selectedCustomer={selectedCustomer} />
                :
                <EditBillingAddressSection franchise={franchise} onAddressChanged={onAddressChanged} states={states} editBillingAddress={editBillingAddress} setEditBillingAddress={setEditBillingAddress}
                    onEditCancel={onEditBillingAdressCancelPressed} onEditDone={onEditDone} handleEditBillingAdress={handleEditBillingAddress} isEditBillingAddressLoading={onEditBillingAdressLoading} setIsEditBillingAddressLoading={setOnEditBillingAddressLoading} onErrorEditBillingAddress={onErrorEditBillingAddress} />
            }
        </Box>
    )
}

function ExistingBillingAddressSection({ states, selectedCustomer }) {
    return (
        <Box >
            <Box sx={{ display: 'flex', flexDirection: "row", flexWrap: "wrap", width: "100%", marginRight: "1em", gap: "1em" }}>
                <Box sx={{ display: 'flex', flexDirection: "column", flexWrap: "wrap", gap: "1em", width: "100%" }}>
                    <Box sx={{ display: 'flex', flexDirection: "raw", flexWrap: "wrap", gap: "1em", width: "100%" }}>
                        <AddressBox address={selectedCustomer?.address} isNotLeadSection={false} isDisabled={true} isRequired={false} isSelectableStates={false} countries={COUNTRIES} states={states} />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

function EditBillingAddressSection({ franchise, states, onAddressChanged, editBillingAddress, setEditBillingAddress, onEditCancel, onEditDone, handleEditBillingAdress, isEditBillingAddressLoading, setIsEditBillingAddressLoading, onErrorEditBillingAddress }) {

    const [isValidZipCode, setIsValidZipCode] = useState(true)

    return (
        <Box >
            <Box sx={{ display: 'flex', flexDirection: "row", flexWrap: "wrap", width: "100%", marginRight: "1em", gap: "1em" }}>
                <Box sx={{ display: 'flex', flexDirection: "column", flexWrap: "wrap", gap: "1em", width: "100%" }}>
                    <AddressAutoComplete label={"Search Location Address"} onChange={onAddressChanged} lat={franchise?.latitude} long={franchise?.longitude} radius={MAX_FRANCHISE_LOCATION_RADIUS} crm={AVAILABLE_CRMS.servicetitan.value} />
                    <Box sx={{ display: 'flex', flexDirection: "raw", flexWrap: "wrap", gap: "1em", width: "100%" }}>
                        <AddressBox address={editBillingAddress?.address} onChangeAddress={onAddressChanged} setIsValidZipCode={setIsValidZipCode} isDisabled={false} isRequired={false} isSelectableStates={false} countries={COUNTRIES} states={states} />
                    </Box>
                </Box>
                <Box sx={{ marginLeft: "auto" }} >
                    <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "right", gap: "1em" }} >
                        <Button size={"small"} variant="contained" onClick={onEditCancel} className={"btn-secondary"} >Cancel</Button>
                        <LoadingButton
                            onClick={() => { handleEditBillingAdress(franchise, editBillingAddress, setIsEditBillingAddressLoading, onEditDone, onErrorEditBillingAddress) }}
                            size={"small"}
                            variant="contained"
                            disabled={isEditBillingAddressLoading || !isValidZipCode || editBillingAddress?.address?.unit?.length > 50}
                            loading={isEditBillingAddressLoading}
                            className={isEditBillingAddressLoading ? "btn-disable" : "btn-primary"} >
                            {"Update Billing Address"}
                        </LoadingButton>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
