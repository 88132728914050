import './styles/index.css';
import { useContext, useState } from "react";
import { withRouter } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import FaqSection from './FaqSection';
import KeywordSection from './KewordSection';
import { UserContext } from '../../../contexts/User';

const FAQ_SECTIONS = {
    FAQ: {
        index: 0,
        section: "faq",
        title: "FAQ",
        userLevel: 40
    },
    KEYWORDS: {
        index: 1,
        section: "keywords",
        title: "Keyword",
        userLevel: 80
    }
}

function Faq() {
    const { me } = useContext(UserContext)
    const [faqSection, setFaqSection] = useState(FAQ_SECTIONS.FAQ)

    function getSection(section) {
        switch (section.index) {
            case FAQ_SECTIONS.FAQ.index:
                return <FaqSection />
            case FAQ_SECTIONS.KEYWORDS.index:
                return <KeywordSection />
            default:
                <></>
        }
    }

    return (
        <Box className="dashboard" sx={{ flexGrow: 1 }}>
            <Grid container >
                <Box className="tabs" sx={{ paddingInline: "0.5em", marginTop: "0.5em" }}>
                    {Object.values(FAQ_SECTIONS).filter((s) => s?.userLevel <= me?.level).map((t) => {
                        return <Button
                            key={t.index}
                            onClick={() => setFaqSection(t)}
                            size={"small"}
                            variant="contained"
                            className={faqSection?.section === t.section ? "btn-primary tab" : "btn-secondary tab"}
                        >{t.title}</Button>;
                    })}
                </Box>
                {getSection(faqSection)}
            </Grid>
        </Box>
    );
}

export default withRouter(Faq);

