import { Box, CircularProgress, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { getTimeZonedDate } from "../../../../utils/time/time";
import { useContext, useEffect, useMemo, useState } from "react";
import { getById, getMatchingBrandByBrandId } from "../../../../utils/miscellaneous";
import { CommonContext } from "../../../../contexts/CommonContextProvider";
import { handleGetBrandFranchises } from "../../../../state-services/franchise";
import { handleGetAngiesLeadById, handleGetAngiesLeads } from "../../../../state-services/angiesLeads/angiesLeads";
import VisibilityIcon from '@mui/icons-material/Visibility';
import FiltrationArea from "./components/FiltrationArea";
import ViewLeadModal from "./components/ViewLeadModal";
import iBSBrandLogo from "../../../../assets/img/brand.png"
import PhoneCard from "../../../../components/servicetitan/PhoneCard";
import ErrorView from "../../../../components/ErrorView";
import { ERROR_COMPONENTS } from "../../../../constants/common";
import { UserContext } from "../../../../contexts/User";
import PleaseSelectBrandAndFranchise from "../../../../components/PleaesSelectBrandAndFranchise";
import CustomTextField from "../../../../components/CustomTextField";
import { useLocation } from "react-router-dom";
import useDebounce from "../../../../hooks/useDebounce";
import ImageBox from "../../../../components/LogoBox/ImageBox";
import moment from "moment"
import { Link } from "react-router-dom";

export default function AngiesListLeads() {

    const { brands, isBrandsLoading, socketMessage, franchises, showNotification, generalTenantConfiguration } = useContext(CommonContext)

    const INITIAL_FILTRATION = {
        brandId: 0,
        franchiseId: 0,
        brandBrandId: 0,
        createdOnOrAfter: generalTenantConfiguration?.time_zone ? moment().tz(generalTenantConfiguration?.time_zone)?.startOf?.("day")?.toISOString?.() : moment()?.startOf?.("day")?.toISOString?.(),
        createdOnOrBefore: generalTenantConfiguration?.time_zone ? moment().tz(generalTenantConfiguration?.time_zone)?.endOf?.("day")?.toISOString?.() : moment()?.endOf?.("day")?.toISOString?.(),
        page: 1,
        pageSize: 50,
        name: ""
    }

    function refreshFiltration(oldFiltration = {}) {
        return {
            ...oldFiltration,
            createdOnOrAfter: generalTenantConfiguration?.time_zone ? moment().tz(generalTenantConfiguration?.time_zone)?.startOf?.("day")?.toISOString?.() : moment()?.startOf?.("day")?.toISOString?.(),
            createdOnOrBefore: generalTenantConfiguration?.time_zone ? moment().tz(generalTenantConfiguration?.time_zone)?.endOf?.("day")?.toISOString?.() : moment()?.endOf?.("day")?.toISOString?.(),
        }
    }


    const location = useLocation();
    const urlParams = useMemo(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');
        return {
            id: id ?? ""
        }
    }, [location])
    const { me } = useContext(UserContext)

    const [filtration, setFiltration] = useState(refreshFiltration(INITIAL_FILTRATION))
    const [brandFranchises, setBrandFranchises] = useState([])
    const [isFranchisesLoading, setIsFranchisesLoading] = useState(false)
    const [leadRes, setLeadRes] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [selectedLedToView, setSelectedLeadToView] = useState()
    const debouncedHandleGetBrandFranchises = useDebounce({ level: me?.level, name: filtration?.name, brandBrandId: filtration.brandBrandId, franchiseId: filtration.franchiseId, createdOnOrAfter: filtration.createdOnOrAfter, createdOnOrBefore: filtration.createdOnOrBefore, page: filtration.page, pageSize: filtration.pageSize, setLeadRes: setLeadRes, setIsLoading: setIsLoading, setIsError: setIsError }, ({ level, name, brandBrandId, franchiseId, createdOnOrAfter, createdOnOrBefore, page, pageSize, setLeadRes, setIsLoading, setIsError }) => {
        if (level <= 40 && !brandBrandId) {
            setLeadRes({})
            setIsLoading(false)
            return
        } else {
            handleGetAngiesLeads(name, brandBrandId, franchiseId, createdOnOrAfter, createdOnOrBefore, page, pageSize, setLeadRes, setIsLoading, setIsError)
        }
    }, 1000)

    useEffect(() => {
        if (socketMessage?.title) {
            const timeOut = setTimeout(setFiltration(refreshFiltration), 2000)
            return () => clearTimeout(timeOut)
        }
    }, [socketMessage])

    useEffect(() => {
        handleGetAngiesLeadById(urlParams.id, setSelectedLeadToView, showNotification)
    }, [urlParams.id])

    useEffect(() => {
        handleGetBrandFranchises(filtration?.brandBrandId, setBrandFranchises, setIsFranchisesLoading)
    }, [filtration?.brandBrandId])

    useEffect(() => {
        setIsLoading(true)
        debouncedHandleGetBrandFranchises({ level: me?.level, name: filtration?.name, brandBrandId: filtration.brandBrandId, franchiseId: filtration.franchiseId, createdOnOrAfter: filtration.createdOnOrAfter, createdOnOrBefore: filtration.createdOnOrBefore, page: filtration.page, pageSize: filtration.pageSize, setLeadRes: setLeadRes, setIsLoading: setIsLoading, setIsError: setIsError })
    }, [me?.level, filtration.brandBrandId, filtration.franchiseId, filtration.createdOnOrAfter, filtration.createdOnOrBefore, filtration.page, filtration.pageSize, filtration.name, debouncedHandleGetBrandFranchises])

    return <Box sx={{ height: "auto", display: "flex", flexDirection: "column" }} >
        <div style={{ marginBottom: "1em", display: "flex", flexDirection: 'row', flexWrap: "wrap", justifyContent: "end", gap: "1em" }}>
            <CustomTextField
                label={"Search by name"}
                size={"small"}
                style={{ width: "200px" }}
                variant="outlined"
                type="search"
                value={filtration.name}
                onChange={(event) => {
                    let newFiltration = { ...filtration, name: event.target.value }
                    if (event.target.value) {
                        newFiltration.brandId = undefined
                        newFiltration.brandBrandId = undefined
                        newFiltration.franchiseId = undefined
                        newFiltration.createdOnOrAfter = undefined
                        newFiltration.createdOnOrBefore = undefined
                    } else {
                        newFiltration = refreshFiltration(newFiltration)
                    }
                    setFiltration(newFiltration)
                }}
            />
        </div>
        <FiltrationArea filtration={filtration} setFiltration={(filter) => setFiltration({ ...filter, page: 1 })} brands={brands} isBrandsLoading={isBrandsLoading} franchises={brandFranchises} isFranchisesLoading={isFranchisesLoading} />
        {me?.level <= 40 && !filtration?.brandId ? <Box sx={{ marginTop: "8em" }} ><PleaseSelectBrandAndFranchise message={"Please select a brand"} /></Box>
            : <TableContainer sx={{ flexGrow: 2, display: "flex", flexDirection: "column", marginTop: "0.3em" }}>
                <Table aria-label="simple table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell className="table-header" style={{ textAlign: "left" }} >#</TableCell>
                            <TableCell className="table-header" style={{ textAlign: "left" }} >ST Lead ID</TableCell>
                            <TableCell className="table-header" style={{ textAlign: "left" }} >Lead OID</TableCell>
                            <TableCell className="table-header" style={{ textAlign: "left" }} >Brand</TableCell>
                            <TableCell className="table-header" style={{ maxWidth: "250px", textAlign: "left", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}  >Franchise</TableCell>
                            <TableCell className="table-header" style={{ maxWidth: "250px", textAlign: "left", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}  >Full Name</TableCell>
                            <TableCell className="table-header" style={{ textAlign: "left" }}  >Phone</TableCell>
                            <TableCell className="table-header" style={{ textAlign: "left" }}  >ZIP/Postal Code</TableCell>
                            <TableCell className="table-header" style={{ textAlign: "left" }}  >Contact Status</TableCell>
                            <TableCell className="table-header" style={{ textAlign: "left" }} >Appointment Status</TableCell>
                            <TableCell className="table-header" style={{ textAlign: "left" }} >Created Date & Time</TableCell>
                            <TableCell className="table-header" style={{ textAlign: "left", minWidth: "100px" }} >Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    {isLoading ? buildLoadingView()
                        : isError ? buildErrorView()
                            : !leadRes?.leads?.length ? buildNoLeadsToShowView() : <TableBody sx={{ flexGrow: 2 }}>
                                {leadRes?.leads?.map?.((lead, index) => {
                                    const brand = getMatchingBrandByBrandId(lead.brand_id, brands)
                                    const franchise = getById(lead?.franchise_id, franchises)
                                    return <TableRow>
                                        <TableCell className="table-header" style={{ textAlign: "left" }} >{index + 1}</TableCell>
                                        <TableCell className="table-header" style={{ textAlign: "left" }}>                      
                                            {lead?.middleware_lead_id ? (
                                                <Tooltip title="View Servicetitan Lead">
                                                    <Link to={`/leads?section=servicetitan&id=${lead.middleware_lead_id}`} style={{ color: 'var(--primary-500)', textDecoration: 'none', fontWeight: 'bold'}} >
                                                        {lead.servicetitan_lead_id}
                                                    </Link>
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title="No Servicetitan lead created">
                                                    <span style={{ cursor: 'pointer' }}>N/A</span>
                                                </Tooltip>
                                            )}
                                        </TableCell>
                                        <TableCell className="table-header" style={{ textAlign: "left" }} >{lead?.lead_oid}</TableCell>
                                        <TableCell className="table-cell" >
                                            <ImageBox alt="brand-logo" src={getMatchingBrandByBrandId(lead.brand_id, brands)?.brand_img ?? iBSBrandLogo} />
                                        </TableCell>
                                        <TableCell className="table-header" style={{ maxWidth: "250px", textAlign: "left", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", textDecorationLine: franchise?.is_removed === 1 ? " line-through" : "" }}  >{franchise?.franchise_name ?? "[ Not Selected ]"}</TableCell>
                                        <TableCell className="table-header" style={{ maxWidth: "250px", textAlign: "left", minWidth: "200px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}  >{lead.name ? lead.name : "-"}</TableCell>
                                        <TableCell className="table-header" style={{ textAlign: "left", minWidth: "200px" }}  >{lead.primary_phone ? <PhoneCard phone={lead.primary_phone} franchiseId={franchise?.id} brandId={brand?.id} contentFontSize="14px" /> : <Typography>-</Typography>}</TableCell>
                                        <TableCell className="table-header" style={{ textAlign: "left" }}  >{lead.postal_code ? lead.postal_code : "-"}</TableCell>
                                        <TableCell className="table-header" style={{ textAlign: "left", maxWidth: "150px" }}  >{lead.contact_status ? lead.contact_status : "-"}</TableCell>
                                        <TableCell className="table-header" style={{ textAlign: "left" }} >{lead?.appointment?.status ? lead?.appointment?.status : "-"}</TableCell>
                                        <TableCell className="table-header" style={{ textAlign: "left" }} >{lead?.created_at ? getTimeZonedDate(lead?.created_at, generalTenantConfiguration?.time_zone)?.format('MM/DD/YYYY - h:mm A') : "-"} </TableCell>
                                        <TableCell className="table-header" style={{ textAlign: "left", minWidth: "100px" }} ><IconButton onClick={() => setSelectedLeadToView(lead)} color="primary" size={"medium"} style={{ marginRight: "10px" }}>
                                            <Tooltip title={"View Lead"}><VisibilityIcon style={{ height: "20px", width: "20px" }} /></Tooltip>
                                        </IconButton></TableCell>
                                    </TableRow>

                                })}
                            </TableBody>}
                </Table>
            </TableContainer>
        }
        <Box flexGrow={2} />
        <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "end" }}>
            <Pagination onChange={(e, v) => {
                setFiltration({ ...filtration, page: v })
            }} color="standard" count={filtration.universalSearchString ? 1 : Math.ceil(leadRes?.total / filtration.pageSize)} sx={{ alignSelf: "right" }}
            />
        </div>
        {selectedLedToView ? <ViewLeadModal open={Boolean(selectedLedToView)} lead={selectedLedToView} franchise={getById(selectedLedToView?.franchise_id, franchises)} brand={getMatchingBrandByBrandId(selectedLedToView?.brand_id, brands)} onClose={() => setSelectedLeadToView()} /> : <></>}
    </Box >
}

function buildErrorView() {
    return buildInformationRowContainer(<ErrorView type={ERROR_COMPONENTS.SomthingWentWrong.type} />)
}

function buildNoLeadsToShowView() {
    return buildInformationRowContainer(<ErrorView type={ERROR_COMPONENTS.NoLeadsFound.type} />)
}

function buildLoadingView() {
    return buildInformationRowContainer(<CircularProgress size={30} color="inherit" />)
}

function buildInformationRowContainer(children) {
    return <TableRow><TableCell colSpan={10} align="center" style={{ border: "none", height: "300px", color: "grey" }}>
        {children}
    </TableCell></TableRow>;
}