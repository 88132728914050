import { Box } from "@mui/material"
import { useEffect, useState } from "react"
import ContactSection from "./ContactSection"
import CreateNewCustomer from "./CreateNewCustomer"
import CustomerLocationDetails from "./CustomerLocationDetails"
import EditJobCustomerDetails from "./EditJobCustomerDetails"
import SelectExistingCustomer from "./SelectExistingCustomer"

export default function CustomerDetailsSection(props) {
    const { isNewCustomer,isDisabled,setIsNewCustomer, brand, franchise, client, selectedJobToEdit, locations, setLocations, location, setLocation, contacts, setContacts, contact, setContact, existingCustomers, setExistingCustomers, selectedCustomer, setSelectedCustomer, isExistingCustomersLoading, onClientChanged, onLocationChanged, onContactChanged, showNotification } = props
    const [isNewLocation, setIsNewLocation] = useState(isNewCustomer)
    const [isNewContact, setIsNewContact] = useState(isNewCustomer)
    useEffect(() => {
        if (isNewCustomer) {
            setIsNewLocation(true)
            setIsNewContact(true)
        }
    }, [isNewCustomer])

    useEffect(() => {
        onClientChanged(selectedCustomer)
    }, [selectedCustomer])

    function onNewCustomerCreated(customer) {
        setExistingCustomers([customer, ...existingCustomers]);
        setSelectedCustomer(customer);
        setIsNewCustomer(false);
    }

    return <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }} >
        {!selectedJobToEdit ? isNewCustomer ? <CreateNewCustomer client={client} brand={brand} franchise={franchise} setContacts={setContacts} setLocations={setLocations} isDisabled={!isNewCustomer} onNewCustomerCreated={onNewCustomerCreated} showNotification={showNotification} /> : <SelectExistingCustomer franchise={franchise} isRequired={!isNewCustomer} existingCustomers={existingCustomers} isExistingCustomersLoading={isExistingCustomersLoading} selectedCustomer={selectedCustomer} setSelectedCustomer={setSelectedCustomer} /> : <></>}
        {selectedJobToEdit ? <EditJobCustomerDetails isDisabled={isDisabled} brand={brand} franchise={franchise} client={client} /> : <></>}
        {!selectedJobToEdit ? <CustomerLocationDetails client={client}  selectedJobToEdit={selectedJobToEdit} isNewCustomer={isNewCustomer} brand={brand} franchise={franchise} selectedCustomer={selectedCustomer} isNewLocation={isNewLocation} setIsNewLocation={setIsNewLocation} location={location} setLocation={setLocation} locations={locations} setLocations={setLocations} onLocationChanged={(location) => { setLocation(location); onLocationChanged(location) }} showNotification={showNotification} /> : <></>}
        {!selectedJobToEdit ? <ContactSection client={client} selectedJobToEdit={selectedJobToEdit} brand={brand} franchise={franchise} isNewCustomer={isNewCustomer} isNewContact={isNewContact} contact={contact} setContact={setContact} contacts={contacts} setContacts={setContacts} setIsNewContact={setIsNewContact} isDisabled={false} selectedCustomer={selectedCustomer} onContactChanged={(contact) => { setContact(contact); onContactChanged(contact) }} showNotification={showNotification} /> : <></>}
    </Box>

}

export const CUSTOMER_TYPES = { RESIDENTIAL: { name: "Residential", optionId: "59" }, COMMERCIAL: { name: "Commercial", optionId: "60" } }
export const INITIAL_CUSTOMER_VALUES = { type: CUSTOMER_TYPES.RESIDENTIAL.name, typeOptionId: CUSTOMER_TYPES.RESIDENTIAL.optionId } 
