import { Button, Dialog } from '@mui/material'
import React from 'react'

export default function ScreenMonitoringTimeOutMessage({ handleCloseTimeOutDialog, handleReconnect, open }) {

    const handleClose = () => {
        handleCloseTimeOutDialog()
    }

    return (
        <Dialog open={open}>
            <div className={"dialog"}>
                <h3 className={"dialog-title"}>Agent Screen Monitoring Time Out</h3>
                <div style={{ display: "flex", justifyContent: "end" }}>
                    <Button
                        size={"small"}
                        variant="contained"
                        onClick={handleClose}
                        className={"btn-secondary"}
                        style={{ marginRight: "10px" }}
                    >
                        Cancel
                    </Button>
                    <Button
                        size={"small"}
                        variant="contained"
                        onClick={() => { handleReconnect() }}
                        className={"btn-primary"}
                    >
                        Reconnect
                    </Button>
                </div>
            </div>
        </Dialog>
    )
}
