import moment from "moment";
import { Paper, Typography, Tooltip, Box } from "@mui/material"
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';

export default function NoteCard(props) {
    const { note } = props;
    return <Paper key={note?.createdOn} style={{ display: "flex", flexDirection: "column", borderRadius: "0.5em", border: "1px solid gray", borderLeft: "4px solid #00a79d", margin: "0.5em", padding: "1em", }}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "start", gap: "1em" }}>
            <Box sx={{ inlineSize: '100%', overflowX: 'auto', overflowWrap: 'break-word' }} flexGrow={2} ><Typography style={{ marginLeft: "1em" }}>{note.text}</Typography></Box>
            <Typography minWidth={"11em"} variant="subtitle2" style={{ color: "GrayText", marginLeft: "auto", wordWrap: "normal" }}>{moment(note.createdOn).format('MM/DD/YYYY - h:mm A')}</Typography>
            <Tooltip title={note.isPinned ? "Pinned" : "Unpinned"}>{note.isPinned ? <PushPinIcon /> : <PushPinOutlinedIcon />}</Tooltip>
        </div>
    </Paper >
}