import { useContext, useEffect, useMemo, useRef, useState } from "react";


import Box from "@mui/material/Box";
import { FormControl, Dialog, Button, Autocomplete, Radio, RadioGroup, FormControlLabel, Checkbox, Tooltip, Typography, Container, Collapse } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import CustomTextField from "../../../../../components/CustomTextField";
import ContactBox from "../../../../../components/ContactBox";
import AddressBox from "../../../../../components/AddressBox";
import PhoneNumberBox from "../../../../../components/PhoneNumberBox";
import AddressAutoComplete from "../../../../../components/AddressAutoComplete";

import { createLead, deleteServiceTitanJobDraft, editLead } from "../../../../../services/serviceTitan"

import { isContactsValid, isContactValid } from "../../../../../utils/validators";
import { getCredentialsMissingWarning, getIsSTCredentialsAvailable, isContactExists } from "../../../../../utils/miscellaneous"

import { NOTIFICATION_TYPES, COUNTRIES, AVAILABLE_CRMS } from "../../../../../constants/common";
import { CONTACTS_TYPES, MIDDLEWARE_FOLLOW_UP_STAGES } from "../../../../../constants/serviceTitan"
import { handleGetAllBrands } from "../../../../../state-services-dublicate/brandServices";
import { handleGetBusinessUnits, handleGetCallReasons, handleGetCampaigns, handleGetCustomerById, handleGetCustomerContacts, handleGetJobTypes, handleGetLocationById, handleGetSelectedCustomerLocations, handleGetServiceTitanFranchisesByBrandId, handleGetTagTypes, handleSearchCustomer, resetFranchiseDependantData } from "../../../../../state-services-dublicate/serviceTitan";
import DialogHeader from "../../../../../components/dialog/DialogHeader";
import DialogBody from "../../../../../components/dialog/DialogBody";

import { MAX_FRANCHISE_LOCATION_RADIUS } from "../../../../../constants/location";
import { renderBrandOption } from "../../../../../components/servicetitan/RenderInput";
import CustomDatePicker from "../../../../../components/CustomDatePicker";
import { TEMPORY_FAILURE } from "../../../../../constants/errorMessages";
import { EncryptionWarningBadge } from "../../../../../components/EncryptionWarningBadge";
import LoadingButton from "../../../../../components/LoadingButton";
import { handleGetAllStates } from "../../../../../state-services/configuration/configuration";
import { CommonContext } from "../../../../../contexts/CommonContextProvider";
import { getMatchingTimezoneByName } from "../../../../../utils/timezone/timezone";
import { updateJob, mapCallJobLead } from "../../../../../services/jobService";
import { LOCAL_STORAGE_KEYS } from "../../../../../constants/browserStorage";
import NewCustomerCreateConfirmModal from "../../../jobs/create-job-page/NewCustomerCreateConfirmModal";
import LocationConfirmModal from "../../../jobs/create-job-page/LocationConfirmModal";
import { shouldFilterFor360Paintings } from "../../../jobs/serviceTitanJobs/components/CreateJobSection";
import { checkIfContactCanBeDeleted } from "../../../jobs/create-job-page/ContactsSection";
import { LeadSectionNotes } from "./LeadSectionNotes";
import { addServiceTitanLeadSpecificNote } from "../../../../../services/serviceTitan";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const CUSTOMER_TYPES = ["Residential", "Commercial"]
const PRIORITIES = ["Urgent", "High", "Normal", "Low"]
const CUSTOMER_INITIAL_CONTACT = { type: "", value: "", memo: "" }
const CUSTOMER_INITIAL_CONTACTS = [{ type: CONTACTS_TYPES[0], value: "", memo: "" }, { type: CONTACTS_TYPES[1], value: "", memo: "" }]

export default function CreateLeadModal(props) {

    const urlParams = new URLSearchParams(window.location.search);
    const isFromCallWindow = urlParams.get("isFromCallWindow");

    let { selectedFranchise, showNotification, onClose, open, selectedBrandId, selectedFranchiseId, selectedZipCode, selectedTenantId, selectedPhoneNumber, selectedLeadToEdit, selectedDraftToContinoue, isAddNewLeadButtonClick } = props;
    const existingLead = useMemo(() => selectedLeadToEdit?.leadResponse ?? selectedDraftToContinoue?.jobCreateRequest ?? {}, [selectedLeadToEdit, selectedDraftToContinoue])
    if (selectedPhoneNumber?.length >= 10) {
        selectedPhoneNumber = selectedPhoneNumber ? selectedPhoneNumber.substr(selectedPhoneNumber.length - 10) : undefined;
    } else {
        selectedPhoneNumber = undefined;
    }
    if (selectedLeadToEdit?.leadResponse) {
        selectedBrandId = selectedLeadToEdit?.brandId;
        selectedFranchiseId = selectedLeadToEdit?.franchiseId;
        selectedTenantId = selectedLeadToEdit?.middlewareServiceTitanLead?.tenantId
    } else if (selectedDraftToContinoue) {
        selectedFranchiseId = selectedDraftToContinoue?.franchiseId;
    }
    const handleClose = (lead, isNewLead = true) => onClose(lead, isNewLead)

    const { generalTenantConfiguration, timezones } = useContext(CommonContext)

    const [brands, setBrands] = useState([]);
    const [franchises, setFranchises] = useState(selectedFranchise ? [selectedFranchise] : []);
    const [isEncryptionFailed, setIsEncyptionFailed] = useState(false)
    const [brand, setBrand] = useState()
    const [franchise, setFranchise] = useState(selectedFranchise)
    const [customerPhoneNumber, setCustomerPhoneNumber] = useState(selectedPhoneNumber ?? existingLead?.callerId ?? undefined);
    const [customerName, setCustomerName] = useState()

    const [campaigns, setCampaigns] = useState([]);
    const [campaign, setCampaign] = useState();
    const [businessUnits, setBusinessUnits] = useState([]);
    const [businessUnit, setBusinessUnit] = useState();
    const [jobTypes, setJobTypes] = useState([]);
    const [jobType, setJobType] = useState();
    const [callReasons, setCallReasons] = useState([]);
    const [callReason, setCallReason] = useState();
    const [tagTypes, setTagTypes] = useState([]);
    const [selectedTagTypes, setSelectedTagTypes] = useState([]);
    const [priority, setPriority] = useState(existingLead?.priority ?? PRIORITIES[2]);
    const [followUpDate, setFollowUpDate] = useState();
    const [summary, setSummary] = useState(existingLead?.summary ?? "");

    const [franchiseSearchCustomers, setFranchiseSearchCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState();
    const [selectedCustomerLocations, setSelectedCustomerLocations] = useState([]);
    const [selectedCustomerSelectedLocation, setSelectedCustomerSelectedLocation] = useState();
    const [selectedCustomerContacts, setSelectedCustomerContacts] = useState([]);
    const [newCustomer, setNewCustomer] = useState(selectedDraftToContinoue?.createCustomerRequest);
    const [newCustomerAddress, setNewCustomerAddress] = useState(selectedDraftToContinoue?.createCustomerRequest?.address);
    const [newCustomerLocation, setNewCustomerLocation] = useState(selectedDraftToContinoue?.createCustomerRequest?.locations?.[0]);
    const [existingCustomerNewLocation, setExistingCustomerNewLocation] = useState(selectedDraftToContinoue?.createCustomerRequest?.locations?.[0] ?? {});
    const [newCustomerContacts, setNewCustomerContacts] = useState(setInitialContacts(selectedDraftToContinoue))

    const [isNewCustomer, setIsNewCustomer] = useState(selectedDraftToContinoue ? selectedDraftToContinoue?.isNewCustomer : false);
    const [shouldAddNewLocationToExistingCustomer, setShouldAddNewLocationToExistingCustomer] = useState(selectedDraftToContinoue ? selectedDraftToContinoue?.isNewLocation : false);
    const shouldCreateLeadWithoutCustomer = useRef(false);
    const [isCreateLeadWithoutCustomerDialogOpen, setIsCreateLeadWithoutCustomerDialogOpen] = useState(false);
    const shouldCreateLeadWithoutFollowUpdate = useRef(false);
    const [isCreateLeadWithoutFollowUpDateDialogOpen, setIsCreateLeadWithoutFollowUpDateDialogOpen] = useState(false);
    const [isCustomerBillingAddressSameAsCustomerLocation, setIsCustomerBillingAddressSameAsCustomerLocation] = useState(true)

    const [isBrandsLoading, setIsBrandsLoading] = useState(false);
    const [isFranchisesLoading, setIsFranchisesLoading] = useState(false);
    const [isSearchResultCutomersLoading, setIsSearchResultCutomersLoading] = useState(false);
    const [isSelectedCustomerLocationsLoading, setIsSelectedCustomerLocationsLoading] = useState(false);
    const [isCampaignsLoading, setIsCampaignsLoading] = useState(false);
    const [isBusinessUnitsLoading, setIsBusinessUnitsLoading] = useState(false);
    const [isJobTypesLoading, setIsJobTypesLoading] = useState(false);
    const [isCallReasonsLoading, setIsCallReasonsLoading] = useState(false);
    const [isTagTypesLoading, setIsTagTypesLoading] = useState(false);
    const [isCreateLeadLoading, setIsCreateLeadLoading] = useState(false);
    const [isEditLeadLoading, setIsEditLeadLoading] = useState(false);
    const [newCustomerClickCount, setNewCustomerClickCount] = useState(0)
    const [isOpenConfirmCreateNewCustomerModal, setIsOpenConfirmCreateNewCustomerModal] = useState(false)
    const [isOpenConfirmLocationModal, setIsOpenConfirmLocationModal] = useState(false)
    const [isAddressSelected, setIsAddressSelected] = useState(false)
    const isAddressChanged = useRef(false)
    const [states, setStates] = useState([]);
    const customerSearchCount = useRef(0);
    const [newLeadNote, setNewLeadNote] = useState({ text: "", pinToTop: false })
    const [isNotesOpen, setNotesOpen] = useState(true);
    const [jobToLeadNote, setJobToLeadNote] = useState({});
    const [fromJobToLead, setFromJobToLead] = useState(false);

    useEffect(() => {
        const storedJobToLeadNote = localStorage.getItem('jobToLeadNote');
        if (storedJobToLeadNote) {
            const jobToLeadNoteValue = JSON.parse(decodeURIComponent(storedJobToLeadNote));
            if (Object.keys(jobToLeadNoteValue).length > 0) {
                setJobToLeadNote(jobToLeadNoteValue);
                setFromJobToLead(true);
            }
            localStorage.removeItem('jobToLeadNote');
        }
        if (isAddNewLeadButtonClick) {
            setFromJobToLead(false);
            setJobToLeadNote({});
            setNewLeadNote({});
        }
    }, [isAddNewLeadButtonClick]);
   
    useEffect(() => {
        handleGetAllBrands(selectedBrandId, selectedDraftToContinoue?.brandId, setBrands, setBrand, setIsBrandsLoading);
        handleGetAllStates(setStates);
    }, [selectedBrandId]);

    useEffect(() => {
        if (brand && !selectedLeadToEdit) {
            customerSearchCount.current = 0;
            return handleGetServiceTitanFranchisesByBrandId(brand, selectedFranchiseId, selectedTenantId, setIsFranchisesLoading, setFranchises, setFranchise, setFranchiseSearchCustomers, setSelectedCustomer, setSelectedCustomerLocations, setSelectedCustomerSelectedLocation, setSelectedCustomerContacts, setIsEncyptionFailed)
        }
    }, [brand, selectedLeadToEdit, selectedFranchiseId, selectedTenantId])

    useEffect(() => {
        if (franchise) {
            customerSearchCount.current = 0;
            resetFranchiseDependantData(setFranchiseSearchCustomers, setSelectedCustomer, setSelectedCustomerLocations, setSelectedCustomerSelectedLocation, setSelectedCustomerContacts, setCampaigns, setCampaign, setBusinessUnits, setBusinessUnit, setJobTypes, setJobType, setSelectedTagTypes, setTagTypes)
            const getCampaignCancelFunc = handleGetCampaigns(franchise?.id, existingLead?.campaignId, setCampaigns, setCampaign, setIsCampaignsLoading);
            const getBusinessUnitsCancelFunc = handleGetBusinessUnits(franchise?.id, existingLead?.businessUnitId, setBusinessUnits, setBusinessUnit, setIsBusinessUnitsLoading, () => { }, () => { });
            const getTagTypesCancelFunc = handleGetTagTypes(franchise, existingLead?.tagTypeIds, setTagTypes, setSelectedTagTypes, setIsTagTypesLoading);
            const getCallReasonsCancelFunc = handleGetCallReasons(franchise, existingLead?.callReasonId, setCallReasons, setCallReason, setIsCallReasonsLoading);
            const geteJobTypesCancelFunc = handleGetJobTypes(franchise?.id, existingLead?.jobTypeId, true, shouldFilterFor360Paintings(brand), setJobTypes, setJobType, setIsJobTypesLoading, () => { }, () => { })
            let getCustomerByIdCancelFunc;
            if (franchise && existingLead?.customerId) {
                getCustomerByIdCancelFunc = handleGetCustomerById(franchise, existingLead?.customerId, setSelectedCustomer, setFranchiseSearchCustomers);
            }
            return () => {
                getCampaignCancelFunc?.();
                getBusinessUnitsCancelFunc?.();
                getTagTypesCancelFunc?.();
                getCallReasonsCancelFunc?.();
                geteJobTypesCancelFunc?.();
                getCustomerByIdCancelFunc?.();
            }
        }
    }, [franchise, selectedPhoneNumber, selectedZipCode, existingLead])

    useEffect(() => {
        if (franchise && selectedCustomer?.id === existingLead.customerId && existingLead?.locationId) return handleGetLocationById(franchise, existingLead?.locationId, setSelectedCustomerSelectedLocation);
    }, [franchise, selectedCustomer, existingLead?.locationId])

    useEffect(() => {
        if (franchise && selectedCustomer?.id) {
            const getCustomerLocationCancelFunc = handleGetSelectedCustomerLocations(franchise?.id, selectedCustomer.id, setSelectedCustomerLocations, setSelectedCustomerSelectedLocation, setIsSelectedCustomerLocationsLoading);
            const getCustomerContactsCancelFunc = handleGetCustomerContacts(franchise?.id, selectedCustomer?.id, setSelectedCustomerContacts);
            return () => {
                getCustomerLocationCancelFunc?.();
                getCustomerContactsCancelFunc?.();
            }
        }
    }, [franchise, selectedCustomer])

    function setInitialContacts(selectedDraftToContinoue) {
        if (selectedDraftToContinoue?.createCustomerRequest?.contacts) {
            if (selectedPhoneNumber && selectedDraftToContinoue.createCustomerRequest?.contacts.length === 0) {
                return [{ type: CONTACTS_TYPES[0], value: selectedPhoneNumber, memo: "" }]
            } else {
                let contacts = []
                selectedDraftToContinoue.createCustomerRequest?.contacts.forEach((c) => {
                    let obj = { type: c.type, value: c.value, memo: c.memo }
                    contacts.push(obj)
                })
                return contacts
            }
        } else {
            return []
        }
    }

    async function createNewLead(e) {
        try {
            e?.preventDefault()
            if (!isContactsValid(newCustomerContacts, [], true)) {
                return
            }
            if (isNewCustomer && !isAddressSufficient(newCustomerAddress) && !shouldCreateLeadWithoutCustomer.current) {
                setIsCreateLeadWithoutCustomerDialogOpen(true);
                return
            }
            if (isNewCustomer && !isAddressSufficient(newCustomerLocation.address) && !shouldCreateLeadWithoutCustomer.current) {
                setIsCreateLeadWithoutCustomerDialogOpen(true);
                return
            }
            if (!followUpDate && !shouldCreateLeadWithoutFollowUpdate.current) {
                setIsCreateLeadWithoutFollowUpDateDialogOpen(true)
                return
            }
            const createLeadRequest = getInformationToCreateLead();
            setIsCreateLeadLoading(true);
            if (selectedDraftToContinoue?.id) await deleteServiceTitanJobDraft(selectedDraftToContinoue?.id)
            const createLeadResponse = await createLead({ brandId: brand.brand_id, franchiseId: franchise.id, ...createLeadRequest, serviceTitanTenantId: franchise.service_titan_tenant_id, clientId: franchise.service_titan_client_id, clientSecret: franchise.service_titan_client_secret })
            showNotification({ message: "The Lead has been created successfully", type: NOTIFICATION_TYPES.SUCCESS })
            //for lead note creation
            if (createLeadResponse.leadResponse.id && newLeadNote.text !== "" && newLeadNote.text !== null && newLeadNote.text !== undefined) {
                try {
                    await addServiceTitanLeadSpecificNote(franchise.service_titan_tenant_id, franchise.service_titan_client_id, franchise.service_titan_client_secret, franchise.id, createLeadResponse.leadResponse.id, newLeadNote);
                } catch (error) {
                    showNotification({ message: "Unable to add lead specific note", type: NOTIFICATION_TYPES.ERROR })
                }
            }

            if (isFromCallWindow == 'true') {
                await updateExistingJob()
                await mapCallWithJobLead(createLeadResponse?.leadResponse?.id)
            }

            handleClose(createLeadResponse);
        } catch (err) {
            let errDetails = JSON.parse(err?.responseText)
            let errCode = errDetails?.data?.ErrorCode
            let stateErr
            if (errCode === "StateNotValid") {
                stateErr = "State doesn't valid with country"
            } else if (errCode === "CountryNotValid") {
                stateErr = "Country is not valid"
            } else {
                stateErr = ""
            }
            showNotification({ message: `Important: Your lead couldn’t be created at this time ${stateErr === "" ? "" : stateErr} . Please try again`, type: NOTIFICATION_TYPES.ERROR })
        } finally {
            setIsCreateLeadLoading(false);
        }
    }

    const onEditeLeadPressed = (e) => {
        e.preventDefault();
        if (franchise && selectedLeadToEdit) {
            const editedLead = {
                id: selectedLeadToEdit?.middlewareServiceTitanLead?.id,
                brandId: selectedBrandId,
                franchiseId: selectedFranchiseId,
                middlewareAssignedToId: selectedLeadToEdit?.middlewareServiceTitanLead?.middlewareAssignedToId,
                middlewareFollowUpStage: selectedLeadToEdit?.middlewareServiceTitanLead?.middlewareFollowUpStage,
                customerName: selectedCustomer?.name,
                customerContacts: selectedCustomerContacts,
                isCreatedWithinMiddlware: selectedLeadToEdit?.middlewareServiceTitanLead?.isCreatedWithinMiddlware,
                ignoreAutomaticFollowUp: selectedLeadToEdit?.middlewareServiceTitanLead?.ignoreAutomaticFollowUp,
                leadUpdateRequest: {
                    brandId: brand.brand_id,
                    franchiseId: franchise.id,
                    campaignId: campaign.id,
                    businessUnitId: businessUnit?.id,
                    jobTypeId: jobType?.id,
                    priority: priority
                }
            };
            setIsEditLeadLoading(true);
            editLead(franchise.service_titan_tenant_id, franchise.service_titan_client_id, franchise.service_titan_client_secret, selectedLeadToEdit?.leadResponse?.id, editedLead).then((editedLeadResponse) => {
                showNotification({ message: "The lead has been updated successfully", type: NOTIFICATION_TYPES.SUCCESS })
                const newLead = { ...editedLeadResponse, middlewareServiceTitanLead: selectedLeadToEdit.middlewareServiceTitanLead }
                handleClose(newLead, false);
            }).catch(() => {
                showNotification({ message: "Important: Your lead couldn’t be updated at this time. Please try again", type: NOTIFICATION_TYPES.ERROR })
            }).finally(() => {
                setIsEditLeadLoading(false);
            })
        }
    }

    const getInformationToCreateLead = () => {
        let createCustomerRequest;
        let leadCreateRequest;
        let leadSummary = summary;
        let canCreateNewCustomer = false;
        if (isNewCustomer) {
            if (isAddressSufficient(newCustomerLocation.address) && isAddressSufficient(newCustomerAddress)) {
                canCreateNewCustomer = true;
                createCustomerRequest = newCustomer;
                createCustomerRequest.address = newCustomerAddress
                createCustomerRequest.locations = [newCustomerLocation]
                createCustomerRequest.contacts = newCustomerContacts;
            } else {
                const addressString = Object.keys(newCustomerLocation?.address ?? {}).map((key) => ` ${newCustomerLocation.address[key]}`).toString();
                let contactString = "";
                newCustomerContacts.forEach((contact) => {
                    contactString += `${contact?.type} - ${contact?.value} ,`
                })
                leadSummary += ` | Address : ${addressString} | Contacts : ${contactString}`;
            }
        }
        leadCreateRequest = {
            customerId: isNewCustomer ? undefined : selectedCustomer?.id,
            locationId: isNewCustomer || shouldAddNewLocationToExistingCustomer ? undefined : selectedCustomerSelectedLocation?.id,
            campaignId: campaign?.id,
            businessUnitId: businessUnit?.id,
            jobTypeId: jobType?.id,
            summary: leadSummary,
            tagTypeIds: selectedTagTypes.map((tag) => tag.id),
            priority: priority,
            callReasonId: callReason?.id,
            followUpDate: followUpDate,
        }
        const middlewareServiceTitanLead = {
            middlewareFollowUpStage: MIDDLEWARE_FOLLOW_UP_STAGES[0],
            ignoreAutomaticFollowUp: 1
        }
        let createLocationRequest = {};
        if (shouldAddNewLocationToExistingCustomer) createLocationRequest = { customerId: selectedCustomer?.id, ...existingCustomerNewLocation }
        const shouldAddNewContactsToExistingCustomer = newCustomerContacts.length !== 0;
        const customerName = isNewCustomer ? newCustomer?.name : selectedCustomer?.name;
        const customerContacts = [...selectedCustomerContacts, ...newCustomerContacts]
        return { customerName, customerContacts, canCreateNewCustomer, createCustomerRequest, leadCreateRequest, middlewareServiceTitanLead, shouldAddNewLocationToExistingCustomer, createLocationRequest, shouldAddNewContactsToExistingCustomer, existingCustomerNewContacts: newCustomerContacts }
    }

    function onOkPressedInCreateLeadWithoutCustomerDialog() {
        shouldCreateLeadWithoutCustomer.current = true;
        setIsCreateLeadWithoutCustomerDialogOpen(false);
        createNewLead()
    }

    function onCancelPressedInCreateLeadWithoutCustomerDialog() {
        setIsCreateLeadWithoutCustomerDialogOpen(false);
    }

    function onOkayPressedInCreateLeadWithoutFollowUpDateDialog() {
        shouldCreateLeadWithoutFollowUpdate.current = true;
        setIsCreateLeadWithoutFollowUpDateDialogOpen(false);
        createNewLead();
    }

    function onCancelPressedInCreateLeadWithoutFollowUpDateDialog() {
        setIsCreateLeadWithoutFollowUpDateDialogOpen(false);
    }

    function isAddressSufficient(address) {
        return ((address?.street !== "" && address?.street) && (address?.city !== "" && address?.city) && (address?.state !== "" && address?.state) && (address?.zip !== "" && address?.zip) && (address?.country !== "" && address?.country));
    }

    function getInsufficientAddressFields(address) {
        let insufficientFields = [];

        if (address?.street === "" || !address?.street) {
            insufficientFields.push("Street");
        }
        if (address?.city === "" || !address?.city) {
            insufficientFields.push("City");
        }
        if (address?.state === "" || !address?.state) {
            insufficientFields.push("State");
        }
        if (address?.zip === "" || !address?.zip) {
            insufficientFields.push("Zip");
        }
        if (address?.country === "" || !address?.country) {
            insufficientFields.push("Country");
        }
        return insufficientFields;
    }

    function getInsufficientLocationFields(location) {
        let insufficientFields = [];
        if (location?.name === "" || !location?.name) {
            insufficientFields.push("Name");
        }
        if (location?.address?.street === "" || !location?.address?.street) {
            insufficientFields.push("Street");
        }
        if (location?.address?.city === "" || !location?.address?.city) {
            insufficientFields.push("City");
        }
        if (location?.address?.state === "" || !location?.address?.state) {
            insufficientFields.push("State");
        }
        if (location?.address?.zip === "" || !location?.address?.zip) {
            insufficientFields.push("Zip");
        }
        if (location?.address?.country === "" || !location?.address?.country) {
            insufficientFields.push("Country");
        }
        return insufficientFields;
    }

    function changeNewCustomerAddress(newCustomerAddressObject = {}) {
        setNewCustomerAddress(newCustomerAddressObject)
    }

    function onCustomerPhoneNumberChanged(event) {
        if (isOnlyContainNumbersOrEmpty(event.target.value)) {
            setCustomerPhoneNumber(event.target.value);
        }
    }
    function onCustomerNameChanged(event) {
        setCustomerName(event.target.value);
    }

    function onFollowUpDateChanged(date) {
        const dateNow = (new Date()).toISOString()
        if (date && date?.toISOString() > dateNow) {
            setFollowUpDate(date.toISOString())
            setCallReason()
        } else if (date && date?.toISOString() < dateNow) {
            setFollowUpDate(dateNow)
            setCallReason()
        } else {
            setFollowUpDate()
        }
    }

    const onSearchCustomerPressed = () => {
        customerSearchCount.current = customerSearchCount.current + 1;
        handleSearchCustomer(franchise, setSelectedCustomer, setFranchiseSearchCustomers, setSelectedCustomerSelectedLocation, setSelectedCustomerLocations, setSelectedCustomerContacts, setIsSearchResultCutomersLoading, customerPhoneNumber, setCustomerPhoneNumber, "", customerName)
    }

    function isSearchButtonDissabled(franchise, customerPhoneNumber = "", customerName = "") {
        const shouldDissabled = (!franchise || !(customerPhoneNumber || customerName) || (customerPhoneNumber.length < 10 && !customerName))
        return shouldDissabled;
    }

    function isOnlyContainNumbersOrEmpty(phoneNumber = "") {
        const isOnlyContainNumbers = /^[0-9]+$/.test(phoneNumber);
        const isPhoneNumberEmpty = phoneNumber === "";
        return (isOnlyContainNumbers || isPhoneNumberEmpty) && phoneNumber.length <= 15
    }

    function onNewCustomerContactsChanged(index, newContact) {
        newCustomerContacts[index] = newContact
        setNewCustomerContacts([...newCustomerContacts])
    }

    function removeContactFromNewCustomerContact(index) {
        setNewCustomerContacts(newCustomerContacts.filter((_, i) => i !== index))
    }

    function addNewCustomerContact() {
        setNewCustomerContacts([...newCustomerContacts, CUSTOMER_INITIAL_CONTACT])
    }

    function isCreateNewContactForNewCustomerDisabled() {
        if (newCustomerContacts?.length > 0) {
            const lastContact = newCustomerContacts[newCustomerContacts.length - 1];
            return !(lastContact?.type && lastContact?.value)
        } else {
            return false
        }
    }

    function handleLocationChange(address) {
        if (isNewCustomer) {
            setNewCustomerLocation({ ...newCustomerLocation, address });
        } else if (shouldAddNewLocationToExistingCustomer) {
            setExistingCustomerNewLocation({ ...existingCustomerNewLocation, address });
        }
        if (isCustomerBillingAddressSameAsCustomerLocation) {
            setNewCustomerAddress(address)
        }
    }

    function handleCustomerNameChange(event) {
        setNewCustomer({ ...newCustomer, name: event.target.value });
        setNewCustomerLocation({ ...newCustomerLocation, name: event.target.value })
    }

    function handleOnTypeChange(e, value) {
        if (isNewCustomer) {
            setNewCustomer({ ...newCustomer, type: value })
        }
    }

    function handleOnChangeSameLocationCheckbox(event) {
        setIsCustomerBillingAddressSameAsCustomerLocation(event.target.checked)
        if (event.target.checked) {
            setNewCustomerAddress({ ...newCustomerLocation?.address })
        } else {
            setNewCustomerAddress({ undefined })
        }
    }

    function handleOnAddressChange(address) {
        if (isNewCustomer) {
            changeNewCustomerAddress(address)
        }
    }

    function handleOnLocationNameChange(event) {
        if (isNewCustomer) {
            setNewCustomerLocation({ ...newCustomerLocation, name: event.target.value });
        } else if (shouldAddNewLocationToExistingCustomer) {
            setExistingCustomerNewLocation({ ...existingCustomerNewLocation, name: event.target.value })
        }
    }

    function setIsAddressChanged() {
        if (!isAddressChanged.current && !isAddressSelected) {
            isAddressChanged.current = true
            setIsOpenConfirmLocationModal(true)
        }
    }

    function onLocationAddressChanged(address) {
        setIsAddressChanged()
        if (isNewCustomer) {
            setNewCustomerLocation({ ...newCustomerLocation, address })
        } else if (shouldAddNewLocationToExistingCustomer) {
            setExistingCustomerNewLocation({ ...existingCustomerNewLocation, address })
        }
        if (isCustomerBillingAddressSameAsCustomerLocation) {
            setNewCustomerAddress({ ...address })
        }
    }

    function onAddNewContactPressed() {
        addNewCustomerContact()
    }

    function handleSetTagTypes(jobType) {
        if (jobType?.tagTypeIds?.length && !selectedLeadToEdit) {
            setSelectedTagTypes(tagTypes?.filter?.((v) => jobType?.tagTypeIds?.some((tId) => tId === v.id)) ?? [])
        } else {
            setSelectedTagTypes([])
        }
    }

    function handleToggleNewCustomer() {
        if (!isNewCustomer && !isOpenConfirmCreateNewCustomerModal) {
            setIsOpenConfirmCreateNewCustomerModal(true)
            return
        }
        if (isOpenConfirmCreateNewCustomerModal) handleCloseCreateNewCustomerConfirmModal()
        setIsAddressSelected(false)
        isAddressChanged.current = false
        setIsNewCustomer(!isNewCustomer)
        if (newCustomerClickCount === 0) {
            setNewCustomerContacts(CUSTOMER_INITIAL_CONTACTS)
        }
        setNewCustomerClickCount(newCustomerClickCount + 1)
    }

    function handleJobTypeChange(_, jobType) {
        if (jobType?.priority) setPriority(jobType?.priority)
        handleAutoPickBusineeUnit(jobType)
        handleSetTagTypes(jobType)
        if (!selectedLeadToEdit) setSummary(jobType !== null ? jobType?.summary : "")
        setJobType(jobType)
    }

    function handleAutoPickBusineeUnit(jobType) {
        setBusinessUnit()
        if (jobType?.businessUnitIds?.length === 1) {
            const businessUnit = businessUnits.find((b) => b.id === jobType?.businessUnitIds[0])
            setBusinessUnit(businessUnit)
        }
    }

    function handleAddNewLocationToExistingCustomer() {
        setShouldAddNewLocationToExistingCustomer(!shouldAddNewLocationToExistingCustomer)
        if (!shouldAddNewLocationToExistingCustomer) {
            setIsAddressSelected(false)
            isAddressChanged.current = false
            setExistingCustomerNewLocation({ ...newCustomerLocation })
        }
    }

    async function updateExistingJob() {
        const body = {
            franchise_id: franchise.franchise_id,
            zip_code: urlParams.get("zipCode"),
            call_type: parseInt(urlParams.get("call_type"))
        }

        const jobId = parseInt(urlParams.get("jobId"))
        const jobtype = urlParams.get("type")

        await updateJob(jobId, jobtype, body).then((res) => {
            showNotification({ message: "The call details updated successfully", type: NOTIFICATION_TYPES.SUCCESS })
        }).catch((err) => {
            showNotification({ message: "Unable to update call details at this time", type: NOTIFICATION_TYPES.ERROR })
        }).finally(() => {
            removeCallDetailsFromLocalStorage()
        })
    }

    async function mapCallWithJobLead(leadId) {
        const body = {
            call_id: parseInt(urlParams.get("jobId")),
            lead_id: leadId,
            franchise_id: franchise?.id,
            brand_id: brand?.brand_id,
        }
        await mapCallJobLead(body).then((res) => {
        }).catch((err) => {
            showNotification({ message: "Unable to map the call and the job", type: NOTIFICATION_TYPES.ERROR })
        })
    }
    function handleCloseCreateNewCustomerConfirmModal() {
        setIsOpenConfirmCreateNewCustomerModal(false)
    }

    function confirmLocation() {
        setIsOpenConfirmLocationModal(false)
    }

    function handleCloseConfirmLocatinModal() {
        setIsOpenConfirmLocationModal(false)
        isAddressChanged.current = false
    }

    const handleToggleCollapse = () => {
        setNotesOpen(!isNotesOpen);
    };

    return (
        <>
            <Dialog fullWidth maxWidth="lg" onClose={() => { }} open={open} className="dialog-zindex" sx={{ overflow: 'hidden' }}>
                <DialogHeader onClose={() => { handleClose() }} className={'dialog-plain-color'}>
                    <Typography variant="h5" component={"h1"} fontWeight="bold" marginTop="1em" marginBottom="0.2em" marginLeft="2em" >{selectedLeadToEdit ? `Edit Lead #${selectedLeadToEdit.leadResponse.id}` : "Create New Lead"} </Typography>
                </DialogHeader>
                <Box className={'dialog'} sx={{ paddingBottom: '1em', overflowY: 'scroll' }}>
                    <form onSubmit={(e) => {
                        e.preventDefault()
                        if (selectedLeadToEdit) {
                            onEditeLeadPressed(e);
                        } else {
                            createNewLead(e);
                        }
                    }} autoComplete="off" >
                        <DialogBody>
                            <div className="form-control" style={{ marginBottom: "1em" }}>
                                <label><Typography variant="subtitle1" >Brand :</Typography></label>
                                <Autocomplete
                                    sx={{ width: "65%" }}
                                    size="small"
                                    disabled={selectedLeadToEdit || isFromCallWindow === 'true'}
                                    loading={isBrandsLoading}
                                    disablePortal
                                    disableClearable
                                    options={brands}
                                    getOptionLabel={(brand) => brand.brand_name}
                                    renderOption={renderBrandOption}
                                    value={brands?.length > 0 ? brand ?? null : null}
                                    onChange={(_, value) => setBrand(value)}
                                    renderInput={(params) => <CustomTextField required={true}  {...params} label="Brand" />}
                                />
                            </div>
                            <div className="form-control" style={{ marginBottom: "1em" }}>
                                <label><Typography variant="subtitle1" >Franchise :</Typography></label>
                                <Autocomplete
                                    sx={{ width: "65%", }}
                                    size="small"
                                    disablePortal
                                    disableClearable
                                    loading={isFranchisesLoading}
                                    noOptionsText={brand ? (isEncryptionFailed ? TEMPORY_FAILURE : "No serviceTitan franchises found") : "Please select a brand"}
                                    disabled={selectedLeadToEdit ?? undefined}
                                    value={franchises?.length > 0 ? franchise ?? null : null}
                                    options={franchises ?? []}
                                    getOptionLabel={franchise => franchise.franchise_name}
                                    getOptionDisabled={(f) => !getIsSTCredentialsAvailable(f)}
                                    renderOption={(props, franchise) => {
                                        return (
                                            <div style={{ position: "relative" }}>
                                                <Typography {...props} component={"p"} key={franchise.id}>
                                                    {franchise.franchise_name}
                                                </Typography>
                                                {getIsSTCredentialsAvailable(franchise) ? <></> : <EncryptionWarningBadge title={isEncryptionFailed ? TEMPORY_FAILURE : getCredentialsMissingWarning(franchise)} bottom={"3px"} right={"3px"} />}
                                            </div>
                                        );
                                    }}
                                    onChange={(_, value) => setFranchise(value)}
                                    renderInput={(params) => <CustomTextField required={true} {...params} label="Franchise" />}
                                />
                            </div>
                            {!selectedLeadToEdit ?
                                !isNewCustomer &&
                                <>
                                    <Box sx={{ display: 'flex', flexDirection: "row", width: "100%", height: "3em", justifyContent: "left", alignItems: "center" }}>
                                        <label style={{ flexGrow: 2 }}><Typography variant="subtitle1" >Customer Search Information : </Typography></label>
                                        <Box sx={{ display: 'flex', flexDirection: "row", width: "65%", height: "3em", justifyContent: "left", alignItems: "center", gap: "1em" }}>
                                            <CustomTextField
                                                style={{ width: "30%" }}
                                                label="Name"
                                                type="search"
                                                size="small"
                                                disabled={selectedLeadToEdit ?? undefined}
                                                value={customerName ?? ""}
                                                onChange={onCustomerNameChanged}
                                            />
                                            <PhoneNumberBox value={customerPhoneNumber} type="search" label="Phone Number" style={{ width: "20%", flexGrow: 2 }} onChange={onCustomerPhoneNumberChanged} disabled={selectedLeadToEdit ? true : false} maxLength={15} />
                                            <LoadingButton sx={{ color: "white", backgroundColor: 'background.dialer', textTransform: "none", "&:hover": { backgroundColor: 'background.dialer' } }} variant="contained" disabled={isSearchButtonDissabled(franchise, customerPhoneNumber, customerName)} loading={isSearchResultCutomersLoading} onClick={onSearchCustomerPressed}>Search</LoadingButton>
                                        </Box>
                                    </Box>
                                </>
                                : <></>}
                            <Box sx={{ display: 'flex', flexDirection: "row", width: "100%", height: "3em", justifyContent: "left", alignItems: "center" }}>
                                <h4>Customer Details</h4>
                                {selectedLeadToEdit ? "" : <Tooltip enterDelay={1000} enterTouchDelay={1000} title={isNewCustomer ? "Create lead for an existing customer" : "Create lead for a new customer"} ><Button sx={{ color: "#00a79d" }} variant="text" onClick={handleToggleNewCustomer}>{isNewCustomer ? " Existing" : "\u002B New"}</Button></Tooltip>}
                            </Box>
                            <Box >
                                <Box sx={{ display: 'flex', flexDirection: "row", width: "100%" }} >
                                    <Box sx={{ display: 'flex', flexDirection: "column", width: "45%", marginRight: "1em" }}>
                                        {isNewCustomer ? <CustomTextField required={true} value={newCustomer?.name ?? ""} size="small" label="Customer Name"
                                            onChange={handleCustomerNameChange} />
                                            : <Autocomplete
                                                sx={{ width: "100%", }}
                                                size="small"
                                                disabled={selectedLeadToEdit}
                                                noOptionsText={franchise ? "No customer found" : "Please select a franchise"}
                                                loading={isSearchResultCutomersLoading}
                                                value={selectedCustomer ?? null}
                                                disablePortal
                                                disableClearable
                                                options={franchiseSearchCustomers}
                                                getOptionLabel={(customer) => customer.name}
                                                renderOption={(props, customer) => {
                                                    return (
                                                        <Typography {...props} key={customer.id}>
                                                            {customer.name}
                                                        </Typography>
                                                    );
                                                }}
                                                onChange={(_, value) => setSelectedCustomer(value)}
                                                renderInput={(params) => <CustomTextField {...params} required={!isNewCustomer} label="Select Customer" />}
                                            />
                                        }
                                        {!isNewCustomer && !isSearchResultCutomersLoading ? customerSearchCount.current > 0 ?
                                            franchiseSearchCustomers.length > 0 ?
                                                <Typography sx={{ color: 'text.green' }} variant="subtitle1">{franchiseSearchCustomers.length === 1 ? "Customer Found" : "Customers Found"}</Typography>
                                                :
                                                <Typography style={{ color: "red" }} variant="subtitle1">{"Customer Not Found"}</Typography>
                                            : <></>
                                            :
                                            <></>
                                        }
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: "row", width: "50%", }}>
                                        <FormControl disabled={!isNewCustomer}>
                                            <RadioGroup
                                                row
                                                value={isNewCustomer ? (newCustomer?.type ?? CUSTOMER_TYPES[0]) : (selectedCustomer?.type ?? "")}
                                                name="type-radio-buttons-group"
                                                onChange={handleOnTypeChange}
                                            >
                                                {CUSTOMER_TYPES.map((type) => <FormControlLabel key={type} value={type} control={<Radio sx={{ '&, &.Mui-checked': { color: '#00a79d', }, "&.Mui-disabled": { color: "grayText" } }} />} label={type} />)}
                                            </RadioGroup>
                                        </FormControl>
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: "row", width: "100%", justifyContent: "left", alignItems: "center", marginBlock: "1em" }}>
                                    <label><Typography variant="subtitle1" >Location</Typography></label>
                                    {!isNewCustomer ? <Tooltip title={shouldAddNewLocationToExistingCustomer ? "Use existing location" : "Add new location"} ><Button disabled={selectedLeadToEdit} sx={{ color: "#00a79d" }} variant="text"
                                        onClick={handleAddNewLocationToExistingCustomer}>
                                        {shouldAddNewLocationToExistingCustomer ? "Existing" : "\u002B New"}
                                    </Button></Tooltip> : <></>}
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                    {(shouldAddNewLocationToExistingCustomer || isNewCustomer) ?
                                        <AddressAutoComplete disabled={selectedLeadToEdit || (!isNewCustomer && !shouldAddNewLocationToExistingCustomer)}
                                            label={"Search Location Address"}
                                            lat={franchise?.latitude}
                                            long={franchise?.longitude}
                                            radius={MAX_FRANCHISE_LOCATION_RADIUS}
                                            isAddressSelected={setIsAddressSelected}
                                            onChange={handleLocationChange}
                                            crm={AVAILABLE_CRMS.servicetitan.value}
                                        />
                                        : <></>
                                    }
                                    <Box sx={{ display: 'flex', flexDirection: "row", flexWrap: "wrap", width: "100%", marginBlock: "0.25em", gap: "1em" }} >
                                        {(isNewCustomer || shouldAddNewLocationToExistingCustomer) ? <CustomTextField style={{ width: "45%" }} required={true} value={isNewCustomer ? newCustomerLocation?.name ?? "" : shouldAddNewLocationToExistingCustomer ? existingCustomerNewLocation?.name ?? "" : ""} size="small" label="Location Name"
                                            onChange={handleOnLocationNameChange} />
                                            : <Autocomplete
                                                sx={{ width: "45%", }}
                                                size="small"
                                                disabled={selectedLeadToEdit}
                                                noOptionsText={selectedCustomer ? "No Locations found for customer" : "Please select a customer"}
                                                loading={isSelectedCustomerLocationsLoading}
                                                value={selectedCustomerSelectedLocation ?? null}
                                                disablePortal
                                                disableClearable
                                                options={selectedCustomerLocations}
                                                getOptionLabel={(location) => location.name}
                                                renderOption={(props, location) => {
                                                    return (
                                                        <Typography {...props} key={location.id}>
                                                            {location.name}
                                                        </Typography>
                                                    );
                                                }}
                                                onChange={(_, value) => setSelectedCustomerSelectedLocation(value)}
                                                renderInput={(params) => <CustomTextField   {...params} required={!isNewCustomer} label="Select Customer's Location" />}
                                            />}
                                        <AddressBox validZipCodesString={franchise ? `${franchise?.primary_zip_codes ?? ""}, ${franchise?.secondary_zip_codes ?? ""}` : ""} address={isNewCustomer ? newCustomerLocation?.address : shouldAddNewLocationToExistingCustomer ? existingCustomerNewLocation.address : selectedCustomerSelectedLocation?.address} isDisabled={isNewCustomer ? false : shouldAddNewLocationToExistingCustomer ? false : true} isRequired={true} isSelectableStates={false} countries={COUNTRIES} states={states}
                                            isNotLeadSection={false} onChangeAddress={onLocationAddressChanged} />
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: "row", width: "100%", marginBlock: "1em", justifyContent: "left", alignItems: "center" }}>
                                    <label><Typography variant="subtitle1" sx={{ marginBlock: "0.4em" }} >Billing Address</Typography></label>
                                    {isNewCustomer ? <FormControlLabel style={{ marginLeft: "1em" }} control={<Checkbox sx={{ '&, &.Mui-checked': { color: '#00a79d', }, }} size={"small"}
                                        onChange={handleOnChangeSameLocationCheckbox} checked={isCustomerBillingAddressSameAsCustomerLocation} />} label="Make customer billing address same as customer location address" /> : <></>}
                                </Box>
                                {(!isCustomerBillingAddressSameAsCustomerLocation || !isNewCustomer) &&
                                    <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                        {(!isCustomerBillingAddressSameAsCustomerLocation && isNewCustomer) &&
                                            <AddressAutoComplete disabled={!isNewCustomer || selectedLeadToEdit} label={"Search Billing Address"} onChange={(address) => changeNewCustomerAddress(address)} lat={franchise?.latitude} long={franchise?.longitude} radius={MAX_FRANCHISE_LOCATION_RADIUS} crm={AVAILABLE_CRMS.servicetitan.value} />
                                        }
                                        <Box sx={{ display: 'flex', flexDirection: "row", flexWrap: "wrap", width: "100%", marginBlock: "0.25em", gap: "1em" }} >
                                            <AddressBox validZipCodesString={franchise ? `${franchise?.primary_zip_codes ?? ""}, ${franchise?.secondary_zip_codes ?? ""}` : ""} address={isNewCustomer ? newCustomerAddress ?? "" : selectedCustomer?.address ?? ""} isDisabled={!isNewCustomer} isRequired={true} isSelectableStates={false} countries={COUNTRIES} states={states}
                                                isNotLeadSection={false} onChangeAddress={handleOnAddressChange} />
                                        </Box>
                                    </Box>
                                }
                                <Box display="flex" flexDirection="row" alignItems="center" marginTop={"1em"} marginBottom={"0.5em"} >
                                    <label ><Typography variant="subtitle1" >Contacts</Typography></label>
                                    <Button title="Add new contact" sx={{ color: "#00a79d" }} disabled={selectedLeadToEdit ? true : (isCreateNewContactForNewCustomerDisabled())} variant="text"
                                        onClick={onAddNewContactPressed}>{"\u002B New"}</Button>
                                </Box>
                                <Box display={"flex"} flexDirection={"column"} gap={"0.9em"} >
                                    {isNewCustomer ? newCustomerContacts.map((contact, index) => buildContact(contact, index, true, true)) : [...selectedCustomerContacts.map((contact, index) => buildContact(contact, index, false, false)), ...newCustomerContacts.map((contact, index) => buildContact(contact, index, true, true))]}
                                </Box>
                            </Box>
                            <h4 style={{ marginBottom: "1em" }}>Lead Details</h4>
                            <Box sx={{ display: 'flex', flexDirection: "row", flexWrap: "wrap", width: "100%", gap: "1em" }} >
                                <Autocomplete
                                    style={{ width: "49%", flexGrow: 2 }}
                                    size="small"
                                    disablePortal
                                    disableClearable
                                    noOptionsText={franchise ? "No campaigns for franchise" : "Please select a franchise"}
                                    loading={isCampaignsLoading}
                                    options={campaigns}
                                    getOptionLabel={(campaign) => campaign.name}
                                    renderOption={(props, campaign) => {
                                        return (
                                            <Typography {...props} key={campaign.id}>
                                                {campaign.name}
                                            </Typography>
                                        );
                                    }}
                                    value={campaigns.length > 0 ? (campaign ?? null) : null}
                                    onChange={(_, campaign) => setCampaign(campaign)}
                                    renderInput={(params) => <CustomTextField   {...params} required={true} label="Select Campaign" />}
                                />
                                <Autocomplete
                                    style={{ width: "49%", flexGrow: 2 }}
                                    size="small"
                                    disablePortal
                                    disableClearable={selectedLeadToEdit}
                                    noOptionsText={franchise ? "No job types for franchise" : "Please select a franchise"}
                                    loading={isJobTypesLoading}
                                    options={jobTypes}
                                    getOptionLabel={(jobType) => jobType.name}
                                    renderOption={(props, jobType) => {
                                        return (
                                            <Typography {...props} key={jobType.id}>
                                                {jobType.name}
                                            </Typography>
                                        );
                                    }}
                                    value={jobTypes.length > 0 ? (jobType ?? null) : null}
                                    onChange={handleJobTypeChange}
                                    renderInput={(params) => <CustomTextField   {...params} label="Select Job Type" />}
                                />
                                <Autocomplete
                                    style={{ width: "49%", flexGrow: 2 }}
                                    size="small"
                                    disablePortals
                                    disabled={!jobType}
                                    disableClearable={selectedLeadToEdit}
                                    noOptionsText={franchise ? "No business units for franchise" : "Please select a franchise"}
                                    loading={isBusinessUnitsLoading}
                                    options={jobType?.businessUnitIds?.length ? businessUnits?.filter?.((v) => jobType?.businessUnitIds?.includes?.(v.id)) : businessUnits}
                                    getOptionDisabled={(option) => !option.active}
                                    getOptionLabel={(businessUnit) => businessUnit.name}
                                    renderOption={(props, businessUnit) => {
                                        return (
                                            <Typography {...props} key={businessUnit.id}>
                                                {businessUnit.name}
                                            </Typography>
                                        );
                                    }}
                                    value={businessUnits.length > 0 ? (businessUnit ?? null) : null}
                                    onChange={(_, businessUnit) => {
                                        setBusinessUnit(businessUnit);
                                    }}
                                    renderInput={(params) => <CustomTextField   {...params} label="Select Busines Unit" />}
                                />
                                <Autocomplete
                                    style={{ width: "49%", flexGrow: 2 }}
                                    size="small"
                                    disablePortal
                                    disableClearable
                                    options={PRIORITIES}
                                    getOptionLabel={(priority) => priority}
                                    renderOption={(props, priority) => {
                                        return (
                                            <Typography {...props} key={priority}>
                                                {priority}
                                            </Typography>
                                        );
                                    }}
                                    value={(priority ?? null)}
                                    onChange={(_, priority) => setPriority(priority)}
                                    renderInput={(params) => <CustomTextField   {...params} required={selectedLeadToEdit} label="Priority" />}
                                />
                                <div style={{ width: "49%", flexGrow: 2, zIndex: 1000 }} >
                                    <CustomDatePicker
                                        minTime={selectedLeadToEdit ? undefined : new Date()}
                                        disabled={Boolean(selectedLeadToEdit)}
                                        label={`Next Follow-Up Date ( ${getMatchingTimezoneByName(generalTenantConfiguration?.time_zone, timezones)?.abbreviation ?? ""} )`}
                                        value={selectedLeadToEdit ? (selectedLeadToEdit?.leadResponse?.followUpDate ? (Date.parse(selectedLeadToEdit?.leadResponse?.followUpDate)) : null) : (followUpDate ? new Date(followUpDate) : null)}
                                        timeZone={generalTenantConfiguration?.time_zone}
                                        onChange={onFollowUpDateChanged}
                                        clearable={followUpDate}
                                        onClear={() => setFollowUpDate()}
                                        required={!callReason}
                                    />
                                </div>
                                <Autocomplete
                                    style={{ width: "49%", flexGrow: 2 }}
                                    size="small"
                                    disablePortal
                                    disabled={selectedLeadToEdit}
                                    noOptionsText={franchise ? "No call reasons for franchise" : "Please select a franchise"}
                                    loading={isCallReasonsLoading}
                                    options={callReasons}
                                    getOptionLabel={(callReason) => callReason.name}
                                    getOptionDisabled={(callReason) => !callReason.active}
                                    renderOption={(props, callReason) => {
                                        return (
                                            <Typography {...props} key={callReason.id}>
                                                {callReason.name}
                                            </Typography>
                                        );
                                    }}
                                    value={callReasons?.length > 0 ? (callReason ?? null) : null}
                                    onChange={(_, callReason) => setCallReason(callReason)}
                                    renderInput={(params) => <CustomTextField required={!followUpDate} {...params} label="Call Reason" />}
                                />
                                <Autocomplete
                                    style={{ width: "49%", flexGrow: 2 }}
                                    size="small"
                                    disablePortal
                                    multiple={true}
                                    disabled={selectedLeadToEdit}
                                    noOptionsText={franchise ? "No tag types for franchise" : "Please select a franchise"}
                                    loading={isTagTypesLoading}
                                    options={tagTypes}
                                    getOptionLabel={(tagType) => tagType.name}
                                    renderOption={(props, tagType) => {
                                        return (
                                            <Typography {...props} key={tagType.id}>
                                                {tagType.name}
                                            </Typography>
                                        );
                                    }}
                                    value={selectedTagTypes}
                                    onChange={(_, selectedTagTypes) => setSelectedTagTypes(selectedTagTypes)}
                                    renderInput={(params) => <CustomTextField {...params} label="Tag Types" />}
                                />
                                <Box style={{ width: "100%", }}>
                                    <CustomTextField disabled={selectedLeadToEdit} multiline rows={2} required={true} label="Summary" variant="outlined" size="small" style={{ width: "100%", }} value={summary}
                                        onChange={(event) => setSummary(event.target.value)} />
                                </Box>
                            </Box>
                            {!isNewCustomer ?
                                <>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 0 }}>
                                        <h4>Notes Details</h4>
                                        <IconButton onClick={handleToggleCollapse} style={{ marginBottom: 0 }}>
                                            {isNotesOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </IconButton>
                                    </Box>
                                    <Collapse in={isNotesOpen}>
                                        <LeadSectionNotes
                                            franchise={franchise}
                                            customerId={selectedCustomer?.id}
                                            locationId={selectedCustomerLocations?.[0]?.id}
                                            showNotification={showNotification}
                                            newLeadNote={newLeadNote}
                                            setNewLeadNote={setNewLeadNote}
                                            fromLeadCreatePage={true}
                                            fromLeadViewPage={false}
                                            fromJobToLead={fromJobToLead}
                                            jobToLeadNote={jobToLeadNote}
                                        />
                                    </Collapse>
                                </>
                                :
                                <Box sx={{marginBottom:"1em"}}></Box>
                            }
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                                <Button size={"small"} variant="contained" onClick={() => {
                                    handleClose();
                                }} className={"btn-secondary"} style={{ marginRight: "10px" }} >Cancel</Button>
                                <LoadingButton type={"submit"} size={"small"} variant="contained" loading={selectedLeadToEdit ? isEditLeadLoading : isCreateLeadLoading} className={"btn-primary"} >
                                    {selectedLeadToEdit ? "Edit Lead" : "Create Lead"}
                                </LoadingButton>
                            </Box>
                        </DialogBody>
                    </form>
                </Box>
            </Dialog>
            <Dialog maxWidth="xs" open={isCreateLeadWithoutCustomerDialogOpen} onClose={onCancelPressedInCreateLeadWithoutCustomerDialog}  ><Container sx={{ display: "flex", flexDirection: "column", padding: "1em", alignContent: "center" }} >
                <Typography variant="p">There is not enough information ({!isAddressSufficient(newCustomerAddress) ? `Billing Address : ${getInsufficientAddressFields(newCustomerAddress).toString()}` : ""} | {!isAddressSufficient(newCustomerLocation?.address) ? `Location : ${getInsufficientLocationFields(newCustomerLocation).toString()}` : ""} ) to create a customer, the provided information will be saved in the “Summary”.</Typography>
                <Container sx={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: "0.5em" }} ><Button size="small" onClick={onCancelPressedInCreateLeadWithoutCustomerDialog} sx={{ color: "#00a79d" }}  >Cancel</Button><Button variant="contained" size="small" onClick={onOkPressedInCreateLeadWithoutCustomerDialog} sx={{ backgroundColor: "#00a79d", "&:hover": { backgroundColor: "#00a79d" } }} >Okay</Button></Container>
            </Container></Dialog>
            <Dialog maxWidth="xs" open={isCreateLeadWithoutFollowUpDateDialogOpen} onClose={onCancelPressedInCreateLeadWithoutCustomerDialog}><Container sx={{ display: "flex", flexDirection: "column", padding: "1em", alignContent: "center" }}>
                <Typography variant="p">Lead without Follow-Up date will be automatically dismissed .Do you want to create lead without a follow-up date?</Typography>
                <Container sx={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: "0.5em" }} ><Button size="small" onClick={onCancelPressedInCreateLeadWithoutFollowUpDateDialog} sx={{ color: "#00a79d" }}  >No</Button><Button variant="contained" size="small" onClick={onOkayPressedInCreateLeadWithoutFollowUpDateDialog} sx={{ backgroundColor: "#00a79d", "&:hover": { backgroundColor: "#00a79d" } }} >Yes</Button></Container>
            </Container></Dialog>
            {isOpenConfirmCreateNewCustomerModal ? <NewCustomerCreateConfirmModal isOpen={isOpenConfirmCreateNewCustomerModal} wantToCreateNewCustomer={handleToggleNewCustomer} onCloseHandler={handleCloseCreateNewCustomerConfirmModal} /> : <></>}
            {isOpenConfirmLocationModal ? <LocationConfirmModal isOpen={isOpenConfirmLocationModal} confirmLocation={confirmLocation} onCloseHandler={handleCloseConfirmLocatinModal} /> : <></>}
        </>
    );

    function buildContact(contact, index, isNewContact = false, isNewCustomerContact = false) {
        let isValidContact = isContactValid(contact) || contact?.value?.length === 0;
        let errorText = ""
        if (isNewContact) {
            if (!isNewCustomerContact) {
                const isExistingContact = isContactExists(contact, [...selectedCustomerContacts])
                if (isExistingContact) {
                    errorText = "Already exists"
                }
                isValidContact = isValidContact && !isExistingContact
            } else {
                let newContacts = []
                newCustomerContacts.forEach((contact, i) => {
                    if (i !== index) {
                        newContacts.push(contact)
                    }
                })
                const isExistingContact = isContactExists(contact, [...newContacts, ...selectedCustomerContacts])
                if (isExistingContact) {
                    errorText = "Already exists"
                }
                isValidContact = isValidContact && !isExistingContact
            }
        }
        return <Box key={index} display={"flex"} flexDirection={"row"} alignItems={"start"} >
            <ContactBox flexGrow={2}
                isValidContact={isValidContact}
                errorText={errorText}
                type={{
                    isDisabled: (isNewCustomer ? false : selectedCustomerContacts.some((value) => value === contact)), options: CONTACTS_TYPES, value: contact.type, onChange: (event, contactType) => {
                        onNewCustomerContactsChanged(index, { ...contact, type: contactType })
                    }
                }}
                value={{
                    isDisabled: (isNewCustomer ? false : selectedCustomerContacts.some((value) => value === contact)),
                    isRequired: isNewCustomer,
                    value: contact.value ?? "",
                    onChange: (event) => {
                        onNewCustomerContactsChanged(index, { ...contact, value: event?.target?.value })
                    }
                }}
                memo={{
                    isDisabled: (isNewCustomer ? false : selectedCustomerContacts.some((value) => value === contact)), value: contact.memo ?? "", onChange: (event) => {
                        onNewCustomerContactsChanged(index, { ...contact, memo: event?.target?.value })
                    }
                }}
            />
            {<IconButton size={"small"} disabled={isNewCustomer ? (!checkIfContactCanBeDeleted(newCustomerContacts, contact)) : !newCustomerContacts.some((value) => contact === value)} onClick={() => {
                removeContactFromNewCustomerContact(index);
            }}>
                <CloseIcon />
            </IconButton>}
        </Box>
    }
}

export function removeCallDetailsFromLocalStorage() {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.media)
    localStorage.removeItem(LOCAL_STORAGE_KEYS.channelName)
    localStorage.removeItem(LOCAL_STORAGE_KEYS.channelId)
    localStorage.removeItem(LOCAL_STORAGE_KEYS.callerId)
    localStorage.removeItem(LOCAL_STORAGE_KEYS.zipCode)
    localStorage.removeItem(LOCAL_STORAGE_KEYS.brandId)
    localStorage.removeItem(LOCAL_STORAGE_KEYS.jobId)
    localStorage.removeItem(LOCAL_STORAGE_KEYS.brandPrimaryId)
}
