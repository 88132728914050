import { Snackbar } from "@mui/material";
import Alert from '@mui/material/Alert'

export default function AppNotification(props) {
    const { notify, setNotify } = props

    const handleClose = (_, reason) => {
        if (reason === "clickway") {
            return
        }
        setNotify({ ...notify, isOpen: false })
    }

    return (
        <Snackbar
            open={notify.isOpen}
            autoHideDuration={6000}
            onClose={handleClose}>
            <Alert
                onClose={handleClose}
                severity={notify.type}>
                {notify.message}
            </Alert>
        </Snackbar>
    )
}