import { FetchClient } from "../utils/fetchClient";



export const getMe = async()=>{
    return FetchClient("/users/me").GET({})
}

export const logout = async()=>{
    return FetchClient("/auth/logout").DELETE({})
}
