import { Box, IconButton, Tooltip } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CommonContext } from "../contexts/CommonContextProvider";
import { useContext } from "react";
import { NOTIFICATION_TYPES } from "../constants/common";

export default function CopyToClipBoard({ textToCopy = "", children }) {

    const { showNotification } = useContext(CommonContext)

    function handleCopyToClipBoard() {
        showNotification({ message: "Copied to clipboard", type: NOTIFICATION_TYPES.SUCCESS })
        navigator.clipboard.writeText(textToCopy)
    }

    return <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }} >
        {children}
        <Tooltip title={"Copy to clipboard"} ><IconButton size={"small"} onClick={handleCopyToClipBoard}><ContentCopyIcon /></IconButton></Tooltip>
    </Box>
}