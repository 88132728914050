import { isValidEmail } from "../../../../../utils/validators"
import { getIsEmailAlreadyExists } from "../../../franchise/model/OwnerTable/utils/common"

export default function getValidationOnOwnerData(ownerEmail, existingEmails) {
    let errMessage = ""
    if (!isValidEmail(ownerEmail)) errMessage = "Invalid email"
    if (getIsEmailAlreadyExists(ownerEmail, existingEmails)) errMessage = "Email already exists"
    const isValid = errMessage === ""
    return isValid
}
