import { agentTypes } from "../pages/dashboard/scoreDashboard/utils";
import { getAllActiveUsers, getAllUsers, getAllUsersIncludeRemovedOnes, getMe } from "../services/userService";

export const handleGetAgents = async (setAgents) => {
    try {
        let data = await getAllActiveUsers(agentTypes?.ALL_ACTIVE_AGENTS)
        setAgents(getEightByEightAgentIds(data?.users))
    } catch (error) {
        setAgents([])
    }
}

const getEightByEightAgentIds = (agents) => {
    return agents.filter((agent) => agent.eight_by_eight_agent_id !== "").map((agent) => agent.eight_by_eight_agent_id)
}

export function handleGetAllUsers(setUsers, setIsUsersLoading, onUsersLoaded = (users = []) => { }) {
    setIsUsersLoading?.(true);
    getAllUsers().then((usersResponse) => {
        if (Array.isArray(usersResponse?.users)) {
            setUsers(usersResponse?.users);
            onUsersLoaded(usersResponse?.users)
        }
    }).catch(() => {
    }).finally(() => {
        setIsUsersLoading?.(false);
    });
}

export async function handleGetAllUsersIncludeRemovedOnes(setUsers) {
    try {
        let res = await getAllUsersIncludeRemovedOnes(agentTypes?.ALL_USERS_INCLUDE_REMOVED)
        if (Array.isArray(res?.users)) {
            setUsers(res?.users)
        } else {
            setUsers([])
        }
    } catch (error) {
        setUsers([])
    }
}

export function handleGetCurrentUser(setCreatedByUser, setIsCurrentUserLoading) {
    setIsCurrentUserLoading(true);
    getMe().then((user) => {
        setCreatedByUser(user ?? null);
    }).catch(() => {
    }).finally(() => {
        setIsCurrentUserLoading(false);
    });
}