import { Box, Grid, Typography, Autocomplete, Popper, Chip, Tooltip, IconButton } from "@mui/material";
import { useState, useContext } from "react";
import Drawer from "@mui/material/Drawer";
import { Button } from "@mui/material";
import { CustomTextFieldForFaqTempalte } from "../../../../../components/CustomTextField";
import EditIcon from '@mui/icons-material/Edit';
import { getPhoneNumberWithInputMask } from "../../../../../utils/inputMasks";
import { removeInputMask } from '../../../../../utils/inputMasks'
import { isValidPhoneNumber } from '../../../../../utils/validators'
import { trimEmptySpaces } from "../../../brands/components/EditBrandTemplateFaqDrawer";
import { UserContext } from "../../../../../contexts/User";
import { USER_TYPES } from "../../../../../constants/users";

export default function Draw({ id, faq, faqs, setFaqs, faqToEdit, setFaqToEdit, setisEditFaq, keywords, isKeywordsLoading, drawerOpen, onClose }) {

    const [helperText, setHelperText] = useState(faq?.answer === "" ? "Answer is required" : "")
    const [isEditAnswer, setIsEditAnswer] = useState(false)
    const [isEditKeywords, setIsEditKeywords] = useState(false)
    const { userPreferences, me } = useContext(UserContext)

    const handleSaveEditedFaq = () => {
        if (faqToEdit?.answer === "") {
            setHelperText("Answer is required")
            return;
        }
        if (helperText !== "") {
            return;
        }
        let newFaqs = faqs.map((f, index) => {
            if (index === id) {
                return faqToEdit
            }
            return f
        })
        setFaqs(newFaqs)
        setisEditFaq(false)
        setIsEditAnswer(false)
        setIsEditKeywords(false)
        onClose();
    }

    const handleDrawerClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        setHelperText("")
        setFaqToEdit(faq)
        setisEditFaq(false)
        setIsEditAnswer(false)
        setIsEditKeywords(false)
        onClose();
    };

    const getFullKeywordFromKeywords = (kwords) => {
        let fullKeywords = []
        kwords?.forEach((kword) => {
            let fullKeyword = keywords?.find?.((k) => k?.keyword === kword)
            fullKeywords.push(fullKeyword)
        })
        return fullKeywords
    }

    const handleSetFaqs = (key, value, type) => {
        if (type === "Answer") {
            if (faqToEdit?.question === "Business Number") {
                setHelperText("")
                value = removeInputMask(value)
                if (!isValidPhoneNumber(value)) {
                    setHelperText("Invalid Phone Number")
                }
            } else {
                setHelperText("")
                if (value === "") setHelperText("Answer is required")
            }
            setFaqToEdit({ ...faqToEdit, answer: value })
        } else if (type === "Keywords") {
            let EditKeywords = value?.map?.((k) => k?.keyword)
            setFaqToEdit({ ...faqToEdit, keywords: EditKeywords })
        }
    }

    const handleSetFaqsByTags = (_, value) => {
        setFaqToEdit({ ...faqToEdit, keywords: value })
    }

    return <Drawer
        sx={{ zIndex: 1301 }} anchor="right" lassName="dock-drawer" open={drawerOpen} onClose={handleDrawerClose} >
        <Box sx={{ height: "auto", width: "700px", padding: "30px" }} >
            <Typography sx={{ marginBottom: "1em" }} variant="h5">Franchise Template FAQ Section</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{ height: "auto", width: "auto" }} >
                        <Typography variant="h6">Question</Typography>
                        <CustomTextFieldForFaqTempalte
                            required
                            sx={{ width: "100%" }}
                            multiline
                            value={faqToEdit?.question ?? null}
                            size="small"
                            margin="normal"
                            variant="outlined"
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ height: "auto", width: "auto" }} >
                        <Box sx={{ display: "flex" }}>
                            <Typography variant="h6">Answer</Typography>
                            {me?.level > USER_TYPES?.supervisor?.level &&
                                <Tooltip title="Edit" placement="top" arrow>
                                    <IconButton color={isEditAnswer ? "disabled" : "primary"} disabled={isEditAnswer} title='Edit Faq'
                                        onClick={() => {
                                            setIsEditAnswer(true)
                                        }
                                        }>
                                        <EditIcon style={{ height: "20px", width: "20px" }} />
                                    </IconButton>
                                </Tooltip>
                            }
                        </Box>
                        <CustomTextFieldForFaqTempalte
                            required
                            disabled={!isEditAnswer}
                            sx={{
                                width: "100%",
                                "& .MuiInputBase-input.Mui-disabled": {
                                    "-webkit-text-fill-color": userPreferences?.dark_mode_enabled ? "" : "#373A40 !important",
                                },
                            }}
                            error={helperText !== ""}
                            helperText={helperText}
                            value={faqToEdit?.question === "Business Number" ? getPhoneNumberWithInputMask(faqToEdit?.answer) : faqToEdit?.answer ?? null}
                            multiline
                            onChange={(e) => {
                                let value = trimEmptySpaces(e.target.value)
                                handleSetFaqs(id, value, "Answer")
                            }}
                            size="small"
                            margin="normal"
                            variant="outlined"
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ height: "auto", marginTop: "0.5em", width: "auto" }} >
                        <Box sx={{ display: "flex" }}>
                            <Typography variant="h6">Keywords</Typography>
                            {me?.level > USER_TYPES?.supervisor?.level &&
                                <Tooltip title="Edit" placement="top" arrow>
                                    <IconButton color={isEditKeywords ? "disabled" : "primary"} title='Edit Faq' disabled={isEditKeywords}
                                        onClick={() => {
                                            setIsEditKeywords(true)
                                        }
                                        }>
                                        <EditIcon style={{ height: "20px", width: "20px" }} />
                                    </IconButton>
                                </Tooltip>
                            }
                        </Box>
                        <Autocomplete
                            sx={{ width: "100%", marginTop: "1em" }}
                            size="small"
                            disabled={!isEditKeywords}
                            disablePortal={true}
                            PopperComponent={
                                (props) => {
                                    return <Popper {...props} placement="bottom" />
                                }
                            }
                            disableClearable={false}
                            multiple={true}
                            loading={isKeywordsLoading}
                            options={keywords}
                            getOptionLabel={(keyword) => keyword?.keyword}
                            renderOption={(props, keyword) => {
                                return <RenderInput {...props} content={keyword?.keyword} />
                            }}
                            renderTags={(keywords) => {
                                return keywords?.map?.((keyword) => {
                                    return <Chip
                                        size="small"
                                        sx={{
                                            marginRight: "0.3em",
                                            marginBottom: "0.6em",
                                            backgroundColor: !userPreferences?.dark_mode_enabled ? "" : !isEditKeywords ? "white" : "",
                                            color: !userPreferences?.dark_mode_enabled ? "" : !isEditKeywords ? "#686D76" : "",
                                            fontWeight: !isEditKeywords ? "bold" : "",
                                        }}
                                        disabled={!isEditKeywords}
                                        label={keyword?.keyword}
                                        onDelete={() => {
                                            handleSetFaqsByTags(id, faqToEdit?.keywords?.filter?.((k) => k !== keyword?.keyword))
                                        }}
                                    />
                                })
                            }}
                            value={getFullKeywordFromKeywords(faqToEdit?.keywords) ?? []}
                            onChange={(_, techniciansIds) => {
                                handleSetFaqs(id, techniciansIds, "Keywords")
                            }}
                            renderInput={(params) => {
                                let trimmedValue = params.inputProps.value?.trimStart();
                                return (
                                    <CustomTextFieldForFaqTempalte
                                        {...params}
                                        inputProps={{
                                            ...params.inputProps,
                                            value: trimmedValue
                                        }}
                                    />
                                );
                            }
                            } />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ display: "flex", gap: "1em", marginTop: "1.2em" }}>
                        <Button
                            sx={{ marginLeft: "auto" }}
                            type={"button"}
                            size=" large"
                            variant="contained"
                            className={"disable-btn-drawer"}
                            onClick={handleDrawerClose}
                        >
                            Cancel
                        </Button>
                        {me?.level > USER_TYPES?.supervisor?.level &&
                            <Button
                                type={"button"}
                                variant="contained"
                                size="large"
                                className={"btn-primary"}
                                onClick={() => handleSaveEditedFaq()}
                            >
                                Save
                            </Button>
                        }
                    </Box>
                </Grid>
            </Grid>
        </Box>
    </Drawer >
}

function RenderInput(props) {
    const { content } = props
    return (
        <Typography {...props}>
            {content}
        </Typography>
    );
}
