import { Box, Grid } from '@mui/material'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { UserContext } from '../../../../contexts/User'
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { getFirebaseJWTToken } from '../../../../services/agentScoreService';
import { getDatabase, ref, off, onValue, query, orderByChild, equalTo, limitToLast, get, startAfter, onChildAdded } from "firebase/database";
import RealTimeMetricsQueueTable from './RealTimeMetricsQueueTable';
import RealTimeMetricsAgentTable from './RealTimeMetricsAgentTable';
import RealTimeAgentStatus from './RealTimeAgentStatus';

let queueMetricsNodeRef;
let agentMetricsNodeRef;
let agentLogsNodeRef;
const queueMetricsDbNode = "QueueMetrics"
const agentMetricsDbNode = "AgentMetrics"
const realTimeAgentLogsDbNode = "Logs"

export default function RealTimeMetrics({ agents }) {

    const { me } = useContext(UserContext)
    const [isQueueMetricsLoading, setIsQueueMetricsLoading] = useState(false)
    const [isAgentMetricsLoading, setIsAgentMetricsLoading] = useState(false)
    const [isAgentLogsLoading, setIsAgentLogsLoading] = useState(false)
    const [queueMetricsData, setQueueMetricsData] = useState([])
    const [agentMetricsData, setAgentMetricsData] = useState([])
    const [logs, setLogs] = useState([])

    const initiateFirebaseConnection = useCallback(async (me) => {
        try {
            setIsQueueMetricsLoading(true)
            setIsAgentMetricsLoading(true)
            setIsAgentLogsLoading(true)
            let token = await getFirebaseJWTToken(me.user_id)
            const auth = getAuth();
            await signInWithCustomToken(auth, token)
            const database = getDatabase();
            queueMetricsNodeRef = ref(database, queueMetricsDbNode)
            agentMetricsNodeRef = ref(database, agentMetricsDbNode)
            agentLogsNodeRef = ref(database, realTimeAgentLogsDbNode)
            getRealTimeQueueMetrics()
            getRealTimeAgentMetrics()
            handleGetAgentLogs()
        } catch (error) {
            setIsQueueMetricsLoading(false)
            setIsAgentMetricsLoading(false)
        }
    }, []);

    useEffect(() => {
        initiateFirebaseConnection(me)
        return () => {
            off(queueMetricsNodeRef)
            off(agentMetricsNodeRef)
            off(agentLogsNodeRef)
        }
    }, [initiateFirebaseConnection, me])

    useEffect(() => {
        if (logs.length > 0) {
            handleGetAgentStatusfromRealTimeDb()
        }
    }, [isAgentLogsLoading])

    const handleGetAgentLogs = async () => {
        try {
            let agentlogs = await handleGetLastLogOfAgents(agents)
            setLogs(agentlogs)
        } catch (error) {
        } finally {
            setIsAgentLogsLoading(false)
        }
    }

    const handleGetLastLogOfAgents = async (agents) => {
        let logs = []
        for (const id of agents) {
            const agentIdQuery = query(agentLogsNodeRef, orderByChild("AgentId"), equalTo(id), limitToLast(1));
            let snapshot = await get(agentIdQuery)
            if (snapshot.exists()) {
                let obj = {}
                let data = Object.values(snapshot.val())
                obj.AgentId = data[0].AgentId
                obj.Status = data[0].Status
                logs.push(obj)
            } else {
                let obj = {}
                obj.AgentId = id
                obj.Status = "Not Started"
                logs.push(obj)
            }
        }
        return logs
    }

    async function getRealTimeQueueMetrics() {
        const agentIdQuery = query(queueMetricsNodeRef);
        onValue(agentIdQuery, (snapshot) => {
            const data = snapshot.val();
            if (Array.isArray(data?.queue_details)) {
                let processedData = []
                processedData = processQueueMetrics(data?.queue_details)
                setQueueMetricsData(processedData)
            }
            setIsQueueMetricsLoading(false)
        });
    }

    async function getRealTimeAgentMetrics() {
        const agentIdQuery = query(agentMetricsNodeRef);
        onValue(agentIdQuery, (snapshot) => {
            let data = snapshot.val();
            if (Array.isArray(data?.agent_details)) {
                setAgentMetricsData(data?.agent_details)
            }
            setIsAgentMetricsLoading(false)
        });
    }

    const handleGetAgentStatusfromRealTimeDb = async () => {
        const currentDate = new Date();
        const currentUtcTime = currentDate.toISOString();
        const agentStatusQuery = query(agentLogsNodeRef, orderByChild("FirbaseRecordCreatedAt"), startAfter(currentUtcTime));
        onChildAdded(agentStatusQuery, (snapshot) => {
            const data = snapshot.val();
            let log = logs.find((log) => log.AgentId === data.AgentId)
            if (log) {
                log.Status = data.Status
                setLogs([...logs])
            }
        });
    };


    function processQueueMetrics(queueMetrics) {
        let queueMetricsData = []
        queueMetrics?.forEach((queueMetric) => {
            let queueMetricData = {}
            queueMetricData["queue_name"] = queueMetric?.queue_name
            queueMetric?.metrics?.forEach((data) => {
                if (data?.value !== undefined) {
                    queueMetricData[data?.metric] = data?.value
                } else {
                    queueMetricData[data?.metric] = '-'
                }
            })
            queueMetricsData.push(queueMetricData)
        })
        return queueMetricsData
    }

    return (
        <Box sx={{ disply: 'flex', flexDirection: 'row' }}>
            <Grid >
                <Grid item xs={8} md={6} sx={{ marginRight: "20px" }}>
                    <RealTimeMetricsQueueTable isLoading={isQueueMetricsLoading} queueMetricsData={queueMetricsData} />
                    <Box className="horizontal-line"></Box>
                </Grid>
                <Grid item xs={4} md={6}>
                    <Box sx={{ height: '600px', display: 'flex', flexDirection: 'row', gap: '5px' }}>
                        <Box sx={{ height: "100%", width: '40%' }}>
                            <RealTimeAgentStatus isLoading={isAgentLogsLoading} logs={logs} />
                        </Box>
                        <Box className="vertical-line"></Box>
                        <Box sx={{ height: '100%', width: '60%', marginRight: "20px" }}>
                            <RealTimeMetricsAgentTable isLoading={isAgentMetricsLoading} agentMetricsData={agentMetricsData} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}
