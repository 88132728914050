import { useEffect, useState } from "react";
import { handleChangeLeadCallbackStatus, handleGetConfigurations } from "../../state-services/eightByEight/configurations";
import { Box, CircularProgress, FormControlLabel, FormGroup, Switch, TextField, Typography } from "@mui/material";
import RefreshErrorView from '../refreshView/RefreshErrorView';

export default function EightByEightSettingsSection(props) {
    const { showNotification } = props
    const [eightByEightConfigurations, setEightByEightConfigurations] = useState();
    const [eightByEightConfigurationsLoading, setEightByEightConfigurationsLoading] = useState();
    const [eightByEightConfigurationsLoadingError, setEightByEightConfigurationsLoadingError] = useState();
    const [toggleRefresh, setToggleRefresh] = useState(true)

    useEffect(() => {
        handleGetConfigurations(setEightByEightConfigurations, setEightByEightConfigurationsLoading, setEightByEightConfigurationsLoadingError)
    }, [toggleRefresh])

    function handleChange(e) {
        const isEnabled = e.target.checked ? 1 : 0
        handleChangeLeadCallbackStatus(eightByEightConfigurations.id, isEnabled, eightByEightConfigurations, setEightByEightConfigurations, showNotification)
    }

    return eightByEightConfigurationsLoading || eightByEightConfigurationsLoadingError ?
        <Box sx={{ width: "100%", margin: "1em", display: "flex", justifyContent: "center" }} >
            {eightByEightConfigurationsLoading ? <CircularProgress /> : <RefreshErrorView onRefresh={() => setToggleRefresh(!toggleRefresh)} />}</Box>
        : <Box>
            <Typography marginBlock="1em" fontWeight="bold" component="h3" variant="subtitle1" >Credentials</Typography>
            <Box sx={{ display: "flex", flexDirection: "row", gap: "1em", flexWrap: "wrap" }} >
                <ReadOnlyTextField label={"Tenant Name"} value={eightByEightConfigurations?.tenant_name ?? ""} />
                <ReadOnlyTextField label={"Tenant URL"} value={eightByEightConfigurations?.tenent_url ?? ""} />
                <ReadOnlyTextField label={"Data Request Token"} value={eightByEightConfigurations?.data_request_token ?? ""} />
                <ReadOnlyTextField label={"Action Request Token"} value={eightByEightConfigurations?.action_request_token ?? ""} />
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" gap="1em" >
                <Typography marginBlock="1em" fontWeight="bold" component="h3" variant="subtitle1" >Outbound</Typography>
                <FormGroup>
                    <FormControlLabel control=
                        {<Switch
                            checked={eightByEightConfigurations?.is_lead_outbound_enabled}
                            onChange={handleChange}
                        />}
                        label="Enable" />
                </FormGroup>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", gap: "1em", flexWrap: "wrap" }} >
                <ReadOnlyTextField label={"Call expiration time"} value={`${eightByEightConfigurations?.outbound_call_expiration_time_in_seconds} Seconds` ?? ""} />
                <ReadOnlyTextField label={"Waiting time before retry"} value={`${eightByEightConfigurations?.outbound_waiting_time_before_retry_in_seconds} Seconds` ?? ""} />
                <ReadOnlyTextField label={"Maximum accepted delay"} value={`${eightByEightConfigurations?.outbound_maximum_accepted_delay_in_seconds} Seconds` ?? ""} style={{ minWidth: "15em" }} />
            </Box>
        </Box>
}

function ReadOnlyTextField(props) {
    const { value, label, style } = props
    return <TextField
        size={"small"}
        sx={style}
        label={label}
        value={value}
        InputProps={{
            readOnly: true,
        }}
    />
}