import React from 'react'
import ReactApexChart from 'react-apexcharts';
import { getDesignTokens } from '../../../../contexts/ThemeContextProvider';
import { UserContext } from '../../../../contexts/User';
import { useContext } from 'react';
import { createTheme } from '@mui/material';

export default function MiddlewearStatisticsBarChart({ formatterFunction, data, dataType, fileName }) {
    const { userPreferences } = useContext(UserContext)
    const themeOptions = getDesignTokens(userPreferences.dark_mode_enabled === 1 ? 'dark' : 'light'); // You can also use 'dark' to switch to dark mode
    const theme = createTheme(themeOptions);

    const decideTheSeries = (dataType) => {
        let series = []
        if (dataType === "All") {
            series = [
                {
                    name: "Jobs",
                    data: formatterFunction(data, "Jobs"),
                },
                {
                    name: "Leads",
                    data: formatterFunction(data, "Leads"),
                },
                {
                    name: "Estimates",
                    data: formatterFunction(data, "Estimates"),
                },
                {
                    name: "Redirected To CRM",
                    data: formatterFunction(data, "Redirected"),
                }
            ]
        } else if (dataType === "Inbound") {
            series = [
                {
                    name: "Jobs",
                    data: formatterFunction(data, "Jobs"),
                },
                {
                    name: "Leads",
                    data: formatterFunction(data, "Leads"),
                },
                {
                    name: "Redirected To CRM",
                    data: formatterFunction(data, "Redirected"),
                }
            ]
        } else if (dataType === "Outbound") {
            series = [
                {
                    name: "Jobs",
                    data: formatterFunction(data, "Jobs"),
                },
                {
                    name: "Leads",
                    data: formatterFunction(data, "Leads"),
                },
                {
                    name: "Estimates",
                    data: formatterFunction(data, "Estimates"),
                }
            ]
        }
        return series
    }

    return (
        <ReactApexChart
            options={{
                chart: {
                    width: 800,
                    height: 500,
                    type: "bar",
                    dropShadow: {
                        enabled: true,
                        color: '#111',
                        top: -1,
                        left: 3,
                        blur: 3,
                        opacity: 0.2
                    },
                    zoom: {
                        enabled: true,
                    },
                    stacked: true,
                    offsetX: 20,
                    offsetY: 20,
                    animations: {
                        initialAnimation: {
                            enabled: false
                        },
                    },
                    background: themeOptions.palette.mode === 'dark' ? "#1F2123" : "#ffffff",
                    toolbar: {
                        show: true,
                        export: {
                            csv: {
                                filename: fileName,
                            },
                            svg: {
                                filename: fileName,
                            },
                            png: {
                                filename: fileName,
                            }
                        },
                    },

                },
                theme: {
                    mode: themeOptions.palette.mode
                },
                colors: ["#038FFA", "#68E497", "#FF4747", "#FFC100"],
                dataLabels: {
                    enabled: true,
                    dropShadow: {
                        blur: 3,
                        opacity: 0.8
                    },
                    style: {
                        colors: [theme?.palette?.text?.primary],
                    },
                },
                xaxis: {
                    tickPlacement: 'on',
                    title: {
                        text: 'Date Range',
                        offsetX: 400,
                        style: {
                            fontSize: '16px',
                            fontWeight: 600,
                            color: theme?.palette?.text?.primary,
                        },
                    },
                    labels: {
                        rotate: -45,
                        rotateAlways: true,
                        show: true,
                        style: {
                            colors: theme?.palette?.text?.primary,
                        }
                    },
                },
                yaxis: {
                    title: {
                        text: 'Quantity',
                        rotate: 360,
                        offsetX: -8,
                        offsetY: -150,
                        style: {
                            fontSize: '16px',
                            fontWeight: 600,
                            color: theme?.palette?.text?.primary,
                        },
                    },
                    labels: {
                        show: true,
                        style: {
                            colors: theme?.palette?.text?.primary,
                        }
                    },
                },
            }}
            series={decideTheSeries(dataType)}
            type="bar"
            height={530}
            width={1230}
        />
    )
}
