import { Box } from "@mui/material"
import RenderInput from "../../../components/servicetitan/RenderInput"
import { STATUS_EMOJI } from "../../../constants/common"
import { getCredentialsMissingWarning, isFranchiseDisabled } from "../../../utils/miscellaneous"
import { TEMPORY_FAILURE } from "../../../constants/errorMessages"
import { EncryptionWarningBadge } from "../../../components/EncryptionWarningBadge"
import { getPhoneNumberWithInputMask } from "../../../utils/inputMasks"

export function renderFranchiseOptionWithEncryptionBadge(props, franchise) {
    return <Box key={franchise?.id}  position={"relative"}>
        <Box {...props} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" paddingRight="1em">
            <RenderInput content={franchise?.franchise_name ?? ""} />
            <Box flexGrow={2} />
            <RenderInput content={franchise?.drive_distance?.drive_distance_text ?? ""} />
        </Box>
        {isFranchiseDisabled(franchise) ? <EncryptionWarningBadge title={franchise?.is_encryption_failed ? TEMPORY_FAILURE : getCredentialsMissingWarning(franchise)} bottom={"3px"} right={"3px"} /> : <></>}
    </Box>
}

export function renderFranchiseOptionWithStatusAndEncryptionBadge(props, franchise) {
    const newFranchise = franchise ? { ...franchise } : {}
    newFranchise.franchise_name = getFranchiseOptionLabel(franchise)
    return renderFranchiseOptionWithEncryptionBadge(props, newFranchise)
}

export function renderFranchiseOption(props, franchise) {
    return <RenderInput {...props} key={franchise?.id} content={getFranchiseOptionLabel(franchise)} />
}

export function getFranchiseOptionLabel(franchise) {
    const statusSymbol = franchise?.is_enabled === 0 ? STATUS_EMOJI.inactive : STATUS_EMOJI.active
    return `${statusSymbol}  ${franchise.franchise_name}`
}

export function getChannelOptionLabel(channel) {
    const statusSymbol = channel?.is_enabled === 0 ? STATUS_EMOJI.inactive : STATUS_EMOJI.active
    return `${statusSymbol}  ${getPhoneNumberWithInputMask(channel?.channel_id)}`
}