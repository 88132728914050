import { Autocomplete, Avatar, Box, IconButton, Tooltip, Typography } from "@mui/material";
import CustomTextField from "../../../components/CustomTextField";
import { useContext, useEffect, useMemo, useState } from "react";
import { CommonContext } from "../../../contexts/CommonContextProvider";
import { TASK_TYPES } from "../../dashboard/schedule/component/TasksSection";
import { handleGetTasks, handleTaskComplete, handleViewTaskPopUp } from "../../../state-services/schedule/schedule";
import moment from "moment";
import LoadingView from "../../../components/refreshView/LoadingView";
import RefreshErrorView from "../../../components/refreshView/RefreshErrorView";
import { UserContext } from "../../../contexts/User";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useHistory } from "react-router-dom";
import NoContentView from "../../../components/refreshView/NoContentView";
import CallMadeIcon from '@mui/icons-material/CallMade';
import { getTenantTimeZoneTimeString } from "../../../utils/time/time";

export default function ToDoClient(props) {
    const { toDoFilter, setToDoFilter, showNotification } = useContext(CommonContext)

    const [tasks, setTasks] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [tasksRefreshToggle, setTasksRefreshToggle] = useState(false)

    useEffect(() => {
        if (toDoFilter?.assignedTo) handleGetTasks(toDoFilter?.taskType, toDoFilter?.brandId, toDoFilter?.startOnOrAfter, toDoFilter?.startOnOrBefore, toDoFilter?.createdOnOrAfter, toDoFilter?.createdOnOrBefore, toDoFilter?.assignedTo, toDoFilter?.assignedBy, toDoFilter?.isCompleted, 0, 0, setTasks, (count) => { }, setIsLoading, setIsError, showNotification)
    }, [tasksRefreshToggle, toDoFilter, showNotification])

    return <Box width="100%" height="100%" display="flex" flexDirection="column" >
        <FilterArea filter={toDoFilter} setFilter={setToDoFilter} />
        <Box sx={{ flexShrink: "0", backgroundColor: "primary.main", height: "4px", width: "100%" }} />
        <Box sx={{ flexGrow: 2, overflowY: "scroll" }} >
            {isLoading ? <LoadingView /> : isError ? <RefreshErrorView onRefresh={() => setTasksRefreshToggle((t) => !t)} /> : tasks?.length === 0 ? <NoContentView message={"No results for the filtration"} /> : tasks?.map?.(task => <TaskCard key={task?.id} task={task} setTasksRefreshToggle={setTasksRefreshToggle} />)}
        </Box>
    </Box>
}


function TaskCard(props) {
    const { task, setTasksRefreshToggle } = props
    const { showNotification, generalTenantConfiguration, timezones } = useContext(CommonContext)
    const history = useHistory()
    const isDisabled = useMemo(() => task?.is_completed === 1, [task?.is_completed])

    function handleOnTaskCompleteClick() {
        handleTaskComplete(task?.id, () => { setTasksRefreshToggle((t) => !t) }, showNotification)
        handleViewTaskPopUp(history, task?.popup_url, showNotification)
    }

    return <Box className="dialog-color" sx={{ margin: "0.5em", padding: "1em", borderRadius: "1em", display: "flex", flexDirection: "row", gap: "0.5em", alignItems: "start" }} >
        <Box sx={{ display: "flex", flexDirection: "column" }} >
            <Box sx={{ display: "flex", flexDirection: "row", gap: "1em" }}>
                <Typography variant="subtitle1" >{task?.task_type}</Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", gap: "1em" }}>
                <Typography >{`${getTenantTimeZoneTimeString(task?.start_time_utc, generalTenantConfiguration?.time_zone, timezones)}`}</Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", gap: "1em" }}>
                <Typography >{`${task?.is_completed ? "Completed" : "Pending"}`}</Typography>
            </Box>
        </Box>
        <Box sx={{ flexGrow: 2 }} />
        <Box sx={{ display: "flex", flexDirection: "row", gap: "0.5em" }}>
            <Tooltip title="Show task pop-up">
                <IconButton sx={{ padding: 0, margin: 0 }} disabled={!task?.popup_url} onClick={() => handleViewTaskPopUp(history, task?.popup_url, showNotification)} >
                    <Avatar sx={{ width: "1.5em", height: "1.5em", backgroundColor: task?.popup_url ? "primary.main" : "primary.disabled" }} ><CallMadeIcon fontSize="small" /></Avatar>
                </IconButton>
            </Tooltip >
            <Tooltip title="Complete">
                <IconButton sx={{ padding: 0, margin: 0, color: "green" }} disabled={isDisabled} onClick={handleOnTaskCompleteClick} >
                    <CheckCircleIcon fontSize="large" />
                </IconButton>
            </Tooltip >
        </Box>
    </Box>
}


function FilterArea(props) {
    const { filter, setFilter } = props

    const { me } = useContext(UserContext)
    const { generalTenantConfiguration } = useContext(CommonContext)
    const filtrationOptions = useMemo(() => Object.values(getFilterOptions(me?.user_id, generalTenantConfiguration?.time_zone)), [me?.user_id])

    useEffect(() => {
        if (!filter?.id && me?.user_id) setFilter(getFilterOptions(me?.user_id, generalTenantConfiguration?.time_zone)?.TodayTasks?.getFilterState?.())
    }, [me?.user_id, filter?.id, setFilter, generalTenantConfiguration?.time_zone])

    return <Box padding="1em" width="100%" display="flex" flexDirection="column" gap="0.5em" >
        <Autocomplete
            sx={{ width: "100%" }}
            size="small"
            value={filter?.id ? filtrationOptions.find?.(option => option?.label === filter?.id) : null}
            onChange={(e, option) => {
                setFilter(option?.getFilterState?.());
            }}
            disableClearable
            disablePortal
            options={filtrationOptions}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => <CustomTextField {...params} label="Filter" />}
        />
    </Box>
}

function getFilterOptions(currentUserId, timezone) {
    return {
        TodayTasks: {
            label: "Today",
            getFilterState: function () {
                return {
                    id: "Today",
                    taskType: TASK_TYPES.ALL.value,
                    brandId: undefined,
                    startOnOrAfter: timezone ? moment().tz(timezone)?.startOf?.("day")?.toISOString?.() : moment()?.startOf?.("day")?.toISOString?.(),
                    startOnOrBefore: timezone ? moment().tz(timezone)?.endOf?.("day")?.toISOString?.() : moment()?.endOf?.("day")?.toISOString?.(),
                    createdOnOrAfter: undefined,
                    createdOnOrBefore: undefined,
                    assignedTo: currentUserId,
                    assignedBy: undefined,
                    isCompleted: undefined,
                }
            }
        },
        TodayUncompletedTasks: {
            label: "Today - Not Completed",
            getFilterState: function () {
                return {
                    id: "Today - Not Completed",
                    taskType: TASK_TYPES.ALL.value,
                    brandId: undefined,
                    startOnOrAfter: timezone ? moment().tz(timezone)?.startOf?.("day")?.toISOString?.() : moment()?.startOf?.("day")?.toISOString?.(),
                    startOnOrBefore: timezone ? moment().tz(timezone)?.endOf?.("day")?.toISOString?.() : moment()?.endOf?.("day")?.toISOString?.(),
                    createdOnOrAfter: undefined,
                    createdOnOrBefore: undefined,
                    assignedTo: currentUserId,
                    assignedBy: undefined,
                    isCompleted: false,
                }
            }
        },
        TodayCompletedTasks: {
            label: "Today - Completed",
            getFilterState: function () {
                return {
                    id: "Today - Completed",
                    taskType: TASK_TYPES.ALL.value,
                    brandId: undefined,
                    startOnOrAfter: timezone ? moment().tz(timezone)?.startOf?.("day")?.toISOString?.() : moment()?.startOf?.("day")?.toISOString?.(),
                    startOnOrBefore: timezone ? moment().tz(timezone)?.endOf?.("day")?.toISOString?.() : moment()?.endOf?.("day")?.toISOString?.(),
                    createdOnOrAfter: undefined,
                    createdOnOrBefore: undefined,
                    assignedTo: currentUserId,
                    assignedBy: undefined,
                    isCompleted: true,
                }
            }
        },
        YesterdayNotCompletedTasks: {
            label: "Yesterday - Not Completed",
            getFilterState: function () {
                return {
                    id: "Yesterday - Not Completed",
                    taskType: TASK_TYPES.ALL.value,
                    brandId: undefined,
                    startOnOrAfter: timezone ? moment().tz(timezone)?.subtract(1, "d")?.startOf?.("day")?.toISOString?.() : moment().tz(timezone)?.subtract(1, "d")?.startOf?.("day")?.toISOString?.(),
                    startOnOrBefore: timezone ? moment().tz(timezone)?.subtract(1, "d")?.endOf?.("day")?.toISOString?.() : moment().tz(timezone)?.subtract(1, "d")?.endOf?.("day")?.toISOString?.(),
                    createdOnOrAfter: undefined,
                    createdOnOrBefore: undefined,
                    assignedTo: currentUserId,
                    assignedBy: undefined,
                    isCompleted: false,
                }
            }
        },
    }
}
