export const EMAIL_ALREADY_EXISTS = 'Email Already Exists'
export const INVALID_EMAIL = 'Invalid Email Address'
export const INVALID_PHONE = "Invaild Phone Number"
export const INITIAL_OWNER = { owner_name: '', owner_email: '', owner_phone: "", is_enabled: 1 }
export const INITIAL_EDIT_INDEX = -1
export const NEW_OWNER_ID = -1
export const NEW_OWNER_KEY = "NEW_OWNER_KEY"
export const SAVE_HELPER_MESSAGE = "Click 💾 to insert"
export const UPDATE_HELPER_MESSAGE = "Click 💾 to update"
export const MAX_OWNER_NAME_LENGTH = 50
export const MAX_OWNERS_COUNT = 10