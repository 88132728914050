import { getChannelIdValidationResponse, getFranchiseBrandChannels } from "../../services/channelIds/channelIds";
import { retry, shouldRetry } from "../../state-services-dublicate/serviceTitan";

export async function handleChannelIdValidation(channelIds = [], excludeFranchiseId = 0) {
    try {
        const response = await getChannelIdValidationResponse(channelIds, excludeFranchiseId);
        return { isValid: response?.is_valid, message: response?.message };
    } catch (error) {
        return { isValid: true, message: "" };
    }
}

export async function handleGetFranchiseBrandChannels(brandId = 0, franchiseId = 0, setChannels, setIsChannelsLoading, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    try {
        setChannels([])
        if (!franchiseId) return
        setIsChannelsLoading(true)
        const res = await getFranchiseBrandChannels(brandId, franchiseId)
        if (Array.isArray(res)) setChannels(res)
    } catch (err) {
        if (shouldRetry(backoffConfig) && err?.status !== 400 && err?.status !== 404) retry(() => handleGetFranchiseBrandChannels(franchiseId, setChannels, setIsChannelsLoading, backoffConfig), backoffConfig)
    } finally {
        setIsChannelsLoading(false)
    }
}

