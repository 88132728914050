import React from 'react'
import { useState } from 'react';
import { Box, TableCell, TableRow, Autocomplete, Popper, Typography, Chip, Tooltip, IconButton } from '@mui/material'
import { CustomTextFieldForFaqTempalte } from '../../../../../../src/components/CustomTextField'
import { getPhoneNumberWithInputMask } from '../../../../../utils/inputMasks';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { removeInputMask } from '../../../../../utils/inputMasks'
import { isValidPhoneNumber } from '../../../../../utils/validators'
import DrawerForEnterFaq from './DrawerForEnterFaq';
import { trimEmptySpaces } from '../../../brands/components/EditBrandTemplateFaqDrawer';

export default function FaqRow({ id, faq, faqs, setFaqs, isKeywordsLoading, isKeywordsLoadingError, keywords }) {

    const [helperText, setHelperText] = useState("")
    const [isFieldsChanged, setIsFieldsChanged] = useState(false)
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    const handleSetFaqs = (key, value, type) => {
        let newFaqs = [...faqs]
        if (type === "Answer") {
            if (faq?.question === "Business Number") {
                setHelperText("")
                value = removeInputMask(value)
                if (!isValidPhoneNumber(value)) {
                    setHelperText("Invalid Phone Number")
                }
            } else {
                setHelperText("")
                if (value === "") setHelperText("Answer is required")
            }
            newFaqs[key].answer = value
        } else if (type === "Keywords") {
            newFaqs[key].keywords = value?.map?.((k) => k?.keyword)?.sort?.((a, b) => a.length - b.length);
        }
        setIsFieldsChanged(true)
        setFaqs(newFaqs)
    }

    const getFullKeywordFromKeywords = (kwords) => {
        let fullKeywords = []
        kwords?.forEach((kword) => {
            let fullKeyword = keywords?.find?.((k) => k?.keyword === kword)
            fullKeywords.push(fullKeyword)
        })
        return fullKeywords
    }

    const handleSetFaqsByTags = (_, value) => {
        let newFaqs = [...faqs]
        newFaqs[id].keywords = value
        setFaqs(newFaqs)
    }

    const fontColor = {
        style: { color: 'rgb(50, 50, 50)' }
    }

    return <>
        <TableRow key={id} >
            <TableCell colSpan={1} >
                <Box sx={{ height: "auto", width: "auto" }} >
                    <CustomTextFieldForFaqTempalte
                        inputProps={{
                            fontColor
                        }}
                        multiline
                        maxRows={1}
                        disabled={true}
                        required
                        sx={{
                            width: "100%",
                        }}
                        value={faq?.question ?? null}
                        size="small"
                        margin="normal"
                        variant="outlined"
                    />
                </Box>
            </TableCell>
            <TableCell colSpan={1} >
                <Box sx={{ height: "4.5em", width: "auto" }} >
                    <CustomTextFieldForFaqTempalte
                        required
                        sx={{ width: "100%" }}
                        inputProps={{
                            "data-test": "faq_answer"
                        }}
                        error={helperText !== ""}
                        helperText={helperText}
                        value={faq?.question === "Business Number" ? getPhoneNumberWithInputMask(faq?.answer) : faq?.answer ?? null}
                        multiline
                        maxRows={1}
                        onChange={(e) => {
                            let value = trimEmptySpaces(e.target.value)
                            handleSetFaqs(id, value, "Answer")
                        }}
                        size="small"
                        margin="normal"
                        variant="outlined"

                    />
                </Box>
            </TableCell>
            <TableCell colSpan={1}>
                <Box sx={{ width: "auto" ,marginTop: "0.5em" }} >
                    <Autocomplete
                        sx={{ 
                            width: "100%",
                        }}
                        size="small"
                        disablePortal={true}
                        PopperComponent={
                            (props) => {
                                return <Popper {...props} placement="top" />
                            }
                        }
                        disableClearable={false}
                        multiple={true}
                        loading={isKeywordsLoading}
                        options={keywords}
                        getOptionLabel={(keyword) => keyword?.keyword}
                        renderOption={(props, keyword) => {
                            return <RenderInput {...props} content={keyword?.keyword} />
                        }}
                        renderTags={(keywords) => {
                            return keywords?.map?.((keyword) => {
                                return <Chip
                                    size="small"
                                    sx={{
                                        marginRight: "0.3em",
                                    }}
                                    label={keyword?.keyword}
                                    onDelete={() => {
                                        handleSetFaqsByTags(id, faq?.keywords?.filter?.((k) => k !== keyword?.keyword))
                                    }}
                                />
                            })
                        }}
                        limitTags={1}
                        getLimitTagsText={(more) => `+${more} more`}
                        value={getFullKeywordFromKeywords(faq?.keywords) ?? []}
                        onChange={(_, techniciansIds) => {
                            handleSetFaqs(id, techniciansIds, "Keywords")
                        }}
                        renderInput={(params) => <CustomTextFieldForFaqTempalte {...params} label="Keywords" />} />
                </Box>
            </TableCell>
            <TableCell align="center">
                <>
                    <Tooltip title="View & Edit" placement="top" arrow>
                        <IconButton disabled={!isFieldsChanged} color="primary" title='Edit Faq'
                            onClick={() => {
                                setIsDrawerOpen(true)
                            }
                            }>
                            <VisibilityIcon style={{ height: "20px", width: "20px" }} />
                        </IconButton>
                    </Tooltip>
                </>
            </TableCell>
        </TableRow>
        <DrawerForEnterFaq id={id} faq={faq} keywords={keywords} helperText={helperText} setHelperText={setHelperText} isKeywordsLoading={isKeywordsLoading} handleSetFaqs={handleSetFaqs} handleSetFaqsByTags={handleSetFaqsByTags} drawerOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}></DrawerForEnterFaq>
    </>

}


function RenderInput(props) {
    const { content } = props
    return (
        <Typography {...props}>
            {content}
        </Typography>
    );
}

