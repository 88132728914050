import { Box, Grid, Typography, Autocomplete, Popper, Chip, Tooltip, IconButton } from "@mui/material";
import { useState, useContext } from "react";
import Drawer from "@mui/material/Drawer";
import { Button } from "@mui/material";
import CustomTextField from '../../../../components/CustomTextField';
import EditIcon from '@mui/icons-material/Edit';
import { UserContext } from "../../../../contexts/User";
import { USER_TYPES } from "../../../../constants/users";

export default function EditBrandTemplateFaqDrawer({ key, id, faqTemplateErros, setFaqTemplateErros, isAddNewQuestion, questionToEdit, setQuestionToEdit, faq, faqs, setFaqs, isKeywordsLoading, keywords, drawerOpen, onClose }) {

    const [helperTextForQuestion, setHelperTextForQuestion] = useState("")
    const [helperTextForAnswer, setHelperTextForAnswer] = useState("")
    const [isEditQuestion, setIsEditQuestion] = useState(false)
    const [isEditAnswer, setIsEditAnswer] = useState(false)
    const [isEditKeywords, setIsEditKeywords] = useState(false)
    const { userPreferences, me } = useContext(UserContext)

    const handleSetFaqs = (value, type) => {
        setHelperTextForAnswer("")
        setHelperTextForQuestion("")
        let faqToEdit = { ...questionToEdit }
        if (type === "Answer") {
            faqToEdit.answer = value
        } else if (type === "Keywords") {

            faqToEdit.keywords = value?.map?.((k) => k?.keyword)
        } else if (type === "Question") {
            faqToEdit.question = value
        }
        setQuestionToEdit(faqToEdit)
        if (faqToEdit?.answer !== "" && faqToEdit?.question === "") {
            setHelperTextForQuestion("Please provide a question")
        } else if (faqToEdit?.question !== "" && faqToEdit?.answer === "") {
            setHelperTextForAnswer("Please provide an answer")
        } else if (faqToEdit?.question === "" && faqToEdit?.answer === "" && faqToEdit?.keywords?.length !== 0) {
            setHelperTextForQuestion("Please provide a question")
            setHelperTextForAnswer("Please provide an answer")
        }
    }

    const handleDeleteKeyword = (keywords) => {
        setHelperTextForAnswer("")
        setHelperTextForQuestion("")
        let faqToEdit = { ...questionToEdit }
        faqToEdit.keywords = keywords
        setQuestionToEdit(faqToEdit)
        if (faqToEdit?.answer !== "" && faqToEdit?.question === "") {
            setHelperTextForQuestion("Please provide a question")
        } else if (faqToEdit?.question !== "" && faqToEdit?.answer === "") {
            setHelperTextForAnswer("Please provide an answer")
        } else if (faqToEdit?.question === "" && faqToEdit?.answer === "" && faqToEdit?.keywords?.length !== 0) {
            setHelperTextForQuestion("Please provide a question")
            setHelperTextForAnswer("Please provide an answer")
        }
    }

    const getFullKeywordFromKeywords = (kwords) => {
        let fullKeywords = []
        kwords?.forEach((kword) => {
            let fullKeyword = keywords?.find?.((k) => k?.keyword === kword)
            fullKeywords.push(fullKeyword)
        })
        return fullKeywords
    }

    const saveEditedFaq = () => {
        let faqToEdit = { ...questionToEdit }
        if (faqToEdit?.answer !== "" && faqToEdit?.question === "") {
            setHelperTextForQuestion("Please provide a question")
            return;
        } else if (faqToEdit?.question !== "" && faqToEdit?.answer === "") {
            setHelperTextForAnswer("Please provide an answer")
            return;
        } else if (faqToEdit?.question === "" && faqToEdit?.answer === "" && faqToEdit?.keywords?.length !== 0) {
            setHelperTextForQuestion("Please provide a question")
            setHelperTextForAnswer("Please provide an answer")
            return;
        }
        setQuestionToEdit(null)
        let newFaqs = [...faqs]
        let index = newFaqs.findIndex((f) => f.id === faqToEdit.id)
        newFaqs[index] = faqToEdit
        let faqTemplateError = faqTemplateErros?.find?.((error) => error?.id === id)
        if (faqTemplateError) {
            let answer = faqToEdit.answer
            let question = faqToEdit.question
            if (answer !== "") {
                faqTemplateError.answer = undefined
            }
            if (question !== "") {
                faqTemplateError.question = undefined
            }
        }
        setFaqs(newFaqs)
    }

    const checkIfFieldIsRequired = (field) => {
        if (field === "question") {
            return (questionToEdit?.answer === "" && questionToEdit?.question !== "") && id === questionToEdit?.id ? true : false
        } else if (field === "answer") {
            return (questionToEdit?.question === "" && questionToEdit?.answer !== "") && id === questionToEdit?.id ? true : false
        }
    }

    const handleDrawerClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        setQuestionToEdit(null)
        let newFaqTemplateErros = faqTemplateErros?.filter?.((error) => error?.id !== id)
        setFaqTemplateErros(newFaqTemplateErros)
        onClose()
    }

    return <Drawer
        sx={{ zIndex: 1301 }} anchor="right" lassName="dock-drawer" open={drawerOpen} onClose={handleDrawerClose} >
        <Box sx={{ height: "auto", width: "700px", padding: "30px" }} >
            <Typography sx={{ marginBottom: "1em" }} variant="h5">Brand Template FAQ Section</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{ height: "auto", width: "auto" }} >
                        <Box sx={{ display: "flex" }}>
                            <Typography variant="h6">Question</Typography>
                            {me?.level > USER_TYPES?.supervisor?.level &&
                                <Tooltip title="Edit" placement="top" arrow>
                                    <IconButton color={isEditQuestion ? "disabled" : "primary"} title='Edit Faq' disabled={isEditQuestion}
                                        onClick={() => {
                                            setIsEditQuestion(true)
                                        }
                                        }>
                                        <EditIcon style={{ height: "20px", width: "20px" }} />
                                    </IconButton>
                                </Tooltip>
                            }
                        </Box>
                        <CustomTextField
                            inputProps={{
                                "data-test": "faq_question"
                            }}
                            required={checkIfFieldIsRequired("answer")}
                            helperText={helperTextForQuestion}
                            error={helperTextForQuestion !== ""}
                            disabled={!isEditQuestion}
                            multiline={true}
                            sx={{
                                width: "100%",
                                "& .MuiInputBase-input.Mui-disabled": {
                                    "-webkit-text-fill-color": userPreferences?.dark_mode_enabled ? "" : "#373A40 !important",
                                },
                            }}
                            value={id === questionToEdit?.id ? questionToEdit?.question : faq?.question ?? null}
                            onChange={(e) => {
                                let value = trimEmptySpaces(e.target.value)
                                handleSetFaqs(value, "Question")
                            }}
                            size="small"
                            margin="normal"
                            variant="outlined"
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ height: "auto", width: "auto" }} >
                        <Box sx={{ display: "flex" }}>
                            <Typography variant="h6">Answer</Typography>
                            {me?.level > USER_TYPES?.supervisor?.level &&
                                <Tooltip title="Edit" placement="top" arrow>
                                    <IconButton color={isEditAnswer ? "disabled" : "primary"} title='Edit Faq' disabled={isEditAnswer}
                                        onClick={() => {
                                            setIsEditAnswer(true)
                                        }
                                        }>
                                        <EditIcon style={{ height: "20px", width: "20px" }} />
                                    </IconButton>
                                </Tooltip>
                            }
                        </Box>
                        <CustomTextField
                            required
                            disabled={!isEditAnswer}
                            sx={{
                                width: "100%",
                                "& .MuiInputBase-input.Mui-disabled": {
                                    "-webkit-text-fill-color": userPreferences?.dark_mode_enabled ? "" : "#373A40 !important",
                                },
                            }}
                            error={helperTextForAnswer !== ""}
                            helperText={helperTextForAnswer}
                            value={faq?.answer ?? null}
                            multiline={true}
                            onChange={(e) => {
                                let value = trimEmptySpaces(e.target.value)
                                handleSetFaqs(value, "Answer")
                            }}
                            size="small"
                            margin="normal"
                            variant="outlined"
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ height: "auto", marginTop: "0.5em", width: "auto" }} >
                        <Box sx={{ display: "flex" }}>
                            <Typography variant="h6">Keywords</Typography>
                            {me?.level > USER_TYPES?.supervisor?.level &&
                                <Tooltip title="Edit" placement="top" arrow>
                                    <IconButton color={isEditKeywords ? "disabled" : "primary"} title='Edit Faq' disabled={isEditKeywords}
                                        onClick={() => {
                                            setIsEditKeywords(true)
                                        }
                                        }>
                                        <EditIcon style={{ height: "20px", width: "20px" }} />
                                    </IconButton>
                                </Tooltip>
                            }
                        </Box>
                        <Autocomplete
                            sx={{ width: "100%", marginTop: "1em" }}
                            size="small"
                            disabled={!isEditKeywords}
                            disablePortal={true}
                            PopperComponent={
                                (props) => {
                                    return <Popper {...props} placement="bottom" />
                                }
                            }
                            disableClearable={false}
                            multiple={true}
                            loading={isKeywordsLoading}
                            options={keywords}
                            getOptionLabel={(keyword) => keyword?.keyword}
                            renderOption={(props, keyword) => {
                                return <RenderInput {...props} content={keyword?.keyword} />
                            }}
                            renderTags={(keywords) => {
                                return keywords?.map?.((keyword) => {
                                    return <Chip
                                        size="small"
                                        sx={{
                                            marginRight: "0.5em",
                                            marginBottom: "0.5em",
                                            backgroundColor: !userPreferences?.dark_mode_enabled ? "" : !isEditKeywords ? "white" : "",
                                            color: !userPreferences?.dark_mode_enabled ? "" : !isEditKeywords ? "#686D76" : "",
                                            fontWeight: !isEditKeywords ? "bold" : "",
                                        }}
                                        disabled={!isEditKeywords}
                                        label={keyword?.keyword}
                                        onDelete={() => {
                                            handleDeleteKeyword(questionToEdit?.keywords?.filter?.((k) => k !== keyword?.keyword))
                                        }}
                                    />
                                })
                            }}
                            value={getFullKeywordFromKeywords(faq?.keywords) ?? []}
                            onChange={(_, techniciansIds) => {
                                handleSetFaqs(techniciansIds, "Keywords")
                            }}
                            renderInput={(params) => {
                                let trimmedValue = params.inputProps.value?.trimStart();
                                return (
                                    <CustomTextField
                                        {...params}
                                        inputProps={{
                                            ...params.inputProps,
                                            value: trimmedValue
                                        }}
                                    />
                                );
                            }
                            }
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ display: "flex", gap: "1em", marginTop: "1.2em" }}>
                        <Button
                            sx={{ marginLeft: "auto" }}
                            type={"button"}
                            size=" large"
                            variant="contained"
                            className={"disable-btn-drawer"}
                            onClick={() => { handleDrawerClose() }}
                        >
                            Cancel
                        </Button>
                        <Button
                            type={"button"}
                            variant="contained"
                            size="large"
                            className={"btn-primary"}
                            onClick={() => saveEditedFaq()}
                        >
                            Save
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    </Drawer >
}

export const trimEmptySpaces = (value) => {
    let splitString = value?.split('\n')
    let nonEmptyCount = 0
    splitString?.forEach((line) => {
        if (line.trim() !== "") {
            nonEmptyCount++
        }
    })
    if (nonEmptyCount === 0) {
        return ""
    } else {
        let nonEmptyStrings = []
        nonEmptyStrings = splitString?.filter((line) => line.trim() !== "")
        // If the last element was empty, keep it
        let arr = []
        for (let string of nonEmptyStrings) {
            let ele = string.trimStart()
            arr.push(ele)
        }
        if (splitString[splitString.length - 1].trim() === "") {
            arr.push("");
        }
        let value = arr?.join('\n')
        return value
    }
}

function RenderInput(props) {
    const { content } = props
    return (
        <Typography {...props}>
            {content}
        </Typography>
    );
}
