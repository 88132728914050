import { handleGetPhoneStatistics } from "../../../../state-services/reports/reports"
import ReportCard from "./ReportCard"
import { useEffect, useState } from "react"
import handleCall from "../../../../assets/img/call-calling.svg";
import Chart, { getChartDataFromMediaReport } from "./Chart"
import { INITIAL_SELECTED_DATA } from "../Reports";
import { Box, Typography } from "@mui/material";
import { getMatchingBrandByBrandId } from "../../../../utils/miscellaneous"

const CHART_TITLES = {
    INBOUND: "Inbound",
    OUTBOUND: "Outbound",
    CALLBACK: "Callback",
    VOICEMAIL: "Voicemail"
}

export default function PhoneStatistics(props) {
    const { filtration, brands, setSelectedData, selectedData, onPhoneStatisticsChange } = props
    const [phoneStatistics, setPhoneStatistics] = useState()
    const [isPhoneStatisticsLoading, setIsPhoneStatisticsLoading] = useState(false)
    const [isError, setIsError] = useState(false)

    useEffect(() => {
        const matchingBrandName = getMatchingBrandByBrandId(filtration.brandBrandId, brands);
        if (matchingBrandName) {
            filtration.brandName = matchingBrandName.brand_name;
        }
        setSelectedData(INITIAL_SELECTED_DATA);
        handleGetPhoneStatistics(
            filtration.brandBrandId,
            filtration.franchiseId,
            filtration.channelId,
            filtration.startDate,
            filtration.endDate,
            (data) => {
                setPhoneStatistics(data);
                onPhoneStatisticsChange(data);
            },
            setIsPhoneStatisticsLoading,
            setIsError
        );
    }, [filtration.brandBrandId, filtration.franchiseId, filtration.channelId, filtration.startDate, filtration.endDate]);

    useEffect(() => {
        if (phoneStatistics) {
            setSelectedData(getChartDataFromMediaReport(CHART_TITLES.INBOUND, phoneStatistics?.inbound_data.inbound))
        }
    }, [phoneStatistics])

    return <ReportCard title={"Phone"} image={handleCall} isPhoneStatistics={true}>
        <Chart width={200} data={getChartDataFromMediaReport(CHART_TITLES.INBOUND, phoneStatistics?.inbound_data.inbound)} onClick={setSelectedData} isSelected={selectedData.title === CHART_TITLES.INBOUND} isLoading={isPhoneStatisticsLoading} isError={isError} />
        {!filtration?.franchiseId ?
            <Chart width={200} data={getChartDataFromMediaReport(CHART_TITLES.OUTBOUND, phoneStatistics?.outbound_data)} onClick={setSelectedData} isSelected={selectedData.title === CHART_TITLES.OUTBOUND} isLoading={isPhoneStatisticsLoading} isError={isError} />
            :
            <Box sx={{ padding: "0.5em", height: "220px", width: "200px", backgroundColor: 'background.main', borderRadius: "0.5em", display: "flex", flexDirection: "column", alignItems: "center" }} >
                <p style={{ marginTop: "50%" }}>{"N/A"}</p>
                <Typography sx={{ marginTop: "30%" }} fontSize={12} fontWeight={600} >{"Outbound"}</Typography>
            </Box>
        }
        <Chart width={200} data={getChartDataFromMediaReport(CHART_TITLES.CALLBACK, phoneStatistics?.inbound_data.callback)} onClick={setSelectedData} isSelected={selectedData.title === CHART_TITLES.CALLBACK} isLoading={isPhoneStatisticsLoading} isError={isError} />
        <Chart width={200} data={getChartDataFromMediaReport(CHART_TITLES.VOICEMAIL, phoneStatistics?.inbound_data.voicemail)} onClick={setSelectedData} isSelected={selectedData.title === CHART_TITLES.VOICEMAIL} isLoading={isPhoneStatisticsLoading} isError={isError} />
    </ReportCard>
}