import { Box } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { getOutboundCalls } from "../../../services/outboundCalls/outboundCalls";
import moment from "moment";
import { retry, shouldRetry } from "../../../state-services-dublicate/serviceTitan";
import RefreshErrorView from "../../refreshView/RefreshErrorView"
import LoadingView from "../../refreshView/LoadingView";
import NoContentView from "../../refreshView/NoContentView";
import SmallCard from "../../servicetitan/SmallCard";
import useDebounce from "../../../hooks/useDebounce";
import { IconButton, Tooltip, Typography } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CommonContext } from "../../../contexts/CommonContextProvider";
import { SERVICETITAN_PAGE_SIZE } from "../../../constants/serviceTitan";

export default function CallsHistorySection(props) {
    const { filterationData, calls, setCalls } = props
    const [isCallssLoading, setIsCallsLoading] = useState(false)
    const [isCallssLoadingError, setIsCallssLoadingError] = useState(false)
    const [toggleGetCalls, setToggleGetCalls] = useState(false)

    const debouncedHandleGetCalls = useDebounce(filterationData, ({ createdOnOrAfter, createdOnOrBefore, phoneNumber }) => {
        handleGetCalls(createdOnOrAfter, createdOnOrBefore, phoneNumber, 1, SERVICETITAN_PAGE_SIZE, setCalls, setIsCallsLoading, setIsCallssLoadingError)
    }, 1000)

    useEffect(() => {
        setIsCallsLoading(true)
        debouncedHandleGetCalls({ createdOnOrAfter: filterationData.createdOnOrAfter, createdOnOrBefore: filterationData.createdOnOrBefore, phoneNumber: filterationData.phoneNumber })
    }, [filterationData.createdOnOrAfter, filterationData.createdOnOrBefore, filterationData.phoneNumber, toggleGetCalls, debouncedHandleGetCalls])

    return <Box marginBlock="1em" width="100%" display="flex" flexDirection="column" gap="0.5em">
        {isCallssLoadingError ? <RefreshErrorView onRefresh={() => setToggleGetCalls(!toggleGetCalls)} /> : <></>}
        {isCallssLoading ? <LoadingView /> : calls?.length === 0 ? <NoContentView message={"No call history to show"} /> : calls.map((c) => <CallTile key={c.id} call={c} />)}
    </Box >
}

async function handleGetCalls(createdOnOrAfter = "", createdOnOrBefore = "", keyword = "", page = 1, pageSize = 50, setCalls, setIsCallsLoading, setIsCallssLoadingError, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    try {
        setIsCallssLoadingError(false)
        setIsCallsLoading(true)
        const res = await getOutboundCalls(createdOnOrAfter, createdOnOrBefore, keyword, page, pageSize)
        if (Array.isArray(res.outbound_calls)) {
            setCalls(res.outbound_calls)
        }
    } catch (err) {
        if (shouldRetry(backoffConfig) && err?.status !== 400) {
            retry(() => handleGetCalls(createdOnOrAfter, createdOnOrBefore, keyword, page, pageSize, setCalls, setIsCallsLoading, setIsCallssLoadingError, backoffConfig), backoffConfig)
        } else {
            setIsCallssLoadingError(true)
        }
    } finally {
        setIsCallsLoading(false)
    }
}


function CallTile(props) {
    const { call } = props
    const { setPhoneInfo } = useContext(CommonContext)

    const handleOnCallIconPressed = () => {
        setPhoneInfo((phoneInfo) => {
            return { ...phoneInfo, to: call.to_phone }
        })
    }

    return <Box sx={{ padding: "0.3em", height: "auto", borderRadius: "0.5em", border: "1px solid #00a79d", borderLeft: "4px solid #00a79d", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "start", gap: "0.5em" }}>
        <Box width="13em"><SmallCard maxHeight="3em" heading={"Time"} value={moment(call.created_at).format('MM/DD/YYYY - h:mm A')} /></Box>
        <Box width="10em"><SmallCard maxHeight="3em" heading={"From"} value={call.from_phone} /></Box>
        <Box width="10em"><SmallCard maxHeight="3em" heading={"To"} value={<Box display={"flex"} flexDirection={"row"} gap="0.2em" ><Typography>{call.to_phone}</Typography><CopyButton handleOnCallIconPressed={handleOnCallIconPressed} /></Box>} /></Box >
        <Box width="10em"><SmallCard maxHeight="3em" heading={"Duration"} value={call.res_call_duration ? call.res_call_duration : "-"} /></Box >
    </Box >
}

function CopyButton(props) {
    const { handleOnCallIconPressed } = props
    return <Tooltip title="Copy to dial pad">
        <span>
            <IconButton sx={{ padding: 0, margin: 0, scale: "0.8" }} size="small" onClick={handleOnCallIconPressed} >
                <ContentCopyIcon />
            </IconButton>
        </span>
    </Tooltip>
}