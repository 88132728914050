import { FetchClient } from "../utils/fetchClient";

export const getAllAgentScoreByDateRange = async (startDate, endDate, isTotal) => {
    return FetchClient(`/agent-score/agentData?start_date=${startDate}&end_date=${endDate}&is_total=${isTotal}`).GET({})
}

export const getSingleAgentDataByDateRange = async (startDate, endDate, isTotal, userId) => {
    return FetchClient(`/agent-score/agentData?start_date=${startDate}&end_date=${endDate}&is_total=${isTotal}&user_id=${userId}`).GET({})
}

export const getAgentLogs = async (currentDate, userId, agentStatus, page, size) => {
    return FetchClient(`/agent-score/agent-logs?current_date=${currentDate}&agent_user_id=${userId}&agent_status=${agentStatus}&page=${page}&size=${size}`).GET({})
}

export const getAgentStatusCodes = async () => {
    return FetchClient(`/agent-score/agent-status-codes`).GET({})
}

export const getServerConfigurations = async (isNew) => {
    return FetchClient(`/agent-score/serverConfig?isNew=${isNew}`).GET({})
}

export const getFirebaseJWTToken = async (userId) => {
    return FetchClient(`/auth/firebase/token?user_id=${userId}`).GET({})
}