import { Autocomplete, Box, InputLabel, MenuItem, Select } from "@mui/material";
import { getTimeZonedDate } from "../../../../../utils/time/time";
import { getById } from "../../../../../utils/miscellaneous";
import CustomTextField from "../../../../../components/CustomTextField";
import { renderBrandOption } from "../../../../../components/servicetitan/RenderInput";
import CustomDatePicker from "../../../../../components/CustomDatePicker";
import moment from "moment";
import { renderFranchiseOption } from "../../../utils/franchise";
import { CommonContext } from "../../../../../contexts/CommonContextProvider";
import { useContext } from "react";
import { getMatchingTimezoneByName } from "../../../../../utils/timezone/timezone";
import { CustomSelect } from "../../../faq/utils/CustomFaqStyles";

const ESTIMATE_STATUSES = {
    Open: "open",
    Sold: "sold",
    Dismissed: "dismissed",
}

export default function FiltrationArea(props) {
    const { filtration, setFiltration, brands, isBrandsLoading, franchises, isFranchisesLoading } = props

    const { generalTenantConfiguration, timezones } = useContext(CommonContext)

    return <Box className="st-filter-area" style={{ display: "flex", padding: '20px', flexDirection: "row", gap: "2em", marginBlock: "2em" }}>
        <Box flexGrow={2} />
        <div style={{ width: "220px", zIndex: 3 }} >
            <CustomDatePicker
                showTimeSelect={false}
                timeZone={generalTenantConfiguration?.time_zone}
                maxTime={new Date(moment(new Date(filtration.createdEnd)).subtract(1, "day").valueOf())}
                disabled={false}
                label={`Start Date ( ${getMatchingTimezoneByName(generalTenantConfiguration?.time_zone, timezones)?.abbreviation ?? ""} )`}
                value={new Date(filtration?.createdStart)}
                onChange={(date) => {
                    setFiltration({ ...filtration, createdStart: date.toISOString() })
                }}
                clearable={false}
                required={true}
            />
        </div>
        <div style={{ width: "220px", zIndex: 3 }} >
            <CustomDatePicker
                showTimeSelect={false}
                timeZone={generalTenantConfiguration?.time_zone}
                maxTime={new Date(getTimeZonedDate(new Date(), generalTenantConfiguration?.time_zone))}
                minTime={new Date(moment(new Date(filtration?.createdStart)).add(1, "day").valueOf())}
                disabled={false}
                label={`End Date ( ${getMatchingTimezoneByName(generalTenantConfiguration?.time_zone, timezones)?.abbreviation ?? ""} )`}
                value={new Date(moment(new Date(filtration?.createdEnd)).valueOf())}
                onChange={(date) => {
                    setFiltration({ ...filtration, createdEnd: date.toISOString() })
                }}
                clearable={false}
                required={true}
            />
        </div>
        <Autocomplete
            sx={{ minWidth: "350px" }}
            size="small"
            loading={isBrandsLoading}
            noOptionsText={"No brands"}
            disabled={brands === []}
            disablePortal
            options={brands}
            getOptionLabel={(brand) => brand.brand_name}
            renderOption={renderBrandOption}
            value={getById(filtration.brandId, brands) ?? null}
            onChange={(e, brand) => setFiltration({ ...filtration, brandId: brand?.id, brandBrandId: brand?.brand_id, brand: brand, franchiseId: undefined, franchise: {} })}
            renderInput={(params) => <CustomTextField {...params} label="Brand" />}
        />
        <Autocomplete
            sx={{ minWidth: "300px" }}
            size="small"
            loading={isFranchisesLoading}
            noOptionsText={filtration.brandId ? "No franchises" : "Please select a brand"}
            disabled={franchises === []}
            disablePortal
            options={franchises}
            getOptionLabel={(franchise) => franchise.franchise_name}
            renderOption={renderFranchiseOption}
            value={getById(filtration.franchiseId, franchises) ?? null}
            onChange={(e, franchise) => setFiltration({ ...filtration, franchiseId: franchise?.id, franchise: franchise })}
            renderInput={(params) => <CustomTextField {...params} label="Franchise" />}
        />
        <CustomSelect style={{ minWidth: "170px", marginRight: "20px" }}>
            <InputLabel >Status</InputLabel>
            <Select
                size={"small"}
                displayEmpty
                label="Status"
                className={"cus-input"}
                value={filtration?.status ?? ESTIMATE_STATUSES.Open}
                onChange={(e) => { setFiltration({ ...filtration, status: e.target.value }) }}
            >
                <MenuItem value={ESTIMATE_STATUSES.Open}> Open</MenuItem>
                <MenuItem value={ESTIMATE_STATUSES.Sold}> Sold</MenuItem>
                <MenuItem value={ESTIMATE_STATUSES.Dismissed}> Dismissed</MenuItem>
            </Select>
        </CustomSelect>
    </Box>
}