import { Box, Button } from "@mui/material"
import { useEffect, useState } from "react"
import CustomTextField from "../../../../../components/CustomTextField"
import { getCampaigns, getIndustries } from "../../../../../services/vonigo/jobScheduling"
import CustomerTypesRadioButton from "../../serviceTitanJobs/components/CustomerTypesRadioButton"
import CampaignSelection from "./CampaignSelection"
import { CUSTOMER_TYPES } from "./CustomerDetailsSection"
import IndustrySelection from "./IndustrySelection"

export default function EditJobCustomerDetails(props) {
    const { brand, franchise, client, isDisabled } = props
    const [industries, setIndustries] = useState([])
    const [isIndustriesLoading, setIsIndustriesLoading] = useState(false)
    const [industry, setIndustry] = useState()
    const [campaign, setCampaign] = useState()
    const [campaigns, setCampaigns] = useState([])
    const [isCampaignsLoading, setIsCampaignsLoading] = useState(false)
    const [type, setType] = useState()

    useEffect(() => {
        if (brand && franchise && brand?.redirect_url && franchise?.franchise_id) {
            loadIndustriesAndCampaigns(brand, franchise)
        }
    }, [brand, franchise])

    useEffect(() => {
        setIndustry?.(client?.industryOptionId)
        setType?.(client?.typeOptionId?.name)
    }, [client])

    useEffect(() => {
        if (campaigns?.length > 0 && client?.marketingCampaingOptionId?.optionID) {
            const campaign = campaigns.find((c) => c.optionID === client?.marketingCampaingOptionId.optionID)
            setCampaign?.(campaign)
        }
    }, [client, campaigns])

    function loadIndustriesAndCampaigns(brand, franchise) {
        setIsIndustriesLoading(true)
        getIndustries(brand?.redirect_url, franchise?.franchise_id).then((industriesResponse) => {
            if (Array.isArray(industriesResponse.Options)) {
                setIndustries(industriesResponse.Options)
            }
        }).catch((err) => {
        }).finally(() => setIsIndustriesLoading(false))
        setIsCampaignsLoading(true)
        getCampaigns(brand?.redirect_url, franchise?.franchise_id).then((campaignResponse) => {
            if (Array.isArray(campaignResponse.Campaigns)) {
                const activeCampaigns = campaignResponse.Campaigns.filter((c) => c?.isActive)
                setCampaigns(activeCampaigns)
            }
        }).catch((err) => {
        }).finally(() => setIsCampaignsLoading(false))
    }

    return <Box style={{ width: "100%" }} >
        <Box style={{ display: "flex", flexDirection: "column", width: "100%", gap: "1em" }} >
            <Box style={{ display: "flex", flexDirection: "row", width: "100%", }}>
                <CustomTextField style={{ minWidth: "30em", flexGrow: "2", marginRight: "0.5em" }} disabled={true} value={client?.name?.fieldValue ?? ""} size="small" label="Customer Name" />
                <CustomerTypesRadioButton options={Object.values(CUSTOMER_TYPES).map((c) => c.name)} isDisabled={isDisabled} value={type ?? ""} />
            </Box>
            <Box style={{ display: "flex", flexDirection: "row", width: "100%", gap: "1em" }}>
                <CampaignSelection noOptionText={franchise ? "No Campaigns" : "Please select a franchise"} isDisabled={isDisabled} value={campaign} campaigns={campaigns} isCampaignsLoading={isCampaignsLoading} />
                {(client?.typeOptionId?.optionID?.toString() === CUSTOMER_TYPES.COMMERCIAL.optionId) ? <IndustrySelection franchise={franchise} noOptionText={franchise ? "Please select a franchise" : "No Industries"} isRequired={true} isDisabled={isDisabled} value={industry} industries={industries} isIndustriesLoading={isIndustriesLoading} /> : <></>}
            </Box>
            <Box style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "end" }}>
                {!isDisabled ? <Button fullWidth={false} size={"small"} variant="contained" className={"btn-disable"} >{"Create Customer"}</Button> : <></>}
            </Box>
        </Box>
    </Box>
}