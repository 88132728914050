import { Box, Button } from "@mui/material"
import { useMemo, useState } from "react"
import { Prompt } from "react-router-dom"
import DialogFooter from "../../../../../components/dialog/DialogFooter"
import CreateAppointmentSection from "./CreateAppointmentSection"
import { getPromptMessage } from "../../../../../utils/jobs";
import { handleAddAppointment } from "../../../../../state-services-dublicate/serviceTitan"
import { NOTIFICATION_TYPES } from "../../../../../constants/common"
import { CustomTextField } from "../../components/customComponents"
import LoadingButton from "../../../../../components/LoadingButton"


export default function MultipleAppointmentSection(props) {
    const { franchise, job, onClose, showNotification } = props

    const [isCreateAppoinntmentLoading, setIsCreateAppointmentLoading] = useState();
    const [appointment, setAppointment] = useState();
    const [specialInstructions, setSpecialInstructions] = useState("")
    const shouldPrompt = useMemo(() => appointment?.start || appointment?.end || appointment?.arrivalWindowStart || appointment?.arrivalWindowEnd || appointment?.technicianIds?.length > 0 || specialInstructions, [appointment, specialInstructions])

    async function addAppointment(e) {
        e?.preventDefault()
        try {
            if (!(appointment?.arrivalWindowStart && appointment?.arrivalWindowEnd)) return showNotification({ message: "Important: Please select a timeslot and try again", type: NOTIFICATION_TYPES.ERROR })
            setIsCreateAppointmentLoading(true)
            const appointmentRequest = { jobId: job?.id, specialInstructions: specialInstructions, ...appointment }
            await handleAddAppointment(franchise, appointmentRequest, setIsCreateAppointmentLoading)
            setIsCreateAppointmentLoading(false)
            onClose(job, undefined)
            showNotification({ message: "The appointment has been created successfully", type: NOTIFICATION_TYPES.SUCCES })
        } catch {
            showNotification({ message: "Important: appointment couldn’t be created at this time. Please try again", type: NOTIFICATION_TYPES.ERROR })
        }
    }

    function handleClose() {
        onClose(job, undefined)
    }

    return <Box sx={{ display: "flex", flexDirection: "column", flex: "1 2 0", width: "100%", overflowX: "hidden", overflowY: "scroll", paddingRight: "1em" }} >
        <form onSubmit={addAppointment}>
            <Prompt
                when={Boolean(shouldPrompt) ?? false}
                message={getPromptMessage}
            />
            <CreateAppointmentSection
                franchise={franchise}
                job={job}
                appointment={appointment}
                setAppointment={setAppointment} />
            <CustomTextField style={{ width: "100%" }} label="Special Instructions" size="small" value={specialInstructions} onChange={(e) => setSpecialInstructions(e?.target?.value)} />
            <DialogFooter className={'dialog-footer'}>
                <Button size={"small"} variant="contained" onClick={handleClose} className={"btn-secondary"}>Cancel</Button>
                <LoadingButton type={"submit"} size={"small"} variant="contained" disabled={isCreateAppoinntmentLoading} loading={isCreateAppoinntmentLoading} className={isCreateAppoinntmentLoading ? "btn-disable" : "btn-primary"} >
                    {"Create Appointment"}
                </LoadingButton>
            </DialogFooter>
        </form>
    </Box>
}