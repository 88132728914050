import { Autocomplete, Box } from "@mui/material";
import CustomTextField from "../../../../../components/CustomTextField";
import RenderInput from "../../../../../components/servicetitan/RenderInput";
import { getSingleCustomerDetails } from "../../../../../services/vonigo/jobScheduling";
import { getExistingCustomerType } from "../../../../../utils/vonigo/miscellaneous";
import CustomerTypesRadioButton from "../../serviceTitanJobs/components/CustomerTypesRadioButton";
import { CUSTOMER_TYPES } from "../../vonigo/components/CustomerDetailsSection";

export default function ExistingCustomerSection(props) {
    const { brand, franchise, isRequired, setEditContact, setEditLocation, customers, isCustomersLoading, selectedCustomer, setSelectedCustomer } = props

    const HideCustomerTypes = true

    function onCustomerChanged(_, customer) {
        setEditContact()
        setEditLocation()
        handelGetSingleCustomerDetails(brand, franchise, customer?.objectID, setSelectedCustomer)
    }

    return <Box style={{ display: "flex", flexDirection: "column", width: "100%", }}>
        <Autocomplete
            style={{ minWidth: "30em", flexGrow: "2", marginRight: "0.5em" }}
            size="small"
            noOptionsText={franchise ? "No customer found" : "Please select a franchise"}
            loading={isCustomersLoading}
            value={selectedCustomer ?? null}
            disablePortal
            disableClearable
            options={customers}
            isOptionEqualToValue={(option, value) => option?.objectID === value?.objectID}
            getOptionLabel={(customer) => customer?.title}
            renderOption={(props, customer) => <RenderInput {...props} key={customer?.objectID} content={customer?.title ?? "-"} />}
            onChange={onCustomerChanged}
            renderInput={(params) => <CustomTextField {...params} required={isRequired} label="Select Customer" />}
        />
        {selectedCustomer?.warningMessage?.fieldValue ? <Box className="messageBoxForWarningMessage" ><p>{selectedCustomer?.warningMessage?.fieldValue}</p></Box> : <></>}
        {HideCustomerTypes ? <></> : <CustomerTypesRadioButton isDisabled={true} options={Object.values(CUSTOMER_TYPES).map((c) => c.name)} value={(getExistingCustomerType(selectedCustomer?.typeOptionId?.optionID) ?? "")} />}
    </Box>
}

async function handelGetSingleCustomerDetails(brand, franchise, id = "", setSelectedCustomer) {
    try {
        if (!id) return
        const customerResponse = await getSingleCustomerDetails(brand?.redirect_url, franchise?.franchise_id, id)
        setSelectedCustomer(customerResponse);
    } catch { }
}