
import { JOB_TABS } from "../../../../constants/common";
import VonigoDraftJobs from "./components/VonigoDraftJobs";
import VoingoJobs from "./components/VonigoJobs";

function VonigoJobsLandingPage(props) {
    const { callerId, zipCode, brand, franchise, openViewJob, onRefreshFilters, globalSearchValue, filterState, setJobStatisticsComponent, tab, setTab, setNotify, jobToView, setJobToView, setDraftToContinue, onCreateJobPageOpen, setIsCreateMultipleWorkOrders, selectedJobToView, setSelectedJobToView, setWorkOrderToReschedule, setIsRescheduleWorkOrder, page, setPage } = props
    switch (tab) {
        case JOB_TABS.JOBS:
            return <VoingoJobs callerId={callerId} zipCode={zipCode} onRefreshFilters={onRefreshFilters} brand={brand} franchise={franchise} openViewJob={openViewJob} setWorkOrderToReschedule={setWorkOrderToReschedule} setIsRescheduleWorkOrder={setIsRescheduleWorkOrder} selectedJobToView={selectedJobToView} setSelectedJobToView={setSelectedJobToView} setIsCreateMultipleWorkOrders={setIsCreateMultipleWorkOrders} onCreateJobPageOpen={onCreateJobPageOpen} filterState={filterState} globalSearchValue={globalSearchValue} setJobStatisticsComponent={setJobStatisticsComponent} setTab={setTab} jobToView={jobToView} setJobToView={setJobToView} setNotify={setNotify} page={page} setPage={setPage} />
        case JOB_TABS.DRAFTS:
            return <VonigoDraftJobs brand={brand} franchise={franchise} filterState={filterState} setJobStatisticsComponent={setJobStatisticsComponent} setTab={setTab} setDraftToContinue={setDraftToContinue} setNotify={setNotify} page={page} setPage={setPage} />
        default:
            return <></>
    }
}
export default VonigoJobsLandingPage;