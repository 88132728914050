import "../styles/scoreDashboardStyles.css"
import React from 'react'
import { timeCard } from './timeCard'

export default function statusCodeItem(time, title) {
    return (
        <div className="status-code-item-container">
            <p>{title}</p>
            {timeCard(time)}
        </div>
    )
}
