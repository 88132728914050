import { Box, IconButton, Tooltip, Typography } from "@mui/material"
import { sendAEmail } from "../../utils/miscellaneous"
import EmailIcon from '@mui/icons-material/Email';

export default function EmailCard(props) {
    const { heading, email } = props
    return <Box sx={{ paddingInline: "0.5em" }}>
        {heading ? <Typography style={{ color: "gray", fontSize: "0.8rem" }}>{heading}</Typography> : <></>}
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Tooltip title="Email" >
                <IconButton sx={{ color: "primary.main", margin: "0", padding: "0", paddingTop: "0.2em", paddingRight: "0.2em" }} aria-label="Email" component="span" onClick={() => {
                    sendAEmail(email)
                }}>
                    <EmailIcon />
                </IconButton>
            </Tooltip>
            <Typography style={{ margin: "0" }}>{email}</Typography>
        </Box>
    </Box >
}