import { Box, Button, CircularProgress, IconButton, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { getWorkOrders } from "../../../../services/vonigo/jobScheduling";
import RefreshIcon from '@mui/icons-material/Refresh';
import WorkOrderCard from "./components/WorkOrderCard";

export default function WorkOrdersSection({ brand, franchise, job, showNotification, setIsViewJobModalOpen, onCreateJobPageOpen, setIsCreateMultipleWorkOrders, setWorkOrderToReschedule, setIsRescheduleWorkOrder }) {

    const [workOrders, setworkOrders] = useState([])
    const [isWorkOrdersLoading, setIsWorkOrdersLoading] = useState(false)
    const [isWorkOrdersLoadingError, setIsWorkOrdersLoadingError] = useState(false)
    const [toggleRefreshWorkOrders, setToggleRefreshWorkOrders] = useState(false);

    useEffect(() => {
        if (job) {
            getWorkOrdersOfTheJob(brand, franchise, job, setworkOrders, setIsWorkOrdersLoading, setIsWorkOrdersLoadingError)
        }
    }, [franchise, brand, job, toggleRefreshWorkOrders])

    async function getWorkOrdersOfTheJob(brand, franchise, job, setworkOrders, setIsWorkOrdersLoading, setIsWorkOrdersLoadingError) {
        setIsWorkOrdersLoading(true)
        setIsWorkOrdersLoadingError(false)
        try {
            const workOrdersResponse = await getWorkOrders(brand.redirect_url, franchise.franchise_id, job.objectID)
            if (Array.isArray(workOrdersResponse.WorkOrder)) {
                workOrdersResponse?.WorkOrder?.sort((a, b) => a.dateService - b.dateService)
                setworkOrders(workOrdersResponse.WorkOrder)
            }
        } catch (error) {
            setIsWorkOrdersLoadingError(true)
        } finally {
            setIsWorkOrdersLoading(false)
        }
    }

    return <>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "0.25em", padding: "0.25em", minHeight: "300px" }}>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "left", justifyItems: "center" }}>
                <Typography style={{ fontSize: "1.5rem", fontWeight: "bold" }}>Work Orders</Typography>
                {buildAppointmentRefreshButton()}
                {job?.jobStatus.name !== "Cancelled" ?
                    <Button style={{ marginLeft: "auto" }} size={"small"} sx={{ color: "#00a79d" }} variant="text" disabled={false} onClick={() => {
                        setIsViewJobModalOpen(false)
                        setIsCreateMultipleWorkOrders(true)
                        onCreateJobPageOpen()
                    }}>
                        {"Add Work Order"}
                    </Button>
                    : <></>}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "0.25em", gap: "1em", minHeight: "5em", paddingBottom:"0.5em" }}>
                {isWorkOrdersLoading ? <CircularProgress size={30} color="inherit" /> : isWorkOrdersLoadingError ? buildErrorView() : workOrders.length === 0 ? buildNoWorkOrdersView() : workOrders?.map((workOrder) => {
                    return <WorkOrderCard key={workOrder?.name} brand={brand} WorkOrder={workOrder} setIsViewJobModalOpen={setIsViewJobModalOpen} onCreateJobPageOpen={onCreateJobPageOpen} setWorkOrderToReschedule={setWorkOrderToReschedule} setIsRescheduleWorkOrder={setIsRescheduleWorkOrder} />;
                })}
            </Box>
        </Box>

    </>;

    function buildAppointmentRefreshButton() {
        return <Tooltip title={"Refresh"} ><IconButton onClick={() => {
            setToggleRefreshWorkOrders(!toggleRefreshWorkOrders);
        }}><RefreshIcon /></IconButton></Tooltip>;
    }

    function buildErrorView() {
        return <Box style={{ display: "flex", flexDirection: "row", justifyItems: "center", alignItems: "center" }} ><Typography color={"GrayText"} variant={"subtitle2"}>Something went wrong</Typography>{buildAppointmentRefreshButton()}</Box>
    }

    function buildNoWorkOrdersView() {
        return <Box style={{ display: "flex", flexDirection: "row", justifyItems: "center", alignItems: "center" }} ><Typography color={"GrayText"} variant={"subtitle2"}>No Work Orders To Show</Typography></Box>
    }
}