import { Grid } from "@mui/material";
import CustomTextField from "../../../../../components/CustomTextField";
import { useEffect, useState } from "react";
import useDebounce from "../../../../../hooks/useDebounce";
import { getServiceTitanCredentialsValidation } from "../../../../../services/serviceTitan";

export const INITIAL_CREDENTIALS = {
    service_titan_tenant_id: "",
    service_titan_tenant_name: "",
    service_titan_client_id: "",
    service_titan_client_secret: "",
    api_key: "",
}

export default function ServicetitanCredentialsSection(props) {
    const { isDisabled, isTollFree, franchise = {}, setFranchise = (franchise) => { } } = props

    const [servicetitanCredentialValidationResponse, setServicetitanCredentialValidationResponse] = useState({})

    const debouncedServiceTitanCredentialsValidation = useDebounce({ service_titan_tenant_id: franchise?.service_titan_tenant_id, service_titan_client_id: franchise?.service_titan_client_id, service_titan_client_secret: franchise?.service_titan_client_secret }, ({ service_titan_tenant_id, service_titan_client_id, service_titan_client_secret }) => {
        if (service_titan_tenant_id && service_titan_client_id && service_titan_client_secret) {
            getServiceTitanCredentialsValidation(service_titan_tenant_id, service_titan_client_id, service_titan_client_secret).then((response) => {
                setServicetitanCredentialValidationResponse(response)
            }).catch((error) => { })
        } else {
            setServicetitanCredentialValidationResponse({})
        }
    }, 1000)

    useEffect(() => {
        debouncedServiceTitanCredentialsValidation({ service_titan_tenant_id: franchise?.service_titan_tenant_id, service_titan_client_id: franchise?.service_titan_client_id, service_titan_client_secret: franchise?.service_titan_client_secret })
    }, [franchise.service_titan_tenant_id, franchise.service_titan_client_id, franchise.service_titan_client_secret])

    return (
        <Grid container spacing={1}>
            <Grid item xs={4}>
                <CustomTextField
                    inputProps={{
                        "data-test": "franchise_servicetitan_tenant_id"
                    }}
                    type={"text"}
                    fullWidth
                    value={franchise.service_titan_tenant_id ?? ""}
                    onChange={(e) => setFranchise((c) => {
                        return { ...c, service_titan_tenant_id: e.target.value }
                    })}
                    error={servicetitanCredentialValidationResponse?.isTenantIdValid === false ? true : false}
                    helperText={servicetitanCredentialValidationResponse?.isTenantIdValid === false ? "Tenant Id invalid" : ""}
                    label={"Service Titan Tenant Id"}
                    required={isTollFree ? false : true}
                    size={"small"}
                    margin="normal"
                    variant="outlined"
                    disabled={isDisabled}
                />
            </Grid>
            <Grid item xs={4}>
                <CustomTextField
                    inputProps={{
                        "data-test": "franchise_servicetitan_tenant_name"
                    }}
                    value={franchise.service_titan_tenant_name ?? ""}
                    onChange={(e) => setFranchise((c) => {
                        return { ...c, service_titan_tenant_name: e.target.value }
                    })}
                    type={"text"}
                    fullWidth
                    id="serviceTitanTenantName"
                    label={"Service Titan Tenant Name"}
                    required={isTollFree ? false : true}
                    size={"small"}
                    margin="normal"
                    variant="outlined"
                    disabled={isDisabled}
                />
            </Grid>
            <Grid item xs={4}>
                <CustomTextField
                    inputProps={{ "data-test": "franchise_servicetitan_api_key" }}
                    type={"text"}
                    fullWidth
                    value={franchise.api_key ?? ""}
                    onChange={(e) => setFranchise((c) => {
                        return { ...c, api_key: e.target.value }
                    })}
                    label={"API key"}
                    required={isTollFree ? false : true}
                    size={"small"}
                    margin="normal"
                    variant="outlined"
                    disabled={isDisabled}
                />
            </Grid>
            <Grid item xs={4}>
                <CustomTextField
                    inputProps={{
                        "data-test": "franchise_servicetitan_client_id"
                    }}
                    type={"text"}
                    fullWidth
                    value={franchise.service_titan_client_id ?? ""}
                    onChange={(e) => setFranchise((c) => {
                        return { ...c, service_titan_client_id: e.target.value }
                    })}
                    required={franchise.service_titan_client_secret && !isTollFree ? true : false}
                    error={servicetitanCredentialValidationResponse?.isClientIdAndClientSecretValid === false ? true : false}
                    helperText={servicetitanCredentialValidationResponse?.isClientIdAndClientSecretValid === false ? "Client ID or Client Secret invalid" : ""}
                    id="clientId"
                    label={"Client ID"}
                    size={"small"}
                    margin="normal"
                    variant="outlined"
                    disabled={isDisabled}
                />
            </Grid>
            <Grid item xs={4}>
                <CustomTextField
                    inputProps={{
                        "data-test": "franchise_servicetitan_client_secret"
                    }}
                    type={"text"}
                    fullWidth
                    value={franchise.service_titan_client_secret ?? ""}
                    onChange={(e) => setFranchise((c) => {
                        return { ...c, service_titan_client_secret: e.target.value }
                    })}
                    required={franchise.service_titan_client_id && !isTollFree ? true : false}
                    error={servicetitanCredentialValidationResponse?.isClientIdAndClientSecretValid === false ? true : false}
                    helperText={servicetitanCredentialValidationResponse?.isClientIdAndClientSecretValid === false ? "Client ID or Client Secret invalid" : ""}
                    id="clientSecret"
                    label={"Client Secret"}
                    size={"small"}
                    margin="normal"
                    variant="outlined"
                    disabled={isDisabled}
                />
            </Grid>
        </Grid>
    )
}