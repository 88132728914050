import { FetchClient } from "../../utils/fetchClient"

export async function searchAudits(body) {
    return FetchClient(`/audit/filter`).POST({ payload: JSON.stringify(body) })
}

export async function getMostRecentFranchiseAuditByGivenAudit (body){
    return FetchClient(`/audit/franchise/mostRecent`).POST({ payload: JSON.stringify(body) })
}

export async function getAuditOptions(auditType){
    return FetchClient(`/audit/options?type=${auditType}`).GET({})
}

export async function getAllBrandFranchises (brandId) {
    return FetchClient(`/data/v3/franchises?brand_id=${brandId}&is_active=3`).GET({})
}

// get franchise details by franchise id
export async function getFranchiseById (franchiseId) {
    return FetchClient(`/data/v3/franchise?id=${franchiseId}&omit_removed=0`).GET({})
}