import { Box, Grid, Typography, Autocomplete, Popper, Chip } from "@mui/material";
import { useState } from "react";
import Drawer from "@mui/material/Drawer";
import { Button } from "@mui/material";
import CustomTextField from '../../../../components/CustomTextField';
import { trimEmptySpaces } from "./EditBrandTemplateFaqDrawer";

export default function BrandTemplateFaqDrawer({ id, faq, faqTemplateErros, setFaqTemplateErros, faqs, setFaqs, setIsAddNewQuestion, isKeywordsLoading, keywords, drawerOpen, onClose }) {

    const [helperTextForQuestion, setHelperTextForQuestion] = useState("")
    const [helperTextForAnswer, setHelperTextForAnswer] = useState("")

    const handleSetFaqs = (key, value, type) => {
        setHelperTextForAnswer("")
        setHelperTextForQuestion("")
        let newFaqs = [...faqs]
        if (type === "Answer") {
            newFaqs[key].answer = value
        } else if (type === "Keywords") {
            newFaqs[key].keywords = value?.map?.((k) => k?.keyword)
        } else if (type === "Question") {
            newFaqs[key].question = value
        }
        setFaqs(newFaqs)
        let faqToEdit = newFaqs[key]
        if (faqToEdit?.answer !== "" && faqToEdit?.question === "") {
            setHelperTextForQuestion("Please provide a question")
        } else if (faqToEdit?.question !== "" && faqToEdit?.answer === "") {
            setHelperTextForAnswer("Please provide an answer")
        } else if (faqToEdit?.question === "" && faqToEdit?.answer === "" && faqToEdit?.keywords?.length !== 0) {
            setHelperTextForQuestion("Please provide a question")
            setHelperTextForAnswer("Please provide an answer")
        }
    }

    const handleDeleteKeyword = (key, keywords) => {
        setHelperTextForAnswer("")
        setHelperTextForQuestion("")
        let newFaqs = [...faqs]
        newFaqs[key].keywords = keywords
        setFaqs(newFaqs)
        let faqToEdit = newFaqs[key]
        if (faqToEdit?.answer !== "" && faqToEdit?.question === "") {
            setHelperTextForQuestion("Please provide a question")
        } else if (faqToEdit?.question !== "" && faqToEdit?.answer === "") {
            setHelperTextForAnswer("Please provide an answer")
        } else if (faqToEdit?.question === "" && faqToEdit?.answer === "" && faqToEdit?.keywords?.length !== 0) {
            setHelperTextForQuestion("Please provide a question")
            setHelperTextForAnswer("Please provide an answer")
        }
    }

    const getFullKeywordFromKeywords = (kwords) => {
        let fullKeywords = []
        kwords?.forEach((kword) => {
            let fullKeyword = keywords?.find?.((k) => k?.keyword === kword)
            fullKeywords.push(fullKeyword)
        })
        return fullKeywords
    }

    const handleDrawerClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        let newFaqs = [...faqs]
        newFaqs.pop()
        setFaqs(newFaqs)
        setIsAddNewQuestion(false)
        let newFaqTemplateErros = faqTemplateErros?.filter?.((error) => error?.id !== id)
        setFaqTemplateErros(newFaqTemplateErros)
        onClose()
    }

    const getFaqQuestionErros = (id) => {
        let questionError = faqTemplateErros?.find?.((error) => error?.id === id)?.question
        let answerError = faqTemplateErros?.find?.((error) => error?.id === id)?.answer
        return { questionError, answerError }
    }


    const checkIfFieldIsRequired = (field) => {
        if (field === "question") {
            return (faq?.answer === "" && faq?.question !== "") && id === faq?.id ? true : false
        } else if (field === "answer") {
            return (faq?.question === "" && faq?.answer !== "") && id === faq?.id ? true : false
        }
    }

    const saveEditedFaq = (key) => {
        let newFaqs = [...faqs]
        let faqToEdit = newFaqs[key]
        if (faqToEdit?.answer !== "" && faqToEdit?.question === "") {
            setHelperTextForQuestion("Please provide a question")
            return
        } else if (faqToEdit?.question !== "" && faqToEdit?.answer === "") {
            setHelperTextForAnswer("Please provide an answer")
            return
        } else if (faqToEdit?.question === "" && faqToEdit?.answer === "" && faqToEdit?.keywords?.length !== 0) {
            setHelperTextForQuestion("Please provide a question")
            setHelperTextForAnswer("Please provide an answer")
            return
        } else if (faqToEdit?.question === "" && faqToEdit?.answer === "" && faqToEdit?.keywords?.length === 0) {
            setHelperTextForQuestion("Please provide a question")
            setHelperTextForAnswer("Please provide an answer")
            return
        }
        setIsAddNewQuestion(false)
        onClose()
    }

    return <Drawer
        sx={{ zIndex: 1301 }} anchor="right" lassName="dock-drawer" open={drawerOpen} onClose={handleDrawerClose} >
        <Box sx={{ height: "auto", width: "700px", padding: "30px" }} >
            <Typography sx={{ marginBottom: "1em" }} variant="h5">Brand Template FAQ Section</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{ height: "auto", width: "auto" }} >
                        <Box sx={{ display: "flex" }}>
                            <Typography variant="h6">Question</Typography>
                        </Box>
                        <CustomTextField
                            sx={{ width: "100%" }}
                            inputProps={{
                                "data-test": "faq_question"
                            }}
                            helperText={helperTextForQuestion}
                            error={getFaqQuestionErros(id)?.questionError || helperTextForQuestion !== ""}
                            required={checkIfFieldIsRequired("answer")}
                            value={faq?.question ?? null}
                            multiline
                            maxRows={2}
                            onChange={(e) => {
                                let value = trimEmptySpaces(e.target.value)
                                handleSetFaqs(id, value, "Question")
                            }}
                            size="small"
                            margin="normal"
                            variant="outlined"
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ height: "auto", width: "auto" }} >
                        <Box sx={{ display: "flex" }}>
                            <Typography variant="h6">Answer</Typography>
                        </Box>
                        <CustomTextField
                            sx={{ width: "100%" }}
                            inputProps={{
                                "data-test": "faq_answer"
                            }}
                            helperText={helperTextForAnswer}
                            error={getFaqQuestionErros(id)?.answerError || helperTextForAnswer !== ""}
                            value={faq?.answer}
                            required={checkIfFieldIsRequired("question")}
                            multiline
                            maxRows={2}
                            onChange={(e) => {
                                let value = trimEmptySpaces(e.target.value)
                                handleSetFaqs(id, value, "Answer")
                            }}
                            size="small"
                            margin="normal"
                            variant="outlined"
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ height: "auto", marginTop: "0.5em", width: "auto" }} >
                        <Box sx={{ display: "flex" }}>
                            <Typography variant="h6">Keywords</Typography>
                        </Box>
                        <Autocomplete
                            sx={{ width: "100%" }}
                            size="small"
                            disablePortal={true}
                            PopperComponent={
                                (props) => {
                                    return <Popper {...props} placement="bottom" />
                                }
                            }
                            disableClearable={false}
                            multiple={true}
                            loading={isKeywordsLoading}
                            options={keywords}
                            getOptionLabel={(keyword) => keyword?.keyword}
                            renderOption={(props, keyword) => {
                                return <RenderInput {...props} content={keyword?.keyword} />
                            }}
                            renderTags={(keywords) => {
                                return keywords?.map?.((keyword) => {
                                    return <Chip
                                        size="small"
                                        sx={{
                                            marginRight: "0.5em",
                                            marginBottom: "0.5em",
                                        }}
                                        label={keyword?.keyword}
                                        onDelete={() => {
                                            handleDeleteKeyword(id, faq?.keywords?.filter?.((k) => k !== keyword?.keyword))
                                        }}
                                    />
                                })
                            }}
                            value={getFullKeywordFromKeywords(faq?.keywords) ?? []}
                            onChange={(_, techniciansIds) => {
                                handleSetFaqs(id, techniciansIds, "Keywords")
                            }}
                            renderInput={(params) => {
                                let trimmedValue = params.inputProps.value?.trimStart();
                                return (
                                    <CustomTextField
                                        {...params}
                                        inputProps={{
                                            ...params.inputProps,
                                            value: trimmedValue
                                        }}
                                    />
                                );
                            }
                            } />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ display: "flex", gap: "1em", marginTop: "1.2em" }}>
                        <Button
                            sx={{ marginLeft: "auto" }}
                            type={"button"}
                            size=" large"
                            variant="contained"
                            className={"disable-btn-drawer"}
                            onClick={() => { handleDrawerClose() }}
                        >
                            Cancel
                        </Button>
                        <Button
                            type={"button"}
                            variant="contained"
                            size="large"
                            className={"btn-primary"}
                            onClick={() => saveEditedFaq(id)}
                        >
                            Save
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    </Drawer >
}

function RenderInput(props) {
    const { content } = props
    return (
        <Typography {...props}>
            {content}
        </Typography>
    );
}
