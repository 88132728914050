import { Box, CircularProgress, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import { getTenantTimeZoneTimeString, getTimeZonedDate } from "../../../../utils/time/time";
import { useContext, useEffect, useState } from "react";
import { getById, getMatchingBrandByBrandId } from "../../../../utils/miscellaneous";
import { CommonContext } from "../../../../contexts/CommonContextProvider";
import { handleGetBrandFranchises } from "../../../../state-services/franchise";
import VisibilityIcon from '@mui/icons-material/Visibility';
import FiltrationArea from "./components/FilterationArea";
import iBSBrandLogo from "../../../../assets/img/brand.png"
import ErrorView from "../../../../components/ErrorView";
import { ERROR_COMPONENTS } from "../../../../constants/common";
import PleaseSelectBrandAndFranchise from "../../../../components/PleaesSelectBrandAndFranchise";
import useDebounce from "../../../../hooks/useDebounce";
import ImageBox from "../../../../components/LogoBox/ImageBox";
import { handleGetServiceTitanEstimateById, handleGetServiceTitanEstimates } from "../../../../state-services/serviceTitanEstimates/serviceTitanEstimates";
import ViewEstimateLandingPage from "./components/ViewEstimateLandingPage";
import { useHistory } from 'react-router-dom';

const INITIAL_FILTRATION = {
    brandId: 0,
    franchiseId: 0,
    franchise: {},
    brand: {},
    status: "open",
    brandBrandId: 0,
    createdStart: getDayBeforeWeeksISOString(new Date(), "America/Montreal", 14),
    createdEnd: getTimeZonedDate(new Date(), "America/Montreal").toISOString(),
    page: 1,
    pageSize: 50,
}

function refreshFiltration(oldFiltration = {}) {
    return {
        ...oldFiltration,
        createdStart: getDayBeforeWeeksISOString(new Date(), "America/Montreal", 14),
        createdEnd: getTimeZonedDate(new Date(), "America/Montreal").toISOString(),
    }
}
function getDayBeforeWeeksISOString(date, timeZone, subtraction = 14) {
    return getTimeZonedDate(date, timeZone).subtract(subtraction, "day").startOf("d").toISOString()
}

export default function ServiceTitanEstimates() {
    const { allBrands, isBrandsLoading, generalTenantConfiguration, timezones } = useContext(CommonContext)

    const urlParams = new URLSearchParams(window.location.search);
    const brandId = parseInt(urlParams.get("brandId"));
    const franchiseId = parseInt(urlParams.get("franchiseId"));
    const estimateId = urlParams.get("estimateId");
    const urlPramData = {
        brandId,
        franchiseId,
        estimateId
    }

    const [filtration, setFiltration] = useState(refreshFiltration(INITIAL_FILTRATION))
    const [brandFranchises, setBrandFranchises] = useState([])
    const [isFranchisesLoading, setIsFranchisesLoading] = useState(false)
    const [estimateRes, setEstimateRes] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [selectedEstimate, setselectedEstimate] = useState(null)
    const [isOpenViewEstimateModal, setIsOpenViewEstimateModal] = useState(false)
    const [toggleReloadEstimates, setToggleReloadEstimates] = useState(false)
    const [isError, setIsError] = useState(false)
    const history = useHistory();
    const debouncedHandleGetBrandFranchises = useDebounce({ franchiseId: filtration.franchiseId, createdStart: filtration.createdStart, createdEnd: filtration.createdEnd, page: filtration.page, pageSize: filtration.pageSize, setEstimateRes: setEstimateRes, setIsLoading: setIsLoading, setIsError: setIsError }, ({ franchiseId, createdOnOrAfter: createdStart, createdOnOrBefore: createdEnd, page, pageSize, setEstimateRes, setIsLoading, setIsError }) => {
        if (filtration?.franchiseId) handleGetServiceTitanEstimates(franchiseId, createdStart, createdEnd, filtration?.status, page, pageSize, setEstimateRes, setIsLoading, setIsError)
    }, 1000)

    useEffect(() => {
        handleGetBrandFranchises(filtration?.brandBrandId, setBrandFranchises, setIsFranchisesLoading)
    }, [filtration?.brandBrandId])

    useEffect(() => {
        if (urlPramData?.brandId && urlPramData?.franchiseId && urlPramData?.estimateId) {
            let brandId = allBrands?.find((brand) => brand?.brand_id === urlPramData?.brandId)?.id
            let brand = allBrands?.find((brand) => brand?.brand_id === urlPramData?.brandId)
            let franchise = brandFranchises?.find((franchise) => franchise?.id === urlPramData?.franchiseId)
            setFiltration({ ...INITIAL_FILTRATION, brandId: brandId, brandBrandId: urlPramData?.brandId, brand: brand, franchiseId: urlPramData?.franchiseId, franchise: franchise })
        }
    }, [allBrands, urlPramData?.brandId, urlPramData?.franchiseId, urlPramData?.estimateId, brandFranchises])

    useEffect(() => {
        if (filtration?.franchiseId && filtration?.franchise && urlPramData?.estimateId) handleGetServiceTitanEstimateById(filtration?.franchiseId, urlPramData?.estimateId, setselectedEstimate, setIsOpenViewEstimateModal)
        return () => {
            setselectedEstimate(null)
            setIsOpenViewEstimateModal(false)
        }
    }, [filtration?.franchiseId, urlPramData?.estimateId, filtration?.franchise])

    useEffect(() => {
        setIsLoading(true)
        setEstimateRes({})
        debouncedHandleGetBrandFranchises({ franchiseId: filtration.franchiseId, createdOnOrAfter: filtration.createdStart, createdOnOrBefore: filtration.createdEnd, page: filtration.page, pageSize: filtration.pageSize, setEstimateRes: setEstimateRes, setIsLoading: setIsLoading, setIsError: setIsError })
    }, [filtration.brandBrandId, toggleReloadEstimates, filtration.franchiseId, filtration.status, filtration.createdStart, filtration.createdEnd, filtration.page, filtration.pageSize, filtration.name, debouncedHandleGetBrandFranchises])

    function handleOpenViewEstimateModal(estimate) {
        setselectedEstimate(estimate)
        setIsOpenViewEstimateModal(true)
    }

    function handleCloseViewEstimateModal(shouldReloadEstimates = false) {
        if (shouldReloadEstimates) {
            setToggleReloadEstimates(!toggleReloadEstimates)
        }
        if (urlPramData?.estimateId) {
            urlParams.delete("estimateId")
            urlParams.delete("brandId")
            urlParams.delete("franchiseId")
            history.push({ pathname: history.location.pathname, search: urlParams.toString() })
        }
        setselectedEstimate(null)
        setIsOpenViewEstimateModal(false)
    }

    function sortEstimates(estimates) {
        let filterdEstimates = estimates?.filter((estimate) => estimate?.jobId)
        if (filtration?.status === "sold") {
            return filterdEstimates?.sort((a, b) => {
                return new Date(b.soldOn) - new Date(a.soldOn);
            });
        } else if (filtration?.status === "open") {
            return filterdEstimates?.sort((a, b) => {
                return new Date(b.createdOn) - new Date(a.createdOn);
            });
        } else {
            return filterdEstimates?.sort((a, b) => {
                return new Date(b.modifiedOn) - new Date(a.modifiedOn);
            });
        }
    }

    return <Box sx={{ height: "auto", display: "flex", flexDirection: "column" }} >
        <FiltrationArea filtration={filtration} setFiltration={(filter) => setFiltration({ ...filter, page: 1 })} brands={allBrands} isBrandsLoading={isBrandsLoading} franchises={brandFranchises.filter((f) => f?.service_brand === "ServiceTitan")} isFranchisesLoading={isFranchisesLoading} />
        {!filtration?.brandId || !filtration?.franchiseId ? <Box sx={{ marginTop: "8em" }} ><PleaseSelectBrandAndFranchise /></Box>
            : <TableContainer sx={{ flexGrow: 2, display: "flex", flexDirection: "column", marginTop: "0.3em" }}>
                <Table aria-label="simple table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell className="table-header" style={{ textAlign: "left" }} >#</TableCell>
                            <TableCell className="table-header" style={{ textAlign: "left" }} >Estimate Id</TableCell>
                            <TableCell className="table-header" style={{ textAlign: "left" }}  >Brand</TableCell>
                            <TableCell className="table-header" style={{ maxWidth: "250px", textAlign: "left", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}  >Franchise</TableCell>
                            <TableCell className="table-header" style={{ maxWidth: "250px", textAlign: "left", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}  >Estimate Name</TableCell>
                            <TableCell className="table-header" style={{ textAlign: "left" }}  >Job Id</TableCell>
                            <TableCell className="table-header" style={{ textAlign: "left" }}  >{filtration?.status === "sold" ? "Sold On" : "Created On"}</TableCell>
                            <TableCell className="table-header" style={{ textAlign: "left" }}  >Estimate Status</TableCell>
                            <TableCell className="table-header" style={{ textAlign: "left", minWidth: "100px" }} >Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    {(!filtration?.franchiseId) ? buildSelectBrandView() :
                        isLoading ? buildLoadingView()
                            : isError ? buildErrorView()
                                : !estimateRes?.data?.length ? buildNoEstimatesToShowView() : <TableBody sx={{ flexGrow: 2 }}>
                                    {sortEstimates(estimateRes?.data)?.map?.((estimate, index) => {
                                        const brand = getMatchingBrandByBrandId(filtration?.brandBrandId, allBrands)
                                        const franchise = getById(filtration?.franchiseId, brandFranchises)
                                        return <TableRow>
                                            <TableCell className="table-header" style={{ textAlign: "left" }} >{index + 1}</TableCell>
                                            <TableCell className="table-header" style={{ textAlign: "left" }} >{estimate?.id}</TableCell>
                                            <TableCell className="table-cell" >
                                                <ImageBox alt="brand-logo" src={brand?.brand_img ?? iBSBrandLogo} />
                                            </TableCell>
                                            <TableCell className="table-header" style={{ maxWidth: "250px", textAlign: "left", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}  >{franchise?.franchise_name}</TableCell>
                                            <TableCell className="table-header" style={{ maxWidth: "250px", textAlign: "left", minWidth: "200px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}  >{estimate.name ? estimate.name : "-"}</TableCell>
                                            <TableCell className="table-header" style={{ textAlign: "left" }}  >{estimate.jobId ? estimate?.jobId : "-"}</TableCell>
                                            <TableCell className="table-header" style={{ textAlign: "left", maxWidth: "150px" }}  >{filtration?.status === "sold" ? estimate.soldOn ? getTenantTimeZoneTimeString(estimate.soldOn, generalTenantConfiguration?.time_zone, timezones) : "-" : estimate.createdOn ? getTenantTimeZoneTimeString(estimate.createdOn, generalTenantConfiguration?.time_zone, timezones) : "-"}</TableCell>
                                            <TableCell className="table-header" style={{ textAlign: "left" }} >{estimate?.status?.name ? estimate?.status?.name : "-"}</TableCell>
                                            <TableCell className="table-header" style={{ textAlign: "left", minWidth: "100px" }} ><IconButton onClick={(_) => { handleOpenViewEstimateModal(estimate) }} color="primary" size={"medium"} style={{ marginRight: "10px" }}>
                                                <Tooltip title={"View Estimate"}><VisibilityIcon style={{ height: "20px", width: "20px" }} /></Tooltip>
                                            </IconButton></TableCell>
                                        </TableRow>

                                    })}
                                </TableBody>}
                </Table>
            </TableContainer>
        }
        <Box flexGrow={2} />
        <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "end" }}>
            {estimateRes?.totalCount ?
                <Pagination onChange={(e, v) => {
                    setFiltration({ ...filtration, page: v })
                }} color="standard" count={Math.ceil(estimateRes?.totalCount / filtration.pageSize)} sx={{ alignSelf: "right" }}
                />
                : <div style={{ flexGrow: 2 }} />
            }
        </div>
        {isOpenViewEstimateModal && <ViewEstimateLandingPage open={isOpenViewEstimateModal} onClose={handleCloseViewEstimateModal} estimate={selectedEstimate}
            franchise={filtration?.franchise} brand={filtration?.brand} />}
    </Box >
}

function buildErrorView() {
    return buildInformationRowContainer(<ErrorView type={ERROR_COMPONENTS.SomthingWentWrong.type} />)
}

function buildNoEstimatesToShowView() {
    return buildInformationRowContainer(<ErrorView type={ERROR_COMPONENTS.NoEstimatesFound.type} />)
}

function buildLoadingView() {
    return buildInformationRowContainer(<CircularProgress size={30} color="inherit" />)
}

function buildSelectBrandView() {
    return buildInformationRowContainer(<PleaseSelectBrandAndFranchise message={"Please select a brand and franchise"} />)
}

function buildInformationRowContainer(children) {
    return <TableRow><TableCell colSpan={10} align="center" style={{ border: "none", height: "300px", color: "grey" }}>
        {children}
    </TableCell></TableRow>;
}