import { Drawer } from "@mui/material";
import PhoneClient from "../../../pages/other/components/PhoneClient";

export default function PhoneClientDrawer({ drawerOpen, onClose }) {

    return <Drawer
        sx={{ zIndex: 1301 }} anchor="right" lassName="dock-drawer" open={drawerOpen} onClose={onClose} >
        <div className="agent-assist-box">
            <PhoneClient />
        </div>
    </Drawer >
}