import { useContext, useEffect, useMemo, useState } from "react"
import { handleGetTasks } from "../../../../state-services/schedule/schedule"
import { CommonContext } from "../../../../contexts/CommonContextProvider"
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import { Autocomplete, Box, Button, Checkbox, Collapse, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material"
import moment from "moment"
import { UserContext } from "../../../../contexts/User"
import LoadingRow from "../../jobs/components/LoadingRow"
import ErrorRow from "../../jobs/components/ErrorRow"
import CustomDatePicker from "../../../../components/CustomDatePicker"
import { renderBrandOption, renderUserOption } from "../../../../components/servicetitan/RenderInput"
import { getMatchingBrandByBrandId, getMatchingUserByUserId, getUserFullName } from "../../../../utils/miscellaneous"
import NoDataRow from "../../jobs/components/NoDataRow"
import TaskViewDialog from "./TaskViewDialog";
import TaskCreateDialog from "./TaskCreateDialog";
import { getTenantTimeZoneTimeString } from "../../../../utils/time/time";
import TaskDeleteDialog from "./TaskDeleteDialog";
import TaskAssignDialog from "./TaskAssignDialog";
import ImageBox from "../../../../components/LogoBox/ImageBox";
import TaskMoveDialog from "./TaskMoveDialog";
import useDebounce from "../../../../hooks/useDebounce";
import CustomTextField from "../../../../components/CustomTextField";
import { getMatchingTimezoneByName } from "../../../../utils/timezone/timezone";

export const TASK_TYPES = {
    ALL: {
        value: undefined,
        label: "All"
    },
    CALLBACK: {
        value: "Callback",
        label: "Callback"
    },
}

export const TASK_IS_COMPLETED_STATUES = {
    ALL: {
        value: undefined,
        label: "All"
    },
    COMPLETED: {
        value: true,
        label: "Completed"
    },
    NOT_COMPLETED: {
        value: false,
        label: "Not Completed"
    },
}

const MAXIMUM_PAGE_SIZE = 100

export const PAGE_SIZE_OPTIONS = [10, 25, 50, 100]

export default function TasksSection(props) {

    const { showNotification, brands, isBrandsLoading, allUsers, generalTenantConfiguration } = useContext(CommonContext)
    const { me } = useContext(UserContext)
    const filterOptions = useMemo(() => getFilterOptions(me?.user_id, generalTenantConfiguration?.time_zone), [me?.user_id, generalTenantConfiguration?.time_zone])
    const [tasks, setTasks] = useState([])
    const [count, setCount] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [selectedTasks, setSelectedTasks] = useState([])
    const [selectedTaskToView, setSelectedTaskView] = useState()
    const [filterState, setFilterState] = useState(filterOptions.TodayTasks.getFilterState())
    const [selectedFilterOption, setSelectedFilterOption] = useState(filterOptions.Custom)
    const [isAdvanceSearchOpen, setIsAdvanceSearchOpen] = useState(true)
    const [isCreateTaskDialogOpen, setIsCreateTaskDialogOpen] = useState(false)
    const [toggleTaskRefresh, setToggleTaskRefresh] = useState(false)
    const debouncedHandleGetTasks = useDebounce(filterState, (filterState) => {
        setIsLoading(true)
        handleGetTasks(filterState.taskType, filterState.brandId, filterState.startOnOrAfter, filterState.startOnOrBefore, filterState.createdOnOrAfter, filterState.createdOnOrBefore, filterState.assignedTo, filterState.assignedBy, filterState.isCompleted, filterState.page, filterState?.pageSize, setTasks, setCount, setIsLoading, setIsError, showNotification)
    }, 1000)
    useEffect(() => {
        setSelectedTasks([])
        debouncedHandleGetTasks({ ...filterState })
    }, [filterState, showNotification, toggleTaskRefresh, debouncedHandleGetTasks])

    return <Box sx={{ width: "100%", display: "flex", flexDirection: "row", gap: "0.5em" }} >
        <Collapse easing="ease-in-out" orientation="horizontal" in={isAdvanceSearchOpen}>
            <AdvanceFiltration filterState={filterState} setFilterState={(state) => { setFilterState(state); setSelectedFilterOption(filterOptions.Custom); }} isBrandsLoading={isBrandsLoading} brands={brands} allUsers={allUsers} />
        </Collapse>
        <Box sx={{ marginRight: "0.5em", flexGrow: "5", display: "flex", flexDirection: "column", gap: "0.5em" }} >
            <ActionsBar selectedFilterOption={selectedFilterOption} setSelectedFilterOption={setSelectedFilterOption} setFilterState={setFilterState} filterOptions={filterOptions} setIsAdvanceSearchOpen={setIsAdvanceSearchOpen} setIsCreateTaskDialogOpen={setIsCreateTaskDialogOpen} selectedTasks={selectedTasks} setToggleTaskRefresh={setToggleTaskRefresh} setSelectedTasks={setSelectedTasks} count={count} />
            <TableContainer sx={{ height: "68vh" }} >
                <Table aria-label="sticky table" stickyHeader>
                    <TaskTableHead selectedTasks={selectedTasks} tasks={tasks} setSelectedTasks={setSelectedTasks} />
                    <TableBody>
                        {isLoading ? <LoadingRow colSpan={8} /> :
                            isError ? <ErrorRow calSpan={8} /> :
                                tasks?.length === 0 ? <NoDataRow colSpan={8} /> :
                                    tasks?.map((task, i) => {
                                        return <TaskRow i={i} task={task} allUsers={allUsers} setSelectedTaskView={setSelectedTaskView} selectedTasks={selectedTasks} setSelectedTasks={setSelectedTasks} brands={brands} />
                                    })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                <Pagination
                    sx={{ marginLeft: "auto" }}
                    color="standard"
                    page={filterState?.page}
                    onChange={(e, v) => setFilterState((state) => { return { ...state, page: v } })}
                    count={Math.ceil(count?.total / filterState?.pageSize)}
                />
            </Box>
        </Box>
        <TaskViewDialog isOpen={Boolean(selectedTaskToView)} task={selectedTaskToView} onClose={() => setSelectedTaskView()} />
        <TaskCreateDialog isOpen={isCreateTaskDialogOpen} onClose={(isCreated) => {
            setIsCreateTaskDialogOpen(false)
            if (isCreated) setToggleTaskRefresh((t) => !t)
        }} />
    </Box >
}

function ActionsBar({ selectedFilterOption, setSelectedFilterOption, setFilterState, filterOptions, setIsAdvanceSearchOpen, setIsCreateTaskDialogOpen, selectedTasks, setToggleTaskRefresh, setSelectedTasks, count }) {
    const [isDeleteTasksDialogOpen, setIsDeleteTasksDialogOpen] = useState(false)
    const [isTaskAssignDialogOpen, setIsTaskAssignDialogOpen] = useState(false)
    const [isTaskMoveDialogOpen, setIsTaskMoveDialogOpen] = useState(false)

    return <Box sx={{ display: "flex", flexDirection: "row", gap: "0.5em", justifyContent: "end", alignItems: "center" }}>
        {count ? <Typography variant={"body2"} fontWeight="bold" >{`Total : ${count.total ?? 0} | Unassigned : ${count.unassigned ?? 0} + Assigned : ${count.assigned ?? 0} | Completed : ${count.completed ?? 0} + Pending : ${count.pending ?? 0}`}</Typography> : <></>}
        <Box sx={{ flexGrow: 2 }} />
        <Tooltip enterDelay={1000} enterTouchDelay={1000} title={"Assign selected"}>
            <Button disabled={selectedTasks?.length === 0} size="small" sx={{ color: "primary" }} variant="text" onClick={() => setIsTaskAssignDialogOpen(true)}>{"Assign"}</Button>
        </Tooltip>
        <Tooltip enterDelay={1000} enterTouchDelay={1000} title={"Move selected"}>
            <Button disabled={selectedTasks?.length === 0} size="small" sx={{ color: "primary" }} variant="text" onClick={() => setIsTaskMoveDialogOpen(true)}>{"Move"}</Button>
        </Tooltip>
        <Tooltip enterDelay={1000} enterTouchDelay={1000} title={"Delete selected"}>
            <Button disabled={selectedTasks?.length === 0} size="small" color="error" variant="text" onClick={() => setIsDeleteTasksDialogOpen(true)}>{"Delete"}</Button>
        </Tooltip>
        <Autocomplete
            sx={{ minWidth: "400px" }}
            size="small"
            value={selectedFilterOption}
            onChange={(e, option) => {
                setSelectedFilterOption(option);
                setFilterState(option?.getFilterState());
                if (option?.label === filterOptions.Custom.label) {
                    setIsAdvanceSearchOpen(true);
                } else {
                    setIsAdvanceSearchOpen(false);
                }
            }}
            disableClearable
            disablePortal
            options={Object.values(filterOptions)}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => <CustomTextField {...params} label="Filter" />}
        />
        <Tooltip title={"Create a Task"}>
            <IconButton className={"icon-btn"} aria-label="delete" onClick={() => setIsCreateTaskDialogOpen(true)}>
                <AddIcon />
            </IconButton>
        </Tooltip>
        <TaskDeleteDialog
            isOpen={isDeleteTasksDialogOpen}
            tasks={selectedTasks}
            onClose={(isDeleted) => {
                if (isDeleted) {
                    setToggleTaskRefresh((t) => !t)
                    setSelectedTasks([])
                }
                setIsDeleteTasksDialogOpen(false)
            }}
        />
        <TaskAssignDialog
            isOpen={isTaskAssignDialogOpen}
            tasks={selectedTasks}
            onClose={(isAssigned) => {
                if (isAssigned) setToggleTaskRefresh((t) => !t)
                setIsTaskAssignDialogOpen(false)
            }}
        />
        <TaskMoveDialog
            isOpen={isTaskMoveDialogOpen}
            tasks={selectedTasks}
            onClose={(isCopied) => {
                if (isCopied) setToggleTaskRefresh((t) => !t)
                setIsTaskMoveDialogOpen(false)
            }}
        />
    </Box>;
}

function TaskTableHead({ selectedTasks, tasks, setSelectedTasks }) {
    const [isHovered, setIsHovered] = useState(false);
    const notCompletedTasks = useMemo(() => {
        return tasks?.filter((t) => !t?.is_completed);
    }, [tasks])

    return <TableHead>
        <TableRow>
            <TableCell sx={{ padding: "auto", width: "5em" }} ><div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} >{(isHovered || (selectedTasks?.length === notCompletedTasks?.length && notCompletedTasks?.length !== 0)) ? <Tooltip title={"Select All"} ><Checkbox sx={{ padding: "0", paddingRight: "0.5em" }} checked={(selectedTasks?.length === notCompletedTasks?.length && notCompletedTasks?.length !== 0)} size="small" onChange={(e) => {

                if (selectedTasks?.length === notCompletedTasks?.length || !e?.target?.checked) {
                    setSelectedTasks([]);
                } else if (e?.target?.checked) {
                    setSelectedTasks(notCompletedTasks);
                }
            }} /></Tooltip> : "#"}
            </div>
            </TableCell>
            <TableCell>Task ID</TableCell>
            <TableCell>Task Type</TableCell>
            <TableCell>Brand</TableCell>
            <TableCell>Start Time</TableCell>
            <TableCell>Assigned To</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Actions</TableCell>
        </TableRow>
    </TableHead>;
}

function TaskRow({ i, task, allUsers, setSelectedTaskView, selectedTasks, setSelectedTasks, brands }) {

    const { generalTenantConfiguration, timezones } = useContext(CommonContext)
    const [isHovered, setIsHovered] = useState(false);
    const isChecked = useMemo(() => {
        return selectedTasks?.find((t) => t.id === task.id) ? true : false
    }, [selectedTasks, task])

    return <TableRow key={task?.id}>
        <TableCell sx={{ padding: "auto" }} ><div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} >{((isHovered || isChecked) && !task?.is_completed) ? <Checkbox sx={{ padding: "0" }} size="small" checked={isChecked} onChange={(e) => {
            if (e.target.checked) {
                setSelectedTasks((tasks) => [...tasks, task])
            } else {
                setSelectedTasks((tasks) => tasks.filter((t) => t.id !== task.id))
            }
        }} /> : <Tooltip title={"Completed Tasks cannot be selected"}><Box>{i + 1}</Box></Tooltip>}</div></TableCell>
        <TableCell>{task?.id}</TableCell>
        <TableCell>{task?.task_type}</TableCell>
        <TableCell>{task?.brand_id ? <ImageBox alt={"brand"} src={getMatchingBrandByBrandId(task?.brand_id, brands)?.brand_img} /> : "[ Unassigned ]"}</TableCell>
        <TableCell>{getTenantTimeZoneTimeString(task?.start_time_utc, generalTenantConfiguration?.time_zone, timezones)}</TableCell>
        <TableCell>{task?.assigned_to ? getUserFullName(getMatchingUserByUserId(task?.assigned_to, allUsers)) : "[ Unassigned ]"}</TableCell>
        <TableCell>{task?.is_completed ? "Completed" : "Not Completed"}</TableCell>
        <TableCell>
            <Box sx={{ display: "flex", flexDirection: "row", gap: "0.5em" }}>
                <IconButton size="small" onClick={() => setSelectedTaskView(task)}><VisibilityIcon /></IconButton>
            </Box>
        </TableCell>
    </TableRow>;
}

function AdvanceFiltration({ filterState, setFilterState, isBrandsLoading, brands, allUsers }) {

    const { generalTenantConfiguration, timezones } = useContext(CommonContext)

    const [pageSize, setPageSize] = useState(filterState?.pageSize)
    const allUsersIncludingUnassigned = useMemo(() => {
        const allUsersSorted = [...allUsers]?.sort?.((a, b) => a?.first_name?.localeCompare?.(b?.first_name))
        return [{ user_id: 0, first_name: "[ Unassigned ]", last_name: "" }, ...allUsersSorted]
    }, [allUsers])

    const allAdminAndSupervisorUsers = useMemo(() => {
        return allUsers?.filter((user) => user?.level >= 60)?.sort?.((a, b) => a?.first_name?.localeCompare?.(b?.first_name))
    }, [allUsers])

    const brandsIncludingUnassigned = useMemo(() => {
        return [{ brand_id: 0, brand_name: "[ Unassigned ]" }, ...brands]
    }, [brands])

    function handleOnPageSizeChanged(e, size) {
        const pageSize = parseInt(size ?? e?.target.value)
        if (pageSize && pageSize <= MAXIMUM_PAGE_SIZE) {
            setFilterState({ ...filterState, pageSize: pageSize })
        }
        setPageSize((pageSize ? pageSize : 0))
    }

    return <Box sx={{ marginTop: "1em", display: "flex", flexDirection: "column", flexGrow: 1, gap: "0.75em" }}>
        <Typography variant="h6">Custom Filter</Typography>
        <Autocomplete
            sx={{ flexGrow: 1 }}
            size="small"
            noOptionsText={"No Task Types"}
            disablePortal
            disableClearable
            options={Object.values(TASK_TYPES)}
            getOptionLabel={(taskType) => taskType.label}
            value={Object.values(TASK_TYPES).find((t) => t.value === filterState.taskType) ?? null}
            onChange={(_, taskType) => {
                setFilterState({ ...filterState, taskType: taskType?.value })
            }}
            renderInput={(params) => <CustomTextField {...params} label="Task Type" />} />
        <div style={{ zIndex: 8 }}>
            <CustomDatePicker
                maxTime={filterState.startOnOrBefore ? new Date(filterState.startOnOrBefore) : undefined}
                disabled={false}
                label={`Starts After ( ${getMatchingTimezoneByName(generalTenantConfiguration?.time_zone, timezones)?.abbreviation ?? ""} )`}
                value={new Date(filterState.startOnOrAfter)}
                timeZone={generalTenantConfiguration?.time_zone}
                onChange={(date) => {
                    if (date) setFilterState({ ...filterState, startOnOrAfter: date?.toISOString?.() })
                }}
                clearable={false}
                required={true}
            />
        </div>
        <div style={{ zIndex: 7 }}>
            <CustomDatePicker
                minTime={filterState.startOnOrAfter ? new Date(filterState.startOnOrAfter) : undefined}
                disabled={false}
                label={`Starts Before ( ${getMatchingTimezoneByName(generalTenantConfiguration?.time_zone, timezones)?.abbreviation ?? ""} )`}
                value={new Date(filterState.startOnOrBefore)}
                timeZone={generalTenantConfiguration?.time_zone}
                onChange={(date) => {
                    if (date) setFilterState({ ...filterState, startOnOrBefore: date?.toISOString?.() })
                }}
                clearable={false}
                required={true}
            />
        </div>
        <div style={{ zIndex: 6 }}>
            <CustomDatePicker
                maxTime={filterState.createdOnOrBefore ? new Date(filterState.createdOnOrBefore) : new Date()}
                disabled={false}
                label={`Created After ( ${getMatchingTimezoneByName(generalTenantConfiguration?.time_zone, timezones)?.abbreviation ?? ""} )`}
                value={filterState.createdOnOrAfter ? new Date(filterState.createdOnOrAfter) : undefined}
                timeZone={generalTenantConfiguration?.time_zone}
                onChange={(date) => {
                    setFilterState({ ...filterState, createdOnOrAfter: date?.toISOString?.() })
                }}
                clearable={false}
                required={false} />
        </div>
        <div style={{ zIndex: 5 }}>
            <CustomDatePicker
                minTime={filterState.createdOnOrAfter ? new Date(filterState.createdOnOrAfter) : undefined}
                maxTime={new Date()}
                disabled={false}
                label={`Created Before ( ${getMatchingTimezoneByName(generalTenantConfiguration?.time_zone, timezones)?.abbreviation ?? ""} )`}
                value={filterState.createdOnOrBefore ? new Date(filterState.createdOnOrBefore) : undefined}
                timeZone={generalTenantConfiguration?.time_zone}
                onChange={(date) => {
                    setFilterState({ ...filterState, createdOnOrBefore: date?.toISOString?.() })
                }}
                clearable={false}
                required={false} />
        </div>
        <Autocomplete
            sx={{ minWidth: "250px", flexGrow: 1 }}
            size="small"
            loading={isBrandsLoading}
            noOptionsText={"No brands"}
            disablePortal
            options={brandsIncludingUnassigned}
            getOptionLabel={(brand) => brand.brand_name}
            renderOption={renderBrandOption}
            value={filterState.brandId !== undefined ? getMatchingBrandByBrandId(filterState.brandId, brandsIncludingUnassigned) ?? null : null}
            onChange={(_, brand) => {
                setFilterState({ ...filterState, brandId: brand?.brand_id })
            }}
            renderInput={(params) => <CustomTextField {...params} label="Brand" />} />
        <Autocomplete
            sx={{ minWidth: "200px", flexGrow: 1 }}
            size="small"
            disablePortal
            clearable={true}
            options={allUsersIncludingUnassigned}
            getOptionLabel={(user) => getUserFullName(user)}
            renderOption={renderUserOption}
            value={filterState.assignedTo !== undefined ? getMatchingUserByUserId(filterState.assignedTo, allUsersIncludingUnassigned) ?? null : null}
            onChange={(_, user) => {
                setFilterState({ ...filterState, assignedTo: user?.user_id })
            }}
            renderInput={(params) => <CustomTextField {...params} label="Assigned To" />} />
        <Autocomplete
            sx={{ minWidth: "200px", flexGrow: 1 }}
            size="small"
            disablePortal
            options={allAdminAndSupervisorUsers}
            getOptionLabel={(user) => getUserFullName(user)}
            renderOption={renderUserOption}
            value={filterState.assignedBy ? getMatchingUserByUserId(filterState.assignedBy, allUsers) ?? null : null}
            onChange={(_, user) => {
                setFilterState({ ...filterState, assignedBy: user?.user_id })
            }}
            renderInput={(params) => <CustomTextField {...params} label="Assigned By" />}
        />
        <Autocomplete
            sx={{ minWidth: "120px", flexGrow: 1 }}
            size="small"
            disablePortal
            disableClearable
            options={Object.values(TASK_IS_COMPLETED_STATUES)}
            getOptionLabel={(status) => status?.label}
            value={Object.values(TASK_IS_COMPLETED_STATUES)?.find((s) => s.value === filterState.isCompleted)}
            onChange={(_, status) => {
                setFilterState({ ...filterState, isCompleted: status?.value })
            }}
            renderInput={(params) => <CustomTextField {...params} label="Status" />} />
        <Autocomplete
            sx={{ minWidth: "120px", flexGrow: 1 }}
            size="small"
            disablePortal
            disableClearable
            freeSolo
            options={Object.values(PAGE_SIZE_OPTIONS)}
            getOptionLabel={(size) => size}
            value={Object.values(PAGE_SIZE_OPTIONS)?.find((size) => size === filterState.pageSize)}
            onChange={handleOnPageSizeChanged}
            renderInput={(params) => <CustomTextField {...params} type={"number"} required={true} error={(pageSize > MAXIMUM_PAGE_SIZE || !pageSize)} helperText={(pageSize > MAXIMUM_PAGE_SIZE) ? "Invalid Page Size" : !pageSize ? "Required" : ""} onChange={handleOnPageSizeChanged} label="Page Size" />} />
    </Box>
}

function getFilterOptions(currentUserId, timezone) {
    return {
        Custom: {
            label: "Custom Filter",
            getFilterState: function () {
                return {
                    taskType: TASK_TYPES.ALL.value,
                    brandId: undefined,
                    startOnOrAfter: timezone ? moment().tz(timezone)?.startOf?.("day")?.toISOString?.() : moment()?.startOf?.("day")?.toISOString?.(),
                    startOnOrBefore: timezone ? moment().tz(timezone)?.endOf?.("day")?.toISOString?.() : moment()?.endOf?.("day")?.toISOString?.(),
                    createdOnOrAfter: undefined,
                    createdOnOrBefore: undefined,
                    assignedTo: undefined,
                    assignedBy: undefined,
                    isCompleted: undefined,
                    page: 1,
                    pageSize: 50,
                }
            }
        },
        TodayTasks: {
            label: "Today",
            getFilterState: function () {
                return {
                    taskType: TASK_TYPES.ALL.value,
                    brandId: undefined,
                    startOnOrAfter: timezone ? moment().tz(timezone)?.startOf?.("day")?.toISOString?.() : moment()?.startOf?.("day")?.toISOString?.(),
                    startOnOrBefore: timezone ? moment().tz(timezone)?.endOf?.("day")?.toISOString?.() : moment()?.endOf?.("day")?.toISOString?.(),
                    createdOnOrAfter: undefined,
                    createdOnOrBefore: undefined,
                    assignedTo: undefined,
                    assignedBy: undefined,
                    isCompleted: undefined,
                    page: 1,
                    pageSize: 50,
                }
            }
        },
        TodayUnassigned: {
            label: "Today - Unassigned",
            getFilterState: function () {
                return {
                    taskType: TASK_TYPES.ALL.value,
                    brandId: undefined,
                    startOnOrAfter: timezone ? moment().tz(timezone)?.startOf?.("day")?.toISOString?.() : moment()?.startOf?.("day")?.toISOString?.(),
                    startOnOrBefore: timezone ? moment().tz(timezone)?.endOf?.("day")?.toISOString?.() : moment()?.endOf?.("day")?.toISOString?.(),
                    createdOnOrAfter: undefined,
                    createdOnOrBefore: undefined,
                    assignedTo: 0,
                    assignedBy: undefined,
                    isCompleted: undefined,
                    page: 1,
                    pageSize: 50,
                }
            }
        },
        TodayCompletedTasks: {
            label: "Today - Completed",
            getFilterState: function () {
                return {
                    taskType: TASK_TYPES.ALL.value,
                    brandId: undefined,
                    startOnOrAfter: timezone ? moment().tz(timezone)?.startOf?.("day")?.toISOString?.() : moment()?.startOf?.("day")?.toISOString?.(),
                    startOnOrBefore: timezone ? moment().tz(timezone)?.endOf?.("day")?.toISOString?.() : moment()?.endOf?.("day")?.toISOString?.(),
                    createdOnOrAfter: undefined,
                    createdOnOrBefore: undefined,
                    assignedTo: undefined,
                    assignedBy: undefined,
                    isCompleted: true,
                    page: 1,
                    pageSize: 50,
                }
            }
        },
        TodayUncompletedTasks: {
            label: "Today - Not Completed",
            getFilterState: function () {
                return {
                    taskType: TASK_TYPES.ALL.value,
                    brandId: undefined,
                    startOnOrAfter: timezone ? moment().tz(timezone)?.startOf?.("day")?.toISOString?.() : moment()?.startOf?.("day")?.toISOString?.(),
                    startOnOrBefore: timezone ? moment().tz(timezone)?.endOf?.("day")?.toISOString?.() : moment()?.endOf?.("day")?.toISOString?.(),
                    createdOnOrAfter: undefined,
                    createdOnOrBefore: undefined,
                    assignedTo: undefined,
                    assignedBy: undefined,
                    isCompleted: false,
                    page: 1,
                    pageSize: 50,
                }
            }
        },
        TodayAssignedToMeTasks: {
            label: "Today - Assigned To Me",
            getFilterState: function () {
                return {
                    taskType: TASK_TYPES.ALL.value,
                    brandId: undefined,
                    startOnOrAfter: timezone ? moment().tz(timezone)?.startOf?.("day")?.toISOString?.() : moment()?.startOf?.("day")?.toISOString?.(),
                    startOnOrBefore: timezone ? moment().tz(timezone)?.endOf?.("day")?.toISOString?.() : moment()?.endOf?.("day")?.toISOString?.(),
                    createdOnOrAfter: undefined,
                    createdOnOrBefore: undefined,
                    assignedTo: currentUserId,
                    assignedBy: undefined,
                    isCompleted: undefined,
                    page: 1,
                    pageSize: 50,
                }
            }
        },
        TodayAssignedByMeTasks: {
            label: "Today - Assigned By Me",
            getFilterState: function () {
                return {
                    taskType: TASK_TYPES.ALL.value,
                    brandId: undefined,
                    startOnOrAfter: timezone ? moment().tz(timezone)?.startOf?.("day")?.toISOString?.() : moment()?.startOf?.("day")?.toISOString?.(),
                    startOnOrBefore: timezone ? moment().tz(timezone)?.endOf?.("day")?.toISOString?.() : moment()?.endOf?.("day")?.toISOString?.(),
                    createdOnOrAfter: undefined,
                    createdOnOrBefore: undefined,
                    assignedTo: undefined,
                    assignedBy: currentUserId,
                    isCompleted: undefined,
                    page: 1,
                    pageSize: 50,
                }
            }
        },
        YesterdayNotCompletedTasks: {
            label: "Yesterday - Not Completed",
            getFilterState: function () {
                return {
                    taskType: TASK_TYPES.ALL.value,
                    brandId: undefined,
                    startOnOrAfter: timezone ? moment().tz(timezone)?.subtract(1, "d")?.startOf?.("day")?.toISOString?.() : moment()?.subtract()?.startOf?.("day")?.toISOString?.(),
                    startOnOrBefore: timezone ? moment().tz(timezone)?.subtract(1, "d")?.endOf?.("day")?.toISOString?.() : moment()?.subtract()?.endOf?.("day")?.toISOString?.(),
                    createdOnOrAfter: undefined,
                    createdOnOrBefore: undefined,
                    assignedTo: undefined,
                    assignedBy: undefined,
                    isCompleted: false,
                    page: 1,
                    pageSize: 50,
                }
            }
        }
    }
}
