import { Autocomplete } from "@mui/material"
import CustomTextField from "../../../../../components/CustomTextField"
import RenderInput from "../../../../../components/servicetitan/RenderInput"

export default function SelectExistingCustomer(props) {
    const { franchise, isRequired, existingCustomers, isExistingCustomersLoading, selectedCustomer, setSelectedCustomer } = props

    return <Autocomplete
        sx={{ width: "100%", }}
        size="small"
        noOptionsText={franchise ? "No customer found" : "Please select a franchise"}
        loading={isExistingCustomersLoading}
        value={selectedCustomer ?? null}
        disablePortal
        disableClearable
        options={existingCustomers}
        getOptionLabel={(customer) => customer?.title}
        renderOption={(props, customer) => <RenderInput {...props} key={customer?.objectID} content={customer?.title ?? "-"} />}
        onChange={(_, customer) => {
            setSelectedCustomer(customer);
        }}
        renderInput={(params) => <CustomTextField {...params} required={isRequired} label="Select Customer" />}
    />
}