import { Box } from '@mui/system'
import React from 'react'
import { addLeadToRedisSearch } from '../../../../../../../services/globalSearch'
import { deleteCustomerContacts, updateServiceTitanMiddlewareLead } from '../../../../../../../services/serviceTitan'
import { ContactBoxWithClearIcon, checkIfContactCanBeDeleted } from '../../../../../jobs/create-job-page/ContactsSection'

export default function ContactsSection({ franchiseId, selectedLeadToEdit, selectedCustomer, customerId, existingContacts, setExistingContacts, showNotification, currentlyEditingContactsCount, setCurrentlyEditingContactsCount }) {

    return (
        <Box>
            <Box sx={{ display: 'flex', flexDirection: "row", width: "100%", height: "3em", justifyContent: "left", alignItems: "center" }}>
                <h4>Contact Details</h4>
            </Box>
            <ExistingContactsSection franchiseId={franchiseId} selectedLeadToEdit={selectedLeadToEdit} selectedCustomer={selectedCustomer} customerId={customerId} existingContacts={existingContacts} setExistingContacts={setExistingContacts} showNotification={showNotification} currentlyEditingContactsCount={currentlyEditingContactsCount} setCurrentlyEditingContactsCount={setCurrentlyEditingContactsCount} />
        </Box>
    )
}

async function onRemoveContact(i, contacts = [], setContacts, customerId, selectedLeadToEdit, selectedCustomer) {
    contacts.splice(i, 1)
    setContacts?.([...contacts])
    try {
        await updateServiceTitanMiddlewareLead({ customerId: customerId, customerContacts: contacts })
        let updatedLeadDetails = {
            id: selectedLeadToEdit?.middlewareServiceTitanLead?.id,
            leadId: selectedLeadToEdit?.middlewareServiceTitanLead?.leadId,
            customerName: selectedCustomer?.name,
            customerContacts: contacts,
        }
        await addLeadToRedisSearch(updatedLeadDetails)
    } catch (error) { }
}

async function handleDeleteContact(i, contacts, setContacts, franchiseId, customerId, selectedLeadToEdit, selectedCustomer) {
    try {
        const contact = contacts[i]
        await deleteCustomerContacts(franchiseId, customerId, contact?.id)
        onRemoveContact(i, contacts, setContacts, customerId, selectedLeadToEdit, selectedCustomer)
    } catch (err) { }
}


function ExistingContactsSection({ franchiseId, selectedLeadToEdit, selectedCustomer, customerId, existingContacts, setExistingContacts, showNotification, currentlyEditingContactsCount, setCurrentlyEditingContactsCount }) {

    return <Box display={"flex"} flexDirection={"column"} alignItems={"start"} gap="1em" paddingBottom="0.1em">
        {existingContacts?.map((c, i) => {
            return <ContactBoxWithClearIcon key={i} franchiseId={franchiseId} selectedLeadToEdit={selectedLeadToEdit} selectedCustomer={selectedCustomer} customerId={customerId} index={i} canDeleteContact={checkIfContactCanBeDeleted(existingContacts, c)} isLeadSection={true} contacts={existingContacts} setContacts={setExistingContacts} allContacts={[...existingContacts]} contact={c} disableClearable={false} isDisabled={true} isEditDisabled={false} onRemoveContact={() => handleDeleteContact(i, existingContacts, setExistingContacts, franchiseId, customerId, selectedLeadToEdit, selectedCustomer)} isExistingContact={true} showNotification={showNotification} onStartEdit={() => setCurrentlyEditingContactsCount(currentlyEditingContactsCount + 1)} onEndEdit={() => setCurrentlyEditingContactsCount(currentlyEditingContactsCount - 1)} />
        })}
    </Box>
}

