export function getPhoneNumberWithInputMask(phoneNumber = "", isTemplateNeeded = false) {
    if (!phoneNumber && isTemplateNeeded) {
        return `( _ _ _ ) _ _ _ - _ _ _ _ x _ _ _ _ _`
    } else if (!phoneNumber) {
        return ""
    }
    const first = phoneNumber.substring(0, 3);
    const second = phoneNumber.substring(3, 6);
    const third = phoneNumber.substring(6, 10);
    const extension = phoneNumber.substring(10)
    let phoneNumberWithMask = ""
    if (first.length > 0) {
        phoneNumberWithMask += `(${first}`
    }
    if (second.length > 0) {
        phoneNumberWithMask += `) ${second}`
    }
    if (third.length > 0) {
        phoneNumberWithMask += `-${third}`
    }
    if (extension.length > 0) {
        phoneNumberWithMask += `x${extension}`
    }
    return phoneNumberWithMask;
}

export function getPhoneNumberWithInputMaskAndSeperateExtention(phoneNumber = "", isTemplateNeeded = false) {
    if (!phoneNumber && isTemplateNeeded) {
        return `( _ _ _ ) _ _ _ - _ _ _ _ x _ _ _ _ _`
    } else if (!phoneNumber) {
        return ""
    }
    const first = phoneNumber.substring(0, 3);
    const second = phoneNumber.substring(3, 6);
    const third = phoneNumber.substring(6, 10);
    let phoneNumberWithMask = ""
    if (first.length > 0) {
        phoneNumberWithMask += `(${first}`
    }
    if (second.length > 0) {
        phoneNumberWithMask += `) ${second}`
    }
    if (third.length > 0) {
        phoneNumberWithMask += `-${third}`
    }
    return phoneNumberWithMask;
}

export function removeInputMask(phoneNumberWithInputMask = "") {
    if (!phoneNumberWithInputMask) return ""
    return phoneNumberWithInputMask.replace(/\D/g, '');
}