import { FetchClient } from "../utils/fetchClient";

export const updateAbandenedCall = async (id, body) => {
    return FetchClient(`/data/abandoned-call-logs?type=single&id=${id}`).PUT({ payload: JSON.stringify(body) })
}

export const getAbandenedCalls = async (userLevel, date, page, size) => {
    return FetchClient(`/data/abandoned-call-logs?type=abandoned_call&userLevel=${userLevel}&date=${date}&page=${page}&size=${size}`).GET({})
}

export const getCallJobLeadDetailsByJobId = async (jobId) => {
    return FetchClient(`/data/jobs/map?jobid=${jobId}`).GET({})
}

export const getOutboundCallsByCallId = async (callId) => {
    return FetchClient(`/data/jobs/map/outbound?callid=${callId}`).GET({})
}

export const getOutboundCallsWithLeadDetails = async (page, size, createdAfter, createdBefore, status, callFor = 0,brandPrimaryIds=[]) => {
    let brandPrimaryIdsStr = brandPrimaryIds.join(',');
    return FetchClient(`/data/outbound-call/with-job-lead?created_after=${createdAfter}&created_before=${createdBefore}&page=${page}&size=${size}&status=${status}&call_for=${callFor}&brand_primary_ids=${brandPrimaryIdsStr}`).GET({})
}

export const getAllCallsService = async (page, size, createdOn, startDate, endDate, callFor, brandIds =[], brandPrimaryIds=[]) => {
    let brandIdsStr = brandIds.join(',');
    let brandPrimaryIdsStr = brandPrimaryIds.join(',');
    return FetchClient(`/data/jobs/all?created_on=${createdOn}&start_date=${startDate}&end_date=${endDate}&page=${page}&size=${size}&call_for=${callFor}&brand_ids=${brandIdsStr}&brand_primary_ids=${brandPrimaryIdsStr}`).GET({})
}

