import { useEffect, useState } from "react";

export function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
        if (value !== "") {
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);
            return () => {
                clearTimeout(handler);
            };
        } else {
            setDebouncedValue('')
        }
    }, [value, delay]);
    return debouncedValue;
}