import { useEffect, useMemo, useState } from "react";
import { Paper, Typography, Box, CircularProgress } from "@mui/material";
import DialogHeader from "../../../../components/dialog/DialogHeader";
import { CRMS } from "../../../../constants/common";
import ServiceTitanMainSection from "./ServiceTitanMainSection";
import FranchiseSelector from "./FranchiseSelector";
import CreateJobStepper from "./CreateJobStepper";
import { CREATE_JOB_SECTION_IDS, SECTIONS } from "../../../../constants/jobs";
import { VonigoMainSection } from "./vonigo/VonigoMainSection";
import AppointmentReschedlingSection from "./servicetitan/AppointmentReschedlingSection";
import MultipleAppointmentSection from "./servicetitan/MultipleAppointmentSection";
import PleaseSelectBrandAndFranchise from "../../../../components/PleaesSelectBrandAndFranchise";

export default function CreateJobPage(props) {
    const { onClose, selectedJobToEdit, brands, isBrandsLoading, initialZipCode, setOpenViewJob, openViewJob, brand, setBrand, franchise, setFranchise,
        selectedPhoneNumber, selectedZipCode, showNotification, selectedDraftToContinue, workOrderToReschedule, setWorkOrderToReschedule, isRescheduleWorkOrder, setIsRescheduleWorkOrder,
        isCreateMultipleWorkOrders, setIsCreateMultipleWorkOrders, selectedJobToView, setSelectedJobToView, jobToAddMultipleAppointments, appointmentToReschedule } = props
    const steps = useMemo(() => {
        let sections = Object.values(SECTIONS)
        return sections
    }, [selectedJobToEdit])
    const [completedSections, setCompletedSections] = useState([])
    const [shouldSaveDraft, setShouldSaveDraft] = useState(false)
    const [shouldRemoveLock, setshouldRemoveLock] = useState(false)
    const [zipCode, setZipCode] = useState(initialZipCode)
    const [isZipCodeValid, setisZipCodeValid] = useState(false)
    const [franchises, setFranchises] = useState([])
    const [jobSchedulingFranchise, setJobSchedulingFranchise] = useState(franchise)
    const [isFranchisesLoading, setIsFranchisesLoading] = useState(false)
    const [isFromHomePage, setIsFromHomePage] = useState(false)

    useEffect(() => {
        handleScrollPosition()
    }, [])

    useEffect(() => {
        setCompletedSections([])
    }, [jobSchedulingFranchise?.id])


    useEffect(() => {
        setJobSchedulingFranchise(franchise)
    }, [franchises, franchise])

    function onActiveStepChanged(i = 0) {
        const section = SECTIONS[Object.keys(SECTIONS)?.[i]]
        handleScrollPosition(section?.id)
    }

    function getIsCompleted(id = SECTIONS.CUSTOMER.id) {
        return completedSections.some((v) => v.id === id)
    }

    function handleClose() {
        if (jobSchedulingFranchise && (!isCreateMultipleWorkOrders)) {
            setShouldSaveDraft(true)
        } else if (appointmentToReschedule || jobToAddMultipleAppointments) {
            onClose(jobToAddMultipleAppointments)
        } else if (!jobSchedulingFranchise) {
            onClose()
        } else {
            setshouldRemoveLock(true)
        }
    }

    return <Paper className="job-background" sx={{ position: "absolute", left: 0, right: 0, top: 0, bottom: 0, zIndex: 5, padding: "1em", backgroundImage: 'none' }}>
        <DialogHeader onClose={handleClose}>
            {isCreateMultipleWorkOrders ? <></> : <Typography variant="h5" component={"h5"} fontWeight="bold" >{selectedJobToEdit ? `Edit Job #${selectedJobToEdit?.jobNumber}` : "Create New Job"}</Typography>}
            {isCreateMultipleWorkOrders && selectedJobToView ? <Typography variant="h5" component={"h5"} fontWeight="bold" >{"Create Work Order"}</Typography> : isCreateMultipleWorkOrders && !appointmentToReschedule ? <Typography variant="h5" component={"h5"} fontWeight="bold" >{"Create Appointment"}</Typography> : <></>}
            {appointmentToReschedule ? <Typography variant="h5" component={"h5"} fontWeight="bold" >{"Reschedule"}</Typography> : <></>}
        </DialogHeader>
        <FranchiseSelector
            isDisabled={Boolean(selectedJobToEdit)}
            isFromHomePage={isFromHomePage}
            brands={brands}
            isBrandsLoading={isBrandsLoading}
            brand={brand}
            setBrand={setBrand}
            franchises={franchises}
            setFranchises={setFranchises}
            isFranchisesLoading={isFranchisesLoading}
            setIsFranchisesLoading={setIsFranchisesLoading}
            franchise={selectedJobToEdit ? franchise : jobSchedulingFranchise}
            setFranchise={setFranchise}
            initialZipCode={zipCode}
            zipCode={zipCode}
            setZipCode={setZipCode}
            isZipCodeValid={isZipCodeValid}
            setisZipCodeValid={setisZipCodeValid}
            isRescheduleWorkOrder={isRescheduleWorkOrder}
            isCreateMultipleWorkOrders={isCreateMultipleWorkOrders}
        />
        {(isCreateMultipleWorkOrders || appointmentToReschedule) && jobSchedulingFranchise?.service_brand?.trim()?.toLowerCase() === CRMS[0] ? <></> : <CreateJobStepper steps={steps} onActiveStepChanged={onActiveStepChanged} getIsCompleted={getIsCompleted} />}
        {(jobSchedulingFranchise || selectedJobToEdit) ? <Box sx={{ display: "flex", flexDirection: "row", width: "100%", height: "75%", gap: "2em" }}>
            {buildCRMCreateJobMainSection()}
        </Box>
            : <Box width="100%" marginBlock="4em" display={"flex"} justifyContent="center" color={"gray"} >
                {isFranchisesLoading ? <CircularProgress size={30} color="inherit" />
                    : <PleaseSelectBrandAndFranchise />}
            </Box>}
    </Paper>

    function buildCRMCreateJobMainSection() {
        const currentFranchise = selectedJobToEdit ? franchise : jobSchedulingFranchise
        switch (currentFranchise?.service_brand?.trim?.()?.toLowerCase?.()) {
            case CRMS[0]: return isCreateMultipleWorkOrders ? appointmentToReschedule ? <AppointmentReschedlingSection brand={brand} franchise={jobSchedulingFranchise} setZipCode={setZipCode} jobToAddMultipleAppointments={jobToAddMultipleAppointments} onClose={onClose} showNotification={showNotification} appointmentToReschedule={appointmentToReschedule} /> : <MultipleAppointmentSection brand={brand} franchise={jobSchedulingFranchise} job={jobToAddMultipleAppointments} onClose={onClose} showNotification={showNotification} /> : <ServiceTitanMainSection brand={brand} franchise={selectedJobToEdit ? franchise : jobSchedulingFranchise} setZipCode={setZipCode} setIsFromHomePage={setIsFromHomePage} selectedJobToEdit={selectedJobToEdit} selectedPhoneNumber={selectedPhoneNumber} selectedZipCode={selectedZipCode} isDisabled={selectedJobToEdit ? !franchise : !jobSchedulingFranchise} showNotification={showNotification} onClose={onClose} setCompletedSections={setCompletedSections} selectedDraftToContinue={selectedDraftToContinue} shouldSaveDraft={shouldSaveDraft} setShouldSaveDraft={setShouldSaveDraft} zipCode={zipCode} />;
            case CRMS[1]: return <VonigoMainSection brand={brand} franchise={jobSchedulingFranchise} setOpenViewJob={setOpenViewJob} openViewJob={openViewJob} workOrderToReschedule={workOrderToReschedule} setWorkOrderToReschedule={setWorkOrderToReschedule} isRescheduleWorkOrder={isRescheduleWorkOrder} setIsRescheduleWorkOrder={setIsRescheduleWorkOrder} selectedJobToView={selectedJobToView} setSelectedJobToView={setSelectedJobToView} isCreateMultipleWorkOrders={isCreateMultipleWorkOrders} setIsCreateMultipleWorkOrders={setIsCreateMultipleWorkOrders} selectedJobToEdit={selectedJobToEdit} selectedPhoneNumber={selectedPhoneNumber} selectedZipCode={selectedZipCode} isDisabled={!jobSchedulingFranchise} showNotification={showNotification} onClose={onClose} setCompletedSections={setCompletedSections} selectedDraftToContinue={selectedDraftToContinue} shouldSaveDraft={shouldSaveDraft} setShouldSaveDraft={setShouldSaveDraft} shouldRemoveLock={shouldRemoveLock} setshouldRemoveLock={setshouldRemoveLock} />;
            default: return <></>
        }
    }
}

export function handleScrollPosition(id = CREATE_JOB_SECTION_IDS.CUSTOMER) {
    const mainSection = document.getElementById(CREATE_JOB_SECTION_IDS.MAIN)
    const elm = document?.getElementById(id)
    if (elm && mainSection) {
        const scrollTo = elm?.offsetTop - mainSection?.offsetTop;
        mainSection.scrollTop = scrollTo
    }
}