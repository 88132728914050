import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import styled from "@emotion/styled";

export const SuccessIcon = styled(CheckCircleIcon)({
    fill: "#00a79d"
})

export const NotSuccessIcon = styled(CancelIcon)({
    fill: "#00a79d"
})

export const NotSuccessIconError = styled(CancelIcon)({
    fill: "red"
})