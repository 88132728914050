import { useMemo, useState } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CloseIcon from '@mui/icons-material/Close';

import { GoogleMap, LoadScript, Marker, InfoWindow, Circle } from '@react-google-maps/api'

import { IconButton } from "@mui/material";
import SubmitDialog from "./SubmitDialog";
import { CustomSlider, EnterZipModal } from "../components/custom";

import { isValidCanadianZipCode, isValidUSZipCode } from "../../../utils/validators";
import { mapStyles } from "../styles/homeStyle";
import CustomTextField from "../../../components/CustomTextField";
import '../styles/map.css'


export default function MapView(props) {
    const { onMapClose, radius, handleSetFranchisesWithinRadius, zipCode, setZipCode, zipCodeLocationData, withinRadiusFranchises, onSelectFranchise, franchisesOutsideRadius, selectedFranchise, setSelectedFranchise, handleMapDialogOpen, isZipEnterModalOpen, isFranchisesByChannelIdAndZipCodeLoading, isOtherFranchisesLoading, closeZipEnterModal, isMapDialogOpen, handleMapDialogClose, channelId, jobId, mapFranchise, brand, callerId, navigateToServiceTitanCreateLeadModal, showNotification, getIsServiceAreaFranchise,iseCheckingServiceAreaFranchise } = props
    const circleStyle = useMemo(() => {
        return {
            strokeColor: '#FF0000',
            strokeWeight: 2,
            fillColor: '#FFFFFF',
            fillOpacity: 0,
            clickable: false,
            draggable: false,
            editable: false,
            visible: true,
            radius: radius * 1609.34,
            zIndex: 1
        }
    }, [radius])

    const [mapZipCode, setMapZipCode] = useState(zipCode);

    return <Box sx={{ flexGrow: 2, paddingInline: "20px", paddingTop: '15px', borderRadius: "7px", paddingBottom: "30px", display: "flex", flexDirection: "column" }} className={"bg-shadow bg-white"}>
        <div style={{ marginBottom: "20px", display: "flex", height: "50px" }}>
            <div style={{ display: "flex", height: "40px", marginTop: "9px" }}>
                <IconButton className={"btn-primary"} onClick={onMapClose}><ArrowBackIosNewIcon /></IconButton>
            </div>
            <div style={{ display: "flex", marginLeft: "15px" }}>
                <h3 style={{ fontWeight: "500", fontSize: "20px"}} className={'text-color'}>Map</h3>
            </div>
            <div style={{ marginLeft: "auto", width: "200px", marginTop: "3px" }}>
                <h3 style={{ fontWeight: "500", fontSize: "20px", textAlign: "center" }} className={'text-color'}>Change radius</h3>
            </div>
            <div style={{ marginLeft: "10px", paddingTop: "15px", marginRight: "0.5em" }}>
                <MapRadiusSlider radius={radius} setRadius={handleSetFranchisesWithinRadius} />
            </div>
            <div style={{ display: "flex", marginLeft: "15px", marginTop: "10px" }}>
                <CustomTextField
                    onChange={(e) => setMapZipCode(e?.target?.value)}
                    value={mapZipCode}
                    id="outlined-basic"
                    size={"small"}
                    error={!(isValidCanadianZipCode(mapZipCode) || isValidUSZipCode(mapZipCode))}
                    helperText={(isValidCanadianZipCode(mapZipCode) || isValidUSZipCode(mapZipCode)) ? "" : mapZipCode ? "Please enter a valid zip code" : "Please enter a zip code"}
                    variant="outlined" />
            </div>
            <div style={{ display: "flex", marginLeft: "10px", height: "40px", marginTop: "10px" }}>
                <Button
                    onClick={() => setZipCode(mapZipCode)}
                    disabled={!(isValidCanadianZipCode(mapZipCode) || isValidUSZipCode(mapZipCode))}
                    className={(isValidCanadianZipCode(mapZipCode) || isValidUSZipCode(mapZipCode)) ? "btn-primary" : "btn-disable"}
                    size="medium"
                    variant="contained">Search
                </Button>
            </div>
        </div>
        <Box sx={{ flexGrow: 2, width: "100%", borderRadius: "0.5em" }}>
            {<LoadScript googleMapsApiKey={process.env.REACT_APP_API_KEY}>
                <GoogleMap
                    mapContainerStyle={mapStyles}
                    zoom={8}
                    center={zipCodeLocationData && zipCodeLocationData}
                >
                    <Circle center={zipCodeLocationData} options={circleStyle}></Circle>
                    <Marker
                        position={zipCodeLocationData}
                        icon={{
                            url: "https://maps.google.com/mapfiles/kml/paddle/grn-blank-lv.png",
                        }}>
                    </Marker>
                    {withinRadiusFranchises?.map?.((franchise, i) => {
                        return <div key={franchise?.id ?? i}>
                            <Marker
                                key={franchise?.id ?? i}
                                position={{ lat: parseFloat(franchise.latitude), lng: parseFloat(franchise.longitude) }}
                                onMouseOver={() => onSelectFranchise(franchise)} />
                        </div>;
                    })}
                    {franchisesOutsideRadius?.map?.((franchise, i) => {
                        return <div key={franchise?.id ?? i}>
                            <Marker
                                key={franchise?.id ?? i}
                                position={{ lat: parseFloat(franchise.latitude), lng: parseFloat(franchise.longitude) }}
                                onMouseOver={() => onSelectFranchise(franchise)} />
                        </div>;
                    })}
                    {selectedFranchise?.latitude && selectedFranchise?.longitude ? <InfoWindow
                        position={{ lat: parseFloat(selectedFranchise?.latitude), lng: parseFloat(selectedFranchise?.longitude) }}
                        clickable={true}
                        onCloseClick={() => setSelectedFranchise({})}>
                        <div style={{ display: "flex", height: "40px", justifyContent: 'space-between' }}>
                            <p style={{fontWeight: 'bold'}}>{selectedFranchise.franchise_name + " -"}</p>
                            <p style={{ marginLeft: "6px", fontWeight: 'bold' }}>{selectedFranchise?.drive_distance?.drive_distance_text}</p>
                            <Button style={{ height: "30px", marginLeft: "10px", marginTop: "6px" }} className={"btn-primary"} onClick={() => handleMapDialogOpen(selectedFranchise)}>select</Button>
                        </div>
                    </InfoWindow> : <></>}
                </GoogleMap>
            </LoadScript>}
            {isZipEnterModalOpen && !(isFranchisesByChannelIdAndZipCodeLoading || isOtherFranchisesLoading) ? <EnterZipModal open={true} onClose={closeZipEnterModal}>
                <div style={{ width: "400px", display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex" }}>
                        <p style={{ marginLeft: "10px", fontWeight: "500" }}>{(zipCodeLocationData?.lat && zipCodeLocationData?.lng) ? `No Franchise found within ${radius} mi radius` : mapZipCode ? `Could not calculate geocodes for ${mapZipCode} zip code` : "Zip code not provided"} </p>
                        <div style={{ marginLeft: "auto" }}>
                            <IconButton onClick={closeZipEnterModal}><CloseIcon style={{ height: "15px", width: "15px" }} /></IconButton>
                        </div>
                    </div>
                    <p style={{ marginLeft: "1em" }}>{mapZipCode ? "Please enter a different zip code and try again" : "Please enter a zip code and try again"} </p>
                    <div style={{ flexGrow: 2, display: "flex", alignItems: "start", marginInline: "1em", marginBottom: "1em", gap: "1em" }}>
                        <CustomTextField
                            style={{ width: "100%" }}
                            id="zipCode"
                            value={mapZipCode}
                            onChange={(e) => setMapZipCode(e?.target?.value)}
                            label={"ZIP Code"}
                            size={"small"}
                            variant="outlined"
                            error={!(isValidCanadianZipCode(mapZipCode) || isValidUSZipCode(mapZipCode))}
                            helperText={(isValidCanadianZipCode(mapZipCode) || isValidUSZipCode(mapZipCode)) ? "" : mapZipCode ? "Please enter a valid zip code" : "Please enter a zip code"} />
                        <Button
                            disabled={!(isValidCanadianZipCode(mapZipCode) || isValidUSZipCode(mapZipCode))}
                            className={(isValidCanadianZipCode(mapZipCode) || isValidUSZipCode(mapZipCode)) ? "btn-primary" : "btn-disable"}
                            size={"medium"}
                            variant="contained"
                            style={{ marginTop: "3px", marginBottom: "7px" }}
                            onClick={() => setZipCode(mapZipCode)}
                        >
                            Search
                        </Button>
                    </div>
                </div>
            </EnterZipModal>
                : <></>}
            {isMapDialogOpen ? <SubmitDialog onClose={handleMapDialogClose} open={isMapDialogOpen} channelId={channelId} jobId={jobId} franchise={mapFranchise} brand={brand} callerId={callerId} zipCode={mapZipCode} onCreateLeadPressed={navigateToServiceTitanCreateLeadModal} showNotification={showNotification} isServiceAreaFranchise={getIsServiceAreaFranchise(mapFranchise)} isOtherFranchisesLoading={iseCheckingServiceAreaFranchise} /> : <></>}
        </Box>
    </Box >;
}

function MapRadiusSlider(props) {
    const { radius, setRadius = () => { } } = props
    return <CustomSlider
        style={{ width: 150 }}
        valueLabelDisplay="on"
        value={radius}
        onChange={(e) => setRadius(e?.target?.value)}
        step={10}
        marks
        min={60}
        max={90}
    />
}