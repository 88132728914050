import { Box } from '@mui/material'
import React from 'react'
import ReactSpeedometer from "react-d3-speedometer"

export default function Speedometer({ data, width, height, minValue, maxValue, textColor = 'gray', needleColor, needleTransitionDuration, needleTransition, title, labels }) {

    const safeTextColor = textColor !== undefined ? textColor : 'gray';

    return (
        <Box sx={{ height: "300px", paddingLeft: '22%', marginBottom: '10px' }}>
            <ReactSpeedometer
                width={width}
                height={height}
                value={data}
                minValue={minValue}
                maxValue={maxValue}
                needleHeightRatio={0.6}
                textColor={safeTextColor}
                needleColor={needleColor}
                needleTransitionDuration={needleTransitionDuration}
                needleTransition={needleTransition}
                currentValueText={title}
                customSegmentLabels={labels}
            />
        </Box>
    )
}
