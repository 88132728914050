import { Tab, Tabs, CircularProgress, Collapse, IconButton, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import DescriptionIcon from '@mui/icons-material/Description';
import WorkIcon from '@mui/icons-material/Work';
import NotesSection from "./NotesSection";
import JobsSection from "./JobsSection";
import { retry } from "../../../../../state-services-dublicate/serviceTitan";
import {getLocationsOfACustomer} from "../../../../../services/serviceTitan";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const HISTORY_SECTIONS = {
    notes: {
        index: 0,
        label: "Notes",
        icon: <DescriptionIcon />
    },
    jobs: {
        index: 1,
        label: "Jobs & Appointments",
        icon: <WorkIcon />
    },
}

async function fetchCustomerLocations(franchiseId, customerId, setIsLoading, setIsError, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    setIsLoading(true);
    setIsError(false);
    let locations = [];
    try {
        const customerLocationsResponse = await getLocationsOfACustomer(franchiseId, customerId);
        if (Array.isArray(customerLocationsResponse.data)) {
            locations = customerLocationsResponse.data;
        }
    } catch (error) {
        await retry(() => fetchCustomerLocations(franchiseId, customerId, setIsLoading, setIsError, backoffConfig), backoffConfig);
        setIsError(true);
    } finally {
        setIsLoading(false);
    }
    return locations;
}

export default function HistorySection(props) {
    const { franchise, customerId, locationId, shouldShowTechnicianCriteriaIcon=false ,isLocationSection, showNotification = () => { }, setPreviousAppointmentForRescheduleJob = () => { }, setjobForTheAppointment = () => { }, setisOpenAppointmentRescheduleModal = () => { }, jobNote, setJobNote } = props
    const [currentIndex, setCurrentIndex] = useState(isLocationSection ? 1 : 0)
    const [isError, setIsError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [locations, setLocations] = useState([])
    const selectedLocationId = locations.length > 0 ? locations[0].id : locationId;
    const theme = useTheme()

    //to handle the collapse and expandation of the sections
    const [expandedSections, setExpandedSections] = useState({
        [HISTORY_SECTIONS.notes.index]: false,
        [HISTORY_SECTIONS.jobs.index]: true
    });
    useEffect(() => {
        async function loadLocations() {
            const loadedLocations = await fetchCustomerLocations(franchise?.id, customerId, setIsLoading, setIsError);
            setLocations(loadedLocations);
        }
        loadLocations();
    }, [franchise?.id, customerId]);

    function handleTabChange(e, index) {
        setCurrentIndex(index);
        setExpandedSections(prev => ({
            ...prev,
            [index]: true
        }));
        const otherTabIndex = index === HISTORY_SECTIONS.notes.index ? HISTORY_SECTIONS.jobs.index : HISTORY_SECTIONS.notes.index;
        setExpandedSections(prev => ({
            ...prev,
            [otherTabIndex]: false
        }));
    };
    function handleToggleSection(index) {
        setExpandedSections(prev => ({
            ...prev,
            [index]: !prev[index]
        }));
    }

    function getCurrentTabPanelContent(index) {
        switch (index) {
            case HISTORY_SECTIONS.notes.index:
                return (
                    <Collapse in={expandedSections[HISTORY_SECTIONS.notes.index]} timeout="auto">
                        <NotesSection franchiseId={franchise?.id} customerId={customerId} locationId={selectedLocationId} showNotification={showNotification} jobNote={jobNote} setJobNote={setJobNote} fromDialPad={false} />
                    </Collapse>
                );
            case HISTORY_SECTIONS.jobs.index:
                return (
                    <Collapse in={expandedSections[HISTORY_SECTIONS.jobs.index]} timeout="auto">
                        <JobsSection franchise={franchise} customerId={customerId} locationId={locationId} showNotification={showNotification} shouldShowTechnicianCriteriaIcon={shouldShowTechnicianCriteriaIcon}
                            setPreviousAppointmentForRescheduleJob={setPreviousAppointmentForRescheduleJob} setjobForTheAppointment={setjobForTheAppointment} setisOpenAppointmentRescheduleModal={setisOpenAppointmentRescheduleModal} shouldAllowCancelAndReschedule={true} />
                    </Collapse>
                );
            default:
                return <></>
        }
    }

    return <>
        <Box sx={{ marginTop: "0.5em", paddingBlock: "0.5em", borderBottom: 1, borderColor: 'divider', paddingInline: "1em", background: theme.palette.mode === 'dark' ? '#237563' : '#E8F0EA' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Tabs value={currentIndex} onChange={handleTabChange} sx={{ flexGrow: 1 }} >
                    {isLocationSection ?
                        <Tab tabIndex={HISTORY_SECTIONS.jobs.index} label={HISTORY_SECTIONS.jobs.label} icon={HISTORY_SECTIONS.jobs.icon} iconPosition="start"/>
                        :
                        Object.values(HISTORY_SECTIONS).map((v) => {
                            return (
                            <Tab key={v.index} data-test={"note-section_" + v.label} tabIndex={v.index} label={v.label} icon={v.icon} iconPosition="start"
                                sx={{
                                    '&.Mui-selected': {
                                        color: theme.palette.mode === 'dark' ? '#ffffff' : undefined
                                    }
                                }}
                            />
                            );
                        })
                    }
                </Tabs>
                <IconButton
                    sx={{ marginInline: "1em", marginRight: "5px", color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000'}}
                    onClick={() => handleToggleSection(currentIndex)}
                    size="small"
                >
                    {expandedSections[currentIndex] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </Box>
            {isLocationSection ?
                <JobsSection franchise={franchise} customerId={customerId} locationId={locationId} />
                :
                getCurrentTabPanelContent(currentIndex)
            }
            {isLoading || isError && <CircularProgress />}
        </Box> 
    </>
}
