import { CircularProgress, Container, Dialog, Typography } from '@mui/material';
import React from 'react'

export default function Loading(props) {
    const { isOpen, loadingMessage } = props

    return <Dialog maxWidth='md' open={isOpen} onClose={() => { }}>
        <Container sx={{ display: "flex", flexDirection: "column", paddingTop: "2em", paddingBottom: "2em", alignContent: "center", gap: "1em" }}>
            <Typography style={{ paddingTop: "0.5em" }} variant="p">{loadingMessage}</Typography>
            <Container sx={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: "1em" }} >
                <CircularProgress style={{ width: "20px", height: "20px" }} color="inherit" />
            </Container>
        </Container>
    </Dialog >;
}
