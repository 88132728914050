import { Box, Autocomplete, Typography } from "@mui/material"

import CustomTextField from "./CustomTextField";
import PhoneNumberBox from "./PhoneNumberBox";

import { CONTACTS_TYPES } from "../constants/serviceTitan";
import { imposibleToMatchRegex } from "../utils/validators";

export default function ContactBox(props) {
    const { type, value, memo, isValidContact, errorText } = props

    let typeValue = selectValue(type)
    const inputPattern = isValidContact ? undefined : imposibleToMatchRegex

    return <Box sx={{ display: 'flex', flexDirection: "row", flexWrap: "wrap", width: "100%", gap: "1em", }} >
        <Autocomplete
            sx={{ width: "20%", }}
            size="small"
            disabled={type.isDisabled}
            options={type.options}
            getOptionLabel={(contactType) => contactType}
            renderOption={renderContactTypeOption}
            value={typeValue}
            onChange={type.onChange}
            renderInput={(params) => <CustomTextField  {...params} required={true} label="Contact Type" />}
        />
        {(type.value === CONTACTS_TYPES[0] || type.value === CONTACTS_TYPES[3]) ?
            <PhoneNumberBox
                disabled={value.isDisabled}
                required={value.isRequired}
                error={!isValidContact}
                helperText={isValidContact ? "" : errorText || `Invalid ${type.value ?? ""}`}
                label={"Value"}
                variant="outlined"
                size="small"
                style={{ flex: "1 1 1", width: "20%" }}
                value={value.value}
                onChange={(e) => {
                    value.onChange(e)
                }} /> : <CustomTextField
                inputProps={{ pattern: inputPattern }}
                disabled={value.isDisabled}
                required={value.isRequired}
                error={!isValidContact}
                helperText={isValidContact ? "" : errorText || `Invalid ${type.value ?? ""}`}
                label={"Value"}
                variant="outlined"
                size="small"
                style={{ flex: "1 1 1", width: "20%" }}
                value={value.value}
                onChange={value.onChange} />
        }
        <CustomTextField disabled={memo.isDisabled} label="Memo" variant="outlined" size="small" style={{ flex: "1 1 auto" }} value={memo.value} onChange={memo.onChange} />
    </Box>
}

function renderContactTypeOption(props, contactType) {
    return (
        <Typography variant="p" {...props} key={contactType}>
            {contactType}
        </Typography>
    );
}

function selectValue(type) {
    let value = ""
    if (type.value === "Phone") {
        value = "Phone"
    } else if (type.value === "MobilePhone") {
        value = "MobilePhone"
    } else if (type.value === "Email") {
        value = "Email"
    } else if (type.value === "Fax") {
        value = "Fax"
    } else {
        value = ""
    }
    return value
}
