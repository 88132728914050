import { Autocomplete, Button, Box, Container, Dialog, IconButton, Tooltip, Typography, CircularProgress } from "@mui/material";
import CustomTextField from "../../../../components/CustomTextField";
import RenderInput from "../../../../components/servicetitan/RenderInput";
import { isContactExists } from "../../../../utils/miscellaneous";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { CONTACTS_TYPES } from "../../../../constants/serviceTitan";
import { imposibleToMatchRegex, isContactValid } from "../../../../utils/validators";
import { CONFIG_MESSAGES, CREATE_JOB_SECTION_IDS } from "../../../../constants/jobs"
import { CUSTOMER_INITIAL_CONTACT } from "./ServiceTitanMainSection";
import { deleteCustomerContacts, updateCustomerContact, updateServiceTitanMiddlewareLead } from "../../../../services/serviceTitan";
import { useState } from "react";
import { getPhoneNumberWithInputMask } from "../../../../utils/inputMasks";
import PhoneNumberBox from "../../../../components/PhoneNumberBox";
import { NOTIFICATION_TYPES } from "../../../../constants/common";
import ConfigMessage from "../../../../components/configMessage/configMessage";
import EditWarningMessage from "../components/EditWarningMessage";
import { addLeadToRedisSearch } from "../../../../services/globalSearch";

export default function ContactsSection(props) {
    const { franchiseId, customerId, isNewCustomer, contacts, setContacts, existingContacts, setExistingContacts, showNotification, currentlyEditingContactsCount, setCurrentlyEditingContactsCount } = props

    //This function is to handle setting new contacts to a customer
    function handleSetNewContacts() {
        setContacts([...contacts, CUSTOMER_INITIAL_CONTACT])
    }

    return <>
        <Box id={CREATE_JOB_SECTION_IDS.CONTACT} display="flex" flexDirection="row" alignItems="center" paddingTop="0.5em">
            <h4>Contacts</h4>
            <Tooltip title="Add new contact" ><span>
                <Button data-test={"job_contact_type"} sx={{ color: "#00a79d" }} disabled={isCreateNewContactForNewCustomerDisabled(contacts)} variant="text" onClick={() => handleSetNewContacts()}>
                    {"\u002B New"}
                </Button>
            </span></Tooltip>
            {<ConfigMessage sectionId={CONFIG_MESSAGES.SERVICETITAN.CONTACTS.id} />}
        </Box >
        {isNewCustomer ? <NewContactsSection contacts={contacts} setContacts={setContacts} showNotification={showNotification} /> : <ExistingContactsSection franchiseId={franchiseId} customerId={customerId} existingContacts={existingContacts} setExistingContacts={setExistingContacts} contacts={contacts} setContacts={setContacts} showNotification={showNotification} currentlyEditingContactsCount={currentlyEditingContactsCount} setCurrentlyEditingContactsCount={setCurrentlyEditingContactsCount} />
        }
    </>
}

async function onContactChanged(contact, i = 0, contacts = [], setContacts, isLeadSection, customerId, selectedLeadToEdit, selectedCustomer) {
    contacts = [...contacts]
    contacts[i] = contact
    setContacts?.([...contacts])
    try {
        if (isLeadSection) {
            await updateServiceTitanMiddlewareLead({ customerId: customerId, customerContacts: contacts })
            let updatedLeadDetails = {
                id: selectedLeadToEdit?.middlewareServiceTitanLead?.id,
                leadId: selectedLeadToEdit?.middlewareServiceTitanLead?.leadId,
                customerName: selectedCustomer?.name,
                customerContacts: contacts,
            }
            await addLeadToRedisSearch(updatedLeadDetails)
        }
    } catch (error) { }
}

function onRemoveContact(i, contacts = [], setContacts) {
    contacts.splice(i, 1)
    setContacts?.([...contacts])
}

async function handleDeleteContact(i, contacts, setContacts, franchiseId, customerId) {
    try {
        const contact = contacts[i]
        await deleteCustomerContacts(franchiseId, customerId, contact?.id)
        onRemoveContact(i, contacts, setContacts)
    } catch (err) { }
}

function NewContactsSection(props) {
    const { contacts, setContacts, showNotification } = props

    return <Box display={"flex"} flexDirection={"column"} alignItems={"start"} gap="1em" paddingBottom="0.1em">
        {contacts?.map((c, i) => <ContactBoxWithClearIcon key={i} contact={c} index={i} contacts={contacts} setContacts={setContacts} allContacts={contacts} disableClearable={!checkIfContactCanBeDeleted(contacts, c)} onRemoveContact={onRemoveContact} isJobSectionNewContact={true} showNotification={showNotification} />)}
    </Box>
}

export function ContactBoxWithClearIcon(props) {
    const { franchiseId, selectedLeadToEdit, selectedCustomer, customerId, contact, index, contacts, setContacts, isDisabled, canDeleteContact = true, isEditDisabled = true, isLeadSection = false, disableClearable, allContacts, onRemoveContact = (i, contacts = [], setContacts) => { }, isExistingContact = false, isJobSectionNewContact = false, showNotification, onStartEdit = () => { }, onEndEdit = () => { } } = props

    const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(false)
    const [isEditContact, setIsEditContact] = useState(false)
    const [editedContact, setEditedContact] = useState()
    const [isEditContactLoading, setIsEditCOntactLoading] = useState(false)

    let [isValidContact, errorText] = getIsValid(isEditContact ? editedContact : contact, allContacts, contacts, index, isExistingContact);

    function onConfirmContactDelete() {
        showNotification({ message: "Successfully deleted", type: NOTIFICATION_TYPES.SUCCESS })
        onRemoveContact(index, contacts, setContacts)
        setIsDeleteConfirmModalOpen(false)
    }

    function onEditContactPressed() {
        onStartEdit()
        setEditedContact({ ...contact })
        setIsEditContact(true)
    }

    function onDoneEditContact(response) {
        onEndEdit()
        showNotification({ message: "Successfully saved", type: NOTIFICATION_TYPES.SUCCESS })
        setIsEditContact(false)
        setEditedContact()
        onContactChanged(response, index, contacts, setContacts, isLeadSection, customerId, selectedLeadToEdit, selectedCustomer)
    }

    function onErrorEditContact() {
        showNotification({ message: "Important: Couldn’t save contact. Please try again", type: NOTIFICATION_TYPES.ERROR })
    }

    function onCancelEdit() {
        onEndEdit()
        setIsEditContact(false)
    }

    return <>
        <Box key={index} display="flex" flexDirection="row" alignItems="start" width="100%" >
            <ContactBox key={isEditContact.toString()} flexGrow={2} isDisabled={isEditContact ? false : isDisabled} isValidContact={isValidContact} errorText={errorText} contact={isEditContact ? editedContact : contact} onChange={(c) => isEditContact ? setEditedContact(c) : onContactChanged(c, index, contacts, setContacts)} />
            {!isJobSectionNewContact &&
                <Tooltip title={isEditContact ? "Done" : "Edit"}>
                    <span>
                        <IconButton sx={{ marginTop: "0.2em" }} size={"small"} disabled={isEditDisabled || (isEditContact && !isValidContact)} onClick={() => isEditContact ? handleEditContact(franchiseId, customerId, editedContact.id, editedContact, setIsEditCOntactLoading, onDoneEditContact, onErrorEditContact) : onEditContactPressed()}>
                            {isEditContact ? isEditContactLoading ? <CircularProgress size={25} color="grey" /> : <DoneIcon /> : <EditIcon color='info' />}
                        </IconButton>
                    </span>
                </Tooltip>
            }
            {isEditContact &&
                <Tooltip title={"Close"}>
                    <span>
                        <IconButton data-test="close_button" sx={{ marginTop: "0.2em" }} size={"small"} disabled={disableClearable ?? index === 0} onClick={() => onCancelEdit()}>
                            <CloseIcon />
                        </IconButton>
                    </span>
                </Tooltip>
            }
            {(!isEditContact && canDeleteContact) &&
                <Tooltip title={"Delete"}>
                    <span>
                        <IconButton data-test="close_or_delete_button" sx={{ marginTop: "0.2em" }} size={"small"} disabled={disableClearable ?? index === 0} onClick={() => setIsDeleteConfirmModalOpen(true)}>
                            {isJobSectionNewContact ? <CloseIcon /> : <DeleteIcon color='error' />}
                        </IconButton>
                    </span>
                </Tooltip>
            }
            {(!canDeleteContact && !isEditContact) &&
                <Box sx={{ width: "35px" }}></Box>
            }
            {(isJobSectionNewContact) &&
                <Box sx={{ width: "35px" }}></Box>
            }
        </Box>
        <ContactDeleteConfirmModal isOpen={isDeleteConfirmModalOpen} contact={contact} onConfirm={onConfirmContactDelete} onClose={() => setIsDeleteConfirmModalOpen(false)} />
    </>
}

function getIsValid(contact, allContacts = [], contacts = [], index, isForExistingContacts = false) {
    let isValidContact = isContactValid(contact) || contact?.value?.length === 0;
    let errorText = "";
    const previousContacts = isForExistingContacts ? contacts.slice(0, index) : allContacts?.slice(0, (allContacts?.length - contacts?.length + index));
    const isExistingContact = isContactExists(contact, previousContacts);
    if (isExistingContact) {
        errorText = "Already exists";
    }
    isValidContact = isValidContact && !isExistingContact;
    return [isValidContact, errorText];
}

function ContactBox(props) {
    const { isDisabled, contact, onChange = (c) => { }, isValidContact, errorText, key } = props
    const inputPattern = isValidContact ? undefined : imposibleToMatchRegex

    return <Box sx={{ display: 'flex', flexDirection: "row", flexWrap: "wrap", width: "100%", gap: "1em", }} >
        <Autocomplete
            sx={{ width: "20%", }}
            size="small"
            disabled={isDisabled}
            required={true}
            options={CONTACTS_TYPES}
            getOptionLabel={(contactType) => contactType}
            renderOption={(props, contactType) => <RenderInput {...props} key={contactType} content={contactType ?? ""} />}
            value={contact.type ? contact?.type : null}
            onChange={(e, t) => onChange({ ...contact, type: t })}
            renderInput={(params) => <CustomTextField  {...params} inputProps={{ ...params.inputProps, 'data-test': 'contact_type' }} required={true} label="Contact Type" />}
        />
        {(contact.type === CONTACTS_TYPES[0] || contact.type === CONTACTS_TYPES[2] || contact.type === CONTACTS_TYPES[3]) ?
            <PhoneNumberBox
                key={key}
                disabled={isDisabled}
                required={true}
                error={!isValidContact && contact?.type}
                helperText={!isValidContact && contact?.type ? errorText || `Invalid ${contact.type ?? ""}` : ""}
                label={"(123) 456-7890"}
                variant="outlined"
                size="small"
                style={{ flex: "1 1 1", width: "20%" }}
                value={contact.value}
                inputProps={{ pattern: inputPattern }}
                onChange={(e) => onChange({ ...contact, value: e?.target?.value })} />
            : <CustomTextField
                disabled={isDisabled}
                required={true}
                error={!isValidContact && contact?.type}
                helperText={!isValidContact && contact?.type ? errorText || `Invalid ${contact.type ?? ""}` : ""}
                label={contact.type === CONTACTS_TYPES[1] ? "test@test.com" : "Value"}
                variant="outlined"
                size="small"
                inputProps={{ pattern: inputPattern, 'data-test': 'contact_value' }}
                style={{ flex: "1 1 1", width: "20%" }}
                value={contact?.value}
                onChange={(e) => onChange({ ...contact, value: e?.target?.value?.trim?.() })} />
        }
        <CustomTextField inputProps={{ 'data-test': 'contact_memo' }} disabled={isDisabled} label="Memo" variant="outlined" size="small" style={{ flex: "1 1 auto" }} value={contact.memo} onChange={(e) => onChange({ ...contact, memo: e?.target?.value })} />
    </Box>
}

function isCreateNewContactForNewCustomerDisabled(contacts = []) {
    const lastContact = contacts[contacts.length - 1];
    return contacts?.length !== 0 && !(lastContact?.type && lastContact?.value)
}

//This funciton is checking if the contact can be deleted 
export function checkIfContactCanBeDeleted(contacts = [], contact) {
    let numberOfEmailTypeContacts = contacts.filter(c => c.type === CONTACTS_TYPES[1]).length
    let numberOfPhoneTypeContacts = contacts.filter(c => c.type === CONTACTS_TYPES[0]).length
    if (contacts.length <= 1) {
        return false
    } else if ((contact?.type === CONTACTS_TYPES[1] && numberOfEmailTypeContacts <= 1) || (contact?.type === CONTACTS_TYPES[0] && numberOfPhoneTypeContacts <= 1)) {
        return false
    } else {
        return true
    }
}

function ExistingContactsSection(props) {
    const { franchiseId, customerId, existingContacts, setExistingContacts, contacts, setContacts, showNotification, currentlyEditingContactsCount, setCurrentlyEditingContactsCount } = props

    return <Box display={"flex"} flexDirection={"column"} alignItems={"start"} gap="1em" paddingBottom="0.1em">
        {existingContacts?.map((c, i) => {
            return <ContactBoxWithClearIcon key={i} franchiseId={franchiseId} customerId={customerId} index={i} contacts={existingContacts} canDeleteContact={checkIfContactCanBeDeleted(existingContacts, c)} setContacts={setExistingContacts} allContacts={[...existingContacts, ...contacts]} contact={c} disableClearable={false} isDisabled={true} isEditDisabled={false} onRemoveContact={() => handleDeleteContact(i, existingContacts, setExistingContacts, franchiseId, customerId)} isExistingContact={true} showNotification={showNotification} onStartEdit={() => setCurrentlyEditingContactsCount(currentlyEditingContactsCount + 1)} onEndEdit={() => setCurrentlyEditingContactsCount(currentlyEditingContactsCount - 1)} />
        })}
        {contacts?.map((c, i) => <ContactBoxWithClearIcon key={i} franchiseId={franchiseId} customerId={customerId} contact={c} index={i} isDisabled={false} contacts={contacts} setContacts={setContacts} disableClearable={false} allContacts={[...existingContacts, ...contacts]} onRemoveContact={onRemoveContact} isJobSectionNewContact={true} showNotification={showNotification} />)}
        {currentlyEditingContactsCount === 0 ? <></> : <EditWarningMessage />}
    </Box>
}



function ContactDeleteConfirmModal(props) {
    const { isOpen, contact, onConfirm, onClose } = props

    return <Dialog maxWidth="xs" open={isOpen} onClose={onClose}>
        <Tooltip title={"Close"}>
            <IconButton data-test="contact_close" onClick={onClose} style={{ position: "absolute", right: "5px", top: "5px" }} size={"small"} aria-label="delete">
                < CloseIcon style={{ height: "15px", width: "15px" }} />
            </IconButton>
        </Tooltip>
        <Container sx={{ display: "flex", flexDirection: "column", paddingTop: "2em", paddingBottom: "2em", alignContent: "center", gap: "1em" }}>
            <Typography style={{ paddingTop: "0.5em" }} variant="p">{`Do you want to delete ${contact.type === CONTACTS_TYPES[1] ? contact.value : getPhoneNumberWithInputMask(contact.value)}`} </Typography>
            <Container sx={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: "1em" }} >
                <Button data-test='contact_cancel' size="small" onClick={onClose} className="btn-secondary btn-no">Cancel</Button>
                <Button data-test='contact_confirm' variant="contained" size="small" onClick={onConfirm} className="btn-primary" >Confirm</Button>
            </Container>
        </Container>
    </Dialog >;
}

async function handleEditContact(franchiseId, customerId, contactId, editedContact, setIsEditContactLoading, onDone, onError) {
    try {
        setIsEditContactLoading(true)
        const response = await updateCustomerContact(franchiseId, customerId, contactId, editedContact)
        onDone(response)
    } catch (err) {
        onError()
    } finally {
        setIsEditContactLoading(false)
    }
}