import { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Autocomplete, IconButton, Slide, Tooltip } from '@mui/material';
import { CustomTextField } from '../../franchiseCustomStyle';
import { CommonContext } from '../../../../../contexts/CommonContextProvider';
import { getMatchingBrandByBrandId } from '../../../../../utils/miscellaneous';
import { renderBrandOption } from '../../../../../components/servicetitan/RenderInput';
import iBSBrandLogo from "../../../../../assets/img/brand.png"
import { handleChannelIdValidation } from '../../../../../state-services/channelIds/channelIds';
import TagViewer from '../../../../../components/TagViewer';
import { handleValidateZipCode } from '../../../../../state-services/zipCodes/zipCode';
import { INITIAL_FRANCHISE_BRAND } from './FranchiseBrandsSection';
import { validateAngiesEntityIdV3 } from '../../../../../state-services/franchise/v3/franchise';
import ReplayIcon from '@mui/icons-material/Replay';
import { handleGetOutboundChannelIds } from '../../../../../state-services/outboundChannelIds/outboundChannelIds';
import { AVAILABLE_CRMS } from '../../../../../constants/common';
import ImageBox from '../../../../../components/LogoBox/ImageBox';

const CREATE_BRAND_STEPS = {
    brand: {
        index: 0,
        label: 'Add Brand Details',
        validate: (franchiseBrand) => {
            return Boolean(franchiseBrand?.brand_id)
        },
        includedForTollFree: true
    },
    channelIds: {
        index: 1,
        label: 'Add Channel Ids',
        validate: (franchiseBrand) => {
            return true
        },
        includedForTollFree: true
    },
    primaryZipCodes: {
        index: 2,
        label: 'Add Primary ZIP/Postal Codes',
        validate: (franchiseBrand) => {
            return true
        },
        includedForTollFree: false
    },
    secondaryZipCodes: {
        index: 3,
        label: 'Add Secondary ZIP/Postal Codes',
        validate: (franchiseBrand) => {
            return true
        },
        includedForTollFree: false
    },
    angeisEntityId: {
        index: 4,
        label: "Add Angie's entity ids",
        validate: (franchiseBrand) => {
            return true
        },
        includedForTollFree: false
    },
    outboundChannelIds: {
        index: 5,
        label: "Add Outbound Channel Ids",
        validate: (franchiseBrand) => {
            return true
        },
        includedForTollFree: false
    }
};

function getCreateBrandSteps(isTollFree = false) {
    if (isTollFree) {
        return Object.keys(CREATE_BRAND_STEPS)
            .filter(key => CREATE_BRAND_STEPS[key].includedForTollFree)
            .reduce((obj, key) => {
                obj[key] = CREATE_BRAND_STEPS[key];
                return obj;
            }, {});
    } else {
        return CREATE_BRAND_STEPS
    }
}

function getCreateBrandStepsValues(isTollFree) {
    return Object.values(getCreateBrandSteps(isTollFree))
}

export default function FranchiseBrandCreateStepper(props) {

    const { franchise, isTollFree, handleClose, handleComplete, franchiseBrand, setFranchiseBrand, franchiseBrands, setFranchiseBrands, all8x8Channels, isAll8x8ChannelsLoading, isAll8x8ChannelsLoadingError, toggleGetAll8x8Channels, setToggleGetAll8x8Channels } = props;

    const [activeStep, setActiveStep] = useState(CREATE_BRAND_STEPS.brand.index);

    return (
        <Box sx={{ marginBlock: "1em" }}>
            <Stepper sx={{ width: "100%" }} activeStep={activeStep}>
                {Object.values(getCreateBrandSteps(isTollFree)).map((v, index) => (
                    <Step key={v?.label} >
                        <StepButton data-test={`franchise_brand_stepper_${v.label}`} color="inherit" onClick={handleStep(index)}>
                            {v?.label}
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
            <div>{getCurrentStepComponent()}</div>
            <Box sx={{ display: "flex", flexDirection: "row", gap: "0.5em" }}>
                <Button data-test={"franchise_brand_stepper_cancel"} onClick={handleClose} sx={{ mt: 3, ml: 1 }}>Cancel</Button>
                <Box sx={{ flexGrow: 2 }} />
                <Button data-test={"franchise_brand_stepper_back"} disabled={activeStep === 0} onClick={handleBack} sx={{ mt: 3, ml: 1 }}>Back</Button>
                {getIsLastStep() ? <Button data-test={"franchise_brand_stepper_complete"} variant="contained" onClick={handleAddFranchiseBrand} sx={{ mt: 3, ml: 1 }}>Complete</Button> : <Button data-test={"franchise_brand_stepper_next"} variant="contained" onClick={handleNext} sx={{ mt: 3, ml: 1 }}>Next</Button>}
            </Box>
        </Box >
    );

    function getCurrentStepComponent() {
        switch (activeStep) {
            case CREATE_BRAND_STEPS.brand.index:
                return <BrandsSection franchise={franchise} franchiseBrands={franchiseBrands} franchiseBrand={franchiseBrand} setFranchiseBrand={setFranchiseBrand} />;
            case CREATE_BRAND_STEPS.channelIds.index:
                return <ChannelIdsSection franchiseBrands={franchiseBrands} franchiseBrand={franchiseBrand} setFranchiseBrand={setFranchiseBrand} />;
            case CREATE_BRAND_STEPS.primaryZipCodes.index:
                return <PrimaryZipCodeSection franchiseBrand={franchiseBrand} setFranchiseBrand={setFranchiseBrand} />;
            case CREATE_BRAND_STEPS.secondaryZipCodes.index:
                return <SecondaryZipCodeSection franchiseBrand={franchiseBrand} setFranchiseBrand={setFranchiseBrand} />;
            case CREATE_BRAND_STEPS.angeisEntityId.index:
                return <AngiesEntityIdSection franchiseBrands={franchiseBrands} franchiseBrand={franchiseBrand} setFranchiseBrand={setFranchiseBrand} />;
            case CREATE_BRAND_STEPS.outboundChannelIds.index:
                return <OutboundChannelIdsSection franchiseBrands={franchiseBrands} franchiseBrand={franchiseBrand} setFranchiseBrand={setFranchiseBrand} all8x8Channels={all8x8Channels} isAll8x8ChannelsLoading={isAll8x8ChannelsLoading} isAll8x8ChannelsLoadingError={isAll8x8ChannelsLoadingError} toggleGetAll8x8Channels={toggleGetAll8x8Channels} setToggleGetAll8x8Channels={setToggleGetAll8x8Channels} />;
            default:
                return <></>
        }

    }

    function handleAddFranchiseBrand() {
        if (!franchiseBrand?.brand_id) {
            setActiveStep(CREATE_BRAND_STEPS.brand.index)
            return
        }
        setFranchiseBrands((prevFranchiseBrands) => {
            return [...prevFranchiseBrands, franchiseBrand]
        })
        handleComplete()
    }

    function getTotalSteps() {
        return Object.keys(getCreateBrandSteps(isTollFree)).length;
    };

    function getIsLastStep() {
        return activeStep === getTotalSteps() - 1;
    };

    async function handleNext() {
        const currentStepValue = getCreateBrandStepsValues(isTollFree)[activeStep];
        if (!currentStepValue?.validate(franchiseBrand)) {
            return
        }
        const newActiveStep = activeStep + 1;
        setActiveStep(newActiveStep);
    };

    function handleBack() {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    function handleStep(step) {
        return () => {
            setActiveStep(step);
        };
    }
}

function BrandsSection(props) {
    const { franchise, franchiseBrands, franchiseBrand, setFranchiseBrand } = props;
    const { brands } = useContext(CommonContext);

    return <Slide direction="left" in={true} timeout={1000} mountOnEnter unmountOnExit>
        <Box>
            <Box sx={{ width: "40em", marginTop: "1.5em", marginBottom: "1em", display: "flex", flexDirection: "row", alignItems: "start", gap: "0.5em" }}>
                <Autocomplete
                    sx={{ flexGrow: 2 }}
                    size="small"
                    disableClearable
                    options={brands}
                    getOptionDisabled={getIsSameVonigoCRMFranchisesOrAlreadyExistingBrand}
                    getOptionLabel={(option) => option.brand_name}
                    value={getMatchingBrandByBrandId(franchiseBrand?.brand_id, brands) ?? null}
                    onChange={(e, newValue) => {
                        setFranchiseBrand({ ...INITIAL_FRANCHISE_BRAND, brand_id: newValue?.brand_id ?? 0 })
                    }}

                    renderOption={renderBrandOption}
                    renderInput={(params) => <CustomTextField {...params} inputProps={{ ...params.inputProps, "data-test": "franchise_brand_brand" }} label="Brand" />}
                />
                <Box sx={{ marginTop: '0.4em', padding: 0 }}>
                    <ImageBox alt={"brand-img"} src={getMatchingBrandByBrandId(franchiseBrand?.brand_id, brands)?.brand_img ?? iBSBrandLogo} size={'xsmall'} />
                </Box>
            </Box>
            {franchiseBrand?.brand_id ? <></> : <Typography color={"error"} >Brand required</Typography>}
        </Box>
    </Slide>

    function getIsSameVonigoCRMFranchisesOrAlreadyExistingBrand(option) {
        return franchiseBrands?.some((franchiseBrand) => franchiseBrand?.brand_id === option?.brand_id) || (franchiseBrands?.[0]?.brand_id && franchise?.service_brand === AVAILABLE_CRMS.vonigo.value && option?.redirect_url !== getMatchingBrandByBrandId(franchiseBrands?.[0]?.brand_id, brands)?.redirect_url && franchise?.is_toll_free === 0)
    }
}

function ChannelIdsSection(props) {
    const { franchiseBrands, franchiseBrand, setFranchiseBrand } = props;

    return <Slide direction="left" in={true} timeout={1000} mountOnEnter unmountOnExit>
        <Box sx={{ marginBlock: "1em", display: "flex", flexDirection: "row", alignItems: "start" }}>
            <TagViewer
                width="10em"
                title={'Channel IDs'}
                displayLimit={8}
                tagArray={franchiseBrand?.channel_ids}
                setTagArray={(channelIds) => {
                    setFranchiseBrand((newFranchiseBrand) => {
                        return { ...newFranchiseBrand, channel_ids: channelIds }
                    })
                }}
                getIsNewValueValid={(channelId) => validateChannelId(channelId, getMergedChannelIds(franchiseBrands, franchiseBrand?.channel_ids))}
                disableTagTrimming={true}
            />
        </Box>
    </Slide>
}

function getAllFranchiseBrandsChannelIds(franchiseBrands = []) {
    return franchiseBrands?.reduce?.((acc, franchiseBrand) => {
        const newChannelIds = franchiseBrand?.channel_ids
        if (newChannelIds) {
            return [...acc, ...newChannelIds]
        }
        return [...acc]
    }, [])
}

function getMergedChannelIds(franchiseBrands = [], channelIds = []) {
    const allChannelIds = getAllFranchiseBrandsChannelIds(franchiseBrands)
    return [...allChannelIds, ...channelIds]
}

async function validateChannelId(channelId, channelIds = [], excludeFranchiseId = 0) {
    const separatedChannelIds = channelId?.split?.(",")
    const trimmedChannelIds = separatedChannelIds?.map?.((channelId) => channelId?.trim?.())
    for (const channelId of trimmedChannelIds) {
        if (channelIds.includes(channelId)) {
            return { isValid: false, message: `${channelId} is a Duplicate` }
        }
    }
    return await handleChannelIdValidation(trimmedChannelIds, excludeFranchiseId)
}

function PrimaryZipCodeSection(props) {
    const { franchiseBrand, setFranchiseBrand } = props;
    return <Slide direction="left" in={true} timeout={1000} mountOnEnter unmountOnExit>
        <Box sx={{ marginBlock: "1em", display: "flex", flexDirection: "row", alignItems: "start" }}>
            <TagViewer
                width="15em"
                title={'Primary ZIP/Postal Codes'}
                displayLimit={8}
                tagArray={franchiseBrand?.primary_zip_codes}
                setTagArray={(primaryZipCodes) => {
                    setFranchiseBrand((newFranchiseBrand) => {
                        return { ...newFranchiseBrand, primary_zip_codes: primaryZipCodes }
                    })
                }}
                getIsNewValueValid={(zipCode) => validatePrimaryAndSecondaryZipCodes(franchiseBrand?.brand_id, zipCode, franchiseBrand?.primary_zip_codes, franchiseBrand?.secondary_zip_codes)}
                disableTagTrimming={true}
            />
        </Box>
    </Slide>
}

function SecondaryZipCodeSection(props) {
    const { franchiseBrand, setFranchiseBrand } = props;
    return <Slide direction="left" in={true} timeout={1000} mountOnEnter unmountOnExit>
        <Box sx={{ marginBlock: "1em", display: "flex", flexDirection: "row", alignItems: "start" }}>
            <TagViewer
                width="15em"
                title={'Secondary ZIP/Postal Codes'}
                displayLimit={8}
                tagArray={franchiseBrand?.secondary_zip_codes}
                setTagArray={(primaryZipCodes) => {
                    setFranchiseBrand((newFranchiseBrand) => {
                        return { ...newFranchiseBrand, secondary_zip_codes: primaryZipCodes }
                    })
                }}
                getIsNewValueValid={(zipCode) => validatePrimaryAndSecondaryZipCodes(franchiseBrand?.brand_id, zipCode, franchiseBrand?.primary_zip_codes, franchiseBrand?.secondary_zip_codes)}
                disableTagTrimming={true}
            />
        </Box>
    </Slide>
}

function AngiesEntityIdSection(props) {
    const { franchiseBrands, franchiseBrand, setFranchiseBrand } = props;
    return <Slide direction="left" in={true} timeout={1000} mountOnEnter unmountOnExit>
        <Box sx={{ marginBlock: "1em", display: "flex", flexDirection: "row", alignItems: "start" }}>
            <TagViewer
                width="15em"
                title={"Angie's Entity Ids"}
                displayLimit={8}
                tagArray={franchiseBrand?.franchise_angies_list_sp_entity_ids?.map?.((id) => `${id}`)}
                setTagArray={handleSetEntityIds}
                getIsNewValueValid={(id) => validateAngiesEntityIdV3(id, getMergedEntityIds(franchiseBrands, franchiseBrand?.franchise_angies_list_sp_entity_ids))}
                disableTagTrimming={true}
            />
        </Box>
    </Slide>

    function handleSetEntityIds(entityIds) {
        const entityIdsInts = entityIds?.map?.((id) => {
            const numberValue = parseInt(id)
            return numberValue
        })
        setFranchiseBrand((newFranchiseBrand) => {
            return { ...newFranchiseBrand, franchise_angies_list_sp_entity_ids: entityIdsInts }
        })
    }
}

function getAllFranchiseBrandsEntityIds(franchiseBrands = []) {
    return franchiseBrands?.reduce?.((acc, franchiseBrand) => {
        const newEntityIds = franchiseBrand?.franchise_angies_list_sp_entity_ids
        if (newEntityIds) {
            return [...acc, ...newEntityIds]
        }
        return [...acc]
    }, [])
}

function getMergedEntityIds(franchiseBrands = [], entityIds = []) {
    const allEntityIds = getAllFranchiseBrandsEntityIds(franchiseBrands)
    return [...allEntityIds, ...entityIds]
}

async function validatePrimaryAndSecondaryZipCodes(brandId, zipCode, primaryZipCodes = [], secondaryZipCodes = [], excludingFranchiseId = 0) {
    const separatedZipCodes = zipCode?.split?.(",")
    const trimmedZipCodes = separatedZipCodes?.map?.((zipCode) => zipCode?.trim?.())
    for (const zipCode of trimmedZipCodes) {
        if (primaryZipCodes.includes(zipCode)) {
            return { isValid: false, message: `Duplicate On Primary - ${zipCode}` }
        } else if (secondaryZipCodes.includes(zipCode)) {
            return { isValid: false, message: `Duplicate On Secondary - ${zipCode}` }
        }
    }
    return await handleValidateZipCode(brandId, trimmedZipCodes, excludingFranchiseId)
}

function OutboundChannelIdsSection(props) {
    const { franchiseBrands, franchiseBrand, setFranchiseBrand, all8x8Channels, isAll8x8ChannelsLoading, isAll8x8ChannelsLoadingError, toggleGetAll8x8Channels, setToggleGetAll8x8Channels } = props;

    const [oldOutboundChannelIds, setOldOutboundChannelIds] = useState([])
    const [isOutboundChannelIdsLoading, setIsOutboundChannelIdsLoading] = useState(false)

    useEffect(() => {
        handleGetOutboundChannelIds(undefined, undefined, 1, setOldOutboundChannelIds, setIsOutboundChannelIdsLoading)
    }, [])

    return <Slide direction="left" in={true} timeout={1000} mountOnEnter unmountOnExit>
        <Box sx={{
            width: "30em",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "center"
        }}>
            <Autocomplete
                sx={{ marginBlock: "1em", flexGrow: "2" }}
                size="small"
                options={all8x8Channels}
                getOptionDisabled={getOutboundOptionDisabled}
                loading={isAll8x8ChannelsLoading || isOutboundChannelIdsLoading}
                noOptionsText={isAll8x8ChannelsLoadingError ? "Temporary failure" : "No Channels Found"}
                value={(all8x8Channels?.length > 0 && franchiseBrand?.outbound_channels?.[0]?.channel_primary_id) ? all8x8Channels?.find?.((v) => franchiseBrand?.outbound_channels?.[0]?.channel_primary_id === v.channel_id) : null}
                onChange={(e, c) => handleSetEightByEightConfigurations(c)}
                getOptionLabel={(c) => `${c?.channel_name}`}
                renderOption={(props, c) => {
                    return <Typography {...props} data-test={`franchise_brand_outbound_channel_${c.channel_name}`} key={`${c.channel_id}`}>{c.channel_name}</Typography>
                }}
                renderInput={(props) => <CustomTextField {...props} data-test="franchise_brand_outbound_channel" label={"8x8 Outbound Channel"} />}
            />
            <Tooltip title={"Refresh"}>
                <span style={{ height: "100%", margin: "auto" }} >
                    <IconButton
                        data-test="franchise_brand_outbound_channel_refresh"
                        onClick={() => setToggleGetAll8x8Channels(!toggleGetAll8x8Channels)}
                    >
                        <ReplayIcon fontSize='0.8em' />
                    </IconButton>
                </span>
            </Tooltip>
        </Box>
    </Slide>

    function handleSetEightByEightConfigurations(c) {
        const newFranchiseBrandOutboundChannel = {
            channel_id: `${c?.channel_name ?? ""}`,
            channel_primary_id: c?.channel_id ?? 0,
        }
        const newOutBoundChannels = c?.channel_id ? [newFranchiseBrandOutboundChannel] : []
        setFranchiseBrand((franchiseBrand) => {
            return { ...franchiseBrand, outbound_channels: newOutBoundChannels }
        })
    }

    function getOutboundOptionDisabled(option) {
        const nonSavedOutboundChannels = franchiseBrands?.map?.((franchiseBrand) => franchiseBrand?.outbound_channels?.[0])
        return oldOutboundChannelIds?.some((oldOutboundChannelId) => oldOutboundChannelId?.channel_id === `${option?.channel_name}`) || nonSavedOutboundChannels?.some?.((nonSavedOutboundChannel) => nonSavedOutboundChannel?.channel_id === `${option?.channel_name}`)
    }
}