import { useContext, useEffect, useState } from "react";
import { handleSaveOrUpdateUserPreferences } from "../../state-services/users";
import LoadingButton from "../LoadingButton";
import { Box, Typography, Autocomplete } from "@mui/material";
import CustomTextField from "../CustomTextField";
import { DIALLER_PROCEED } from "../../constants/userPreferences";
import { UserContext } from "../../contexts/User";
import { CONFIG_MESSAGES } from "../../constants/jobs";
import ServiceTitanMessagesEditModal from "./ServiceTitanMessagesEditModal";
import { WARNING_MESSAGE } from "../../constants/warningMessages";
import { userLevels } from "../../constants/common";
import WarningMessageWithSeparateButtons from "../warningMessageWithSeparateButtons/warningMessageWithSeparateButtons";

export default function UserPreferencesSettingsSection(props) {
	const { showNotification } = props;
	const { userPreferences, setUserPreferences, me } = useContext(UserContext);
	const [preferences, setPreferences] = useState();
	const [isUpdateLoading, setIsUpdateLoading] = useState(false);

	useEffect(() => {
		setPreferences(userPreferences);
	}, [userPreferences]);

	function handleSave(e) {
		e?.preventDefault();
		setUserPreferences(preferences);
		handleSaveOrUpdateUserPreferences(
			preferences,
			setIsUpdateLoading,
			showNotification
		);
	}


	return (
		<Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "start",
					gap: "0.5em",
				}}
			>
				<form onSubmit={handleSave}>
					<Typography
						marginTop="1em"
						fontWeight="bold"
						component="h3"
						variant="subtitle1"
					>
						Dialer Page
					</Typography>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							gap: "1em",
							flexWrap: "wrap",
							paddingTop: "0.3em",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								width: "45%",
								marginTop: "0.2em",
							}}
						>
							<Autocomplete
								size="small"
								disableClearable
								options={DIALLER_PROCEED}
								filterOptions={(options, state) => options}
								onChange={(e, value) =>
									setPreferences({
										...preferences,
										dialler_proceed: value?.id ?? "",
									}) || null
								}
								value={
									DIALLER_PROCEED.find(
										(option) =>
											option.id ===
											userPreferences.dialler_proceed
									) || null
								}
								getOptionLabel={(option) => option.label}
								renderInput={(params) => (
									<CustomTextField
										{...params}
										label="Select Option"
									/>
								)}
							/>
						</Box>
					</Box>
					<Box
						sx={{
							width: "100%",
							marginTop: "0.5em",
							display: "flex",
							flexDirection: "row",
						}}
					>
						<Box flexGrow={2} />
						<LoadingButton
							type="submit"
							loading={isUpdateLoading}
							size="small"
							variant="contained"
						>
							Update
						</LoadingButton>
					</Box>
				</form>
				<Box>
					{me?.level >= userLevels.SUPER_ADMIN && <Box>
						<Typography
							marginTop="1em"
							fontWeight="bold"
							component="h3"
							variant="subtitle1"
						>
							Jobs Page
						</Typography>
						<ServiceTitanMessagesEditModal sectionId={CONFIG_MESSAGES.SERVICETITAN.ZIP_COMPARISON.id} />
					</Box>
					}
				</Box>
				<Box>
					<Typography
						marginTop="1em"
						fontWeight="bold"
						component="h3"
						variant="subtitle1"
					>
						Home Page
					</Typography>
					<WarningMessageWithSeparateButtons sectionId={WARNING_MESSAGE.HOMEPAGE.SUBMISSION_FORM.id} />
				</Box>
			</Box>
		</Box>
	);
}
