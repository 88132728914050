import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import React, { useMemo } from 'react'
import { withRouter } from 'react-router-dom';
import failureImage from '../../../../assets/img/brand/failure.png'

function FailureView() {
    const reason = useMemo(() => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get("reason");
    }, []);

    return (
        <div style={{ marginTop: "10%" }}>
            <Card sx={{ maxWidth: 350, margin: 'auto', boxShadow: 'none', marginTop: 10 }}>
                <CardMedia
                    component="img"
                    alt="card image"
                    height="100%"
                    width="100%"
                    image={failureImage}
                />
                <CardContent sx={{ textAlign: 'center' }}>
                    <Typography gutterBottom variant="h6" component="div">
                        {"Failure"}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {`Validation failed. Reason : ${reason}`}
                    </Typography>
                </CardContent>
            </Card>
        </div>
    )
}

export default withRouter(FailureView);
