export function compareTwoRFCDateStringsAssendingOrder(firstDate, secondDate, optionalFirstDate, optionalSecondDate) {
    if (firstDate && secondDate) {
        if (firstDate < secondDate) {
            return -1;
        } else if (firstDate > secondDate) {
            return 1;
        } else {
            return 0;
        }
    } else if (!firstDate && secondDate) {
        return 1
    } else if (firstDate && !secondDate) {
        return -1
    } else { //if both lead's followUpDates missing
        if (optionalFirstDate < optionalSecondDate) {
            return -1;
        } else if (optionalFirstDate > optionalSecondDate) {
            return 1;
        } else {
            return 0;
        }
    }
}

export function compareTwoRFCDateStringsDecendingOrder(firstDate, secondDate, optionalFirstDate, optionalSecondDate) {
    return -1 * compareTwoRFCDateStringsAssendingOrder(firstDate, secondDate, optionalFirstDate, optionalSecondDate)
}

export function compareTwoStringsInAssendingOrder(a, b) {
    if (a === b) {
        return 0
    } else if (a < b) {
        return -1
    } else {
        return 1
    }
}

export function compareTwoStringsInDecendingOrder(a, b) {
    return -1 * compareTwoStringsInAssendingOrder(a, b)
}