import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useState } from 'react'
import LoadingRow from '../../../../components/table/LoadingRow';
import ErrorRow from '../../../../components/table/ErrorRow';
import { ERROR_COMPONENTS } from '../../../../constants/common';
import { CustomTextField } from '../../user/userCustomStyle';

export default function RealTimeMetricsAgentTable({ isLoading, agentMetricsData }) {

    const [searchVal, setSearchVal] = useState("")

    function filterByAgentName(data) {
        if (searchVal === "") {
            return data
        } else {
            return data?.filter?.((data) => {
                return data["agentName"].toLowerCase().includes(searchVal.toLowerCase())
            })
        }
    }

    function checkData(data) {
        if (searchVal === "") {
            return true
        } else {
            if (data?.some((data) => data["agentName"].toLowerCase().includes(searchVal.toLowerCase()))) {
                return true
            } else {
                return false
            }
        }
    }

    function buildDataRows() {
        return (filterByAgentName(agentMetricsData)?.sort((a, b) => a?.agentName.localeCompare(b?.agentName)))?.map?.((d, i) => <TableRow>
            <TableCell style={{ textAlign: "left" }} >{i + 1}</TableCell>
            <TableCell style={{ textAlign: "left" }} >{d["agentName"]}</TableCell>
            <TableCell style={{ textAlign: "left", }}  >{d["status"]}</TableCell>
            <TableCell style={{ textAlign: "left", }}  >{convertMillisecondsToTime(d["timeOnCurrentStatus"])}</TableCell>
            <TableCell style={{ textAlign: "left" }}  >{d["statusCode"]}</TableCell>
            <TableCell style={{ textAlign: "left" }}  >{d["totalCallsHandled"]}</TableCell>
        </TableRow>)
    }

    function convertMillisecondsToTime(milliseconds) {
        if (milliseconds === "-") {
            return milliseconds
        } else {
            let seconds = Math.floor(milliseconds / 1000);
            let minutes = Math.floor(seconds / 60);
            let hours = Math.floor(minutes / 60);

            seconds = seconds % 60;
            minutes = minutes % 60;

            hours = hours.toString().padStart(2, '0');
            minutes = minutes.toString().padStart(2, '0');
            seconds = seconds.toString().padStart(2, '0');

            return `${hours}:${minutes}:${seconds}`;
        }
    }

    return (
        <Box sx={{ height: "610px", display: "flex", flexDirection: "column", overflow: 'auto' }}>
            <Box sx={{ display: "flex", flexDirection: "row", paddingTop: "10px", paddingBottom: "10px" }}>
                <Typography variant="h6">Real Time Agent Metrics</Typography>
                <Box sx={{ flexGrow: 1 }} />
                <CustomTextField
                    label={"Search by agent name"}
                    size={"small"}
                    style={{ width: "200px", marginRight: "10px" }}
                    variant="outlined"
                    type="search"
                    value={searchVal}
                    onChange={(event) => {
                        setSearchVal(event.target.value)
                    }}
                />
            </Box>
            <TableContainer sx={{ flexGrow: 2 }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "left" }} >#</TableCell>
                            <TableCell style={{ textAlign: "left" }} >Agent Name</TableCell>
                            <TableCell style={{ textAlign: "left", }}  >Current Status(RT)</TableCell>
                            <TableCell style={{ textAlign: "left", }}  >Time On Current Status(RT)</TableCell>
                            <TableCell style={{ textAlign: "left" }}  >Status Code(RT)</TableCell>
                            <TableCell style={{ textAlign: "left" }}  >Accepted(Today)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading ? <LoadingRow colSpan={6} /> : (agentMetricsData?.length === 0 || !checkData(agentMetricsData)) ? <ErrorRow colSpan={6} type={ERROR_COMPONENTS.NoDataFound.type} /> : buildDataRows()}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}
