import { Button, Checkbox, FormControlLabel, TextField, Tooltip, IconButton, useTheme, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { addServiceTitanCustomerNote, getServiceTitanCustomerNotes, addServiceTitanJobSpecificNote, getServiceTitanJobSpecificNotes, addServiceTitanLocationNote, getServiceTitanLocationNotes } from "../../../../../services/serviceTitan";
import LoadingButton from "../../../../../components/LoadingButton";
import { NOTIFICATION_TYPES } from "../../../../../constants/common";
import RefreshErrorView from "../../../../../components/refreshView/RefreshErrorView";
import NoteCard from "../../../../../components/servicetitan/NoteCard";
import { pinnedLatestFirstCompareFunction } from "../../../../../utils/miscellaneous";
import NoContentView from "../../../../../components/refreshView/NoContentView";
import LoadingView from "../../../../../components/refreshView/LoadingView";
import { CommonContext } from "../../../../../contexts/CommonContextProvider";
import AddIcon from '@mui/icons-material/Add';

export default function ViewJobModalNotesSection(props) {
    const { franchiseId, customerId, locationId, jobId, showNotification } = props

    const [isAddNewNote, setIsAddNewNotes] = useState(false)
    const [isNotesLoading, setIsNotesLoading] = useState(false)
    const [isNotesLoadingError, setIsNotesLoadingError] = useState(false)
    const [toggleGetNotes, setToggleGetNotes] = useState(true)

    const [jobNotes, setJobNotes] = useState([])
    const [customerNotes, setCustomerNotes] = useState([])
    const [locationNotes, setLocationNotes] = useState([])
    const [selectedTab, setSelectedTab] = useState(0);
    const theme = useTheme()

    useEffect(() => {
        if (!(franchiseId && (locationId || customerId || jobId))) return
        if (selectedTab === 0){
            handleGetJobNotes(franchiseId, jobId, setJobNotes, setIsNotesLoading, setIsNotesLoadingError)
        }
        if (selectedTab === 1){
            handleGetCustomerNotes(franchiseId, customerId, setCustomerNotes, setIsNotesLoading, setIsNotesLoadingError)
        }
        if (selectedTab === 2) {
            handleGetLocationNotes(franchiseId, locationId, setLocationNotes, setIsNotesLoading, setIsNotesLoadingError)
        }
    }, [franchiseId, customerId, locationId, jobId, selectedTab, toggleGetNotes])

    const getFilteredNotes = () => {
        switch (selectedTab) {
            case 0: return jobNotes;
            case 1: return customerNotes;
            case 2: return locationNotes;
            default: return "";
        }
    };

    function onDoneAddJobNote(res) {
        showNotification({ message: "Successfully saved", type: NOTIFICATION_TYPES.SUCCESS })
        const newNotes = [res, ...jobNotes]
        newNotes.sort(pinnedLatestFirstCompareFunction);
        setJobNotes(newNotes);
        setIsAddNewNotes(false);
        handleGetJobNotes(franchiseId, jobId, setJobNotes, setIsNotesLoading, setIsNotesLoadingError)
    }

    function onDoneAddCustomerNote(res) {
        showNotification({ message: "Successfully saved", type: NOTIFICATION_TYPES.SUCCESS })
        const newNotes = [res, ...customerNotes]
        newNotes.sort(pinnedLatestFirstCompareFunction);
        setCustomerNotes(newNotes);
        setIsAddNewNotes(false);
        handleGetCustomerNotes(franchiseId, customerId, setCustomerNotes, setIsNotesLoading, setIsNotesLoadingError)
    }

    function onDoneAddLocationNote(res) {
        showNotification({ message: "Successfully saved", type: NOTIFICATION_TYPES.SUCCESS })
        const newNotes = [res, ...locationNotes]
        newNotes.sort(pinnedLatestFirstCompareFunction);
        setLocationNotes(newNotes);
        setIsAddNewNotes(false);
        handleGetLocationNotes(franchiseId, locationId, setLocationNotes, setIsNotesLoading, setIsNotesLoadingError)

    }

    const TABS = {
        JOB_NOTES: {
            index: 0,
            label: "Job Specific Notes",
        },
        CUSTOMER_NOTES: {
            index: 1,
            label: "Customer Notes",
        },
        LOCATION_NOTES: {
            index: 2,
            label: "Location Notes",
        },
    };
    
    return franchiseId && (customerId || locationId || jobId) ? (
        <Box marginTop="2em" marginBottom="2em" width="100%">
            <Typography style={{ fontSize: "1.5rem", fontWeight: "bold" }}>Notes</Typography>
            <Box className={"tabs"} sx={{ display: "flex", flexDirection: "row" }}>
                {Object.values(TABS).map((tab) => (
                    <Button
                        key={tab.index}
                        onClick={() => {
                            setSelectedTab(tab.index);
                            setIsAddNewNotes(false);
                        }}
                        size="small"
                        variant="contained"
                        className={selectedTab === tab.index ? "btn-primary tab" : "btn-secondary tab"}
                        sx={{
                            color: selectedTab === tab.index ? 'primary.main' : 'secondary.main',
                            backgroundColor: selectedTab === tab.index ? 'primary.light' : 'transparent',
                            padding: "8px 16px",
                            margin: "4px 0"
                        }}
                    >
                        {tab.label}
                    </Button>
                ))}
                {!isAddNewNote && (
                <Box sx={{ marginLeft: 'auto', marginRight: '20px' }}>
                    <Tooltip enterDelay={1000} enterTouchDelay={1000} title={selectedTab === 0 ? "Add new job specific note" : selectedTab === 1 ? "Add new customer note" : selectedTab === 2 ? "Add new location note" : "" }>
                        <IconButton
                            disabled={isAddNewNote}
                            data-test="add-note-button"
                            size="small"
                            sx={{ color:'#FFFFFF', background: theme.palette.mode === 'dark' ? '#000000' : '#00A79D' }}
                            variant="text"
                            onClick={() => setIsAddNewNotes(true)}
                        >
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
                )}
            </Box>
            {isAddNewNote && selectedTab === 0 ? <AddNewJobSpecificNote franchiseId={franchiseId} jobId={jobId} onDone={onDoneAddJobNote} onCancel={() => setIsAddNewNotes(false)} /> : null}
            {isAddNewNote && selectedTab === 1 ? <AddNewCustomerNote franchiseId={franchiseId} customerId={customerId} onDone={onDoneAddCustomerNote} onCancel={() => setIsAddNewNotes(false)} /> : null}
            {isAddNewNote && selectedTab === 2 ? <AddNewLocationNote franchiseId={franchiseId} locationId={locationId} onDone={onDoneAddLocationNote} onCancel={() => setIsAddNewNotes(false)} /> : null}

            {isNotesLoadingError ? <RefreshErrorView onRefresh={() => setToggleGetNotes(!toggleGetNotes)} /> : null}
            {isNotesLoading ? (
                <LoadingView />
            ) : getFilteredNotes().length === 0 ? (
                    <NoContentView message={"No notes to show"} />
            ) : (
                getFilteredNotes().map((n) => <NoteCard note={n} />)
            )}
        </Box>
    ) : null;
}

async function handleGetJobNotes(franchiseId, jobId, setJobNotes, setIsLoading, setIsError) {
    try {
        setIsError(false)
        setIsLoading(true)
        const res = await getServiceTitanJobSpecificNotes(franchiseId, jobId)
        if (Array.isArray(res.data)) {
            res.data.sort(pinnedLatestFirstCompareFunction);
            setJobNotes(res.data)
        }
    } catch {
        setIsError(true)
    } finally {
        setIsLoading(false)
    }
}

async function handleGetCustomerNotes(franchiseId, customerId, setCustomerNotes, setIsLoading, setIsError) {
    try {
        setIsError(false)
        setIsLoading(true)
        const res = await getServiceTitanCustomerNotes(franchiseId, customerId)
        if (Array.isArray(res.data)) {
            res.data.sort(pinnedLatestFirstCompareFunction);
            setCustomerNotes(res.data)
        }
    } catch {
        setIsError(true)
    } finally {
        setIsLoading(false)
    }
}

async function handleGetLocationNotes(franchiseId, locationId, setLocationNotes, setIsLoading, setIsError) {
    try {
        setIsError(false)
        setIsLoading(true)
        const res = await getServiceTitanLocationNotes(franchiseId, locationId)
        if (Array.isArray(res.data)) {
            res.data.sort(pinnedLatestFirstCompareFunction);
            setLocationNotes(res.data)
        }
    } catch {
        setIsError(true)
    } finally {
        setIsLoading(false)
    }
}

function AddNewJobSpecificNote(props) {
    const { franchiseId, jobId, onDone, onCancel } = props

    const [note, setNote] = useState({ text: "", pinToTop: false })
    const [isAddNoteLoading, setIsAddNoteLoading] = useState(false)
    const { showNotification } = useContext(CommonContext);

    function onAddNoteError() {
        showNotification({ isOpen: true, message: "Important : Could not save the note. Please try again", type: NOTIFICATION_TYPES.ERROR })
    }
    function onAddNoteDone(res) {
        onDone(res)
        showNotification({ isOpen: true, message: "Successfully added the job specific note", type: NOTIFICATION_TYPES.SUCCESS })
    }

    return <>
        <TextField
            label="Leave a job specific Note"
            size="small"
            multiline={true}
            data-test="note-text-field"
            maxRows={2}
            onChange={(event) => {
                setNote({ ...note, text: event.target.value })
            }}
            value={note?.text ?? ""}
            minRows={2}
            maxLength={"100%"}
            style={{ marginTop: "1.25em", width: "100%"}}
        />
        <Box sx={{ display: "flex", flexDirection: "row", width: "100%", gap: "0.5em", marginBlock: "0.5em"}}>
            <div>
                <LoadingButton data-test="save-note-button" size="small" sx={{ color: "white" }} type="submit" variant="contained" loading={isAddNoteLoading} onClick={(e) => { handleAddNewJobNote(franchiseId, jobId, note, setIsAddNoteLoading, onAddNoteError, onAddNoteDone ) }}>Save</LoadingButton>
            </div>
            <div>
                <Button size="small" data-test="cancel-note-button" variant="contained" className="button-secondary" onClick={onCancel} style={{ marginRight: "10px" }}>Cancel</Button>
            </div>
            <div>
                <FormControlLabel style={{ margin: 0, padding: 0, marginLeft: "1em" }} control={<Checkbox inputProps={{ 'data-test': "note_pin_to_top" }} size="small" checked={note?.pinToTop}
                    onChange={(event) => {
                        setNote({ ...note, pinToTop: event.target.checked })
                    }} />} label="Pin this note to the top" />
            </div>
        </Box>
    </>
}

function AddNewCustomerNote(props) {
    const { franchiseId, customerId, onDone, onCancel } = props

    const [note, setNote] = useState({ text: "", pinToTop: false, addToLocations: false })
    const [isAddNoteLoading, setIsAddNoteLoading] = useState(false)
    const { showNotification } = useContext(CommonContext);

    function onAddNoteError() {
        showNotification({ isOpen: true, message: "Important : Could not save the note. Please try again", type: NOTIFICATION_TYPES.ERROR })
    }
    function onAddNoteDone(res) {
        onDone(res)
        showNotification({ isOpen: true, message: "Successfully added the customer note", type: NOTIFICATION_TYPES.SUCCESS })
    }

    return <>
        <TextField
            label="Leave a Customer Note"
            size="small"
            multiline={true}
            data-test="note-text-field"
            maxRows={2}
            onChange={(event) => {
                setNote({ ...note, text: event.target.value })
            }}
            value={note?.text ?? ""}
            minRows={2}
            maxLength={"100%"}
            style={{ marginTop: "1.25em", width: "100%"}}
        />
        <Box sx={{ display: "flex", flexDirection: "row", width: "100%", gap: "0.5em", marginBlock: "0.5em"}}>
            <div>
                <LoadingButton data-test="save-note-button" size="small" sx={{ color: "white" }} type="submit" variant="contained" loading={isAddNoteLoading} onClick={(e) => { handleAddNewCustomerNote(franchiseId, customerId, note, setIsAddNoteLoading, onAddNoteError, onAddNoteDone ) }}>Save</LoadingButton>
            </div>
            <div>
                <Button size="small" data-test="cancel-note-button" variant="contained" className="button-secondary" onClick={onCancel} style={{ marginRight: "10px" }}>Cancel</Button>
            </div>
            <div>
                <FormControlLabel style={{ margin: 0, padding: 0, marginLeft: "1em" }} control={<Checkbox inputProps={{ 'data-test': "note_pin_to_top" }} size="small" checked={note?.pinToTop}
                    onChange={(event) => {
                        setNote({ ...note, pinToTop: event.target.checked })
                    }} />} label="Pin this note to the top" />
            </div>
            <div>
                <FormControlLabel style={{ margin: 0, padding: 0, marginLeft: "1em" }} control={<Checkbox inputProps={{ 'data-test': "note_add_to_location" }} size="small" checked={note?.addToLocations}
                    onChange={(event) => {
                            setNote({ ...note, addToLocations: event.target.checked })
                    }} />} label={"Copy to Location Notes"} />
            </div>
        </Box>
    </>
}

function AddNewLocationNote(props) {
    const { franchiseId, locationId, onDone, onCancel } = props

    const [note, setNote] = useState({ text: "", pinToTop: false, addToCustomer: false })
    const [isAddNoteLoading, setIsAddNoteLoading] = useState(false)
    const { showNotification } = useContext(CommonContext);

    function onAddNoteError() {
        showNotification({ isOpen: true, message: "Important : Could not save the note. Please try again", type: NOTIFICATION_TYPES.ERROR })
    }
    function onAddNoteDone(res) {
        onDone(res)
        showNotification({ isOpen: true, message: "Successfully added the location note", type: NOTIFICATION_TYPES.SUCCESS })
    }

    return <>
        <TextField
            label="Leave a Location Note"
            size="small"
            multiline={true}
            data-test="note-text-field"
            maxRows={2}
            onChange={(event) => {
                setNote({ ...note, text: event.target.value })
            }}
            value={note?.text ?? ""}
            minRows={2}
            maxLength={"100%"}
            style={{ marginTop: "1.25em", width: "100%" }}
        />
        <Box sx={{ display: "flex", flexDirection: "row", width: "100%", gap: "0.5em", marginBlock: "0.5em" }}>
            <div>
                <LoadingButton data-test="save-note-button" size="small" sx={{ color: "white" }} type="submit" variant="contained" loading={isAddNoteLoading} onClick={(e) => { handleAddNewLocationNote(franchiseId, locationId, note, setIsAddNoteLoading, onAddNoteError, onAddNoteDone ) }}>Save</LoadingButton>
            </div>
            <div>
                <Button size="small" data-test="cancel-note-button" variant="contained" className="button-secondary" onClick={onCancel} style={{ marginRight: "10px" }}>Cancel</Button>
            </div>
            <div>
                <FormControlLabel style={{ margin: 0, padding: 0, marginLeft: "1em" }} control={<Checkbox inputProps={{ 'data-test': "note_pin_to_top" }} size="small" checked={note?.pinToTop}
                    onChange={(event) => {
                        setNote({ ...note, pinToTop: event.target.checked })
                    }} />} label="Pin this note to the top" />
            </div>
            <div>
                <FormControlLabel style={{ margin: 0, padding: 0, marginLeft: "1em" }} control={<Checkbox inputProps={{ 'data-test': "note_add_to_customer" }} size="small" checked={note?.addToCustomer}
                    onChange={(event) => {
                        if (locationId) {
                            setNote({ ...note, addToCustomer: event.target.checked })
                        }
                    }} />} label={"Copy to Customer Notes"} />
            </div>
        </Box>
    </>
}

async function handleAddNewJobNote(franchiseId, jobId, note, setIsLoading, onError, onDone) {
    try {
        setIsLoading(true)
        const res = await addServiceTitanJobSpecificNote(franchiseId, jobId, note)
        onDone(res)
    } catch {
        onError()
    } finally {
        setIsLoading(false)
    }
}

async function handleAddNewCustomerNote(franchiseId, customerId, note, setIsLoading, onError, onDone) {
    try {
        setIsLoading(true)
        const res = await addServiceTitanCustomerNote(franchiseId, customerId, note)
        onDone(res)
    } catch {
        onError()
    } finally {
        setIsLoading(false)
    }
}

async function handleAddNewLocationNote(franchiseId, locationId, note, setIsLoading, onError, onDone) {
    try {
        setIsLoading(true)
        const res = await addServiceTitanLocationNote(franchiseId, locationId, note)
        onDone(res)
    } catch {
        onError()
    } finally {
        setIsLoading(false)
    }
}