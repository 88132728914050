import { ALL_FRANCHISES_STATUS } from "../constants/common"
import { getArrivalWindowByBrandId, getTemplateFaqs,getBrandByEmailAddress, getBrandOwnersById, validateBrandCode,getBrandJobTypesById } from "../services/brandService"
import { getFranchiseByBrandId } from "../services/franchises"
import { retry, shouldRetry } from "../state-services-dublicate/serviceTitan"
import { getSortedFranchisesAlphabeticallyAndActiveStatus } from "../utils/franchise"
import _ from 'lodash'; //eslint-disable-line

export async function handleGetBrandByEmailAddress(email, onDone = (brand) => { }, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    try {
        const brand = await getBrandByEmailAddress(email)
        onDone(brand)
    } catch (err) {
        if (shouldRetry(backoffConfig) && err?.status !== 400 && err?.status !== 404) {
            retry(() => handleGetBrandByEmailAddress(email, onDone, backoffConfig), backoffConfig)
        } else if (err?.status !== 404) {
            onDone()
        }
    }
}

export async function handleGetArrivalWindowByBrandId(brandId, setArrivalWindow) {
    try {
        const res = await getArrivalWindowByBrandId(brandId)
        setArrivalWindow(res?.arrival_window ?? null)
    } catch (error) {
        setArrivalWindow(null)
    }
}

export async function handleGetBrandFaqTemplate(brandId, setFaqTemplate,setOldBrandTemplateFaqs) {
    try {
        const res = await getTemplateFaqs(brandId)
        let faqTemplate = []
        if (Array.isArray(res)) {
            res.forEach((item, index) => {
                let faq = {}
                faq.id = item.id
                faq.question = item.question
                faq.answer = item.answer
                faq.keywords = item.keywords
                faq.brand_id = item.brand_id
                faq.alreadyInDb = true
                faqTemplate.push(faq)
            })
            setFaqTemplate(faqTemplate)
            setOldBrandTemplateFaqs(faqTemplate)
        }
    } catch (error) {
        setFaqTemplate()
    }

}

export async function handleValidateBrandCodes(brandCode = "",id=0, setBrandShortCodeErr) {
    try {
        const res = await validateBrandCode(brandCode,id);
        if (res?.message === "Brand Code Duplicate") {
            setBrandShortCodeErr(res?.message);
            return true;
        } else {
            setBrandShortCodeErr("");
            return false;
        }
    } catch (error) {

    }
}

export async function handleLoadOwners(id, setBrandOwners) {
    try {
        const brandOwners = await getBrandOwnersById(id);
        if (Array.isArray(brandOwners)) {
            setBrandOwners(brandOwners);
        } else {
            setBrandOwners([]);
        }
    } catch (error) {
        setBrandOwners([]);
    }
}

export async function handleGetBrandFranchises(brandId = 0, setFranchises, setIsFranchisesLoading, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    try {
        setFranchises([])
        if (!brandId) return
        setIsFranchisesLoading(true)
        const res = await getFranchiseByBrandId(brandId, ALL_FRANCHISES_STATUS)
        if (Array.isArray(res)) {
            const sortedArray = getSortedFranchisesAlphabeticallyAndActiveStatus(res)
            setFranchises(sortedArray)
        }
    } catch (err) {
        if (shouldRetry(backoffConfig) && err?.status !== 400 && err?.status !== 404) retry(() => handleGetBrandFranchises(brandId, setFranchises, setIsFranchisesLoading, backoffConfig), backoffConfig)
    } finally {
        setIsFranchisesLoading(false)
    }
}

// load brand job types using brand id and set the job types
export async function handleLoadBrandJobTypes(brandId, setJobTypes) {
    try {
        const brandJobTypes = await getBrandJobTypesById(brandId)
        if (Array.isArray(brandJobTypes)) {
            setJobTypes(brandJobTypes);
        } else {
            setJobTypes([]);
        }
    } catch (error) {
        setJobTypes([])
    }
}