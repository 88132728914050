import { Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import SmallCard, { SmallCardForBrand, SmallCardForSummary } from "../../../../../components/servicetitan/SmallCard";
import moment from "moment";

export default function WorkOrderCard(props) {
    const { WorkOrder, brand } = props
    const WorkOrderId = WorkOrder?.name?.split(":")

    return <Paper sx={{ width: "100%", borderRadius: "0.5em", border: "1px solid gray", borderLeft: "4px solid #00a79d", marginTop: "0.5em" }}>
        <><Box sx={{ display: "flex", flexDirection: "row", padding: "0.4em", alignItems: "center", borderRadius: "0.5em", gap: "1em" }}>
            <Typography style={{ fontWeight: "bold" }} >{(`#${WorkOrderId[1]}` ?? "")}</Typography>
        </Box>
            <Grid container sx={{ marginLeft: "10px" }} >
                <Grid item xs={3} >
                    <SmallCard heading={"Scheduled Date"} value={WorkOrder?.dateService ? moment.unix(WorkOrder?.dateService).utc().format('MM/DD/YYYY h:mm A') : "-"}></SmallCard>
                </Grid>
                <Grid item xs={3}>
                    <SmallCardForBrand heading={"Brand"} brand={brand}></SmallCardForBrand>
                </Grid>
                <Grid item xs={3}>
                    <SmallCard heading={"Route"} value={WorkOrder?.route ? WorkOrder?.route.name : "-"}></SmallCard>
                </Grid>
                <Grid item xs={3}>
                    <SmallCard heading={"Duration"} value={WorkOrder?.duration ? `${WorkOrder?.duration?.fieldValue} ${"mins"}` : "-"}></SmallCard>
                </Grid>
            </Grid>
            <Grid container sx={{ marginLeft: "10px" }} >
                <Grid item xs={3} >
                    <SmallCard heading={"Status"} value={WorkOrder?.status ? WorkOrder?.status.fieldValue : ""}></SmallCard>
                </Grid>
                <Grid item xs={3} >
                    <SmallCard heading={"Client"} value={WorkOrder?.client ? WorkOrder?.client.name : "-"}></SmallCard>
                </Grid>
                <Grid item xs={3}>
                    <SmallCard heading={"Contact"} value={WorkOrder?.contact ? WorkOrder?.contact.name : "-"}></SmallCard>
                </Grid>
                <Grid item xs={3}>
                    <SmallCardForSummary heading={"Location"} value={WorkOrder?.location ? WorkOrder?.location.name : "-"}></SmallCardForSummary>
                </Grid>
            </Grid>
            <Grid container sx={{ marginLeft: "10px" }}>
                <Grid item xs={12} >
                    <SmallCardForSummary heading={"Summary"} value={WorkOrder?.summary ? WorkOrder?.summary.fieldValue : ""}></SmallCardForSummary>
                </Grid>
            </Grid>
            <Box sx={{ display: "flex", flexDirection: "row", padding: "0.4em", borderRadius: "0.5em", gap: "1em" }}>
            </Box>
        </>
    </Paper>
}