import { globalSearch } from "../../services/globalSearch"

export async function handleGlobalSearch(indexName, searchValue, setRecords, setIsLoading, setIsError) {
    try {
        setIsError(false)
        setIsLoading(true)
        setRecords([])
        if (!searchValue || !indexName) return
        const res = await globalSearch(indexName, searchValue)
        if (Array.isArray(res)) {
            setRecords(res)
        } else {
            setIsLoading(false)
        }
    } catch {
        setIsError(true)
    }
}