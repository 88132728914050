import { Autocomplete, Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useState } from "react";
import AddressAutoComplete from "../../../../../components/AddressAutoComplete";
import AddressBox from "../../../../../components/AddressBox";
import CustomTextField from "../../../../../components/CustomTextField";
import RenderInput from "../../../../../components/servicetitan/RenderInput";
import ServiceAreaMessage from "./ServiceAreaMessage"
import { CREATE_JOB_SECTION_IDS } from "../../../../../constants/jobs";
import { MAX_FRANCHISE_LOCATION_RADIUS } from "../../../../../constants/location";
import { getCountries, getStates, updateLocation } from "../../../../../services/vonigo/jobScheduling";
import { isValidZipCode } from "../../../../../utils/validators";
import { handleGetSingleLoctionDetails, validateStateWithCountryForEditLocation } from "../../../../../state-services-dublicate/vonigo";
import AddressBoxOfLocation from "../../../../../components/AddressBoxOfLocation";
import { AVAILABLE_CRMS, NOTIFICATION_TYPES } from "../../../../../constants/common";
import LoadingButton from "../../../../../components/LoadingButton";

export default function LocationDetailsSection(props) {
    const { brand, franchise, selectedDraftToContinue, handleCloseEditLocation, isEditLocation, isNewLocationForExistingCustomer, setIsEditLocation, selectConfigMessage, isCreateMultipleWorkOrders, configMessages, locationEditCount, setLocationEditCount, customerSearchCount, editLocation,
        setEditLocation, isNewCustomer, isNewLocation, setIsNewLocation, location, setLocation, selectedCustomer, locations, isLocationsLoading, selectedLocation, setSelectedLocation, serviceableZipCodeResponse, setSelectedLocationDetails, showNotification } = props
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [isEditLocationLoading, setIsEditLocationLoading] = useState(false)
    const [isValidZipCodeAddress, setisValidZipCodeAddress] = useState(true)

    useEffect(() => {
        handleGetCountries(brand, franchise, setCountries, setStates);
    }, [brand, franchise])

    useEffect(() => {
        if (selectedLocation) {
            handleGetSingleLoctionDetails(brand, franchise, selectedLocation, setSelectedLocationDetails, setEditLocation)
        } else {
            setSelectedLocationDetails()
        }
    }, [selectedLocation])

    function handleOnAddressChanged(address) {
        if (isNewLocation || isNewCustomer) {
            const countryOptionId = countries?.find((c) => c?.name === address?.country)?.optionID
            const stateOptionId = states?.find((c) => c?.name === address?.state)?.optionID
            setLocation({ ...location, address: address, countryOptionId: countryOptionId, stateOptionId: stateOptionId })
        }
    }

    function handleOnEditAddressChanged(address) {
        const countryOptionId = countries?.find((c) => c?.name === address?.country)?.optionID
        const stateOptionId = states?.find((c) => c?.name === address?.state)?.optionID
        setEditLocation({ ...editLocation, zip: address?.zip, city: address?.city, street: address?.street, unit: address?.unit, state: address?.state, country: address?.country, countryOptionId: countryOptionId, stateOptionId: stateOptionId })
    }

    function zipCodeValidator(z, c) {
        return location?.address?.country ? isValidZipCode(z, c) && (z?.length > 3 || !z?.length) : true
    }

    function zipCodeValidatorForEditLocation(z, c) {
        return editLocation?.country ? isValidZipCode(z, c) && (z?.length > 3 || !z?.length) : true
    }

    function onLocationNameChanged(event) {
        setLocation({ ...location, name: event?.target?.value });
    }

    function onEditLocationNameChanged(e) {
        setEditLocation({ ...editLocation, title: e?.target?.value });
    }

    function changeFlow() {
        setIsNewLocation(!isNewLocation)
        handleCloseEditLocation()
    }

    return <Box id={CREATE_JOB_SECTION_IDS.LOCATION} sx={{ display: "flex", flexDirection: "column", paddingTop: "0.5em" }} >
        <Box sx={{ display: 'flex', flexDirection: "row", width: "100%", justifyContent: "left", alignItems: "center" }}>
            <h4>Location</h4>
            {isNewCustomer || customerSearchCount === 0 ? <></> : <Tooltip title={isNewLocation ? "Use existing location" : "Add new location"} >
                <Button sx={{ color: "#00a79d" }} variant="text"
                    onClick={() => { changeFlow() }}>
                    {isNewLocation ? "Existing" : "\u002B New"}
                </Button>
            </Tooltip>}
            {isCreateMultipleWorkOrders && <Tooltip title={isNewLocation ? "Use existing location" : "Add new location"} >
                <Button sx={{ color: "#00a79d" }} variant="text"
                    onClick={() => { changeFlow() }}>
                    {isNewLocation ? "Existing" : "\u002B New"}
                </Button>
            </Tooltip>}
            {selectedDraftToContinue && customerSearchCount <= 0 && !isNewCustomer && <Tooltip title={isNewLocation ? "Use existing location" : "Add new location"} >
                <Button sx={{ color: "#00a79d" }} variant="text"
                    onClick={() => { changeFlow() }}>
                    {isNewLocation ? "Existing" : "\u002B New"}
                </Button>
            </Tooltip>}
            {!isNewCustomer && !isNewLocation ?
                <IconButton color="primary" size={"medium"} disabled={!selectedLocation} style={{ marginRight: "10px" }}
                    onClick={() => setIsEditLocation(true)}>
                    <Tooltip title={"Edit Location"}><EditIcon style={{ height: "20px", width: "20px" }} /></Tooltip>
                </IconButton>
                : <></>}
            <Box flexGrow={2}></Box>
            {isNewCustomer || isNewLocation ?
                <AddressAutoComplete
                    label={"Search Location Address"}
                    onChange={handleOnAddressChanged}
                    lat={franchise?.latitude}
                    long={franchise?.longitude}
                    radius={MAX_FRANCHISE_LOCATION_RADIUS}
                    crm={AVAILABLE_CRMS.vonigo.value}
                /> : <></>
            }
            {configMessages.length !== 0 && (isNewCustomer || isNewLocation) ? <Box className="messageBox" sx={{ marginLeft: "auto" }}><p>{selectConfigMessage(configMessages, "locationAndContact")}</p></Box> : <></>}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: "row", flexWrap: "wrap", width: "100%", gap: "1em" }} >
            {isNewCustomer || isNewLocation ? <>
                <CustomTextField style={{ width: "45%" }} required={true} value={location?.name ?? ""} size="small" label="Location Name"
                    onChange={onLocationNameChanged} />
                <AddressBox address={location?.address} location={location} isDisabled={false} isRequired={true}
                    isStateRequired={false}
                    states={states}
                    setIsValidZipCode={setisValidZipCodeAddress}
                    countries={countries?.map(c => c.name)}
                    zipCodeValidator={zipCodeValidator}
                    onChangeAddress={handleOnAddressChanged} />
            </> : <>
                <Autocomplete
                    sx={{ width: "100%", }}
                    size="small"
                    noOptionsText={selectedCustomer ? "No Locations found for customer" : "Please select a customer"}
                    loading={isLocationsLoading}
                    value={selectedLocation ?? null}
                    disablePortal
                    disableClearable
                    options={locations}
                    getOptionLabel={(location) => location?.name ?? "-"}
                    renderOption={(props, location) => <RenderInput {...props} key={location?.objectID} content={location?.name} />}
                    onChange={(_, value) => {
                        setSelectedLocation(value);
                    }}
                    renderInput={(params) => <CustomTextField {...params} required={!isNewLocation} label="Select Customer's Location" />}
                />
                <CustomTextField style={{ width: "45%" }} disabled={!isEditLocation} required={true} value={editLocation?.title ?? ""} size="small" label="Location Name"
                    onChange={onEditLocationNameChanged} />
                <AddressBoxOfLocation address={editLocation} setisValidZipCodeAddress={setisValidZipCodeAddress} isDisabled={!isEditLocation} isRequired={true}
                    isStateRequired={false}
                    states={states}
                    editLocation={editLocation}
                    countries={countries?.map(c => c.name)}
                    zipCodeValidator={zipCodeValidatorForEditLocation}
                    onChangeEditAddress={handleOnEditAddressChanged} />
                {isEditLocation ?
                    <Box sx={{ display: "flex", marginLeft: "auto", height: "60%", marginRight: "3.5em", marginTop: "1em", flexDirection: "row", gap: "1em" }}>
                        <Button size={"small"} variant="contained" className={"btn-secondary"} onClick={() => handleCloseEditLocation()}>Cancel</Button>
                        <LoadingButton size={"small"} sx={{ flex: "0 0 12em" }} type={"submit"} disabled={!isValidZipCodeAddress || !validateStateWithCountryForEditLocation(states, editLocation)} loading={isEditLocationLoading} variant="contained" className={isValidZipCodeAddress && validateStateWithCountryForEditLocation(states, editLocation) ? "btn-primary" : "btn-disable"}
                            onClick={() => handleEditLocation(brand, franchise, editLocation, selectedLocation, setIsEditLocation, setSelectedLocation, setIsEditLocationLoading, locationEditCount, setLocationEditCount, showNotification)}>
                            Edit Location
                        </LoadingButton>
                    </Box>
                    : <></>}
            </>
            }
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
            {serviceableZipCodeResponse ? <ServiceAreaMessage serviceableZipCodeResponse={serviceableZipCodeResponse} /> : <></>}
            {isEditLocation && !isNewCustomer && !isNewLocationForExistingCustomer &&
                <Box sx={{ marginTop: "1em", marginLeft: "auto" }}>
                    <Typography flexGrow={0} color={"orange"}>Before creating the job, please edit the details or cancel them.</Typography>
                </Box>
            }
        </Box>
    </Box>
}

async function handleEditLocation(brand, franchise, location, selectedLocation, setIsEditLocation, setSelectedLocation,
    setIsEditLocationLoading, locationEditCount, setLocationEditCount, showNotification) {
    if (location?.title !== "" && location?.street !== "" && location?.city !== "" && location?.zip !== "") {
        setIsEditLocationLoading(true)
        const locationEditRequest = {
            ...location,
            baseUrl: brand?.redirect_url,
            franchiseId: franchise?.franchise_id,
            locationId: parseInt(selectedLocation?.objectID, 10),

        }
        let locationResponse = await updateLocation(locationEditRequest)
        setLocationEditCount(locationEditCount + 1)
        setSelectedLocation(locationResponse)
        setIsEditLocationLoading(false)
        setIsEditLocation(false)
    } else {
        showNotification({ message: "Please Fill Required Fields", type: NOTIFICATION_TYPES.ERROR })
    }
}

async function handleGetCountries(brand, franchise, setCountries, setStates) {
    try {
        if (brand && franchise && brand?.redirect_url && franchise?.franchise_id) {
            const response = await getCountries(brand?.redirect_url, franchise?.franchise_id)
            if (Array.isArray(response?.Options)) {
                setCountries(response?.Options);
            }
        }
        if (brand && franchise && brand?.redirect_url && franchise?.franchise_id) {
            const response = await getStates(brand?.redirect_url, franchise?.franchise_id)
            if (Array.isArray(response?.Options)) {
                setStates(response?.Options);
            }
        }
    } catch { }
}
