import { withRouter } from "react-router-dom"
import ErrorView from "../components/ErrorView"
import { ERROR_COMPONENTS } from "../constants/common"


function NotFoundPage(props) {
    return (
        <div style={{ marginTop: '10%' }}>
            <ErrorView type={ERROR_COMPONENTS.RouteNotFound.type} />
        </div>
    )
}


export default withRouter(NotFoundPage)
