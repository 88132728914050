import { FRANCHISE_STATUS } from "../../../constants/common"

export const setStatusName = (status) => {
    switch (status) {
        case FRANCHISE_STATUS.active:
            return ["active", "activated"]
        case FRANCHISE_STATUS.deleted:
            return ["deactivate", "deactivated"]
        case FRANCHISE_STATUS.removed:
            return ["PERMANENTLY DELETE", "PERMANENTLY DELETE"]
        default:
            return ""
    }
}