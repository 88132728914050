import { NOTIFICATION_TYPES, USER_STATUS } from "../constants/common"
import { createUser, getMe, setUserStatus, updateUser, getUserByEmail, getUserPreferences, userPreferences, updateDarkMode } from "../services/userService"
import { getMeActionTypes } from "../constants/users"

export const getMeHandler = async (setIsMyId, setUpdatedUser, actionType) => {
    try {
        let currentUser = await getMe()     // Get current user
        actionType === getMeActionTypes.id && setIsMyId(currentUser?.user_id)
        actionType === getMeActionTypes.all && setUpdatedUser(currentUser)
        if (currentUser.is_enabled === USER_STATUS.disabled) window.location.replace("/")
    } catch (error) {
    }
}

export const handleUserStatusChange = async (status, userId, showNotification, handleRefreshData, handleClose) => {
    try {
        await setUserStatus(status, userId)
        showNotification({ message: `${status === USER_STATUS.enabled ? "User has been activated successfully" : 'User has been deactivated successfully'}`, type: NOTIFICATION_TYPES.SUCCESS })
        handleRefreshData()
    } catch (error) {
        showNotification({ message: "Unable to update status at thios moment", type: NOTIFICATION_TYPES.ERROR })
    } finally {
        handleClose()
    }
}

export const handleCheckByUserEmail = async (userId, email, next) => {
    let include = false
    try {
        let emaildata = await getUserByEmail(email)
        if (!userId && emaildata?.user_id) {
            include = true
        }
    } catch (err) { }
    finally {
        next(include)
    }
}

export const handleSaveOrUpdateUser = async (userId, user, showNotification, setErr, handleRefreshData, handleClose, setUserUpdated, setIsLoading) => {
    try {
        setIsLoading(true)
        if (!userId) {
            await createUser(user)
            showNotification({ message: "User has been added successfully", type: NOTIFICATION_TYPES.SUCCESS })
        } else {
            await updateUser(userId, user)
            showNotification({ message: "User has been updated successfully", type: NOTIFICATION_TYPES.SUCCESS })
            setUserUpdated(true)
        }
        handleClose()
    } catch (error) {
        let errDetails = error?.responseText
        if (errDetails?.includes("user already exist")) {
            setErr("Email already exist please try another one")
        }
        if (!userId) {
            showNotification({ message: "Unable to add user at this time", type: NOTIFICATION_TYPES.ERROR })
        } else {
            showNotification({ message: "Unable to Update User at this moment", type: NOTIFICATION_TYPES.ERROR })
        }
    } finally {
        setIsLoading(false)
        handleRefreshData()
    }
}

export async function handleGetMe(setUser) {
    try {
        let res = await getMe()
        if (res) setUser(res)
    } catch { }
}

export async function handleSaveOrUpdateUserPreferences(
    userPreference,
    setIsLoading,
    showNotification
) {
    try {
        setIsLoading(true);
        await userPreferences(userPreference);
        showNotification({
            message: "User preferences has been updated successfully",
            type: NOTIFICATION_TYPES.SUCCESS,
        });
    } catch (error) {
        showNotification({
            message: "Unable to update user preferences at this moment",
            type: NOTIFICATION_TYPES.ERROR,
        });
    } finally {
        setIsLoading(false)
    }
}

export async function handleGetUserPreferenceOfLoggedUser(setUserPreferences) {
    try {
        let res = await getUserPreferences();
        if (res) setUserPreferences(res);
    } catch { }
}

export async function handleSaveOrUpdateDarkMode(userPreference) {
    try {
        await userPreferences(userPreference);
    } catch (error) { }
}

export async function handleUpdateDarkMode(status) {
    try {
        await updateDarkMode(status);
    } catch (error) { }
}