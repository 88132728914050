import { FetchClient } from "../utils/fetchClient";

export async function getAllUsers(page, size) {
    return FetchClient(`/users?page=${page}&size=${size}`).GET({})
}

export const getAllActiveUsers = async (type, page, size) => {
    return FetchClient(`/users?page=${page}&size=${size}&agent_type=${type}`).GET({})
}

export const getAllUsersIncludeRemovedOnes = async (type, page, size) => {
    return FetchClient(`/users?page=${page}&size=${size}&agent_type=${type}`).GET({})
}

export const getMe = async () => {
    return FetchClient(`/users/me`).GET({})
}

export const createUser = async (body) => {
    return FetchClient(`/users`).POST({ payload: JSON.stringify(body) })
}

export const setUserStatus = async (status, id) => {
    return FetchClient(`/users?id=${id}&status=${status}`).PUT({})
}

export const updateUser = async (id, body) => {
    return FetchClient(`/users?id=${id}`).PUT({ payload: JSON.stringify(body) })
}

export const deleteUserById = async (id) => {
    return FetchClient(`/users?id=${id}`).DELETE({})
}

export const getUserByEmail = async (email) => {
    return FetchClient(`/users?email=${email}`).GET({})
}

export const checkLoginStatus = async () => {
    return FetchClient(`/auth/is-logged`).GET({})
}

export const userPreferences = async (body) => {
    return FetchClient(`/users/user/preference`).POST({ payload: JSON.stringify(body) });
};

export const getUserPreferences = async () => {
    return FetchClient(`/users/user/preference`).GET({})
};

export const updateDarkMode = async (status) => {
    return FetchClient(`/users/user/preference?darkmode=${status}`).PUT({})
};

export async function getUsers(ids = [], brandId = [], userLevel = [], page = 1, pageSize = 10) {
    let idsString = ""
    if (Array.isArray(ids)) {
        idsString = ids.join(",")
    }
    if (Array.isArray(brandId)) {
        brandId = brandId.join(",")
    }
    if (Array.isArray(userLevel)) {
        userLevel = userLevel.join(",")
    }
    return FetchClient(`/users?id=${idsString}&brand=${brandId}&level=${userLevel}&page=${page}&size=${pageSize}`).GET({})
}

//to get an user by id
export async function getAnUser(id) {
    return FetchClient(`/users?user_id=${id}`).GET({})
}
