import { Box, Typography } from "@mui/material"
import DownloadImage from "../../assets/img/download.png";
const downloadUrl = process.env.REACT_APP_CCAI_DESKTOP_APP_DOWNLOAD_URL

export default function AgentAssistSettingsSection(props) {
    const { handleClose } = props
    return <Box>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <p>IBS Call Center Agent Assist captures the real-time call script for you.</p>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'background.icon' }}>
                <a
                    style={{ textDecoration: 'none', color: "inherit", display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 5 }}
                    variant="contained" href={downloadUrl}
                    onClick={handleClose}
                >
                    <img style={{ marginRight: '1em', cursor: 'pointer' }} src={DownloadImage} alt="downloadApplication" width={35} height={35} />
                    Download IBS Agent Assist for windows
                </a>
            </Box>
        </Typography>
    </Box>
}