import { NOTIFICATION_TYPES } from "../constants/common";
import { SUPERVISOR_USER_LEVEL } from "../constants/serviceTitan";
import { getAllBrands, getBrandById, getBrandsByIds } from "../services/brandService";
import { alphabeticallySortArray } from "../utils/array";
import { getMatchingBrandByBrandId, getMatchingBrandById, sortBrandsAlphabetically } from "../utils/miscellaneous";

export function handleGetAllBrands(selectedBrandId, brandPrimaryKeyId, setBrands, setBrand = () => { }, setIsBrandsLoading, onDone = (brands) => { }) {
    setIsBrandsLoading(true);
    getAllBrands().then((brandsResponse) => {
        if (Array.isArray(brandsResponse?.brands)) {
            brandsResponse.brands.sort((a, b) => alphabeticallySortArray(a.brand_name, b.brand_name))
            setBrands(sortBrandsAlphabetically(brandsResponse?.brands));
            let brand
            if (brandPrimaryKeyId) {
                brand = getMatchingBrandById(brandPrimaryKeyId, brandsResponse?.brands)
            }
            else if (selectedBrandId) {
                brand = getMatchingBrandByBrandId(selectedBrandId, brandsResponse?.brands);
            }
            setBrand(brand);
            onDone(brandsResponse?.brands)
        }
    }).catch(() => {
    }).finally(() => {
        setIsBrandsLoading(false);
    });
}

export async function handleGetBrandById(brandId, setBrand = (brand) => { }, onError = (error) => { }) {
    try {
        const res = await getBrandById(brandId)
        if (res) setBrand(res)
    } catch (err) {
        onError(err)
    }
}

export async function handleGetBrandsByIds(brandIds = [], setBrands = (brands) => { }, setIsBrandsLoading = (isLoading) => { }, setNotify = (notify) => { }) {
    try {
        setIsBrandsLoading(true)
        const res = await getBrandsByIds(brandIds)
        if (Array.isArray(res)) setBrands(sortBrandsAlphabetically(res))
    } catch {

    } finally {
        setIsBrandsLoading(false)
    }
}

export async function handleGetUserBrands(isAllBrands, user, setBrands = (brands) => { }, setIsBrandsLoading = (isLoading) => { }, showNotification, setAllBrands) {
    if (!isAllBrands && user?.level < SUPERVISOR_USER_LEVEL) {
        if (Array.isArray(user?.user_brands) && user?.user_brands?.length > 0) {
            handleGetBrandsByIds(user?.user_brands?.map?.((b) => b.brand_id), setBrands, setIsBrandsLoading)
        } else {
            showNotification?.({ isOpen: true, message: "Important : You are not assigned to any brand. Please contact your administrator", type: NOTIFICATION_TYPES.ERROR })
        }
    }
    handleGetAllBrands(undefined, undefined, (brands) => {
        if (isAllBrands || user?.level >= SUPERVISOR_USER_LEVEL) setBrands(brands)
        setAllBrands(brands)
    }, () => { }, setIsBrandsLoading)
}