import { createContext, useState } from 'react';

export const ScreenCapturesocketContext = createContext();

const ScreenCaptureSocketProvider = (props) => {
    const [screeenCapturingInfo, setScreenCapturingInfo] = useState(null)

    return (
        <ScreenCapturesocketContext.Provider value={[screeenCapturingInfo, setScreenCapturingInfo]}>
            {props.children}
        </ScreenCapturesocketContext.Provider>
    );
};

export default ScreenCaptureSocketProvider;
