import { FetchClient } from "../../utils/fetchClient";


export async function getOutboundCalls(createdOnOrAfter = "", createdOnOrBefore = "", keyword = "", page = 1, pageSize = 50) {
    return await FetchClient(`/data/outbound-call?page=${page}&page-size=${pageSize}&keyword=${keyword}&created-on-or-after=${createdOnOrAfter}&created-on-or-before=${createdOnOrBefore}`).GET({})
}

export async function createOutboundCall(body = { from_franchise_id: 0, from_phone: "", to_phone: "" }) {
    return await FetchClient(`/data/outbound-call`).POST({ payload: JSON.stringify(body) })
}

export async function mapOutboundCallWithInboundCall(outboundCallId, jobOrLeadId, jobOrLead) {
    let body = { outbound_call_id: outboundCallId };
    if (jobOrLead === "ServiceTitanJob") {
        body.service_titan_job_id = jobOrLeadId;
    } else {
        body.lead_id = jobOrLeadId;
    }
    return await FetchClient(`/data/outbound-call/map`).POST({ payload: JSON.stringify(body) })
}