import { AVAILABLE_CRMS } from "../constants/common";
import { getPlacesAutocomplete } from "../services/googleServices";

export function handleLocationAddressSearchPlaceDetails(placeDetailsResponse, setPlaceDetails, setSessionToken, crm) {
    try {
        if (placeDetailsResponse?.result) {
            const addressMap = getAddressMap(placeDetailsResponse, crm);
            let st = addressMap["street_number"] ?? " ";
            let rt = addressMap["route"] ?? " ";
            const addressObject = {
                street: (st + " " + rt),
                city: (addressMap["locality"]),
                state: (addressMap["administrative_area_level_1"]),
                zip: (addressMap["postal_code"]),
                country: (addressMap["country"])
            };
            setPlaceDetails(addressObject)
        }
    } catch (error) {

    } finally {
        setSessionToken("");
    }
}

export function handleGetPlacesAutocomplete(searchString = "", sessionToken, setAddressSearchResults, setSessionToken, lat = "", long = "", radius = "") {
    getPlacesAutocomplete(searchString, sessionToken, lat, long, radius).then((placesAutocompleteResponse) => {
        if (Array.isArray(placesAutocompleteResponse?.predictions)) {
            const predictions = [...placesAutocompleteResponse?.predictions, { description: "poweredByGoogle" }];
            setAddressSearchResults(predictions);
            if (!sessionToken) {
                setSessionToken(placesAutocompleteResponse?.sessionToken);
            }
        }
    }).catch(() => {
    });
}

function getAddressMap(placeDetailsResponse, crm) {
    const componentTypesMap = new Map();
    placeDetailsResponse?.result?.address_components?.forEach(component => {
        component?.types.forEach((type) => {
            if (type === "administrative_area_level_1") {
                setStateAccordingToCrm(component, crm, componentTypesMap);
            } else {
                componentTypesMap[type] = component?.long_name;
            }
        });
    });
    if (componentTypesMap["country"] === "United States" && crm === AVAILABLE_CRMS.servicetitan.value) {
        componentTypesMap["country"] = "USA";
    }
    return componentTypesMap;
}

function setStateAccordingToCrm(component, crm, componentTypesMap) {
    if (crm === AVAILABLE_CRMS.servicetitan.value) {
        componentTypesMap["administrative_area_level_1"] = component?.short_name;
    } else {
        componentTypesMap["administrative_area_level_1"] = component?.long_name;
    }
}