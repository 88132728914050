import { FetchClient } from "../utils/fetchClient";

export const fetchConnectedAgents = async () => {
    return FetchClient(`/notification/subscribers`).GET({})
}

export const initializeMonitoring = async (body) => {
    return FetchClient(`/notification/communication/publish`).POST({ payload: JSON.stringify(body) })
}

export const sendAnswerToAgent = async (body) => {
    return FetchClient(`/notification/communication/publish`).POST({ payload: JSON.stringify(body) })
}

export const sendIceCandidateToAgent = async (body) => {
    return FetchClient(`/notification/communication/publish`).POST({ payload: JSON.stringify(body) })
}

export const closeConnection = async (body) => {
    return FetchClient(`/notification/communication/publish`).POST({ payload: JSON.stringify(body) })
}

export const initializeMonitoringLogs = async (body) => {
    return FetchClient(`/agent-score/monitoringLogs`).POST({ payload: JSON.stringify(body) })
}

export const updateMonitoringLogs = async (body) => {
    return FetchClient(`/agent-score/monitoringLogs`).PUT({ payload: JSON.stringify(body) })
}
