import DescriptionIcon from '@mui/icons-material/Description';
import WorkIcon from '@mui/icons-material/Work';
import { getAddressString } from "../../../utils/miscellaneous";
import { Collapse, IconButton, Box, Tabs, Tab } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ContactPageIcon from '@mui/icons-material/ContactPage';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import SmallCard from '../../servicetitan/SmallCard';
import { useContext, useState } from 'react';
import NotesSection from '../../../pages/dashboard/jobs/create-job-page/servicetitan/NotesSection';
import { CommonContext } from '../../../contexts/CommonContextProvider';
import JobsSection from '../../../pages/dashboard/jobs/create-job-page/servicetitan/JobsSection';
import LeadsSection from '../../../pages/dashboard/jobs/create-job-page/servicetitan/LeadsSection';
import ContactsSection from '../../../pages/dashboard/jobs/create-job-page/servicetitan/ContactsSection';
import LocationsSection from '../../../pages/dashboard/jobs/create-job-page/servicetitan/LocationsSection';

const CUSTOMER_HISTORY_SECTIONS = {
    NOTES: {
        index: 0,
        label: "Notes",
        icon: <DescriptionIcon />
    },
    LEADS: {
        index: 1,
        label: "Leads",
        icon: <TrendingUpIcon />
    },
    JOBS: {
        index: 2,
        label: "Jobs",
        icon: <WorkIcon />
    },
    LOCATIONS: {
        index: 3,
        label: "Locations",
        icon: <LocationOnIcon />
    },
    CONTACTS: {
        index: 4,
        label: "Contacts",
        icon: <ContactPageIcon />
    }
}


export default function CustomerTile(props) {
    const { franchise, brand, customer } = props
    const [isExpanded, setIsExpanded] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(0)
    const { showNotification } = useContext(CommonContext)
    function handleTabChange(e, index) {
        setCurrentIndex(index);
    };

    function getCurrentTabPanelContent(index) {
        switch (index) {
            case CUSTOMER_HISTORY_SECTIONS.NOTES.index:
                return <NotesSection franchiseId={franchise?.id} customerId={customer?.id} showNotification={showNotification} fromDialPad={true} />
            case CUSTOMER_HISTORY_SECTIONS.LEADS.index:
                return <LeadsSection franchiseId={franchise?.id} customerId={customer?.id} showNotification={showNotification} />
            case CUSTOMER_HISTORY_SECTIONS.JOBS.index:
                return <JobsSection franchise={franchise} brand={brand} customerId={customer?.id} isShort={true} />
            case CUSTOMER_HISTORY_SECTIONS.LOCATIONS.index:
                return <LocationsSection franchiseId={franchise?.id} customerId={customer?.id} />
            case CUSTOMER_HISTORY_SECTIONS.CONTACTS.index:
                return <ContactsSection franchiseId={franchise?.id} customerId={customer?.id} />
            default:
                return <></>
        }
    }

    return <Box sx={{ padding: "0.5em", borderRadius: "0.5em", border: "1px solid #00a79d", borderLeft: "4px solid #00a79d" }} display={"flex"} flexDirection={"column"}  >
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "start", gap: "0.5em" }}>
            <Box width="13em"><SmallCard heading={"Name"} value={customer.name} /></Box>
            <Box width="10em"><SmallCard heading={"Type"} value={customer.type} /></Box>
            <Box width="25em"><SmallCard heading={"Address"} value={getAddressString(customer.address)} /></Box >
            <IconButton
                onClick={() => setIsExpanded(!isExpanded)}
                size="small"
            >
                {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
        </Box >
        <Collapse in={isExpanded} >
            <Box>
                <Tabs value={currentIndex} onChange={handleTabChange}  >
                    {Object.values(CUSTOMER_HISTORY_SECTIONS).map((v) => {
                        return <Tab key={v.index} tabIndex={v.index} label={v.label} icon={v.icon} iconPosition="start" />
                    })}
                </Tabs>
                {isExpanded && getCurrentTabPanelContent(currentIndex)}
            </Box>
        </Collapse>
    </Box>
}