import { Autocomplete, Box, Chip, CircularProgress, Collapse, IconButton, Tooltip, Typography } from "@mui/material"
import { useEffect, useState, useRef, useContext } from "react"
import { getEmails } from "../../../services/email/email"
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CustomTextField from "../../../components/CustomTextField";
import useDebounce from "../../../hooks/useDebounce";
import { CommonContext } from "../../../contexts/CommonContextProvider";
import EmailErrorImage from "../../../assets/img/error_images/validation_failed.png"
import NoEmailImage from "../../../assets/img/error_images/no_emails_found.png"
import { renderBrandOption } from "../../../components/servicetitan/RenderInput";
import { getBrandEmailsById } from "../../../services/brandService";
import { retry, shouldRetry } from "../../../state-services-dublicate/serviceTitan";
import { getMatchingBrandById } from "../../../utils/miscellaneous";
import EmailField from "../../dashboard/jobs/components/EmailField";
import { isValidEmail } from "../../../utils/validators";
import moment from "moment";

function EmailContent({ emailContent }) {
    const ref = useRef();

    useEffect(() => {
        if (ref.current) {
            const iframe = ref.current.contentWindow.document;
            iframe.open();
            iframe.write(emailContent);
            iframe.close();
            const iframeBody = iframe.body;
            iframeBody.style.backgroundColor = 'white';
            iframeBody.style.color = 'black';
        }
    }, [emailContent]);

    return <iframe title="Email Client" style={{ border: "none", backgroundColor: "white", test: emailContent }} width="100%" height="100%" ref={ref} />
}


export default function EmailClient(props) {
    const { emailInfo, setEmailInfo, brands, isBrandsLoading } = useContext(CommonContext)
    const [brandEmails, setBrandEmails] = useState([])
    const [isBrandEmailsLoading, setIsBrandEmailsLoading] = useState(false)
    const [emailsResponse, setEmailsResponse] = useState()
    const [isEmailsLoading, setIsEmailsLoading] = useState(false)
    const [isEmailsLoadingError, setIsEmailsLoadingError] = useState(false)
    const [previousPageTokens, setPreviousPageTokens] = useState([])
    const [pageToken, setPageToken] = useState("")
    const [selectedEmailAddress, setSelectedEmailAddress] = useState("")

    const debouncedHandleGetEmails = useDebounce({ to: emailInfo.to, from: emailInfo.from, subject: emailInfo.subject, pageToken: pageToken, setEmailsResponse: setEmailsResponse, setIsEmailsLoading: setIsEmailsLoading, setIsEmailsLoadingError: setIsEmailsLoadingError }, ({ to, from, subject, pageToken, setEmailsResponse, setIsEmailsLoading, setIsEmailsLoadingError }) => {
        handleGetEmails(to, from, subject, 0, 0, pageToken, setEmailsResponse, setIsEmailsLoading, setIsEmailsLoadingError)
    }, 1000)

    useEffect(() => {
        const email = brandEmails.find(email => email.email === emailInfo.to)
        setSelectedEmailAddress(email?.email)
    }, [emailInfo.to, brandEmails])

    useEffect(() => {
        if (emailInfo?.brandId) {
            handleGetBrandEmails(emailInfo?.brandId, setBrandEmails, setIsBrandEmailsLoading)
        } else {
            setBrandEmails([])
        }
    }, [emailInfo?.brandId])

    useEffect(() => {
        debouncedHandleGetEmails({ to: selectedEmailAddress, from: emailInfo.from, subject: emailInfo.subject, pageToken: pageToken, setEmailsResponse: setEmailsResponse, setIsEmailsLoading: setIsEmailsLoading, setIsEmailsLoadingError: setIsEmailsLoadingError })
        if (isValidEmail((emailInfo.from) || !Boolean(emailInfo.from)) && isValidEmail(emailInfo.to)) {
            setIsEmailsLoading(true)
            setEmailsResponse()
        }
    }, [selectedEmailAddress, emailInfo.to, emailInfo.from, emailInfo.subject, pageToken, debouncedHandleGetEmails])

    useEffect(() => {
        setPageToken("")
        setPreviousPageTokens([])
    }, [selectedEmailAddress, emailInfo.to, emailInfo.from, emailInfo.subject])

    function getEmailContent() {
        if (!Boolean(selectedEmailAddress)) {
            return <EmailClientImage text={"Please select a Brand Email address"} image={NoEmailImage} />
        } else if (isEmailsLoading) {
            return <Box sx={{ flexGrow: 2, marginTop: "10em" }} ><CircularProgress size={30} color="inherit" /></Box>
        } else if (isEmailsLoadingError) {
            return <EmailClientImage text={"The email address entered has yet to be validated"} image={EmailErrorImage} />
        } else if (!emailsResponse?.messages?.length) {
            return <EmailClientImage text={"Your inbox appears to be empty at this time"} image={NoEmailImage} />
        } else {
            return <Box sx={{ overflow: "scroll", flexGrow: 2, width: "100%", }} >
                <EmailContent emailContent={emailsResponse?.messages?.length ? getHtmlString(emailsResponse.messages[0]) : ""} />
            </Box>
        }
    }

    return <Box sx={{ position: "absolute", top: 0, bottom: 0, right: 0, width: "95%", padding: "1em", display: "flex", flexDirection: "column", alignItems: "center"}}>
        <Box padding="1em" display="flex" flexDirection="column" gap="0.5em" width={"100%"} >
            <Box display="flex" flexDirection="row" gap="0.5em" width={"100%"} >
                <Autocomplete
                    sx={{ minWidth: "300px" }}
                    size="small"
                    loading={isBrandsLoading}
                    noOptionsText={"No brands"}
                    disabled={brands === []}
                    disablePortal
                    disableClearable
                    options={brands}
                    getOptionLabel={(brand) => brand.brand_name}
                    renderOption={renderBrandOption}
                    value={getMatchingBrandById(emailInfo?.brandId, brands) ?? null}
                    onChange={(_, brand) => {
                        setEmailInfo({ ...emailInfo, brandId: brand?.id });
                    }}
                    renderInput={(params) => <CustomTextField {...params} label="Brand" />}
                />
                <Autocomplete
                    sx={{ minWidth: "300px", flexGrow: 2 }}
                    size="small"
                    loading={isBrandEmailsLoading}
                    noOptionsText={"No brand emails"}
                    disabled={brands === []}
                    disablePortal
                    disableClearable
                    options={brandEmails.map((e) => e.email)}
                    value={selectedEmailAddress ? selectedEmailAddress : null}
                    onChange={(_, email) => {
                        setEmailInfo({ ...emailInfo, to: email });
                    }}
                    renderInput={(params) => <CustomTextField {...params} label="Brand Email" />}
                />
            </Box>
            <Box display="flex" flexDirection="row" gap="0.5em" width={"100%"} >
                <EmailField sx={{ minWidth: "300px" }} size="small" label="From" value={emailInfo.from ?? ""} onChange={(e) => setEmailInfo({ ...emailInfo, from: e?.target?.value })} />
                <CustomTextField sx={{ minWidth: "300px", flexGrow: 2 }} size="small" label="Subject" value={emailInfo.subject ?? ""} onChange={(e) => setEmailInfo({ ...emailInfo, subject: e?.target?.value })} />
            </Box>
            <Box sx={{ backgroundColor: "primary.main", height: "4px", width: "100%" }} />
            {emailsResponse?.messages?.[0] ? <EmailInfoSection emailResponse={emailsResponse?.messages?.[0]} /> : <></>}
        </Box>
        {getEmailContent()}
        <Box padding="1em" display="flex" flexDirection="row" >
            <Tooltip title="Previous">
                <span>
                    <IconButton disabled={isEmailsLoading || !Boolean(previousPageTokens.length)} onClick={() => {
                        setPageToken(previousPageTokens.pop() ?? "")
                    }}>
                        <ChevronLeftIcon />
                    </IconButton>
                </span>
            </Tooltip>
            <Tooltip title="Next" >
                <span>
                    <IconButton
                        disabled={isEmailsLoading || !Boolean(emailsResponse?.next_page_token)}
                        onClick={() => {
                            setPreviousPageTokens([...previousPageTokens, pageToken])
                            setPageToken(emailsResponse?.next_page_token)
                        }}>
                        <ChevronRightIcon />
                    </IconButton>
                </span>
            </Tooltip>

        </Box>
    </Box >
}

async function handleGetBrandEmails(brandId = 0, setBrandEmails, setIsBrandEmailsLoading, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    try {
        setIsBrandEmailsLoading(true)
        const brandEmails = await getBrandEmailsById(brandId)
        if (Array.isArray(brandEmails)) {
            setBrandEmails(brandEmails)
        } else {
            setBrandEmails([])
        }
    } catch (err) {
        if (shouldRetry(backoffConfig) && err?.status !== 400) {
            retry(() => handleGetBrandEmails(brandId, setBrandEmails, setIsBrandEmailsLoading, backoffConfig), backoffConfig)
        }
    } finally {
        setIsBrandEmailsLoading(false)
    }
}

let lastGetEmailsProps;
async function handleGetEmails(to = "", from = "", subject = "", afterTimeEpoch = 0, beforeTimeEpoch = 0, nextPageToken = "", setEmailsResponse, setIsEmailsLoading, setIsEmailsLoadingError) {
    try {
        lastGetEmailsProps = getEmailPropsObject(to, from, subject, afterTimeEpoch, beforeTimeEpoch, nextPageToken)
        if ((isValidEmail(from) || !Boolean(from)) && isValidEmail(to)) {
            setIsEmailsLoading(true)
            setIsEmailsLoadingError(false)
            const res = await getEmails(from, to, subject, afterTimeEpoch, beforeTimeEpoch, nextPageToken)
            if (JSON.stringify(getEmailPropsObject(to, from, subject, afterTimeEpoch, beforeTimeEpoch, nextPageToken)) === JSON.stringify(lastGetEmailsProps)) setEmailsResponse(res)
        } else {
            setEmailsResponse()
            setIsEmailsLoading(false)
        }
    } catch {
        setIsEmailsLoadingError(true)
    } finally {
        setIsEmailsLoading(false)
    }
}

function getEmailPropsObject(to = "", from = "", subject = "", afterTimeEpoch = 0, beforeTimeEpoch = 0, nextPageToken = "") {
    return { to, from, subject, afterTimeEpoch, beforeTimeEpoch, nextPageToken }
}

function getHtmlString(message) {
    return getHtmlPart(message.payload.parts)

}

function getHtmlPart(parts = []) {
    const htmlParts = []
    for (let i = 0; i < parts.length; i++) {
        const part = parts[i]
        if (part?.parts?.length) {
            htmlParts.push(getHtmlPart(part.parts))
        }
        if (part.mimeType === "text/html") {
            htmlParts.push(convertUrlSafeBase64toNormalBase64(part.body.data))
        } else {
            continue
        }
    }
    let htmlString = removeScripts(htmlParts.join(""))
    htmlString = removeHref(htmlString)
    htmlString = removeRefreshMetaTag(htmlString)
    return htmlString
}

function convertUrlSafeBase64toNormalBase64(base64String = "") {
    const base64 = base64String.replace(/-/g, '+').replace(/_/g, '/');
    return atob(base64);
}

function removeScripts(html) {
    const div = document.createElement('div');
    div.innerHTML = html;
    const scripts = div.getElementsByTagName('script');
    for (let i = scripts.length - 1; i >= 0; i--) {
        scripts[i].parentNode.removeChild(scripts[i]);
    }
    return div.innerHTML;
}

function removeHref(html) {
    const div = document.createElement('div');
    div.innerHTML = html;
    const links = div.getElementsByTagName('a');
    for (let i = 0; i < links.length; i++) {
        const link = links[i];
        link.removeAttribute('href');
    }
    return div.innerHTML;
}

function removeRefreshMetaTag(html) {
    const div = document.createElement('div');
    div.innerHTML = html;
    const metaTags = div.getElementsByTagName('meta');
    for (let i = metaTags.length - 1; i >= 0; i--) {
        const metaTag = metaTags[i];
        if (metaTag.getAttribute('http-equiv') === 'refresh') {
            metaTag.parentNode.removeChild(metaTag);
        }
    }
    return div.innerHTML;
}


function EmailClientImage(props) {
    const { text, image } = props
    return <Box sx={{ flexGrow: 2, paddingTop: "10em", display: "flex", flexDirection: "column", alignItems: "center", gap: "1em" }} >
        <img style={{ width: "15em" }} src={image} alt="Email Client" />
        <Typography variant="h6">{text}</Typography>
    </Box>
}

function EmailInfoSection(props) {
    const { emailResponse } = props
    const [isInfoExpanded, setIsInfoExpanded] = useState(false)

    return <Box paddingInline="0.5em">
        <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Typography variant="subtitle2" sx={{ flexGrow: 2 }}>Email Info</Typography>
            <Tooltip title={isInfoExpanded ? "Contract" : "Expand"}>
                <span>
                    <IconButton onClick={() => setIsInfoExpanded(!isInfoExpanded)} >
                        {isInfoExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                </span>
            </Tooltip>
        </Box>
        <Collapse in={isInfoExpanded}>
            <Box display="flex" flexDirection="column" gap="0.5em">
                <EmailInfoItem key="From" header="From" value={getHeaderValue("From", emailResponse?.payload?.headers)} />
                <EmailInfoItem key="Subject" header="Subject" value={getHeaderValue("Subject", emailResponse?.payload?.headers)} />
                <EmailInfoItem key="Date" header="Date" value={moment(getHeaderValue("Date", emailResponse?.payload?.headers)).format("MM/DD/YYYY hh:mm A")} />
            </Box>
        </Collapse>
    </Box>
}

function EmailInfoItem(props) {
    const { header, value } = props
    return <Box display="flex" flexDirection="row" justifyContent="start" alignItems="center">
        <Typography sx={{ marginRight: "1em", width: "4em" }} variant="body2" >{header}</Typography>
        <Chip label={value} />
    </Box>
}

function getHeaderValue(key = "", headers = []) {
    const header = headers.find(header => header.name === key)
    return header?.value ?? ""
}