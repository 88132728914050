import { Autocomplete } from "@mui/material"
import CustomTextField from "../../../../../components/CustomTextField"
import RenderInput from "../../../../../components/servicetitan/RenderInput"
export default function IndustrySelection(props) {
    const { noOptionText, isRequired, industries, isIndustriesLoading = false } = props

    function selectIndustry(industries) {
        if (industries) {
            return industries?.find(({ name }) => name === "Repaint - Office Space")
        }
    }

    return <Autocomplete
        sx={{ flex: "1 1 50%" }}
        size="small"
        noOptionsText={noOptionText}
        loading={isIndustriesLoading}
        value={selectIndustry(industries) ?? null}
        disablePortal
        disableClearable
        disabled={true}
        options={industries}
        getOptionLabel={(customer) => customer.name}
        renderOption={(props, option) => <RenderInput {...props} key={option?.optionID} content={option?.name ?? "-"} />}
        renderInput={(params) => <CustomTextField {...params} required={isRequired} disabled={true} label="Select Industry" />}
    />
}