import { Box, Button, Dialog, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Select, Switch, Typography } from "@mui/material"
import CustomTextField from "../../../../../components/CustomTextField"
import { CustomSelect } from "../../franchiseCustomStyle"
import { getIsValidUSOrCanadianZipCode } from "../../../../../utils/validators"
import { AVAILABLE_CRMS, TIME_ZONES } from "../../../../../constants/common"
import { useState } from "react"
import { handleFranchiseIdValidation } from "../../../../../state-services/franchise/v3/franchise"
import useDebounce from "../../../../../hooks/useDebounce"

export default function FranchiseCreateMainSection(props) {

    const { oldFranchise, isDisabled, franchise, setFranchise } = props

    const [isMigrationConfirmationDialogOpen, setIsMigrationConfirmationDialogOpen] = useState(false)
    const [selectedCRMToMigrate, setSelectedCRMToMigrate] = useState()
    const [franchiseIdValidationResponse, setFranchiseIdValidationResponse] = useState()
    const debouncedHandleFranchiseIdValidation = useDebounce((franchise?.franchise_id), (franchiseId) => {
        if (franchiseId && !oldFranchise?.id) {
            handleFranchiseIdValidation(franchiseId, setFranchiseIdValidationResponse)
        } else {
            setFranchiseIdValidationResponse()
        }
    })

    function handleMigrationDialogCancel() {
        setIsMigrationConfirmationDialogOpen(false)
        setSelectedCRMToMigrate()
    }

    function handleMigrateCRM() {
        setFranchise((f) => {
            return { ...f, service_brand: selectedCRMToMigrate, last_migrated_at: (new Date()).toISOString() }
        })
        handleMigrationDialogCancel()
    }

    function handleCRMChange(e) {
        if (oldFranchise && e.target.value !== oldFranchise?.service_brand) {
            setSelectedCRMToMigrate(e.target.value)
            setIsMigrationConfirmationDialogOpen(true)
        } else {
            setFranchise((f) => { return { ...f, service_brand: e.target.value } })
        }
    }

    function handleOnFranchiseIdChange(e) {
        if (e?.target?.value?.length > 11) return
        let franchiseId = undefined
        if (e?.target?.value) {
            franchiseId = parseInt(e.target.value)
        }
        if (!oldFranchise?.id) debouncedHandleFranchiseIdValidation(franchiseId)
        setFranchise((f) => {
            return { ...f, franchise_id: franchiseId }
        })
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={franchise?.is_toll_free ? 7 : 5}>
                <CustomTextField
                    inputProps={{ "data-test": "franchise_main_franchise_name" }}
                    required
                    value={franchise?.franchise_name ?? ""}
                    type={"text"}
                    fullWidth
                    label={"Franchise Name"}
                    size={"small"}
                    margin="normal"
                    variant="outlined"
                    disabled={isDisabled}
                    onChange={(e) => setFranchise((f) => {
                        return { ...f, franchise_name: e.target.value }
                    })}
                />
            </Grid>
            <Grid item xs={5}>
                <CustomTextField
                    inputProps={{ "data-test": "franchise_main_franchise_location" }}
                    value={franchise?.franchise_location ?? ""}
                    onChange={(e) => setFranchise((f) => {
                        return { ...f, franchise_location: e.target.value }
                    })}
                    type={"text"}
                    fullWidth
                    required
                    label={"Franchise Location"}
                    size={"small"}
                    margin="normal"
                    variant="outlined"
                    disabled={isDisabled}
                />
            </Grid>
            {!Boolean(franchise?.is_toll_free) &&
                <>
                    <Grid item xs={2}>
                        <CustomTextField
                            inputProps={{ "data-test": "franchise_main_franchise_exact_zip_code" }}
                            required={true}
                            value={franchise?.exact_zipcode}
                            error={!getIsValidUSOrCanadianZipCode(franchise?.exact_zipcode)}
                            helperText={getIsValidUSOrCanadianZipCode(franchise?.exact_zipcode) ? "" : "Invalid zip code"}
                            onChange={(e) => setFranchise((f) => {
                                return { ...f, exact_zipcode: e.target.value }
                            })}
                            type={"text"}
                            fullWidth
                            label={"Franchise Exact ZIP Code"}
                            size={"small"}
                            margin="normal"
                            variant="outlined"
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <CustomTextField
                            inputProps={{ "data-test": "franchise_main_franchise_id" }}
                            required={true}
                            value={franchise?.franchise_id}
                            onChange={handleOnFranchiseIdChange}
                            type={"number"}
                            fullWidth
                            label={"Franchise Id"}
                            size={"small"}
                            margin="normal"
                            variant="outlined"
                            error={franchiseIdValidationResponse?.is_valid === false}
                            helperText={franchiseIdValidationResponse?.is_valid === true ? "" : franchiseIdValidationResponse?.message}
                            disabled={isDisabled || oldFranchise || franchise?.is_toll_free}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <CustomSelect fullWidth size={"small"} margin="normal">
                            <InputLabel required id="crm-label">CRM System</InputLabel>
                            <Select
                                inputProps={{ "data-test": "franchise_main_franchise_crm" }}
                                labelId="crm-label"
                                required
                                label="CRM System"
                                onChange={handleCRMChange}
                                value={franchise?.service_brand ? franchise?.service_brand : null}
                                disabled={isDisabled || franchise?.is_toll_free}
                            >
                                {Object.keys(AVAILABLE_CRMS).map(key =>
                                    <MenuItem data-test={`franchise_main_franchise_crm_${AVAILABLE_CRMS[key].value}`} key={key} style={{ fontSize: "14px" }} value={AVAILABLE_CRMS[key].value}>
                                        {AVAILABLE_CRMS[key].label}
                                    </MenuItem>
                                )}
                            </Select>
                        </CustomSelect>
                    </Grid>
                    <Grid item xs={5} >
                        <CustomSelect fullWidth size={"small"} required={franchise?.is_toll_free !== 1} margin="normal">
                            <InputLabel id="timeZone-label">Time Zone</InputLabel>
                            <Select
                                inputProps={{ "data-test": "franchise_main_franchise_timezone" }}

                                labelId="timeZone-label"
                                label="Time Zone"
                                onChange={(e) => setFranchise((f) => {
                                    return { ...f, time_zone: e.target.value }
                                })}
                                value={franchise?.time_zone ?? ""}
                                disabled={isDisabled || franchise?.is_toll_free}
                            >
                                {TIME_ZONES.map((zone, i) => {
                                    return <MenuItem key={i} data-test={`franchise_main_franchise_timezone_${zone?.zone}`} style={{ fontSize: "14px" }} value={zone?.zone_id}>{zone?.zone}</MenuItem>
                                })}
                            </Select>
                        </CustomSelect>
                    </Grid>
                    <Grid item xs={2}>
                        <Box display="flex" flexDirection="row" alignItems="center" paddingTop="20px" marginLeft="20px" >
                            <FormGroup>
                                <FormControlLabel control=
                                    {<Switch
                                        inputProps={{ "data-test": `franchise_is_call_center_booking_${franchise?.is_call_center_booking === 1}` }}
                                        disabled={isDisabled}
                                        checked={franchise?.is_call_center_booking === 1}
                                        onChange={(e, cheked) => setFranchise((f) => {
                                            return { ...f, is_call_center_booking: cheked ? 1 : 0 }

                                        })}
                                    />}
                                    label="Call Center Booking" />
                            </FormGroup>
                        </Box>
                    </Grid>
                </>}
            {oldFranchise ? <Dialog maxWidth="xs" open={isMigrationConfirmationDialogOpen} onClose={handleMigrationDialogCancel}><Box data-test="franchise_migration_dialog" sx={{ display: "flex", flexDirection: "column", padding: "1em", alignContent: "center" }}>
                <Typography variant="p">{`This action will migrate franchise to ${selectedCRMToMigrate}. Do you want to migrate ?`}</Typography>
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: "0.5em" }} ><Button data-test="franchise_migration_dialog_no" size="small" onClick={handleMigrationDialogCancel} sx={{ color: "#00a79d" }}  >No</Button><Button data-test="franchise_migration_dialog_yes" variant="contained" size="small" onClick={handleMigrateCRM} sx={{ backgroundColor: "#00a79d", "&:hover": { backgroundColor: "#00a79d" } }} >Yes</Button></Box>
            </Box></Dialog> : <></>}
        </Grid >
    )
}