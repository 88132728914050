import { getLoopCancelCallback, retry, shouldRetry } from "../../state-services-dublicate/serviceTitan";
import { getUserNotification, markAllAsNotificationRead, markNotificationAsRead } from "../../services/notifications";
import { NOTIFICATION_TYPES } from "../../constants/common";

const MAXIMUM_ONLINE_STATUS_DURATION_IN_MILLISECONDS = 60 * 1000

export function handleSocketConnection(userId, sessionId, broadcastType, setSocketMessage, setSocket, setAdminId, setSessionId, backoffConfig = { backoffDelay: 1000, backoffGrowth: 2, maxTries: 10, loopCondition: true, currentTries: 0 }) {
    const subscribeUrl = `${process.env.REACT_APP_NOTIFICATION_WS_URL}/subscribe?user_id=${userId}&broadcast_type=${broadcastType}&session_id=${sessionId}`
    let socket = new WebSocket(subscribeUrl);
    setSocket(socket)
    setAdminId(sessionId)
    setSessionId(sessionId)
    socket.onmessage = async (event) => {
        var msg = JSON.parse(event.data);
        setSocketMessage(msg)
    }
    socket.onclose = async (event) => {
        if (shouldRetry(backoffConfig)) {
            retry(() => handleSocketConnection(userId, sessionId, broadcastType, setSocketMessage, setSocket, setAdminId, backoffConfig), backoffConfig)
        }
    }
    const intervalId = setInterval(() => {
        socket.send(JSON.stringify({ type: "ping" }))
    }, MAXIMUM_ONLINE_STATUS_DURATION_IN_MILLISECONDS)
    return () => {
        socket.close()
        clearInterval(intervalId)
        getLoopCancelCallback(backoffConfig)()
    }
}


export async function handleGetUserNotifications(userId, from, to, page, pageSize, setNotificationCount, setNotifications, setIsNotificationsLoading, setIsNotificationsLoadingError, backoffConfig = { backoffDelay: 1000, backoffGrowth: 2, maxTries: 10, loopCondition: true, currentTries: 0 }) {
    try {
        setIsNotificationsLoadingError(false)
        setIsNotificationsLoading(true)
        const res = await getUserNotification(userId, from, to, page, pageSize)
        if (Array.isArray(res.notifications)) setNotifications(res.notifications)
        setNotificationCount(res.count)
    } catch (error) {
        if (shouldRetry(backoffConfig)) {
            retry(() => handleGetUserNotifications(userId, from, to, page, pageSize, setNotificationCount, setNotifications, setIsNotificationsLoading, setIsNotificationsLoadingError, backoffConfig), backoffConfig)
        } else {
            setIsNotificationsLoadingError(true)
        }
    } finally {
        setIsNotificationsLoading(false)
    }
}

export async function handleMarkAllNotificationAsRead(userId = 0, showNotification = () => { }) {
    try {
        await markAllAsNotificationRead(userId)
        showNotification({ message: "Success", type: NOTIFICATION_TYPES.SUCCESS })
    } catch (error) {
        showNotification({ message: "Important: Could not clear notifications. Please try again later", type: NOTIFICATION_TYPES.ERROR })
    }
}

export async function handleMarkNotificationAsRead(id = 0, userId = 0, showNotification = () => { }) {
    try {
        await markNotificationAsRead(id, userId)
        showNotification({ message: "Success", type: NOTIFICATION_TYPES.SUCCESS })
    } catch (error) {
        showNotification({ message: "Important: Could not clear notification", type: NOTIFICATION_TYPES.ERROR })
    }
}