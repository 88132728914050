import { FormControl, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CustomTextField = styled(TextField)(({ theme }) => ({
    "& label.Mui-focused": {
        color: "#00a79d",
        fontWeight: "500",
    },
    "& label": {
        fontSize: "15px",
        fontWeight: "300",
        color: theme.palette.fieldLabelColor,
    },
    // Default asterisk is hidden for required fields
    "& label.Mui-required .MuiFormLabel-asterisk": {
        display: 'none',
    },
    // Custom asterisk is used for required fields
    "& label.Mui-required::after": {
        content: '"*"',
        color: 'red',
        marginLeft: '2px',
    },
    "& .MuiOutlinedInput-root": {
        "&:hover fieldset": {
            borderColor: "#00a79d",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#00a79d",
        },
    },
}));

export const CustomSelect = styled(FormControl)(({ theme }) => ({
    "& label.Mui-focused": {
        color: "#00a79d",
        fontWeight: "500",
    },
    "& label": {
        fontSize: "15px",
        fontWeight: "300",
        color: theme.palette.fieldLabelColor,
    },
    "& .MuiOutlinedInput-root": {
        "&:hover fieldset": {
            borderColor: "#00a79d",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#00a79d",
        },
    },
}));

export const CustomInput = styled(TextField)(({ theme }) => ({
    "& label.Mui-focused": {
        color: "#00a79d",
        fontWeight: "500",
    },
    "& label": {
        fontSize: "15px",
        fontWeight: "300",
        color: theme.palette.fieldLabelColor,
    },
    "& .MuiInputBase-root": {
        backgroundColor: "#F5F5F5",
        fontSize: "13px",
    },
    "& .MuiOutlinedInput-root": {
        "& svg": {
            height: "20px",
        },
        "& fieldset": {
            borderColor: "#F5F5F5",
        },
        "&:hover fieldset": {
            borderColor: "#00a79d",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#00a79d",
        },
    },
}));