import React from 'react';
import { Dialog, Button } from '@mui/material';

export default function AngieListDeleteDialog({ open, onClose, handleConfirmDelete, configurations, configToDeleteIndex  }) {
    return (
        <Dialog open={open} onClose={onClose}>
            <div className={"dialog"}>
                <h3 className={"dialog-title"}>
                    Are you sure you want to permanently delete the task details ?
                </h3>
                <p style={{ marginTop: "-10px", marginBottom: "15px" }}>
                    {configurations[configToDeleteIndex]?.task_name}
                </p>
                <div style={{ display: "flex", justifyContent: "end" }}>
                <Button
                        size={"small"}
                        variant="contained"
                        onClick={onClose}
                        className={"btn-secondary"}
                        style={{ marginRight: "10px" }}
                    >
                        No
                    </Button>
                    <Button
                        size={"small"}
                        variant="contained"
                        onClick={handleConfirmDelete}
                        className={"btn-primary"}
                    >
                        yes
                    </Button>
                </div>
            </div>
        </Dialog>
    );
}
