import { useEffect, useState } from "react"
import { SmallCardForCrmData } from "../../../../components/servicetitan/SmallCard"
import { handleGetServiceTitanStatisticsForStackFranchises } from "../../../../state-services/reports/reports"
import jobImg from "../../../../assets/img/jobs.png";
import leadImg from "../../../../assets/img/leads.png";
import workorderImg from "../../../../assets/img/workorders.png";
import { Box, CircularProgress, Typography } from "@mui/material";
import ImageBox from "../../../../components/LogoBox/ImageBox";
import { getMatchingBrandByBrandId } from "../../../../utils/miscellaneous"

export default function StackFranchisesStatistics(props) {
    const { filtration, stackFranchises, brands, franchiseBrands, onStackFranchisesStatisticsChange } = props
    const [stackFranchisesStatistics, setStackFranchisesStatistics] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)

    useEffect(() => {
        const matchingBrandName = getMatchingBrandByBrandId(filtration.brandBrandId, brands);
        if (matchingBrandName) {
            filtration.brandName = matchingBrandName.brand_name;
        }
        if (stackFranchises?.length > 0) {
            let stackFranchiseWithBrands = getBrandsOfStackFranchise()
            handleGetServiceTitanStatisticsForStackFranchises(
                filtration.brandBrandId, 
                stackFranchiseWithBrands, 
                filtration.startDate, 
                filtration.endDate, 
                (data) => {
                    const statisticsData = data.map((item) => {
                        const brandNames = item.brands.map((brandUrl) => {
                            const matchingBrand = brands.find(
                                (b) => b.brand_img === brandUrl
                            );
                            return matchingBrand ? matchingBrand.brand_name : null;
                        });
                        return { ...item, brand_names: brandNames };
                    });
                    setStackFranchisesStatistics(statisticsData);
                    onStackFranchisesStatisticsChange(statisticsData);
                },
                setIsLoading,
                setIsError)
        };
    }, [filtration.startDate, filtration.endDate ])

    function getBrandsOfStackFranchise() {
        let stackFranchiseWithBrands = []
        for (let i = 0; i < stackFranchises?.length; i++) {
            let brandIds = []
            for (let j = 0; j < franchiseBrands?.length; j++) {
                if (stackFranchises[i]?.id === franchiseBrands[j]?.franchise_id) {
                    brandIds.push(franchiseBrands[j]?.brand_id)
                }
            }
            let filterdBrandIds = brandIds?.filter((id) => id !== filtration?.brandBrandId)
            let brandImages = []
            for (let k = 0; k < filterdBrandIds?.length; k++) {
                for (let l = 0; l < brands?.length; l++) {
                    if (filterdBrandIds[k] === brands[l]?.brand_id) {
                        brandImages.push(brands[l]?.brand_img)
                    }
                }
            }
            let stackFranchise = stackFranchises[i]
            stackFranchise.brandImages = brandImages
            stackFranchiseWithBrands.push(stackFranchise)
        }
        return stackFranchiseWithBrands
    }

    return <>
        {
            isLoading ?
                <Box sx={{ width: "99%", height: "35vh", display: "flex", alignItems: "center" }}>
                    <Box sx={{ marginLeft: "auto", marginRight: "auto" }}>
                        <CircularProgress color="inherit" />
                    </Box>
                </Box>
                : isError ?
                    <Box sx={{ width: "99%", height: "35vh", display: "flex", alignItems: "center" }}>
                        <Box sx={{ marginLeft: "auto", marginRight: "auto" }}>
                            <h6>{"Something went wrong"}</h6>
                        </Box>
                    </Box>
                    :
                    <Box sx={{ width: "99%", height: "35vh", overflow: "auto", display: "flex", flexDirection: "column", gap: "0.5em" }}>
                        {
                            stackFranchisesStatistics?.map((stackFranchiseStatistics, index) => {
                                return <Box sx={{ width: "100%", backgroundColor: 'reports.main', padding: "1em" }}>
                                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                                        <Typography >{stackFranchiseStatistics?.franchiseName}</Typography>
                                        <Box sx={{ display: "flex", flexDirection: "row", gap: "10px", marginLeft: "auto" }}>
                                            {stackFranchiseStatistics?.brands?.map((brand, index) => {
                                                return <Box sx={{ marginBottom: "10px", marginLeft: "10px" }}>
                                                    <ImageBox src={brand} size={'medium'} />
                                                </Box>
                                            })
                                            }
                                        </Box>
                                    </Box>
                                    <Box key={index} sx={{ display: "flex", flexDirection: "row", gap: "20px", justifyContent: "space-evenly", minWidth: "150px" }}>
                                        <SmallCardForCrmData heading={"Leads"} image={leadImg} value={stackFranchiseStatistics?.st_leads?.toLocaleString?.() ?? "-"} isLoading={isLoading} isError={isError} />
                                        <SmallCardForCrmData heading={"Appointments"} image={workorderImg} value={stackFranchiseStatistics?.st_appointments?.toLocaleString?.() ?? "-"} isLoading={isLoading} isError={isError} />
                                        <SmallCardForCrmData heading={"Jobs"} image={jobImg} value={stackFranchiseStatistics?.st_jobs?.toLocaleString?.() ?? "-"} isLoading={isLoading} isError={isError} />
                                    </Box>
                                </Box>
                            })
                        }
                    </Box>
        }
    </>
}

