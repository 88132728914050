import { Box, Button, Chip, Collapse, Dialog, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { useContext } from "react";
import { CommonContext } from "../../../../../contexts/CommonContextProvider";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from '@mui/icons-material/Remove';
import { getMatchingBrandByBrandId } from "../../../../../utils/miscellaneous";
import iBSBrandLogo from "../../../../../assets/img/brand.png"
import FranchiseBrandCreateStepper from "./FranchiseBrandCreateStepper";
import CopyToClipBoard from "../../../../../components/CopyToClipBoard";
import { AVAILABLE_CRMS, NOTIFICATION_TYPES } from "../../../../../constants/common";
import ImageBox from "../../../../../components/LogoBox/ImageBox";

export const INITIAL_FRANCHISE_BRAND = {
    brand_id: 0,
    channel_ids: [],
    primary_zip_codes: [],
    secondary_zip_codes: []
}

export default function FranchiseBrandsSection(props) {
    const { franchise, isTollFree, franchiseBrands, setFranchiseBrands, isDisabled, selectedFranchiseBrandToEdit, setSelectedFranchiseBrandToEdit, all8x8Channels, isAll8x8ChannelsLoading, isAll8x8ChannelsLoadingError, toggleGetAll8x8Channels, setToggleGetAll8x8Channels } = props;
    const [shouldAddNewFranchiseBrand, setShouldAddNewFranchiseBrand] = useState(false);
    const [newFranchiseBrand, setNewFranchiseBrand] = useState(INITIAL_FRANCHISE_BRAND);

    function handleComplete() {
        setShouldAddNewFranchiseBrand(false)
        setSelectedFranchiseBrandToEdit()
        setNewFranchiseBrand(INITIAL_FRANCHISE_BRAND);
    }

    function handleClose() {
        handleComplete()
        if (selectedFranchiseBrandToEdit) {
            setFranchiseBrands([...franchiseBrands, selectedFranchiseBrandToEdit])
            setSelectedFranchiseBrandToEdit()
            setNewFranchiseBrand();
        }
    }

    function handleOnEdit(b) {
        if (selectedFranchiseBrandToEdit) {
            return false
        } else {
            setSelectedFranchiseBrandToEdit(b)
            setNewFranchiseBrand(b);
            setShouldAddNewFranchiseBrand(true)
            return true
        }
    }

    function handleCreateNewFranchiseBrand() {
        setShouldAddNewFranchiseBrand((s) => !s)
        if (selectedFranchiseBrandToEdit) {
            setFranchiseBrands([...franchiseBrands, selectedFranchiseBrandToEdit])
            setSelectedFranchiseBrandToEdit()
        }
        setNewFranchiseBrand(INITIAL_FRANCHISE_BRAND);
    }

    return <Box>
        {isDisabled ? <></> : <Box sx={{ display: "flex", flexDirection: "row", gap: "0.5em", justifyContent: "space-between", alignItems: "start" }} >
            <Box sx={{ flexGrow: 2 }} >
                <Collapse in={shouldAddNewFranchiseBrand} unmountOnExit>
                    <FranchiseBrandCreateStepper franchise={franchise} isTollFree={isTollFree} handleClose={handleClose} handleComplete={handleComplete} franchiseBrands={franchiseBrands} setFranchiseBrands={setFranchiseBrands} franchiseBrand={newFranchiseBrand} setFranchiseBrand={setNewFranchiseBrand} all8x8Channels={all8x8Channels} isAll8x8ChannelsLoading={isAll8x8ChannelsLoading} isAll8x8ChannelsLoadingError={isAll8x8ChannelsLoadingError} toggleGetAll8x8Channels={toggleGetAll8x8Channels} setToggleGetAll8x8Channels={setToggleGetAll8x8Channels} />
                </Collapse>
            </Box>
            <Tooltip title={shouldAddNewFranchiseBrand ? "Cancel" : "Add"}>
                <span>
                    <IconButton data-test="franchise_brand_add" sx={{ marginTop: "0.5em" }} size='small' className="icon-btn" onClick={handleCreateNewFranchiseBrand} >
                        {shouldAddNewFranchiseBrand ? <RemoveIcon /> : <AddIcon />}
                    </IconButton>
                </span>
            </Tooltip>
        </Box>}
        <FranchiseBrandsTable franchise={franchise} isDisabled={isDisabled} isTollFree={isTollFree} franchiseBrands={franchiseBrands} setFranchiseBrands={setFranchiseBrands} handleOnEdit={handleOnEdit} />
    </Box>
}

function FranchiseBrandsTable(props) {
    const { franchise, isTollFree, isDisabled, franchiseBrands, setFranchiseBrands, handleOnEdit = (b) => { } } = props;

    const { brands, showNotification } = useContext(CommonContext)
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
    const [selectedBrandToDeleteIndex, setSelectedBrandToDeleteIndex] = useState()

    const userFranchiseBrands = useMemo(() => {
        return franchiseBrands?.filter?.((fb) => brands?.some?.((b) => b?.brand_id === fb?.brand_id))
    }, [brands, franchiseBrands])

    const firstBrandVonigoRedirectUrl = useMemo(() => {
        const brand = getMatchingBrandByBrandId(userFranchiseBrands[0]?.brand_id, brands)
        return brand?.redirect_url ?? ""
    }, [userFranchiseBrands?.[0]?.brand_id, brands])

    function handleDeleteDialogClose() {
        setSelectedBrandToDeleteIndex()
        setIsDeleteDialogOpen(false)
    }

    function handleDeleteDialogOpen(i) {
        setSelectedBrandToDeleteIndex(i)
        setIsDeleteDialogOpen(true)
    }

    function handleDelete() {
        setFranchiseBrands((brands = []) => {
            const newBrands = brands.filter((_, i) => i !== selectedBrandToDeleteIndex)
            return newBrands;
        })
        handleDeleteDialogClose()
    }

    return <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2, padding: "1em" }}>
            <TableContainer>
                <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={'small'}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell align="left">Brand</TableCell>
                            <TableCell align="left" >Channel IDs</TableCell>
                            {isTollFree ? <></> : <TableCell align="left" >Primary ZIP/Postal Codes</TableCell>}
                            {isTollFree ? <></> : <TableCell align="left" >Secondary ZIP/Postal Codes</TableCell>}
                            {isTollFree ? <></> : <TableCell align="left" >Angie's Entity Ids</TableCell>}
                            {isTollFree ? <></> : <TableCell align="left" >Outbound Channel</TableCell>}
                            {isDisabled ? <></> : <TableCell align="center">Actions</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {userFranchiseBrands?.length === 0 ? <TableCell component="th" align={"center"} colSpan={6} scope="row">No Data to Show</TableCell>
                            : userFranchiseBrands?.map?.((b, index) => {
                                const labelId = `enhanced-table-checkbox-${index}`;
                                const brand = getMatchingBrandByBrandId(b?.brand_id, brands)
                                return (
                                    <TableRow sx={{ backgroundColor: getShouldHighlight(firstBrandVonigoRedirectUrl, brand, franchise) ? "#ffb74d" : undefined, "&:hover": { backgroundColor: getShouldHighlight(firstBrandVonigoRedirectUrl, brand, franchise) ? "#ffb74d" : undefined } }} tabIndex={-1} key={b.brand_id}>
                                        <TableCell component="th" align={"left"} padding={"1em"} id={labelId} scope="row">{index + 1}</TableCell>
                                        <TableCell sx={{ maxWidth: "180px" }} align="left" >
                                            <Tooltip title={getMatchingBrandByBrandId(b.brand_id, brands)?.brand_name ?? ""} >
                                                <ImageBox alt={"brand-img"} src={getMatchingBrandByBrandId(b?.brand_id, brands)?.brand_img ?? iBSBrandLogo} size={'xsmall'} />
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="left" >{b?.channel_ids?.map?.((channelId) => { return <Chip sx={{ marginTop: "0.2em", marginRight: "0.2em" }} label={channelId} /> }) ?? <></>}</TableCell>
                                        {isTollFree ? <></> : <TableCell component="th" align={"left"} id={labelId} scope="row">{b?.primary_zip_codes?.length ? <CopyToClipBoard textToCopy={b?.primary_zip_codes?.join?.(",")} >{b?.primary_zip_codes?.map?.((zipCode) => { return <Chip sx={{ marginTop: "0.2em", marginRight: "0.2em" }} label={zipCode} /> }) ?? <></>}</CopyToClipBoard> : ""}</TableCell>}
                                        {isTollFree ? <></> : <TableCell component="th" align={"left"} id={labelId} scope="row">{b?.secondary_zip_codes?.length ? <CopyToClipBoard textToCopy={b?.secondary_zip_codes?.join?.(",")} >{b?.secondary_zip_codes?.map?.((zipCode) => { return <Chip sx={{ marginTop: "0.2em", marginRight: "0.2em" }} label={zipCode} /> }) ?? <></>}</CopyToClipBoard> : ""}</TableCell>}
                                        {isTollFree ? <></> : <TableCell component="th" align={"left"} id={labelId} scope="row">{b?.franchise_angies_list_sp_entity_ids?.map?.((id) => { return <Chip sx={{ marginTop: "0.2em", marginRight: "0.2em" }} label={`${id}`} /> }) ?? <></>}</TableCell>}
                                        {isTollFree ? <></> : <TableCell component="th" align={"left"} id={labelId} scope="row">{b?.outbound_channels?.[0]?.channel_id ? <Chip sx={{ marginTop: "0.2em", marginRight: "0.2em" }} label={`${b?.outbound_channels?.[0]?.channel_id}`} /> : <></>}</TableCell>}
                                        {isDisabled ? <></> : <TableCell align="center" >
                                            <Tooltip title={"Edit"}>
                                                <span style={{ height: "100%", margin: "auto" }}>
                                                    <IconButton data-test="franchise_brand_edit" size='small' title={`Edit`} color="info"
                                                        onClick={() => {
                                                            const ok = handleOnEdit(b)
                                                            if (ok) {
                                                                setFranchiseBrands((brands = []) => {
                                                                    const newBrands = brands.filter((_, i) => i !== index)
                                                                    return newBrands;
                                                                })
                                                            } else {
                                                                showNotification({ isOpen: true, type: NOTIFICATION_TYPES.WARNING, message: "Please complete current edit and try again" })
                                                            }
                                                        }}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                            <Tooltip title={"Delete"}>
                                                <span style={{ height: "100%", margin: "auto" }}>
                                                    <IconButton data-test="franchise_brand_delete" size='small' title={`Delete`} color="error"
                                                        onClick={() => handleDeleteDialogOpen(index)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </TableCell>}
                                    </TableRow>
                                );
                            }) ?? <></>}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog open={isDeleteDialogOpen} onClose={handleDeleteDialogClose}>
                <Box data-test="franchise_brand_delete_dialog" sx={{ margin: "1em" }}>
                    <Typography variant="p" component="div">⚠️  Please note that any information related to this brand will be permanently removed from the following areas.</Typography>
                    <ul>
                        <li>Channel Ids</li>
                        {isTollFree ? <></> : <li>ZIP/Postal Codes</li>}
                        {isTollFree ? <></> : <li>Angie's Entity Ids</li>}
                        {isTollFree ? <></> : <li>Franchise FAQ's related to brand</li>}
                    </ul>
                    <Box sx={{ display: "flex", flexDirection: "row", gap: "0.5em" }} >
                        <Box sx={{ flexGrow: 2 }} />
                        <Button data-test="franchise_brand_delete_cancel" variant="text" onClick={handleDeleteDialogClose}>Cancel</Button>
                        <Button data-test="franchise_brand_delete_delete" variant="contained" onClick={handleDelete}>Delete</Button>
                    </Box>
                </Box>
            </Dialog>
        </Paper>
    </Box >;

    function getShouldHighlight(firstBrandVonigoRedirectUrl, brand, franchise) {
        return brand?.redirect_url !== firstBrandVonigoRedirectUrl && franchise?.service_brand === AVAILABLE_CRMS.vonigo.value && franchise?.is_toll_free === 0
    }

}
