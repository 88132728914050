import { Autocomplete, Button, IconButton, Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import EditIcon from '@mui/icons-material/Edit';
import React, { useState } from 'react'
import AddressBox from '../../../../../../../components/AddressBox'
import RenderInput from '../../../../../../../components/servicetitan/RenderInput'
import { AVAILABLE_CRMS, COUNTRIES, NOTIFICATION_TYPES } from '../../../../../../../constants/common'
import { CustomTextField } from '../../../../../jobs/components/customComponents'
import LoadingButton from '../../../../../../../components/LoadingButton';
import { MAX_FRANCHISE_LOCATION_RADIUS } from '../../../../../../../constants/location';
import AddressAutoComplete from '../../../../../../../components/AddressAutoComplete';
import { updateCustomerLocation } from '../../../../../../../services/serviceTitan';

export default function LocationSection({ franchise, selectedCustomer, setLocations, locations, states, isLocationsLoading, selectedLocation, setSelectedLocation, showNotification }) {

    const [editLocationDetails, setEditLocationDetails] = useState(null)
    const [onEditLocationLoading, setOnEditLocationLoading] = useState(false)

    function onLocationEditPressed() {
        setEditLocationDetails(selectedLocation)
    }

    function onEditCustomerCancelPressed() {
        setEditLocationDetails(null)
    }

    function onEditDone(editedLocation) {
        const index = locations.findIndex((v) => v.id === editedLocation.id)
        locations[index] = editedLocation
        setLocations(locations)
        setSelectedLocation(editedLocation)
        showNotification({ message: "Successfully saved", type: NOTIFICATION_TYPES.SUCCESS })
        setEditLocationDetails(null)
    }

    function onErrorEditLocation(err) {
        let errDetails = JSON.parse(err.responseText)
        let errCode = errDetails?.data?.ErrorCode
        let stateErr
        if (errCode === "StateNotValid") {
            stateErr = "State doesn't valid with country"
        } else if (errCode === "CountryNotValid") {
            stateErr = "Country is not valid"
        } else {
            stateErr = ""
        }
        showNotification({ message: `Important: Could not edit the location. ${stateErr}. Please try again`, type: NOTIFICATION_TYPES.ERROR })
    }

    async function handleEditLocation(franchise, editedLocation, setOnEditLocationLoading, onDone, onError) {
        setOnEditLocationLoading(true)
        try {
            const res = await updateCustomerLocation(franchise.id, editedLocation.id, editedLocation)
            onDone(res)
        } catch (err) {
            onError(err)
        } finally {
            setOnEditLocationLoading(false)
        }
    }

    function onAddressChanged(address) {
        setEditLocationDetails({ ...editLocationDetails, address: address })
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: "column", gap: "0.5em" }}>
            <Box sx={{ display: 'flex', flexDirection: "row", width: "100%", height: "3em", alignItems: "center" }}>
                <Box sx={{ display: "flex", flexDirection: "row", minWidth: "200px" }}>
                    <h4>Location Details</h4>
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                        <Tooltip title="Edit Location" ><IconButton onClick={onLocationEditPressed} disabled={editLocationDetails !== null} size="small" ><EditIcon color={!editLocationDetails ? "primary" : "disabled"} /></IconButton></Tooltip>
                    </Box>
                </Box>
            </Box>
            {!editLocationDetails ?
                <ExistingLocationSection selectedCustomer={selectedCustomer} states={states} locations={locations} isLocationsLoading={isLocationsLoading} selectedLocation={selectedLocation} setSelectedLocation={setSelectedLocation} />
                :
                <EditLocationSection franchise={franchise} onAddressChanged={onAddressChanged} states={states} editLocationDetails={editLocationDetails} setEditLocationDetails={setEditLocationDetails}
                    onEditCancel={onEditCustomerCancelPressed} onEditDone={onEditDone} handleEditLocation={handleEditLocation} isEditLocationLoading={onEditLocationLoading} setIsEditLocationLoading={setOnEditLocationLoading} onErrorEditLocation={onErrorEditLocation} />
            }
        </Box>
    )
}

function ExistingLocationSection({ selectedCustomer, locations, isLocationsLoading, selectedLocation, setSelectedLocation, states = [] }) {
    return (
        < Box >
            <Box sx={{ display: 'flex', flexDirection: "row", flexWrap: "wrap", width: "100%", gap: "1em" }} >
                <Autocomplete
                    sx={{ width: "45%", }}
                    size="small"
                    noOptionsText={selectedCustomer ? "No Locations found for customer" : "Please select a customer"}
                    loading={isLocationsLoading}
                    value={locations?.length > 0 ? selectedLocation ?? null : null}
                    disablePortal
                    disableClearable
                    options={locations}
                    getOptionLabel={(location) => location.name}
                    renderOption={(props, location) => <RenderInput {...props} key={location?.id} content={location?.name} />}
                    onChange={(_, l) => {
                        setSelectedLocation(l);
                    }}
                    renderInput={(params) => <CustomTextField   {...params} required={true} label="Select Customer's Location" />}
                />
                <AddressBox address={selectedLocation?.address} isNotLeadSection={false} isDisabled={true} isRequired={false} isSelectableStates={false} countries={COUNTRIES} states={states} />
            </Box>
        </Box >
    )
}

function EditLocationSection({ franchise, onAddressChanged, editLocationDetails, setEditLocationDetails, states = [],
    onEditCancel, onEditDone, handleEditLocation, isEditLocationLoading, setIsEditLocationLoading, onErrorEditLocation }) {

    const [isValidZipCode, setIsValidZipCode] = useState(true)

    function onNameChanged(e) {
        setEditLocationDetails({ ...editLocationDetails, name: e.target.value })
    }

    return (
        < Box >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "1em", width: "100%" }}>
                <AddressAutoComplete label={"Search Location Address"} onChange={onAddressChanged} lat={franchise?.latitude} long={franchise?.longitude} radius={MAX_FRANCHISE_LOCATION_RADIUS} crm={AVAILABLE_CRMS.servicetitan.value} />
                <Box sx={{ display: 'flex', flexDirection: "row", flexWrap: "wrap", width: "100%", gap: "1em" }} >
                    <CustomTextField style={{ width: "45%" }} required={true} value={editLocationDetails?.name ?? ""} size="small" label="Location Name" onChange={onNameChanged} />
                    <AddressBox address={editLocationDetails?.address} onChangeAddress={onAddressChanged} setIsValidZipCode={setIsValidZipCode} isDisabled={false} isRequired={false} isSelectableStates={false} countries={COUNTRIES} states={states} />
                </Box>
            </Box>
            <Box sx={{ marginLeft: 'auto' }} >
                <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "right", gap: "1em" }} >
                    <Button size={"small"} variant="contained" onClick={onEditCancel} className={"btn-secondary"} >Cancel</Button>
                    <LoadingButton
                        onClick={() => { handleEditLocation(franchise, editLocationDetails, setIsEditLocationLoading, onEditDone, onErrorEditLocation) }}
                        size={"small"}
                        variant="contained"
                        disabled={isEditLocationLoading || !isValidZipCode || editLocationDetails?.address?.unit?.length > 50}
                        loading={isEditLocationLoading}
                        className={isEditLocationLoading ? "btn-disable" : "btn-primary"} >
                        {"Update Location"}
                    </LoadingButton>
                </Box>
            </Box>
        </Box >
    )
}