import React from 'react';
import Chip from '@mui/material/Chip';

export default function Hashtag({ label }) {
    return (
        <Chip
            label={`#${label}`}
            variant="outlined"
            style={{ marginRight: '0.5em', marginBottom: '0.5em' }}
        />
    );
}