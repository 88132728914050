export default function chartOptions(chartLabels) {

    const checkDigitsOfNumber = (number) => {
        return number?.toString().length
    }

    const makeTimeValue = (time) => {
        let timeString = ""
        checkDigitsOfNumber(time) >= 2 ? timeString = time : timeString = `0${time}`
        return timeString
    }

    return {
        dataLabels: { style: { fontSize: "14px" } },
        labels: chartLabels,
        legend: { show: true, fontSize: "15px", position: "right" },
        colors: ["#038FFA", "#68E497", "#FCB031", "#F14C5F", "#775DD0", "#671F34"],
        tooltip: {
            style: {
                fontSize: "20px"
            },
            y: {
                formatter: (val) => {
                    let minutes = Math.floor(val / 60);
                    val = val % 60;
                    let hours = Math.floor(minutes / 60)
                    minutes = minutes % 60;
                    let hoursTime = makeTimeValue(hours)
                    let minutesTime = makeTimeValue(minutes)
                    let secondsTime = makeTimeValue(val)
                    return `${hoursTime}:${minutesTime}:${secondsTime}`;
                }
            }
        }
    }
}
