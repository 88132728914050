export const DARK_THEME = {
	ENABLED: 1,
	DISABLED: 0,
};

export const DIALLER_PROCEED = [
	{label: "Proceed", id: 1},
	{label: "Proceed in new tab", id: 2},
	{label: "Clear and proceed in new tab", id:3},
]
